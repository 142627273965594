import { Theme } from "@emotion/react";

const theme: Theme = {
  fontSizes: {
    smaller: 10,
    small: 12,
    default: 14,
    medium: 16,
    heading: 18,
    large: 20,
    larger: 24,
    heading1: 30,
    strongHeading: 56,
    heading2: 28,
    heading3: 42,
    heading4: 36,
    heading5: 40,
  },
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
  gap: [0, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24],
  iconSize: {
    smaller: 8,
    small: 16,
    default: 24,
    medium: 30,
  },
  margin: {
    small: 4,
    default: 8,
    mediumSmaller: 12,
    mediumSmall: 14,
    medium: 16,
    large: 20,
    larger: 24,
    veryLarge: 30,
  },
  padding: {
    small: 4,
    default: 8,
    mediumSmaller: 12,
    mediumSmall: 14,
    medium: 16,
    large: 20,
    larger: 24,
    veryLarge: 30,
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 600,
    medium: 500,
  },
  radii: {
    smaller: "4px",
    small: "6px",
    medium: "10px",
    large: "14px",
    larger: "20px",
    full: "50%",
  },
  colors: {
    panel: "#f6f7f8",
    text: "#08090a",
    text2: "#191919",
    text3: "rgba(29, 33, 38, 0.5)",
    navy: "#00212c",
    red: "#de3d57",
    primary: "#FA4865",
    primaryLight: "#FEDAE0",
    success: "#55d9b1",
    error: "#ff3b31",
    gray: "#9eacaf",
    lightSlateGray: "#7A869A",
    lightGray: "#edf2f7",
    gray100: "#8F92A1",
    gray200: "#5B5B5B",
    gray300: "#5D5D5D",
    seaGreen: "#34A853",
    brightGreen: "#49C200",
    white: "#ffffff",
    black: "#000000",
    cinder: "#131316",
    checkboxChecked: "#56DCAB",
    checkBoxUnchecked: "#C9CBCF",
    inputBorder: "#D9D9D9",
    radioBorder: "#E0E0E0",
    grayText: "#8E9093",
    erieBlack: "#1E1E1E",
    lightBg: "#EFEFEF",
    vLightBg: "#cccccc",
    taupeGray: "#888",
    cultured: "#F2F8F4",
    bangladeshGreen: "#087348",
    disabled: "rgba(0, 0, 0, 0.25)",
    darkSilver: "#6F6F6F",
    venetianRed: "#DF1B41",
    blue: "#3085C3",
    brightGray: "#E9E9E9",
    lightBlue: "#1D44CE",
    blue2: "#0052FE",
    lightOrange: "#E7B549",
  },
  map: {
    colorRange: ["#0000ff", "#9397fa", "#ffded7", "#ff8174", "#ff0000"],
    colorRangeReverse: ["#ff0000", "#ff8174", "#ffded7", "#9397fa", "#0000ff"],
    paintPercentileColorRange: [
      "#0000ff",
      "#836cfe",
      "#c1b6fe",
      "#fafafa",
      "#ffb3a4",
      "#ff6d54",
      "#ff0000",
    ],
    paintPercentileColorRangeRevers: [
      "#ff0000",
      "#ff6d54",
      "#ffb3a4",
      "#fafafa",
      "#c1b6fe",
      "#836cfe",
      "#0000ff",

    ],
  },
  buttons: {
    primary: {
      color: "white",
      bg: "#00212c",
      borderRadius: 2,
      outline: "none",
      cursor: "pointer",
      transition: "all 0.4s ease",
      textDecoration: "none",
      maxHeight: "50px",
      fontSize: 16,
      "&:hover": {
        transition: "all 0.4s ease",
        opacity: 0.85,
      },
    },
    secondary: {
      color: "#00212c",
      bg: "#9eacaf",
      borderRadius: 2,
      outline: "none",
      cursor: "pointer",
      transition: "all 0.4s ease",
      textDecoration: "none",
      maxHeight: "50px",
      fontSize: 16,
      "&:hover": {
        transition: "all 0.4s ease",
        opacity: 0.85,
      },
    },
    alternate: {
      color: "white",
      bg: "red",
      borderRadius: 2,
      outline: "none",
      cursor: "pointer",
      transition: "all 0.4s ease",
      textDecoration: "none",
      maxHeight: "50px",
      fontSize: 16,

      "&:hover": {
        transition: "all 0.4s ease",
        opacity: 0.85,
      },
    },
  },
  variants: {
    card: {
      p: 2,
      bg: "gray",
      borderRadius: 2,
    },
  },
  textShadow: `1.2px 1.2px 0 #fff,
  -1.2px -1.2px 0 #fff,
  1.2px -1.2px 0 #fff,
  -1.2px 1.2px 0 #fff,
  0px 1.2px 0 #fff,
  1.2px 0px 0 #fff,
  0px -1.2px 0 #fff,
  -1.2px 0px 0 #fff`,
  shadow: `-webkit-box-shadow: 5px 5px 15px 1px rgba(204,192,204,0.8);
  -moz-box-shadow: 5px 5px 15px 1px rgba(204,192,204,0.8);
  box-shadow: 5px 5px 15px 1px rgba(204,192,204,0.8);`,
  drawerShadow: "-1px 1px 10px 1px rgba(148, 148, 148, 0.72)",

  reportBgGradients: [
    "linear-gradient(140deg, #FEF2F1 13.77%, #F2FEEC 85.44%)",
    "linear-gradient(140deg, #FEF2FE 13.77%, #EDFEFE 85.44%)",
    "linear-gradient(140deg, #F2FEEC 13.77%, #F1F1FE 85.44%)",
    "linear-gradient(140deg, #FEE 13.77%, #EFFEFF 85.44%)",
  ],
};

export default theme;
