import { InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { useTheme } from "@emotion/react";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import Radio from "components/Radio";
import { Text } from "components/Text";
import CrossIcon from "components/icons/CrossIcon";
import useUser, { useUserData } from "hooks/useUser";
import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { createHelpRequest } from "services/ZendeskService";
import axios from "axios";
import { getRelativePathname } from "lib/getRelativePathname";
import posthog from "posthog-js";

const DeleteAccount = (): JSX.Element => {
  const user = useUserData();
  const router = useRouter();
  const theme = useTheme();
  const [comment, setComment] = useState<string>("");
  const [selectedPoint, setSelectedPoint] = useState(
    "This app is not what I expected",
  );

  const FeedbackOptions = [
    "This app is not what I expected",
    "Found a better alternative",
    "Difficulty with cancelling subscription or managing services",
    "Not sure how to use the app",
    "Trying to reduce my online footprint",
    "Others (please specify)",
  ];

  const handleSendFeedback = async () => {
    const userName = user.name;
    const email = user.email;
    const userId = user.id;
    try {
      const { data } = await axios.post("/api/delete-user-account", {
        userId: userId,
      });
      createHelpRequest({
        comment: `${selectedPoint}. ${comment}`,
        user: { email: email, name: userName },
        subject: "Deleted Account",
      });
      posthog.identify(email, {
        is_deleted: true,
      });
      await user.signOut!();
      router.replace(`${getRelativePathname(router)}`);
    } catch (error: any) {
      toast(error.message, {
        type: "error",
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
    }
  };
  return user.uid &&
    router.pathname.includes("help") &&
    router.query.DeleteAccount &&
    router.query.tab ? (
    <>
      <Modal
        overlayStyle={{ overflow: "auto" }}
        contentStyle={{
          padding: 0,
          background:
            router.query.tab === "info"
              ? "white"
              : "linear-gradient(0deg, white 95%, transparent 5%)",
          maxWidth: 360,
          width: "100%",
        }}
        isOpen={!!router.query.DeleteAccount}
      >
        <Flex
          onClick={() => {}}
          align="center"
          justify="center"
          css={{
            position: "absolute",
            width: "100%",
            top: router.query.tab === "info" ? 15 : 50,
            right: 0,
          }}
        >
          <Flex
            justify={router.query.tab === "info" ? "space-between" : "end"}
            align="center"
            css={{
              width: "100%",
              padding: "0 15px",
            }}
          >
            {router.query.tab === "info" && (
              <Flex>
                <Text css={{ fontSize: "20px", fontWeight: 700 }}>
                  Delete Account
                </Text>
              </Flex>
            )}
            <Flex
              justify="center"
              align="center"
              onClick={() => {
                router.push(router.pathname);
              }}
              css={{
                cursor: "pointer",
                background: "#EFEFEF",
                borderRadius: 4,
                height: 37,
                width: 37,
              }}
            >
              <CrossIcon />
            </Flex>
          </Flex>
        </Flex>

        {!!router.query.DeleteAccount && router.query.tab === "info" && (
          <Flex direction="column" css={{ margin: "15px", marginTop: "80px" }}>
            <Flex css={{ width: "100%" }} justify="center">
              <WarningOutlined style={{ fontSize: "60px", color: "red" }} />
            </Flex>
            <Text
              css={{
                fontSize: "24px",
                fontWeight: 700,
                width: "100%",
                textAlign: "center",
                margin: "15px 0",
                padding: "0 32px",
              }}
            >
              Are you sure you want to delete this account ?
            </Text>
            <Flex gap={16} direction="column">
              <Text css={{ fontSize: "16px", color: theme.colors.grayText }}>
                If you delete this account, all your information will be
                deleted.
              </Text>
              <Text
                css={{ fontSize: "16px", fontWeight: 700 }}
                color={theme.colors.black}
              >
                I understand that :
              </Text>
              <Flex css={{ height: "30px" }} align="center" gap={12}>
                <InfoCircleOutlined
                  style={{ fontSize: "20px", color: "red" }}
                />
                <Text css={{ fontSize: "16px", color: theme.colors.grayText }}>
                  My account will be deleted and I won’t be able to sign in or
                  access my account.
                </Text>
              </Flex>
              <Flex css={{ height: "30px" }} align="center" gap={12}>
                <InfoCircleOutlined
                  style={{ fontSize: "20px", color: "red" }}
                />
                <Text css={{ fontSize: "16px", color: theme.colors.grayText }}>
                  My subscription will be cancelled.
                </Text>
              </Flex>
            </Flex>
            <Button
              onClick={() => {
                if (
                  !!router.query.DeleteAccount &&
                  router.query.tab === "info"
                ) {
                  router.push("/profile/help?DeleteAccount=true&tab=feedback");
                }
              }}
              css={{
                background: theme.colors.red,
                padding: 10,
                color: "white",
                width: "100%",
                marginTop: 20,
                marginBottom: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 8,
                borderRadius: 3,
              }}
            >
              Delete Account
            </Button>
          </Flex>
        )}
        {!!router.query.DeleteAccount && router.query.tab === "feedback" && (
          <Flex
            direction="column"
            justify="center"
            align="center"
            css={{ padding: "15px", marginTop: "130px", width: "100%" }}
          >
            <Flex
              justify="center"
              align="center"
              css={{
                padding: "8px",
                background: "white",
                borderRadius: theme.radii.full,
                position: "absolute",
                top: 0,
              }}
            >
              <Flex
                justify="center"
                align="center"
                css={{
                  width: 133,
                  height: 133,
                  background: "#FF7C7C",
                  borderRadius: theme.radii.full,
                }}
              >
                <Image
                  src={"/assets/icons/logo-short.png"}
                  width={50}
                  alt="pfp"
                  css={{
                    color: "white",
                  }}
                  height={24}
                />
              </Flex>
            </Flex>
            <Text
              css={{
                fontSize: "24px",
                fontWeight: 700,
                width: "100%",
                textAlign: "center",
                margin: "5px 0",
                padding: "0 32px",
              }}
            >
              {user?.name?.split("@")[0] || user?.email?.split("@")[0]}, before
              you go...
            </Text>
            <Text
              css={{
                fontSize: "16px",
                color: theme.colors.grayText,
                textAlign: "center",
              }}
            >
              {"We're sorry you're thinking of leaving us."}
            </Text>
            <Text
              css={{
                fontSize: "16px",
                color: theme.colors.grayText,
                textAlign: "center",
              }}
            >
              Would you like to tell us why?
            </Text>
            <Flex gap={10} css={{ marginTop: 12 }} direction="column">
              {FeedbackOptions.map((item) => (
                <div
                  css={{
                    width: "100%",
                    border: `1px solid ${theme.colors.radioBorder}`,
                    padding: "8px 10px",
                    borderRadius: theme.radii.smaller,
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    cursor: "pointer",
                  }}
                  key={item}
                  onClick={() => {
                    setSelectedPoint(item);
                  }}
                >
                  <Radio
                    variant="primary"
                    hideBorder
                    checked={selectedPoint === item}
                  />
                  <div>{item}</div>
                </div>
              ))}
              <textarea
                css={{
                  width: "100%",
                  border: `1px solid ${theme.colors.inputBorder}`,
                  padding: "10px 16px",
                  borderRadius: theme.radii.smaller,
                  ":focus": {
                    outline: "none",
                  },
                  resize: "unset",
                }}
                placeholder="Anything you want to share (optional)"
                rows={4}
                name="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <Button
                onClick={handleSendFeedback}
                css={{ width: "100%" }}
                variant="alternate"
              >
                {"Send feedback"}
              </Button>
            </Flex>
          </Flex>
        )}
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default DeleteAccount;
