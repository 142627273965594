import { useTheme } from "@emotion/react";
import { Modal } from "antd";
import { Button } from "components/Button";
import { Flex } from "components/Flex";
import RoundedCrossIcon from "components/icons/RoundedCrossIcon";
import { PaymentModalContext } from "components/provider/PaymentModalProvider";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import useFilters from "hooks/useFilters";
import { useUserData } from "hooks/useUser";
import { currencyFormatter } from "lib/currencyFormatter";
import { getNameForCode } from "lib/helpers/exploratoryHelpers";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { useContext, useEffect, useState } from "react";
import Stripe from "stripe";

const stripe = new Stripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string, {
  apiVersion: "2022-11-15",
});

const PaymentModal = (): JSX.Element => {
  const theme = useTheme();
  const user = useUserData();
  const router = useRouter();
  const posthog = usePostHog();
  const { planPopupTrigger } = useContext(PlanModalContext);
  const { exploratory } = useFilters();
  const { setShowPaymentModal, showPaymentModal } =
    useContext(PaymentModalContext);

  const [amount, setAmount] = useState<number | null>(null);

  useEffect(() => {
    const amount = !!router.query?.paymentAmount
      ? Number(router.query?.paymentAmount)
      : null;
    if (amount !== null && user?.uid) {
      posthog.identify(user.email, {
        plan: user?.plan,
        planId: user?.planId,
        cadence: user?.planCycle,
        amount: (amount / 100).toFixed(2),
      });
      let upgradeTrigger = planPopupTrigger;
      let datapoint = exploratory as string;
      if (planPopupTrigger?.includes(": ")) {
        upgradeTrigger = planPopupTrigger.split(": ")[0];
        datapoint = planPopupTrigger.split(": ")[1];
      }
      setShowPaymentModal(true);
      setAmount(amount);
    }
  }, [router.query, user?.uid]);

  const handleContinue = () => {
    setShowPaymentModal(false);
    delete router.query["paymentAmount"];
    let relativeURL = "";
    const slug = router.query.slug;
    if (slug) {
      relativeURL = router.pathname.replace("[slug]", slug as string);
    } else {
      relativeURL = router.pathname;
    }
    router.replace({ pathname: relativeURL, query: router.query }, undefined, {
      shallow: true,
    });
  };

  return (
    <Modal
      open={showPaymentModal}
      closable={false}
      footer={null}
      width={500}
      bodyStyle={{ padding: `20px 15px 15px` }}
      destroyOnClose
    >
      <Flex
        justify="center"
        align="center"
        onClick={() => {
          setShowPaymentModal(false);
          handleContinue();
        }}
        css={{
          position: "absolute",
          top: -15,
          right: -15,
          width: 30,
          height: 30,
          borderRadius: theme.radii.full,
          backgroundColor: theme.colors.white,
          filter: `drop-shadow(-6px 6px 12px rgba(0, 0, 0, 0.34))`,
          cursor: "pointer",

          ":hover": {
            backgroundColor: theme.colors.lightBg,
          },
        }}
      >
        <RoundedCrossIcon />
      </Flex>

      <Flex direction="column">
        <iframe
          width={"100%"}
          height={264}
          src={"https://www.youtube.com/embed/ZDKPiiDSG0s"}
          css={{ border: "none", marginBottom: theme.margin.large }}
        />
        <Flex
          direction="column"
          justify="center"
          gap={15}
          css={{ margin: "0 20px" }}
        >
          <Flex
            direction="column"
            justify="center"
            align="center"
            gap={8}
            css={{ width: "100%" }}
          >
            <span
              css={{
                color: theme.colors.black,
                fontSize: theme.fontSizes.larger,
                fontWeight: theme.fontWeights.heading,
                lineHeight: "36px",
              }}
            >
              Hi {user.name} 👋
            </span>
            <span
              css={{
                color: theme.colors.black,
                fontSize: theme.fontSizes.larger,
                fontWeight: theme.fontWeights.heading,
                lineHeight: "36px",
              }}
            >
              Welcome to Reventure
            </span>
            <span
              css={{
                color: "rgba(0, 0, 0, 0.72)",
                fontSize: theme.fontSizes.medium,
                textAlign: "center",
              }}
            >
              Thanks for your purchase and get ready to Unleash the Power of
              Real Estate Data
            </span>
          </Flex>

          <Flex
            direction="column"
            gap={theme.gap[3]}
            css={{ width: "100%", fontSize: theme.fontSizes.medium }}
          >
            <Flex
              justify="space-between"
              align="center"
              css={{ width: "100%" }}
            >
              <span>Plan</span>
              <span>{user?.plan}</span>
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              css={{ width: "100%" }}
            >
              <span>Type</span>
              <span>{user?.planCycle}</span>
            </Flex>

            <Flex
              justify="space-between"
              align="center"
              css={{ fontWeight: theme.fontWeights.bold, width: "100%" }}
            >
              <span>Amount Paid</span>
              <span>{currencyFormatter((amount || 0) / 100)}</span>
            </Flex>
          </Flex>
          <Button
            css={{
              marginTop: theme.margin.medium,
              backgroundColor: theme.colors.black,
              color: theme.colors.white,
              alignSelf: "center",
              width: "80%",
            }}
            onClick={handleContinue}
          >
            Go to Reventure app
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default PaymentModal;
