import scoreConfigs from "lib/scoreBoard/scoreConfigs";
import { Geo } from "types/MapContext";
import { CombinedDatapoints, Datapoints, ScoreDatapoints } from "types/cube";

export enum ExploratoryType {
  EXPLORATORY = "exploratory",
  SCORE = "score",
}

export interface AllExploratoryOption {
  value: CombinedDatapoints;
  label: string;
  category: string;
  ytVideoCode: string;
  geo: Geo[];
  type: ExploratoryType;
  isNew?: boolean;
  isComingSoon?: boolean;
  parentScores?: ScoreDatapoints[];
}

export const exploratoryGroupMap = {
  popular: "Popular Data",
  homePriceAndAffordability: "Home Price & Affordability",
  marketTrend: "Market Trends",
  investorMetrics: "Investor Metrics",
  demographic: "Demographic",
  scores: "Reventure Scores",
  bls: "Labour Statistics",
};

export const allExploratoryOptions: AllExploratoryOption[] = [
  {
    value: Datapoints.HOME_VALUE,
    label: "Home Value",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "N9HJm0j2mO0",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.HOME_VALUE_GROWTH_RATE,
    label: "Home Value Growth (YoY)",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "r-FZQctXzn8",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE,
    label: "Home Value Growth (5-Year)",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "HxRWCki4xN4",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.OVER_UNDER_VALUED_PERCENTAGE,
    label: "Overvalued %",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "w9tD6t3fCDk",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.HOME_VALUE_TO_EARNINGS_RATIO,
    label: "Value / Income Ratio",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "HChwbILkmUE",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.TYPICAL_HOUSE_PAYMENT,
    label: "Mortgage Payment",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "b-_iIYiGHNc",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.SALARY_TO_AFFORD_HOUSE,
    label: "Salary to Afford a House",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "zcfUFKfNtoU",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.HOUSE_PAYMENT_RATE_OF_MED_INCOME,
    label: "Mtg Payment as % of Income",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "kV4KBi594gw",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.PROPERTY_TAX_RATE,
    label: "Property Tax Rate",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "I5isb-WdgBQ",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.BUY_VERSUS_RENT_PERCENTAGE,
    label: "Buy v Rent Differential",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "g1xQWWwDyPg",
    geo: [Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.PER_CHANGE_HOME_VALUE_JUNE,
    label: "% Change from June 2022",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "0tSPY8G8HiA",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.PERCENT_CRASH_GREAT_RECESSION,
    label: "% Crash from 2007-12",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "RIPW_epX4Yk",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.MOM_HOME_VALUE_GROWTH,
    label: "Home Value Growth (MoM)",
    category: exploratoryGroupMap.homePriceAndAffordability,
    ytVideoCode: "ADBMYaELPMw",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.TOTAL_ACTIVE_INVENTORY,
    label: "For Sale Inventory",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "WM70puz4heg",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.YOY_INVENTORY_CHANGE_PERCENTAGE,
    label: "Sale Inventory Growth (YoY)",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "FMiyQJSxfgY",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.FOR_SALE_INVENTORY_V_AVG,
    label: "Inventory Surplus/Deficit",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "VtaattqrPQM",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.SHARE_OF_LISTINGS_WITH_PRICE_CUT,
    label: "Price Cut %",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "od4s3RY9tzs",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.DAYS_ON_MARKET,
    label: "Days on Market",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "p-WlEbcHmFY",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.YOY_DAYS_ON_MARKET,
    label: "Days on Market Growth (YoY)",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "jUl6tRvAmEc",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.INVENTORY_PERCENTAGE_OF_HOUSES,
    label: "Inventory as % of Houses",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "5j4PFwZSMjg",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.MEDIAN_LISTING_PRICE,
    label: "Median Listing Price",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "-hclKJvs9qk",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.MEDIAN_LISTING_PRICE_YOY,
    label: "Median Listing Price (YoY)",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "XhCZ8v-d4Xg",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.NEW_LISTING_COUNT,
    label: "New Listing Count",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "QaW7qtEG_rI",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.NEW_LISTING_COUNT_YOY,
    label: "New Listing Count (YoY)",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "UqpBBUAujws",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.MOM_ACTIVE_INVENTORY_CHANGE_PERCENTAGE,
    label: "Sale Inventory Growth (MoM)",
    category: exploratoryGroupMap.marketTrend,
    ytVideoCode: "ZRijjeorPbM",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.POPULATION,
    label: "Population",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "CEAAk82J2mk",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.POPULATION_GROWTH_RATE,
    label: "Population Growth",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "U3pSqLrXxOE",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.MEDIAN_HOUSEHOLD_INCOME,
    label: "Median Household Income",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "LY9FM0K0gcU",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.MEDIAN_INCOME_GROWTH_RATE,
    label: "Income Growth",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "3_vVAsqcyIs",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.REMOTE_WORK_PERCENTAGE,
    label: "Remote Work %",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.COLLEGE_DEGREE_RATE,
    label: "College Degree Rate",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.UNEMPLOYMENT_RATE,
    label: "Unemployment Rate",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.HOME_OWNERSHIP_RATE,
    label: "Homeownership Rate",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "H2y-XhRIcRU",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.MORTGAGED_HOME_PERCENTAGE,
    label: "Mortgaged Home %",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "1-8ER2MxQWI",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.MEDIAN_AGE,
    label: "Median Age",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "c8gcAvtJJrs",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.POVERTY_PERCENTAGE,
    label: "Poverty Rate",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "PsQHUVsz-mo",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.HOUSING_UNITS,
    label: "Housing Units",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "RnTufPDHfRc",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.HOUSING_UNIT_GROWTH,
    label: "Housing Unit Growth Rate",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "iXBmFyztFQU",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.TRAILING_TWELVE_MONTHS_BUILDING_PERMITS,
    label: "Building Permits",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "FtSSd5yMsY0",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY],
    type: ExploratoryType.EXPLORATORY,
  },
  // {
  //   value: Datapoints.TRAILING_TWELVE_MONTHS_SINGLE_PERMITS,
  //   label: "Single Family Permits",
  //   category: exploratoryGroupMap.demographic,
  //   ytVideoCode: "-ikw24EVU5k",
  //   geo: [Geo.STATE, Geo.METRO, Geo.COUNTY],
  //   type: ExploratoryType.EXPLORATORY,
  // },
  // {
  //   value: Datapoints.TRAILING_TWELVE_MONTHS_MULTI_PERMITS,
  //   label: "Multi Family Permits",
  //   category: exploratoryGroupMap.demographic,
  //   ytVideoCode: "46shAXBhe0E",
  //   geo: [Geo.STATE, Geo.METRO, Geo.COUNTY],
  //   type: ExploratoryType.EXPLORATORY,
  // },
  // {
  //   value: Datapoints.BUILDING_PERMIT_PERCENTAGE,
  //   label: "Building Permit %",
  //   category: exploratoryGroupMap.demographic,
  //   ytVideoCode: "",
  //   geo: [Geo.STATE, Geo.METRO, Geo.COUNTY],
  //   type: ExploratoryType.EXPLORATORY,
  // },
  {
    value: Datapoints.RENT_FOR_APARTMENTS,
    label: "Rental Rate",
    category: exploratoryGroupMap.investorMetrics,
    ytVideoCode: "-wK6Uaj_NHs",
    geo: [Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.RENT_FOR_HOUSES,
    label: "Rent For Houses",
    category: exploratoryGroupMap.investorMetrics,
    ytVideoCode: "RZBflvBazVI",
    geo: [Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.CAP_RATE,
    label: "Cap Rate",
    category: exploratoryGroupMap.investorMetrics,
    ytVideoCode: "ga8P77-Lqfk",
    geo: [Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.VACANCY_RATE,
    label: "Vacancy Rate",
    category: exploratoryGroupMap.investorMetrics,
    ytVideoCode: "WZfpW8K2fwY",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.HOME_VALUE_TO_ANNUAL_RENT_RATIO,
    label: "Home Value to Rent Ratio",
    category: exploratoryGroupMap.investorMetrics,
    ytVideoCode: "",
    geo: [Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.RENT_PERCENTAGE_OF_INCOME,
    label: "Rent as % of Income",
    category: exploratoryGroupMap.investorMetrics,
    ytVideoCode: "KQZjgRFM4Lw",
    geo: [Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.ABSENTEE_OWNER_PERCENTAGE,
    label: "Shadow Inventory %",
    category: exploratoryGroupMap.investorMetrics,
    ytVideoCode: "5EaFlh78ldo",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.TOTAL_MIGRATION,
    label: "Migration Total",
    category: exploratoryGroupMap.investorMetrics,
    ytVideoCode: "eE9J9RABR5w",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.MIGRATION_PERCENTAGE,
    label: "Migration % of Population",
    category: exploratoryGroupMap.investorMetrics,
    ytVideoCode: "A-gX1Qce_Xc",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.RENT_GROWTH_YOY,
    label: "Rent Growth (YoY)",
    category: exploratoryGroupMap.investorMetrics,
    ytVideoCode: "BdCmhAGHuNU",
    geo: [Geo.METRO],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.VOTING_REPUBLICAN_PERCENTAGE,
    label: "Vote Republican %",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY],
    type: ExploratoryType.EXPLORATORY,
  },
  {
    value: Datapoints.VOTING_DEMOCRAT_PERCENTAGE,
    label: "Vote Democrat %",
    category: exploratoryGroupMap.demographic,
    ytVideoCode: "",
    geo: [Geo.STATE, Geo.METRO, Geo.COUNTY],
    type: ExploratoryType.EXPLORATORY,
  },
  ...scoreConfigs.reduce((acc, cur) => {
    acc.push({
      value: cur.code,
      label: cur.label,
      category: exploratoryGroupMap.scores,
      ytVideoCode: cur.ytVideoCode,
      geo: cur.geo,
      isNew: cur.isNew,
      type: ExploratoryType.SCORE,
      isComingSoon: cur.isComingSoon,
      parentScores: cur.parent,
    });
    return acc;
  }, [] as AllExploratoryOption[]),
];

export const popularExploratories = [
  ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE,
  Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE,
  Datapoints.OVER_UNDER_VALUED_PERCENTAGE,
  Datapoints.CAP_RATE,
  Datapoints.POPULATION_GROWTH_RATE,
  Datapoints.SHARE_OF_LISTINGS_WITH_PRICE_CUT,
];

export const popularExploratoryValues = [
  "home_price_momentum_score",
  "over_under_valued_percentage",
  "cap_rate",
  "population_growth_rate",
  "share_of_listings_with_price_cut",
  "home_value_five_year_growth_rate",
];

export const freeExploratories: CombinedDatapoints[] = [
  Datapoints.HOME_VALUE,
  Datapoints.HOME_VALUE_GROWTH_RATE,
  Datapoints.YOY_INVENTORY_CHANGE_PERCENTAGE,
  Datapoints.POPULATION,
  Datapoints.MEDIAN_HOUSEHOLD_INCOME,
  Datapoints.TOTAL_ACTIVE_INVENTORY,
  Datapoints.RENT_FOR_APARTMENTS,
  Datapoints.RENT_FOR_HOUSES,
];
