import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { useTheme } from "@emotion/react";
import { Plan } from "backend/utils/plan";
import { getStripePromise } from "lib/helpers/getStripePromise";
import CheckoutFormStripe from "./CheckoutFormStripe";

const stripePromise = getStripePromise();

export type StripeCheckoutProps = {
  amount: number;
  priceId: string;
  selectedPlan: Plan;
  productId: string;
  checkingOut: boolean | undefined;
  setCheckingOut: any;
  userName?: any;
};

export const StripeElementsNewDesign = ({
  amount,
  priceId,
  selectedPlan,
  productId,
  checkingOut,
  setCheckingOut,
  userName,
}: StripeCheckoutProps) => {
  const theme = useTheme();

  const options = {
    mode: "subscription",
    amount: 1099,
    currency: "usd",
    paymentMethodTypes: ["card"],
    fonts: [
      {
        family: "Lato",
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700&display=swap",
      },
    ],
    // Fully customizable with appearance API.
    appearance: {
      variables: {
        fontSizeSm: "16px",
        fontFamily: "Lato",
      },
      rules: {
        ".Input": {
          padding: "8px 11px",
          borderRadius: `3px`,
          fontSize: `${theme.fontSizes.default}px`,
          outline: "none",
          border: `1px solid ${theme.colors.inputBorder}`,
        },
        ".Input:focus": {
          outline: "none",
          border: `1px solid ${theme.colors.inputBorder}`,
          boxShadow: "none",
        },
        ".Label": {
          fontWeight: "700",
          marginBottom: "10px",
        },
      },
    },
  } as StripeElementsOptions;

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutFormStripe
        userName={userName}
        checkingOut={checkingOut}
        setCheckingOut={setCheckingOut}
        productId={productId}
        priceId={priceId}
        selectedPlan={selectedPlan}
        amount={amount}
      />
    </Elements>
  );
};
