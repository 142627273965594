export const ZipToCity = [
  {
    postal_code: "71865",
    zip_name: "wilton, ar",
  },
  {
    postal_code: "83806",
    zip_name: "bovill, id",
  },
  {
    postal_code: "86434",
    zip_name: "peach springs, az",
  },
  {
    postal_code: "39747",
    zip_name: "kilmichael, ms",
  },
  {
    postal_code: "82421",
    zip_name: "deaver, wy",
  },
  {
    postal_code: "72460",
    zip_name: "ravenden springs, ar",
  },
  {
    postal_code: "74358",
    zip_name: "north miami, ok",
  },
  {
    postal_code: "80544",
    zip_name: "niwot, co",
  },
  {
    postal_code: "43407",
    zip_name: "burgoon, oh",
  },
  {
    postal_code: "82943",
    zip_name: "reliance, wy",
  },
  {
    postal_code: "76873",
    zip_name: "rockwood, tx",
  },
  {
    postal_code: "45716",
    zip_name: "buchtel, oh",
  },
  {
    postal_code: "53501",
    zip_name: "afton, wi",
  },
  {
    postal_code: "56162",
    zip_name: "ormsby, mn",
  },
  {
    postal_code: "88422",
    zip_name: "gladstone, nm",
  },
  {
    postal_code: "41763",
    zip_name: "slemp, ky",
  },
  {
    postal_code: "14604",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "79846",
    zip_name: "redford, tx",
  },
  {
    postal_code: "58046",
    zip_name: "hope, nd",
  },
  {
    postal_code: "74845",
    zip_name: "hanna, ok",
  },
  {
    postal_code: "78548",
    zip_name: "grulla, tx",
  },
  {
    postal_code: "82336",
    zip_name: "wamsutter, wy",
  },
  {
    postal_code: "58650",
    zip_name: "regent, nd",
  },
  {
    postal_code: "57788",
    zip_name: "vale, sd",
  },
  {
    postal_code: "93608",
    zip_name: "cantua creek, ca",
  },
  {
    postal_code: "70736",
    zip_name: "glynn, la",
  },
  {
    postal_code: "40763",
    zip_name: "siler, ky",
  },
  {
    postal_code: "70393",
    zip_name: "plattenville, la",
  },
  {
    postal_code: "57620",
    zip_name: "bison, sd",
  },
  {
    postal_code: "13806",
    zip_name: "meridale, ny",
  },
  {
    postal_code: "39745",
    zip_name: "french camp, ms",
  },
  {
    postal_code: "55029",
    zip_name: "grandy, mn",
  },
  {
    postal_code: "24607",
    zip_name: "breaks, va",
  },
  {
    postal_code: "22811",
    zip_name: "bergton, va",
  },
  {
    postal_code: "84338",
    zip_name: "trenton, ut",
  },
  {
    postal_code: "29208",
    zip_name: "columbia, sc",
  },
  {
    postal_code: "5666",
    zip_name: "north montpelier, vt",
  },
  {
    postal_code: "57420",
    zip_name: "akaska, sd",
  },
  {
    postal_code: "63837",
    zip_name: "clarkton, mo",
  },
  {
    postal_code: "58362",
    zip_name: "penn, nd",
  },
  {
    postal_code: "40350",
    zip_name: "moorefield, ky",
  },
  {
    postal_code: "64625",
    zip_name: "breckenridge, mo",
  },
  {
    postal_code: "67584",
    zip_name: "utica, ks",
  },
  {
    postal_code: "73668",
    zip_name: "texola, ok",
  },
  {
    postal_code: "61848",
    zip_name: "henning, il",
  },
  {
    postal_code: "24920",
    zip_name: "bartow, wv",
  },
  {
    postal_code: "62538",
    zip_name: "harvel, il",
  },
  {
    postal_code: "74422",
    zip_name: "boynton, ok",
  },
  {
    postal_code: "61941",
    zip_name: "murdock, il",
  },
  {
    postal_code: "75493",
    zip_name: "winfield, tx",
  },
  {
    postal_code: "15413",
    zip_name: "allison, pa",
  },
  {
    postal_code: "57243",
    zip_name: "henry, sd",
  },
  {
    postal_code: "18035",
    zip_name: "cherryville, pa",
  },
  {
    postal_code: "84533",
    zip_name: "lake powell, ut",
  },
  {
    postal_code: "5447",
    zip_name: "east berkshire, vt",
  },
  {
    postal_code: "83526",
    zip_name: "ferdinand, id",
  },
  {
    postal_code: "87941",
    zip_name: "salem, nm",
  },
  {
    postal_code: "35544",
    zip_name: "beaverton, al",
  },
  {
    postal_code: "56680",
    zip_name: "spring lake, mn",
  },
  {
    postal_code: "89046",
    zip_name: "searchlight, nv",
  },
  {
    postal_code: "56759",
    zip_name: "strathcona, mn",
  },
  {
    postal_code: "73857",
    zip_name: "sharon, ok",
  },
  {
    postal_code: "72679",
    zip_name: "tilly, ar",
  },
  {
    postal_code: "72392",
    zip_name: "wheatley, ar",
  },
  {
    postal_code: "42275",
    zip_name: "roundhill, ky",
  },
  {
    postal_code: "52749",
    zip_name: "fruitland, ia",
  },
  {
    postal_code: "57773",
    zip_name: "pringle, sd",
  },
  {
    postal_code: "15007",
    zip_name: "bakerstown, pa",
  },
  {
    postal_code: "61477",
    zip_name: "smithfield, il",
  },
  {
    postal_code: "51063",
    zip_name: "whiting, ia",
  },
  {
    postal_code: "24977",
    zip_name: "smoot, wv",
  },
  {
    postal_code: "96086",
    zip_name: "seiad valley, ca",
  },
  {
    postal_code: "26590",
    zip_name: "wana, wv",
  },
  {
    postal_code: "67642",
    zip_name: "hill city, ks",
  },
  {
    postal_code: "83841",
    zip_name: "laclede, id",
  },
  {
    postal_code: "63825",
    zip_name: "bloomfield, mo",
  },
  {
    postal_code: "67525",
    zip_name: "claflin, ks",
  },
  {
    postal_code: "24747",
    zip_name: "rock, wv",
  },
  {
    postal_code: "66866",
    zip_name: "peabody, ks",
  },
  {
    postal_code: "92309",
    zip_name: "baker, ca",
  },
  {
    postal_code: "31001",
    zip_name: "abbeville, ga",
  },
  {
    postal_code: "48865",
    zip_name: "orleans, mi",
  },
  {
    postal_code: "73543",
    zip_name: "geronimo, ok",
  },
  {
    postal_code: "26261",
    zip_name: "richwood, wv",
  },
  {
    postal_code: "31830",
    zip_name: "warm springs, ga",
  },
  {
    postal_code: "46799",
    zip_name: "zanesville, in",
  },
  {
    postal_code: "43350",
    zip_name: "sparta, oh",
  },
  {
    postal_code: "4442",
    zip_name: "greenville junction, me",
  },
  {
    postal_code: "51444",
    zip_name: "halbur, ia",
  },
  {
    postal_code: "98284",
    zip_name: "sedro woolley, wa",
  },
  {
    postal_code: "72470",
    zip_name: "success, ar",
  },
  {
    postal_code: "47345",
    zip_name: "greens fork, in",
  },
  {
    postal_code: "74062",
    zip_name: "ripley, ok",
  },
  {
    postal_code: "72080",
    zip_name: "jerusalem, ar",
  },
  {
    postal_code: "12763",
    zip_name: "mountain dale, ny",
  },
  {
    postal_code: "47524",
    zip_name: "decker, in",
  },
  {
    postal_code: "72843",
    zip_name: "hector, ar",
  },
  {
    postal_code: "29658",
    zip_name: "long creek, sc",
  },
  {
    postal_code: "87936",
    zip_name: "garfield, nm",
  },
  {
    postal_code: "26374",
    zip_name: "independence, wv",
  },
  {
    postal_code: "54212",
    zip_name: "fish creek, wi",
  },
  {
    postal_code: "79734",
    zip_name: "fort davis, tx",
  },
  {
    postal_code: "24256",
    zip_name: "haysi, va",
  },
  {
    postal_code: "77624",
    zip_name: "hillister, tx",
  },
  {
    postal_code: "83283",
    zip_name: "thatcher, id",
  },
  {
    postal_code: "14885",
    zip_name: "troupsburg, ny",
  },
  {
    postal_code: "57238",
    zip_name: "goodwin, sd",
  },
  {
    postal_code: "70355",
    zip_name: "gheens, la",
  },
  {
    postal_code: "63440",
    zip_name: "ewing, mo",
  },
  {
    postal_code: "58033",
    zip_name: "fort ransom, nd",
  },
  {
    postal_code: "36720",
    zip_name: "alberta, al",
  },
  {
    postal_code: "42349",
    zip_name: "horse branch, ky",
  },
  {
    postal_code: "63538",
    zip_name: "elmer, mo",
  },
  {
    postal_code: "68726",
    zip_name: "clearwater, ne",
  },
  {
    postal_code: "34739",
    zip_name: "kenansville, fl",
  },
  {
    postal_code: "66754",
    zip_name: "mapleton, ks",
  },
  {
    postal_code: "95676",
    zip_name: "robbins, ca",
  },
  {
    postal_code: "97050",
    zip_name: "rufus, or",
  },
  {
    postal_code: "30807",
    zip_name: "camak, ga",
  },
  {
    postal_code: "50582",
    zip_name: "rutland, ia",
  },
  {
    postal_code: "73760",
    zip_name: "meno, ok",
  },
  {
    postal_code: "39363",
    zip_name: "stonewall, ms",
  },
  {
    postal_code: "71720",
    zip_name: "bearden, ar",
  },
  {
    postal_code: "99352",
    zip_name: "richland, wa",
  },
  {
    postal_code: "95721",
    zip_name: "echo lake, ca",
  },
  {
    postal_code: "93512",
    zip_name: "benton, ca",
  },
  {
    postal_code: "35452",
    zip_name: "coker, al",
  },
  {
    postal_code: "51016",
    zip_name: "correctionville, ia",
  },
  {
    postal_code: "56446",
    zip_name: "eagle bend, mn",
  },
  {
    postal_code: "35621",
    zip_name: "eva, al",
  },
  {
    postal_code: "32464",
    zip_name: "westville, fl",
  },
  {
    postal_code: "62334",
    zip_name: "elvaston, il",
  },
  {
    postal_code: "49752",
    zip_name: "kinross, mi",
  },
  {
    postal_code: "14207",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "51106",
    zip_name: "sioux city, ia",
  },
  {
    postal_code: "52536",
    zip_name: "blakesburg, ia",
  },
  {
    postal_code: "67360",
    zip_name: "peru, ks",
  },
  {
    postal_code: "31041",
    zip_name: "ideal, ga",
  },
  {
    postal_code: "12789",
    zip_name: "woodridge, ny",
  },
  {
    postal_code: "54169",
    zip_name: "sherwood, wi",
  },
  {
    postal_code: "42028",
    zip_name: "burna, ky",
  },
  {
    postal_code: "76871",
    zip_name: "richland springs, tx",
  },
  {
    postal_code: "36607",
    zip_name: "mobile, al",
  },
  {
    postal_code: "29206",
    zip_name: "columbia, sc",
  },
  {
    postal_code: "62613",
    zip_name: "athens, il",
  },
  {
    postal_code: "59601",
    zip_name: "helena, mt",
  },
  {
    postal_code: "75574",
    zip_name: "simms, tx",
  },
  {
    postal_code: "47454",
    zip_name: "paoli, in",
  },
  {
    postal_code: "38957",
    zip_name: "sumner, ms",
  },
  {
    postal_code: "14802",
    zip_name: "alfred, ny",
  },
  {
    postal_code: "64668",
    zip_name: "norborne, mo",
  },
  {
    postal_code: "14209",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "30516",
    zip_name: "bowersville, ga",
  },
  {
    postal_code: "47929",
    zip_name: "chalmers, in",
  },
  {
    postal_code: "61325",
    zip_name: "grand ridge, il",
  },
  {
    postal_code: "47125",
    zip_name: "hardinsburg, in",
  },
  {
    postal_code: "26629",
    zip_name: "little birch, wv",
  },
  {
    postal_code: "63549",
    zip_name: "la plata, mo",
  },
  {
    postal_code: "68346",
    zip_name: "dunbar, ne",
  },
  {
    postal_code: "54537",
    zip_name: "kennan, wi",
  },
  {
    postal_code: "59480",
    zip_name: "stockett, mt",
  },
  {
    postal_code: "28617",
    zip_name: "crumpler, nc",
  },
  {
    postal_code: "45772",
    zip_name: "reedsville, oh",
  },
  {
    postal_code: "29446",
    zip_name: "green pond, sc",
  },
  {
    postal_code: "5747",
    zip_name: "granville, vt",
  },
  {
    postal_code: "39825",
    zip_name: "brinson, ga",
  },
  {
    postal_code: "31749",
    zip_name: "enigma, ga",
  },
  {
    postal_code: "38661",
    zip_name: "red banks, ms",
  },
  {
    postal_code: "98391",
    zip_name: "bonney lake, wa",
  },
  {
    postal_code: "50833",
    zip_name: "bedford, ia",
  },
  {
    postal_code: "26263",
    zip_name: "dryfork, wv",
  },
  {
    postal_code: "14030",
    zip_name: "chaffee, ny",
  },
  {
    postal_code: "73840",
    zip_name: "fargo, ok",
  },
  {
    postal_code: "12435",
    zip_name: "greenfield park, ny",
  },
  {
    postal_code: "72386",
    zip_name: "tyronza, ar",
  },
  {
    postal_code: "28644",
    zip_name: "laurel springs, nc",
  },
  {
    postal_code: "14009",
    zip_name: "arcade, ny",
  },
  {
    postal_code: "51446",
    zip_name: "irwin, ia",
  },
  {
    postal_code: "56142",
    zip_name: "ivanhoe, mn",
  },
  {
    postal_code: "97137",
    zip_name: "saint paul, or",
  },
  {
    postal_code: "26369",
    zip_name: "hepzibah, wv",
  },
  {
    postal_code: "54657",
    zip_name: "steuben, wi",
  },
  {
    postal_code: "58271",
    zip_name: "pembina, nd",
  },
  {
    postal_code: "65262",
    zip_name: "kingdom city, mo",
  },
  {
    postal_code: "26562",
    zip_name: "burton, wv",
  },
  {
    postal_code: "47840",
    zip_name: "centerpoint, in",
  },
  {
    postal_code: "36567",
    zip_name: "robertsdale, al",
  },
  {
    postal_code: "39756",
    zip_name: "prairie, ms",
  },
  {
    postal_code: "66439",
    zip_name: "horton, ks",
  },
  {
    postal_code: "58856",
    zip_name: "zahl, nd",
  },
  {
    postal_code: "24018",
    zip_name: "roanoke, va",
  },
  {
    postal_code: "66073",
    zip_name: "perry, ks",
  },
  {
    postal_code: "50174",
    zip_name: "murray, ia",
  },
  {
    postal_code: "18635",
    zip_name: "nescopeck, pa",
  },
  {
    postal_code: "7022",
    zip_name: "fairview, nj",
  },
  {
    postal_code: "84734",
    zip_name: "hanksville, ut",
  },
  {
    postal_code: "24603",
    zip_name: "big rock, va",
  },
  {
    postal_code: "56280",
    zip_name: "porter, mn",
  },
  {
    postal_code: "54658",
    zip_name: "stoddard, wi",
  },
  {
    postal_code: "87062",
    zip_name: "veguita, nm",
  },
  {
    postal_code: "23032",
    zip_name: "church view, va",
  },
  {
    postal_code: "73096",
    zip_name: "weatherford, ok",
  },
  {
    postal_code: "98812",
    zip_name: "brewster, wa",
  },
  {
    postal_code: "99688",
    zip_name: "willow, ak",
  },
  {
    postal_code: "24067",
    zip_name: "callaway, va",
  },
  {
    postal_code: "98248",
    zip_name: "ferndale, wa",
  },
  {
    postal_code: "74729",
    zip_name: "caddo, ok",
  },
  {
    postal_code: "57239",
    zip_name: "grenville, sd",
  },
  {
    postal_code: "97020",
    zip_name: "donald, or",
  },
  {
    postal_code: "48208",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "51649",
    zip_name: "randolph, ia",
  },
  {
    postal_code: "72935",
    zip_name: "dyer, ar",
  },
  {
    postal_code: "57077",
    zip_name: "worthing, sd",
  },
  {
    postal_code: "72153",
    zip_name: "shirley, ar",
  },
  {
    postal_code: "29364",
    zip_name: "lockhart, sc",
  },
  {
    postal_code: "4979",
    zip_name: "solon, me",
  },
  {
    postal_code: "72074",
    zip_name: "hunter, ar",
  },
  {
    postal_code: "5252",
    zip_name: "east arlington, vt",
  },
  {
    postal_code: "7711",
    zip_name: "allenhurst, nj",
  },
  {
    postal_code: "71454",
    zip_name: "montgomery, la",
  },
  {
    postal_code: "95441",
    zip_name: "geyserville, ca",
  },
  {
    postal_code: "36353",
    zip_name: "newville, al",
  },
  {
    postal_code: "87517",
    zip_name: "carson, nm",
  },
  {
    postal_code: "72553",
    zip_name: "magness, ar",
  },
  {
    postal_code: "25444",
    zip_name: "slanesville, wv",
  },
  {
    postal_code: "98280",
    zip_name: "orcas, wa",
  },
  {
    postal_code: "72128",
    zip_name: "poyen, ar",
  },
  {
    postal_code: "88311",
    zip_name: "alamogordo, nm",
  },
  {
    postal_code: "83336",
    zip_name: "heyburn, id",
  },
  {
    postal_code: "95454",
    zip_name: "laytonville, ca",
  },
  {
    postal_code: "7977",
    zip_name: "peapack, nj",
  },
  {
    postal_code: "83714",
    zip_name: "garden city, id",
  },
  {
    postal_code: "4923",
    zip_name: "cambridge, me",
  },
  {
    postal_code: "23839",
    zip_name: "dendron, va",
  },
  {
    postal_code: "58483",
    zip_name: "streeter, nd",
  },
  {
    postal_code: "81649",
    zip_name: "red cliff, co",
  },
  {
    postal_code: "12427",
    zip_name: "elka park, ny",
  },
  {
    postal_code: "78582",
    zip_name: "rio grande city, tx",
  },
  {
    postal_code: "37191",
    zip_name: "woodlawn, tn",
  },
  {
    postal_code: "79001",
    zip_name: "adrian, tx",
  },
  {
    postal_code: "67025",
    zip_name: "cheney, ks",
  },
  {
    postal_code: "95968",
    zip_name: "palermo, ca",
  },
  {
    postal_code: "26137",
    zip_name: "big springs, wv",
  },
  {
    postal_code: "73664",
    zip_name: "sentinel, ok",
  },
  {
    postal_code: "58529",
    zip_name: "carson, nd",
  },
  {
    postal_code: "95717",
    zip_name: "gold run, ca",
  },
  {
    postal_code: "65564",
    zip_name: "solo, mo",
  },
  {
    postal_code: "98830",
    zip_name: "mansfield, wa",
  },
  {
    postal_code: "62356",
    zip_name: "new canton, il",
  },
  {
    postal_code: "15087",
    zip_name: "webster, pa",
  },
  {
    postal_code: "16616",
    zip_name: "beccaria, pa",
  },
  {
    postal_code: "67737",
    zip_name: "grainfield, ks",
  },
  {
    postal_code: "37680",
    zip_name: "laurel bloomery, tn",
  },
  {
    postal_code: "43782",
    zip_name: "shawnee, oh",
  },
  {
    postal_code: "71838",
    zip_name: "fulton, ar",
  },
  {
    postal_code: "72955",
    zip_name: "uniontown, ar",
  },
  {
    postal_code: "41855",
    zip_name: "thornton, ky",
  },
  {
    postal_code: "26273",
    zip_name: "huttonsville, wv",
  },
  {
    postal_code: "72729",
    zip_name: "evansville, ar",
  },
  {
    postal_code: "99729",
    zip_name: "cantwell, ak",
  },
  {
    postal_code: "79718",
    zip_name: "balmorhea, tx",
  },
  {
    postal_code: "98643",
    zip_name: "rosburg, wa",
  },
  {
    postal_code: "93563",
    zip_name: "valyermo, ca",
  },
  {
    postal_code: "15764",
    zip_name: "oliveburg, pa",
  },
  {
    postal_code: "68377",
    zip_name: "jansen, ne",
  },
  {
    postal_code: "68442",
    zip_name: "stella, ne",
  },
  {
    postal_code: "63827",
    zip_name: "bragg city, mo",
  },
  {
    postal_code: "17221",
    zip_name: "fannettsburg, pa",
  },
  {
    postal_code: "58428",
    zip_name: "dawson, nd",
  },
  {
    postal_code: "88353",
    zip_name: "vaughn, nm",
  },
  {
    postal_code: "95652",
    zip_name: "mcclellan, ca",
  },
  {
    postal_code: "72617",
    zip_name: "big flat, ar",
  },
  {
    postal_code: "56754",
    zip_name: "saint hilaire, mn",
  },
  {
    postal_code: "75556",
    zip_name: "bloomburg, tx",
  },
  {
    postal_code: "55785",
    zip_name: "swatara, mn",
  },
  {
    postal_code: "41667",
    zip_name: "weeksbury, ky",
  },
  {
    postal_code: "57536",
    zip_name: "harrold, sd",
  },
  {
    postal_code: "36751",
    zip_name: "lower peach tree, al",
  },
  {
    postal_code: "51248",
    zip_name: "sanborn, ia",
  },
  {
    postal_code: "25193",
    zip_name: "sylvester, wv",
  },
  {
    postal_code: "14549",
    zip_name: "silver lake, ny",
  },
  {
    postal_code: "68820",
    zip_name: "boelus, ne",
  },
  {
    postal_code: "72571",
    zip_name: "rosie, ar",
  },
  {
    postal_code: "71043",
    zip_name: "hosston, la",
  },
  {
    postal_code: "13834",
    zip_name: "portlandville, ny",
  },
  {
    postal_code: "16644",
    zip_name: "glasgow, pa",
  },
  {
    postal_code: "64655",
    zip_name: "lucerne, mo",
  },
  {
    postal_code: "50119",
    zip_name: "harvey, ia",
  },
  {
    postal_code: "76848",
    zip_name: "hext, tx",
  },
  {
    postal_code: "39633",
    zip_name: "crosby, ms",
  },
  {
    postal_code: "68861",
    zip_name: "odessa, ne",
  },
  {
    postal_code: "97837",
    zip_name: "hereford, or",
  },
  {
    postal_code: "78830",
    zip_name: "big wells, tx",
  },
  {
    postal_code: "67028",
    zip_name: "coats, ks",
  },
  {
    postal_code: "79009",
    zip_name: "bovina, tx",
  },
  {
    postal_code: "14842",
    zip_name: "himrod, ny",
  },
  {
    postal_code: "47866",
    zip_name: "pimento, in",
  },
  {
    postal_code: "72456",
    zip_name: "pollard, ar",
  },
  {
    postal_code: "33521",
    zip_name: "coleman, fl",
  },
  {
    postal_code: "68813",
    zip_name: "anselmo, ne",
  },
  {
    postal_code: "73073",
    zip_name: "orlando, ok",
  },
  {
    postal_code: "66952",
    zip_name: "lebanon, ks",
  },
  {
    postal_code: "72170",
    zip_name: "ulm, ar",
  },
  {
    postal_code: "78008",
    zip_name: "campbellton, tx",
  },
  {
    postal_code: "47449",
    zip_name: "newberry, in",
  },
  {
    postal_code: "57067",
    zip_name: "utica, sd",
  },
  {
    postal_code: "73729",
    zip_name: "cleo springs, ok",
  },
  {
    postal_code: "98572",
    zip_name: "pe ell, wa",
  },
  {
    postal_code: "35221",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "15533",
    zip_name: "breezewood, pa",
  },
  {
    postal_code: "53177",
    zip_name: "sturtevant, wi",
  },
  {
    postal_code: "72827",
    zip_name: "bluffton, ar",
  },
  {
    postal_code: "40870",
    zip_name: "totz, ky",
  },
  {
    postal_code: "24954",
    zip_name: "marlinton, wv",
  },
  {
    postal_code: "26075",
    zip_name: "windsor heights, wv",
  },
  {
    postal_code: "64431",
    zip_name: "clearmont, mo",
  },
  {
    postal_code: "67052",
    zip_name: "goddard, ks",
  },
  {
    postal_code: "49452",
    zip_name: "rothbury, mi",
  },
  {
    postal_code: "70353",
    zip_name: "dulac, la",
  },
  {
    postal_code: "41132",
    zip_name: "denton, ky",
  },
  {
    postal_code: "48818",
    zip_name: "crystal, mi",
  },
  {
    postal_code: "42027",
    zip_name: "boaz, ky",
  },
  {
    postal_code: "49428",
    zip_name: "jenison, mi",
  },
  {
    postal_code: "87415",
    zip_name: "flora vista, nm",
  },
  {
    postal_code: "50448",
    zip_name: "kensett, ia",
  },
  {
    postal_code: "36867",
    zip_name: "phenix city, al",
  },
  {
    postal_code: "25854",
    zip_name: "hico, wv",
  },
  {
    postal_code: "62316",
    zip_name: "bowen, il",
  },
  {
    postal_code: "59254",
    zip_name: "plentywood, mt",
  },
  {
    postal_code: "58776",
    zip_name: "ross, nd",
  },
  {
    postal_code: "64624",
    zip_name: "braymer, mo",
  },
  {
    postal_code: "18072",
    zip_name: "pen argyl, pa",
  },
  {
    postal_code: "76624",
    zip_name: "axtell, tx",
  },
  {
    postal_code: "28619",
    zip_name: "drexel, nc",
  },
  {
    postal_code: "78503",
    zip_name: "mcallen, tx",
  },
  {
    postal_code: "51240",
    zip_name: "inwood, ia",
  },
  {
    postal_code: "73052",
    zip_name: "lindsay, ok",
  },
  {
    postal_code: "68722",
    zip_name: "butte, ne",
  },
  {
    postal_code: "72079",
    zip_name: "jefferson, ar",
  },
  {
    postal_code: "85540",
    zip_name: "morenci, az",
  },
  {
    postal_code: "25607",
    zip_name: "amherstdale, wv",
  },
  {
    postal_code: "62850",
    zip_name: "johnsonville, il",
  },
  {
    postal_code: "67072",
    zip_name: "latham, ks",
  },
  {
    postal_code: "84758",
    zip_name: "orderville, ut",
  },
  {
    postal_code: "58464",
    zip_name: "mchenry, nd",
  },
  {
    postal_code: "64857",
    zip_name: "purcell, mo",
  },
  {
    postal_code: "51651",
    zip_name: "shambaugh, ia",
  },
  {
    postal_code: "67621",
    zip_name: "agra, ks",
  },
  {
    postal_code: "88025",
    zip_name: "buckhorn, nm",
  },
  {
    postal_code: "99780",
    zip_name: "tok, ak",
  },
  {
    postal_code: "43932",
    zip_name: "irondale, oh",
  },
  {
    postal_code: "4646",
    zip_name: "islesford, me",
  },
  {
    postal_code: "98631",
    zip_name: "long beach, wa",
  },
  {
    postal_code: "5035",
    zip_name: "bridgewater corners, vt",
  },
  {
    postal_code: "82637",
    zip_name: "glenrock, wy",
  },
  {
    postal_code: "37030",
    zip_name: "carthage, tn",
  },
  {
    postal_code: "13469",
    zip_name: "stittville, ny",
  },
  {
    postal_code: "95468",
    zip_name: "point arena, ca",
  },
  {
    postal_code: "89020",
    zip_name: "amargosa valley, nv",
  },
  {
    postal_code: "43463",
    zip_name: "stony ridge, oh",
  },
  {
    postal_code: "82214",
    zip_name: "guernsey, wy",
  },
  {
    postal_code: "62954",
    zip_name: "junction, il",
  },
  {
    postal_code: "24847",
    zip_name: "itmann, wv",
  },
  {
    postal_code: "64028",
    zip_name: "farley, mo",
  },
  {
    postal_code: "65279",
    zip_name: "rocheport, mo",
  },
  {
    postal_code: "47551",
    zip_name: "leopold, in",
  },
  {
    postal_code: "32504",
    zip_name: "pensacola, fl",
  },
  {
    postal_code: "59640",
    zip_name: "marysville, mt",
  },
  {
    postal_code: "46528",
    zip_name: "goshen, in",
  },
  {
    postal_code: "31094",
    zip_name: "warthen, ga",
  },
  {
    postal_code: "71227",
    zip_name: "choudrant, la",
  },
  {
    postal_code: "74741",
    zip_name: "hendrix, ok",
  },
  {
    postal_code: "15430",
    zip_name: "dickerson run, pa",
  },
  {
    postal_code: "73105",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "53029",
    zip_name: "hartland, wi",
  },
  {
    postal_code: "95462",
    zip_name: "monte rio, ca",
  },
  {
    postal_code: "55703",
    zip_name: "angora, mn",
  },
  {
    postal_code: "7981",
    zip_name: "whippany, nj",
  },
  {
    postal_code: "31089",
    zip_name: "tennille, ga",
  },
  {
    postal_code: "94576",
    zip_name: "deer park, ca",
  },
  {
    postal_code: "64035",
    zip_name: "hardin, mo",
  },
  {
    postal_code: "47528",
    zip_name: "edwardsport, in",
  },
  {
    postal_code: "10930",
    zip_name: "highland mills, ny",
  },
  {
    postal_code: "95567",
    zip_name: "smith river, ca",
  },
  {
    postal_code: "64770",
    zip_name: "montrose, mo",
  },
  {
    postal_code: "4095",
    zip_name: "west newfield, me",
  },
  {
    postal_code: "63560",
    zip_name: "pollock, mo",
  },
  {
    postal_code: "67511",
    zip_name: "albert, ks",
  },
  {
    postal_code: "78836",
    zip_name: "catarina, tx",
  },
  {
    postal_code: "75670",
    zip_name: "marshall, tx",
  },
  {
    postal_code: "62428",
    zip_name: "greenup, il",
  },
  {
    postal_code: "63945",
    zip_name: "harviell, mo",
  },
  {
    postal_code: "31764",
    zip_name: "leslie, ga",
  },
  {
    postal_code: "49667",
    zip_name: "merritt, mi",
  },
  {
    postal_code: "38841",
    zip_name: "ecru, ms",
  },
  {
    postal_code: "97750",
    zip_name: "mitchell, or",
  },
  {
    postal_code: "17224",
    zip_name: "fort loudon, pa",
  },
  {
    postal_code: "39189",
    zip_name: "walnut grove, ms",
  },
  {
    postal_code: "93430",
    zip_name: "cayucos, ca",
  },
  {
    postal_code: "37145",
    zip_name: "pleasant shade, tn",
  },
  {
    postal_code: "25121",
    zip_name: "lake, wv",
  },
  {
    postal_code: "51523",
    zip_name: "blencoe, ia",
  },
  {
    postal_code: "24476",
    zip_name: "steeles tavern, va",
  },
  {
    postal_code: "49419",
    zip_name: "hamilton, mi",
  },
  {
    postal_code: "73045",
    zip_name: "harrah, ok",
  },
  {
    postal_code: "68979",
    zip_name: "sutton, ne",
  },
  {
    postal_code: "67658",
    zip_name: "paradise, ks",
  },
  {
    postal_code: "16254",
    zip_name: "shippenville, pa",
  },
  {
    postal_code: "16839",
    zip_name: "grassflat, pa",
  },
  {
    postal_code: "49101",
    zip_name: "baroda, mi",
  },
  {
    postal_code: "14819",
    zip_name: "cameron, ny",
  },
  {
    postal_code: "56723",
    zip_name: "fisher, mn",
  },
  {
    postal_code: "35901",
    zip_name: "gadsden, al",
  },
  {
    postal_code: "10956",
    zip_name: "new city, ny",
  },
  {
    postal_code: "37059",
    zip_name: "dowelltown, tn",
  },
  {
    postal_code: "66080",
    zip_name: "richmond, ks",
  },
  {
    postal_code: "41121",
    zip_name: "argillite, ky",
  },
  {
    postal_code: "98359",
    zip_name: "olalla, wa",
  },
  {
    postal_code: "98501",
    zip_name: "olympia, wa",
  },
  {
    postal_code: "27875",
    zip_name: "scranton, nc",
  },
  {
    postal_code: "63767",
    zip_name: "morley, mo",
  },
  {
    postal_code: "63743",
    zip_name: "daisy, mo",
  },
  {
    postal_code: "79731",
    zip_name: "crane, tx",
  },
  {
    postal_code: "84772",
    zip_name: "summit, ut",
  },
  {
    postal_code: "28325",
    zip_name: "calypso, nc",
  },
  {
    postal_code: "80025",
    zip_name: "eldorado springs, co",
  },
  {
    postal_code: "97543",
    zip_name: "wilderville, or",
  },
  {
    postal_code: "66852",
    zip_name: "gridley, ks",
  },
  {
    postal_code: "36317",
    zip_name: "clopton, al",
  },
  {
    postal_code: "52584",
    zip_name: "pulaski, ia",
  },
  {
    postal_code: "64867",
    zip_name: "stella, mo",
  },
  {
    postal_code: "53003",
    zip_name: "ashippun, wi",
  },
  {
    postal_code: "48342",
    zip_name: "pontiac, mi",
  },
  {
    postal_code: "22641",
    zip_name: "strasburg, va",
  },
  {
    postal_code: "98580",
    zip_name: "roy, wa",
  },
  {
    postal_code: "58573",
    zip_name: "strasburg, nd",
  },
  {
    postal_code: "41385",
    zip_name: "vancleve, ky",
  },
  {
    postal_code: "58580",
    zip_name: "zap, nd",
  },
  {
    postal_code: "36610",
    zip_name: "mobile, al",
  },
  {
    postal_code: "50665",
    zip_name: "parkersburg, ia",
  },
  {
    postal_code: "46035",
    zip_name: "colfax, in",
  },
  {
    postal_code: "54228",
    zip_name: "mishicot, wi",
  },
  {
    postal_code: "55402",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "52650",
    zip_name: "sperry, ia",
  },
  {
    postal_code: "68464",
    zip_name: "western, ne",
  },
  {
    postal_code: "16852",
    zip_name: "madisonburg, pa",
  },
  {
    postal_code: "79741",
    zip_name: "goldsmith, tx",
  },
  {
    postal_code: "50477",
    zip_name: "swaledale, ia",
  },
  {
    postal_code: "51447",
    zip_name: "kirkman, ia",
  },
  {
    postal_code: "41540",
    zip_name: "lick creek, ky",
  },
  {
    postal_code: "61262",
    zip_name: "lynn center, il",
  },
  {
    postal_code: "64479",
    zip_name: "ravenwood, mo",
  },
  {
    postal_code: "82643",
    zip_name: "midwest, wy",
  },
  {
    postal_code: "28007",
    zip_name: "ansonville, nc",
  },
  {
    postal_code: "26680",
    zip_name: "nallen, wv",
  },
  {
    postal_code: "87715",
    zip_name: "cleveland, nm",
  },
  {
    postal_code: "96718",
    zip_name: "hawaii national park, hi",
  },
  {
    postal_code: "40953",
    zip_name: "hinkle, ky",
  },
  {
    postal_code: "60956",
    zip_name: "papineau, il",
  },
  {
    postal_code: "26586",
    zip_name: "montana mines, wv",
  },
  {
    postal_code: "59525",
    zip_name: "gildford, mt",
  },
  {
    postal_code: "16621",
    zip_name: "broad top, pa",
  },
  {
    postal_code: "18420",
    zip_name: "fleetville, pa",
  },
  {
    postal_code: "79351",
    zip_name: "odonnell, tx",
  },
  {
    postal_code: "67744",
    zip_name: "ludell, ks",
  },
  {
    postal_code: "68033",
    zip_name: "ithaca, ne",
  },
  {
    postal_code: "36738",
    zip_name: "faunsdale, al",
  },
  {
    postal_code: "99113",
    zip_name: "colton, wa",
  },
  {
    postal_code: "66094",
    zip_name: "white cloud, ks",
  },
  {
    postal_code: "39737",
    zip_name: "bellefontaine, ms",
  },
  {
    postal_code: "15483",
    zip_name: "stockdale, pa",
  },
  {
    postal_code: "58654",
    zip_name: "sentinel butte, nd",
  },
  {
    postal_code: "63942",
    zip_name: "gatewood, mo",
  },
  {
    postal_code: "59855",
    zip_name: "pablo, mt",
  },
  {
    postal_code: "57355",
    zip_name: "kimball, sd",
  },
  {
    postal_code: "71286",
    zip_name: "transylvania, la",
  },
  {
    postal_code: "41669",
    zip_name: "wheelwright, ky",
  },
  {
    postal_code: "6467",
    zip_name: "milldale, ct",
  },
  {
    postal_code: "59451",
    zip_name: "hilger, mt",
  },
  {
    postal_code: "39366",
    zip_name: "vossburg, ms",
  },
  {
    postal_code: "58223",
    zip_name: "cummings, nd",
  },
  {
    postal_code: "51049",
    zip_name: "quimby, ia",
  },
  {
    postal_code: "62380",
    zip_name: "west point, il",
  },
  {
    postal_code: "25244",
    zip_name: "gay, wv",
  },
  {
    postal_code: "76464",
    zip_name: "moran, tx",
  },
  {
    postal_code: "40771",
    zip_name: "woodbine, ky",
  },
  {
    postal_code: "67518",
    zip_name: "beeler, ks",
  },
  {
    postal_code: "87316",
    zip_name: "fort wingate, nm",
  },
  {
    postal_code: "79851",
    zip_name: "sierra blanca, tx",
  },
  {
    postal_code: "42163",
    zip_name: "rocky hill, ky",
  },
  {
    postal_code: "50836",
    zip_name: "blockton, ia",
  },
  {
    postal_code: "66416",
    zip_name: "circleville, ks",
  },
  {
    postal_code: "79742",
    zip_name: "grandfalls, tx",
  },
  {
    postal_code: "98641",
    zip_name: "oysterville, wa",
  },
  {
    postal_code: "58262",
    zip_name: "mountain, nd",
  },
  {
    postal_code: "58467",
    zip_name: "medina, nd",
  },
  {
    postal_code: "66407",
    zip_name: "belvue, ks",
  },
  {
    postal_code: "39841",
    zip_name: "damascus, ga",
  },
  {
    postal_code: "50539",
    zip_name: "fenton, ia",
  },
  {
    postal_code: "80812",
    zip_name: "cope, co",
  },
  {
    postal_code: "65461",
    zip_name: "duke, mo",
  },
  {
    postal_code: "69144",
    zip_name: "keystone, ne",
  },
  {
    postal_code: "62438",
    zip_name: "lakewood, il",
  },
  {
    postal_code: "82321",
    zip_name: "baggs, wy",
  },
  {
    postal_code: "42035",
    zip_name: "cunningham, ky",
  },
  {
    postal_code: "16733",
    zip_name: "hazel hurst, pa",
  },
  {
    postal_code: "39824",
    zip_name: "bluffton, ga",
  },
  {
    postal_code: "16619",
    zip_name: "blandburg, pa",
  },
  {
    postal_code: "73444",
    zip_name: "hennepin, ok",
  },
  {
    postal_code: "59417",
    zip_name: "browning, mt",
  },
  {
    postal_code: "66761",
    zip_name: "piqua, ks",
  },
  {
    postal_code: "67650",
    zip_name: "morland, ks",
  },
  {
    postal_code: "87347",
    zip_name: "jamestown, nm",
  },
  {
    postal_code: "48475",
    zip_name: "ubly, mi",
  },
  {
    postal_code: "78071",
    zip_name: "three rivers, tx",
  },
  {
    postal_code: "85610",
    zip_name: "elfrida, az",
  },
  {
    postal_code: "5058",
    zip_name: "post mills, vt",
  },
  {
    postal_code: "15446",
    zip_name: "indian head, pa",
  },
  {
    postal_code: "97412",
    zip_name: "blachly, or",
  },
  {
    postal_code: "12768",
    zip_name: "parksville, ny",
  },
  {
    postal_code: "41514",
    zip_name: "belfry, ky",
  },
  {
    postal_code: "61928",
    zip_name: "gays, il",
  },
  {
    postal_code: "21545",
    zip_name: "mount savage, md",
  },
  {
    postal_code: "15638",
    zip_name: "hostetter, pa",
  },
  {
    postal_code: "39657",
    zip_name: "osyka, ms",
  },
  {
    postal_code: "41844",
    zip_name: "pippa passes, ky",
  },
  {
    postal_code: "70777",
    zip_name: "slaughter, la",
  },
  {
    postal_code: "62426",
    zip_name: "edgewood, il",
  },
  {
    postal_code: "55711",
    zip_name: "brookston, mn",
  },
  {
    postal_code: "87402",
    zip_name: "farmington, nm",
  },
  {
    postal_code: "17827",
    zip_name: "freeburg, pa",
  },
  {
    postal_code: "70639",
    zip_name: "evans, la",
  },
  {
    postal_code: "66548",
    zip_name: "waterville, ks",
  },
  {
    postal_code: "98851",
    zip_name: "soap lake, wa",
  },
  {
    postal_code: "25849",
    zip_name: "glen white, wv",
  },
  {
    postal_code: "63957",
    zip_name: "piedmont, mo",
  },
  {
    postal_code: "23897",
    zip_name: "yale, va",
  },
  {
    postal_code: "48878",
    zip_name: "rosebush, mi",
  },
  {
    postal_code: "32466",
    zip_name: "youngstown, fl",
  },
  {
    postal_code: "64679",
    zip_name: "spickard, mo",
  },
  {
    postal_code: "49349",
    zip_name: "white cloud, mi",
  },
  {
    postal_code: "71969",
    zip_name: "sims, ar",
  },
  {
    postal_code: "96069",
    zip_name: "oak run, ca",
  },
  {
    postal_code: "12766",
    zip_name: "north branch, ny",
  },
  {
    postal_code: "16939",
    zip_name: "morris run, pa",
  },
  {
    postal_code: "47577",
    zip_name: "saint meinrad, in",
  },
  {
    postal_code: "45734",
    zip_name: "graysville, oh",
  },
  {
    postal_code: "3273",
    zip_name: "south sutton, nh",
  },
  {
    postal_code: "93201",
    zip_name: "alpaugh, ca",
  },
  {
    postal_code: "80750",
    zip_name: "snyder, co",
  },
  {
    postal_code: "68854",
    zip_name: "marquette, ne",
  },
  {
    postal_code: "59347",
    zip_name: "rosebud, mt",
  },
  {
    postal_code: "80822",
    zip_name: "joes, co",
  },
  {
    postal_code: "81620",
    zip_name: "avon, co",
  },
  {
    postal_code: "64743",
    zip_name: "east lynne, mo",
  },
  {
    postal_code: "32459",
    zip_name: "santa rosa beach, fl",
  },
  {
    postal_code: "29320",
    zip_name: "arcadia, sc",
  },
  {
    postal_code: "10986",
    zip_name: "tomkins cove, ny",
  },
  {
    postal_code: "42202",
    zip_name: "adairville, ky",
  },
  {
    postal_code: "74052",
    zip_name: "oilton, ok",
  },
  {
    postal_code: "60942",
    zip_name: "hoopeston, il",
  },
  {
    postal_code: "38477",
    zip_name: "prospect, tn",
  },
  {
    postal_code: "36030",
    zip_name: "forest home, al",
  },
  {
    postal_code: "68792",
    zip_name: "wynot, ne",
  },
  {
    postal_code: "31829",
    zip_name: "upatoi, ga",
  },
  {
    postal_code: "51250",
    zip_name: "sioux center, ia",
  },
  {
    postal_code: "66507",
    zip_name: "maple hill, ks",
  },
  {
    postal_code: "61930",
    zip_name: "hindsboro, il",
  },
  {
    postal_code: "13664",
    zip_name: "morristown, ny",
  },
  {
    postal_code: "73735",
    zip_name: "drummond, ok",
  },
  {
    postal_code: "62277",
    zip_name: "prairie du rocher, il",
  },
  {
    postal_code: "3601",
    zip_name: "acworth, nh",
  },
  {
    postal_code: "20862",
    zip_name: "brinklow, md",
  },
  {
    postal_code: "23464",
    zip_name: "virginia beach, va",
  },
  {
    postal_code: "62083",
    zip_name: "rosamond, il",
  },
  {
    postal_code: "72682",
    zip_name: "valley springs, ar",
  },
  {
    postal_code: "24569",
    zip_name: "long island, va",
  },
  {
    postal_code: "58558",
    zip_name: "menoken, nd",
  },
  {
    postal_code: "35755",
    zip_name: "langston, al",
  },
  {
    postal_code: "49264",
    zip_name: "onondaga, mi",
  },
  {
    postal_code: "88336",
    zip_name: "hondo, nm",
  },
  {
    postal_code: "14216",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "69020",
    zip_name: "bartley, ne",
  },
  {
    postal_code: "46794",
    zip_name: "wawaka, in",
  },
  {
    postal_code: "73169",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "17565",
    zip_name: "pequea, pa",
  },
  {
    postal_code: "14550",
    zip_name: "silver springs, ny",
  },
  {
    postal_code: "37359",
    zip_name: "mulberry, tn",
  },
  {
    postal_code: "52213",
    zip_name: "center point, ia",
  },
  {
    postal_code: "45319",
    zip_name: "donnelsville, oh",
  },
  {
    postal_code: "59416",
    zip_name: "brady, mt",
  },
  {
    postal_code: "52165",
    zip_name: "ridgeway, ia",
  },
  {
    postal_code: "38839",
    zip_name: "derma, ms",
  },
  {
    postal_code: "79744",
    zip_name: "iraan, tx",
  },
  {
    postal_code: "70656",
    zip_name: "pitkin, la",
  },
  {
    postal_code: "70805",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "56453",
    zip_name: "hewitt, mn",
  },
  {
    postal_code: "17835",
    zip_name: "laurelton, pa",
  },
  {
    postal_code: "50860",
    zip_name: "redding, ia",
  },
  {
    postal_code: "81076",
    zip_name: "sugar city, co",
  },
  {
    postal_code: "98021",
    zip_name: "bothell, wa",
  },
  {
    postal_code: "76388",
    zip_name: "weinert, tx",
  },
  {
    postal_code: "41141",
    zip_name: "garrison, ky",
  },
  {
    postal_code: "49115",
    zip_name: "harbert, mi",
  },
  {
    postal_code: "47557",
    zip_name: "monroe city, in",
  },
  {
    postal_code: "49705",
    zip_name: "afton, mi",
  },
  {
    postal_code: "26178",
    zip_name: "smithville, wv",
  },
  {
    postal_code: "73737",
    zip_name: "fairview, ok",
  },
  {
    postal_code: "31049",
    zip_name: "kite, ga",
  },
  {
    postal_code: "72930",
    zip_name: "cecil, ar",
  },
  {
    postal_code: "52314",
    zip_name: "mount vernon, ia",
  },
  {
    postal_code: "19736",
    zip_name: "yorklyn, de",
  },
  {
    postal_code: "46929",
    zip_name: "flora, in",
  },
  {
    postal_code: "72472",
    zip_name: "trumann, ar",
  },
  {
    postal_code: "80483",
    zip_name: "yampa, co",
  },
  {
    postal_code: "57035",
    zip_name: "humboldt, sd",
  },
  {
    postal_code: "40176",
    zip_name: "webster, ky",
  },
  {
    postal_code: "81123",
    zip_name: "blanca, co",
  },
  {
    postal_code: "39666",
    zip_name: "summit, ms",
  },
  {
    postal_code: "54480",
    zip_name: "stetsonville, wi",
  },
  {
    postal_code: "24874",
    zip_name: "pineville, wv",
  },
  {
    postal_code: "97633",
    zip_name: "merrill, or",
  },
  {
    postal_code: "23845",
    zip_name: "ebony, va",
  },
  {
    postal_code: "65690",
    zip_name: "couch, mo",
  },
  {
    postal_code: "13410",
    zip_name: "nelliston, ny",
  },
  {
    postal_code: "46065",
    zip_name: "rossville, in",
  },
  {
    postal_code: "17949",
    zip_name: "mahanoy plane, pa",
  },
  {
    postal_code: "12914",
    zip_name: "bombay, ny",
  },
  {
    postal_code: "25266",
    zip_name: "newton, wv",
  },
  {
    postal_code: "47881",
    zip_name: "staunton, in",
  },
  {
    postal_code: "30805",
    zip_name: "blythe, ga",
  },
  {
    postal_code: "62942",
    zip_name: "grand tower, il",
  },
  {
    postal_code: "38863",
    zip_name: "pontotoc, ms",
  },
  {
    postal_code: "29051",
    zip_name: "gable, sc",
  },
  {
    postal_code: "97901",
    zip_name: "adrian, or",
  },
  {
    postal_code: "73557",
    zip_name: "medicine park, ok",
  },
  {
    postal_code: "38359",
    zip_name: "milledgeville, tn",
  },
  {
    postal_code: "13633",
    zip_name: "de peyster, ny",
  },
  {
    postal_code: "98008",
    zip_name: "bellevue, wa",
  },
  {
    postal_code: "23882",
    zip_name: "stony creek, va",
  },
  {
    postal_code: "85934",
    zip_name: "pinedale, az",
  },
  {
    postal_code: "58429",
    zip_name: "dazey, nd",
  },
  {
    postal_code: "40972",
    zip_name: "oneida, ky",
  },
  {
    postal_code: "13065",
    zip_name: "fayette, ny",
  },
  {
    postal_code: "71651",
    zip_name: "jersey, ar",
  },
  {
    postal_code: "93554",
    zip_name: "randsburg, ca",
  },
  {
    postal_code: "93652",
    zip_name: "raisin city, ca",
  },
  {
    postal_code: "51044",
    zip_name: "oto, ia",
  },
  {
    postal_code: "58260",
    zip_name: "milton, nd",
  },
  {
    postal_code: "63869",
    zip_name: "new madrid, mo",
  },
  {
    postal_code: "73728",
    zip_name: "cherokee, ok",
  },
  {
    postal_code: "38617",
    zip_name: "coahoma, ms",
  },
  {
    postal_code: "59528",
    zip_name: "hingham, mt",
  },
  {
    postal_code: "59465",
    zip_name: "neihart, mt",
  },
  {
    postal_code: "5649",
    zip_name: "east barre, vt",
  },
  {
    postal_code: "40036",
    zip_name: "lockport, ky",
  },
  {
    postal_code: "54761",
    zip_name: "plum city, wi",
  },
  {
    postal_code: "38927",
    zip_name: "enid, ms",
  },
  {
    postal_code: "81049",
    zip_name: "kim, co",
  },
  {
    postal_code: "12460",
    zip_name: "oak hill, ny",
  },
  {
    postal_code: "65730",
    zip_name: "powell, mo",
  },
  {
    postal_code: "73722",
    zip_name: "burlington, ok",
  },
  {
    postal_code: "54921",
    zip_name: "bancroft, wi",
  },
  {
    postal_code: "57543",
    zip_name: "kadoka, sd",
  },
  {
    postal_code: "58241",
    zip_name: "hensel, nd",
  },
  {
    postal_code: "68938",
    zip_name: "fairfield, ne",
  },
  {
    postal_code: "59230",
    zip_name: "glasgow, mt",
  },
  {
    postal_code: "26676",
    zip_name: "leivasy, wv",
  },
  {
    postal_code: "65660",
    zip_name: "graff, mo",
  },
  {
    postal_code: "63567",
    zip_name: "worthington, mo",
  },
  {
    postal_code: "89424",
    zip_name: "nixon, nv",
  },
  {
    postal_code: "33865",
    zip_name: "ona, fl",
  },
  {
    postal_code: "42130",
    zip_name: "eighty eight, ky",
  },
  {
    postal_code: "75157",
    zip_name: "rosser, tx",
  },
  {
    postal_code: "40946",
    zip_name: "green road, ky",
  },
  {
    postal_code: "62803",
    zip_name: "hoyleton, il",
  },
  {
    postal_code: "67631",
    zip_name: "collyer, ks",
  },
  {
    postal_code: "29849",
    zip_name: "ulmer, sc",
  },
  {
    postal_code: "31007",
    zip_name: "byromville, ga",
  },
  {
    postal_code: "32530",
    zip_name: "bagdad, fl",
  },
  {
    postal_code: "57057",
    zip_name: "rutland, sd",
  },
  {
    postal_code: "58439",
    zip_name: "forbes, nd",
  },
  {
    postal_code: "38046",
    zip_name: "la grange, tn",
  },
  {
    postal_code: "55950",
    zip_name: "lansing, mn",
  },
  {
    postal_code: "60917",
    zip_name: "buckingham, il",
  },
  {
    postal_code: "61312",
    zip_name: "arlington, il",
  },
  {
    postal_code: "5901",
    zip_name: "averill, vt",
  },
  {
    postal_code: "67757",
    zip_name: "selden, ks",
  },
  {
    postal_code: "67626",
    zip_name: "bunker hill, ks",
  },
  {
    postal_code: "97627",
    zip_name: "keno, or",
  },
  {
    postal_code: "50051",
    zip_name: "clemons, ia",
  },
  {
    postal_code: "35183",
    zip_name: "weogufka, al",
  },
  {
    postal_code: "69346",
    zip_name: "harrison, ne",
  },
  {
    postal_code: "38387",
    zip_name: "westport, tn",
  },
  {
    postal_code: "41776",
    zip_name: "wooton, ky",
  },
  {
    postal_code: "56574",
    zip_name: "perley, mn",
  },
  {
    postal_code: "68860",
    zip_name: "oconto, ne",
  },
  {
    postal_code: "36562",
    zip_name: "perdido, al",
  },
  {
    postal_code: "64688",
    zip_name: "wheeling, mo",
  },
  {
    postal_code: "83427",
    zip_name: "iona, id",
  },
  {
    postal_code: "5640",
    zip_name: "adamant, vt",
  },
  {
    postal_code: "16328",
    zip_name: "hydetown, pa",
  },
  {
    postal_code: "52550",
    zip_name: "delta, ia",
  },
  {
    postal_code: "18437",
    zip_name: "lake como, pa",
  },
  {
    postal_code: "26266",
    zip_name: "upperglade, wv",
  },
  {
    postal_code: "72421",
    zip_name: "cash, ar",
  },
  {
    postal_code: "73559",
    zip_name: "mountain park, ok",
  },
  {
    postal_code: "61940",
    zip_name: "metcalf, il",
  },
  {
    postal_code: "50066",
    zip_name: "dawson, ia",
  },
  {
    postal_code: "57436",
    zip_name: "doland, sd",
  },
  {
    postal_code: "97357",
    zip_name: "logsden, or",
  },
  {
    postal_code: "52207",
    zip_name: "baldwin, ia",
  },
  {
    postal_code: "57469",
    zip_name: "redfield, sd",
  },
  {
    postal_code: "26361",
    zip_name: "gypsy, wv",
  },
  {
    postal_code: "67635",
    zip_name: "dresden, ks",
  },
  {
    postal_code: "40940",
    zip_name: "frakes, ky",
  },
  {
    postal_code: "35975",
    zip_name: "groveoak, al",
  },
  {
    postal_code: "58005",
    zip_name: "argusville, nd",
  },
  {
    postal_code: "7088",
    zip_name: "vauxhall, nj",
  },
  {
    postal_code: "1608",
    zip_name: "worcester, ma",
  },
  {
    postal_code: "53182",
    zip_name: "union grove, wi",
  },
  {
    postal_code: "15949",
    zip_name: "robinson, pa",
  },
  {
    postal_code: "95531",
    zip_name: "crescent city, ca",
  },
  {
    postal_code: "76866",
    zip_name: "paint rock, tx",
  },
  {
    postal_code: "39305",
    zip_name: "meridian, ms",
  },
  {
    postal_code: "68443",
    zip_name: "sterling, ne",
  },
  {
    postal_code: "75551",
    zip_name: "atlanta, tx",
  },
  {
    postal_code: "75421",
    zip_name: "brookston, tx",
  },
  {
    postal_code: "71003",
    zip_name: "athens, la",
  },
  {
    postal_code: "25067",
    zip_name: "east bank, wv",
  },
  {
    postal_code: "72335",
    zip_name: "forrest city, ar",
  },
  {
    postal_code: "24216",
    zip_name: "appalachia, va",
  },
  {
    postal_code: "57374",
    zip_name: "spencer, sd",
  },
  {
    postal_code: "38846",
    zip_name: "glen, ms",
  },
  {
    postal_code: "97447",
    zip_name: "idleyld park, or",
  },
  {
    postal_code: "12738",
    zip_name: "glen wild, ny",
  },
  {
    postal_code: "98579",
    zip_name: "rochester, wa",
  },
  {
    postal_code: "25181",
    zip_name: "seth, wv",
  },
  {
    postal_code: "62943",
    zip_name: "grantsburg, il",
  },
  {
    postal_code: "53021",
    zip_name: "fredonia, wi",
  },
  {
    postal_code: "76377",
    zip_name: "petrolia, tx",
  },
  {
    postal_code: "94304",
    zip_name: "palo alto, ca",
  },
  {
    postal_code: "31550",
    zip_name: "manor, ga",
  },
  {
    postal_code: "32407",
    zip_name: "panama city beach, fl",
  },
  {
    postal_code: "29180",
    zip_name: "winnsboro, sc",
  },
  {
    postal_code: "39153",
    zip_name: "raleigh, ms",
  },
  {
    postal_code: "24016",
    zip_name: "roanoke, va",
  },
  {
    postal_code: "95002",
    zip_name: "alviso, ca",
  },
  {
    postal_code: "78107",
    zip_name: "berclair, tx",
  },
  {
    postal_code: "78162",
    zip_name: "tuleta, tx",
  },
  {
    postal_code: "79837",
    zip_name: "dell city, tx",
  },
  {
    postal_code: "67952",
    zip_name: "moscow, ks",
  },
  {
    postal_code: "18352",
    zip_name: "reeders, pa",
  },
  {
    postal_code: "62446",
    zip_name: "mount erie, il",
  },
  {
    postal_code: "71866",
    zip_name: "winthrop, ar",
  },
  {
    postal_code: "71416",
    zip_name: "cloutierville, la",
  },
  {
    postal_code: "79063",
    zip_name: "nazareth, tx",
  },
  {
    postal_code: "59067",
    zip_name: "rapelje, mt",
  },
  {
    postal_code: "72413",
    zip_name: "biggers, ar",
  },
  {
    postal_code: "63446",
    zip_name: "knox city, mo",
  },
  {
    postal_code: "24830",
    zip_name: "elbert, wv",
  },
  {
    postal_code: "61091",
    zip_name: "woosung, il",
  },
  {
    postal_code: "49753",
    zip_name: "lachine, mi",
  },
  {
    postal_code: "68941",
    zip_name: "glenvil, ne",
  },
  {
    postal_code: "56750",
    zip_name: "red lake falls, mn",
  },
  {
    postal_code: "98396",
    zip_name: "wilkeson, wa",
  },
  {
    postal_code: "25845",
    zip_name: "glen fork, wv",
  },
  {
    postal_code: "14414",
    zip_name: "avon, ny",
  },
  {
    postal_code: "16695",
    zip_name: "woodbury, pa",
  },
  {
    postal_code: "83434",
    zip_name: "menan, id",
  },
  {
    postal_code: "58455",
    zip_name: "kensal, nd",
  },
  {
    postal_code: "35175",
    zip_name: "union grove, al",
  },
  {
    postal_code: "30828",
    zip_name: "warrenton, ga",
  },
  {
    postal_code: "27849",
    zip_name: "lewiston woodville, nc",
  },
  {
    postal_code: "15742",
    zip_name: "glen campbell, pa",
  },
  {
    postal_code: "59853",
    zip_name: "noxon, mt",
  },
  {
    postal_code: "87801",
    zip_name: "socorro, nm",
  },
  {
    postal_code: "81044",
    zip_name: "hasty, co",
  },
  {
    postal_code: "37306",
    zip_name: "belvidere, tn",
  },
  {
    postal_code: "99611",
    zip_name: "kenai, ak",
  },
  {
    postal_code: "70581",
    zip_name: "roanoke, la",
  },
  {
    postal_code: "16345",
    zip_name: "russell, pa",
  },
  {
    postal_code: "16364",
    zip_name: "venus, pa",
  },
  {
    postal_code: "73048",
    zip_name: "hydro, ok",
  },
  {
    postal_code: "97493",
    zip_name: "westlake, or",
  },
  {
    postal_code: "71701",
    zip_name: "camden, ar",
  },
  {
    postal_code: "18655",
    zip_name: "shickshinny, pa",
  },
  {
    postal_code: "35401",
    zip_name: "tuscaloosa, al",
  },
  {
    postal_code: "55919",
    zip_name: "brownsville, mn",
  },
  {
    postal_code: "39759",
    zip_name: "starkville, ms",
  },
  {
    postal_code: "12748",
    zip_name: "jeffersonville, ny",
  },
  {
    postal_code: "24013",
    zip_name: "roanoke, va",
  },
  {
    postal_code: "63091",
    zip_name: "rosebud, mo",
  },
  {
    postal_code: "57046",
    zip_name: "mission hill, sd",
  },
  {
    postal_code: "71836",
    zip_name: "foreman, ar",
  },
  {
    postal_code: "98586",
    zip_name: "south bend, wa",
  },
  {
    postal_code: "38579",
    zip_name: "quebeck, tn",
  },
  {
    postal_code: "35768",
    zip_name: "scottsboro, al",
  },
  {
    postal_code: "37419",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "10506",
    zip_name: "bedford, ny",
  },
  {
    postal_code: "57237",
    zip_name: "gary, sd",
  },
  {
    postal_code: "4443",
    zip_name: "guilford, me",
  },
  {
    postal_code: "73095",
    zip_name: "wayne, ok",
  },
  {
    postal_code: "14743",
    zip_name: "hinsdale, ny",
  },
  {
    postal_code: "69154",
    zip_name: "oshkosh, ne",
  },
  {
    postal_code: "74852",
    zip_name: "macomb, ok",
  },
  {
    postal_code: "18705",
    zip_name: "wilkes barre, pa",
  },
  {
    postal_code: "70453",
    zip_name: "pine grove, la",
  },
  {
    postal_code: "65109",
    zip_name: "jefferson city, mo",
  },
  {
    postal_code: "37087",
    zip_name: "lebanon, tn",
  },
  {
    postal_code: "38257",
    zip_name: "south fulton, tn",
  },
  {
    postal_code: "32439",
    zip_name: "freeport, fl",
  },
  {
    postal_code: "38371",
    zip_name: "sardis, tn",
  },
  {
    postal_code: "55926",
    zip_name: "dexter, mn",
  },
  {
    postal_code: "50624",
    zip_name: "dike, ia",
  },
  {
    postal_code: "18603",
    zip_name: "berwick, pa",
  },
  {
    postal_code: "64001",
    zip_name: "alma, mo",
  },
  {
    postal_code: "86331",
    zip_name: "jerome, az",
  },
  {
    postal_code: "76821",
    zip_name: "ballinger, tx",
  },
  {
    postal_code: "27974",
    zip_name: "shiloh, nc",
  },
  {
    postal_code: "39192",
    zip_name: "west, ms",
  },
  {
    postal_code: "98323",
    zip_name: "carbonado, wa",
  },
  {
    postal_code: "45863",
    zip_name: "middle point, oh",
  },
  {
    postal_code: "23416",
    zip_name: "oak hall, va",
  },
  {
    postal_code: "31638",
    zip_name: "morven, ga",
  },
  {
    postal_code: "52229",
    zip_name: "garrison, ia",
  },
  {
    postal_code: "38871",
    zip_name: "thaxton, ms",
  },
  {
    postal_code: "49245",
    zip_name: "homer, mi",
  },
  {
    postal_code: "99143",
    zip_name: "lacrosse, wa",
  },
  {
    postal_code: "31824",
    zip_name: "preston, ga",
  },
  {
    postal_code: "61377",
    zip_name: "wenona, il",
  },
  {
    postal_code: "35565",
    zip_name: "haleyville, al",
  },
  {
    postal_code: "45623",
    zip_name: "crown city, oh",
  },
  {
    postal_code: "23432",
    zip_name: "suffolk, va",
  },
  {
    postal_code: "14228",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "40819",
    zip_name: "coldiron, ky",
  },
  {
    postal_code: "99919",
    zip_name: "thorne bay, ak",
  },
  {
    postal_code: "63936",
    zip_name: "dudley, mo",
  },
  {
    postal_code: "23177",
    zip_name: "walkerton, va",
  },
  {
    postal_code: "37184",
    zip_name: "watertown, tn",
  },
  {
    postal_code: "45809",
    zip_name: "gomer, oh",
  },
  {
    postal_code: "23889",
    zip_name: "warfield, va",
  },
  {
    postal_code: "62823",
    zip_name: "cisne, il",
  },
  {
    postal_code: "65790",
    zip_name: "pottersville, mo",
  },
  {
    postal_code: "46913",
    zip_name: "bringhurst, in",
  },
  {
    postal_code: "73501",
    zip_name: "lawton, ok",
  },
  {
    postal_code: "7093",
    zip_name: "west new york, nj",
  },
  {
    postal_code: "26268",
    zip_name: "glady, wv",
  },
  {
    postal_code: "65446",
    zip_name: "cherryville, mo",
  },
  {
    postal_code: "95570",
    zip_name: "trinidad, ca",
  },
  {
    postal_code: "60967",
    zip_name: "stockland, il",
  },
  {
    postal_code: "95546",
    zip_name: "hoopa, ca",
  },
  {
    postal_code: "78145",
    zip_name: "pawnee, tx",
  },
  {
    postal_code: "38450",
    zip_name: "collinwood, tn",
  },
  {
    postal_code: "57219",
    zip_name: "bristol, sd",
  },
  {
    postal_code: "14729",
    zip_name: "east otto, ny",
  },
  {
    postal_code: "26720",
    zip_name: "gormania, wv",
  },
  {
    postal_code: "30815",
    zip_name: "hephzibah, ga",
  },
  {
    postal_code: "37138",
    zip_name: "old hickory, tn",
  },
  {
    postal_code: "45773",
    zip_name: "reno, oh",
  },
  {
    postal_code: "53040",
    zip_name: "kewaskum, wi",
  },
  {
    postal_code: "77622",
    zip_name: "hamshire, tx",
  },
  {
    postal_code: "73724",
    zip_name: "canton, ok",
  },
  {
    postal_code: "52048",
    zip_name: "garber, ia",
  },
  {
    postal_code: "86437",
    zip_name: "valentine, az",
  },
  {
    postal_code: "46786",
    zip_name: "south milford, in",
  },
  {
    postal_code: "58460",
    zip_name: "lehr, nd",
  },
  {
    postal_code: "68627",
    zip_name: "cedar rapids, ne",
  },
  {
    postal_code: "31642",
    zip_name: "pearson, ga",
  },
  {
    postal_code: "58792",
    zip_name: "voltaire, nd",
  },
  {
    postal_code: "13136",
    zip_name: "pitcher, ny",
  },
  {
    postal_code: "57047",
    zip_name: "monroe, sd",
  },
  {
    postal_code: "67632",
    zip_name: "damar, ks",
  },
  {
    postal_code: "25932",
    zip_name: "surveyor, wv",
  },
  {
    postal_code: "55036",
    zip_name: "henriette, mn",
  },
  {
    postal_code: "67423",
    zip_name: "beverly, ks",
  },
  {
    postal_code: "73749",
    zip_name: "jet, ok",
  },
  {
    postal_code: "69041",
    zip_name: "parks, ne",
  },
  {
    postal_code: "79043",
    zip_name: "hart, tx",
  },
  {
    postal_code: "56735",
    zip_name: "lancaster, mn",
  },
  {
    postal_code: "26884",
    zip_name: "seneca rocks, wv",
  },
  {
    postal_code: "14480",
    zip_name: "lakeville, ny",
  },
  {
    postal_code: "97859",
    zip_name: "meacham, or",
  },
  {
    postal_code: "83448",
    zip_name: "sugar city, id",
  },
  {
    postal_code: "69150",
    zip_name: "madrid, ne",
  },
  {
    postal_code: "56545",
    zip_name: "gary, mn",
  },
  {
    postal_code: "71839",
    zip_name: "garland city, ar",
  },
  {
    postal_code: "56212",
    zip_name: "bellingham, mn",
  },
  {
    postal_code: "70534",
    zip_name: "estherwood, la",
  },
  {
    postal_code: "56139",
    zip_name: "holland, mn",
  },
  {
    postal_code: "63882",
    zip_name: "wyatt, mo",
  },
  {
    postal_code: "76566",
    zip_name: "purmela, tx",
  },
  {
    postal_code: "73528",
    zip_name: "chattanooga, ok",
  },
  {
    postal_code: "21130",
    zip_name: "perryman, md",
  },
  {
    postal_code: "13418",
    zip_name: "north brookfield, ny",
  },
  {
    postal_code: "58535",
    zip_name: "flasher, nd",
  },
  {
    postal_code: "62908",
    zip_name: "belknap, il",
  },
  {
    postal_code: "36518",
    zip_name: "chatom, al",
  },
  {
    postal_code: "65784",
    zip_name: "zanoni, mo",
  },
  {
    postal_code: "71676",
    zip_name: "wilmot, ar",
  },
  {
    postal_code: "56328",
    zip_name: "flensburg, mn",
  },
  {
    postal_code: "66953",
    zip_name: "linn, ks",
  },
  {
    postal_code: "24860",
    zip_name: "matheny, wv",
  },
  {
    postal_code: "25862",
    zip_name: "lansing, wv",
  },
  {
    postal_code: "24976",
    zip_name: "sinks grove, wv",
  },
  {
    postal_code: "16692",
    zip_name: "westover, pa",
  },
  {
    postal_code: "63536",
    zip_name: "downing, mo",
  },
  {
    postal_code: "58049",
    zip_name: "kathryn, nd",
  },
  {
    postal_code: "18970",
    zip_name: "trumbauersville, pa",
  },
  {
    postal_code: "47981",
    zip_name: "romney, in",
  },
  {
    postal_code: "57271",
    zip_name: "vienna, sd",
  },
  {
    postal_code: "58466",
    zip_name: "marion, nd",
  },
  {
    postal_code: "26202",
    zip_name: "fenwick, wv",
  },
  {
    postal_code: "68879",
    zip_name: "taylor, ne",
  },
  {
    postal_code: "31323",
    zip_name: "riceboro, ga",
  },
  {
    postal_code: "59231",
    zip_name: "saint marie, mt",
  },
  {
    postal_code: "79082",
    zip_name: "springlake, tx",
  },
  {
    postal_code: "66508",
    zip_name: "marysville, ks",
  },
  {
    postal_code: "58723",
    zip_name: "butte, nd",
  },
  {
    postal_code: "62886",
    zip_name: "sims, il",
  },
  {
    postal_code: "78837",
    zip_name: "comstock, tx",
  },
  {
    postal_code: "59241",
    zip_name: "hinsdale, mt",
  },
  {
    postal_code: "45727",
    zip_name: "dexter city, oh",
  },
  {
    postal_code: "63381",
    zip_name: "truxton, mo",
  },
  {
    postal_code: "15940",
    zip_name: "loretto, pa",
  },
  {
    postal_code: "92364",
    zip_name: "nipton, ca",
  },
  {
    postal_code: "39771",
    zip_name: "walthall, ms",
  },
  {
    postal_code: "27821",
    zip_name: "edward, nc",
  },
  {
    postal_code: "46127",
    zip_name: "falmouth, in",
  },
  {
    postal_code: "81138",
    zip_name: "jaroso, co",
  },
  {
    postal_code: "25019",
    zip_name: "bickmore, wv",
  },
  {
    postal_code: "51650",
    zip_name: "riverton, ia",
  },
  {
    postal_code: "67117",
    zip_name: "north newton, ks",
  },
  {
    postal_code: "64428",
    zip_name: "burlington junction, mo",
  },
  {
    postal_code: "50476",
    zip_name: "stacyville, ia",
  },
  {
    postal_code: "86343",
    zip_name: "crown king, az",
  },
  {
    postal_code: "49058",
    zip_name: "hastings, mi",
  },
  {
    postal_code: "30823",
    zip_name: "stapleton, ga",
  },
  {
    postal_code: "59648",
    zip_name: "wolf creek, mt",
  },
  {
    postal_code: "84718",
    zip_name: "cannonville, ut",
  },
  {
    postal_code: "72027",
    zip_name: "center ridge, ar",
  },
  {
    postal_code: "24093",
    zip_name: "glen lyn, va",
  },
  {
    postal_code: "5654",
    zip_name: "graniteville, vt",
  },
  {
    postal_code: "54603",
    zip_name: "la crosse, wi",
  },
  {
    postal_code: "32421",
    zip_name: "altha, fl",
  },
  {
    postal_code: "68954",
    zip_name: "inland, ne",
  },
  {
    postal_code: "56587",
    zip_name: "vergas, mn",
  },
  {
    postal_code: "10965",
    zip_name: "pearl river, ny",
  },
  {
    postal_code: "16436",
    zip_name: "spring creek, pa",
  },
  {
    postal_code: "62338",
    zip_name: "fowler, il",
  },
  {
    postal_code: "93239",
    zip_name: "kettleman city, ca",
  },
  {
    postal_code: "56235",
    zip_name: "donnelly, mn",
  },
  {
    postal_code: "35550",
    zip_name: "cordova, al",
  },
  {
    postal_code: "26534",
    zip_name: "granville, wv",
  },
  {
    postal_code: "57465",
    zip_name: "northville, sd",
  },
  {
    postal_code: "62261",
    zip_name: "modoc, il",
  },
  {
    postal_code: "87723",
    zip_name: "holman, nm",
  },
  {
    postal_code: "49883",
    zip_name: "seney, mi",
  },
  {
    postal_code: "39079",
    zip_name: "goodman, ms",
  },
  {
    postal_code: "59315",
    zip_name: "bloomfield, mt",
  },
  {
    postal_code: "93925",
    zip_name: "chualar, ca",
  },
  {
    postal_code: "68789",
    zip_name: "winnetoon, ne",
  },
  {
    postal_code: "53176",
    zip_name: "springfield, wi",
  },
  {
    postal_code: "56221",
    zip_name: "chokio, mn",
  },
  {
    postal_code: "61459",
    zip_name: "marietta, il",
  },
  {
    postal_code: "57340",
    zip_name: "fulton, sd",
  },
  {
    postal_code: "50578",
    zip_name: "ringsted, ia",
  },
  {
    postal_code: "66830",
    zip_name: "admire, ks",
  },
  {
    postal_code: "26619",
    zip_name: "exchange, wv",
  },
  {
    postal_code: "88435",
    zip_name: "santa rosa, nm",
  },
  {
    postal_code: "73661",
    zip_name: "rocky, ok",
  },
  {
    postal_code: "7928",
    zip_name: "chatham, nj",
  },
  {
    postal_code: "61087",
    zip_name: "warren, il",
  },
  {
    postal_code: "37369",
    zip_name: "reliance, tn",
  },
  {
    postal_code: "94956",
    zip_name: "point reyes station, ca",
  },
  {
    postal_code: "35640",
    zip_name: "hartselle, al",
  },
  {
    postal_code: "52361",
    zip_name: "williamsburg, ia",
  },
  {
    postal_code: "99723",
    zip_name: "barrow, ak",
  },
  {
    postal_code: "53079",
    zip_name: "saint cloud, wi",
  },
  {
    postal_code: "74869",
    zip_name: "sparks, ok",
  },
  {
    postal_code: "54616",
    zip_name: "blair, wi",
  },
  {
    postal_code: "49001",
    zip_name: "kalamazoo, mi",
  },
  {
    postal_code: "42040",
    zip_name: "farmington, ky",
  },
  {
    postal_code: "61720",
    zip_name: "anchor, il",
  },
  {
    postal_code: "56260",
    zip_name: "maynard, mn",
  },
  {
    postal_code: "43521",
    zip_name: "fayette, oh",
  },
  {
    postal_code: "7976",
    zip_name: "new vernon, nj",
  },
  {
    postal_code: "32502",
    zip_name: "pensacola, fl",
  },
  {
    postal_code: "72521",
    zip_name: "cave city, ar",
  },
  {
    postal_code: "57451",
    zip_name: "ipswich, sd",
  },
  {
    postal_code: "30634",
    zip_name: "dewy rose, ga",
  },
  {
    postal_code: "16665",
    zip_name: "newry, pa",
  },
  {
    postal_code: "62807",
    zip_name: "alma, il",
  },
  {
    postal_code: "14437",
    zip_name: "dansville, ny",
  },
  {
    postal_code: "49279",
    zip_name: "sand creek, mi",
  },
  {
    postal_code: "99133",
    zip_name: "grand coulee, wa",
  },
  {
    postal_code: "56722",
    zip_name: "euclid, mn",
  },
  {
    postal_code: "39456",
    zip_name: "mc lain, ms",
  },
  {
    postal_code: "25846",
    zip_name: "glen jean, wv",
  },
  {
    postal_code: "47837",
    zip_name: "carbon, in",
  },
  {
    postal_code: "62076",
    zip_name: "ohlman, il",
  },
  {
    postal_code: "41719",
    zip_name: "bonnyman, ky",
  },
  {
    postal_code: "99401",
    zip_name: "anatone, wa",
  },
  {
    postal_code: "49021",
    zip_name: "bellevue, mi",
  },
  {
    postal_code: "88324",
    zip_name: "glencoe, nm",
  },
  {
    postal_code: "51559",
    zip_name: "neola, ia",
  },
  {
    postal_code: "52233",
    zip_name: "hiawatha, ia",
  },
  {
    postal_code: "95463",
    zip_name: "navarro, ca",
  },
  {
    postal_code: "7481",
    zip_name: "wyckoff, nj",
  },
  {
    postal_code: "54867",
    zip_name: "radisson, wi",
  },
  {
    postal_code: "46805",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "47392",
    zip_name: "webster, in",
  },
  {
    postal_code: "47145",
    zip_name: "milltown, in",
  },
  {
    postal_code: "62612",
    zip_name: "ashland, il",
  },
  {
    postal_code: "42339",
    zip_name: "dunmor, ky",
  },
  {
    postal_code: "4427",
    zip_name: "corinth, me",
  },
  {
    postal_code: "57005",
    zip_name: "brandon, sd",
  },
  {
    postal_code: "73179",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "56240",
    zip_name: "graceville, mn",
  },
  {
    postal_code: "37118",
    zip_name: "milton, tn",
  },
  {
    postal_code: "14171",
    zip_name: "west valley, ny",
  },
  {
    postal_code: "3890",
    zip_name: "west ossipee, nh",
  },
  {
    postal_code: "23480",
    zip_name: "wachapreague, va",
  },
  {
    postal_code: "22821",
    zip_name: "dayton, va",
  },
  {
    postal_code: "65244",
    zip_name: "clifton hill, mo",
  },
  {
    postal_code: "25559",
    zip_name: "salt rock, wv",
  },
  {
    postal_code: "12736",
    zip_name: "fremont center, ny",
  },
  {
    postal_code: "67226",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "59871",
    zip_name: "sula, mt",
  },
  {
    postal_code: "16669",
    zip_name: "petersburg, pa",
  },
  {
    postal_code: "57001",
    zip_name: "alcester, sd",
  },
  {
    postal_code: "46402",
    zip_name: "gary, in",
  },
  {
    postal_code: "53184",
    zip_name: "walworth, wi",
  },
  {
    postal_code: "22843",
    zip_name: "mount solon, va",
  },
  {
    postal_code: "65635",
    zip_name: "dadeville, mo",
  },
  {
    postal_code: "66748",
    zip_name: "humboldt, ks",
  },
  {
    postal_code: "98325",
    zip_name: "chimacum, wa",
  },
  {
    postal_code: "26291",
    zip_name: "slatyfork, wv",
  },
  {
    postal_code: "32539",
    zip_name: "crestview, fl",
  },
  {
    postal_code: "91931",
    zip_name: "guatay, ca",
  },
  {
    postal_code: "67649",
    zip_name: "luray, ks",
  },
  {
    postal_code: "31803",
    zip_name: "buena vista, ga",
  },
  {
    postal_code: "87036",
    zip_name: "mountainair, nm",
  },
  {
    postal_code: "45306",
    zip_name: "botkins, oh",
  },
  {
    postal_code: "26802",
    zip_name: "brandywine, wv",
  },
  {
    postal_code: "37388",
    zip_name: "tullahoma, tn",
  },
  {
    postal_code: "24149",
    zip_name: "riner, va",
  },
  {
    postal_code: "37207",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "54421",
    zip_name: "colby, wi",
  },
  {
    postal_code: "38850",
    zip_name: "houlka, ms",
  },
  {
    postal_code: "83444",
    zip_name: "roberts, id",
  },
  {
    postal_code: "71842",
    zip_name: "horatio, ar",
  },
  {
    postal_code: "37380",
    zip_name: "south pittsburg, tn",
  },
  {
    postal_code: "38665",
    zip_name: "sarah, ms",
  },
  {
    postal_code: "72438",
    zip_name: "leachville, ar",
  },
  {
    postal_code: "33930",
    zip_name: "felda, fl",
  },
  {
    postal_code: "16923",
    zip_name: "genesee, pa",
  },
  {
    postal_code: "62530",
    zip_name: "divernon, il",
  },
  {
    postal_code: "98188",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "73029",
    zip_name: "cyril, ok",
  },
  {
    postal_code: "35476",
    zip_name: "northport, al",
  },
  {
    postal_code: "75438",
    zip_name: "dodd city, tx",
  },
  {
    postal_code: "21679",
    zip_name: "wye mills, md",
  },
  {
    postal_code: "66860",
    zip_name: "madison, ks",
  },
  {
    postal_code: "83537",
    zip_name: "kendrick, id",
  },
  {
    postal_code: "82832",
    zip_name: "banner, wy",
  },
  {
    postal_code: "71762",
    zip_name: "smackover, ar",
  },
  {
    postal_code: "97903",
    zip_name: "brogan, or",
  },
  {
    postal_code: "52052",
    zip_name: "guttenberg, ia",
  },
  {
    postal_code: "71643",
    zip_name: "gould, ar",
  },
  {
    postal_code: "67336",
    zip_name: "chetopa, ks",
  },
  {
    postal_code: "44417",
    zip_name: "farmdale, oh",
  },
  {
    postal_code: "73082",
    zip_name: "rush springs, ok",
  },
  {
    postal_code: "99149",
    zip_name: "malden, wa",
  },
  {
    postal_code: "70442",
    zip_name: "husser, la",
  },
  {
    postal_code: "67146",
    zip_name: "udall, ks",
  },
  {
    postal_code: "14720",
    zip_name: "celoron, ny",
  },
  {
    postal_code: "57376",
    zip_name: "tripp, sd",
  },
  {
    postal_code: "59262",
    zip_name: "savage, mt",
  },
  {
    postal_code: "28552",
    zip_name: "lowland, nc",
  },
  {
    postal_code: "50065",
    zip_name: "davis city, ia",
  },
  {
    postal_code: "75207",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "52072",
    zip_name: "saint olaf, ia",
  },
  {
    postal_code: "64426",
    zip_name: "blythedale, mo",
  },
  {
    postal_code: "50235",
    zip_name: "rippey, ia",
  },
  {
    postal_code: "13111",
    zip_name: "martville, ny",
  },
  {
    postal_code: "59831",
    zip_name: "dixon, mt",
  },
  {
    postal_code: "52801",
    zip_name: "davenport, ia",
  },
  {
    postal_code: "71858",
    zip_name: "rosston, ar",
  },
  {
    postal_code: "73550",
    zip_name: "hollis, ok",
  },
  {
    postal_code: "67862",
    zip_name: "manter, ks",
  },
  {
    postal_code: "36550",
    zip_name: "little river, al",
  },
  {
    postal_code: "50473",
    zip_name: "scarville, ia",
  },
  {
    postal_code: "56725",
    zip_name: "goodridge, mn",
  },
  {
    postal_code: "96034",
    zip_name: "gazelle, ca",
  },
  {
    postal_code: "3897",
    zip_name: "wonalancet, nh",
  },
  {
    postal_code: "12475",
    zip_name: "ruby, ny",
  },
  {
    postal_code: "16321",
    zip_name: "east hickory, pa",
  },
  {
    postal_code: "36907",
    zip_name: "cuba, al",
  },
  {
    postal_code: "59524",
    zip_name: "dodson, mt",
  },
  {
    postal_code: "75560",
    zip_name: "douglassville, tx",
  },
  {
    postal_code: "24731",
    zip_name: "kegley, wv",
  },
  {
    postal_code: "4491",
    zip_name: "vanceboro, me",
  },
  {
    postal_code: "96014",
    zip_name: "callahan, ca",
  },
  {
    postal_code: "23108",
    zip_name: "mascot, va",
  },
  {
    postal_code: "66419",
    zip_name: "denison, ks",
  },
  {
    postal_code: "49303",
    zip_name: "bailey, mi",
  },
  {
    postal_code: "17263",
    zip_name: "state line, pa",
  },
  {
    postal_code: "26285",
    zip_name: "norton, wv",
  },
  {
    postal_code: "31045",
    zip_name: "jewell, ga",
  },
  {
    postal_code: "58782",
    zip_name: "sherwood, nd",
  },
  {
    postal_code: "16943",
    zip_name: "sabinsville, pa",
  },
  {
    postal_code: "83645",
    zip_name: "midvale, id",
  },
  {
    postal_code: "44633",
    zip_name: "holmesville, oh",
  },
  {
    postal_code: "26560",
    zip_name: "baxter, wv",
  },
  {
    postal_code: "31072",
    zip_name: "pitts, ga",
  },
  {
    postal_code: "39743",
    zip_name: "crawford, ms",
  },
  {
    postal_code: "43018",
    zip_name: "etna, oh",
  },
  {
    postal_code: "79534",
    zip_name: "mc caulley, tx",
  },
  {
    postal_code: "57474",
    zip_name: "stratford, sd",
  },
  {
    postal_code: "64149",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "72368",
    zip_name: "moro, ar",
  },
  {
    postal_code: "46798",
    zip_name: "yoder, in",
  },
  {
    postal_code: "68014",
    zip_name: "bruno, ne",
  },
  {
    postal_code: "71326",
    zip_name: "clayton, la",
  },
  {
    postal_code: "50143",
    zip_name: "leighton, ia",
  },
  {
    postal_code: "62467",
    zip_name: "teutopolis, il",
  },
  {
    postal_code: "35443",
    zip_name: "boligee, al",
  },
  {
    postal_code: "66870",
    zip_name: "virgil, ks",
  },
  {
    postal_code: "78675",
    zip_name: "willow city, tx",
  },
  {
    postal_code: "77863",
    zip_name: "lyons, tx",
  },
  {
    postal_code: "78827",
    zip_name: "asherton, tx",
  },
  {
    postal_code: "98198",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "64648",
    zip_name: "jamesport, mo",
  },
  {
    postal_code: "26722",
    zip_name: "green spring, wv",
  },
  {
    postal_code: "54154",
    zip_name: "oconto falls, wi",
  },
  {
    postal_code: "48889",
    zip_name: "sumner, mi",
  },
  {
    postal_code: "26866",
    zip_name: "upper tract, wv",
  },
  {
    postal_code: "57103",
    zip_name: "sioux falls, sd",
  },
  {
    postal_code: "66771",
    zip_name: "saint paul, ks",
  },
  {
    postal_code: "66839",
    zip_name: "burlington, ks",
  },
  {
    postal_code: "36609",
    zip_name: "mobile, al",
  },
  {
    postal_code: "52215",
    zip_name: "chelsea, ia",
  },
  {
    postal_code: "16668",
    zip_name: "patton, pa",
  },
  {
    postal_code: "7722",
    zip_name: "colts neck, nj",
  },
  {
    postal_code: "24473",
    zip_name: "rockbridge baths, va",
  },
  {
    postal_code: "39746",
    zip_name: "hamilton, ms",
  },
  {
    postal_code: "49004",
    zip_name: "kalamazoo, mi",
  },
  {
    postal_code: "46531",
    zip_name: "grovertown, in",
  },
  {
    postal_code: "10988",
    zip_name: "unionville, ny",
  },
  {
    postal_code: "40358",
    zip_name: "olympia, ky",
  },
  {
    postal_code: "70658",
    zip_name: "reeves, la",
  },
  {
    postal_code: "64486",
    zip_name: "sheridan, mo",
  },
  {
    postal_code: "58418",
    zip_name: "bowdon, nd",
  },
  {
    postal_code: "88418",
    zip_name: "des moines, nm",
  },
  {
    postal_code: "54416",
    zip_name: "bowler, wi",
  },
  {
    postal_code: "27853",
    zip_name: "margarettsville, nc",
  },
  {
    postal_code: "57330",
    zip_name: "delmont, sd",
  },
  {
    postal_code: "54517",
    zip_name: "clam lake, wi",
  },
  {
    postal_code: "97414",
    zip_name: "broadbent, or",
  },
  {
    postal_code: "5489",
    zip_name: "underhill, vt",
  },
  {
    postal_code: "73481",
    zip_name: "ratliff city, ok",
  },
  {
    postal_code: "58572",
    zip_name: "sterling, nd",
  },
  {
    postal_code: "63782",
    zip_name: "sturdivant, mo",
  },
  {
    postal_code: "41135",
    zip_name: "emerson, ky",
  },
  {
    postal_code: "29111",
    zip_name: "new zion, sc",
  },
  {
    postal_code: "87712",
    zip_name: "buena vista, nm",
  },
  {
    postal_code: "69201",
    zip_name: "valentine, ne",
  },
  {
    postal_code: "65501",
    zip_name: "jadwin, mo",
  },
  {
    postal_code: "64072",
    zip_name: "missouri city, mo",
  },
  {
    postal_code: "65557",
    zip_name: "roby, mo",
  },
  {
    postal_code: "71972",
    zip_name: "vandervoort, ar",
  },
  {
    postal_code: "45651",
    zip_name: "mc arthur, oh",
  },
  {
    postal_code: "98934",
    zip_name: "kittitas, wa",
  },
  {
    postal_code: "52586",
    zip_name: "rose hill, ia",
  },
  {
    postal_code: "14569",
    zip_name: "warsaw, ny",
  },
  {
    postal_code: "98057",
    zip_name: "renton, wa",
  },
  {
    postal_code: "98074",
    zip_name: "sammamish, wa",
  },
  {
    postal_code: "95554",
    zip_name: "myers flat, ca",
  },
  {
    postal_code: "23846",
    zip_name: "elberon, va",
  },
  {
    postal_code: "58463",
    zip_name: "mcclusky, nd",
  },
  {
    postal_code: "99329",
    zip_name: "dixie, wa",
  },
  {
    postal_code: "62540",
    zip_name: "kincaid, il",
  },
  {
    postal_code: "35653",
    zip_name: "russellville, al",
  },
  {
    postal_code: "16650",
    zip_name: "hopewell, pa",
  },
  {
    postal_code: "99036",
    zip_name: "valleyford, wa",
  },
  {
    postal_code: "50606",
    zip_name: "arlington, ia",
  },
  {
    postal_code: "25831",
    zip_name: "danese, wv",
  },
  {
    postal_code: "98036",
    zip_name: "lynnwood, wa",
  },
  {
    postal_code: "98946",
    zip_name: "thorp, wa",
  },
  {
    postal_code: "26570",
    zip_name: "fairview, wv",
  },
  {
    postal_code: "45628",
    zip_name: "frankfort, oh",
  },
  {
    postal_code: "17864",
    zip_name: "port trevorton, pa",
  },
  {
    postal_code: "77865",
    zip_name: "marquez, tx",
  },
  {
    postal_code: "77468",
    zip_name: "pledger, tx",
  },
  {
    postal_code: "65237",
    zip_name: "bunceton, mo",
  },
  {
    postal_code: "98203",
    zip_name: "everett, wa",
  },
  {
    postal_code: "66528",
    zip_name: "quenemo, ks",
  },
  {
    postal_code: "83545",
    zip_name: "peck, id",
  },
  {
    postal_code: "49125",
    zip_name: "sawyer, mi",
  },
  {
    postal_code: "13353",
    zip_name: "hoffmeister, ny",
  },
  {
    postal_code: "95560",
    zip_name: "redway, ca",
  },
  {
    postal_code: "72332",
    zip_name: "edmondson, ar",
  },
  {
    postal_code: "72860",
    zip_name: "rover, ar",
  },
  {
    postal_code: "12493",
    zip_name: "west park, ny",
  },
  {
    postal_code: "58621",
    zip_name: "beach, nd",
  },
  {
    postal_code: "49079",
    zip_name: "paw paw, mi",
  },
  {
    postal_code: "59046",
    zip_name: "lavina, mt",
  },
  {
    postal_code: "85620",
    zip_name: "naco, az",
  },
  {
    postal_code: "29842",
    zip_name: "beech island, sc",
  },
  {
    postal_code: "99762",
    zip_name: "nome, ak",
  },
  {
    postal_code: "95556",
    zip_name: "orleans, ca",
  },
  {
    postal_code: "50452",
    zip_name: "latimer, ia",
  },
  {
    postal_code: "12454",
    zip_name: "maplecrest, ny",
  },
  {
    postal_code: "24465",
    zip_name: "monterey, va",
  },
  {
    postal_code: "37362",
    zip_name: "old fort, tn",
  },
  {
    postal_code: "93517",
    zip_name: "bridgeport, ca",
  },
  {
    postal_code: "47393",
    zip_name: "williamsburg, in",
  },
  {
    postal_code: "42566",
    zip_name: "yosemite, ky",
  },
  {
    postal_code: "89835",
    zip_name: "wells, nv",
  },
  {
    postal_code: "72137",
    zip_name: "rose bud, ar",
  },
  {
    postal_code: "52542",
    zip_name: "cantril, ia",
  },
  {
    postal_code: "73841",
    zip_name: "fort supply, ok",
  },
  {
    postal_code: "36862",
    zip_name: "lafayette, al",
  },
  {
    postal_code: "87827",
    zip_name: "pie town, nm",
  },
  {
    postal_code: "98282",
    zip_name: "camano island, wa",
  },
  {
    postal_code: "72675",
    zip_name: "saint joe, ar",
  },
  {
    postal_code: "32536",
    zip_name: "crestview, fl",
  },
  {
    postal_code: "31549",
    zip_name: "lumber city, ga",
  },
  {
    postal_code: "7450",
    zip_name: "ridgewood, nj",
  },
  {
    postal_code: "97326",
    zip_name: "blodgett, or",
  },
  {
    postal_code: "71075",
    zip_name: "springhill, la",
  },
  {
    postal_code: "53023",
    zip_name: "glenbeulah, wi",
  },
  {
    postal_code: "72199",
    zip_name: "north little rock, ar",
  },
  {
    postal_code: "62627",
    zip_name: "chandlerville, il",
  },
  {
    postal_code: "73843",
    zip_name: "gage, ok",
  },
  {
    postal_code: "47357",
    zip_name: "milton, in",
  },
  {
    postal_code: "32565",
    zip_name: "jay, fl",
  },
  {
    postal_code: "49791",
    zip_name: "topinabee, mi",
  },
  {
    postal_code: "29075",
    zip_name: "little mountain, sc",
  },
  {
    postal_code: "26815",
    zip_name: "sugar grove, wv",
  },
  {
    postal_code: "38858",
    zip_name: "nettleton, ms",
  },
  {
    postal_code: "50670",
    zip_name: "shell rock, ia",
  },
  {
    postal_code: "59827",
    zip_name: "conner, mt",
  },
  {
    postal_code: "25005",
    zip_name: "amma, wv",
  },
  {
    postal_code: "80476",
    zip_name: "silver plume, co",
  },
  {
    postal_code: "66869",
    zip_name: "strong city, ks",
  },
  {
    postal_code: "46155",
    zip_name: "mays, in",
  },
  {
    postal_code: "39846",
    zip_name: "edison, ga",
  },
  {
    postal_code: "39355",
    zip_name: "quitman, ms",
  },
  {
    postal_code: "48411",
    zip_name: "atlas, mi",
  },
  {
    postal_code: "32570",
    zip_name: "milton, fl",
  },
  {
    postal_code: "73016",
    zip_name: "cashion, ok",
  },
  {
    postal_code: "42085",
    zip_name: "water valley, ky",
  },
  {
    postal_code: "49322",
    zip_name: "coral, mi",
  },
  {
    postal_code: "49512",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "73538",
    zip_name: "elgin, ok",
  },
  {
    postal_code: "29543",
    zip_name: "fork, sc",
  },
  {
    postal_code: "58647",
    zip_name: "new england, nd",
  },
  {
    postal_code: "38663",
    zip_name: "ripley, ms",
  },
  {
    postal_code: "47108",
    zip_name: "campbellsburg, in",
  },
  {
    postal_code: "66423",
    zip_name: "eskridge, ks",
  },
  {
    postal_code: "25024",
    zip_name: "bloomingrose, wv",
  },
  {
    postal_code: "79353",
    zip_name: "pep, tx",
  },
  {
    postal_code: "57381",
    zip_name: "wessington, sd",
  },
  {
    postal_code: "41835",
    zip_name: "mc roberts, ky",
  },
  {
    postal_code: "67029",
    zip_name: "coldwater, ks",
  },
  {
    postal_code: "56565",
    zip_name: "nashua, mn",
  },
  {
    postal_code: "61917",
    zip_name: "brocton, il",
  },
  {
    postal_code: "68315",
    zip_name: "belvidere, ne",
  },
  {
    postal_code: "45352",
    zip_name: "palestine, oh",
  },
  {
    postal_code: "67482",
    zip_name: "talmage, ks",
  },
  {
    postal_code: "58830",
    zip_name: "alamo, nd",
  },
  {
    postal_code: "61324",
    zip_name: "eldena, il",
  },
  {
    postal_code: "39877",
    zip_name: "parrott, ga",
  },
  {
    postal_code: "18460",
    zip_name: "south sterling, pa",
  },
  {
    postal_code: "58327",
    zip_name: "crary, nd",
  },
  {
    postal_code: "17215",
    zip_name: "burnt cabins, pa",
  },
  {
    postal_code: "57782",
    zip_name: "smithwick, sd",
  },
  {
    postal_code: "66427",
    zip_name: "frankfort, ks",
  },
  {
    postal_code: "14714",
    zip_name: "black creek, ny",
  },
  {
    postal_code: "5362",
    zip_name: "williamsville, vt",
  },
  {
    postal_code: "58249",
    zip_name: "langdon, nd",
  },
  {
    postal_code: "50556",
    zip_name: "ledyard, ia",
  },
  {
    postal_code: "62434",
    zip_name: "ingraham, il",
  },
  {
    postal_code: "15847",
    zip_name: "knox dale, pa",
  },
  {
    postal_code: "93219",
    zip_name: "earlimart, ca",
  },
  {
    postal_code: "64456",
    zip_name: "grant city, mo",
  },
  {
    postal_code: "59450",
    zip_name: "highwood, mt",
  },
  {
    postal_code: "51365",
    zip_name: "wallingford, ia",
  },
  {
    postal_code: "39078",
    zip_name: "georgetown, ms",
  },
  {
    postal_code: "41567",
    zip_name: "stone, ky",
  },
  {
    postal_code: "68019",
    zip_name: "craig, ne",
  },
  {
    postal_code: "89426",
    zip_name: "paradise valley, nv",
  },
  {
    postal_code: "62815",
    zip_name: "bone gap, il",
  },
  {
    postal_code: "56121",
    zip_name: "ceylon, mn",
  },
  {
    postal_code: "25564",
    zip_name: "sod, wv",
  },
  {
    postal_code: "62878",
    zip_name: "rinard, il",
  },
  {
    postal_code: "68822",
    zip_name: "broken bow, ne",
  },
  {
    postal_code: "15560",
    zip_name: "shanksville, pa",
  },
  {
    postal_code: "58642",
    zip_name: "manning, nd",
  },
  {
    postal_code: "63563",
    zip_name: "rutledge, mo",
  },
  {
    postal_code: "42634",
    zip_name: "parkers lake, ky",
  },
  {
    postal_code: "62639",
    zip_name: "frederick, il",
  },
  {
    postal_code: "78614",
    zip_name: "cost, tx",
  },
  {
    postal_code: "29065",
    zip_name: "jenkinsville, sc",
  },
  {
    postal_code: "62624",
    zip_name: "browning, il",
  },
  {
    postal_code: "61311",
    zip_name: "ancona, il",
  },
  {
    postal_code: "15539",
    zip_name: "fishertown, pa",
  },
  {
    postal_code: "75470",
    zip_name: "petty, tx",
  },
  {
    postal_code: "19554",
    zip_name: "shartlesville, pa",
  },
  {
    postal_code: "25811",
    zip_name: "amigo, wv",
  },
  {
    postal_code: "26210",
    zip_name: "adrian, wv",
  },
  {
    postal_code: "47166",
    zip_name: "ramsey, in",
  },
  {
    postal_code: "54909",
    zip_name: "almond, wi",
  },
  {
    postal_code: "72951",
    zip_name: "ratcliff, ar",
  },
  {
    postal_code: "58030",
    zip_name: "fairmount, nd",
  },
  {
    postal_code: "39335",
    zip_name: "lauderdale, ms",
  },
  {
    postal_code: "39656",
    zip_name: "oak vale, ms",
  },
  {
    postal_code: "49461",
    zip_name: "whitehall, mi",
  },
  {
    postal_code: "52317",
    zip_name: "north liberty, ia",
  },
  {
    postal_code: "68771",
    zip_name: "randolph, ne",
  },
  {
    postal_code: "52324",
    zip_name: "palo, ia",
  },
  {
    postal_code: "76932",
    zip_name: "big lake, tx",
  },
  {
    postal_code: "52245",
    zip_name: "iowa city, ia",
  },
  {
    postal_code: "12725",
    zip_name: "claryville, ny",
  },
  {
    postal_code: "7086",
    zip_name: "weehawken, nj",
  },
  {
    postal_code: "75555",
    zip_name: "bivins, tx",
  },
  {
    postal_code: "36321",
    zip_name: "cowarts, al",
  },
  {
    postal_code: "99540",
    zip_name: "indian, ak",
  },
  {
    postal_code: "25259",
    zip_name: "looneyville, wv",
  },
  {
    postal_code: "80747",
    zip_name: "peetz, co",
  },
  {
    postal_code: "87521",
    zip_name: "chamisal, nm",
  },
  {
    postal_code: "41838",
    zip_name: "millstone, ky",
  },
  {
    postal_code: "16694",
    zip_name: "wood, pa",
  },
  {
    postal_code: "79033",
    zip_name: "farnsworth, tx",
  },
  {
    postal_code: "31756",
    zip_name: "hartsfield, ga",
  },
  {
    postal_code: "84729",
    zip_name: "glendale, ut",
  },
  {
    postal_code: "72587",
    zip_name: "wiseman, ar",
  },
  {
    postal_code: "64676",
    zip_name: "rothville, mo",
  },
  {
    postal_code: "51654",
    zip_name: "thurman, ia",
  },
  {
    postal_code: "52307",
    zip_name: "middle amana, ia",
  },
  {
    postal_code: "71473",
    zip_name: "sikes, la",
  },
  {
    postal_code: "56185",
    zip_name: "wilmont, mn",
  },
  {
    postal_code: "5034",
    zip_name: "bridgewater, vt",
  },
  {
    postal_code: "55150",
    zip_name: "mendota, mn",
  },
  {
    postal_code: "41052",
    zip_name: "jonesville, ky",
  },
  {
    postal_code: "73938",
    zip_name: "forgan, ok",
  },
  {
    postal_code: "47584",
    zip_name: "spurgeon, in",
  },
  {
    postal_code: "45770",
    zip_name: "portland, oh",
  },
  {
    postal_code: "21607",
    zip_name: "barclay, md",
  },
  {
    postal_code: "48630",
    zip_name: "houghton lake heights, mi",
  },
  {
    postal_code: "35951",
    zip_name: "albertville, al",
  },
  {
    postal_code: "36555",
    zip_name: "magnolia springs, al",
  },
  {
    postal_code: "93634",
    zip_name: "lakeshore, ca",
  },
  {
    postal_code: "48829",
    zip_name: "edmore, mi",
  },
  {
    postal_code: "68453",
    zip_name: "tobias, ne",
  },
  {
    postal_code: "86431",
    zip_name: "chloride, az",
  },
  {
    postal_code: "65444",
    zip_name: "bucyrus, mo",
  },
  {
    postal_code: "38849",
    zip_name: "guntown, ms",
  },
  {
    postal_code: "41519",
    zip_name: "canada, ky",
  },
  {
    postal_code: "62451",
    zip_name: "palestine, il",
  },
  {
    postal_code: "50277",
    zip_name: "yale, ia",
  },
  {
    postal_code: "50598",
    zip_name: "whittemore, ia",
  },
  {
    postal_code: "59327",
    zip_name: "forsyth, mt",
  },
  {
    postal_code: "20632",
    zip_name: "faulkner, md",
  },
  {
    postal_code: "39346",
    zip_name: "noxapater, ms",
  },
  {
    postal_code: "20687",
    zip_name: "scotland, md",
  },
  {
    postal_code: "7524",
    zip_name: "paterson, nj",
  },
  {
    postal_code: "16313",
    zip_name: "clarendon, pa",
  },
  {
    postal_code: "38545",
    zip_name: "bloomington springs, tn",
  },
  {
    postal_code: "59745",
    zip_name: "norris, mt",
  },
  {
    postal_code: "98047",
    zip_name: "pacific, wa",
  },
  {
    postal_code: "48315",
    zip_name: "utica, mi",
  },
  {
    postal_code: "71465",
    zip_name: "olla, la",
  },
  {
    postal_code: "21829",
    zip_name: "girdletree, md",
  },
  {
    postal_code: "51556",
    zip_name: "modale, ia",
  },
  {
    postal_code: "63956",
    zip_name: "patterson, mo",
  },
  {
    postal_code: "50560",
    zip_name: "lu verne, ia",
  },
  {
    postal_code: "79243",
    zip_name: "mcadoo, tx",
  },
  {
    postal_code: "95141",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "98240",
    zip_name: "custer, wa",
  },
  {
    postal_code: "59432",
    zip_name: "dupuyer, mt",
  },
  {
    postal_code: "98638",
    zip_name: "naselle, wa",
  },
  {
    postal_code: "15625",
    zip_name: "darragh, pa",
  },
  {
    postal_code: "61410",
    zip_name: "abingdon, il",
  },
  {
    postal_code: "36703",
    zip_name: "selma, al",
  },
  {
    postal_code: "53404",
    zip_name: "racine, wi",
  },
  {
    postal_code: "35546",
    zip_name: "berry, al",
  },
  {
    postal_code: "80705",
    zip_name: "log lane village, co",
  },
  {
    postal_code: "98843",
    zip_name: "orondo, wa",
  },
  {
    postal_code: "98326",
    zip_name: "clallam bay, wa",
  },
  {
    postal_code: "49724",
    zip_name: "dafter, mi",
  },
  {
    postal_code: "79085",
    zip_name: "summerfield, tx",
  },
  {
    postal_code: "15921",
    zip_name: "beaverdale, pa",
  },
  {
    postal_code: "65284",
    zip_name: "sturgeon, mo",
  },
  {
    postal_code: "68376",
    zip_name: "humboldt, ne",
  },
  {
    postal_code: "29718",
    zip_name: "jefferson, sc",
  },
  {
    postal_code: "78501",
    zip_name: "mcallen, tx",
  },
  {
    postal_code: "67745",
    zip_name: "mc donald, ks",
  },
  {
    postal_code: "57255",
    zip_name: "new effington, sd",
  },
  {
    postal_code: "79834",
    zip_name: "big bend national park, tx",
  },
  {
    postal_code: "35089",
    zip_name: "kellyton, al",
  },
  {
    postal_code: "37150",
    zip_name: "red boiling springs, tn",
  },
  {
    postal_code: "65283",
    zip_name: "stoutsville, mo",
  },
  {
    postal_code: "47552",
    zip_name: "lincoln city, in",
  },
  {
    postal_code: "83314",
    zip_name: "bliss, id",
  },
  {
    postal_code: "93645",
    zip_name: "o neals, ca",
  },
  {
    postal_code: "95387",
    zip_name: "westley, ca",
  },
  {
    postal_code: "56157",
    zip_name: "lynd, mn",
  },
  {
    postal_code: "24486",
    zip_name: "weyers cave, va",
  },
  {
    postal_code: "10507",
    zip_name: "bedford hills, ny",
  },
  {
    postal_code: "78946",
    zip_name: "ledbetter, tx",
  },
  {
    postal_code: "28605",
    zip_name: "blowing rock, nc",
  },
  {
    postal_code: "36035",
    zip_name: "goshen, al",
  },
  {
    postal_code: "71407",
    zip_name: "bentley, la",
  },
  {
    postal_code: "16935",
    zip_name: "middlebury center, pa",
  },
  {
    postal_code: "73084",
    zip_name: "spencer, ok",
  },
  {
    postal_code: "31220",
    zip_name: "macon, ga",
  },
  {
    postal_code: "74966",
    zip_name: "wister, ok",
  },
  {
    postal_code: "71644",
    zip_name: "grady, ar",
  },
  {
    postal_code: "50484",
    zip_name: "woden, ia",
  },
  {
    postal_code: "37327",
    zip_name: "dunlap, tn",
  },
  {
    postal_code: "38341",
    zip_name: "holladay, tn",
  },
  {
    postal_code: "76941",
    zip_name: "mertzon, tx",
  },
  {
    postal_code: "51345",
    zip_name: "harris, ia",
  },
  {
    postal_code: "88056",
    zip_name: "rodeo, nm",
  },
  {
    postal_code: "73090",
    zip_name: "union city, ok",
  },
  {
    postal_code: "18331",
    zip_name: "gilbert, pa",
  },
  {
    postal_code: "4628",
    zip_name: "dennysville, me",
  },
  {
    postal_code: "58027",
    zip_name: "enderlin, nd",
  },
  {
    postal_code: "10983",
    zip_name: "tappan, ny",
  },
  {
    postal_code: "46526",
    zip_name: "goshen, in",
  },
  {
    postal_code: "52219",
    zip_name: "prairieburg, ia",
  },
  {
    postal_code: "83536",
    zip_name: "kamiah, id",
  },
  {
    postal_code: "24318",
    zip_name: "ceres, va",
  },
  {
    postal_code: "98244",
    zip_name: "deming, wa",
  },
  {
    postal_code: "22716",
    zip_name: "castleton, va",
  },
  {
    postal_code: "52039",
    zip_name: "durango, ia",
  },
  {
    postal_code: "79528",
    zip_name: "jayton, tx",
  },
  {
    postal_code: "98056",
    zip_name: "renton, wa",
  },
  {
    postal_code: "97525",
    zip_name: "gold hill, or",
  },
  {
    postal_code: "97841",
    zip_name: "imbler, or",
  },
  {
    postal_code: "47946",
    zip_name: "francesville, in",
  },
  {
    postal_code: "46733",
    zip_name: "decatur, in",
  },
  {
    postal_code: "74045",
    zip_name: "maramec, ok",
  },
  {
    postal_code: "50544",
    zip_name: "harcourt, ia",
  },
  {
    postal_code: "56518",
    zip_name: "bluffton, mn",
  },
  {
    postal_code: "25083",
    zip_name: "gallagher, wv",
  },
  {
    postal_code: "65727",
    zip_name: "polk, mo",
  },
  {
    postal_code: "15440",
    zip_name: "gibbon glade, pa",
  },
  {
    postal_code: "62345",
    zip_name: "kinderhook, il",
  },
  {
    postal_code: "73720",
    zip_name: "bison, ok",
  },
  {
    postal_code: "42124",
    zip_name: "beaumont, ky",
  },
  {
    postal_code: "66933",
    zip_name: "barnes, ks",
  },
  {
    postal_code: "68664",
    zip_name: "snyder, ne",
  },
  {
    postal_code: "68764",
    zip_name: "orchard, ne",
  },
  {
    postal_code: "57633",
    zip_name: "isabel, sd",
  },
  {
    postal_code: "59430",
    zip_name: "denton, mt",
  },
  {
    postal_code: "59452",
    zip_name: "hobson, mt",
  },
  {
    postal_code: "78116",
    zip_name: "gillett, tx",
  },
  {
    postal_code: "41804",
    zip_name: "blackey, ky",
  },
  {
    postal_code: "39088",
    zip_name: "holly bluff, ms",
  },
  {
    postal_code: "76389",
    zip_name: "windthorst, tx",
  },
  {
    postal_code: "47110",
    zip_name: "central, in",
  },
  {
    postal_code: "36041",
    zip_name: "highland home, al",
  },
  {
    postal_code: "57522",
    zip_name: "blunt, sd",
  },
  {
    postal_code: "14441",
    zip_name: "dresden, ny",
  },
  {
    postal_code: "58490",
    zip_name: "verona, nd",
  },
  {
    postal_code: "62963",
    zip_name: "mound city, il",
  },
  {
    postal_code: "69030",
    zip_name: "haigler, ne",
  },
  {
    postal_code: "58009",
    zip_name: "blanchard, nd",
  },
  {
    postal_code: "73461",
    zip_name: "wapanucka, ok",
  },
  {
    postal_code: "21538",
    zip_name: "kitzmiller, md",
  },
  {
    postal_code: "68774",
    zip_name: "saint helena, ne",
  },
  {
    postal_code: "65017",
    zip_name: "brumley, mo",
  },
  {
    postal_code: "24827",
    zip_name: "cyclone, wv",
  },
  {
    postal_code: "59762",
    zip_name: "wise river, mt",
  },
  {
    postal_code: "77994",
    zip_name: "westhoff, tx",
  },
  {
    postal_code: "60111",
    zip_name: "clare, il",
  },
  {
    postal_code: "39097",
    zip_name: "louise, ms",
  },
  {
    postal_code: "72026",
    zip_name: "casscoe, ar",
  },
  {
    postal_code: "74534",
    zip_name: "centrahoma, ok",
  },
  {
    postal_code: "29743",
    zip_name: "smyrna, sc",
  },
  {
    postal_code: "54875",
    zip_name: "springbrook, wi",
  },
  {
    postal_code: "45041",
    zip_name: "miamitown, oh",
  },
  {
    postal_code: "67556",
    zip_name: "mc cracken, ks",
  },
  {
    postal_code: "29941",
    zip_name: "sheldon, sc",
  },
  {
    postal_code: "70537",
    zip_name: "evangeline, la",
  },
  {
    postal_code: "68790",
    zip_name: "winside, ne",
  },
  {
    postal_code: "59468",
    zip_name: "power, mt",
  },
  {
    postal_code: "5850",
    zip_name: "lyndon center, vt",
  },
  {
    postal_code: "78590",
    zip_name: "san perlita, tx",
  },
  {
    postal_code: "70552",
    zip_name: "loreauville, la",
  },
  {
    postal_code: "84525",
    zip_name: "green river, ut",
  },
  {
    postal_code: "57642",
    zip_name: "mc laughlin, sd",
  },
  {
    postal_code: "16613",
    zip_name: "ashville, pa",
  },
  {
    postal_code: "15619",
    zip_name: "bovard, pa",
  },
  {
    postal_code: "72330",
    zip_name: "dyess, ar",
  },
  {
    postal_code: "21052",
    zip_name: "fort howard, md",
  },
  {
    postal_code: "58756",
    zip_name: "makoti, nd",
  },
  {
    postal_code: "58380",
    zip_name: "tolna, nd",
  },
  {
    postal_code: "68783",
    zip_name: "verdigre, ne",
  },
  {
    postal_code: "61435",
    zip_name: "gerlaw, il",
  },
  {
    postal_code: "31210",
    zip_name: "macon, ga",
  },
  {
    postal_code: "54204",
    zip_name: "brussels, wi",
  },
  {
    postal_code: "31523",
    zip_name: "brunswick, ga",
  },
  {
    postal_code: "35572",
    zip_name: "houston, al",
  },
  {
    postal_code: "39330",
    zip_name: "enterprise, ms",
  },
  {
    postal_code: "54229",
    zip_name: "new franken, wi",
  },
  {
    postal_code: "10538",
    zip_name: "larchmont, ny",
  },
  {
    postal_code: "31532",
    zip_name: "denton, ga",
  },
  {
    postal_code: "98445",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "30710",
    zip_name: "cohutta, ga",
  },
  {
    postal_code: "72372",
    zip_name: "palestine, ar",
  },
  {
    postal_code: "30222",
    zip_name: "greenville, ga",
  },
  {
    postal_code: "24086",
    zip_name: "eggleston, va",
  },
  {
    postal_code: "31017",
    zip_name: "danville, ga",
  },
  {
    postal_code: "4673",
    zip_name: "sargentville, me",
  },
  {
    postal_code: "58032",
    zip_name: "forman, nd",
  },
  {
    postal_code: "31527",
    zip_name: "jekyll island, ga",
  },
  {
    postal_code: "84023",
    zip_name: "dutch john, ut",
  },
  {
    postal_code: "31836",
    zip_name: "woodland, ga",
  },
  {
    postal_code: "45650",
    zip_name: "lynx, oh",
  },
  {
    postal_code: "70648",
    zip_name: "kinder, la",
  },
  {
    postal_code: "87750",
    zip_name: "valmora, nm",
  },
  {
    postal_code: "36267",
    zip_name: "millerville, al",
  },
  {
    postal_code: "88120",
    zip_name: "grady, nm",
  },
  {
    postal_code: "52555",
    zip_name: "exline, ia",
  },
  {
    postal_code: "98584",
    zip_name: "shelton, wa",
  },
  {
    postal_code: "15337",
    zip_name: "graysville, pa",
  },
  {
    postal_code: "15929",
    zip_name: "dilltown, pa",
  },
  {
    postal_code: "66408",
    zip_name: "bern, ks",
  },
  {
    postal_code: "57233",
    zip_name: "erwin, sd",
  },
  {
    postal_code: "50559",
    zip_name: "lone rock, ia",
  },
  {
    postal_code: "62843",
    zip_name: "golden gate, il",
  },
  {
    postal_code: "36759",
    zip_name: "marion junction, al",
  },
  {
    postal_code: "59531",
    zip_name: "joplin, mt",
  },
  {
    postal_code: "18241",
    zip_name: "nuremberg, pa",
  },
  {
    postal_code: "46977",
    zip_name: "rockfield, in",
  },
  {
    postal_code: "68743",
    zip_name: "jackson, ne",
  },
  {
    postal_code: "76864",
    zip_name: "mullin, tx",
  },
  {
    postal_code: "31568",
    zip_name: "white oak, ga",
  },
  {
    postal_code: "67951",
    zip_name: "hugoton, ks",
  },
  {
    postal_code: "51062",
    zip_name: "westfield, ia",
  },
  {
    postal_code: "24432",
    zip_name: "deerfield, va",
  },
  {
    postal_code: "59824",
    zip_name: "charlo, mt",
  },
  {
    postal_code: "31009",
    zip_name: "cadwell, ga",
  },
  {
    postal_code: "67347",
    zip_name: "havana, ks",
  },
  {
    postal_code: "71846",
    zip_name: "lockesburg, ar",
  },
  {
    postal_code: "58575",
    zip_name: "turtle lake, nd",
  },
  {
    postal_code: "36038",
    zip_name: "gantt, al",
  },
  {
    postal_code: "4674",
    zip_name: "seal cove, me",
  },
  {
    postal_code: "53225",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "57706",
    zip_name: "ellsworth afb, sd",
  },
  {
    postal_code: "23837",
    zip_name: "courtland, va",
  },
  {
    postal_code: "38680",
    zip_name: "walls, ms",
  },
  {
    postal_code: "38469",
    zip_name: "loretto, tn",
  },
  {
    postal_code: "58382",
    zip_name: "webster, nd",
  },
  {
    postal_code: "84723",
    zip_name: "circleville, ut",
  },
  {
    postal_code: "49015",
    zip_name: "battle creek, mi",
  },
  {
    postal_code: "96087",
    zip_name: "shasta, ca",
  },
  {
    postal_code: "72568",
    zip_name: "pleasant plains, ar",
  },
  {
    postal_code: "42036",
    zip_name: "dexter, ky",
  },
  {
    postal_code: "71749",
    zip_name: "junction city, ar",
  },
  {
    postal_code: "97489",
    zip_name: "walterville, or",
  },
  {
    postal_code: "61810",
    zip_name: "allerton, il",
  },
  {
    postal_code: "84760",
    zip_name: "paragonah, ut",
  },
  {
    postal_code: "17093",
    zip_name: "summerdale, pa",
  },
  {
    postal_code: "81656",
    zip_name: "woody creek, co",
  },
  {
    postal_code: "23323",
    zip_name: "chesapeake, va",
  },
  {
    postal_code: "93529",
    zip_name: "june lake, ca",
  },
  {
    postal_code: "60963",
    zip_name: "rossville, il",
  },
  {
    postal_code: "71766",
    zip_name: "thornton, ar",
  },
  {
    postal_code: "49680",
    zip_name: "south boardman, mi",
  },
  {
    postal_code: "67660",
    zip_name: "pfeifer, ks",
  },
  {
    postal_code: "38627",
    zip_name: "etta, ms",
  },
  {
    postal_code: "16661",
    zip_name: "madera, pa",
  },
  {
    postal_code: "87729",
    zip_name: "miami, nm",
  },
  {
    postal_code: "24237",
    zip_name: "dante, va",
  },
  {
    postal_code: "79086",
    zip_name: "sunray, tx",
  },
  {
    postal_code: "97416",
    zip_name: "camas valley, or",
  },
  {
    postal_code: "23963",
    zip_name: "red house, va",
  },
  {
    postal_code: "13687",
    zip_name: "south colton, ny",
  },
  {
    postal_code: "78560",
    zip_name: "la joya, tx",
  },
  {
    postal_code: "82322",
    zip_name: "bairoil, wy",
  },
  {
    postal_code: "4853",
    zip_name: "north haven, me",
  },
  {
    postal_code: "25132",
    zip_name: "mammoth, wv",
  },
  {
    postal_code: "38833",
    zip_name: "burnsville, ms",
  },
  {
    postal_code: "97885",
    zip_name: "wallowa, or",
  },
  {
    postal_code: "66093",
    zip_name: "westphalia, ks",
  },
  {
    postal_code: "63901",
    zip_name: "poplar bluff, mo",
  },
  {
    postal_code: "48881",
    zip_name: "saranac, mi",
  },
  {
    postal_code: "73122",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "35950",
    zip_name: "albertville, al",
  },
  {
    postal_code: "59020",
    zip_name: "cooke city, mt",
  },
  {
    postal_code: "54462",
    zip_name: "pearson, wi",
  },
  {
    postal_code: "26761",
    zip_name: "shanks, wv",
  },
  {
    postal_code: "52651",
    zip_name: "stockport, ia",
  },
  {
    postal_code: "62897",
    zip_name: "whittington, il",
  },
  {
    postal_code: "98257",
    zip_name: "la conner, wa",
  },
  {
    postal_code: "38543",
    zip_name: "alpine, tn",
  },
  {
    postal_code: "57043",
    zip_name: "marion, sd",
  },
  {
    postal_code: "30711",
    zip_name: "crandall, ga",
  },
  {
    postal_code: "36375",
    zip_name: "slocomb, al",
  },
  {
    postal_code: "38468",
    zip_name: "leoma, tn",
  },
  {
    postal_code: "45147",
    zip_name: "miamiville, oh",
  },
  {
    postal_code: "12574",
    zip_name: "rhinecliff, ny",
  },
  {
    postal_code: "12837",
    zip_name: "hampton, ny",
  },
  {
    postal_code: "57725",
    zip_name: "caputa, sd",
  },
  {
    postal_code: "75939",
    zip_name: "corrigan, tx",
  },
  {
    postal_code: "57073",
    zip_name: "wakonda, sd",
  },
  {
    postal_code: "97856",
    zip_name: "long creek, or",
  },
  {
    postal_code: "75974",
    zip_name: "tenaha, tx",
  },
  {
    postal_code: "74931",
    zip_name: "bunch, ok",
  },
  {
    postal_code: "38603",
    zip_name: "ashland, ms",
  },
  {
    postal_code: "64741",
    zip_name: "deerfield, mo",
  },
  {
    postal_code: "10920",
    zip_name: "congers, ny",
  },
  {
    postal_code: "15684",
    zip_name: "slickville, pa",
  },
  {
    postal_code: "85264",
    zip_name: "fort mcdowell, az",
  },
  {
    postal_code: "51647",
    zip_name: "northboro, ia",
  },
  {
    postal_code: "93255",
    zip_name: "onyx, ca",
  },
  {
    postal_code: "27818",
    zip_name: "como, nc",
  },
  {
    postal_code: "81148",
    zip_name: "romeo, co",
  },
  {
    postal_code: "98566",
    zip_name: "neilton, wa",
  },
  {
    postal_code: "52136",
    zip_name: "cresco, ia",
  },
  {
    postal_code: "52228",
    zip_name: "fairfax, ia",
  },
  {
    postal_code: "30833",
    zip_name: "wrens, ga",
  },
  {
    postal_code: "39664",
    zip_name: "smithdale, ms",
  },
  {
    postal_code: "49788",
    zip_name: "kincheloe, mi",
  },
  {
    postal_code: "21711",
    zip_name: "big pool, md",
  },
  {
    postal_code: "51338",
    zip_name: "everly, ia",
  },
  {
    postal_code: "63648",
    zip_name: "irondale, mo",
  },
  {
    postal_code: "47573",
    zip_name: "ragsdale, in",
  },
  {
    postal_code: "64433",
    zip_name: "conception, mo",
  },
  {
    postal_code: "42603",
    zip_name: "alpha, ky",
  },
  {
    postal_code: "73771",
    zip_name: "wakita, ok",
  },
  {
    postal_code: "58492",
    zip_name: "wimbledon, nd",
  },
  {
    postal_code: "51019",
    zip_name: "danbury, ia",
  },
  {
    postal_code: "67513",
    zip_name: "alexander, ks",
  },
  {
    postal_code: "17727",
    zip_name: "cedar run, pa",
  },
  {
    postal_code: "88430",
    zip_name: "nara visa, nm",
  },
  {
    postal_code: "49948",
    zip_name: "mass city, mi",
  },
  {
    postal_code: "59915",
    zip_name: "elmo, mt",
  },
  {
    postal_code: "96126",
    zip_name: "sierraville, ca",
  },
  {
    postal_code: "78872",
    zip_name: "la pryor, tx",
  },
  {
    postal_code: "73437",
    zip_name: "graham, ok",
  },
  {
    postal_code: "41721",
    zip_name: "buckhorn, ky",
  },
  {
    postal_code: "72546",
    zip_name: "ida, ar",
  },
  {
    postal_code: "73838",
    zip_name: "chester, ok",
  },
  {
    postal_code: "68350",
    zip_name: "endicott, ne",
  },
  {
    postal_code: "56111",
    zip_name: "alpha, mn",
  },
  {
    postal_code: "40840",
    zip_name: "helton, ky",
  },
  {
    postal_code: "83220",
    zip_name: "bern, id",
  },
  {
    postal_code: "74724",
    zip_name: "bethel, ok",
  },
  {
    postal_code: "56523",
    zip_name: "climax, mn",
  },
  {
    postal_code: "77616",
    zip_name: "fred, tx",
  },
  {
    postal_code: "25928",
    zip_name: "stephenson, wv",
  },
  {
    postal_code: "44809",
    zip_name: "bascom, oh",
  },
  {
    postal_code: "68717",
    zip_name: "belden, ne",
  },
  {
    postal_code: "68381",
    zip_name: "liberty, ne",
  },
  {
    postal_code: "65436",
    zip_name: "beulah, mo",
  },
  {
    postal_code: "39359",
    zip_name: "sebastopol, ms",
  },
  {
    postal_code: "97204",
    zip_name: "portland, or",
  },
  {
    postal_code: "38769",
    zip_name: "rosedale, ms",
  },
  {
    postal_code: "68875",
    zip_name: "scotia, ne",
  },
  {
    postal_code: "93249",
    zip_name: "lost hills, ca",
  },
  {
    postal_code: "51037",
    zip_name: "meriden, ia",
  },
  {
    postal_code: "95555",
    zip_name: "orick, ca",
  },
  {
    postal_code: "68063",
    zip_name: "uehling, ne",
  },
  {
    postal_code: "18636",
    zip_name: "noxen, pa",
  },
  {
    postal_code: "76372",
    zip_name: "newcastle, tx",
  },
  {
    postal_code: "93208",
    zip_name: "camp nelson, ca",
  },
  {
    postal_code: "37132",
    zip_name: "murfreesboro, tn",
  },
  {
    postal_code: "24058",
    zip_name: "belspring, va",
  },
  {
    postal_code: "31722",
    zip_name: "berlin, ga",
  },
  {
    postal_code: "2877",
    zip_name: "slocum, ri",
  },
  {
    postal_code: "50031",
    zip_name: "beaver, ia",
  },
  {
    postal_code: "64424",
    zip_name: "bethany, mo",
  },
  {
    postal_code: "98923",
    zip_name: "cowiche, wa",
  },
  {
    postal_code: "52533",
    zip_name: "batavia, ia",
  },
  {
    postal_code: "59259",
    zip_name: "richey, mt",
  },
  {
    postal_code: "63551",
    zip_name: "livonia, mo",
  },
  {
    postal_code: "65449",
    zip_name: "cook sta, mo",
  },
  {
    postal_code: "25134",
    zip_name: "miami, wv",
  },
  {
    postal_code: "68322",
    zip_name: "bruning, ne",
  },
  {
    postal_code: "18441",
    zip_name: "lenoxville, pa",
  },
  {
    postal_code: "95653",
    zip_name: "madison, ca",
  },
  {
    postal_code: "31775",
    zip_name: "omega, ga",
  },
  {
    postal_code: "41034",
    zip_name: "dover, ky",
  },
  {
    postal_code: "98619",
    zip_name: "glenwood, wa",
  },
  {
    postal_code: "99515",
    zip_name: "anchorage, ak",
  },
  {
    postal_code: "82334",
    zip_name: "sinclair, wy",
  },
  {
    postal_code: "15435",
    zip_name: "fairbank, pa",
  },
  {
    postal_code: "67212",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "17255",
    zip_name: "shade gap, pa",
  },
  {
    postal_code: "72326",
    zip_name: "colt, ar",
  },
  {
    postal_code: "71275",
    zip_name: "simsboro, la",
  },
  {
    postal_code: "46959",
    zip_name: "miami, in",
  },
  {
    postal_code: "24282",
    zip_name: "saint charles, va",
  },
  {
    postal_code: "17855",
    zip_name: "new berlin, pa",
  },
  {
    postal_code: "48080",
    zip_name: "saint clair shores, mi",
  },
  {
    postal_code: "65247",
    zip_name: "excello, mo",
  },
  {
    postal_code: "67352",
    zip_name: "longton, ks",
  },
  {
    postal_code: "83420",
    zip_name: "ashton, id",
  },
  {
    postal_code: "99676",
    zip_name: "talkeetna, ak",
  },
  {
    postal_code: "35744",
    zip_name: "dutton, al",
  },
  {
    postal_code: "45647",
    zip_name: "londonderry, oh",
  },
  {
    postal_code: "41512",
    zip_name: "ashcamp, ky",
  },
  {
    postal_code: "83823",
    zip_name: "deary, id",
  },
  {
    postal_code: "10706",
    zip_name: "hastings on hudson, ny",
  },
  {
    postal_code: "77623",
    zip_name: "high island, tx",
  },
  {
    postal_code: "52315",
    zip_name: "newhall, ia",
  },
  {
    postal_code: "80478",
    zip_name: "tabernash, co",
  },
  {
    postal_code: "24924",
    zip_name: "buckeye, wv",
  },
  {
    postal_code: "48340",
    zip_name: "pontiac, mi",
  },
  {
    postal_code: "70644",
    zip_name: "grant, la",
  },
  {
    postal_code: "24818",
    zip_name: "brenton, wv",
  },
  {
    postal_code: "24272",
    zip_name: "nora, va",
  },
  {
    postal_code: "47550",
    zip_name: "lamar, in",
  },
  {
    postal_code: "25049",
    zip_name: "comfort, wv",
  },
  {
    postal_code: "87123",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "56159",
    zip_name: "mountain lake, mn",
  },
  {
    postal_code: "39862",
    zip_name: "leary, ga",
  },
  {
    postal_code: "50645",
    zip_name: "ionia, ia",
  },
  {
    postal_code: "15064",
    zip_name: "morgan, pa",
  },
  {
    postal_code: "98846",
    zip_name: "pateros, wa",
  },
  {
    postal_code: "67135",
    zip_name: "sedgwick, ks",
  },
  {
    postal_code: "14517",
    zip_name: "nunda, ny",
  },
  {
    postal_code: "98382",
    zip_name: "sequim, wa",
  },
  {
    postal_code: "33849",
    zip_name: "kathleen, fl",
  },
  {
    postal_code: "36613",
    zip_name: "eight mile, al",
  },
  {
    postal_code: "65464",
    zip_name: "elk creek, mo",
  },
  {
    postal_code: "50658",
    zip_name: "nashua, ia",
  },
  {
    postal_code: "48626",
    zip_name: "hemlock, mi",
  },
  {
    postal_code: "4685",
    zip_name: "swans island, me",
  },
  {
    postal_code: "52402",
    zip_name: "cedar rapids, ia",
  },
  {
    postal_code: "74804",
    zip_name: "shawnee, ok",
  },
  {
    postal_code: "67063",
    zip_name: "hillsboro, ks",
  },
  {
    postal_code: "18708",
    zip_name: "shavertown, pa",
  },
  {
    postal_code: "62253",
    zip_name: "keyesport, il",
  },
  {
    postal_code: "99833",
    zip_name: "petersburg, ak",
  },
  {
    postal_code: "7440",
    zip_name: "pequannock, nj",
  },
  {
    postal_code: "65067",
    zip_name: "portland, mo",
  },
  {
    postal_code: "58530",
    zip_name: "center, nd",
  },
  {
    postal_code: "26141",
    zip_name: "creston, wv",
  },
  {
    postal_code: "35763",
    zip_name: "owens cross roads, al",
  },
  {
    postal_code: "26660",
    zip_name: "calvin, wv",
  },
  {
    postal_code: "48502",
    zip_name: "flint, mi",
  },
  {
    postal_code: "49045",
    zip_name: "decatur, mi",
  },
  {
    postal_code: "4017",
    zip_name: "chebeague island, me",
  },
  {
    postal_code: "21647",
    zip_name: "mcdaniel, md",
  },
  {
    postal_code: "51039",
    zip_name: "moville, ia",
  },
  {
    postal_code: "52588",
    zip_name: "selma, ia",
  },
  {
    postal_code: "1243",
    zip_name: "middlefield, ma",
  },
  {
    postal_code: "42713",
    zip_name: "bonnieville, ky",
  },
  {
    postal_code: "76653",
    zip_name: "kosse, tx",
  },
  {
    postal_code: "74574",
    zip_name: "tuskahoma, ok",
  },
  {
    postal_code: "76354",
    zip_name: "burkburnett, tx",
  },
  {
    postal_code: "72655",
    zip_name: "mount judea, ar",
  },
  {
    postal_code: "5769",
    zip_name: "salisbury, vt",
  },
  {
    postal_code: "56688",
    zip_name: "wirt, mn",
  },
  {
    postal_code: "64434",
    zip_name: "conception junction, mo",
  },
  {
    postal_code: "35654",
    zip_name: "russellville, al",
  },
  {
    postal_code: "39827",
    zip_name: "cairo, ga",
  },
  {
    postal_code: "52334",
    zip_name: "south amana, ia",
  },
  {
    postal_code: "97342",
    zip_name: "detroit, or",
  },
  {
    postal_code: "52767",
    zip_name: "pleasant valley, ia",
  },
  {
    postal_code: "23294",
    zip_name: "henrico, va",
  },
  {
    postal_code: "37049",
    zip_name: "cross plains, tn",
  },
  {
    postal_code: "62689",
    zip_name: "thayer, il",
  },
  {
    postal_code: "71832",
    zip_name: "de queen, ar",
  },
  {
    postal_code: "26521",
    zip_name: "blacksville, wv",
  },
  {
    postal_code: "54482",
    zip_name: "stevens point, wi",
  },
  {
    postal_code: "7751",
    zip_name: "morganville, nj",
  },
  {
    postal_code: "64756",
    zip_name: "jerico springs, mo",
  },
  {
    postal_code: "18070",
    zip_name: "palm, pa",
  },
  {
    postal_code: "67142",
    zip_name: "spivey, ks",
  },
  {
    postal_code: "87419",
    zip_name: "navajo dam, nm",
  },
  {
    postal_code: "98106",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "49111",
    zip_name: "eau claire, mi",
  },
  {
    postal_code: "38342",
    zip_name: "hollow rock, tn",
  },
  {
    postal_code: "23426",
    zip_name: "sanford, va",
  },
  {
    postal_code: "16875",
    zip_name: "spring mills, pa",
  },
  {
    postal_code: "70655",
    zip_name: "oberlin, la",
  },
  {
    postal_code: "26807",
    zip_name: "franklin, wv",
  },
  {
    postal_code: "36346",
    zip_name: "jack, al",
  },
  {
    postal_code: "78589",
    zip_name: "san juan, tx",
  },
  {
    postal_code: "48039",
    zip_name: "marine city, mi",
  },
  {
    postal_code: "25908",
    zip_name: "princewick, wv",
  },
  {
    postal_code: "22715",
    zip_name: "brightwood, va",
  },
  {
    postal_code: "67674",
    zip_name: "walker, ks",
  },
  {
    postal_code: "39662",
    zip_name: "ruth, ms",
  },
  {
    postal_code: "62539",
    zip_name: "illiopolis, il",
  },
  {
    postal_code: "98230",
    zip_name: "blaine, wa",
  },
  {
    postal_code: "11548",
    zip_name: "greenvale, ny",
  },
  {
    postal_code: "26681",
    zip_name: "nettie, wv",
  },
  {
    postal_code: "83344",
    zip_name: "murtaugh, id",
  },
  {
    postal_code: "89826",
    zip_name: "jarbidge, nv",
  },
  {
    postal_code: "48894",
    zip_name: "westphalia, mi",
  },
  {
    postal_code: "66552",
    zip_name: "whiting, ks",
  },
  {
    postal_code: "99623",
    zip_name: "wasilla, ak",
  },
  {
    postal_code: "46781",
    zip_name: "poneto, in",
  },
  {
    postal_code: "40868",
    zip_name: "stinnett, ky",
  },
  {
    postal_code: "71857",
    zip_name: "prescott, ar",
  },
  {
    postal_code: "56078",
    zip_name: "pemberton, mn",
  },
  {
    postal_code: "36561",
    zip_name: "orange beach, al",
  },
  {
    postal_code: "77582",
    zip_name: "raywood, tx",
  },
  {
    postal_code: "36453",
    zip_name: "kinston, al",
  },
  {
    postal_code: "59074",
    zip_name: "ryegate, mt",
  },
  {
    postal_code: "49060",
    zip_name: "hickory corners, mi",
  },
  {
    postal_code: "37066",
    zip_name: "gallatin, tn",
  },
  {
    postal_code: "72156",
    zip_name: "solgohachia, ar",
  },
  {
    postal_code: "63530",
    zip_name: "atlanta, mo",
  },
  {
    postal_code: "29145",
    zip_name: "silverstreet, sc",
  },
  {
    postal_code: "66527",
    zip_name: "powhattan, ks",
  },
  {
    postal_code: "25047",
    zip_name: "clothier, wv",
  },
  {
    postal_code: "71340",
    zip_name: "harrisonburg, la",
  },
  {
    postal_code: "67054",
    zip_name: "greensburg, ks",
  },
  {
    postal_code: "95585",
    zip_name: "leggett, ca",
  },
  {
    postal_code: "82221",
    zip_name: "lagrange, wy",
  },
  {
    postal_code: "36773",
    zip_name: "safford, al",
  },
  {
    postal_code: "25051",
    zip_name: "costa, wv",
  },
  {
    postal_code: "68759",
    zip_name: "newport, ne",
  },
  {
    postal_code: "16645",
    zip_name: "glen hope, pa",
  },
  {
    postal_code: "41612",
    zip_name: "bypro, ky",
  },
  {
    postal_code: "84080",
    zip_name: "vernon, ut",
  },
  {
    postal_code: "72412",
    zip_name: "beech grove, ar",
  },
  {
    postal_code: "37819",
    zip_name: "newcomb, tn",
  },
  {
    postal_code: "64780",
    zip_name: "rockville, mo",
  },
  {
    postal_code: "98552",
    zip_name: "humptulips, wa",
  },
  {
    postal_code: "88416",
    zip_name: "conchas dam, nm",
  },
  {
    postal_code: "5084",
    zip_name: "west hartford, vt",
  },
  {
    postal_code: "35775",
    zip_name: "valhermoso springs, al",
  },
  {
    postal_code: "83643",
    zip_name: "mesa, id",
  },
  {
    postal_code: "71971",
    zip_name: "umpire, ar",
  },
  {
    postal_code: "81429",
    zip_name: "paradox, co",
  },
  {
    postal_code: "63747",
    zip_name: "friedheim, mo",
  },
  {
    postal_code: "71658",
    zip_name: "montrose, ar",
  },
  {
    postal_code: "64496",
    zip_name: "watson, mo",
  },
  {
    postal_code: "68018",
    zip_name: "colon, ne",
  },
  {
    postal_code: "63939",
    zip_name: "fairdealing, mo",
  },
  {
    postal_code: "84636",
    zip_name: "holden, ut",
  },
  {
    postal_code: "26448",
    zip_name: "wallace, wv",
  },
  {
    postal_code: "56216",
    zip_name: "blomkest, mn",
  },
  {
    postal_code: "61775",
    zip_name: "strawn, il",
  },
  {
    postal_code: "99576",
    zip_name: "dillingham, ak",
  },
  {
    postal_code: "38913",
    zip_name: "banner, ms",
  },
  {
    postal_code: "82838",
    zip_name: "parkman, wy",
  },
  {
    postal_code: "16861",
    zip_name: "new millport, pa",
  },
  {
    postal_code: "45698",
    zip_name: "zaleski, oh",
  },
  {
    postal_code: "72523",
    zip_name: "concord, ar",
  },
  {
    postal_code: "15489",
    zip_name: "west leisenring, pa",
  },
  {
    postal_code: "62518",
    zip_name: "chestnut, il",
  },
  {
    postal_code: "69046",
    zip_name: "wilsonville, ne",
  },
  {
    postal_code: "26269",
    zip_name: "hambleton, wv",
  },
  {
    postal_code: "97905",
    zip_name: "durkee, or",
  },
  {
    postal_code: "47585",
    zip_name: "stendal, in",
  },
  {
    postal_code: "46930",
    zip_name: "fowlerton, in",
  },
  {
    postal_code: "62355",
    zip_name: "nebo, il",
  },
  {
    postal_code: "18950",
    zip_name: "point pleasant, pa",
  },
  {
    postal_code: "62413",
    zip_name: "annapolis, il",
  },
  {
    postal_code: "24884",
    zip_name: "squire, wv",
  },
  {
    postal_code: "26611",
    zip_name: "cedarville, wv",
  },
  {
    postal_code: "73716",
    zip_name: "aline, ok",
  },
  {
    postal_code: "77967",
    zip_name: "hochheim, tx",
  },
  {
    postal_code: "50420",
    zip_name: "alexander, ia",
  },
  {
    postal_code: "13777",
    zip_name: "glen aubrey, ny",
  },
  {
    postal_code: "72572",
    zip_name: "saffell, ar",
  },
  {
    postal_code: "61730",
    zip_name: "cooksville, il",
  },
  {
    postal_code: "72429",
    zip_name: "fisher, ar",
  },
  {
    postal_code: "58105",
    zip_name: "fargo, nd",
  },
  {
    postal_code: "58844",
    zip_name: "fortuna, nd",
  },
  {
    postal_code: "71367",
    zip_name: "saint landry, la",
  },
  {
    postal_code: "98834",
    zip_name: "methow, wa",
  },
  {
    postal_code: "23302",
    zip_name: "assawoman, va",
  },
  {
    postal_code: "83650",
    zip_name: "murphy, id",
  },
  {
    postal_code: "13655",
    zip_name: "hogansburg, ny",
  },
  {
    postal_code: "79789",
    zip_name: "wink, tx",
  },
  {
    postal_code: "38924",
    zip_name: "cruger, ms",
  },
  {
    postal_code: "76887",
    zip_name: "voca, tx",
  },
  {
    postal_code: "65077",
    zip_name: "steedman, mo",
  },
  {
    postal_code: "29564",
    zip_name: "lane, sc",
  },
  {
    postal_code: "42409",
    zip_name: "dixon, ky",
  },
  {
    postal_code: "81089",
    zip_name: "walsenburg, co",
  },
  {
    postal_code: "50605",
    zip_name: "aredale, ia",
  },
  {
    postal_code: "24622",
    zip_name: "jewell ridge, va",
  },
  {
    postal_code: "76643",
    zip_name: "hewitt, tx",
  },
  {
    postal_code: "72824",
    zip_name: "belleville, ar",
  },
  {
    postal_code: "87013",
    zip_name: "cuba, nm",
  },
  {
    postal_code: "30816",
    zip_name: "keysville, ga",
  },
  {
    postal_code: "49235",
    zip_name: "clayton, mi",
  },
  {
    postal_code: "4490",
    zip_name: "topsfield, me",
  },
  {
    postal_code: "35441",
    zip_name: "akron, al",
  },
  {
    postal_code: "99152",
    zip_name: "metaline, wa",
  },
  {
    postal_code: "41271",
    zip_name: "williamsport, ky",
  },
  {
    postal_code: "98377",
    zip_name: "randle, wa",
  },
  {
    postal_code: "49330",
    zip_name: "kent city, mi",
  },
  {
    postal_code: "54489",
    zip_name: "vesper, wi",
  },
  {
    postal_code: "59085",
    zip_name: "two dot, mt",
  },
  {
    postal_code: "49130",
    zip_name: "union, mi",
  },
  {
    postal_code: "50444",
    zip_name: "hanlontown, ia",
  },
  {
    postal_code: "74735",
    zip_name: "fort towson, ok",
  },
  {
    postal_code: "63462",
    zip_name: "perry, mo",
  },
  {
    postal_code: "38753",
    zip_name: "inverness, ms",
  },
  {
    postal_code: "99603",
    zip_name: "homer, ak",
  },
  {
    postal_code: "41555",
    zip_name: "pinsonfork, ky",
  },
  {
    postal_code: "21714",
    zip_name: "braddock heights, md",
  },
  {
    postal_code: "67515",
    zip_name: "arnold, ks",
  },
  {
    postal_code: "73764",
    zip_name: "omega, ok",
  },
  {
    postal_code: "22433",
    zip_name: "burr hill, va",
  },
  {
    postal_code: "81030",
    zip_name: "cheraw, co",
  },
  {
    postal_code: "78007",
    zip_name: "calliham, tx",
  },
  {
    postal_code: "40052",
    zip_name: "new hope, ky",
  },
  {
    postal_code: "48874",
    zip_name: "pompeii, mi",
  },
  {
    postal_code: "50027",
    zip_name: "barnes city, ia",
  },
  {
    postal_code: "71067",
    zip_name: "princeton, la",
  },
  {
    postal_code: "62353",
    zip_name: "mount sterling, il",
  },
  {
    postal_code: "36545",
    zip_name: "jackson, al",
  },
  {
    postal_code: "14008",
    zip_name: "appleton, ny",
  },
  {
    postal_code: "50636",
    zip_name: "greene, ia",
  },
  {
    postal_code: "45652",
    zip_name: "mc dermott, oh",
  },
  {
    postal_code: "27829",
    zip_name: "fountain, nc",
  },
  {
    postal_code: "50660",
    zip_name: "new hartford, ia",
  },
  {
    postal_code: "32683",
    zip_name: "otter creek, fl",
  },
  {
    postal_code: "59436",
    zip_name: "fairfield, mt",
  },
  {
    postal_code: "12482",
    zip_name: "south cairo, ny",
  },
  {
    postal_code: "25984",
    zip_name: "rupert, wv",
  },
  {
    postal_code: "88351",
    zip_name: "tinnie, nm",
  },
  {
    postal_code: "30531",
    zip_name: "cornelia, ga",
  },
  {
    postal_code: "71045",
    zip_name: "jamestown, la",
  },
  {
    postal_code: "71665",
    zip_name: "rison, ar",
  },
  {
    postal_code: "75855",
    zip_name: "oakwood, tx",
  },
  {
    postal_code: "78340",
    zip_name: "bayside, tx",
  },
  {
    postal_code: "35980",
    zip_name: "horton, al",
  },
  {
    postal_code: "61854",
    zip_name: "mansfield, il",
  },
  {
    postal_code: "76471",
    zip_name: "rising star, tx",
  },
  {
    postal_code: "74546",
    zip_name: "haileyville, ok",
  },
  {
    postal_code: "46057",
    zip_name: "michigantown, in",
  },
  {
    postal_code: "31707",
    zip_name: "albany, ga",
  },
  {
    postal_code: "31823",
    zip_name: "pine mountain valley, ga",
  },
  {
    postal_code: "57401",
    zip_name: "aberdeen, sd",
  },
  {
    postal_code: "50456",
    zip_name: "manly, ia",
  },
  {
    postal_code: "19436",
    zip_name: "gwynedd, pa",
  },
  {
    postal_code: "7838",
    zip_name: "great meadows, nj",
  },
  {
    postal_code: "46947",
    zip_name: "logansport, in",
  },
  {
    postal_code: "6091",
    zip_name: "west hartland, ct",
  },
  {
    postal_code: "83523",
    zip_name: "craigmont, id",
  },
  {
    postal_code: "56141",
    zip_name: "iona, mn",
  },
  {
    postal_code: "73040",
    zip_name: "geary, ok",
  },
  {
    postal_code: "58760",
    zip_name: "maxbass, nd",
  },
  {
    postal_code: "46747",
    zip_name: "hudson, in",
  },
  {
    postal_code: "35647",
    zip_name: "lester, al",
  },
  {
    postal_code: "58769",
    zip_name: "palermo, nd",
  },
  {
    postal_code: "65080",
    zip_name: "tebbetts, mo",
  },
  {
    postal_code: "58231",
    zip_name: "fordville, nd",
  },
  {
    postal_code: "98358",
    zip_name: "nordland, wa",
  },
  {
    postal_code: "36784",
    zip_name: "thomasville, al",
  },
  {
    postal_code: "26852",
    zip_name: "purgitsville, wv",
  },
  {
    postal_code: "18830",
    zip_name: "little meadows, pa",
  },
  {
    postal_code: "5342",
    zip_name: "jacksonville, vt",
  },
  {
    postal_code: "15351",
    zip_name: "nemacolin, pa",
  },
  {
    postal_code: "98848",
    zip_name: "quincy, wa",
  },
  {
    postal_code: "82441",
    zip_name: "shell, wy",
  },
  {
    postal_code: "54623",
    zip_name: "coon valley, wi",
  },
  {
    postal_code: "64723",
    zip_name: "amsterdam, mo",
  },
  {
    postal_code: "53005",
    zip_name: "brookfield, wi",
  },
  {
    postal_code: "71722",
    zip_name: "bluff city, ar",
  },
  {
    postal_code: "36483",
    zip_name: "wing, al",
  },
  {
    postal_code: "81052",
    zip_name: "lamar, co",
  },
  {
    postal_code: "38464",
    zip_name: "lawrenceburg, tn",
  },
  {
    postal_code: "77519",
    zip_name: "batson, tx",
  },
  {
    postal_code: "76865",
    zip_name: "norton, tx",
  },
  {
    postal_code: "48652",
    zip_name: "rhodes, mi",
  },
  {
    postal_code: "98286",
    zip_name: "shaw island, wa",
  },
  {
    postal_code: "61956",
    zip_name: "villa grove, il",
  },
  {
    postal_code: "62711",
    zip_name: "springfield, il",
  },
  {
    postal_code: "12770",
    zip_name: "pond eddy, ny",
  },
  {
    postal_code: "45828",
    zip_name: "coldwater, oh",
  },
  {
    postal_code: "15737",
    zip_name: "elmora, pa",
  },
  {
    postal_code: "84742",
    zip_name: "kanarraville, ut",
  },
  {
    postal_code: "49618",
    zip_name: "boon, mi",
  },
  {
    postal_code: "16255",
    zip_name: "sligo, pa",
  },
  {
    postal_code: "46374",
    zip_name: "san pierre, in",
  },
  {
    postal_code: "88267",
    zip_name: "tatum, nm",
  },
  {
    postal_code: "53928",
    zip_name: "doylestown, wi",
  },
  {
    postal_code: "47804",
    zip_name: "terre haute, in",
  },
  {
    postal_code: "35130",
    zip_name: "quinton, al",
  },
  {
    postal_code: "95736",
    zip_name: "weimar, ca",
  },
  {
    postal_code: "98177",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "98602",
    zip_name: "appleton, wa",
  },
  {
    postal_code: "50588",
    zip_name: "storm lake, ia",
  },
  {
    postal_code: "61485",
    zip_name: "victoria, il",
  },
  {
    postal_code: "87326",
    zip_name: "vanderwagen, nm",
  },
  {
    postal_code: "66540",
    zip_name: "soldier, ks",
  },
  {
    postal_code: "61251",
    zip_name: "fenton, il",
  },
  {
    postal_code: "25107",
    zip_name: "hernshaw, wv",
  },
  {
    postal_code: "57435",
    zip_name: "cresbard, sd",
  },
  {
    postal_code: "17951",
    zip_name: "mar lin, pa",
  },
  {
    postal_code: "43961",
    zip_name: "stratton, oh",
  },
  {
    postal_code: "73544",
    zip_name: "gould, ok",
  },
  {
    postal_code: "84521",
    zip_name: "elmo, ut",
  },
  {
    postal_code: "41862",
    zip_name: "topmost, ky",
  },
  {
    postal_code: "55752",
    zip_name: "jacobson, mn",
  },
  {
    postal_code: "79854",
    zip_name: "valentine, tx",
  },
  {
    postal_code: "71356",
    zip_name: "morrow, la",
  },
  {
    postal_code: "61936",
    zip_name: "la place, il",
  },
  {
    postal_code: "68643",
    zip_name: "leigh, ne",
  },
  {
    postal_code: "67736",
    zip_name: "gove, ks",
  },
  {
    postal_code: "89010",
    zip_name: "dyer, nv",
  },
  {
    postal_code: "56588",
    zip_name: "vining, mn",
  },
  {
    postal_code: "15378",
    zip_name: "westland, pa",
  },
  {
    postal_code: "60945",
    zip_name: "iroquois, il",
  },
  {
    postal_code: "72577",
    zip_name: "sidney, ar",
  },
  {
    postal_code: "21835",
    zip_name: "linkwood, md",
  },
  {
    postal_code: "15773",
    zip_name: "saint benedict, pa",
  },
  {
    postal_code: "26148",
    zip_name: "macfarlan, wv",
  },
  {
    postal_code: "62201",
    zip_name: "east saint louis, il",
  },
  {
    postal_code: "98933",
    zip_name: "harrah, wa",
  },
  {
    postal_code: "35979",
    zip_name: "higdon, al",
  },
  {
    postal_code: "59270",
    zip_name: "sidney, mt",
  },
  {
    postal_code: "59314",
    zip_name: "biddle, mt",
  },
  {
    postal_code: "58235",
    zip_name: "gilby, nd",
  },
  {
    postal_code: "73568",
    zip_name: "temple, ok",
  },
  {
    postal_code: "99039",
    zip_name: "waverly, wa",
  },
  {
    postal_code: "65457",
    zip_name: "devils elbow, mo",
  },
  {
    postal_code: "63474",
    zip_name: "wyaconda, mo",
  },
  {
    postal_code: "72838",
    zip_name: "gravelly, ar",
  },
  {
    postal_code: "79078",
    zip_name: "sanford, tx",
  },
  {
    postal_code: "57015",
    zip_name: "chancellor, sd",
  },
  {
    postal_code: "24733",
    zip_name: "lashmeet, wv",
  },
  {
    postal_code: "87028",
    zip_name: "la joya, nm",
  },
  {
    postal_code: "38949",
    zip_name: "paris, ms",
  },
  {
    postal_code: "21778",
    zip_name: "rocky ridge, md",
  },
  {
    postal_code: "57026",
    zip_name: "elkton, sd",
  },
  {
    postal_code: "35554",
    zip_name: "eldridge, al",
  },
  {
    postal_code: "93234",
    zip_name: "huron, ca",
  },
  {
    postal_code: "24831",
    zip_name: "elkhorn, wv",
  },
  {
    postal_code: "43548",
    zip_name: "new bavaria, oh",
  },
  {
    postal_code: "88135",
    zip_name: "texico, nm",
  },
  {
    postal_code: "52635",
    zip_name: "lockridge, ia",
  },
  {
    postal_code: "97904",
    zip_name: "drewsey, or",
  },
  {
    postal_code: "96780",
    zip_name: "papaaloa, hi",
  },
  {
    postal_code: "71853",
    zip_name: "ogden, ar",
  },
  {
    postal_code: "36473",
    zip_name: "range, al",
  },
  {
    postal_code: "31711",
    zip_name: "andersonville, ga",
  },
  {
    postal_code: "4624",
    zip_name: "corea, me",
  },
  {
    postal_code: "45894",
    zip_name: "venedocia, oh",
  },
  {
    postal_code: "23186",
    zip_name: "williamsburg, va",
  },
  {
    postal_code: "29166",
    zip_name: "ward, sc",
  },
  {
    postal_code: "57266",
    zip_name: "summit, sd",
  },
  {
    postal_code: "66946",
    zip_name: "hollenberg, ks",
  },
  {
    postal_code: "72464",
    zip_name: "saint francis, ar",
  },
  {
    postal_code: "66717",
    zip_name: "buffalo, ks",
  },
  {
    postal_code: "74756",
    zip_name: "sawyer, ok",
  },
  {
    postal_code: "57432",
    zip_name: "claremont, sd",
  },
  {
    postal_code: "57790",
    zip_name: "wall, sd",
  },
  {
    postal_code: "98443",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "41263",
    zip_name: "tutor key, ky",
  },
  {
    postal_code: "84733",
    zip_name: "gunlock, ut",
  },
  {
    postal_code: "73655",
    zip_name: "lone wolf, ok",
  },
  {
    postal_code: "40943",
    zip_name: "girdler, ky",
  },
  {
    postal_code: "92401",
    zip_name: "san bernardino, ca",
  },
  {
    postal_code: "53024",
    zip_name: "grafton, wi",
  },
  {
    postal_code: "64847",
    zip_name: "lanagan, mo",
  },
  {
    postal_code: "54120",
    zip_name: "fence, wi",
  },
  {
    postal_code: "41562",
    zip_name: "shelbiana, ky",
  },
  {
    postal_code: "98007",
    zip_name: "bellevue, wa",
  },
  {
    postal_code: "83217",
    zip_name: "bancroft, id",
  },
  {
    postal_code: "24147",
    zip_name: "rich creek, va",
  },
  {
    postal_code: "18656",
    zip_name: "sweet valley, pa",
  },
  {
    postal_code: "50619",
    zip_name: "clarksville, ia",
  },
  {
    postal_code: "47576",
    zip_name: "saint croix, in",
  },
  {
    postal_code: "5671",
    zip_name: "waterbury, vt",
  },
  {
    postal_code: "49775",
    zip_name: "pointe aux pins, mi",
  },
  {
    postal_code: "72004",
    zip_name: "altheimer, ar",
  },
  {
    postal_code: "79092",
    zip_name: "vega, tx",
  },
  {
    postal_code: "40815",
    zip_name: "cawood, ky",
  },
  {
    postal_code: "72928",
    zip_name: "branch, ar",
  },
  {
    postal_code: "54441",
    zip_name: "hewitt, wi",
  },
  {
    postal_code: "78117",
    zip_name: "hobson, tx",
  },
  {
    postal_code: "15553",
    zip_name: "new baltimore, pa",
  },
  {
    postal_code: "73719",
    zip_name: "amorita, ok",
  },
  {
    postal_code: "24943",
    zip_name: "grassy meadows, wv",
  },
  {
    postal_code: "15733",
    zip_name: "de lancey, pa",
  },
  {
    postal_code: "28629",
    zip_name: "glendale springs, nc",
  },
  {
    postal_code: "45870",
    zip_name: "new hampshire, oh",
  },
  {
    postal_code: "65257",
    zip_name: "higbee, mo",
  },
  {
    postal_code: "33847",
    zip_name: "homeland, fl",
  },
  {
    postal_code: "84306",
    zip_name: "collinston, ut",
  },
  {
    postal_code: "24620",
    zip_name: "hurley, va",
  },
  {
    postal_code: "82217",
    zip_name: "hawk springs, wy",
  },
  {
    postal_code: "43768",
    zip_name: "old washington, oh",
  },
  {
    postal_code: "36908",
    zip_name: "gilbertown, al",
  },
  {
    postal_code: "39866",
    zip_name: "morgan, ga",
  },
  {
    postal_code: "40855",
    zip_name: "lynch, ky",
  },
  {
    postal_code: "5748",
    zip_name: "hancock, vt",
  },
  {
    postal_code: "98222",
    zip_name: "blakely island, wa",
  },
  {
    postal_code: "99176",
    zip_name: "thornton, wa",
  },
  {
    postal_code: "99709",
    zip_name: "fairbanks, ak",
  },
  {
    postal_code: "39342",
    zip_name: "marion, ms",
  },
  {
    postal_code: "98108",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "35148",
    zip_name: "sumiton, al",
  },
  {
    postal_code: "24740",
    zip_name: "princeton, wv",
  },
  {
    postal_code: "23025",
    zip_name: "cardinal, va",
  },
  {
    postal_code: "53939",
    zip_name: "kingston, wi",
  },
  {
    postal_code: "82801",
    zip_name: "sheridan, wy",
  },
  {
    postal_code: "57006",
    zip_name: "brookings, sd",
  },
  {
    postal_code: "52561",
    zip_name: "fremont, ia",
  },
  {
    postal_code: "48326",
    zip_name: "auburn hills, mi",
  },
  {
    postal_code: "70802",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "46803",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "7033",
    zip_name: "kenilworth, nj",
  },
  {
    postal_code: "30441",
    zip_name: "midville, ga",
  },
  {
    postal_code: "23507",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "51554",
    zip_name: "mineola, ia",
  },
  {
    postal_code: "47561",
    zip_name: "oaktown, in",
  },
  {
    postal_code: "51640",
    zip_name: "hamburg, ia",
  },
  {
    postal_code: "49007",
    zip_name: "kalamazoo, mi",
  },
  {
    postal_code: "58772",
    zip_name: "portal, nd",
  },
  {
    postal_code: "80138",
    zip_name: "parker, co",
  },
  {
    postal_code: "54446",
    zip_name: "loyal, wi",
  },
  {
    postal_code: "64658",
    zip_name: "marceline, mo",
  },
  {
    postal_code: "53093",
    zip_name: "waldo, wi",
  },
  {
    postal_code: "15849",
    zip_name: "penfield, pa",
  },
  {
    postal_code: "13345",
    zip_name: "greig, ny",
  },
  {
    postal_code: "61949",
    zip_name: "redmon, il",
  },
  {
    postal_code: "65661",
    zip_name: "greenfield, mo",
  },
  {
    postal_code: "98258",
    zip_name: "lake stevens, wa",
  },
  {
    postal_code: "37305",
    zip_name: "beersheba springs, tn",
  },
  {
    postal_code: "36089",
    zip_name: "union springs, al",
  },
  {
    postal_code: "53088",
    zip_name: "stockbridge, wi",
  },
  {
    postal_code: "4434",
    zip_name: "etna, me",
  },
  {
    postal_code: "59007",
    zip_name: "bearcreek, mt",
  },
  {
    postal_code: "26559",
    zip_name: "barrackville, wv",
  },
  {
    postal_code: "66801",
    zip_name: "emporia, ks",
  },
  {
    postal_code: "53066",
    zip_name: "oconomowoc, wi",
  },
  {
    postal_code: "31079",
    zip_name: "rochelle, ga",
  },
  {
    postal_code: "73835",
    zip_name: "camargo, ok",
  },
  {
    postal_code: "52236",
    zip_name: "homestead, ia",
  },
  {
    postal_code: "70752",
    zip_name: "lakeland, la",
  },
  {
    postal_code: "26447",
    zip_name: "walkersville, wv",
  },
  {
    postal_code: "93252",
    zip_name: "maricopa, ca",
  },
  {
    postal_code: "75972",
    zip_name: "san augustine, tx",
  },
  {
    postal_code: "21766",
    zip_name: "little orleans, md",
  },
  {
    postal_code: "7029",
    zip_name: "harrison, nj",
  },
  {
    postal_code: "47382",
    zip_name: "saratoga, in",
  },
  {
    postal_code: "57234",
    zip_name: "estelline, sd",
  },
  {
    postal_code: "16639",
    zip_name: "fallentimber, pa",
  },
  {
    postal_code: "60930",
    zip_name: "danforth, il",
  },
  {
    postal_code: "52313",
    zip_name: "mount auburn, ia",
  },
  {
    postal_code: "24635",
    zip_name: "pocahontas, va",
  },
  {
    postal_code: "14468",
    zip_name: "hilton, ny",
  },
  {
    postal_code: "27922",
    zip_name: "cofield, nc",
  },
  {
    postal_code: "70772",
    zip_name: "rosedale, la",
  },
  {
    postal_code: "47451",
    zip_name: "oolitic, in",
  },
  {
    postal_code: "13141",
    zip_name: "preble, ny",
  },
  {
    postal_code: "57216",
    zip_name: "big stone city, sd",
  },
  {
    postal_code: "25165",
    zip_name: "racine, wv",
  },
  {
    postal_code: "20839",
    zip_name: "beallsville, md",
  },
  {
    postal_code: "38044",
    zip_name: "hornsby, tn",
  },
  {
    postal_code: "47137",
    zip_name: "leavenworth, in",
  },
  {
    postal_code: "49740",
    zip_name: "harbor springs, mi",
  },
  {
    postal_code: "51565",
    zip_name: "portsmouth, ia",
  },
  {
    postal_code: "17876",
    zip_name: "shamokin dam, pa",
  },
  {
    postal_code: "51331",
    zip_name: "arnolds park, ia",
  },
  {
    postal_code: "83114",
    zip_name: "cokeville, wy",
  },
  {
    postal_code: "53063",
    zip_name: "newton, wi",
  },
  {
    postal_code: "73151",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "13435",
    zip_name: "prospect, ny",
  },
  {
    postal_code: "51631",
    zip_name: "braddyville, ia",
  },
  {
    postal_code: "31022",
    zip_name: "dudley, ga",
  },
  {
    postal_code: "15537",
    zip_name: "everett, pa",
  },
  {
    postal_code: "97636",
    zip_name: "paisley, or",
  },
  {
    postal_code: "37318",
    zip_name: "cowan, tn",
  },
  {
    postal_code: "77625",
    zip_name: "kountze, tx",
  },
  {
    postal_code: "23129",
    zip_name: "oilville, va",
  },
  {
    postal_code: "87108",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "64670",
    zip_name: "pattonsburg, mo",
  },
  {
    postal_code: "50558",
    zip_name: "livermore, ia",
  },
  {
    postal_code: "49925",
    zip_name: "ewen, mi",
  },
  {
    postal_code: "19733",
    zip_name: "saint georges, de",
  },
  {
    postal_code: "79527",
    zip_name: "ira, tx",
  },
  {
    postal_code: "56174",
    zip_name: "storden, mn",
  },
  {
    postal_code: "57034",
    zip_name: "hudson, sd",
  },
  {
    postal_code: "57027",
    zip_name: "fairview, sd",
  },
  {
    postal_code: "61323",
    zip_name: "dover, il",
  },
  {
    postal_code: "41630",
    zip_name: "garrett, ky",
  },
  {
    postal_code: "79058",
    zip_name: "masterson, tx",
  },
  {
    postal_code: "72527",
    zip_name: "desha, ar",
  },
  {
    postal_code: "72453",
    zip_name: "peach orchard, ar",
  },
  {
    postal_code: "95558",
    zip_name: "petrolia, ca",
  },
  {
    postal_code: "49952",
    zip_name: "nisula, mi",
  },
  {
    postal_code: "3441",
    zip_name: "ashuelot, nh",
  },
  {
    postal_code: "50457",
    zip_name: "meservey, ia",
  },
  {
    postal_code: "84753",
    zip_name: "modena, ut",
  },
  {
    postal_code: "25025",
    zip_name: "blount, wv",
  },
  {
    postal_code: "82432",
    zip_name: "manderson, wy",
  },
  {
    postal_code: "72828",
    zip_name: "briggsville, ar",
  },
  {
    postal_code: "57359",
    zip_name: "letcher, sd",
  },
  {
    postal_code: "71826",
    zip_name: "bradley, ar",
  },
  {
    postal_code: "57471",
    zip_name: "roscoe, sd",
  },
  {
    postal_code: "67103",
    zip_name: "mayfield, ks",
  },
  {
    postal_code: "82510",
    zip_name: "arapahoe, wy",
  },
  {
    postal_code: "66940",
    zip_name: "cuba, ks",
  },
  {
    postal_code: "14166",
    zip_name: "van buren point, ny",
  },
  {
    postal_code: "87357",
    zip_name: "pinehill, nm",
  },
  {
    postal_code: "73741",
    zip_name: "helena, ok",
  },
  {
    postal_code: "83446",
    zip_name: "spencer, id",
  },
  {
    postal_code: "28733",
    zip_name: "fontana dam, nc",
  },
  {
    postal_code: "54723",
    zip_name: "bay city, wi",
  },
  {
    postal_code: "62820",
    zip_name: "burnt prairie, il",
  },
  {
    postal_code: "71944",
    zip_name: "grannis, ar",
  },
  {
    postal_code: "58710",
    zip_name: "anamoose, nd",
  },
  {
    postal_code: "85640",
    zip_name: "tumacacori, az",
  },
  {
    postal_code: "69171",
    zip_name: "willow island, ne",
  },
  {
    postal_code: "36585",
    zip_name: "wagarville, al",
  },
  {
    postal_code: "66418",
    zip_name: "delia, ks",
  },
  {
    postal_code: "70632",
    zip_name: "creole, la",
  },
  {
    postal_code: "57021",
    zip_name: "davis, sd",
  },
  {
    postal_code: "63879",
    zip_name: "wardell, mo",
  },
  {
    postal_code: "24985",
    zip_name: "wayside, wv",
  },
  {
    postal_code: "92389",
    zip_name: "tecopa, ca",
  },
  {
    postal_code: "56224",
    zip_name: "clements, mn",
  },
  {
    postal_code: "41264",
    zip_name: "ulysses, ky",
  },
  {
    postal_code: "63675",
    zip_name: "vulcan, mo",
  },
  {
    postal_code: "99330",
    zip_name: "eltopia, wa",
  },
  {
    postal_code: "66843",
    zip_name: "cedar point, ks",
  },
  {
    postal_code: "57010",
    zip_name: "burbank, sd",
  },
  {
    postal_code: "14816",
    zip_name: "breesport, ny",
  },
  {
    postal_code: "62517",
    zip_name: "bulpitt, il",
  },
  {
    postal_code: "99686",
    zip_name: "valdez, ak",
  },
  {
    postal_code: "17850",
    zip_name: "montandon, pa",
  },
  {
    postal_code: "62363",
    zip_name: "pittsfield, il",
  },
  {
    postal_code: "29138",
    zip_name: "saluda, sc",
  },
  {
    postal_code: "14134",
    zip_name: "sardinia, ny",
  },
  {
    postal_code: "29212",
    zip_name: "columbia, sc",
  },
  {
    postal_code: "24054",
    zip_name: "axton, va",
  },
  {
    postal_code: "54914",
    zip_name: "appleton, wi",
  },
  {
    postal_code: "58265",
    zip_name: "neche, nd",
  },
  {
    postal_code: "24966",
    zip_name: "renick, wv",
  },
  {
    postal_code: "74640",
    zip_name: "hunter, ok",
  },
  {
    postal_code: "13147",
    zip_name: "scipio center, ny",
  },
  {
    postal_code: "97535",
    zip_name: "phoenix, or",
  },
  {
    postal_code: "52411",
    zip_name: "cedar rapids, ia",
  },
  {
    postal_code: "53192",
    zip_name: "wilmot, wi",
  },
  {
    postal_code: "81251",
    zip_name: "twin lakes, co",
  },
  {
    postal_code: "79733",
    zip_name: "forsan, tx",
  },
  {
    postal_code: "67459",
    zip_name: "lorraine, ks",
  },
  {
    postal_code: "82430",
    zip_name: "kirby, wy",
  },
  {
    postal_code: "4492",
    zip_name: "waite, me",
  },
  {
    postal_code: "56173",
    zip_name: "steen, mn",
  },
  {
    postal_code: "59462",
    zip_name: "moccasin, mt",
  },
  {
    postal_code: "58476",
    zip_name: "pingree, nd",
  },
  {
    postal_code: "72515",
    zip_name: "bexar, ar",
  },
  {
    postal_code: "26710",
    zip_name: "burlington, wv",
  },
  {
    postal_code: "62030",
    zip_name: "fidelity, il",
  },
  {
    postal_code: "18216",
    zip_name: "beaver meadows, pa",
  },
  {
    postal_code: "96822",
    zip_name: "honolulu, hi",
  },
  {
    postal_code: "48835",
    zip_name: "fowler, mi",
  },
  {
    postal_code: "63763",
    zip_name: "mc gee, mo",
  },
  {
    postal_code: "72360",
    zip_name: "marianna, ar",
  },
  {
    postal_code: "56119",
    zip_name: "brewster, mn",
  },
  {
    postal_code: "39356",
    zip_name: "rose hill, ms",
  },
  {
    postal_code: "65609",
    zip_name: "bakersfield, mo",
  },
  {
    postal_code: "37057",
    zip_name: "dixon springs, tn",
  },
  {
    postal_code: "4109",
    zip_name: "portland, me",
  },
  {
    postal_code: "4955",
    zip_name: "new sharon, me",
  },
  {
    postal_code: "68414",
    zip_name: "nemaha, ne",
  },
  {
    postal_code: "46103",
    zip_name: "amo, in",
  },
  {
    postal_code: "14897",
    zip_name: "whitesville, ny",
  },
  {
    postal_code: "44864",
    zip_name: "perrysville, oh",
  },
  {
    postal_code: "38456",
    zip_name: "ethridge, tn",
  },
  {
    postal_code: "27820",
    zip_name: "conway, nc",
  },
  {
    postal_code: "98856",
    zip_name: "twisp, wa",
  },
  {
    postal_code: "26283",
    zip_name: "montrose, wv",
  },
  {
    postal_code: "96757",
    zip_name: "kualapuu, hi",
  },
  {
    postal_code: "15937",
    zip_name: "jerome, pa",
  },
  {
    postal_code: "59859",
    zip_name: "plains, mt",
  },
  {
    postal_code: "28905",
    zip_name: "marble, nc",
  },
  {
    postal_code: "73542",
    zip_name: "frederick, ok",
  },
  {
    postal_code: "50653",
    zip_name: "marble rock, ia",
  },
  {
    postal_code: "12416",
    zip_name: "chichester, ny",
  },
  {
    postal_code: "87524",
    zip_name: "costilla, nm",
  },
  {
    postal_code: "85346",
    zip_name: "quartzsite, az",
  },
  {
    postal_code: "64767",
    zip_name: "milo, mo",
  },
  {
    postal_code: "32355",
    zip_name: "saint marks, fl",
  },
  {
    postal_code: "14032",
    zip_name: "clarence center, ny",
  },
  {
    postal_code: "65806",
    zip_name: "springfield, mo",
  },
  {
    postal_code: "68417",
    zip_name: "otoe, ne",
  },
  {
    postal_code: "43144",
    zip_name: "murray city, oh",
  },
  {
    postal_code: "39039",
    zip_name: "benton, ms",
  },
  {
    postal_code: "98670",
    zip_name: "wahkiacus, wa",
  },
  {
    postal_code: "64653",
    zip_name: "linneus, mo",
  },
  {
    postal_code: "70757",
    zip_name: "maringouin, la",
  },
  {
    postal_code: "30802",
    zip_name: "appling, ga",
  },
  {
    postal_code: "68424",
    zip_name: "plymouth, ne",
  },
  {
    postal_code: "62880",
    zip_name: "saint peter, il",
  },
  {
    postal_code: "49793",
    zip_name: "trout lake, mi",
  },
  {
    postal_code: "7206",
    zip_name: "elizabethport, nj",
  },
  {
    postal_code: "87112",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "68036",
    zip_name: "linwood, ne",
  },
  {
    postal_code: "50675",
    zip_name: "traer, ia",
  },
  {
    postal_code: "98363",
    zip_name: "port angeles, wa",
  },
  {
    postal_code: "61876",
    zip_name: "sidell, il",
  },
  {
    postal_code: "62365",
    zip_name: "plainville, il",
  },
  {
    postal_code: "10578",
    zip_name: "purdys, ny",
  },
  {
    postal_code: "62248",
    zip_name: "hecker, il",
  },
  {
    postal_code: "17538",
    zip_name: "landisville, pa",
  },
  {
    postal_code: "57063",
    zip_name: "tabor, sd",
  },
  {
    postal_code: "29856",
    zip_name: "windsor, sc",
  },
  {
    postal_code: "76519",
    zip_name: "burlington, tx",
  },
  {
    postal_code: "14770",
    zip_name: "portville, ny",
  },
  {
    postal_code: "21071",
    zip_name: "glyndon, md",
  },
  {
    postal_code: "15427",
    zip_name: "daisytown, pa",
  },
  {
    postal_code: "14060",
    zip_name: "farmersville station, ny",
  },
  {
    postal_code: "62351",
    zip_name: "mendon, il",
  },
  {
    postal_code: "29081",
    zip_name: "ehrhardt, sc",
  },
  {
    postal_code: "79749",
    zip_name: "lenorah, tx",
  },
  {
    postal_code: "13464",
    zip_name: "smyrna, ny",
  },
  {
    postal_code: "78539",
    zip_name: "edinburg, tx",
  },
  {
    postal_code: "13661",
    zip_name: "mannsville, ny",
  },
  {
    postal_code: "72670",
    zip_name: "ponca, ar",
  },
  {
    postal_code: "71801",
    zip_name: "hope, ar",
  },
  {
    postal_code: "61815",
    zip_name: "bondville, il",
  },
  {
    postal_code: "36553",
    zip_name: "mc intosh, al",
  },
  {
    postal_code: "40820",
    zip_name: "cranks, ky",
  },
  {
    postal_code: "47546",
    zip_name: "jasper, in",
  },
  {
    postal_code: "52252",
    zip_name: "langworthy, ia",
  },
  {
    postal_code: "98387",
    zip_name: "spanaway, wa",
  },
  {
    postal_code: "30747",
    zip_name: "summerville, ga",
  },
  {
    postal_code: "95587",
    zip_name: "piercy, ca",
  },
  {
    postal_code: "47713",
    zip_name: "evansville, in",
  },
  {
    postal_code: "8224",
    zip_name: "new gretna, nj",
  },
  {
    postal_code: "45640",
    zip_name: "jackson, oh",
  },
  {
    postal_code: "7111",
    zip_name: "irvington, nj",
  },
  {
    postal_code: "71353",
    zip_name: "melville, la",
  },
  {
    postal_code: "82845",
    zip_name: "wyarno, wy",
  },
  {
    postal_code: "14726",
    zip_name: "conewango valley, ny",
  },
  {
    postal_code: "73527",
    zip_name: "cache, ok",
  },
  {
    postal_code: "98112",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "36421",
    zip_name: "andalusia, al",
  },
  {
    postal_code: "79783",
    zip_name: "tarzan, tx",
  },
  {
    postal_code: "29817",
    zip_name: "blackville, sc",
  },
  {
    postal_code: "73644",
    zip_name: "elk city, ok",
  },
  {
    postal_code: "62376",
    zip_name: "ursa, il",
  },
  {
    postal_code: "20612",
    zip_name: "benedict, md",
  },
  {
    postal_code: "15721",
    zip_name: "burnside, pa",
  },
  {
    postal_code: "2836",
    zip_name: "kenyon, ri",
  },
  {
    postal_code: "83342",
    zip_name: "malta, id",
  },
  {
    postal_code: "68329",
    zip_name: "cook, ne",
  },
  {
    postal_code: "40060",
    zip_name: "raywick, ky",
  },
  {
    postal_code: "38879",
    zip_name: "verona, ms",
  },
  {
    postal_code: "80755",
    zip_name: "vernon, co",
  },
  {
    postal_code: "10933",
    zip_name: "johnson, ny",
  },
  {
    postal_code: "66863",
    zip_name: "neal, ks",
  },
  {
    postal_code: "39179",
    zip_name: "vaughan, ms",
  },
  {
    postal_code: "67764",
    zip_name: "winona, ks",
  },
  {
    postal_code: "61955",
    zip_name: "vermilion, il",
  },
  {
    postal_code: "82725",
    zip_name: "recluse, wy",
  },
  {
    postal_code: "81024",
    zip_name: "boncarbo, co",
  },
  {
    postal_code: "65618",
    zip_name: "brixey, mo",
  },
  {
    postal_code: "28587",
    zip_name: "vandemere, nc",
  },
  {
    postal_code: "73546",
    zip_name: "grandfield, ok",
  },
  {
    postal_code: "58021",
    zip_name: "davenport, nd",
  },
  {
    postal_code: "63339",
    zip_name: "curryville, mo",
  },
  {
    postal_code: "67625",
    zip_name: "bogue, ks",
  },
  {
    postal_code: "58029",
    zip_name: "erie, nd",
  },
  {
    postal_code: "16691",
    zip_name: "wells tannery, pa",
  },
  {
    postal_code: "56218",
    zip_name: "boyd, mn",
  },
  {
    postal_code: "24536",
    zip_name: "coleman falls, va",
  },
  {
    postal_code: "76263",
    zip_name: "rosston, tx",
  },
  {
    postal_code: "24628",
    zip_name: "maxie, va",
  },
  {
    postal_code: "62410",
    zip_name: "allendale, il",
  },
  {
    postal_code: "69214",
    zip_name: "johnstown, ne",
  },
  {
    postal_code: "79350",
    zip_name: "new deal, tx",
  },
  {
    postal_code: "69142",
    zip_name: "halsey, ne",
  },
  {
    postal_code: "38720",
    zip_name: "alligator, ms",
  },
  {
    postal_code: "50612",
    zip_name: "buckingham, ia",
  },
  {
    postal_code: "52560",
    zip_name: "floris, ia",
  },
  {
    postal_code: "14557",
    zip_name: "south byron, ny",
  },
  {
    postal_code: "29015",
    zip_name: "blair, sc",
  },
  {
    postal_code: "65783",
    zip_name: "windyville, mo",
  },
  {
    postal_code: "83454",
    zip_name: "ucon, id",
  },
  {
    postal_code: "95679",
    zip_name: "rumsey, ca",
  },
  {
    postal_code: "82933",
    zip_name: "fort bridger, wy",
  },
  {
    postal_code: "42344",
    zip_name: "graham, ky",
  },
  {
    postal_code: "57051",
    zip_name: "oldham, sd",
  },
  {
    postal_code: "57375",
    zip_name: "stickney, sd",
  },
  {
    postal_code: "62370",
    zip_name: "rockport, il",
  },
  {
    postal_code: "43345",
    zip_name: "ridgeway, oh",
  },
  {
    postal_code: "25162",
    zip_name: "pratt, wv",
  },
  {
    postal_code: "36456",
    zip_name: "mc kenzie, al",
  },
  {
    postal_code: "68881",
    zip_name: "westerville, ne",
  },
  {
    postal_code: "51440",
    zip_name: "dedham, ia",
  },
  {
    postal_code: "64442",
    zip_name: "eagleville, mo",
  },
  {
    postal_code: "93251",
    zip_name: "mc kittrick, ca",
  },
  {
    postal_code: "18225",
    zip_name: "harleigh, pa",
  },
  {
    postal_code: "79743",
    zip_name: "imperial, tx",
  },
  {
    postal_code: "59351",
    zip_name: "volborg, mt",
  },
  {
    postal_code: "79013",
    zip_name: "cactus, tx",
  },
  {
    postal_code: "27862",
    zip_name: "pendleton, nc",
  },
  {
    postal_code: "47160",
    zip_name: "new middletown, in",
  },
  {
    postal_code: "36901",
    zip_name: "bellamy, al",
  },
  {
    postal_code: "50050",
    zip_name: "churdan, ia",
  },
  {
    postal_code: "66859",
    zip_name: "lost springs, ks",
  },
  {
    postal_code: "72520",
    zip_name: "camp, ar",
  },
  {
    postal_code: "20765",
    zip_name: "galesville, md",
  },
  {
    postal_code: "71642",
    zip_name: "fountain hill, ar",
  },
  {
    postal_code: "41348",
    zip_name: "lost creek, ky",
  },
  {
    postal_code: "45348",
    zip_name: "new weston, oh",
  },
  {
    postal_code: "50252",
    zip_name: "swan, ia",
  },
  {
    postal_code: "26376",
    zip_name: "ireland, wv",
  },
  {
    postal_code: "31065",
    zip_name: "montrose, ga",
  },
  {
    postal_code: "66937",
    zip_name: "clifton, ks",
  },
  {
    postal_code: "39348",
    zip_name: "paulding, ms",
  },
  {
    postal_code: "98255",
    zip_name: "hamilton, wa",
  },
  {
    postal_code: "59456",
    zip_name: "ledger, mt",
  },
  {
    postal_code: "67752",
    zip_name: "quinter, ks",
  },
  {
    postal_code: "43322",
    zip_name: "green camp, oh",
  },
  {
    postal_code: "49716",
    zip_name: "brutus, mi",
  },
  {
    postal_code: "98329",
    zip_name: "gig harbor, wa",
  },
  {
    postal_code: "53223",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "46111",
    zip_name: "brooklyn, in",
  },
  {
    postal_code: "16225",
    zip_name: "fisher, pa",
  },
  {
    postal_code: "98281",
    zip_name: "point roberts, wa",
  },
  {
    postal_code: "73832",
    zip_name: "arnett, ok",
  },
  {
    postal_code: "54448",
    zip_name: "marathon, wi",
  },
  {
    postal_code: "98005",
    zip_name: "bellevue, wa",
  },
  {
    postal_code: "70662",
    zip_name: "sugartown, la",
  },
  {
    postal_code: "55738",
    zip_name: "forbes, mn",
  },
  {
    postal_code: "5079",
    zip_name: "vershire, vt",
  },
  {
    postal_code: "57038",
    zip_name: "jefferson, sd",
  },
  {
    postal_code: "12459",
    zip_name: "new kingston, ny",
  },
  {
    postal_code: "80222",
    zip_name: "denver, co",
  },
  {
    postal_code: "12821",
    zip_name: "comstock, ny",
  },
  {
    postal_code: "97344",
    zip_name: "falls city, or",
  },
  {
    postal_code: "35633",
    zip_name: "florence, al",
  },
  {
    postal_code: "73620",
    zip_name: "arapaho, ok",
  },
  {
    postal_code: "52253",
    zip_name: "lisbon, ia",
  },
  {
    postal_code: "65732",
    zip_name: "preston, mo",
  },
  {
    postal_code: "87375",
    zip_name: "yatahey, nm",
  },
  {
    postal_code: "17964",
    zip_name: "pitman, pa",
  },
  {
    postal_code: "64432",
    zip_name: "clyde, mo",
  },
  {
    postal_code: "89317",
    zip_name: "lund, nv",
  },
  {
    postal_code: "57559",
    zip_name: "murdo, sd",
  },
  {
    postal_code: "41723",
    zip_name: "busy, ky",
  },
  {
    postal_code: "67644",
    zip_name: "kirwin, ks",
  },
  {
    postal_code: "15549",
    zip_name: "listie, pa",
  },
  {
    postal_code: "62366",
    zip_name: "pleasant hill, il",
  },
  {
    postal_code: "57373",
    zip_name: "saint lawrence, sd",
  },
  {
    postal_code: "50141",
    zip_name: "laurel, ia",
  },
  {
    postal_code: "49901",
    zip_name: "ahmeek, mi",
  },
  {
    postal_code: "12927",
    zip_name: "cranberry lake, ny",
  },
  {
    postal_code: "37348",
    zip_name: "kelso, tn",
  },
  {
    postal_code: "71235",
    zip_name: "dubach, la",
  },
  {
    postal_code: "73129",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "55990",
    zip_name: "wykoff, mn",
  },
  {
    postal_code: "40914",
    zip_name: "big creek, ky",
  },
  {
    postal_code: "98361",
    zip_name: "packwood, wa",
  },
  {
    postal_code: "22722",
    zip_name: "haywood, va",
  },
  {
    postal_code: "32448",
    zip_name: "marianna, fl",
  },
  {
    postal_code: "23324",
    zip_name: "chesapeake, va",
  },
  {
    postal_code: "84086",
    zip_name: "woodruff, ut",
  },
  {
    postal_code: "62341",
    zip_name: "hamilton, il",
  },
  {
    postal_code: "98376",
    zip_name: "quilcene, wa",
  },
  {
    postal_code: "74723",
    zip_name: "bennington, ok",
  },
  {
    postal_code: "72837",
    zip_name: "dover, ar",
  },
  {
    postal_code: "31905",
    zip_name: "fort benning, ga",
  },
  {
    postal_code: "24459",
    zip_name: "middlebrook, va",
  },
  {
    postal_code: "57791",
    zip_name: "wasta, sd",
  },
  {
    postal_code: "30752",
    zip_name: "trenton, ga",
  },
  {
    postal_code: "24167",
    zip_name: "staffordsville, va",
  },
  {
    postal_code: "60913",
    zip_name: "bonfield, il",
  },
  {
    postal_code: "49777",
    zip_name: "presque isle, mi",
  },
  {
    postal_code: "76637",
    zip_name: "cranfills gap, tx",
  },
  {
    postal_code: "52583",
    zip_name: "promise city, ia",
  },
  {
    postal_code: "78850",
    zip_name: "d hanis, tx",
  },
  {
    postal_code: "25043",
    zip_name: "clay, wv",
  },
  {
    postal_code: "47879",
    zip_name: "shelburn, in",
  },
  {
    postal_code: "98271",
    zip_name: "marysville, wa",
  },
  {
    postal_code: "49032",
    zip_name: "centreville, mi",
  },
  {
    postal_code: "2142",
    zip_name: "cambridge, ma",
  },
  {
    postal_code: "83524",
    zip_name: "culdesac, id",
  },
  {
    postal_code: "98547",
    zip_name: "grayland, wa",
  },
  {
    postal_code: "38388",
    zip_name: "wildersville, tn",
  },
  {
    postal_code: "47591",
    zip_name: "vincennes, in",
  },
  {
    postal_code: "73768",
    zip_name: "ringwood, ok",
  },
  {
    postal_code: "56214",
    zip_name: "belview, mn",
  },
  {
    postal_code: "16744",
    zip_name: "rew, pa",
  },
  {
    postal_code: "37416",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "49348",
    zip_name: "wayland, mi",
  },
  {
    postal_code: "31023",
    zip_name: "eastman, ga",
  },
  {
    postal_code: "25275",
    zip_name: "sandyville, wv",
  },
  {
    postal_code: "39336",
    zip_name: "lawrence, ms",
  },
  {
    postal_code: "17760",
    zip_name: "north bend, pa",
  },
  {
    postal_code: "21667",
    zip_name: "still pond, md",
  },
  {
    postal_code: "72513",
    zip_name: "ash flat, ar",
  },
  {
    postal_code: "58631",
    zip_name: "glen ullin, nd",
  },
  {
    postal_code: "47135",
    zip_name: "laconia, in",
  },
  {
    postal_code: "46943",
    zip_name: "laketon, in",
  },
  {
    postal_code: "46975",
    zip_name: "rochester, in",
  },
  {
    postal_code: "32628",
    zip_name: "cross city, fl",
  },
  {
    postal_code: "83656",
    zip_name: "notus, id",
  },
  {
    postal_code: "71770",
    zip_name: "waldo, ar",
  },
  {
    postal_code: "26520",
    zip_name: "arthurdale, wv",
  },
  {
    postal_code: "74834",
    zip_name: "chandler, ok",
  },
  {
    postal_code: "25638",
    zip_name: "omar, wv",
  },
  {
    postal_code: "14013",
    zip_name: "basom, ny",
  },
  {
    postal_code: "48809",
    zip_name: "belding, mi",
  },
  {
    postal_code: "39645",
    zip_name: "liberty, ms",
  },
  {
    postal_code: "47928",
    zip_name: "cayuga, in",
  },
  {
    postal_code: "31563",
    zip_name: "surrency, ga",
  },
  {
    postal_code: "18333",
    zip_name: "kresgeville, pa",
  },
  {
    postal_code: "32569",
    zip_name: "mary esther, fl",
  },
  {
    postal_code: "43734",
    zip_name: "duncan falls, oh",
  },
  {
    postal_code: "70775",
    zip_name: "saint francisville, la",
  },
  {
    postal_code: "27888",
    zip_name: "walstonburg, nc",
  },
  {
    postal_code: "41603",
    zip_name: "banner, ky",
  },
  {
    postal_code: "36574",
    zip_name: "seminole, al",
  },
  {
    postal_code: "26238",
    zip_name: "volga, wv",
  },
  {
    postal_code: "37302",
    zip_name: "apison, tn",
  },
  {
    postal_code: "57450",
    zip_name: "hoven, sd",
  },
  {
    postal_code: "70761",
    zip_name: "norwood, la",
  },
  {
    postal_code: "50173",
    zip_name: "montour, ia",
  },
  {
    postal_code: "59440",
    zip_name: "floweree, mt",
  },
  {
    postal_code: "73115",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "99674",
    zip_name: "sutton, ak",
  },
  {
    postal_code: "70729",
    zip_name: "erwinville, la",
  },
  {
    postal_code: "71234",
    zip_name: "downsville, la",
  },
  {
    postal_code: "43541",
    zip_name: "milton center, oh",
  },
  {
    postal_code: "57384",
    zip_name: "wolsey, sd",
  },
  {
    postal_code: "83462",
    zip_name: "carmen, id",
  },
  {
    postal_code: "14511",
    zip_name: "mumford, ny",
  },
  {
    postal_code: "53074",
    zip_name: "port washington, wi",
  },
  {
    postal_code: "57321",
    zip_name: "canova, sd",
  },
  {
    postal_code: "24834",
    zip_name: "fanrock, wv",
  },
  {
    postal_code: "22509",
    zip_name: "loretto, va",
  },
  {
    postal_code: "41367",
    zip_name: "rowdy, ky",
  },
  {
    postal_code: "57446",
    zip_name: "hecla, sd",
  },
  {
    postal_code: "49792",
    zip_name: "tower, mi",
  },
  {
    postal_code: "79087",
    zip_name: "texline, tx",
  },
  {
    postal_code: "58752",
    zip_name: "lignite, nd",
  },
  {
    postal_code: "39836",
    zip_name: "coleman, ga",
  },
  {
    postal_code: "65035",
    zip_name: "freeburg, mo",
  },
  {
    postal_code: "42370",
    zip_name: "rosine, ky",
  },
  {
    postal_code: "29052",
    zip_name: "gadsden, sc",
  },
  {
    postal_code: "61851",
    zip_name: "ivesdale, il",
  },
  {
    postal_code: "65058",
    zip_name: "meta, mo",
  },
  {
    postal_code: "82712",
    zip_name: "beulah, wy",
  },
  {
    postal_code: "58783",
    zip_name: "souris, nd",
  },
  {
    postal_code: "65083",
    zip_name: "ulman, mo",
  },
  {
    postal_code: "41535",
    zip_name: "huddy, ky",
  },
  {
    postal_code: "72414",
    zip_name: "black oak, ar",
  },
  {
    postal_code: "38856",
    zip_name: "marietta, ms",
  },
  {
    postal_code: "59059",
    zip_name: "musselshell, mt",
  },
  {
    postal_code: "67557",
    zip_name: "macksville, ks",
  },
  {
    postal_code: "58649",
    zip_name: "reeder, nd",
  },
  {
    postal_code: "93429",
    zip_name: "casmalia, ca",
  },
  {
    postal_code: "61470",
    zip_name: "prairie city, il",
  },
  {
    postal_code: "67878",
    zip_name: "syracuse, ks",
  },
  {
    postal_code: "67758",
    zip_name: "sharon springs, ks",
  },
  {
    postal_code: "80818",
    zip_name: "genoa, co",
  },
  {
    postal_code: "72036",
    zip_name: "cotton plant, ar",
  },
  {
    postal_code: "94548",
    zip_name: "knightsen, ca",
  },
  {
    postal_code: "29377",
    zip_name: "startex, sc",
  },
  {
    postal_code: "69027",
    zip_name: "enders, ne",
  },
  {
    postal_code: "71404",
    zip_name: "atlanta, la",
  },
  {
    postal_code: "36262",
    zip_name: "fruithurst, al",
  },
  {
    postal_code: "68439",
    zip_name: "staplehurst, ne",
  },
  {
    postal_code: "97736",
    zip_name: "frenchglen, or",
  },
  {
    postal_code: "63472",
    zip_name: "wayland, mo",
  },
  {
    postal_code: "62975",
    zip_name: "pomona, il",
  },
  {
    postal_code: "32083",
    zip_name: "raiford, fl",
  },
  {
    postal_code: "38963",
    zip_name: "tutwiler, ms",
  },
  {
    postal_code: "96111",
    zip_name: "floriston, ca",
  },
  {
    postal_code: "94512",
    zip_name: "birds landing, ca",
  },
  {
    postal_code: "66751",
    zip_name: "la harpe, ks",
  },
  {
    postal_code: "57323",
    zip_name: "carthage, sd",
  },
  {
    postal_code: "67643",
    zip_name: "jennings, ks",
  },
  {
    postal_code: "51363",
    zip_name: "superior, ia",
  },
  {
    postal_code: "4683",
    zip_name: "sunset, me",
  },
  {
    postal_code: "68740",
    zip_name: "hoskins, ne",
  },
  {
    postal_code: "78406",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "75784",
    zip_name: "reklaw, tx",
  },
  {
    postal_code: "95224",
    zip_name: "avery, ca",
  },
  {
    postal_code: "67132",
    zip_name: "rosalia, ks",
  },
  {
    postal_code: "66714",
    zip_name: "benedict, ks",
  },
  {
    postal_code: "74577",
    zip_name: "whitesboro, ok",
  },
  {
    postal_code: "63633",
    zip_name: "centerville, mo",
  },
  {
    postal_code: "58048",
    zip_name: "hunter, nd",
  },
  {
    postal_code: "72165",
    zip_name: "thida, ar",
  },
  {
    postal_code: "95564",
    zip_name: "samoa, ca",
  },
  {
    postal_code: "78547",
    zip_name: "garciasville, tx",
  },
  {
    postal_code: "68827",
    zip_name: "chapman, ne",
  },
  {
    postal_code: "40062",
    zip_name: "saint francis, ky",
  },
  {
    postal_code: "55090",
    zip_name: "willernie, mn",
  },
  {
    postal_code: "68303",
    zip_name: "alexandria, ne",
  },
  {
    postal_code: "58420",
    zip_name: "buchanan, nd",
  },
  {
    postal_code: "59341",
    zip_name: "mildred, mt",
  },
  {
    postal_code: "93633",
    zip_name: "kings canyon national pk, ca",
  },
  {
    postal_code: "36925",
    zip_name: "york, al",
  },
  {
    postal_code: "57018",
    zip_name: "colton, sd",
  },
  {
    postal_code: "62375",
    zip_name: "timewell, il",
  },
  {
    postal_code: "78569",
    zip_name: "lyford, tx",
  },
  {
    postal_code: "80468",
    zip_name: "parshall, co",
  },
  {
    postal_code: "72167",
    zip_name: "traskwood, ar",
  },
  {
    postal_code: "38642",
    zip_name: "lamar, ms",
  },
  {
    postal_code: "98571",
    zip_name: "pacific beach, wa",
  },
  {
    postal_code: "47525",
    zip_name: "derby, in",
  },
  {
    postal_code: "72160",
    zip_name: "stuttgart, ar",
  },
  {
    postal_code: "50472",
    zip_name: "saint ansgar, ia",
  },
  {
    postal_code: "17801",
    zip_name: "sunbury, pa",
  },
  {
    postal_code: "15482",
    zip_name: "star junction, pa",
  },
  {
    postal_code: "44490",
    zip_name: "washingtonville, oh",
  },
  {
    postal_code: "54137",
    zip_name: "krakow, wi",
  },
  {
    postal_code: "53097",
    zip_name: "mequon, wi",
  },
  {
    postal_code: "31768",
    zip_name: "moultrie, ga",
  },
  {
    postal_code: "58721",
    zip_name: "bowbells, nd",
  },
  {
    postal_code: "36003",
    zip_name: "autaugaville, al",
  },
  {
    postal_code: "57225",
    zip_name: "clark, sd",
  },
  {
    postal_code: "31637",
    zip_name: "lenox, ga",
  },
  {
    postal_code: "97869",
    zip_name: "prairie city, or",
  },
  {
    postal_code: "67581",
    zip_name: "sylvia, ks",
  },
  {
    postal_code: "79227",
    zip_name: "crowell, tx",
  },
  {
    postal_code: "52766",
    zip_name: "nichols, ia",
  },
  {
    postal_code: "88116",
    zip_name: "elida, nm",
  },
  {
    postal_code: "72677",
    zip_name: "summit, ar",
  },
  {
    postal_code: "57476",
    zip_name: "tulare, sd",
  },
  {
    postal_code: "87728",
    zip_name: "maxwell, nm",
  },
  {
    postal_code: "29932",
    zip_name: "luray, sc",
  },
  {
    postal_code: "24132",
    zip_name: "parrott, va",
  },
  {
    postal_code: "99757",
    zip_name: "lake minchumina, ak",
  },
  {
    postal_code: "12765",
    zip_name: "neversink, ny",
  },
  {
    postal_code: "29803",
    zip_name: "aiken, sc",
  },
  {
    postal_code: "64673",
    zip_name: "princeton, mo",
  },
  {
    postal_code: "57278",
    zip_name: "willow lake, sd",
  },
  {
    postal_code: "71291",
    zip_name: "west monroe, la",
  },
  {
    postal_code: "73128",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "7041",
    zip_name: "millburn, nj",
  },
  {
    postal_code: "61233",
    zip_name: "andover, il",
  },
  {
    postal_code: "74423",
    zip_name: "braggs, ok",
  },
  {
    postal_code: "50467",
    zip_name: "rock falls, ia",
  },
  {
    postal_code: "78557",
    zip_name: "hidalgo, tx",
  },
  {
    postal_code: "57014",
    zip_name: "centerville, sd",
  },
  {
    postal_code: "84061",
    zip_name: "peoa, ut",
  },
  {
    postal_code: "7094",
    zip_name: "secaucus, nj",
  },
  {
    postal_code: "7863",
    zip_name: "oxford, nj",
  },
  {
    postal_code: "83825",
    zip_name: "dover, id",
  },
  {
    postal_code: "64835",
    zip_name: "carterville, mo",
  },
  {
    postal_code: "23421",
    zip_name: "parksley, va",
  },
  {
    postal_code: "52754",
    zip_name: "letts, ia",
  },
  {
    postal_code: "42039",
    zip_name: "fancy farm, ky",
  },
  {
    postal_code: "97813",
    zip_name: "athena, or",
  },
  {
    postal_code: "38226",
    zip_name: "dukedom, tn",
  },
  {
    postal_code: "98823",
    zip_name: "ephrata, wa",
  },
  {
    postal_code: "67133",
    zip_name: "rose hill, ks",
  },
  {
    postal_code: "47023",
    zip_name: "holton, in",
  },
  {
    postal_code: "31812",
    zip_name: "junction city, ga",
  },
  {
    postal_code: "23523",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "66769",
    zip_name: "redfield, ks",
  },
  {
    postal_code: "98595",
    zip_name: "westport, wa",
  },
  {
    postal_code: "54564",
    zip_name: "tripoli, wi",
  },
  {
    postal_code: "98569",
    zip_name: "ocean shores, wa",
  },
  {
    postal_code: "84710",
    zip_name: "alton, ut",
  },
  {
    postal_code: "23069",
    zip_name: "hanover, va",
  },
  {
    postal_code: "50673",
    zip_name: "stout, ia",
  },
  {
    postal_code: "69038",
    zip_name: "maywood, ne",
  },
  {
    postal_code: "66861",
    zip_name: "marion, ks",
  },
  {
    postal_code: "45378",
    zip_name: "verona, oh",
  },
  {
    postal_code: "6266",
    zip_name: "south windham, ct",
  },
  {
    postal_code: "62561",
    zip_name: "riverton, il",
  },
  {
    postal_code: "97347",
    zip_name: "grand ronde, or",
  },
  {
    postal_code: "41234",
    zip_name: "meally, ky",
  },
  {
    postal_code: "49613",
    zip_name: "arcadia, mi",
  },
  {
    postal_code: "65261",
    zip_name: "keytesville, mo",
  },
  {
    postal_code: "94592",
    zip_name: "vallejo, ca",
  },
  {
    postal_code: "97329",
    zip_name: "cascadia, or",
  },
  {
    postal_code: "68746",
    zip_name: "lynch, ne",
  },
  {
    postal_code: "24082",
    zip_name: "critz, va",
  },
  {
    postal_code: "77878",
    zip_name: "snook, tx",
  },
  {
    postal_code: "72430",
    zip_name: "greenway, ar",
  },
  {
    postal_code: "67228",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "57078",
    zip_name: "yankton, sd",
  },
  {
    postal_code: "32568",
    zip_name: "mc david, fl",
  },
  {
    postal_code: "87539",
    zip_name: "la madera, nm",
  },
  {
    postal_code: "67151",
    zip_name: "walton, ks",
  },
  {
    postal_code: "7026",
    zip_name: "garfield, nj",
  },
  {
    postal_code: "58365",
    zip_name: "rocklake, nd",
  },
  {
    postal_code: "51366",
    zip_name: "webb, ia",
  },
  {
    postal_code: "61539",
    zip_name: "kingston mines, il",
  },
  {
    postal_code: "49962",
    zip_name: "skanee, mi",
  },
  {
    postal_code: "97635",
    zip_name: "new pine creek, or",
  },
  {
    postal_code: "58064",
    zip_name: "page, nd",
  },
  {
    postal_code: "25075",
    zip_name: "eskdale, wv",
  },
  {
    postal_code: "36374",
    zip_name: "skipperville, al",
  },
  {
    postal_code: "83013",
    zip_name: "moran, wy",
  },
  {
    postal_code: "62044",
    zip_name: "greenfield, il",
  },
  {
    postal_code: "52249",
    zip_name: "keystone, ia",
  },
  {
    postal_code: "39630",
    zip_name: "bude, ms",
  },
  {
    postal_code: "70542",
    zip_name: "gueydan, la",
  },
  {
    postal_code: "23875",
    zip_name: "prince george, va",
  },
  {
    postal_code: "35645",
    zip_name: "killen, al",
  },
  {
    postal_code: "25841",
    zip_name: "flat top, wv",
  },
  {
    postal_code: "58653",
    zip_name: "scranton, nd",
  },
  {
    postal_code: "85533",
    zip_name: "clifton, az",
  },
  {
    postal_code: "24374",
    zip_name: "speedwell, va",
  },
  {
    postal_code: "82901",
    zip_name: "rock springs, wy",
  },
  {
    postal_code: "83215",
    zip_name: "atomic city, id",
  },
  {
    postal_code: "56713",
    zip_name: "argyle, mn",
  },
  {
    postal_code: "61843",
    zip_name: "fisher, il",
  },
  {
    postal_code: "37213",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "14098",
    zip_name: "lyndonville, ny",
  },
  {
    postal_code: "12432",
    zip_name: "glasco, ny",
  },
  {
    postal_code: "36748",
    zip_name: "linden, al",
  },
  {
    postal_code: "68460",
    zip_name: "waco, ne",
  },
  {
    postal_code: "68874",
    zip_name: "sargent, ne",
  },
  {
    postal_code: "76635",
    zip_name: "coolidge, tx",
  },
  {
    postal_code: "99627",
    zip_name: "mc grath, ak",
  },
  {
    postal_code: "58541",
    zip_name: "golden valley, nd",
  },
  {
    postal_code: "43323",
    zip_name: "harpster, oh",
  },
  {
    postal_code: "40915",
    zip_name: "bimble, ky",
  },
  {
    postal_code: "67738",
    zip_name: "grinnell, ks",
  },
  {
    postal_code: "84712",
    zip_name: "antimony, ut",
  },
  {
    postal_code: "26215",
    zip_name: "cleveland, wv",
  },
  {
    postal_code: "57342",
    zip_name: "geddes, sd",
  },
  {
    postal_code: "25119",
    zip_name: "kincaid, wv",
  },
  {
    postal_code: "96119",
    zip_name: "madeline, ca",
  },
  {
    postal_code: "62421",
    zip_name: "claremont, il",
  },
  {
    postal_code: "97840",
    zip_name: "oxbow, or",
  },
  {
    postal_code: "73660",
    zip_name: "reydon, ok",
  },
  {
    postal_code: "39352",
    zip_name: "porterville, ms",
  },
  {
    postal_code: "97819",
    zip_name: "bridgeport, or",
  },
  {
    postal_code: "81064",
    zip_name: "pritchett, co",
  },
  {
    postal_code: "73756",
    zip_name: "loyal, ok",
  },
  {
    postal_code: "74558",
    zip_name: "nashoba, ok",
  },
  {
    postal_code: "72347",
    zip_name: "hickory ridge, ar",
  },
  {
    postal_code: "96089",
    zip_name: "shasta lake, ca",
  },
  {
    postal_code: "54621",
    zip_name: "chaseburg, wi",
  },
  {
    postal_code: "54152",
    zip_name: "nichols, wi",
  },
  {
    postal_code: "71333",
    zip_name: "evergreen, la",
  },
  {
    postal_code: "88213",
    zip_name: "caprock, nm",
  },
  {
    postal_code: "41528",
    zip_name: "freeburn, ky",
  },
  {
    postal_code: "84784",
    zip_name: "hildale, ut",
  },
  {
    postal_code: "50470",
    zip_name: "rowan, ia",
  },
  {
    postal_code: "56371",
    zip_name: "roscoe, mn",
  },
  {
    postal_code: "97638",
    zip_name: "silver lake, or",
  },
  {
    postal_code: "82224",
    zip_name: "lost springs, wy",
  },
  {
    postal_code: "21675",
    zip_name: "wingate, md",
  },
  {
    postal_code: "16871",
    zip_name: "pottersdale, pa",
  },
  {
    postal_code: "79220",
    zip_name: "afton, tx",
  },
  {
    postal_code: "17080",
    zip_name: "pillow, pa",
  },
  {
    postal_code: "99363",
    zip_name: "wallula, wa",
  },
  {
    postal_code: "76827",
    zip_name: "brookesmith, tx",
  },
  {
    postal_code: "58363",
    zip_name: "perth, nd",
  },
  {
    postal_code: "78122",
    zip_name: "leesville, tx",
  },
  {
    postal_code: "73079",
    zip_name: "pocasset, ok",
  },
  {
    postal_code: "71331",
    zip_name: "effie, la",
  },
  {
    postal_code: "24853",
    zip_name: "kimball, wv",
  },
  {
    postal_code: "68841",
    zip_name: "giltner, ne",
  },
  {
    postal_code: "79247",
    zip_name: "odell, tx",
  },
  {
    postal_code: "31012",
    zip_name: "chester, ga",
  },
  {
    postal_code: "54230",
    zip_name: "reedsville, wi",
  },
  {
    postal_code: "51052",
    zip_name: "salix, ia",
  },
  {
    postal_code: "56039",
    zip_name: "granada, mn",
  },
  {
    postal_code: "28685",
    zip_name: "traphill, nc",
  },
  {
    postal_code: "79772",
    zip_name: "pecos, tx",
  },
  {
    postal_code: "24844",
    zip_name: "iaeger, wv",
  },
  {
    postal_code: "52073",
    zip_name: "sherrill, ia",
  },
  {
    postal_code: "54644",
    zip_name: "mindoro, wi",
  },
  {
    postal_code: "36318",
    zip_name: "coffee springs, al",
  },
  {
    postal_code: "54107",
    zip_name: "bonduel, wi",
  },
  {
    postal_code: "10517",
    zip_name: "crompond, ny",
  },
  {
    postal_code: "36032",
    zip_name: "fort deposit, al",
  },
  {
    postal_code: "58545",
    zip_name: "hazen, nd",
  },
  {
    postal_code: "57268",
    zip_name: "toronto, sd",
  },
  {
    postal_code: "68440",
    zip_name: "steele city, ne",
  },
  {
    postal_code: "16347",
    zip_name: "sheffield, pa",
  },
  {
    postal_code: "20880",
    zip_name: "washington grove, md",
  },
  {
    postal_code: "50583",
    zip_name: "sac city, ia",
  },
  {
    postal_code: "57345",
    zip_name: "highmore, sd",
  },
  {
    postal_code: "6414",
    zip_name: "cobalt, ct",
  },
  {
    postal_code: "48440",
    zip_name: "hadley, mi",
  },
  {
    postal_code: "25287",
    zip_name: "west columbia, wv",
  },
  {
    postal_code: "76364",
    zip_name: "harrold, tx",
  },
  {
    postal_code: "22948",
    zip_name: "locust dale, va",
  },
  {
    postal_code: "77448",
    zip_name: "hungerford, tx",
  },
  {
    postal_code: "94021",
    zip_name: "loma mar, ca",
  },
  {
    postal_code: "57264",
    zip_name: "stockholm, sd",
  },
  {
    postal_code: "25437",
    zip_name: "points, wv",
  },
  {
    postal_code: "26217",
    zip_name: "diana, wv",
  },
  {
    postal_code: "67211",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "61333",
    zip_name: "long point, il",
  },
  {
    postal_code: "53004",
    zip_name: "belgium, wi",
  },
  {
    postal_code: "61751",
    zip_name: "lawndale, il",
  },
  {
    postal_code: "47118",
    zip_name: "english, in",
  },
  {
    postal_code: "17213",
    zip_name: "blairs mills, pa",
  },
  {
    postal_code: "83349",
    zip_name: "richfield, id",
  },
  {
    postal_code: "66088",
    zip_name: "valley falls, ks",
  },
  {
    postal_code: "7646",
    zip_name: "new milford, nj",
  },
  {
    postal_code: "62667",
    zip_name: "modesto, il",
  },
  {
    postal_code: "16233",
    zip_name: "leeper, pa",
  },
  {
    postal_code: "67859",
    zip_name: "kismet, ks",
  },
  {
    postal_code: "1054",
    zip_name: "leverett, ma",
  },
  {
    postal_code: "47601",
    zip_name: "boonville, in",
  },
  {
    postal_code: "29838",
    zip_name: "modoc, sc",
  },
  {
    postal_code: "96008",
    zip_name: "bella vista, ca",
  },
  {
    postal_code: "81059",
    zip_name: "model, co",
  },
  {
    postal_code: "38804",
    zip_name: "tupelo, ms",
  },
  {
    postal_code: "61740",
    zip_name: "flanagan, il",
  },
  {
    postal_code: "59075",
    zip_name: "saint xavier, mt",
  },
  {
    postal_code: "72121",
    zip_name: "pangburn, ar",
  },
  {
    postal_code: "49424",
    zip_name: "holland, mi",
  },
  {
    postal_code: "24915",
    zip_name: "arbovale, wv",
  },
  {
    postal_code: "79229",
    zip_name: "dickens, tx",
  },
  {
    postal_code: "48064",
    zip_name: "casco, mi",
  },
  {
    postal_code: "14478",
    zip_name: "keuka park, ny",
  },
  {
    postal_code: "39897",
    zip_name: "whigham, ga",
  },
  {
    postal_code: "56357",
    zip_name: "oak park, mn",
  },
  {
    postal_code: "65646",
    zip_name: "everton, mo",
  },
  {
    postal_code: "41257",
    zip_name: "stambaugh, ky",
  },
  {
    postal_code: "57252",
    zip_name: "milbank, sd",
  },
  {
    postal_code: "12788",
    zip_name: "woodbourne, ny",
  },
  {
    postal_code: "73008",
    zip_name: "bethany, ok",
  },
  {
    postal_code: "52038",
    zip_name: "dundee, ia",
  },
  {
    postal_code: "17886",
    zip_name: "west milton, pa",
  },
  {
    postal_code: "1072",
    zip_name: "shutesbury, ma",
  },
  {
    postal_code: "68818",
    zip_name: "aurora, ne",
  },
  {
    postal_code: "47993",
    zip_name: "williamsport, in",
  },
  {
    postal_code: "13639",
    zip_name: "fine, ny",
  },
  {
    postal_code: "53207",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "65762",
    zip_name: "thornfield, mo",
  },
  {
    postal_code: "52626",
    zip_name: "farmington, ia",
  },
  {
    postal_code: "92242",
    zip_name: "earp, ca",
  },
  {
    postal_code: "62894",
    zip_name: "waltonville, il",
  },
  {
    postal_code: "24105",
    zip_name: "indian valley, va",
  },
  {
    postal_code: "83253",
    zip_name: "may, id",
  },
  {
    postal_code: "98125",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "51461",
    zip_name: "schleswig, ia",
  },
  {
    postal_code: "14551",
    zip_name: "sodus, ny",
  },
  {
    postal_code: "98819",
    zip_name: "conconully, wa",
  },
  {
    postal_code: "37189",
    zip_name: "whites creek, tn",
  },
  {
    postal_code: "32561",
    zip_name: "gulf breeze, fl",
  },
  {
    postal_code: "98030",
    zip_name: "kent, wa",
  },
  {
    postal_code: "59643",
    zip_name: "toston, mt",
  },
  {
    postal_code: "14206",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "56263",
    zip_name: "milroy, mn",
  },
  {
    postal_code: "95978",
    zip_name: "stirling city, ca",
  },
  {
    postal_code: "14059",
    zip_name: "elma, ny",
  },
  {
    postal_code: "50450",
    zip_name: "lake mills, ia",
  },
  {
    postal_code: "75651",
    zip_name: "harleton, tx",
  },
  {
    postal_code: "70730",
    zip_name: "ethel, la",
  },
  {
    postal_code: "52205",
    zip_name: "anamosa, ia",
  },
  {
    postal_code: "44682",
    zip_name: "tuscarawas, oh",
  },
  {
    postal_code: "36544",
    zip_name: "irvington, al",
  },
  {
    postal_code: "82732",
    zip_name: "wright, wy",
  },
  {
    postal_code: "77990",
    zip_name: "tivoli, tx",
  },
  {
    postal_code: "16343",
    zip_name: "reno, pa",
  },
  {
    postal_code: "13826",
    zip_name: "ouaquaga, ny",
  },
  {
    postal_code: "51036",
    zip_name: "maurice, ia",
  },
  {
    postal_code: "52232",
    zip_name: "hartwick, ia",
  },
  {
    postal_code: "95333",
    zip_name: "le grand, ca",
  },
  {
    postal_code: "78012",
    zip_name: "christine, tx",
  },
  {
    postal_code: "7865",
    zip_name: "port murray, nj",
  },
  {
    postal_code: "98349",
    zip_name: "lakebay, wa",
  },
  {
    postal_code: "4623",
    zip_name: "columbia falls, me",
  },
  {
    postal_code: "49961",
    zip_name: "sidnaw, mi",
  },
  {
    postal_code: "36618",
    zip_name: "mobile, al",
  },
  {
    postal_code: "46792",
    zip_name: "warren, in",
  },
  {
    postal_code: "68624",
    zip_name: "bellwood, ne",
  },
  {
    postal_code: "73565",
    zip_name: "ryan, ok",
  },
  {
    postal_code: "37085",
    zip_name: "lascassas, tn",
  },
  {
    postal_code: "94971",
    zip_name: "tomales, ca",
  },
  {
    postal_code: "74873",
    zip_name: "tecumseh, ok",
  },
  {
    postal_code: "62828",
    zip_name: "dahlgren, il",
  },
  {
    postal_code: "39740",
    zip_name: "caledonia, ms",
  },
  {
    postal_code: "26656",
    zip_name: "belva, wv",
  },
  {
    postal_code: "52727",
    zip_name: "bryant, ia",
  },
  {
    postal_code: "67159",
    zip_name: "zenda, ks",
  },
  {
    postal_code: "25061",
    zip_name: "drybranch, wv",
  },
  {
    postal_code: "82219",
    zip_name: "jay em, wy",
  },
  {
    postal_code: "68773",
    zip_name: "royal, ne",
  },
  {
    postal_code: "41847",
    zip_name: "redfox, ky",
  },
  {
    postal_code: "93673",
    zip_name: "traver, ca",
  },
  {
    postal_code: "62436",
    zip_name: "jewett, il",
  },
  {
    postal_code: "79248",
    zip_name: "paducah, tx",
  },
  {
    postal_code: "4851",
    zip_name: "matinicus, me",
  },
  {
    postal_code: "83223",
    zip_name: "bloomington, id",
  },
  {
    postal_code: "15361",
    zip_name: "southview, pa",
  },
  {
    postal_code: "7513",
    zip_name: "paterson, nj",
  },
  {
    postal_code: "16647",
    zip_name: "hesston, pa",
  },
  {
    postal_code: "64622",
    zip_name: "bogard, mo",
  },
  {
    postal_code: "66840",
    zip_name: "burns, ks",
  },
  {
    postal_code: "97033",
    zip_name: "kent, or",
  },
  {
    postal_code: "73032",
    zip_name: "dougherty, ok",
  },
  {
    postal_code: "68816",
    zip_name: "archer, ne",
  },
  {
    postal_code: "68736",
    zip_name: "fordyce, ne",
  },
  {
    postal_code: "62081",
    zip_name: "rockbridge, il",
  },
  {
    postal_code: "83233",
    zip_name: "dingle, id",
  },
  {
    postal_code: "86032",
    zip_name: "joseph city, az",
  },
  {
    postal_code: "53953",
    zip_name: "packwaukee, wi",
  },
  {
    postal_code: "38944",
    zip_name: "minter city, ms",
  },
  {
    postal_code: "42151",
    zip_name: "hestand, ky",
  },
  {
    postal_code: "73622",
    zip_name: "bessie, ok",
  },
  {
    postal_code: "78588",
    zip_name: "san isidro, tx",
  },
  {
    postal_code: "25161",
    zip_name: "powellton, wv",
  },
  {
    postal_code: "1088",
    zip_name: "west hatfield, ma",
  },
  {
    postal_code: "54214",
    zip_name: "francis creek, wi",
  },
  {
    postal_code: "97911",
    zip_name: "juntura, or",
  },
  {
    postal_code: "45671",
    zip_name: "rarden, oh",
  },
  {
    postal_code: "44825",
    zip_name: "chatfield, oh",
  },
  {
    postal_code: "59032",
    zip_name: "grass range, mt",
  },
  {
    postal_code: "36765",
    zip_name: "newbern, al",
  },
  {
    postal_code: "62553",
    zip_name: "oconee, il",
  },
  {
    postal_code: "15544",
    zip_name: "gray, pa",
  },
  {
    postal_code: "6336",
    zip_name: "gilman, ct",
  },
  {
    postal_code: "99605",
    zip_name: "hope, ak",
  },
  {
    postal_code: "47860",
    zip_name: "mecca, in",
  },
  {
    postal_code: "68337",
    zip_name: "dawson, ne",
  },
  {
    postal_code: "30334",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "58451",
    zip_name: "hurdsfield, nd",
  },
  {
    postal_code: "71008",
    zip_name: "bienville, la",
  },
  {
    postal_code: "62514",
    zip_name: "boody, il",
  },
  {
    postal_code: "18079",
    zip_name: "slatedale, pa",
  },
  {
    postal_code: "57456",
    zip_name: "leola, sd",
  },
  {
    postal_code: "17765",
    zip_name: "roaring branch, pa",
  },
  {
    postal_code: "31827",
    zip_name: "talbotton, ga",
  },
  {
    postal_code: "29082",
    zip_name: "lodge, sc",
  },
  {
    postal_code: "55725",
    zip_name: "crane lake, mn",
  },
  {
    postal_code: "30425",
    zip_name: "garfield, ga",
  },
  {
    postal_code: "69210",
    zip_name: "ainsworth, ne",
  },
  {
    postal_code: "7762",
    zip_name: "spring lake, nj",
  },
  {
    postal_code: "58203",
    zip_name: "grand forks, nd",
  },
  {
    postal_code: "42758",
    zip_name: "mannsville, ky",
  },
  {
    postal_code: "87315",
    zip_name: "fence lake, nm",
  },
  {
    postal_code: "48529",
    zip_name: "burton, mi",
  },
  {
    postal_code: "98575",
    zip_name: "quinault, wa",
  },
  {
    postal_code: "62992",
    zip_name: "ullin, il",
  },
  {
    postal_code: "36314",
    zip_name: "black, al",
  },
  {
    postal_code: "59322",
    zip_name: "cohagen, mt",
  },
  {
    postal_code: "66932",
    zip_name: "athol, ks",
  },
  {
    postal_code: "45721",
    zip_name: "coal run, oh",
  },
  {
    postal_code: "41544",
    zip_name: "mc carr, ky",
  },
  {
    postal_code: "84635",
    zip_name: "hinckley, ut",
  },
  {
    postal_code: "97417",
    zip_name: "canyonville, or",
  },
  {
    postal_code: "21153",
    zip_name: "stevenson, md",
  },
  {
    postal_code: "39741",
    zip_name: "cedarbluff, ms",
  },
  {
    postal_code: "43137",
    zip_name: "lockbourne, oh",
  },
  {
    postal_code: "47514",
    zip_name: "branchville, in",
  },
  {
    postal_code: "75566",
    zip_name: "marietta, tx",
  },
  {
    postal_code: "64657",
    zip_name: "mc fall, mo",
  },
  {
    postal_code: "48022",
    zip_name: "emmett, mi",
  },
  {
    postal_code: "26253",
    zip_name: "beverly, wv",
  },
  {
    postal_code: "23307",
    zip_name: "birdsnest, va",
  },
  {
    postal_code: "54124",
    zip_name: "gillett, wi",
  },
  {
    postal_code: "52141",
    zip_name: "elgin, ia",
  },
  {
    postal_code: "51108",
    zip_name: "sioux city, ia",
  },
  {
    postal_code: "98335",
    zip_name: "gig harbor, wa",
  },
  {
    postal_code: "57110",
    zip_name: "sioux falls, sd",
  },
  {
    postal_code: "10992",
    zip_name: "washingtonville, ny",
  },
  {
    postal_code: "16825",
    zip_name: "bigler, pa",
  },
  {
    postal_code: "39750",
    zip_name: "maben, ms",
  },
  {
    postal_code: "63622",
    zip_name: "belgrade, mo",
  },
  {
    postal_code: "54721",
    zip_name: "arkansaw, wi",
  },
  {
    postal_code: "52325",
    zip_name: "parnell, ia",
  },
  {
    postal_code: "57242",
    zip_name: "hazel, sd",
  },
  {
    postal_code: "73134",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "37212",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "42207",
    zip_name: "bee spring, ky",
  },
  {
    postal_code: "24087",
    zip_name: "elliston, va",
  },
  {
    postal_code: "79533",
    zip_name: "lueders, tx",
  },
  {
    postal_code: "61419",
    zip_name: "bishop hill, il",
  },
  {
    postal_code: "59832",
    zip_name: "drummond, mt",
  },
  {
    postal_code: "26296",
    zip_name: "whitmer, wv",
  },
  {
    postal_code: "59914",
    zip_name: "dayton, mt",
  },
  {
    postal_code: "31650",
    zip_name: "willacoochee, ga",
  },
  {
    postal_code: "44854",
    zip_name: "new washington, oh",
  },
  {
    postal_code: "41606",
    zip_name: "bevinsville, ky",
  },
  {
    postal_code: "89045",
    zip_name: "round mountain, nv",
  },
  {
    postal_code: "63388",
    zip_name: "williamsburg, mo",
  },
  {
    postal_code: "67731",
    zip_name: "bird city, ks",
  },
  {
    postal_code: "58559",
    zip_name: "mercer, nd",
  },
  {
    postal_code: "39149",
    zip_name: "pinola, ms",
  },
  {
    postal_code: "52347",
    zip_name: "victor, ia",
  },
  {
    postal_code: "16362",
    zip_name: "utica, pa",
  },
  {
    postal_code: "46536",
    zip_name: "lakeville, in",
  },
  {
    postal_code: "80024",
    zip_name: "dupont, co",
  },
  {
    postal_code: "56276",
    zip_name: "odessa, mn",
  },
  {
    postal_code: "24161",
    zip_name: "sandy level, va",
  },
  {
    postal_code: "14105",
    zip_name: "middleport, ny",
  },
  {
    postal_code: "50268",
    zip_name: "what cheer, ia",
  },
  {
    postal_code: "78027",
    zip_name: "kendalia, tx",
  },
  {
    postal_code: "37095",
    zip_name: "liberty, tn",
  },
  {
    postal_code: "65729",
    zip_name: "pontiac, mo",
  },
  {
    postal_code: "5772",
    zip_name: "stockbridge, vt",
  },
  {
    postal_code: "42053",
    zip_name: "kevil, ky",
  },
  {
    postal_code: "62469",
    zip_name: "trilla, il",
  },
  {
    postal_code: "33493",
    zip_name: "south bay, fl",
  },
  {
    postal_code: "13696",
    zip_name: "west stockholm, ny",
  },
  {
    postal_code: "40863",
    zip_name: "pathfork, ky",
  },
  {
    postal_code: "99661",
    zip_name: "sand point, ak",
  },
  {
    postal_code: "16228",
    zip_name: "ford cliff, pa",
  },
  {
    postal_code: "14489",
    zip_name: "lyons, ny",
  },
  {
    postal_code: "58571",
    zip_name: "stanton, nd",
  },
  {
    postal_code: "14721",
    zip_name: "ceres, ny",
  },
  {
    postal_code: "35446",
    zip_name: "buhl, al",
  },
  {
    postal_code: "58438",
    zip_name: "fessenden, nd",
  },
  {
    postal_code: "98051",
    zip_name: "ravensdale, wa",
  },
  {
    postal_code: "44844",
    zip_name: "mc cutchenville, oh",
  },
  {
    postal_code: "15450",
    zip_name: "la belle, pa",
  },
  {
    postal_code: "98256",
    zip_name: "index, wa",
  },
  {
    postal_code: "26716",
    zip_name: "eglon, wv",
  },
  {
    postal_code: "72940",
    zip_name: "huntington, ar",
  },
  {
    postal_code: "4460",
    zip_name: "medway, me",
  },
  {
    postal_code: "80735",
    zip_name: "idalia, co",
  },
  {
    postal_code: "7644",
    zip_name: "lodi, nj",
  },
  {
    postal_code: "73645",
    zip_name: "erick, ok",
  },
  {
    postal_code: "24065",
    zip_name: "boones mill, va",
  },
  {
    postal_code: "63439",
    zip_name: "emden, mo",
  },
  {
    postal_code: "21627",
    zip_name: "crocheron, md",
  },
  {
    postal_code: "29044",
    zip_name: "eastover, sc",
  },
  {
    postal_code: "78376",
    zip_name: "realitos, tx",
  },
  {
    postal_code: "13666",
    zip_name: "newton falls, ny",
  },
  {
    postal_code: "15647",
    zip_name: "larimer, pa",
  },
  {
    postal_code: "19980",
    zip_name: "woodside, de",
  },
  {
    postal_code: "46580",
    zip_name: "warsaw, in",
  },
  {
    postal_code: "98004",
    zip_name: "bellevue, wa",
  },
  {
    postal_code: "83868",
    zip_name: "smelterville, id",
  },
  {
    postal_code: "64623",
    zip_name: "bosworth, mo",
  },
  {
    postal_code: "39327",
    zip_name: "decatur, ms",
  },
  {
    postal_code: "85936",
    zip_name: "saint johns, az",
  },
  {
    postal_code: "73639",
    zip_name: "custer city, ok",
  },
  {
    postal_code: "15462",
    zip_name: "melcroft, pa",
  },
  {
    postal_code: "73053",
    zip_name: "lookeba, ok",
  },
  {
    postal_code: "65322",
    zip_name: "blackwater, mo",
  },
  {
    postal_code: "16416",
    zip_name: "garland, pa",
  },
  {
    postal_code: "58061",
    zip_name: "mooreton, nd",
  },
  {
    postal_code: "42528",
    zip_name: "dunnville, ky",
  },
  {
    postal_code: "88132",
    zip_name: "rogers, nm",
  },
  {
    postal_code: "52326",
    zip_name: "quasqueton, ia",
  },
  {
    postal_code: "38859",
    zip_name: "new site, ms",
  },
  {
    postal_code: "52053",
    zip_name: "holy cross, ia",
  },
  {
    postal_code: "99724",
    zip_name: "beaver, ak",
  },
  {
    postal_code: "71661",
    zip_name: "parkdale, ar",
  },
  {
    postal_code: "43330",
    zip_name: "kirby, oh",
  },
  {
    postal_code: "87026",
    zip_name: "laguna, nm",
  },
  {
    postal_code: "99647",
    zip_name: "pedro bay, ak",
  },
  {
    postal_code: "65443",
    zip_name: "brinktown, mo",
  },
  {
    postal_code: "59532",
    zip_name: "kremlin, mt",
  },
  {
    postal_code: "17776",
    zip_name: "waterville, pa",
  },
  {
    postal_code: "83012",
    zip_name: "moose, wy",
  },
  {
    postal_code: "73758",
    zip_name: "manchester, ok",
  },
  {
    postal_code: "57553",
    zip_name: "milesville, sd",
  },
  {
    postal_code: "69360",
    zip_name: "rushville, ne",
  },
  {
    postal_code: "80473",
    zip_name: "rand, co",
  },
  {
    postal_code: "70352",
    zip_name: "donner, la",
  },
  {
    postal_code: "4571",
    zip_name: "trevett, me",
  },
  {
    postal_code: "25030",
    zip_name: "bomont, wv",
  },
  {
    postal_code: "62050",
    zip_name: "hillview, il",
  },
  {
    postal_code: "39045",
    zip_name: "camden, ms",
  },
  {
    postal_code: "67345",
    zip_name: "elk falls, ks",
  },
  {
    postal_code: "72540",
    zip_name: "guion, ar",
  },
  {
    postal_code: "64674",
    zip_name: "purdin, mo",
  },
  {
    postal_code: "99128",
    zip_name: "farmington, wa",
  },
  {
    postal_code: "62630",
    zip_name: "chesterfield, il",
  },
  {
    postal_code: "29683",
    zip_name: "slater, sc",
  },
  {
    postal_code: "39054",
    zip_name: "cary, ms",
  },
  {
    postal_code: "88253",
    zip_name: "lake arthur, nm",
  },
  {
    postal_code: "57235",
    zip_name: "florence, sd",
  },
  {
    postal_code: "72426",
    zip_name: "dell, ar",
  },
  {
    postal_code: "97021",
    zip_name: "dufur, or",
  },
  {
    postal_code: "15624",
    zip_name: "crabtree, pa",
  },
  {
    postal_code: "68001",
    zip_name: "abie, ne",
  },
  {
    postal_code: "32426",
    zip_name: "campbellton, fl",
  },
  {
    postal_code: "24594",
    zip_name: "sutherlin, va",
  },
  {
    postal_code: "64480",
    zip_name: "rea, mo",
  },
  {
    postal_code: "16440",
    zip_name: "venango, pa",
  },
  {
    postal_code: "45388",
    zip_name: "yorkshire, oh",
  },
  {
    postal_code: "58538",
    zip_name: "fort yates, nd",
  },
  {
    postal_code: "36922",
    zip_name: "ward, al",
  },
  {
    postal_code: "54841",
    zip_name: "haugen, wi",
  },
  {
    postal_code: "64651",
    zip_name: "laclede, mo",
  },
  {
    postal_code: "47247",
    zip_name: "jonesville, in",
  },
  {
    postal_code: "14056",
    zip_name: "east pembroke, ny",
  },
  {
    postal_code: "68870",
    zip_name: "riverdale, ne",
  },
  {
    postal_code: "63941",
    zip_name: "fremont, mo",
  },
  {
    postal_code: "74333",
    zip_name: "bluejacket, ok",
  },
  {
    postal_code: "99040",
    zip_name: "wellpinit, wa",
  },
  {
    postal_code: "63544",
    zip_name: "green castle, mo",
  },
  {
    postal_code: "68647",
    zip_name: "monroe, ne",
  },
  {
    postal_code: "65025",
    zip_name: "clarksburg, mo",
  },
  {
    postal_code: "16322",
    zip_name: "endeavor, pa",
  },
  {
    postal_code: "73011",
    zip_name: "bradley, ok",
  },
  {
    postal_code: "64855",
    zip_name: "oronogo, mo",
  },
  {
    postal_code: "70660",
    zip_name: "singer, la",
  },
  {
    postal_code: "68436",
    zip_name: "shickley, ne",
  },
  {
    postal_code: "73015",
    zip_name: "carnegie, ok",
  },
  {
    postal_code: "87002",
    zip_name: "belen, nm",
  },
  {
    postal_code: "73453",
    zip_name: "overbrook, ok",
  },
  {
    postal_code: "67523",
    zip_name: "burdett, ks",
  },
  {
    postal_code: "7422",
    zip_name: "highland lakes, nj",
  },
  {
    postal_code: "82223",
    zip_name: "lingle, wy",
  },
  {
    postal_code: "23707",
    zip_name: "portsmouth, va",
  },
  {
    postal_code: "75468",
    zip_name: "pattonville, tx",
  },
  {
    postal_code: "51354",
    zip_name: "ocheyedan, ia",
  },
  {
    postal_code: "75946",
    zip_name: "garrison, tx",
  },
  {
    postal_code: "58346",
    zip_name: "leeds, nd",
  },
  {
    postal_code: "67045",
    zip_name: "eureka, ks",
  },
  {
    postal_code: "72639",
    zip_name: "harriet, ar",
  },
  {
    postal_code: "98537",
    zip_name: "cosmopolis, wa",
  },
  {
    postal_code: "98943",
    zip_name: "south cle elum, wa",
  },
  {
    postal_code: "62558",
    zip_name: "pawnee, il",
  },
  {
    postal_code: "88321",
    zip_name: "encino, nm",
  },
  {
    postal_code: "95432",
    zip_name: "elk, ca",
  },
  {
    postal_code: "95942",
    zip_name: "forest ranch, ca",
  },
  {
    postal_code: "35035",
    zip_name: "brierfield, al",
  },
  {
    postal_code: "7932",
    zip_name: "florham park, nj",
  },
  {
    postal_code: "25573",
    zip_name: "yawkey, wv",
  },
  {
    postal_code: "51543",
    zip_name: "kimballton, ia",
  },
  {
    postal_code: "11547",
    zip_name: "glenwood landing, ny",
  },
  {
    postal_code: "46987",
    zip_name: "sweetser, in",
  },
  {
    postal_code: "49078",
    zip_name: "otsego, mi",
  },
  {
    postal_code: "46791",
    zip_name: "uniondale, in",
  },
  {
    postal_code: "54458",
    zip_name: "nelsonville, wi",
  },
  {
    postal_code: "58214",
    zip_name: "arvilla, nd",
  },
  {
    postal_code: "67634",
    zip_name: "dorrance, ks",
  },
  {
    postal_code: "58795",
    zip_name: "wildrose, nd",
  },
  {
    postal_code: "81057",
    zip_name: "mc clave, co",
  },
  {
    postal_code: "25986",
    zip_name: "spring dale, wv",
  },
  {
    postal_code: "25209",
    zip_name: "whitesville, wv",
  },
  {
    postal_code: "85352",
    zip_name: "tacna, az",
  },
  {
    postal_code: "31535",
    zip_name: "douglas, ga",
  },
  {
    postal_code: "41616",
    zip_name: "david, ky",
  },
  {
    postal_code: "67849",
    zip_name: "hanston, ks",
  },
  {
    postal_code: "7512",
    zip_name: "totowa, nj",
  },
  {
    postal_code: "29453",
    zip_name: "jamestown, sc",
  },
  {
    postal_code: "78142",
    zip_name: "normanna, tx",
  },
  {
    postal_code: "73750",
    zip_name: "kingfisher, ok",
  },
  {
    postal_code: "96071",
    zip_name: "old station, ca",
  },
  {
    postal_code: "14841",
    zip_name: "hector, ny",
  },
  {
    postal_code: "49009",
    zip_name: "kalamazoo, mi",
  },
  {
    postal_code: "22841",
    zip_name: "mount crawford, va",
  },
  {
    postal_code: "43217",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "59317",
    zip_name: "broadus, mt",
  },
  {
    postal_code: "42159",
    zip_name: "oakland, ky",
  },
  {
    postal_code: "98589",
    zip_name: "tenino, wa",
  },
  {
    postal_code: "68844",
    zip_name: "hazard, ne",
  },
  {
    postal_code: "50515",
    zip_name: "ayrshire, ia",
  },
  {
    postal_code: "58790",
    zip_name: "velva, nd",
  },
  {
    postal_code: "95543",
    zip_name: "gasquet, ca",
  },
  {
    postal_code: "54427",
    zip_name: "eland, wi",
  },
  {
    postal_code: "83632",
    zip_name: "indian valley, id",
  },
  {
    postal_code: "56168",
    zip_name: "rushmore, mn",
  },
  {
    postal_code: "72544",
    zip_name: "henderson, ar",
  },
  {
    postal_code: "76951",
    zip_name: "sterling city, tx",
  },
  {
    postal_code: "35962",
    zip_name: "crossville, al",
  },
  {
    postal_code: "32571",
    zip_name: "milton, fl",
  },
  {
    postal_code: "84735",
    zip_name: "hatch, ut",
  },
  {
    postal_code: "68770",
    zip_name: "ponca, ne",
  },
  {
    postal_code: "64870",
    zip_name: "webb city, mo",
  },
  {
    postal_code: "26325",
    zip_name: "auburn, wv",
  },
  {
    postal_code: "62445",
    zip_name: "montrose, il",
  },
  {
    postal_code: "68628",
    zip_name: "clarks, ne",
  },
  {
    postal_code: "75657",
    zip_name: "jefferson, tx",
  },
  {
    postal_code: "67335",
    zip_name: "cherryvale, ks",
  },
  {
    postal_code: "45333",
    zip_name: "houston, oh",
  },
  {
    postal_code: "58042",
    zip_name: "harwood, nd",
  },
  {
    postal_code: "17867",
    zip_name: "rebuck, pa",
  },
  {
    postal_code: "72534",
    zip_name: "floral, ar",
  },
  {
    postal_code: "98027",
    zip_name: "issaquah, wa",
  },
  {
    postal_code: "17021",
    zip_name: "east waterford, pa",
  },
  {
    postal_code: "49403",
    zip_name: "conklin, mi",
  },
  {
    postal_code: "36832",
    zip_name: "auburn, al",
  },
  {
    postal_code: "16879",
    zip_name: "winburne, pa",
  },
  {
    postal_code: "99824",
    zip_name: "douglas, ak",
  },
  {
    postal_code: "37137",
    zip_name: "nunnelly, tn",
  },
  {
    postal_code: "24520",
    zip_name: "alton, va",
  },
  {
    postal_code: "14132",
    zip_name: "sanborn, ny",
  },
  {
    postal_code: "28448",
    zip_name: "kelly, nc",
  },
  {
    postal_code: "99577",
    zip_name: "eagle river, ak",
  },
  {
    postal_code: "52338",
    zip_name: "swisher, ia",
  },
  {
    postal_code: "88232",
    zip_name: "hagerman, nm",
  },
  {
    postal_code: "75570",
    zip_name: "new boston, tx",
  },
  {
    postal_code: "29135",
    zip_name: "saint matthews, sc",
  },
  {
    postal_code: "10533",
    zip_name: "irvington, ny",
  },
  {
    postal_code: "77803",
    zip_name: "bryan, tx",
  },
  {
    postal_code: "35466",
    zip_name: "gordo, al",
  },
  {
    postal_code: "53020",
    zip_name: "elkhart lake, wi",
  },
  {
    postal_code: "98233",
    zip_name: "burlington, wa",
  },
  {
    postal_code: "10922",
    zip_name: "fort montgomery, ny",
  },
  {
    postal_code: "47541",
    zip_name: "holland, in",
  },
  {
    postal_code: "17302",
    zip_name: "airville, pa",
  },
  {
    postal_code: "50651",
    zip_name: "la porte city, ia",
  },
  {
    postal_code: "52216",
    zip_name: "clarence, ia",
  },
  {
    postal_code: "16859",
    zip_name: "moshannon, pa",
  },
  {
    postal_code: "97810",
    zip_name: "adams, or",
  },
  {
    postal_code: "81022",
    zip_name: "avondale, co",
  },
  {
    postal_code: "48120",
    zip_name: "dearborn, mi",
  },
  {
    postal_code: "40949",
    zip_name: "heidrick, ky",
  },
  {
    postal_code: "98249",
    zip_name: "freeland, wa",
  },
  {
    postal_code: "59936",
    zip_name: "west glacier, mt",
  },
  {
    postal_code: "99755",
    zip_name: "denali national park, ak",
  },
  {
    postal_code: "98940",
    zip_name: "ronald, wa",
  },
  {
    postal_code: "49268",
    zip_name: "palmyra, mi",
  },
  {
    postal_code: "42088",
    zip_name: "wingo, ky",
  },
  {
    postal_code: "37179",
    zip_name: "thompsons station, tn",
  },
  {
    postal_code: "59053",
    zip_name: "martinsdale, mt",
  },
  {
    postal_code: "98019",
    zip_name: "duvall, wa",
  },
  {
    postal_code: "75501",
    zip_name: "texarkana, tx",
  },
  {
    postal_code: "95439",
    zip_name: "fulton, ca",
  },
  {
    postal_code: "62555",
    zip_name: "owaneco, il",
  },
  {
    postal_code: "18701",
    zip_name: "wilkes barre, pa",
  },
  {
    postal_code: "58351",
    zip_name: "minnewaukan, nd",
  },
  {
    postal_code: "78949",
    zip_name: "muldoon, tx",
  },
  {
    postal_code: "21920",
    zip_name: "elk mills, md",
  },
  {
    postal_code: "52355",
    zip_name: "webster, ia",
  },
  {
    postal_code: "15925",
    zip_name: "cassandra, pa",
  },
  {
    postal_code: "85545",
    zip_name: "roosevelt, az",
  },
  {
    postal_code: "38726",
    zip_name: "beulah, ms",
  },
  {
    postal_code: "49970",
    zip_name: "watton, mi",
  },
  {
    postal_code: "28347",
    zip_name: "hoffman, nc",
  },
  {
    postal_code: "99564",
    zip_name: "chignik, ak",
  },
  {
    postal_code: "53922",
    zip_name: "burnett, wi",
  },
  {
    postal_code: "56271",
    zip_name: "murdock, mn",
  },
  {
    postal_code: "58475",
    zip_name: "pettibone, nd",
  },
  {
    postal_code: "35574",
    zip_name: "kennedy, al",
  },
  {
    postal_code: "29592",
    zip_name: "sellers, sc",
  },
  {
    postal_code: "16624",
    zip_name: "chest springs, pa",
  },
  {
    postal_code: "30451",
    zip_name: "pulaski, ga",
  },
  {
    postal_code: "50103",
    zip_name: "garden grove, ia",
  },
  {
    postal_code: "47846",
    zip_name: "cory, in",
  },
  {
    postal_code: "73834",
    zip_name: "buffalo, ok",
  },
  {
    postal_code: "86433",
    zip_name: "oatman, az",
  },
  {
    postal_code: "21051",
    zip_name: "fork, md",
  },
  {
    postal_code: "71677",
    zip_name: "winchester, ar",
  },
  {
    postal_code: "68872",
    zip_name: "saint libory, ne",
  },
  {
    postal_code: "68307",
    zip_name: "avoca, ne",
  },
  {
    postal_code: "52746",
    zip_name: "donahue, ia",
  },
  {
    postal_code: "56122",
    zip_name: "chandler, mn",
  },
  {
    postal_code: "79346",
    zip_name: "morton, tx",
  },
  {
    postal_code: "69125",
    zip_name: "broadwater, ne",
  },
  {
    postal_code: "64750",
    zip_name: "harwood, mo",
  },
  {
    postal_code: "25118",
    zip_name: "kimberly, wv",
  },
  {
    postal_code: "64660",
    zip_name: "mendon, mo",
  },
  {
    postal_code: "67516",
    zip_name: "bazine, ks",
  },
  {
    postal_code: "99621",
    zip_name: "kwethluk, ak",
  },
  {
    postal_code: "59222",
    zip_name: "flaxville, mt",
  },
  {
    postal_code: "50607",
    zip_name: "aurora, ia",
  },
  {
    postal_code: "4984",
    zip_name: "temple, me",
  },
  {
    postal_code: "61416",
    zip_name: "bardolph, il",
  },
  {
    postal_code: "87313",
    zip_name: "crownpoint, nm",
  },
  {
    postal_code: "21624",
    zip_name: "claiborne, md",
  },
  {
    postal_code: "52231",
    zip_name: "harper, ia",
  },
  {
    postal_code: "67484",
    zip_name: "tescott, ks",
  },
  {
    postal_code: "36480",
    zip_name: "uriah, al",
  },
  {
    postal_code: "58734",
    zip_name: "donnybrook, nd",
  },
  {
    postal_code: "71462",
    zip_name: "noble, la",
  },
  {
    postal_code: "59731",
    zip_name: "garrison, mt",
  },
  {
    postal_code: "66432",
    zip_name: "havensville, ks",
  },
  {
    postal_code: "41660",
    zip_name: "teaberry, ky",
  },
  {
    postal_code: "87731",
    zip_name: "montezuma, nm",
  },
  {
    postal_code: "47231",
    zip_name: "dupont, in",
  },
  {
    postal_code: "99321",
    zip_name: "beverly, wa",
  },
  {
    postal_code: "52581",
    zip_name: "plano, ia",
  },
  {
    postal_code: "24011",
    zip_name: "roanoke, va",
  },
  {
    postal_code: "71254",
    zip_name: "lake providence, la",
  },
  {
    postal_code: "64635",
    zip_name: "chula, mo",
  },
  {
    postal_code: "68342",
    zip_name: "diller, ne",
  },
  {
    postal_code: "5077",
    zip_name: "tunbridge, vt",
  },
  {
    postal_code: "15660",
    zip_name: "lowber, pa",
  },
  {
    postal_code: "59830",
    zip_name: "de borgia, mt",
  },
  {
    postal_code: "87828",
    zip_name: "polvadera, nm",
  },
  {
    postal_code: "98466",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "57312",
    zip_name: "alpena, sd",
  },
  {
    postal_code: "85325",
    zip_name: "bouse, az",
  },
  {
    postal_code: "38236",
    zip_name: "mansfield, tn",
  },
  {
    postal_code: "82329",
    zip_name: "medicine bow, wy",
  },
  {
    postal_code: "61467",
    zip_name: "oneida, il",
  },
  {
    postal_code: "54422",
    zip_name: "curtiss, wi",
  },
  {
    postal_code: "16674",
    zip_name: "robertsdale, pa",
  },
  {
    postal_code: "36801",
    zip_name: "opelika, al",
  },
  {
    postal_code: "51031",
    zip_name: "le mars, ia",
  },
  {
    postal_code: "49092",
    zip_name: "tekonsha, mi",
  },
  {
    postal_code: "72564",
    zip_name: "oil trough, ar",
  },
  {
    postal_code: "68864",
    zip_name: "palmer, ne",
  },
  {
    postal_code: "56760",
    zip_name: "viking, mn",
  },
  {
    postal_code: "68821",
    zip_name: "brewster, ne",
  },
  {
    postal_code: "79503",
    zip_name: "avoca, tx",
  },
  {
    postal_code: "76429",
    zip_name: "caddo, tx",
  },
  {
    postal_code: "15959",
    zip_name: "tire hill, pa",
  },
  {
    postal_code: "4746",
    zip_name: "grand isle, me",
  },
  {
    postal_code: "31066",
    zip_name: "musella, ga",
  },
  {
    postal_code: "63935",
    zip_name: "doniphan, mo",
  },
  {
    postal_code: "2367",
    zip_name: "plympton, ma",
  },
  {
    postal_code: "38488",
    zip_name: "taft, tn",
  },
  {
    postal_code: "81430",
    zip_name: "placerville, co",
  },
  {
    postal_code: "52322",
    zip_name: "oxford, ia",
  },
  {
    postal_code: "58843",
    zip_name: "epping, nd",
  },
  {
    postal_code: "50648",
    zip_name: "jesup, ia",
  },
  {
    postal_code: "72823",
    zip_name: "atkins, ar",
  },
  {
    postal_code: "98023",
    zip_name: "federal way, wa",
  },
  {
    postal_code: "57226",
    zip_name: "clear lake, sd",
  },
  {
    postal_code: "72555",
    zip_name: "marcella, ar",
  },
  {
    postal_code: "67481",
    zip_name: "sylvan grove, ks",
  },
  {
    postal_code: "44859",
    zip_name: "nova, oh",
  },
  {
    postal_code: "93266",
    zip_name: "stratford, ca",
  },
  {
    postal_code: "87416",
    zip_name: "fruitland, nm",
  },
  {
    postal_code: "76430",
    zip_name: "albany, tx",
  },
  {
    postal_code: "79029",
    zip_name: "dumas, tx",
  },
  {
    postal_code: "58278",
    zip_name: "thompson, nd",
  },
  {
    postal_code: "72739",
    zip_name: "hiwasse, ar",
  },
  {
    postal_code: "53042",
    zip_name: "kiel, wi",
  },
  {
    postal_code: "48314",
    zip_name: "sterling heights, mi",
  },
  {
    postal_code: "83842",
    zip_name: "medimont, id",
  },
  {
    postal_code: "35036",
    zip_name: "brookside, al",
  },
  {
    postal_code: "74002",
    zip_name: "barnsdall, ok",
  },
  {
    postal_code: "65631",
    zip_name: "clever, mo",
  },
  {
    postal_code: "97351",
    zip_name: "independence, or",
  },
  {
    postal_code: "74343",
    zip_name: "fairland, ok",
  },
  {
    postal_code: "75172",
    zip_name: "wilmer, tx",
  },
  {
    postal_code: "67656",
    zip_name: "ogallah, ks",
  },
  {
    postal_code: "15412",
    zip_name: "allenport, pa",
  },
  {
    postal_code: "71079",
    zip_name: "summerfield, la",
  },
  {
    postal_code: "30477",
    zip_name: "wadley, ga",
  },
  {
    postal_code: "86023",
    zip_name: "grand canyon, az",
  },
  {
    postal_code: "84026",
    zip_name: "fort duchesne, ut",
  },
  {
    postal_code: "46076",
    zip_name: "windfall, in",
  },
  {
    postal_code: "49929",
    zip_name: "greenland, mi",
  },
  {
    postal_code: "35087",
    zip_name: "joppa, al",
  },
  {
    postal_code: "49453",
    zip_name: "saugatuck, mi",
  },
  {
    postal_code: "47427",
    zip_name: "coal city, in",
  },
  {
    postal_code: "24017",
    zip_name: "roanoke, va",
  },
  {
    postal_code: "74857",
    zip_name: "newalla, ok",
  },
  {
    postal_code: "20630",
    zip_name: "drayden, md",
  },
  {
    postal_code: "73099",
    zip_name: "yukon, ok",
  },
  {
    postal_code: "84656",
    zip_name: "scipio, ut",
  },
  {
    postal_code: "67735",
    zip_name: "goodland, ks",
  },
  {
    postal_code: "42764",
    zip_name: "mount sherman, ky",
  },
  {
    postal_code: "47861",
    zip_name: "merom, in",
  },
  {
    postal_code: "22639",
    zip_name: "hume, va",
  },
  {
    postal_code: "49444",
    zip_name: "muskegon, mi",
  },
  {
    postal_code: "14706",
    zip_name: "allegany, ny",
  },
  {
    postal_code: "12518",
    zip_name: "cornwall, ny",
  },
  {
    postal_code: "71726",
    zip_name: "chidester, ar",
  },
  {
    postal_code: "58228",
    zip_name: "emerado, nd",
  },
  {
    postal_code: "68341",
    zip_name: "de witt, ne",
  },
  {
    postal_code: "80230",
    zip_name: "denver, co",
  },
  {
    postal_code: "64071",
    zip_name: "mayview, mo",
  },
  {
    postal_code: "83346",
    zip_name: "oakley, id",
  },
  {
    postal_code: "37333",
    zip_name: "farner, tn",
  },
  {
    postal_code: "62420",
    zip_name: "casey, il",
  },
  {
    postal_code: "82649",
    zip_name: "shoshoni, wy",
  },
  {
    postal_code: "57273",
    zip_name: "waubay, sd",
  },
  {
    postal_code: "41858",
    zip_name: "whitesburg, ky",
  },
  {
    postal_code: "28623",
    zip_name: "ennice, nc",
  },
  {
    postal_code: "96110",
    zip_name: "eagleville, ca",
  },
  {
    postal_code: "37122",
    zip_name: "mount juliet, tn",
  },
  {
    postal_code: "23192",
    zip_name: "montpelier, va",
  },
  {
    postal_code: "74854",
    zip_name: "maud, ok",
  },
  {
    postal_code: "37405",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "67491",
    zip_name: "windom, ks",
  },
  {
    postal_code: "61473",
    zip_name: "roseville, il",
  },
  {
    postal_code: "73951",
    zip_name: "tyrone, ok",
  },
  {
    postal_code: "85554",
    zip_name: "young, az",
  },
  {
    postal_code: "73456",
    zip_name: "ringling, ok",
  },
  {
    postal_code: "46978",
    zip_name: "royal center, in",
  },
  {
    postal_code: "72433",
    zip_name: "hoxie, ar",
  },
  {
    postal_code: "17264",
    zip_name: "three springs, pa",
  },
  {
    postal_code: "22932",
    zip_name: "crozet, va",
  },
  {
    postal_code: "54247",
    zip_name: "whitelaw, wi",
  },
  {
    postal_code: "72042",
    zip_name: "de witt, ar",
  },
  {
    postal_code: "31518",
    zip_name: "bristol, ga",
  },
  {
    postal_code: "68781",
    zip_name: "tilden, ne",
  },
  {
    postal_code: "80807",
    zip_name: "burlington, co",
  },
  {
    postal_code: "7446",
    zip_name: "ramsey, nj",
  },
  {
    postal_code: "51050",
    zip_name: "remsen, ia",
  },
  {
    postal_code: "24477",
    zip_name: "stuarts draft, va",
  },
  {
    postal_code: "52310",
    zip_name: "monticello, ia",
  },
  {
    postal_code: "56207",
    zip_name: "alberta, mn",
  },
  {
    postal_code: "38011",
    zip_name: "brighton, tn",
  },
  {
    postal_code: "43802",
    zip_name: "adamsville, oh",
  },
  {
    postal_code: "16821",
    zip_name: "allport, pa",
  },
  {
    postal_code: "68335",
    zip_name: "davenport, ne",
  },
  {
    postal_code: "72085",
    zip_name: "letona, ar",
  },
  {
    postal_code: "47871",
    zip_name: "riley, in",
  },
  {
    postal_code: "15738",
    zip_name: "emeigh, pa",
  },
  {
    postal_code: "81081",
    zip_name: "trinchera, co",
  },
  {
    postal_code: "72458",
    zip_name: "powhatan, ar",
  },
  {
    postal_code: "36754",
    zip_name: "magnolia, al",
  },
  {
    postal_code: "59081",
    zip_name: "silver gate, mt",
  },
  {
    postal_code: "15712",
    zip_name: "arcadia, pa",
  },
  {
    postal_code: "57246",
    zip_name: "labolt, sd",
  },
  {
    postal_code: "62011",
    zip_name: "bingham, il",
  },
  {
    postal_code: "67639",
    zip_name: "glade, ks",
  },
  {
    postal_code: "77585",
    zip_name: "saratoga, tx",
  },
  {
    postal_code: "45808",
    zip_name: "beaverdam, oh",
  },
  {
    postal_code: "10953",
    zip_name: "mountainville, ny",
  },
  {
    postal_code: "38844",
    zip_name: "gattman, ms",
  },
  {
    postal_code: "25567",
    zip_name: "sumerco, wv",
  },
  {
    postal_code: "82731",
    zip_name: "weston, wy",
  },
  {
    postal_code: "68631",
    zip_name: "creston, ne",
  },
  {
    postal_code: "62928",
    zip_name: "eddyville, il",
  },
  {
    postal_code: "38674",
    zip_name: "tiplersville, ms",
  },
  {
    postal_code: "64453",
    zip_name: "gentry, mo",
  },
  {
    postal_code: "15827",
    zip_name: "byrnedale, pa",
  },
  {
    postal_code: "67061",
    zip_name: "hazelton, ks",
  },
  {
    postal_code: "63833",
    zip_name: "catron, mo",
  },
  {
    postal_code: "29452",
    zip_name: "jacksonboro, sc",
  },
  {
    postal_code: "38947",
    zip_name: "north carrollton, ms",
  },
  {
    postal_code: "57259",
    zip_name: "revillo, sd",
  },
  {
    postal_code: "51572",
    zip_name: "soldier, ia",
  },
  {
    postal_code: "96063",
    zip_name: "mineral, ca",
  },
  {
    postal_code: "38929",
    zip_name: "gore springs, ms",
  },
  {
    postal_code: "41268",
    zip_name: "west van lear, ky",
  },
  {
    postal_code: "75247",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "78963",
    zip_name: "west point, tx",
  },
  {
    postal_code: "66724",
    zip_name: "cherokee, ks",
  },
  {
    postal_code: "73646",
    zip_name: "fay, ok",
  },
  {
    postal_code: "74472",
    zip_name: "whitefield, ok",
  },
  {
    postal_code: "66959",
    zip_name: "munden, ks",
  },
  {
    postal_code: "71375",
    zip_name: "waterproof, la",
  },
  {
    postal_code: "82516",
    zip_name: "kinnear, wy",
  },
  {
    postal_code: "24962",
    zip_name: "pence springs, wv",
  },
  {
    postal_code: "69134",
    zip_name: "elsie, ne",
  },
  {
    postal_code: "66520",
    zip_name: "olsburg, ks",
  },
  {
    postal_code: "73569",
    zip_name: "terral, ok",
  },
  {
    postal_code: "56568",
    zip_name: "nielsville, mn",
  },
  {
    postal_code: "62515",
    zip_name: "buffalo, il",
  },
  {
    postal_code: "24151",
    zip_name: "rocky mount, va",
  },
  {
    postal_code: "54948",
    zip_name: "leopolis, wi",
  },
  {
    postal_code: "67030",
    zip_name: "colwich, ks",
  },
  {
    postal_code: "30824",
    zip_name: "thomson, ga",
  },
  {
    postal_code: "66868",
    zip_name: "reading, ks",
  },
  {
    postal_code: "47339",
    zip_name: "economy, in",
  },
  {
    postal_code: "51520",
    zip_name: "arion, ia",
  },
  {
    postal_code: "71432",
    zip_name: "georgetown, la",
  },
  {
    postal_code: "39301",
    zip_name: "meridian, ms",
  },
  {
    postal_code: "2020",
    zip_name: "brant rock, ma",
  },
  {
    postal_code: "98354",
    zip_name: "milton, wa",
  },
  {
    postal_code: "30755",
    zip_name: "tunnel hill, ga",
  },
  {
    postal_code: "14041",
    zip_name: "dayton, ny",
  },
  {
    postal_code: "30820",
    zip_name: "mitchell, ga",
  },
  {
    postal_code: "53220",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "79739",
    zip_name: "garden city, tx",
  },
  {
    postal_code: "56292",
    zip_name: "vesta, mn",
  },
  {
    postal_code: "97523",
    zip_name: "cave junction, or",
  },
  {
    postal_code: "82324",
    zip_name: "elk mountain, wy",
  },
  {
    postal_code: "17237",
    zip_name: "mont alto, pa",
  },
  {
    postal_code: "72057",
    zip_name: "grapevine, ar",
  },
  {
    postal_code: "57367",
    zip_name: "pickstown, sd",
  },
  {
    postal_code: "38253",
    zip_name: "rives, tn",
  },
  {
    postal_code: "48002",
    zip_name: "allenton, mi",
  },
  {
    postal_code: "37075",
    zip_name: "hendersonville, tn",
  },
  {
    postal_code: "97544",
    zip_name: "williams, or",
  },
  {
    postal_code: "63557",
    zip_name: "new boston, mo",
  },
  {
    postal_code: "93666",
    zip_name: "sultana, ca",
  },
  {
    postal_code: "95417",
    zip_name: "branscomb, ca",
  },
  {
    postal_code: "15617",
    zip_name: "arona, pa",
  },
  {
    postal_code: "38339",
    zip_name: "guys, tn",
  },
  {
    postal_code: "52769",
    zip_name: "stockton, ia",
  },
  {
    postal_code: "97336",
    zip_name: "crawfordsville, or",
  },
  {
    postal_code: "50431",
    zip_name: "coulter, ia",
  },
  {
    postal_code: "64686",
    zip_name: "utica, mo",
  },
  {
    postal_code: "71758",
    zip_name: "mount holly, ar",
  },
  {
    postal_code: "98558",
    zip_name: "mckenna, wa",
  },
  {
    postal_code: "38676",
    zip_name: "tunica, ms",
  },
  {
    postal_code: "73446",
    zip_name: "madill, ok",
  },
  {
    postal_code: "39176",
    zip_name: "vaiden, ms",
  },
  {
    postal_code: "23092",
    zip_name: "locust hill, va",
  },
  {
    postal_code: "37172",
    zip_name: "springfield, tn",
  },
  {
    postal_code: "95571",
    zip_name: "weott, ca",
  },
  {
    postal_code: "30056",
    zip_name: "newborn, ga",
  },
  {
    postal_code: "72479",
    zip_name: "weiner, ar",
  },
  {
    postal_code: "97632",
    zip_name: "malin, or",
  },
  {
    postal_code: "27014",
    zip_name: "cooleemee, nc",
  },
  {
    postal_code: "64459",
    zip_name: "helena, mo",
  },
  {
    postal_code: "37209",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "23452",
    zip_name: "virginia beach, va",
  },
  {
    postal_code: "41219",
    zip_name: "flatgap, ky",
  },
  {
    postal_code: "15863",
    zip_name: "stump creek, pa",
  },
  {
    postal_code: "23702",
    zip_name: "portsmouth, va",
  },
  {
    postal_code: "74450",
    zip_name: "oktaha, ok",
  },
  {
    postal_code: "98104",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "97833",
    zip_name: "haines, or",
  },
  {
    postal_code: "65624",
    zip_name: "cape fair, mo",
  },
  {
    postal_code: "48755",
    zip_name: "pigeon, mi",
  },
  {
    postal_code: "72461",
    zip_name: "rector, ar",
  },
  {
    postal_code: "12071",
    zip_name: "fultonham, ny",
  },
  {
    postal_code: "31826",
    zip_name: "shiloh, ga",
  },
  {
    postal_code: "47034",
    zip_name: "napoleon, in",
  },
  {
    postal_code: "38857",
    zip_name: "mooreville, ms",
  },
  {
    postal_code: "78579",
    zip_name: "progreso, tx",
  },
  {
    postal_code: "42716",
    zip_name: "buffalo, ky",
  },
  {
    postal_code: "85553",
    zip_name: "tonto basin, az",
  },
  {
    postal_code: "71461",
    zip_name: "new llano, la",
  },
  {
    postal_code: "35575",
    zip_name: "lynn, al",
  },
  {
    postal_code: "38457",
    zip_name: "five points, tn",
  },
  {
    postal_code: "50842",
    zip_name: "cromwell, ia",
  },
  {
    postal_code: "26411",
    zip_name: "new milton, wv",
  },
  {
    postal_code: "54962",
    zip_name: "ogdensburg, wi",
  },
  {
    postal_code: "47615",
    zip_name: "grandview, in",
  },
  {
    postal_code: "50133",
    zip_name: "kellerton, ia",
  },
  {
    postal_code: "57567",
    zip_name: "philip, sd",
  },
  {
    postal_code: "58636",
    zip_name: "halliday, nd",
  },
  {
    postal_code: "16311",
    zip_name: "carlton, pa",
  },
  {
    postal_code: "5351",
    zip_name: "south newfane, vt",
  },
  {
    postal_code: "4024",
    zip_name: "east baldwin, me",
  },
  {
    postal_code: "53189",
    zip_name: "waukesha, wi",
  },
  {
    postal_code: "98042",
    zip_name: "kent, wa",
  },
  {
    postal_code: "65580",
    zip_name: "vichy, mo",
  },
  {
    postal_code: "61872",
    zip_name: "sadorus, il",
  },
  {
    postal_code: "53051",
    zip_name: "menomonee falls, wi",
  },
  {
    postal_code: "70780",
    zip_name: "sunshine, la",
  },
  {
    postal_code: "15828",
    zip_name: "clarington, pa",
  },
  {
    postal_code: "43760",
    zip_name: "mount perry, oh",
  },
  {
    postal_code: "45340",
    zip_name: "maplewood, oh",
  },
  {
    postal_code: "43843",
    zip_name: "walhonding, oh",
  },
  {
    postal_code: "26136",
    zip_name: "big bend, wv",
  },
  {
    postal_code: "52132",
    zip_name: "calmar, ia",
  },
  {
    postal_code: "31639",
    zip_name: "nashville, ga",
  },
  {
    postal_code: "92003",
    zip_name: "bonsall, ca",
  },
  {
    postal_code: "51038",
    zip_name: "merrill, ia",
  },
  {
    postal_code: "44427",
    zip_name: "kensington, oh",
  },
  {
    postal_code: "44699",
    zip_name: "tippecanoe, oh",
  },
  {
    postal_code: "39766",
    zip_name: "steens, ms",
  },
  {
    postal_code: "39665",
    zip_name: "sontag, ms",
  },
  {
    postal_code: "68370",
    zip_name: "hebron, ne",
  },
  {
    postal_code: "61942",
    zip_name: "newman, il",
  },
  {
    postal_code: "43468",
    zip_name: "williston, oh",
  },
  {
    postal_code: "87413",
    zip_name: "bloomfield, nm",
  },
  {
    postal_code: "49232",
    zip_name: "camden, mi",
  },
  {
    postal_code: "30421",
    zip_name: "collins, ga",
  },
  {
    postal_code: "54627",
    zip_name: "ettrick, wi",
  },
  {
    postal_code: "23055",
    zip_name: "fork union, va",
  },
  {
    postal_code: "59442",
    zip_name: "fort benton, mt",
  },
  {
    postal_code: "79010",
    zip_name: "boys ranch, tx",
  },
  {
    postal_code: "31566",
    zip_name: "waynesville, ga",
  },
  {
    postal_code: "93641",
    zip_name: "miramonte, ca",
  },
  {
    postal_code: "65652",
    zip_name: "fordland, mo",
  },
  {
    postal_code: "51008",
    zip_name: "brunsville, ia",
  },
  {
    postal_code: "15078",
    zip_name: "slovan, pa",
  },
  {
    postal_code: "24131",
    zip_name: "paint bank, va",
  },
  {
    postal_code: "74549",
    zip_name: "honobia, ok",
  },
  {
    postal_code: "73744",
    zip_name: "hitchcock, ok",
  },
  {
    postal_code: "16732",
    zip_name: "gifford, pa",
  },
  {
    postal_code: "97625",
    zip_name: "dairy, or",
  },
  {
    postal_code: "56160",
    zip_name: "odin, mn",
  },
  {
    postal_code: "49780",
    zip_name: "rudyard, mi",
  },
  {
    postal_code: "59929",
    zip_name: "proctor, mt",
  },
  {
    postal_code: "74552",
    zip_name: "kinta, ok",
  },
  {
    postal_code: "15734",
    zip_name: "dixonville, pa",
  },
  {
    postal_code: "65777",
    zip_name: "moody, mo",
  },
  {
    postal_code: "45782",
    zip_name: "trimble, oh",
  },
  {
    postal_code: "43779",
    zip_name: "sarahsville, oh",
  },
  {
    postal_code: "95974",
    zip_name: "richvale, ca",
  },
  {
    postal_code: "56737",
    zip_name: "middle river, mn",
  },
  {
    postal_code: "47522",
    zip_name: "crane, in",
  },
  {
    postal_code: "81036",
    zip_name: "eads, co",
  },
  {
    postal_code: "41843",
    zip_name: "pine top, ky",
  },
  {
    postal_code: "74652",
    zip_name: "shidler, ok",
  },
  {
    postal_code: "66429",
    zip_name: "grantville, ks",
  },
  {
    postal_code: "52312",
    zip_name: "morley, ia",
  },
  {
    postal_code: "16220",
    zip_name: "crown, pa",
  },
  {
    postal_code: "69161",
    zip_name: "seneca, ne",
  },
  {
    postal_code: "26412",
    zip_name: "orlando, wv",
  },
  {
    postal_code: "51548",
    zip_name: "mc clelland, ia",
  },
  {
    postal_code: "45062",
    zip_name: "seven mile, oh",
  },
  {
    postal_code: "56118",
    zip_name: "bingham lake, mn",
  },
  {
    postal_code: "23488",
    zip_name: "withams, va",
  },
  {
    postal_code: "57065",
    zip_name: "trent, sd",
  },
  {
    postal_code: "25511",
    zip_name: "dunlow, wv",
  },
  {
    postal_code: "73730",
    zip_name: "covington, ok",
  },
  {
    postal_code: "97634",
    zip_name: "midland, or",
  },
  {
    postal_code: "97817",
    zip_name: "bates, or",
  },
  {
    postal_code: "34661",
    zip_name: "nobleton, fl",
  },
  {
    postal_code: "78594",
    zip_name: "sebastian, tx",
  },
  {
    postal_code: "67447",
    zip_name: "green, ks",
  },
  {
    postal_code: "57369",
    zip_name: "platte, sd",
  },
  {
    postal_code: "36858",
    zip_name: "hatchechubbee, al",
  },
  {
    postal_code: "72650",
    zip_name: "marshall, ar",
  },
  {
    postal_code: "50029",
    zip_name: "bayard, ia",
  },
  {
    postal_code: "25812",
    zip_name: "ansted, wv",
  },
  {
    postal_code: "10543",
    zip_name: "mamaroneck, ny",
  },
  {
    postal_code: "81431",
    zip_name: "redvale, co",
  },
  {
    postal_code: "84051",
    zip_name: "mountain home, ut",
  },
  {
    postal_code: "67869",
    zip_name: "plains, ks",
  },
  {
    postal_code: "17888",
    zip_name: "wilburton, pa",
  },
  {
    postal_code: "35594",
    zip_name: "winfield, al",
  },
  {
    postal_code: "13667",
    zip_name: "norfolk, ny",
  },
  {
    postal_code: "49282",
    zip_name: "somerset center, mi",
  },
  {
    postal_code: "65570",
    zip_name: "success, mo",
  },
  {
    postal_code: "96016",
    zip_name: "cassel, ca",
  },
  {
    postal_code: "45864",
    zip_name: "miller city, oh",
  },
  {
    postal_code: "14803",
    zip_name: "alfred station, ny",
  },
  {
    postal_code: "95937",
    zip_name: "dunnigan, ca",
  },
  {
    postal_code: "98922",
    zip_name: "cle elum, wa",
  },
  {
    postal_code: "36871",
    zip_name: "pittsview, al",
  },
  {
    postal_code: "62443",
    zip_name: "mason, il",
  },
  {
    postal_code: "72152",
    zip_name: "sherrill, ar",
  },
  {
    postal_code: "42054",
    zip_name: "kirksey, ky",
  },
  {
    postal_code: "73049",
    zip_name: "jones, ok",
  },
  {
    postal_code: "14783",
    zip_name: "steamburg, ny",
  },
  {
    postal_code: "57437",
    zip_name: "eureka, sd",
  },
  {
    postal_code: "66041",
    zip_name: "lancaster, ks",
  },
  {
    postal_code: "18254",
    zip_name: "tresckow, pa",
  },
  {
    postal_code: "59424",
    zip_name: "coffee creek, mt",
  },
  {
    postal_code: "26750",
    zip_name: "piedmont, wv",
  },
  {
    postal_code: "57260",
    zip_name: "rosholt, sd",
  },
  {
    postal_code: "67445",
    zip_name: "glasco, ks",
  },
  {
    postal_code: "16848",
    zip_name: "lamar, pa",
  },
  {
    postal_code: "59419",
    zip_name: "bynum, mt",
  },
  {
    postal_code: "43359",
    zip_name: "wharton, oh",
  },
  {
    postal_code: "12507",
    zip_name: "barrytown, ny",
  },
  {
    postal_code: "73058",
    zip_name: "meridian, ok",
  },
  {
    postal_code: "39744",
    zip_name: "eupora, ms",
  },
  {
    postal_code: "7075",
    zip_name: "wood ridge, nj",
  },
  {
    postal_code: "30411",
    zip_name: "alamo, ga",
  },
  {
    postal_code: "67565",
    zip_name: "otis, ks",
  },
  {
    postal_code: "58225",
    zip_name: "drayton, nd",
  },
  {
    postal_code: "70807",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "29063",
    zip_name: "irmo, sc",
  },
  {
    postal_code: "35620",
    zip_name: "elkmont, al",
  },
  {
    postal_code: "88318",
    zip_name: "corona, nm",
  },
  {
    postal_code: "38736",
    zip_name: "doddsville, ms",
  },
  {
    postal_code: "98028",
    zip_name: "kenmore, wa",
  },
  {
    postal_code: "46761",
    zip_name: "lagrange, in",
  },
  {
    postal_code: "25039",
    zip_name: "cedar grove, wv",
  },
  {
    postal_code: "37034",
    zip_name: "chapel hill, tn",
  },
  {
    postal_code: "98366",
    zip_name: "port orchard, wa",
  },
  {
    postal_code: "56178",
    zip_name: "tyler, mn",
  },
  {
    postal_code: "16829",
    zip_name: "clarence, pa",
  },
  {
    postal_code: "76861",
    zip_name: "miles, tx",
  },
  {
    postal_code: "84540",
    zip_name: "thompson, ut",
  },
  {
    postal_code: "37347",
    zip_name: "jasper, tn",
  },
  {
    postal_code: "30537",
    zip_name: "dillard, ga",
  },
  {
    postal_code: "42361",
    zip_name: "olaton, ky",
  },
  {
    postal_code: "24715",
    zip_name: "bramwell, wv",
  },
  {
    postal_code: "73641",
    zip_name: "dill city, ok",
  },
  {
    postal_code: "58472",
    zip_name: "montpelier, nd",
  },
  {
    postal_code: "58425",
    zip_name: "cooperstown, nd",
  },
  {
    postal_code: "16865",
    zip_name: "pennsylvania furnace, pa",
  },
  {
    postal_code: "23833",
    zip_name: "church road, va",
  },
  {
    postal_code: "61850",
    zip_name: "indianola, il",
  },
  {
    postal_code: "99013",
    zip_name: "ford, wa",
  },
  {
    postal_code: "16329",
    zip_name: "irvine, pa",
  },
  {
    postal_code: "49706",
    zip_name: "alanson, mi",
  },
  {
    postal_code: "31790",
    zip_name: "sycamore, ga",
  },
  {
    postal_code: "66733",
    zip_name: "erie, ks",
  },
  {
    postal_code: "97825",
    zip_name: "dayville, or",
  },
  {
    postal_code: "68763",
    zip_name: "oneill, ne",
  },
  {
    postal_code: "30218",
    zip_name: "gay, ga",
  },
  {
    postal_code: "62433",
    zip_name: "hutsonville, il",
  },
  {
    postal_code: "25247",
    zip_name: "hartford, wv",
  },
  {
    postal_code: "50436",
    zip_name: "forest city, ia",
  },
  {
    postal_code: "31032",
    zip_name: "gray, ga",
  },
  {
    postal_code: "50680",
    zip_name: "wellsburg, ia",
  },
  {
    postal_code: "99921",
    zip_name: "craig, ak",
  },
  {
    postal_code: "68757",
    zip_name: "newcastle, ne",
  },
  {
    postal_code: "36263",
    zip_name: "graham, al",
  },
  {
    postal_code: "49401",
    zip_name: "allendale, mi",
  },
  {
    postal_code: "51023",
    zip_name: "hawarden, ia",
  },
  {
    postal_code: "49760",
    zip_name: "moran, mi",
  },
  {
    postal_code: "57016",
    zip_name: "chester, sd",
  },
  {
    postal_code: "38759",
    zip_name: "merigold, ms",
  },
  {
    postal_code: "79373",
    zip_name: "tahoka, tx",
  },
  {
    postal_code: "98392",
    zip_name: "suquamish, wa",
  },
  {
    postal_code: "23938",
    zip_name: "dundas, va",
  },
  {
    postal_code: "65655",
    zip_name: "gainesville, mo",
  },
  {
    postal_code: "62431",
    zip_name: "herrick, il",
  },
  {
    postal_code: "42215",
    zip_name: "cerulean, ky",
  },
  {
    postal_code: "74349",
    zip_name: "ketchum, ok",
  },
  {
    postal_code: "24467",
    zip_name: "mount sidney, va",
  },
  {
    postal_code: "17981",
    zip_name: "tremont, pa",
  },
  {
    postal_code: "55796",
    zip_name: "winton, mn",
  },
  {
    postal_code: "43926",
    zip_name: "empire, oh",
  },
  {
    postal_code: "62923",
    zip_name: "cypress, il",
  },
  {
    postal_code: "53186",
    zip_name: "waukesha, wi",
  },
  {
    postal_code: "76872",
    zip_name: "rochelle, tx",
  },
  {
    postal_code: "24485",
    zip_name: "west augusta, va",
  },
  {
    postal_code: "14143",
    zip_name: "stafford, ny",
  },
  {
    postal_code: "41093",
    zip_name: "wallingford, ky",
  },
  {
    postal_code: "31798",
    zip_name: "wray, ga",
  },
  {
    postal_code: "65333",
    zip_name: "houstonia, mo",
  },
  {
    postal_code: "15744",
    zip_name: "hamilton, pa",
  },
  {
    postal_code: "16636",
    zip_name: "dysart, pa",
  },
  {
    postal_code: "49097",
    zip_name: "vicksburg, mi",
  },
  {
    postal_code: "48028",
    zip_name: "harsens island, mi",
  },
  {
    postal_code: "31903",
    zip_name: "columbus, ga",
  },
  {
    postal_code: "5071",
    zip_name: "south woodstock, vt",
  },
  {
    postal_code: "10987",
    zip_name: "tuxedo park, ny",
  },
  {
    postal_code: "10960",
    zip_name: "nyack, ny",
  },
  {
    postal_code: "58448",
    zip_name: "hannaford, nd",
  },
  {
    postal_code: "37183",
    zip_name: "wartrace, tn",
  },
  {
    postal_code: "98827",
    zip_name: "loomis, wa",
  },
  {
    postal_code: "95922",
    zip_name: "camptonville, ca",
  },
  {
    postal_code: "46994",
    zip_name: "walton, in",
  },
  {
    postal_code: "98065",
    zip_name: "snoqualmie, wa",
  },
  {
    postal_code: "38126",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "83677",
    zip_name: "yellow pine, id",
  },
  {
    postal_code: "71358",
    zip_name: "palmetto, la",
  },
  {
    postal_code: "56116",
    zip_name: "beaver creek, mn",
  },
  {
    postal_code: "71970",
    zip_name: "story, ar",
  },
  {
    postal_code: "31780",
    zip_name: "plains, ga",
  },
  {
    postal_code: "54417",
    zip_name: "brokaw, wi",
  },
  {
    postal_code: "24726",
    zip_name: "herndon, wv",
  },
  {
    postal_code: "64092",
    zip_name: "waldron, mo",
  },
  {
    postal_code: "17723",
    zip_name: "cammal, pa",
  },
  {
    postal_code: "68842",
    zip_name: "greeley, ne",
  },
  {
    postal_code: "35460",
    zip_name: "epes, al",
  },
  {
    postal_code: "47849",
    zip_name: "fairbanks, in",
  },
  {
    postal_code: "81653",
    zip_name: "slater, co",
  },
  {
    postal_code: "38391",
    zip_name: "denmark, tn",
  },
  {
    postal_code: "14415",
    zip_name: "bellona, ny",
  },
  {
    postal_code: "69367",
    zip_name: "whitney, ne",
  },
  {
    postal_code: "25634",
    zip_name: "mallory, wv",
  },
  {
    postal_code: "62475",
    zip_name: "west liberty, il",
  },
  {
    postal_code: "58532",
    zip_name: "driscoll, nd",
  },
  {
    postal_code: "66746",
    zip_name: "hepler, ks",
  },
  {
    postal_code: "70637",
    zip_name: "dry creek, la",
  },
  {
    postal_code: "41605",
    zip_name: "betsy layne, ky",
  },
  {
    postal_code: "67853",
    zip_name: "ingalls, ks",
  },
  {
    postal_code: "73853",
    zip_name: "mutual, ok",
  },
  {
    postal_code: "43437",
    zip_name: "jerry city, oh",
  },
  {
    postal_code: "36736",
    zip_name: "dixons mills, al",
  },
  {
    postal_code: "66970",
    zip_name: "webber, ks",
  },
  {
    postal_code: "36792",
    zip_name: "randolph, al",
  },
  {
    postal_code: "64643",
    zip_name: "hale, mo",
  },
  {
    postal_code: "59248",
    zip_name: "nashua, mt",
  },
  {
    postal_code: "21814",
    zip_name: "bivalve, md",
  },
  {
    postal_code: "83425",
    zip_name: "hamer, id",
  },
  {
    postal_code: "65688",
    zip_name: "brandsville, mo",
  },
  {
    postal_code: "82514",
    zip_name: "fort washakie, wy",
  },
  {
    postal_code: "56325",
    zip_name: "elrosa, mn",
  },
  {
    postal_code: "80646",
    zip_name: "lucerne, co",
  },
  {
    postal_code: "58004",
    zip_name: "amenia, nd",
  },
  {
    postal_code: "67417",
    zip_name: "aurora, ks",
  },
  {
    postal_code: "58069",
    zip_name: "stirum, nd",
  },
  {
    postal_code: "68742",
    zip_name: "inman, ne",
  },
  {
    postal_code: "83533",
    zip_name: "greencreek, id",
  },
  {
    postal_code: "65230",
    zip_name: "armstrong, mo",
  },
  {
    postal_code: "68853",
    zip_name: "loup city, ne",
  },
  {
    postal_code: "16850",
    zip_name: "lecontes mills, pa",
  },
  {
    postal_code: "65541",
    zip_name: "lenox, mo",
  },
  {
    postal_code: "50118",
    zip_name: "hartford, ia",
  },
  {
    postal_code: "57473",
    zip_name: "seneca, sd",
  },
  {
    postal_code: "59854",
    zip_name: "ovando, mt",
  },
  {
    postal_code: "63453",
    zip_name: "luray, mo",
  },
  {
    postal_code: "48743",
    zip_name: "long lake, mi",
  },
  {
    postal_code: "66968",
    zip_name: "washington, ks",
  },
  {
    postal_code: "25106",
    zip_name: "henderson, wv",
  },
  {
    postal_code: "79035",
    zip_name: "friona, tx",
  },
  {
    postal_code: "56323",
    zip_name: "cyrus, mn",
  },
  {
    postal_code: "53526",
    zip_name: "cobb, wi",
  },
  {
    postal_code: "41531",
    zip_name: "hardy, ky",
  },
  {
    postal_code: "44841",
    zip_name: "kansas, oh",
  },
  {
    postal_code: "12862",
    zip_name: "riparius, ny",
  },
  {
    postal_code: "22840",
    zip_name: "mc gaheysville, va",
  },
  {
    postal_code: "27872",
    zip_name: "roxobel, nc",
  },
  {
    postal_code: "15716",
    zip_name: "black lick, pa",
  },
  {
    postal_code: "35555",
    zip_name: "fayette, al",
  },
  {
    postal_code: "72801",
    zip_name: "russellville, ar",
  },
  {
    postal_code: "96786",
    zip_name: "wahiawa, hi",
  },
  {
    postal_code: "97626",
    zip_name: "fort klamath, or",
  },
  {
    postal_code: "37336",
    zip_name: "georgetown, tn",
  },
  {
    postal_code: "73521",
    zip_name: "altus, ok",
  },
  {
    postal_code: "67036",
    zip_name: "danville, ks",
  },
  {
    postal_code: "10924",
    zip_name: "goshen, ny",
  },
  {
    postal_code: "80442",
    zip_name: "fraser, co",
  },
  {
    postal_code: "74884",
    zip_name: "wewoka, ok",
  },
  {
    postal_code: "25817",
    zip_name: "bolt, wv",
  },
  {
    postal_code: "59028",
    zip_name: "fishtail, mt",
  },
  {
    postal_code: "98530",
    zip_name: "bucoda, wa",
  },
  {
    postal_code: "49029",
    zip_name: "burlington, mi",
  },
  {
    postal_code: "48054",
    zip_name: "east china, mi",
  },
  {
    postal_code: "64672",
    zip_name: "powersville, mo",
  },
  {
    postal_code: "46302",
    zip_name: "boone grove, in",
  },
  {
    postal_code: "72181",
    zip_name: "wooster, ar",
  },
  {
    postal_code: "71406",
    zip_name: "belmont, la",
  },
  {
    postal_code: "54565",
    zip_name: "upson, wi",
  },
  {
    postal_code: "56244",
    zip_name: "hancock, mn",
  },
  {
    postal_code: "62672",
    zip_name: "nilwood, il",
  },
  {
    postal_code: "57217",
    zip_name: "bradley, sd",
  },
  {
    postal_code: "48884",
    zip_name: "sheridan, mi",
  },
  {
    postal_code: "85191",
    zip_name: "valley farms, az",
  },
  {
    postal_code: "78019",
    zip_name: "encinal, tx",
  },
  {
    postal_code: "99685",
    zip_name: "unalaska, ak",
  },
  {
    postal_code: "49017",
    zip_name: "battle creek, mi",
  },
  {
    postal_code: "71950",
    zip_name: "kirby, ar",
  },
  {
    postal_code: "98617",
    zip_name: "dallesport, wa",
  },
  {
    postal_code: "87410",
    zip_name: "aztec, nm",
  },
  {
    postal_code: "35801",
    zip_name: "huntsville, al",
  },
  {
    postal_code: "83449",
    zip_name: "swan valley, id",
  },
  {
    postal_code: "41622",
    zip_name: "eastern, ky",
  },
  {
    postal_code: "57036",
    zip_name: "hurley, sd",
  },
  {
    postal_code: "26438",
    zip_name: "spelter, wv",
  },
  {
    postal_code: "56727",
    zip_name: "grygla, mn",
  },
  {
    postal_code: "37188",
    zip_name: "white house, tn",
  },
  {
    postal_code: "82944",
    zip_name: "robertson, wy",
  },
  {
    postal_code: "4942",
    zip_name: "harmony, me",
  },
  {
    postal_code: "12172",
    zip_name: "stottville, ny",
  },
  {
    postal_code: "98331",
    zip_name: "forks, wa",
  },
  {
    postal_code: "10901",
    zip_name: "suffern, ny",
  },
  {
    postal_code: "71418",
    zip_name: "columbia, la",
  },
  {
    postal_code: "5487",
    zip_name: "starksboro, vt",
  },
  {
    postal_code: "29593",
    zip_name: "society hill, sc",
  },
  {
    postal_code: "48207",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "10532",
    zip_name: "hawthorne, ny",
  },
  {
    postal_code: "87825",
    zip_name: "magdalena, nm",
  },
  {
    postal_code: "31016",
    zip_name: "culloden, ga",
  },
  {
    postal_code: "7750",
    zip_name: "monmouth beach, nj",
  },
  {
    postal_code: "98296",
    zip_name: "snohomish, wa",
  },
  {
    postal_code: "10994",
    zip_name: "west nyack, ny",
  },
  {
    postal_code: "47633",
    zip_name: "poseyville, in",
  },
  {
    postal_code: "46703",
    zip_name: "angola, in",
  },
  {
    postal_code: "49639",
    zip_name: "hersey, mi",
  },
  {
    postal_code: "68939",
    zip_name: "franklin, ne",
  },
  {
    postal_code: "78369",
    zip_name: "mirando city, tx",
  },
  {
    postal_code: "13621",
    zip_name: "chase mills, ny",
  },
  {
    postal_code: "76945",
    zip_name: "robert lee, tx",
  },
  {
    postal_code: "29126",
    zip_name: "pomaria, sc",
  },
  {
    postal_code: "73112",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "69163",
    zip_name: "stapleton, ne",
  },
  {
    postal_code: "99672",
    zip_name: "sterling, ak",
  },
  {
    postal_code: "32059",
    zip_name: "lee, fl",
  },
  {
    postal_code: "53108",
    zip_name: "caledonia, wi",
  },
  {
    postal_code: "16878",
    zip_name: "west decatur, pa",
  },
  {
    postal_code: "46970",
    zip_name: "peru, in",
  },
  {
    postal_code: "28624",
    zip_name: "ferguson, nc",
  },
  {
    postal_code: "81657",
    zip_name: "vail, co",
  },
  {
    postal_code: "58458",
    zip_name: "lamoure, nd",
  },
  {
    postal_code: "49460",
    zip_name: "west olive, mi",
  },
  {
    postal_code: "37013",
    zip_name: "antioch, tn",
  },
  {
    postal_code: "72776",
    zip_name: "witter, ar",
  },
  {
    postal_code: "25865",
    zip_name: "lester, wv",
  },
  {
    postal_code: "13784",
    zip_name: "harford, ny",
  },
  {
    postal_code: "81220",
    zip_name: "cimarron, co",
  },
  {
    postal_code: "37052",
    zip_name: "cunningham, tn",
  },
  {
    postal_code: "36006",
    zip_name: "billingsley, al",
  },
  {
    postal_code: "76936",
    zip_name: "eldorado, tx",
  },
  {
    postal_code: "53085",
    zip_name: "sheboygan falls, wi",
  },
  {
    postal_code: "79323",
    zip_name: "denver city, tx",
  },
  {
    postal_code: "59750",
    zip_name: "butte, mt",
  },
  {
    postal_code: "23866",
    zip_name: "ivor, va",
  },
  {
    postal_code: "54411",
    zip_name: "athens, wi",
  },
  {
    postal_code: "89003",
    zip_name: "beatty, nv",
  },
  {
    postal_code: "82639",
    zip_name: "kaycee, wy",
  },
  {
    postal_code: "37355",
    zip_name: "manchester, tn",
  },
  {
    postal_code: "53202",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "39815",
    zip_name: "attapulgus, ga",
  },
  {
    postal_code: "46784",
    zip_name: "rome city, in",
  },
  {
    postal_code: "64738",
    zip_name: "collins, mo",
  },
  {
    postal_code: "78164",
    zip_name: "yorktown, tx",
  },
  {
    postal_code: "58060",
    zip_name: "milnor, nd",
  },
  {
    postal_code: "31784",
    zip_name: "sale city, ga",
  },
  {
    postal_code: "62892",
    zip_name: "vernon, il",
  },
  {
    postal_code: "41837",
    zip_name: "mayking, ky",
  },
  {
    postal_code: "47519",
    zip_name: "cannelburg, in",
  },
  {
    postal_code: "74827",
    zip_name: "atwood, ok",
  },
  {
    postal_code: "72747",
    zip_name: "maysville, ar",
  },
  {
    postal_code: "14082",
    zip_name: "java center, ny",
  },
  {
    postal_code: "55925",
    zip_name: "dakota, mn",
  },
  {
    postal_code: "73530",
    zip_name: "davidson, ok",
  },
  {
    postal_code: "79566",
    zip_name: "wingate, tx",
  },
  {
    postal_code: "70740",
    zip_name: "grosse tete, la",
  },
  {
    postal_code: "78344",
    zip_name: "bruni, tx",
  },
  {
    postal_code: "89421",
    zip_name: "mc dermitt, nv",
  },
  {
    postal_code: "87053",
    zip_name: "san ysidro, nm",
  },
  {
    postal_code: "66758",
    zip_name: "neosho falls, ks",
  },
  {
    postal_code: "51448",
    zip_name: "kiron, ia",
  },
  {
    postal_code: "62856",
    zip_name: "logan, il",
  },
  {
    postal_code: "97350",
    zip_name: "idanha, or",
  },
  {
    postal_code: "38569",
    zip_name: "lancaster, tn",
  },
  {
    postal_code: "52133",
    zip_name: "castalia, ia",
  },
  {
    postal_code: "83433",
    zip_name: "macks inn, id",
  },
  {
    postal_code: "31648",
    zip_name: "statenville, ga",
  },
  {
    postal_code: "71725",
    zip_name: "carthage, ar",
  },
  {
    postal_code: "26731",
    zip_name: "lahmansville, wv",
  },
  {
    postal_code: "45853",
    zip_name: "kalida, oh",
  },
  {
    postal_code: "16111",
    zip_name: "atlantic, pa",
  },
  {
    postal_code: "55931",
    zip_name: "eitzen, mn",
  },
  {
    postal_code: "87560",
    zip_name: "ribera, nm",
  },
  {
    postal_code: "69333",
    zip_name: "ashby, ne",
  },
  {
    postal_code: "45888",
    zip_name: "uniopolis, oh",
  },
  {
    postal_code: "83312",
    zip_name: "almo, id",
  },
  {
    postal_code: "38241",
    zip_name: "palmersville, tn",
  },
  {
    postal_code: "88055",
    zip_name: "redrock, nm",
  },
  {
    postal_code: "68444",
    zip_name: "strang, ne",
  },
  {
    postal_code: "72141",
    zip_name: "scotland, ar",
  },
  {
    postal_code: "61932",
    zip_name: "hume, il",
  },
  {
    postal_code: "63061",
    zip_name: "luebbering, mo",
  },
  {
    postal_code: "68858",
    zip_name: "miller, ne",
  },
  {
    postal_code: "46045",
    zip_name: "goldsmith, in",
  },
  {
    postal_code: "50520",
    zip_name: "bradgate, ia",
  },
  {
    postal_code: "57579",
    zip_name: "white river, sd",
  },
  {
    postal_code: "25669",
    zip_name: "crum, wv",
  },
  {
    postal_code: "76874",
    zip_name: "roosevelt, tx",
  },
  {
    postal_code: "52660",
    zip_name: "yarmouth, ia",
  },
  {
    postal_code: "22847",
    zip_name: "quicksburg, va",
  },
  {
    postal_code: "80742",
    zip_name: "new raymer, co",
  },
  {
    postal_code: "68438",
    zip_name: "sprague, ne",
  },
  {
    postal_code: "50538",
    zip_name: "farnhamville, ia",
  },
  {
    postal_code: "98639",
    zip_name: "north bonneville, wa",
  },
  {
    postal_code: "46740",
    zip_name: "geneva, in",
  },
  {
    postal_code: "79039",
    zip_name: "groom, tx",
  },
  {
    postal_code: "43908",
    zip_name: "bergholz, oh",
  },
  {
    postal_code: "25208",
    zip_name: "wharton, wv",
  },
  {
    postal_code: "63457",
    zip_name: "monticello, mo",
  },
  {
    postal_code: "7657",
    zip_name: "ridgefield, nj",
  },
  {
    postal_code: "49630",
    zip_name: "empire, mi",
  },
  {
    postal_code: "40328",
    zip_name: "gravel switch, ky",
  },
  {
    postal_code: "72130",
    zip_name: "prim, ar",
  },
  {
    postal_code: "49871",
    zip_name: "palmer, mi",
  },
  {
    postal_code: "35404",
    zip_name: "tuscaloosa, al",
  },
  {
    postal_code: "30413",
    zip_name: "bartow, ga",
  },
  {
    postal_code: "83232",
    zip_name: "dayton, id",
  },
  {
    postal_code: "68654",
    zip_name: "polk, ne",
  },
  {
    postal_code: "84529",
    zip_name: "kenilworth, ut",
  },
  {
    postal_code: "72410",
    zip_name: "alicia, ar",
  },
  {
    postal_code: "49263",
    zip_name: "norvell, mi",
  },
  {
    postal_code: "61519",
    zip_name: "bryant, il",
  },
  {
    postal_code: "87421",
    zip_name: "waterflow, nm",
  },
  {
    postal_code: "24822",
    zip_name: "clear fork, wv",
  },
  {
    postal_code: "52163",
    zip_name: "protivin, ia",
  },
  {
    postal_code: "13672",
    zip_name: "parishville, ny",
  },
  {
    postal_code: "83011",
    zip_name: "kelly, wy",
  },
  {
    postal_code: "68925",
    zip_name: "ayr, ne",
  },
  {
    postal_code: "43536",
    zip_name: "mark center, oh",
  },
  {
    postal_code: "76661",
    zip_name: "marlin, tx",
  },
  {
    postal_code: "81236",
    zip_name: "nathrop, co",
  },
  {
    postal_code: "97913",
    zip_name: "nyssa, or",
  },
  {
    postal_code: "93954",
    zip_name: "san lucas, ca",
  },
  {
    postal_code: "45855",
    zip_name: "latty, oh",
  },
  {
    postal_code: "26651",
    zip_name: "summersville, wv",
  },
  {
    postal_code: "83703",
    zip_name: "boise, id",
  },
  {
    postal_code: "98616",
    zip_name: "cougar, wa",
  },
  {
    postal_code: "47588",
    zip_name: "troy, in",
  },
  {
    postal_code: "38463",
    zip_name: "iron city, tn",
  },
  {
    postal_code: "8832",
    zip_name: "keasbey, nj",
  },
  {
    postal_code: "99664",
    zip_name: "seward, ak",
  },
  {
    postal_code: "61084",
    zip_name: "stillman valley, il",
  },
  {
    postal_code: "24269",
    zip_name: "mc clure, va",
  },
  {
    postal_code: "41175",
    zip_name: "south shore, ky",
  },
  {
    postal_code: "38337",
    zip_name: "gadsden, tn",
  },
  {
    postal_code: "48217",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "98351",
    zip_name: "longbranch, wa",
  },
  {
    postal_code: "87008",
    zip_name: "cedar crest, nm",
  },
  {
    postal_code: "80834",
    zip_name: "seibert, co",
  },
  {
    postal_code: "63783",
    zip_name: "uniontown, mo",
  },
  {
    postal_code: "98597",
    zip_name: "yelm, wa",
  },
  {
    postal_code: "29934",
    zip_name: "pineland, sc",
  },
  {
    postal_code: "50238",
    zip_name: "russell, ia",
  },
  {
    postal_code: "10993",
    zip_name: "west haverstraw, ny",
  },
  {
    postal_code: "57223",
    zip_name: "castlewood, sd",
  },
  {
    postal_code: "42204",
    zip_name: "allensville, ky",
  },
  {
    postal_code: "62642",
    zip_name: "greenview, il",
  },
  {
    postal_code: "31554",
    zip_name: "nicholls, ga",
  },
  {
    postal_code: "71220",
    zip_name: "bastrop, la",
  },
  {
    postal_code: "36477",
    zip_name: "samson, al",
  },
  {
    postal_code: "52332",
    zip_name: "shellsburg, ia",
  },
  {
    postal_code: "97733",
    zip_name: "crescent, or",
  },
  {
    postal_code: "42440",
    zip_name: "mortons gap, ky",
  },
  {
    postal_code: "37050",
    zip_name: "cumberland city, tn",
  },
  {
    postal_code: "67203",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "54413",
    zip_name: "babcock, wi",
  },
  {
    postal_code: "65335",
    zip_name: "ionia, mo",
  },
  {
    postal_code: "17086",
    zip_name: "richfield, pa",
  },
  {
    postal_code: "38486",
    zip_name: "westpoint, tn",
  },
  {
    postal_code: "4969",
    zip_name: "plymouth, me",
  },
  {
    postal_code: "23893",
    zip_name: "white plains, va",
  },
  {
    postal_code: "53065",
    zip_name: "oakfield, wi",
  },
  {
    postal_code: "50239",
    zip_name: "saint anthony, ia",
  },
  {
    postal_code: "59538",
    zip_name: "malta, mt",
  },
  {
    postal_code: "53961",
    zip_name: "rock springs, wi",
  },
  {
    postal_code: "47542",
    zip_name: "huntingburg, in",
  },
  {
    postal_code: "98220",
    zip_name: "acme, wa",
  },
  {
    postal_code: "66734",
    zip_name: "farlington, ks",
  },
  {
    postal_code: "50666",
    zip_name: "plainfield, ia",
  },
  {
    postal_code: "54499",
    zip_name: "wittenberg, wi",
  },
  {
    postal_code: "32564",
    zip_name: "holt, fl",
  },
  {
    postal_code: "36830",
    zip_name: "auburn, al",
  },
  {
    postal_code: "36425",
    zip_name: "beatrice, al",
  },
  {
    postal_code: "13649",
    zip_name: "helena, ny",
  },
  {
    postal_code: "36251",
    zip_name: "ashland, al",
  },
  {
    postal_code: "14608",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "49960",
    zip_name: "rockland, mi",
  },
  {
    postal_code: "23224",
    zip_name: "richmond, va",
  },
  {
    postal_code: "47885",
    zip_name: "west terre haute, in",
  },
  {
    postal_code: "51041",
    zip_name: "orange city, ia",
  },
  {
    postal_code: "24070",
    zip_name: "catawba, va",
  },
  {
    postal_code: "36048",
    zip_name: "louisville, al",
  },
  {
    postal_code: "71030",
    zip_name: "gloster, la",
  },
  {
    postal_code: "50075",
    zip_name: "ellsworth, ia",
  },
  {
    postal_code: "96777",
    zip_name: "pahala, hi",
  },
  {
    postal_code: "98526",
    zip_name: "amanda park, wa",
  },
  {
    postal_code: "7663",
    zip_name: "saddle brook, nj",
  },
  {
    postal_code: "98844",
    zip_name: "oroville, wa",
  },
  {
    postal_code: "7764",
    zip_name: "west long branch, nj",
  },
  {
    postal_code: "16623",
    zip_name: "cassville, pa",
  },
  {
    postal_code: "62439",
    zip_name: "lawrenceville, il",
  },
  {
    postal_code: "31701",
    zip_name: "albany, ga",
  },
  {
    postal_code: "18828",
    zip_name: "lawton, pa",
  },
  {
    postal_code: "57247",
    zip_name: "lake city, sd",
  },
  {
    postal_code: "36455",
    zip_name: "lockhart, al",
  },
  {
    postal_code: "36532",
    zip_name: "fairhope, al",
  },
  {
    postal_code: "40759",
    zip_name: "rockholds, ky",
  },
  {
    postal_code: "1436",
    zip_name: "baldwinville, ma",
  },
  {
    postal_code: "48725",
    zip_name: "caseville, mi",
  },
  {
    postal_code: "49776",
    zip_name: "posen, mi",
  },
  {
    postal_code: "67342",
    zip_name: "edna, ks",
  },
  {
    postal_code: "62462",
    zip_name: "sigel, il",
  },
  {
    postal_code: "41832",
    zip_name: "letcher, ky",
  },
  {
    postal_code: "57328",
    zip_name: "corsica, sd",
  },
  {
    postal_code: "48862",
    zip_name: "north star, mi",
  },
  {
    postal_code: "74839",
    zip_name: "dustin, ok",
  },
  {
    postal_code: "45776",
    zip_name: "shade, oh",
  },
  {
    postal_code: "56123",
    zip_name: "currie, mn",
  },
  {
    postal_code: "68761",
    zip_name: "oakdale, ne",
  },
  {
    postal_code: "42252",
    zip_name: "jetson, ky",
  },
  {
    postal_code: "30822",
    zip_name: "perkins, ga",
  },
  {
    postal_code: "16726",
    zip_name: "cyclone, pa",
  },
  {
    postal_code: "76832",
    zip_name: "cherokee, tx",
  },
  {
    postal_code: "50067",
    zip_name: "decatur, ia",
  },
  {
    postal_code: "45384",
    zip_name: "wilberforce, oh",
  },
  {
    postal_code: "4669",
    zip_name: "prospect harbor, me",
  },
  {
    postal_code: "58560",
    zip_name: "moffit, nd",
  },
  {
    postal_code: "85721",
    zip_name: "tucson, az",
  },
  {
    postal_code: "87046",
    zip_name: "regina, nm",
  },
  {
    postal_code: "61337",
    zip_name: "malden, il",
  },
  {
    postal_code: "52721",
    zip_name: "bennett, ia",
  },
  {
    postal_code: "29943",
    zip_name: "tillman, sc",
  },
  {
    postal_code: "45816",
    zip_name: "benton ridge, oh",
  },
  {
    postal_code: "68067",
    zip_name: "walthill, ne",
  },
  {
    postal_code: "83438",
    zip_name: "parker, id",
  },
  {
    postal_code: "63539",
    zip_name: "ethel, mo",
  },
  {
    postal_code: "57738",
    zip_name: "fairburn, sd",
  },
  {
    postal_code: "13313",
    zip_name: "bridgewater, ny",
  },
  {
    postal_code: "50478",
    zip_name: "thompson, ia",
  },
  {
    postal_code: "88034",
    zip_name: "faywood, nm",
  },
  {
    postal_code: "15353",
    zip_name: "nineveh, pa",
  },
  {
    postal_code: "16402",
    zip_name: "bear lake, pa",
  },
  {
    postal_code: "76858",
    zip_name: "melvin, tx",
  },
  {
    postal_code: "74760",
    zip_name: "spencerville, ok",
  },
  {
    postal_code: "59247",
    zip_name: "medicine lake, mt",
  },
  {
    postal_code: "3853",
    zip_name: "mirror lake, nh",
  },
  {
    postal_code: "28719",
    zip_name: "cherokee, nc",
  },
  {
    postal_code: "47467",
    zip_name: "tunnelton, in",
  },
  {
    postal_code: "74027",
    zip_name: "delaware, ok",
  },
  {
    postal_code: "58634",
    zip_name: "grassy butte, nd",
  },
  {
    postal_code: "79018",
    zip_name: "channing, tx",
  },
  {
    postal_code: "64455",
    zip_name: "graham, mo",
  },
  {
    postal_code: "73949",
    zip_name: "texhoma, ok",
  },
  {
    postal_code: "74829",
    zip_name: "boley, ok",
  },
  {
    postal_code: "52649",
    zip_name: "salem, ia",
  },
  {
    postal_code: "68810",
    zip_name: "alda, ne",
  },
  {
    postal_code: "84064",
    zip_name: "randolph, ut",
  },
  {
    postal_code: "79530",
    zip_name: "lawn, tx",
  },
  {
    postal_code: "19017",
    zip_name: "chester heights, pa",
  },
  {
    postal_code: "14822",
    zip_name: "canaseraga, ny",
  },
  {
    postal_code: "99744",
    zip_name: "anderson, ak",
  },
  {
    postal_code: "43006",
    zip_name: "brinkhaven, oh",
  },
  {
    postal_code: "43555",
    zip_name: "ridgeville corners, oh",
  },
  {
    postal_code: "58344",
    zip_name: "lakota, nd",
  },
  {
    postal_code: "63629",
    zip_name: "bunker, mo",
  },
  {
    postal_code: "99565",
    zip_name: "chignik lagoon, ak",
  },
  {
    postal_code: "62818",
    zip_name: "browns, il",
  },
  {
    postal_code: "77950",
    zip_name: "austwell, tx",
  },
  {
    postal_code: "50655",
    zip_name: "maynard, ia",
  },
  {
    postal_code: "49315",
    zip_name: "byron center, mi",
  },
  {
    postal_code: "62059",
    zip_name: "lovejoy, il",
  },
  {
    postal_code: "78017",
    zip_name: "dilley, tx",
  },
  {
    postal_code: "31063",
    zip_name: "montezuma, ga",
  },
  {
    postal_code: "92378",
    zip_name: "rimforest, ca",
  },
  {
    postal_code: "51024",
    zip_name: "hinton, ia",
  },
  {
    postal_code: "50148",
    zip_name: "liscomb, ia",
  },
  {
    postal_code: "58243",
    zip_name: "hoople, nd",
  },
  {
    postal_code: "43333",
    zip_name: "lewistown, oh",
  },
  {
    postal_code: "70760",
    zip_name: "new roads, la",
  },
  {
    postal_code: "29201",
    zip_name: "columbia, sc",
  },
  {
    postal_code: "39481",
    zip_name: "stringer, ms",
  },
  {
    postal_code: "37325",
    zip_name: "delano, tn",
  },
  {
    postal_code: "88029",
    zip_name: "columbus, nm",
  },
  {
    postal_code: "96017",
    zip_name: "castella, ca",
  },
  {
    postal_code: "24322",
    zip_name: "cripple creek, va",
  },
  {
    postal_code: "49261",
    zip_name: "napoleon, mi",
  },
  {
    postal_code: "80732",
    zip_name: "hereford, co",
  },
  {
    postal_code: "73055",
    zip_name: "marlow, ok",
  },
  {
    postal_code: "41513",
    zip_name: "belcher, ky",
  },
  {
    postal_code: "38452",
    zip_name: "cypress inn, tn",
  },
  {
    postal_code: "27816",
    zip_name: "castalia, nc",
  },
  {
    postal_code: "65050",
    zip_name: "latham, mo",
  },
  {
    postal_code: "80804",
    zip_name: "arriba, co",
  },
  {
    postal_code: "56566",
    zip_name: "naytahwaush, mn",
  },
  {
    postal_code: "66501",
    zip_name: "mc farland, ks",
  },
  {
    postal_code: "87412",
    zip_name: "blanco, nm",
  },
  {
    postal_code: "74643",
    zip_name: "lamont, ok",
  },
  {
    postal_code: "99615",
    zip_name: "kodiak, ak",
  },
  {
    postal_code: "29580",
    zip_name: "nesmith, sc",
  },
  {
    postal_code: "46573",
    zip_name: "wakarusa, in",
  },
  {
    postal_code: "72454",
    zip_name: "piggott, ar",
  },
  {
    postal_code: "43321",
    zip_name: "fulton, oh",
  },
  {
    postal_code: "59434",
    zip_name: "east glacier park, mt",
  },
  {
    postal_code: "65463",
    zip_name: "eldridge, mo",
  },
  {
    postal_code: "49864",
    zip_name: "nahma, mi",
  },
  {
    postal_code: "26851",
    zip_name: "wardensville, wv",
  },
  {
    postal_code: "73065",
    zip_name: "newcastle, ok",
  },
  {
    postal_code: "61372",
    zip_name: "troy grove, il",
  },
  {
    postal_code: "36075",
    zip_name: "shorter, al",
  },
  {
    postal_code: "1506",
    zip_name: "brookfield, ma",
  },
  {
    postal_code: "93623",
    zip_name: "fish camp, ca",
  },
  {
    postal_code: "98524",
    zip_name: "allyn, wa",
  },
  {
    postal_code: "73532",
    zip_name: "duke, ok",
  },
  {
    postal_code: "62481",
    zip_name: "yale, il",
  },
  {
    postal_code: "37051",
    zip_name: "cumberland furnace, tn",
  },
  {
    postal_code: "43773",
    zip_name: "quaker city, oh",
  },
  {
    postal_code: "97040",
    zip_name: "mosier, or",
  },
  {
    postal_code: "30753",
    zip_name: "trion, ga",
  },
  {
    postal_code: "71639",
    zip_name: "dumas, ar",
  },
  {
    postal_code: "49435",
    zip_name: "marne, mi",
  },
  {
    postal_code: "37073",
    zip_name: "greenbrier, tn",
  },
  {
    postal_code: "75853",
    zip_name: "montalba, tx",
  },
  {
    postal_code: "63834",
    zip_name: "charleston, mo",
  },
  {
    postal_code: "98045",
    zip_name: "north bend, wa",
  },
  {
    postal_code: "49640",
    zip_name: "honor, mi",
  },
  {
    postal_code: "68421",
    zip_name: "peru, ne",
  },
  {
    postal_code: "72010",
    zip_name: "bald knob, ar",
  },
  {
    postal_code: "46562",
    zip_name: "pierceton, in",
  },
  {
    postal_code: "66958",
    zip_name: "morrowville, ks",
  },
  {
    postal_code: "95488",
    zip_name: "westport, ca",
  },
  {
    postal_code: "53217",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "97358",
    zip_name: "lyons, or",
  },
  {
    postal_code: "23085",
    zip_name: "king and queen court house, va",
  },
  {
    postal_code: "48635",
    zip_name: "lupton, mi",
  },
  {
    postal_code: "72628",
    zip_name: "deer, ar",
  },
  {
    postal_code: "99929",
    zip_name: "wrangell, ak",
  },
  {
    postal_code: "59487",
    zip_name: "vaughn, mt",
  },
  {
    postal_code: "14737",
    zip_name: "franklinville, ny",
  },
  {
    postal_code: "12063",
    zip_name: "east schodack, ny",
  },
  {
    postal_code: "47969",
    zip_name: "newtown, in",
  },
  {
    postal_code: "81248",
    zip_name: "sargents, co",
  },
  {
    postal_code: "98564",
    zip_name: "mossyrock, wa",
  },
  {
    postal_code: "16625",
    zip_name: "claysburg, pa",
  },
  {
    postal_code: "98821",
    zip_name: "dryden, wa",
  },
  {
    postal_code: "46510",
    zip_name: "claypool, in",
  },
  {
    postal_code: "61317",
    zip_name: "cherry, il",
  },
  {
    postal_code: "39462",
    zip_name: "new augusta, ms",
  },
  {
    postal_code: "39769",
    zip_name: "sturgis, ms",
  },
  {
    postal_code: "8805",
    zip_name: "bound brook, nj",
  },
  {
    postal_code: "56659",
    zip_name: "max, mn",
  },
  {
    postal_code: "87832",
    zip_name: "san antonio, nm",
  },
  {
    postal_code: "47803",
    zip_name: "terre haute, in",
  },
  {
    postal_code: "59639",
    zip_name: "lincoln, mt",
  },
  {
    postal_code: "26384",
    zip_name: "linn, wv",
  },
  {
    postal_code: "62346",
    zip_name: "la prairie, il",
  },
  {
    postal_code: "47224",
    zip_name: "canaan, in",
  },
  {
    postal_code: "29810",
    zip_name: "allendale, sc",
  },
  {
    postal_code: "54418",
    zip_name: "bryant, wi",
  },
  {
    postal_code: "57022",
    zip_name: "dell rapids, sd",
  },
  {
    postal_code: "15829",
    zip_name: "corsica, pa",
  },
  {
    postal_code: "37167",
    zip_name: "smyrna, tn",
  },
  {
    postal_code: "49625",
    zip_name: "copemish, mi",
  },
  {
    postal_code: "35904",
    zip_name: "gadsden, al",
  },
  {
    postal_code: "83666",
    zip_name: "placerville, id",
  },
  {
    postal_code: "50162",
    zip_name: "melbourne, ia",
  },
  {
    postal_code: "99160",
    zip_name: "orient, wa",
  },
  {
    postal_code: "10919",
    zip_name: "circleville, ny",
  },
  {
    postal_code: "57020",
    zip_name: "crooks, sd",
  },
  {
    postal_code: "23867",
    zip_name: "jarratt, va",
  },
  {
    postal_code: "23919",
    zip_name: "bracey, va",
  },
  {
    postal_code: "37015",
    zip_name: "ashland city, tn",
  },
  {
    postal_code: "96104",
    zip_name: "cedarville, ca",
  },
  {
    postal_code: "52309",
    zip_name: "monmouth, ia",
  },
  {
    postal_code: "66862",
    zip_name: "matfield green, ks",
  },
  {
    postal_code: "17271",
    zip_name: "willow hill, pa",
  },
  {
    postal_code: "26817",
    zip_name: "bloomery, wv",
  },
  {
    postal_code: "25521",
    zip_name: "griffithsville, wv",
  },
  {
    postal_code: "56226",
    zip_name: "clontarf, mn",
  },
  {
    postal_code: "32332",
    zip_name: "gretna, fl",
  },
  {
    postal_code: "81047",
    zip_name: "holly, co",
  },
  {
    postal_code: "45741",
    zip_name: "langsville, oh",
  },
  {
    postal_code: "58632",
    zip_name: "golva, nd",
  },
  {
    postal_code: "58348",
    zip_name: "maddock, nd",
  },
  {
    postal_code: "62874",
    zip_name: "orient, il",
  },
  {
    postal_code: "69221",
    zip_name: "wood lake, ne",
  },
  {
    postal_code: "81136",
    zip_name: "hooper, co",
  },
  {
    postal_code: "30818",
    zip_name: "matthews, ga",
  },
  {
    postal_code: "64659",
    zip_name: "meadville, mo",
  },
  {
    postal_code: "39067",
    zip_name: "ethel, ms",
  },
  {
    postal_code: "63438",
    zip_name: "durham, mo",
  },
  {
    postal_code: "17371",
    zip_name: "york new salem, pa",
  },
  {
    postal_code: "40010",
    zip_name: "buckner, ky",
  },
  {
    postal_code: "89414",
    zip_name: "golconda, nv",
  },
  {
    postal_code: "79024",
    zip_name: "darrouzett, tx",
  },
  {
    postal_code: "41746",
    zip_name: "happy, ky",
  },
  {
    postal_code: "39767",
    zip_name: "stewart, ms",
  },
  {
    postal_code: "47845",
    zip_name: "coalmont, in",
  },
  {
    postal_code: "24150",
    zip_name: "ripplemead, va",
  },
  {
    postal_code: "44702",
    zip_name: "canton, oh",
  },
  {
    postal_code: "25621",
    zip_name: "gilbert, wv",
  },
  {
    postal_code: "79095",
    zip_name: "wellington, tx",
  },
  {
    postal_code: "57363",
    zip_name: "mount vernon, sd",
  },
  {
    postal_code: "71455",
    zip_name: "mora, la",
  },
  {
    postal_code: "64769",
    zip_name: "mindenmines, mo",
  },
  {
    postal_code: "15334",
    zip_name: "garards fort, pa",
  },
  {
    postal_code: "43931",
    zip_name: "hannibal, oh",
  },
  {
    postal_code: "31743",
    zip_name: "de soto, ga",
  },
  {
    postal_code: "56287",
    zip_name: "seaforth, mn",
  },
  {
    postal_code: "59732",
    zip_name: "glen, mt",
  },
  {
    postal_code: "62217",
    zip_name: "baldwin, il",
  },
  {
    postal_code: "16238",
    zip_name: "manorville, pa",
  },
  {
    postal_code: "73717",
    zip_name: "alva, ok",
  },
  {
    postal_code: "70733",
    zip_name: "french settlement, la",
  },
  {
    postal_code: "31636",
    zip_name: "lake park, ga",
  },
  {
    postal_code: "31634",
    zip_name: "homerville, ga",
  },
  {
    postal_code: "59485",
    zip_name: "ulm, mt",
  },
  {
    postal_code: "18617",
    zip_name: "glen lyon, pa",
  },
  {
    postal_code: "74833",
    zip_name: "castle, ok",
  },
  {
    postal_code: "15856",
    zip_name: "rockton, pa",
  },
  {
    postal_code: "76854",
    zip_name: "london, tx",
  },
  {
    postal_code: "59414",
    zip_name: "black eagle, mt",
  },
  {
    postal_code: "41659",
    zip_name: "stanville, ky",
  },
  {
    postal_code: "24313",
    zip_name: "barren springs, va",
  },
  {
    postal_code: "23898",
    zip_name: "zuni, va",
  },
  {
    postal_code: "68401",
    zip_name: "mc cool junction, ne",
  },
  {
    postal_code: "17813",
    zip_name: "beavertown, pa",
  },
  {
    postal_code: "17953",
    zip_name: "middleport, pa",
  },
  {
    postal_code: "72636",
    zip_name: "gilbert, ar",
  },
  {
    postal_code: "15611",
    zip_name: "adamsburg, pa",
  },
  {
    postal_code: "57013",
    zip_name: "canton, sd",
  },
  {
    postal_code: "78043",
    zip_name: "laredo, tx",
  },
  {
    postal_code: "54473",
    zip_name: "rosholt, wi",
  },
  {
    postal_code: "75692",
    zip_name: "waskom, tx",
  },
  {
    postal_code: "96059",
    zip_name: "manton, ca",
  },
  {
    postal_code: "17729",
    zip_name: "cross fork, pa",
  },
  {
    postal_code: "57763",
    zip_name: "oelrichs, sd",
  },
  {
    postal_code: "21930",
    zip_name: "georgetown, md",
  },
  {
    postal_code: "52595",
    zip_name: "university park, ia",
  },
  {
    postal_code: "51231",
    zip_name: "archer, ia",
  },
  {
    postal_code: "57461",
    zip_name: "mellette, sd",
  },
  {
    postal_code: "47123",
    zip_name: "grantsburg, in",
  },
  {
    postal_code: "61042",
    zip_name: "harmon, il",
  },
  {
    postal_code: "67455",
    zip_name: "lincoln, ks",
  },
  {
    postal_code: "17749",
    zip_name: "mc ewensville, pa",
  },
  {
    postal_code: "72865",
    zip_name: "subiaco, ar",
  },
  {
    postal_code: "67566",
    zip_name: "partridge, ks",
  },
  {
    postal_code: "76427",
    zip_name: "bryson, tx",
  },
  {
    postal_code: "75669",
    zip_name: "long branch, tx",
  },
  {
    postal_code: "14036",
    zip_name: "corfu, ny",
  },
  {
    postal_code: "71463",
    zip_name: "oakdale, la",
  },
  {
    postal_code: "47921",
    zip_name: "boswell, in",
  },
  {
    postal_code: "70615",
    zip_name: "lake charles, la",
  },
  {
    postal_code: "70559",
    zip_name: "morse, la",
  },
  {
    postal_code: "31055",
    zip_name: "mc rae helena, ga",
  },
  {
    postal_code: "21625",
    zip_name: "cordova, md",
  },
  {
    postal_code: "87001",
    zip_name: "algodones, nm",
  },
  {
    postal_code: "77857",
    zip_name: "gause, tx",
  },
  {
    postal_code: "15945",
    zip_name: "parkhill, pa",
  },
  {
    postal_code: "32438",
    zip_name: "fountain, fl",
  },
  {
    postal_code: "13684",
    zip_name: "russell, ny",
  },
  {
    postal_code: "86411",
    zip_name: "hackberry, az",
  },
  {
    postal_code: "61417",
    zip_name: "berwick, il",
  },
  {
    postal_code: "78840",
    zip_name: "del rio, tx",
  },
  {
    postal_code: "67053",
    zip_name: "goessel, ks",
  },
  {
    postal_code: "24898",
    zip_name: "wyoming, wv",
  },
  {
    postal_code: "31557",
    zip_name: "patterson, ga",
  },
  {
    postal_code: "46776",
    zip_name: "orland, in",
  },
  {
    postal_code: "51246",
    zip_name: "rock rapids, ia",
  },
  {
    postal_code: "39094",
    zip_name: "lena, ms",
  },
  {
    postal_code: "35072",
    zip_name: "goodwater, al",
  },
  {
    postal_code: "72640",
    zip_name: "hasty, ar",
  },
  {
    postal_code: "54561",
    zip_name: "star lake, wi",
  },
  {
    postal_code: "45328",
    zip_name: "gettysburg, oh",
  },
  {
    postal_code: "77412",
    zip_name: "altair, tx",
  },
  {
    postal_code: "97877",
    zip_name: "sumpter, or",
  },
  {
    postal_code: "38451",
    zip_name: "culleoka, tn",
  },
  {
    postal_code: "66020",
    zip_name: "easton, ks",
  },
  {
    postal_code: "99830",
    zip_name: "kake, ak",
  },
  {
    postal_code: "49011",
    zip_name: "athens, mi",
  },
  {
    postal_code: "14544",
    zip_name: "rushville, ny",
  },
  {
    postal_code: "52543",
    zip_name: "cedar, ia",
  },
  {
    postal_code: "46567",
    zip_name: "syracuse, in",
  },
  {
    postal_code: "78041",
    zip_name: "laredo, tx",
  },
  {
    postal_code: "10962",
    zip_name: "orangeburg, ny",
  },
  {
    postal_code: "75554",
    zip_name: "avery, tx",
  },
  {
    postal_code: "47116",
    zip_name: "eckerty, in",
  },
  {
    postal_code: "46745",
    zip_name: "hoagland, in",
  },
  {
    postal_code: "42638",
    zip_name: "revelo, ky",
  },
  {
    postal_code: "48765",
    zip_name: "turner, mi",
  },
  {
    postal_code: "73093",
    zip_name: "washington, ok",
  },
  {
    postal_code: "29108",
    zip_name: "newberry, sc",
  },
  {
    postal_code: "47965",
    zip_name: "new market, in",
  },
  {
    postal_code: "71295",
    zip_name: "winnsboro, la",
  },
  {
    postal_code: "53817",
    zip_name: "patch grove, wi",
  },
  {
    postal_code: "97826",
    zip_name: "echo, or",
  },
  {
    postal_code: "46144",
    zip_name: "gwynneville, in",
  },
  {
    postal_code: "42262",
    zip_name: "oak grove, ky",
  },
  {
    postal_code: "58218",
    zip_name: "buxton, nd",
  },
  {
    postal_code: "35475",
    zip_name: "northport, al",
  },
  {
    postal_code: "76265",
    zip_name: "saint jo, tx",
  },
  {
    postal_code: "38076",
    zip_name: "williston, tn",
  },
  {
    postal_code: "84053",
    zip_name: "neola, ut",
  },
  {
    postal_code: "18846",
    zip_name: "sugar run, pa",
  },
  {
    postal_code: "41640",
    zip_name: "hueysville, ky",
  },
  {
    postal_code: "68947",
    zip_name: "hildreth, ne",
  },
  {
    postal_code: "50511",
    zip_name: "algona, ia",
  },
  {
    postal_code: "36861",
    zip_name: "jacksons gap, al",
  },
  {
    postal_code: "25635",
    zip_name: "man, wv",
  },
  {
    postal_code: "22476",
    zip_name: "hustle, va",
  },
  {
    postal_code: "58562",
    zip_name: "new leipzig, nd",
  },
  {
    postal_code: "69166",
    zip_name: "thedford, ne",
  },
  {
    postal_code: "71724",
    zip_name: "calion, ar",
  },
  {
    postal_code: "78391",
    zip_name: "tynan, tx",
  },
  {
    postal_code: "36584",
    zip_name: "vinegar bend, al",
  },
  {
    postal_code: "58733",
    zip_name: "des lacs, nd",
  },
  {
    postal_code: "26228",
    zip_name: "kanawha head, wv",
  },
  {
    postal_code: "16405",
    zip_name: "columbus, pa",
  },
  {
    postal_code: "88265",
    zip_name: "monument, nm",
  },
  {
    postal_code: "55758",
    zip_name: "kinney, mn",
  },
  {
    postal_code: "78828",
    zip_name: "barksdale, tx",
  },
  {
    postal_code: "15534",
    zip_name: "buffalo mills, pa",
  },
  {
    postal_code: "87041",
    zip_name: "pena blanca, nm",
  },
  {
    postal_code: "61340",
    zip_name: "mark, il",
  },
  {
    postal_code: "77655",
    zip_name: "sabine pass, tx",
  },
  {
    postal_code: "68437",
    zip_name: "shubert, ne",
  },
  {
    postal_code: "45719",
    zip_name: "chauncey, oh",
  },
  {
    postal_code: "58377",
    zip_name: "starkweather, nd",
  },
  {
    postal_code: "41619",
    zip_name: "drift, ky",
  },
  {
    postal_code: "24808",
    zip_name: "anawalt, wv",
  },
  {
    postal_code: "58481",
    zip_name: "spiritwood, nd",
  },
  {
    postal_code: "68338",
    zip_name: "daykin, ne",
  },
  {
    postal_code: "43029",
    zip_name: "irwin, oh",
  },
  {
    postal_code: "41534",
    zip_name: "hellier, ky",
  },
  {
    postal_code: "18619",
    zip_name: "hillsgrove, pa",
  },
  {
    postal_code: "67761",
    zip_name: "wallace, ks",
  },
  {
    postal_code: "47964",
    zip_name: "mount ayr, in",
  },
  {
    postal_code: "14102",
    zip_name: "marilla, ny",
  },
  {
    postal_code: "67122",
    zip_name: "piedmont, ks",
  },
  {
    postal_code: "14113",
    zip_name: "north java, ny",
  },
  {
    postal_code: "46778",
    zip_name: "petroleum, in",
  },
  {
    postal_code: "16689",
    zip_name: "waterfall, pa",
  },
  {
    postal_code: "57386",
    zip_name: "yale, sd",
  },
  {
    postal_code: "72424",
    zip_name: "datto, ar",
  },
  {
    postal_code: "15472",
    zip_name: "oliver, pa",
  },
  {
    postal_code: "63821",
    zip_name: "arbyrd, mo",
  },
  {
    postal_code: "15753",
    zip_name: "la jose, pa",
  },
  {
    postal_code: "58423",
    zip_name: "chaseley, nd",
  },
  {
    postal_code: "61315",
    zip_name: "bureau, il",
  },
  {
    postal_code: "38454",
    zip_name: "duck river, tn",
  },
  {
    postal_code: "4003",
    zip_name: "bailey island, me",
  },
  {
    postal_code: "83321",
    zip_name: "castleford, id",
  },
  {
    postal_code: "35769",
    zip_name: "scottsboro, al",
  },
  {
    postal_code: "27957",
    zip_name: "merry hill, nc",
  },
  {
    postal_code: "59540",
    zip_name: "rudyard, mt",
  },
  {
    postal_code: "65788",
    zip_name: "peace valley, mo",
  },
  {
    postal_code: "85320",
    zip_name: "aguila, az",
  },
  {
    postal_code: "49328",
    zip_name: "hopkins, mi",
  },
  {
    postal_code: "12740",
    zip_name: "grahamsville, ny",
  },
  {
    postal_code: "17859",
    zip_name: "orangeville, pa",
  },
  {
    postal_code: "82836",
    zip_name: "dayton, wy",
  },
  {
    postal_code: "41712",
    zip_name: "ary, ky",
  },
  {
    postal_code: "98058",
    zip_name: "renton, wa",
  },
  {
    postal_code: "66732",
    zip_name: "elsmore, ks",
  },
  {
    postal_code: "51237",
    zip_name: "george, ia",
  },
  {
    postal_code: "66404",
    zip_name: "baileyville, ks",
  },
  {
    postal_code: "25985",
    zip_name: "sandstone, wv",
  },
  {
    postal_code: "72467",
    zip_name: "state university, ar",
  },
  {
    postal_code: "88027",
    zip_name: "chamberino, nm",
  },
  {
    postal_code: "68982",
    zip_name: "wilcox, ne",
  },
  {
    postal_code: "12812",
    zip_name: "blue mountain lake, ny",
  },
  {
    postal_code: "31624",
    zip_name: "axson, ga",
  },
  {
    postal_code: "24944",
    zip_name: "green bank, wv",
  },
  {
    postal_code: "26440",
    zip_name: "thornton, wv",
  },
  {
    postal_code: "98542",
    zip_name: "ethel, wa",
  },
  {
    postal_code: "29547",
    zip_name: "hamer, sc",
  },
  {
    postal_code: "53070",
    zip_name: "oostburg, wi",
  },
  {
    postal_code: "73131",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "61442",
    zip_name: "keithsburg, il",
  },
  {
    postal_code: "88354",
    zip_name: "weed, nm",
  },
  {
    postal_code: "22726",
    zip_name: "lignum, va",
  },
  {
    postal_code: "49959",
    zip_name: "ramsay, mi",
  },
  {
    postal_code: "45673",
    zip_name: "richmond dale, oh",
  },
  {
    postal_code: "55366",
    zip_name: "new auburn, mn",
  },
  {
    postal_code: "52308",
    zip_name: "millersburg, ia",
  },
  {
    postal_code: "75689",
    zip_name: "selman city, tx",
  },
  {
    postal_code: "98031",
    zip_name: "kent, wa",
  },
  {
    postal_code: "12307",
    zip_name: "schenectady, ny",
  },
  {
    postal_code: "47970",
    zip_name: "otterbein, in",
  },
  {
    postal_code: "67074",
    zip_name: "leon, ks",
  },
  {
    postal_code: "28657",
    zip_name: "newland, nc",
  },
  {
    postal_code: "73625",
    zip_name: "butler, ok",
  },
  {
    postal_code: "57049",
    zip_name: "north sioux city, sd",
  },
  {
    postal_code: "35603",
    zip_name: "decatur, al",
  },
  {
    postal_code: "7058",
    zip_name: "pine brook, nj",
  },
  {
    postal_code: "7946",
    zip_name: "millington, nj",
  },
  {
    postal_code: "40997",
    zip_name: "walker, ky",
  },
  {
    postal_code: "98229",
    zip_name: "bellingham, wa",
  },
  {
    postal_code: "72668",
    zip_name: "peel, ar",
  },
  {
    postal_code: "89428",
    zip_name: "silver city, nv",
  },
  {
    postal_code: "49519",
    zip_name: "wyoming, mi",
  },
  {
    postal_code: "98816",
    zip_name: "chelan, wa",
  },
  {
    postal_code: "98121",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "25085",
    zip_name: "gauley bridge, wv",
  },
  {
    postal_code: "7676",
    zip_name: "township of washington, nj",
  },
  {
    postal_code: "48649",
    zip_name: "oakley, mi",
  },
  {
    postal_code: "74650",
    zip_name: "ralston, ok",
  },
  {
    postal_code: "88051",
    zip_name: "mule creek, nm",
  },
  {
    postal_code: "57433",
    zip_name: "columbia, sd",
  },
  {
    postal_code: "22438",
    zip_name: "champlain, va",
  },
  {
    postal_code: "99150",
    zip_name: "malo, wa",
  },
  {
    postal_code: "4773",
    zip_name: "saint david, me",
  },
  {
    postal_code: "63566",
    zip_name: "winigan, mo",
  },
  {
    postal_code: "77629",
    zip_name: "nome, tx",
  },
  {
    postal_code: "85334",
    zip_name: "ehrenberg, az",
  },
  {
    postal_code: "55961",
    zip_name: "ostrander, mn",
  },
  {
    postal_code: "47464",
    zip_name: "stinesville, in",
  },
  {
    postal_code: "98644",
    zip_name: "seaview, wa",
  },
  {
    postal_code: "61865",
    zip_name: "potomac, il",
  },
  {
    postal_code: "29006",
    zip_name: "batesburg, sc",
  },
  {
    postal_code: "38453",
    zip_name: "dellrose, tn",
  },
  {
    postal_code: "71033",
    zip_name: "greenwood, la",
  },
  {
    postal_code: "70549",
    zip_name: "lake arthur, la",
  },
  {
    postal_code: "59520",
    zip_name: "big sandy, mt",
  },
  {
    postal_code: "54857",
    zip_name: "mikana, wi",
  },
  {
    postal_code: "85925",
    zip_name: "eagar, az",
  },
  {
    postal_code: "31720",
    zip_name: "barwick, ga",
  },
  {
    postal_code: "73072",
    zip_name: "norman, ok",
  },
  {
    postal_code: "17229",
    zip_name: "hustontown, pa",
  },
  {
    postal_code: "7624",
    zip_name: "closter, nj",
  },
  {
    postal_code: "39654",
    zip_name: "monticello, ms",
  },
  {
    postal_code: "57580",
    zip_name: "winner, sd",
  },
  {
    postal_code: "37031",
    zip_name: "castalian springs, tn",
  },
  {
    postal_code: "56714",
    zip_name: "badger, mn",
  },
  {
    postal_code: "29037",
    zip_name: "chappells, sc",
  },
  {
    postal_code: "66039",
    zip_name: "kincaid, ks",
  },
  {
    postal_code: "24530",
    zip_name: "callands, va",
  },
  {
    postal_code: "70789",
    zip_name: "wilson, la",
  },
  {
    postal_code: "57261",
    zip_name: "roslyn, sd",
  },
  {
    postal_code: "47459",
    zip_name: "solsberry, in",
  },
  {
    postal_code: "80726",
    zip_name: "crook, co",
  },
  {
    postal_code: "56724",
    zip_name: "gatzke, mn",
  },
  {
    postal_code: "25007",
    zip_name: "arnett, wv",
  },
  {
    postal_code: "75226",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "45646",
    zip_name: "latham, oh",
  },
  {
    postal_code: "65354",
    zip_name: "syracuse, mo",
  },
  {
    postal_code: "17210",
    zip_name: "amberson, pa",
  },
  {
    postal_code: "98950",
    zip_name: "vantage, wa",
  },
  {
    postal_code: "14026",
    zip_name: "bowmansville, ny",
  },
  {
    postal_code: "15866",
    zip_name: "troutville, pa",
  },
  {
    postal_code: "83802",
    zip_name: "avery, id",
  },
  {
    postal_code: "56734",
    zip_name: "lake bronson, mn",
  },
  {
    postal_code: "55922",
    zip_name: "canton, mn",
  },
  {
    postal_code: "51333",
    zip_name: "dickens, ia",
  },
  {
    postal_code: "50611",
    zip_name: "bristow, ia",
  },
  {
    postal_code: "23843",
    zip_name: "dolphin, va",
  },
  {
    postal_code: "84085",
    zip_name: "whiterocks, ut",
  },
  {
    postal_code: "96023",
    zip_name: "dorris, ca",
  },
  {
    postal_code: "57441",
    zip_name: "frederick, sd",
  },
  {
    postal_code: "59344",
    zip_name: "plevna, mt",
  },
  {
    postal_code: "64422",
    zip_name: "amity, mo",
  },
  {
    postal_code: "68838",
    zip_name: "farwell, ne",
  },
  {
    postal_code: "74766",
    zip_name: "wright city, ok",
  },
  {
    postal_code: "68365",
    zip_name: "grafton, ne",
  },
  {
    postal_code: "68846",
    zip_name: "hordville, ne",
  },
  {
    postal_code: "61543",
    zip_name: "liverpool, il",
  },
  {
    postal_code: "79547",
    zip_name: "rule, tx",
  },
  {
    postal_code: "59530",
    zip_name: "inverness, mt",
  },
  {
    postal_code: "99359",
    zip_name: "starbuck, wa",
  },
  {
    postal_code: "47853",
    zip_name: "harmony, in",
  },
  {
    postal_code: "17262",
    zip_name: "spring run, pa",
  },
  {
    postal_code: "96133",
    zip_name: "topaz, ca",
  },
  {
    postal_code: "68957",
    zip_name: "lawrence, ne",
  },
  {
    postal_code: "64656",
    zip_name: "ludlow, mo",
  },
  {
    postal_code: "74736",
    zip_name: "garvin, ok",
  },
  {
    postal_code: "25021",
    zip_name: "bim, wv",
  },
  {
    postal_code: "68325",
    zip_name: "byron, ne",
  },
  {
    postal_code: "15671",
    zip_name: "new derry, pa",
  },
  {
    postal_code: "41044",
    zip_name: "germantown, ky",
  },
  {
    postal_code: "67468",
    zip_name: "morganville, ks",
  },
  {
    postal_code: "4964",
    zip_name: "oquossoc, me",
  },
  {
    postal_code: "29567",
    zip_name: "little rock, sc",
  },
  {
    postal_code: "67762",
    zip_name: "weskan, ks",
  },
  {
    postal_code: "60934",
    zip_name: "emington, il",
  },
  {
    postal_code: "73432",
    zip_name: "coleman, ok",
  },
  {
    postal_code: "57428",
    zip_name: "bowdle, sd",
  },
  {
    postal_code: "15429",
    zip_name: "denbo, pa",
  },
  {
    postal_code: "42032",
    zip_name: "columbus, ky",
  },
  {
    postal_code: "40145",
    zip_name: "hudson, ky",
  },
  {
    postal_code: "65320",
    zip_name: "arrow rock, mo",
  },
  {
    postal_code: "7072",
    zip_name: "carlstadt, nj",
  },
  {
    postal_code: "26623",
    zip_name: "frametown, wv",
  },
  {
    postal_code: "1840",
    zip_name: "lawrence, ma",
  },
  {
    postal_code: "80425",
    zip_name: "buffalo creek, co",
  },
  {
    postal_code: "64165",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "23968",
    zip_name: "skipwith, va",
  },
  {
    postal_code: "67950",
    zip_name: "elkhart, ks",
  },
  {
    postal_code: "72051",
    zip_name: "fox, ar",
  },
  {
    postal_code: "49343",
    zip_name: "sand lake, mi",
  },
  {
    postal_code: "4760",
    zip_name: "monticello, me",
  },
  {
    postal_code: "81133",
    zip_name: "fort garland, co",
  },
  {
    postal_code: "26276",
    zip_name: "kerens, wv",
  },
  {
    postal_code: "39886",
    zip_name: "shellman, ga",
  },
  {
    postal_code: "71245",
    zip_name: "grambling, la",
  },
  {
    postal_code: "59410",
    zip_name: "augusta, mt",
  },
  {
    postal_code: "30471",
    zip_name: "twin city, ga",
  },
  {
    postal_code: "14715",
    zip_name: "bolivar, ny",
  },
  {
    postal_code: "98029",
    zip_name: "issaquah, wa",
  },
  {
    postal_code: "24938",
    zip_name: "frankford, wv",
  },
  {
    postal_code: "62461",
    zip_name: "shumway, il",
  },
  {
    postal_code: "41663",
    zip_name: "tram, ky",
  },
  {
    postal_code: "33867",
    zip_name: "river ranch, fl",
  },
  {
    postal_code: "25644",
    zip_name: "sarah ann, wv",
  },
  {
    postal_code: "24634",
    zip_name: "pilgrims knob, va",
  },
  {
    postal_code: "70556",
    zip_name: "mermentau, la",
  },
  {
    postal_code: "87930",
    zip_name: "arrey, nm",
  },
  {
    postal_code: "99343",
    zip_name: "mesa, wa",
  },
  {
    postal_code: "41360",
    zip_name: "pine ridge, ky",
  },
  {
    postal_code: "97065",
    zip_name: "wasco, or",
  },
  {
    postal_code: "58444",
    zip_name: "goodrich, nd",
  },
  {
    postal_code: "93640",
    zip_name: "mendota, ca",
  },
  {
    postal_code: "28020",
    zip_name: "casar, nc",
  },
  {
    postal_code: "31035",
    zip_name: "harrison, ga",
  },
  {
    postal_code: "76682",
    zip_name: "riesel, tx",
  },
  {
    postal_code: "40865",
    zip_name: "putney, ky",
  },
  {
    postal_code: "27047",
    zip_name: "siloam, nc",
  },
  {
    postal_code: "40009",
    zip_name: "bradfordsville, ky",
  },
  {
    postal_code: "16853",
    zip_name: "milesburg, pa",
  },
  {
    postal_code: "32008",
    zip_name: "branford, fl",
  },
  {
    postal_code: "99159",
    zip_name: "odessa, wa",
  },
  {
    postal_code: "20606",
    zip_name: "abell, md",
  },
  {
    postal_code: "49099",
    zip_name: "white pigeon, mi",
  },
  {
    postal_code: "29038",
    zip_name: "cope, sc",
  },
  {
    postal_code: "22831",
    zip_name: "hinton, va",
  },
  {
    postal_code: "72111",
    zip_name: "mount vernon, ar",
  },
  {
    postal_code: "49412",
    zip_name: "fremont, mi",
  },
  {
    postal_code: "42323",
    zip_name: "beechmont, ky",
  },
  {
    postal_code: "54440",
    zip_name: "hatley, wi",
  },
  {
    postal_code: "31096",
    zip_name: "wrightsville, ga",
  },
  {
    postal_code: "73662",
    zip_name: "sayre, ok",
  },
  {
    postal_code: "57344",
    zip_name: "harrison, sd",
  },
  {
    postal_code: "4622",
    zip_name: "cherryfield, me",
  },
  {
    postal_code: "35442",
    zip_name: "aliceville, al",
  },
  {
    postal_code: "38685",
    zip_name: "waterford, ms",
  },
  {
    postal_code: "41722",
    zip_name: "bulan, ky",
  },
  {
    postal_code: "46571",
    zip_name: "topeka, in",
  },
  {
    postal_code: "38865",
    zip_name: "rienzi, ms",
  },
  {
    postal_code: "12069",
    zip_name: "fort hunter, ny",
  },
  {
    postal_code: "37311",
    zip_name: "cleveland, tn",
  },
  {
    postal_code: "46706",
    zip_name: "auburn, in",
  },
  {
    postal_code: "45695",
    zip_name: "wilkesville, oh",
  },
  {
    postal_code: "49312",
    zip_name: "brohman, mi",
  },
  {
    postal_code: "68765",
    zip_name: "osmond, ne",
  },
  {
    postal_code: "14591",
    zip_name: "wyoming, ny",
  },
  {
    postal_code: "4925",
    zip_name: "caratunk, me",
  },
  {
    postal_code: "31002",
    zip_name: "adrian, ga",
  },
  {
    postal_code: "36740",
    zip_name: "forkland, al",
  },
  {
    postal_code: "82842",
    zip_name: "story, wy",
  },
  {
    postal_code: "46807",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "64841",
    zip_name: "duenweg, mo",
  },
  {
    postal_code: "98338",
    zip_name: "graham, wa",
  },
  {
    postal_code: "56151",
    zip_name: "lake wilson, mn",
  },
  {
    postal_code: "62611",
    zip_name: "arenzville, il",
  },
  {
    postal_code: "59739",
    zip_name: "lima, mt",
  },
  {
    postal_code: "48360",
    zip_name: "lake orion, mi",
  },
  {
    postal_code: "73852",
    zip_name: "mooreland, ok",
  },
  {
    postal_code: "36344",
    zip_name: "hartford, al",
  },
  {
    postal_code: "98122",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "31831",
    zip_name: "waverly hall, ga",
  },
  {
    postal_code: "70631",
    zip_name: "cameron, la",
  },
  {
    postal_code: "72373",
    zip_name: "parkin, ar",
  },
  {
    postal_code: "73673",
    zip_name: "willow, ok",
  },
  {
    postal_code: "82935",
    zip_name: "green river, wy",
  },
  {
    postal_code: "75847",
    zip_name: "kennard, tx",
  },
  {
    postal_code: "57638",
    zip_name: "lemmon, sd",
  },
  {
    postal_code: "23229",
    zip_name: "henrico, va",
  },
  {
    postal_code: "59865",
    zip_name: "saint ignatius, mt",
  },
  {
    postal_code: "17508",
    zip_name: "brownstown, pa",
  },
  {
    postal_code: "87417",
    zip_name: "kirtland, nm",
  },
  {
    postal_code: "51355",
    zip_name: "okoboji, ia",
  },
  {
    postal_code: "71009",
    zip_name: "blanchard, la",
  },
  {
    postal_code: "46818",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "74731",
    zip_name: "cartwright, ok",
  },
  {
    postal_code: "46917",
    zip_name: "camden, in",
  },
  {
    postal_code: "47832",
    zip_name: "bloomingdale, in",
  },
  {
    postal_code: "61859",
    zip_name: "ogden, il",
  },
  {
    postal_code: "57448",
    zip_name: "hosmer, sd",
  },
  {
    postal_code: "25264",
    zip_name: "mount alto, wv",
  },
  {
    postal_code: "22538",
    zip_name: "rappahannock academy, va",
  },
  {
    postal_code: "72945",
    zip_name: "midland, ar",
  },
  {
    postal_code: "76676",
    zip_name: "penelope, tx",
  },
  {
    postal_code: "39096",
    zip_name: "lorman, ms",
  },
  {
    postal_code: "72366",
    zip_name: "marvell, ar",
  },
  {
    postal_code: "83235",
    zip_name: "ellis, id",
  },
  {
    postal_code: "48845",
    zip_name: "hubbardston, mi",
  },
  {
    postal_code: "12745",
    zip_name: "hortonville, ny",
  },
  {
    postal_code: "43158",
    zip_name: "union furnace, oh",
  },
  {
    postal_code: "24981",
    zip_name: "talcott, wv",
  },
  {
    postal_code: "15962",
    zip_name: "wilmore, pa",
  },
  {
    postal_code: "71940",
    zip_name: "delight, ar",
  },
  {
    postal_code: "66603",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "67512",
    zip_name: "alden, ks",
  },
  {
    postal_code: "1039",
    zip_name: "haydenville, ma",
  },
  {
    postal_code: "61258",
    zip_name: "hooppole, il",
  },
  {
    postal_code: "83238",
    zip_name: "geneva, id",
  },
  {
    postal_code: "52077",
    zip_name: "volga, ia",
  },
  {
    postal_code: "15710",
    zip_name: "alverda, pa",
  },
  {
    postal_code: "43788",
    zip_name: "summerfield, oh",
  },
  {
    postal_code: "58222",
    zip_name: "crystal, nd",
  },
  {
    postal_code: "72760",
    zip_name: "saint paul, ar",
  },
  {
    postal_code: "41560",
    zip_name: "robinson creek, ky",
  },
  {
    postal_code: "67470",
    zip_name: "new cambria, ks",
  },
  {
    postal_code: "65076",
    zip_name: "saint thomas, mo",
  },
  {
    postal_code: "50455",
    zip_name: "mc intire, ia",
  },
  {
    postal_code: "40862",
    zip_name: "partridge, ky",
  },
  {
    postal_code: "98853",
    zip_name: "stratford, wa",
  },
  {
    postal_code: "56294",
    zip_name: "wanda, mn",
  },
  {
    postal_code: "8528",
    zip_name: "kingston, nj",
  },
  {
    postal_code: "83435",
    zip_name: "monteview, id",
  },
  {
    postal_code: "77974",
    zip_name: "meyersville, tx",
  },
  {
    postal_code: "99347",
    zip_name: "pomeroy, wa",
  },
  {
    postal_code: "59425",
    zip_name: "conrad, mt",
  },
  {
    postal_code: "11770",
    zip_name: "ocean beach, ny",
  },
  {
    postal_code: "39638",
    zip_name: "gloster, ms",
  },
  {
    postal_code: "54313",
    zip_name: "green bay, wi",
  },
  {
    postal_code: "49631",
    zip_name: "evart, mi",
  },
  {
    postal_code: "31510",
    zip_name: "alma, ga",
  },
  {
    postal_code: "45767",
    zip_name: "new matamoras, oh",
  },
  {
    postal_code: "99357",
    zip_name: "royal city, wa",
  },
  {
    postal_code: "15851",
    zip_name: "reynoldsville, pa",
  },
  {
    postal_code: "30803",
    zip_name: "avera, ga",
  },
  {
    postal_code: "62463",
    zip_name: "stewardson, il",
  },
  {
    postal_code: "83872",
    zip_name: "viola, id",
  },
  {
    postal_code: "37361",
    zip_name: "ocoee, tn",
  },
  {
    postal_code: "73103",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "14536",
    zip_name: "portageville, ny",
  },
  {
    postal_code: "38552",
    zip_name: "chestnut mound, tn",
  },
  {
    postal_code: "36320",
    zip_name: "cottonwood, al",
  },
  {
    postal_code: "30731",
    zip_name: "menlo, ga",
  },
  {
    postal_code: "24646",
    zip_name: "rowe, va",
  },
  {
    postal_code: "45622",
    zip_name: "creola, oh",
  },
  {
    postal_code: "89039",
    zip_name: "cal nev ari, nv",
  },
  {
    postal_code: "15781",
    zip_name: "walston, pa",
  },
  {
    postal_code: "79239",
    zip_name: "lakeview, tx",
  },
  {
    postal_code: "41201",
    zip_name: "adams, ky",
  },
  {
    postal_code: "25650",
    zip_name: "verner, wv",
  },
  {
    postal_code: "45160",
    zip_name: "owensville, oh",
  },
  {
    postal_code: "62425",
    zip_name: "dundas, il",
  },
  {
    postal_code: "74949",
    zip_name: "muse, ok",
  },
  {
    postal_code: "12754",
    zip_name: "liberty, ny",
  },
  {
    postal_code: "35570",
    zip_name: "hamilton, al",
  },
  {
    postal_code: "70589",
    zip_name: "washington, la",
  },
  {
    postal_code: "62670",
    zip_name: "new berlin, il",
  },
  {
    postal_code: "15762",
    zip_name: "nicktown, pa",
  },
  {
    postal_code: "41522",
    zip_name: "elkhorn city, ky",
  },
  {
    postal_code: "98503",
    zip_name: "lacey, wa",
  },
  {
    postal_code: "72020",
    zip_name: "bradford, ar",
  },
  {
    postal_code: "64476",
    zip_name: "pickering, mo",
  },
  {
    postal_code: "23502",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "6065",
    zip_name: "riverton, ct",
  },
  {
    postal_code: "72039",
    zip_name: "damascus, ar",
  },
  {
    postal_code: "58054",
    zip_name: "lisbon, nd",
  },
  {
    postal_code: "71028",
    zip_name: "gibsland, la",
  },
  {
    postal_code: "73627",
    zip_name: "carter, ok",
  },
  {
    postal_code: "53007",
    zip_name: "butler, wi",
  },
  {
    postal_code: "35756",
    zip_name: "madison, al",
  },
  {
    postal_code: "31090",
    zip_name: "toomsboro, ga",
  },
  {
    postal_code: "98327",
    zip_name: "dupont, wa",
  },
  {
    postal_code: "55968",
    zip_name: "reads landing, mn",
  },
  {
    postal_code: "67645",
    zip_name: "lenora, ks",
  },
  {
    postal_code: "25978",
    zip_name: "nimitz, wv",
  },
  {
    postal_code: "4456",
    zip_name: "levant, me",
  },
  {
    postal_code: "75933",
    zip_name: "call, tx",
  },
  {
    postal_code: "48139",
    zip_name: "hamburg, mi",
  },
  {
    postal_code: "51009",
    zip_name: "calumet, ia",
  },
  {
    postal_code: "62477",
    zip_name: "west union, il",
  },
  {
    postal_code: "13697",
    zip_name: "winthrop, ny",
  },
  {
    postal_code: "36303",
    zip_name: "dothan, al",
  },
  {
    postal_code: "93626",
    zip_name: "friant, ca",
  },
  {
    postal_code: "57249",
    zip_name: "lake preston, sd",
  },
  {
    postal_code: "59848",
    zip_name: "lonepine, mt",
  },
  {
    postal_code: "25501",
    zip_name: "alkol, wv",
  },
  {
    postal_code: "29584",
    zip_name: "patrick, sc",
  },
  {
    postal_code: "67842",
    zip_name: "ford, ks",
  },
  {
    postal_code: "49052",
    zip_name: "fulton, mi",
  },
  {
    postal_code: "7305",
    zip_name: "jersey city, nj",
  },
  {
    postal_code: "37181",
    zip_name: "vanleer, tn",
  },
  {
    postal_code: "66547",
    zip_name: "wamego, ks",
  },
  {
    postal_code: "78573",
    zip_name: "mission, tx",
  },
  {
    postal_code: "5680",
    zip_name: "wolcott, vt",
  },
  {
    postal_code: "75105",
    zip_name: "chatfield, tx",
  },
  {
    postal_code: "54213",
    zip_name: "forestville, wi",
  },
  {
    postal_code: "12720",
    zip_name: "bethel, ny",
  },
  {
    postal_code: "56219",
    zip_name: "browns valley, mn",
  },
  {
    postal_code: "39641",
    zip_name: "jayess, ms",
  },
  {
    postal_code: "37330",
    zip_name: "estill springs, tn",
  },
  {
    postal_code: "12785",
    zip_name: "westbrookville, ny",
  },
  {
    postal_code: "75639",
    zip_name: "de berry, tx",
  },
  {
    postal_code: "56629",
    zip_name: "birchdale, mn",
  },
  {
    postal_code: "10601",
    zip_name: "white plains, ny",
  },
  {
    postal_code: "95443",
    zip_name: "glenhaven, ca",
  },
  {
    postal_code: "38740",
    zip_name: "duncan, ms",
  },
  {
    postal_code: "23860",
    zip_name: "hopewell, va",
  },
  {
    postal_code: "61731",
    zip_name: "cropsey, il",
  },
  {
    postal_code: "7847",
    zip_name: "kenvil, nj",
  },
  {
    postal_code: "12964",
    zip_name: "new russia, ny",
  },
  {
    postal_code: "83423",
    zip_name: "dubois, id",
  },
  {
    postal_code: "46777",
    zip_name: "ossian, in",
  },
  {
    postal_code: "22940",
    zip_name: "free union, va",
  },
  {
    postal_code: "85632",
    zip_name: "san simon, az",
  },
  {
    postal_code: "61833",
    zip_name: "tilton, il",
  },
  {
    postal_code: "49066",
    zip_name: "leonidas, mi",
  },
  {
    postal_code: "40818",
    zip_name: "coalgood, ky",
  },
  {
    postal_code: "72072",
    zip_name: "humnoke, ar",
  },
  {
    postal_code: "16634",
    zip_name: "dudley, pa",
  },
  {
    postal_code: "23876",
    zip_name: "rawlings, va",
  },
  {
    postal_code: "77538",
    zip_name: "devers, tx",
  },
  {
    postal_code: "63540",
    zip_name: "gibbs, mo",
  },
  {
    postal_code: "66938",
    zip_name: "clyde, ks",
  },
  {
    postal_code: "62867",
    zip_name: "new haven, il",
  },
  {
    postal_code: "95569",
    zip_name: "redcrest, ca",
  },
  {
    postal_code: "5776",
    zip_name: "west rupert, vt",
  },
  {
    postal_code: "50671",
    zip_name: "stanley, ia",
  },
  {
    postal_code: "62325",
    zip_name: "coatsburg, il",
  },
  {
    postal_code: "93541",
    zip_name: "lee vining, ca",
  },
  {
    postal_code: "4063",
    zip_name: "ocean park, me",
  },
  {
    postal_code: "86502",
    zip_name: "chambers, az",
  },
  {
    postal_code: "68871",
    zip_name: "rockville, ne",
  },
  {
    postal_code: "73097",
    zip_name: "wheatland, ok",
  },
  {
    postal_code: "41632",
    zip_name: "gunlock, ky",
  },
  {
    postal_code: "71474",
    zip_name: "simpson, la",
  },
  {
    postal_code: "16937",
    zip_name: "mills, pa",
  },
  {
    postal_code: "66858",
    zip_name: "lincolnville, ks",
  },
  {
    postal_code: "89018",
    zip_name: "indian springs, nv",
  },
  {
    postal_code: "60183",
    zip_name: "wasco, il",
  },
  {
    postal_code: "15380",
    zip_name: "wind ridge, pa",
  },
  {
    postal_code: "97920",
    zip_name: "westfall, or",
  },
  {
    postal_code: "56349",
    zip_name: "lowry, mn",
  },
  {
    postal_code: "23828",
    zip_name: "branchville, va",
  },
  {
    postal_code: "84313",
    zip_name: "grouse creek, ut",
  },
  {
    postal_code: "64458",
    zip_name: "hatfield, mo",
  },
  {
    postal_code: "21715",
    zip_name: "brownsville, md",
  },
  {
    postal_code: "72478",
    zip_name: "warm springs, ar",
  },
  {
    postal_code: "56034",
    zip_name: "garden city, mn",
  },
  {
    postal_code: "56186",
    zip_name: "woodstock, mn",
  },
  {
    postal_code: "26581",
    zip_name: "littleton, wv",
  },
  {
    postal_code: "73663",
    zip_name: "seiling, ok",
  },
  {
    postal_code: "50633",
    zip_name: "geneva, ia",
  },
  {
    postal_code: "80744",
    zip_name: "ovid, co",
  },
  {
    postal_code: "52549",
    zip_name: "cincinnati, ia",
  },
  {
    postal_code: "16253",
    zip_name: "seminole, pa",
  },
  {
    postal_code: "73933",
    zip_name: "boise city, ok",
  },
  {
    postal_code: "51030",
    zip_name: "lawton, ia",
  },
  {
    postal_code: "61344",
    zip_name: "mineral, il",
  },
  {
    postal_code: "89833",
    zip_name: "ruby valley, nv",
  },
  {
    postal_code: "46146",
    zip_name: "homer, in",
  },
  {
    postal_code: "40209",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "14826",
    zip_name: "cohocton, ny",
  },
  {
    postal_code: "64126",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "30442",
    zip_name: "millen, ga",
  },
  {
    postal_code: "48092",
    zip_name: "warren, mi",
  },
  {
    postal_code: "96748",
    zip_name: "kaunakakai, hi",
  },
  {
    postal_code: "61430",
    zip_name: "east galesburg, il",
  },
  {
    postal_code: "64636",
    zip_name: "coffey, mo",
  },
  {
    postal_code: "13310",
    zip_name: "bouckville, ny",
  },
  {
    postal_code: "58261",
    zip_name: "minto, nd",
  },
  {
    postal_code: "38848",
    zip_name: "greenwood springs, ms",
  },
  {
    postal_code: "83847",
    zip_name: "naples, id",
  },
  {
    postal_code: "24076",
    zip_name: "claudville, va",
  },
  {
    postal_code: "61773",
    zip_name: "sibley, il",
  },
  {
    postal_code: "68883",
    zip_name: "wood river, ne",
  },
  {
    postal_code: "70783",
    zip_name: "ventress, la",
  },
  {
    postal_code: "95317",
    zip_name: "el nido, ca",
  },
  {
    postal_code: "53073",
    zip_name: "plymouth, wi",
  },
  {
    postal_code: "31735",
    zip_name: "cobb, ga",
  },
  {
    postal_code: "63830",
    zip_name: "caruthersville, mo",
  },
  {
    postal_code: "69152",
    zip_name: "mullen, ne",
  },
  {
    postal_code: "82512",
    zip_name: "crowheart, wy",
  },
  {
    postal_code: "71670",
    zip_name: "tillar, ar",
  },
  {
    postal_code: "56517",
    zip_name: "beltrami, mn",
  },
  {
    postal_code: "44638",
    zip_name: "lakeville, oh",
  },
  {
    postal_code: "38626",
    zip_name: "dundee, ms",
  },
  {
    postal_code: "74936",
    zip_name: "gans, ok",
  },
  {
    postal_code: "98037",
    zip_name: "lynnwood, wa",
  },
  {
    postal_code: "42076",
    zip_name: "new concord, ky",
  },
  {
    postal_code: "16832",
    zip_name: "coburn, pa",
  },
  {
    postal_code: "57279",
    zip_name: "wilmot, sd",
  },
  {
    postal_code: "55962",
    zip_name: "peterson, mn",
  },
  {
    postal_code: "98250",
    zip_name: "friday harbor, wa",
  },
  {
    postal_code: "98109",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "61553",
    zip_name: "norris, il",
  },
  {
    postal_code: "75693",
    zip_name: "white oak, tx",
  },
  {
    postal_code: "98055",
    zip_name: "renton, wa",
  },
  {
    postal_code: "13415",
    zip_name: "new lisbon, ny",
  },
  {
    postal_code: "96717",
    zip_name: "hauula, hi",
  },
  {
    postal_code: "52222",
    zip_name: "deep river, ia",
  },
  {
    postal_code: "50676",
    zip_name: "tripoli, ia",
  },
  {
    postal_code: "56114",
    zip_name: "avoca, mn",
  },
  {
    postal_code: "37032",
    zip_name: "cedar hill, tn",
  },
  {
    postal_code: "47165",
    zip_name: "pekin, in",
  },
  {
    postal_code: "21734",
    zip_name: "funkstown, md",
  },
  {
    postal_code: "28438",
    zip_name: "evergreen, nc",
  },
  {
    postal_code: "6510",
    zip_name: "new haven, ct",
  },
  {
    postal_code: "18643",
    zip_name: "pittston, pa",
  },
  {
    postal_code: "30434",
    zip_name: "louisville, ga",
  },
  {
    postal_code: "88072",
    zip_name: "vado, nm",
  },
  {
    postal_code: "65280",
    zip_name: "rush hill, mo",
  },
  {
    postal_code: "57274",
    zip_name: "webster, sd",
  },
  {
    postal_code: "50126",
    zip_name: "iowa falls, ia",
  },
  {
    postal_code: "23433",
    zip_name: "suffolk, va",
  },
  {
    postal_code: "97830",
    zip_name: "fossil, or",
  },
  {
    postal_code: "79752",
    zip_name: "mc camey, tx",
  },
  {
    postal_code: "66872",
    zip_name: "white city, ks",
  },
  {
    postal_code: "97454",
    zip_name: "marcola, or",
  },
  {
    postal_code: "98294",
    zip_name: "sultan, wa",
  },
  {
    postal_code: "52358",
    zip_name: "west branch, ia",
  },
  {
    postal_code: "48836",
    zip_name: "fowlerville, mi",
  },
  {
    postal_code: "50604",
    zip_name: "aplington, ia",
  },
  {
    postal_code: "42455",
    zip_name: "sebree, ky",
  },
  {
    postal_code: "99323",
    zip_name: "burbank, wa",
  },
  {
    postal_code: "36020",
    zip_name: "coosada, al",
  },
  {
    postal_code: "38567",
    zip_name: "hickman, tn",
  },
  {
    postal_code: "67520",
    zip_name: "bison, ks",
  },
  {
    postal_code: "78886",
    zip_name: "yancey, tx",
  },
  {
    postal_code: "52168",
    zip_name: "spillville, ia",
  },
  {
    postal_code: "46787",
    zip_name: "south whitley, in",
  },
  {
    postal_code: "23023",
    zip_name: "bruington, va",
  },
  {
    postal_code: "76670",
    zip_name: "milford, tx",
  },
  {
    postal_code: "98116",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "10916",
    zip_name: "campbell hall, ny",
  },
  {
    postal_code: "32440",
    zip_name: "graceville, fl",
  },
  {
    postal_code: "98034",
    zip_name: "kirkland, wa",
  },
  {
    postal_code: "72941",
    zip_name: "lavaca, ar",
  },
  {
    postal_code: "16635",
    zip_name: "duncansville, pa",
  },
  {
    postal_code: "41740",
    zip_name: "emmalena, ky",
  },
  {
    postal_code: "48027",
    zip_name: "goodells, mi",
  },
  {
    postal_code: "57053",
    zip_name: "parker, sd",
  },
  {
    postal_code: "53214",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "14620",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "54669",
    zip_name: "west salem, wi",
  },
  {
    postal_code: "28581",
    zip_name: "stacy, nc",
  },
  {
    postal_code: "66711",
    zip_name: "arcadia, ks",
  },
  {
    postal_code: "12139",
    zip_name: "piseco, ny",
  },
  {
    postal_code: "62570",
    zip_name: "tovey, il",
  },
  {
    postal_code: "97870",
    zip_name: "richland, or",
  },
  {
    postal_code: "77470",
    zip_name: "rock island, tx",
  },
  {
    postal_code: "36693",
    zip_name: "mobile, al",
  },
  {
    postal_code: "56313",
    zip_name: "bock, mn",
  },
  {
    postal_code: "49411",
    zip_name: "free soil, mi",
  },
  {
    postal_code: "37076",
    zip_name: "hermitage, tn",
  },
  {
    postal_code: "61816",
    zip_name: "broadlands, il",
  },
  {
    postal_code: "35453",
    zip_name: "cottondale, al",
  },
  {
    postal_code: "84069",
    zip_name: "rush valley, ut",
  },
  {
    postal_code: "23836",
    zip_name: "chester, va",
  },
  {
    postal_code: "98935",
    zip_name: "mabton, wa",
  },
  {
    postal_code: "53153",
    zip_name: "north prairie, wi",
  },
  {
    postal_code: "16151",
    zip_name: "sheakleyville, pa",
  },
  {
    postal_code: "25180",
    zip_name: "saxon, wv",
  },
  {
    postal_code: "54965",
    zip_name: "pine river, wi",
  },
  {
    postal_code: "46760",
    zip_name: "kimmell, in",
  },
  {
    postal_code: "71004",
    zip_name: "belcher, la",
  },
  {
    postal_code: "98371",
    zip_name: "puyallup, wa",
  },
  {
    postal_code: "72021",
    zip_name: "brinkley, ar",
  },
  {
    postal_code: "74939",
    zip_name: "hodgen, ok",
  },
  {
    postal_code: "7435",
    zip_name: "newfoundland, nj",
  },
  {
    postal_code: "50662",
    zip_name: "oelwein, ia",
  },
  {
    postal_code: "51648",
    zip_name: "percival, ia",
  },
  {
    postal_code: "37014",
    zip_name: "arrington, tn",
  },
  {
    postal_code: "38773",
    zip_name: "shaw, ms",
  },
  {
    postal_code: "42326",
    zip_name: "browder, ky",
  },
  {
    postal_code: "63452",
    zip_name: "lewistown, mo",
  },
  {
    postal_code: "58737",
    zip_name: "flaxton, nd",
  },
  {
    postal_code: "54625",
    zip_name: "dodge, wi",
  },
  {
    postal_code: "98539",
    zip_name: "doty, wa",
  },
  {
    postal_code: "94963",
    zip_name: "san geronimo, ca",
  },
  {
    postal_code: "43007",
    zip_name: "broadway, oh",
  },
  {
    postal_code: "51048",
    zip_name: "pierson, ia",
  },
  {
    postal_code: "59215",
    zip_name: "circle, mt",
  },
  {
    postal_code: "95075",
    zip_name: "tres pinos, ca",
  },
  {
    postal_code: "38952",
    zip_name: "schlater, ms",
  },
  {
    postal_code: "57227",
    zip_name: "corona, sd",
  },
  {
    postal_code: "56710",
    zip_name: "alvarado, mn",
  },
  {
    postal_code: "42753",
    zip_name: "knifley, ky",
  },
  {
    postal_code: "76957",
    zip_name: "wall, tx",
  },
  {
    postal_code: "99322",
    zip_name: "bickleton, wa",
  },
  {
    postal_code: "17768",
    zip_name: "shunk, pa",
  },
  {
    postal_code: "15492",
    zip_name: "wickhaven, pa",
  },
  {
    postal_code: "24316",
    zip_name: "broadford, va",
  },
  {
    postal_code: "81121",
    zip_name: "arboles, co",
  },
  {
    postal_code: "54765",
    zip_name: "sand creek, wi",
  },
  {
    postal_code: "66550",
    zip_name: "wetmore, ks",
  },
  {
    postal_code: "72030",
    zip_name: "cleveland, ar",
  },
  {
    postal_code: "79847",
    zip_name: "salt flat, tx",
  },
  {
    postal_code: "62478",
    zip_name: "west york, il",
  },
  {
    postal_code: "65439",
    zip_name: "bixby, mo",
  },
  {
    postal_code: "62601",
    zip_name: "alexander, il",
  },
  {
    postal_code: "25204",
    zip_name: "twilight, wv",
  },
  {
    postal_code: "35587",
    zip_name: "townley, al",
  },
  {
    postal_code: "17060",
    zip_name: "mill creek, pa",
  },
  {
    postal_code: "26814",
    zip_name: "riverton, wv",
  },
  {
    postal_code: "61374",
    zip_name: "van orin, il",
  },
  {
    postal_code: "89425",
    zip_name: "orovada, nv",
  },
  {
    postal_code: "50152",
    zip_name: "luther, ia",
  },
  {
    postal_code: "44510",
    zip_name: "youngstown, oh",
  },
  {
    postal_code: "26437",
    zip_name: "smithfield, wv",
  },
  {
    postal_code: "39144",
    zip_name: "pattison, ms",
  },
  {
    postal_code: "6389",
    zip_name: "yantic, ct",
  },
  {
    postal_code: "73537",
    zip_name: "eldorado, ok",
  },
  {
    postal_code: "16659",
    zip_name: "loysburg, pa",
  },
  {
    postal_code: "91917",
    zip_name: "dulzura, ca",
  },
  {
    postal_code: "68635",
    zip_name: "dwight, ne",
  },
  {
    postal_code: "24066",
    zip_name: "buchanan, va",
  },
  {
    postal_code: "52076",
    zip_name: "strawberry point, ia",
  },
  {
    postal_code: "17266",
    zip_name: "walnut bottom, pa",
  },
  {
    postal_code: "84782",
    zip_name: "veyo, ut",
  },
  {
    postal_code: "58201",
    zip_name: "grand forks, nd",
  },
  {
    postal_code: "71243",
    zip_name: "fort necessity, la",
  },
  {
    postal_code: "66515",
    zip_name: "morrill, ks",
  },
  {
    postal_code: "39817",
    zip_name: "bainbridge, ga",
  },
  {
    postal_code: "47161",
    zip_name: "new salisbury, in",
  },
  {
    postal_code: "58233",
    zip_name: "forest river, nd",
  },
  {
    postal_code: "26237",
    zip_name: "tallmansville, wv",
  },
  {
    postal_code: "36870",
    zip_name: "phenix city, al",
  },
  {
    postal_code: "3607",
    zip_name: "south acworth, nh",
  },
  {
    postal_code: "37396",
    zip_name: "whiteside, tn",
  },
  {
    postal_code: "73552",
    zip_name: "indiahoma, ok",
  },
  {
    postal_code: "50256",
    zip_name: "tracy, ia",
  },
  {
    postal_code: "66406",
    zip_name: "beattie, ks",
  },
  {
    postal_code: "62862",
    zip_name: "mill shoals, il",
  },
  {
    postal_code: "71250",
    zip_name: "jones, la",
  },
  {
    postal_code: "64467",
    zip_name: "martinsville, mo",
  },
  {
    postal_code: "56167",
    zip_name: "round lake, mn",
  },
  {
    postal_code: "92283",
    zip_name: "winterhaven, ca",
  },
  {
    postal_code: "25268",
    zip_name: "orma, wv",
  },
  {
    postal_code: "62070",
    zip_name: "mozier, il",
  },
  {
    postal_code: "72315",
    zip_name: "blytheville, ar",
  },
  {
    postal_code: "62674",
    zip_name: "palmyra, il",
  },
  {
    postal_code: "80426",
    zip_name: "burns, co",
  },
  {
    postal_code: "50446",
    zip_name: "joice, ia",
  },
  {
    postal_code: "24957",
    zip_name: "maxwelton, wv",
  },
  {
    postal_code: "4613",
    zip_name: "birch harbor, me",
  },
  {
    postal_code: "98279",
    zip_name: "olga, wa",
  },
  {
    postal_code: "1370",
    zip_name: "shelburne falls, ma",
  },
  {
    postal_code: "81655",
    zip_name: "wolcott, co",
  },
  {
    postal_code: "60912",
    zip_name: "beaverville, il",
  },
  {
    postal_code: "30541",
    zip_name: "epworth, ga",
  },
  {
    postal_code: "84038",
    zip_name: "laketown, ut",
  },
  {
    postal_code: "41564",
    zip_name: "sidney, ky",
  },
  {
    postal_code: "54407",
    zip_name: "amherst junction, wi",
  },
  {
    postal_code: "79376",
    zip_name: "tokio, tx",
  },
  {
    postal_code: "12723",
    zip_name: "callicoon, ny",
  },
  {
    postal_code: "23436",
    zip_name: "suffolk, va",
  },
  {
    postal_code: "30456",
    zip_name: "sardis, ga",
  },
  {
    postal_code: "58356",
    zip_name: "new rockford, nd",
  },
  {
    postal_code: "25502",
    zip_name: "apple grove, wv",
  },
  {
    postal_code: "7739",
    zip_name: "little silver, nj",
  },
  {
    postal_code: "52041",
    zip_name: "earlville, ia",
  },
  {
    postal_code: "58301",
    zip_name: "devils lake, nd",
  },
  {
    postal_code: "30453",
    zip_name: "reidsville, ga",
  },
  {
    postal_code: "25938",
    zip_name: "victor, wv",
  },
  {
    postal_code: "51104",
    zip_name: "sioux city, ia",
  },
  {
    postal_code: "53924",
    zip_name: "cazenovia, wi",
  },
  {
    postal_code: "37080",
    zip_name: "joelton, tn",
  },
  {
    postal_code: "5040",
    zip_name: "east corinth, vt",
  },
  {
    postal_code: "7463",
    zip_name: "waldwick, nj",
  },
  {
    postal_code: "50426",
    zip_name: "carpenter, ia",
  },
  {
    postal_code: "83539",
    zip_name: "kooskia, id",
  },
  {
    postal_code: "67223",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "35541",
    zip_name: "arley, al",
  },
  {
    postal_code: "98261",
    zip_name: "lopez island, wa",
  },
  {
    postal_code: "32501",
    zip_name: "pensacola, fl",
  },
  {
    postal_code: "76351",
    zip_name: "archer city, tx",
  },
  {
    postal_code: "32567",
    zip_name: "laurel hill, fl",
  },
  {
    postal_code: "24471",
    zip_name: "port republic, va",
  },
  {
    postal_code: "47598",
    zip_name: "winslow, in",
  },
  {
    postal_code: "47140",
    zip_name: "marengo, in",
  },
  {
    postal_code: "82323",
    zip_name: "dixon, wy",
  },
  {
    postal_code: "65039",
    zip_name: "hartsburg, mo",
  },
  {
    postal_code: "54484",
    zip_name: "stratford, wi",
  },
  {
    postal_code: "80821",
    zip_name: "hugo, co",
  },
  {
    postal_code: "61846",
    zip_name: "georgetown, il",
  },
  {
    postal_code: "67144",
    zip_name: "towanda, ks",
  },
  {
    postal_code: "27891",
    zip_name: "whitakers, nc",
  },
  {
    postal_code: "92266",
    zip_name: "palo verde, ca",
  },
  {
    postal_code: "25844",
    zip_name: "glen daniel, wv",
  },
  {
    postal_code: "44670",
    zip_name: "robertsville, oh",
  },
  {
    postal_code: "71744",
    zip_name: "hampton, ar",
  },
  {
    postal_code: "72419",
    zip_name: "caraway, ar",
  },
  {
    postal_code: "7503",
    zip_name: "paterson, nj",
  },
  {
    postal_code: "24375",
    zip_name: "sugar grove, va",
  },
  {
    postal_code: "61259",
    zip_name: "illinois city, il",
  },
  {
    postal_code: "30457",
    zip_name: "soperton, ga",
  },
  {
    postal_code: "72064",
    zip_name: "hazen, ar",
  },
  {
    postal_code: "38109",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "18351",
    zip_name: "portland, pa",
  },
  {
    postal_code: "14170",
    zip_name: "west falls, ny",
  },
  {
    postal_code: "35542",
    zip_name: "bankston, al",
  },
  {
    postal_code: "10926",
    zip_name: "harriman, ny",
  },
  {
    postal_code: "37097",
    zip_name: "lobelville, tn",
  },
  {
    postal_code: "57438",
    zip_name: "faulkton, sd",
  },
  {
    postal_code: "41731",
    zip_name: "cornettsville, ky",
  },
  {
    postal_code: "72905",
    zip_name: "fort smith, ar",
  },
  {
    postal_code: "16110",
    zip_name: "adamsville, pa",
  },
  {
    postal_code: "51451",
    zip_name: "lanesboro, ia",
  },
  {
    postal_code: "72826",
    zip_name: "blue mountain, ar",
  },
  {
    postal_code: "73731",
    zip_name: "dacoma, ok",
  },
  {
    postal_code: "58210",
    zip_name: "adams, nd",
  },
  {
    postal_code: "16847",
    zip_name: "kylertown, pa",
  },
  {
    postal_code: "35745",
    zip_name: "estillfork, al",
  },
  {
    postal_code: "93526",
    zip_name: "independence, ca",
  },
  {
    postal_code: "56251",
    zip_name: "kandiyohi, mn",
  },
  {
    postal_code: "68323",
    zip_name: "burchard, ne",
  },
  {
    postal_code: "43914",
    zip_name: "cameron, oh",
  },
  {
    postal_code: "62085",
    zip_name: "sawyerville, il",
  },
  {
    postal_code: "59219",
    zip_name: "dagmar, mt",
  },
  {
    postal_code: "63951",
    zip_name: "lowndes, mo",
  },
  {
    postal_code: "69347",
    zip_name: "hay springs, ne",
  },
  {
    postal_code: "17868",
    zip_name: "riverside, pa",
  },
  {
    postal_code: "93224",
    zip_name: "fellows, ca",
  },
  {
    postal_code: "51636",
    zip_name: "coin, ia",
  },
  {
    postal_code: "15432",
    zip_name: "dunlevy, pa",
  },
  {
    postal_code: "22649",
    zip_name: "middletown, va",
  },
  {
    postal_code: "99741",
    zip_name: "galena, ak",
  },
  {
    postal_code: "25624",
    zip_name: "henlawson, wv",
  },
  {
    postal_code: "70723",
    zip_name: "convent, la",
  },
  {
    postal_code: "64639",
    zip_name: "de witt, mo",
  },
  {
    postal_code: "49796",
    zip_name: "walloon lake, mi",
  },
  {
    postal_code: "97848",
    zip_name: "kimberly, or",
  },
  {
    postal_code: "68314",
    zip_name: "bee, ne",
  },
  {
    postal_code: "74569",
    zip_name: "stringtown, ok",
  },
  {
    postal_code: "67638",
    zip_name: "gaylord, ks",
  },
  {
    postal_code: "83214",
    zip_name: "arimo, id",
  },
  {
    postal_code: "79538",
    zip_name: "novice, tx",
  },
  {
    postal_code: "97873",
    zip_name: "seneca, or",
  },
  {
    postal_code: "68336",
    zip_name: "davey, ne",
  },
  {
    postal_code: "41166",
    zip_name: "quincy, ky",
  },
  {
    postal_code: "24474",
    zip_name: "selma, va",
  },
  {
    postal_code: "67346",
    zip_name: "grenola, ks",
  },
  {
    postal_code: "98003",
    zip_name: "federal way, wa",
  },
  {
    postal_code: "42232",
    zip_name: "gracey, ky",
  },
  {
    postal_code: "74523",
    zip_name: "antlers, ok",
  },
  {
    postal_code: "98226",
    zip_name: "bellingham, wa",
  },
  {
    postal_code: "29033",
    zip_name: "cayce, sc",
  },
  {
    postal_code: "81211",
    zip_name: "buena vista, co",
  },
  {
    postal_code: "97622",
    zip_name: "bly, or",
  },
  {
    postal_code: "98576",
    zip_name: "rainier, wa",
  },
  {
    postal_code: "59201",
    zip_name: "wolf point, mt",
  },
  {
    postal_code: "52401",
    zip_name: "cedar rapids, ia",
  },
  {
    postal_code: "49725",
    zip_name: "de tour village, mi",
  },
  {
    postal_code: "57722",
    zip_name: "buffalo gap, sd",
  },
  {
    postal_code: "31771",
    zip_name: "norman park, ga",
  },
  {
    postal_code: "13611",
    zip_name: "belleville, ny",
  },
  {
    postal_code: "72455",
    zip_name: "pocahontas, ar",
  },
  {
    postal_code: "88036",
    zip_name: "fort bayard, nm",
  },
  {
    postal_code: "23050",
    zip_name: "dutton, va",
  },
  {
    postal_code: "92060",
    zip_name: "palomar mountain, ca",
  },
  {
    postal_code: "98236",
    zip_name: "clinton, wa",
  },
  {
    postal_code: "58496",
    zip_name: "woodworth, nd",
  },
  {
    postal_code: "71667",
    zip_name: "star city, ar",
  },
  {
    postal_code: "98394",
    zip_name: "vaughn, wa",
  },
  {
    postal_code: "99568",
    zip_name: "clam gulch, ak",
  },
  {
    postal_code: "96022",
    zip_name: "cottonwood, ca",
  },
  {
    postal_code: "59420",
    zip_name: "carter, mt",
  },
  {
    postal_code: "68662",
    zip_name: "shelby, ne",
  },
  {
    postal_code: "62282",
    zip_name: "saint libory, il",
  },
  {
    postal_code: "71962",
    zip_name: "okolona, ar",
  },
  {
    postal_code: "16633",
    zip_name: "defiance, pa",
  },
  {
    postal_code: "17002",
    zip_name: "allensville, pa",
  },
  {
    postal_code: "13477",
    zip_name: "vernon center, ny",
  },
  {
    postal_code: "63625",
    zip_name: "black, mo",
  },
  {
    postal_code: "61460",
    zip_name: "media, il",
  },
  {
    postal_code: "16664",
    zip_name: "new enterprise, pa",
  },
  {
    postal_code: "98342",
    zip_name: "indianola, wa",
  },
  {
    postal_code: "76712",
    zip_name: "woodway, tx",
  },
  {
    postal_code: "17774",
    zip_name: "unityville, pa",
  },
  {
    postal_code: "56584",
    zip_name: "twin valley, mn",
  },
  {
    postal_code: "63851",
    zip_name: "hayti, mo",
  },
  {
    postal_code: "59064",
    zip_name: "pompeys pillar, mt",
  },
  {
    postal_code: "21790",
    zip_name: "tuscarora, md",
  },
  {
    postal_code: "57107",
    zip_name: "sioux falls, sd",
  },
  {
    postal_code: "59501",
    zip_name: "havre, mt",
  },
  {
    postal_code: "49316",
    zip_name: "caledonia, mi",
  },
  {
    postal_code: "58072",
    zip_name: "valley city, nd",
  },
  {
    postal_code: "53132",
    zip_name: "franklin, wi",
  },
  {
    postal_code: "62092",
    zip_name: "white hall, il",
  },
  {
    postal_code: "50531",
    zip_name: "dolliver, ia",
  },
  {
    postal_code: "81033",
    zip_name: "crowley, co",
  },
  {
    postal_code: "47280",
    zip_name: "taylorsville, in",
  },
  {
    postal_code: "64019",
    zip_name: "centerview, mo",
  },
  {
    postal_code: "36420",
    zip_name: "andalusia, al",
  },
  {
    postal_code: "44838",
    zip_name: "hayesville, oh",
  },
  {
    postal_code: "33122",
    zip_name: "miami, fl",
  },
  {
    postal_code: "10913",
    zip_name: "blauvelt, ny",
  },
  {
    postal_code: "25140",
    zip_name: "naoma, wv",
  },
  {
    postal_code: "20680",
    zip_name: "ridge, md",
  },
  {
    postal_code: "23509",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "75561",
    zip_name: "hooks, tx",
  },
  {
    postal_code: "28455",
    zip_name: "nakina, nc",
  },
  {
    postal_code: "74855",
    zip_name: "meeker, ok",
  },
  {
    postal_code: "18660",
    zip_name: "wapwallopen, pa",
  },
  {
    postal_code: "51450",
    zip_name: "lake view, ia",
  },
  {
    postal_code: "23125",
    zip_name: "new point, va",
  },
  {
    postal_code: "16242",
    zip_name: "new bethlehem, pa",
  },
  {
    postal_code: "87528",
    zip_name: "dulce, nm",
  },
  {
    postal_code: "65589",
    zip_name: "yukon, mo",
  },
  {
    postal_code: "50151",
    zip_name: "lucas, ia",
  },
  {
    postal_code: "53183",
    zip_name: "wales, wi",
  },
  {
    postal_code: "41559",
    zip_name: "regina, ky",
  },
  {
    postal_code: "62618",
    zip_name: "beardstown, il",
  },
  {
    postal_code: "72473",
    zip_name: "tuckerman, ar",
  },
  {
    postal_code: "23701",
    zip_name: "portsmouth, va",
  },
  {
    postal_code: "38824",
    zip_name: "baldwyn, ms",
  },
  {
    postal_code: "25882",
    zip_name: "mullens, wv",
  },
  {
    postal_code: "45658",
    zip_name: "patriot, oh",
  },
  {
    postal_code: "63839",
    zip_name: "cooter, mo",
  },
  {
    postal_code: "39086",
    zip_name: "hermanville, ms",
  },
  {
    postal_code: "29113",
    zip_name: "norway, sc",
  },
  {
    postal_code: "17321",
    zip_name: "fawn grove, pa",
  },
  {
    postal_code: "31301",
    zip_name: "allenhurst, ga",
  },
  {
    postal_code: "72904",
    zip_name: "fort smith, ar",
  },
  {
    postal_code: "7107",
    zip_name: "newark, nj",
  },
  {
    postal_code: "57366",
    zip_name: "parkston, sd",
  },
  {
    postal_code: "23872",
    zip_name: "mc kenney, va",
  },
  {
    postal_code: "56522",
    zip_name: "campbell, mn",
  },
  {
    postal_code: "67334",
    zip_name: "chautauqua, ks",
  },
  {
    postal_code: "50274",
    zip_name: "wiota, ia",
  },
  {
    postal_code: "50586",
    zip_name: "somers, ia",
  },
  {
    postal_code: "82063",
    zip_name: "jelm, wy",
  },
  {
    postal_code: "57626",
    zip_name: "faith, sd",
  },
  {
    postal_code: "87317",
    zip_name: "gamerco, nm",
  },
  {
    postal_code: "72014",
    zip_name: "beedeville, ar",
  },
  {
    postal_code: "74560",
    zip_name: "pittsburg, ok",
  },
  {
    postal_code: "50642",
    zip_name: "holland, ia",
  },
  {
    postal_code: "29922",
    zip_name: "garnett, sc",
  },
  {
    postal_code: "88113",
    zip_name: "causey, nm",
  },
  {
    postal_code: "67354",
    zip_name: "mound valley, ks",
  },
  {
    postal_code: "14881",
    zip_name: "slaterville springs, ny",
  },
  {
    postal_code: "72528",
    zip_name: "dolph, ar",
  },
  {
    postal_code: "80722",
    zip_name: "atwood, co",
  },
  {
    postal_code: "58269",
    zip_name: "osnabrock, nd",
  },
  {
    postal_code: "47568",
    zip_name: "plainville, in",
  },
  {
    postal_code: "16682",
    zip_name: "sproul, pa",
  },
  {
    postal_code: "97010",
    zip_name: "bridal veil, or",
  },
  {
    postal_code: "52646",
    zip_name: "oakville, ia",
  },
  {
    postal_code: "41735",
    zip_name: "delphia, ky",
  },
  {
    postal_code: "67573",
    zip_name: "raymond, ks",
  },
  {
    postal_code: "38645",
    zip_name: "lyon, ms",
  },
  {
    postal_code: "67155",
    zip_name: "wilmore, ks",
  },
  {
    postal_code: "57646",
    zip_name: "mound city, sd",
  },
  {
    postal_code: "39755",
    zip_name: "pheba, ms",
  },
  {
    postal_code: "97880",
    zip_name: "ukiah, or",
  },
  {
    postal_code: "83812",
    zip_name: "clarkia, id",
  },
  {
    postal_code: "83124",
    zip_name: "opal, wy",
  },
  {
    postal_code: "57356",
    zip_name: "lake andes, sd",
  },
  {
    postal_code: "40144",
    zip_name: "harned, ky",
  },
  {
    postal_code: "25647",
    zip_name: "switzer, wv",
  },
  {
    postal_code: "58067",
    zip_name: "rutland, nd",
  },
  {
    postal_code: "23453",
    zip_name: "virginia beach, va",
  },
  {
    postal_code: "13087",
    zip_name: "little york, ny",
  },
  {
    postal_code: "87512",
    zip_name: "amalia, nm",
  },
  {
    postal_code: "25625",
    zip_name: "holden, wv",
  },
  {
    postal_code: "49971",
    zip_name: "white pine, mi",
  },
  {
    postal_code: "85626",
    zip_name: "pirtleville, az",
  },
  {
    postal_code: "69043",
    zip_name: "stratton, ne",
  },
  {
    postal_code: "36013",
    zip_name: "cecil, al",
  },
  {
    postal_code: "50459",
    zip_name: "northwood, ia",
  },
  {
    postal_code: "15747",
    zip_name: "home, pa",
  },
  {
    postal_code: "73092",
    zip_name: "verden, ok",
  },
  {
    postal_code: "72550",
    zip_name: "locust grove, ar",
  },
  {
    postal_code: "49061",
    zip_name: "jones, mi",
  },
  {
    postal_code: "47235",
    zip_name: "freetown, in",
  },
  {
    postal_code: "57350",
    zip_name: "huron, sd",
  },
  {
    postal_code: "37221",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "50629",
    zip_name: "fairbank, ia",
  },
  {
    postal_code: "15463",
    zip_name: "merrittstown, pa",
  },
  {
    postal_code: "5778",
    zip_name: "whiting, vt",
  },
  {
    postal_code: "76445",
    zip_name: "desdemona, tx",
  },
  {
    postal_code: "74632",
    zip_name: "braman, ok",
  },
  {
    postal_code: "65075",
    zip_name: "saint elizabeth, mo",
  },
  {
    postal_code: "99117",
    zip_name: "creston, wa",
  },
  {
    postal_code: "75415",
    zip_name: "ben franklin, tx",
  },
  {
    postal_code: "51014",
    zip_name: "cleghorn, ia",
  },
  {
    postal_code: "42214",
    zip_name: "center, ky",
  },
  {
    postal_code: "29030",
    zip_name: "cameron, sc",
  },
  {
    postal_code: "24613",
    zip_name: "falls mills, va",
  },
  {
    postal_code: "35571",
    zip_name: "hodges, al",
  },
  {
    postal_code: "72554",
    zip_name: "mammoth spring, ar",
  },
  {
    postal_code: "61475",
    zip_name: "sciota, il",
  },
  {
    postal_code: "16258",
    zip_name: "strattanville, pa",
  },
  {
    postal_code: "92281",
    zip_name: "westmorland, ca",
  },
  {
    postal_code: "49252",
    zip_name: "litchfield, mi",
  },
  {
    postal_code: "71403",
    zip_name: "anacoco, la",
  },
  {
    postal_code: "98053",
    zip_name: "redmond, wa",
  },
  {
    postal_code: "26739",
    zip_name: "mount storm, wv",
  },
  {
    postal_code: "57501",
    zip_name: "pierre, sd",
  },
  {
    postal_code: "73042",
    zip_name: "gracemont, ok",
  },
  {
    postal_code: "97436",
    zip_name: "elkton, or",
  },
  {
    postal_code: "70788",
    zip_name: "white castle, la",
  },
  {
    postal_code: "83870",
    zip_name: "tensed, id",
  },
  {
    postal_code: "37328",
    zip_name: "elora, tn",
  },
  {
    postal_code: "75416",
    zip_name: "blossom, tx",
  },
  {
    postal_code: "33960",
    zip_name: "venus, fl",
  },
  {
    postal_code: "98673",
    zip_name: "wishram, wa",
  },
  {
    postal_code: "73135",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "72846",
    zip_name: "lamar, ar",
  },
  {
    postal_code: "87323",
    zip_name: "thoreau, nm",
  },
  {
    postal_code: "37018",
    zip_name: "beechgrove, tn",
  },
  {
    postal_code: "16858",
    zip_name: "morrisdale, pa",
  },
  {
    postal_code: "73739",
    zip_name: "goltry, ok",
  },
  {
    postal_code: "99361",
    zip_name: "waitsburg, wa",
  },
  {
    postal_code: "83404",
    zip_name: "idaho falls, id",
  },
  {
    postal_code: "22834",
    zip_name: "linville, va",
  },
  {
    postal_code: "47381",
    zip_name: "salamonia, in",
  },
  {
    postal_code: "98087",
    zip_name: "lynnwood, wa",
  },
  {
    postal_code: "47925",
    zip_name: "buffalo, in",
  },
  {
    postal_code: "87049",
    zip_name: "san fidel, nm",
  },
  {
    postal_code: "24868",
    zip_name: "northfork, wv",
  },
  {
    postal_code: "24245",
    zip_name: "dungannon, va",
  },
  {
    postal_code: "59427",
    zip_name: "cut bank, mt",
  },
  {
    postal_code: "24347",
    zip_name: "hiwassee, va",
  },
  {
    postal_code: "65638",
    zip_name: "drury, mo",
  },
  {
    postal_code: "80453",
    zip_name: "idledale, co",
  },
  {
    postal_code: "79330",
    zip_name: "justiceburg, tx",
  },
  {
    postal_code: "23237",
    zip_name: "richmond, va",
  },
  {
    postal_code: "35581",
    zip_name: "phil campbell, al",
  },
  {
    postal_code: "83126",
    zip_name: "smoot, wy",
  },
  {
    postal_code: "95910",
    zip_name: "alleghany, ca",
  },
  {
    postal_code: "52224",
    zip_name: "dysart, ia",
  },
  {
    postal_code: "62914",
    zip_name: "cairo, il",
  },
  {
    postal_code: "26546",
    zip_name: "pursglove, wv",
  },
  {
    postal_code: "40460",
    zip_name: "orlando, ky",
  },
  {
    postal_code: "51241",
    zip_name: "larchwood, ia",
  },
  {
    postal_code: "38232",
    zip_name: "hornbeak, tn",
  },
  {
    postal_code: "26035",
    zip_name: "colliers, wv",
  },
  {
    postal_code: "82609",
    zip_name: "casper, wy",
  },
  {
    postal_code: "38943",
    zip_name: "mc carley, ms",
  },
  {
    postal_code: "57380",
    zip_name: "wagner, sd",
  },
  {
    postal_code: "73942",
    zip_name: "guymon, ok",
  },
  {
    postal_code: "47244",
    zip_name: "hartsville, in",
  },
  {
    postal_code: "46808",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "62631",
    zip_name: "concord, il",
  },
  {
    postal_code: "29370",
    zip_name: "mountville, sc",
  },
  {
    postal_code: "70719",
    zip_name: "brusly, la",
  },
  {
    postal_code: "72434",
    zip_name: "imboden, ar",
  },
  {
    postal_code: "23510",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "60536",
    zip_name: "millbrook, il",
  },
  {
    postal_code: "64437",
    zip_name: "craig, mo",
  },
  {
    postal_code: "40310",
    zip_name: "burgin, ky",
  },
  {
    postal_code: "58331",
    zip_name: "egeland, nd",
  },
  {
    postal_code: "49921",
    zip_name: "dodgeville, mi",
  },
  {
    postal_code: "13020",
    zip_name: "apulia station, ny",
  },
  {
    postal_code: "66948",
    zip_name: "jamestown, ks",
  },
  {
    postal_code: "62297",
    zip_name: "walsh, il",
  },
  {
    postal_code: "63451",
    zip_name: "leonard, mo",
  },
  {
    postal_code: "16750",
    zip_name: "turtlepoint, pa",
  },
  {
    postal_code: "58266",
    zip_name: "niagara, nd",
  },
  {
    postal_code: "80131",
    zip_name: "louviers, co",
  },
  {
    postal_code: "3752",
    zip_name: "goshen, nh",
  },
  {
    postal_code: "52580",
    zip_name: "packwood, ia",
  },
  {
    postal_code: "79853",
    zip_name: "tornillo, tx",
  },
  {
    postal_code: "23045",
    zip_name: "diggs, va",
  },
  {
    postal_code: "5086",
    zip_name: "west topsham, vt",
  },
  {
    postal_code: "73727",
    zip_name: "carrier, ok",
  },
  {
    postal_code: "96729",
    zip_name: "hoolehua, hi",
  },
  {
    postal_code: "85347",
    zip_name: "roll, az",
  },
  {
    postal_code: "97125",
    zip_name: "manning, or",
  },
  {
    postal_code: "41250",
    zip_name: "pilgrim, ky",
  },
  {
    postal_code: "57551",
    zip_name: "martin, sd",
  },
  {
    postal_code: "58482",
    zip_name: "steele, nd",
  },
  {
    postal_code: "62617",
    zip_name: "bath, il",
  },
  {
    postal_code: "41554",
    zip_name: "phyllis, ky",
  },
  {
    postal_code: "50562",
    zip_name: "mallard, ia",
  },
  {
    postal_code: "45351",
    zip_name: "osgood, oh",
  },
  {
    postal_code: "99752",
    zip_name: "kotzebue, ak",
  },
  {
    postal_code: "58062",
    zip_name: "nome, nd",
  },
  {
    postal_code: "24892",
    zip_name: "war, wv",
  },
  {
    postal_code: "64833",
    zip_name: "avilla, mo",
  },
  {
    postal_code: "41267",
    zip_name: "warfield, ky",
  },
  {
    postal_code: "15540",
    zip_name: "fort hill, pa",
  },
  {
    postal_code: "47596",
    zip_name: "westphalia, in",
  },
  {
    postal_code: "67492",
    zip_name: "woodbine, ks",
  },
  {
    postal_code: "50254",
    zip_name: "thayer, ia",
  },
  {
    postal_code: "8316",
    zip_name: "dorchester, nj",
  },
  {
    postal_code: "25699",
    zip_name: "wilsondale, wv",
  },
  {
    postal_code: "76363",
    zip_name: "goree, tx",
  },
  {
    postal_code: "68332",
    zip_name: "crab orchard, ne",
  },
  {
    postal_code: "50107",
    zip_name: "grand junction, ia",
  },
  {
    postal_code: "71034",
    zip_name: "hall summit, la",
  },
  {
    postal_code: "14785",
    zip_name: "stow, ny",
  },
  {
    postal_code: "80749",
    zip_name: "sedgwick, co",
  },
  {
    postal_code: "17249",
    zip_name: "rockhill furnace, pa",
  },
  {
    postal_code: "46759",
    zip_name: "keystone, in",
  },
  {
    postal_code: "66543",
    zip_name: "vassar, ks",
  },
  {
    postal_code: "73142",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "52248",
    zip_name: "keota, ia",
  },
  {
    postal_code: "80833",
    zip_name: "rush, co",
  },
  {
    postal_code: "61070",
    zip_name: "rock city, il",
  },
  {
    postal_code: "6332",
    zip_name: "central village, ct",
  },
  {
    postal_code: "35548",
    zip_name: "brilliant, al",
  },
  {
    postal_code: "15720",
    zip_name: "brush valley, pa",
  },
  {
    postal_code: "98276",
    zip_name: "nooksack, wa",
  },
  {
    postal_code: "62916",
    zip_name: "campbell hill, il",
  },
  {
    postal_code: "98001",
    zip_name: "auburn, wa",
  },
  {
    postal_code: "21156",
    zip_name: "upper falls, md",
  },
  {
    postal_code: "62567",
    zip_name: "stonington, il",
  },
  {
    postal_code: "17878",
    zip_name: "stillwater, pa",
  },
  {
    postal_code: "31645",
    zip_name: "ray city, ga",
  },
  {
    postal_code: "56521",
    zip_name: "callaway, mn",
  },
  {
    postal_code: "17550",
    zip_name: "maytown, pa",
  },
  {
    postal_code: "52305",
    zip_name: "martelle, ia",
  },
  {
    postal_code: "31832",
    zip_name: "weston, ga",
  },
  {
    postal_code: "63870",
    zip_name: "parma, mo",
  },
  {
    postal_code: "15741",
    zip_name: "gipsy, pa",
  },
  {
    postal_code: "51230",
    zip_name: "alvord, ia",
  },
  {
    postal_code: "40927",
    zip_name: "closplint, ky",
  },
  {
    postal_code: "29613",
    zip_name: "greenville, sc",
  },
  {
    postal_code: "30148",
    zip_name: "marble hill, ga",
  },
  {
    postal_code: "36034",
    zip_name: "glenwood, al",
  },
  {
    postal_code: "69121",
    zip_name: "arthur, ne",
  },
  {
    postal_code: "24435",
    zip_name: "fairfield, va",
  },
  {
    postal_code: "70774",
    zip_name: "saint amant, la",
  },
  {
    postal_code: "78932",
    zip_name: "carmine, tx",
  },
  {
    postal_code: "32563",
    zip_name: "gulf breeze, fl",
  },
  {
    postal_code: "65667",
    zip_name: "hartville, mo",
  },
  {
    postal_code: "48637",
    zip_name: "merrill, mi",
  },
  {
    postal_code: "50644",
    zip_name: "independence, ia",
  },
  {
    postal_code: "70036",
    zip_name: "barataria, la",
  },
  {
    postal_code: "40445",
    zip_name: "livingston, ky",
  },
  {
    postal_code: "16372",
    zip_name: "clintonville, pa",
  },
  {
    postal_code: "48841",
    zip_name: "henderson, mi",
  },
  {
    postal_code: "29129",
    zip_name: "ridge spring, sc",
  },
  {
    postal_code: "18211",
    zip_name: "andreas, pa",
  },
  {
    postal_code: "18628",
    zip_name: "lopez, pa",
  },
  {
    postal_code: "41773",
    zip_name: "vicco, ky",
  },
  {
    postal_code: "65746",
    zip_name: "seymour, mo",
  },
  {
    postal_code: "64832",
    zip_name: "asbury, mo",
  },
  {
    postal_code: "4667",
    zip_name: "perry, me",
  },
  {
    postal_code: "40110",
    zip_name: "clermont, ky",
  },
  {
    postal_code: "19374",
    zip_name: "toughkenamon, pa",
  },
  {
    postal_code: "65321",
    zip_name: "blackburn, mo",
  },
  {
    postal_code: "99652",
    zip_name: "big lake, ak",
  },
  {
    postal_code: "53013",
    zip_name: "cedar grove, wi",
  },
  {
    postal_code: "72567",
    zip_name: "pleasant grove, ar",
  },
  {
    postal_code: "76561",
    zip_name: "oglesby, tx",
  },
  {
    postal_code: "49504",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "48724",
    zip_name: "carrollton, mi",
  },
  {
    postal_code: "58015",
    zip_name: "christine, nd",
  },
  {
    postal_code: "62340",
    zip_name: "griggsville, il",
  },
  {
    postal_code: "16720",
    zip_name: "austin, pa",
  },
  {
    postal_code: "38482",
    zip_name: "santa fe, tn",
  },
  {
    postal_code: "47162",
    zip_name: "new washington, in",
  },
  {
    postal_code: "67047",
    zip_name: "fall river, ks",
  },
  {
    postal_code: "92258",
    zip_name: "north palm springs, ca",
  },
  {
    postal_code: "37078",
    zip_name: "hurricane mills, tn",
  },
  {
    postal_code: "16729",
    zip_name: "duke center, pa",
  },
  {
    postal_code: "36352",
    zip_name: "newton, al",
  },
  {
    postal_code: "93434",
    zip_name: "guadalupe, ca",
  },
  {
    postal_code: "62554",
    zip_name: "oreana, il",
  },
  {
    postal_code: "74567",
    zip_name: "snow, ok",
  },
  {
    postal_code: "35967",
    zip_name: "fort payne, al",
  },
  {
    postal_code: "96055",
    zip_name: "los molinos, ca",
  },
  {
    postal_code: "50231",
    zip_name: "randall, ia",
  },
  {
    postal_code: "66749",
    zip_name: "iola, ks",
  },
  {
    postal_code: "54426",
    zip_name: "edgar, wi",
  },
  {
    postal_code: "41214",
    zip_name: "debord, ky",
  },
  {
    postal_code: "41636",
    zip_name: "hi hat, ky",
  },
  {
    postal_code: "98336",
    zip_name: "glenoma, wa",
  },
  {
    postal_code: "46992",
    zip_name: "wabash, in",
  },
  {
    postal_code: "66058",
    zip_name: "muscotah, ks",
  },
  {
    postal_code: "55786",
    zip_name: "taconite, mn",
  },
  {
    postal_code: "38876",
    zip_name: "tremont, ms",
  },
  {
    postal_code: "37129",
    zip_name: "murfreesboro, tn",
  },
  {
    postal_code: "59482",
    zip_name: "sunburst, mt",
  },
  {
    postal_code: "98860",
    zip_name: "wilson creek, wa",
  },
  {
    postal_code: "45827",
    zip_name: "cloverdale, oh",
  },
  {
    postal_code: "54525",
    zip_name: "gile, wi",
  },
  {
    postal_code: "46946",
    zip_name: "liberty mills, in",
  },
  {
    postal_code: "16646",
    zip_name: "hastings, pa",
  },
  {
    postal_code: "40845",
    zip_name: "hulen, ky",
  },
  {
    postal_code: "16334",
    zip_name: "marble, pa",
  },
  {
    postal_code: "65034",
    zip_name: "fortuna, mo",
  },
  {
    postal_code: "84749",
    zip_name: "lyman, ut",
  },
  {
    postal_code: "58456",
    zip_name: "kulm, nd",
  },
  {
    postal_code: "79719",
    zip_name: "barstow, tx",
  },
  {
    postal_code: "50546",
    zip_name: "havelock, ia",
  },
  {
    postal_code: "68785",
    zip_name: "waterbury, ne",
  },
  {
    postal_code: "26343",
    zip_name: "crawford, wv",
  },
  {
    postal_code: "71475",
    zip_name: "slagle, la",
  },
  {
    postal_code: "31421",
    zip_name: "savannah, ga",
  },
  {
    postal_code: "56389",
    zip_name: "west union, mn",
  },
  {
    postal_code: "67051",
    zip_name: "geuda springs, ks",
  },
  {
    postal_code: "58068",
    zip_name: "sheldon, nd",
  },
  {
    postal_code: "88262",
    zip_name: "mcdonald, nm",
  },
  {
    postal_code: "73932",
    zip_name: "beaver, ok",
  },
  {
    postal_code: "25148",
    zip_name: "orgas, wv",
  },
  {
    postal_code: "64120",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "58367",
    zip_name: "rolla, nd",
  },
  {
    postal_code: "50521",
    zip_name: "burnside, ia",
  },
  {
    postal_code: "67057",
    zip_name: "hardtner, ks",
  },
  {
    postal_code: "76666",
    zip_name: "mertens, tx",
  },
  {
    postal_code: "78067",
    zip_name: "san ygnacio, tx",
  },
  {
    postal_code: "18927",
    zip_name: "hilltown, pa",
  },
  {
    postal_code: "29516",
    zip_name: "blenheim, sc",
  },
  {
    postal_code: "71942",
    zip_name: "friendship, ar",
  },
  {
    postal_code: "50664",
    zip_name: "oran, ia",
  },
  {
    postal_code: "50518",
    zip_name: "barnum, ia",
  },
  {
    postal_code: "40979",
    zip_name: "roark, ky",
  },
  {
    postal_code: "63431",
    zip_name: "anabel, mo",
  },
  {
    postal_code: "14592",
    zip_name: "york, ny",
  },
  {
    postal_code: "15934",
    zip_name: "elton, pa",
  },
  {
    postal_code: "71479",
    zip_name: "tullos, la",
  },
  {
    postal_code: "86321",
    zip_name: "bagdad, az",
  },
  {
    postal_code: "49896",
    zip_name: "wilson, mi",
  },
  {
    postal_code: "68710",
    zip_name: "allen, ne",
  },
  {
    postal_code: "65586",
    zip_name: "wesco, mo",
  },
  {
    postal_code: "72522",
    zip_name: "charlotte, ar",
  },
  {
    postal_code: "31068",
    zip_name: "oglethorpe, ga",
  },
  {
    postal_code: "75976",
    zip_name: "wells, tx",
  },
  {
    postal_code: "78671",
    zip_name: "stonewall, tx",
  },
  {
    postal_code: "98199",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "98548",
    zip_name: "hoodsport, wa",
  },
  {
    postal_code: "64446",
    zip_name: "fairfax, mo",
  },
  {
    postal_code: "65264",
    zip_name: "martinsburg, mo",
  },
  {
    postal_code: "47855",
    zip_name: "hymera, in",
  },
  {
    postal_code: "47120",
    zip_name: "fredericksburg, in",
  },
  {
    postal_code: "50230",
    zip_name: "radcliffe, ia",
  },
  {
    postal_code: "70776",
    zip_name: "saint gabriel, la",
  },
  {
    postal_code: "48006",
    zip_name: "avoca, mi",
  },
  {
    postal_code: "10928",
    zip_name: "highland falls, ny",
  },
  {
    postal_code: "50638",
    zip_name: "grundy center, ia",
  },
  {
    postal_code: "15562",
    zip_name: "springs, pa",
  },
  {
    postal_code: "69352",
    zip_name: "lyman, ne",
  },
  {
    postal_code: "78938",
    zip_name: "ellinger, tx",
  },
  {
    postal_code: "57362",
    zip_name: "miller, sd",
  },
  {
    postal_code: "50631",
    zip_name: "frederika, ia",
  },
  {
    postal_code: "67446",
    zip_name: "glen elder, ks",
  },
  {
    postal_code: "39152",
    zip_name: "pulaski, ms",
  },
  {
    postal_code: "22729",
    zip_name: "mitchells, va",
  },
  {
    postal_code: "32609",
    zip_name: "gainesville, fl",
  },
  {
    postal_code: "50006",
    zip_name: "alden, ia",
  },
  {
    postal_code: "32401",
    zip_name: "panama city, fl",
  },
  {
    postal_code: "10984",
    zip_name: "thiells, ny",
  },
  {
    postal_code: "61491",
    zip_name: "wyoming, il",
  },
  {
    postal_code: "83244",
    zip_name: "howe, id",
  },
  {
    postal_code: "25081",
    zip_name: "foster, wv",
  },
  {
    postal_code: "59256",
    zip_name: "raymond, mt",
  },
  {
    postal_code: "35078",
    zip_name: "harpersville, al",
  },
  {
    postal_code: "85940",
    zip_name: "vernon, az",
  },
  {
    postal_code: "71238",
    zip_name: "eros, la",
  },
  {
    postal_code: "99504",
    zip_name: "anchorage, ak",
  },
  {
    postal_code: "52341",
    zip_name: "toddville, ia",
  },
  {
    postal_code: "16657",
    zip_name: "james creek, pa",
  },
  {
    postal_code: "65744",
    zip_name: "rueter, mo",
  },
  {
    postal_code: "16926",
    zip_name: "granville summit, pa",
  },
  {
    postal_code: "53920",
    zip_name: "briggsville, wi",
  },
  {
    postal_code: "29664",
    zip_name: "mountain rest, sc",
  },
  {
    postal_code: "50616",
    zip_name: "charles city, ia",
  },
  {
    postal_code: "49302",
    zip_name: "alto, mi",
  },
  {
    postal_code: "70763",
    zip_name: "paulina, la",
  },
  {
    postal_code: "16693",
    zip_name: "williamsburg, pa",
  },
  {
    postal_code: "82327",
    zip_name: "hanna, wy",
  },
  {
    postal_code: "61449",
    zip_name: "la fayette, il",
  },
  {
    postal_code: "81325",
    zip_name: "egnar, co",
  },
  {
    postal_code: "15329",
    zip_name: "prosperity, pa",
  },
  {
    postal_code: "5455",
    zip_name: "fairfield, vt",
  },
  {
    postal_code: "98337",
    zip_name: "bremerton, wa",
  },
  {
    postal_code: "23451",
    zip_name: "virginia beach, va",
  },
  {
    postal_code: "70341",
    zip_name: "belle rose, la",
  },
  {
    postal_code: "35447",
    zip_name: "carrollton, al",
  },
  {
    postal_code: "17778",
    zip_name: "westport, pa",
  },
  {
    postal_code: "37387",
    zip_name: "tracy city, tn",
  },
  {
    postal_code: "24445",
    zip_name: "hot springs, va",
  },
  {
    postal_code: "26267",
    zip_name: "ellamore, wv",
  },
  {
    postal_code: "67206",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "49720",
    zip_name: "charlevoix, mi",
  },
  {
    postal_code: "63437",
    zip_name: "clarence, mo",
  },
  {
    postal_code: "66056",
    zip_name: "mound city, ks",
  },
  {
    postal_code: "59858",
    zip_name: "philipsburg, mt",
  },
  {
    postal_code: "42541",
    zip_name: "middleburg, ky",
  },
  {
    postal_code: "26755",
    zip_name: "rio, wv",
  },
  {
    postal_code: "98345",
    zip_name: "keyport, wa",
  },
  {
    postal_code: "17212",
    zip_name: "big cove tannery, pa",
  },
  {
    postal_code: "57059",
    zip_name: "scotland, sd",
  },
  {
    postal_code: "62544",
    zip_name: "macon, il",
  },
  {
    postal_code: "71763",
    zip_name: "sparkman, ar",
  },
  {
    postal_code: "49274",
    zip_name: "reading, mi",
  },
  {
    postal_code: "98829",
    zip_name: "malott, wa",
  },
  {
    postal_code: "59463",
    zip_name: "monarch, mt",
  },
  {
    postal_code: "7842",
    zip_name: "hibernia, nj",
  },
  {
    postal_code: "95385",
    zip_name: "vernalis, ca",
  },
  {
    postal_code: "13695",
    zip_name: "wanakena, ny",
  },
  {
    postal_code: "68340",
    zip_name: "deshler, ne",
  },
  {
    postal_code: "16350",
    zip_name: "sugar grove, pa",
  },
  {
    postal_code: "59250",
    zip_name: "opheim, mt",
  },
  {
    postal_code: "38328",
    zip_name: "darden, tn",
  },
  {
    postal_code: "4469",
    zip_name: "orono, me",
  },
  {
    postal_code: "25108",
    zip_name: "hewett, wv",
  },
  {
    postal_code: "51431",
    zip_name: "arthur, ia",
  },
  {
    postal_code: "62360",
    zip_name: "payson, il",
  },
  {
    postal_code: "95471",
    zip_name: "rio nido, ca",
  },
  {
    postal_code: "58244",
    zip_name: "inkster, nd",
  },
  {
    postal_code: "58765",
    zip_name: "noonan, nd",
  },
  {
    postal_code: "97752",
    zip_name: "post, or",
  },
  {
    postal_code: "56577",
    zip_name: "richwood, mn",
  },
  {
    postal_code: "57050",
    zip_name: "nunda, sd",
  },
  {
    postal_code: "69023",
    zip_name: "champion, ne",
  },
  {
    postal_code: "45620",
    zip_name: "cheshire, oh",
  },
  {
    postal_code: "55957",
    zip_name: "millville, mn",
  },
  {
    postal_code: "82243",
    zip_name: "veteran, wy",
  },
  {
    postal_code: "87027",
    zip_name: "la jara, nm",
  },
  {
    postal_code: "62079",
    zip_name: "piasa, il",
  },
  {
    postal_code: "58748",
    zip_name: "kramer, nd",
  },
  {
    postal_code: "74740",
    zip_name: "haworth, ok",
  },
  {
    postal_code: "99667",
    zip_name: "skwentna, ak",
  },
  {
    postal_code: "98603",
    zip_name: "ariel, wa",
  },
  {
    postal_code: "58727",
    zip_name: "columbus, nd",
  },
  {
    postal_code: "6758",
    zip_name: "lakeside, ct",
  },
  {
    postal_code: "72376",
    zip_name: "proctor, ar",
  },
  {
    postal_code: "16655",
    zip_name: "imler, pa",
  },
  {
    postal_code: "14784",
    zip_name: "stockton, ny",
  },
  {
    postal_code: "74738",
    zip_name: "grant, ok",
  },
  {
    postal_code: "99140",
    zip_name: "keller, wa",
  },
  {
    postal_code: "59054",
    zip_name: "melstone, mt",
  },
  {
    postal_code: "41824",
    zip_name: "isom, ky",
  },
  {
    postal_code: "78829",
    zip_name: "batesville, tx",
  },
  {
    postal_code: "98050",
    zip_name: "preston, wa",
  },
  {
    postal_code: "66105",
    zip_name: "kansas city, ks",
  },
  {
    postal_code: "35766",
    zip_name: "princeton, al",
  },
  {
    postal_code: "36761",
    zip_name: "minter, al",
  },
  {
    postal_code: "95494",
    zip_name: "yorkville, ca",
  },
  {
    postal_code: "67418",
    zip_name: "barnard, ks",
  },
  {
    postal_code: "67628",
    zip_name: "cedar, ks",
  },
  {
    postal_code: "47666",
    zip_name: "patoka, in",
  },
  {
    postal_code: "29118",
    zip_name: "orangeburg, sc",
  },
  {
    postal_code: "52757",
    zip_name: "low moor, ia",
  },
  {
    postal_code: "41274",
    zip_name: "wittensville, ky",
  },
  {
    postal_code: "62957",
    zip_name: "mc clure, il",
  },
  {
    postal_code: "49710",
    zip_name: "barbeau, mi",
  },
  {
    postal_code: "97352",
    zip_name: "jefferson, or",
  },
  {
    postal_code: "58652",
    zip_name: "richardton, nd",
  },
  {
    postal_code: "59856",
    zip_name: "paradise, mt",
  },
  {
    postal_code: "26845",
    zip_name: "old fields, wv",
  },
  {
    postal_code: "45893",
    zip_name: "vaughnsville, oh",
  },
  {
    postal_code: "57075",
    zip_name: "wentworth, sd",
  },
  {
    postal_code: "76308",
    zip_name: "wichita falls, tx",
  },
  {
    postal_code: "57064",
    zip_name: "tea, sd",
  },
  {
    postal_code: "37210",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "15346",
    zip_name: "mather, pa",
  },
  {
    postal_code: "17830",
    zip_name: "herndon, pa",
  },
  {
    postal_code: "13625",
    zip_name: "colton, ny",
  },
  {
    postal_code: "7627",
    zip_name: "demarest, nj",
  },
  {
    postal_code: "88052",
    zip_name: "organ, nm",
  },
  {
    postal_code: "83834",
    zip_name: "harvard, id",
  },
  {
    postal_code: "31077",
    zip_name: "rhine, ga",
  },
  {
    postal_code: "25617",
    zip_name: "davin, wv",
  },
  {
    postal_code: "95312",
    zip_name: "cressey, ca",
  },
  {
    postal_code: "40357",
    zip_name: "north middletown, ky",
  },
  {
    postal_code: "56733",
    zip_name: "kennedy, mn",
  },
  {
    postal_code: "56158",
    zip_name: "magnolia, mn",
  },
  {
    postal_code: "35481",
    zip_name: "reform, al",
  },
  {
    postal_code: "25920",
    zip_name: "slab fork, wv",
  },
  {
    postal_code: "72005",
    zip_name: "amagon, ar",
  },
  {
    postal_code: "65263",
    zip_name: "madison, mo",
  },
  {
    postal_code: "96061",
    zip_name: "mill creek, ca",
  },
  {
    postal_code: "40025",
    zip_name: "glenview, ky",
  },
  {
    postal_code: "84750",
    zip_name: "marysvale, ut",
  },
  {
    postal_code: "4852",
    zip_name: "monhegan, me",
  },
  {
    postal_code: "98277",
    zip_name: "oak harbor, wa",
  },
  {
    postal_code: "45819",
    zip_name: "buckland, oh",
  },
  {
    postal_code: "53129",
    zip_name: "greendale, wi",
  },
  {
    postal_code: "68935",
    zip_name: "edgar, ne",
  },
  {
    postal_code: "49759",
    zip_name: "millersburg, mi",
  },
  {
    postal_code: "15358",
    zip_name: "richeyville, pa",
  },
  {
    postal_code: "54430",
    zip_name: "elton, wi",
  },
  {
    postal_code: "80207",
    zip_name: "denver, co",
  },
  {
    postal_code: "42378",
    zip_name: "whitesville, ky",
  },
  {
    postal_code: "17535",
    zip_name: "kinzers, pa",
  },
  {
    postal_code: "54650",
    zip_name: "onalaska, wi",
  },
  {
    postal_code: "77951",
    zip_name: "bloomington, tx",
  },
  {
    postal_code: "89822",
    zip_name: "carlin, nv",
  },
  {
    postal_code: "52049",
    zip_name: "garnavillo, ia",
  },
  {
    postal_code: "49034",
    zip_name: "climax, mi",
  },
  {
    postal_code: "71862",
    zip_name: "washington, ar",
  },
  {
    postal_code: "75643",
    zip_name: "gary, tx",
  },
  {
    postal_code: "27941",
    zip_name: "harbinger, nc",
  },
  {
    postal_code: "10969",
    zip_name: "pine island, ny",
  },
  {
    postal_code: "51430",
    zip_name: "arcadia, ia",
  },
  {
    postal_code: "15770",
    zip_name: "ringgold, pa",
  },
  {
    postal_code: "63953",
    zip_name: "naylor, mo",
  },
  {
    postal_code: "61741",
    zip_name: "forrest, il",
  },
  {
    postal_code: "10954",
    zip_name: "nanuet, ny",
  },
  {
    postal_code: "49664",
    zip_name: "maple city, mi",
  },
  {
    postal_code: "97639",
    zip_name: "sprague river, or",
  },
  {
    postal_code: "32578",
    zip_name: "niceville, fl",
  },
  {
    postal_code: "67543",
    zip_name: "haven, ks",
  },
  {
    postal_code: "13312",
    zip_name: "brantingham, ny",
  },
  {
    postal_code: "4671",
    zip_name: "robbinston, me",
  },
  {
    postal_code: "71369",
    zip_name: "simmesport, la",
  },
  {
    postal_code: "24290",
    zip_name: "weber city, va",
  },
  {
    postal_code: "25676",
    zip_name: "lenore, wv",
  },
  {
    postal_code: "52257",
    zip_name: "luzerne, ia",
  },
  {
    postal_code: "14777",
    zip_name: "rushford, ny",
  },
  {
    postal_code: "97102",
    zip_name: "arch cape, or",
  },
  {
    postal_code: "28689",
    zip_name: "union grove, nc",
  },
  {
    postal_code: "72949",
    zip_name: "ozark, ar",
  },
  {
    postal_code: "99639",
    zip_name: "ninilchik, ak",
  },
  {
    postal_code: "99820",
    zip_name: "angoon, ak",
  },
  {
    postal_code: "26547",
    zip_name: "reedsville, wv",
  },
  {
    postal_code: "39166",
    zip_name: "silver city, ms",
  },
  {
    postal_code: "50554",
    zip_name: "laurens, ia",
  },
  {
    postal_code: "7825",
    zip_name: "blairstown, nj",
  },
  {
    postal_code: "76630",
    zip_name: "bruceville, tx",
  },
  {
    postal_code: "37715",
    zip_name: "clairfield, tn",
  },
  {
    postal_code: "48470",
    zip_name: "ruth, mi",
  },
  {
    postal_code: "59631",
    zip_name: "basin, mt",
  },
  {
    postal_code: "28433",
    zip_name: "clarkton, nc",
  },
  {
    postal_code: "80469",
    zip_name: "phippsburg, co",
  },
  {
    postal_code: "58501",
    zip_name: "bismarck, nd",
  },
  {
    postal_code: "72933",
    zip_name: "charleston, ar",
  },
  {
    postal_code: "8329",
    zip_name: "mauricetown, nj",
  },
  {
    postal_code: "44651",
    zip_name: "mechanicstown, oh",
  },
  {
    postal_code: "50659",
    zip_name: "new hampton, ia",
  },
  {
    postal_code: "65260",
    zip_name: "jacksonville, mo",
  },
  {
    postal_code: "58421",
    zip_name: "carrington, nd",
  },
  {
    postal_code: "29590",
    zip_name: "salters, sc",
  },
  {
    postal_code: "61412",
    zip_name: "alexis, il",
  },
  {
    postal_code: "72425",
    zip_name: "delaplaine, ar",
  },
  {
    postal_code: "26818",
    zip_name: "fisher, wv",
  },
  {
    postal_code: "36042",
    zip_name: "honoraville, al",
  },
  {
    postal_code: "73564",
    zip_name: "roosevelt, ok",
  },
  {
    postal_code: "72666",
    zip_name: "parthenon, ar",
  },
  {
    postal_code: "71427",
    zip_name: "flatwoods, la",
  },
  {
    postal_code: "23874",
    zip_name: "newsoms, va",
  },
  {
    postal_code: "62352",
    zip_name: "milton, il",
  },
  {
    postal_code: "47590",
    zip_name: "velpen, in",
  },
  {
    postal_code: "26289",
    zip_name: "red creek, wv",
  },
  {
    postal_code: "67564",
    zip_name: "olmitz, ks",
  },
  {
    postal_code: "25114",
    zip_name: "jeffrey, wv",
  },
  {
    postal_code: "78843",
    zip_name: "laughlin afb, tx",
  },
  {
    postal_code: "29031",
    zip_name: "carlisle, sc",
  },
  {
    postal_code: "24993",
    zip_name: "wolfcreek, wv",
  },
  {
    postal_code: "57220",
    zip_name: "bruce, sd",
  },
  {
    postal_code: "43210",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "59863",
    zip_name: "ravalli, mt",
  },
  {
    postal_code: "76852",
    zip_name: "lohn, tx",
  },
  {
    postal_code: "17029",
    zip_name: "granville, pa",
  },
  {
    postal_code: "67073",
    zip_name: "lehigh, ks",
  },
  {
    postal_code: "28611",
    zip_name: "collettsville, nc",
  },
  {
    postal_code: "25876",
    zip_name: "saulsville, wv",
  },
  {
    postal_code: "62970",
    zip_name: "olmsted, il",
  },
  {
    postal_code: "62633",
    zip_name: "easton, il",
  },
  {
    postal_code: "48607",
    zip_name: "saginaw, mi",
  },
  {
    postal_code: "99144",
    zip_name: "lamona, wa",
  },
  {
    postal_code: "29911",
    zip_name: "brunson, sc",
  },
  {
    postal_code: "67864",
    zip_name: "meade, ks",
  },
  {
    postal_code: "78948",
    zip_name: "lincoln, tx",
  },
  {
    postal_code: "63543",
    zip_name: "gorin, mo",
  },
  {
    postal_code: "52737",
    zip_name: "columbus city, ia",
  },
  {
    postal_code: "58370",
    zip_name: "saint michael, nd",
  },
  {
    postal_code: "58787",
    zip_name: "tolley, nd",
  },
  {
    postal_code: "61370",
    zip_name: "tonica, il",
  },
  {
    postal_code: "84743",
    zip_name: "kingston, ut",
  },
  {
    postal_code: "57048",
    zip_name: "montrose, sd",
  },
  {
    postal_code: "77614",
    zip_name: "deweyville, tx",
  },
  {
    postal_code: "48412",
    zip_name: "attica, mi",
  },
  {
    postal_code: "82939",
    zip_name: "mountain view, wy",
  },
  {
    postal_code: "98260",
    zip_name: "langley, wa",
  },
  {
    postal_code: "53091",
    zip_name: "theresa, wi",
  },
  {
    postal_code: "49088",
    zip_name: "scotts, mi",
  },
  {
    postal_code: "50848",
    zip_name: "gravity, ia",
  },
  {
    postal_code: "1531",
    zip_name: "new braintree, ma",
  },
  {
    postal_code: "95923",
    zip_name: "canyon dam, ca",
  },
  {
    postal_code: "81054",
    zip_name: "las animas, co",
  },
  {
    postal_code: "21669",
    zip_name: "taylors island, md",
  },
  {
    postal_code: "73047",
    zip_name: "hinton, ok",
  },
  {
    postal_code: "98038",
    zip_name: "maple valley, wa",
  },
  {
    postal_code: "32648",
    zip_name: "horseshoe beach, fl",
  },
  {
    postal_code: "71261",
    zip_name: "mer rouge, la",
  },
  {
    postal_code: "83546",
    zip_name: "pierce, id",
  },
  {
    postal_code: "35772",
    zip_name: "stevenson, al",
  },
  {
    postal_code: "98292",
    zip_name: "stanwood, wa",
  },
  {
    postal_code: "17872",
    zip_name: "shamokin, pa",
  },
  {
    postal_code: "65484",
    zip_name: "huggins, mo",
  },
  {
    postal_code: "37733",
    zip_name: "rugby, tn",
  },
  {
    postal_code: "58343",
    zip_name: "knox, nd",
  },
  {
    postal_code: "68752",
    zip_name: "meadow grove, ne",
  },
  {
    postal_code: "62314",
    zip_name: "baylis, il",
  },
  {
    postal_code: "8840",
    zip_name: "metuchen, nj",
  },
  {
    postal_code: "71923",
    zip_name: "arkadelphia, ar",
  },
  {
    postal_code: "56548",
    zip_name: "halstad, mn",
  },
  {
    postal_code: "82050",
    zip_name: "albin, wy",
  },
  {
    postal_code: "62573",
    zip_name: "warrensburg, il",
  },
  {
    postal_code: "50523",
    zip_name: "callender, ia",
  },
  {
    postal_code: "24483",
    zip_name: "vesuvius, va",
  },
  {
    postal_code: "74831",
    zip_name: "byars, ok",
  },
  {
    postal_code: "61336",
    zip_name: "magnolia, il",
  },
  {
    postal_code: "50621",
    zip_name: "conrad, ia",
  },
  {
    postal_code: "98002",
    zip_name: "auburn, wa",
  },
  {
    postal_code: "13157",
    zip_name: "sylvan beach, ny",
  },
  {
    postal_code: "80427",
    zip_name: "central city, co",
  },
  {
    postal_code: "42050",
    zip_name: "hickman, ky",
  },
  {
    postal_code: "41311",
    zip_name: "beattyville, ky",
  },
  {
    postal_code: "26134",
    zip_name: "belmont, wv",
  },
  {
    postal_code: "44807",
    zip_name: "attica, oh",
  },
  {
    postal_code: "37338",
    zip_name: "graysville, tn",
  },
  {
    postal_code: "61414",
    zip_name: "altona, il",
  },
  {
    postal_code: "59755",
    zip_name: "virginia city, mt",
  },
  {
    postal_code: "10921",
    zip_name: "florida, ny",
  },
  {
    postal_code: "83627",
    zip_name: "hammett, id",
  },
  {
    postal_code: "84640",
    zip_name: "lynndyl, ut",
  },
  {
    postal_code: "59910",
    zip_name: "big arm, mt",
  },
  {
    postal_code: "97109",
    zip_name: "buxton, or",
  },
  {
    postal_code: "22976",
    zip_name: "tyro, va",
  },
  {
    postal_code: "56211",
    zip_name: "beardsley, mn",
  },
  {
    postal_code: "98862",
    zip_name: "winthrop, wa",
  },
  {
    postal_code: "99654",
    zip_name: "wasilla, ak",
  },
  {
    postal_code: "69122",
    zip_name: "big springs, ne",
  },
  {
    postal_code: "98405",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "16827",
    zip_name: "boalsburg, pa",
  },
  {
    postal_code: "65742",
    zip_name: "rogersville, mo",
  },
  {
    postal_code: "26408",
    zip_name: "mount clare, wv",
  },
  {
    postal_code: "16849",
    zip_name: "lanse, pa",
  },
  {
    postal_code: "65647",
    zip_name: "exeter, mo",
  },
  {
    postal_code: "51201",
    zip_name: "sheldon, ia",
  },
  {
    postal_code: "97637",
    zip_name: "plush, or",
  },
  {
    postal_code: "83839",
    zip_name: "kingston, id",
  },
  {
    postal_code: "98022",
    zip_name: "enumclaw, wa",
  },
  {
    postal_code: "83218",
    zip_name: "basalt, id",
  },
  {
    postal_code: "50423",
    zip_name: "britt, ia",
  },
  {
    postal_code: "24153",
    zip_name: "salem, va",
  },
  {
    postal_code: "97324",
    zip_name: "alsea, or",
  },
  {
    postal_code: "98043",
    zip_name: "mountlake terrace, wa",
  },
  {
    postal_code: "49042",
    zip_name: "constantine, mi",
  },
  {
    postal_code: "73766",
    zip_name: "pond creek, ok",
  },
  {
    postal_code: "72355",
    zip_name: "lexa, ar",
  },
  {
    postal_code: "53215",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "38920",
    zip_name: "cascilla, ms",
  },
  {
    postal_code: "78719",
    zip_name: "austin, tx",
  },
  {
    postal_code: "88048",
    zip_name: "mesquite, nm",
  },
  {
    postal_code: "80236",
    zip_name: "denver, co",
  },
  {
    postal_code: "8802",
    zip_name: "asbury, nj",
  },
  {
    postal_code: "30728",
    zip_name: "la fayette, ga",
  },
  {
    postal_code: "83549",
    zip_name: "riggins, id",
  },
  {
    postal_code: "32548",
    zip_name: "fort walton beach, fl",
  },
  {
    postal_code: "74745",
    zip_name: "idabel, ok",
  },
  {
    postal_code: "47665",
    zip_name: "owensville, in",
  },
  {
    postal_code: "18245",
    zip_name: "quakake, pa",
  },
  {
    postal_code: "67123",
    zip_name: "potwin, ks",
  },
  {
    postal_code: "5827",
    zip_name: "craftsbury common, vt",
  },
  {
    postal_code: "71601",
    zip_name: "pine bluff, ar",
  },
  {
    postal_code: "56155",
    zip_name: "lismore, mn",
  },
  {
    postal_code: "16677",
    zip_name: "sandy ridge, pa",
  },
  {
    postal_code: "98272",
    zip_name: "monroe, wa",
  },
  {
    postal_code: "73078",
    zip_name: "piedmont, ok",
  },
  {
    postal_code: "51561",
    zip_name: "pacific junction, ia",
  },
  {
    postal_code: "71859",
    zip_name: "saratoga, ar",
  },
  {
    postal_code: "68741",
    zip_name: "hubbard, ne",
  },
  {
    postal_code: "80452",
    zip_name: "idaho springs, co",
  },
  {
    postal_code: "28520",
    zip_name: "cedar island, nc",
  },
  {
    postal_code: "73165",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "24639",
    zip_name: "raven, va",
  },
  {
    postal_code: "38774",
    zip_name: "shelby, ms",
  },
  {
    postal_code: "83867",
    zip_name: "silverton, id",
  },
  {
    postal_code: "46991",
    zip_name: "van buren, in",
  },
  {
    postal_code: "63877",
    zip_name: "steele, mo",
  },
  {
    postal_code: "62510",
    zip_name: "assumption, il",
  },
  {
    postal_code: "24059",
    zip_name: "bent mountain, va",
  },
  {
    postal_code: "25201",
    zip_name: "tad, wv",
  },
  {
    postal_code: "38560",
    zip_name: "elmwood, tn",
  },
  {
    postal_code: "43070",
    zip_name: "rosewood, oh",
  },
  {
    postal_code: "29821",
    zip_name: "clarks hill, sc",
  },
  {
    postal_code: "35651",
    zip_name: "mount hope, al",
  },
  {
    postal_code: "49642",
    zip_name: "idlewild, mi",
  },
  {
    postal_code: "37141",
    zip_name: "orlinda, tn",
  },
  {
    postal_code: "99349",
    zip_name: "mattawa, wa",
  },
  {
    postal_code: "35622",
    zip_name: "falkville, al",
  },
  {
    postal_code: "71055",
    zip_name: "minden, la",
  },
  {
    postal_code: "49336",
    zip_name: "morley, mi",
  },
  {
    postal_code: "52330",
    zip_name: "ryan, ia",
  },
  {
    postal_code: "44639",
    zip_name: "leesville, oh",
  },
  {
    postal_code: "76309",
    zip_name: "wichita falls, tx",
  },
  {
    postal_code: "35652",
    zip_name: "rogersville, al",
  },
  {
    postal_code: "37203",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "47640",
    zip_name: "hazleton, in",
  },
  {
    postal_code: "73002",
    zip_name: "alex, ok",
  },
  {
    postal_code: "68852",
    zip_name: "litchfield, ne",
  },
  {
    postal_code: "38847",
    zip_name: "golden, ms",
  },
  {
    postal_code: "17576",
    zip_name: "smoketown, pa",
  },
  {
    postal_code: "53047",
    zip_name: "lebanon, wi",
  },
  {
    postal_code: "65054",
    zip_name: "loose creek, mo",
  },
  {
    postal_code: "65664",
    zip_name: "halltown, mo",
  },
  {
    postal_code: "40334",
    zip_name: "hope, ky",
  },
  {
    postal_code: "67519",
    zip_name: "belpre, ks",
  },
  {
    postal_code: "84766",
    zip_name: "sevier, ut",
  },
  {
    postal_code: "83431",
    zip_name: "lewisville, id",
  },
  {
    postal_code: "63373",
    zip_name: "portage des sioux, mo",
  },
  {
    postal_code: "55982",
    zip_name: "waltham, mn",
  },
  {
    postal_code: "50481",
    zip_name: "toeterville, ia",
  },
  {
    postal_code: "43747",
    zip_name: "jerusalem, oh",
  },
  {
    postal_code: "68070",
    zip_name: "weston, ne",
  },
  {
    postal_code: "61474",
    zip_name: "saint augustine, il",
  },
  {
    postal_code: "97057",
    zip_name: "shaniko, or",
  },
  {
    postal_code: "50552",
    zip_name: "knierim, ia",
  },
  {
    postal_code: "40161",
    zip_name: "rhodelia, ky",
  },
  {
    postal_code: "39358",
    zip_name: "scooba, ms",
  },
  {
    postal_code: "56127",
    zip_name: "dunnell, mn",
  },
  {
    postal_code: "58079",
    zip_name: "wheatland, nd",
  },
  {
    postal_code: "64682",
    zip_name: "tina, mo",
  },
  {
    postal_code: "95697",
    zip_name: "yolo, ca",
  },
  {
    postal_code: "57265",
    zip_name: "strandburg, sd",
  },
  {
    postal_code: "41604",
    zip_name: "beaver, ky",
  },
  {
    postal_code: "66415",
    zip_name: "centralia, ks",
  },
  {
    postal_code: "15922",
    zip_name: "belsano, pa",
  },
  {
    postal_code: "80652",
    zip_name: "roggen, co",
  },
  {
    postal_code: "62313",
    zip_name: "basco, il",
  },
  {
    postal_code: "41849",
    zip_name: "seco, ky",
  },
  {
    postal_code: "58352",
    zip_name: "munich, nd",
  },
  {
    postal_code: "58495",
    zip_name: "wishek, nd",
  },
  {
    postal_code: "68817",
    zip_name: "ashton, ne",
  },
  {
    postal_code: "58018",
    zip_name: "colfax, nd",
  },
  {
    postal_code: "24570",
    zip_name: "lowry, va",
  },
  {
    postal_code: "68980",
    zip_name: "trumbull, ne",
  },
  {
    postal_code: "67575",
    zip_name: "rush center, ks",
  },
  {
    postal_code: "76238",
    zip_name: "era, tx",
  },
  {
    postal_code: "39361",
    zip_name: "shuqualak, ms",
  },
  {
    postal_code: "71270",
    zip_name: "ruston, la",
  },
  {
    postal_code: "52172",
    zip_name: "waukon, ia",
  },
  {
    postal_code: "48203",
    zip_name: "highland park, mi",
  },
  {
    postal_code: "48096",
    zip_name: "ray, mi",
  },
  {
    postal_code: "64498",
    zip_name: "westboro, mo",
  },
  {
    postal_code: "48850",
    zip_name: "lakeview, mi",
  },
  {
    postal_code: "56132",
    zip_name: "garvin, mn",
  },
  {
    postal_code: "54452",
    zip_name: "merrill, wi",
  },
  {
    postal_code: "41055",
    zip_name: "mayslick, ky",
  },
  {
    postal_code: "23513",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "70818",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "69131",
    zip_name: "dalton, ne",
  },
  {
    postal_code: "38224",
    zip_name: "cottage grove, tn",
  },
  {
    postal_code: "41727",
    zip_name: "chavies, ky",
  },
  {
    postal_code: "51527",
    zip_name: "defiance, ia",
  },
  {
    postal_code: "68653",
    zip_name: "platte center, ne",
  },
  {
    postal_code: "49834",
    zip_name: "foster city, mi",
  },
  {
    postal_code: "52752",
    zip_name: "grandview, ia",
  },
  {
    postal_code: "99353",
    zip_name: "west richland, wa",
  },
  {
    postal_code: "7803",
    zip_name: "mine hill, nj",
  },
  {
    postal_code: "87530",
    zip_name: "el rito, nm",
  },
  {
    postal_code: "48210",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "39701",
    zip_name: "columbus, ms",
  },
  {
    postal_code: "36582",
    zip_name: "theodore, al",
  },
  {
    postal_code: "29164",
    zip_name: "wagener, sc",
  },
  {
    postal_code: "16340",
    zip_name: "pittsfield, pa",
  },
  {
    postal_code: "64483",
    zip_name: "rosendale, mo",
  },
  {
    postal_code: "65345",
    zip_name: "mora, mo",
  },
  {
    postal_code: "63785",
    zip_name: "whitewater, mo",
  },
  {
    postal_code: "67218",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "14211",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "35760",
    zip_name: "new hope, al",
  },
  {
    postal_code: "87014",
    zip_name: "cubero, nm",
  },
  {
    postal_code: "50603",
    zip_name: "alta vista, ia",
  },
  {
    postal_code: "47553",
    zip_name: "loogootee, in",
  },
  {
    postal_code: "57054",
    zip_name: "ramona, sd",
  },
  {
    postal_code: "62859",
    zip_name: "mc leansboro, il",
  },
  {
    postal_code: "26812",
    zip_name: "mathias, wv",
  },
  {
    postal_code: "10504",
    zip_name: "armonk, ny",
  },
  {
    postal_code: "72530",
    zip_name: "drasco, ar",
  },
  {
    postal_code: "40923",
    zip_name: "cannon, ky",
  },
  {
    postal_code: "74080",
    zip_name: "talala, ok",
  },
  {
    postal_code: "28708",
    zip_name: "balsam grove, nc",
  },
  {
    postal_code: "74860",
    zip_name: "paden, ok",
  },
  {
    postal_code: "7077",
    zip_name: "sewaren, nj",
  },
  {
    postal_code: "27980",
    zip_name: "tyner, nc",
  },
  {
    postal_code: "66539",
    zip_name: "silver lake, ks",
  },
  {
    postal_code: "48817",
    zip_name: "corunna, mi",
  },
  {
    postal_code: "88426",
    zip_name: "logan, nm",
  },
  {
    postal_code: "13641",
    zip_name: "fishers landing, ny",
  },
  {
    postal_code: "18622",
    zip_name: "huntington mills, pa",
  },
  {
    postal_code: "71046",
    zip_name: "keatchie, la",
  },
  {
    postal_code: "21536",
    zip_name: "grantsville, md",
  },
  {
    postal_code: "58104",
    zip_name: "fargo, nd",
  },
  {
    postal_code: "57382",
    zip_name: "wessington springs, sd",
  },
  {
    postal_code: "16344",
    zip_name: "rouseville, pa",
  },
  {
    postal_code: "36782",
    zip_name: "sweet water, al",
  },
  {
    postal_code: "97375",
    zip_name: "scotts mills, or",
  },
  {
    postal_code: "70770",
    zip_name: "pride, la",
  },
  {
    postal_code: "39653",
    zip_name: "meadville, ms",
  },
  {
    postal_code: "37098",
    zip_name: "lyles, tn",
  },
  {
    postal_code: "99612",
    zip_name: "king cove, ak",
  },
  {
    postal_code: "66773",
    zip_name: "scammon, ks",
  },
  {
    postal_code: "98651",
    zip_name: "underwood, wa",
  },
  {
    postal_code: "39823",
    zip_name: "blakely, ga",
  },
  {
    postal_code: "94104",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "61458",
    zip_name: "maquon, il",
  },
  {
    postal_code: "82620",
    zip_name: "alcova, wy",
  },
  {
    postal_code: "35611",
    zip_name: "athens, al",
  },
  {
    postal_code: "63964",
    zip_name: "silva, mo",
  },
  {
    postal_code: "58240",
    zip_name: "hatton, nd",
  },
  {
    postal_code: "61913",
    zip_name: "atwood, il",
  },
  {
    postal_code: "76463",
    zip_name: "mingus, tx",
  },
  {
    postal_code: "79378",
    zip_name: "wellman, tx",
  },
  {
    postal_code: "63823",
    zip_name: "bertrand, mo",
  },
  {
    postal_code: "36875",
    zip_name: "seale, al",
  },
  {
    postal_code: "49266",
    zip_name: "osseo, mi",
  },
  {
    postal_code: "98831",
    zip_name: "manson, wa",
  },
  {
    postal_code: "62473",
    zip_name: "watson, il",
  },
  {
    postal_code: "18249",
    zip_name: "sugarloaf, pa",
  },
  {
    postal_code: "26627",
    zip_name: "heaters, wv",
  },
  {
    postal_code: "49757",
    zip_name: "mackinac island, mi",
  },
  {
    postal_code: "73017",
    zip_name: "cement, ok",
  },
  {
    postal_code: "67202",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "23703",
    zip_name: "portsmouth, va",
  },
  {
    postal_code: "15758",
    zip_name: "marchand, pa",
  },
  {
    postal_code: "36373",
    zip_name: "shorterville, al",
  },
  {
    postal_code: "67857",
    zip_name: "kendall, ks",
  },
  {
    postal_code: "41631",
    zip_name: "grethel, ky",
  },
  {
    postal_code: "85333",
    zip_name: "dateland, az",
  },
  {
    postal_code: "73659",
    zip_name: "putnam, ok",
  },
  {
    postal_code: "99103",
    zip_name: "almira, wa",
  },
  {
    postal_code: "36436",
    zip_name: "dickinson, al",
  },
  {
    postal_code: "63541",
    zip_name: "glenwood, mo",
  },
  {
    postal_code: "52251",
    zip_name: "ladora, ia",
  },
  {
    postal_code: "59851",
    zip_name: "milltown, mt",
  },
  {
    postal_code: "83450",
    zip_name: "terreton, id",
  },
  {
    postal_code: "72359",
    zip_name: "madison, ar",
  },
  {
    postal_code: "56032",
    zip_name: "freeborn, mn",
  },
  {
    postal_code: "74117",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "36039",
    zip_name: "hardaway, al",
  },
  {
    postal_code: "52760",
    zip_name: "moscow, ia",
  },
  {
    postal_code: "80736",
    zip_name: "iliff, co",
  },
  {
    postal_code: "58040",
    zip_name: "gwinner, nd",
  },
  {
    postal_code: "27926",
    zip_name: "corapeake, nc",
  },
  {
    postal_code: "97732",
    zip_name: "crane, or",
  },
  {
    postal_code: "50857",
    zip_name: "nodaway, ia",
  },
  {
    postal_code: "61274",
    zip_name: "osco, il",
  },
  {
    postal_code: "25870",
    zip_name: "maben, wv",
  },
  {
    postal_code: "57317",
    zip_name: "bonesteel, sd",
  },
  {
    postal_code: "59326",
    zip_name: "fallon, mt",
  },
  {
    postal_code: "99017",
    zip_name: "lamont, wa",
  },
  {
    postal_code: "42223",
    zip_name: "fort campbell, tn",
  },
  {
    postal_code: "62444",
    zip_name: "mode, il",
  },
  {
    postal_code: "5073",
    zip_name: "taftsville, vt",
  },
  {
    postal_code: "26339",
    zip_name: "center point, wv",
  },
  {
    postal_code: "76483",
    zip_name: "throckmorton, tx",
  },
  {
    postal_code: "51452",
    zip_name: "lidderdale, ia",
  },
  {
    postal_code: "25059",
    zip_name: "dixie, wv",
  },
  {
    postal_code: "80805",
    zip_name: "bethune, co",
  },
  {
    postal_code: "83824",
    zip_name: "desmet, id",
  },
  {
    postal_code: "83624",
    zip_name: "grand view, id",
  },
  {
    postal_code: "96076",
    zip_name: "platina, ca",
  },
  {
    postal_code: "53022",
    zip_name: "germantown, wi",
  },
  {
    postal_code: "62899",
    zip_name: "xenia, il",
  },
  {
    postal_code: "66735",
    zip_name: "franklin, ks",
  },
  {
    postal_code: "58730",
    zip_name: "crosby, nd",
  },
  {
    postal_code: "97039",
    zip_name: "moro, or",
  },
  {
    postal_code: "24925",
    zip_name: "caldwell, wv",
  },
  {
    postal_code: "66864",
    zip_name: "neosho rapids, ks",
  },
  {
    postal_code: "72923",
    zip_name: "barling, ar",
  },
  {
    postal_code: "50246",
    zip_name: "stanhope, ia",
  },
  {
    postal_code: "12911",
    zip_name: "keeseville, ny",
  },
  {
    postal_code: "93287",
    zip_name: "woody, ca",
  },
  {
    postal_code: "67664",
    zip_name: "prairie view, ks",
  },
  {
    postal_code: "15484",
    zip_name: "uledi, pa",
  },
  {
    postal_code: "25267",
    zip_name: "normantown, wv",
  },
  {
    postal_code: "98939",
    zip_name: "parker, wa",
  },
  {
    postal_code: "96062",
    zip_name: "millville, ca",
  },
  {
    postal_code: "66833",
    zip_name: "allen, ks",
  },
  {
    postal_code: "50530",
    zip_name: "dayton, ia",
  },
  {
    postal_code: "16638",
    zip_name: "entriken, pa",
  },
  {
    postal_code: "49918",
    zip_name: "copper harbor, mi",
  },
  {
    postal_code: "56023",
    zip_name: "delavan, mn",
  },
  {
    postal_code: "70739",
    zip_name: "greenwell springs, la",
  },
  {
    postal_code: "21610",
    zip_name: "betterton, md",
  },
  {
    postal_code: "5069",
    zip_name: "south ryegate, vt",
  },
  {
    postal_code: "54864",
    zip_name: "poplar, wi",
  },
  {
    postal_code: "2576",
    zip_name: "west wareham, ma",
  },
  {
    postal_code: "97434",
    zip_name: "dorena, or",
  },
  {
    postal_code: "82442",
    zip_name: "ten sleep, wy",
  },
  {
    postal_code: "5442",
    zip_name: "belvidere center, vt",
  },
  {
    postal_code: "65010",
    zip_name: "ashland, mo",
  },
  {
    postal_code: "48050",
    zip_name: "new haven, mi",
  },
  {
    postal_code: "35776",
    zip_name: "woodville, al",
  },
  {
    postal_code: "98126",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "23696",
    zip_name: "seaford, va",
  },
  {
    postal_code: "22904",
    zip_name: "charlottesville, va",
  },
  {
    postal_code: "73106",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "62468",
    zip_name: "toledo, il",
  },
  {
    postal_code: "56319",
    zip_name: "carlos, mn",
  },
  {
    postal_code: "67844",
    zip_name: "fowler, ks",
  },
  {
    postal_code: "99743",
    zip_name: "healy, ak",
  },
  {
    postal_code: "38737",
    zip_name: "drew, ms",
  },
  {
    postal_code: "88118",
    zip_name: "floyd, nm",
  },
  {
    postal_code: "49886",
    zip_name: "spalding, mi",
  },
  {
    postal_code: "78576",
    zip_name: "penitas, tx",
  },
  {
    postal_code: "31705",
    zip_name: "albany, ga",
  },
  {
    postal_code: "24464",
    zip_name: "montebello, va",
  },
  {
    postal_code: "52567",
    zip_name: "libertyville, ia",
  },
  {
    postal_code: "26833",
    zip_name: "maysville, wv",
  },
  {
    postal_code: "98409",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "74930",
    zip_name: "bokoshe, ok",
  },
  {
    postal_code: "71655",
    zip_name: "monticello, ar",
  },
  {
    postal_code: "41216",
    zip_name: "east point, ky",
  },
  {
    postal_code: "63665",
    zip_name: "redford, mo",
  },
  {
    postal_code: "80101",
    zip_name: "agate, co",
  },
  {
    postal_code: "23221",
    zip_name: "richmond, va",
  },
  {
    postal_code: "38967",
    zip_name: "winona, ms",
  },
  {
    postal_code: "80830",
    zip_name: "matheson, co",
  },
  {
    postal_code: "17309",
    zip_name: "brogue, pa",
  },
  {
    postal_code: "66873",
    zip_name: "wilsey, ks",
  },
  {
    postal_code: "48033",
    zip_name: "southfield, mi",
  },
  {
    postal_code: "4413",
    zip_name: "brookton, me",
  },
  {
    postal_code: "38647",
    zip_name: "michigan city, ms",
  },
  {
    postal_code: "41601",
    zip_name: "allen, ky",
  },
  {
    postal_code: "19931",
    zip_name: "bethel, de",
  },
  {
    postal_code: "26537",
    zip_name: "kingwood, wv",
  },
  {
    postal_code: "67870",
    zip_name: "satanta, ks",
  },
  {
    postal_code: "99171",
    zip_name: "saint john, wa",
  },
  {
    postal_code: "15756",
    zip_name: "mc intyre, pa",
  },
  {
    postal_code: "80828",
    zip_name: "limon, co",
  },
  {
    postal_code: "61335",
    zip_name: "mc nabb, il",
  },
  {
    postal_code: "48460",
    zip_name: "new lothrop, mi",
  },
  {
    postal_code: "68756",
    zip_name: "neligh, ne",
  },
  {
    postal_code: "23040",
    zip_name: "cumberland, va",
  },
  {
    postal_code: "83840",
    zip_name: "kootenai, id",
  },
  {
    postal_code: "94940",
    zip_name: "marshall, ca",
  },
  {
    postal_code: "57625",
    zip_name: "eagle butte, sd",
  },
  {
    postal_code: "63781",
    zip_name: "sedgewickville, mo",
  },
  {
    postal_code: "4006",
    zip_name: "biddeford pool, me",
  },
  {
    postal_code: "62872",
    zip_name: "opdyke, il",
  },
  {
    postal_code: "98817",
    zip_name: "chelan falls, wa",
  },
  {
    postal_code: "70375",
    zip_name: "mathews, la",
  },
  {
    postal_code: "62976",
    zip_name: "pulaski, il",
  },
  {
    postal_code: "26587",
    zip_name: "rachel, wv",
  },
  {
    postal_code: "65062",
    zip_name: "mount sterling, mo",
  },
  {
    postal_code: "2841",
    zip_name: "newport, ri",
  },
  {
    postal_code: "97906",
    zip_name: "harper, or",
  },
  {
    postal_code: "68669",
    zip_name: "ulysses, ne",
  },
  {
    postal_code: "82081",
    zip_name: "meriden, wy",
  },
  {
    postal_code: "41821",
    zip_name: "hallie, ky",
  },
  {
    postal_code: "56274",
    zip_name: "norcross, mn",
  },
  {
    postal_code: "41859",
    zip_name: "dema, ky",
  },
  {
    postal_code: "88038",
    zip_name: "gila, nm",
  },
  {
    postal_code: "51552",
    zip_name: "marne, ia",
  },
  {
    postal_code: "24565",
    zip_name: "java, va",
  },
  {
    postal_code: "74644",
    zip_name: "marland, ok",
  },
  {
    postal_code: "68413",
    zip_name: "nehawka, ne",
  },
  {
    postal_code: "64036",
    zip_name: "henrietta, mo",
  },
  {
    postal_code: "31512",
    zip_name: "ambrose, ga",
  },
  {
    postal_code: "67838",
    zip_name: "deerfield, ks",
  },
  {
    postal_code: "87515",
    zip_name: "canjilon, nm",
  },
  {
    postal_code: "57457",
    zip_name: "long lake, sd",
  },
  {
    postal_code: "10474",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "62362",
    zip_name: "perry, il",
  },
  {
    postal_code: "89823",
    zip_name: "deeth, nv",
  },
  {
    postal_code: "67572",
    zip_name: "ransom, ks",
  },
  {
    postal_code: "68733",
    zip_name: "emerson, ne",
  },
  {
    postal_code: "67140",
    zip_name: "south haven, ks",
  },
  {
    postal_code: "30216",
    zip_name: "flovilla, ga",
  },
  {
    postal_code: "50524",
    zip_name: "clare, ia",
  },
  {
    postal_code: "28637",
    zip_name: "hildebran, nc",
  },
  {
    postal_code: "96013",
    zip_name: "burney, ca",
  },
  {
    postal_code: "92121",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "75667",
    zip_name: "laneville, tx",
  },
  {
    postal_code: "37340",
    zip_name: "guild, tn",
  },
  {
    postal_code: "98221",
    zip_name: "anacortes, wa",
  },
  {
    postal_code: "24737",
    zip_name: "montcalm, wv",
  },
  {
    postal_code: "23315",
    zip_name: "carrsville, va",
  },
  {
    postal_code: "43127",
    zip_name: "haydenville, oh",
  },
  {
    postal_code: "49112",
    zip_name: "edwardsburg, mi",
  },
  {
    postal_code: "38873",
    zip_name: "tishomingo, ms",
  },
  {
    postal_code: "57062",
    zip_name: "springfield, sd",
  },
  {
    postal_code: "67008",
    zip_name: "atlanta, ks",
  },
  {
    postal_code: "25173",
    zip_name: "robson, wv",
  },
  {
    postal_code: "62655",
    zip_name: "kilbourne, il",
  },
  {
    postal_code: "97117",
    zip_name: "gales creek, or",
  },
  {
    postal_code: "87824",
    zip_name: "luna, nm",
  },
  {
    postal_code: "72449",
    zip_name: "o kean, ar",
  },
  {
    postal_code: "15449",
    zip_name: "keisterville, pa",
  },
  {
    postal_code: "52647",
    zip_name: "olds, ia",
  },
  {
    postal_code: "17017",
    zip_name: "dalmatia, pa",
  },
  {
    postal_code: "5821",
    zip_name: "barnet, vt",
  },
  {
    postal_code: "98550",
    zip_name: "hoquiam, wa",
  },
  {
    postal_code: "56327",
    zip_name: "farwell, mn",
  },
  {
    postal_code: "96116",
    zip_name: "likely, ca",
  },
  {
    postal_code: "71411",
    zip_name: "campti, la",
  },
  {
    postal_code: "4987",
    zip_name: "troy, me",
  },
  {
    postal_code: "55753",
    zip_name: "keewatin, mn",
  },
  {
    postal_code: "4349",
    zip_name: "kents hill, me",
  },
  {
    postal_code: "53218",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "79057",
    zip_name: "mclean, tx",
  },
  {
    postal_code: "64631",
    zip_name: "bucklin, mo",
  },
  {
    postal_code: "69217",
    zip_name: "long pine, ne",
  },
  {
    postal_code: "67432",
    zip_name: "clay center, ks",
  },
  {
    postal_code: "23508",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "32420",
    zip_name: "alford, fl",
  },
  {
    postal_code: "66535",
    zip_name: "saint george, ks",
  },
  {
    postal_code: "61724",
    zip_name: "bellflower, il",
  },
  {
    postal_code: "55310",
    zip_name: "bird island, mn",
  },
  {
    postal_code: "63954",
    zip_name: "neelyville, mo",
  },
  {
    postal_code: "80117",
    zip_name: "kiowa, co",
  },
  {
    postal_code: "14623",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "38472",
    zip_name: "lynnville, tn",
  },
  {
    postal_code: "10923",
    zip_name: "garnerville, ny",
  },
  {
    postal_code: "87548",
    zip_name: "medanales, nm",
  },
  {
    postal_code: "82084",
    zip_name: "tie siding, wy",
  },
  {
    postal_code: "7011",
    zip_name: "clifton, nj",
  },
  {
    postal_code: "71958",
    zip_name: "murfreesboro, ar",
  },
  {
    postal_code: "35543",
    zip_name: "bear creek, al",
  },
  {
    postal_code: "95542",
    zip_name: "garberville, ca",
  },
  {
    postal_code: "72087",
    zip_name: "lonsdale, ar",
  },
  {
    postal_code: "18083",
    zip_name: "stockertown, pa",
  },
  {
    postal_code: "59024",
    zip_name: "custer, mt",
  },
  {
    postal_code: "5820",
    zip_name: "albany, vt",
  },
  {
    postal_code: "84761",
    zip_name: "parowan, ut",
  },
  {
    postal_code: "70725",
    zip_name: "darrow, la",
  },
  {
    postal_code: "49955",
    zip_name: "painesdale, mi",
  },
  {
    postal_code: "37404",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "25265",
    zip_name: "new haven, wv",
  },
  {
    postal_code: "36474",
    zip_name: "red level, al",
  },
  {
    postal_code: "5874",
    zip_name: "westfield, vt",
  },
  {
    postal_code: "63829",
    zip_name: "cardwell, mo",
  },
  {
    postal_code: "71327",
    zip_name: "cottonport, la",
  },
  {
    postal_code: "42079",
    zip_name: "sedalia, ky",
  },
  {
    postal_code: "32435",
    zip_name: "defuniak springs, fl",
  },
  {
    postal_code: "26443",
    zip_name: "troy, wv",
  },
  {
    postal_code: "21717",
    zip_name: "buckeystown, md",
  },
  {
    postal_code: "68072",
    zip_name: "winslow, ne",
  },
  {
    postal_code: "31092",
    zip_name: "vienna, ga",
  },
  {
    postal_code: "76937",
    zip_name: "eola, tx",
  },
  {
    postal_code: "50071",
    zip_name: "dows, ia",
  },
  {
    postal_code: "97531",
    zip_name: "kerby, or",
  },
  {
    postal_code: "98155",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "82710",
    zip_name: "aladdin, wy",
  },
  {
    postal_code: "72937",
    zip_name: "hackett, ar",
  },
  {
    postal_code: "68055",
    zip_name: "rosalie, ne",
  },
  {
    postal_code: "70819",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "41645",
    zip_name: "langley, ky",
  },
  {
    postal_code: "48760",
    zip_name: "silverwood, mi",
  },
  {
    postal_code: "81055",
    zip_name: "la veta, co",
  },
  {
    postal_code: "67863",
    zip_name: "marienthal, ks",
  },
  {
    postal_code: "42369",
    zip_name: "rockport, ky",
  },
  {
    postal_code: "72321",
    zip_name: "burdette, ar",
  },
  {
    postal_code: "87567",
    zip_name: "santa cruz, nm",
  },
  {
    postal_code: "1029",
    zip_name: "east otis, ma",
  },
  {
    postal_code: "67485",
    zip_name: "tipton, ks",
  },
  {
    postal_code: "67138",
    zip_name: "sharon, ks",
  },
  {
    postal_code: "51364",
    zip_name: "terril, ia",
  },
  {
    postal_code: "68642",
    zip_name: "humphrey, ne",
  },
  {
    postal_code: "46180",
    zip_name: "stilesville, in",
  },
  {
    postal_code: "80727",
    zip_name: "eckley, co",
  },
  {
    postal_code: "47147",
    zip_name: "nabb, in",
  },
  {
    postal_code: "25505",
    zip_name: "big creek, wv",
  },
  {
    postal_code: "57467",
    zip_name: "orient, sd",
  },
  {
    postal_code: "68415",
    zip_name: "odell, ne",
  },
  {
    postal_code: "13860",
    zip_name: "west davenport, ny",
  },
  {
    postal_code: "57569",
    zip_name: "reliance, sd",
  },
  {
    postal_code: "73667",
    zip_name: "taloga, ok",
  },
  {
    postal_code: "65281",
    zip_name: "salisbury, mo",
  },
  {
    postal_code: "84308",
    zip_name: "cornish, ut",
  },
  {
    postal_code: "92327",
    zip_name: "daggett, ca",
  },
  {
    postal_code: "51045",
    zip_name: "oyens, ia",
  },
  {
    postal_code: "66521",
    zip_name: "onaga, ks",
  },
  {
    postal_code: "41845",
    zip_name: "premium, ky",
  },
  {
    postal_code: "36482",
    zip_name: "whatley, al",
  },
  {
    postal_code: "81027",
    zip_name: "branson, co",
  },
  {
    postal_code: "45820",
    zip_name: "cairo, oh",
  },
  {
    postal_code: "56626",
    zip_name: "bena, mn",
  },
  {
    postal_code: "72168",
    zip_name: "tucker, ar",
  },
  {
    postal_code: "62361",
    zip_name: "pearl, il",
  },
  {
    postal_code: "47529",
    zip_name: "elnora, in",
  },
  {
    postal_code: "83604",
    zip_name: "bruneau, id",
  },
  {
    postal_code: "42321",
    zip_name: "beech creek, ky",
  },
  {
    postal_code: "35474",
    zip_name: "moundville, al",
  },
  {
    postal_code: "36031",
    zip_name: "fort davis, al",
  },
  {
    postal_code: "70514",
    zip_name: "baldwin, la",
  },
  {
    postal_code: "52301",
    zip_name: "marengo, ia",
  },
  {
    postal_code: "49288",
    zip_name: "waldron, mi",
  },
  {
    postal_code: "71263",
    zip_name: "oak grove, la",
  },
  {
    postal_code: "69120",
    zip_name: "arnold, ne",
  },
  {
    postal_code: "98506",
    zip_name: "olympia, wa",
  },
  {
    postal_code: "10576",
    zip_name: "pound ridge, ny",
  },
  {
    postal_code: "37016",
    zip_name: "auburntown, tn",
  },
  {
    postal_code: "47611",
    zip_name: "chrisney, in",
  },
  {
    postal_code: "72539",
    zip_name: "glencoe, ar",
  },
  {
    postal_code: "18817",
    zip_name: "east smithfield, pa",
  },
  {
    postal_code: "31567",
    zip_name: "west green, ga",
  },
  {
    postal_code: "31712",
    zip_name: "arabi, ga",
  },
  {
    postal_code: "99360",
    zip_name: "touchet, wa",
  },
  {
    postal_code: "37407",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "37688",
    zip_name: "shady valley, tn",
  },
  {
    postal_code: "95983",
    zip_name: "taylorsville, ca",
  },
  {
    postal_code: "65778",
    zip_name: "myrtle, mo",
  },
  {
    postal_code: "59404",
    zip_name: "great falls, mt",
  },
  {
    postal_code: "49070",
    zip_name: "martin, mi",
  },
  {
    postal_code: "51242",
    zip_name: "lester, ia",
  },
  {
    postal_code: "54813",
    zip_name: "barronett, wi",
  },
  {
    postal_code: "66014",
    zip_name: "centerville, ks",
  },
  {
    postal_code: "49779",
    zip_name: "rogers city, mi",
  },
  {
    postal_code: "99116",
    zip_name: "coulee dam, wa",
  },
  {
    postal_code: "58554",
    zip_name: "mandan, nd",
  },
  {
    postal_code: "76379",
    zip_name: "scotland, tx",
  },
  {
    postal_code: "64637",
    zip_name: "cowgill, mo",
  },
  {
    postal_code: "48801",
    zip_name: "alma, mi",
  },
  {
    postal_code: "45735",
    zip_name: "guysville, oh",
  },
  {
    postal_code: "62967",
    zip_name: "new burnside, il",
  },
  {
    postal_code: "29125",
    zip_name: "pinewood, sc",
  },
  {
    postal_code: "67364",
    zip_name: "tyro, ks",
  },
  {
    postal_code: "10530",
    zip_name: "hartsdale, ny",
  },
  {
    postal_code: "83530",
    zip_name: "grangeville, id",
  },
  {
    postal_code: "80451",
    zip_name: "hot sulphur springs, co",
  },
  {
    postal_code: "14877",
    zip_name: "rexville, ny",
  },
  {
    postal_code: "71346",
    zip_name: "lecompte, la",
  },
  {
    postal_code: "58563",
    zip_name: "new salem, nd",
  },
  {
    postal_code: "61834",
    zip_name: "danville, il",
  },
  {
    postal_code: "92004",
    zip_name: "borrego springs, ca",
  },
  {
    postal_code: "35650",
    zip_name: "moulton, al",
  },
  {
    postal_code: "98833",
    zip_name: "mazama, wa",
  },
  {
    postal_code: "4920",
    zip_name: "bingham, me",
  },
  {
    postal_code: "98535",
    zip_name: "copalis beach, wa",
  },
  {
    postal_code: "41240",
    zip_name: "paintsville, ky",
  },
  {
    postal_code: "49012",
    zip_name: "augusta, mi",
  },
  {
    postal_code: "48005",
    zip_name: "armada, mi",
  },
  {
    postal_code: "70630",
    zip_name: "bell city, la",
  },
  {
    postal_code: "67330",
    zip_name: "altamont, ks",
  },
  {
    postal_code: "71835",
    zip_name: "emmet, ar",
  },
  {
    postal_code: "47443",
    zip_name: "lyons, in",
  },
  {
    postal_code: "57334",
    zip_name: "ethan, sd",
  },
  {
    postal_code: "67119",
    zip_name: "oxford, ks",
  },
  {
    postal_code: "64633",
    zip_name: "carrollton, mo",
  },
  {
    postal_code: "76357",
    zip_name: "byers, tx",
  },
  {
    postal_code: "50840",
    zip_name: "clearfield, ia",
  },
  {
    postal_code: "52321",
    zip_name: "onslow, ia",
  },
  {
    postal_code: "17251",
    zip_name: "roxbury, pa",
  },
  {
    postal_code: "73061",
    zip_name: "morrison, ok",
  },
  {
    postal_code: "44404",
    zip_name: "burghill, oh",
  },
  {
    postal_code: "54410",
    zip_name: "arpin, wi",
  },
  {
    postal_code: "71280",
    zip_name: "sterlington, la",
  },
  {
    postal_code: "5833",
    zip_name: "east charleston, vt",
  },
  {
    postal_code: "96073",
    zip_name: "palo cedro, ca",
  },
  {
    postal_code: "30660",
    zip_name: "rayle, ga",
  },
  {
    postal_code: "31806",
    zip_name: "ellaville, ga",
  },
  {
    postal_code: "25174",
    zip_name: "rock creek, wv",
  },
  {
    postal_code: "45613",
    zip_name: "beaver, oh",
  },
  {
    postal_code: "74543",
    zip_name: "finley, ok",
  },
  {
    postal_code: "73860",
    zip_name: "waynoka, ok",
  },
  {
    postal_code: "96136",
    zip_name: "wendel, ca",
  },
  {
    postal_code: "65337",
    zip_name: "la monte, mo",
  },
  {
    postal_code: "67651",
    zip_name: "natoma, ks",
  },
  {
    postal_code: "56252",
    zip_name: "kerkhoven, mn",
  },
  {
    postal_code: "25529",
    zip_name: "julian, wv",
  },
  {
    postal_code: "50117",
    zip_name: "hamlin, ia",
  },
  {
    postal_code: "16679",
    zip_name: "six mile run, pa",
  },
  {
    postal_code: "56525",
    zip_name: "comstock, mn",
  },
  {
    postal_code: "13483",
    zip_name: "westdale, ny",
  },
  {
    postal_code: "94074",
    zip_name: "san gregorio, ca",
  },
  {
    postal_code: "96054",
    zip_name: "lookout, ca",
  },
  {
    postal_code: "79713",
    zip_name: "ackerly, tx",
  },
  {
    postal_code: "99927",
    zip_name: "point baker, ak",
  },
  {
    postal_code: "25820",
    zip_name: "camp creek, wv",
  },
  {
    postal_code: "24867",
    zip_name: "new richmond, wv",
  },
  {
    postal_code: "64436",
    zip_name: "cosby, mo",
  },
  {
    postal_code: "70091",
    zip_name: "venice, la",
  },
  {
    postal_code: "66546",
    zip_name: "wakarusa, ks",
  },
  {
    postal_code: "25671",
    zip_name: "dingess, wv",
  },
  {
    postal_code: "63386",
    zip_name: "west alton, mo",
  },
  {
    postal_code: "63433",
    zip_name: "ashburn, mo",
  },
  {
    postal_code: "3871",
    zip_name: "rye beach, nh",
  },
  {
    postal_code: "65334",
    zip_name: "hughesville, mo",
  },
  {
    postal_code: "93528",
    zip_name: "johannesburg, ca",
  },
  {
    postal_code: "21626",
    zip_name: "crapo, md",
  },
  {
    postal_code: "80444",
    zip_name: "georgetown, co",
  },
  {
    postal_code: "95427",
    zip_name: "comptche, ca",
  },
  {
    postal_code: "51533",
    zip_name: "emerson, ia",
  },
  {
    postal_code: "35593",
    zip_name: "vina, al",
  },
  {
    postal_code: "78860",
    zip_name: "el indio, tx",
  },
  {
    postal_code: "52738",
    zip_name: "columbus junction, ia",
  },
  {
    postal_code: "87059",
    zip_name: "tijeras, nm",
  },
  {
    postal_code: "47512",
    zip_name: "bicknell, in",
  },
  {
    postal_code: "62650",
    zip_name: "jacksonville, il",
  },
  {
    postal_code: "65640",
    zip_name: "dunnegan, mo",
  },
  {
    postal_code: "95549",
    zip_name: "kneeland, ca",
  },
  {
    postal_code: "59058",
    zip_name: "mosby, mt",
  },
  {
    postal_code: "95568",
    zip_name: "somes bar, ca",
  },
  {
    postal_code: "99903",
    zip_name: "meyers chuck, ak",
  },
  {
    postal_code: "57039",
    zip_name: "lennox, sd",
  },
  {
    postal_code: "25868",
    zip_name: "lookout, wv",
  },
  {
    postal_code: "25837",
    zip_name: "edmond, wv",
  },
  {
    postal_code: "99721",
    zip_name: "anaktuvuk pass, ak",
  },
  {
    postal_code: "28672",
    zip_name: "scottville, nc",
  },
  {
    postal_code: "61363",
    zip_name: "standard, il",
  },
  {
    postal_code: "36569",
    zip_name: "saint stephens, al",
  },
  {
    postal_code: "69156",
    zip_name: "potter, ne",
  },
  {
    postal_code: "87933",
    zip_name: "derry, nm",
  },
  {
    postal_code: "59472",
    zip_name: "sand coulee, mt",
  },
  {
    postal_code: "65791",
    zip_name: "thayer, mo",
  },
  {
    postal_code: "38461",
    zip_name: "hampshire, tn",
  },
  {
    postal_code: "8736",
    zip_name: "manasquan, nj",
  },
  {
    postal_code: "73447",
    zip_name: "mannsville, ok",
  },
  {
    postal_code: "31054",
    zip_name: "mc intyre, ga",
  },
  {
    postal_code: "24986",
    zip_name: "white sulphur springs, wv",
  },
  {
    postal_code: "42049",
    zip_name: "hazel, ky",
  },
  {
    postal_code: "72422",
    zip_name: "corning, ar",
  },
  {
    postal_code: "25136",
    zip_name: "montgomery, wv",
  },
  {
    postal_code: "17250",
    zip_name: "rouzerville, pa",
  },
  {
    postal_code: "54630",
    zip_name: "galesville, wi",
  },
  {
    postal_code: "49872",
    zip_name: "perkins, mi",
  },
  {
    postal_code: "98588",
    zip_name: "tahuya, wa",
  },
  {
    postal_code: "46989",
    zip_name: "upland, in",
  },
  {
    postal_code: "72466",
    zip_name: "smithville, ar",
  },
  {
    postal_code: "48346",
    zip_name: "clarkston, mi",
  },
  {
    postal_code: "84764",
    zip_name: "bryce, ut",
  },
  {
    postal_code: "37149",
    zip_name: "readyville, tn",
  },
  {
    postal_code: "50625",
    zip_name: "dumont, ia",
  },
  {
    postal_code: "55749",
    zip_name: "holyoke, mn",
  },
  {
    postal_code: "7452",
    zip_name: "glen rock, nj",
  },
  {
    postal_code: "42055",
    zip_name: "kuttawa, ky",
  },
  {
    postal_code: "24134",
    zip_name: "pearisburg, va",
  },
  {
    postal_code: "62459",
    zip_name: "sainte marie, il",
  },
  {
    postal_code: "24162",
    zip_name: "shawsville, va",
  },
  {
    postal_code: "73089",
    zip_name: "tuttle, ok",
  },
  {
    postal_code: "58237",
    zip_name: "grafton, nd",
  },
  {
    postal_code: "69140",
    zip_name: "grant, ne",
  },
  {
    postal_code: "4617",
    zip_name: "brooksville, me",
  },
  {
    postal_code: "29809",
    zip_name: "new ellenton, sc",
  },
  {
    postal_code: "37144",
    zip_name: "petersburg, tn",
  },
  {
    postal_code: "47634",
    zip_name: "richland, in",
  },
  {
    postal_code: "10464",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "52247",
    zip_name: "kalona, ia",
  },
  {
    postal_code: "23831",
    zip_name: "chester, va",
  },
  {
    postal_code: "49783",
    zip_name: "sault sainte marie, mi",
  },
  {
    postal_code: "54827",
    zip_name: "cornucopia, wi",
  },
  {
    postal_code: "15542",
    zip_name: "garrett, pa",
  },
  {
    postal_code: "31015",
    zip_name: "cordele, ga",
  },
  {
    postal_code: "48206",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "7304",
    zip_name: "jersey city, nj",
  },
  {
    postal_code: "68328",
    zip_name: "clatonia, ne",
  },
  {
    postal_code: "53036",
    zip_name: "ixonia, wi",
  },
  {
    postal_code: "68663",
    zip_name: "silver creek, ne",
  },
  {
    postal_code: "73059",
    zip_name: "minco, ok",
  },
  {
    postal_code: "65548",
    zip_name: "mountain view, mo",
  },
  {
    postal_code: "57105",
    zip_name: "sioux falls, sd",
  },
  {
    postal_code: "72301",
    zip_name: "west memphis, ar",
  },
  {
    postal_code: "45634",
    zip_name: "hamden, oh",
  },
  {
    postal_code: "50585",
    zip_name: "sioux rapids, ia",
  },
  {
    postal_code: "47532",
    zip_name: "ferdinand, in",
  },
  {
    postal_code: "67156",
    zip_name: "winfield, ks",
  },
  {
    postal_code: "31805",
    zip_name: "cusseta, ga",
  },
  {
    postal_code: "77969",
    zip_name: "la salle, tx",
  },
  {
    postal_code: "35563",
    zip_name: "guin, al",
  },
  {
    postal_code: "74457",
    zip_name: "proctor, ok",
  },
  {
    postal_code: "59401",
    zip_name: "great falls, mt",
  },
  {
    postal_code: "58641",
    zip_name: "lefor, nd",
  },
  {
    postal_code: "62831",
    zip_name: "du bois, il",
  },
  {
    postal_code: "66526",
    zip_name: "paxico, ks",
  },
  {
    postal_code: "41232",
    zip_name: "lowmansville, ky",
  },
  {
    postal_code: "61359",
    zip_name: "seatonville, il",
  },
  {
    postal_code: "47575",
    zip_name: "saint anthony, in",
  },
  {
    postal_code: "23161",
    zip_name: "stevensville, va",
  },
  {
    postal_code: "70086",
    zip_name: "saint james, la",
  },
  {
    postal_code: "59003",
    zip_name: "ashland, mt",
  },
  {
    postal_code: "74856",
    zip_name: "mill creek, ok",
  },
  {
    postal_code: "26224",
    zip_name: "helvetia, wv",
  },
  {
    postal_code: "29133",
    zip_name: "rowesville, sc",
  },
  {
    postal_code: "30821",
    zip_name: "norwood, ga",
  },
  {
    postal_code: "51010",
    zip_name: "castana, ia",
  },
  {
    postal_code: "88427",
    zip_name: "mcalister, nm",
  },
  {
    postal_code: "57648",
    zip_name: "pollock, sd",
  },
  {
    postal_code: "68457",
    zip_name: "verdon, ne",
  },
  {
    postal_code: "58229",
    zip_name: "fairdale, nd",
  },
  {
    postal_code: "69045",
    zip_name: "wauneta, ne",
  },
  {
    postal_code: "56249",
    zip_name: "holloway, mn",
  },
  {
    postal_code: "74572",
    zip_name: "tupelo, ok",
  },
  {
    postal_code: "62836",
    zip_name: "ewing, il",
  },
  {
    postal_code: "87516",
    zip_name: "canones, nm",
  },
  {
    postal_code: "17223",
    zip_name: "fort littleton, pa",
  },
  {
    postal_code: "62343",
    zip_name: "hull, il",
  },
  {
    postal_code: "2071",
    zip_name: "south walpole, ma",
  },
  {
    postal_code: "68828",
    zip_name: "comstock, ne",
  },
  {
    postal_code: "36529",
    zip_name: "deer park, al",
  },
  {
    postal_code: "61378",
    zip_name: "west brooklyn, il",
  },
  {
    postal_code: "56027",
    zip_name: "elmore, mn",
  },
  {
    postal_code: "38864",
    zip_name: "randolph, ms",
  },
  {
    postal_code: "73601",
    zip_name: "clinton, ok",
  },
  {
    postal_code: "56627",
    zip_name: "big falls, mn",
  },
  {
    postal_code: "46990",
    zip_name: "urbana, in",
  },
  {
    postal_code: "57068",
    zip_name: "valley springs, sd",
  },
  {
    postal_code: "7755",
    zip_name: "oakhurst, nj",
  },
  {
    postal_code: "56019",
    zip_name: "comfrey, mn",
  },
  {
    postal_code: "99115",
    zip_name: "coulee city, wa",
  },
  {
    postal_code: "73005",
    zip_name: "anadarko, ok",
  },
  {
    postal_code: "68826",
    zip_name: "central city, ne",
  },
  {
    postal_code: "47805",
    zip_name: "terre haute, in",
  },
  {
    postal_code: "14880",
    zip_name: "scio, ny",
  },
  {
    postal_code: "17836",
    zip_name: "leck kill, pa",
  },
  {
    postal_code: "26425",
    zip_name: "rowlesburg, wv",
  },
  {
    postal_code: "58075",
    zip_name: "wahpeton, nd",
  },
  {
    postal_code: "57568",
    zip_name: "presho, sd",
  },
  {
    postal_code: "82715",
    zip_name: "four corners, wy",
  },
  {
    postal_code: "76884",
    zip_name: "valera, tx",
  },
  {
    postal_code: "41775",
    zip_name: "wendover, ky",
  },
  {
    postal_code: "64650",
    zip_name: "kingston, mo",
  },
  {
    postal_code: "97343",
    zip_name: "eddyville, or",
  },
  {
    postal_code: "50068",
    zip_name: "derby, ia",
  },
  {
    postal_code: "67301",
    zip_name: "independence, ks",
  },
  {
    postal_code: "29203",
    zip_name: "columbia, sc",
  },
  {
    postal_code: "22812",
    zip_name: "bridgewater, va",
  },
  {
    postal_code: "7108",
    zip_name: "newark, nj",
  },
  {
    postal_code: "79084",
    zip_name: "stratford, tx",
  },
  {
    postal_code: "81642",
    zip_name: "meredith, co",
  },
  {
    postal_code: "37205",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "72082",
    zip_name: "kensett, ar",
  },
  {
    postal_code: "38951",
    zip_name: "pittsboro, ms",
  },
  {
    postal_code: "75569",
    zip_name: "nash, tx",
  },
  {
    postal_code: "94709",
    zip_name: "berkeley, ca",
  },
  {
    postal_code: "74028",
    zip_name: "depew, ok",
  },
  {
    postal_code: "14040",
    zip_name: "darien center, ny",
  },
  {
    postal_code: "81241",
    zip_name: "pitkin, co",
  },
  {
    postal_code: "98424",
    zip_name: "fife, wa",
  },
  {
    postal_code: "24225",
    zip_name: "cleveland, va",
  },
  {
    postal_code: "58655",
    zip_name: "south heart, nd",
  },
  {
    postal_code: "92275",
    zip_name: "salton city, ca",
  },
  {
    postal_code: "53010",
    zip_name: "campbellsport, wi",
  },
  {
    postal_code: "57248",
    zip_name: "lake norden, sd",
  },
  {
    postal_code: "73038",
    zip_name: "fort cobb, ok",
  },
  {
    postal_code: "37313",
    zip_name: "coalmont, tn",
  },
  {
    postal_code: "68658",
    zip_name: "rising city, ne",
  },
  {
    postal_code: "53931",
    zip_name: "fairwater, wi",
  },
  {
    postal_code: "56350",
    zip_name: "mc grath, mn",
  },
  {
    postal_code: "26865",
    zip_name: "yellow spring, wv",
  },
  {
    postal_code: "16927",
    zip_name: "harrison valley, pa",
  },
  {
    postal_code: "57042",
    zip_name: "madison, sd",
  },
  {
    postal_code: "29835",
    zip_name: "mc cormick, sc",
  },
  {
    postal_code: "74359",
    zip_name: "oaks, ok",
  },
  {
    postal_code: "47521",
    zip_name: "celestine, in",
  },
  {
    postal_code: "68455",
    zip_name: "union, ne",
  },
  {
    postal_code: "62950",
    zip_name: "jacob, il",
  },
  {
    postal_code: "98010",
    zip_name: "black diamond, wa",
  },
  {
    postal_code: "31029",
    zip_name: "forsyth, ga",
  },
  {
    postal_code: "79748",
    zip_name: "knott, tx",
  },
  {
    postal_code: "35674",
    zip_name: "tuscumbia, al",
  },
  {
    postal_code: "64451",
    zip_name: "forest city, mo",
  },
  {
    postal_code: "5904",
    zip_name: "gilman, vt",
  },
  {
    postal_code: "7718",
    zip_name: "belford, nj",
  },
  {
    postal_code: "23505",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "50525",
    zip_name: "clarion, ia",
  },
  {
    postal_code: "70791",
    zip_name: "zachary, la",
  },
  {
    postal_code: "70762",
    zip_name: "oscar, la",
  },
  {
    postal_code: "26271",
    zip_name: "hendricks, wv",
  },
  {
    postal_code: "97721",
    zip_name: "princeton, or",
  },
  {
    postal_code: "71422",
    zip_name: "dodson, la",
  },
  {
    postal_code: "72531",
    zip_name: "elizabeth, ar",
  },
  {
    postal_code: "23222",
    zip_name: "richmond, va",
  },
  {
    postal_code: "30420",
    zip_name: "cobbtown, ga",
  },
  {
    postal_code: "10604",
    zip_name: "west harrison, ny",
  },
  {
    postal_code: "46538",
    zip_name: "leesburg, in",
  },
  {
    postal_code: "96124",
    zip_name: "calpine, ca",
  },
  {
    postal_code: "26323",
    zip_name: "anmoore, wv",
  },
  {
    postal_code: "83116",
    zip_name: "diamondville, wy",
  },
  {
    postal_code: "32330",
    zip_name: "greensboro, fl",
  },
  {
    postal_code: "79046",
    zip_name: "higgins, tx",
  },
  {
    postal_code: "62280",
    zip_name: "rockwood, il",
  },
  {
    postal_code: "82058",
    zip_name: "garrett, wy",
  },
  {
    postal_code: "63746",
    zip_name: "farrar, mo",
  },
  {
    postal_code: "68974",
    zip_name: "ruskin, ne",
  },
  {
    postal_code: "80721",
    zip_name: "amherst, co",
  },
  {
    postal_code: "59641",
    zip_name: "radersburg, mt",
  },
  {
    postal_code: "67868",
    zip_name: "pierceville, ks",
  },
  {
    postal_code: "94964",
    zip_name: "san quentin, ca",
  },
  {
    postal_code: "67474",
    zip_name: "portis, ks",
  },
  {
    postal_code: "82642",
    zip_name: "lysite, wy",
  },
  {
    postal_code: "88121",
    zip_name: "house, nm",
  },
  {
    postal_code: "64423",
    zip_name: "barnard, mo",
  },
  {
    postal_code: "22850",
    zip_name: "singers glen, va",
  },
  {
    postal_code: "76660",
    zip_name: "malone, tx",
  },
  {
    postal_code: "70049",
    zip_name: "edgard, la",
  },
  {
    postal_code: "58238",
    zip_name: "hamilton, nd",
  },
  {
    postal_code: "68402",
    zip_name: "malcolm, ne",
  },
  {
    postal_code: "98621",
    zip_name: "grays river, wa",
  },
  {
    postal_code: "68626",
    zip_name: "brainard, ne",
  },
  {
    postal_code: "38631",
    zip_name: "friars point, ms",
  },
  {
    postal_code: "28330",
    zip_name: "cordova, nc",
  },
  {
    postal_code: "61532",
    zip_name: "forest city, il",
  },
  {
    postal_code: "59275",
    zip_name: "westby, mt",
  },
  {
    postal_code: "66411",
    zip_name: "blue rapids, ks",
  },
  {
    postal_code: "15616",
    zip_name: "armbrust, pa",
  },
  {
    postal_code: "56729",
    zip_name: "halma, mn",
  },
  {
    postal_code: "78943",
    zip_name: "glidden, tx",
  },
  {
    postal_code: "25612",
    zip_name: "chauncey, wv",
  },
  {
    postal_code: "63876",
    zip_name: "senath, mo",
  },
  {
    postal_code: "87325",
    zip_name: "tohatchi, nm",
  },
  {
    postal_code: "36776",
    zip_name: "sawyerville, al",
  },
  {
    postal_code: "58251",
    zip_name: "larimore, nd",
  },
  {
    postal_code: "25133",
    zip_name: "maysel, wv",
  },
  {
    postal_code: "62378",
    zip_name: "versailles, il",
  },
  {
    postal_code: "56731",
    zip_name: "humboldt, mn",
  },
  {
    postal_code: "35601",
    zip_name: "decatur, al",
  },
  {
    postal_code: "56025",
    zip_name: "easton, mn",
  },
  {
    postal_code: "97534",
    zip_name: "o brien, or",
  },
  {
    postal_code: "29036",
    zip_name: "chapin, sc",
  },
  {
    postal_code: "3883",
    zip_name: "south tamworth, nh",
  },
  {
    postal_code: "47016",
    zip_name: "cedar grove, in",
  },
  {
    postal_code: "65603",
    zip_name: "arcola, mo",
  },
  {
    postal_code: "32455",
    zip_name: "ponce de leon, fl",
  },
  {
    postal_code: "93615",
    zip_name: "cutler, ca",
  },
  {
    postal_code: "39323",
    zip_name: "chunky, ms",
  },
  {
    postal_code: "84073",
    zip_name: "talmage, ut",
  },
  {
    postal_code: "63460",
    zip_name: "novelty, mo",
  },
  {
    postal_code: "24226",
    zip_name: "clinchco, va",
  },
  {
    postal_code: "58230",
    zip_name: "finley, nd",
  },
  {
    postal_code: "54651",
    zip_name: "ontario, wi",
  },
  {
    postal_code: "7506",
    zip_name: "hawthorne, nj",
  },
  {
    postal_code: "68020",
    zip_name: "decatur, ne",
  },
  {
    postal_code: "59443",
    zip_name: "fort shaw, mt",
  },
  {
    postal_code: "76384",
    zip_name: "vernon, tx",
  },
  {
    postal_code: "48356",
    zip_name: "highland, mi",
  },
  {
    postal_code: "61817",
    zip_name: "catlin, il",
  },
  {
    postal_code: "36804",
    zip_name: "opelika, al",
  },
  {
    postal_code: "62019",
    zip_name: "donnellson, il",
  },
  {
    postal_code: "99161",
    zip_name: "palouse, wa",
  },
  {
    postal_code: "8846",
    zip_name: "middlesex, nj",
  },
  {
    postal_code: "56732",
    zip_name: "karlstad, mn",
  },
  {
    postal_code: "35754",
    zip_name: "laceys spring, al",
  },
  {
    postal_code: "52329",
    zip_name: "rowley, ia",
  },
  {
    postal_code: "38473",
    zip_name: "minor hill, tn",
  },
  {
    postal_code: "43983",
    zip_name: "piedmont, oh",
  },
  {
    postal_code: "42087",
    zip_name: "wickliffe, ky",
  },
  {
    postal_code: "73003",
    zip_name: "edmond, ok",
  },
  {
    postal_code: "50597",
    zip_name: "west bend, ia",
  },
  {
    postal_code: "57212",
    zip_name: "arlington, sd",
  },
  {
    postal_code: "27885",
    zip_name: "swanquarter, nc",
  },
  {
    postal_code: "67547",
    zip_name: "kinsley, ks",
  },
  {
    postal_code: "7649",
    zip_name: "oradell, nj",
  },
  {
    postal_code: "61466",
    zip_name: "north henderson, il",
  },
  {
    postal_code: "46711",
    zip_name: "berne, in",
  },
  {
    postal_code: "50669",
    zip_name: "reinbeck, ia",
  },
  {
    postal_code: "47513",
    zip_name: "birdseye, in",
  },
  {
    postal_code: "95552",
    zip_name: "mad river, ca",
  },
  {
    postal_code: "68433",
    zip_name: "salem, ne",
  },
  {
    postal_code: "52354",
    zip_name: "watkins, ia",
  },
  {
    postal_code: "45849",
    zip_name: "grover hill, oh",
  },
  {
    postal_code: "99123",
    zip_name: "electric city, wa",
  },
  {
    postal_code: "50076",
    zip_name: "exira, ia",
  },
  {
    postal_code: "63937",
    zip_name: "ellsinore, mo",
  },
  {
    postal_code: "52209",
    zip_name: "blairstown, ia",
  },
  {
    postal_code: "62427",
    zip_name: "flat rock, il",
  },
  {
    postal_code: "13859",
    zip_name: "wells bridge, ny",
  },
  {
    postal_code: "67039",
    zip_name: "douglass, ks",
  },
  {
    postal_code: "49040",
    zip_name: "colon, mi",
  },
  {
    postal_code: "48838",
    zip_name: "greenville, mi",
  },
  {
    postal_code: "87121",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "61772",
    zip_name: "shirley, il",
  },
  {
    postal_code: "38870",
    zip_name: "smithville, ms",
  },
  {
    postal_code: "85923",
    zip_name: "clay springs, az",
  },
  {
    postal_code: "23942",
    zip_name: "green bay, va",
  },
  {
    postal_code: "76305",
    zip_name: "wichita falls, tx",
  },
  {
    postal_code: "59875",
    zip_name: "victor, mt",
  },
  {
    postal_code: "99130",
    zip_name: "garfield, wa",
  },
  {
    postal_code: "16671",
    zip_name: "ramey, pa",
  },
  {
    postal_code: "46774",
    zip_name: "new haven, in",
  },
  {
    postal_code: "53046",
    zip_name: "lannon, wi",
  },
  {
    postal_code: "43010",
    zip_name: "catawba, oh",
  },
  {
    postal_code: "36921",
    zip_name: "toxey, al",
  },
  {
    postal_code: "68948",
    zip_name: "holbrook, ne",
  },
  {
    postal_code: "58273",
    zip_name: "pisek, nd",
  },
  {
    postal_code: "37140",
    zip_name: "only, tn",
  },
  {
    postal_code: "41317",
    zip_name: "clayhole, ky",
  },
  {
    postal_code: "50044",
    zip_name: "bussey, ia",
  },
  {
    postal_code: "68863",
    zip_name: "overton, ne",
  },
  {
    postal_code: "83119",
    zip_name: "fairview, wy",
  },
  {
    postal_code: "21543",
    zip_name: "midlothian, md",
  },
  {
    postal_code: "96132",
    zip_name: "termo, ca",
  },
  {
    postal_code: "15006",
    zip_name: "bairdford, pa",
  },
  {
    postal_code: "26684",
    zip_name: "pool, wv",
  },
  {
    postal_code: "50052",
    zip_name: "clio, ia",
  },
  {
    postal_code: "72569",
    zip_name: "poughkeepsie, ar",
  },
  {
    postal_code: "73041",
    zip_name: "gotebo, ok",
  },
  {
    postal_code: "25966",
    zip_name: "green sulphur springs, wv",
  },
  {
    postal_code: "43925",
    zip_name: "east springfield, oh",
  },
  {
    postal_code: "66442",
    zip_name: "fort riley, ks",
  },
  {
    postal_code: "68834",
    zip_name: "eddyville, ne",
  },
  {
    postal_code: "87006",
    zip_name: "bosque, nm",
  },
  {
    postal_code: "58758",
    zip_name: "martin, nd",
  },
  {
    postal_code: "75856",
    zip_name: "pennington, tx",
  },
  {
    postal_code: "41397",
    zip_name: "zoe, ky",
  },
  {
    postal_code: "57523",
    zip_name: "burke, sd",
  },
  {
    postal_code: "47115",
    zip_name: "depauw, in",
  },
  {
    postal_code: "16230",
    zip_name: "hawthorn, pa",
  },
  {
    postal_code: "68652",
    zip_name: "petersburg, ne",
  },
  {
    postal_code: "64499",
    zip_name: "worth, mo",
  },
  {
    postal_code: "64642",
    zip_name: "gilman city, mo",
  },
  {
    postal_code: "82423",
    zip_name: "frannie, wy",
  },
  {
    postal_code: "18248",
    zip_name: "sheppton, pa",
  },
  {
    postal_code: "98583",
    zip_name: "satsop, wa",
  },
  {
    postal_code: "15310",
    zip_name: "aleppo, pa",
  },
  {
    postal_code: "46110",
    zip_name: "boggstown, in",
  },
  {
    postal_code: "59217",
    zip_name: "crane, mt",
  },
  {
    postal_code: "98367",
    zip_name: "port orchard, wa",
  },
  {
    postal_code: "27022",
    zip_name: "lawsonville, nc",
  },
  {
    postal_code: "35750",
    zip_name: "hazel green, al",
  },
  {
    postal_code: "75102",
    zip_name: "barry, tx",
  },
  {
    postal_code: "98568",
    zip_name: "oakville, wa",
  },
  {
    postal_code: "99516",
    zip_name: "anchorage, ak",
  },
  {
    postal_code: "49459",
    zip_name: "walkerville, mi",
  },
  {
    postal_code: "26169",
    zip_name: "rockport, wv",
  },
  {
    postal_code: "38658",
    zip_name: "pope, ms",
  },
  {
    postal_code: "88338",
    zip_name: "lincoln, nm",
  },
  {
    postal_code: "97839",
    zip_name: "lexington, or",
  },
  {
    postal_code: "70808",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "51053",
    zip_name: "schaller, ia",
  },
  {
    postal_code: "5840",
    zip_name: "granby, vt",
  },
  {
    postal_code: "97335",
    zip_name: "crabtree, or",
  },
  {
    postal_code: "25981",
    zip_name: "quinwood, wv",
  },
  {
    postal_code: "53825",
    zip_name: "stitzer, wi",
  },
  {
    postal_code: "76652",
    zip_name: "kopperl, tx",
  },
  {
    postal_code: "39643",
    zip_name: "kokomo, ms",
  },
  {
    postal_code: "59087",
    zip_name: "winnett, mt",
  },
  {
    postal_code: "64466",
    zip_name: "maitland, mo",
  },
  {
    postal_code: "73012",
    zip_name: "edmond, ok",
  },
  {
    postal_code: "52146",
    zip_name: "harpers ferry, ia",
  },
  {
    postal_code: "32444",
    zip_name: "lynn haven, fl",
  },
  {
    postal_code: "36539",
    zip_name: "fruitdale, al",
  },
  {
    postal_code: "16938",
    zip_name: "morris, pa",
  },
  {
    postal_code: "46920",
    zip_name: "cutler, in",
  },
  {
    postal_code: "78341",
    zip_name: "benavides, tx",
  },
  {
    postal_code: "53224",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "53571",
    zip_name: "morrisonville, wi",
  },
  {
    postal_code: "98201",
    zip_name: "everett, wa",
  },
  {
    postal_code: "38564",
    zip_name: "granville, tn",
  },
  {
    postal_code: "13158",
    zip_name: "truxton, ny",
  },
  {
    postal_code: "71219",
    zip_name: "baskin, la",
  },
  {
    postal_code: "38843",
    zip_name: "fulton, ms",
  },
  {
    postal_code: "53522",
    zip_name: "browntown, wi",
  },
  {
    postal_code: "87722",
    zip_name: "guadalupita, nm",
  },
  {
    postal_code: "71225",
    zip_name: "calhoun, la",
  },
  {
    postal_code: "36376",
    zip_name: "webb, al",
  },
  {
    postal_code: "47341",
    zip_name: "fountain city, in",
  },
  {
    postal_code: "32430",
    zip_name: "clarksville, fl",
  },
  {
    postal_code: "16050",
    zip_name: "petrolia, pa",
  },
  {
    postal_code: "75631",
    zip_name: "beckville, tx",
  },
  {
    postal_code: "98612",
    zip_name: "cathlamet, wa",
  },
  {
    postal_code: "49347",
    zip_name: "trufant, mi",
  },
  {
    postal_code: "15777",
    zip_name: "starford, pa",
  },
  {
    postal_code: "17035",
    zip_name: "honey grove, pa",
  },
  {
    postal_code: "16870",
    zip_name: "port matilda, pa",
  },
  {
    postal_code: "75411",
    zip_name: "arthur city, tx",
  },
  {
    postal_code: "24127",
    zip_name: "new castle, va",
  },
  {
    postal_code: "62649",
    zip_name: "hettick, il",
  },
  {
    postal_code: "59433",
    zip_name: "dutton, mt",
  },
  {
    postal_code: "32352",
    zip_name: "quincy, fl",
  },
  {
    postal_code: "83631",
    zip_name: "idaho city, id",
  },
  {
    postal_code: "48434",
    zip_name: "forestville, mi",
  },
  {
    postal_code: "23021",
    zip_name: "bohannon, va",
  },
  {
    postal_code: "48202",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "53110",
    zip_name: "cudahy, wi",
  },
  {
    postal_code: "15861",
    zip_name: "sinnamahoning, pa",
  },
  {
    postal_code: "65617",
    zip_name: "brighton, mo",
  },
  {
    postal_code: "26160",
    zip_name: "palestine, wv",
  },
  {
    postal_code: "70748",
    zip_name: "jackson, la",
  },
  {
    postal_code: "70817",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "54653",
    zip_name: "rockland, wi",
  },
  {
    postal_code: "68768",
    zip_name: "pilger, ne",
  },
  {
    postal_code: "42533",
    zip_name: "ferguson, ky",
  },
  {
    postal_code: "4657",
    zip_name: "meddybemps, me",
  },
  {
    postal_code: "67210",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "54245",
    zip_name: "valders, wi",
  },
  {
    postal_code: "36345",
    zip_name: "headland, al",
  },
  {
    postal_code: "31631",
    zip_name: "fargo, ga",
  },
  {
    postal_code: "73062",
    zip_name: "mountain view, ok",
  },
  {
    postal_code: "73004",
    zip_name: "amber, ok",
  },
  {
    postal_code: "36855",
    zip_name: "five points, al",
  },
  {
    postal_code: "46988",
    zip_name: "twelve mile, in",
  },
  {
    postal_code: "71108",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "52171",
    zip_name: "waucoma, ia",
  },
  {
    postal_code: "67739",
    zip_name: "herndon, ks",
  },
  {
    postal_code: "40020",
    zip_name: "fairfield, ky",
  },
  {
    postal_code: "50460",
    zip_name: "orchard, ia",
  },
  {
    postal_code: "83256",
    zip_name: "moreland, id",
  },
  {
    postal_code: "58533",
    zip_name: "elgin, nd",
  },
  {
    postal_code: "50528",
    zip_name: "cylinder, ia",
  },
  {
    postal_code: "80745",
    zip_name: "padroni, co",
  },
  {
    postal_code: "4735",
    zip_name: "bridgewater, me",
  },
  {
    postal_code: "60470",
    zip_name: "ransom, il",
  },
  {
    postal_code: "58520",
    zip_name: "almont, nd",
  },
  {
    postal_code: "65285",
    zip_name: "thompson, mo",
  },
  {
    postal_code: "20812",
    zip_name: "glen echo, md",
  },
  {
    postal_code: "73666",
    zip_name: "sweetwater, ok",
  },
  {
    postal_code: "1965",
    zip_name: "prides crossing, ma",
  },
  {
    postal_code: "63873",
    zip_name: "portageville, mo",
  },
  {
    postal_code: "75492",
    zip_name: "windom, tx",
  },
  {
    postal_code: "36919",
    zip_name: "silas, al",
  },
  {
    postal_code: "80810",
    zip_name: "cheyenne wells, co",
  },
  {
    postal_code: "93628",
    zip_name: "hume, ca",
  },
  {
    postal_code: "61322",
    zip_name: "depue, il",
  },
  {
    postal_code: "97490",
    zip_name: "walton, or",
  },
  {
    postal_code: "57460",
    zip_name: "mansfield, sd",
  },
  {
    postal_code: "92318",
    zip_name: "bryn mawr, ca",
  },
  {
    postal_code: "68058",
    zip_name: "south bend, ne",
  },
  {
    postal_code: "94569",
    zip_name: "port costa, ca",
  },
  {
    postal_code: "66772",
    zip_name: "savonburg, ks",
  },
  {
    postal_code: "25922",
    zip_name: "spanishburg, wv",
  },
  {
    postal_code: "56129",
    zip_name: "ellsworth, mn",
  },
  {
    postal_code: "66035",
    zip_name: "highland, ks",
  },
  {
    postal_code: "47104",
    zip_name: "bethlehem, in",
  },
  {
    postal_code: "56054",
    zip_name: "lafayette, mn",
  },
  {
    postal_code: "26342",
    zip_name: "coxs mills, wv",
  },
  {
    postal_code: "83876",
    zip_name: "worley, id",
  },
  {
    postal_code: "45653",
    zip_name: "minford, oh",
  },
  {
    postal_code: "4451",
    zip_name: "kingman, me",
  },
  {
    postal_code: "73009",
    zip_name: "binger, ok",
  },
  {
    postal_code: "71269",
    zip_name: "rayville, la",
  },
  {
    postal_code: "87064",
    zip_name: "youngsville, nm",
  },
  {
    postal_code: "46914",
    zip_name: "bunker hill, in",
  },
  {
    postal_code: "62621",
    zip_name: "bluffs, il",
  },
  {
    postal_code: "8887",
    zip_name: "three bridges, nj",
  },
  {
    postal_code: "65676",
    zip_name: "isabella, mo",
  },
  {
    postal_code: "35616",
    zip_name: "cherokee, al",
  },
  {
    postal_code: "96065",
    zip_name: "montgomery creek, ca",
  },
  {
    postal_code: "23404",
    zip_name: "locustville, va",
  },
  {
    postal_code: "16920",
    zip_name: "elkland, pa",
  },
  {
    postal_code: "31641",
    zip_name: "naylor, ga",
  },
  {
    postal_code: "15368",
    zip_name: "vestaburg, pa",
  },
  {
    postal_code: "4495",
    zip_name: "winn, me",
  },
  {
    postal_code: "78353",
    zip_name: "encino, tx",
  },
  {
    postal_code: "13864",
    zip_name: "willseyville, ny",
  },
  {
    postal_code: "79080",
    zip_name: "skellytown, tx",
  },
  {
    postal_code: "64745",
    zip_name: "foster, mo",
  },
  {
    postal_code: "14466",
    zip_name: "hemlock, ny",
  },
  {
    postal_code: "29378",
    zip_name: "una, sc",
  },
  {
    postal_code: "59537",
    zip_name: "loring, mt",
  },
  {
    postal_code: "83302",
    zip_name: "rogerson, id",
  },
  {
    postal_code: "15633",
    zip_name: "forbes road, pa",
  },
  {
    postal_code: "79011",
    zip_name: "briscoe, tx",
  },
  {
    postal_code: "88119",
    zip_name: "fort sumner, nm",
  },
  {
    postal_code: "1031",
    zip_name: "gilbertville, ma",
  },
  {
    postal_code: "53015",
    zip_name: "cleveland, wi",
  },
  {
    postal_code: "65720",
    zip_name: "oldfield, mo",
  },
  {
    postal_code: "40040",
    zip_name: "mackville, ky",
  },
  {
    postal_code: "4050",
    zip_name: "long island, me",
  },
  {
    postal_code: "83348",
    zip_name: "picabo, id",
  },
  {
    postal_code: "27830",
    zip_name: "fremont, nc",
  },
  {
    postal_code: "14091",
    zip_name: "lawtons, ny",
  },
  {
    postal_code: "37090",
    zip_name: "lebanon, tn",
  },
  {
    postal_code: "61925",
    zip_name: "dalton city, il",
  },
  {
    postal_code: "98925",
    zip_name: "easton, wa",
  },
  {
    postal_code: "44902",
    zip_name: "mansfield, oh",
  },
  {
    postal_code: "27876",
    zip_name: "seaboard, nc",
  },
  {
    postal_code: "37060",
    zip_name: "eagleville, tn",
  },
  {
    postal_code: "36370",
    zip_name: "pansey, al",
  },
  {
    postal_code: "43945",
    zip_name: "salineville, oh",
  },
  {
    postal_code: "39339",
    zip_name: "louisville, ms",
  },
  {
    postal_code: "98528",
    zip_name: "belfair, wa",
  },
  {
    postal_code: "69129",
    zip_name: "chappell, ne",
  },
  {
    postal_code: "97827",
    zip_name: "elgin, or",
  },
  {
    postal_code: "29137",
    zip_name: "salley, sc",
  },
  {
    postal_code: "16678",
    zip_name: "saxton, pa",
  },
  {
    postal_code: "49736",
    zip_name: "goetzville, mi",
  },
  {
    postal_code: "53210",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "97406",
    zip_name: "agness, or",
  },
  {
    postal_code: "69343",
    zip_name: "gordon, ne",
  },
  {
    postal_code: "12729",
    zip_name: "cuddebackville, ny",
  },
  {
    postal_code: "37185",
    zip_name: "waverly, tn",
  },
  {
    postal_code: "36775",
    zip_name: "sardis, al",
  },
  {
    postal_code: "7501",
    zip_name: "paterson, nj",
  },
  {
    postal_code: "39175",
    zip_name: "utica, ms",
  },
  {
    postal_code: "87004",
    zip_name: "bernalillo, nm",
  },
  {
    postal_code: "16932",
    zip_name: "mainesburg, pa",
  },
  {
    postal_code: "51006",
    zip_name: "battle creek, ia",
  },
  {
    postal_code: "98852",
    zip_name: "stehekin, wa",
  },
  {
    postal_code: "39116",
    zip_name: "mize, ms",
  },
  {
    postal_code: "38901",
    zip_name: "grenada, ms",
  },
  {
    postal_code: "23065",
    zip_name: "gum spring, va",
  },
  {
    postal_code: "59484",
    zip_name: "sweet grass, mt",
  },
  {
    postal_code: "62860",
    zip_name: "macedonia, il",
  },
  {
    postal_code: "61436",
    zip_name: "gilson, il",
  },
  {
    postal_code: "35805",
    zip_name: "huntsville, al",
  },
  {
    postal_code: "38625",
    zip_name: "dumas, ms",
  },
  {
    postal_code: "30401",
    zip_name: "swainsboro, ga",
  },
  {
    postal_code: "58006",
    zip_name: "arthur, nd",
  },
  {
    postal_code: "43728",
    zip_name: "chesterhill, oh",
  },
  {
    postal_code: "95039",
    zip_name: "moss landing, ca",
  },
  {
    postal_code: "87939",
    zip_name: "monticello, nm",
  },
  {
    postal_code: "63744",
    zip_name: "delta, mo",
  },
  {
    postal_code: "74959",
    zip_name: "spiro, ok",
  },
  {
    postal_code: "43778",
    zip_name: "salesville, oh",
  },
  {
    postal_code: "12777",
    zip_name: "forestburgh, ny",
  },
  {
    postal_code: "78607",
    zip_name: "bluffton, tx",
  },
  {
    postal_code: "44887",
    zip_name: "tiro, oh",
  },
  {
    postal_code: "62321",
    zip_name: "carthage, il",
  },
  {
    postal_code: "76862",
    zip_name: "millersview, tx",
  },
  {
    postal_code: "98305",
    zip_name: "beaver, wa",
  },
  {
    postal_code: "70753",
    zip_name: "lettsworth, la",
  },
  {
    postal_code: "94938",
    zip_name: "lagunitas, ca",
  },
  {
    postal_code: "28436",
    zip_name: "delco, nc",
  },
  {
    postal_code: "58366",
    zip_name: "rolette, nd",
  },
  {
    postal_code: "62452",
    zip_name: "parkersburg, il",
  },
  {
    postal_code: "15004",
    zip_name: "atlasburg, pa",
  },
  {
    postal_code: "30470",
    zip_name: "tarrytown, ga",
  },
  {
    postal_code: "68777",
    zip_name: "spencer, ne",
  },
  {
    postal_code: "41826",
    zip_name: "jeremiah, ky",
  },
  {
    postal_code: "45746",
    zip_name: "macksburg, oh",
  },
  {
    postal_code: "63820",
    zip_name: "anniston, mo",
  },
  {
    postal_code: "59471",
    zip_name: "roy, mt",
  },
  {
    postal_code: "42741",
    zip_name: "glens fork, ky",
  },
  {
    postal_code: "15350",
    zip_name: "muse, pa",
  },
  {
    postal_code: "17534",
    zip_name: "intercourse, pa",
  },
  {
    postal_code: "64090",
    zip_name: "strasburg, mo",
  },
  {
    postal_code: "87029",
    zip_name: "lindrith, nm",
  },
  {
    postal_code: "31071",
    zip_name: "pineview, ga",
  },
  {
    postal_code: "72517",
    zip_name: "brockwell, ar",
  },
  {
    postal_code: "15410",
    zip_name: "adah, pa",
  },
  {
    postal_code: "72585",
    zip_name: "wideman, ar",
  },
  {
    postal_code: "29493",
    zip_name: "williams, sc",
  },
  {
    postal_code: "78235",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "10985",
    zip_name: "thompson ridge, ny",
  },
  {
    postal_code: "63848",
    zip_name: "gideon, mo",
  },
  {
    postal_code: "71663",
    zip_name: "portland, ar",
  },
  {
    postal_code: "79560",
    zip_name: "sylvester, tx",
  },
  {
    postal_code: "62089",
    zip_name: "taylor springs, il",
  },
  {
    postal_code: "97731",
    zip_name: "chemult, or",
  },
  {
    postal_code: "42461",
    zip_name: "uniontown, ky",
  },
  {
    postal_code: "17239",
    zip_name: "neelyton, pa",
  },
  {
    postal_code: "38042",
    zip_name: "hickory valley, tn",
  },
  {
    postal_code: "75937",
    zip_name: "chireno, tx",
  },
  {
    postal_code: "59006",
    zip_name: "ballantine, mt",
  },
  {
    postal_code: "35816",
    zip_name: "huntsville, al",
  },
  {
    postal_code: "72950",
    zip_name: "parks, ar",
  },
  {
    postal_code: "49087",
    zip_name: "schoolcraft, mi",
  },
  {
    postal_code: "79064",
    zip_name: "olton, tx",
  },
  {
    postal_code: "61919",
    zip_name: "camargo, il",
  },
  {
    postal_code: "65243",
    zip_name: "clark, mo",
  },
  {
    postal_code: "35765",
    zip_name: "pisgah, al",
  },
  {
    postal_code: "58531",
    zip_name: "coleharbor, nd",
  },
  {
    postal_code: "4745",
    zip_name: "frenchville, me",
  },
  {
    postal_code: "82604",
    zip_name: "casper, wy",
  },
  {
    postal_code: "7606",
    zip_name: "south hackensack, nj",
  },
  {
    postal_code: "15685",
    zip_name: "southwest, pa",
  },
  {
    postal_code: "88348",
    zip_name: "san patricio, nm",
  },
  {
    postal_code: "39322",
    zip_name: "buckatunna, ms",
  },
  {
    postal_code: "72127",
    zip_name: "plumerville, ar",
  },
  {
    postal_code: "47982",
    zip_name: "state line, in",
  },
  {
    postal_code: "58013",
    zip_name: "cayuga, nd",
  },
  {
    postal_code: "84336",
    zip_name: "snowville, ut",
  },
  {
    postal_code: "24850",
    zip_name: "jolo, wv",
  },
  {
    postal_code: "67841",
    zip_name: "ensign, ks",
  },
  {
    postal_code: "68862",
    zip_name: "ord, ne",
  },
  {
    postal_code: "27042",
    zip_name: "pine hall, nc",
  },
  {
    postal_code: "8869",
    zip_name: "raritan, nj",
  },
  {
    postal_code: "56134",
    zip_name: "hardwick, mn",
  },
  {
    postal_code: "68030",
    zip_name: "homer, ne",
  },
  {
    postal_code: "46550",
    zip_name: "nappanee, in",
  },
  {
    postal_code: "76689",
    zip_name: "valley mills, tx",
  },
  {
    postal_code: "23704",
    zip_name: "portsmouth, va",
  },
  {
    postal_code: "30817",
    zip_name: "lincolnton, ga",
  },
  {
    postal_code: "57434",
    zip_name: "conde, sd",
  },
  {
    postal_code: "59263",
    zip_name: "scobey, mt",
  },
  {
    postal_code: "73950",
    zip_name: "turpin, ok",
  },
  {
    postal_code: "53011",
    zip_name: "cascade, wi",
  },
  {
    postal_code: "72047",
    zip_name: "enola, ar",
  },
  {
    postal_code: "47802",
    zip_name: "terre haute, in",
  },
  {
    postal_code: "58258",
    zip_name: "mekinock, nd",
  },
  {
    postal_code: "52159",
    zip_name: "monona, ia",
  },
  {
    postal_code: "65611",
    zip_name: "blue eye, mo",
  },
  {
    postal_code: "78881",
    zip_name: "sabinal, tx",
  },
  {
    postal_code: "71825",
    zip_name: "blevins, ar",
  },
  {
    postal_code: "75977",
    zip_name: "wiergate, tx",
  },
  {
    postal_code: "95469",
    zip_name: "potter valley, ca",
  },
  {
    postal_code: "56741",
    zip_name: "oak island, mn",
  },
  {
    postal_code: "84537",
    zip_name: "orangeville, ut",
  },
  {
    postal_code: "84329",
    zip_name: "park valley, ut",
  },
  {
    postal_code: "17318",
    zip_name: "emigsville, pa",
  },
  {
    postal_code: "47223",
    zip_name: "butlerville, in",
  },
  {
    postal_code: "28693",
    zip_name: "warrensville, nc",
  },
  {
    postal_code: "68640",
    zip_name: "genoa, ne",
  },
  {
    postal_code: "87580",
    zip_name: "valdez, nm",
  },
  {
    postal_code: "30624",
    zip_name: "bowman, ga",
  },
  {
    postal_code: "74364",
    zip_name: "rose, ok",
  },
  {
    postal_code: "98119",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "99574",
    zip_name: "cordova, ak",
  },
  {
    postal_code: "52403",
    zip_name: "cedar rapids, ia",
  },
  {
    postal_code: "12961",
    zip_name: "moriah center, ny",
  },
  {
    postal_code: "98408",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "67640",
    zip_name: "gorham, ks",
  },
  {
    postal_code: "87531",
    zip_name: "embudo, nm",
  },
  {
    postal_code: "67031",
    zip_name: "conway springs, ks",
  },
  {
    postal_code: "62556",
    zip_name: "palmer, il",
  },
  {
    postal_code: "75567",
    zip_name: "maud, tx",
  },
  {
    postal_code: "76310",
    zip_name: "wichita falls, tx",
  },
  {
    postal_code: "45684",
    zip_name: "stout, oh",
  },
  {
    postal_code: "48234",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "73051",
    zip_name: "lexington, ok",
  },
  {
    postal_code: "5762",
    zip_name: "pittsfield, vt",
  },
  {
    postal_code: "66770",
    zip_name: "riverton, ks",
  },
  {
    postal_code: "52206",
    zip_name: "atkins, ia",
  },
  {
    postal_code: "15464",
    zip_name: "mill run, pa",
  },
  {
    postal_code: "83350",
    zip_name: "rupert, id",
  },
  {
    postal_code: "48720",
    zip_name: "bay port, mi",
  },
  {
    postal_code: "26060",
    zip_name: "valley grove, wv",
  },
  {
    postal_code: "99663",
    zip_name: "seldovia, ak",
  },
  {
    postal_code: "52755",
    zip_name: "lone tree, ia",
  },
  {
    postal_code: "49053",
    zip_name: "galesburg, mi",
  },
  {
    postal_code: "98107",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "93435",
    zip_name: "harmony, ca",
  },
  {
    postal_code: "87061",
    zip_name: "torreon, nm",
  },
  {
    postal_code: "54451",
    zip_name: "medford, wi",
  },
  {
    postal_code: "41180",
    zip_name: "webbville, ky",
  },
  {
    postal_code: "67855",
    zip_name: "johnson, ks",
  },
  {
    postal_code: "63631",
    zip_name: "caledonia, mo",
  },
  {
    postal_code: "46911",
    zip_name: "amboy, in",
  },
  {
    postal_code: "56557",
    zip_name: "mahnomen, mn",
  },
  {
    postal_code: "80482",
    zip_name: "winter park, co",
  },
  {
    postal_code: "27845",
    zip_name: "jackson, nc",
  },
  {
    postal_code: "69026",
    zip_name: "danbury, ne",
  },
  {
    postal_code: "64164",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "69218",
    zip_name: "merriman, ne",
  },
  {
    postal_code: "41745",
    zip_name: "gays creek, ky",
  },
  {
    postal_code: "51630",
    zip_name: "blanchard, ia",
  },
  {
    postal_code: "59736",
    zip_name: "jackson, mt",
  },
  {
    postal_code: "7935",
    zip_name: "green village, nj",
  },
  {
    postal_code: "45899",
    zip_name: "wren, oh",
  },
  {
    postal_code: "25649",
    zip_name: "verdunville, wv",
  },
  {
    postal_code: "67105",
    zip_name: "milan, ks",
  },
  {
    postal_code: "78014",
    zip_name: "cotulla, tx",
  },
  {
    postal_code: "67035",
    zip_name: "cunningham, ks",
  },
  {
    postal_code: "55613",
    zip_name: "schroeder, mn",
  },
  {
    postal_code: "74430",
    zip_name: "crowder, ok",
  },
  {
    postal_code: "68447",
    zip_name: "table rock, ne",
  },
  {
    postal_code: "95943",
    zip_name: "glenn, ca",
  },
  {
    postal_code: "87312",
    zip_name: "continental divide, nm",
  },
  {
    postal_code: "84602",
    zip_name: "provo, ut",
  },
  {
    postal_code: "19731",
    zip_name: "port penn, de",
  },
  {
    postal_code: "78562",
    zip_name: "la villa, tx",
  },
  {
    postal_code: "25503",
    zip_name: "ashton, wv",
  },
  {
    postal_code: "78885",
    zip_name: "vanderpool, tx",
  },
  {
    postal_code: "15784",
    zip_name: "worthville, pa",
  },
  {
    postal_code: "89831",
    zip_name: "mountain city, nv",
  },
  {
    postal_code: "1066",
    zip_name: "north hatfield, ma",
  },
  {
    postal_code: "63036",
    zip_name: "french village, mo",
  },
  {
    postal_code: "54130",
    zip_name: "kaukauna, wi",
  },
  {
    postal_code: "72752",
    zip_name: "pettigrew, ar",
  },
  {
    postal_code: "35963",
    zip_name: "dawson, al",
  },
  {
    postal_code: "96047",
    zip_name: "igo, ca",
  },
  {
    postal_code: "48213",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "28439",
    zip_name: "fair bluff, nc",
  },
  {
    postal_code: "58626",
    zip_name: "dunn center, nd",
  },
  {
    postal_code: "12746",
    zip_name: "huguenot, ny",
  },
  {
    postal_code: "66401",
    zip_name: "alma, ks",
  },
  {
    postal_code: "65063",
    zip_name: "new bloomfield, mo",
  },
  {
    postal_code: "59453",
    zip_name: "judith gap, mt",
  },
  {
    postal_code: "24351",
    zip_name: "lambsburg, va",
  },
  {
    postal_code: "50650",
    zip_name: "lamont, ia",
  },
  {
    postal_code: "71357",
    zip_name: "newellton, la",
  },
  {
    postal_code: "68320",
    zip_name: "brock, ne",
  },
  {
    postal_code: "87401",
    zip_name: "farmington, nm",
  },
  {
    postal_code: "54161",
    zip_name: "pound, wi",
  },
  {
    postal_code: "56650",
    zip_name: "kelliher, mn",
  },
  {
    postal_code: "67016",
    zip_name: "bentley, ks",
  },
  {
    postal_code: "36071",
    zip_name: "rutledge, al",
  },
  {
    postal_code: "76474",
    zip_name: "sidney, tx",
  },
  {
    postal_code: "64438",
    zip_name: "darlington, mo",
  },
  {
    postal_code: "47326",
    zip_name: "bryant, in",
  },
  {
    postal_code: "88024",
    zip_name: "berino, nm",
  },
  {
    postal_code: "41421",
    zip_name: "elkfork, ky",
  },
  {
    postal_code: "66403",
    zip_name: "axtell, ks",
  },
  {
    postal_code: "88124",
    zip_name: "melrose, nm",
  },
  {
    postal_code: "73844",
    zip_name: "gate, ok",
  },
  {
    postal_code: "74843",
    zip_name: "fitzhugh, ok",
  },
  {
    postal_code: "37351",
    zip_name: "lupton city, tn",
  },
  {
    postal_code: "35989",
    zip_name: "valley head, al",
  },
  {
    postal_code: "27824",
    zip_name: "engelhard, nc",
  },
  {
    postal_code: "47003",
    zip_name: "west college corner, in",
  },
  {
    postal_code: "18709",
    zip_name: "luzerne, pa",
  },
  {
    postal_code: "48851",
    zip_name: "lyons, mi",
  },
  {
    postal_code: "29204",
    zip_name: "columbia, sc",
  },
  {
    postal_code: "67877",
    zip_name: "sublette, ks",
  },
  {
    postal_code: "28393",
    zip_name: "turkey, nc",
  },
  {
    postal_code: "99135",
    zip_name: "hartline, wa",
  },
  {
    postal_code: "82301",
    zip_name: "rawlins, wy",
  },
  {
    postal_code: "32445",
    zip_name: "malone, fl",
  },
  {
    postal_code: "26264",
    zip_name: "durbin, wv",
  },
  {
    postal_code: "46517",
    zip_name: "elkhart, in",
  },
  {
    postal_code: "73114",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "80459",
    zip_name: "kremmling, co",
  },
  {
    postal_code: "73628",
    zip_name: "cheyenne, ok",
  },
  {
    postal_code: "72863",
    zip_name: "scranton, ar",
  },
  {
    postal_code: "49405",
    zip_name: "custer, mi",
  },
  {
    postal_code: "63454",
    zip_name: "maywood, mo",
  },
  {
    postal_code: "52774",
    zip_name: "welton, ia",
  },
  {
    postal_code: "45846",
    zip_name: "fort recovery, oh",
  },
  {
    postal_code: "83311",
    zip_name: "albion, id",
  },
  {
    postal_code: "47842",
    zip_name: "clinton, in",
  },
  {
    postal_code: "71764",
    zip_name: "stephens, ar",
  },
  {
    postal_code: "99835",
    zip_name: "sitka, ak",
  },
  {
    postal_code: "77978",
    zip_name: "point comfort, tx",
  },
  {
    postal_code: "76640",
    zip_name: "elm mott, tx",
  },
  {
    postal_code: "58047",
    zip_name: "horace, nd",
  },
  {
    postal_code: "13682",
    zip_name: "rodman, ny",
  },
  {
    postal_code: "29684",
    zip_name: "starr, sc",
  },
  {
    postal_code: "73572",
    zip_name: "walters, ok",
  },
  {
    postal_code: "83830",
    zip_name: "fernwood, id",
  },
  {
    postal_code: "3456",
    zip_name: "marlow, nh",
  },
  {
    postal_code: "37357",
    zip_name: "morrison, tn",
  },
  {
    postal_code: "53139",
    zip_name: "kansasville, wi",
  },
  {
    postal_code: "71973",
    zip_name: "wickes, ar",
  },
  {
    postal_code: "72001",
    zip_name: "adona, ar",
  },
  {
    postal_code: "67055",
    zip_name: "greenwich, ks",
  },
  {
    postal_code: "21649",
    zip_name: "marydel, md",
  },
  {
    postal_code: "23462",
    zip_name: "virginia beach, va",
  },
  {
    postal_code: "72063",
    zip_name: "hattieville, ar",
  },
  {
    postal_code: "49805",
    zip_name: "allouez, mi",
  },
  {
    postal_code: "47122",
    zip_name: "georgetown, in",
  },
  {
    postal_code: "70815",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "14560",
    zip_name: "springwater, ny",
  },
  {
    postal_code: "62465",
    zip_name: "strasburg, il",
  },
  {
    postal_code: "6390",
    zip_name: "fishers island, ny",
  },
  {
    postal_code: "89815",
    zip_name: "spring creek, nv",
  },
  {
    postal_code: "98020",
    zip_name: "edmonds, wa",
  },
  {
    postal_code: "41543",
    zip_name: "mc andrews, ky",
  },
  {
    postal_code: "5736",
    zip_name: "center rutland, vt",
  },
  {
    postal_code: "72416",
    zip_name: "bono, ar",
  },
  {
    postal_code: "35773",
    zip_name: "toney, al",
  },
  {
    postal_code: "50110",
    zip_name: "gray, ia",
  },
  {
    postal_code: "79535",
    zip_name: "maryneal, tx",
  },
  {
    postal_code: "99633",
    zip_name: "naknek, ak",
  },
  {
    postal_code: "15612",
    zip_name: "alverton, pa",
  },
  {
    postal_code: "76649",
    zip_name: "iredell, tx",
  },
  {
    postal_code: "72384",
    zip_name: "turrell, ar",
  },
  {
    postal_code: "58043",
    zip_name: "havana, nd",
  },
  {
    postal_code: "56022",
    zip_name: "darfur, mn",
  },
  {
    postal_code: "79244",
    zip_name: "matador, tx",
  },
  {
    postal_code: "68977",
    zip_name: "stamford, ne",
  },
  {
    postal_code: "98836",
    zip_name: "monitor, wa",
  },
  {
    postal_code: "80825",
    zip_name: "kit carson, co",
  },
  {
    postal_code: "57720",
    zip_name: "buffalo, sd",
  },
  {
    postal_code: "59258",
    zip_name: "reserve, mt",
  },
  {
    postal_code: "69339",
    zip_name: "crawford, ne",
  },
  {
    postal_code: "99579",
    zip_name: "egegik, ak",
  },
  {
    postal_code: "57041",
    zip_name: "lyons, sd",
  },
  {
    postal_code: "37376",
    zip_name: "sherwood, tn",
  },
  {
    postal_code: "50223",
    zip_name: "pilot mound, ia",
  },
  {
    postal_code: "69168",
    zip_name: "venango, ne",
  },
  {
    postal_code: "81228",
    zip_name: "granite, co",
  },
  {
    postal_code: "61845",
    zip_name: "foosland, il",
  },
  {
    postal_code: "15551",
    zip_name: "markleton, pa",
  },
  {
    postal_code: "66962",
    zip_name: "palmer, ks",
  },
  {
    postal_code: "68644",
    zip_name: "lindsay, ne",
  },
  {
    postal_code: "30285",
    zip_name: "the rock, ga",
  },
  {
    postal_code: "39320",
    zip_name: "bailey, ms",
  },
  {
    postal_code: "40921",
    zip_name: "bryants store, ky",
  },
  {
    postal_code: "62987",
    zip_name: "stonefort, il",
  },
  {
    postal_code: "69155",
    zip_name: "paxton, ne",
  },
  {
    postal_code: "38954",
    zip_name: "sidon, ms",
  },
  {
    postal_code: "78951",
    zip_name: "oakland, tx",
  },
  {
    postal_code: "58031",
    zip_name: "fingal, nd",
  },
  {
    postal_code: "64762",
    zip_name: "liberal, mo",
  },
  {
    postal_code: "40376",
    zip_name: "slade, ky",
  },
  {
    postal_code: "62537",
    zip_name: "harristown, il",
  },
  {
    postal_code: "30293",
    zip_name: "woodbury, ga",
  },
  {
    postal_code: "72401",
    zip_name: "jonesboro, ar",
  },
  {
    postal_code: "29801",
    zip_name: "aiken, sc",
  },
  {
    postal_code: "35978",
    zip_name: "henagar, al",
  },
  {
    postal_code: "62268",
    zip_name: "oakdale, il",
  },
  {
    postal_code: "35974",
    zip_name: "geraldine, al",
  },
  {
    postal_code: "65768",
    zip_name: "vanzant, mo",
  },
  {
    postal_code: "80421",
    zip_name: "bailey, co",
  },
  {
    postal_code: "40856",
    zip_name: "miracle, ky",
  },
  {
    postal_code: "73642",
    zip_name: "durham, ok",
  },
  {
    postal_code: "52644",
    zip_name: "mount union, ia",
  },
  {
    postal_code: "60969",
    zip_name: "union hill, il",
  },
  {
    postal_code: "18427",
    zip_name: "hamlin, pa",
  },
  {
    postal_code: "83636",
    zip_name: "letha, id",
  },
  {
    postal_code: "83602",
    zip_name: "banks, id",
  },
  {
    postal_code: "75412",
    zip_name: "bagwell, tx",
  },
  {
    postal_code: "71847",
    zip_name: "mc caskill, ar",
  },
  {
    postal_code: "66853",
    zip_name: "hamilton, ks",
  },
  {
    postal_code: "36351",
    zip_name: "new brockton, al",
  },
  {
    postal_code: "53235",
    zip_name: "saint francis, wi",
  },
  {
    postal_code: "99923",
    zip_name: "hyder, ak",
  },
  {
    postal_code: "27242",
    zip_name: "eagle springs, nc",
  },
  {
    postal_code: "45715",
    zip_name: "beverly, oh",
  },
  {
    postal_code: "48065",
    zip_name: "romeo, mi",
  },
  {
    postal_code: "67005",
    zip_name: "arkansas city, ks",
  },
  {
    postal_code: "99348",
    zip_name: "prescott, wa",
  },
  {
    postal_code: "15750",
    zip_name: "josephine, pa",
  },
  {
    postal_code: "47833",
    zip_name: "bowling green, in",
  },
  {
    postal_code: "98614",
    zip_name: "chinook, wa",
  },
  {
    postal_code: "16835",
    zip_name: "fleming, pa",
  },
  {
    postal_code: "35207",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "51234",
    zip_name: "boyden, ia",
  },
  {
    postal_code: "49272",
    zip_name: "pleasant lake, mi",
  },
  {
    postal_code: "64861",
    zip_name: "rocky comfort, mo",
  },
  {
    postal_code: "58523",
    zip_name: "beulah, nd",
  },
  {
    postal_code: "99131",
    zip_name: "gifford, wa",
  },
  {
    postal_code: "61422",
    zip_name: "bushnell, il",
  },
  {
    postal_code: "25028",
    zip_name: "bob white, wv",
  },
  {
    postal_code: "96112",
    zip_name: "fort bidwell, ca",
  },
  {
    postal_code: "29546",
    zip_name: "gresham, sc",
  },
  {
    postal_code: "66413",
    zip_name: "burlingame, ks",
  },
  {
    postal_code: "47390",
    zip_name: "union city, in",
  },
  {
    postal_code: "86438",
    zip_name: "yucca, az",
  },
  {
    postal_code: "6380",
    zip_name: "taftville, ct",
  },
  {
    postal_code: "73459",
    zip_name: "thackerville, ok",
  },
  {
    postal_code: "83355",
    zip_name: "wendell, id",
  },
  {
    postal_code: "1349",
    zip_name: "millers falls, ma",
  },
  {
    postal_code: "58051",
    zip_name: "kindred, nd",
  },
  {
    postal_code: "7632",
    zip_name: "englewood cliffs, nj",
  },
  {
    postal_code: "30105",
    zip_name: "armuchee, ga",
  },
  {
    postal_code: "29638",
    zip_name: "donalds, sc",
  },
  {
    postal_code: "46793",
    zip_name: "waterloo, in",
  },
  {
    postal_code: "35648",
    zip_name: "lexington, al",
  },
  {
    postal_code: "36750",
    zip_name: "maplesville, al",
  },
  {
    postal_code: "27866",
    zip_name: "pleasant hill, nc",
  },
  {
    postal_code: "71109",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "87565",
    zip_name: "san jose, nm",
  },
  {
    postal_code: "73018",
    zip_name: "chickasha, ok",
  },
  {
    postal_code: "49301",
    zip_name: "ada, mi",
  },
  {
    postal_code: "83552",
    zip_name: "stites, id",
  },
  {
    postal_code: "14174",
    zip_name: "youngstown, ny",
  },
  {
    postal_code: "15054",
    zip_name: "langeloth, pa",
  },
  {
    postal_code: "35060",
    zip_name: "docena, al",
  },
  {
    postal_code: "49345",
    zip_name: "sparta, mi",
  },
  {
    postal_code: "81320",
    zip_name: "cahone, co",
  },
  {
    postal_code: "71049",
    zip_name: "logansport, la",
  },
  {
    postal_code: "31801",
    zip_name: "box springs, ga",
  },
  {
    postal_code: "67834",
    zip_name: "bucklin, ks",
  },
  {
    postal_code: "35578",
    zip_name: "nauvoo, al",
  },
  {
    postal_code: "29107",
    zip_name: "neeses, sc",
  },
  {
    postal_code: "24983",
    zip_name: "union, wv",
  },
  {
    postal_code: "72835",
    zip_name: "delaware, ar",
  },
  {
    postal_code: "68454",
    zip_name: "unadilla, ne",
  },
  {
    postal_code: "7643",
    zip_name: "little ferry, nj",
  },
  {
    postal_code: "70734",
    zip_name: "geismar, la",
  },
  {
    postal_code: "46616",
    zip_name: "south bend, in",
  },
  {
    postal_code: "12851",
    zip_name: "minerva, ny",
  },
  {
    postal_code: "72721",
    zip_name: "combs, ar",
  },
  {
    postal_code: "23071",
    zip_name: "hartfield, va",
  },
  {
    postal_code: "30259",
    zip_name: "moreland, ga",
  },
  {
    postal_code: "37375",
    zip_name: "sewanee, tn",
  },
  {
    postal_code: "59446",
    zip_name: "geraldine, mt",
  },
  {
    postal_code: "57337",
    zip_name: "fedora, sd",
  },
  {
    postal_code: "82711",
    zip_name: "alva, wy",
  },
  {
    postal_code: "47556",
    zip_name: "mariah hill, in",
  },
  {
    postal_code: "68667",
    zip_name: "surprise, ne",
  },
  {
    postal_code: "25878",
    zip_name: "midway, wv",
  },
  {
    postal_code: "26838",
    zip_name: "milam, wv",
  },
  {
    postal_code: "84076",
    zip_name: "tridell, ut",
  },
  {
    postal_code: "53506",
    zip_name: "avoca, wi",
  },
  {
    postal_code: "84667",
    zip_name: "wales, ut",
  },
  {
    postal_code: "22723",
    zip_name: "hood, va",
  },
  {
    postal_code: "1242",
    zip_name: "lenox dale, ma",
  },
  {
    postal_code: "26675",
    zip_name: "keslers cross lanes, wv",
  },
  {
    postal_code: "15783",
    zip_name: "west lebanon, pa",
  },
  {
    postal_code: "58713",
    zip_name: "bantry, nd",
  },
  {
    postal_code: "50561",
    zip_name: "lytton, ia",
  },
  {
    postal_code: "39840",
    zip_name: "cuthbert, ga",
  },
  {
    postal_code: "39772",
    zip_name: "weir, ms",
  },
  {
    postal_code: "63445",
    zip_name: "kahoka, mo",
  },
  {
    postal_code: "69148",
    zip_name: "lisco, ne",
  },
  {
    postal_code: "19536",
    zip_name: "lyon station, pa",
  },
  {
    postal_code: "95972",
    zip_name: "rackerby, ca",
  },
  {
    postal_code: "12008",
    zip_name: "alplaus, ny",
  },
  {
    postal_code: "26767",
    zip_name: "wiley ford, wv",
  },
  {
    postal_code: "88314",
    zip_name: "bent, nm",
  },
  {
    postal_code: "82332",
    zip_name: "savery, wy",
  },
  {
    postal_code: "62476",
    zip_name: "west salem, il",
  },
  {
    postal_code: "98267",
    zip_name: "marblemount, wa",
  },
  {
    postal_code: "48466",
    zip_name: "peck, mi",
  },
  {
    postal_code: "29104",
    zip_name: "mayesville, sc",
  },
  {
    postal_code: "66016",
    zip_name: "cummings, ks",
  },
  {
    postal_code: "72437",
    zip_name: "lake city, ar",
  },
  {
    postal_code: "41649",
    zip_name: "martin, ky",
  },
  {
    postal_code: "77419",
    zip_name: "blessing, tx",
  },
  {
    postal_code: "36852",
    zip_name: "cusseta, al",
  },
  {
    postal_code: "47175",
    zip_name: "taswell, in",
  },
  {
    postal_code: "36767",
    zip_name: "orrville, al",
  },
  {
    postal_code: "47142",
    zip_name: "mauckport, in",
  },
  {
    postal_code: "3579",
    zip_name: "errol, nh",
  },
  {
    postal_code: "83543",
    zip_name: "nezperce, id",
  },
  {
    postal_code: "96115",
    zip_name: "lake city, ca",
  },
  {
    postal_code: "67127",
    zip_name: "protection, ks",
  },
  {
    postal_code: "48062",
    zip_name: "richmond, mi",
  },
  {
    postal_code: "73772",
    zip_name: "watonga, ok",
  },
  {
    postal_code: "65656",
    zip_name: "galena, mo",
  },
  {
    postal_code: "49820",
    zip_name: "curtis, mi",
  },
  {
    postal_code: "13614",
    zip_name: "brier hill, ny",
  },
  {
    postal_code: "97843",
    zip_name: "ione, or",
  },
  {
    postal_code: "50632",
    zip_name: "garwin, ia",
  },
  {
    postal_code: "96010",
    zip_name: "big bar, ca",
  },
  {
    postal_code: "50630",
    zip_name: "fredericksburg, ia",
  },
  {
    postal_code: "14614",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "14427",
    zip_name: "castile, ny",
  },
  {
    postal_code: "73139",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "12874",
    zip_name: "silver bay, ny",
  },
  {
    postal_code: "7407",
    zip_name: "elmwood park, nj",
  },
  {
    postal_code: "67204",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "16627",
    zip_name: "coalport, pa",
  },
  {
    postal_code: "97532",
    zip_name: "merlin, or",
  },
  {
    postal_code: "54423",
    zip_name: "custer, wi",
  },
  {
    postal_code: "95615",
    zip_name: "courtland, ca",
  },
  {
    postal_code: "48458",
    zip_name: "mount morris, mi",
  },
  {
    postal_code: "98577",
    zip_name: "raymond, wa",
  },
  {
    postal_code: "45788",
    zip_name: "whipple, oh",
  },
  {
    postal_code: "46406",
    zip_name: "gary, in",
  },
  {
    postal_code: "73036",
    zip_name: "el reno, ok",
  },
  {
    postal_code: "97874",
    zip_name: "spray, or",
  },
  {
    postal_code: "75852",
    zip_name: "midway, tx",
  },
  {
    postal_code: "57032",
    zip_name: "harrisburg, sd",
  },
  {
    postal_code: "26278",
    zip_name: "mabie, wv",
  },
  {
    postal_code: "96046",
    zip_name: "hyampom, ca",
  },
  {
    postal_code: "62547",
    zip_name: "mount auburn, il",
  },
  {
    postal_code: "15522",
    zip_name: "bedford, pa",
  },
  {
    postal_code: "48070",
    zip_name: "huntington woods, mi",
  },
  {
    postal_code: "46614",
    zip_name: "south bend, in",
  },
  {
    postal_code: "61840",
    zip_name: "dewey, il",
  },
  {
    postal_code: "78011",
    zip_name: "charlotte, tx",
  },
  {
    postal_code: "24916",
    zip_name: "asbury, wv",
  },
  {
    postal_code: "93524",
    zip_name: "edwards, ca",
  },
  {
    postal_code: "23027",
    zip_name: "cartersville, va",
  },
  {
    postal_code: "37048",
    zip_name: "cottontown, tn",
  },
  {
    postal_code: "71854",
    zip_name: "texarkana, ar",
  },
  {
    postal_code: "27016",
    zip_name: "danbury, nc",
  },
  {
    postal_code: "98855",
    zip_name: "tonasket, wa",
  },
  {
    postal_code: "59226",
    zip_name: "froid, mt",
  },
  {
    postal_code: "49319",
    zip_name: "cedar springs, mi",
  },
  {
    postal_code: "7662",
    zip_name: "rochelle park, nj",
  },
  {
    postal_code: "71018",
    zip_name: "cotton valley, la",
  },
  {
    postal_code: "27890",
    zip_name: "weldon, nc",
  },
  {
    postal_code: "12530",
    zip_name: "hollowville, ny",
  },
  {
    postal_code: "98312",
    zip_name: "bremerton, wa",
  },
  {
    postal_code: "61261",
    zip_name: "lyndon, il",
  },
  {
    postal_code: "56291",
    zip_name: "taunton, mn",
  },
  {
    postal_code: "13751",
    zip_name: "davenport center, ny",
  },
  {
    postal_code: "32535",
    zip_name: "century, fl",
  },
  {
    postal_code: "63537",
    zip_name: "edina, mo",
  },
  {
    postal_code: "29009",
    zip_name: "bethune, sc",
  },
  {
    postal_code: "65614",
    zip_name: "bradleyville, mo",
  },
  {
    postal_code: "42516",
    zip_name: "bethelridge, ky",
  },
  {
    postal_code: "6262",
    zip_name: "quinebaug, ct",
  },
  {
    postal_code: "57631",
    zip_name: "glenham, sd",
  },
  {
    postal_code: "98421",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "76518",
    zip_name: "buckholts, tx",
  },
  {
    postal_code: "61059",
    zip_name: "nora, il",
  },
  {
    postal_code: "27826",
    zip_name: "fairfield, nc",
  },
  {
    postal_code: "13153",
    zip_name: "skaneateles falls, ny",
  },
  {
    postal_code: "72524",
    zip_name: "cord, ar",
  },
  {
    postal_code: "21641",
    zip_name: "hillsboro, md",
  },
  {
    postal_code: "63030",
    zip_name: "fletcher, mo",
  },
  {
    postal_code: "56748",
    zip_name: "plummer, mn",
  },
  {
    postal_code: "62214",
    zip_name: "addieville, il",
  },
  {
    postal_code: "47017",
    zip_name: "cross plains, in",
  },
  {
    postal_code: "51545",
    zip_name: "little sioux, ia",
  },
  {
    postal_code: "22964",
    zip_name: "piney river, va",
  },
  {
    postal_code: "17742",
    zip_name: "lairdsville, pa",
  },
  {
    postal_code: "38838",
    zip_name: "dennis, ms",
  },
  {
    postal_code: "17077",
    zip_name: "ono, pa",
  },
  {
    postal_code: "75450",
    zip_name: "lake creek, tx",
  },
  {
    postal_code: "98587",
    zip_name: "taholah, wa",
  },
  {
    postal_code: "78536",
    zip_name: "delmita, tx",
  },
  {
    postal_code: "19009",
    zip_name: "bryn athyn, pa",
  },
  {
    postal_code: "67355",
    zip_name: "niotaze, ks",
  },
  {
    postal_code: "74369",
    zip_name: "welch, ok",
  },
  {
    postal_code: "67340",
    zip_name: "dearing, ks",
  },
  {
    postal_code: "14752",
    zip_name: "lily dale, ny",
  },
  {
    postal_code: "87045",
    zip_name: "prewitt, nm",
  },
  {
    postal_code: "67438",
    zip_name: "durham, ks",
  },
  {
    postal_code: "47997",
    zip_name: "yeoman, in",
  },
  {
    postal_code: "75661",
    zip_name: "karnack, tx",
  },
  {
    postal_code: "18332",
    zip_name: "henryville, pa",
  },
  {
    postal_code: "98373",
    zip_name: "puyallup, wa",
  },
  {
    postal_code: "43434",
    zip_name: "harbor view, oh",
  },
  {
    postal_code: "36604",
    zip_name: "mobile, al",
  },
  {
    postal_code: "48832",
    zip_name: "elwell, mi",
  },
  {
    postal_code: "29210",
    zip_name: "columbia, sc",
  },
  {
    postal_code: "83440",
    zip_name: "rexburg, id",
  },
  {
    postal_code: "47952",
    zip_name: "kingman, in",
  },
  {
    postal_code: "54443",
    zip_name: "junction city, wi",
  },
  {
    postal_code: "24281",
    zip_name: "rose hill, va",
  },
  {
    postal_code: "72584",
    zip_name: "violet hill, ar",
  },
  {
    postal_code: "35634",
    zip_name: "florence, al",
  },
  {
    postal_code: "14475",
    zip_name: "ionia, ny",
  },
  {
    postal_code: "40995",
    zip_name: "trosper, ky",
  },
  {
    postal_code: "43759",
    zip_name: "morristown, oh",
  },
  {
    postal_code: "69220",
    zip_name: "sparks, ne",
  },
  {
    postal_code: "74531",
    zip_name: "calvin, ok",
  },
  {
    postal_code: "80861",
    zip_name: "vona, co",
  },
  {
    postal_code: "48370",
    zip_name: "oxford, mi",
  },
  {
    postal_code: "72365",
    zip_name: "marked tree, ar",
  },
  {
    postal_code: "73028",
    zip_name: "crescent, ok",
  },
  {
    postal_code: "43030",
    zip_name: "jacksontown, oh",
  },
  {
    postal_code: "20624",
    zip_name: "clements, md",
  },
  {
    postal_code: "77663",
    zip_name: "village mills, tx",
  },
  {
    postal_code: "73650",
    zip_name: "hammon, ok",
  },
  {
    postal_code: "32443",
    zip_name: "greenwood, fl",
  },
  {
    postal_code: "72440",
    zip_name: "lynn, ar",
  },
  {
    postal_code: "26836",
    zip_name: "moorefield, wv",
  },
  {
    postal_code: "15053",
    zip_name: "joffre, pa",
  },
  {
    postal_code: "71222",
    zip_name: "bernice, la",
  },
  {
    postal_code: "22650",
    zip_name: "rileyville, va",
  },
  {
    postal_code: "41777",
    zip_name: "yeaddiss, ky",
  },
  {
    postal_code: "4041",
    zip_name: "hiram, me",
  },
  {
    postal_code: "97630",
    zip_name: "lakeview, or",
  },
  {
    postal_code: "16161",
    zip_name: "wheatland, pa",
  },
  {
    postal_code: "87122",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "95551",
    zip_name: "loleta, ca",
  },
  {
    postal_code: "14203",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "67106",
    zip_name: "milton, ks",
  },
  {
    postal_code: "58474",
    zip_name: "oakes, nd",
  },
  {
    postal_code: "23457",
    zip_name: "virginia beach, va",
  },
  {
    postal_code: "24165",
    zip_name: "spencer, va",
  },
  {
    postal_code: "71264",
    zip_name: "oak ridge, la",
  },
  {
    postal_code: "18063",
    zip_name: "martins creek, pa",
  },
  {
    postal_code: "12849",
    zip_name: "middle granville, ny",
  },
  {
    postal_code: "49342",
    zip_name: "rodney, mi",
  },
  {
    postal_code: "31331",
    zip_name: "townsend, ga",
  },
  {
    postal_code: "67227",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "39629",
    zip_name: "bogue chitto, ms",
  },
  {
    postal_code: "15688",
    zip_name: "tarrs, pa",
  },
  {
    postal_code: "60920",
    zip_name: "campus, il",
  },
  {
    postal_code: "78663",
    zip_name: "round mountain, tx",
  },
  {
    postal_code: "54135",
    zip_name: "keshena, wi",
  },
  {
    postal_code: "65756",
    zip_name: "stotts city, mo",
  },
  {
    postal_code: "83610",
    zip_name: "cambridge, id",
  },
  {
    postal_code: "12759",
    zip_name: "loch sheldrake, ny",
  },
  {
    postal_code: "10595",
    zip_name: "valhalla, ny",
  },
  {
    postal_code: "78933",
    zip_name: "cat spring, tx",
  },
  {
    postal_code: "38242",
    zip_name: "paris, tn",
  },
  {
    postal_code: "99341",
    zip_name: "lind, wa",
  },
  {
    postal_code: "48613",
    zip_name: "bentley, mi",
  },
  {
    postal_code: "62684",
    zip_name: "sherman, il",
  },
  {
    postal_code: "24325",
    zip_name: "dugspur, va",
  },
  {
    postal_code: "79040",
    zip_name: "gruver, tx",
  },
  {
    postal_code: "28663",
    zip_name: "piney creek, nc",
  },
  {
    postal_code: "25971",
    zip_name: "lerona, wv",
  },
  {
    postal_code: "40012",
    zip_name: "chaplin, ky",
  },
  {
    postal_code: "67563",
    zip_name: "offerle, ks",
  },
  {
    postal_code: "39095",
    zip_name: "lexington, ms",
  },
  {
    postal_code: "61468",
    zip_name: "ophiem, il",
  },
  {
    postal_code: "61844",
    zip_name: "fithian, il",
  },
  {
    postal_code: "64161",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "37127",
    zip_name: "murfreesboro, tn",
  },
  {
    postal_code: "24839",
    zip_name: "hanover, wv",
  },
  {
    postal_code: "53033",
    zip_name: "hubertus, wi",
  },
  {
    postal_code: "8314",
    zip_name: "delmont, nj",
  },
  {
    postal_code: "14711",
    zip_name: "belfast, ny",
  },
  {
    postal_code: "24656",
    zip_name: "vansant, va",
  },
  {
    postal_code: "12956",
    zip_name: "mineville, ny",
  },
  {
    postal_code: "15470",
    zip_name: "ohiopyle, pa",
  },
  {
    postal_code: "36749",
    zip_name: "jones, al",
  },
  {
    postal_code: "74734",
    zip_name: "eagletown, ok",
  },
  {
    postal_code: "79845",
    zip_name: "presidio, tx",
  },
  {
    postal_code: "97620",
    zip_name: "adel, or",
  },
  {
    postal_code: "99756",
    zip_name: "manley hot springs, ak",
  },
  {
    postal_code: "42453",
    zip_name: "saint charles, ky",
  },
  {
    postal_code: "68720",
    zip_name: "brunswick, ne",
  },
  {
    postal_code: "13103",
    zip_name: "mallory, ny",
  },
  {
    postal_code: "17887",
    zip_name: "white deer, pa",
  },
  {
    postal_code: "86021",
    zip_name: "colorado city, az",
  },
  {
    postal_code: "68766",
    zip_name: "page, ne",
  },
  {
    postal_code: "66780",
    zip_name: "walnut, ks",
  },
  {
    postal_code: "99557",
    zip_name: "aniak, ak",
  },
  {
    postal_code: "47574",
    zip_name: "rome, in",
  },
  {
    postal_code: "4548",
    zip_name: "georgetown, me",
  },
  {
    postal_code: "79788",
    zip_name: "wickett, tx",
  },
  {
    postal_code: "47351",
    zip_name: "kennard, in",
  },
  {
    postal_code: "68351",
    zip_name: "exeter, ne",
  },
  {
    postal_code: "31098",
    zip_name: "warner robins, ga",
  },
  {
    postal_code: "65001",
    zip_name: "argyle, mo",
  },
  {
    postal_code: "75481",
    zip_name: "sulphur bluff, tx",
  },
  {
    postal_code: "72134",
    zip_name: "roe, ar",
  },
  {
    postal_code: "81522",
    zip_name: "gateway, co",
  },
  {
    postal_code: "5448",
    zip_name: "east fairfield, vt",
  },
  {
    postal_code: "39201",
    zip_name: "jackson, ms",
  },
  {
    postal_code: "55979",
    zip_name: "utica, mn",
  },
  {
    postal_code: "56376",
    zip_name: "saint martin, mn",
  },
  {
    postal_code: "15555",
    zip_name: "quecreek, pa",
  },
  {
    postal_code: "36524",
    zip_name: "coffeeville, al",
  },
  {
    postal_code: "65282",
    zip_name: "santa fe, mo",
  },
  {
    postal_code: "61338",
    zip_name: "manlius, il",
  },
  {
    postal_code: "51055",
    zip_name: "sloan, ia",
  },
  {
    postal_code: "56581",
    zip_name: "shelly, mn",
  },
  {
    postal_code: "54963",
    zip_name: "omro, wi",
  },
  {
    postal_code: "76633",
    zip_name: "china spring, tx",
  },
  {
    postal_code: "39834",
    zip_name: "climax, ga",
  },
  {
    postal_code: "4662",
    zip_name: "northeast harbor, me",
  },
  {
    postal_code: "63932",
    zip_name: "broseley, mo",
  },
  {
    postal_code: "27379",
    zip_name: "yanceyville, nc",
  },
  {
    postal_code: "98225",
    zip_name: "bellingham, wa",
  },
  {
    postal_code: "54844",
    zip_name: "herbster, wi",
  },
  {
    postal_code: "58577",
    zip_name: "washburn, nd",
  },
  {
    postal_code: "32066",
    zip_name: "mayo, fl",
  },
  {
    postal_code: "98837",
    zip_name: "moses lake, wa",
  },
  {
    postal_code: "57385",
    zip_name: "woonsocket, sd",
  },
  {
    postal_code: "39360",
    zip_name: "shubuta, ms",
  },
  {
    postal_code: "51573",
    zip_name: "stanton, ia",
  },
  {
    postal_code: "31763",
    zip_name: "leesburg, ga",
  },
  {
    postal_code: "98146",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "10597",
    zip_name: "waccabuc, ny",
  },
  {
    postal_code: "63866",
    zip_name: "marston, mo",
  },
  {
    postal_code: "41817",
    zip_name: "garner, ky",
  },
  {
    postal_code: "45657",
    zip_name: "otway, oh",
  },
  {
    postal_code: "50432",
    zip_name: "crystal lake, ia",
  },
  {
    postal_code: "4488",
    zip_name: "stetson, me",
  },
  {
    postal_code: "98311",
    zip_name: "bremerton, wa",
  },
  {
    postal_code: "14067",
    zip_name: "gasport, ny",
  },
  {
    postal_code: "66615",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "7508",
    zip_name: "haledon, nj",
  },
  {
    postal_code: "57017",
    zip_name: "colman, sd",
  },
  {
    postal_code: "16662",
    zip_name: "martinsburg, pa",
  },
  {
    postal_code: "4961",
    zip_name: "new portland, me",
  },
  {
    postal_code: "47874",
    zip_name: "rosedale, in",
  },
  {
    postal_code: "45898",
    zip_name: "willshire, oh",
  },
  {
    postal_code: "26321",
    zip_name: "alum bridge, wv",
  },
  {
    postal_code: "71048",
    zip_name: "lisbon, la",
  },
  {
    postal_code: "51530",
    zip_name: "earling, ia",
  },
  {
    postal_code: "70764",
    zip_name: "plaquemine, la",
  },
  {
    postal_code: "79745",
    zip_name: "kermit, tx",
  },
  {
    postal_code: "26282",
    zip_name: "monterville, wv",
  },
  {
    postal_code: "47862",
    zip_name: "montezuma, in",
  },
  {
    postal_code: "7068",
    zip_name: "roseland, nj",
  },
  {
    postal_code: "14051",
    zip_name: "east amherst, ny",
  },
  {
    postal_code: "44619",
    zip_name: "damascus, oh",
  },
  {
    postal_code: "19710",
    zip_name: "montchanin, de",
  },
  {
    postal_code: "49450",
    zip_name: "pullman, mi",
  },
  {
    postal_code: "13680",
    zip_name: "rensselaer falls, ny",
  },
  {
    postal_code: "64755",
    zip_name: "jasper, mo",
  },
  {
    postal_code: "1037",
    zip_name: "hardwick, ma",
  },
  {
    postal_code: "25125",
    zip_name: "lizemores, wv",
  },
  {
    postal_code: "95934",
    zip_name: "crescent mills, ca",
  },
  {
    postal_code: "99501",
    zip_name: "anchorage, ak",
  },
  {
    postal_code: "12762",
    zip_name: "mongaup valley, ny",
  },
  {
    postal_code: "26610",
    zip_name: "birch river, wv",
  },
  {
    postal_code: "56637",
    zip_name: "talmoon, mn",
  },
  {
    postal_code: "24918",
    zip_name: "ballard, wv",
  },
  {
    postal_code: "36010",
    zip_name: "brundidge, al",
  },
  {
    postal_code: "7055",
    zip_name: "passaic, nj",
  },
  {
    postal_code: "62837",
    zip_name: "fairfield, il",
  },
  {
    postal_code: "12751",
    zip_name: "kiamesha lake, ny",
  },
  {
    postal_code: "30436",
    zip_name: "lyons, ga",
  },
  {
    postal_code: "21645",
    zip_name: "kennedyville, md",
  },
  {
    postal_code: "67214",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "38105",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "62349",
    zip_name: "loraine, il",
  },
  {
    postal_code: "72932",
    zip_name: "cedarville, ar",
  },
  {
    postal_code: "54559",
    zip_name: "saxon, wi",
  },
  {
    postal_code: "82215",
    zip_name: "hartville, wy",
  },
  {
    postal_code: "41840",
    zip_name: "neon, ky",
  },
  {
    postal_code: "68360",
    zip_name: "garland, ne",
  },
  {
    postal_code: "14874",
    zip_name: "pulteney, ny",
  },
  {
    postal_code: "71961",
    zip_name: "oden, ar",
  },
  {
    postal_code: "17563",
    zip_name: "peach bottom, pa",
  },
  {
    postal_code: "47163",
    zip_name: "otisco, in",
  },
  {
    postal_code: "40075",
    zip_name: "turners station, ky",
  },
  {
    postal_code: "39140",
    zip_name: "newhebron, ms",
  },
  {
    postal_code: "17306",
    zip_name: "bendersville, pa",
  },
  {
    postal_code: "15451",
    zip_name: "lake lynn, pa",
  },
  {
    postal_code: "56667",
    zip_name: "puposky, mn",
  },
  {
    postal_code: "71751",
    zip_name: "louann, ar",
  },
  {
    postal_code: "58259",
    zip_name: "michigan, nd",
  },
  {
    postal_code: "57427",
    zip_name: "bath, sd",
  },
  {
    postal_code: "31815",
    zip_name: "lumpkin, ga",
  },
  {
    postal_code: "8321",
    zip_name: "fortescue, nj",
  },
  {
    postal_code: "5652",
    zip_name: "eden, vt",
  },
  {
    postal_code: "59038",
    zip_name: "hysham, mt",
  },
  {
    postal_code: "37301",
    zip_name: "altamont, tn",
  },
  {
    postal_code: "74553",
    zip_name: "kiowa, ok",
  },
  {
    postal_code: "84637",
    zip_name: "kanosh, ut",
  },
  {
    postal_code: "64859",
    zip_name: "reeds, mo",
  },
  {
    postal_code: "66834",
    zip_name: "alta vista, ks",
  },
  {
    postal_code: "52739",
    zip_name: "conesville, ia",
  },
  {
    postal_code: "47612",
    zip_name: "cynthiana, in",
  },
  {
    postal_code: "18825",
    zip_name: "jackson, pa",
  },
  {
    postal_code: "66951",
    zip_name: "kensington, ks",
  },
  {
    postal_code: "49881",
    zip_name: "sagola, mi",
  },
  {
    postal_code: "15957",
    zip_name: "strongstown, pa",
  },
  {
    postal_code: "19730",
    zip_name: "odessa, de",
  },
  {
    postal_code: "50853",
    zip_name: "massena, ia",
  },
  {
    postal_code: "66431",
    zip_name: "harveyville, ks",
  },
  {
    postal_code: "57263",
    zip_name: "south shore, sd",
  },
  {
    postal_code: "72573",
    zip_name: "sage, ar",
  },
  {
    postal_code: "56042",
    zip_name: "hartland, mn",
  },
  {
    postal_code: "68343",
    zip_name: "dorchester, ne",
  },
  {
    postal_code: "67038",
    zip_name: "dexter, ks",
  },
  {
    postal_code: "68632",
    zip_name: "david city, ne",
  },
  {
    postal_code: "4359",
    zip_name: "south gardiner, me",
  },
  {
    postal_code: "78618",
    zip_name: "doss, tx",
  },
  {
    postal_code: "15420",
    zip_name: "cardale, pa",
  },
  {
    postal_code: "41553",
    zip_name: "phelps, ky",
  },
  {
    postal_code: "77482",
    zip_name: "van vleck, tx",
  },
  {
    postal_code: "68812",
    zip_name: "amherst, ne",
  },
  {
    postal_code: "14775",
    zip_name: "ripley, ny",
  },
  {
    postal_code: "59727",
    zip_name: "divide, mt",
  },
  {
    postal_code: "14846",
    zip_name: "hunt, ny",
  },
  {
    postal_code: "13124",
    zip_name: "north pitcher, ny",
  },
  {
    postal_code: "14884",
    zip_name: "swain, ny",
  },
  {
    postal_code: "67623",
    zip_name: "alton, ks",
  },
  {
    postal_code: "71419",
    zip_name: "converse, la",
  },
  {
    postal_code: "72394",
    zip_name: "widener, ar",
  },
  {
    postal_code: "35972",
    zip_name: "gallant, al",
  },
  {
    postal_code: "56128",
    zip_name: "edgerton, mn",
  },
  {
    postal_code: "36040",
    zip_name: "hayneville, al",
  },
  {
    postal_code: "34138",
    zip_name: "chokoloskee, fl",
  },
  {
    postal_code: "50251",
    zip_name: "sully, ia",
  },
  {
    postal_code: "50147",
    zip_name: "lineville, ia",
  },
  {
    postal_code: "51531",
    zip_name: "elk horn, ia",
  },
  {
    postal_code: "26690",
    zip_name: "swiss, wv",
  },
  {
    postal_code: "72073",
    zip_name: "humphrey, ar",
  },
  {
    postal_code: "4481",
    zip_name: "sebec, me",
  },
  {
    postal_code: "55339",
    zip_name: "hamburg, mn",
  },
  {
    postal_code: "99760",
    zip_name: "nenana, ak",
  },
  {
    postal_code: "59526",
    zip_name: "harlem, mt",
  },
  {
    postal_code: "65276",
    zip_name: "pilot grove, mo",
  },
  {
    postal_code: "51546",
    zip_name: "logan, ia",
  },
  {
    postal_code: "69345",
    zip_name: "harrisburg, ne",
  },
  {
    postal_code: "77853",
    zip_name: "dime box, tx",
  },
  {
    postal_code: "23964",
    zip_name: "red oak, va",
  },
  {
    postal_code: "17024",
    zip_name: "elliottsburg, pa",
  },
  {
    postal_code: "49812",
    zip_name: "carney, mi",
  },
  {
    postal_code: "68041",
    zip_name: "mead, ne",
  },
  {
    postal_code: "47124",
    zip_name: "greenville, in",
  },
  {
    postal_code: "72351",
    zip_name: "keiser, ar",
  },
  {
    postal_code: "93206",
    zip_name: "buttonwillow, ca",
  },
  {
    postal_code: "36611",
    zip_name: "mobile, al",
  },
  {
    postal_code: "83334",
    zip_name: "hansen, id",
  },
  {
    postal_code: "35751",
    zip_name: "hollytree, al",
  },
  {
    postal_code: "74727",
    zip_name: "boswell, ok",
  },
  {
    postal_code: "46763",
    zip_name: "laotto, in",
  },
  {
    postal_code: "56083",
    zip_name: "sanborn, mn",
  },
  {
    postal_code: "44878",
    zip_name: "shiloh, oh",
  },
  {
    postal_code: "24460",
    zip_name: "millboro, va",
  },
  {
    postal_code: "76885",
    zip_name: "valley spring, tx",
  },
  {
    postal_code: "31551",
    zip_name: "mershon, ga",
  },
  {
    postal_code: "50451",
    zip_name: "lakota, ia",
  },
  {
    postal_code: "57270",
    zip_name: "veblen, sd",
  },
  {
    postal_code: "21862",
    zip_name: "showell, md",
  },
  {
    postal_code: "67514",
    zip_name: "arlington, ks",
  },
  {
    postal_code: "1434",
    zip_name: "devens, ma",
  },
  {
    postal_code: "59454",
    zip_name: "kevin, mt",
  },
  {
    postal_code: "90743",
    zip_name: "surfside, ca",
  },
  {
    postal_code: "68637",
    zip_name: "ericson, ne",
  },
  {
    postal_code: "3583",
    zip_name: "jefferson, nh",
  },
  {
    postal_code: "57024",
    zip_name: "egan, sd",
  },
  {
    postal_code: "61358",
    zip_name: "rutland, il",
  },
  {
    postal_code: "36269",
    zip_name: "muscadine, al",
  },
  {
    postal_code: "74940",
    zip_name: "howe, ok",
  },
  {
    postal_code: "17088",
    zip_name: "schaefferstown, pa",
  },
  {
    postal_code: "59710",
    zip_name: "alder, mt",
  },
  {
    postal_code: "78608",
    zip_name: "briggs, tx",
  },
  {
    postal_code: "72333",
    zip_name: "elaine, ar",
  },
  {
    postal_code: "74366",
    zip_name: "spavinaw, ok",
  },
  {
    postal_code: "71029",
    zip_name: "gilliam, la",
  },
  {
    postal_code: "36046",
    zip_name: "lapine, al",
  },
  {
    postal_code: "62996",
    zip_name: "villa ridge, il",
  },
  {
    postal_code: "36587",
    zip_name: "wilmer, al",
  },
  {
    postal_code: "22820",
    zip_name: "criders, va",
  },
  {
    postal_code: "54530",
    zip_name: "hawkins, wi",
  },
  {
    postal_code: "15077",
    zip_name: "shippingport, pa",
  },
  {
    postal_code: "16260",
    zip_name: "vowinckel, pa",
  },
  {
    postal_code: "13428",
    zip_name: "palatine bridge, ny",
  },
  {
    postal_code: "79005",
    zip_name: "booker, tx",
  },
  {
    postal_code: "63458",
    zip_name: "newark, mo",
  },
  {
    postal_code: "59751",
    zip_name: "silver star, mt",
  },
  {
    postal_code: "87005",
    zip_name: "bluewater, nm",
  },
  {
    postal_code: "74880",
    zip_name: "weleetka, ok",
  },
  {
    postal_code: "63654",
    zip_name: "lesterville, mo",
  },
  {
    postal_code: "50652",
    zip_name: "lincoln, ia",
  },
  {
    postal_code: "31552",
    zip_name: "millwood, ga",
  },
  {
    postal_code: "70531",
    zip_name: "egan, la",
  },
  {
    postal_code: "73441",
    zip_name: "leon, ok",
  },
  {
    postal_code: "4216",
    zip_name: "andover, me",
  },
  {
    postal_code: "61777",
    zip_name: "wapella, il",
  },
  {
    postal_code: "53233",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "59447",
    zip_name: "geyser, mt",
  },
  {
    postal_code: "80474",
    zip_name: "rollinsville, co",
  },
  {
    postal_code: "41566",
    zip_name: "steele, ky",
  },
  {
    postal_code: "62984",
    zip_name: "shawneetown, il",
  },
  {
    postal_code: "15348",
    zip_name: "millsboro, pa",
  },
  {
    postal_code: "63952",
    zip_name: "mill spring, mo",
  },
  {
    postal_code: "75448",
    zip_name: "klondike, tx",
  },
  {
    postal_code: "49632",
    zip_name: "falmouth, mi",
  },
  {
    postal_code: "49255",
    zip_name: "montgomery, mi",
  },
  {
    postal_code: "39092",
    zip_name: "lake, ms",
  },
  {
    postal_code: "82934",
    zip_name: "granger, wy",
  },
  {
    postal_code: "56145",
    zip_name: "jeffers, mn",
  },
  {
    postal_code: "50843",
    zip_name: "cumberland, ia",
  },
  {
    postal_code: "14066",
    zip_name: "gainesville, ny",
  },
  {
    postal_code: "63735",
    zip_name: "bell city, mo",
  },
  {
    postal_code: "73056",
    zip_name: "marshall, ok",
  },
  {
    postal_code: "68636",
    zip_name: "elgin, ne",
  },
  {
    postal_code: "51105",
    zip_name: "sioux city, ia",
  },
  {
    postal_code: "29570",
    zip_name: "mc coll, sc",
  },
  {
    postal_code: "62811",
    zip_name: "bellmont, il",
  },
  {
    postal_code: "56227",
    zip_name: "correll, mn",
  },
  {
    postal_code: "35746",
    zip_name: "fackler, al",
  },
  {
    postal_code: "98385",
    zip_name: "south prairie, wa",
  },
  {
    postal_code: "27928",
    zip_name: "creswell, nc",
  },
  {
    postal_code: "58651",
    zip_name: "rhame, nd",
  },
  {
    postal_code: "18632",
    zip_name: "mildred, pa",
  },
  {
    postal_code: "61482",
    zip_name: "table grove, il",
  },
  {
    postal_code: "8212",
    zip_name: "cape may point, nj",
  },
  {
    postal_code: "67646",
    zip_name: "logan, ks",
  },
  {
    postal_code: "61929",
    zip_name: "hammond, il",
  },
  {
    postal_code: "52570",
    zip_name: "milton, ia",
  },
  {
    postal_code: "16030",
    zip_name: "eau claire, pa",
  },
  {
    postal_code: "14723",
    zip_name: "cherry creek, ny",
  },
  {
    postal_code: "83118",
    zip_name: "etna, wy",
  },
  {
    postal_code: "13342",
    zip_name: "garrattsville, ny",
  },
  {
    postal_code: "53808",
    zip_name: "dickeyville, wi",
  },
  {
    postal_code: "54846",
    zip_name: "high bridge, wi",
  },
  {
    postal_code: "76228",
    zip_name: "bellevue, tx",
  },
  {
    postal_code: "71855",
    zip_name: "ozan, ar",
  },
  {
    postal_code: "71027",
    zip_name: "frierson, la",
  },
  {
    postal_code: "62693",
    zip_name: "williamsville, il",
  },
  {
    postal_code: "64497",
    zip_name: "weatherby, mo",
  },
  {
    postal_code: "49833",
    zip_name: "little lake, mi",
  },
  {
    postal_code: "33001",
    zip_name: "long key, fl",
  },
  {
    postal_code: "36866",
    zip_name: "notasulga, al",
  },
  {
    postal_code: "61957",
    zip_name: "windsor, il",
  },
  {
    postal_code: "42715",
    zip_name: "breeding, ky",
  },
  {
    postal_code: "67882",
    zip_name: "wright, ks",
  },
  {
    postal_code: "42265",
    zip_name: "olmstead, ky",
  },
  {
    postal_code: "45339",
    zip_name: "ludlow falls, oh",
  },
  {
    postal_code: "24315",
    zip_name: "bland, va",
  },
  {
    postal_code: "4625",
    zip_name: "cranberry isles, me",
  },
  {
    postal_code: "66422",
    zip_name: "emmett, ks",
  },
  {
    postal_code: "59735",
    zip_name: "harrison, mt",
  },
  {
    postal_code: "81646",
    zip_name: "molina, co",
  },
  {
    postal_code: "99124",
    zip_name: "elmer city, wa",
  },
  {
    postal_code: "78113",
    zip_name: "falls city, tx",
  },
  {
    postal_code: "37337",
    zip_name: "grandview, tn",
  },
  {
    postal_code: "58220",
    zip_name: "cavalier, nd",
  },
  {
    postal_code: "12779",
    zip_name: "south fallsburg, ny",
  },
  {
    postal_code: "54826",
    zip_name: "comstock, wi",
  },
  {
    postal_code: "49247",
    zip_name: "hudson, mi",
  },
  {
    postal_code: "6387",
    zip_name: "wauregan, ct",
  },
  {
    postal_code: "74083",
    zip_name: "wann, ok",
  },
  {
    postal_code: "99356",
    zip_name: "roosevelt, wa",
  },
  {
    postal_code: "50120",
    zip_name: "haverhill, ia",
  },
  {
    postal_code: "63662",
    zip_name: "patton, mo",
  },
  {
    postal_code: "4417",
    zip_name: "burlington, me",
  },
  {
    postal_code: "50469",
    zip_name: "rockwell, ia",
  },
  {
    postal_code: "24991",
    zip_name: "williamsburg, wv",
  },
  {
    postal_code: "13143",
    zip_name: "red creek, ny",
  },
  {
    postal_code: "62219",
    zip_name: "beckemeyer, il",
  },
  {
    postal_code: "66856",
    zip_name: "lebo, ks",
  },
  {
    postal_code: "58768",
    zip_name: "norwich, nd",
  },
  {
    postal_code: "98243",
    zip_name: "deer harbor, wa",
  },
  {
    postal_code: "15661",
    zip_name: "loyalhanna, pa",
  },
  {
    postal_code: "57766",
    zip_name: "oral, sd",
  },
  {
    postal_code: "23883",
    zip_name: "surry, va",
  },
  {
    postal_code: "40806",
    zip_name: "baxter, ky",
  },
  {
    postal_code: "71959",
    zip_name: "newhope, ar",
  },
  {
    postal_code: "63469",
    zip_name: "shelbyville, mo",
  },
  {
    postal_code: "88020",
    zip_name: "animas, nm",
  },
  {
    postal_code: "51026",
    zip_name: "hornick, ia",
  },
  {
    postal_code: "71065",
    zip_name: "pleasant hill, la",
  },
  {
    postal_code: "4766",
    zip_name: "perham, me",
  },
  {
    postal_code: "79342",
    zip_name: "loop, tx",
  },
  {
    postal_code: "47138",
    zip_name: "lexington, in",
  },
  {
    postal_code: "22719",
    zip_name: "etlan, va",
  },
  {
    postal_code: "61544",
    zip_name: "london mills, il",
  },
  {
    postal_code: "13475",
    zip_name: "van hornesville, ny",
  },
  {
    postal_code: "1379",
    zip_name: "wendell, ma",
  },
  {
    postal_code: "92066",
    zip_name: "ranchita, ca",
  },
  {
    postal_code: "83414",
    zip_name: "alta, wy",
  },
  {
    postal_code: "72717",
    zip_name: "canehill, ar",
  },
  {
    postal_code: "68423",
    zip_name: "pleasant dale, ne",
  },
  {
    postal_code: "52323",
    zip_name: "oxford junction, ia",
  },
  {
    postal_code: "97621",
    zip_name: "beatty, or",
  },
  {
    postal_code: "36256",
    zip_name: "daviston, al",
  },
  {
    postal_code: "74764",
    zip_name: "valliant, ok",
  },
  {
    postal_code: "72532",
    zip_name: "evening shade, ar",
  },
  {
    postal_code: "47281",
    zip_name: "vallonia, in",
  },
  {
    postal_code: "39751",
    zip_name: "mantee, ms",
  },
  {
    postal_code: "26575",
    zip_name: "hundred, wv",
  },
  {
    postal_code: "83451",
    zip_name: "teton, id",
  },
  {
    postal_code: "62013",
    zip_name: "brussels, il",
  },
  {
    postal_code: "71082",
    zip_name: "vivian, la",
  },
  {
    postal_code: "58384",
    zip_name: "willow city, nd",
  },
  {
    postal_code: "3770",
    zip_name: "meriden, nh",
  },
  {
    postal_code: "56742",
    zip_name: "oklee, mn",
  },
  {
    postal_code: "44853",
    zip_name: "new riegel, oh",
  },
  {
    postal_code: "26543",
    zip_name: "osage, wv",
  },
  {
    postal_code: "24412",
    zip_name: "bacova, va",
  },
  {
    postal_code: "80463",
    zip_name: "mc coy, co",
  },
  {
    postal_code: "12007",
    zip_name: "alcove, ny",
  },
  {
    postal_code: "57365",
    zip_name: "oacoma, sd",
  },
  {
    postal_code: "98628",
    zip_name: "klickitat, wa",
  },
  {
    postal_code: "96117",
    zip_name: "litchfield, ca",
  },
  {
    postal_code: "35458",
    zip_name: "elrod, al",
  },
  {
    postal_code: "64790",
    zip_name: "walker, mo",
  },
  {
    postal_code: "25251",
    zip_name: "left hand, wv",
  },
  {
    postal_code: "18246",
    zip_name: "rock glen, pa",
  },
  {
    postal_code: "1344",
    zip_name: "erving, ma",
  },
  {
    postal_code: "10527",
    zip_name: "granite springs, ny",
  },
  {
    postal_code: "97441",
    zip_name: "gardiner, or",
  },
  {
    postal_code: "97842",
    zip_name: "imnaha, or",
  },
  {
    postal_code: "53080",
    zip_name: "saukville, wi",
  },
  {
    postal_code: "58053",
    zip_name: "lidgerwood, nd",
  },
  {
    postal_code: "82054",
    zip_name: "carpenter, wy",
  },
  {
    postal_code: "32336",
    zip_name: "lamont, fl",
  },
  {
    postal_code: "47359",
    zip_name: "montpelier, in",
  },
  {
    postal_code: "97450",
    zip_name: "langlois, or",
  },
  {
    postal_code: "57383",
    zip_name: "white lake, sd",
  },
  {
    postal_code: "83227",
    zip_name: "clayton, id",
  },
  {
    postal_code: "89316",
    zip_name: "eureka, nv",
  },
  {
    postal_code: "68758",
    zip_name: "newman grove, ne",
  },
  {
    postal_code: "68831",
    zip_name: "dannebrog, ne",
  },
  {
    postal_code: "47924",
    zip_name: "buck creek, in",
  },
  {
    postal_code: "56236",
    zip_name: "dumont, mn",
  },
  {
    postal_code: "97429",
    zip_name: "days creek, or",
  },
  {
    postal_code: "89017",
    zip_name: "hiko, nv",
  },
  {
    postal_code: "40171",
    zip_name: "union star, ky",
  },
  {
    postal_code: "67561",
    zip_name: "nickerson, ks",
  },
  {
    postal_code: "30177",
    zip_name: "tate, ga",
  },
  {
    postal_code: "31025",
    zip_name: "elko, ga",
  },
  {
    postal_code: "31901",
    zip_name: "columbus, ga",
  },
  {
    postal_code: "75943",
    zip_name: "douglass, tx",
  },
  {
    postal_code: "41774",
    zip_name: "viper, ky",
  },
  {
    postal_code: "66518",
    zip_name: "oketo, ks",
  },
  {
    postal_code: "23518",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "54862",
    zip_name: "ojibwa, wi",
  },
  {
    postal_code: "80479",
    zip_name: "toponas, co",
  },
  {
    postal_code: "71237",
    zip_name: "epps, la",
  },
  {
    postal_code: "82060",
    zip_name: "hillsdale, wy",
  },
  {
    postal_code: "82723",
    zip_name: "osage, wy",
  },
  {
    postal_code: "55784",
    zip_name: "swan river, mn",
  },
  {
    postal_code: "17028",
    zip_name: "grantville, pa",
  },
  {
    postal_code: "56326",
    zip_name: "evansville, mn",
  },
  {
    postal_code: "75153",
    zip_name: "powell, tx",
  },
  {
    postal_code: "87024",
    zip_name: "jemez pueblo, nm",
  },
  {
    postal_code: "25033",
    zip_name: "buffalo, wv",
  },
  {
    postal_code: "16259",
    zip_name: "templeton, pa",
  },
  {
    postal_code: "59015",
    zip_name: "broadview, mt",
  },
  {
    postal_code: "31625",
    zip_name: "barney, ga",
  },
  {
    postal_code: "10518",
    zip_name: "cross river, ny",
  },
  {
    postal_code: "85192",
    zip_name: "winkelman, az",
  },
  {
    postal_code: "36471",
    zip_name: "peterman, al",
  },
  {
    postal_code: "52044",
    zip_name: "elkport, ia",
  },
  {
    postal_code: "72327",
    zip_name: "crawfordsville, ar",
  },
  {
    postal_code: "65250",
    zip_name: "franklin, mo",
  },
  {
    postal_code: "40903",
    zip_name: "artemus, ky",
  },
  {
    postal_code: "57331",
    zip_name: "dimock, sd",
  },
  {
    postal_code: "83271",
    zip_name: "rockland, id",
  },
  {
    postal_code: "64469",
    zip_name: "maysville, mo",
  },
  {
    postal_code: "70339",
    zip_name: "pierre part, la",
  },
  {
    postal_code: "60437",
    zip_name: "kinsman, il",
  },
  {
    postal_code: "41255",
    zip_name: "sitka, ky",
  },
  {
    postal_code: "65529",
    zip_name: "jerome, mo",
  },
  {
    postal_code: "73063",
    zip_name: "mulhall, ok",
  },
  {
    postal_code: "64783",
    zip_name: "schell city, mo",
  },
  {
    postal_code: "52638",
    zip_name: "middletown, ia",
  },
  {
    postal_code: "41314",
    zip_name: "booneville, ky",
  },
  {
    postal_code: "14839",
    zip_name: "greenwood, ny",
  },
  {
    postal_code: "16235",
    zip_name: "lucinda, pa",
  },
  {
    postal_code: "67442",
    zip_name: "falun, ks",
  },
  {
    postal_code: "59837",
    zip_name: "hall, mt",
  },
  {
    postal_code: "26234",
    zip_name: "rock cave, wv",
  },
  {
    postal_code: "61067",
    zip_name: "ridott, il",
  },
  {
    postal_code: "3443",
    zip_name: "chesterfield, nh",
  },
  {
    postal_code: "23967",
    zip_name: "saxe, va",
  },
  {
    postal_code: "67867",
    zip_name: "montezuma, ks",
  },
  {
    postal_code: "12489",
    zip_name: "wawarsing, ny",
  },
  {
    postal_code: "22643",
    zip_name: "markham, va",
  },
  {
    postal_code: "97480",
    zip_name: "swisshome, or",
  },
  {
    postal_code: "25839",
    zip_name: "fairdale, wv",
  },
  {
    postal_code: "52335",
    zip_name: "south english, ia",
  },
  {
    postal_code: "76841",
    zip_name: "fort mc kavett, tx",
  },
  {
    postal_code: "53172",
    zip_name: "south milwaukee, wi",
  },
  {
    postal_code: "15960",
    zip_name: "twin rocks, pa",
  },
  {
    postal_code: "17220",
    zip_name: "dry run, pa",
  },
  {
    postal_code: "89042",
    zip_name: "panaca, nv",
  },
  {
    postal_code: "39842",
    zip_name: "dawson, ga",
  },
  {
    postal_code: "24934",
    zip_name: "dunmore, wv",
  },
  {
    postal_code: "26563",
    zip_name: "carolina, wv",
  },
  {
    postal_code: "41836",
    zip_name: "mallie, ky",
  },
  {
    postal_code: "39867",
    zip_name: "morris, ga",
  },
  {
    postal_code: "50041",
    zip_name: "bradford, ia",
  },
  {
    postal_code: "43840",
    zip_name: "stone creek, oh",
  },
  {
    postal_code: "69042",
    zip_name: "stockville, ne",
  },
  {
    postal_code: "71675",
    zip_name: "wilmar, ar",
  },
  {
    postal_code: "13774",
    zip_name: "fishs eddy, ny",
  },
  {
    postal_code: "47836",
    zip_name: "bridgeton, in",
  },
  {
    postal_code: "56138",
    zip_name: "hills, mn",
  },
  {
    postal_code: "5873",
    zip_name: "west danville, vt",
  },
  {
    postal_code: "94972",
    zip_name: "valley ford, ca",
  },
  {
    postal_code: "64167",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "72040",
    zip_name: "des arc, ar",
  },
  {
    postal_code: "62464",
    zip_name: "stoy, il",
  },
  {
    postal_code: "62819",
    zip_name: "buckner, il",
  },
  {
    postal_code: "95527",
    zip_name: "burnt ranch, ca",
  },
  {
    postal_code: "86338",
    zip_name: "skull valley, az",
  },
  {
    postal_code: "47373",
    zip_name: "redkey, in",
  },
  {
    postal_code: "48728",
    zip_name: "curran, mi",
  },
  {
    postal_code: "10976",
    zip_name: "sparkill, ny",
  },
  {
    postal_code: "26377",
    zip_name: "jacksonburg, wv",
  },
  {
    postal_code: "71024",
    zip_name: "dubberly, la",
  },
  {
    postal_code: "15757",
    zip_name: "mahaffey, pa",
  },
  {
    postal_code: "21733",
    zip_name: "fairplay, md",
  },
  {
    postal_code: "58424",
    zip_name: "cleveland, nd",
  },
  {
    postal_code: "36036",
    zip_name: "grady, al",
  },
  {
    postal_code: "44506",
    zip_name: "youngstown, oh",
  },
  {
    postal_code: "72436",
    zip_name: "lafe, ar",
  },
  {
    postal_code: "47273",
    zip_name: "scipio, in",
  },
  {
    postal_code: "45682",
    zip_name: "south webster, oh",
  },
  {
    postal_code: "62551",
    zip_name: "niantic, il",
  },
  {
    postal_code: "59642",
    zip_name: "ringling, mt",
  },
  {
    postal_code: "10475",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "99691",
    zip_name: "nikolai, ak",
  },
  {
    postal_code: "95524",
    zip_name: "bayside, ca",
  },
  {
    postal_code: "62560",
    zip_name: "raymond, il",
  },
  {
    postal_code: "4680",
    zip_name: "steuben, me",
  },
  {
    postal_code: "12515",
    zip_name: "clintondale, ny",
  },
  {
    postal_code: "58341",
    zip_name: "harvey, nd",
  },
  {
    postal_code: "97751",
    zip_name: "paulina, or",
  },
  {
    postal_code: "63014",
    zip_name: "berger, mo",
  },
  {
    postal_code: "65046",
    zip_name: "jamestown, mo",
  },
  {
    postal_code: "50609",
    zip_name: "beaman, ia",
  },
  {
    postal_code: "51646",
    zip_name: "new market, ia",
  },
  {
    postal_code: "56170",
    zip_name: "ruthton, mn",
  },
  {
    postal_code: "72948",
    zip_name: "natural dam, ar",
  },
  {
    postal_code: "59721",
    zip_name: "cardwell, mt",
  },
  {
    postal_code: "13364",
    zip_name: "leonardsville, ny",
  },
  {
    postal_code: "5670",
    zip_name: "south barre, vt",
  },
  {
    postal_code: "36311",
    zip_name: "ariton, al",
  },
  {
    postal_code: "97389",
    zip_name: "tangent, or",
  },
  {
    postal_code: "68517",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "83464",
    zip_name: "leadore, id",
  },
  {
    postal_code: "71630",
    zip_name: "arkansas city, ar",
  },
  {
    postal_code: "73726",
    zip_name: "carmen, ok",
  },
  {
    postal_code: "62339",
    zip_name: "golden, il",
  },
  {
    postal_code: "15821",
    zip_name: "benezett, pa",
  },
  {
    postal_code: "25141",
    zip_name: "nebo, wv",
  },
  {
    postal_code: "84715",
    zip_name: "bicknell, ut",
  },
  {
    postal_code: "15715",
    zip_name: "big run, pa",
  },
  {
    postal_code: "72179",
    zip_name: "wilburn, ar",
  },
  {
    postal_code: "7656",
    zip_name: "park ridge, nj",
  },
  {
    postal_code: "57533",
    zip_name: "gregory, sd",
  },
  {
    postal_code: "81237",
    zip_name: "ohio city, co",
  },
  {
    postal_code: "61735",
    zip_name: "dewitt, il",
  },
  {
    postal_code: "72346",
    zip_name: "heth, ar",
  },
  {
    postal_code: "30445",
    zip_name: "mount vernon, ga",
  },
  {
    postal_code: "45156",
    zip_name: "neville, oh",
  },
  {
    postal_code: "72435",
    zip_name: "knobel, ar",
  },
  {
    postal_code: "38327",
    zip_name: "crump, tn",
  },
  {
    postal_code: "30111",
    zip_name: "clarkdale, ga",
  },
  {
    postal_code: "67042",
    zip_name: "el dorado, ks",
  },
  {
    postal_code: "43467",
    zip_name: "west millgrove, oh",
  },
  {
    postal_code: "62358",
    zip_name: "niota, il",
  },
  {
    postal_code: "52037",
    zip_name: "delmar, ia",
  },
  {
    postal_code: "46998",
    zip_name: "young america, in",
  },
  {
    postal_code: "71827",
    zip_name: "buckner, ar",
  },
  {
    postal_code: "63559",
    zip_name: "novinger, mo",
  },
  {
    postal_code: "16863",
    zip_name: "olanta, pa",
  },
  {
    postal_code: "4575",
    zip_name: "west boothbay harbor, me",
  },
  {
    postal_code: "60973",
    zip_name: "wellington, il",
  },
  {
    postal_code: "43842",
    zip_name: "trinway, oh",
  },
  {
    postal_code: "73753",
    zip_name: "kremlin, ok",
  },
  {
    postal_code: "29471",
    zip_name: "reevesville, sc",
  },
  {
    postal_code: "51350",
    zip_name: "melvin, ia",
  },
  {
    postal_code: "56654",
    zip_name: "loman, mn",
  },
  {
    postal_code: "62006",
    zip_name: "batchtown, il",
  },
  {
    postal_code: "13692",
    zip_name: "thousand island park, ny",
  },
  {
    postal_code: "21082",
    zip_name: "hydes, md",
  },
  {
    postal_code: "58544",
    zip_name: "hazelton, nd",
  },
  {
    postal_code: "53553",
    zip_name: "linden, wi",
  },
  {
    postal_code: "15538",
    zip_name: "fairhope, pa",
  },
  {
    postal_code: "72350",
    zip_name: "joiner, ar",
  },
  {
    postal_code: "36769",
    zip_name: "pine hill, al",
  },
  {
    postal_code: "43356",
    zip_name: "waldo, oh",
  },
  {
    postal_code: "53935",
    zip_name: "friesland, wi",
  },
  {
    postal_code: "64681",
    zip_name: "sumner, mo",
  },
  {
    postal_code: "41759",
    zip_name: "sassafras, ky",
  },
  {
    postal_code: "28766",
    zip_name: "penrose, nc",
  },
  {
    postal_code: "97722",
    zip_name: "diamond, or",
  },
  {
    postal_code: "99559",
    zip_name: "bethel, ak",
  },
  {
    postal_code: "17243",
    zip_name: "orbisonia, pa",
  },
  {
    postal_code: "24265",
    zip_name: "keokee, va",
  },
  {
    postal_code: "15466",
    zip_name: "newell, pa",
  },
  {
    postal_code: "75246",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "28392",
    zip_name: "tar heel, nc",
  },
  {
    postal_code: "38646",
    zip_name: "marks, ms",
  },
  {
    postal_code: "64461",
    zip_name: "hopkins, mo",
  },
  {
    postal_code: "73626",
    zip_name: "canute, ok",
  },
  {
    postal_code: "42649",
    zip_name: "strunk, ky",
  },
  {
    postal_code: "95939",
    zip_name: "elk creek, ca",
  },
  {
    postal_code: "47992",
    zip_name: "westpoint, in",
  },
  {
    postal_code: "18230",
    zip_name: "junedale, pa",
  },
  {
    postal_code: "8553",
    zip_name: "rocky hill, nj",
  },
  {
    postal_code: "72471",
    zip_name: "swifton, ar",
  },
  {
    postal_code: "68942",
    zip_name: "guide rock, ne",
  },
  {
    postal_code: "61471",
    zip_name: "raritan, il",
  },
  {
    postal_code: "17097",
    zip_name: "wiconisco, pa",
  },
  {
    postal_code: "50123",
    zip_name: "humeston, ia",
  },
  {
    postal_code: "95698",
    zip_name: "zamora, ca",
  },
  {
    postal_code: "50565",
    zip_name: "marathon, ia",
  },
  {
    postal_code: "16680",
    zip_name: "smithmill, pa",
  },
  {
    postal_code: "46545",
    zip_name: "mishawaka, in",
  },
  {
    postal_code: "4454",
    zip_name: "lambert lake, me",
  },
  {
    postal_code: "12970",
    zip_name: "paul smiths, ny",
  },
  {
    postal_code: "97310",
    zip_name: "salem, or",
  },
  {
    postal_code: "87940",
    zip_name: "rincon, nm",
  },
  {
    postal_code: "72749",
    zip_name: "morrow, ar",
  },
  {
    postal_code: "64487",
    zip_name: "skidmore, mo",
  },
  {
    postal_code: "14516",
    zip_name: "north rose, ny",
  },
  {
    postal_code: "15325",
    zip_name: "crucible, pa",
  },
  {
    postal_code: "57037",
    zip_name: "irene, sd",
  },
  {
    postal_code: "14024",
    zip_name: "bliss, ny",
  },
  {
    postal_code: "43939",
    zip_name: "mount pleasant, oh",
  },
  {
    postal_code: "4066",
    zip_name: "orrs island, me",
  },
  {
    postal_code: "61454",
    zip_name: "lomax, il",
  },
  {
    postal_code: "56726",
    zip_name: "greenbush, mn",
  },
  {
    postal_code: "63443",
    zip_name: "hunnewell, mo",
  },
  {
    postal_code: "59035",
    zip_name: "fort smith, mt",
  },
  {
    postal_code: "46150",
    zip_name: "manilla, in",
  },
  {
    postal_code: "62941",
    zip_name: "grand chain, il",
  },
  {
    postal_code: "51027",
    zip_name: "ireton, ia",
  },
  {
    postal_code: "63847",
    zip_name: "gibson, mo",
  },
  {
    postal_code: "71063",
    zip_name: "pelican, la",
  },
  {
    postal_code: "74630",
    zip_name: "billings, ok",
  },
  {
    postal_code: "72068",
    zip_name: "higginson, ar",
  },
  {
    postal_code: "21557",
    zip_name: "rawlings, md",
  },
  {
    postal_code: "49819",
    zip_name: "arnold, mi",
  },
  {
    postal_code: "73759",
    zip_name: "medford, ok",
  },
  {
    postal_code: "66608",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "64667",
    zip_name: "newtown, mo",
  },
  {
    postal_code: "46950",
    zip_name: "lucerne, in",
  },
  {
    postal_code: "79237",
    zip_name: "hedley, tx",
  },
  {
    postal_code: "83251",
    zip_name: "mackay, id",
  },
  {
    postal_code: "57430",
    zip_name: "britton, sd",
  },
  {
    postal_code: "31050",
    zip_name: "knoxville, ga",
  },
  {
    postal_code: "40115",
    zip_name: "custer, ky",
  },
  {
    postal_code: "4859",
    zip_name: "spruce head, me",
  },
  {
    postal_code: "74754",
    zip_name: "ringold, ok",
  },
  {
    postal_code: "66740",
    zip_name: "galesburg, ks",
  },
  {
    postal_code: "97377",
    zip_name: "shedd, or",
  },
  {
    postal_code: "18056",
    zip_name: "hereford, pa",
  },
  {
    postal_code: "64475",
    zip_name: "parnell, mo",
  },
  {
    postal_code: "72070",
    zip_name: "houston, ar",
  },
  {
    postal_code: "66512",
    zip_name: "meriden, ks",
  },
  {
    postal_code: "36523",
    zip_name: "coden, al",
  },
  {
    postal_code: "50532",
    zip_name: "duncombe, ia",
  },
  {
    postal_code: "98938",
    zip_name: "outlook, wa",
  },
  {
    postal_code: "25102",
    zip_name: "handley, wv",
  },
  {
    postal_code: "51034",
    zip_name: "mapleton, ia",
  },
  {
    postal_code: "74565",
    zip_name: "savanna, ok",
  },
  {
    postal_code: "76486",
    zip_name: "perrin, tx",
  },
  {
    postal_code: "31011",
    zip_name: "chauncey, ga",
  },
  {
    postal_code: "50540",
    zip_name: "fonda, ia",
  },
  {
    postal_code: "42083",
    zip_name: "tiline, ky",
  },
  {
    postal_code: "78880",
    zip_name: "rocksprings, tx",
  },
  {
    postal_code: "35456",
    zip_name: "duncanville, al",
  },
  {
    postal_code: "66554",
    zip_name: "randolph, ks",
  },
  {
    postal_code: "76859",
    zip_name: "menard, tx",
  },
  {
    postal_code: "80475",
    zip_name: "shawnee, co",
  },
  {
    postal_code: "30667",
    zip_name: "stephens, ga",
  },
  {
    postal_code: "71354",
    zip_name: "monterey, la",
  },
  {
    postal_code: "75413",
    zip_name: "bailey, tx",
  },
  {
    postal_code: "52594",
    zip_name: "unionville, ia",
  },
  {
    postal_code: "52585",
    zip_name: "richland, ia",
  },
  {
    postal_code: "39870",
    zip_name: "newton, ga",
  },
  {
    postal_code: "20838",
    zip_name: "barnesville, md",
  },
  {
    postal_code: "55967",
    zip_name: "racine, mn",
  },
  {
    postal_code: "38392",
    zip_name: "mercer, tn",
  },
  {
    postal_code: "50242",
    zip_name: "searsboro, ia",
  },
  {
    postal_code: "57262",
    zip_name: "sisseton, sd",
  },
  {
    postal_code: "25285",
    zip_name: "wallback, wv",
  },
  {
    postal_code: "63748",
    zip_name: "frohna, mo",
  },
  {
    postal_code: "68407",
    zip_name: "murdock, ne",
  },
  {
    postal_code: "50150",
    zip_name: "lovilia, ia",
  },
  {
    postal_code: "83111",
    zip_name: "auburn, wy",
  },
  {
    postal_code: "52170",
    zip_name: "waterville, ia",
  },
  {
    postal_code: "35459",
    zip_name: "emelle, al",
  },
  {
    postal_code: "41159",
    zip_name: "martha, ky",
  },
  {
    postal_code: "10596",
    zip_name: "verplanck, ny",
  },
  {
    postal_code: "57311",
    zip_name: "alexandria, sd",
  },
  {
    postal_code: "26419",
    zip_name: "pine grove, wv",
  },
  {
    postal_code: "89821",
    zip_name: "crescent valley, nv",
  },
  {
    postal_code: "38240",
    zip_name: "obion, tn",
  },
  {
    postal_code: "39663",
    zip_name: "silver creek, ms",
  },
  {
    postal_code: "62244",
    zip_name: "fults, il",
  },
  {
    postal_code: "26717",
    zip_name: "elk garden, wv",
  },
  {
    postal_code: "15745",
    zip_name: "heilwood, pa",
  },
  {
    postal_code: "18370",
    zip_name: "swiftwater, pa",
  },
  {
    postal_code: "74636",
    zip_name: "deer creek, ok",
  },
  {
    postal_code: "17219",
    zip_name: "doylesburg, pa",
  },
  {
    postal_code: "63626",
    zip_name: "blackwell, mo",
  },
  {
    postal_code: "24945",
    zip_name: "greenville, wv",
  },
  {
    postal_code: "78635",
    zip_name: "hye, tx",
  },
  {
    postal_code: "36912",
    zip_name: "lisman, al",
  },
  {
    postal_code: "68835",
    zip_name: "elba, ne",
  },
  {
    postal_code: "29727",
    zip_name: "mount croghan, sc",
  },
  {
    postal_code: "98533",
    zip_name: "cinebar, wa",
  },
  {
    postal_code: "45890",
    zip_name: "vanlue, oh",
  },
  {
    postal_code: "56297",
    zip_name: "wood lake, mn",
  },
  {
    postal_code: "39146",
    zip_name: "pickens, ms",
  },
  {
    postal_code: "84314",
    zip_name: "honeyville, ut",
  },
  {
    postal_code: "62830",
    zip_name: "dix, il",
  },
  {
    postal_code: "85609",
    zip_name: "dragoon, az",
  },
  {
    postal_code: "67361",
    zip_name: "sedan, ks",
  },
  {
    postal_code: "55933",
    zip_name: "elkton, mn",
  },
  {
    postal_code: "78959",
    zip_name: "waelder, tx",
  },
  {
    postal_code: "62432",
    zip_name: "hidalgo, il",
  },
  {
    postal_code: "68730",
    zip_name: "crofton, ne",
  },
  {
    postal_code: "13495",
    zip_name: "yorkville, ny",
  },
  {
    postal_code: "66078",
    zip_name: "princeton, ks",
  },
  {
    postal_code: "4564",
    zip_name: "round pond, me",
  },
  {
    postal_code: "68936",
    zip_name: "edison, ne",
  },
  {
    postal_code: "71336",
    zip_name: "gilbert, la",
  },
  {
    postal_code: "41558",
    zip_name: "ransom, ky",
  },
  {
    postal_code: "67448",
    zip_name: "gypsum, ks",
  },
  {
    postal_code: "35552",
    zip_name: "detroit, al",
  },
  {
    postal_code: "14055",
    zip_name: "east concord, ny",
  },
  {
    postal_code: "87012",
    zip_name: "coyote, nm",
  },
  {
    postal_code: "76930",
    zip_name: "barnhart, tx",
  },
  {
    postal_code: "62808",
    zip_name: "ashley, il",
  },
  {
    postal_code: "26222",
    zip_name: "hacker valley, wv",
  },
  {
    postal_code: "19954",
    zip_name: "houston, de",
  },
  {
    postal_code: "49917",
    zip_name: "copper city, mi",
  },
  {
    postal_code: "39427",
    zip_name: "carson, ms",
  },
  {
    postal_code: "68044",
    zip_name: "nickerson, ne",
  },
  {
    postal_code: "32579",
    zip_name: "shalimar, fl",
  },
  {
    postal_code: "24220",
    zip_name: "birchleaf, va",
  },
  {
    postal_code: "63653",
    zip_name: "leadwood, mo",
  },
  {
    postal_code: "67567",
    zip_name: "pawnee rock, ks",
  },
  {
    postal_code: "39362",
    zip_name: "state line, ms",
  },
  {
    postal_code: "65759",
    zip_name: "taneyville, mo",
  },
  {
    postal_code: "79044",
    zip_name: "hartley, tx",
  },
  {
    postal_code: "83285",
    zip_name: "wayan, id",
  },
  {
    postal_code: "16745",
    zip_name: "rixford, pa",
  },
  {
    postal_code: "59546",
    zip_name: "zortman, mt",
  },
  {
    postal_code: "16641",
    zip_name: "gallitzin, pa",
  },
  {
    postal_code: "63013",
    zip_name: "beaufort, mo",
  },
  {
    postal_code: "89412",
    zip_name: "gerlach, nv",
  },
  {
    postal_code: "49116",
    zip_name: "lakeside, mi",
  },
  {
    postal_code: "57004",
    zip_name: "beresford, sd",
  },
  {
    postal_code: "87527",
    zip_name: "dixon, nm",
  },
  {
    postal_code: "58041",
    zip_name: "hankinson, nd",
  },
  {
    postal_code: "61421",
    zip_name: "bradford, il",
  },
  {
    postal_code: "4929",
    zip_name: "detroit, me",
  },
  {
    postal_code: "51245",
    zip_name: "primghar, ia",
  },
  {
    postal_code: "12742",
    zip_name: "harris, ny",
  },
  {
    postal_code: "64842",
    zip_name: "fairview, mo",
  },
  {
    postal_code: "31783",
    zip_name: "rebecca, ga",
  },
  {
    postal_code: "55775",
    zip_name: "pengilly, mn",
  },
  {
    postal_code: "84531",
    zip_name: "mexican hat, ut",
  },
  {
    postal_code: "28441",
    zip_name: "garland, nc",
  },
  {
    postal_code: "65024",
    zip_name: "chamois, mo",
  },
  {
    postal_code: "2815",
    zip_name: "clayville, ri",
  },
  {
    postal_code: "15736",
    zip_name: "elderton, pa",
  },
  {
    postal_code: "66779",
    zip_name: "uniontown, ks",
  },
  {
    postal_code: "17582",
    zip_name: "washington boro, pa",
  },
  {
    postal_code: "31058",
    zip_name: "mauk, ga",
  },
  {
    postal_code: "67871",
    zip_name: "scott city, ks",
  },
  {
    postal_code: "84776",
    zip_name: "tropic, ut",
  },
  {
    postal_code: "99840",
    zip_name: "skagway, ak",
  },
  {
    postal_code: "87523",
    zip_name: "cordova, nm",
  },
  {
    postal_code: "62344",
    zip_name: "huntsville, il",
  },
  {
    postal_code: "64649",
    zip_name: "kidder, mo",
  },
  {
    postal_code: "67351",
    zip_name: "liberty, ks",
  },
  {
    postal_code: "67059",
    zip_name: "haviland, ks",
  },
  {
    postal_code: "54731",
    zip_name: "conrath, wi",
  },
  {
    postal_code: "5875",
    zip_name: "barton, vt",
  },
  {
    postal_code: "58488",
    zip_name: "tuttle, nd",
  },
  {
    postal_code: "11957",
    zip_name: "orient, ny",
  },
  {
    postal_code: "74032",
    zip_name: "glencoe, ok",
  },
  {
    postal_code: "17252",
    zip_name: "saint thomas, pa",
  },
  {
    postal_code: "26421",
    zip_name: "pullman, wv",
  },
  {
    postal_code: "71424",
    zip_name: "elmer, la",
  },
  {
    postal_code: "52553",
    zip_name: "eddyville, ia",
  },
  {
    postal_code: "84052",
    zip_name: "myton, ut",
  },
  {
    postal_code: "56165",
    zip_name: "reading, mn",
  },
  {
    postal_code: "82945",
    zip_name: "superior, wy",
  },
  {
    postal_code: "58036",
    zip_name: "gardner, nd",
  },
  {
    postal_code: "51544",
    zip_name: "lewis, ia",
  },
  {
    postal_code: "13652",
    zip_name: "hermon, ny",
  },
  {
    postal_code: "38921",
    zip_name: "charleston, ms",
  },
  {
    postal_code: "54127",
    zip_name: "green valley, wi",
  },
  {
    postal_code: "74748",
    zip_name: "kenefic, ok",
  },
  {
    postal_code: "79059",
    zip_name: "miami, tx",
  },
  {
    postal_code: "26280",
    zip_name: "mill creek, wv",
  },
  {
    postal_code: "1256",
    zip_name: "savoy, ma",
  },
  {
    postal_code: "62694",
    zip_name: "winchester, il",
  },
  {
    postal_code: "51553",
    zip_name: "minden, ia",
  },
  {
    postal_code: "52140",
    zip_name: "dorchester, ia",
  },
  {
    postal_code: "18455",
    zip_name: "preston park, pa",
  },
  {
    postal_code: "74533",
    zip_name: "caney, ok",
  },
  {
    postal_code: "15490",
    zip_name: "white, pa",
  },
  {
    postal_code: "72742",
    zip_name: "kingston, ar",
  },
  {
    postal_code: "67574",
    zip_name: "rozel, ks",
  },
  {
    postal_code: "41650",
    zip_name: "melvin, ky",
  },
  {
    postal_code: "68786",
    zip_name: "wausa, ne",
  },
  {
    postal_code: "81128",
    zip_name: "chromo, co",
  },
  {
    postal_code: "72469",
    zip_name: "strawberry, ar",
  },
  {
    postal_code: "48811",
    zip_name: "carson city, mi",
  },
  {
    postal_code: "70356",
    zip_name: "gibson, la",
  },
  {
    postal_code: "26338",
    zip_name: "camden, wv",
  },
  {
    postal_code: "71070",
    zip_name: "saline, la",
  },
  {
    postal_code: "66782",
    zip_name: "west mineral, ks",
  },
  {
    postal_code: "25524",
    zip_name: "harts, wv",
  },
  {
    postal_code: "30708",
    zip_name: "cisco, ga",
  },
  {
    postal_code: "49769",
    zip_name: "pellston, mi",
  },
  {
    postal_code: "60968",
    zip_name: "thawville, il",
  },
  {
    postal_code: "29827",
    zip_name: "fairfax, sc",
  },
  {
    postal_code: "84739",
    zip_name: "joseph, ut",
  },
  {
    postal_code: "1340",
    zip_name: "colrain, ma",
  },
  {
    postal_code: "81331",
    zip_name: "pleasant view, co",
  },
  {
    postal_code: "26161",
    zip_name: "petroleum, wv",
  },
  {
    postal_code: "80939",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "52158",
    zip_name: "marquette, ia",
  },
  {
    postal_code: "45729",
    zip_name: "fleming, oh",
  },
  {
    postal_code: "76678",
    zip_name: "prairie hill, tx",
  },
  {
    postal_code: "8804",
    zip_name: "bloomsbury, nj",
  },
  {
    postal_code: "8045",
    zip_name: "lawnside, nj",
  },
  {
    postal_code: "83347",
    zip_name: "paul, id",
  },
  {
    postal_code: "62347",
    zip_name: "liberty, il",
  },
  {
    postal_code: "53812",
    zip_name: "kieler, wi",
  },
  {
    postal_code: "81335",
    zip_name: "yellow jacket, co",
  },
  {
    postal_code: "35618",
    zip_name: "courtland, al",
  },
  {
    postal_code: "68748",
    zip_name: "madison, ne",
  },
  {
    postal_code: "50241",
    zip_name: "saint marys, ia",
  },
  {
    postal_code: "95550",
    zip_name: "korbel, ca",
  },
  {
    postal_code: "64484",
    zip_name: "rushville, mo",
  },
  {
    postal_code: "56060",
    zip_name: "lewisville, mn",
  },
  {
    postal_code: "79563",
    zip_name: "tye, tx",
  },
  {
    postal_code: "43014",
    zip_name: "danville, oh",
  },
  {
    postal_code: "52576",
    zip_name: "ollie, ia",
  },
  {
    postal_code: "30426",
    zip_name: "girard, ga",
  },
  {
    postal_code: "28527",
    zip_name: "ernul, nc",
  },
  {
    postal_code: "66414",
    zip_name: "carbondale, ks",
  },
  {
    postal_code: "29518",
    zip_name: "cades, sc",
  },
  {
    postal_code: "72839",
    zip_name: "hagarville, ar",
  },
  {
    postal_code: "48747",
    zip_name: "munger, mi",
  },
  {
    postal_code: "4676",
    zip_name: "sedgwick, me",
  },
  {
    postal_code: "77615",
    zip_name: "evadale, tx",
  },
  {
    postal_code: "72354",
    zip_name: "lepanto, ar",
  },
  {
    postal_code: "4410",
    zip_name: "bradford, me",
  },
  {
    postal_code: "56755",
    zip_name: "saint vincent, mn",
  },
  {
    postal_code: "37374",
    zip_name: "sequatchie, tn",
  },
  {
    postal_code: "42458",
    zip_name: "spottsville, ky",
  },
  {
    postal_code: "47346",
    zip_name: "hagerstown, in",
  },
  {
    postal_code: "74850",
    zip_name: "lamar, ok",
  },
  {
    postal_code: "53188",
    zip_name: "waukesha, wi",
  },
  {
    postal_code: "72623",
    zip_name: "clarkridge, ar",
  },
  {
    postal_code: "39069",
    zip_name: "fayette, ms",
  },
  {
    postal_code: "87937",
    zip_name: "hatch, nm",
  },
  {
    postal_code: "23359",
    zip_name: "hallwood, va",
  },
  {
    postal_code: "98235",
    zip_name: "clearlake, wa",
  },
  {
    postal_code: "71277",
    zip_name: "spearsville, la",
  },
  {
    postal_code: "47581",
    zip_name: "shoals, in",
  },
  {
    postal_code: "57564",
    zip_name: "onida, sd",
  },
  {
    postal_code: "68824",
    zip_name: "cairo, ne",
  },
  {
    postal_code: "14539",
    zip_name: "retsof, ny",
  },
  {
    postal_code: "39082",
    zip_name: "harrisville, ms",
  },
  {
    postal_code: "54488",
    zip_name: "unity, wi",
  },
  {
    postal_code: "80929",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "45724",
    zip_name: "cutler, oh",
  },
  {
    postal_code: "15870",
    zip_name: "wilcox, pa",
  },
  {
    postal_code: "22832",
    zip_name: "keezletown, va",
  },
  {
    postal_code: "65085",
    zip_name: "westphalia, mo",
  },
  {
    postal_code: "14864",
    zip_name: "millport, ny",
  },
  {
    postal_code: "64481",
    zip_name: "ridgeway, mo",
  },
  {
    postal_code: "4777",
    zip_name: "stacyville, me",
  },
  {
    postal_code: "15352",
    zip_name: "new freeport, pa",
  },
  {
    postal_code: "15315",
    zip_name: "bobtown, pa",
  },
  {
    postal_code: "36793",
    zip_name: "lawley, al",
  },
  {
    postal_code: "78563",
    zip_name: "linn, tx",
  },
  {
    postal_code: "67235",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "34762",
    zip_name: "okahumpka, fl",
  },
  {
    postal_code: "84638",
    zip_name: "leamington, ut",
  },
  {
    postal_code: "47859",
    zip_name: "marshall, in",
  },
  {
    postal_code: "83522",
    zip_name: "cottonwood, id",
  },
  {
    postal_code: "19477",
    zip_name: "spring house, pa",
  },
  {
    postal_code: "40140",
    zip_name: "garfield, ky",
  },
  {
    postal_code: "8001",
    zip_name: "alloway, nj",
  },
  {
    postal_code: "58480",
    zip_name: "sanborn, nd",
  },
  {
    postal_code: "89883",
    zip_name: "west wendover, nv",
  },
  {
    postal_code: "84331",
    zip_name: "portage, ut",
  },
  {
    postal_code: "95735",
    zip_name: "twin bridges, ca",
  },
  {
    postal_code: "52758",
    zip_name: "mc causland, ia",
  },
  {
    postal_code: "15663",
    zip_name: "madison, pa",
  },
  {
    postal_code: "81039",
    zip_name: "fowler, co",
  },
  {
    postal_code: "63845",
    zip_name: "east prairie, mo",
  },
  {
    postal_code: "59071",
    zip_name: "roscoe, mt",
  },
  {
    postal_code: "24250",
    zip_name: "fort blackmore, va",
  },
  {
    postal_code: "49744",
    zip_name: "herron, mi",
  },
  {
    postal_code: "66757",
    zip_name: "neodesha, ks",
  },
  {
    postal_code: "16333",
    zip_name: "ludlow, pa",
  },
  {
    postal_code: "45841",
    zip_name: "jenera, oh",
  },
  {
    postal_code: "76882",
    zip_name: "talpa, tx",
  },
  {
    postal_code: "12194",
    zip_name: "west fulton, ny",
  },
  {
    postal_code: "73763",
    zip_name: "okeene, ok",
  },
  {
    postal_code: "58770",
    zip_name: "parshall, nd",
  },
  {
    postal_code: "2199",
    zip_name: "boston, ma",
  },
  {
    postal_code: "24649",
    zip_name: "swords creek, va",
  },
  {
    postal_code: "36005",
    zip_name: "banks, al",
  },
  {
    postal_code: "53947",
    zip_name: "marquette, wi",
  },
  {
    postal_code: "70638",
    zip_name: "elizabeth, la",
  },
  {
    postal_code: "62887",
    zip_name: "springerton, il",
  },
  {
    postal_code: "77991",
    zip_name: "vanderbilt, tx",
  },
  {
    postal_code: "71834",
    zip_name: "doddridge, ar",
  },
  {
    postal_code: "72358",
    zip_name: "luxora, ar",
  },
  {
    postal_code: "36583",
    zip_name: "tibbie, al",
  },
  {
    postal_code: "50430",
    zip_name: "corwith, ia",
  },
  {
    postal_code: "56536",
    zip_name: "felton, mn",
  },
  {
    postal_code: "7418",
    zip_name: "glenwood, nj",
  },
  {
    postal_code: "12750",
    zip_name: "kenoza lake, ny",
  },
  {
    postal_code: "59460",
    zip_name: "loma, mt",
  },
  {
    postal_code: "6753",
    zip_name: "cornwall, ct",
  },
  {
    postal_code: "57003",
    zip_name: "baltic, sd",
  },
  {
    postal_code: "84530",
    zip_name: "la sal, ut",
  },
  {
    postal_code: "69170",
    zip_name: "wellfleet, ne",
  },
  {
    postal_code: "36511",
    zip_name: "bon secour, al",
  },
  {
    postal_code: "63556",
    zip_name: "milan, mo",
  },
  {
    postal_code: "16667",
    zip_name: "osterburg, pa",
  },
  {
    postal_code: "7424",
    zip_name: "little falls, nj",
  },
  {
    postal_code: "52056",
    zip_name: "luxemburg, ia",
  },
  {
    postal_code: "12490",
    zip_name: "west camp, ny",
  },
  {
    postal_code: "83874",
    zip_name: "murray, id",
  },
  {
    postal_code: "47515",
    zip_name: "bristow, in",
  },
  {
    postal_code: "58413",
    zip_name: "ashley, nd",
  },
  {
    postal_code: "67483",
    zip_name: "tampa, ks",
  },
  {
    postal_code: "79083",
    zip_name: "stinnett, tx",
  },
  {
    postal_code: "29916",
    zip_name: "early branch, sc",
  },
  {
    postal_code: "66097",
    zip_name: "winchester, ks",
  },
  {
    postal_code: "4475",
    zip_name: "passadumkeag, me",
  },
  {
    postal_code: "11239",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "50134",
    zip_name: "kelley, ia",
  },
  {
    postal_code: "61425",
    zip_name: "carman, il",
  },
  {
    postal_code: "67450",
    zip_name: "holyrood, ks",
  },
  {
    postal_code: "62207",
    zip_name: "east saint louis, il",
  },
  {
    postal_code: "74084",
    zip_name: "wynona, ok",
  },
  {
    postal_code: "16685",
    zip_name: "todd, pa",
  },
  {
    postal_code: "48891",
    zip_name: "vestaburg, mi",
  },
  {
    postal_code: "71747",
    zip_name: "huttig, ar",
  },
  {
    postal_code: "22504",
    zip_name: "laneview, va",
  },
  {
    postal_code: "41548",
    zip_name: "mouthcard, ky",
  },
  {
    postal_code: "83228",
    zip_name: "clifton, id",
  },
  {
    postal_code: "14065",
    zip_name: "freedom, ny",
  },
  {
    postal_code: "42565",
    zip_name: "windsor, ky",
  },
  {
    postal_code: "16834",
    zip_name: "drifting, pa",
  },
  {
    postal_code: "66776",
    zip_name: "thayer, ks",
  },
  {
    postal_code: "25540",
    zip_name: "midkiff, wv",
  },
  {
    postal_code: "26335",
    zip_name: "burnsville, wv",
  },
  {
    postal_code: "45832",
    zip_name: "convoy, oh",
  },
  {
    postal_code: "4485",
    zip_name: "shirley mills, me",
  },
  {
    postal_code: "78141",
    zip_name: "nordheim, tx",
  },
  {
    postal_code: "21555",
    zip_name: "oldtown, md",
  },
  {
    postal_code: "30272",
    zip_name: "red oak, ga",
  },
  {
    postal_code: "10975",
    zip_name: "southfields, ny",
  },
  {
    postal_code: "83520",
    zip_name: "ahsahka, id",
  },
  {
    postal_code: "71038",
    zip_name: "haynesville, la",
  },
  {
    postal_code: "62543",
    zip_name: "latham, il",
  },
  {
    postal_code: "58479",
    zip_name: "rogers, nd",
  },
  {
    postal_code: "7522",
    zip_name: "paterson, nj",
  },
  {
    postal_code: "36481",
    zip_name: "vredenburgh, al",
  },
  {
    postal_code: "53076",
    zip_name: "richfield, wi",
  },
  {
    postal_code: "95514",
    zip_name: "blocksburg, ca",
  },
  {
    postal_code: "76875",
    zip_name: "rowena, tx",
  },
  {
    postal_code: "2875",
    zip_name: "shannock, ri",
  },
  {
    postal_code: "1079",
    zip_name: "thorndike, ma",
  },
  {
    postal_code: "52750",
    zip_name: "goose lake, ia",
  },
  {
    postal_code: "25086",
    zip_name: "glasgow, wv",
  },
  {
    postal_code: "67109",
    zip_name: "mullinville, ks",
  },
  {
    postal_code: "27568",
    zip_name: "pine level, nc",
  },
  {
    postal_code: "84309",
    zip_name: "deweyville, ut",
  },
  {
    postal_code: "59934",
    zip_name: "trego, mt",
  },
  {
    postal_code: "58071",
    zip_name: "tower city, nd",
  },
  {
    postal_code: "22134",
    zip_name: "quantico, va",
  },
  {
    postal_code: "38746",
    zip_name: "gunnison, ms",
  },
  {
    postal_code: "25514",
    zip_name: "fort gay, wv",
  },
  {
    postal_code: "54536",
    zip_name: "iron belt, wi",
  },
  {
    postal_code: "65068",
    zip_name: "prairie home, mo",
  },
  {
    postal_code: "50577",
    zip_name: "renwick, ia",
  },
  {
    postal_code: "93452",
    zip_name: "san simeon, ca",
  },
  {
    postal_code: "16826",
    zip_name: "blanchard, pa",
  },
  {
    postal_code: "72853",
    zip_name: "ola, ar",
  },
  {
    postal_code: "79252",
    zip_name: "quanah, tx",
  },
  {
    postal_code: "24442",
    zip_name: "head waters, va",
  },
  {
    postal_code: "32427",
    zip_name: "caryville, fl",
  },
  {
    postal_code: "58272",
    zip_name: "petersburg, nd",
  },
  {
    postal_code: "30454",
    zip_name: "rockledge, ga",
  },
  {
    postal_code: "24168",
    zip_name: "stanleytown, va",
  },
  {
    postal_code: "50846",
    zip_name: "fontanelle, ia",
  },
  {
    postal_code: "61937",
    zip_name: "lovington, il",
  },
  {
    postal_code: "39813",
    zip_name: "arlington, ga",
  },
  {
    postal_code: "89161",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "51007",
    zip_name: "bronson, ia",
  },
  {
    postal_code: "68318",
    zip_name: "blue springs, ne",
  },
  {
    postal_code: "58227",
    zip_name: "edinburg, nd",
  },
  {
    postal_code: "50106",
    zip_name: "gilman, ia",
  },
  {
    postal_code: "65053",
    zip_name: "lohman, mo",
  },
  {
    postal_code: "66956",
    zip_name: "mankato, ks",
  },
  {
    postal_code: "72415",
    zip_name: "black rock, ar",
  },
  {
    postal_code: "43524",
    zip_name: "hamler, oh",
  },
  {
    postal_code: "25902",
    zip_name: "odd, wv",
  },
  {
    postal_code: "42254",
    zip_name: "la fayette, ky",
  },
  {
    postal_code: "68648",
    zip_name: "morse bluff, ne",
  },
  {
    postal_code: "67149",
    zip_name: "viola, ks",
  },
  {
    postal_code: "61910",
    zip_name: "arcola, il",
  },
  {
    postal_code: "38941",
    zip_name: "itta bena, ms",
  },
  {
    postal_code: "35461",
    zip_name: "ethelsville, al",
  },
  {
    postal_code: "45301",
    zip_name: "alpha, oh",
  },
  {
    postal_code: "44671",
    zip_name: "sandyville, oh",
  },
  {
    postal_code: "29368",
    zip_name: "mayo, sc",
  },
  {
    postal_code: "38255",
    zip_name: "sharon, tn",
  },
  {
    postal_code: "69337",
    zip_name: "chadron, ne",
  },
  {
    postal_code: "51003",
    zip_name: "alton, ia",
  },
  {
    postal_code: "62245",
    zip_name: "germantown, il",
  },
  {
    postal_code: "99631",
    zip_name: "moose pass, ak",
  },
  {
    postal_code: "14432",
    zip_name: "clifton springs, ny",
  },
  {
    postal_code: "38030",
    zip_name: "finley, tn",
  },
  {
    postal_code: "12575",
    zip_name: "rock tavern, ny",
  },
  {
    postal_code: "43033",
    zip_name: "kirkersville, oh",
  },
  {
    postal_code: "50275",
    zip_name: "woodburn, ia",
  },
  {
    postal_code: "61484",
    zip_name: "vermont, il",
  },
  {
    postal_code: "52069",
    zip_name: "preston, ia",
  },
  {
    postal_code: "15536",
    zip_name: "crystal spring, pa",
  },
  {
    postal_code: "66054",
    zip_name: "mc louth, ks",
  },
  {
    postal_code: "95607",
    zip_name: "capay, ca",
  },
  {
    postal_code: "50449",
    zip_name: "klemme, ia",
  },
  {
    postal_code: "35956",
    zip_name: "boaz, al",
  },
  {
    postal_code: "79052",
    zip_name: "kress, tx",
  },
  {
    postal_code: "50453",
    zip_name: "leland, ia",
  },
  {
    postal_code: "52729",
    zip_name: "calamus, ia",
  },
  {
    postal_code: "25169",
    zip_name: "ridgeview, wv",
  },
  {
    postal_code: "50078",
    zip_name: "ferguson, ia",
  },
  {
    postal_code: "17960",
    zip_name: "new ringgold, pa",
  },
  {
    postal_code: "23934",
    zip_name: "cullen, va",
  },
  {
    postal_code: "76943",
    zip_name: "ozona, tx",
  },
  {
    postal_code: "93962",
    zip_name: "spreckels, ca",
  },
  {
    postal_code: "55945",
    zip_name: "kellogg, mn",
  },
  {
    postal_code: "36509",
    zip_name: "bayou la batre, al",
  },
  {
    postal_code: "14753",
    zip_name: "limestone, ny",
  },
  {
    postal_code: "58374",
    zip_name: "sheyenne, nd",
  },
  {
    postal_code: "5902",
    zip_name: "beecher falls, vt",
  },
  {
    postal_code: "50136",
    zip_name: "keswick, ia",
  },
  {
    postal_code: "75478",
    zip_name: "saltillo, tx",
  },
  {
    postal_code: "50516",
    zip_name: "badger, ia",
  },
  {
    postal_code: "89013",
    zip_name: "goldfield, nv",
  },
  {
    postal_code: "14824",
    zip_name: "cayuta, ny",
  },
  {
    postal_code: "35774",
    zip_name: "trenton, al",
  },
  {
    postal_code: "73043",
    zip_name: "greenfield, ok",
  },
  {
    postal_code: "62934",
    zip_name: "equality, il",
  },
  {
    postal_code: "61367",
    zip_name: "sublette, il",
  },
  {
    postal_code: "87581",
    zip_name: "vallecitos, nm",
  },
  {
    postal_code: "65543",
    zip_name: "lynchburg, mo",
  },
  {
    postal_code: "56231",
    zip_name: "danvers, mn",
  },
  {
    postal_code: "35469",
    zip_name: "knoxville, al",
  },
  {
    postal_code: "47516",
    zip_name: "bruceville, in",
  },
  {
    postal_code: "71965",
    zip_name: "pencil bluff, ar",
  },
  {
    postal_code: "24974",
    zip_name: "secondcreek, wv",
  },
  {
    postal_code: "95345",
    zip_name: "midpines, ca",
  },
  {
    postal_code: "62374",
    zip_name: "tennessee, il",
  },
  {
    postal_code: "87831",
    zip_name: "san acacia, nm",
  },
  {
    postal_code: "98439",
    zip_name: "lakewood, wa",
  },
  {
    postal_code: "54450",
    zip_name: "mattoon, wi",
  },
  {
    postal_code: "47858",
    zip_name: "lewis, in",
  },
  {
    postal_code: "66042",
    zip_name: "lane, ks",
  },
  {
    postal_code: "85922",
    zip_name: "blue, az",
  },
  {
    postal_code: "27935",
    zip_name: "eure, nc",
  },
  {
    postal_code: "59842",
    zip_name: "haugan, mt",
  },
  {
    postal_code: "78159",
    zip_name: "smiley, tx",
  },
  {
    postal_code: "72561",
    zip_name: "mount pleasant, ar",
  },
  {
    postal_code: "51029",
    zip_name: "larrabee, ia",
  },
  {
    postal_code: "25976",
    zip_name: "meadow bridge, wv",
  },
  {
    postal_code: "82052",
    zip_name: "buford, wy",
  },
  {
    postal_code: "50160",
    zip_name: "martensdale, ia",
  },
  {
    postal_code: "67112",
    zip_name: "nashville, ks",
  },
  {
    postal_code: "37339",
    zip_name: "gruetli laager, tn",
  },
  {
    postal_code: "47527",
    zip_name: "dubois, in",
  },
  {
    postal_code: "38333",
    zip_name: "eva, tn",
  },
  {
    postal_code: "84083",
    zip_name: "wendover, ut",
  },
  {
    postal_code: "49894",
    zip_name: "wells, mi",
  },
  {
    postal_code: "64646",
    zip_name: "humphreys, mo",
  },
  {
    postal_code: "36053",
    zip_name: "midway, al",
  },
  {
    postal_code: "72633",
    zip_name: "everton, ar",
  },
  {
    postal_code: "25060",
    zip_name: "dorothy, wv",
  },
  {
    postal_code: "24433",
    zip_name: "doe hill, va",
  },
  {
    postal_code: "65254",
    zip_name: "glasgow, mo",
  },
  {
    postal_code: "56543",
    zip_name: "foxhome, mn",
  },
  {
    postal_code: "51060",
    zip_name: "ute, ia",
  },
  {
    postal_code: "58454",
    zip_name: "jud, nd",
  },
  {
    postal_code: "49033",
    zip_name: "ceresco, mi",
  },
  {
    postal_code: "47531",
    zip_name: "evanston, in",
  },
  {
    postal_code: "83852",
    zip_name: "ponderay, id",
  },
  {
    postal_code: "64863",
    zip_name: "south west city, mo",
  },
  {
    postal_code: "52169",
    zip_name: "wadena, ia",
  },
  {
    postal_code: "56021",
    zip_name: "courtland, mn",
  },
  {
    postal_code: "62671",
    zip_name: "new holland, il",
  },
  {
    postal_code: "27840",
    zip_name: "hamilton, nc",
  },
  {
    postal_code: "23354",
    zip_name: "franktown, va",
  },
  {
    postal_code: "71371",
    zip_name: "trout, la",
  },
  {
    postal_code: "42154",
    zip_name: "knob lick, ky",
  },
  {
    postal_code: "69024",
    zip_name: "culbertson, ne",
  },
  {
    postal_code: "47564",
    zip_name: "otwell, in",
  },
  {
    postal_code: "39332",
    zip_name: "hickory, ms",
  },
  {
    postal_code: "62816",
    zip_name: "bonnie, il",
  },
  {
    postal_code: "76435",
    zip_name: "carbon, tx",
  },
  {
    postal_code: "51562",
    zip_name: "panama, ia",
  },
  {
    postal_code: "54978",
    zip_name: "tilleda, wi",
  },
  {
    postal_code: "8620",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "3243",
    zip_name: "hill, nh",
  },
  {
    postal_code: "15746",
    zip_name: "hillsdale, pa",
  },
  {
    postal_code: "4650",
    zip_name: "little deer isle, me",
  },
  {
    postal_code: "31622",
    zip_name: "alapaha, ga",
  },
  {
    postal_code: "62927",
    zip_name: "dowell, il",
  },
  {
    postal_code: "29525",
    zip_name: "clio, sc",
  },
  {
    postal_code: "15628",
    zip_name: "donegal, pa",
  },
  {
    postal_code: "4265",
    zip_name: "north monmouth, me",
  },
  {
    postal_code: "68023",
    zip_name: "fort calhoun, ne",
  },
  {
    postal_code: "62688",
    zip_name: "tallula, il",
  },
  {
    postal_code: "58035",
    zip_name: "galesburg, nd",
  },
  {
    postal_code: "78953",
    zip_name: "rosanky, tx",
  },
  {
    postal_code: "39425",
    zip_name: "brooklyn, ms",
  },
  {
    postal_code: "99653",
    zip_name: "port alsworth, ak",
  },
  {
    postal_code: "96123",
    zip_name: "ravendale, ca",
  },
  {
    postal_code: "16420",
    zip_name: "grand valley, pa",
  },
  {
    postal_code: "38487",
    zip_name: "williamsport, tn",
  },
  {
    postal_code: "51540",
    zip_name: "hastings, ia",
  },
  {
    postal_code: "85605",
    zip_name: "bowie, az",
  },
  {
    postal_code: "4966",
    zip_name: "phillips, me",
  },
  {
    postal_code: "66549",
    zip_name: "westmoreland, ks",
  },
  {
    postal_code: "56458",
    zip_name: "lake george, mn",
  },
  {
    postal_code: "69211",
    zip_name: "cody, ne",
  },
  {
    postal_code: "61743",
    zip_name: "graymont, il",
  },
  {
    postal_code: "54746",
    zip_name: "humbird, wi",
  },
  {
    postal_code: "99371",
    zip_name: "washtucna, wa",
  },
  {
    postal_code: "65064",
    zip_name: "olean, mo",
  },
  {
    postal_code: "51462",
    zip_name: "scranton, ia",
  },
  {
    postal_code: "59926",
    zip_name: "martin city, mt",
  },
  {
    postal_code: "62238",
    zip_name: "cutler, il",
  },
  {
    postal_code: "61488",
    zip_name: "wataga, il",
  },
  {
    postal_code: "50654",
    zip_name: "masonville, ia",
  },
  {
    postal_code: "16352",
    zip_name: "tiona, pa",
  },
  {
    postal_code: "25062",
    zip_name: "dry creek, wv",
  },
  {
    postal_code: "57779",
    zip_name: "saint onge, sd",
  },
  {
    postal_code: "38778",
    zip_name: "sunflower, ms",
  },
  {
    postal_code: "29112",
    zip_name: "north, sc",
  },
  {
    postal_code: "56434",
    zip_name: "aldrich, mn",
  },
  {
    postal_code: "62250",
    zip_name: "hoffman, il",
  },
  {
    postal_code: "78005",
    zip_name: "bigfoot, tx",
  },
  {
    postal_code: "13318",
    zip_name: "cassville, ny",
  },
  {
    postal_code: "42368",
    zip_name: "reynolds station, ky",
  },
  {
    postal_code: "95113",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "63636",
    zip_name: "des arc, mo",
  },
  {
    postal_code: "62940",
    zip_name: "gorham, il",
  },
  {
    postal_code: "32334",
    zip_name: "hosford, fl",
  },
  {
    postal_code: "18933",
    zip_name: "lumberville, pa",
  },
  {
    postal_code: "97451",
    zip_name: "lorane, or",
  },
  {
    postal_code: "83469",
    zip_name: "shoup, id",
  },
  {
    postal_code: "96040",
    zip_name: "hat creek, ca",
  },
  {
    postal_code: "46183",
    zip_name: "west newton, in",
  },
  {
    postal_code: "75861",
    zip_name: "tennessee colony, tx",
  },
  {
    postal_code: "42285",
    zip_name: "sweeden, ky",
  },
  {
    postal_code: "81092",
    zip_name: "wiley, co",
  },
  {
    postal_code: "61044",
    zip_name: "kent, il",
  },
  {
    postal_code: "17260",
    zip_name: "shirleysburg, pa",
  },
  {
    postal_code: "52065",
    zip_name: "new vienna, ia",
  },
  {
    postal_code: "85135",
    zip_name: "hayden, az",
  },
  {
    postal_code: "61420",
    zip_name: "blandinsville, il",
  },
  {
    postal_code: "16876",
    zip_name: "wallaceton, pa",
  },
  {
    postal_code: "28447",
    zip_name: "ivanhoe, nc",
  },
  {
    postal_code: "69032",
    zip_name: "hayes center, ne",
  },
  {
    postal_code: "68970",
    zip_name: "red cloud, ne",
  },
  {
    postal_code: "73450",
    zip_name: "milburn, ok",
  },
  {
    postal_code: "47324",
    zip_name: "boston, in",
  },
  {
    postal_code: "39162",
    zip_name: "satartia, ms",
  },
  {
    postal_code: "53078",
    zip_name: "rubicon, wi",
  },
  {
    postal_code: "42733",
    zip_name: "elk horn, ky",
  },
  {
    postal_code: "16430",
    zip_name: "north springfield, pa",
  },
  {
    postal_code: "12045",
    zip_name: "coeymans, ny",
  },
  {
    postal_code: "64752",
    zip_name: "hume, mo",
  },
  {
    postal_code: "72395",
    zip_name: "wilson, ar",
  },
  {
    postal_code: "99508",
    zip_name: "anchorage, ak",
  },
  {
    postal_code: "63333",
    zip_name: "bellflower, mo",
  },
  {
    postal_code: "59211",
    zip_name: "antelope, mt",
  },
  {
    postal_code: "66612",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "16836",
    zip_name: "frenchville, pa",
  },
  {
    postal_code: "81073",
    zip_name: "springfield, co",
  },
  {
    postal_code: "15955",
    zip_name: "sidman, pa",
  },
  {
    postal_code: "81243",
    zip_name: "powderhorn, co",
  },
  {
    postal_code: "97107",
    zip_name: "bay city, or",
  },
  {
    postal_code: "40858",
    zip_name: "mozelle, ky",
  },
  {
    postal_code: "52047",
    zip_name: "farmersburg, ia",
  },
  {
    postal_code: "72533",
    zip_name: "fifty six, ar",
  },
  {
    postal_code: "69029",
    zip_name: "farnam, ne",
  },
  {
    postal_code: "18101",
    zip_name: "allentown, pa",
  },
  {
    postal_code: "62571",
    zip_name: "tower hill, il",
  },
  {
    postal_code: "71631",
    zip_name: "banks, ar",
  },
  {
    postal_code: "15359",
    zip_name: "rogersville, pa",
  },
  {
    postal_code: "35176",
    zip_name: "vandiver, al",
  },
  {
    postal_code: "42350",
    zip_name: "island, ky",
  },
  {
    postal_code: "23079",
    zip_name: "jamaica, va",
  },
  {
    postal_code: "21662",
    zip_name: "royal oak, md",
  },
  {
    postal_code: "1084",
    zip_name: "west chesterfield, ma",
  },
  {
    postal_code: "12433",
    zip_name: "glenford, ny",
  },
  {
    postal_code: "41772",
    zip_name: "vest, ky",
  },
  {
    postal_code: "97108",
    zip_name: "beaver, or",
  },
  {
    postal_code: "39668",
    zip_name: "union church, ms",
  },
  {
    postal_code: "63750",
    zip_name: "gipsy, mo",
  },
  {
    postal_code: "68930",
    zip_name: "blue hill, ne",
  },
  {
    postal_code: "81411",
    zip_name: "bedrock, co",
  },
  {
    postal_code: "19964",
    zip_name: "marydel, de",
  },
  {
    postal_code: "66436",
    zip_name: "holton, ks",
  },
  {
    postal_code: "96726",
    zip_name: "honaunau, hi",
  },
  {
    postal_code: "56147",
    zip_name: "kenneth, mn",
  },
  {
    postal_code: "43153",
    zip_name: "south solon, oh",
  },
  {
    postal_code: "65256",
    zip_name: "harrisburg, mo",
  },
  {
    postal_code: "25260",
    zip_name: "mason, wv",
  },
  {
    postal_code: "50657",
    zip_name: "morrison, ia",
  },
  {
    postal_code: "36602",
    zip_name: "mobile, al",
  },
  {
    postal_code: "67444",
    zip_name: "geneseo, ks",
  },
  {
    postal_code: "84334",
    zip_name: "riverside, ut",
  },
  {
    postal_code: "66079",
    zip_name: "rantoul, ks",
  },
  {
    postal_code: "49505",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "62695",
    zip_name: "woodson, il",
  },
  {
    postal_code: "16263",
    zip_name: "yatesboro, pa",
  },
  {
    postal_code: "48211",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "50157",
    zip_name: "malcom, ia",
  },
  {
    postal_code: "50571",
    zip_name: "palmer, ia",
  },
  {
    postal_code: "10931",
    zip_name: "hillburn, ny",
  },
  {
    postal_code: "84731",
    zip_name: "greenville, ut",
  },
  {
    postal_code: "52162",
    zip_name: "postville, ia",
  },
  {
    postal_code: "67111",
    zip_name: "murdock, ks",
  },
  {
    postal_code: "58521",
    zip_name: "baldwin, nd",
  },
  {
    postal_code: "74948",
    zip_name: "muldrow, ok",
  },
  {
    postal_code: "31018",
    zip_name: "davisboro, ga",
  },
  {
    postal_code: "13314",
    zip_name: "brookfield, ny",
  },
  {
    postal_code: "62956",
    zip_name: "karnak, il",
  },
  {
    postal_code: "56279",
    zip_name: "pennock, mn",
  },
  {
    postal_code: "28006",
    zip_name: "alexis, nc",
  },
  {
    postal_code: "79098",
    zip_name: "wildorado, tx",
  },
  {
    postal_code: "8827",
    zip_name: "hampton, nj",
  },
  {
    postal_code: "56117",
    zip_name: "bigelow, mn",
  },
  {
    postal_code: "87063",
    zip_name: "willard, nm",
  },
  {
    postal_code: "15763",
    zip_name: "northpoint, pa",
  },
  {
    postal_code: "4939",
    zip_name: "garland, me",
  },
  {
    postal_code: "19979",
    zip_name: "viola, de",
  },
  {
    postal_code: "59214",
    zip_name: "brockway, mt",
  },
  {
    postal_code: "66033",
    zip_name: "greeley, ks",
  },
  {
    postal_code: "61314",
    zip_name: "buda, il",
  },
  {
    postal_code: "14820",
    zip_name: "cameron mills, ny",
  },
  {
    postal_code: "93244",
    zip_name: "lemon cove, ca",
  },
  {
    postal_code: "21521",
    zip_name: "barton, md",
  },
  {
    postal_code: "58625",
    zip_name: "dodge, nd",
  },
  {
    postal_code: "4471",
    zip_name: "orient, me",
  },
  {
    postal_code: "73562",
    zip_name: "randlett, ok",
  },
  {
    postal_code: "66845",
    zip_name: "cottonwood falls, ks",
  },
  {
    postal_code: "15364",
    zip_name: "sycamore, pa",
  },
  {
    postal_code: "35576",
    zip_name: "millport, al",
  },
  {
    postal_code: "71039",
    zip_name: "heflin, la",
  },
  {
    postal_code: "10560",
    zip_name: "north salem, ny",
  },
  {
    postal_code: "50034",
    zip_name: "blairsburg, ia",
  },
  {
    postal_code: "99147",
    zip_name: "lincoln, wa",
  },
  {
    postal_code: "61439",
    zip_name: "henderson, il",
  },
  {
    postal_code: "16936",
    zip_name: "millerton, pa",
  },
  {
    postal_code: "8556",
    zip_name: "rosemont, nj",
  },
  {
    postal_code: "58497",
    zip_name: "ypsilanti, nd",
  },
  {
    postal_code: "67553",
    zip_name: "liebenthal, ks",
  },
  {
    postal_code: "61480",
    zip_name: "stronghurst, il",
  },
  {
    postal_code: "35490",
    zip_name: "vance, al",
  },
  {
    postal_code: "59002",
    zip_name: "acton, mt",
  },
  {
    postal_code: "62480",
    zip_name: "willow hill, il",
  },
  {
    postal_code: "70372",
    zip_name: "labadieville, la",
  },
  {
    postal_code: "6456",
    zip_name: "middle haddam, ct",
  },
  {
    postal_code: "94957",
    zip_name: "ross, ca",
  },
  {
    postal_code: "81638",
    zip_name: "hamilton, co",
  },
  {
    postal_code: "47282",
    zip_name: "vernon, in",
  },
  {
    postal_code: "49863",
    zip_name: "nadeau, mi",
  },
  {
    postal_code: "68878",
    zip_name: "sumner, ne",
  },
  {
    postal_code: "62423",
    zip_name: "dennison, il",
  },
  {
    postal_code: "35986",
    zip_name: "rainsville, al",
  },
  {
    postal_code: "57324",
    zip_name: "cavour, sd",
  },
  {
    postal_code: "98828",
    zip_name: "malaga, wa",
  },
  {
    postal_code: "76631",
    zip_name: "bynum, tx",
  },
  {
    postal_code: "66865",
    zip_name: "olpe, ks",
  },
  {
    postal_code: "84622",
    zip_name: "centerfield, ut",
  },
  {
    postal_code: "79034",
    zip_name: "follett, tx",
  },
  {
    postal_code: "92259",
    zip_name: "ocotillo, ca",
  },
  {
    postal_code: "40077",
    zip_name: "westport, ky",
  },
  {
    postal_code: "22943",
    zip_name: "greenwood, va",
  },
  {
    postal_code: "45363",
    zip_name: "russia, oh",
  },
  {
    postal_code: "99572",
    zip_name: "cooper landing, ak",
  },
  {
    postal_code: "68344",
    zip_name: "douglas, ne",
  },
  {
    postal_code: "54493",
    zip_name: "willard, wi",
  },
  {
    postal_code: "58793",
    zip_name: "westhope, nd",
  },
  {
    postal_code: "72660",
    zip_name: "oak grove, ar",
  },
  {
    postal_code: "5775",
    zip_name: "west pawlet, vt",
  },
  {
    postal_code: "64645",
    zip_name: "harris, mo",
  },
  {
    postal_code: "68731",
    zip_name: "dakota city, ne",
  },
  {
    postal_code: "29530",
    zip_name: "coward, sc",
  },
  {
    postal_code: "23423",
    zip_name: "quinby, va",
  },
  {
    postal_code: "4453",
    zip_name: "lagrange, me",
  },
  {
    postal_code: "14012",
    zip_name: "barker, ny",
  },
  {
    postal_code: "58329",
    zip_name: "dunseith, nd",
  },
  {
    postal_code: "15942",
    zip_name: "mineral point, pa",
  },
  {
    postal_code: "79906",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "73560",
    zip_name: "olustee, ok",
  },
  {
    postal_code: "36728",
    zip_name: "catherine, al",
  },
  {
    postal_code: "24931",
    zip_name: "crawley, wv",
  },
  {
    postal_code: "67458",
    zip_name: "longford, ks",
  },
  {
    postal_code: "67524",
    zip_name: "chase, ks",
  },
  {
    postal_code: "46381",
    zip_name: "thayer, in",
  },
  {
    postal_code: "38548",
    zip_name: "buffalo valley, tn",
  },
  {
    postal_code: "20693",
    zip_name: "welcome, md",
  },
  {
    postal_code: "52621",
    zip_name: "crawfordsville, ia",
  },
  {
    postal_code: "11965",
    zip_name: "shelter island heights, ny",
  },
  {
    postal_code: "16630",
    zip_name: "cresson, pa",
  },
  {
    postal_code: "13468",
    zip_name: "springfield center, ny",
  },
  {
    postal_code: "64493",
    zip_name: "turney, mo",
  },
  {
    postal_code: "17744",
    zip_name: "linden, pa",
  },
  {
    postal_code: "46133",
    zip_name: "glenwood, in",
  },
  {
    postal_code: "15341",
    zip_name: "holbrook, pa",
  },
  {
    postal_code: "79372",
    zip_name: "sundown, tx",
  },
  {
    postal_code: "72140",
    zip_name: "saint charles, ar",
  },
  {
    postal_code: "13163",
    zip_name: "wampsville, ny",
  },
  {
    postal_code: "74867",
    zip_name: "sasakwa, ok",
  },
  {
    postal_code: "56052",
    zip_name: "kilkenny, mn",
  },
  {
    postal_code: "21665",
    zip_name: "sherwood, md",
  },
  {
    postal_code: "62065",
    zip_name: "michael, il",
  },
  {
    postal_code: "68641",
    zip_name: "howells, ne",
  },
  {
    postal_code: "84072",
    zip_name: "tabiona, ut",
  },
  {
    postal_code: "72025",
    zip_name: "casa, ar",
  },
  {
    postal_code: "68031",
    zip_name: "hooper, ne",
  },
  {
    postal_code: "67657",
    zip_name: "palco, ks",
  },
  {
    postal_code: "15038",
    zip_name: "elrama, pa",
  },
  {
    postal_code: "26208",
    zip_name: "camden on gauley, wv",
  },
  {
    postal_code: "1354",
    zip_name: "gill, ma",
  },
  {
    postal_code: "82523",
    zip_name: "pavillion, wy",
  },
  {
    postal_code: "82714",
    zip_name: "devils tower, wy",
  },
  {
    postal_code: "76525",
    zip_name: "evant, tx",
  },
  {
    postal_code: "4733",
    zip_name: "benedicta, me",
  },
  {
    postal_code: "29741",
    zip_name: "ruby, sc",
  },
  {
    postal_code: "58755",
    zip_name: "mcgregor, nd",
  },
  {
    postal_code: "99588",
    zip_name: "glennallen, ak",
  },
  {
    postal_code: "30428",
    zip_name: "glenwood, ga",
  },
  {
    postal_code: "64661",
    zip_name: "mercer, mo",
  },
  {
    postal_code: "12207",
    zip_name: "albany, ny",
  },
  {
    postal_code: "82937",
    zip_name: "lyman, wy",
  },
  {
    postal_code: "1244",
    zip_name: "mill river, ma",
  },
  {
    postal_code: "4289",
    zip_name: "west paris, me",
  },
  {
    postal_code: "54966",
    zip_name: "plainfield, wi",
  },
  {
    postal_code: "39338",
    zip_name: "louin, ms",
  },
  {
    postal_code: "58570",
    zip_name: "solen, nd",
  },
  {
    postal_code: "54927",
    zip_name: "butte des morts, wi",
  },
  {
    postal_code: "56580",
    zip_name: "sabin, mn",
  },
  {
    postal_code: "90058",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "79230",
    zip_name: "dodson, tx",
  },
  {
    postal_code: "57012",
    zip_name: "canistota, sd",
  },
  {
    postal_code: "89318",
    zip_name: "mc gill, nv",
  },
  {
    postal_code: "36454",
    zip_name: "lenox, al",
  },
  {
    postal_code: "73442",
    zip_name: "loco, ok",
  },
  {
    postal_code: "65766",
    zip_name: "udall, mo",
  },
  {
    postal_code: "81041",
    zip_name: "granada, co",
  },
  {
    postal_code: "98283",
    zip_name: "rockport, wa",
  },
  {
    postal_code: "46770",
    zip_name: "markle, in",
  },
  {
    postal_code: "62036",
    zip_name: "golden eagle, il",
  },
  {
    postal_code: "13162",
    zip_name: "verona beach, ny",
  },
  {
    postal_code: "25245",
    zip_name: "given, wv",
  },
  {
    postal_code: "63961",
    zip_name: "qulin, mo",
  },
  {
    postal_code: "41189",
    zip_name: "tollesboro, ky",
  },
  {
    postal_code: "65069",
    zip_name: "rhineland, mo",
  },
  {
    postal_code: "44802",
    zip_name: "alvada, oh",
  },
  {
    postal_code: "56314",
    zip_name: "bowlus, mn",
  },
  {
    postal_code: "68422",
    zip_name: "pickrell, ne",
  },
  {
    postal_code: "93669",
    zip_name: "wishon, ca",
  },
  {
    postal_code: "21837",
    zip_name: "mardela springs, md",
  },
  {
    postal_code: "65755",
    zip_name: "squires, mo",
  },
  {
    postal_code: "17952",
    zip_name: "mary d, pa",
  },
  {
    postal_code: "67730",
    zip_name: "atwood, ks",
  },
  {
    postal_code: "5681",
    zip_name: "woodbury, vt",
  },
  {
    postal_code: "87752",
    zip_name: "wagon mound, nm",
  },
  {
    postal_code: "37765",
    zip_name: "kyles ford, tn",
  },
  {
    postal_code: "65059",
    zip_name: "mokane, mo",
  },
  {
    postal_code: "54772",
    zip_name: "wheeler, wi",
  },
  {
    postal_code: "72578",
    zip_name: "sturkie, ar",
  },
  {
    postal_code: "78549",
    zip_name: "hargill, tx",
  },
  {
    postal_code: "74363",
    zip_name: "quapaw, ok",
  },
  {
    postal_code: "62905",
    zip_name: "alto pass, il",
  },
  {
    postal_code: "4763",
    zip_name: "oakfield, me",
  },
  {
    postal_code: "15677",
    zip_name: "rector, pa",
  },
  {
    postal_code: "87578",
    zip_name: "truchas, nm",
  },
  {
    postal_code: "68429",
    zip_name: "reynolds, ne",
  },
  {
    postal_code: "99125",
    zip_name: "endicott, wa",
  },
  {
    postal_code: "71743",
    zip_name: "gurdon, ar",
  },
  {
    postal_code: "28453",
    zip_name: "magnolia, nc",
  },
  {
    postal_code: "89049",
    zip_name: "tonopah, nv",
  },
  {
    postal_code: "1378",
    zip_name: "warwick, ma",
  },
  {
    postal_code: "70051",
    zip_name: "garyville, la",
  },
  {
    postal_code: "25678",
    zip_name: "matewan, wv",
  },
  {
    postal_code: "12411",
    zip_name: "bloomington, ny",
  },
  {
    postal_code: "21644",
    zip_name: "ingleside, md",
  },
  {
    postal_code: "95231",
    zip_name: "french camp, ca",
  },
  {
    postal_code: "41203",
    zip_name: "beauty, ky",
  },
  {
    postal_code: "24221",
    zip_name: "blackwater, va",
  },
  {
    postal_code: "65040",
    zip_name: "henley, mo",
  },
  {
    postal_code: "4743",
    zip_name: "fort kent, me",
  },
  {
    postal_code: "23169",
    zip_name: "topping, va",
  },
  {
    postal_code: "17941",
    zip_name: "klingerstown, pa",
  },
  {
    postal_code: "47649",
    zip_name: "francisco, in",
  },
  {
    postal_code: "50217",
    zip_name: "paton, ia",
  },
  {
    postal_code: "61371",
    zip_name: "triumph, il",
  },
  {
    postal_code: "28508",
    zip_name: "albertson, nc",
  },
  {
    postal_code: "11109",
    zip_name: "long island city, ny",
  },
  {
    postal_code: "40988",
    zip_name: "stoney fork, ky",
  },
  {
    postal_code: "68718",
    zip_name: "bloomfield, ne",
  },
  {
    postal_code: "98832",
    zip_name: "marlin, wa",
  },
  {
    postal_code: "45868",
    zip_name: "mount cory, oh",
  },
  {
    postal_code: "58445",
    zip_name: "grace city, nd",
  },
  {
    postal_code: "59733",
    zip_name: "gold creek, mt",
  },
  {
    postal_code: "50522",
    zip_name: "burt, ia",
  },
  {
    postal_code: "58552",
    zip_name: "linton, nd",
  },
  {
    postal_code: "72157",
    zip_name: "springfield, ar",
  },
  {
    postal_code: "97640",
    zip_name: "summer lake, or",
  },
  {
    postal_code: "42782",
    zip_name: "summersville, ky",
  },
  {
    postal_code: "84082",
    zip_name: "wallsburg, ut",
  },
  {
    postal_code: "47470",
    zip_name: "williams, in",
  },
  {
    postal_code: "46154",
    zip_name: "maxwell, in",
  },
  {
    postal_code: "66741",
    zip_name: "garland, ks",
  },
  {
    postal_code: "44049",
    zip_name: "kipton, oh",
  },
  {
    postal_code: "66738",
    zip_name: "fulton, ks",
  },
  {
    postal_code: "61079",
    zip_name: "shirland, il",
  },
  {
    postal_code: "69028",
    zip_name: "eustis, ne",
  },
  {
    postal_code: "60537",
    zip_name: "millington, il",
  },
  {
    postal_code: "65466",
    zip_name: "eminence, mo",
  },
  {
    postal_code: "95227",
    zip_name: "clements, ca",
  },
  {
    postal_code: "54648",
    zip_name: "norwalk, wi",
  },
  {
    postal_code: "75926",
    zip_name: "apple springs, tx",
  },
  {
    postal_code: "88032",
    zip_name: "dona ana, nm",
  },
  {
    postal_code: "45775",
    zip_name: "rutland, oh",
  },
  {
    postal_code: "4635",
    zip_name: "frenchboro, me",
  },
  {
    postal_code: "65259",
    zip_name: "huntsville, mo",
  },
  {
    postal_code: "32694",
    zip_name: "waldo, fl",
  },
  {
    postal_code: "57555",
    zip_name: "mission, sd",
  },
  {
    postal_code: "10522",
    zip_name: "dobbs ferry, ny",
  },
  {
    postal_code: "49325",
    zip_name: "freeport, mi",
  },
  {
    postal_code: "49262",
    zip_name: "north adams, mi",
  },
  {
    postal_code: "48454",
    zip_name: "melvin, mi",
  },
  {
    postal_code: "15848",
    zip_name: "luthersburg, pa",
  },
  {
    postal_code: "64873",
    zip_name: "wentworth, mo",
  },
  {
    postal_code: "74339",
    zip_name: "commerce, ok",
  },
  {
    postal_code: "65016",
    zip_name: "bonnots mill, mo",
  },
  {
    postal_code: "49612",
    zip_name: "alden, mi",
  },
  {
    postal_code: "51249",
    zip_name: "sibley, ia",
  },
  {
    postal_code: "58461",
    zip_name: "litchville, nd",
  },
  {
    postal_code: "87734",
    zip_name: "ocate, nm",
  },
  {
    postal_code: "60955",
    zip_name: "onarga, il",
  },
  {
    postal_code: "70661",
    zip_name: "starks, la",
  },
  {
    postal_code: "72851",
    zip_name: "new blaine, ar",
  },
  {
    postal_code: "46543",
    zip_name: "millersburg, in",
  },
  {
    postal_code: "50421",
    zip_name: "belmond, ia",
  },
  {
    postal_code: "47994",
    zip_name: "wingate, in",
  },
  {
    postal_code: "50040",
    zip_name: "boxholm, ia",
  },
  {
    postal_code: "72041",
    zip_name: "de valls bluff, ar",
  },
  {
    postal_code: "4645",
    zip_name: "isle au haut, me",
  },
  {
    postal_code: "97461",
    zip_name: "noti, or",
  },
  {
    postal_code: "76958",
    zip_name: "water valley, tx",
  },
  {
    postal_code: "20006",
    zip_name: "washington, dc",
  },
  {
    postal_code: "68016",
    zip_name: "cedar creek, ne",
  },
  {
    postal_code: "25142",
    zip_name: "nellis, wv",
  },
  {
    postal_code: "23130",
    zip_name: "onemo, va",
  },
  {
    postal_code: "31649",
    zip_name: "stockton, ga",
  },
  {
    postal_code: "57368",
    zip_name: "plankinton, sd",
  },
  {
    postal_code: "54513",
    zip_name: "brantwood, wi",
  },
  {
    postal_code: "79505",
    zip_name: "benjamin, tx",
  },
  {
    postal_code: "68760",
    zip_name: "niobrara, ne",
  },
  {
    postal_code: "61541",
    zip_name: "la rose, il",
  },
  {
    postal_code: "23438",
    zip_name: "suffolk, va",
  },
  {
    postal_code: "36860",
    zip_name: "hurtsboro, al",
  },
  {
    postal_code: "50433",
    zip_name: "dougherty, ia",
  },
  {
    postal_code: "79839",
    zip_name: "fort hancock, tx",
  },
  {
    postal_code: "5253",
    zip_name: "east dorset, vt",
  },
  {
    postal_code: "24270",
    zip_name: "mendota, va",
  },
  {
    postal_code: "26257",
    zip_name: "coalton, wv",
  },
  {
    postal_code: "15860",
    zip_name: "sigel, pa",
  },
  {
    postal_code: "68971",
    zip_name: "republican city, ne",
  },
  {
    postal_code: "58081",
    zip_name: "wyndmere, nd",
  },
  {
    postal_code: "13660",
    zip_name: "madrid, ny",
  },
  {
    postal_code: "15434",
    zip_name: "elco, pa",
  },
  {
    postal_code: "84730",
    zip_name: "glenwood, ut",
  },
  {
    postal_code: "76261",
    zip_name: "ringgold, tx",
  },
  {
    postal_code: "16882",
    zip_name: "woodward, pa",
  },
  {
    postal_code: "24555",
    zip_name: "glasgow, va",
  },
  {
    postal_code: "37729",
    zip_name: "duff, tn",
  },
  {
    postal_code: "65239",
    zip_name: "cairo, mo",
  },
  {
    postal_code: "93067",
    zip_name: "summerland, ca",
  },
  {
    postal_code: "92059",
    zip_name: "pala, ca",
  },
  {
    postal_code: "95253",
    zip_name: "victor, ca",
  },
  {
    postal_code: "44689",
    zip_name: "wilmot, oh",
  },
  {
    postal_code: "79241",
    zip_name: "lockney, tx",
  },
  {
    postal_code: "4422",
    zip_name: "charleston, me",
  },
  {
    postal_code: "84716",
    zip_name: "boulder, ut",
  },
  {
    postal_code: "67425",
    zip_name: "brookville, ks",
  },
  {
    postal_code: "82244",
    zip_name: "yoder, wy",
  },
  {
    postal_code: "56594",
    zip_name: "wolverton, mn",
  },
  {
    postal_code: "63087",
    zip_name: "valles mines, mo",
  },
  {
    postal_code: "94922",
    zip_name: "bodega, ca",
  },
  {
    postal_code: "62853",
    zip_name: "kell, il",
  },
  {
    postal_code: "95699",
    zip_name: "drytown, ca",
  },
  {
    postal_code: "27305",
    zip_name: "milton, nc",
  },
  {
    postal_code: "45866",
    zip_name: "montezuma, oh",
  },
  {
    postal_code: "68066",
    zip_name: "wahoo, ne",
  },
  {
    postal_code: "4497",
    zip_name: "wytopitlock, me",
  },
  {
    postal_code: "61452",
    zip_name: "littleton, il",
  },
  {
    postal_code: "31327",
    zip_name: "sapelo island, ga",
  },
  {
    postal_code: "95466",
    zip_name: "philo, ca",
  },
  {
    postal_code: "42236",
    zip_name: "herndon, ky",
  },
  {
    postal_code: "87573",
    zip_name: "tererro, nm",
  },
  {
    postal_code: "31733",
    zip_name: "chula, ga",
  },
  {
    postal_code: "51238",
    zip_name: "hospers, ia",
  },
  {
    postal_code: "23857",
    zip_name: "gasburg, va",
  },
  {
    postal_code: "57072",
    zip_name: "volin, sd",
  },
  {
    postal_code: "36047",
    zip_name: "letohatchee, al",
  },
  {
    postal_code: "48735",
    zip_name: "gagetown, mi",
  },
  {
    postal_code: "89019",
    zip_name: "jean, nv",
  },
  {
    postal_code: "49713",
    zip_name: "boyne falls, mi",
  },
  {
    postal_code: "47578",
    zip_name: "sandborn, in",
  },
  {
    postal_code: "73718",
    zip_name: "ames, ok",
  },
  {
    postal_code: "32423",
    zip_name: "bascom, fl",
  },
  {
    postal_code: "76869",
    zip_name: "pontotoc, tx",
  },
  {
    postal_code: "3774",
    zip_name: "north haverhill, nh",
  },
  {
    postal_code: "82059",
    zip_name: "granite canon, wy",
  },
  {
    postal_code: "29001",
    zip_name: "alcolu, sc",
  },
  {
    postal_code: "60919",
    zip_name: "cabery, il",
  },
  {
    postal_code: "52202",
    zip_name: "alburnett, ia",
  },
  {
    postal_code: "67353",
    zip_name: "moline, ks",
  },
  {
    postal_code: "81058",
    zip_name: "manzanola, co",
  },
  {
    postal_code: "48729",
    zip_name: "deford, mi",
  },
  {
    postal_code: "39861",
    zip_name: "jakin, ga",
  },
  {
    postal_code: "62919",
    zip_name: "cave in rock, il",
  },
  {
    postal_code: "3597",
    zip_name: "west stewartstown, nh",
  },
  {
    postal_code: "1560",
    zip_name: "south grafton, ma",
  },
  {
    postal_code: "24317",
    zip_name: "cana, va",
  },
  {
    postal_code: "83525",
    zip_name: "elk city, id",
  },
  {
    postal_code: "4231",
    zip_name: "stoneham, me",
  },
  {
    postal_code: "68040",
    zip_name: "malmo, ne",
  },
  {
    postal_code: "21056",
    zip_name: "gibson island, md",
  },
  {
    postal_code: "57218",
    zip_name: "brandt, sd",
  },
  {
    postal_code: "53203",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "41043",
    zip_name: "foster, ky",
  },
  {
    postal_code: "64733",
    zip_name: "chilhowee, mo",
  },
  {
    postal_code: "17823",
    zip_name: "dornsife, pa",
  },
  {
    postal_code: "36029",
    zip_name: "fitzpatrick, al",
  },
  {
    postal_code: "79778",
    zip_name: "rankin, tx",
  },
  {
    postal_code: "45854",
    zip_name: "lafayette, oh",
  },
  {
    postal_code: "29848",
    zip_name: "troy, sc",
  },
  {
    postal_code: "91934",
    zip_name: "jacumba, ca",
  },
  {
    postal_code: "43157",
    zip_name: "thurston, oh",
  },
  {
    postal_code: "14422",
    zip_name: "byron, ny",
  },
  {
    postal_code: "64620",
    zip_name: "altamont, mo",
  },
  {
    postal_code: "67466",
    zip_name: "miltonvale, ks",
  },
  {
    postal_code: "49825",
    zip_name: "eben junction, mi",
  },
  {
    postal_code: "61411",
    zip_name: "adair, il",
  },
  {
    postal_code: "76432",
    zip_name: "blanket, tx",
  },
  {
    postal_code: "31738",
    zip_name: "coolidge, ga",
  },
  {
    postal_code: "67831",
    zip_name: "ashland, ks",
  },
  {
    postal_code: "70450",
    zip_name: "mount hermon, la",
  },
  {
    postal_code: "50581",
    zip_name: "rolfe, ia",
  },
  {
    postal_code: "12987",
    zip_name: "upper jay, ny",
  },
  {
    postal_code: "12924",
    zip_name: "keeseville, ny",
  },
  {
    postal_code: "61263",
    zip_name: "matherville, il",
  },
  {
    postal_code: "62871",
    zip_name: "omaha, il",
  },
  {
    postal_code: "82839",
    zip_name: "ranchester, wy",
  },
  {
    postal_code: "46058",
    zip_name: "mulberry, in",
  },
  {
    postal_code: "68780",
    zip_name: "stuart, ne",
  },
  {
    postal_code: "70456",
    zip_name: "roseland, la",
  },
  {
    postal_code: "7027",
    zip_name: "garwood, nj",
  },
  {
    postal_code: "51558",
    zip_name: "moorhead, ia",
  },
  {
    postal_code: "71223",
    zip_name: "bonita, la",
  },
  {
    postal_code: "48426",
    zip_name: "decker, mi",
  },
  {
    postal_code: "71640",
    zip_name: "eudora, ar",
  },
  {
    postal_code: "88134",
    zip_name: "taiban, nm",
  },
  {
    postal_code: "97710",
    zip_name: "fields, or",
  },
  {
    postal_code: "52156",
    zip_name: "luana, ia",
  },
  {
    postal_code: "65731",
    zip_name: "powersite, mo",
  },
  {
    postal_code: "10505",
    zip_name: "baldwin place, ny",
  },
  {
    postal_code: "5061",
    zip_name: "randolph center, vt",
  },
  {
    postal_code: "24562",
    zip_name: "howardsville, va",
  },
  {
    postal_code: "5766",
    zip_name: "ripton, vt",
  },
  {
    postal_code: "58741",
    zip_name: "granville, nd",
  },
  {
    postal_code: "18066",
    zip_name: "new tripoli, pa",
  },
  {
    postal_code: "47950",
    zip_name: "idaville, in",
  },
  {
    postal_code: "23952",
    zip_name: "lunenburg, va",
  },
  {
    postal_code: "97053",
    zip_name: "warren, or",
  },
  {
    postal_code: "59746",
    zip_name: "polaris, mt",
  },
  {
    postal_code: "36540",
    zip_name: "gainestown, al",
  },
  {
    postal_code: "63656",
    zip_name: "middle brook, mo",
  },
  {
    postal_code: "4740",
    zip_name: "easton, me",
  },
  {
    postal_code: "43930",
    zip_name: "hammondsville, oh",
  },
  {
    postal_code: "95140",
    zip_name: "mount hamilton, ca",
  },
  {
    postal_code: "29105",
    zip_name: "monetta, sc",
  },
  {
    postal_code: "19559",
    zip_name: "strausstown, pa",
  },
  {
    postal_code: "96038",
    zip_name: "grenada, ca",
  },
  {
    postal_code: "5260",
    zip_name: "north pownal, vt",
  },
  {
    postal_code: "95017",
    zip_name: "davenport, ca",
  },
  {
    postal_code: "49701",
    zip_name: "mackinaw city, mi",
  },
  {
    postal_code: "62701",
    zip_name: "springfield, il",
  },
  {
    postal_code: "33944",
    zip_name: "palmdale, fl",
  },
  {
    postal_code: "36786",
    zip_name: "uniontown, al",
  },
  {
    postal_code: "56262",
    zip_name: "milan, mn",
  },
  {
    postal_code: "45321",
    zip_name: "eldorado, oh",
  },
  {
    postal_code: "13635",
    zip_name: "edwards, ny",
  },
  {
    postal_code: "38347",
    zip_name: "jacks creek, tn",
  },
  {
    postal_code: "62284",
    zip_name: "smithboro, il",
  },
  {
    postal_code: "16360",
    zip_name: "townville, pa",
  },
  {
    postal_code: "61441",
    zip_name: "ipava, il",
  },
  {
    postal_code: "45821",
    zip_name: "cecil, oh",
  },
  {
    postal_code: "58736",
    zip_name: "drake, nd",
  },
  {
    postal_code: "41647",
    zip_name: "mc dowell, ky",
  },
  {
    postal_code: "60479",
    zip_name: "verona, il",
  },
  {
    postal_code: "14463",
    zip_name: "hall, ny",
  },
  {
    postal_code: "68784",
    zip_name: "wakefield, ne",
  },
  {
    postal_code: "58052",
    zip_name: "leonard, nd",
  },
  {
    postal_code: "37848",
    zip_name: "powder springs, tn",
  },
  {
    postal_code: "74368",
    zip_name: "twin oaks, ok",
  },
  {
    postal_code: "26804",
    zip_name: "circleville, wv",
  },
  {
    postal_code: "62947",
    zip_name: "herod, il",
  },
  {
    postal_code: "25124",
    zip_name: "liberty, wv",
  },
  {
    postal_code: "4693",
    zip_name: "winter harbor, me",
  },
  {
    postal_code: "57335",
    zip_name: "fairfax, sd",
  },
  {
    postal_code: "77597",
    zip_name: "wallisville, tx",
  },
  {
    postal_code: "76556",
    zip_name: "milano, tx",
  },
  {
    postal_code: "59728",
    zip_name: "elliston, mt",
  },
  {
    postal_code: "13632",
    zip_name: "depauville, ny",
  },
  {
    postal_code: "26337",
    zip_name: "cairo, wv",
  },
  {
    postal_code: "71466",
    zip_name: "otis, la",
  },
  {
    postal_code: "67062",
    zip_name: "hesston, ks",
  },
  {
    postal_code: "56010",
    zip_name: "amboy, mn",
  },
  {
    postal_code: "56363",
    zip_name: "pease, mn",
  },
  {
    postal_code: "42436",
    zip_name: "manitou, ky",
  },
  {
    postal_code: "24927",
    zip_name: "cass, wv",
  },
  {
    postal_code: "17246",
    zip_name: "pleasant hall, pa",
  },
  {
    postal_code: "71646",
    zip_name: "hamburg, ar",
  },
  {
    postal_code: "13303",
    zip_name: "ava, ny",
  },
  {
    postal_code: "50541",
    zip_name: "gilmore city, ia",
  },
  {
    postal_code: "7856",
    zip_name: "mount arlington, nj",
  },
  {
    postal_code: "62474",
    zip_name: "westfield, il",
  },
  {
    postal_code: "52351",
    zip_name: "walford, ia",
  },
  {
    postal_code: "76629",
    zip_name: "bremond, tx",
  },
  {
    postal_code: "28528",
    zip_name: "gloucester, nc",
  },
  {
    postal_code: "76888",
    zip_name: "voss, tx",
  },
  {
    postal_code: "38771",
    zip_name: "ruleville, ms",
  },
  {
    postal_code: "81062",
    zip_name: "olney springs, co",
  },
  {
    postal_code: "8340",
    zip_name: "milmay, nj",
  },
  {
    postal_code: "96051",
    zip_name: "lakehead, ca",
  },
  {
    postal_code: "56289",
    zip_name: "sunburg, mn",
  },
  {
    postal_code: "80823",
    zip_name: "karval, co",
  },
  {
    postal_code: "17763",
    zip_name: "ralston, pa",
  },
  {
    postal_code: "27356",
    zip_name: "star, nc",
  },
  {
    postal_code: "68767",
    zip_name: "pierce, ne",
  },
  {
    postal_code: "79255",
    zip_name: "quitaque, tx",
  },
  {
    postal_code: "52030",
    zip_name: "andrew, ia",
  },
  {
    postal_code: "67013",
    zip_name: "belle plaine, ks",
  },
  {
    postal_code: "50243",
    zip_name: "sheldahl, ia",
  },
  {
    postal_code: "41124",
    zip_name: "blaine, ky",
  },
  {
    postal_code: "50529",
    zip_name: "dakota city, ia",
  },
  {
    postal_code: "68713",
    zip_name: "atkinson, ne",
  },
  {
    postal_code: "39108",
    zip_name: "mc cool, ms",
  },
  {
    postal_code: "73540",
    zip_name: "faxon, ok",
  },
  {
    postal_code: "59252",
    zip_name: "outlook, mt",
  },
  {
    postal_code: "46982",
    zip_name: "silver lake, in",
  },
  {
    postal_code: "15780",
    zip_name: "valier, pa",
  },
  {
    postal_code: "50048",
    zip_name: "casey, ia",
  },
  {
    postal_code: "52720",
    zip_name: "atalissa, ia",
  },
  {
    postal_code: "4758",
    zip_name: "mars hill, me",
  },
  {
    postal_code: "97476",
    zip_name: "sixes, or",
  },
  {
    postal_code: "60951",
    zip_name: "martinton, il",
  },
  {
    postal_code: "46117",
    zip_name: "charlottesville, in",
  },
  {
    postal_code: "74442",
    zip_name: "indianola, ok",
  },
  {
    postal_code: "71861",
    zip_name: "taylor, ar",
  },
  {
    postal_code: "99730",
    zip_name: "central, ak",
  },
  {
    postal_code: "45032",
    zip_name: "harveysburg, oh",
  },
  {
    postal_code: "66941",
    zip_name: "esbon, ks",
  },
  {
    postal_code: "62810",
    zip_name: "belle rive, il",
  },
  {
    postal_code: "26327",
    zip_name: "berea, wv",
  },
  {
    postal_code: "58443",
    zip_name: "glenfield, nd",
  },
  {
    postal_code: "26346",
    zip_name: "ellenboro, wv",
  },
  {
    postal_code: "69133",
    zip_name: "dix, ne",
  },
  {
    postal_code: "48807",
    zip_name: "bannister, mi",
  },
  {
    postal_code: "83335",
    zip_name: "hazelton, id",
  },
  {
    postal_code: "26320",
    zip_name: "alma, wv",
  },
  {
    postal_code: "73754",
    zip_name: "lahoma, ok",
  },
  {
    postal_code: "54526",
    zip_name: "glen flora, wi",
  },
  {
    postal_code: "51439",
    zip_name: "charter oak, ia",
  },
  {
    postal_code: "36785",
    zip_name: "tyler, al",
  },
  {
    postal_code: "63345",
    zip_name: "farber, mo",
  },
  {
    postal_code: "71256",
    zip_name: "lillie, la",
  },
  {
    postal_code: "47117",
    zip_name: "elizabeth, in",
  },
  {
    postal_code: "47141",
    zip_name: "marysville, in",
  },
  {
    postal_code: "32631",
    zip_name: "earleton, fl",
  },
  {
    postal_code: "79842",
    zip_name: "marathon, tx",
  },
  {
    postal_code: "63447",
    zip_name: "la belle, mo",
  },
  {
    postal_code: "4642",
    zip_name: "harborside, me",
  },
  {
    postal_code: "68779",
    zip_name: "stanton, ne",
  },
  {
    postal_code: "39063",
    zip_name: "durant, ms",
  },
  {
    postal_code: "26621",
    zip_name: "flatwoods, wv",
  },
  {
    postal_code: "71660",
    zip_name: "new edinburg, ar",
  },
  {
    postal_code: "56375",
    zip_name: "saint stephen, mn",
  },
  {
    postal_code: "79081",
    zip_name: "spearman, tx",
  },
  {
    postal_code: "16868",
    zip_name: "pine grove mills, pa",
  },
  {
    postal_code: "14708",
    zip_name: "alma, ny",
  },
  {
    postal_code: "50599",
    zip_name: "woolstock, ia",
  },
  {
    postal_code: "64449",
    zip_name: "fillmore, mo",
  },
  {
    postal_code: "74471",
    zip_name: "welling, ok",
  },
  {
    postal_code: "95415",
    zip_name: "boonville, ca",
  },
  {
    postal_code: "39203",
    zip_name: "jackson, ms",
  },
  {
    postal_code: "26293",
    zip_name: "valley bend, wv",
  },
  {
    postal_code: "4419",
    zip_name: "carmel, me",
  },
  {
    postal_code: "42273",
    zip_name: "rochester, ky",
  },
  {
    postal_code: "40111",
    zip_name: "cloverport, ky",
  },
  {
    postal_code: "71740",
    zip_name: "emerson, ar",
  },
  {
    postal_code: "12760",
    zip_name: "long eddy, ny",
  },
  {
    postal_code: "65258",
    zip_name: "holliday, mo",
  },
  {
    postal_code: "31787",
    zip_name: "smithville, ga",
  },
  {
    postal_code: "79781",
    zip_name: "sheffield, tx",
  },
  {
    postal_code: "78877",
    zip_name: "quemado, tx",
  },
  {
    postal_code: "54767",
    zip_name: "spring valley, wi",
  },
  {
    postal_code: "68448",
    zip_name: "talmage, ne",
  },
  {
    postal_code: "58561",
    zip_name: "napoleon, nd",
  },
  {
    postal_code: "96085",
    zip_name: "scott bar, ca",
  },
  {
    postal_code: "95364",
    zip_name: "pinecrest, ca",
  },
  {
    postal_code: "96783",
    zip_name: "pepeekeo, hi",
  },
  {
    postal_code: "53937",
    zip_name: "hillpoint, wi",
  },
  {
    postal_code: "66510",
    zip_name: "melvern, ks",
  },
  {
    postal_code: "93603",
    zip_name: "badger, ca",
  },
  {
    postal_code: "15927",
    zip_name: "colver, pa",
  },
  {
    postal_code: "24631",
    zip_name: "oakwood, va",
  },
  {
    postal_code: "62419",
    zip_name: "calhoun, il",
  },
  {
    postal_code: "79014",
    zip_name: "canadian, tx",
  },
  {
    postal_code: "4360",
    zip_name: "vienna, me",
  },
  {
    postal_code: "84711",
    zip_name: "annabella, ut",
  },
  {
    postal_code: "12976",
    zip_name: "rainbow lake, ny",
  },
  {
    postal_code: "59747",
    zip_name: "pony, mt",
  },
  {
    postal_code: "21824",
    zip_name: "ewell, md",
  },
  {
    postal_code: "29032",
    zip_name: "cassatt, sc",
  },
  {
    postal_code: "46961",
    zip_name: "new waverly, in",
  },
  {
    postal_code: "22640",
    zip_name: "huntly, va",
  },
  {
    postal_code: "59337",
    zip_name: "jordan, mt",
  },
  {
    postal_code: "22736",
    zip_name: "richardsville, va",
  },
  {
    postal_code: "17925",
    zip_name: "brockton, pa",
  },
  {
    postal_code: "61552",
    zip_name: "mossville, il",
  },
  {
    postal_code: "56293",
    zip_name: "wabasso, mn",
  },
  {
    postal_code: "68715",
    zip_name: "battle creek, ne",
  },
  {
    postal_code: "73014",
    zip_name: "calumet, ok",
  },
  {
    postal_code: "69169",
    zip_name: "wallace, ne",
  },
  {
    postal_code: "78146",
    zip_name: "pettus, tx",
  },
  {
    postal_code: "4568",
    zip_name: "south bristol, me",
  },
  {
    postal_code: "14481",
    zip_name: "leicester, ny",
  },
  {
    postal_code: "77977",
    zip_name: "placedo, tx",
  },
  {
    postal_code: "38376",
    zip_name: "shiloh, tn",
  },
  {
    postal_code: "77971",
    zip_name: "lolita, tx",
  },
  {
    postal_code: "14541",
    zip_name: "romulus, ny",
  },
  {
    postal_code: "31519",
    zip_name: "broxton, ga",
  },
  {
    postal_code: "39337",
    zip_name: "little rock, ms",
  },
  {
    postal_code: "49404",
    zip_name: "coopersville, mi",
  },
  {
    postal_code: "26205",
    zip_name: "craigsville, wv",
  },
  {
    postal_code: "72566",
    zip_name: "pineville, ar",
  },
  {
    postal_code: "66854",
    zip_name: "hartford, ks",
  },
  {
    postal_code: "16840",
    zip_name: "hawk run, pa",
  },
  {
    postal_code: "62311",
    zip_name: "augusta, il",
  },
  {
    postal_code: "50440",
    zip_name: "grafton, ia",
  },
  {
    postal_code: "73654",
    zip_name: "leedey, ok",
  },
  {
    postal_code: "65440",
    zip_name: "boss, mo",
  },
  {
    postal_code: "58623",
    zip_name: "bowman, nd",
  },
  {
    postal_code: "41651",
    zip_name: "minnie, ky",
  },
  {
    postal_code: "78535",
    zip_name: "combes, tx",
  },
  {
    postal_code: "1032",
    zip_name: "goshen, ma",
  },
  {
    postal_code: "42404",
    zip_name: "clay, ky",
  },
  {
    postal_code: "68926",
    zip_name: "beaver city, ne",
  },
  {
    postal_code: "61771",
    zip_name: "secor, il",
  },
  {
    postal_code: "46941",
    zip_name: "lagro, in",
  },
  {
    postal_code: "59026",
    zip_name: "edgar, mt",
  },
  {
    postal_code: "29717",
    zip_name: "hickory grove, sc",
  },
  {
    postal_code: "26288",
    zip_name: "webster springs, wv",
  },
  {
    postal_code: "16034",
    zip_name: "fenelton, pa",
  },
  {
    postal_code: "52155",
    zip_name: "lime springs, ia",
  },
  {
    postal_code: "80455",
    zip_name: "jamestown, co",
  },
  {
    postal_code: "5669",
    zip_name: "roxbury, vt",
  },
  {
    postal_code: "72411",
    zip_name: "bay, ar",
  },
  {
    postal_code: "56585",
    zip_name: "ulen, mn",
  },
  {
    postal_code: "80448",
    zip_name: "grant, co",
  },
  {
    postal_code: "38332",
    zip_name: "enville, tn",
  },
  {
    postal_code: "80649",
    zip_name: "orchard, co",
  },
  {
    postal_code: "58781",
    zip_name: "sawyer, nd",
  },
  {
    postal_code: "95595",
    zip_name: "zenia, ca",
  },
  {
    postal_code: "62091",
    zip_name: "walshville, il",
  },
  {
    postal_code: "63359",
    zip_name: "middletown, mo",
  },
  {
    postal_code: "67576",
    zip_name: "st john, ks",
  },
  {
    postal_code: "40157",
    zip_name: "payneville, ky",
  },
  {
    postal_code: "52345",
    zip_name: "urbana, ia",
  },
  {
    postal_code: "66023",
    zip_name: "effingham, ks",
  },
  {
    postal_code: "35677",
    zip_name: "waterloo, al",
  },
  {
    postal_code: "38381",
    zip_name: "toone, tn",
  },
  {
    postal_code: "96763",
    zip_name: "lanai city, hi",
  },
  {
    postal_code: "72060",
    zip_name: "griffithville, ar",
  },
  {
    postal_code: "66533",
    zip_name: "rossville, ks",
  },
  {
    postal_code: "79359",
    zip_name: "seagraves, tx",
  },
  {
    postal_code: "52318",
    zip_name: "norway, ia",
  },
  {
    postal_code: "66010",
    zip_name: "blue mound, ks",
  },
  {
    postal_code: "50575",
    zip_name: "pomeroy, ia",
  },
  {
    postal_code: "62063",
    zip_name: "medora, il",
  },
  {
    postal_code: "61440",
    zip_name: "industry, il",
  },
  {
    postal_code: "84518",
    zip_name: "cleveland, ut",
  },
  {
    postal_code: "48873",
    zip_name: "pewamo, mi",
  },
  {
    postal_code: "52620",
    zip_name: "bonaparte, ia",
  },
  {
    postal_code: "95501",
    zip_name: "eureka, ca",
  },
  {
    postal_code: "83236",
    zip_name: "firth, id",
  },
  {
    postal_code: "25674",
    zip_name: "kermit, wv",
  },
  {
    postal_code: "45358",
    zip_name: "pitsburg, oh",
  },
  {
    postal_code: "98605",
    zip_name: "bingen, wa",
  },
  {
    postal_code: "73773",
    zip_name: "waukomis, ok",
  },
  {
    postal_code: "38610",
    zip_name: "blue mountain, ms",
  },
  {
    postal_code: "78584",
    zip_name: "roma, tx",
  },
  {
    postal_code: "66935",
    zip_name: "belleville, ks",
  },
  {
    postal_code: "15521",
    zip_name: "alum bank, pa",
  },
  {
    postal_code: "15772",
    zip_name: "rossiter, pa",
  },
  {
    postal_code: "73074",
    zip_name: "paoli, ok",
  },
  {
    postal_code: "54471",
    zip_name: "ringle, wi",
  },
  {
    postal_code: "14735",
    zip_name: "fillmore, ny",
  },
  {
    postal_code: "52352",
    zip_name: "walker, ia",
  },
  {
    postal_code: "68873",
    zip_name: "saint paul, ne",
  },
  {
    postal_code: "62324",
    zip_name: "clayton, il",
  },
  {
    postal_code: "53002",
    zip_name: "allenton, wi",
  },
  {
    postal_code: "19706",
    zip_name: "delaware city, de",
  },
  {
    postal_code: "4091",
    zip_name: "west baldwin, me",
  },
  {
    postal_code: "64097",
    zip_name: "wellington, mo",
  },
  {
    postal_code: "20868",
    zip_name: "spencerville, md",
  },
  {
    postal_code: "4774",
    zip_name: "saint francis, me",
  },
  {
    postal_code: "35585",
    zip_name: "spruce pine, al",
  },
  {
    postal_code: "83445",
    zip_name: "saint anthony, id",
  },
  {
    postal_code: "59221",
    zip_name: "fairview, mt",
  },
  {
    postal_code: "55602",
    zip_name: "brimson, mn",
  },
  {
    postal_code: "71833",
    zip_name: "dierks, ar",
  },
  {
    postal_code: "95925",
    zip_name: "challenge, ca",
  },
  {
    postal_code: "52218",
    zip_name: "coggon, ia",
  },
  {
    postal_code: "35988",
    zip_name: "sylvania, al",
  },
  {
    postal_code: "36579",
    zip_name: "stockton, al",
  },
  {
    postal_code: "3450",
    zip_name: "harrisville, nh",
  },
  {
    postal_code: "17037",
    zip_name: "ickesburg, pa",
  },
  {
    postal_code: "50626",
    zip_name: "dunkerton, ia",
  },
  {
    postal_code: "40823",
    zip_name: "cumberland, ky",
  },
  {
    postal_code: "25063",
    zip_name: "duck, wv",
  },
  {
    postal_code: "65620",
    zip_name: "bruner, mo",
  },
  {
    postal_code: "79849",
    zip_name: "san elizario, tx",
  },
  {
    postal_code: "77456",
    zip_name: "markham, tx",
  },
  {
    postal_code: "66026",
    zip_name: "fontana, ks",
  },
  {
    postal_code: "74726",
    zip_name: "bokchito, ok",
  },
  {
    postal_code: "5441",
    zip_name: "bakersfield, vt",
  },
  {
    postal_code: "71752",
    zip_name: "mc neil, ar",
  },
  {
    postal_code: "97430",
    zip_name: "deadwood, or",
  },
  {
    postal_code: "97122",
    zip_name: "hebo, or",
  },
  {
    postal_code: "14126",
    zip_name: "olcott, ny",
  },
  {
    postal_code: "24602",
    zip_name: "bandy, va",
  },
  {
    postal_code: "67004",
    zip_name: "argonia, ks",
  },
  {
    postal_code: "31042",
    zip_name: "irwinton, ga",
  },
  {
    postal_code: "47854",
    zip_name: "hillsdale, in",
  },
  {
    postal_code: "74536",
    zip_name: "clayton, ok",
  },
  {
    postal_code: "27212",
    zip_name: "blanch, nc",
  },
  {
    postal_code: "15954",
    zip_name: "seward, pa",
  },
  {
    postal_code: "71845",
    zip_name: "lewisville, ar",
  },
  {
    postal_code: "14506",
    zip_name: "mendon, ny",
  },
  {
    postal_code: "16860",
    zip_name: "munson, pa",
  },
  {
    postal_code: "31542",
    zip_name: "hoboken, ga",
  },
  {
    postal_code: "61437",
    zip_name: "gladstone, il",
  },
  {
    postal_code: "59927",
    zip_name: "olney, mt",
  },
  {
    postal_code: "67134",
    zip_name: "sawyer, ks",
  },
  {
    postal_code: "78357",
    zip_name: "freer, tx",
  },
  {
    postal_code: "71654",
    zip_name: "mc gehee, ar",
  },
  {
    postal_code: "24311",
    zip_name: "atkins, va",
  },
  {
    postal_code: "45761",
    zip_name: "millfield, oh",
  },
  {
    postal_code: "54631",
    zip_name: "gays mills, wi",
  },
  {
    postal_code: "62536",
    zip_name: "glenarm, il",
  },
  {
    postal_code: "80815",
    zip_name: "flagler, co",
  },
  {
    postal_code: "4956",
    zip_name: "new vineyard, me",
  },
  {
    postal_code: "57055",
    zip_name: "renner, sd",
  },
  {
    postal_code: "95493",
    zip_name: "witter springs, ca",
  },
  {
    postal_code: "44308",
    zip_name: "akron, oh",
  },
  {
    postal_code: "89004",
    zip_name: "blue diamond, nv",
  },
  {
    postal_code: "57241",
    zip_name: "hayti, sd",
  },
  {
    postal_code: "15377",
    zip_name: "west finley, pa",
  },
  {
    postal_code: "40902",
    zip_name: "arjay, ky",
  },
  {
    postal_code: "42367",
    zip_name: "powderly, ky",
  },
  {
    postal_code: "56681",
    zip_name: "squaw lake, mn",
  },
  {
    postal_code: "52254",
    zip_name: "lost nation, ia",
  },
  {
    postal_code: "98952",
    zip_name: "white swan, wa",
  },
  {
    postal_code: "68865",
    zip_name: "phillips, ne",
  },
  {
    postal_code: "54964",
    zip_name: "pickett, wi",
  },
  {
    postal_code: "47177",
    zip_name: "underwood, in",
  },
  {
    postal_code: "62054",
    zip_name: "kane, il",
  },
  {
    postal_code: "62015",
    zip_name: "butler, il",
  },
  {
    postal_code: "25252",
    zip_name: "le roy, wv",
  },
  {
    postal_code: "65582",
    zip_name: "vienna, mo",
  },
  {
    postal_code: "57332",
    zip_name: "emery, sd",
  },
  {
    postal_code: "75127",
    zip_name: "fruitvale, tx",
  },
  {
    postal_code: "22931",
    zip_name: "covesville, va",
  },
  {
    postal_code: "24458",
    zip_name: "mc dowell, va",
  },
  {
    postal_code: "71841",
    zip_name: "gillham, ar",
  },
  {
    postal_code: "65348",
    zip_name: "otterville, mo",
  },
  {
    postal_code: "95565",
    zip_name: "scotia, ca",
  },
  {
    postal_code: "93272",
    zip_name: "tipton, ca",
  },
  {
    postal_code: "46915",
    zip_name: "burlington, in",
  },
  {
    postal_code: "17923",
    zip_name: "branchdale, pa",
  },
  {
    postal_code: "87943",
    zip_name: "winston, nm",
  },
  {
    postal_code: "59078",
    zip_name: "shawmut, mt",
  },
  {
    postal_code: "87511",
    zip_name: "alcalde, nm",
  },
  {
    postal_code: "98263",
    zip_name: "lyman, wa",
  },
  {
    postal_code: "12448",
    zip_name: "lake hill, ny",
  },
  {
    postal_code: "15729",
    zip_name: "commodore, pa",
  },
  {
    postal_code: "14740",
    zip_name: "gerry, ny",
  },
  {
    postal_code: "31772",
    zip_name: "oakfield, ga",
  },
  {
    postal_code: "89007",
    zip_name: "bunkerville, nv",
  },
  {
    postal_code: "83633",
    zip_name: "king hill, id",
  },
  {
    postal_code: "67749",
    zip_name: "oberlin, ks",
  },
  {
    postal_code: "16843",
    zip_name: "hyde, pa",
  },
  {
    postal_code: "71362",
    zip_name: "plaucheville, la",
  },
  {
    postal_code: "95601",
    zip_name: "amador city, ca",
  },
  {
    postal_code: "67050",
    zip_name: "garden plain, ks",
  },
  {
    postal_code: "95941",
    zip_name: "forbestown, ca",
  },
  {
    postal_code: "58849",
    zip_name: "ray, nd",
  },
  {
    postal_code: "3823",
    zip_name: "madbury, nh",
  },
  {
    postal_code: "68638",
    zip_name: "fullerton, ne",
  },
  {
    postal_code: "96773",
    zip_name: "ninole, hi",
  },
  {
    postal_code: "18824",
    zip_name: "hop bottom, pa",
  },
  {
    postal_code: "14709",
    zip_name: "angelica, ny",
  },
  {
    postal_code: "39752",
    zip_name: "mathiston, ms",
  },
  {
    postal_code: "95547",
    zip_name: "hydesville, ca",
  },
  {
    postal_code: "88039",
    zip_name: "glenwood, nm",
  },
  {
    postal_code: "43971",
    zip_name: "yorkville, oh",
  },
  {
    postal_code: "63548",
    zip_name: "lancaster, mo",
  },
  {
    postal_code: "99826",
    zip_name: "gustavus, ak",
  },
  {
    postal_code: "23937",
    zip_name: "drakes branch, va",
  },
  {
    postal_code: "59411",
    zip_name: "babb, mt",
  },
  {
    postal_code: "8353",
    zip_name: "shiloh, nj",
  },
  {
    postal_code: "69021",
    zip_name: "benkelman, ne",
  },
  {
    postal_code: "68634",
    zip_name: "duncan, ne",
  },
  {
    postal_code: "84726",
    zip_name: "escalante, ut",
  },
  {
    postal_code: "4476",
    zip_name: "penobscot, me",
  },
  {
    postal_code: "35582",
    zip_name: "red bay, al",
  },
  {
    postal_code: "50447",
    zip_name: "kanawha, ia",
  },
  {
    postal_code: "2134",
    zip_name: "allston, ma",
  },
  {
    postal_code: "26631",
    zip_name: "napier, wv",
  },
  {
    postal_code: "58645",
    zip_name: "medora, nd",
  },
  {
    postal_code: "71435",
    zip_name: "grayson, la",
  },
  {
    postal_code: "15840",
    zip_name: "falls creek, pa",
  },
  {
    postal_code: "35643",
    zip_name: "hillsboro, al",
  },
  {
    postal_code: "68791",
    zip_name: "wisner, ne",
  },
  {
    postal_code: "53019",
    zip_name: "eden, wi",
  },
  {
    postal_code: "74557",
    zip_name: "moyers, ok",
  },
  {
    postal_code: "35470",
    zip_name: "livingston, al",
  },
  {
    postal_code: "95944",
    zip_name: "goodyears bar, ca",
  },
  {
    postal_code: "26662",
    zip_name: "canvas, wv",
  },
  {
    postal_code: "64096",
    zip_name: "waverly, mo",
  },
  {
    postal_code: "36904",
    zip_name: "butler, al",
  },
  {
    postal_code: "36558",
    zip_name: "millry, al",
  },
  {
    postal_code: "2047",
    zip_name: "humarock, ma",
  },
  {
    postal_code: "68769",
    zip_name: "plainview, ne",
  },
  {
    postal_code: "68823",
    zip_name: "burwell, ne",
  },
  {
    postal_code: "58274",
    zip_name: "portland, nd",
  },
  {
    postal_code: "62534",
    zip_name: "findlay, il",
  },
  {
    postal_code: "67349",
    zip_name: "howard, ks",
  },
  {
    postal_code: "14572",
    zip_name: "wayland, ny",
  },
  {
    postal_code: "56169",
    zip_name: "russell, mn",
  },
  {
    postal_code: "95511",
    zip_name: "alderpoint, ca",
  },
  {
    postal_code: "45349",
    zip_name: "north hampton, oh",
  },
  {
    postal_code: "96795",
    zip_name: "waimanalo, hi",
  },
  {
    postal_code: "95553",
    zip_name: "miranda, ca",
  },
  {
    postal_code: "76374",
    zip_name: "olney, tx",
  },
  {
    postal_code: "80758",
    zip_name: "wray, co",
  },
  {
    postal_code: "14568",
    zip_name: "walworth, ny",
  },
  {
    postal_code: "82225",
    zip_name: "lusk, wy",
  },
  {
    postal_code: "4110",
    zip_name: "cumberland foreside, me",
  },
  {
    postal_code: "66040",
    zip_name: "la cygne, ks",
  },
  {
    postal_code: "39341",
    zip_name: "macon, ms",
  },
  {
    postal_code: "10703",
    zip_name: "yonkers, ny",
  },
  {
    postal_code: "81091",
    zip_name: "weston, co",
  },
  {
    postal_code: "48621",
    zip_name: "fairview, mi",
  },
  {
    postal_code: "71001",
    zip_name: "arcadia, la",
  },
  {
    postal_code: "47436",
    zip_name: "heltonville, in",
  },
  {
    postal_code: "30473",
    zip_name: "uvalda, ga",
  },
  {
    postal_code: "4430",
    zip_name: "east millinocket, me",
  },
  {
    postal_code: "10968",
    zip_name: "piermont, ny",
  },
  {
    postal_code: "38317",
    zip_name: "bruceton, tn",
  },
  {
    postal_code: "81423",
    zip_name: "norwood, co",
  },
  {
    postal_code: "62863",
    zip_name: "mount carmel, il",
  },
  {
    postal_code: "8097",
    zip_name: "woodbury heights, nj",
  },
  {
    postal_code: "35748",
    zip_name: "gurley, al",
  },
  {
    postal_code: "24064",
    zip_name: "blue ridge, va",
  },
  {
    postal_code: "62827",
    zip_name: "crossville, il",
  },
  {
    postal_code: "49748",
    zip_name: "hulbert, mi",
  },
  {
    postal_code: "14739",
    zip_name: "friendship, ny",
  },
  {
    postal_code: "65270",
    zip_name: "moberly, mo",
  },
  {
    postal_code: "35619",
    zip_name: "danville, al",
  },
  {
    postal_code: "82331",
    zip_name: "saratoga, wy",
  },
  {
    postal_code: "38559",
    zip_name: "doyle, tn",
  },
  {
    postal_code: "72943",
    zip_name: "magazine, ar",
  },
  {
    postal_code: "82720",
    zip_name: "hulett, wy",
  },
  {
    postal_code: "7980",
    zip_name: "stirling, nj",
  },
  {
    postal_code: "70591",
    zip_name: "welsh, la",
  },
  {
    postal_code: "15722",
    zip_name: "carrolltown, pa",
  },
  {
    postal_code: "48374",
    zip_name: "novi, mi",
  },
  {
    postal_code: "1083",
    zip_name: "warren, ma",
  },
  {
    postal_code: "53034",
    zip_name: "hustisford, wi",
  },
  {
    postal_code: "46511",
    zip_name: "culver, in",
  },
  {
    postal_code: "48063",
    zip_name: "columbus, mi",
  },
  {
    postal_code: "46783",
    zip_name: "roanoke, in",
  },
  {
    postal_code: "39459",
    zip_name: "moselle, ms",
  },
  {
    postal_code: "67356",
    zip_name: "oswego, ks",
  },
  {
    postal_code: "52572",
    zip_name: "moulton, ia",
  },
  {
    postal_code: "58845",
    zip_name: "grenora, nd",
  },
  {
    postal_code: "35747",
    zip_name: "grant, al",
  },
  {
    postal_code: "88301",
    zip_name: "carrizozo, nm",
  },
  {
    postal_code: "29853",
    zip_name: "williston, sc",
  },
  {
    postal_code: "41602",
    zip_name: "auxier, ky",
  },
  {
    postal_code: "31647",
    zip_name: "sparks, ga",
  },
  {
    postal_code: "71103",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "71226",
    zip_name: "chatham, la",
  },
  {
    postal_code: "35824",
    zip_name: "huntsville, al",
  },
  {
    postal_code: "24578",
    zip_name: "natural bridge, va",
  },
  {
    postal_code: "46532",
    zip_name: "hamlet, in",
  },
  {
    postal_code: "1341",
    zip_name: "conway, ma",
  },
  {
    postal_code: "8853",
    zip_name: "neshanic station, nj",
  },
  {
    postal_code: "14416",
    zip_name: "bergen, ny",
  },
  {
    postal_code: "56183",
    zip_name: "westbrook, mn",
  },
  {
    postal_code: "17881",
    zip_name: "trevorton, pa",
  },
  {
    postal_code: "95528",
    zip_name: "carlotta, ca",
  },
  {
    postal_code: "78061",
    zip_name: "pearsall, tx",
  },
  {
    postal_code: "4761",
    zip_name: "new limerick, me",
  },
  {
    postal_code: "36205",
    zip_name: "anniston, al",
  },
  {
    postal_code: "59918",
    zip_name: "fortine, mt",
  },
  {
    postal_code: "71323",
    zip_name: "center point, la",
  },
  {
    postal_code: "96006",
    zip_name: "adin, ca",
  },
  {
    postal_code: "16854",
    zip_name: "millheim, pa",
  },
  {
    postal_code: "17983",
    zip_name: "valley view, pa",
  },
  {
    postal_code: "17211",
    zip_name: "artemas, pa",
  },
  {
    postal_code: "8828",
    zip_name: "helmetta, nj",
  },
  {
    postal_code: "36619",
    zip_name: "mobile, al",
  },
  {
    postal_code: "70722",
    zip_name: "clinton, la",
  },
  {
    postal_code: "29481",
    zip_name: "smoaks, sc",
  },
  {
    postal_code: "53103",
    zip_name: "big bend, wi",
  },
  {
    postal_code: "81233",
    zip_name: "howard, co",
  },
  {
    postal_code: "70778",
    zip_name: "sorrento, la",
  },
  {
    postal_code: "63435",
    zip_name: "canton, mo",
  },
  {
    postal_code: "53178",
    zip_name: "sullivan, wi",
  },
  {
    postal_code: "29069",
    zip_name: "lamar, sc",
  },
  {
    postal_code: "12791",
    zip_name: "youngsville, ny",
  },
  {
    postal_code: "14769",
    zip_name: "portland, ny",
  },
  {
    postal_code: "51035",
    zip_name: "marcus, ia",
  },
  {
    postal_code: "12577",
    zip_name: "salisbury mills, ny",
  },
  {
    postal_code: "53130",
    zip_name: "hales corners, wi",
  },
  {
    postal_code: "59353",
    zip_name: "wibaux, mt",
  },
  {
    postal_code: "25917",
    zip_name: "scarbro, wv",
  },
  {
    postal_code: "72946",
    zip_name: "mountainburg, ar",
  },
  {
    postal_code: "48730",
    zip_name: "east tawas, mi",
  },
  {
    postal_code: "67654",
    zip_name: "norton, ks",
  },
  {
    postal_code: "13363",
    zip_name: "lee center, ny",
  },
  {
    postal_code: "68038",
    zip_name: "lyons, ne",
  },
  {
    postal_code: "59916",
    zip_name: "essex, mt",
  },
  {
    postal_code: "71937",
    zip_name: "cove, ar",
  },
  {
    postal_code: "81040",
    zip_name: "gardner, co",
  },
  {
    postal_code: "72562",
    zip_name: "newark, ar",
  },
  {
    postal_code: "16914",
    zip_name: "columbia cross roads, pa",
  },
  {
    postal_code: "4783",
    zip_name: "stockholm, me",
  },
  {
    postal_code: "10964",
    zip_name: "palisades, ny",
  },
  {
    postal_code: "41666",
    zip_name: "wayland, ky",
  },
  {
    postal_code: "96028",
    zip_name: "fall river mills, ca",
  },
  {
    postal_code: "78147",
    zip_name: "poth, tx",
  },
  {
    postal_code: "53075",
    zip_name: "random lake, wi",
  },
  {
    postal_code: "73651",
    zip_name: "hobart, ok",
  },
  {
    postal_code: "62848",
    zip_name: "irvington, il",
  },
  {
    postal_code: "75435",
    zip_name: "deport, tx",
  },
  {
    postal_code: "95526",
    zip_name: "bridgeville, ca",
  },
  {
    postal_code: "54125",
    zip_name: "goodman, wi",
  },
  {
    postal_code: "23410",
    zip_name: "melfa, va",
  },
  {
    postal_code: "10526",
    zip_name: "goldens bridge, ny",
  },
  {
    postal_code: "52346",
    zip_name: "van horne, ia",
  },
  {
    postal_code: "47660",
    zip_name: "oakland city, in",
  },
  {
    postal_code: "47635",
    zip_name: "rockport, in",
  },
  {
    postal_code: "52776",
    zip_name: "west liberty, ia",
  },
  {
    postal_code: "74902",
    zip_name: "pocola, ok",
  },
  {
    postal_code: "70395",
    zip_name: "schriever, la",
  },
  {
    postal_code: "24175",
    zip_name: "troutville, va",
  },
  {
    postal_code: "17929",
    zip_name: "cressona, pa",
  },
  {
    postal_code: "17509",
    zip_name: "christiana, pa",
  },
  {
    postal_code: "7950",
    zip_name: "morris plains, nj",
  },
  {
    postal_code: "96730",
    zip_name: "kaaawa, hi",
  },
  {
    postal_code: "7603",
    zip_name: "bogota, nj",
  },
  {
    postal_code: "35670",
    zip_name: "somerville, al",
  },
  {
    postal_code: "83845",
    zip_name: "moyie springs, id",
  },
  {
    postal_code: "53146",
    zip_name: "new berlin, wi",
  },
  {
    postal_code: "46714",
    zip_name: "bluffton, in",
  },
  {
    postal_code: "14717",
    zip_name: "caneadea, ny",
  },
  {
    postal_code: "35586",
    zip_name: "sulligent, al",
  },
  {
    postal_code: "53211",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "4551",
    zip_name: "bremen, me",
  },
  {
    postal_code: "62675",
    zip_name: "petersburg, il",
  },
  {
    postal_code: "45845",
    zip_name: "fort loramie, oh",
  },
  {
    postal_code: "75944",
    zip_name: "etoile, tx",
  },
  {
    postal_code: "51028",
    zip_name: "kingsley, ia",
  },
  {
    postal_code: "42442",
    zip_name: "nortonville, ky",
  },
  {
    postal_code: "41238",
    zip_name: "oil springs, ky",
  },
  {
    postal_code: "84744",
    zip_name: "koosharem, ut",
  },
  {
    postal_code: "21865",
    zip_name: "tyaskin, md",
  },
  {
    postal_code: "56556",
    zip_name: "mcintosh, mn",
  },
  {
    postal_code: "93450",
    zip_name: "san ardo, ca",
  },
  {
    postal_code: "53058",
    zip_name: "nashotah, wi",
  },
  {
    postal_code: "38673",
    zip_name: "taylor, ms",
  },
  {
    postal_code: "39041",
    zip_name: "bolton, ms",
  },
  {
    postal_code: "57349",
    zip_name: "howard, sd",
  },
  {
    postal_code: "39345",
    zip_name: "newton, ms",
  },
  {
    postal_code: "45812",
    zip_name: "alger, oh",
  },
  {
    postal_code: "14507",
    zip_name: "middlesex, ny",
  },
  {
    postal_code: "28649",
    zip_name: "mc grady, nc",
  },
  {
    postal_code: "54420",
    zip_name: "chili, wi",
  },
  {
    postal_code: "46741",
    zip_name: "grabill, in",
  },
  {
    postal_code: "22714",
    zip_name: "brandy station, va",
  },
  {
    postal_code: "62330",
    zip_name: "dallas city, il",
  },
  {
    postal_code: "47613",
    zip_name: "elberfeld, in",
  },
  {
    postal_code: "15444",
    zip_name: "hiller, pa",
  },
  {
    postal_code: "96105",
    zip_name: "chilcoot, ca",
  },
  {
    postal_code: "43977",
    zip_name: "flushing, oh",
  },
  {
    postal_code: "81025",
    zip_name: "boone, co",
  },
  {
    postal_code: "11939",
    zip_name: "east marion, ny",
  },
  {
    postal_code: "35034",
    zip_name: "brent, al",
  },
  {
    postal_code: "54485",
    zip_name: "summit lake, wi",
  },
  {
    postal_code: "98822",
    zip_name: "entiat, wa",
  },
  {
    postal_code: "82428",
    zip_name: "hyattville, wy",
  },
  {
    postal_code: "24566",
    zip_name: "keeling, va",
  },
  {
    postal_code: "10523",
    zip_name: "elmsford, ny",
  },
  {
    postal_code: "97371",
    zip_name: "rickreall, or",
  },
  {
    postal_code: "14135",
    zip_name: "sheridan, ny",
  },
  {
    postal_code: "87553",
    zip_name: "penasco, nm",
  },
  {
    postal_code: "52225",
    zip_name: "elberon, ia",
  },
  {
    postal_code: "99829",
    zip_name: "hoonah, ak",
  },
  {
    postal_code: "62668",
    zip_name: "murrayville, il",
  },
  {
    postal_code: "38475",
    zip_name: "olivehill, tn",
  },
  {
    postal_code: "49241",
    zip_name: "hanover, mi",
  },
  {
    postal_code: "50466",
    zip_name: "riceville, ia",
  },
  {
    postal_code: "62844",
    zip_name: "grayville, il",
  },
  {
    postal_code: "95715",
    zip_name: "emigrant gap, ca",
  },
  {
    postal_code: "54737",
    zip_name: "eau galle, wi",
  },
  {
    postal_code: "72938",
    zip_name: "hartford, ar",
  },
  {
    postal_code: "87051",
    zip_name: "san rafael, nm",
  },
  {
    postal_code: "36850",
    zip_name: "camp hill, al",
  },
  {
    postal_code: "72847",
    zip_name: "london, ar",
  },
  {
    postal_code: "19551",
    zip_name: "robesonia, pa",
  },
  {
    postal_code: "10914",
    zip_name: "blooming grove, ny",
  },
  {
    postal_code: "97907",
    zip_name: "huntington, or",
  },
  {
    postal_code: "80103",
    zip_name: "byers, co",
  },
  {
    postal_code: "53554",
    zip_name: "livingston, wi",
  },
  {
    postal_code: "75439",
    zip_name: "ector, tx",
  },
  {
    postal_code: "15938",
    zip_name: "lilly, pa",
  },
  {
    postal_code: "63534",
    zip_name: "callao, mo",
  },
  {
    postal_code: "5346",
    zip_name: "putney, vt",
  },
  {
    postal_code: "95645",
    zip_name: "knights landing, ca",
  },
  {
    postal_code: "43942",
    zip_name: "powhatan point, oh",
  },
  {
    postal_code: "91906",
    zip_name: "campo, ca",
  },
  {
    postal_code: "88047",
    zip_name: "mesilla park, nm",
  },
  {
    postal_code: "71653",
    zip_name: "lake village, ar",
  },
  {
    postal_code: "71456",
    zip_name: "natchez, la",
  },
  {
    postal_code: "84747",
    zip_name: "loa, ut",
  },
  {
    postal_code: "50222",
    zip_name: "peru, ia",
  },
  {
    postal_code: "68466",
    zip_name: "wymore, ne",
  },
  {
    postal_code: "47901",
    zip_name: "lafayette, in",
  },
  {
    postal_code: "20667",
    zip_name: "park hall, md",
  },
  {
    postal_code: "68450",
    zip_name: "tecumseh, ne",
  },
  {
    postal_code: "72773",
    zip_name: "wesley, ar",
  },
  {
    postal_code: "41049",
    zip_name: "hillsboro, ky",
  },
  {
    postal_code: "16020",
    zip_name: "boyers, pa",
  },
  {
    postal_code: "76622",
    zip_name: "aquilla, tx",
  },
  {
    postal_code: "74641",
    zip_name: "kaw city, ok",
  },
  {
    postal_code: "22432",
    zip_name: "burgess, va",
  },
  {
    postal_code: "30455",
    zip_name: "rocky ford, ga",
  },
  {
    postal_code: "42464",
    zip_name: "white plains, ky",
  },
  {
    postal_code: "96096",
    zip_name: "whitmore, ca",
  },
  {
    postal_code: "29628",
    zip_name: "calhoun falls, sc",
  },
  {
    postal_code: "72579",
    zip_name: "sulphur rock, ar",
  },
  {
    postal_code: "72331",
    zip_name: "earle, ar",
  },
  {
    postal_code: "87749",
    zip_name: "ute park, nm",
  },
  {
    postal_code: "54490",
    zip_name: "westboro, wi",
  },
  {
    postal_code: "17353",
    zip_name: "orrtanna, pa",
  },
  {
    postal_code: "31781",
    zip_name: "poulan, ga",
  },
  {
    postal_code: "44428",
    zip_name: "kinsman, oh",
  },
  {
    postal_code: "64021",
    zip_name: "corder, mo",
  },
  {
    postal_code: "38039",
    zip_name: "grand junction, tn",
  },
  {
    postal_code: "25646",
    zip_name: "stollings, wv",
  },
  {
    postal_code: "3765",
    zip_name: "haverhill, nh",
  },
  {
    postal_code: "10958",
    zip_name: "new hampton, ny",
  },
  {
    postal_code: "48418",
    zip_name: "byron, mi",
  },
  {
    postal_code: "52147",
    zip_name: "hawkeye, ia",
  },
  {
    postal_code: "60549",
    zip_name: "serena, il",
  },
  {
    postal_code: "51453",
    zip_name: "lohrville, ia",
  },
  {
    postal_code: "76877",
    zip_name: "san saba, tx",
  },
  {
    postal_code: "65685",
    zip_name: "louisburg, mo",
  },
  {
    postal_code: "18602",
    zip_name: "bear creek, pa",
  },
  {
    postal_code: "73533",
    zip_name: "duncan, ok",
  },
  {
    postal_code: "26743",
    zip_name: "new creek, wv",
  },
  {
    postal_code: "5767",
    zip_name: "rochester, vt",
  },
  {
    postal_code: "40155",
    zip_name: "muldraugh, ky",
  },
  {
    postal_code: "36742",
    zip_name: "gallion, al",
  },
  {
    postal_code: "28444",
    zip_name: "harrells, nc",
  },
  {
    postal_code: "31057",
    zip_name: "marshallville, ga",
  },
  {
    postal_code: "19373",
    zip_name: "thornton, pa",
  },
  {
    postal_code: "94937",
    zip_name: "inverness, ca",
  },
  {
    postal_code: "95589",
    zip_name: "whitethorn, ca",
  },
  {
    postal_code: "76252",
    zip_name: "muenster, tx",
  },
  {
    postal_code: "48766",
    zip_name: "twining, mi",
  },
  {
    postal_code: "99118",
    zip_name: "curlew, wa",
  },
  {
    postal_code: "18443",
    zip_name: "milanville, pa",
  },
  {
    postal_code: "46960",
    zip_name: "monterey, in",
  },
  {
    postal_code: "63766",
    zip_name: "millersville, mo",
  },
  {
    postal_code: "58012",
    zip_name: "casselton, nd",
  },
  {
    postal_code: "87016",
    zip_name: "estancia, nm",
  },
  {
    postal_code: "45645",
    zip_name: "kitts hill, oh",
  },
  {
    postal_code: "62964",
    zip_name: "mounds, il",
  },
  {
    postal_code: "15324",
    zip_name: "cokeburg, pa",
  },
  {
    postal_code: "96009",
    zip_name: "bieber, ca",
  },
  {
    postal_code: "17948",
    zip_name: "mahanoy city, pa",
  },
  {
    postal_code: "76455",
    zip_name: "gustine, tx",
  },
  {
    postal_code: "32462",
    zip_name: "vernon, fl",
  },
  {
    postal_code: "25864",
    zip_name: "layland, wv",
  },
  {
    postal_code: "50519",
    zip_name: "bode, ia",
  },
  {
    postal_code: "52135",
    zip_name: "clermont, ia",
  },
  {
    postal_code: "12517",
    zip_name: "copake falls, ny",
  },
  {
    postal_code: "71071",
    zip_name: "sarepta, la",
  },
  {
    postal_code: "25507",
    zip_name: "ceredo, wv",
  },
  {
    postal_code: "62949",
    zip_name: "hurst, il",
  },
  {
    postal_code: "22535",
    zip_name: "port royal, va",
  },
  {
    postal_code: "31565",
    zip_name: "waverly, ga",
  },
  {
    postal_code: "56540",
    zip_name: "fertile, mn",
  },
  {
    postal_code: "17365",
    zip_name: "wellsville, pa",
  },
  {
    postal_code: "87732",
    zip_name: "mora, nm",
  },
  {
    postal_code: "92257",
    zip_name: "niland, ca",
  },
  {
    postal_code: "88230",
    zip_name: "dexter, nm",
  },
  {
    postal_code: "48831",
    zip_name: "elsie, mi",
  },
  {
    postal_code: "3593",
    zip_name: "randolph, nh",
  },
  {
    postal_code: "72852",
    zip_name: "oark, ar",
  },
  {
    postal_code: "49026",
    zip_name: "bloomingdale, mi",
  },
  {
    postal_code: "8848",
    zip_name: "milford, nj",
  },
  {
    postal_code: "46926",
    zip_name: "denver, in",
  },
  {
    postal_code: "54442",
    zip_name: "irma, wi",
  },
  {
    postal_code: "39739",
    zip_name: "brooksville, ms",
  },
  {
    postal_code: "72856",
    zip_name: "pelsor, ar",
  },
  {
    postal_code: "68034",
    zip_name: "kennard, ne",
  },
  {
    postal_code: "72342",
    zip_name: "helena, ar",
  },
  {
    postal_code: "56051",
    zip_name: "kiester, mn",
  },
  {
    postal_code: "27919",
    zip_name: "belvidere, nc",
  },
  {
    postal_code: "20692",
    zip_name: "valley lee, md",
  },
  {
    postal_code: "65630",
    zip_name: "chestnutridge, mo",
  },
  {
    postal_code: "36444",
    zip_name: "franklin, al",
  },
  {
    postal_code: "36343",
    zip_name: "gordon, al",
  },
  {
    postal_code: "56533",
    zip_name: "elizabeth, mn",
  },
  {
    postal_code: "96033",
    zip_name: "french gulch, ca",
  },
  {
    postal_code: "87083",
    zip_name: "cochiti lake, nm",
  },
  {
    postal_code: "93621",
    zip_name: "dunlap, ca",
  },
  {
    postal_code: "74470",
    zip_name: "webbers falls, ok",
  },
  {
    postal_code: "39364",
    zip_name: "toomsuba, ms",
  },
  {
    postal_code: "82083",
    zip_name: "rock river, wy",
  },
  {
    postal_code: "48026",
    zip_name: "fraser, mi",
  },
  {
    postal_code: "54652",
    zip_name: "readstown, wi",
  },
  {
    postal_code: "7832",
    zip_name: "columbia, nj",
  },
  {
    postal_code: "12480",
    zip_name: "shandaken, ny",
  },
  {
    postal_code: "62077",
    zip_name: "panama, il",
  },
  {
    postal_code: "52154",
    zip_name: "lawler, ia",
  },
  {
    postal_code: "46958",
    zip_name: "mexico, in",
  },
  {
    postal_code: "59212",
    zip_name: "bainville, mt",
  },
  {
    postal_code: "72583",
    zip_name: "viola, ar",
  },
  {
    postal_code: "49663",
    zip_name: "manton, mi",
  },
  {
    postal_code: "39423",
    zip_name: "beaumont, ms",
  },
  {
    postal_code: "18955",
    zip_name: "richlandtown, pa",
  },
  {
    postal_code: "25823",
    zip_name: "coal city, wv",
  },
  {
    postal_code: "8542",
    zip_name: "princeton, nj",
  },
  {
    postal_code: "71325",
    zip_name: "cheneyville, la",
  },
  {
    postal_code: "77442",
    zip_name: "garwood, tx",
  },
  {
    postal_code: "84755",
    zip_name: "mount carmel, ut",
  },
  {
    postal_code: "28579",
    zip_name: "smyrna, nc",
  },
  {
    postal_code: "45844",
    zip_name: "fort jennings, oh",
  },
  {
    postal_code: "54006",
    zip_name: "cushing, wi",
  },
  {
    postal_code: "47385",
    zip_name: "spiceland, in",
  },
  {
    postal_code: "32428",
    zip_name: "chipley, fl",
  },
  {
    postal_code: "47431",
    zip_name: "freedom, in",
  },
  {
    postal_code: "63852",
    zip_name: "holcomb, mo",
  },
  {
    postal_code: "27855",
    zip_name: "murfreesboro, nc",
  },
  {
    postal_code: "4219",
    zip_name: "bryant pond, me",
  },
  {
    postal_code: "53083",
    zip_name: "sheboygan, wi",
  },
  {
    postal_code: "60961",
    zip_name: "reddick, il",
  },
  {
    postal_code: "47438",
    zip_name: "jasonville, in",
  },
  {
    postal_code: "73566",
    zip_name: "snyder, ok",
  },
  {
    postal_code: "58275",
    zip_name: "reynolds, nd",
  },
  {
    postal_code: "17853",
    zip_name: "mount pleasant mills, pa",
  },
  {
    postal_code: "99032",
    zip_name: "sprague, wa",
  },
  {
    postal_code: "66537",
    zip_name: "scranton, ks",
  },
  {
    postal_code: "49905",
    zip_name: "atlantic mine, mi",
  },
  {
    postal_code: "70041",
    zip_name: "buras, la",
  },
  {
    postal_code: "61318",
    zip_name: "compton, il",
  },
  {
    postal_code: "62666",
    zip_name: "middletown, il",
  },
  {
    postal_code: "26292",
    zip_name: "thomas, wv",
  },
  {
    postal_code: "74570",
    zip_name: "stuart, ok",
  },
  {
    postal_code: "27946",
    zip_name: "hobbsville, nc",
  },
  {
    postal_code: "26444",
    zip_name: "tunnelton, wv",
  },
  {
    postal_code: "73762",
    zip_name: "okarche, ok",
  },
  {
    postal_code: "21622",
    zip_name: "church creek, md",
  },
  {
    postal_code: "87931",
    zip_name: "caballo, nm",
  },
  {
    postal_code: "18214",
    zip_name: "barnesville, pa",
  },
  {
    postal_code: "48767",
    zip_name: "unionville, mi",
  },
  {
    postal_code: "12741",
    zip_name: "hankins, ny",
  },
  {
    postal_code: "8836",
    zip_name: "martinsville, nj",
  },
  {
    postal_code: "83848",
    zip_name: "nordman, id",
  },
  {
    postal_code: "32190",
    zip_name: "seville, fl",
  },
  {
    postal_code: "75928",
    zip_name: "bon wier, tx",
  },
  {
    postal_code: "74370",
    zip_name: "wyandotte, ok",
  },
  {
    postal_code: "23350",
    zip_name: "exmore, va",
  },
  {
    postal_code: "15711",
    zip_name: "anita, pa",
  },
  {
    postal_code: "54113",
    zip_name: "combined locks, wi",
  },
  {
    postal_code: "79313",
    zip_name: "anton, tx",
  },
  {
    postal_code: "29469",
    zip_name: "pinopolis, sc",
  },
  {
    postal_code: "74840",
    zip_name: "earlsboro, ok",
  },
  {
    postal_code: "57442",
    zip_name: "gettysburg, sd",
  },
  {
    postal_code: "37402",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "15357",
    zip_name: "rices landing, pa",
  },
  {
    postal_code: "71007",
    zip_name: "bethany, la",
  },
  {
    postal_code: "52161",
    zip_name: "ossian, ia",
  },
  {
    postal_code: "24599",
    zip_name: "wingina, va",
  },
  {
    postal_code: "39347",
    zip_name: "pachuta, ms",
  },
  {
    postal_code: "96791",
    zip_name: "waialua, hi",
  },
  {
    postal_code: "71355",
    zip_name: "moreauville, la",
  },
  {
    postal_code: "48749",
    zip_name: "omer, mi",
  },
  {
    postal_code: "95313",
    zip_name: "crows landing, ca",
  },
  {
    postal_code: "83443",
    zip_name: "ririe, id",
  },
  {
    postal_code: "61563",
    zip_name: "saint david, il",
  },
  {
    postal_code: "62094",
    zip_name: "witt, il",
  },
  {
    postal_code: "22488",
    zip_name: "kinsale, va",
  },
  {
    postal_code: "88042",
    zip_name: "hillsboro, nm",
  },
  {
    postal_code: "49544",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "74832",
    zip_name: "carney, ok",
  },
  {
    postal_code: "7626",
    zip_name: "cresskill, nj",
  },
  {
    postal_code: "73121",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "81523",
    zip_name: "glade park, co",
  },
  {
    postal_code: "37317",
    zip_name: "copperhill, tn",
  },
  {
    postal_code: "7114",
    zip_name: "newark, nj",
  },
  {
    postal_code: "17401",
    zip_name: "york, pa",
  },
  {
    postal_code: "54614",
    zip_name: "bangor, wi",
  },
  {
    postal_code: "61951",
    zip_name: "sullivan, il",
  },
  {
    postal_code: "23068",
    zip_name: "hallieford, va",
  },
  {
    postal_code: "30410",
    zip_name: "ailey, ga",
  },
  {
    postal_code: "26351",
    zip_name: "glenville, wv",
  },
  {
    postal_code: "88316",
    zip_name: "capitan, nm",
  },
  {
    postal_code: "97834",
    zip_name: "halfway, or",
  },
  {
    postal_code: "64748",
    zip_name: "golden city, mo",
  },
  {
    postal_code: "89060",
    zip_name: "pahrump, nv",
  },
  {
    postal_code: "73149",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "35019",
    zip_name: "baileyton, al",
  },
  {
    postal_code: "7514",
    zip_name: "paterson, nj",
  },
  {
    postal_code: "64473",
    zip_name: "oregon, mo",
  },
  {
    postal_code: "42020",
    zip_name: "almo, ky",
  },
  {
    postal_code: "51342",
    zip_name: "graettinger, ia",
  },
  {
    postal_code: "32350",
    zip_name: "pinetta, fl",
  },
  {
    postal_code: "99180",
    zip_name: "usk, wa",
  },
  {
    postal_code: "65240",
    zip_name: "centralia, mo",
  },
  {
    postal_code: "12998",
    zip_name: "witherbee, ny",
  },
  {
    postal_code: "17562",
    zip_name: "paradise, pa",
  },
  {
    postal_code: "10973",
    zip_name: "slate hill, ny",
  },
  {
    postal_code: "82325",
    zip_name: "encampment, wy",
  },
  {
    postal_code: "59313",
    zip_name: "baker, mt",
  },
  {
    postal_code: "31833",
    zip_name: "west point, ga",
  },
  {
    postal_code: "72046",
    zip_name: "england, ar",
  },
  {
    postal_code: "18614",
    zip_name: "dushore, pa",
  },
  {
    postal_code: "99503",
    zip_name: "anchorage, ak",
  },
  {
    postal_code: "67215",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "23163",
    zip_name: "susan, va",
  },
  {
    postal_code: "5868",
    zip_name: "troy, vt",
  },
  {
    postal_code: "5905",
    zip_name: "guildhall, vt",
  },
  {
    postal_code: "29061",
    zip_name: "hopkins, sc",
  },
  {
    postal_code: "72927",
    zip_name: "booneville, ar",
  },
  {
    postal_code: "58761",
    zip_name: "mohall, nd",
  },
  {
    postal_code: "97492",
    zip_name: "westfir, or",
  },
  {
    postal_code: "71016",
    zip_name: "castor, la",
  },
  {
    postal_code: "19567",
    zip_name: "womelsdorf, pa",
  },
  {
    postal_code: "46031",
    zip_name: "atlanta, in",
  },
  {
    postal_code: "48834",
    zip_name: "fenwick, mi",
  },
  {
    postal_code: "65018",
    zip_name: "california, mo",
  },
  {
    postal_code: "39735",
    zip_name: "ackerman, ms",
  },
  {
    postal_code: "36505",
    zip_name: "axis, al",
  },
  {
    postal_code: "68727",
    zip_name: "coleridge, ne",
  },
  {
    postal_code: "71837",
    zip_name: "fouke, ar",
  },
  {
    postal_code: "15436",
    zip_name: "fairchance, pa",
  },
  {
    postal_code: "7660",
    zip_name: "ridgefield park, nj",
  },
  {
    postal_code: "74554",
    zip_name: "krebs, ok",
  },
  {
    postal_code: "97641",
    zip_name: "christmas valley, or",
  },
  {
    postal_code: "23418",
    zip_name: "onley, va",
  },
  {
    postal_code: "59412",
    zip_name: "belt, mt",
  },
  {
    postal_code: "72952",
    zip_name: "rudy, ar",
  },
  {
    postal_code: "81434",
    zip_name: "somerset, co",
  },
  {
    postal_code: "78609",
    zip_name: "buchanan dam, tx",
  },
  {
    postal_code: "46702",
    zip_name: "andrews, in",
  },
  {
    postal_code: "35981",
    zip_name: "ider, al",
  },
  {
    postal_code: "15754",
    zip_name: "lucernemines, pa",
  },
  {
    postal_code: "19133",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "57025",
    zip_name: "elk point, sd",
  },
  {
    postal_code: "83670",
    zip_name: "sweet, id",
  },
  {
    postal_code: "19310",
    zip_name: "atglen, pa",
  },
  {
    postal_code: "36017",
    zip_name: "clio, al",
  },
  {
    postal_code: "19609",
    zip_name: "reading, pa",
  },
  {
    postal_code: "1071",
    zip_name: "russell, ma",
  },
  {
    postal_code: "99587",
    zip_name: "girdwood, ak",
  },
  {
    postal_code: "17372",
    zip_name: "york springs, pa",
  },
  {
    postal_code: "31796",
    zip_name: "warwick, ga",
  },
  {
    postal_code: "22747",
    zip_name: "washington, va",
  },
  {
    postal_code: "74332",
    zip_name: "big cabin, ok",
  },
  {
    postal_code: "89130",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "66531",
    zip_name: "riley, ks",
  },
  {
    postal_code: "2120",
    zip_name: "roxbury crossing, ma",
  },
  {
    postal_code: "12959",
    zip_name: "mooers forks, ny",
  },
  {
    postal_code: "49902",
    zip_name: "alpha, mi",
  },
  {
    postal_code: "72624",
    zip_name: "compton, ar",
  },
  {
    postal_code: "51637",
    zip_name: "college springs, ia",
  },
  {
    postal_code: "87747",
    zip_name: "springer, nm",
  },
  {
    postal_code: "67879",
    zip_name: "tribune, ks",
  },
  {
    postal_code: "77444",
    zip_name: "guy, tx",
  },
  {
    postal_code: "70650",
    zip_name: "lacassine, la",
  },
  {
    postal_code: "55782",
    zip_name: "soudan, mn",
  },
  {
    postal_code: "48437",
    zip_name: "genesee, mi",
  },
  {
    postal_code: "42410",
    zip_name: "earlington, ky",
  },
  {
    postal_code: "16872",
    zip_name: "rebersburg, pa",
  },
  {
    postal_code: "48472",
    zip_name: "snover, mi",
  },
  {
    postal_code: "33846",
    zip_name: "highland city, fl",
  },
  {
    postal_code: "53167",
    zip_name: "rochester, wi",
  },
  {
    postal_code: "66095",
    zip_name: "williamsburg, ks",
  },
  {
    postal_code: "97486",
    zip_name: "umpqua, or",
  },
  {
    postal_code: "17214",
    zip_name: "blue ridge summit, pa",
  },
  {
    postal_code: "52217",
    zip_name: "clutier, ia",
  },
  {
    postal_code: "55956",
    zip_name: "mazeppa, mn",
  },
  {
    postal_code: "36560",
    zip_name: "mount vernon, al",
  },
  {
    postal_code: "59761",
    zip_name: "wisdom, mt",
  },
  {
    postal_code: "16210",
    zip_name: "adrian, pa",
  },
  {
    postal_code: "14080",
    zip_name: "holland, ny",
  },
  {
    postal_code: "21872",
    zip_name: "whaleyville, md",
  },
  {
    postal_code: "62422",
    zip_name: "cowden, il",
  },
  {
    postal_code: "4677",
    zip_name: "sorrento, me",
  },
  {
    postal_code: "18831",
    zip_name: "milan, pa",
  },
  {
    postal_code: "69128",
    zip_name: "bushnell, ne",
  },
  {
    postal_code: "62915",
    zip_name: "cambria, il",
  },
  {
    postal_code: "16746",
    zip_name: "roulette, pa",
  },
  {
    postal_code: "35671",
    zip_name: "tanner, al",
  },
  {
    postal_code: "95525",
    zip_name: "blue lake, ca",
  },
  {
    postal_code: "49893",
    zip_name: "wallace, mi",
  },
  {
    postal_code: "15698",
    zip_name: "yukon, pa",
  },
  {
    postal_code: "59338",
    zip_name: "kinsey, mt",
  },
  {
    postal_code: "24413",
    zip_name: "blue grass, va",
  },
  {
    postal_code: "29819",
    zip_name: "bradley, sc",
  },
  {
    postal_code: "55742",
    zip_name: "goodland, mn",
  },
  {
    postal_code: "60960",
    zip_name: "rankin, il",
  },
  {
    postal_code: "13670",
    zip_name: "oswegatchie, ny",
  },
  {
    postal_code: "15546",
    zip_name: "jenners, pa",
  },
  {
    postal_code: "47036",
    zip_name: "oldenburg, in",
  },
  {
    postal_code: "15550",
    zip_name: "manns choice, pa",
  },
  {
    postal_code: "27917",
    zip_name: "barco, nc",
  },
  {
    postal_code: "55953",
    zip_name: "lyle, mn",
  },
  {
    postal_code: "56355",
    zip_name: "nelson, mn",
  },
  {
    postal_code: "41231",
    zip_name: "lovely, ky",
  },
  {
    postal_code: "99346",
    zip_name: "plymouth, wa",
  },
  {
    postal_code: "79345",
    zip_name: "meadow, tx",
  },
  {
    postal_code: "47383",
    zip_name: "selma, in",
  },
  {
    postal_code: "23180",
    zip_name: "water view, va",
  },
  {
    postal_code: "54405",
    zip_name: "abbotsford, wi",
  },
  {
    postal_code: "23123",
    zip_name: "new canton, va",
  },
  {
    postal_code: "12161",
    zip_name: "south bethlehem, ny",
  },
  {
    postal_code: "47020",
    zip_name: "florence, in",
  },
  {
    postal_code: "27924",
    zip_name: "colerain, nc",
  },
  {
    postal_code: "71260",
    zip_name: "marion, la",
  },
  {
    postal_code: "97541",
    zip_name: "trail, or",
  },
  {
    postal_code: "89086",
    zip_name: "north las vegas, nv",
  },
  {
    postal_code: "40442",
    zip_name: "kings mountain, ky",
  },
  {
    postal_code: "56137",
    zip_name: "heron lake, mn",
  },
  {
    postal_code: "13054",
    zip_name: "durhamville, ny",
  },
  {
    postal_code: "16948",
    zip_name: "ulysses, pa",
  },
  {
    postal_code: "72055",
    zip_name: "gillett, ar",
  },
  {
    postal_code: "2791",
    zip_name: "westport point, ma",
  },
  {
    postal_code: "58063",
    zip_name: "oriska, nd",
  },
  {
    postal_code: "56547",
    zip_name: "glyndon, mn",
  },
  {
    postal_code: "15865",
    zip_name: "sykesville, pa",
  },
  {
    postal_code: "28434",
    zip_name: "council, nc",
  },
  {
    postal_code: "42411",
    zip_name: "fredonia, ky",
  },
  {
    postal_code: "38744",
    zip_name: "glen allan, ms",
  },
  {
    postal_code: "97443",
    zip_name: "glide, or",
  },
  {
    postal_code: "66542",
    zip_name: "tecumseh, ks",
  },
  {
    postal_code: "47917",
    zip_name: "ambia, in",
  },
  {
    postal_code: "56020",
    zip_name: "conger, mn",
  },
  {
    postal_code: "67344",
    zip_name: "elk city, ks",
  },
  {
    postal_code: "43716",
    zip_name: "beallsville, oh",
  },
  {
    postal_code: "80430",
    zip_name: "coalmont, co",
  },
  {
    postal_code: "18078",
    zip_name: "schnecksville, pa",
  },
  {
    postal_code: "88344",
    zip_name: "pinon, nm",
  },
  {
    postal_code: "63967",
    zip_name: "williamsville, mo",
  },
  {
    postal_code: "67839",
    zip_name: "dighton, ks",
  },
  {
    postal_code: "5832",
    zip_name: "east burke, vt",
  },
  {
    postal_code: "50568",
    zip_name: "newell, ia",
  },
  {
    postal_code: "31553",
    zip_name: "nahunta, ga",
  },
  {
    postal_code: "74881",
    zip_name: "wellston, ok",
  },
  {
    postal_code: "12734",
    zip_name: "ferndale, ny",
  },
  {
    postal_code: "45654",
    zip_name: "new plymouth, oh",
  },
  {
    postal_code: "56152",
    zip_name: "lamberton, mn",
  },
  {
    postal_code: "47368",
    zip_name: "parker city, in",
  },
  {
    postal_code: "63623",
    zip_name: "belleview, mo",
  },
  {
    postal_code: "64074",
    zip_name: "napoleon, mo",
  },
  {
    postal_code: "71430",
    zip_name: "forest hill, la",
  },
  {
    postal_code: "83424",
    zip_name: "felt, id",
  },
  {
    postal_code: "53505",
    zip_name: "avalon, wi",
  },
  {
    postal_code: "81077",
    zip_name: "swink, co",
  },
  {
    postal_code: "48815",
    zip_name: "clarksville, mi",
  },
  {
    postal_code: "27857",
    zip_name: "oak city, nc",
  },
  {
    postal_code: "4285",
    zip_name: "weld, me",
  },
  {
    postal_code: "17023",
    zip_name: "elizabethville, pa",
  },
  {
    postal_code: "84006",
    zip_name: "bingham canyon, ut",
  },
  {
    postal_code: "77962",
    zip_name: "ganado, tx",
  },
  {
    postal_code: "16022",
    zip_name: "bruin, pa",
  },
  {
    postal_code: "81124",
    zip_name: "capulin, co",
  },
  {
    postal_code: "43915",
    zip_name: "clarington, oh",
  },
  {
    postal_code: "88026",
    zip_name: "santa clara, nm",
  },
  {
    postal_code: "57066",
    zip_name: "tyndall, sd",
  },
  {
    postal_code: "4965",
    zip_name: "palmyra, me",
  },
  {
    postal_code: "62628",
    zip_name: "chapin, il",
  },
  {
    postal_code: "54550",
    zip_name: "montreal, wi",
  },
  {
    postal_code: "43501",
    zip_name: "alvordton, oh",
  },
  {
    postal_code: "18631",
    zip_name: "mifflinville, pa",
  },
  {
    postal_code: "97884",
    zip_name: "unity, or",
  },
  {
    postal_code: "28606",
    zip_name: "boomer, nc",
  },
  {
    postal_code: "13638",
    zip_name: "felts mills, ny",
  },
  {
    postal_code: "26298",
    zip_name: "bergoo, wv",
  },
  {
    postal_code: "87564",
    zip_name: "san cristobal, nm",
  },
  {
    postal_code: "61453",
    zip_name: "little york, il",
  },
  {
    postal_code: "31544",
    zip_name: "jacksonville, ga",
  },
  {
    postal_code: "1339",
    zip_name: "charlemont, ma",
  },
  {
    postal_code: "50579",
    zip_name: "rockwell city, ia",
  },
  {
    postal_code: "41365",
    zip_name: "rogers, ky",
  },
  {
    postal_code: "48856",
    zip_name: "middleton, mi",
  },
  {
    postal_code: "12886",
    zip_name: "wevertown, ny",
  },
  {
    postal_code: "80137",
    zip_name: "watkins, co",
  },
  {
    postal_code: "17052",
    zip_name: "mapleton depot, pa",
  },
  {
    postal_code: "38925",
    zip_name: "duck hill, ms",
  },
  {
    postal_code: "77475",
    zip_name: "sheridan, tx",
  },
  {
    postal_code: "59008",
    zip_name: "belfry, mt",
  },
  {
    postal_code: "56552",
    zip_name: "hitterdal, mn",
  },
  {
    postal_code: "56678",
    zip_name: "solway, mn",
  },
  {
    postal_code: "62031",
    zip_name: "fieldon, il",
  },
  {
    postal_code: "16617",
    zip_name: "bellwood, pa",
  },
  {
    postal_code: "41098",
    zip_name: "worthville, ky",
  },
  {
    postal_code: "62533",
    zip_name: "farmersville, il",
  },
  {
    postal_code: "40935",
    zip_name: "flat lick, ky",
  },
  {
    postal_code: "62846",
    zip_name: "ina, il",
  },
  {
    postal_code: "63934",
    zip_name: "clubb, mo",
  },
  {
    postal_code: "47452",
    zip_name: "orleans, in",
  },
  {
    postal_code: "65601",
    zip_name: "aldrich, mo",
  },
  {
    postal_code: "44441",
    zip_name: "negley, oh",
  },
  {
    postal_code: "84039",
    zip_name: "lapoint, ut",
  },
  {
    postal_code: "15559",
    zip_name: "schellsburg, pa",
  },
  {
    postal_code: "97536",
    zip_name: "prospect, or",
  },
  {
    postal_code: "61812",
    zip_name: "armstrong, il",
  },
  {
    postal_code: "99683",
    zip_name: "trapper creek, ak",
  },
  {
    postal_code: "80930",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "54408",
    zip_name: "aniwa, wi",
  },
  {
    postal_code: "5730",
    zip_name: "belmont, vt",
  },
  {
    postal_code: "61018",
    zip_name: "dakota, il",
  },
  {
    postal_code: "4573",
    zip_name: "walpole, me",
  },
  {
    postal_code: "10546",
    zip_name: "millwood, ny",
  },
  {
    postal_code: "99689",
    zip_name: "yakutat, ak",
  },
  {
    postal_code: "67865",
    zip_name: "minneola, ks",
  },
  {
    postal_code: "65629",
    zip_name: "chadwick, mo",
  },
  {
    postal_code: "76673",
    zip_name: "mount calm, tx",
  },
  {
    postal_code: "21612",
    zip_name: "bozman, md",
  },
  {
    postal_code: "81640",
    zip_name: "maybell, co",
  },
  {
    postal_code: "67451",
    zip_name: "hope, ks",
  },
  {
    postal_code: "51570",
    zip_name: "shelby, ia",
  },
  {
    postal_code: "52640",
    zip_name: "morning sun, ia",
  },
  {
    postal_code: "87577",
    zip_name: "tres piedras, nm",
  },
  {
    postal_code: "7432",
    zip_name: "midland park, nj",
  },
  {
    postal_code: "8720",
    zip_name: "allenwood, nj",
  },
  {
    postal_code: "79367",
    zip_name: "smyer, tx",
  },
  {
    postal_code: "71860",
    zip_name: "stamps, ar",
  },
  {
    postal_code: "36445",
    zip_name: "frisco city, al",
  },
  {
    postal_code: "69336",
    zip_name: "bridgeport, ne",
  },
  {
    postal_code: "79852",
    zip_name: "terlingua, tx",
  },
  {
    postal_code: "16218",
    zip_name: "cowansville, pa",
  },
  {
    postal_code: "84665",
    zip_name: "sterling, ut",
  },
  {
    postal_code: "65760",
    zip_name: "tecumseh, mo",
  },
  {
    postal_code: "65456",
    zip_name: "davisville, mo",
  },
  {
    postal_code: "71378",
    zip_name: "wisner, la",
  },
  {
    postal_code: "59261",
    zip_name: "saco, mt",
  },
  {
    postal_code: "22623",
    zip_name: "chester gap, va",
  },
  {
    postal_code: "78593",
    zip_name: "santa rosa, tx",
  },
  {
    postal_code: "53001",
    zip_name: "adell, wi",
  },
  {
    postal_code: "51025",
    zip_name: "holstein, ia",
  },
  {
    postal_code: "68364",
    zip_name: "goehner, ne",
  },
  {
    postal_code: "72324",
    zip_name: "cherry valley, ar",
  },
  {
    postal_code: "54832",
    zip_name: "drummond, wi",
  },
  {
    postal_code: "17985",
    zip_name: "zion grove, pa",
  },
  {
    postal_code: "46524",
    zip_name: "etna green, in",
  },
  {
    postal_code: "16130",
    zip_name: "hadley, pa",
  },
  {
    postal_code: "45681",
    zip_name: "south salem, oh",
  },
  {
    postal_code: "43442",
    zip_name: "lindsey, oh",
  },
  {
    postal_code: "18340",
    zip_name: "millrift, pa",
  },
  {
    postal_code: "61379",
    zip_name: "wyanet, il",
  },
  {
    postal_code: "52320",
    zip_name: "olin, ia",
  },
  {
    postal_code: "4263",
    zip_name: "leeds, me",
  },
  {
    postal_code: "35752",
    zip_name: "hollywood, al",
  },
  {
    postal_code: "99153",
    zip_name: "metaline falls, wa",
  },
  {
    postal_code: "65715",
    zip_name: "noble, mo",
  },
  {
    postal_code: "15313",
    zip_name: "beallsville, pa",
  },
  {
    postal_code: "32061",
    zip_name: "lulu, fl",
  },
  {
    postal_code: "41831",
    zip_name: "leburn, ky",
  },
  {
    postal_code: "54838",
    zip_name: "gordon, wi",
  },
  {
    postal_code: "83542",
    zip_name: "lucile, id",
  },
  {
    postal_code: "56756",
    zip_name: "salol, mn",
  },
  {
    postal_code: "14823",
    zip_name: "canisteo, ny",
  },
  {
    postal_code: "87582",
    zip_name: "velarde, nm",
  },
  {
    postal_code: "63547",
    zip_name: "hurdland, mo",
  },
  {
    postal_code: "84328",
    zip_name: "paradise, ut",
  },
  {
    postal_code: "75469",
    zip_name: "pecan gap, tx",
  },
  {
    postal_code: "59218",
    zip_name: "culbertson, mt",
  },
  {
    postal_code: "53801",
    zip_name: "bagley, wi",
  },
  {
    postal_code: "47358",
    zip_name: "modoc, in",
  },
  {
    postal_code: "93441",
    zip_name: "los olivos, ca",
  },
  {
    postal_code: "30575",
    zip_name: "talmo, ga",
  },
  {
    postal_code: "66710",
    zip_name: "altoona, ks",
  },
  {
    postal_code: "24941",
    zip_name: "gap mills, wv",
  },
  {
    postal_code: "47971",
    zip_name: "oxford, in",
  },
  {
    postal_code: "69034",
    zip_name: "indianola, ne",
  },
  {
    postal_code: "50435",
    zip_name: "floyd, ia",
  },
  {
    postal_code: "45111",
    zip_name: "camp dennison, oh",
  },
  {
    postal_code: "27208",
    zip_name: "bennett, nc",
  },
  {
    postal_code: "26372",
    zip_name: "horner, wv",
  },
  {
    postal_code: "73761",
    zip_name: "nash, ok",
  },
  {
    postal_code: "5658",
    zip_name: "marshfield, vt",
  },
  {
    postal_code: "49831",
    zip_name: "felch, mi",
  },
  {
    postal_code: "67001",
    zip_name: "andale, ks",
  },
  {
    postal_code: "55765",
    zip_name: "meadowlands, mn",
  },
  {
    postal_code: "82515",
    zip_name: "hudson, wy",
  },
  {
    postal_code: "67154",
    zip_name: "whitewater, ks",
  },
  {
    postal_code: "61476",
    zip_name: "seaton, il",
  },
  {
    postal_code: "96075",
    zip_name: "paynes creek, ca",
  },
  {
    postal_code: "45372",
    zip_name: "tremont city, oh",
  },
  {
    postal_code: "74759",
    zip_name: "soper, ok",
  },
  {
    postal_code: "4932",
    zip_name: "dixmont, me",
  },
  {
    postal_code: "57045",
    zip_name: "menno, sd",
  },
  {
    postal_code: "28369",
    zip_name: "orrum, nc",
  },
  {
    postal_code: "83250",
    zip_name: "mccammon, id",
  },
  {
    postal_code: "28718",
    zip_name: "cedar mountain, nc",
  },
  {
    postal_code: "73434",
    zip_name: "foster, ok",
  },
  {
    postal_code: "26146",
    zip_name: "friendly, wv",
  },
  {
    postal_code: "58773",
    zip_name: "powers lake, nd",
  },
  {
    postal_code: "43963",
    zip_name: "tiltonsville, oh",
  },
  {
    postal_code: "52066",
    zip_name: "north buena vista, ia",
  },
  {
    postal_code: "40070",
    zip_name: "sulphur, ky",
  },
  {
    postal_code: "10535",
    zip_name: "jefferson valley, ny",
  },
  {
    postal_code: "70076",
    zip_name: "mount airy, la",
  },
  {
    postal_code: "26055",
    zip_name: "proctor, wv",
  },
  {
    postal_code: "13636",
    zip_name: "ellisburg, ny",
  },
  {
    postal_code: "97413",
    zip_name: "blue river, or",
  },
  {
    postal_code: "42021",
    zip_name: "arlington, ky",
  },
  {
    postal_code: "4686",
    zip_name: "wesley, me",
  },
  {
    postal_code: "23976",
    zip_name: "wylliesburg, va",
  },
  {
    postal_code: "79532",
    zip_name: "loraine, tx",
  },
  {
    postal_code: "31629",
    zip_name: "dixie, ga",
  },
  {
    postal_code: "4922",
    zip_name: "burnham, me",
  },
  {
    postal_code: "73734",
    zip_name: "dover, ok",
  },
  {
    postal_code: "44867",
    zip_name: "republic, oh",
  },
  {
    postal_code: "56354",
    zip_name: "miltona, mn",
  },
  {
    postal_code: "67740",
    zip_name: "hoxie, ks",
  },
  {
    postal_code: "66424",
    zip_name: "everest, ks",
  },
  {
    postal_code: "35740",
    zip_name: "bridgeport, al",
  },
  {
    postal_code: "55781",
    zip_name: "side lake, mn",
  },
  {
    postal_code: "40363",
    zip_name: "perry park, ky",
  },
  {
    postal_code: "78050",
    zip_name: "leming, tx",
  },
  {
    postal_code: "14418",
    zip_name: "branchport, ny",
  },
  {
    postal_code: "57231",
    zip_name: "de smet, sd",
  },
  {
    postal_code: "50108",
    zip_name: "grand river, ia",
  },
  {
    postal_code: "54469",
    zip_name: "port edwards, wi",
  },
  {
    postal_code: "83855",
    zip_name: "potlatch, id",
  },
  {
    postal_code: "75572",
    zip_name: "queen city, tx",
  },
  {
    postal_code: "25113",
    zip_name: "ivydale, wv",
  },
  {
    postal_code: "21652",
    zip_name: "neavitt, md",
  },
  {
    postal_code: "46049",
    zip_name: "kempton, in",
  },
  {
    postal_code: "25187",
    zip_name: "southside, wv",
  },
  {
    postal_code: "45861",
    zip_name: "melrose, oh",
  },
  {
    postal_code: "43533",
    zip_name: "lyons, oh",
  },
  {
    postal_code: "27884",
    zip_name: "stokes, nc",
  },
  {
    postal_code: "95421",
    zip_name: "cazadero, ca",
  },
  {
    postal_code: "62841",
    zip_name: "freeman spur, il",
  },
  {
    postal_code: "42456",
    zip_name: "slaughters, ky",
  },
  {
    postal_code: "14817",
    zip_name: "brooktondale, ny",
  },
  {
    postal_code: "59349",
    zip_name: "terry, mt",
  },
  {
    postal_code: "67437",
    zip_name: "downs, ks",
  },
  {
    postal_code: "3259",
    zip_name: "north sandwich, nh",
  },
  {
    postal_code: "35646",
    zip_name: "leighton, al",
  },
  {
    postal_code: "10917",
    zip_name: "central valley, ny",
  },
  {
    postal_code: "24580",
    zip_name: "nelson, va",
  },
  {
    postal_code: "55798",
    zip_name: "wright, mn",
  },
  {
    postal_code: "17974",
    zip_name: "seltzer, pa",
  },
  {
    postal_code: "46779",
    zip_name: "pleasant lake, in",
  },
  {
    postal_code: "63940",
    zip_name: "fisk, mo",
  },
  {
    postal_code: "27508",
    zip_name: "bunn, nc",
  },
  {
    postal_code: "22946",
    zip_name: "keene, va",
  },
  {
    postal_code: "83463",
    zip_name: "gibbonsville, id",
  },
  {
    postal_code: "63056",
    zip_name: "leslie, mo",
  },
  {
    postal_code: "51532",
    zip_name: "elliott, ia",
  },
  {
    postal_code: "63846",
    zip_name: "essex, mo",
  },
  {
    postal_code: "81155",
    zip_name: "villa grove, co",
  },
  {
    postal_code: "85348",
    zip_name: "salome, az",
  },
  {
    postal_code: "5461",
    zip_name: "hinesburg, vt",
  },
  {
    postal_code: "88256",
    zip_name: "loving, nm",
  },
  {
    postal_code: "84022",
    zip_name: "dugway, ut",
  },
  {
    postal_code: "71266",
    zip_name: "pioneer, la",
  },
  {
    postal_code: "76680",
    zip_name: "reagan, tx",
  },
  {
    postal_code: "54845",
    zip_name: "hertel, wi",
  },
  {
    postal_code: "95519",
    zip_name: "mckinleyville, ca",
  },
  {
    postal_code: "74878",
    zip_name: "wanette, ok",
  },
  {
    postal_code: "99714",
    zip_name: "salcha, ak",
  },
  {
    postal_code: "63943",
    zip_name: "grandin, mo",
  },
  {
    postal_code: "95319",
    zip_name: "empire, ca",
  },
  {
    postal_code: "68313",
    zip_name: "beaver crossing, ne",
  },
  {
    postal_code: "26585",
    zip_name: "metz, wv",
  },
  {
    postal_code: "5675",
    zip_name: "washington, vt",
  },
  {
    postal_code: "14485",
    zip_name: "lima, ny",
  },
  {
    postal_code: "11697",
    zip_name: "breezy point, ny",
  },
  {
    postal_code: "7822",
    zip_name: "augusta, nj",
  },
  {
    postal_code: "42166",
    zip_name: "summer shade, ky",
  },
  {
    postal_code: "87711",
    zip_name: "anton chico, nm",
  },
  {
    postal_code: "14548",
    zip_name: "shortsville, ny",
  },
  {
    postal_code: "80622",
    zip_name: "galeton, co",
  },
  {
    postal_code: "68832",
    zip_name: "doniphan, ne",
  },
  {
    postal_code: "21864",
    zip_name: "stockton, md",
  },
  {
    postal_code: "47637",
    zip_name: "tennyson, in",
  },
  {
    postal_code: "67529",
    zip_name: "garfield, ks",
  },
  {
    postal_code: "14472",
    zip_name: "honeoye falls, ny",
  },
  {
    postal_code: "53537",
    zip_name: "footville, wi",
  },
  {
    postal_code: "48806",
    zip_name: "ashley, mi",
  },
  {
    postal_code: "86511",
    zip_name: "saint michaels, az",
  },
  {
    postal_code: "73024",
    zip_name: "corn, ok",
  },
  {
    postal_code: "89043",
    zip_name: "pioche, nv",
  },
  {
    postal_code: "50464",
    zip_name: "plymouth, ia",
  },
  {
    postal_code: "50008",
    zip_name: "allerton, ia",
  },
  {
    postal_code: "61472",
    zip_name: "rio, il",
  },
  {
    postal_code: "53540",
    zip_name: "gotham, wi",
  },
  {
    postal_code: "29053",
    zip_name: "gaston, sc",
  },
  {
    postal_code: "44443",
    zip_name: "new springfield, oh",
  },
  {
    postal_code: "60959",
    zip_name: "piper city, il",
  },
  {
    postal_code: "17068",
    zip_name: "new bloomfield, pa",
  },
  {
    postal_code: "64440",
    zip_name: "de kalb, mo",
  },
  {
    postal_code: "41524",
    zip_name: "fedscreek, ky",
  },
  {
    postal_code: "25139",
    zip_name: "mount carbon, wv",
  },
  {
    postal_code: "56257",
    zip_name: "marietta, mn",
  },
  {
    postal_code: "58381",
    zip_name: "warwick, nd",
  },
  {
    postal_code: "52079",
    zip_name: "zwingle, ia",
  },
  {
    postal_code: "4459",
    zip_name: "mattawamkeag, me",
  },
  {
    postal_code: "49821",
    zip_name: "daggett, mi",
  },
  {
    postal_code: "16124",
    zip_name: "fredonia, pa",
  },
  {
    postal_code: "87823",
    zip_name: "lemitar, nm",
  },
  {
    postal_code: "55603",
    zip_name: "finland, mn",
  },
  {
    postal_code: "52054",
    zip_name: "la motte, ia",
  },
  {
    postal_code: "81222",
    zip_name: "coaldale, co",
  },
  {
    postal_code: "49873",
    zip_name: "perronville, mi",
  },
  {
    postal_code: "68815",
    zip_name: "arcadia, ne",
  },
  {
    postal_code: "36578",
    zip_name: "stapleton, al",
  },
  {
    postal_code: "23176",
    zip_name: "wake, va",
  },
  {
    postal_code: "12028",
    zip_name: "buskirk, ny",
  },
  {
    postal_code: "42746",
    zip_name: "hardyville, ky",
  },
  {
    postal_code: "84714",
    zip_name: "beryl, ut",
  },
  {
    postal_code: "25202",
    zip_name: "tornado, wv",
  },
  {
    postal_code: "19435",
    zip_name: "frederick, pa",
  },
  {
    postal_code: "17030",
    zip_name: "gratz, pa",
  },
  {
    postal_code: "67648",
    zip_name: "lucas, ks",
  },
  {
    postal_code: "34141",
    zip_name: "ochopee, fl",
  },
  {
    postal_code: "94924",
    zip_name: "bolinas, ca",
  },
  {
    postal_code: "50101",
    zip_name: "galt, ia",
  },
  {
    postal_code: "67022",
    zip_name: "caldwell, ks",
  },
  {
    postal_code: "47325",
    zip_name: "brownsville, in",
  },
  {
    postal_code: "72565",
    zip_name: "oxford, ar",
  },
  {
    postal_code: "64630",
    zip_name: "browning, mo",
  },
  {
    postal_code: "95606",
    zip_name: "brooks, ca",
  },
  {
    postal_code: "59323",
    zip_name: "colstrip, mt",
  },
  {
    postal_code: "61545",
    zip_name: "lowpoint, il",
  },
  {
    postal_code: "18821",
    zip_name: "great bend, pa",
  },
  {
    postal_code: "71450",
    zip_name: "marthaville, la",
  },
  {
    postal_code: "55764",
    zip_name: "marble, mn",
  },
  {
    postal_code: "29659",
    zip_name: "lowndesville, sc",
  },
  {
    postal_code: "41815",
    zip_name: "ermine, ky",
  },
  {
    postal_code: "52201",
    zip_name: "ainsworth, ia",
  },
  {
    postal_code: "76626",
    zip_name: "blooming grove, tx",
  },
  {
    postal_code: "22743",
    zip_name: "syria, va",
  },
  {
    postal_code: "89106",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "12922",
    zip_name: "childwold, ny",
  },
  {
    postal_code: "25670",
    zip_name: "delbarton, wv",
  },
  {
    postal_code: "38940",
    zip_name: "holcomb, ms",
  },
  {
    postal_code: "62458",
    zip_name: "saint elmo, il",
  },
  {
    postal_code: "84001",
    zip_name: "altamont, ut",
  },
  {
    postal_code: "68461",
    zip_name: "walton, ne",
  },
  {
    postal_code: "15731",
    zip_name: "coral, pa",
  },
  {
    postal_code: "64471",
    zip_name: "new hampton, mo",
  },
  {
    postal_code: "50641",
    zip_name: "hazleton, ia",
  },
  {
    postal_code: "49963",
    zip_name: "south range, mi",
  },
  {
    postal_code: "53827",
    zip_name: "woodman, wi",
  },
  {
    postal_code: "81151",
    zip_name: "sanford, co",
  },
  {
    postal_code: "66849",
    zip_name: "dwight, ks",
  },
  {
    postal_code: "50517",
    zip_name: "bancroft, ia",
  },
  {
    postal_code: "50566",
    zip_name: "moorland, ia",
  },
  {
    postal_code: "98647",
    zip_name: "skamokawa, wa",
  },
  {
    postal_code: "13122",
    zip_name: "new woodstock, ny",
  },
  {
    postal_code: "48659",
    zip_name: "sterling, mi",
  },
  {
    postal_code: "74130",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "31744",
    zip_name: "doerun, ga",
  },
  {
    postal_code: "62979",
    zip_name: "ridgway, il",
  },
  {
    postal_code: "38037",
    zip_name: "gates, tn",
  },
  {
    postal_code: "5492",
    zip_name: "waterville, vt",
  },
  {
    postal_code: "61431",
    zip_name: "ellisville, il",
  },
  {
    postal_code: "21640",
    zip_name: "henderson, md",
  },
  {
    postal_code: "53580",
    zip_name: "rewey, wi",
  },
  {
    postal_code: "41739",
    zip_name: "dwarf, ky",
  },
  {
    postal_code: "38370",
    zip_name: "saltillo, tn",
  },
  {
    postal_code: "45745",
    zip_name: "lower salem, oh",
  },
  {
    postal_code: "12856",
    zip_name: "north river, ny",
  },
  {
    postal_code: "54479",
    zip_name: "spencer, wi",
  },
  {
    postal_code: "63533",
    zip_name: "brashear, mo",
  },
  {
    postal_code: "12823",
    zip_name: "cossayuna, ny",
  },
  {
    postal_code: "13844",
    zip_name: "south plymouth, ny",
  },
  {
    postal_code: "89310",
    zip_name: "austin, nv",
  },
  {
    postal_code: "50594",
    zip_name: "vincent, ia",
  },
  {
    postal_code: "89319",
    zip_name: "ruth, nv",
  },
  {
    postal_code: "64018",
    zip_name: "camden point, mo",
  },
  {
    postal_code: "56384",
    zip_name: "upsala, mn",
  },
  {
    postal_code: "41083",
    zip_name: "sanders, ky",
  },
  {
    postal_code: "73458",
    zip_name: "springer, ok",
  },
  {
    postal_code: "77446",
    zip_name: "prairie view, tx",
  },
  {
    postal_code: "46376",
    zip_name: "schneider, in",
  },
  {
    postal_code: "43535",
    zip_name: "malinta, oh",
  },
  {
    postal_code: "29123",
    zip_name: "pelion, sc",
  },
  {
    postal_code: "43451",
    zip_name: "portage, oh",
  },
  {
    postal_code: "12031",
    zip_name: "carlisle, ny",
  },
  {
    postal_code: "78066",
    zip_name: "rio medina, tx",
  },
  {
    postal_code: "68378",
    zip_name: "johnson, ne",
  },
  {
    postal_code: "56029",
    zip_name: "emmons, mn",
  },
  {
    postal_code: "77420",
    zip_name: "boling, tx",
  },
  {
    postal_code: "7001",
    zip_name: "avenel, nj",
  },
  {
    postal_code: "73526",
    zip_name: "blair, ok",
  },
  {
    postal_code: "61914",
    zip_name: "bethany, il",
  },
  {
    postal_code: "68950",
    zip_name: "holstein, ne",
  },
  {
    postal_code: "16211",
    zip_name: "beyer, pa",
  },
  {
    postal_code: "72629",
    zip_name: "dennard, ar",
  },
  {
    postal_code: "4408",
    zip_name: "aurora, me",
  },
  {
    postal_code: "59483",
    zip_name: "sun river, mt",
  },
  {
    postal_code: "14525",
    zip_name: "pavilion, ny",
  },
  {
    postal_code: "81244",
    zip_name: "rockvale, co",
  },
  {
    postal_code: "63944",
    zip_name: "greenville, mo",
  },
  {
    postal_code: "65339",
    zip_name: "malta bend, mo",
  },
  {
    postal_code: "61483",
    zip_name: "toulon, il",
  },
  {
    postal_code: "12787",
    zip_name: "white sulphur springs, ny",
  },
  {
    postal_code: "40810",
    zip_name: "bledsoe, ky",
  },
  {
    postal_code: "58276",
    zip_name: "saint thomas, nd",
  },
  {
    postal_code: "4772",
    zip_name: "saint agatha, me",
  },
  {
    postal_code: "47369",
    zip_name: "pennville, in",
  },
  {
    postal_code: "56579",
    zip_name: "rothsay, mn",
  },
  {
    postal_code: "57232",
    zip_name: "eden, sd",
  },
  {
    postal_code: "68321",
    zip_name: "brownville, ne",
  },
  {
    postal_code: "99586",
    zip_name: "gakona, ak",
  },
  {
    postal_code: "64017",
    zip_name: "camden, mo",
  },
  {
    postal_code: "29667",
    zip_name: "norris, sc",
  },
  {
    postal_code: "24072",
    zip_name: "check, va",
  },
  {
    postal_code: "38923",
    zip_name: "coila, ms",
  },
  {
    postal_code: "84063",
    zip_name: "randlett, ut",
  },
  {
    postal_code: "43408",
    zip_name: "clay center, oh",
  },
  {
    postal_code: "97054",
    zip_name: "deer island, or",
  },
  {
    postal_code: "3047",
    zip_name: "greenfield, nh",
  },
  {
    postal_code: "53119",
    zip_name: "eagle, wi",
  },
  {
    postal_code: "97028",
    zip_name: "government camp, or",
  },
  {
    postal_code: "6024",
    zip_name: "east canaan, ct",
  },
  {
    postal_code: "56228",
    zip_name: "cosmos, mn",
  },
  {
    postal_code: "51458",
    zip_name: "odebolt, ia",
  },
  {
    postal_code: "58762",
    zip_name: "newburg, nd",
  },
  {
    postal_code: "54967",
    zip_name: "poy sippi, wi",
  },
  {
    postal_code: "44865",
    zip_name: "plymouth, oh",
  },
  {
    postal_code: "84632",
    zip_name: "fountain green, ut",
  },
  {
    postal_code: "99008",
    zip_name: "edwall, wa",
  },
  {
    postal_code: "49276",
    zip_name: "riga, mi",
  },
  {
    postal_code: "38575",
    zip_name: "moss, tn",
  },
  {
    postal_code: "14805",
    zip_name: "alpine, ny",
  },
  {
    postal_code: "45001",
    zip_name: "addyston, oh",
  },
  {
    postal_code: "42352",
    zip_name: "livermore, ky",
  },
  {
    postal_code: "68520",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "16422",
    zip_name: "harmonsburg, pa",
  },
  {
    postal_code: "95251",
    zip_name: "vallecito, ca",
  },
  {
    postal_code: "5477",
    zip_name: "richmond, vt",
  },
  {
    postal_code: "47926",
    zip_name: "burnettsville, in",
  },
  {
    postal_code: "21718",
    zip_name: "burkittsville, md",
  },
  {
    postal_code: "97864",
    zip_name: "monument, or",
  },
  {
    postal_code: "60949",
    zip_name: "ludlow, il",
  },
  {
    postal_code: "19456",
    zip_name: "oaks, pa",
  },
  {
    postal_code: "47850",
    zip_name: "farmersburg, in",
  },
  {
    postal_code: "3101",
    zip_name: "manchester, nh",
  },
  {
    postal_code: "38762",
    zip_name: "mound bayou, ms",
  },
  {
    postal_code: "15951",
    zip_name: "saint michael, pa",
  },
  {
    postal_code: "1260",
    zip_name: "south lee, ma",
  },
  {
    postal_code: "73021",
    zip_name: "colony, ok",
  },
  {
    postal_code: "15713",
    zip_name: "aultman, pa",
  },
  {
    postal_code: "23110",
    zip_name: "mattaponi, va",
  },
  {
    postal_code: "40355",
    zip_name: "new liberty, ky",
  },
  {
    postal_code: "49910",
    zip_name: "bergland, mi",
  },
  {
    postal_code: "66756",
    zip_name: "mulberry, ks",
  },
  {
    postal_code: "83322",
    zip_name: "corral, id",
  },
  {
    postal_code: "23358",
    zip_name: "hacksneck, va",
  },
  {
    postal_code: "28137",
    zip_name: "richfield, nc",
  },
  {
    postal_code: "71425",
    zip_name: "enterprise, la",
  },
  {
    postal_code: "43126",
    zip_name: "harrisburg, oh",
  },
  {
    postal_code: "76472",
    zip_name: "santo, tx",
  },
  {
    postal_code: "46365",
    zip_name: "mill creek, in",
  },
  {
    postal_code: "36028",
    zip_name: "dozier, al",
  },
  {
    postal_code: "68419",
    zip_name: "panama, ne",
  },
  {
    postal_code: "25103",
    zip_name: "hansford, wv",
  },
  {
    postal_code: "78359",
    zip_name: "gregory, tx",
  },
  {
    postal_code: "87562",
    zip_name: "rowe, nm",
  },
  {
    postal_code: "55333",
    zip_name: "franklin, mn",
  },
  {
    postal_code: "61501",
    zip_name: "astoria, il",
  },
  {
    postal_code: "4658",
    zip_name: "milbridge, me",
  },
  {
    postal_code: "79031",
    zip_name: "earth, tx",
  },
  {
    postal_code: "47597",
    zip_name: "wheatland, in",
  },
  {
    postal_code: "43458",
    zip_name: "rocky ridge, oh",
  },
  {
    postal_code: "53557",
    zip_name: "lowell, wi",
  },
  {
    postal_code: "62312",
    zip_name: "barry, il",
  },
  {
    postal_code: "61051",
    zip_name: "milledgeville, il",
  },
  {
    postal_code: "63352",
    zip_name: "laddonia, mo",
  },
  {
    postal_code: "54160",
    zip_name: "potter, wi",
  },
  {
    postal_code: "51445",
    zip_name: "ida grove, ia",
  },
  {
    postal_code: "85343",
    zip_name: "palo verde, az",
  },
  {
    postal_code: "69141",
    zip_name: "gurley, ne",
  },
  {
    postal_code: "5070",
    zip_name: "south strafford, vt",
  },
  {
    postal_code: "15920",
    zip_name: "armagh, pa",
  },
  {
    postal_code: "48465",
    zip_name: "palms, mi",
  },
  {
    postal_code: "27981",
    zip_name: "wanchese, nc",
  },
  {
    postal_code: "56546",
    zip_name: "georgetown, mn",
  },
  {
    postal_code: "64166",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "54436",
    zip_name: "granton, wi",
  },
  {
    postal_code: "21529",
    zip_name: "ellerslie, md",
  },
  {
    postal_code: "17845",
    zip_name: "millmont, pa",
  },
  {
    postal_code: "74562",
    zip_name: "rattan, ok",
  },
  {
    postal_code: "60927",
    zip_name: "clifton, il",
  },
  {
    postal_code: "95248",
    zip_name: "rail road flat, ca",
  },
  {
    postal_code: "65236",
    zip_name: "brunswick, mo",
  },
  {
    postal_code: "24258",
    zip_name: "hiltons, va",
  },
  {
    postal_code: "43549",
    zip_name: "ney, oh",
  },
  {
    postal_code: "23844",
    zip_name: "drewryville, va",
  },
  {
    postal_code: "93668",
    zip_name: "tranquillity, ca",
  },
  {
    postal_code: "86038",
    zip_name: "mormon lake, az",
  },
  {
    postal_code: "36316",
    zip_name: "chancellor, al",
  },
  {
    postal_code: "88434",
    zip_name: "san jon, nm",
  },
  {
    postal_code: "42140",
    zip_name: "gamaliel, ky",
  },
  {
    postal_code: "15363",
    zip_name: "strabane, pa",
  },
  {
    postal_code: "20689",
    zip_name: "sunderland, md",
  },
  {
    postal_code: "74957",
    zip_name: "smithville, ok",
  },
  {
    postal_code: "29114",
    zip_name: "olanta, sc",
  },
  {
    postal_code: "13436",
    zip_name: "raquette lake, ny",
  },
  {
    postal_code: "82932",
    zip_name: "farson, wy",
  },
  {
    postal_code: "83541",
    zip_name: "lenore, id",
  },
  {
    postal_code: "91963",
    zip_name: "potrero, ca",
  },
  {
    postal_code: "51536",
    zip_name: "hancock, ia",
  },
  {
    postal_code: "18842",
    zip_name: "south gibson, pa",
  },
  {
    postal_code: "52362",
    zip_name: "wyoming, ia",
  },
  {
    postal_code: "82412",
    zip_name: "byron, wy",
  },
  {
    postal_code: "50863",
    zip_name: "tingley, ia",
  },
  {
    postal_code: "12131",
    zip_name: "north blenheim, ny",
  },
  {
    postal_code: "58330",
    zip_name: "edmore, nd",
  },
  {
    postal_code: "42451",
    zip_name: "reed, ky",
  },
  {
    postal_code: "23827",
    zip_name: "boykins, va",
  },
  {
    postal_code: "43913",
    zip_name: "brilliant, oh",
  },
  {
    postal_code: "38761",
    zip_name: "moorhead, ms",
  },
  {
    postal_code: "97883",
    zip_name: "union, or",
  },
  {
    postal_code: "17967",
    zip_name: "ringtown, pa",
  },
  {
    postal_code: "38643",
    zip_name: "lambert, ms",
  },
  {
    postal_code: "64763",
    zip_name: "lowry city, mo",
  },
  {
    postal_code: "66607",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "55970",
    zip_name: "rose creek, mn",
  },
  {
    postal_code: "61423",
    zip_name: "cameron, il",
  },
  {
    postal_code: "98381",
    zip_name: "sekiu, wa",
  },
  {
    postal_code: "51022",
    zip_name: "granville, ia",
  },
  {
    postal_code: "36475",
    zip_name: "repton, al",
  },
  {
    postal_code: "94514",
    zip_name: "byron, ca",
  },
  {
    postal_code: "19319",
    zip_name: "cheyney, pa",
  },
  {
    postal_code: "68015",
    zip_name: "cedar bluffs, ne",
  },
  {
    postal_code: "49880",
    zip_name: "rock, mi",
  },
  {
    postal_code: "60928",
    zip_name: "crescent city, il",
  },
  {
    postal_code: "47010",
    zip_name: "bath, in",
  },
  {
    postal_code: "46797",
    zip_name: "woodburn, in",
  },
  {
    postal_code: "25557",
    zip_name: "ranger, wv",
  },
  {
    postal_code: "40813",
    zip_name: "calvin, ky",
  },
  {
    postal_code: "99704",
    zip_name: "clear, ak",
  },
  {
    postal_code: "56338",
    zip_name: "hillman, mn",
  },
  {
    postal_code: "37829",
    zip_name: "oakdale, tn",
  },
  {
    postal_code: "88252",
    zip_name: "jal, nm",
  },
  {
    postal_code: "14435",
    zip_name: "conesus, ny",
  },
  {
    postal_code: "59843",
    zip_name: "helmville, mt",
  },
  {
    postal_code: "41204",
    zip_name: "boons camp, ky",
  },
  {
    postal_code: "87551",
    zip_name: "los ojos, nm",
  },
  {
    postal_code: "13146",
    zip_name: "savannah, ny",
  },
  {
    postal_code: "25512",
    zip_name: "east lynn, wv",
  },
  {
    postal_code: "26808",
    zip_name: "high view, wv",
  },
  {
    postal_code: "65634",
    zip_name: "cross timbers, mo",
  },
  {
    postal_code: "64401",
    zip_name: "agency, mo",
  },
  {
    postal_code: "25115",
    zip_name: "kanawha falls, wv",
  },
  {
    postal_code: "26705",
    zip_name: "aurora, wv",
  },
  {
    postal_code: "54425",
    zip_name: "dorchester, wi",
  },
  {
    postal_code: "56761",
    zip_name: "wannaska, mn",
  },
  {
    postal_code: "29080",
    zip_name: "lynchburg, sc",
  },
  {
    postal_code: "12857",
    zip_name: "olmstedville, ny",
  },
  {
    postal_code: "14748",
    zip_name: "kill buck, ny",
  },
  {
    postal_code: "42069",
    zip_name: "melber, ky",
  },
  {
    postal_code: "61912",
    zip_name: "ashmore, il",
  },
  {
    postal_code: "53006",
    zip_name: "brownsville, wi",
  },
  {
    postal_code: "39040",
    zip_name: "bentonia, ms",
  },
  {
    postal_code: "20896",
    zip_name: "garrett park, md",
  },
  {
    postal_code: "31795",
    zip_name: "ty ty, ga",
  },
  {
    postal_code: "45766",
    zip_name: "new marshfield, oh",
  },
  {
    postal_code: "49915",
    zip_name: "caspian, mi",
  },
  {
    postal_code: "40346",
    zip_name: "means, ky",
  },
  {
    postal_code: "41149",
    zip_name: "isonville, ky",
  },
  {
    postal_code: "68825",
    zip_name: "callaway, ne",
  },
  {
    postal_code: "97438",
    zip_name: "fall creek, or",
  },
  {
    postal_code: "43727",
    zip_name: "chandlersville, oh",
  },
  {
    postal_code: "45778",
    zip_name: "stewart, oh",
  },
  {
    postal_code: "46348",
    zip_name: "la crosse, in",
  },
  {
    postal_code: "47465",
    zip_name: "switz city, in",
  },
  {
    postal_code: "39461",
    zip_name: "neely, ms",
  },
  {
    postal_code: "12438",
    zip_name: "halcottsville, ny",
  },
  {
    postal_code: "57481",
    zip_name: "westport, sd",
  },
  {
    postal_code: "78161",
    zip_name: "sutherland springs, tx",
  },
  {
    postal_code: "46104",
    zip_name: "arlington, in",
  },
  {
    postal_code: "63660",
    zip_name: "mineral point, mo",
  },
  {
    postal_code: "19529",
    zip_name: "kempton, pa",
  },
  {
    postal_code: "50139",
    zip_name: "lacona, ia",
  },
  {
    postal_code: "29591",
    zip_name: "scranton, sc",
  },
  {
    postal_code: "66060",
    zip_name: "nortonville, ks",
  },
  {
    postal_code: "47106",
    zip_name: "borden, in",
  },
  {
    postal_code: "49852",
    zip_name: "loretto, mi",
  },
  {
    postal_code: "4541",
    zip_name: "chamberlain, me",
  },
  {
    postal_code: "63663",
    zip_name: "pilot knob, mo",
  },
  {
    postal_code: "62661",
    zip_name: "loami, il",
  },
  {
    postal_code: "46974",
    zip_name: "roann, in",
  },
  {
    postal_code: "5757",
    zip_name: "middletown springs, vt",
  },
  {
    postal_code: "47857",
    zip_name: "knightsville, in",
  },
  {
    postal_code: "20137",
    zip_name: "broad run, va",
  },
  {
    postal_code: "54205",
    zip_name: "casco, wi",
  },
  {
    postal_code: "23022",
    zip_name: "bremo bluff, va",
  },
  {
    postal_code: "72129",
    zip_name: "prattsville, ar",
  },
  {
    postal_code: "32798",
    zip_name: "zellwood, fl",
  },
  {
    postal_code: "67110",
    zip_name: "mulvane, ks",
  },
  {
    postal_code: "76041",
    zip_name: "forreston, tx",
  },
  {
    postal_code: "77613",
    zip_name: "china, tx",
  },
  {
    postal_code: "5151",
    zip_name: "perkinsville, vt",
  },
  {
    postal_code: "97910",
    zip_name: "jordan valley, or",
  },
  {
    postal_code: "56255",
    zip_name: "lucan, mn",
  },
  {
    postal_code: "38725",
    zip_name: "benoit, ms",
  },
  {
    postal_code: "85337",
    zip_name: "gila bend, az",
  },
  {
    postal_code: "56295",
    zip_name: "watson, mn",
  },
  {
    postal_code: "50116",
    zip_name: "hamilton, ia",
  },
  {
    postal_code: "46951",
    zip_name: "macy, in",
  },
  {
    postal_code: "49340",
    zip_name: "remus, mi",
  },
  {
    postal_code: "15765",
    zip_name: "penn run, pa",
  },
  {
    postal_code: "55018",
    zip_name: "dennison, mn",
  },
  {
    postal_code: "74452",
    zip_name: "peggs, ok",
  },
  {
    postal_code: "12973",
    zip_name: "piercefield, ny",
  },
  {
    postal_code: "31037",
    zip_name: "mc rae helena, ga",
  },
  {
    postal_code: "20818",
    zip_name: "cabin john, md",
  },
  {
    postal_code: "41039",
    zip_name: "ewing, ky",
  },
  {
    postal_code: "60553",
    zip_name: "steward, il",
  },
  {
    postal_code: "4226",
    zip_name: "east andover, me",
  },
  {
    postal_code: "24352",
    zip_name: "laurel fork, va",
  },
  {
    postal_code: "15433",
    zip_name: "east millsboro, pa",
  },
  {
    postal_code: "54447",
    zip_name: "lublin, wi",
  },
  {
    postal_code: "47038",
    zip_name: "patriot, in",
  },
  {
    postal_code: "24323",
    zip_name: "crockett, va",
  },
  {
    postal_code: "97029",
    zip_name: "grass valley, or",
  },
  {
    postal_code: "82833",
    zip_name: "big horn, wy",
  },
  {
    postal_code: "52032",
    zip_name: "bernard, ia",
  },
  {
    postal_code: "13802",
    zip_name: "maine, ny",
  },
  {
    postal_code: "67473",
    zip_name: "osborne, ks",
  },
  {
    postal_code: "57329",
    zip_name: "dante, sd",
  },
  {
    postal_code: "45360",
    zip_name: "port jefferson, oh",
  },
  {
    postal_code: "95444",
    zip_name: "graton, ca",
  },
  {
    postal_code: "73848",
    zip_name: "laverne, ok",
  },
  {
    postal_code: "27832",
    zip_name: "gaston, nc",
  },
  {
    postal_code: "39859",
    zip_name: "iron city, ga",
  },
  {
    postal_code: "51639",
    zip_name: "farragut, ia",
  },
  {
    postal_code: "61007",
    zip_name: "baileyville, il",
  },
  {
    postal_code: "15043",
    zip_name: "georgetown, pa",
  },
  {
    postal_code: "17217",
    zip_name: "concord, pa",
  },
  {
    postal_code: "32449",
    zip_name: "wewahitchka, fl",
  },
  {
    postal_code: "24984",
    zip_name: "waiteville, wv",
  },
  {
    postal_code: "26678",
    zip_name: "mount lookout, wv",
  },
  {
    postal_code: "44861",
    zip_name: "old fort, oh",
  },
  {
    postal_code: "69151",
    zip_name: "maxwell, ne",
  },
  {
    postal_code: "41834",
    zip_name: "littcarr, ky",
  },
  {
    postal_code: "63955",
    zip_name: "oxly, mo",
  },
  {
    postal_code: "15864",
    zip_name: "summerville, pa",
  },
  {
    postal_code: "44099",
    zip_name: "windsor, oh",
  },
  {
    postal_code: "67627",
    zip_name: "catharine, ks",
  },
  {
    postal_code: "31714",
    zip_name: "ashburn, ga",
  },
  {
    postal_code: "59324",
    zip_name: "ekalaka, mt",
  },
  {
    postal_code: "5472",
    zip_name: "new haven, vt",
  },
  {
    postal_code: "4982",
    zip_name: "stratton, me",
  },
  {
    postal_code: "58788",
    zip_name: "towner, nd",
  },
  {
    postal_code: "36758",
    zip_name: "plantersville, al",
  },
  {
    postal_code: "42082",
    zip_name: "symsonia, ky",
  },
  {
    postal_code: "15316",
    zip_name: "brave, pa",
  },
  {
    postal_code: "83808",
    zip_name: "calder, id",
  },
  {
    postal_code: "95323",
    zip_name: "hickman, ca",
  },
  {
    postal_code: "42324",
    zip_name: "belton, ky",
  },
  {
    postal_code: "38961",
    zip_name: "tillatoba, ms",
  },
  {
    postal_code: "45686",
    zip_name: "vinton, oh",
  },
  {
    postal_code: "1050",
    zip_name: "huntington, ma",
  },
  {
    postal_code: "38878",
    zip_name: "vardaman, ms",
  },
  {
    postal_code: "82420",
    zip_name: "cowley, wy",
  },
  {
    postal_code: "30446",
    zip_name: "newington, ga",
  },
  {
    postal_code: "66438",
    zip_name: "home, ks",
  },
  {
    postal_code: "54622",
    zip_name: "cochrane, wi",
  },
  {
    postal_code: "95957",
    zip_name: "meridian, ca",
  },
  {
    postal_code: "17529",
    zip_name: "gordonville, pa",
  },
  {
    postal_code: "54947",
    zip_name: "larsen, wi",
  },
  {
    postal_code: "61818",
    zip_name: "cerro gordo, il",
  },
  {
    postal_code: "14145",
    zip_name: "strykersville, ny",
  },
  {
    postal_code: "67560",
    zip_name: "ness city, ks",
  },
  {
    postal_code: "56550",
    zip_name: "hendrum, mn",
  },
  {
    postal_code: "71949",
    zip_name: "jessieville, ar",
  },
  {
    postal_code: "2873",
    zip_name: "rockville, ri",
  },
  {
    postal_code: "3457",
    zip_name: "nelson, nh",
  },
  {
    postal_code: "58077",
    zip_name: "walcott, nd",
  },
  {
    postal_code: "26855",
    zip_name: "cabins, wv",
  },
  {
    postal_code: "52212",
    zip_name: "center junction, ia",
  },
  {
    postal_code: "55736",
    zip_name: "floodwood, mn",
  },
  {
    postal_code: "23442",
    zip_name: "temperanceville, va",
  },
  {
    postal_code: "5473",
    zip_name: "north ferrisburgh, vt",
  },
  {
    postal_code: "47011",
    zip_name: "bennington, in",
  },
  {
    postal_code: "75960",
    zip_name: "moscow, tx",
  },
  {
    postal_code: "62047",
    zip_name: "hardin, il",
  },
  {
    postal_code: "72045",
    zip_name: "el paso, ar",
  },
  {
    postal_code: "8733",
    zip_name: "lakehurst, nj",
  },
  {
    postal_code: "14728",
    zip_name: "dewittville, ny",
  },
  {
    postal_code: "71068",
    zip_name: "ringgold, la",
  },
  {
    postal_code: "54874",
    zip_name: "south range, wi",
  },
  {
    postal_code: "52337",
    zip_name: "stanwood, ia",
  },
  {
    postal_code: "68601",
    zip_name: "columbus, ne",
  },
  {
    postal_code: "47631",
    zip_name: "new harmony, in",
  },
  {
    postal_code: "83255",
    zip_name: "moore, id",
  },
  {
    postal_code: "49623",
    zip_name: "chase, mi",
  },
  {
    postal_code: "60911",
    zip_name: "ashkum, il",
  },
  {
    postal_code: "16370",
    zip_name: "west hickory, pa",
  },
  {
    postal_code: "56208",
    zip_name: "appleton, mn",
  },
  {
    postal_code: "4479",
    zip_name: "sangerville, me",
  },
  {
    postal_code: "12752",
    zip_name: "lake huntington, ny",
  },
  {
    postal_code: "42325",
    zip_name: "bremen, ky",
  },
  {
    postal_code: "35061",
    zip_name: "dolomite, al",
  },
  {
    postal_code: "16160",
    zip_name: "west pittsburg, pa",
  },
  {
    postal_code: "21869",
    zip_name: "vienna, md",
  },
  {
    postal_code: "14561",
    zip_name: "stanley, ny",
  },
  {
    postal_code: "12923",
    zip_name: "churubusco, ny",
  },
  {
    postal_code: "16651",
    zip_name: "houtzdale, pa",
  },
  {
    postal_code: "45153",
    zip_name: "moscow, oh",
  },
  {
    postal_code: "24421",
    zip_name: "churchville, va",
  },
  {
    postal_code: "23517",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "13651",
    zip_name: "henderson harbor, ny",
  },
  {
    postal_code: "52045",
    zip_name: "epworth, ia",
  },
  {
    postal_code: "99121",
    zip_name: "danville, wa",
  },
  {
    postal_code: "55756",
    zip_name: "kerrick, mn",
  },
  {
    postal_code: "74367",
    zip_name: "strang, ok",
  },
  {
    postal_code: "46570",
    zip_name: "tippecanoe, in",
  },
  {
    postal_code: "14101",
    zip_name: "machias, ny",
  },
  {
    postal_code: "56324",
    zip_name: "dalton, mn",
  },
  {
    postal_code: "29540",
    zip_name: "darlington, sc",
  },
  {
    postal_code: "19562",
    zip_name: "topton, pa",
  },
  {
    postal_code: "88242",
    zip_name: "hobbs, nm",
  },
  {
    postal_code: "88043",
    zip_name: "hurley, nm",
  },
  {
    postal_code: "55936",
    zip_name: "grand meadow, mn",
  },
  {
    postal_code: "72006",
    zip_name: "augusta, ar",
  },
  {
    postal_code: "17938",
    zip_name: "hegins, pa",
  },
  {
    postal_code: "62625",
    zip_name: "cantrall, il",
  },
  {
    postal_code: "97503",
    zip_name: "white city, or",
  },
  {
    postal_code: "46539",
    zip_name: "mentone, in",
  },
  {
    postal_code: "73547",
    zip_name: "granite, ok",
  },
  {
    postal_code: "54835",
    zip_name: "exeland, wi",
  },
  {
    postal_code: "74428",
    zip_name: "council hill, ok",
  },
  {
    postal_code: "65248",
    zip_name: "fayette, mo",
  },
  {
    postal_code: "81610",
    zip_name: "dinosaur, co",
  },
  {
    postal_code: "5836",
    zip_name: "east hardwick, vt",
  },
  {
    postal_code: "16912",
    zip_name: "blossburg, pa",
  },
  {
    postal_code: "4949",
    zip_name: "liberty, me",
  },
  {
    postal_code: "75845",
    zip_name: "groveton, tx",
  },
  {
    postal_code: "14716",
    zip_name: "brocton, ny",
  },
  {
    postal_code: "37028",
    zip_name: "bumpus mills, tn",
  },
  {
    postal_code: "3282",
    zip_name: "wentworth, nh",
  },
  {
    postal_code: "10501",
    zip_name: "amawalk, ny",
  },
  {
    postal_code: "31060",
    zip_name: "milan, ga",
  },
  {
    postal_code: "74932",
    zip_name: "cameron, ok",
  },
  {
    postal_code: "73057",
    zip_name: "maysville, ok",
  },
  {
    postal_code: "15083",
    zip_name: "sutersville, pa",
  },
  {
    postal_code: "23856",
    zip_name: "freeman, va",
  },
  {
    postal_code: "58282",
    zip_name: "walhalla, nd",
  },
  {
    postal_code: "97437",
    zip_name: "elmira, or",
  },
  {
    postal_code: "52306",
    zip_name: "mechanicsville, ia",
  },
  {
    postal_code: "52356",
    zip_name: "wellman, ia",
  },
  {
    postal_code: "47340",
    zip_name: "farmland, in",
  },
  {
    postal_code: "97867",
    zip_name: "north powder, or",
  },
  {
    postal_code: "46346",
    zip_name: "kingsford heights, in",
  },
  {
    postal_code: "27942",
    zip_name: "harrellsville, nc",
  },
  {
    postal_code: "42234",
    zip_name: "guthrie, ky",
  },
  {
    postal_code: "62806",
    zip_name: "albion, il",
  },
  {
    postal_code: "87742",
    zip_name: "rociada, nm",
  },
  {
    postal_code: "58256",
    zip_name: "manvel, nd",
  },
  {
    postal_code: "61489",
    zip_name: "williamsfield, il",
  },
  {
    postal_code: "38321",
    zip_name: "cedar grove, tn",
  },
  {
    postal_code: "71647",
    zip_name: "hermitage, ar",
  },
  {
    postal_code: "57031",
    zip_name: "gayville, sd",
  },
  {
    postal_code: "80653",
    zip_name: "weldona, co",
  },
  {
    postal_code: "84305",
    zip_name: "clarkston, ut",
  },
  {
    postal_code: "2746",
    zip_name: "new bedford, ma",
  },
  {
    postal_code: "66945",
    zip_name: "hanover, ks",
  },
  {
    postal_code: "51571",
    zip_name: "silver city, ia",
  },
  {
    postal_code: "27563",
    zip_name: "norlina, nc",
  },
  {
    postal_code: "54475",
    zip_name: "rudolph, wi",
  },
  {
    postal_code: "96129",
    zip_name: "beckwourth, ca",
  },
  {
    postal_code: "80611",
    zip_name: "briggsdale, co",
  },
  {
    postal_code: "71765",
    zip_name: "strong, ar",
  },
  {
    postal_code: "51549",
    zip_name: "macedonia, ia",
  },
  {
    postal_code: "4346",
    zip_name: "randolph, me",
  },
  {
    postal_code: "31044",
    zip_name: "jeffersonville, ga",
  },
  {
    postal_code: "3838",
    zip_name: "glen, nh",
  },
  {
    postal_code: "15678",
    zip_name: "rillton, pa",
  },
  {
    postal_code: "15019",
    zip_name: "bulger, pa",
  },
  {
    postal_code: "7102",
    zip_name: "newark, nj",
  },
  {
    postal_code: "61330",
    zip_name: "la moille, il",
  },
  {
    postal_code: "38633",
    zip_name: "hickory flat, ms",
  },
  {
    postal_code: "21405",
    zip_name: "annapolis, md",
  },
  {
    postal_code: "17518",
    zip_name: "drumore, pa",
  },
  {
    postal_code: "55793",
    zip_name: "warba, mn",
  },
  {
    postal_code: "17963",
    zip_name: "pine grove, pa",
  },
  {
    postal_code: "95548",
    zip_name: "klamath, ca",
  },
  {
    postal_code: "56652",
    zip_name: "leonard, mn",
  },
  {
    postal_code: "13355",
    zip_name: "hubbardsville, ny",
  },
  {
    postal_code: "61075",
    zip_name: "scales mound, il",
  },
  {
    postal_code: "54828",
    zip_name: "couderay, wi",
  },
  {
    postal_code: "51525",
    zip_name: "carson, ia",
  },
  {
    postal_code: "71652",
    zip_name: "kingsland, ar",
  },
  {
    postal_code: "98585",
    zip_name: "silver creek, wa",
  },
  {
    postal_code: "42647",
    zip_name: "stearns, ky",
  },
  {
    postal_code: "72069",
    zip_name: "holly grove, ar",
  },
  {
    postal_code: "5648",
    zip_name: "calais, vt",
  },
  {
    postal_code: "50510",
    zip_name: "albert city, ia",
  },
  {
    postal_code: "49822",
    zip_name: "deerton, mi",
  },
  {
    postal_code: "45896",
    zip_name: "waynesfield, oh",
  },
  {
    postal_code: "62885",
    zip_name: "shobonier, il",
  },
  {
    postal_code: "68866",
    zip_name: "pleasanton, ne",
  },
  {
    postal_code: "50271",
    zip_name: "williams, ia",
  },
  {
    postal_code: "66072",
    zip_name: "parker, ks",
  },
  {
    postal_code: "21840",
    zip_name: "nanticoke, md",
  },
  {
    postal_code: "5154",
    zip_name: "saxtons river, vt",
  },
  {
    postal_code: "12456",
    zip_name: "mount marion, ny",
  },
  {
    postal_code: "48476",
    zip_name: "vernon, mi",
  },
  {
    postal_code: "4438",
    zip_name: "frankfort, me",
  },
  {
    postal_code: "12918",
    zip_name: "cadyville, ny",
  },
  {
    postal_code: "76436",
    zip_name: "carlton, tx",
  },
  {
    postal_code: "48435",
    zip_name: "fostoria, mi",
  },
  {
    postal_code: "70750",
    zip_name: "krotz springs, la",
  },
  {
    postal_code: "29178",
    zip_name: "whitmire, sc",
  },
  {
    postal_code: "71638",
    zip_name: "dermott, ar",
  },
  {
    postal_code: "68505",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "88023",
    zip_name: "bayard, nm",
  },
  {
    postal_code: "35764",
    zip_name: "paint rock, al",
  },
  {
    postal_code: "10511",
    zip_name: "buchanan, ny",
  },
  {
    postal_code: "55726",
    zip_name: "cromwell, mn",
  },
  {
    postal_code: "69040",
    zip_name: "palisade, ne",
  },
  {
    postal_code: "98565",
    zip_name: "napavine, wa",
  },
  {
    postal_code: "13832",
    zip_name: "plymouth, ny",
  },
  {
    postal_code: "4547",
    zip_name: "friendship, me",
  },
  {
    postal_code: "46764",
    zip_name: "larwill, in",
  },
  {
    postal_code: "5841",
    zip_name: "greensboro, vt",
  },
  {
    postal_code: "75968",
    zip_name: "pineland, tx",
  },
  {
    postal_code: "46957",
    zip_name: "matthews, in",
  },
  {
    postal_code: "15311",
    zip_name: "amity, pa",
  },
  {
    postal_code: "18823",
    zip_name: "harford, pa",
  },
  {
    postal_code: "79250",
    zip_name: "petersburg, tx",
  },
  {
    postal_code: "75449",
    zip_name: "ladonia, tx",
  },
  {
    postal_code: "4558",
    zip_name: "pemaquid, me",
  },
  {
    postal_code: "4108",
    zip_name: "peaks island, me",
  },
  {
    postal_code: "58056",
    zip_name: "luverne, nd",
  },
  {
    postal_code: "64784",
    zip_name: "sheldon, mo",
  },
  {
    postal_code: "23915",
    zip_name: "baskerville, va",
  },
  {
    postal_code: "23959",
    zip_name: "phenix, va",
  },
  {
    postal_code: "21628",
    zip_name: "crumpton, md",
  },
  {
    postal_code: "68633",
    zip_name: "dodge, ne",
  },
  {
    postal_code: "46705",
    zip_name: "ashley, in",
  },
  {
    postal_code: "17810",
    zip_name: "allenwood, pa",
  },
  {
    postal_code: "87021",
    zip_name: "milan, nm",
  },
  {
    postal_code: "58771",
    zip_name: "plaza, nd",
  },
  {
    postal_code: "32431",
    zip_name: "cottondale, fl",
  },
  {
    postal_code: "51563",
    zip_name: "persia, ia",
  },
  {
    postal_code: "3905",
    zip_name: "kittery point, me",
  },
  {
    postal_code: "54741",
    zip_name: "fairchild, wi",
  },
  {
    postal_code: "69044",
    zip_name: "trenton, ne",
  },
  {
    postal_code: "2534",
    zip_name: "cataumet, ma",
  },
  {
    postal_code: "78215",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "4787",
    zip_name: "westfield, me",
  },
  {
    postal_code: "26151",
    zip_name: "mount zion, wv",
  },
  {
    postal_code: "48860",
    zip_name: "muir, mi",
  },
  {
    postal_code: "63545",
    zip_name: "green city, mo",
  },
  {
    postal_code: "49967",
    zip_name: "trout creek, mi",
  },
  {
    postal_code: "43148",
    zip_name: "pleasantville, oh",
  },
  {
    postal_code: "4640",
    zip_name: "hancock, me",
  },
  {
    postal_code: "44450",
    zip_name: "north bloomfield, oh",
  },
  {
    postal_code: "15347",
    zip_name: "meadow lands, pa",
  },
  {
    postal_code: "54932",
    zip_name: "eldorado, wi",
  },
  {
    postal_code: "80457",
    zip_name: "kittredge, co",
  },
  {
    postal_code: "33514",
    zip_name: "center hill, fl",
  },
  {
    postal_code: "66835",
    zip_name: "americus, ks",
  },
  {
    postal_code: "69348",
    zip_name: "hemingford, ne",
  },
  {
    postal_code: "1070",
    zip_name: "plainfield, ma",
  },
  {
    postal_code: "68331",
    zip_name: "cortland, ne",
  },
  {
    postal_code: "15018",
    zip_name: "buena vista, pa",
  },
  {
    postal_code: "41033",
    zip_name: "de mossville, ky",
  },
  {
    postal_code: "44235",
    zip_name: "homerville, oh",
  },
  {
    postal_code: "61778",
    zip_name: "waynesville, il",
  },
  {
    postal_code: "95563",
    zip_name: "salyer, ca",
  },
  {
    postal_code: "57221",
    zip_name: "bryant, sd",
  },
  {
    postal_code: "60931",
    zip_name: "donovan, il",
  },
  {
    postal_code: "65566",
    zip_name: "viburnum, mo",
  },
  {
    postal_code: "44068",
    zip_name: "north kingsville, oh",
  },
  {
    postal_code: "79561",
    zip_name: "trent, tx",
  },
  {
    postal_code: "28683",
    zip_name: "thurmond, nc",
  },
  {
    postal_code: "15771",
    zip_name: "rochester mills, pa",
  },
  {
    postal_code: "28526",
    zip_name: "dover, nc",
  },
  {
    postal_code: "48885",
    zip_name: "sidney, mi",
  },
  {
    postal_code: "23440",
    zip_name: "tangier, va",
  },
  {
    postal_code: "19534",
    zip_name: "lenhartsville, pa",
  },
  {
    postal_code: "13361",
    zip_name: "jordanville, ny",
  },
  {
    postal_code: "59919",
    zip_name: "hungry horse, mt",
  },
  {
    postal_code: "23894",
    zip_name: "wilsons, va",
  },
  {
    postal_code: "39038",
    zip_name: "belzoni, ms",
  },
  {
    postal_code: "23821",
    zip_name: "alberta, va",
  },
  {
    postal_code: "5866",
    zip_name: "sheffield, vt",
  },
  {
    postal_code: "85137",
    zip_name: "kearny, az",
  },
  {
    postal_code: "17087",
    zip_name: "richland, pa",
  },
  {
    postal_code: "42459",
    zip_name: "sturgis, ky",
  },
  {
    postal_code: "41171",
    zip_name: "sandy hook, ky",
  },
  {
    postal_code: "78058",
    zip_name: "mountain home, tx",
  },
  {
    postal_code: "47638",
    zip_name: "wadesville, in",
  },
  {
    postal_code: "97738",
    zip_name: "hines, or",
  },
  {
    postal_code: "18651",
    zip_name: "plymouth, pa",
  },
  {
    postal_code: "84767",
    zip_name: "springdale, ut",
  },
  {
    postal_code: "65023",
    zip_name: "centertown, mo",
  },
  {
    postal_code: "62898",
    zip_name: "woodlawn, il",
  },
  {
    postal_code: "49903",
    zip_name: "amasa, mi",
  },
  {
    postal_code: "92358",
    zip_name: "lytle creek, ca",
  },
  {
    postal_code: "22949",
    zip_name: "lovingston, va",
  },
  {
    postal_code: "45168",
    zip_name: "russellville, oh",
  },
  {
    postal_code: "19936",
    zip_name: "cheswold, de",
  },
  {
    postal_code: "62262",
    zip_name: "mulberry grove, il",
  },
  {
    postal_code: "67583",
    zip_name: "turon, ks",
  },
  {
    postal_code: "79838",
    zip_name: "fabens, tx",
  },
  {
    postal_code: "21668",
    zip_name: "sudlersville, md",
  },
  {
    postal_code: "38748",
    zip_name: "hollandale, ms",
  },
  {
    postal_code: "14747",
    zip_name: "kennedy, ny",
  },
  {
    postal_code: "23091",
    zip_name: "little plymouth, va",
  },
  {
    postal_code: "52144",
    zip_name: "fort atkinson, ia",
  },
  {
    postal_code: "64474",
    zip_name: "osborn, mo",
  },
  {
    postal_code: "71072",
    zip_name: "shongaloo, la",
  },
  {
    postal_code: "74042",
    zip_name: "lenapah, ok",
  },
  {
    postal_code: "66008",
    zip_name: "bendena, ks",
  },
  {
    postal_code: "68047",
    zip_name: "pender, ne",
  },
  {
    postal_code: "29355",
    zip_name: "kinards, sc",
  },
  {
    postal_code: "13072",
    zip_name: "georgetown, ny",
  },
  {
    postal_code: "5072",
    zip_name: "strafford, vt",
  },
  {
    postal_code: "34140",
    zip_name: "goodland, fl",
  },
  {
    postal_code: "28399",
    zip_name: "white oak, nc",
  },
  {
    postal_code: "84018",
    zip_name: "croydon, ut",
  },
  {
    postal_code: "4776",
    zip_name: "sherman, me",
  },
  {
    postal_code: "32460",
    zip_name: "sneads, fl",
  },
  {
    postal_code: "51357",
    zip_name: "royal, ia",
  },
  {
    postal_code: "62994",
    zip_name: "vergennes, il",
  },
  {
    postal_code: "95915",
    zip_name: "belden, ca",
  },
  {
    postal_code: "62985",
    zip_name: "simpson, il",
  },
  {
    postal_code: "47974",
    zip_name: "perrysville, in",
  },
  {
    postal_code: "79517",
    zip_name: "fluvanna, tx",
  },
  {
    postal_code: "40958",
    zip_name: "kettle island, ky",
  },
  {
    postal_code: "25517",
    zip_name: "genoa, wv",
  },
  {
    postal_code: "58442",
    zip_name: "gackle, nd",
  },
  {
    postal_code: "5150",
    zip_name: "north springfield, vt",
  },
  {
    postal_code: "66523",
    zip_name: "osage city, ks",
  },
  {
    postal_code: "68814",
    zip_name: "ansley, ne",
  },
  {
    postal_code: "8348",
    zip_name: "port elizabeth, nj",
  },
  {
    postal_code: "46039",
    zip_name: "forest, in",
  },
  {
    postal_code: "24366",
    zip_name: "rocky gap, va",
  },
  {
    postal_code: "45786",
    zip_name: "waterford, oh",
  },
  {
    postal_code: "16027",
    zip_name: "connoquenessing, pa",
  },
  {
    postal_code: "84649",
    zip_name: "oak city, ut",
  },
  {
    postal_code: "47164",
    zip_name: "palmyra, in",
  },
  {
    postal_code: "48456",
    zip_name: "minden city, mi",
  },
  {
    postal_code: "65329",
    zip_name: "florence, mo",
  },
  {
    postal_code: "43946",
    zip_name: "sardis, oh",
  },
  {
    postal_code: "51541",
    zip_name: "henderson, ia",
  },
  {
    postal_code: "99034",
    zip_name: "tumtum, wa",
  },
  {
    postal_code: "88250",
    zip_name: "hope, nm",
  },
  {
    postal_code: "23829",
    zip_name: "capron, va",
  },
  {
    postal_code: "28432",
    zip_name: "clarendon, nc",
  },
  {
    postal_code: "15520",
    zip_name: "acosta, pa",
  },
  {
    postal_code: "45621",
    zip_name: "coalton, oh",
  },
  {
    postal_code: "95830",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "25048",
    zip_name: "colcord, wv",
  },
  {
    postal_code: "95412",
    zip_name: "annapolis, ca",
  },
  {
    postal_code: "72536",
    zip_name: "franklin, ar",
  },
  {
    postal_code: "82210",
    zip_name: "chugwater, wy",
  },
  {
    postal_code: "62512",
    zip_name: "beason, il",
  },
  {
    postal_code: "77880",
    zip_name: "washington, tx",
  },
  {
    postal_code: "78615",
    zip_name: "coupland, tx",
  },
  {
    postal_code: "15641",
    zip_name: "hyde park, pa",
  },
  {
    postal_code: "46766",
    zip_name: "liberty center, in",
  },
  {
    postal_code: "49815",
    zip_name: "channing, mi",
  },
  {
    postal_code: "18913",
    zip_name: "carversville, pa",
  },
  {
    postal_code: "45740",
    zip_name: "jacksonville, oh",
  },
  {
    postal_code: "10519",
    zip_name: "croton falls, ny",
  },
  {
    postal_code: "95659",
    zip_name: "nicolaus, ca",
  },
  {
    postal_code: "2824",
    zip_name: "forestdale, ri",
  },
  {
    postal_code: "47610",
    zip_name: "chandler, in",
  },
  {
    postal_code: "45332",
    zip_name: "hollansburg, oh",
  },
  {
    postal_code: "47360",
    zip_name: "mooreland, in",
  },
  {
    postal_code: "27229",
    zip_name: "candor, nc",
  },
  {
    postal_code: "48468",
    zip_name: "port hope, mi",
  },
  {
    postal_code: "43341",
    zip_name: "new bloomington, oh",
  },
  {
    postal_code: "16319",
    zip_name: "cranberry, pa",
  },
  {
    postal_code: "14462",
    zip_name: "groveland, ny",
  },
  {
    postal_code: "66509",
    zip_name: "mayetta, ks",
  },
  {
    postal_code: "29039",
    zip_name: "cordova, sc",
  },
  {
    postal_code: "18851",
    zip_name: "warren center, pa",
  },
  {
    postal_code: "74944",
    zip_name: "mccurtain, ok",
  },
  {
    postal_code: "49050",
    zip_name: "dowling, mi",
  },
  {
    postal_code: "65347",
    zip_name: "nelson, mo",
  },
  {
    postal_code: "62359",
    zip_name: "paloma, il",
  },
  {
    postal_code: "45362",
    zip_name: "rossburg, oh",
  },
  {
    postal_code: "12884",
    zip_name: "victory mills, ny",
  },
  {
    postal_code: "67019",
    zip_name: "burden, ks",
  },
  {
    postal_code: "78384",
    zip_name: "san diego, tx",
  },
  {
    postal_code: "66516",
    zip_name: "netawaka, ks",
  },
  {
    postal_code: "49874",
    zip_name: "powers, mi",
  },
  {
    postal_code: "2561",
    zip_name: "sagamore, ma",
  },
  {
    postal_code: "58277",
    zip_name: "sharon, nd",
  },
  {
    postal_code: "70759",
    zip_name: "morganza, la",
  },
  {
    postal_code: "81141",
    zip_name: "manassa, co",
  },
  {
    postal_code: "68927",
    zip_name: "bertrand, ne",
  },
  {
    postal_code: "57315",
    zip_name: "avon, sd",
  },
  {
    postal_code: "89442",
    zip_name: "wadsworth, nv",
  },
  {
    postal_code: "56669",
    zip_name: "kabetogama, mn",
  },
  {
    postal_code: "62982",
    zip_name: "rosiclare, il",
  },
  {
    postal_code: "4768",
    zip_name: "portage, me",
  },
  {
    postal_code: "17978",
    zip_name: "spring glen, pa",
  },
  {
    postal_code: "49876",
    zip_name: "quinnesec, mi",
  },
  {
    postal_code: "14139",
    zip_name: "south wales, ny",
  },
  {
    postal_code: "5050",
    zip_name: "mc indoe falls, vt",
  },
  {
    postal_code: "58731",
    zip_name: "deering, nd",
  },
  {
    postal_code: "79261",
    zip_name: "turkey, tx",
  },
  {
    postal_code: "59928",
    zip_name: "polebridge, mt",
  },
  {
    postal_code: "27978",
    zip_name: "stumpy point, nc",
  },
  {
    postal_code: "63867",
    zip_name: "matthews, mo",
  },
  {
    postal_code: "83657",
    zip_name: "ola, id",
  },
  {
    postal_code: "1338",
    zip_name: "buckland, ma",
  },
  {
    postal_code: "41503",
    zip_name: "south williamson, ky",
  },
  {
    postal_code: "66024",
    zip_name: "elwood, ks",
  },
  {
    postal_code: "76632",
    zip_name: "chilton, tx",
  },
  {
    postal_code: "74954",
    zip_name: "roland, ok",
  },
  {
    postal_code: "61839",
    zip_name: "de land, il",
  },
  {
    postal_code: "39826",
    zip_name: "bronwood, ga",
  },
  {
    postal_code: "50262",
    zip_name: "van wert, ia",
  },
  {
    postal_code: "15823",
    zip_name: "brockport, pa",
  },
  {
    postal_code: "61014",
    zip_name: "chadwick, il",
  },
  {
    postal_code: "81210",
    zip_name: "almont, co",
  },
  {
    postal_code: "16683",
    zip_name: "spruce creek, pa",
  },
  {
    postal_code: "31765",
    zip_name: "meigs, ga",
  },
  {
    postal_code: "42742",
    zip_name: "gradyville, ky",
  },
  {
    postal_code: "51529",
    zip_name: "dunlap, ia",
  },
  {
    postal_code: "56683",
    zip_name: "tenstrike, mn",
  },
  {
    postal_code: "62817",
    zip_name: "broughton, il",
  },
  {
    postal_code: "62835",
    zip_name: "enfield, il",
  },
  {
    postal_code: "59223",
    zip_name: "fort peck, mt",
  },
  {
    postal_code: "15088",
    zip_name: "west elizabeth, pa",
  },
  {
    postal_code: "74875",
    zip_name: "tryon, ok",
  },
  {
    postal_code: "63463",
    zip_name: "philadelphia, mo",
  },
  {
    postal_code: "58011",
    zip_name: "buffalo, nd",
  },
  {
    postal_code: "25206",
    zip_name: "van, wv",
  },
  {
    postal_code: "68629",
    zip_name: "clarkson, ne",
  },
  {
    postal_code: "47136",
    zip_name: "lanesville, in",
  },
  {
    postal_code: "65479",
    zip_name: "hartshorn, mo",
  },
  {
    postal_code: "58735",
    zip_name: "douglas, nd",
  },
  {
    postal_code: "74963",
    zip_name: "watson, ok",
  },
  {
    postal_code: "74082",
    zip_name: "vera, ok",
  },
  {
    postal_code: "80819",
    zip_name: "green mountain falls, co",
  },
  {
    postal_code: "83827",
    zip_name: "elk river, id",
  },
  {
    postal_code: "96058",
    zip_name: "macdoel, ca",
  },
  {
    postal_code: "66015",
    zip_name: "colony, ks",
  },
  {
    postal_code: "67653",
    zip_name: "norcatur, ks",
  },
  {
    postal_code: "39354",
    zip_name: "preston, ms",
  },
  {
    postal_code: "84327",
    zip_name: "newton, ut",
  },
  {
    postal_code: "70342",
    zip_name: "berwick, la",
  },
  {
    postal_code: "3595",
    zip_name: "twin mountain, nh",
  },
  {
    postal_code: "54855",
    zip_name: "marengo, wi",
  },
  {
    postal_code: "42157",
    zip_name: "mount hermon, ky",
  },
  {
    postal_code: "65246",
    zip_name: "dalton, mo",
  },
  {
    postal_code: "38390",
    zip_name: "yuma, tn",
  },
  {
    postal_code: "82644",
    zip_name: "mills, wy",
  },
  {
    postal_code: "29845",
    zip_name: "plum branch, sc",
  },
  {
    postal_code: "41615",
    zip_name: "dana, ky",
  },
  {
    postal_code: "4734",
    zip_name: "blaine, me",
  },
  {
    postal_code: "93226",
    zip_name: "glennville, ca",
  },
  {
    postal_code: "72926",
    zip_name: "boles, ar",
  },
  {
    postal_code: "12967",
    zip_name: "north lawrence, ny",
  },
  {
    postal_code: "53810",
    zip_name: "glen haven, wi",
  },
  {
    postal_code: "20758",
    zip_name: "friendship, md",
  },
  {
    postal_code: "14898",
    zip_name: "woodhull, ny",
  },
  {
    postal_code: "17812",
    zip_name: "beaver springs, pa",
  },
  {
    postal_code: "95456",
    zip_name: "little river, ca",
  },
  {
    postal_code: "88053",
    zip_name: "pinos altos, nm",
  },
  {
    postal_code: "85618",
    zip_name: "mammoth, az",
  },
  {
    postal_code: "71073",
    zip_name: "sibley, la",
  },
  {
    postal_code: "78595",
    zip_name: "sullivan city, tx",
  },
  {
    postal_code: "78650",
    zip_name: "mc dade, tx",
  },
  {
    postal_code: "1745",
    zip_name: "fayville, ma",
  },
  {
    postal_code: "30648",
    zip_name: "lexington, ga",
  },
  {
    postal_code: "89085",
    zip_name: "north las vegas, nv",
  },
  {
    postal_code: "50859",
    zip_name: "prescott, ia",
  },
  {
    postal_code: "88044",
    zip_name: "la mesa, nm",
  },
  {
    postal_code: "83809",
    zip_name: "careywood, id",
  },
  {
    postal_code: "57479",
    zip_name: "warner, sd",
  },
  {
    postal_code: "69146",
    zip_name: "lemoyne, ne",
  },
  {
    postal_code: "12934",
    zip_name: "ellenburg center, ny",
  },
  {
    postal_code: "4411",
    zip_name: "bradley, me",
  },
  {
    postal_code: "61572",
    zip_name: "yates city, il",
  },
  {
    postal_code: "23119",
    zip_name: "moon, va",
  },
  {
    postal_code: "86025",
    zip_name: "holbrook, az",
  },
  {
    postal_code: "27843",
    zip_name: "hobgood, nc",
  },
  {
    postal_code: "62032",
    zip_name: "fillmore, il",
  },
  {
    postal_code: "41642",
    zip_name: "ivel, ky",
  },
  {
    postal_code: "36432",
    zip_name: "castleberry, al",
  },
  {
    postal_code: "20615",
    zip_name: "broomes island, md",
  },
  {
    postal_code: "38471",
    zip_name: "lutts, tn",
  },
  {
    postal_code: "17739",
    zip_name: "jersey mills, pa",
  },
  {
    postal_code: "31789",
    zip_name: "sumner, ga",
  },
  {
    postal_code: "57319",
    zip_name: "bridgewater, sd",
  },
  {
    postal_code: "7878",
    zip_name: "mount tabor, nj",
  },
  {
    postal_code: "12176",
    zip_name: "surprise, ny",
  },
  {
    postal_code: "52657",
    zip_name: "saint paul, ia",
  },
  {
    postal_code: "95956",
    zip_name: "meadow valley, ca",
  },
  {
    postal_code: "4275",
    zip_name: "roxbury, me",
  },
  {
    postal_code: "64722",
    zip_name: "amoret, mo",
  },
  {
    postal_code: "18503",
    zip_name: "scranton, pa",
  },
  {
    postal_code: "76250",
    zip_name: "lindsay, tx",
  },
  {
    postal_code: "66532",
    zip_name: "robinson, ks",
  },
  {
    postal_code: "67953",
    zip_name: "richfield, ks",
  },
  {
    postal_code: "55780",
    zip_name: "sawyer, mn",
  },
  {
    postal_code: "82310",
    zip_name: "jeffrey city, wy",
  },
  {
    postal_code: "80729",
    zip_name: "grover, co",
  },
  {
    postal_code: "67070",
    zip_name: "kiowa, ks",
  },
  {
    postal_code: "96015",
    zip_name: "canby, ca",
  },
  {
    postal_code: "78935",
    zip_name: "alleyton, tx",
  },
  {
    postal_code: "15047",
    zip_name: "greenock, pa",
  },
  {
    postal_code: "68304",
    zip_name: "alvo, ne",
  },
  {
    postal_code: "74571",
    zip_name: "talihina, ok",
  },
  {
    postal_code: "58778",
    zip_name: "ruso, nd",
  },
  {
    postal_code: "61814",
    zip_name: "bismarck, il",
  },
  {
    postal_code: "24271",
    zip_name: "nickelsville, va",
  },
  {
    postal_code: "63637",
    zip_name: "doe run, mo",
  },
  {
    postal_code: "5677",
    zip_name: "waterbury center, vt",
  },
  {
    postal_code: "73141",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "50038",
    zip_name: "booneville, ia",
  },
  {
    postal_code: "70732",
    zip_name: "fordoche, la",
  },
  {
    postal_code: "26149",
    zip_name: "middlebourne, wv",
  },
  {
    postal_code: "95930",
    zip_name: "clipper mills, ca",
  },
  {
    postal_code: "58750",
    zip_name: "lansford, nd",
  },
  {
    postal_code: "17957",
    zip_name: "muir, pa",
  },
  {
    postal_code: "51466",
    zip_name: "wall lake, ia",
  },
  {
    postal_code: "65702",
    zip_name: "macomb, mo",
  },
  {
    postal_code: "71935",
    zip_name: "caddo gap, ar",
  },
  {
    postal_code: "84512",
    zip_name: "bluff, ut",
  },
  {
    postal_code: "27965",
    zip_name: "poplar branch, nc",
  },
  {
    postal_code: "26270",
    zip_name: "harman, wv",
  },
  {
    postal_code: "61870",
    zip_name: "ridge farm, il",
  },
  {
    postal_code: "66753",
    zip_name: "mc cune, ks",
  },
  {
    postal_code: "62367",
    zip_name: "plymouth, il",
  },
  {
    postal_code: "67416",
    zip_name: "assaria, ks",
  },
  {
    postal_code: "18059",
    zip_name: "laurys station, pa",
  },
  {
    postal_code: "36548",
    zip_name: "leroy, al",
  },
  {
    postal_code: "53016",
    zip_name: "clyman, wi",
  },
  {
    postal_code: "61774",
    zip_name: "stanford, il",
  },
  {
    postal_code: "5052",
    zip_name: "north hartland, vt",
  },
  {
    postal_code: "57528",
    zip_name: "colome, sd",
  },
  {
    postal_code: "4616",
    zip_name: "brooklin, me",
  },
  {
    postal_code: "50005",
    zip_name: "albion, ia",
  },
  {
    postal_code: "55385",
    zip_name: "stewart, mn",
  },
  {
    postal_code: "1901",
    zip_name: "lynn, ma",
  },
  {
    postal_code: "44449",
    zip_name: "north benton, oh",
  },
  {
    postal_code: "14504",
    zip_name: "manchester, ny",
  },
  {
    postal_code: "16501",
    zip_name: "erie, pa",
  },
  {
    postal_code: "50483",
    zip_name: "wesley, ia",
  },
  {
    postal_code: "78742",
    zip_name: "austin, tx",
  },
  {
    postal_code: "52535",
    zip_name: "birmingham, ia",
  },
  {
    postal_code: "18936",
    zip_name: "montgomeryville, pa",
  },
  {
    postal_code: "57454",
    zip_name: "langford, sd",
  },
  {
    postal_code: "97484",
    zip_name: "tiller, or",
  },
  {
    postal_code: "50146",
    zip_name: "linden, ia",
  },
  {
    postal_code: "62323",
    zip_name: "chambersburg, il",
  },
  {
    postal_code: "60952",
    zip_name: "melvin, il",
  },
  {
    postal_code: "3852",
    zip_name: "milton mills, nh",
  },
  {
    postal_code: "25421",
    zip_name: "glengary, wv",
  },
  {
    postal_code: "4342",
    zip_name: "dresden, me",
  },
  {
    postal_code: "84736",
    zip_name: "henrieville, ut",
  },
  {
    postal_code: "50167",
    zip_name: "minburn, ia",
  },
  {
    postal_code: "18220",
    zip_name: "delano, pa",
  },
  {
    postal_code: "15477",
    zip_name: "roscoe, pa",
  },
  {
    postal_code: "43948",
    zip_name: "smithfield, oh",
  },
  {
    postal_code: "8346",
    zip_name: "newtonville, nj",
  },
  {
    postal_code: "84621",
    zip_name: "axtell, ut",
  },
  {
    postal_code: "44045",
    zip_name: "grand river, oh",
  },
  {
    postal_code: "68456",
    zip_name: "utica, ne",
  },
  {
    postal_code: "3780",
    zip_name: "pike, nh",
  },
  {
    postal_code: "64491",
    zip_name: "tarkio, mo",
  },
  {
    postal_code: "62969",
    zip_name: "olive branch, il",
  },
  {
    postal_code: "29843",
    zip_name: "olar, sc",
  },
  {
    postal_code: "55017",
    zip_name: "dalbo, mn",
  },
  {
    postal_code: "62289",
    zip_name: "summerfield, il",
  },
  {
    postal_code: "49338",
    zip_name: "paris, mi",
  },
  {
    postal_code: "68324",
    zip_name: "burr, ne",
  },
  {
    postal_code: "60910",
    zip_name: "aroma park, il",
  },
  {
    postal_code: "50628",
    zip_name: "elma, ia",
  },
  {
    postal_code: "63350",
    zip_name: "high hill, mo",
  },
  {
    postal_code: "97857",
    zip_name: "lostine, or",
  },
  {
    postal_code: "95303",
    zip_name: "ballico, ca",
  },
  {
    postal_code: "39159",
    zip_name: "rolling fork, ms",
  },
  {
    postal_code: "67436",
    zip_name: "delphos, ks",
  },
  {
    postal_code: "47943",
    zip_name: "fair oaks, in",
  },
  {
    postal_code: "45883",
    zip_name: "saint henry, oh",
  },
  {
    postal_code: "46730",
    zip_name: "corunna, in",
  },
  {
    postal_code: "52656",
    zip_name: "west point, ia",
  },
  {
    postal_code: "5043",
    zip_name: "east thetford, vt",
  },
  {
    postal_code: "44611",
    zip_name: "big prairie, oh",
  },
  {
    postal_code: "25969",
    zip_name: "jumping branch, wv",
  },
  {
    postal_code: "4535",
    zip_name: "alna, me",
  },
  {
    postal_code: "50128",
    zip_name: "jamaica, ia",
  },
  {
    postal_code: "22731",
    zip_name: "pratts, va",
  },
  {
    postal_code: "56590",
    zip_name: "wendell, mn",
  },
  {
    postal_code: "62017",
    zip_name: "coffeen, il",
  },
  {
    postal_code: "61432",
    zip_name: "fairview, il",
  },
  {
    postal_code: "98224",
    zip_name: "baring, wa",
  },
  {
    postal_code: "55712",
    zip_name: "bruno, mn",
  },
  {
    postal_code: "8073",
    zip_name: "rancocas, nj",
  },
  {
    postal_code: "64443",
    zip_name: "easton, mo",
  },
  {
    postal_code: "21540",
    zip_name: "luke, md",
  },
  {
    postal_code: "85141",
    zip_name: "picacho, az",
  },
  {
    postal_code: "44627",
    zip_name: "fredericksburg, oh",
  },
  {
    postal_code: "54007",
    zip_name: "deer park, wi",
  },
  {
    postal_code: "44634",
    zip_name: "homeworth, oh",
  },
  {
    postal_code: "65470",
    zip_name: "falcon, mo",
  },
  {
    postal_code: "13630",
    zip_name: "de kalb junction, ny",
  },
  {
    postal_code: "48615",
    zip_name: "breckenridge, mi",
  },
  {
    postal_code: "82636",
    zip_name: "evansville, wy",
  },
  {
    postal_code: "36069",
    zip_name: "ramer, al",
  },
  {
    postal_code: "29101",
    zip_name: "mc bee, sc",
  },
  {
    postal_code: "37888",
    zip_name: "washburn, tn",
  },
  {
    postal_code: "75681",
    zip_name: "mount enterprise, tx",
  },
  {
    postal_code: "16740",
    zip_name: "mount jewett, pa",
  },
  {
    postal_code: "4785",
    zip_name: "van buren, me",
  },
  {
    postal_code: "14058",
    zip_name: "elba, ny",
  },
  {
    postal_code: "42337",
    zip_name: "drakesboro, ky",
  },
  {
    postal_code: "79325",
    zip_name: "farwell, tx",
  },
  {
    postal_code: "12747",
    zip_name: "hurleyville, ny",
  },
  {
    postal_code: "47352",
    zip_name: "lewisville, in",
  },
  {
    postal_code: "4556",
    zip_name: "edgecomb, me",
  },
  {
    postal_code: "95043",
    zip_name: "paicines, ca",
  },
  {
    postal_code: "43337",
    zip_name: "morral, oh",
  },
  {
    postal_code: "57353",
    zip_name: "iroquois, sd",
  },
  {
    postal_code: "81424",
    zip_name: "nucla, co",
  },
  {
    postal_code: "29170",
    zip_name: "west columbia, sc",
  },
  {
    postal_code: "54560",
    zip_name: "sayner, wi",
  },
  {
    postal_code: "21676",
    zip_name: "wittman, md",
  },
  {
    postal_code: "79848",
    zip_name: "sanderson, tx",
  },
  {
    postal_code: "74103",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "2462",
    zip_name: "newton lower falls, ma",
  },
  {
    postal_code: "36783",
    zip_name: "thomaston, al",
  },
  {
    postal_code: "62215",
    zip_name: "albers, il",
  },
  {
    postal_code: "27844",
    zip_name: "hollister, nc",
  },
  {
    postal_code: "12844",
    zip_name: "kattskill bay, ny",
  },
  {
    postal_code: "46772",
    zip_name: "monroe, in",
  },
  {
    postal_code: "36879",
    zip_name: "waverly, al",
  },
  {
    postal_code: "47639",
    zip_name: "haubstadt, in",
  },
  {
    postal_code: "73842",
    zip_name: "freedom, ok",
  },
  {
    postal_code: "23923",
    zip_name: "charlotte court house, va",
  },
  {
    postal_code: "4781",
    zip_name: "wallagrass, me",
  },
  {
    postal_code: "28578",
    zip_name: "seven springs, nc",
  },
  {
    postal_code: "96128",
    zip_name: "standish, ca",
  },
  {
    postal_code: "29056",
    zip_name: "greeleyville, sc",
  },
  {
    postal_code: "76878",
    zip_name: "santa anna, tx",
  },
  {
    postal_code: "39478",
    zip_name: "sandy hook, ms",
  },
  {
    postal_code: "75558",
    zip_name: "cookville, tx",
  },
  {
    postal_code: "79343",
    zip_name: "lorenzo, tx",
  },
  {
    postal_code: "62292",
    zip_name: "tilden, il",
  },
  {
    postal_code: "31006",
    zip_name: "butler, ga",
  },
  {
    postal_code: "62875",
    zip_name: "patoka, il",
  },
  {
    postal_code: "84311",
    zip_name: "fielding, ut",
  },
  {
    postal_code: "48464",
    zip_name: "otter lake, mi",
  },
  {
    postal_code: "40737",
    zip_name: "keavy, ky",
  },
  {
    postal_code: "48216",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "4611",
    zip_name: "beals, me",
  },
  {
    postal_code: "97121",
    zip_name: "hammond, or",
  },
  {
    postal_code: "37691",
    zip_name: "trade, tn",
  },
  {
    postal_code: "35660",
    zip_name: "sheffield, al",
  },
  {
    postal_code: "19560",
    zip_name: "temple, pa",
  },
  {
    postal_code: "7677",
    zip_name: "woodcliff lake, nj",
  },
  {
    postal_code: "35749",
    zip_name: "harvest, al",
  },
  {
    postal_code: "99517",
    zip_name: "anchorage, ak",
  },
  {
    postal_code: "32409",
    zip_name: "panama city, fl",
  },
  {
    postal_code: "54467",
    zip_name: "plover, wi",
  },
  {
    postal_code: "89005",
    zip_name: "boulder city, nv",
  },
  {
    postal_code: "31033",
    zip_name: "haddock, ga",
  },
  {
    postal_code: "7620",
    zip_name: "alpine, nj",
  },
  {
    postal_code: "49655",
    zip_name: "leroy, mi",
  },
  {
    postal_code: "83706",
    zip_name: "boise, id",
  },
  {
    postal_code: "49676",
    zip_name: "rapid city, mi",
  },
  {
    postal_code: "8085",
    zip_name: "swedesboro, nj",
  },
  {
    postal_code: "29812",
    zip_name: "barnwell, sc",
  },
  {
    postal_code: "53406",
    zip_name: "racine, wi",
  },
  {
    postal_code: "42220",
    zip_name: "elkton, ky",
  },
  {
    postal_code: "83623",
    zip_name: "glenns ferry, id",
  },
  {
    postal_code: "15554",
    zip_name: "new paris, pa",
  },
  {
    postal_code: "17064",
    zip_name: "mount gretna, pa",
  },
  {
    postal_code: "36575",
    zip_name: "semmes, al",
  },
  {
    postal_code: "7647",
    zip_name: "northvale, nj",
  },
  {
    postal_code: "7423",
    zip_name: "ho ho kus, nj",
  },
  {
    postal_code: "29829",
    zip_name: "graniteville, sc",
  },
  {
    postal_code: "72857",
    zip_name: "plainview, ar",
  },
  {
    postal_code: "14081",
    zip_name: "irving, ny",
  },
  {
    postal_code: "83712",
    zip_name: "boise, id",
  },
  {
    postal_code: "96782",
    zip_name: "pearl city, hi",
  },
  {
    postal_code: "7866",
    zip_name: "rockaway, nj",
  },
  {
    postal_code: "13808",
    zip_name: "morris, ny",
  },
  {
    postal_code: "29565",
    zip_name: "latta, sc",
  },
  {
    postal_code: "49718",
    zip_name: "carp lake, mi",
  },
  {
    postal_code: "7069",
    zip_name: "watchung, nj",
  },
  {
    postal_code: "48081",
    zip_name: "saint clair shores, mi",
  },
  {
    postal_code: "7628",
    zip_name: "dumont, nj",
  },
  {
    postal_code: "48313",
    zip_name: "sterling heights, mi",
  },
  {
    postal_code: "89131",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "8882",
    zip_name: "south river, nj",
  },
  {
    postal_code: "7421",
    zip_name: "hewitt, nj",
  },
  {
    postal_code: "73110",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "53118",
    zip_name: "dousman, wi",
  },
  {
    postal_code: "36026",
    zip_name: "equality, al",
  },
  {
    postal_code: "23487",
    zip_name: "windsor, va",
  },
  {
    postal_code: "49043",
    zip_name: "covert, mi",
  },
  {
    postal_code: "30906",
    zip_name: "augusta, ga",
  },
  {
    postal_code: "54491",
    zip_name: "white lake, wi",
  },
  {
    postal_code: "7604",
    zip_name: "hasbrouck heights, nj",
  },
  {
    postal_code: "35406",
    zip_name: "tuscaloosa, al",
  },
  {
    postal_code: "36301",
    zip_name: "dothan, al",
  },
  {
    postal_code: "88312",
    zip_name: "alto, nm",
  },
  {
    postal_code: "63965",
    zip_name: "van buren, mo",
  },
  {
    postal_code: "48048",
    zip_name: "new haven, mi",
  },
  {
    postal_code: "7719",
    zip_name: "belmar, nj",
  },
  {
    postal_code: "49337",
    zip_name: "newaygo, mi",
  },
  {
    postal_code: "88005",
    zip_name: "las cruces, nm",
  },
  {
    postal_code: "35811",
    zip_name: "huntsville, al",
  },
  {
    postal_code: "96701",
    zip_name: "aiea, hi",
  },
  {
    postal_code: "70812",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "53122",
    zip_name: "elm grove, wi",
  },
  {
    postal_code: "49246",
    zip_name: "horton, mi",
  },
  {
    postal_code: "89146",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "97538",
    zip_name: "selma, or",
  },
  {
    postal_code: "67152",
    zip_name: "wellington, ks",
  },
  {
    postal_code: "30417",
    zip_name: "claxton, ga",
  },
  {
    postal_code: "24901",
    zip_name: "lewisburg, wv",
  },
  {
    postal_code: "47126",
    zip_name: "henryville, in",
  },
  {
    postal_code: "93205",
    zip_name: "bodfish, ca",
  },
  {
    postal_code: "72936",
    zip_name: "greenwood, ar",
  },
  {
    postal_code: "54481",
    zip_name: "stevens point, wi",
  },
  {
    postal_code: "46738",
    zip_name: "garrett, in",
  },
  {
    postal_code: "89135",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "10594",
    zip_name: "thornwood, ny",
  },
  {
    postal_code: "7922",
    zip_name: "berkeley heights, nj",
  },
  {
    postal_code: "65203",
    zip_name: "columbia, mo",
  },
  {
    postal_code: "12520",
    zip_name: "cornwall on hudson, ny",
  },
  {
    postal_code: "47394",
    zip_name: "winchester, in",
  },
  {
    postal_code: "57104",
    zip_name: "sioux falls, sd",
  },
  {
    postal_code: "67205",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "71241",
    zip_name: "farmerville, la",
  },
  {
    postal_code: "7670",
    zip_name: "tenafly, nj",
  },
  {
    postal_code: "78537",
    zip_name: "donna, tx",
  },
  {
    postal_code: "66616",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "67213",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "54766",
    zip_name: "sheldon, wi",
  },
  {
    postal_code: "66755",
    zip_name: "moran, ks",
  },
  {
    postal_code: "58646",
    zip_name: "mott, nd",
  },
  {
    postal_code: "51247",
    zip_name: "rock valley, ia",
  },
  {
    postal_code: "18634",
    zip_name: "nanticoke, pa",
  },
  {
    postal_code: "54414",
    zip_name: "birnamwood, wi",
  },
  {
    postal_code: "48733",
    zip_name: "fairgrove, mi",
  },
  {
    postal_code: "56514",
    zip_name: "barnesville, mn",
  },
  {
    postal_code: "4973",
    zip_name: "searsmont, me",
  },
  {
    postal_code: "95536",
    zip_name: "ferndale, ca",
  },
  {
    postal_code: "16404",
    zip_name: "centerville, pa",
  },
  {
    postal_code: "16411",
    zip_name: "east springfield, pa",
  },
  {
    postal_code: "68008",
    zip_name: "blair, ne",
  },
  {
    postal_code: "82633",
    zip_name: "douglas, wy",
  },
  {
    postal_code: "53221",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "8835",
    zip_name: "manville, nj",
  },
  {
    postal_code: "7205",
    zip_name: "hillside, nj",
  },
  {
    postal_code: "32653",
    zip_name: "gainesville, fl",
  },
  {
    postal_code: "68528",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "17815",
    zip_name: "bloomsburg, pa",
  },
  {
    postal_code: "18844",
    zip_name: "springville, pa",
  },
  {
    postal_code: "17018",
    zip_name: "dauphin, pa",
  },
  {
    postal_code: "7758",
    zip_name: "port monmouth, nj",
  },
  {
    postal_code: "65255",
    zip_name: "hallsville, mo",
  },
  {
    postal_code: "35771",
    zip_name: "section, al",
  },
  {
    postal_code: "57070",
    zip_name: "viborg, sd",
  },
  {
    postal_code: "49891",
    zip_name: "trenary, mi",
  },
  {
    postal_code: "66101",
    zip_name: "kansas city, ks",
  },
  {
    postal_code: "48014",
    zip_name: "capac, mi",
  },
  {
    postal_code: "55708",
    zip_name: "biwabik, mn",
  },
  {
    postal_code: "4287",
    zip_name: "bowdoin, me",
  },
  {
    postal_code: "75638",
    zip_name: "daingerfield, tx",
  },
  {
    postal_code: "11718",
    zip_name: "brightwaters, ny",
  },
  {
    postal_code: "64463",
    zip_name: "king city, mo",
  },
  {
    postal_code: "15732",
    zip_name: "creekside, pa",
  },
  {
    postal_code: "7201",
    zip_name: "elizabeth, nj",
  },
  {
    postal_code: "53017",
    zip_name: "colgate, wi",
  },
  {
    postal_code: "49306",
    zip_name: "belmont, mi",
  },
  {
    postal_code: "10603",
    zip_name: "white plains, ny",
  },
  {
    postal_code: "49083",
    zip_name: "richland, mi",
  },
  {
    postal_code: "39350",
    zip_name: "philadelphia, ms",
  },
  {
    postal_code: "93241",
    zip_name: "lamont, ca",
  },
  {
    postal_code: "7746",
    zip_name: "marlboro, nj",
  },
  {
    postal_code: "4739",
    zip_name: "eagle lake, me",
  },
  {
    postal_code: "7650",
    zip_name: "palisades park, nj",
  },
  {
    postal_code: "84752",
    zip_name: "minersville, ut",
  },
  {
    postal_code: "72832",
    zip_name: "coal hill, ar",
  },
  {
    postal_code: "13668",
    zip_name: "norwood, ny",
  },
  {
    postal_code: "7465",
    zip_name: "wanaque, nj",
  },
  {
    postal_code: "68869",
    zip_name: "ravenna, ne",
  },
  {
    postal_code: "19607",
    zip_name: "reading, pa",
  },
  {
    postal_code: "89143",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "29078",
    zip_name: "lugoff, sc",
  },
  {
    postal_code: "32550",
    zip_name: "miramar beach, fl",
  },
  {
    postal_code: "53089",
    zip_name: "sussex, wi",
  },
  {
    postal_code: "67209",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "31774",
    zip_name: "ocilla, ga",
  },
  {
    postal_code: "10803",
    zip_name: "pelham, ny",
  },
  {
    postal_code: "89103",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "35957",
    zip_name: "boaz, al",
  },
  {
    postal_code: "30725",
    zip_name: "flintstone, ga",
  },
  {
    postal_code: "81132",
    zip_name: "del norte, co",
  },
  {
    postal_code: "71203",
    zip_name: "monroe, la",
  },
  {
    postal_code: "75760",
    zip_name: "cushing, tx",
  },
  {
    postal_code: "81082",
    zip_name: "trinidad, co",
  },
  {
    postal_code: "46563",
    zip_name: "plymouth, in",
  },
  {
    postal_code: "32759",
    zip_name: "oak hill, fl",
  },
  {
    postal_code: "7828",
    zip_name: "budd lake, nj",
  },
  {
    postal_code: "36081",
    zip_name: "troy, al",
  },
  {
    postal_code: "47353",
    zip_name: "liberty, in",
  },
  {
    postal_code: "7042",
    zip_name: "montclair, nj",
  },
  {
    postal_code: "7936",
    zip_name: "east hanover, nj",
  },
  {
    postal_code: "99567",
    zip_name: "chugiak, ak",
  },
  {
    postal_code: "71730",
    zip_name: "el dorado, ar",
  },
  {
    postal_code: "32534",
    zip_name: "pensacola, fl",
  },
  {
    postal_code: "37343",
    zip_name: "hixson, tn",
  },
  {
    postal_code: "54639",
    zip_name: "la farge, wi",
  },
  {
    postal_code: "42045",
    zip_name: "grand rivers, ky",
  },
  {
    postal_code: "14469",
    zip_name: "bloomfield, ny",
  },
  {
    postal_code: "12776",
    zip_name: "roscoe, ny",
  },
  {
    postal_code: "36312",
    zip_name: "ashford, al",
  },
  {
    postal_code: "12466",
    zip_name: "port ewen, ny",
  },
  {
    postal_code: "52043",
    zip_name: "elkader, ia",
  },
  {
    postal_code: "52328",
    zip_name: "robins, ia",
  },
  {
    postal_code: "67101",
    zip_name: "maize, ks",
  },
  {
    postal_code: "82513",
    zip_name: "dubois, wy",
  },
  {
    postal_code: "36083",
    zip_name: "tuskegee, al",
  },
  {
    postal_code: "30814",
    zip_name: "harlem, ga",
  },
  {
    postal_code: "49451",
    zip_name: "ravenna, mi",
  },
  {
    postal_code: "8829",
    zip_name: "high bridge, nj",
  },
  {
    postal_code: "81525",
    zip_name: "mack, co",
  },
  {
    postal_code: "13654",
    zip_name: "heuvelton, ny",
  },
  {
    postal_code: "17048",
    zip_name: "lykens, pa",
  },
  {
    postal_code: "23504",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "83637",
    zip_name: "lowman, id",
  },
  {
    postal_code: "54463",
    zip_name: "pelican lake, wi",
  },
  {
    postal_code: "8310",
    zip_name: "buena, nj",
  },
  {
    postal_code: "73463",
    zip_name: "wilson, ok",
  },
  {
    postal_code: "7641",
    zip_name: "haworth, nj",
  },
  {
    postal_code: "73098",
    zip_name: "wynnewood, ok",
  },
  {
    postal_code: "98672",
    zip_name: "white salmon, wa",
  },
  {
    postal_code: "52160",
    zip_name: "new albin, ia",
  },
  {
    postal_code: "61943",
    zip_name: "oakland, il",
  },
  {
    postal_code: "58045",
    zip_name: "hillsboro, nd",
  },
  {
    postal_code: "16041",
    zip_name: "karns city, pa",
  },
  {
    postal_code: "35739",
    zip_name: "ardmore, al",
  },
  {
    postal_code: "56647",
    zip_name: "hines, mn",
  },
  {
    postal_code: "15687",
    zip_name: "stahlstown, pa",
  },
  {
    postal_code: "35592",
    zip_name: "vernon, al",
  },
  {
    postal_code: "54638",
    zip_name: "kendall, wi",
  },
  {
    postal_code: "31075",
    zip_name: "rentz, ga",
  },
  {
    postal_code: "38348",
    zip_name: "lavinia, tn",
  },
  {
    postal_code: "21849",
    zip_name: "parsonsburg, md",
  },
  {
    postal_code: "20628",
    zip_name: "dameron, md",
  },
  {
    postal_code: "54151",
    zip_name: "niagara, wi",
  },
  {
    postal_code: "61725",
    zip_name: "carlock, il",
  },
  {
    postal_code: "17980",
    zip_name: "tower city, pa",
  },
  {
    postal_code: "40178",
    zip_name: "westview, ky",
  },
  {
    postal_code: "87535",
    zip_name: "glorieta, nm",
  },
  {
    postal_code: "7661",
    zip_name: "river edge, nj",
  },
  {
    postal_code: "89015",
    zip_name: "henderson, nv",
  },
  {
    postal_code: "64865",
    zip_name: "seneca, mo",
  },
  {
    postal_code: "7457",
    zip_name: "riverdale, nj",
  },
  {
    postal_code: "29130",
    zip_name: "ridgeway, sc",
  },
  {
    postal_code: "23325",
    zip_name: "chesapeake, va",
  },
  {
    postal_code: "78205",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "18032",
    zip_name: "catasauqua, pa",
  },
  {
    postal_code: "18925",
    zip_name: "furlong, pa",
  },
  {
    postal_code: "19956",
    zip_name: "laurel, de",
  },
  {
    postal_code: "32669",
    zip_name: "newberry, fl",
  },
  {
    postal_code: "35761",
    zip_name: "new market, al",
  },
  {
    postal_code: "53086",
    zip_name: "slinger, wi",
  },
  {
    postal_code: "53027",
    zip_name: "hartford, wi",
  },
  {
    postal_code: "53044",
    zip_name: "kohler, wi",
  },
  {
    postal_code: "18330",
    zip_name: "effort, pa",
  },
  {
    postal_code: "39667",
    zip_name: "tylertown, ms",
  },
  {
    postal_code: "32580",
    zip_name: "valparaiso, fl",
  },
  {
    postal_code: "24484",
    zip_name: "warm springs, va",
  },
  {
    postal_code: "73430",
    zip_name: "burneyville, ok",
  },
  {
    postal_code: "99669",
    zip_name: "soldotna, ak",
  },
  {
    postal_code: "36530",
    zip_name: "elberta, al",
  },
  {
    postal_code: "7203",
    zip_name: "roselle, nj",
  },
  {
    postal_code: "28365",
    zip_name: "mount olive, nc",
  },
  {
    postal_code: "24228",
    zip_name: "clintwood, va",
  },
  {
    postal_code: "7721",
    zip_name: "cliffwood, nj",
  },
  {
    postal_code: "51358",
    zip_name: "ruthven, ia",
  },
  {
    postal_code: "31561",
    zip_name: "sea island, ga",
  },
  {
    postal_code: "7849",
    zip_name: "lake hopatcong, nj",
  },
  {
    postal_code: "54460",
    zip_name: "owen, wi",
  },
  {
    postal_code: "49548",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "17866",
    zip_name: "coal township, pa",
  },
  {
    postal_code: "53205",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "61085",
    zip_name: "stockton, il",
  },
  {
    postal_code: "4441",
    zip_name: "greenville, me",
  },
  {
    postal_code: "37397",
    zip_name: "whitwell, tn",
  },
  {
    postal_code: "17304",
    zip_name: "aspers, pa",
  },
  {
    postal_code: "58784",
    zip_name: "stanley, nd",
  },
  {
    postal_code: "7960",
    zip_name: "morristown, nj",
  },
  {
    postal_code: "5494",
    zip_name: "westford, vt",
  },
  {
    postal_code: "64628",
    zip_name: "brookfield, mo",
  },
  {
    postal_code: "66503",
    zip_name: "manhattan, ks",
  },
  {
    postal_code: "88345",
    zip_name: "ruidoso, nm",
  },
  {
    postal_code: "23503",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "7083",
    zip_name: "union, nj",
  },
  {
    postal_code: "58639",
    zip_name: "hettinger, nd",
  },
  {
    postal_code: "10520",
    zip_name: "croton on hudson, ny",
  },
  {
    postal_code: "31031",
    zip_name: "gordon, ga",
  },
  {
    postal_code: "18621",
    zip_name: "hunlock creek, pa",
  },
  {
    postal_code: "73010",
    zip_name: "blanchard, ok",
  },
  {
    postal_code: "7306",
    zip_name: "jersey city, nj",
  },
  {
    postal_code: "81637",
    zip_name: "gypsum, co",
  },
  {
    postal_code: "29824",
    zip_name: "edgefield, sc",
  },
  {
    postal_code: "89002",
    zip_name: "henderson, nv",
  },
  {
    postal_code: "53150",
    zip_name: "muskego, wi",
  },
  {
    postal_code: "4414",
    zip_name: "brownville, me",
  },
  {
    postal_code: "54220",
    zip_name: "manitowoc, wi",
  },
  {
    postal_code: "8030",
    zip_name: "gloucester city, nj",
  },
  {
    postal_code: "73554",
    zip_name: "mangum, ok",
  },
  {
    postal_code: "53114",
    zip_name: "darien, wi",
  },
  {
    postal_code: "49636",
    zip_name: "glen arbor, mi",
  },
  {
    postal_code: "7885",
    zip_name: "wharton, nj",
  },
  {
    postal_code: "89145",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "32433",
    zip_name: "defuniak springs, fl",
  },
  {
    postal_code: "89048",
    zip_name: "pahrump, nv",
  },
  {
    postal_code: "89144",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "15421",
    zip_name: "chalk hill, pa",
  },
  {
    postal_code: "4978",
    zip_name: "smithfield, me",
  },
  {
    postal_code: "48619",
    zip_name: "comins, mi",
  },
  {
    postal_code: "79257",
    zip_name: "silverton, tx",
  },
  {
    postal_code: "68503",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "62545",
    zip_name: "mechanicsburg, il",
  },
  {
    postal_code: "54470",
    zip_name: "rib lake, wi",
  },
  {
    postal_code: "53403",
    zip_name: "racine, wi",
  },
  {
    postal_code: "46409",
    zip_name: "gary, in",
  },
  {
    postal_code: "53219",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "35802",
    zip_name: "huntsville, al",
  },
  {
    postal_code: "53072",
    zip_name: "pewaukee, wi",
  },
  {
    postal_code: "46802",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "59634",
    zip_name: "clancy, mt",
  },
  {
    postal_code: "7724",
    zip_name: "eatontown, nj",
  },
  {
    postal_code: "7024",
    zip_name: "fort lee, nj",
  },
  {
    postal_code: "49638",
    zip_name: "harrietta, mi",
  },
  {
    postal_code: "17502",
    zip_name: "bainbridge, pa",
  },
  {
    postal_code: "1222",
    zip_name: "ashley falls, ma",
  },
  {
    postal_code: "68317",
    zip_name: "bennet, ne",
  },
  {
    postal_code: "49629",
    zip_name: "elk rapids, mi",
  },
  {
    postal_code: "16640",
    zip_name: "flinton, pa",
  },
  {
    postal_code: "29070",
    zip_name: "leesville, sc",
  },
  {
    postal_code: "72501",
    zip_name: "batesville, ar",
  },
  {
    postal_code: "89129",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "19953",
    zip_name: "hartly, de",
  },
  {
    postal_code: "96817",
    zip_name: "honolulu, hi",
  },
  {
    postal_code: "7738",
    zip_name: "lincroft, nj",
  },
  {
    postal_code: "36522",
    zip_name: "citronelle, al",
  },
  {
    postal_code: "88240",
    zip_name: "hobbs, nm",
  },
  {
    postal_code: "76367",
    zip_name: "iowa park, tx",
  },
  {
    postal_code: "10536",
    zip_name: "katonah, ny",
  },
  {
    postal_code: "7456",
    zip_name: "ringwood, nj",
  },
  {
    postal_code: "32547",
    zip_name: "fort walton beach, fl",
  },
  {
    postal_code: "64468",
    zip_name: "maryville, mo",
  },
  {
    postal_code: "32533",
    zip_name: "cantonment, fl",
  },
  {
    postal_code: "89147",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "8730",
    zip_name: "brielle, nj",
  },
  {
    postal_code: "36521",
    zip_name: "chunchula, al",
  },
  {
    postal_code: "25951",
    zip_name: "hinton, wv",
  },
  {
    postal_code: "24739",
    zip_name: "princeton, wv",
  },
  {
    postal_code: "53206",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "37377",
    zip_name: "signal mountain, tn",
  },
  {
    postal_code: "89122",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "73075",
    zip_name: "pauls valley, ok",
  },
  {
    postal_code: "7078",
    zip_name: "short hills, nj",
  },
  {
    postal_code: "35905",
    zip_name: "gadsden, al",
  },
  {
    postal_code: "48336",
    zip_name: "farmington, mi",
  },
  {
    postal_code: "18929",
    zip_name: "jamison, pa",
  },
  {
    postal_code: "53092",
    zip_name: "mequon, wi",
  },
  {
    postal_code: "7874",
    zip_name: "stanhope, nj",
  },
  {
    postal_code: "7039",
    zip_name: "livingston, nj",
  },
  {
    postal_code: "7461",
    zip_name: "sussex, nj",
  },
  {
    postal_code: "29841",
    zip_name: "north augusta, sc",
  },
  {
    postal_code: "7734",
    zip_name: "keansburg, nj",
  },
  {
    postal_code: "7460",
    zip_name: "stockholm, nj",
  },
  {
    postal_code: "32446",
    zip_name: "marianna, fl",
  },
  {
    postal_code: "19610",
    zip_name: "reading, pa",
  },
  {
    postal_code: "8086",
    zip_name: "thorofare, nj",
  },
  {
    postal_code: "46701",
    zip_name: "albion, in",
  },
  {
    postal_code: "84513",
    zip_name: "castle dale, ut",
  },
  {
    postal_code: "75938",
    zip_name: "colmesneil, tx",
  },
  {
    postal_code: "48127",
    zip_name: "dearborn heights, mi",
  },
  {
    postal_code: "68133",
    zip_name: "papillion, ne",
  },
  {
    postal_code: "7826",
    zip_name: "branchville, nj",
  },
  {
    postal_code: "48367",
    zip_name: "leonard, mi",
  },
  {
    postal_code: "4756",
    zip_name: "madawaska, me",
  },
  {
    postal_code: "7756",
    zip_name: "ocean grove, nj",
  },
  {
    postal_code: "10591",
    zip_name: "tarrytown, ny",
  },
  {
    postal_code: "19808",
    zip_name: "wilmington, de",
  },
  {
    postal_code: "7840",
    zip_name: "hackettstown, nj",
  },
  {
    postal_code: "49331",
    zip_name: "lowell, mi",
  },
  {
    postal_code: "53125",
    zip_name: "fontana, wi",
  },
  {
    postal_code: "81623",
    zip_name: "carbondale, co",
  },
  {
    postal_code: "13676",
    zip_name: "potsdam, ny",
  },
  {
    postal_code: "68138",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "72858",
    zip_name: "pottsville, ar",
  },
  {
    postal_code: "49620",
    zip_name: "buckley, mi",
  },
  {
    postal_code: "53585",
    zip_name: "sharon, wi",
  },
  {
    postal_code: "54111",
    zip_name: "cecil, wi",
  },
  {
    postal_code: "53120",
    zip_name: "east troy, wi",
  },
  {
    postal_code: "19464",
    zip_name: "pottstown, pa",
  },
  {
    postal_code: "30568",
    zip_name: "rabun gap, ga",
  },
  {
    postal_code: "24326",
    zip_name: "elk creek, va",
  },
  {
    postal_code: "83328",
    zip_name: "filer, id",
  },
  {
    postal_code: "40740",
    zip_name: "lily, ky",
  },
  {
    postal_code: "97624",
    zip_name: "chiloquin, or",
  },
  {
    postal_code: "23128",
    zip_name: "north, va",
  },
  {
    postal_code: "71051",
    zip_name: "elm grove, la",
  },
  {
    postal_code: "46561",
    zip_name: "osceola, in",
  },
  {
    postal_code: "73007",
    zip_name: "arcadia, ok",
  },
  {
    postal_code: "99402",
    zip_name: "asotin, wa",
  },
  {
    postal_code: "61434",
    zip_name: "galva, il",
  },
  {
    postal_code: "46746",
    zip_name: "howe, in",
  },
  {
    postal_code: "48427",
    zip_name: "deckerville, mi",
  },
  {
    postal_code: "85616",
    zip_name: "huachuca city, az",
  },
  {
    postal_code: "12743",
    zip_name: "highland lake, ny",
  },
  {
    postal_code: "83226",
    zip_name: "challis, id",
  },
  {
    postal_code: "38251",
    zip_name: "puryear, tn",
  },
  {
    postal_code: "41007",
    zip_name: "california, ky",
  },
  {
    postal_code: "32506",
    zip_name: "pensacola, fl",
  },
  {
    postal_code: "7032",
    zip_name: "kearny, nj",
  },
  {
    postal_code: "81632",
    zip_name: "edwards, co",
  },
  {
    postal_code: "49633",
    zip_name: "fife lake, mi",
  },
  {
    postal_code: "49727",
    zip_name: "east jordan, mi",
  },
  {
    postal_code: "17922",
    zip_name: "auburn, pa",
  },
  {
    postal_code: "36441",
    zip_name: "flomaton, al",
  },
  {
    postal_code: "10590",
    zip_name: "south salem, ny",
  },
  {
    postal_code: "53216",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "99645",
    zip_name: "palmer, ak",
  },
  {
    postal_code: "46156",
    zip_name: "milroy, in",
  },
  {
    postal_code: "5734",
    zip_name: "bridport, vt",
  },
  {
    postal_code: "93932",
    zip_name: "lockwood, ca",
  },
  {
    postal_code: "3854",
    zip_name: "new castle, nh",
  },
  {
    postal_code: "5457",
    zip_name: "franklin, vt",
  },
  {
    postal_code: "56644",
    zip_name: "gonvick, mn",
  },
  {
    postal_code: "14510",
    zip_name: "mount morris, ny",
  },
  {
    postal_code: "40313",
    zip_name: "clearfield, ky",
  },
  {
    postal_code: "85322",
    zip_name: "arlington, az",
  },
  {
    postal_code: "67037",
    zip_name: "derby, ks",
  },
  {
    postal_code: "53212",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "71753",
    zip_name: "magnolia, ar",
  },
  {
    postal_code: "54406",
    zip_name: "amherst, wi",
  },
  {
    postal_code: "1098",
    zip_name: "worthington, ma",
  },
  {
    postal_code: "5839",
    zip_name: "glover, vt",
  },
  {
    postal_code: "71671",
    zip_name: "warren, ar",
  },
  {
    postal_code: "65555",
    zip_name: "raymondville, mo",
  },
  {
    postal_code: "23435",
    zip_name: "suffolk, va",
  },
  {
    postal_code: "39819",
    zip_name: "bainbridge, ga",
  },
  {
    postal_code: "46804",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "54455",
    zip_name: "mosinee, wi",
  },
  {
    postal_code: "8535",
    zip_name: "millstone township, nj",
  },
  {
    postal_code: "54449",
    zip_name: "marshfield, wi",
  },
  {
    postal_code: "83318",
    zip_name: "burley, id",
  },
  {
    postal_code: "98262",
    zip_name: "lummi island, wa",
  },
  {
    postal_code: "46613",
    zip_name: "south bend, in",
  },
  {
    postal_code: "53204",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "14063",
    zip_name: "fredonia, ny",
  },
  {
    postal_code: "47243",
    zip_name: "hanover, in",
  },
  {
    postal_code: "46938",
    zip_name: "jonesboro, in",
  },
  {
    postal_code: "71366",
    zip_name: "saint joseph, la",
  },
  {
    postal_code: "7442",
    zip_name: "pompton lakes, nj",
  },
  {
    postal_code: "57108",
    zip_name: "sioux falls, sd",
  },
  {
    postal_code: "83330",
    zip_name: "gooding, id",
  },
  {
    postal_code: "71822",
    zip_name: "ashdown, ar",
  },
  {
    postal_code: "74872",
    zip_name: "stratford, ok",
  },
  {
    postal_code: "83836",
    zip_name: "hope, id",
  },
  {
    postal_code: "79843",
    zip_name: "marfa, tx",
  },
  {
    postal_code: "96056",
    zip_name: "mcarthur, ca",
  },
  {
    postal_code: "49415",
    zip_name: "fruitport, mi",
  },
  {
    postal_code: "73111",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "54433",
    zip_name: "gilman, wi",
  },
  {
    postal_code: "73069",
    zip_name: "norman, ok",
  },
  {
    postal_code: "92061",
    zip_name: "pauma valley, ca",
  },
  {
    postal_code: "49965",
    zip_name: "toivola, mi",
  },
  {
    postal_code: "98645",
    zip_name: "silverlake, wa",
  },
  {
    postal_code: "53069",
    zip_name: "okauchee, wi",
  },
  {
    postal_code: "7064",
    zip_name: "port reading, nj",
  },
  {
    postal_code: "7110",
    zip_name: "nutley, nj",
  },
  {
    postal_code: "83316",
    zip_name: "buhl, id",
  },
  {
    postal_code: "46737",
    zip_name: "fremont, in",
  },
  {
    postal_code: "53154",
    zip_name: "oak creek, wi",
  },
  {
    postal_code: "7060",
    zip_name: "plainfield, nj",
  },
  {
    postal_code: "23320",
    zip_name: "chesapeake, va",
  },
  {
    postal_code: "24540",
    zip_name: "danville, va",
  },
  {
    postal_code: "7834",
    zip_name: "denville, nj",
  },
  {
    postal_code: "7065",
    zip_name: "rahway, nj",
  },
  {
    postal_code: "49617",
    zip_name: "beulah, mi",
  },
  {
    postal_code: "39307",
    zip_name: "meridian, ms",
  },
  {
    postal_code: "97488",
    zip_name: "vida, or",
  },
  {
    postal_code: "99573",
    zip_name: "copper center, ak",
  },
  {
    postal_code: "48201",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "97110",
    zip_name: "cannon beach, or",
  },
  {
    postal_code: "8343",
    zip_name: "monroeville, nj",
  },
  {
    postal_code: "17959",
    zip_name: "new philadelphia, pa",
  },
  {
    postal_code: "35968",
    zip_name: "fort payne, al",
  },
  {
    postal_code: "63638",
    zip_name: "ellington, mo",
  },
  {
    postal_code: "49434",
    zip_name: "macatawa, mi",
  },
  {
    postal_code: "43446",
    zip_name: "middle bass, oh",
  },
  {
    postal_code: "94060",
    zip_name: "pescadero, ca",
  },
  {
    postal_code: "48032",
    zip_name: "jeddo, mi",
  },
  {
    postal_code: "30450",
    zip_name: "portal, ga",
  },
  {
    postal_code: "39328",
    zip_name: "de kalb, ms",
  },
  {
    postal_code: "23303",
    zip_name: "atlantic, va",
  },
  {
    postal_code: "18354",
    zip_name: "sciota, pa",
  },
  {
    postal_code: "99737",
    zip_name: "delta junction, ak",
  },
  {
    postal_code: "44822",
    zip_name: "butler, oh",
  },
  {
    postal_code: "4253",
    zip_name: "livermore, me",
  },
  {
    postal_code: "71921",
    zip_name: "amity, ar",
  },
  {
    postal_code: "57445",
    zip_name: "groton, sd",
  },
  {
    postal_code: "3218",
    zip_name: "barnstead, nh",
  },
  {
    postal_code: "63532",
    zip_name: "bevier, mo",
  },
  {
    postal_code: "7801",
    zip_name: "dover, nj",
  },
  {
    postal_code: "24579",
    zip_name: "natural bridge station, va",
  },
  {
    postal_code: "21036",
    zip_name: "dayton, md",
  },
  {
    postal_code: "35564",
    zip_name: "hackleburg, al",
  },
  {
    postal_code: "27315",
    zip_name: "providence, nc",
  },
  {
    postal_code: "24292",
    zip_name: "whitetop, va",
  },
  {
    postal_code: "82411",
    zip_name: "burlington, wy",
  },
  {
    postal_code: "41749",
    zip_name: "hyden, ky",
  },
  {
    postal_code: "13681",
    zip_name: "richville, ny",
  },
  {
    postal_code: "27979",
    zip_name: "sunbury, nc",
  },
  {
    postal_code: "38577",
    zip_name: "pall mall, tn",
  },
  {
    postal_code: "73529",
    zip_name: "comanche, ok",
  },
  {
    postal_code: "85601",
    zip_name: "arivaca, az",
  },
  {
    postal_code: "8738",
    zip_name: "mantoloking, nj",
  },
  {
    postal_code: "36527",
    zip_name: "daphne, al",
  },
  {
    postal_code: "57029",
    zip_name: "freeman, sd",
  },
  {
    postal_code: "7605",
    zip_name: "leonia, nj",
  },
  {
    postal_code: "8041",
    zip_name: "jobstown, nj",
  },
  {
    postal_code: "82729",
    zip_name: "sundance, wy",
  },
  {
    postal_code: "10989",
    zip_name: "valley cottage, ny",
  },
  {
    postal_code: "1012",
    zip_name: "chesterfield, ma",
  },
  {
    postal_code: "77975",
    zip_name: "moulton, tx",
  },
  {
    postal_code: "4765",
    zip_name: "patten, me",
  },
  {
    postal_code: "18629",
    zip_name: "mehoopany, pa",
  },
  {
    postal_code: "95914",
    zip_name: "bangor, ca",
  },
  {
    postal_code: "14733",
    zip_name: "falconer, ny",
  },
  {
    postal_code: "50482",
    zip_name: "ventura, ia",
  },
  {
    postal_code: "49615",
    zip_name: "bellaire, mi",
  },
  {
    postal_code: "81023",
    zip_name: "beulah, co",
  },
  {
    postal_code: "4643",
    zip_name: "harrington, me",
  },
  {
    postal_code: "78879",
    zip_name: "rio frio, tx",
  },
  {
    postal_code: "96027",
    zip_name: "etna, ca",
  },
  {
    postal_code: "62255",
    zip_name: "lenzburg, il",
  },
  {
    postal_code: "20658",
    zip_name: "marbury, md",
  },
  {
    postal_code: "12451",
    zip_name: "leeds, ny",
  },
  {
    postal_code: "16648",
    zip_name: "hollidaysburg, pa",
  },
  {
    postal_code: "38259",
    zip_name: "trimble, tn",
  },
  {
    postal_code: "64077",
    zip_name: "orrick, mo",
  },
  {
    postal_code: "47469",
    zip_name: "west baden springs, in",
  },
  {
    postal_code: "13337",
    zip_name: "fly creek, ny",
  },
  {
    postal_code: "46928",
    zip_name: "fairmount, in",
  },
  {
    postal_code: "98613",
    zip_name: "centerville, wa",
  },
  {
    postal_code: "18469",
    zip_name: "tyler hill, pa",
  },
  {
    postal_code: "46565",
    zip_name: "shipshewana, in",
  },
  {
    postal_code: "40402",
    zip_name: "annville, ky",
  },
  {
    postal_code: "24801",
    zip_name: "welch, wv",
  },
  {
    postal_code: "28909",
    zip_name: "warne, nc",
  },
  {
    postal_code: "71268",
    zip_name: "quitman, la",
  },
  {
    postal_code: "3875",
    zip_name: "silver lake, nh",
  },
  {
    postal_code: "58831",
    zip_name: "alexander, nd",
  },
  {
    postal_code: "5907",
    zip_name: "norton, vt",
  },
  {
    postal_code: "71439",
    zip_name: "hornbeck, la",
  },
  {
    postal_code: "27209",
    zip_name: "biscoe, nc",
  },
  {
    postal_code: "7307",
    zip_name: "jersey city, nj",
  },
  {
    postal_code: "98660",
    zip_name: "vancouver, wa",
  },
  {
    postal_code: "58504",
    zip_name: "bismarck, nd",
  },
  {
    postal_code: "72112",
    zip_name: "newport, ar",
  },
  {
    postal_code: "7642",
    zip_name: "hillsdale, nj",
  },
  {
    postal_code: "97037",
    zip_name: "maupin, or",
  },
  {
    postal_code: "59931",
    zip_name: "rollins, mt",
  },
  {
    postal_code: "3284",
    zip_name: "springfield, nh",
  },
  {
    postal_code: "81612",
    zip_name: "aspen, co",
  },
  {
    postal_code: "68123",
    zip_name: "bellevue, ne",
  },
  {
    postal_code: "7631",
    zip_name: "englewood, nj",
  },
  {
    postal_code: "67219",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "54234",
    zip_name: "sister bay, wi",
  },
  {
    postal_code: "14512",
    zip_name: "naples, ny",
  },
  {
    postal_code: "48223",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "53226",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "35630",
    zip_name: "florence, al",
  },
  {
    postal_code: "46628",
    zip_name: "south bend, in",
  },
  {
    postal_code: "36507",
    zip_name: "bay minette, al",
  },
  {
    postal_code: "54915",
    zip_name: "appleton, wi",
  },
  {
    postal_code: "8752",
    zip_name: "seaside park, nj",
  },
  {
    postal_code: "24176",
    zip_name: "union hall, va",
  },
  {
    postal_code: "73020",
    zip_name: "choctaw, ok",
  },
  {
    postal_code: "76366",
    zip_name: "holliday, tx",
  },
  {
    postal_code: "49256",
    zip_name: "morenci, mi",
  },
  {
    postal_code: "49080",
    zip_name: "plainwell, mi",
  },
  {
    postal_code: "95450",
    zip_name: "jenner, ca",
  },
  {
    postal_code: "67207",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "10925",
    zip_name: "greenwood lake, ny",
  },
  {
    postal_code: "14202",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "46819",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "73117",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "15474",
    zip_name: "point marion, pa",
  },
  {
    postal_code: "41301",
    zip_name: "campton, ky",
  },
  {
    postal_code: "55606",
    zip_name: "hovland, mn",
  },
  {
    postal_code: "31807",
    zip_name: "ellerslie, ga",
  },
  {
    postal_code: "62703",
    zip_name: "springfield, il",
  },
  {
    postal_code: "49768",
    zip_name: "paradise, mi",
  },
  {
    postal_code: "7652",
    zip_name: "paramus, nj",
  },
  {
    postal_code: "14586",
    zip_name: "west henrietta, ny",
  },
  {
    postal_code: "70706",
    zip_name: "denham springs, la",
  },
  {
    postal_code: "68046",
    zip_name: "papillion, ne",
  },
  {
    postal_code: "7410",
    zip_name: "fair lawn, nj",
  },
  {
    postal_code: "48226",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "25962",
    zip_name: "rainelle, wv",
  },
  {
    postal_code: "62531",
    zip_name: "edinburg, il",
  },
  {
    postal_code: "8867",
    zip_name: "pittstown, nj",
  },
  {
    postal_code: "34139",
    zip_name: "everglades city, fl",
  },
  {
    postal_code: "23663",
    zip_name: "hampton, va",
  },
  {
    postal_code: "35462",
    zip_name: "eutaw, al",
  },
  {
    postal_code: "83213",
    zip_name: "arco, id",
  },
  {
    postal_code: "21757",
    zip_name: "keymar, md",
  },
  {
    postal_code: "96731",
    zip_name: "kahuku, hi",
  },
  {
    postal_code: "32505",
    zip_name: "pensacola, fl",
  },
  {
    postal_code: "24970",
    zip_name: "ronceverte, wv",
  },
  {
    postal_code: "81645",
    zip_name: "minturn, co",
  },
  {
    postal_code: "49323",
    zip_name: "dorr, mi",
  },
  {
    postal_code: "13144",
    zip_name: "richland, ny",
  },
  {
    postal_code: "48320",
    zip_name: "keego harbor, mi",
  },
  {
    postal_code: "97419",
    zip_name: "cheshire, or",
  },
  {
    postal_code: "18344",
    zip_name: "mount pocono, pa",
  },
  {
    postal_code: "1611",
    zip_name: "cherry valley, ma",
  },
  {
    postal_code: "21856",
    zip_name: "quantico, md",
  },
  {
    postal_code: "5483",
    zip_name: "sheldon, vt",
  },
  {
    postal_code: "13608",
    zip_name: "antwerp, ny",
  },
  {
    postal_code: "10548",
    zip_name: "montrose, ny",
  },
  {
    postal_code: "16637",
    zip_name: "east freedom, pa",
  },
  {
    postal_code: "35810",
    zip_name: "huntsville, al",
  },
  {
    postal_code: "73127",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "19507",
    zip_name: "bethel, pa",
  },
  {
    postal_code: "56253",
    zip_name: "lake lillian, mn",
  },
  {
    postal_code: "50602",
    zip_name: "allison, ia",
  },
  {
    postal_code: "37403",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "60922",
    zip_name: "chebanse, il",
  },
  {
    postal_code: "54138",
    zip_name: "lakewood, wi",
  },
  {
    postal_code: "12548",
    zip_name: "modena, ny",
  },
  {
    postal_code: "88046",
    zip_name: "mesilla, nm",
  },
  {
    postal_code: "23308",
    zip_name: "bloxom, va",
  },
  {
    postal_code: "17557",
    zip_name: "new holland, pa",
  },
  {
    postal_code: "96744",
    zip_name: "kaneohe, hi",
  },
  {
    postal_code: "46806",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "15459",
    zip_name: "markleysburg, pa",
  },
  {
    postal_code: "32321",
    zip_name: "bristol, fl",
  },
  {
    postal_code: "8241",
    zip_name: "port republic, nj",
  },
  {
    postal_code: "20621",
    zip_name: "chaptico, md",
  },
  {
    postal_code: "84751",
    zip_name: "milford, ut",
  },
  {
    postal_code: "57325",
    zip_name: "chamberlain, sd",
  },
  {
    postal_code: "13659",
    zip_name: "lorraine, ny",
  },
  {
    postal_code: "62895",
    zip_name: "wayne city, il",
  },
  {
    postal_code: "68973",
    zip_name: "roseland, ne",
  },
  {
    postal_code: "93432",
    zip_name: "creston, ca",
  },
  {
    postal_code: "74561",
    zip_name: "quinton, ok",
  },
  {
    postal_code: "55974",
    zip_name: "spring grove, mn",
  },
  {
    postal_code: "14138",
    zip_name: "south dayton, ny",
  },
  {
    postal_code: "96108",
    zip_name: "davis creek, ca",
  },
  {
    postal_code: "27860",
    zip_name: "pantego, nc",
  },
  {
    postal_code: "28386",
    zip_name: "shannon, nc",
  },
  {
    postal_code: "84756",
    zip_name: "newcastle, ut",
  },
  {
    postal_code: "50163",
    zip_name: "melcher dallas, ia",
  },
  {
    postal_code: "71433",
    zip_name: "glenmora, la",
  },
  {
    postal_code: "26679",
    zip_name: "mount nebo, wv",
  },
  {
    postal_code: "84035",
    zip_name: "jensen, ut",
  },
  {
    postal_code: "74956",
    zip_name: "shady point, ok",
  },
  {
    postal_code: "40316",
    zip_name: "denniston, ky",
  },
  {
    postal_code: "45711",
    zip_name: "amesville, oh",
  },
  {
    postal_code: "61776",
    zip_name: "towanda, il",
  },
  {
    postal_code: "15961",
    zip_name: "vintondale, pa",
  },
  {
    postal_code: "55716",
    zip_name: "calumet, mn",
  },
  {
    postal_code: "39451",
    zip_name: "leakesville, ms",
  },
  {
    postal_code: "13776",
    zip_name: "gilbertsville, ny",
  },
  {
    postal_code: "96728",
    zip_name: "honomu, hi",
  },
  {
    postal_code: "76837",
    zip_name: "eden, tx",
  },
  {
    postal_code: "24243",
    zip_name: "dryden, va",
  },
  {
    postal_code: "67669",
    zip_name: "stockton, ks",
  },
  {
    postal_code: "73102",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "65351",
    zip_name: "sweet springs, mo",
  },
  {
    postal_code: "83323",
    zip_name: "declo, id",
  },
  {
    postal_code: "8324",
    zip_name: "heislerville, nj",
  },
  {
    postal_code: "88350",
    zip_name: "timberon, nm",
  },
  {
    postal_code: "17930",
    zip_name: "cumbola, pa",
  },
  {
    postal_code: "93461",
    zip_name: "shandon, ca",
  },
  {
    postal_code: "16131",
    zip_name: "hartstown, pa",
  },
  {
    postal_code: "95936",
    zip_name: "downieville, ca",
  },
  {
    postal_code: "5737",
    zip_name: "chittenden, vt",
  },
  {
    postal_code: "35579",
    zip_name: "oakman, al",
  },
  {
    postal_code: "97868",
    zip_name: "pilot rock, or",
  },
  {
    postal_code: "54642",
    zip_name: "melrose, wi",
  },
  {
    postal_code: "68431",
    zip_name: "rulo, ne",
  },
  {
    postal_code: "63336",
    zip_name: "clarksville, mo",
  },
  {
    postal_code: "1009",
    zip_name: "bondsville, ma",
  },
  {
    postal_code: "24870",
    zip_name: "oceana, wv",
  },
  {
    postal_code: "13322",
    zip_name: "clayville, ny",
  },
  {
    postal_code: "87579",
    zip_name: "vadito, nm",
  },
  {
    postal_code: "14859",
    zip_name: "lockwood, ny",
  },
  {
    postal_code: "12783",
    zip_name: "swan lake, ny",
  },
  {
    postal_code: "15344",
    zip_name: "jefferson, pa",
  },
  {
    postal_code: "63862",
    zip_name: "lilbourn, mo",
  },
  {
    postal_code: "51004",
    zip_name: "anthon, ia",
  },
  {
    postal_code: "98813",
    zip_name: "bridgeport, wa",
  },
  {
    postal_code: "15923",
    zip_name: "bolivar, pa",
  },
  {
    postal_code: "58718",
    zip_name: "berthold, nd",
  },
  {
    postal_code: "50026",
    zip_name: "bagley, ia",
  },
  {
    postal_code: "4576",
    zip_name: "southport, me",
  },
  {
    postal_code: "56309",
    zip_name: "ashby, mn",
  },
  {
    postal_code: "28669",
    zip_name: "roaring river, nc",
  },
  {
    postal_code: "65032",
    zip_name: "eugene, mo",
  },
  {
    postal_code: "74941",
    zip_name: "keota, ok",
  },
  {
    postal_code: "27314",
    zip_name: "prospect hill, nc",
  },
  {
    postal_code: "52747",
    zip_name: "durant, ia",
  },
  {
    postal_code: "89008",
    zip_name: "caliente, nv",
  },
  {
    postal_code: "78330",
    zip_name: "agua dulce, tx",
  },
  {
    postal_code: "12092",
    zip_name: "howes cave, ny",
  },
  {
    postal_code: "69127",
    zip_name: "brule, ne",
  },
  {
    postal_code: "58740",
    zip_name: "glenburn, nd",
  },
  {
    postal_code: "99134",
    zip_name: "harrington, wa",
  },
  {
    postal_code: "40486",
    zip_name: "tyner, ky",
  },
  {
    postal_code: "42081",
    zip_name: "smithland, ky",
  },
  {
    postal_code: "1542",
    zip_name: "rochdale, ma",
  },
  {
    postal_code: "97345",
    zip_name: "foster, or",
  },
  {
    postal_code: "23306",
    zip_name: "belle haven, va",
  },
  {
    postal_code: "44455",
    zip_name: "rogers, oh",
  },
  {
    postal_code: "3605",
    zip_name: "lempster, nh",
  },
  {
    postal_code: "43342",
    zip_name: "prospect, oh",
  },
  {
    postal_code: "65710",
    zip_name: "morrisville, mo",
  },
  {
    postal_code: "83428",
    zip_name: "irwin, id",
  },
  {
    postal_code: "72669",
    zip_name: "pindall, ar",
  },
  {
    postal_code: "62513",
    zip_name: "blue mound, il",
  },
  {
    postal_code: "90021",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "15438",
    zip_name: "fayette city, pa",
  },
  {
    postal_code: "83324",
    zip_name: "dietrich, id",
  },
  {
    postal_code: "73549",
    zip_name: "headrick, ok",
  },
  {
    postal_code: "16930",
    zip_name: "liberty, pa",
  },
  {
    postal_code: "50007",
    zip_name: "alleman, ia",
  },
  {
    postal_code: "46290",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "54670",
    zip_name: "wilton, wi",
  },
  {
    postal_code: "57761",
    zip_name: "new underwood, sd",
  },
  {
    postal_code: "82835",
    zip_name: "clearmont, wy",
  },
  {
    postal_code: "5650",
    zip_name: "east calais, vt",
  },
  {
    postal_code: "4237",
    zip_name: "hanover, me",
  },
  {
    postal_code: "70528",
    zip_name: "delcambre, la",
  },
  {
    postal_code: "61089",
    zip_name: "winslow, il",
  },
  {
    postal_code: "12406",
    zip_name: "arkville, ny",
  },
  {
    postal_code: "50590",
    zip_name: "swea city, ia",
  },
  {
    postal_code: "17578",
    zip_name: "stevens, pa",
  },
  {
    postal_code: "21913",
    zip_name: "cecilton, md",
  },
  {
    postal_code: "66619",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "30520",
    zip_name: "canon, ga",
  },
  {
    postal_code: "17731",
    zip_name: "eagles mere, pa",
  },
  {
    postal_code: "48721",
    zip_name: "black river, mi",
  },
  {
    postal_code: "71229",
    zip_name: "collinston, la",
  },
  {
    postal_code: "98582",
    zip_name: "salkum, wa",
  },
  {
    postal_code: "78543",
    zip_name: "elsa, tx",
  },
  {
    postal_code: "48322",
    zip_name: "west bloomfield, mi",
  },
  {
    postal_code: "51334",
    zip_name: "estherville, ia",
  },
  {
    postal_code: "7931",
    zip_name: "far hills, nj",
  },
  {
    postal_code: "89121",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "49006",
    zip_name: "kalamazoo, mi",
  },
  {
    postal_code: "7104",
    zip_name: "newark, nj",
  },
  {
    postal_code: "45612",
    zip_name: "bainbridge, oh",
  },
  {
    postal_code: "71047",
    zip_name: "keithville, la",
  },
  {
    postal_code: "89141",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "7419",
    zip_name: "hamburg, nj",
  },
  {
    postal_code: "7014",
    zip_name: "clifton, nj",
  },
  {
    postal_code: "7090",
    zip_name: "westfield, nj",
  },
  {
    postal_code: "67337",
    zip_name: "coffeyville, ks",
  },
  {
    postal_code: "23076",
    zip_name: "hudgins, va",
  },
  {
    postal_code: "66075",
    zip_name: "pleasanton, ks",
  },
  {
    postal_code: "56455",
    zip_name: "ironton, mn",
  },
  {
    postal_code: "13786",
    zip_name: "harpersfield, ny",
  },
  {
    postal_code: "22652",
    zip_name: "fort valley, va",
  },
  {
    postal_code: "7439",
    zip_name: "ogdensburg, nj",
  },
  {
    postal_code: "72833",
    zip_name: "danville, ar",
  },
  {
    postal_code: "53208",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "30251",
    zip_name: "luthersville, ga",
  },
  {
    postal_code: "20623",
    zip_name: "cheltenham, md",
  },
  {
    postal_code: "10963",
    zip_name: "otisville, ny",
  },
  {
    postal_code: "49835",
    zip_name: "garden, mi",
  },
  {
    postal_code: "47586",
    zip_name: "tell city, in",
  },
  {
    postal_code: "29169",
    zip_name: "west columbia, sc",
  },
  {
    postal_code: "48034",
    zip_name: "southfield, mi",
  },
  {
    postal_code: "73173",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "58059",
    zip_name: "mapleton, nd",
  },
  {
    postal_code: "99502",
    zip_name: "anchorage, ak",
  },
  {
    postal_code: "72442",
    zip_name: "manila, ar",
  },
  {
    postal_code: "61041",
    zip_name: "hanover, il",
  },
  {
    postal_code: "23603",
    zip_name: "newport news, va",
  },
  {
    postal_code: "75975",
    zip_name: "timpson, tx",
  },
  {
    postal_code: "99925",
    zip_name: "klawock, ak",
  },
  {
    postal_code: "77837",
    zip_name: "calvert, tx",
  },
  {
    postal_code: "66402",
    zip_name: "auburn, ks",
  },
  {
    postal_code: "36049",
    zip_name: "luverne, al",
  },
  {
    postal_code: "99362",
    zip_name: "walla walla, wa",
  },
  {
    postal_code: "42066",
    zip_name: "mayfield, ky",
  },
  {
    postal_code: "49683",
    zip_name: "thompsonville, mi",
  },
  {
    postal_code: "19054",
    zip_name: "levittown, pa",
  },
  {
    postal_code: "48748",
    zip_name: "national city, mi",
  },
  {
    postal_code: "7760",
    zip_name: "rumson, nj",
  },
  {
    postal_code: "2493",
    zip_name: "weston, ma",
  },
  {
    postal_code: "19604",
    zip_name: "reading, pa",
  },
  {
    postal_code: "7924",
    zip_name: "bernardsville, nj",
  },
  {
    postal_code: "58103",
    zip_name: "fargo, nd",
  },
  {
    postal_code: "54165",
    zip_name: "seymour, wi",
  },
  {
    postal_code: "19380",
    zip_name: "west chester, pa",
  },
  {
    postal_code: "39191",
    zip_name: "wesson, ms",
  },
  {
    postal_code: "23454",
    zip_name: "virginia beach, va",
  },
  {
    postal_code: "54101",
    zip_name: "abrams, wi",
  },
  {
    postal_code: "28702",
    zip_name: "almond, nc",
  },
  {
    postal_code: "43152",
    zip_name: "south bloomingville, oh",
  },
  {
    postal_code: "29127",
    zip_name: "prosperity, sc",
  },
  {
    postal_code: "73433",
    zip_name: "elmore city, ok",
  },
  {
    postal_code: "48359",
    zip_name: "lake orion, mi",
  },
  {
    postal_code: "49839",
    zip_name: "grand marais, mi",
  },
  {
    postal_code: "8214",
    zip_name: "dennisville, nj",
  },
  {
    postal_code: "58401",
    zip_name: "jamestown, nd",
  },
  {
    postal_code: "7480",
    zip_name: "west milford, nj",
  },
  {
    postal_code: "83544",
    zip_name: "orofino, id",
  },
  {
    postal_code: "7871",
    zip_name: "sparta, nj",
  },
  {
    postal_code: "64683",
    zip_name: "trenton, mo",
  },
  {
    postal_code: "8090",
    zip_name: "wenonah, nj",
  },
  {
    postal_code: "7040",
    zip_name: "maplewood, nj",
  },
  {
    postal_code: "72542",
    zip_name: "hardy, ar",
  },
  {
    postal_code: "35614",
    zip_name: "athens, al",
  },
  {
    postal_code: "32641",
    zip_name: "gainesville, fl",
  },
  {
    postal_code: "72908",
    zip_name: "fort smith, ar",
  },
  {
    postal_code: "39705",
    zip_name: "columbus, ms",
  },
  {
    postal_code: "7070",
    zip_name: "rutherford, nj",
  },
  {
    postal_code: "53191",
    zip_name: "williams bay, wi",
  },
  {
    postal_code: "46845",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "7054",
    zip_name: "parsippany, nj",
  },
  {
    postal_code: "1756",
    zip_name: "mendon, ma",
  },
  {
    postal_code: "7071",
    zip_name: "lyndhurst, nj",
  },
  {
    postal_code: "8826",
    zip_name: "glen gardner, nj",
  },
  {
    postal_code: "7106",
    zip_name: "newark, nj",
  },
  {
    postal_code: "52327",
    zip_name: "riverside, ia",
  },
  {
    postal_code: "36580",
    zip_name: "summerdale, al",
  },
  {
    postal_code: "99518",
    zip_name: "anchorage, ak",
  },
  {
    postal_code: "49696",
    zip_name: "traverse city, mi",
  },
  {
    postal_code: "46582",
    zip_name: "warsaw, in",
  },
  {
    postal_code: "7018",
    zip_name: "east orange, nj",
  },
  {
    postal_code: "8809",
    zip_name: "clinton, nj",
  },
  {
    postal_code: "89139",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "72417",
    zip_name: "brookland, ar",
  },
  {
    postal_code: "42058",
    zip_name: "ledbetter, ky",
  },
  {
    postal_code: "70634",
    zip_name: "deridder, la",
  },
  {
    postal_code: "49507",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "72901",
    zip_name: "fort smith, ar",
  },
  {
    postal_code: "74079",
    zip_name: "stroud, ok",
  },
  {
    postal_code: "19126",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "89169",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "89014",
    zip_name: "henderson, nv",
  },
  {
    postal_code: "7010",
    zip_name: "cliffside park, nj",
  },
  {
    postal_code: "89044",
    zip_name: "henderson, nv",
  },
  {
    postal_code: "53126",
    zip_name: "franksville, wi",
  },
  {
    postal_code: "14201",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "47944",
    zip_name: "fowler, in",
  },
  {
    postal_code: "68134",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "89183",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "36426",
    zip_name: "brewton, al",
  },
  {
    postal_code: "76856",
    zip_name: "mason, tx",
  },
  {
    postal_code: "92363",
    zip_name: "needles, ca",
  },
  {
    postal_code: "99403",
    zip_name: "clarkston, wa",
  },
  {
    postal_code: "53095",
    zip_name: "west bend, wi",
  },
  {
    postal_code: "31201",
    zip_name: "macon, ga",
  },
  {
    postal_code: "32507",
    zip_name: "pensacola, fl",
  },
  {
    postal_code: "97459",
    zip_name: "north bend, or",
  },
  {
    postal_code: "54110",
    zip_name: "brillion, wi",
  },
  {
    postal_code: "89104",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "48001",
    zip_name: "algonac, mi",
  },
  {
    postal_code: "96819",
    zip_name: "honolulu, hi",
  },
  {
    postal_code: "7035",
    zip_name: "lincoln park, nj",
  },
  {
    postal_code: "38582",
    zip_name: "silver point, tn",
  },
  {
    postal_code: "78538",
    zip_name: "edcouch, tx",
  },
  {
    postal_code: "71101",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "66783",
    zip_name: "yates center, ks",
  },
  {
    postal_code: "12726",
    zip_name: "cochecton, ny",
  },
  {
    postal_code: "78076",
    zip_name: "zapata, tx",
  },
  {
    postal_code: "18832",
    zip_name: "monroeton, pa",
  },
  {
    postal_code: "13626",
    zip_name: "copenhagen, ny",
  },
  {
    postal_code: "54403",
    zip_name: "wausau, wi",
  },
  {
    postal_code: "36460",
    zip_name: "monroeville, al",
  },
  {
    postal_code: "5161",
    zip_name: "weston, vt",
  },
  {
    postal_code: "35961",
    zip_name: "collinsville, al",
  },
  {
    postal_code: "12457",
    zip_name: "mount tremper, ny",
  },
  {
    postal_code: "37409",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "96088",
    zip_name: "shingletown, ca",
  },
  {
    postal_code: "89138",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "7843",
    zip_name: "hopatcong, nj",
  },
  {
    postal_code: "48457",
    zip_name: "montrose, mi",
  },
  {
    postal_code: "7030",
    zip_name: "hoboken, nj",
  },
  {
    postal_code: "97499",
    zip_name: "yoncalla, or",
  },
  {
    postal_code: "59720",
    zip_name: "cameron, mt",
  },
  {
    postal_code: "70749",
    zip_name: "jarreau, la",
  },
  {
    postal_code: "12965",
    zip_name: "nicholville, ny",
  },
  {
    postal_code: "61490",
    zip_name: "woodhull, il",
  },
  {
    postal_code: "15437",
    zip_name: "farmington, pa",
  },
  {
    postal_code: "89142",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "71044",
    zip_name: "ida, la",
  },
  {
    postal_code: "28372",
    zip_name: "pembroke, nc",
  },
  {
    postal_code: "44843",
    zip_name: "lucas, oh",
  },
  {
    postal_code: "97882",
    zip_name: "umatilla, or",
  },
  {
    postal_code: "59086",
    zip_name: "wilsall, mt",
  },
  {
    postal_code: "73150",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "65588",
    zip_name: "winona, mo",
  },
  {
    postal_code: "20620",
    zip_name: "callaway, md",
  },
  {
    postal_code: "24139",
    zip_name: "pittsville, va",
  },
  {
    postal_code: "17860",
    zip_name: "paxinos, pa",
  },
  {
    postal_code: "12786",
    zip_name: "white lake, ny",
  },
  {
    postal_code: "61257",
    zip_name: "hillsdale, il",
  },
  {
    postal_code: "12852",
    zip_name: "newcomb, ny",
  },
  {
    postal_code: "56653",
    zip_name: "littlefork, mn",
  },
  {
    postal_code: "55763",
    zip_name: "makinen, mn",
  },
  {
    postal_code: "72916",
    zip_name: "fort smith, ar",
  },
  {
    postal_code: "89179",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "85938",
    zip_name: "springerville, az",
  },
  {
    postal_code: "24380",
    zip_name: "willis, va",
  },
  {
    postal_code: "7458",
    zip_name: "saddle river, nj",
  },
  {
    postal_code: "82834",
    zip_name: "buffalo, wy",
  },
  {
    postal_code: "19940",
    zip_name: "delmar, de",
  },
  {
    postal_code: "7202",
    zip_name: "elizabeth, nj",
  },
  {
    postal_code: "24128",
    zip_name: "newport, va",
  },
  {
    postal_code: "74555",
    zip_name: "lane, ok",
  },
  {
    postal_code: "52235",
    zip_name: "hills, ia",
  },
  {
    postal_code: "70653",
    zip_name: "merryville, la",
  },
  {
    postal_code: "15076",
    zip_name: "russellton, pa",
  },
  {
    postal_code: "24077",
    zip_name: "cloverdale, va",
  },
  {
    postal_code: "13489",
    zip_name: "west leyden, ny",
  },
  {
    postal_code: "69022",
    zip_name: "cambridge, ne",
  },
  {
    postal_code: "48822",
    zip_name: "eagle, mi",
  },
  {
    postal_code: "65081",
    zip_name: "tipton, mo",
  },
  {
    postal_code: "17501",
    zip_name: "akron, pa",
  },
  {
    postal_code: "57313",
    zip_name: "armour, sd",
  },
  {
    postal_code: "49271",
    zip_name: "pittsford, mi",
  },
  {
    postal_code: "82213",
    zip_name: "glendo, wy",
  },
  {
    postal_code: "41655",
    zip_name: "printer, ky",
  },
  {
    postal_code: "7851",
    zip_name: "layton, nj",
  },
  {
    postal_code: "4606",
    zip_name: "addison, me",
  },
  {
    postal_code: "56296",
    zip_name: "wheaton, mn",
  },
  {
    postal_code: "12945",
    zip_name: "lake clear, ny",
  },
  {
    postal_code: "78839",
    zip_name: "crystal city, tx",
  },
  {
    postal_code: "89061",
    zip_name: "pahrump, nv",
  },
  {
    postal_code: "54563",
    zip_name: "tony, wi",
  },
  {
    postal_code: "73647",
    zip_name: "foss, ok",
  },
  {
    postal_code: "49309",
    zip_name: "bitely, mi",
  },
  {
    postal_code: "36268",
    zip_name: "munford, al",
  },
  {
    postal_code: "30730",
    zip_name: "lyerly, ga",
  },
  {
    postal_code: "83272",
    zip_name: "saint charles, id",
  },
  {
    postal_code: "99129",
    zip_name: "fruitland, wa",
  },
  {
    postal_code: "74026",
    zip_name: "davenport, ok",
  },
  {
    postal_code: "12855",
    zip_name: "north hudson, ny",
  },
  {
    postal_code: "61450",
    zip_name: "la harpe, il",
  },
  {
    postal_code: "94130",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "64482",
    zip_name: "rock port, mo",
  },
  {
    postal_code: "71851",
    zip_name: "mineral springs, ar",
  },
  {
    postal_code: "16611",
    zip_name: "alexandria, pa",
  },
  {
    postal_code: "61841",
    zip_name: "fairmount, il",
  },
  {
    postal_code: "30538",
    zip_name: "eastanollee, ga",
  },
  {
    postal_code: "98560",
    zip_name: "matlock, wa",
  },
  {
    postal_code: "84643",
    zip_name: "mayfield, ut",
  },
  {
    postal_code: "71941",
    zip_name: "donaldson, ar",
  },
  {
    postal_code: "17101",
    zip_name: "harrisburg, pa",
  },
  {
    postal_code: "16881",
    zip_name: "woodland, pa",
  },
  {
    postal_code: "15620",
    zip_name: "bradenville, pa",
  },
  {
    postal_code: "20143",
    zip_name: "catharpin, va",
  },
  {
    postal_code: "27355",
    zip_name: "staley, nc",
  },
  {
    postal_code: "61345",
    zip_name: "neponset, il",
  },
  {
    postal_code: "52745",
    zip_name: "dixon, ia",
  },
  {
    postal_code: "29840",
    zip_name: "mount carmel, sc",
  },
  {
    postal_code: "21530",
    zip_name: "flintstone, md",
  },
  {
    postal_code: "5065",
    zip_name: "sharon, vt",
  },
  {
    postal_code: "56282",
    zip_name: "raymond, mn",
  },
  {
    postal_code: "79546",
    zip_name: "rotan, tx",
  },
  {
    postal_code: "25547",
    zip_name: "pecks mill, wv",
  },
  {
    postal_code: "7630",
    zip_name: "emerson, nj",
  },
  {
    postal_code: "92347",
    zip_name: "hinkley, ca",
  },
  {
    postal_code: "45354",
    zip_name: "phillipsburg, oh",
  },
  {
    postal_code: "49320",
    zip_name: "chippewa lake, mi",
  },
  {
    postal_code: "77873",
    zip_name: "richards, tx",
  },
  {
    postal_code: "38318",
    zip_name: "buena vista, tn",
  },
  {
    postal_code: "98941",
    zip_name: "roslyn, wa",
  },
  {
    postal_code: "12819",
    zip_name: "clemons, ny",
  },
  {
    postal_code: "52046",
    zip_name: "farley, ia",
  },
  {
    postal_code: "68045",
    zip_name: "oakland, ne",
  },
  {
    postal_code: "36435",
    zip_name: "coy, al",
  },
  {
    postal_code: "14533",
    zip_name: "piffard, ny",
  },
  {
    postal_code: "70516",
    zip_name: "branch, la",
  },
  {
    postal_code: "16024",
    zip_name: "callery, pa",
  },
  {
    postal_code: "95420",
    zip_name: "caspar, ca",
  },
  {
    postal_code: "16326",
    zip_name: "fryburg, pa",
  },
  {
    postal_code: "62086",
    zip_name: "sorento, il",
  },
  {
    postal_code: "12439",
    zip_name: "hensonville, ny",
  },
  {
    postal_code: "64445",
    zip_name: "elmo, mo",
  },
  {
    postal_code: "54928",
    zip_name: "caroline, wi",
  },
  {
    postal_code: "19474",
    zip_name: "skippack, pa",
  },
  {
    postal_code: "55983",
    zip_name: "wanamingo, mn",
  },
  {
    postal_code: "25003",
    zip_name: "alum creek, wv",
  },
  {
    postal_code: "45851",
    zip_name: "haviland, oh",
  },
  {
    postal_code: "17265",
    zip_name: "upperstrasburg, pa",
  },
  {
    postal_code: "23878",
    zip_name: "sedley, va",
  },
  {
    postal_code: "15695",
    zip_name: "wyano, pa",
  },
  {
    postal_code: "63448",
    zip_name: "la grange, mo",
  },
  {
    postal_code: "4238",
    zip_name: "hebron, me",
  },
  {
    postal_code: "53969",
    zip_name: "wyocena, wi",
  },
  {
    postal_code: "37366",
    zip_name: "pelham, tn",
  },
  {
    postal_code: "52563",
    zip_name: "hedrick, ia",
  },
  {
    postal_code: "56091",
    zip_name: "waldorf, mn",
  },
  {
    postal_code: "89040",
    zip_name: "overton, nv",
  },
  {
    postal_code: "41828",
    zip_name: "kite, ky",
  },
  {
    postal_code: "45889",
    zip_name: "van buren, oh",
  },
  {
    postal_code: "5845",
    zip_name: "irasburg, vt",
  },
  {
    postal_code: "50845",
    zip_name: "diagonal, ia",
  },
  {
    postal_code: "8858",
    zip_name: "oldwick, nj",
  },
  {
    postal_code: "60933",
    zip_name: "elliott, il",
  },
  {
    postal_code: "76681",
    zip_name: "richland, tx",
  },
  {
    postal_code: "67570",
    zip_name: "pretty prairie, ks",
  },
  {
    postal_code: "76070",
    zip_name: "nemo, tx",
  },
  {
    postal_code: "23056",
    zip_name: "foster, va",
  },
  {
    postal_code: "48762",
    zip_name: "spruce, mi",
  },
  {
    postal_code: "16620",
    zip_name: "brisbin, pa",
  },
  {
    postal_code: "3575",
    zip_name: "bretton woods, nh",
  },
  {
    postal_code: "68524",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "62417",
    zip_name: "bridgeport, il",
  },
  {
    postal_code: "93622",
    zip_name: "firebaugh, ca",
  },
  {
    postal_code: "88415",
    zip_name: "clayton, nm",
  },
  {
    postal_code: "37335",
    zip_name: "flintville, tn",
  },
  {
    postal_code: "50858",
    zip_name: "orient, ia",
  },
  {
    postal_code: "50479",
    zip_name: "thornton, ia",
  },
  {
    postal_code: "12470",
    zip_name: "purling, ny",
  },
  {
    postal_code: "5030",
    zip_name: "ascutney, vt",
  },
  {
    postal_code: "12453",
    zip_name: "malden on hudson, ny",
  },
  {
    postal_code: "64470",
    zip_name: "mound city, mo",
  },
  {
    postal_code: "62320",
    zip_name: "camp point, il",
  },
  {
    postal_code: "16040",
    zip_name: "hilliards, pa",
  },
  {
    postal_code: "61722",
    zip_name: "arrowsmith, il",
  },
  {
    postal_code: "47930",
    zip_name: "clarks hill, in",
  },
  {
    postal_code: "29596",
    zip_name: "wallace, sc",
  },
  {
    postal_code: "51002",
    zip_name: "alta, ia",
  },
  {
    postal_code: "2543",
    zip_name: "woods hole, ma",
  },
  {
    postal_code: "49817",
    zip_name: "cooks, mi",
  },
  {
    postal_code: "8608",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "66767",
    zip_name: "prescott, ks",
  },
  {
    postal_code: "13417",
    zip_name: "new york mills, ny",
  },
  {
    postal_code: "80532",
    zip_name: "glen haven, co",
  },
  {
    postal_code: "57028",
    zip_name: "flandreau, sd",
  },
  {
    postal_code: "25168",
    zip_name: "red house, wv",
  },
  {
    postal_code: "17099",
    zip_name: "yeagertown, pa",
  },
  {
    postal_code: "27252",
    zip_name: "goldston, nc",
  },
  {
    postal_code: "68368",
    zip_name: "hallam, ne",
  },
  {
    postal_code: "68729",
    zip_name: "creighton, ne",
  },
  {
    postal_code: "56332",
    zip_name: "garfield, mn",
  },
  {
    postal_code: "65061",
    zip_name: "morrison, mo",
  },
  {
    postal_code: "25186",
    zip_name: "smithers, wv",
  },
  {
    postal_code: "64689",
    zip_name: "winston, mo",
  },
  {
    postal_code: "15345",
    zip_name: "marianna, pa",
  },
  {
    postal_code: "65662",
    zip_name: "grovespring, mo",
  },
  {
    postal_code: "63471",
    zip_name: "taylor, mo",
  },
  {
    postal_code: "66781",
    zip_name: "weir, ks",
  },
  {
    postal_code: "95232",
    zip_name: "glencoe, ca",
  },
  {
    postal_code: "40348",
    zip_name: "millersburg, ky",
  },
  {
    postal_code: "14445",
    zip_name: "east rochester, ny",
  },
  {
    postal_code: "59523",
    zip_name: "chinook, mt",
  },
  {
    postal_code: "14767",
    zip_name: "panama, ny",
  },
  {
    postal_code: "55702",
    zip_name: "alborn, mn",
  },
  {
    postal_code: "42286",
    zip_name: "trenton, ky",
  },
  {
    postal_code: "84773",
    zip_name: "teasdale, ut",
  },
  {
    postal_code: "77458",
    zip_name: "midfield, tx",
  },
  {
    postal_code: "14860",
    zip_name: "lodi, ny",
  },
  {
    postal_code: "99012",
    zip_name: "fairfield, wa",
  },
  {
    postal_code: "61863",
    zip_name: "pesotum, il",
  },
  {
    postal_code: "50278",
    zip_name: "zearing, ia",
  },
  {
    postal_code: "61250",
    zip_name: "erie, il",
  },
  {
    postal_code: "47619",
    zip_name: "lynnville, in",
  },
  {
    postal_code: "61931",
    zip_name: "humboldt, il",
  },
  {
    postal_code: "60929",
    zip_name: "cullom, il",
  },
  {
    postal_code: "62051",
    zip_name: "irving, il",
  },
  {
    postal_code: "54624",
    zip_name: "de soto, wi",
  },
  {
    postal_code: "56657",
    zip_name: "marcell, mn",
  },
  {
    postal_code: "56176",
    zip_name: "trimont, mn",
  },
  {
    postal_code: "54763",
    zip_name: "ridgeland, wi",
  },
  {
    postal_code: "68354",
    zip_name: "fairmont, ne",
  },
  {
    postal_code: "13301",
    zip_name: "alder creek, ny",
  },
  {
    postal_code: "99146",
    zip_name: "laurier, wa",
  },
  {
    postal_code: "54734",
    zip_name: "downing, wi",
  },
  {
    postal_code: "64746",
    zip_name: "freeman, mo",
  },
  {
    postal_code: "62045",
    zip_name: "hamburg, il",
  },
  {
    postal_code: "45630",
    zip_name: "friendship, oh",
  },
  {
    postal_code: "15480",
    zip_name: "smock, pa",
  },
  {
    postal_code: "47230",
    zip_name: "deputy, in",
  },
  {
    postal_code: "14806",
    zip_name: "andover, ny",
  },
  {
    postal_code: "52035",
    zip_name: "colesburg, ia",
  },
  {
    postal_code: "31562",
    zip_name: "saint george, ga",
  },
  {
    postal_code: "95686",
    zip_name: "thornton, ca",
  },
  {
    postal_code: "65654",
    zip_name: "freistatt, mo",
  },
  {
    postal_code: "63620",
    zip_name: "annapolis, mo",
  },
  {
    postal_code: "5653",
    zip_name: "eden mills, vt",
  },
  {
    postal_code: "15486",
    zip_name: "vanderbilt, pa",
  },
  {
    postal_code: "54922",
    zip_name: "bear creek, wi",
  },
  {
    postal_code: "5742",
    zip_name: "east wallingford, vt",
  },
  {
    postal_code: "33840",
    zip_name: "eaton park, fl",
  },
  {
    postal_code: "96113",
    zip_name: "herlong, ca",
  },
  {
    postal_code: "21523",
    zip_name: "bloomington, md",
  },
  {
    postal_code: "68966",
    zip_name: "orleans, ne",
  },
  {
    postal_code: "24377",
    zip_name: "tannersville, va",
  },
  {
    postal_code: "24280",
    zip_name: "rosedale, va",
  },
  {
    postal_code: "45685",
    zip_name: "thurman, oh",
  },
  {
    postal_code: "31019",
    zip_name: "dexter, ga",
  },
  {
    postal_code: "68958",
    zip_name: "loomis, ne",
  },
  {
    postal_code: "95230",
    zip_name: "farmington, ca",
  },
  {
    postal_code: "59647",
    zip_name: "winston, mt",
  },
  {
    postal_code: "5862",
    zip_name: "peacham, vt",
  },
  {
    postal_code: "49654",
    zip_name: "leland, mi",
  },
  {
    postal_code: "75930",
    zip_name: "bronson, tx",
  },
  {
    postal_code: "92239",
    zip_name: "desert center, ca",
  },
  {
    postal_code: "18616",
    zip_name: "forksville, pa",
  },
  {
    postal_code: "53936",
    zip_name: "grand marsh, wi",
  },
  {
    postal_code: "13328",
    zip_name: "deansboro, ny",
  },
  {
    postal_code: "28660",
    zip_name: "olin, nc",
  },
  {
    postal_code: "12841",
    zip_name: "huletts landing, ny",
  },
  {
    postal_code: "54412",
    zip_name: "auburndale, wi",
  },
  {
    postal_code: "26206",
    zip_name: "cowen, wv",
  },
  {
    postal_code: "29163",
    zip_name: "vance, sc",
  },
  {
    postal_code: "58789",
    zip_name: "upham, nd",
  },
  {
    postal_code: "68922",
    zip_name: "arapahoe, ne",
  },
  {
    postal_code: "25431",
    zip_name: "levels, wv",
  },
  {
    postal_code: "98590",
    zip_name: "tokeland, wa",
  },
  {
    postal_code: "50569",
    zip_name: "otho, ia",
  },
  {
    postal_code: "44625",
    zip_name: "east rochester, oh",
  },
  {
    postal_code: "66538",
    zip_name: "seneca, ks",
  },
  {
    postal_code: "13694",
    zip_name: "waddington, ny",
  },
  {
    postal_code: "64084",
    zip_name: "rayville, mo",
  },
  {
    postal_code: "68465",
    zip_name: "wilber, ne",
  },
  {
    postal_code: "54227",
    zip_name: "maribel, wi",
  },
  {
    postal_code: "53090",
    zip_name: "west bend, wi",
  },
  {
    postal_code: "97453",
    zip_name: "mapleton, or",
  },
  {
    postal_code: "35966",
    zip_name: "flat rock, al",
  },
  {
    postal_code: "73107",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "29832",
    zip_name: "johnston, sc",
  },
  {
    postal_code: "83555",
    zip_name: "winchester, id",
  },
  {
    postal_code: "54961",
    zip_name: "new london, wi",
  },
  {
    postal_code: "68428",
    zip_name: "raymond, ne",
  },
  {
    postal_code: "54246",
    zip_name: "washington island, wi",
  },
  {
    postal_code: "16748",
    zip_name: "shinglehouse, pa",
  },
  {
    postal_code: "20119",
    zip_name: "catlett, va",
  },
  {
    postal_code: "62665",
    zip_name: "meredosia, il",
  },
  {
    postal_code: "6021",
    zip_name: "colebrook, ct",
  },
  {
    postal_code: "37852",
    zip_name: "robbins, tn",
  },
  {
    postal_code: "1908",
    zip_name: "nahant, ma",
  },
  {
    postal_code: "66517",
    zip_name: "ogden, ks",
  },
  {
    postal_code: "89156",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "7730",
    zip_name: "hazlet, nj",
  },
  {
    postal_code: "24910",
    zip_name: "alderson, wv",
  },
  {
    postal_code: "17347",
    zip_name: "mount wolf, pa",
  },
  {
    postal_code: "89128",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "46615",
    zip_name: "south bend, in",
  },
  {
    postal_code: "60953",
    zip_name: "milford, il",
  },
  {
    postal_code: "17033",
    zip_name: "hershey, pa",
  },
  {
    postal_code: "47041",
    zip_name: "sunman, in",
  },
  {
    postal_code: "54103",
    zip_name: "armstrong creek, wi",
  },
  {
    postal_code: "44250",
    zip_name: "lakemore, oh",
  },
  {
    postal_code: "76853",
    zip_name: "lometa, tx",
  },
  {
    postal_code: "53048",
    zip_name: "lomira, wi",
  },
  {
    postal_code: "53012",
    zip_name: "cedarburg, wi",
  },
  {
    postal_code: "24529",
    zip_name: "buffalo junction, va",
  },
  {
    postal_code: "37096",
    zip_name: "linden, tn",
  },
  {
    postal_code: "19806",
    zip_name: "wilmington, de",
  },
  {
    postal_code: "10457",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "17038",
    zip_name: "jonestown, pa",
  },
  {
    postal_code: "42653",
    zip_name: "whitley city, ky",
  },
  {
    postal_code: "64601",
    zip_name: "chillicothe, mo",
  },
  {
    postal_code: "56110",
    zip_name: "adrian, mn",
  },
  {
    postal_code: "7504",
    zip_name: "paterson, nj",
  },
  {
    postal_code: "53156",
    zip_name: "palmyra, wi",
  },
  {
    postal_code: "48877",
    zip_name: "riverdale, mi",
  },
  {
    postal_code: "73130",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "47620",
    zip_name: "mount vernon, in",
  },
  {
    postal_code: "77968",
    zip_name: "inez, tx",
  },
  {
    postal_code: "67220",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "18038",
    zip_name: "danielsville, pa",
  },
  {
    postal_code: "36033",
    zip_name: "georgiana, al",
  },
  {
    postal_code: "31560",
    zip_name: "screven, ga",
  },
  {
    postal_code: "14750",
    zip_name: "lakewood, ny",
  },
  {
    postal_code: "14821",
    zip_name: "campbell, ny",
  },
  {
    postal_code: "67441",
    zip_name: "enterprise, ks",
  },
  {
    postal_code: "17320",
    zip_name: "fairfield, pa",
  },
  {
    postal_code: "31503",
    zip_name: "waycross, ga",
  },
  {
    postal_code: "36549",
    zip_name: "lillian, al",
  },
  {
    postal_code: "54937",
    zip_name: "fond du lac, wi",
  },
  {
    postal_code: "23138",
    zip_name: "port haywood, va",
  },
  {
    postal_code: "70633",
    zip_name: "dequincy, la",
  },
  {
    postal_code: "7921",
    zip_name: "bedminster, nj",
  },
  {
    postal_code: "89084",
    zip_name: "north las vegas, nv",
  },
  {
    postal_code: "35803",
    zip_name: "huntsville, al",
  },
  {
    postal_code: "73132",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "35673",
    zip_name: "trinity, al",
  },
  {
    postal_code: "35758",
    zip_name: "madison, al",
  },
  {
    postal_code: "10805",
    zip_name: "new rochelle, ny",
  },
  {
    postal_code: "78040",
    zip_name: "laredo, tx",
  },
  {
    postal_code: "23605",
    zip_name: "newport news, va",
  },
  {
    postal_code: "89149",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "49682",
    zip_name: "suttons bay, mi",
  },
  {
    postal_code: "53209",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "80465",
    zip_name: "morrison, co",
  },
  {
    postal_code: "19018",
    zip_name: "clifton heights, pa",
  },
  {
    postal_code: "89081",
    zip_name: "north las vegas, nv",
  },
  {
    postal_code: "89031",
    zip_name: "north las vegas, nv",
  },
  {
    postal_code: "53402",
    zip_name: "racine, wi",
  },
  {
    postal_code: "54210",
    zip_name: "ellison bay, wi",
  },
  {
    postal_code: "8801",
    zip_name: "annandale, nj",
  },
  {
    postal_code: "68701",
    zip_name: "norfolk, ne",
  },
  {
    postal_code: "7821",
    zip_name: "andover, nj",
  },
  {
    postal_code: "88001",
    zip_name: "las cruces, nm",
  },
  {
    postal_code: "68117",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "53222",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "96821",
    zip_name: "honolulu, hi",
  },
  {
    postal_code: "7059",
    zip_name: "warren, nj",
  },
  {
    postal_code: "49418",
    zip_name: "grandville, mi",
  },
  {
    postal_code: "81615",
    zip_name: "snowmass village, co",
  },
  {
    postal_code: "82601",
    zip_name: "casper, wy",
  },
  {
    postal_code: "78045",
    zip_name: "laredo, tx",
  },
  {
    postal_code: "65201",
    zip_name: "columbia, mo",
  },
  {
    postal_code: "19123",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "65779",
    zip_name: "wheatland, mo",
  },
  {
    postal_code: "36612",
    zip_name: "mobile, al",
  },
  {
    postal_code: "83332",
    zip_name: "hagerman, id",
  },
  {
    postal_code: "46910",
    zip_name: "akron, in",
  },
  {
    postal_code: "83442",
    zip_name: "rigby, id",
  },
  {
    postal_code: "14813",
    zip_name: "belmont, ny",
  },
  {
    postal_code: "44304",
    zip_name: "akron, oh",
  },
  {
    postal_code: "21132",
    zip_name: "pylesville, md",
  },
  {
    postal_code: "27046",
    zip_name: "sandy ridge, nc",
  },
  {
    postal_code: "40464",
    zip_name: "parksville, ky",
  },
  {
    postal_code: "65627",
    zip_name: "cedarcreek, mo",
  },
  {
    postal_code: "53170",
    zip_name: "silver lake, wi",
  },
  {
    postal_code: "65567",
    zip_name: "stoutland, mo",
  },
  {
    postal_code: "67671",
    zip_name: "victoria, ks",
  },
  {
    postal_code: "87575",
    zip_name: "tierra amarilla, nm",
  },
  {
    postal_code: "77455",
    zip_name: "louise, tx",
  },
  {
    postal_code: "56662",
    zip_name: "outing, mn",
  },
  {
    postal_code: "29583",
    zip_name: "pamplico, sc",
  },
  {
    postal_code: "45780",
    zip_name: "the plains, oh",
  },
  {
    postal_code: "15423",
    zip_name: "coal center, pa",
  },
  {
    postal_code: "15056",
    zip_name: "leetsdale, pa",
  },
  {
    postal_code: "81621",
    zip_name: "basalt, co",
  },
  {
    postal_code: "17527",
    zip_name: "gap, pa",
  },
  {
    postal_code: "49534",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "7727",
    zip_name: "farmingdale, nj",
  },
  {
    postal_code: "8904",
    zip_name: "highland park, nj",
  },
  {
    postal_code: "70814",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "7733",
    zip_name: "holmdel, nj",
  },
  {
    postal_code: "21671",
    zip_name: "tilghman, md",
  },
  {
    postal_code: "98406",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "68025",
    zip_name: "fremont, ne",
  },
  {
    postal_code: "30907",
    zip_name: "augusta, ga",
  },
  {
    postal_code: "14611",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "66763",
    zip_name: "frontenac, ks",
  },
  {
    postal_code: "12733",
    zip_name: "fallsburg, ny",
  },
  {
    postal_code: "48165",
    zip_name: "new hudson, mi",
  },
  {
    postal_code: "87513",
    zip_name: "arroyo hondo, nm",
  },
  {
    postal_code: "62501",
    zip_name: "argenta, il",
  },
  {
    postal_code: "58078",
    zip_name: "west fargo, nd",
  },
  {
    postal_code: "7403",
    zip_name: "bloomingdale, nj",
  },
  {
    postal_code: "73044",
    zip_name: "guthrie, ok",
  },
  {
    postal_code: "7601",
    zip_name: "hackensack, nj",
  },
  {
    postal_code: "99507",
    zip_name: "anchorage, ak",
  },
  {
    postal_code: "74030",
    zip_name: "drumright, ok",
  },
  {
    postal_code: "53151",
    zip_name: "new berlin, wi",
  },
  {
    postal_code: "48125",
    zip_name: "dearborn heights, mi",
  },
  {
    postal_code: "36874",
    zip_name: "salem, al",
  },
  {
    postal_code: "18644",
    zip_name: "wyoming, pa",
  },
  {
    postal_code: "7836",
    zip_name: "flanders, nj",
  },
  {
    postal_code: "35984",
    zip_name: "mentone, al",
  },
  {
    postal_code: "8742",
    zip_name: "point pleasant beach, nj",
  },
  {
    postal_code: "17976",
    zip_name: "shenandoah, pa",
  },
  {
    postal_code: "81611",
    zip_name: "aspen, co",
  },
  {
    postal_code: "32607",
    zip_name: "gainesville, fl",
  },
  {
    postal_code: "12778",
    zip_name: "smallwood, ny",
  },
  {
    postal_code: "10606",
    zip_name: "white plains, ny",
  },
  {
    postal_code: "1264",
    zip_name: "tyringham, ma",
  },
  {
    postal_code: "43326",
    zip_name: "kenton, oh",
  },
  {
    postal_code: "18615",
    zip_name: "falls, pa",
  },
  {
    postal_code: "26410",
    zip_name: "newburg, wv",
  },
  {
    postal_code: "32603",
    zip_name: "gainesville, fl",
  },
  {
    postal_code: "47848",
    zip_name: "dugger, in",
  },
  {
    postal_code: "5843",
    zip_name: "hardwick, vt",
  },
  {
    postal_code: "56284",
    zip_name: "renville, mn",
  },
  {
    postal_code: "20625",
    zip_name: "cobb island, md",
  },
  {
    postal_code: "46377",
    zip_name: "shelby, in",
  },
  {
    postal_code: "17339",
    zip_name: "lewisberry, pa",
  },
  {
    postal_code: "59754",
    zip_name: "twin bridges, mt",
  },
  {
    postal_code: "72459",
    zip_name: "ravenden, ar",
  },
  {
    postal_code: "95637",
    zip_name: "guinda, ca",
  },
  {
    postal_code: "10537",
    zip_name: "lake peekskill, ny",
  },
  {
    postal_code: "7502",
    zip_name: "paterson, nj",
  },
  {
    postal_code: "95981",
    zip_name: "strawberry valley, ca",
  },
  {
    postal_code: "95369",
    zip_name: "snelling, ca",
  },
  {
    postal_code: "71019",
    zip_name: "coushatta, la",
  },
  {
    postal_code: "11739",
    zip_name: "great river, ny",
  },
  {
    postal_code: "53507",
    zip_name: "barneveld, wi",
  },
  {
    postal_code: "4985",
    zip_name: "west forks, me",
  },
  {
    postal_code: "84645",
    zip_name: "mona, ut",
  },
  {
    postal_code: "69123",
    zip_name: "brady, ne",
  },
  {
    postal_code: "7737",
    zip_name: "leonardo, nj",
  },
  {
    postal_code: "72821",
    zip_name: "altus, ar",
  },
  {
    postal_code: "73742",
    zip_name: "hennessey, ok",
  },
  {
    postal_code: "72944",
    zip_name: "mansfield, ar",
  },
  {
    postal_code: "24534",
    zip_name: "clover, va",
  },
  {
    postal_code: "83661",
    zip_name: "payette, id",
  },
  {
    postal_code: "19732",
    zip_name: "rockland, de",
  },
  {
    postal_code: "29016",
    zip_name: "blythewood, sc",
  },
  {
    postal_code: "5076",
    zip_name: "topsham, vt",
  },
  {
    postal_code: "8019",
    zip_name: "chatsworth, nj",
  },
  {
    postal_code: "75155",
    zip_name: "rice, tx",
  },
  {
    postal_code: "65637",
    zip_name: "dora, mo",
  },
  {
    postal_code: "54619",
    zip_name: "cashton, wi",
  },
  {
    postal_code: "7043",
    zip_name: "montclair, nj",
  },
  {
    postal_code: "53826",
    zip_name: "wauzeka, wi",
  },
  {
    postal_code: "67663",
    zip_name: "plainville, ks",
  },
  {
    postal_code: "99610",
    zip_name: "kasilof, ak",
  },
  {
    postal_code: "47951",
    zip_name: "kentland, in",
  },
  {
    postal_code: "83466",
    zip_name: "north fork, id",
  },
  {
    postal_code: "54474",
    zip_name: "rothschild, wi",
  },
  {
    postal_code: "43824",
    zip_name: "fresno, oh",
  },
  {
    postal_code: "83813",
    zip_name: "cocolalla, id",
  },
  {
    postal_code: "42024",
    zip_name: "barlow, ky",
  },
  {
    postal_code: "36525",
    zip_name: "creola, al",
  },
  {
    postal_code: "72834",
    zip_name: "dardanelle, ar",
  },
  {
    postal_code: "88063",
    zip_name: "sunland park, nm",
  },
  {
    postal_code: "24935",
    zip_name: "forest hill, wv",
  },
  {
    postal_code: "89430",
    zip_name: "smith, nv",
  },
  {
    postal_code: "41045",
    zip_name: "ghent, ky",
  },
  {
    postal_code: "4260",
    zip_name: "new gloucester, me",
  },
  {
    postal_code: "72132",
    zip_name: "redfield, ar",
  },
  {
    postal_code: "47172",
    zip_name: "sellersburg, in",
  },
  {
    postal_code: "31716",
    zip_name: "baconton, ga",
  },
  {
    postal_code: "88346",
    zip_name: "ruidoso downs, nm",
  },
  {
    postal_code: "69025",
    zip_name: "curtis, ne",
  },
  {
    postal_code: "24314",
    zip_name: "bastian, va",
  },
  {
    postal_code: "45876",
    zip_name: "ottoville, oh",
  },
  {
    postal_code: "31825",
    zip_name: "richland, ga",
  },
  {
    postal_code: "95916",
    zip_name: "berry creek, ca",
  },
  {
    postal_code: "26347",
    zip_name: "flemington, wv",
  },
  {
    postal_code: "31091",
    zip_name: "unadilla, ga",
  },
  {
    postal_code: "65789",
    zip_name: "pomona, mo",
  },
  {
    postal_code: "48636",
    zip_name: "luzerne, mi",
  },
  {
    postal_code: "97530",
    zip_name: "jacksonville, or",
  },
  {
    postal_code: "73449",
    zip_name: "mead, ok",
  },
  {
    postal_code: "24630",
    zip_name: "north tazewell, va",
  },
  {
    postal_code: "12843",
    zip_name: "johnsburg, ny",
  },
  {
    postal_code: "72370",
    zip_name: "osceola, ar",
  },
  {
    postal_code: "89119",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "8035",
    zip_name: "haddon heights, nj",
  },
  {
    postal_code: "89074",
    zip_name: "henderson, nv",
  },
  {
    postal_code: "96706",
    zip_name: "ewa beach, hi",
  },
  {
    postal_code: "24416",
    zip_name: "buena vista, va",
  },
  {
    postal_code: "24122",
    zip_name: "montvale, va",
  },
  {
    postal_code: "36756",
    zip_name: "marion, al",
  },
  {
    postal_code: "24946",
    zip_name: "hillsboro, wv",
  },
  {
    postal_code: "61448",
    zip_name: "knoxville, il",
  },
  {
    postal_code: "7852",
    zip_name: "ledgewood, nj",
  },
  {
    postal_code: "96125",
    zip_name: "sierra city, ca",
  },
  {
    postal_code: "4092",
    zip_name: "westbrook, me",
  },
  {
    postal_code: "31217",
    zip_name: "macon, ga",
  },
  {
    postal_code: "49254",
    zip_name: "michigan center, mi",
  },
  {
    postal_code: "70767",
    zip_name: "port allen, la",
  },
  {
    postal_code: "46732",
    zip_name: "cromwell, in",
  },
  {
    postal_code: "62869",
    zip_name: "norris city, il",
  },
  {
    postal_code: "8247",
    zip_name: "stone harbor, nj",
  },
  {
    postal_code: "71107",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "83850",
    zip_name: "pinehurst, id",
  },
  {
    postal_code: "53213",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "29223",
    zip_name: "columbia, sc",
  },
  {
    postal_code: "23851",
    zip_name: "franklin, va",
  },
  {
    postal_code: "71469",
    zip_name: "robeline, la",
  },
  {
    postal_code: "81149",
    zip_name: "saguache, co",
  },
  {
    postal_code: "48238",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "27953",
    zip_name: "manns harbor, nc",
  },
  {
    postal_code: "40003",
    zip_name: "bagdad, ky",
  },
  {
    postal_code: "29073",
    zip_name: "lexington, sc",
  },
  {
    postal_code: "41183",
    zip_name: "worthington, ky",
  },
  {
    postal_code: "1258",
    zip_name: "south egremont, ma",
  },
  {
    postal_code: "48761",
    zip_name: "south branch, mi",
  },
  {
    postal_code: "56331",
    zip_name: "freeport, mn",
  },
  {
    postal_code: "49321",
    zip_name: "comstock park, mi",
  },
  {
    postal_code: "49037",
    zip_name: "battle creek, mi",
  },
  {
    postal_code: "5032",
    zip_name: "bethel, vt",
  },
  {
    postal_code: "49237",
    zip_name: "concord, mi",
  },
  {
    postal_code: "78393",
    zip_name: "woodsboro, tx",
  },
  {
    postal_code: "92539",
    zip_name: "anza, ca",
  },
  {
    postal_code: "62549",
    zip_name: "mt zion, il",
  },
  {
    postal_code: "67104",
    zip_name: "medicine lodge, ks",
  },
  {
    postal_code: "81235",
    zip_name: "lake city, co",
  },
  {
    postal_code: "4292",
    zip_name: "sumner, me",
  },
  {
    postal_code: "65733",
    zip_name: "protem, mo",
  },
  {
    postal_code: "27869",
    zip_name: "rich square, nc",
  },
  {
    postal_code: "14571",
    zip_name: "waterport, ny",
  },
  {
    postal_code: "42266",
    zip_name: "pembroke, ky",
  },
  {
    postal_code: "14477",
    zip_name: "kent, ny",
  },
  {
    postal_code: "81125",
    zip_name: "center, co",
  },
  {
    postal_code: "54836",
    zip_name: "foxboro, wi",
  },
  {
    postal_code: "12484",
    zip_name: "stone ridge, ny",
  },
  {
    postal_code: "73505",
    zip_name: "lawton, ok",
  },
  {
    postal_code: "49648",
    zip_name: "kewadin, mi",
  },
  {
    postal_code: "70344",
    zip_name: "chauvin, la",
  },
  {
    postal_code: "14546",
    zip_name: "scottsville, ny",
  },
  {
    postal_code: "49712",
    zip_name: "boyne city, mi",
  },
  {
    postal_code: "48205",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "23437",
    zip_name: "suffolk, va",
  },
  {
    postal_code: "50622",
    zip_name: "denver, ia",
  },
  {
    postal_code: "54983",
    zip_name: "weyauwega, wi",
  },
  {
    postal_code: "49073",
    zip_name: "nashville, mi",
  },
  {
    postal_code: "88260",
    zip_name: "lovington, nm",
  },
  {
    postal_code: "7720",
    zip_name: "bradley beach, nj",
  },
  {
    postal_code: "87525",
    zip_name: "taos ski valley, nm",
  },
  {
    postal_code: "17015",
    zip_name: "carlisle, pa",
  },
  {
    postal_code: "32583",
    zip_name: "milton, fl",
  },
  {
    postal_code: "82930",
    zip_name: "evanston, wy",
  },
  {
    postal_code: "7047",
    zip_name: "north bergen, nj",
  },
  {
    postal_code: "46516",
    zip_name: "elkhart, in",
  },
  {
    postal_code: "31533",
    zip_name: "douglas, ga",
  },
  {
    postal_code: "50471",
    zip_name: "rudd, ia",
  },
  {
    postal_code: "8248",
    zip_name: "strathmere, nj",
  },
  {
    postal_code: "46501",
    zip_name: "argos, in",
  },
  {
    postal_code: "62990",
    zip_name: "thebes, il",
  },
  {
    postal_code: "71486",
    zip_name: "zwolle, la",
  },
  {
    postal_code: "29704",
    zip_name: "catawba, sc",
  },
  {
    postal_code: "81422",
    zip_name: "naturita, co",
  },
  {
    postal_code: "4762",
    zip_name: "new sweden, me",
  },
  {
    postal_code: "72136",
    zip_name: "romance, ar",
  },
  {
    postal_code: "39421",
    zip_name: "bassfield, ms",
  },
  {
    postal_code: "31038",
    zip_name: "hillsboro, ga",
  },
  {
    postal_code: "50142",
    zip_name: "le grand, ia",
  },
  {
    postal_code: "72934",
    zip_name: "chester, ar",
  },
  {
    postal_code: "47143",
    zip_name: "memphis, in",
  },
  {
    postal_code: "80423",
    zip_name: "bond, co",
  },
  {
    postal_code: "51005",
    zip_name: "aurelia, ia",
  },
  {
    postal_code: "73104",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "54626",
    zip_name: "eastman, wi",
  },
  {
    postal_code: "25840",
    zip_name: "fayetteville, wv",
  },
  {
    postal_code: "37753",
    zip_name: "hartford, tn",
  },
  {
    postal_code: "75563",
    zip_name: "linden, tx",
  },
  {
    postal_code: "75488",
    zip_name: "telephone, tx",
  },
  {
    postal_code: "15629",
    zip_name: "east vandergrift, pa",
  },
  {
    postal_code: "50841",
    zip_name: "corning, ia",
  },
  {
    postal_code: "66871",
    zip_name: "waverly, ks",
  },
  {
    postal_code: "21648",
    zip_name: "madison, md",
  },
  {
    postal_code: "38619",
    zip_name: "como, ms",
  },
  {
    postal_code: "24085",
    zip_name: "eagle rock, va",
  },
  {
    postal_code: "67108",
    zip_name: "mount hope, ks",
  },
  {
    postal_code: "62673",
    zip_name: "oakford, il",
  },
  {
    postal_code: "71259",
    zip_name: "mangham, la",
  },
  {
    postal_code: "13665",
    zip_name: "natural bridge, ny",
  },
  {
    postal_code: "29018",
    zip_name: "bowman, sc",
  },
  {
    postal_code: "82212",
    zip_name: "fort laramie, wy",
  },
  {
    postal_code: "5341",
    zip_name: "east dover, vt",
  },
  {
    postal_code: "11958",
    zip_name: "peconic, ny",
  },
  {
    postal_code: "68514",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "48853",
    zip_name: "maple rapids, mi",
  },
  {
    postal_code: "78151",
    zip_name: "runge, tx",
  },
  {
    postal_code: "55943",
    zip_name: "houston, mn",
  },
  {
    postal_code: "43464",
    zip_name: "vickery, oh",
  },
  {
    postal_code: "5036",
    zip_name: "brookfield, vt",
  },
  {
    postal_code: "72680",
    zip_name: "timbo, ar",
  },
  {
    postal_code: "56728",
    zip_name: "hallock, mn",
  },
  {
    postal_code: "56144",
    zip_name: "jasper, mn",
  },
  {
    postal_code: "15535",
    zip_name: "clearville, pa",
  },
  {
    postal_code: "76570",
    zip_name: "rosebud, tx",
  },
  {
    postal_code: "57030",
    zip_name: "garretson, sd",
  },
  {
    postal_code: "78940",
    zip_name: "fayetteville, tx",
  },
  {
    postal_code: "83120",
    zip_name: "freedom, wy",
  },
  {
    postal_code: "19565",
    zip_name: "wernersville, pa",
  },
  {
    postal_code: "52659",
    zip_name: "winfield, ia",
  },
  {
    postal_code: "46748",
    zip_name: "huntertown, in",
  },
  {
    postal_code: "4284",
    zip_name: "wayne, me",
  },
  {
    postal_code: "62550",
    zip_name: "moweaqua, il",
  },
  {
    postal_code: "17536",
    zip_name: "kirkwood, pa",
  },
  {
    postal_code: "55952",
    zip_name: "lewiston, mn",
  },
  {
    postal_code: "13485",
    zip_name: "west edmeston, ny",
  },
  {
    postal_code: "23126",
    zip_name: "newtown, va",
  },
  {
    postal_code: "17407",
    zip_name: "york, pa",
  },
  {
    postal_code: "47980",
    zip_name: "reynolds, in",
  },
  {
    postal_code: "38379",
    zip_name: "stantonville, tn",
  },
  {
    postal_code: "49339",
    zip_name: "pierson, mi",
  },
  {
    postal_code: "43003",
    zip_name: "ashley, oh",
  },
  {
    postal_code: "27805",
    zip_name: "aulander, nc",
  },
  {
    postal_code: "5075",
    zip_name: "thetford center, vt",
  },
  {
    postal_code: "28119",
    zip_name: "morven, nc",
  },
  {
    postal_code: "67876",
    zip_name: "spearville, ks",
  },
  {
    postal_code: "49030",
    zip_name: "burr oak, mi",
  },
  {
    postal_code: "17581",
    zip_name: "terre hill, pa",
  },
  {
    postal_code: "83641",
    zip_name: "melba, id",
  },
  {
    postal_code: "12543",
    zip_name: "maybrook, ny",
  },
  {
    postal_code: "83261",
    zip_name: "paris, id",
  },
  {
    postal_code: "12978",
    zip_name: "redford, ny",
  },
  {
    postal_code: "17545",
    zip_name: "manheim, pa",
  },
  {
    postal_code: "60512",
    zip_name: "bristol, il",
  },
  {
    postal_code: "27812",
    zip_name: "bethel, nc",
  },
  {
    postal_code: "70390",
    zip_name: "napoleonville, la",
  },
  {
    postal_code: "38917",
    zip_name: "carrollton, ms",
  },
  {
    postal_code: "87829",
    zip_name: "quemado, nm",
  },
  {
    postal_code: "14782",
    zip_name: "sinclairville, ny",
  },
  {
    postal_code: "18106",
    zip_name: "allentown, pa",
  },
  {
    postal_code: "68157",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "50668",
    zip_name: "readlyn, ia",
  },
  {
    postal_code: "17721",
    zip_name: "avis, pa",
  },
  {
    postal_code: "12465",
    zip_name: "pine hill, ny",
  },
  {
    postal_code: "61283",
    zip_name: "tampico, il",
  },
  {
    postal_code: "64421",
    zip_name: "amazonia, mo",
  },
  {
    postal_code: "38061",
    zip_name: "pocahontas, tn",
  },
  {
    postal_code: "89102",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "25565",
    zip_name: "spurlockville, wv",
  },
  {
    postal_code: "72029",
    zip_name: "clarendon, ar",
  },
  {
    postal_code: "42156",
    zip_name: "lucas, ky",
  },
  {
    postal_code: "81332",
    zip_name: "rico, co",
  },
  {
    postal_code: "53579",
    zip_name: "reeseville, wi",
  },
  {
    postal_code: "14744",
    zip_name: "houghton, ny",
  },
  {
    postal_code: "95960",
    zip_name: "north san juan, ca",
  },
  {
    postal_code: "54977",
    zip_name: "scandinavia, wi",
  },
  {
    postal_code: "77010",
    zip_name: "houston, tx",
  },
  {
    postal_code: "20618",
    zip_name: "bushwood, md",
  },
  {
    postal_code: "48614",
    zip_name: "brant, mi",
  },
  {
    postal_code: "37373",
    zip_name: "sale creek, tn",
  },
  {
    postal_code: "53137",
    zip_name: "helenville, wi",
  },
  {
    postal_code: "70040",
    zip_name: "braithwaite, la",
  },
  {
    postal_code: "55026",
    zip_name: "frontenac, mn",
  },
  {
    postal_code: "47558",
    zip_name: "montgomery, in",
  },
  {
    postal_code: "62242",
    zip_name: "evansville, il",
  },
  {
    postal_code: "41265",
    zip_name: "van lear, ky",
  },
  {
    postal_code: "25035",
    zip_name: "cabin creek, wv",
  },
  {
    postal_code: "28538",
    zip_name: "hookerton, nc",
  },
  {
    postal_code: "30707",
    zip_name: "chickamauga, ga",
  },
  {
    postal_code: "96796",
    zip_name: "waimea, hi",
  },
  {
    postal_code: "62977",
    zip_name: "raleigh, il",
  },
  {
    postal_code: "80480",
    zip_name: "walden, co",
  },
  {
    postal_code: "16256",
    zip_name: "smicksburg, pa",
  },
  {
    postal_code: "26519",
    zip_name: "albright, wv",
  },
  {
    postal_code: "62001",
    zip_name: "alhambra, il",
  },
  {
    postal_code: "64011",
    zip_name: "bates city, mo",
  },
  {
    postal_code: "58369",
    zip_name: "saint john, nd",
  },
  {
    postal_code: "29346",
    zip_name: "glendale, sc",
  },
  {
    postal_code: "39661",
    zip_name: "roxie, ms",
  },
  {
    postal_code: "19021",
    zip_name: "croydon, pa",
  },
  {
    postal_code: "63330",
    zip_name: "annada, mo",
  },
  {
    postal_code: "61243",
    zip_name: "deer grove, il",
  },
  {
    postal_code: "50535",
    zip_name: "early, ia",
  },
  {
    postal_code: "5456",
    zip_name: "ferrisburgh, vt",
  },
  {
    postal_code: "98538",
    zip_name: "curtis, wa",
  },
  {
    postal_code: "29172",
    zip_name: "west columbia, sc",
  },
  {
    postal_code: "72854",
    zip_name: "ozone, ar",
  },
  {
    postal_code: "61811",
    zip_name: "alvin, il",
  },
  {
    postal_code: "18014",
    zip_name: "bath, pa",
  },
  {
    postal_code: "65722",
    zip_name: "phillipsburg, mo",
  },
  {
    postal_code: "47977",
    zip_name: "remington, in",
  },
  {
    postal_code: "53923",
    zip_name: "cambria, wi",
  },
  {
    postal_code: "14505",
    zip_name: "marion, ny",
  },
  {
    postal_code: "47453",
    zip_name: "owensburg, in",
  },
  {
    postal_code: "18626",
    zip_name: "laporte, pa",
  },
  {
    postal_code: "62893",
    zip_name: "walnut hill, il",
  },
  {
    postal_code: "12492",
    zip_name: "west kill, ny",
  },
  {
    postal_code: "68876",
    zip_name: "shelton, ne",
  },
  {
    postal_code: "31020",
    zip_name: "dry branch, ga",
  },
  {
    postal_code: "50434",
    zip_name: "fertile, ia",
  },
  {
    postal_code: "36255",
    zip_name: "cragford, al",
  },
  {
    postal_code: "37657",
    zip_name: "flag pond, tn",
  },
  {
    postal_code: "88041",
    zip_name: "hanover, nm",
  },
  {
    postal_code: "97737",
    zip_name: "gilchrist, or",
  },
  {
    postal_code: "4069",
    zip_name: "pownal, me",
  },
  {
    postal_code: "51463",
    zip_name: "templeton, ia",
  },
  {
    postal_code: "29014",
    zip_name: "blackstock, sc",
  },
  {
    postal_code: "13071",
    zip_name: "genoa, ny",
  },
  {
    postal_code: "12952",
    zip_name: "lyon mountain, ny",
  },
  {
    postal_code: "74826",
    zip_name: "asher, ok",
  },
  {
    postal_code: "80733",
    zip_name: "hillrose, co",
  },
  {
    postal_code: "35463",
    zip_name: "fosters, al",
  },
  {
    postal_code: "76511",
    zip_name: "bartlett, tx",
  },
  {
    postal_code: "94933",
    zip_name: "forest knolls, ca",
  },
  {
    postal_code: "41822",
    zip_name: "hindman, ky",
  },
  {
    postal_code: "73006",
    zip_name: "apache, ok",
  },
  {
    postal_code: "75683",
    zip_name: "ore city, tx",
  },
  {
    postal_code: "98814",
    zip_name: "carlton, wa",
  },
  {
    postal_code: "51047",
    zip_name: "peterson, ia",
  },
  {
    postal_code: "97473",
    zip_name: "scottsburg, or",
  },
  {
    postal_code: "46773",
    zip_name: "monroeville, in",
  },
  {
    postal_code: "13486",
    zip_name: "westernville, ny",
  },
  {
    postal_code: "84763",
    zip_name: "rockville, ut",
  },
  {
    postal_code: "15475",
    zip_name: "republic, pa",
  },
  {
    postal_code: "56041",
    zip_name: "hanska, mn",
  },
  {
    postal_code: "79101",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "17972",
    zip_name: "schuylkill haven, pa",
  },
  {
    postal_code: "63855",
    zip_name: "hornersville, mo",
  },
  {
    postal_code: "48770",
    zip_name: "whittemore, mi",
  },
  {
    postal_code: "12419",
    zip_name: "cottekill, ny",
  },
  {
    postal_code: "54628",
    zip_name: "ferryville, wi",
  },
  {
    postal_code: "71060",
    zip_name: "mooringsport, la",
  },
  {
    postal_code: "38345",
    zip_name: "huron, tn",
  },
  {
    postal_code: "63933",
    zip_name: "campbell, mo",
  },
  {
    postal_code: "52237",
    zip_name: "hopkinton, ia",
  },
  {
    postal_code: "83262",
    zip_name: "pingree, id",
  },
  {
    postal_code: "15081",
    zip_name: "south heights, pa",
  },
  {
    postal_code: "48654",
    zip_name: "rose city, mi",
  },
  {
    postal_code: "80926",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "18219",
    zip_name: "conyngham, pa",
  },
  {
    postal_code: "61038",
    zip_name: "garden prairie, il",
  },
  {
    postal_code: "84301",
    zip_name: "bear river city, ut",
  },
  {
    postal_code: "17324",
    zip_name: "gardners, pa",
  },
  {
    postal_code: "13740",
    zip_name: "bovina center, ny",
  },
  {
    postal_code: "56230",
    zip_name: "danube, mn",
  },
  {
    postal_code: "93254",
    zip_name: "new cuyama, ca",
  },
  {
    postal_code: "44697",
    zip_name: "zoar, oh",
  },
  {
    postal_code: "25537",
    zip_name: "lesage, wv",
  },
  {
    postal_code: "17352",
    zip_name: "new park, pa",
  },
  {
    postal_code: "6785",
    zip_name: "south kent, ct",
  },
  {
    postal_code: "68316",
    zip_name: "benedict, ne",
  },
  {
    postal_code: "62882",
    zip_name: "sandoval, il",
  },
  {
    postal_code: "97466",
    zip_name: "powers, or",
  },
  {
    postal_code: "1096",
    zip_name: "williamsburg, ma",
  },
  {
    postal_code: "62046",
    zip_name: "hamel, il",
  },
  {
    postal_code: "73067",
    zip_name: "ninnekah, ok",
  },
  {
    postal_code: "1128",
    zip_name: "springfield, ma",
  },
  {
    postal_code: "56016",
    zip_name: "clarks grove, mn",
  },
  {
    postal_code: "72374",
    zip_name: "poplar grove, ar",
  },
  {
    postal_code: "97452",
    zip_name: "lowell, or",
  },
  {
    postal_code: "59633",
    zip_name: "canyon creek, mt",
  },
  {
    postal_code: "48871",
    zip_name: "perrinton, mi",
  },
  {
    postal_code: "77612",
    zip_name: "buna, tx",
  },
  {
    postal_code: "72956",
    zip_name: "van buren, ar",
  },
  {
    postal_code: "78355",
    zip_name: "falfurrias, tx",
  },
  {
    postal_code: "47167",
    zip_name: "salem, in",
  },
  {
    postal_code: "88012",
    zip_name: "las cruces, nm",
  },
  {
    postal_code: "29205",
    zip_name: "columbia, sc",
  },
  {
    postal_code: "7002",
    zip_name: "bayonne, nj",
  },
  {
    postal_code: "73632",
    zip_name: "cordell, ok",
  },
  {
    postal_code: "36541",
    zip_name: "grand bay, al",
  },
  {
    postal_code: "30535",
    zip_name: "demorest, ga",
  },
  {
    postal_code: "29160",
    zip_name: "swansea, sc",
  },
  {
    postal_code: "13472",
    zip_name: "thendara, ny",
  },
  {
    postal_code: "42048",
    zip_name: "hardin, ky",
  },
  {
    postal_code: "17238",
    zip_name: "needmore, pa",
  },
  {
    postal_code: "47119",
    zip_name: "floyds knobs, in",
  },
  {
    postal_code: "15479",
    zip_name: "smithton, pa",
  },
  {
    postal_code: "53149",
    zip_name: "mukwonago, wi",
  },
  {
    postal_code: "72396",
    zip_name: "wynne, ar",
  },
  {
    postal_code: "96792",
    zip_name: "waianae, hi",
  },
  {
    postal_code: "24091",
    zip_name: "floyd, va",
  },
  {
    postal_code: "4049",
    zip_name: "limington, me",
  },
  {
    postal_code: "39648",
    zip_name: "mccomb, ms",
  },
  {
    postal_code: "54162",
    zip_name: "pulaski, wi",
  },
  {
    postal_code: "49770",
    zip_name: "petoskey, mi",
  },
  {
    postal_code: "29860",
    zip_name: "north augusta, sc",
  },
  {
    postal_code: "78574",
    zip_name: "mission, tx",
  },
  {
    postal_code: "7034",
    zip_name: "lake hiawatha, nj",
  },
  {
    postal_code: "54209",
    zip_name: "egg harbor, wi",
  },
  {
    postal_code: "95410",
    zip_name: "albion, ca",
  },
  {
    postal_code: "7302",
    zip_name: "jersey city, nj",
  },
  {
    postal_code: "78046",
    zip_name: "laredo, tx",
  },
  {
    postal_code: "20184",
    zip_name: "upperville, va",
  },
  {
    postal_code: "27311",
    zip_name: "pelham, nc",
  },
  {
    postal_code: "16673",
    zip_name: "roaring spring, pa",
  },
  {
    postal_code: "62933",
    zip_name: "energy, il",
  },
  {
    postal_code: "23413",
    zip_name: "nassawadox, va",
  },
  {
    postal_code: "36264",
    zip_name: "heflin, al",
  },
  {
    postal_code: "7017",
    zip_name: "east orange, nj",
  },
  {
    postal_code: "19508",
    zip_name: "birdsboro, pa",
  },
  {
    postal_code: "75672",
    zip_name: "marshall, tx",
  },
  {
    postal_code: "18954",
    zip_name: "richboro, pa",
  },
  {
    postal_code: "10709",
    zip_name: "eastchester, ny",
  },
  {
    postal_code: "99827",
    zip_name: "haines, ak",
  },
  {
    postal_code: "57033",
    zip_name: "hartford, sd",
  },
  {
    postal_code: "38260",
    zip_name: "troy, tn",
  },
  {
    postal_code: "70343",
    zip_name: "bourg, la",
  },
  {
    postal_code: "71742",
    zip_name: "fordyce, ar",
  },
  {
    postal_code: "7016",
    zip_name: "cranford, nj",
  },
  {
    postal_code: "4424",
    zip_name: "danforth, me",
  },
  {
    postal_code: "18034",
    zip_name: "center valley, pa",
  },
  {
    postal_code: "72845",
    zip_name: "knoxville, ar",
  },
  {
    postal_code: "44813",
    zip_name: "bellville, oh",
  },
  {
    postal_code: "81063",
    zip_name: "ordway, co",
  },
  {
    postal_code: "95947",
    zip_name: "greenville, ca",
  },
  {
    postal_code: "8345",
    zip_name: "newport, nj",
  },
  {
    postal_code: "70645",
    zip_name: "hackberry, la",
  },
  {
    postal_code: "5760",
    zip_name: "orwell, vt",
  },
  {
    postal_code: "37406",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "71052",
    zip_name: "mansfield, la",
  },
  {
    postal_code: "46542",
    zip_name: "milford, in",
  },
  {
    postal_code: "62535",
    zip_name: "forsyth, il",
  },
  {
    postal_code: "59913",
    zip_name: "coram, mt",
  },
  {
    postal_code: "37410",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "72855",
    zip_name: "paris, ar",
  },
  {
    postal_code: "4668",
    zip_name: "princeton, me",
  },
  {
    postal_code: "7702",
    zip_name: "shrewsbury, nj",
  },
  {
    postal_code: "49743",
    zip_name: "hawks, mi",
  },
  {
    postal_code: "7021",
    zip_name: "essex fells, nj",
  },
  {
    postal_code: "7416",
    zip_name: "franklin, nj",
  },
  {
    postal_code: "24531",
    zip_name: "chatham, va",
  },
  {
    postal_code: "70354",
    zip_name: "galliano, la",
  },
  {
    postal_code: "59061",
    zip_name: "nye, mt",
  },
  {
    postal_code: "15824",
    zip_name: "brockway, pa",
  },
  {
    postal_code: "7005",
    zip_name: "boonton, nj",
  },
  {
    postal_code: "48617",
    zip_name: "clare, mi",
  },
  {
    postal_code: "36551",
    zip_name: "loxley, al",
  },
  {
    postal_code: "48893",
    zip_name: "weidman, mi",
  },
  {
    postal_code: "48609",
    zip_name: "saginaw, mi",
  },
  {
    postal_code: "35741",
    zip_name: "brownsboro, al",
  },
  {
    postal_code: "83274",
    zip_name: "shelley, id",
  },
  {
    postal_code: "67147",
    zip_name: "valley center, ks",
  },
  {
    postal_code: "48095",
    zip_name: "washington, mi",
  },
  {
    postal_code: "17267",
    zip_name: "warfordsburg, pa",
  },
  {
    postal_code: "7823",
    zip_name: "belvidere, nj",
  },
  {
    postal_code: "14514",
    zip_name: "north chili, ny",
  },
  {
    postal_code: "54656",
    zip_name: "sparta, wi",
  },
  {
    postal_code: "97064",
    zip_name: "vernonia, or",
  },
  {
    postal_code: "16371",
    zip_name: "youngsville, pa",
  },
  {
    postal_code: "48350",
    zip_name: "davisburg, mi",
  },
  {
    postal_code: "57744",
    zip_name: "hermosa, sd",
  },
  {
    postal_code: "52227",
    zip_name: "ely, ia",
  },
  {
    postal_code: "32503",
    zip_name: "pensacola, fl",
  },
  {
    postal_code: "14006",
    zip_name: "angola, ny",
  },
  {
    postal_code: "81631",
    zip_name: "eagle, co",
  },
  {
    postal_code: "21822",
    zip_name: "eden, md",
  },
  {
    postal_code: "49046",
    zip_name: "delton, mi",
  },
  {
    postal_code: "71852",
    zip_name: "nashville, ar",
  },
  {
    postal_code: "71232",
    zip_name: "delhi, la",
  },
  {
    postal_code: "19025",
    zip_name: "dresher, pa",
  },
  {
    postal_code: "89134",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "54901",
    zip_name: "oshkosh, wi",
  },
  {
    postal_code: "49234",
    zip_name: "clarklake, mi",
  },
  {
    postal_code: "49690",
    zip_name: "williamsburg, mi",
  },
  {
    postal_code: "7940",
    zip_name: "madison, nj",
  },
  {
    postal_code: "36854",
    zip_name: "valley, al",
  },
  {
    postal_code: "39476",
    zip_name: "richton, ms",
  },
  {
    postal_code: "72482",
    zip_name: "williford, ar",
  },
  {
    postal_code: "29128",
    zip_name: "rembert, sc",
  },
  {
    postal_code: "14741",
    zip_name: "great valley, ny",
  },
  {
    postal_code: "49836",
    zip_name: "germfask, mi",
  },
  {
    postal_code: "67216",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "29003",
    zip_name: "bamberg, sc",
  },
  {
    postal_code: "4941",
    zip_name: "freedom, me",
  },
  {
    postal_code: "35976",
    zip_name: "guntersville, al",
  },
  {
    postal_code: "11948",
    zip_name: "laurel, ny",
  },
  {
    postal_code: "53140",
    zip_name: "kenosha, wi",
  },
  {
    postal_code: "83401",
    zip_name: "idaho falls, id",
  },
  {
    postal_code: "36571",
    zip_name: "saraland, al",
  },
  {
    postal_code: "7045",
    zip_name: "montville, nj",
  },
  {
    postal_code: "89158",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "19106",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "7063",
    zip_name: "plainfield, nj",
  },
  {
    postal_code: "12974",
    zip_name: "port henry, ny",
  },
  {
    postal_code: "7666",
    zip_name: "teaneck, nj",
  },
  {
    postal_code: "35473",
    zip_name: "northport, al",
  },
  {
    postal_code: "82443",
    zip_name: "thermopolis, wy",
  },
  {
    postal_code: "17954",
    zip_name: "minersville, pa",
  },
  {
    postal_code: "4945",
    zip_name: "jackman, me",
  },
  {
    postal_code: "88201",
    zip_name: "roswell, nm",
  },
  {
    postal_code: "23314",
    zip_name: "carrollton, va",
  },
  {
    postal_code: "97118",
    zip_name: "garibaldi, or",
  },
  {
    postal_code: "73448",
    zip_name: "marietta, ok",
  },
  {
    postal_code: "89123",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "46721",
    zip_name: "butler, in",
  },
  {
    postal_code: "4406",
    zip_name: "abbot, me",
  },
  {
    postal_code: "19475",
    zip_name: "spring city, pa",
  },
  {
    postal_code: "48122",
    zip_name: "melvindale, mi",
  },
  {
    postal_code: "18069",
    zip_name: "orefield, pa",
  },
  {
    postal_code: "59070",
    zip_name: "roberts, mt",
  },
  {
    postal_code: "89108",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "56050",
    zip_name: "kasota, mn",
  },
  {
    postal_code: "37892",
    zip_name: "winfield, tn",
  },
  {
    postal_code: "89801",
    zip_name: "elko, nv",
  },
  {
    postal_code: "92116",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "49437",
    zip_name: "montague, mi",
  },
  {
    postal_code: "47567",
    zip_name: "petersburg, in",
  },
  {
    postal_code: "62447",
    zip_name: "neoga, il",
  },
  {
    postal_code: "7757",
    zip_name: "oceanport, nj",
  },
  {
    postal_code: "7012",
    zip_name: "clifton, nj",
  },
  {
    postal_code: "73119",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "29209",
    zip_name: "columbia, sc",
  },
  {
    postal_code: "7076",
    zip_name: "scotch plains, nj",
  },
  {
    postal_code: "89166",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "81433",
    zip_name: "silverton, co",
  },
  {
    postal_code: "19611",
    zip_name: "reading, pa",
  },
  {
    postal_code: "56578",
    zip_name: "rochert, mn",
  },
  {
    postal_code: "1368",
    zip_name: "royalston, ma",
  },
  {
    postal_code: "32514",
    zip_name: "pensacola, fl",
  },
  {
    postal_code: "56149",
    zip_name: "lake benton, mn",
  },
  {
    postal_code: "35958",
    zip_name: "bryant, al",
  },
  {
    postal_code: "26801",
    zip_name: "baker, wv",
  },
  {
    postal_code: "4649",
    zip_name: "jonesport, me",
  },
  {
    postal_code: "24083",
    zip_name: "daleville, va",
  },
  {
    postal_code: "47374",
    zip_name: "richmond, in",
  },
  {
    postal_code: "72519",
    zip_name: "calico rock, ar",
  },
  {
    postal_code: "8830",
    zip_name: "iselin, nj",
  },
  {
    postal_code: "17022",
    zip_name: "elizabethtown, pa",
  },
  {
    postal_code: "41653",
    zip_name: "prestonsburg, ky",
  },
  {
    postal_code: "32526",
    zip_name: "pensacola, fl",
  },
  {
    postal_code: "32566",
    zip_name: "navarre, fl",
  },
  {
    postal_code: "32541",
    zip_name: "destin, fl",
  },
  {
    postal_code: "82923",
    zip_name: "boulder, wy",
  },
  {
    postal_code: "27982",
    zip_name: "waves, nc",
  },
  {
    postal_code: "49621",
    zip_name: "cedar, mi",
  },
  {
    postal_code: "18218",
    zip_name: "coaldale, pa",
  },
  {
    postal_code: "7701",
    zip_name: "red bank, nj",
  },
  {
    postal_code: "70397",
    zip_name: "theriot, la",
  },
  {
    postal_code: "49827",
    zip_name: "engadine, mi",
  },
  {
    postal_code: "10472",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "98333",
    zip_name: "fox island, wa",
  },
  {
    postal_code: "17066",
    zip_name: "mount union, pa",
  },
  {
    postal_code: "10998",
    zip_name: "westtown, ny",
  },
  {
    postal_code: "20674",
    zip_name: "piney point, md",
  },
  {
    postal_code: "98648",
    zip_name: "stevenson, wa",
  },
  {
    postal_code: "95562",
    zip_name: "rio dell, ca",
  },
  {
    postal_code: "67526",
    zip_name: "ellinwood, ks",
  },
  {
    postal_code: "87418",
    zip_name: "la plata, nm",
  },
  {
    postal_code: "71956",
    zip_name: "mountain pine, ar",
  },
  {
    postal_code: "50667",
    zip_name: "raymond, ia",
  },
  {
    postal_code: "80938",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "38730",
    zip_name: "boyle, ms",
  },
  {
    postal_code: "45337",
    zip_name: "laura, oh",
  },
  {
    postal_code: "5770",
    zip_name: "shoreham, vt",
  },
  {
    postal_code: "26386",
    zip_name: "lumberport, wv",
  },
  {
    postal_code: "12064",
    zip_name: "east worcester, ny",
  },
  {
    postal_code: "42056",
    zip_name: "la center, ky",
  },
  {
    postal_code: "50240",
    zip_name: "saint charles, ia",
  },
  {
    postal_code: "52777",
    zip_name: "wheatland, ia",
  },
  {
    postal_code: "88337",
    zip_name: "la luz, nm",
  },
  {
    postal_code: "18923",
    zip_name: "fountainville, pa",
  },
  {
    postal_code: "25442",
    zip_name: "shenandoah junction, wv",
  },
  {
    postal_code: "55909",
    zip_name: "adams, mn",
  },
  {
    postal_code: "83535",
    zip_name: "juliaetta, id",
  },
  {
    postal_code: "87537",
    zip_name: "hernandez, nm",
  },
  {
    postal_code: "17228",
    zip_name: "harrisonville, pa",
  },
  {
    postal_code: "98849",
    zip_name: "riverside, wa",
  },
  {
    postal_code: "25535",
    zip_name: "lavalette, wv",
  },
  {
    postal_code: "19362",
    zip_name: "nottingham, pa",
  },
  {
    postal_code: "61078",
    zip_name: "shannon, il",
  },
  {
    postal_code: "46742",
    zip_name: "hamilton, in",
  },
  {
    postal_code: "29560",
    zip_name: "lake city, sc",
  },
  {
    postal_code: "7848",
    zip_name: "lafayette, nj",
  },
  {
    postal_code: "81201",
    zip_name: "salida, co",
  },
  {
    postal_code: "36605",
    zip_name: "mobile, al",
  },
  {
    postal_code: "4464",
    zip_name: "monson, me",
  },
  {
    postal_code: "19950",
    zip_name: "greenwood, de",
  },
  {
    postal_code: "36572",
    zip_name: "satsuma, al",
  },
  {
    postal_code: "36360",
    zip_name: "ozark, al",
  },
  {
    postal_code: "8066",
    zip_name: "paulsboro, nj",
  },
  {
    postal_code: "92075",
    zip_name: "solana beach, ca",
  },
  {
    postal_code: "5038",
    zip_name: "chelsea, vt",
  },
  {
    postal_code: "71129",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "89107",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "19504",
    zip_name: "barto, pa",
  },
  {
    postal_code: "17090",
    zip_name: "shermans dale, pa",
  },
  {
    postal_code: "4747",
    zip_name: "island falls, me",
  },
  {
    postal_code: "72476",
    zip_name: "walnut ridge, ar",
  },
  {
    postal_code: "35033",
    zip_name: "bremen, al",
  },
  {
    postal_code: "48204",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "24089",
    zip_name: "fieldale, va",
  },
  {
    postal_code: "95573",
    zip_name: "willow creek, ca",
  },
  {
    postal_code: "97820",
    zip_name: "canyon city, or",
  },
  {
    postal_code: "97423",
    zip_name: "coquille, or",
  },
  {
    postal_code: "5825",
    zip_name: "coventry, vt",
  },
  {
    postal_code: "54495",
    zip_name: "wisconsin rapids, wi",
  },
  {
    postal_code: "52208",
    zip_name: "belle plaine, ia",
  },
  {
    postal_code: "96007",
    zip_name: "anderson, ca",
  },
  {
    postal_code: "19930",
    zip_name: "bethany beach, de",
  },
  {
    postal_code: "89101",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "68803",
    zip_name: "grand island, ne",
  },
  {
    postal_code: "48214",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "7930",
    zip_name: "chester, nj",
  },
  {
    postal_code: "54149",
    zip_name: "mountain, wi",
  },
  {
    postal_code: "49616",
    zip_name: "benzonia, mi",
  },
  {
    postal_code: "13331",
    zip_name: "eagle bay, ny",
  },
  {
    postal_code: "7079",
    zip_name: "south orange, nj",
  },
  {
    postal_code: "32531",
    zip_name: "baker, fl",
  },
  {
    postal_code: "35613",
    zip_name: "athens, al",
  },
  {
    postal_code: "18037",
    zip_name: "coplay, pa",
  },
  {
    postal_code: "49649",
    zip_name: "kingsley, mi",
  },
  {
    postal_code: "12771",
    zip_name: "port jervis, ny",
  },
  {
    postal_code: "14712",
    zip_name: "bemus point, ny",
  },
  {
    postal_code: "18020",
    zip_name: "bethlehem, pa",
  },
  {
    postal_code: "17061",
    zip_name: "millersburg, pa",
  },
  {
    postal_code: "19152",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "75949",
    zip_name: "huntington, tx",
  },
  {
    postal_code: "10970",
    zip_name: "pomona, ny",
  },
  {
    postal_code: "49782",
    zip_name: "beaver island, mi",
  },
  {
    postal_code: "46825",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "68132",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "99901",
    zip_name: "ketchikan, ak",
  },
  {
    postal_code: "45663",
    zip_name: "west portsmouth, oh",
  },
  {
    postal_code: "19063",
    zip_name: "media, pa",
  },
  {
    postal_code: "58503",
    zip_name: "bismarck, nd",
  },
  {
    postal_code: "48082",
    zip_name: "saint clair shores, mi",
  },
  {
    postal_code: "87043",
    zip_name: "placitas, nm",
  },
  {
    postal_code: "67026",
    zip_name: "clearwater, ks",
  },
  {
    postal_code: "67487",
    zip_name: "wakefield, ks",
  },
  {
    postal_code: "8880",
    zip_name: "south bound brook, nj",
  },
  {
    postal_code: "37012",
    zip_name: "alexandria, tn",
  },
  {
    postal_code: "13476",
    zip_name: "vernon, ny",
  },
  {
    postal_code: "5444",
    zip_name: "cambridge, vt",
  },
  {
    postal_code: "24589",
    zip_name: "scottsburg, va",
  },
  {
    postal_code: "75954",
    zip_name: "joaquin, tx",
  },
  {
    postal_code: "35480",
    zip_name: "ralph, al",
  },
  {
    postal_code: "74901",
    zip_name: "arkoma, ok",
  },
  {
    postal_code: "36603",
    zip_name: "mobile, al",
  },
  {
    postal_code: "61924",
    zip_name: "chrisman, il",
  },
  {
    postal_code: "49679",
    zip_name: "sears, mi",
  },
  {
    postal_code: "17345",
    zip_name: "manchester, pa",
  },
  {
    postal_code: "7645",
    zip_name: "montvale, nj",
  },
  {
    postal_code: "54115",
    zip_name: "de pere, wi",
  },
  {
    postal_code: "8732",
    zip_name: "island heights, nj",
  },
  {
    postal_code: "18707",
    zip_name: "mountain top, pa",
  },
  {
    postal_code: "17921",
    zip_name: "ashland, pa",
  },
  {
    postal_code: "89117",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "47037",
    zip_name: "osgood, in",
  },
  {
    postal_code: "73120",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "48757",
    zip_name: "reese, mi",
  },
  {
    postal_code: "7704",
    zip_name: "fair haven, nj",
  },
  {
    postal_code: "53227",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "16028",
    zip_name: "east brady, pa",
  },
  {
    postal_code: "6058",
    zip_name: "norfolk, ct",
  },
  {
    postal_code: "7934",
    zip_name: "gladstone, nj",
  },
  {
    postal_code: "7640",
    zip_name: "harrington park, nj",
  },
  {
    postal_code: "7112",
    zip_name: "newark, nj",
  },
  {
    postal_code: "84725",
    zip_name: "enterprise, ut",
  },
  {
    postal_code: "12486",
    zip_name: "tillson, ny",
  },
  {
    postal_code: "4455",
    zip_name: "lee, me",
  },
  {
    postal_code: "5360",
    zip_name: "west wardsboro, vt",
  },
  {
    postal_code: "6282",
    zip_name: "woodstock valley, ct",
  },
  {
    postal_code: "62449",
    zip_name: "oblong, il",
  },
  {
    postal_code: "72958",
    zip_name: "waldron, ar",
  },
  {
    postal_code: "83626",
    zip_name: "greenleaf, id",
  },
  {
    postal_code: "28511",
    zip_name: "atlantic, nc",
  },
  {
    postal_code: "88021",
    zip_name: "anthony, nm",
  },
  {
    postal_code: "8327",
    zip_name: "leesburg, nj",
  },
  {
    postal_code: "87124",
    zip_name: "rio rancho, nm",
  },
  {
    postal_code: "29851",
    zip_name: "warrenville, sc",
  },
  {
    postal_code: "24701",
    zip_name: "bluefield, wv",
  },
  {
    postal_code: "54904",
    zip_name: "oshkosh, wi",
  },
  {
    postal_code: "35806",
    zip_name: "huntsville, al",
  },
  {
    postal_code: "31569",
    zip_name: "woodbine, ga",
  },
  {
    postal_code: "24422",
    zip_name: "clifton forge, va",
  },
  {
    postal_code: "73068",
    zip_name: "noble, ok",
  },
  {
    postal_code: "8510",
    zip_name: "millstone township, nj",
  },
  {
    postal_code: "68850",
    zip_name: "lexington, ne",
  },
  {
    postal_code: "5463",
    zip_name: "isle la motte, vt",
  },
  {
    postal_code: "14222",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "19543",
    zip_name: "morgantown, pa",
  },
  {
    postal_code: "93238",
    zip_name: "kernville, ca",
  },
  {
    postal_code: "66451",
    zip_name: "lyndon, ks",
  },
  {
    postal_code: "83278",
    zip_name: "stanley, id",
  },
  {
    postal_code: "13082",
    zip_name: "kirkville, ny",
  },
  {
    postal_code: "7092",
    zip_name: "mountainside, nj",
  },
  {
    postal_code: "7850",
    zip_name: "landing, nj",
  },
  {
    postal_code: "67443",
    zip_name: "galva, ks",
  },
  {
    postal_code: "67860",
    zip_name: "lakin, ks",
  },
  {
    postal_code: "49071",
    zip_name: "mattawan, mi",
  },
  {
    postal_code: "77660",
    zip_name: "spurger, tx",
  },
  {
    postal_code: "38827",
    zip_name: "belmont, ms",
  },
  {
    postal_code: "23966",
    zip_name: "rice, va",
  },
  {
    postal_code: "20776",
    zip_name: "harwood, md",
  },
  {
    postal_code: "93203",
    zip_name: "arvin, ca",
  },
  {
    postal_code: "14722",
    zip_name: "chautauqua, ny",
  },
  {
    postal_code: "8341",
    zip_name: "minotola, nj",
  },
  {
    postal_code: "15545",
    zip_name: "hyndman, pa",
  },
  {
    postal_code: "27326",
    zip_name: "ruffin, nc",
  },
  {
    postal_code: "4634",
    zip_name: "franklin, me",
  },
  {
    postal_code: "79766",
    zip_name: "odessa, tx",
  },
  {
    postal_code: "57301",
    zip_name: "mitchell, sd",
  },
  {
    postal_code: "97042",
    zip_name: "mulino, or",
  },
  {
    postal_code: "46172",
    zip_name: "roachdale, in",
  },
  {
    postal_code: "4255",
    zip_name: "greenwood, me",
  },
  {
    postal_code: "93263",
    zip_name: "shafter, ca",
  },
  {
    postal_code: "21053",
    zip_name: "freeland, md",
  },
  {
    postal_code: "17555",
    zip_name: "narvon, pa",
  },
  {
    postal_code: "16351",
    zip_name: "tidioute, pa",
  },
  {
    postal_code: "97041",
    zip_name: "mount hood parkdale, or",
  },
  {
    postal_code: "97836",
    zip_name: "heppner, or",
  },
  {
    postal_code: "56433",
    zip_name: "akeley, mn",
  },
  {
    postal_code: "7920",
    zip_name: "basking ridge, nj",
  },
  {
    postal_code: "87942",
    zip_name: "williamsburg, nm",
  },
  {
    postal_code: "65612",
    zip_name: "bois d arc, mo",
  },
  {
    postal_code: "41527",
    zip_name: "forest hills, ky",
  },
  {
    postal_code: "97540",
    zip_name: "talent, or",
  },
  {
    postal_code: "79526",
    zip_name: "hermleigh, tx",
  },
  {
    postal_code: "16023",
    zip_name: "cabot, pa",
  },
  {
    postal_code: "59469",
    zip_name: "raynesford, mt",
  },
  {
    postal_code: "13409",
    zip_name: "munnsville, ny",
  },
  {
    postal_code: "36276",
    zip_name: "wadley, al",
  },
  {
    postal_code: "89001",
    zip_name: "alamo, nv",
  },
  {
    postal_code: "51454",
    zip_name: "manilla, ia",
  },
  {
    postal_code: "68944",
    zip_name: "harvard, ne",
  },
  {
    postal_code: "63960",
    zip_name: "puxico, mo",
  },
  {
    postal_code: "83101",
    zip_name: "kemmerer, wy",
  },
  {
    postal_code: "97369",
    zip_name: "otter rock, or",
  },
  {
    postal_code: "72061",
    zip_name: "guy, ar",
  },
  {
    postal_code: "81067",
    zip_name: "rocky ford, co",
  },
  {
    postal_code: "87023",
    zip_name: "jarales, nm",
  },
  {
    postal_code: "62824",
    zip_name: "clay city, il",
  },
  {
    postal_code: "70373",
    zip_name: "larose, la",
  },
  {
    postal_code: "50164",
    zip_name: "menlo, ia",
  },
  {
    postal_code: "27938",
    zip_name: "gatesville, nc",
  },
  {
    postal_code: "23395",
    zip_name: "horntown, va",
  },
  {
    postal_code: "21672",
    zip_name: "toddville, md",
  },
  {
    postal_code: "61260",
    zip_name: "joy, il",
  },
  {
    postal_code: "36744",
    zip_name: "greensboro, al",
  },
  {
    postal_code: "35672",
    zip_name: "town creek, al",
  },
  {
    postal_code: "5062",
    zip_name: "reading, vt",
  },
  {
    postal_code: "24079",
    zip_name: "copper hill, va",
  },
  {
    postal_code: "62442",
    zip_name: "martinsville, il",
  },
  {
    postal_code: "59052",
    zip_name: "mc leod, mt",
  },
  {
    postal_code: "17579",
    zip_name: "strasburg, pa",
  },
  {
    postal_code: "47959",
    zip_name: "monon, in",
  },
  {
    postal_code: "64494",
    zip_name: "union star, mo",
  },
  {
    postal_code: "61564",
    zip_name: "south pekin, il",
  },
  {
    postal_code: "12955",
    zip_name: "lyon mountain, ny",
  },
  {
    postal_code: "78954",
    zip_name: "round top, tx",
  },
  {
    postal_code: "49715",
    zip_name: "brimley, mi",
  },
  {
    postal_code: "82925",
    zip_name: "cora, wy",
  },
  {
    postal_code: "23415",
    zip_name: "new church, va",
  },
  {
    postal_code: "52336",
    zip_name: "springville, ia",
  },
  {
    postal_code: "54839",
    zip_name: "grand view, wi",
  },
  {
    postal_code: "13155",
    zip_name: "south otselic, ny",
  },
  {
    postal_code: "14034",
    zip_name: "collins, ny",
  },
  {
    postal_code: "32442",
    zip_name: "grand ridge, fl",
  },
  {
    postal_code: "48209",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "56448",
    zip_name: "fifty lakes, mn",
  },
  {
    postal_code: "48015",
    zip_name: "center line, mi",
  },
  {
    postal_code: "13788",
    zip_name: "hobart, ny",
  },
  {
    postal_code: "87522",
    zip_name: "chimayo, nm",
  },
  {
    postal_code: "62288",
    zip_name: "steeleville, il",
  },
  {
    postal_code: "16342",
    zip_name: "polk, pa",
  },
  {
    postal_code: "53544",
    zip_name: "hollandale, wi",
  },
  {
    postal_code: "27507",
    zip_name: "bullock, nc",
  },
  {
    postal_code: "84033",
    zip_name: "henefer, ut",
  },
  {
    postal_code: "17364",
    zip_name: "thomasville, pa",
  },
  {
    postal_code: "14470",
    zip_name: "holley, ny",
  },
  {
    postal_code: "56744",
    zip_name: "oslo, mn",
  },
  {
    postal_code: "48616",
    zip_name: "chesaning, mi",
  },
  {
    postal_code: "13846",
    zip_name: "treadwell, ny",
  },
  {
    postal_code: "43071",
    zip_name: "saint louisville, oh",
  },
  {
    postal_code: "12565",
    zip_name: "philmont, ny",
  },
  {
    postal_code: "5158",
    zip_name: "westminster, vt",
  },
  {
    postal_code: "65626",
    zip_name: "caulfield, mo",
  },
  {
    postal_code: "61320",
    zip_name: "dalzell, il",
  },
  {
    postal_code: "72842",
    zip_name: "havana, ar",
  },
  {
    postal_code: "3464",
    zip_name: "stoddard, nh",
  },
  {
    postal_code: "18932",
    zip_name: "line lexington, pa",
  },
  {
    postal_code: "56225",
    zip_name: "clinton, mn",
  },
  {
    postal_code: "83246",
    zip_name: "lava hot springs, id",
  },
  {
    postal_code: "25506",
    zip_name: "branchland, wv",
  },
  {
    postal_code: "16917",
    zip_name: "covington, pa",
  },
  {
    postal_code: "35954",
    zip_name: "attalla, al",
  },
  {
    postal_code: "73030",
    zip_name: "davis, ok",
  },
  {
    postal_code: "15868",
    zip_name: "weedville, pa",
  },
  {
    postal_code: "2833",
    zip_name: "hopkinton, ri",
  },
  {
    postal_code: "58324",
    zip_name: "cando, nd",
  },
  {
    postal_code: "54661",
    zip_name: "trempealeau, wi",
  },
  {
    postal_code: "72390",
    zip_name: "west helena, ar",
  },
  {
    postal_code: "41572",
    zip_name: "virgie, ky",
  },
  {
    postal_code: "78118",
    zip_name: "karnes city, tx",
  },
  {
    postal_code: "19134",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "18917",
    zip_name: "dublin, pa",
  },
  {
    postal_code: "89032",
    zip_name: "north las vegas, nv",
  },
  {
    postal_code: "31606",
    zip_name: "valdosta, ga",
  },
  {
    postal_code: "24350",
    zip_name: "ivanhoe, va",
  },
  {
    postal_code: "74733",
    zip_name: "colbert, ok",
  },
  {
    postal_code: "42051",
    zip_name: "hickory, ky",
  },
  {
    postal_code: "2725",
    zip_name: "somerset, ma",
  },
  {
    postal_code: "4952",
    zip_name: "morrill, me",
  },
  {
    postal_code: "85927",
    zip_name: "greer, az",
  },
  {
    postal_code: "99185",
    zip_name: "wilbur, wa",
  },
  {
    postal_code: "67578",
    zip_name: "stafford, ks",
  },
  {
    postal_code: "46986",
    zip_name: "swayzee, in",
  },
  {
    postal_code: "71933",
    zip_name: "bonnerdale, ar",
  },
  {
    postal_code: "95007",
    zip_name: "brookdale, ca",
  },
  {
    postal_code: "61319",
    zip_name: "cornell, il",
  },
  {
    postal_code: "72611",
    zip_name: "alpena, ar",
  },
  {
    postal_code: "45672",
    zip_name: "ray, oh",
  },
  {
    postal_code: "15846",
    zip_name: "kersey, pa",
  },
  {
    postal_code: "67667",
    zip_name: "schoenchen, ks",
  },
  {
    postal_code: "27839",
    zip_name: "halifax, nc",
  },
  {
    postal_code: "5042",
    zip_name: "east ryegate, vt",
  },
  {
    postal_code: "16132",
    zip_name: "hillsville, pa",
  },
  {
    postal_code: "8014",
    zip_name: "bridgeport, nj",
  },
  {
    postal_code: "87566",
    zip_name: "ohkay owingeh, nm",
  },
  {
    postal_code: "28631",
    zip_name: "grassy creek, nc",
  },
  {
    postal_code: "79565",
    zip_name: "westbrook, tx",
  },
  {
    postal_code: "20860",
    zip_name: "sandy spring, md",
  },
  {
    postal_code: "32622",
    zip_name: "brooker, fl",
  },
  {
    postal_code: "71061",
    zip_name: "oil city, la",
  },
  {
    postal_code: "6264",
    zip_name: "scotland, ct",
  },
  {
    postal_code: "71064",
    zip_name: "plain dealing, la",
  },
  {
    postal_code: "68142",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "92662",
    zip_name: "newport beach, ca",
  },
  {
    postal_code: "17771",
    zip_name: "trout run, pa",
  },
  {
    postal_code: "74824",
    zip_name: "agra, ok",
  },
  {
    postal_code: "4435",
    zip_name: "exeter, me",
  },
  {
    postal_code: "36009",
    zip_name: "brantley, al",
  },
  {
    postal_code: "53943",
    zip_name: "loganville, wi",
  },
  {
    postal_code: "39647",
    zip_name: "mc call creek, ms",
  },
  {
    postal_code: "56311",
    zip_name: "barrett, mn",
  },
  {
    postal_code: "72447",
    zip_name: "monette, ar",
  },
  {
    postal_code: "12980",
    zip_name: "saint regis falls, ny",
  },
  {
    postal_code: "30757",
    zip_name: "wildwood, ga",
  },
  {
    postal_code: "72645",
    zip_name: "leslie, ar",
  },
  {
    postal_code: "38587",
    zip_name: "walling, tn",
  },
  {
    postal_code: "63071",
    zip_name: "richwoods, mo",
  },
  {
    postal_code: "43128",
    zip_name: "jeffersonville, oh",
  },
  {
    postal_code: "51109",
    zip_name: "sioux city, ia",
  },
  {
    postal_code: "68114",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "79901",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "51465",
    zip_name: "vail, ia",
  },
  {
    postal_code: "59441",
    zip_name: "forest grove, mt",
  },
  {
    postal_code: "67490",
    zip_name: "wilson, ks",
  },
  {
    postal_code: "35971",
    zip_name: "fyffe, al",
  },
  {
    postal_code: "52064",
    zip_name: "miles, ia",
  },
  {
    postal_code: "41010",
    zip_name: "corinth, ky",
  },
  {
    postal_code: "39631",
    zip_name: "centreville, ms",
  },
  {
    postal_code: "96825",
    zip_name: "honolulu, hi",
  },
  {
    postal_code: "35184",
    zip_name: "west blocton, al",
  },
  {
    postal_code: "81253",
    zip_name: "wetmore, co",
  },
  {
    postal_code: "6481",
    zip_name: "rockfall, ct",
  },
  {
    postal_code: "16921",
    zip_name: "gaines, pa",
  },
  {
    postal_code: "17752",
    zip_name: "montgomery, pa",
  },
  {
    postal_code: "15331",
    zip_name: "ellsworth, pa",
  },
  {
    postal_code: "37809",
    zip_name: "midway, tn",
  },
  {
    postal_code: "65332",
    zip_name: "green ridge, mo",
  },
  {
    postal_code: "18461",
    zip_name: "starlight, pa",
  },
  {
    postal_code: "63060",
    zip_name: "lonedell, mo",
  },
  {
    postal_code: "8561",
    zip_name: "windsor, nj",
  },
  {
    postal_code: "26074",
    zip_name: "west liberty, wv",
  },
  {
    postal_code: "25243",
    zip_name: "gandeeville, wv",
  },
  {
    postal_code: "16341",
    zip_name: "pleasantville, pa",
  },
  {
    postal_code: "95365",
    zip_name: "planada, ca",
  },
  {
    postal_code: "4290",
    zip_name: "peru, me",
  },
  {
    postal_code: "15340",
    zip_name: "hickory, pa",
  },
  {
    postal_code: "99023",
    zip_name: "mica, wa",
  },
  {
    postal_code: "81426",
    zip_name: "ophir, co",
  },
  {
    postal_code: "14836",
    zip_name: "dalton, ny",
  },
  {
    postal_code: "55751",
    zip_name: "iron, mn",
  },
  {
    postal_code: "20626",
    zip_name: "coltons point, md",
  },
  {
    postal_code: "8350",
    zip_name: "richland, nj",
  },
  {
    postal_code: "32577",
    zip_name: "molino, fl",
  },
  {
    postal_code: "61447",
    zip_name: "kirkwood, il",
  },
  {
    postal_code: "37019",
    zip_name: "belfast, tn",
  },
  {
    postal_code: "16224",
    zip_name: "fairmount city, pa",
  },
  {
    postal_code: "2763",
    zip_name: "attleboro falls, ma",
  },
  {
    postal_code: "53587",
    zip_name: "south wayne, wi",
  },
  {
    postal_code: "76251",
    zip_name: "montague, tx",
  },
  {
    postal_code: "97019",
    zip_name: "corbett, or",
  },
  {
    postal_code: "54466",
    zip_name: "pittsville, wi",
  },
  {
    postal_code: "16735",
    zip_name: "kane, pa",
  },
  {
    postal_code: "82433",
    zip_name: "meeteetse, wy",
  },
  {
    postal_code: "98858",
    zip_name: "waterville, wa",
  },
  {
    postal_code: "37360",
    zip_name: "normandy, tn",
  },
  {
    postal_code: "54986",
    zip_name: "winneconne, wi",
  },
  {
    postal_code: "87514",
    zip_name: "arroyo seco, nm",
  },
  {
    postal_code: "8032",
    zip_name: "grenloch, nj",
  },
  {
    postal_code: "12409",
    zip_name: "bearsville, ny",
  },
  {
    postal_code: "16410",
    zip_name: "cranesville, pa",
  },
  {
    postal_code: "71032",
    zip_name: "grand cane, la",
  },
  {
    postal_code: "81019",
    zip_name: "colorado city, co",
  },
  {
    postal_code: "41557",
    zip_name: "raccoon, ky",
  },
  {
    postal_code: "42450",
    zip_name: "providence, ky",
  },
  {
    postal_code: "83239",
    zip_name: "georgetown, id",
  },
  {
    postal_code: "6023",
    zip_name: "east berlin, ct",
  },
  {
    postal_code: "43457",
    zip_name: "risingsun, oh",
  },
  {
    postal_code: "48218",
    zip_name: "river rouge, mi",
  },
  {
    postal_code: "54970",
    zip_name: "redgranite, wi",
  },
  {
    postal_code: "19944",
    zip_name: "fenwick island, de",
  },
  {
    postal_code: "68666",
    zip_name: "stromsburg, ne",
  },
  {
    postal_code: "76849",
    zip_name: "junction, tx",
  },
  {
    postal_code: "51040",
    zip_name: "onawa, ia",
  },
  {
    postal_code: "5045",
    zip_name: "fairlee, vt",
  },
  {
    postal_code: "96050",
    zip_name: "klamath river, ca",
  },
  {
    postal_code: "19104",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "72101",
    zip_name: "mc crory, ar",
  },
  {
    postal_code: "87821",
    zip_name: "datil, nm",
  },
  {
    postal_code: "39422",
    zip_name: "bay springs, ms",
  },
  {
    postal_code: "91978",
    zip_name: "spring valley, ca",
  },
  {
    postal_code: "75691",
    zip_name: "tatum, tx",
  },
  {
    postal_code: "25520",
    zip_name: "glenwood, wv",
  },
  {
    postal_code: "64739",
    zip_name: "creighton, mo",
  },
  {
    postal_code: "8562",
    zip_name: "wrightstown, nj",
  },
  {
    postal_code: "68118",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "59001",
    zip_name: "absarokee, mt",
  },
  {
    postal_code: "78055",
    zip_name: "medina, tx",
  },
  {
    postal_code: "27864",
    zip_name: "pinetops, nc",
  },
  {
    postal_code: "54895",
    zip_name: "weyerhaeuser, wi",
  },
  {
    postal_code: "19031",
    zip_name: "flourtown, pa",
  },
  {
    postal_code: "61465",
    zip_name: "new windsor, il",
  },
  {
    postal_code: "16156",
    zip_name: "volant, pa",
  },
  {
    postal_code: "54119",
    zip_name: "dunbar, wi",
  },
  {
    postal_code: "66440",
    zip_name: "hoyt, ks",
  },
  {
    postal_code: "74051",
    zip_name: "ochelata, ok",
  },
  {
    postal_code: "14423",
    zip_name: "caledonia, ny",
  },
  {
    postal_code: "58576",
    zip_name: "underwood, nd",
  },
  {
    postal_code: "54756",
    zip_name: "nelson, wi",
  },
  {
    postal_code: "62466",
    zip_name: "sumner, il",
  },
  {
    postal_code: "50672",
    zip_name: "steamboat rock, ia",
  },
  {
    postal_code: "8105",
    zip_name: "camden, nj",
  },
  {
    postal_code: "35444",
    zip_name: "brookwood, al",
  },
  {
    postal_code: "55030",
    zip_name: "grasston, mn",
  },
  {
    postal_code: "62021",
    zip_name: "dorsey, il",
  },
  {
    postal_code: "62814",
    zip_name: "bluford, il",
  },
  {
    postal_code: "46301",
    zip_name: "beverly shores, in",
  },
  {
    postal_code: "83553",
    zip_name: "weippe, id",
  },
  {
    postal_code: "22972",
    zip_name: "somerset, va",
  },
  {
    postal_code: "19539",
    zip_name: "mertztown, pa",
  },
  {
    postal_code: "12491",
    zip_name: "west hurley, ny",
  },
  {
    postal_code: "30582",
    zip_name: "young harris, ga",
  },
  {
    postal_code: "47501",
    zip_name: "washington, in",
  },
  {
    postal_code: "19027",
    zip_name: "elkins park, pa",
  },
  {
    postal_code: "19333",
    zip_name: "devon, pa",
  },
  {
    postal_code: "23322",
    zip_name: "chesapeake, va",
  },
  {
    postal_code: "66609",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "56156",
    zip_name: "luverne, mn",
  },
  {
    postal_code: "46835",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "53045",
    zip_name: "brookfield, wi",
  },
  {
    postal_code: "30738",
    zip_name: "rising fawn, ga",
  },
  {
    postal_code: "49729",
    zip_name: "ellsworth, mi",
  },
  {
    postal_code: "96035",
    zip_name: "gerber, ca",
  },
  {
    postal_code: "15931",
    zip_name: "ebensburg, pa",
  },
  {
    postal_code: "65074",
    zip_name: "russellville, mo",
  },
  {
    postal_code: "18237",
    zip_name: "mcadoo, pa",
  },
  {
    postal_code: "54173",
    zip_name: "suamico, wi",
  },
  {
    postal_code: "59069",
    zip_name: "reed point, mt",
  },
  {
    postal_code: "21723",
    zip_name: "cooksville, md",
  },
  {
    postal_code: "85932",
    zip_name: "nutrioso, az",
  },
  {
    postal_code: "50461",
    zip_name: "osage, ia",
  },
  {
    postal_code: "54126",
    zip_name: "greenleaf, wi",
  },
  {
    postal_code: "80436",
    zip_name: "dumont, co",
  },
  {
    postal_code: "4924",
    zip_name: "canaan, me",
  },
  {
    postal_code: "20861",
    zip_name: "ashton, md",
  },
  {
    postal_code: "72738",
    zip_name: "hindsville, ar",
  },
  {
    postal_code: "56017",
    zip_name: "cleveland, mn",
  },
  {
    postal_code: "49072",
    zip_name: "mendon, mi",
  },
  {
    postal_code: "88008",
    zip_name: "santa teresa, nm",
  },
  {
    postal_code: "24712",
    zip_name: "athens, wv",
  },
  {
    postal_code: "28396",
    zip_name: "wagram, nc",
  },
  {
    postal_code: "48049",
    zip_name: "north street, mi",
  },
  {
    postal_code: "46809",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "97497",
    zip_name: "wolf creek, or",
  },
  {
    postal_code: "21160",
    zip_name: "whiteford, md",
  },
  {
    postal_code: "63621",
    zip_name: "arcadia, mo",
  },
  {
    postal_code: "53098",
    zip_name: "watertown, wi",
  },
  {
    postal_code: "45616",
    zip_name: "blue creek, oh",
  },
  {
    postal_code: "83871",
    zip_name: "troy, id",
  },
  {
    postal_code: "73669",
    zip_name: "thomas, ok",
  },
  {
    postal_code: "17516",
    zip_name: "conestoga, pa",
  },
  {
    postal_code: "78160",
    zip_name: "stockdale, tx",
  },
  {
    postal_code: "10044",
    zip_name: "new york, ny",
  },
  {
    postal_code: "3227",
    zip_name: "center sandwich, nh",
  },
  {
    postal_code: "31543",
    zip_name: "hortense, ga",
  },
  {
    postal_code: "68788",
    zip_name: "west point, ne",
  },
  {
    postal_code: "48051",
    zip_name: "new baltimore, mi",
  },
  {
    postal_code: "4450",
    zip_name: "kenduskeag, me",
  },
  {
    postal_code: "18224",
    zip_name: "freeland, pa",
  },
  {
    postal_code: "17857",
    zip_name: "northumberland, pa",
  },
  {
    postal_code: "26040",
    zip_name: "mcmechen, wv",
  },
  {
    postal_code: "35233",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "61369",
    zip_name: "toluca, il",
  },
  {
    postal_code: "5350",
    zip_name: "readsboro, vt",
  },
  {
    postal_code: "33834",
    zip_name: "bowling green, fl",
  },
  {
    postal_code: "18417",
    zip_name: "equinunk, pa",
  },
  {
    postal_code: "68840",
    zip_name: "gibbon, ne",
  },
  {
    postal_code: "36869",
    zip_name: "phenix city, al",
  },
  {
    postal_code: "73162",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "84713",
    zip_name: "beaver, ut",
  },
  {
    postal_code: "12421",
    zip_name: "denver, ny",
  },
  {
    postal_code: "12860",
    zip_name: "pottersville, ny",
  },
  {
    postal_code: "48503",
    zip_name: "flint, mi",
  },
  {
    postal_code: "18040",
    zip_name: "easton, pa",
  },
  {
    postal_code: "96712",
    zip_name: "haleiwa, hi",
  },
  {
    postal_code: "54655",
    zip_name: "soldiers grove, wi",
  },
  {
    postal_code: "67208",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "10580",
    zip_name: "rye, ny",
  },
  {
    postal_code: "19087",
    zip_name: "wayne, pa",
  },
  {
    postal_code: "18940",
    zip_name: "newtown, pa",
  },
  {
    postal_code: "62546",
    zip_name: "morrisonville, il",
  },
  {
    postal_code: "25315",
    zip_name: "charleston, wv",
  },
  {
    postal_code: "48705",
    zip_name: "barton city, mi",
  },
  {
    postal_code: "49650",
    zip_name: "lake ann, mi",
  },
  {
    postal_code: "22749",
    zip_name: "woodville, va",
  },
  {
    postal_code: "54128",
    zip_name: "gresham, wi",
  },
  {
    postal_code: "81020",
    zip_name: "aguilar, co",
  },
  {
    postal_code: "36350",
    zip_name: "midland city, al",
  },
  {
    postal_code: "96826",
    zip_name: "honolulu, hi",
  },
  {
    postal_code: "2557",
    zip_name: "oak bluffs, ma",
  },
  {
    postal_code: "7726",
    zip_name: "englishtown, nj",
  },
  {
    postal_code: "8007",
    zip_name: "barrington, nj",
  },
  {
    postal_code: "95629",
    zip_name: "fiddletown, ca",
  },
  {
    postal_code: "7050",
    zip_name: "orange, nj",
  },
  {
    postal_code: "48230",
    zip_name: "grosse pointe, mi",
  },
  {
    postal_code: "49637",
    zip_name: "grawn, mi",
  },
  {
    postal_code: "49968",
    zip_name: "wakefield, mi",
  },
  {
    postal_code: "89113",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "79830",
    zip_name: "alpine, tx",
  },
  {
    postal_code: "23669",
    zip_name: "hampton, va",
  },
  {
    postal_code: "30830",
    zip_name: "waynesboro, ga",
  },
  {
    postal_code: "26763",
    zip_name: "springfield, wv",
  },
  {
    postal_code: "72556",
    zip_name: "melbourne, ar",
  },
  {
    postal_code: "18051",
    zip_name: "fogelsville, pa",
  },
  {
    postal_code: "17047",
    zip_name: "loysville, pa",
  },
  {
    postal_code: "4928",
    zip_name: "corinna, me",
  },
  {
    postal_code: "62858",
    zip_name: "louisville, il",
  },
  {
    postal_code: "44804",
    zip_name: "arcadia, oh",
  },
  {
    postal_code: "89110",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "17003",
    zip_name: "annville, pa",
  },
  {
    postal_code: "30720",
    zip_name: "dalton, ga",
  },
  {
    postal_code: "99705",
    zip_name: "north pole, ak",
  },
  {
    postal_code: "19118",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "36608",
    zip_name: "mobile, al",
  },
  {
    postal_code: "32413",
    zip_name: "panama city beach, fl",
  },
  {
    postal_code: "87935",
    zip_name: "elephant butte, nm",
  },
  {
    postal_code: "35983",
    zip_name: "leesburg, al",
  },
  {
    postal_code: "83821",
    zip_name: "coolin, id",
  },
  {
    postal_code: "29072",
    zip_name: "lexington, sc",
  },
  {
    postal_code: "46814",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "67357",
    zip_name: "parsons, ks",
  },
  {
    postal_code: "25880",
    zip_name: "mount hope, wv",
  },
  {
    postal_code: "87068",
    zip_name: "bosque farms, nm",
  },
  {
    postal_code: "24963",
    zip_name: "peterstown, wv",
  },
  {
    postal_code: "24124",
    zip_name: "narrows, va",
  },
  {
    postal_code: "14605",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "8311",
    zip_name: "cedarville, nj",
  },
  {
    postal_code: "73443",
    zip_name: "lone grove, ok",
  },
  {
    postal_code: "35661",
    zip_name: "muscle shoals, al",
  },
  {
    postal_code: "19602",
    zip_name: "reading, pa",
  },
  {
    postal_code: "4352",
    zip_name: "mount vernon, me",
  },
  {
    postal_code: "49728",
    zip_name: "eckerman, mi",
  },
  {
    postal_code: "19060",
    zip_name: "garnet valley, pa",
  },
  {
    postal_code: "7675",
    zip_name: "westwood, nj",
  },
  {
    postal_code: "7857",
    zip_name: "netcong, nj",
  },
  {
    postal_code: "73858",
    zip_name: "shattuck, ok",
  },
  {
    postal_code: "46979",
    zip_name: "russiaville, in",
  },
  {
    postal_code: "46940",
    zip_name: "la fontaine, in",
  },
  {
    postal_code: "54216",
    zip_name: "kewaunee, wi",
  },
  {
    postal_code: "24133",
    zip_name: "patrick springs, va",
  },
  {
    postal_code: "47648",
    zip_name: "fort branch, in",
  },
  {
    postal_code: "98859",
    zip_name: "wauconda, wa",
  },
  {
    postal_code: "57601",
    zip_name: "mobridge, sd",
  },
  {
    postal_code: "85611",
    zip_name: "elgin, az",
  },
  {
    postal_code: "13033",
    zip_name: "cato, ny",
  },
  {
    postal_code: "43720",
    zip_name: "blue rock, oh",
  },
  {
    postal_code: "37762",
    zip_name: "jellico, tn",
  },
  {
    postal_code: "37723",
    zip_name: "crab orchard, tn",
  },
  {
    postal_code: "28135",
    zip_name: "polkton, nc",
  },
  {
    postal_code: "5443",
    zip_name: "bristol, vt",
  },
  {
    postal_code: "4666",
    zip_name: "pembroke, me",
  },
  {
    postal_code: "59088",
    zip_name: "worden, mt",
  },
  {
    postal_code: "17832",
    zip_name: "marion heights, pa",
  },
  {
    postal_code: "21677",
    zip_name: "woolford, md",
  },
  {
    postal_code: "7405",
    zip_name: "butler, nj",
  },
  {
    postal_code: "75652",
    zip_name: "henderson, tx",
  },
  {
    postal_code: "62615",
    zip_name: "auburn, il",
  },
  {
    postal_code: "14555",
    zip_name: "sodus point, ny",
  },
  {
    postal_code: "96762",
    zip_name: "laie, hi",
  },
  {
    postal_code: "48079",
    zip_name: "saint clair, mi",
  },
  {
    postal_code: "54303",
    zip_name: "green bay, wi",
  },
  {
    postal_code: "97623",
    zip_name: "bonanza, or",
  },
  {
    postal_code: "17102",
    zip_name: "harrisburg, pa",
  },
  {
    postal_code: "35503",
    zip_name: "jasper, al",
  },
  {
    postal_code: "71945",
    zip_name: "hatfield, ar",
  },
  {
    postal_code: "29146",
    zip_name: "springfield, sc",
  },
  {
    postal_code: "79079",
    zip_name: "shamrock, tx",
  },
  {
    postal_code: "71635",
    zip_name: "crossett, ar",
  },
  {
    postal_code: "72404",
    zip_name: "jonesboro, ar",
  },
  {
    postal_code: "46637",
    zip_name: "south bend, in",
  },
  {
    postal_code: "98562",
    zip_name: "moclips, wa",
  },
  {
    postal_code: "72802",
    zip_name: "russellville, ar",
  },
  {
    postal_code: "65051",
    zip_name: "linn, mo",
  },
  {
    postal_code: "33848",
    zip_name: "intercession city, fl",
  },
  {
    postal_code: "74883",
    zip_name: "wetumka, ok",
  },
  {
    postal_code: "62418",
    zip_name: "brownstown, il",
  },
  {
    postal_code: "42078",
    zip_name: "salem, ky",
  },
  {
    postal_code: "24951",
    zip_name: "lindside, wv",
  },
  {
    postal_code: "22627",
    zip_name: "flint hill, va",
  },
  {
    postal_code: "56569",
    zip_name: "ogema, mn",
  },
  {
    postal_code: "58779",
    zip_name: "ryder, nd",
  },
  {
    postal_code: "52540",
    zip_name: "brighton, ia",
  },
  {
    postal_code: "45317",
    zip_name: "conover, oh",
  },
  {
    postal_code: "55954",
    zip_name: "mabel, mn",
  },
  {
    postal_code: "27559",
    zip_name: "moncure, nc",
  },
  {
    postal_code: "75668",
    zip_name: "lone star, tx",
  },
  {
    postal_code: "46731",
    zip_name: "craigville, in",
  },
  {
    postal_code: "64874",
    zip_name: "wheaton, mo",
  },
  {
    postal_code: "14476",
    zip_name: "kendall, ny",
  },
  {
    postal_code: "50480",
    zip_name: "titonka, ia",
  },
  {
    postal_code: "58763",
    zip_name: "new town, nd",
  },
  {
    postal_code: "47708",
    zip_name: "evansville, in",
  },
  {
    postal_code: "16052",
    zip_name: "prospect, pa",
  },
  {
    postal_code: "19453",
    zip_name: "mont clare, pa",
  },
  {
    postal_code: "39572",
    zip_name: "pearlington, ms",
  },
  {
    postal_code: "37828",
    zip_name: "norris, tn",
  },
  {
    postal_code: "67118",
    zip_name: "norwich, ks",
  },
  {
    postal_code: "63769",
    zip_name: "oak ridge, mo",
  },
  {
    postal_code: "43771",
    zip_name: "philo, oh",
  },
  {
    postal_code: "68064",
    zip_name: "valley, ne",
  },
  {
    postal_code: "48655",
    zip_name: "saint charles, mi",
  },
  {
    postal_code: "7462",
    zip_name: "vernon, nj",
  },
  {
    postal_code: "71334",
    zip_name: "ferriday, la",
  },
  {
    postal_code: "44115",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "95521",
    zip_name: "arcata, ca",
  },
  {
    postal_code: "7073",
    zip_name: "east rutherford, nj",
  },
  {
    postal_code: "54821",
    zip_name: "cable, wi",
  },
  {
    postal_code: "98650",
    zip_name: "trout lake, wa",
  },
  {
    postal_code: "84628",
    zip_name: "eureka, ut",
  },
  {
    postal_code: "4612",
    zip_name: "bernard, me",
  },
  {
    postal_code: "4448",
    zip_name: "howland, me",
  },
  {
    postal_code: "8328",
    zip_name: "malaga, nj",
  },
  {
    postal_code: "8020",
    zip_name: "clarksboro, nj",
  },
  {
    postal_code: "7008",
    zip_name: "carteret, nj",
  },
  {
    postal_code: "7901",
    zip_name: "summit, nj",
  },
  {
    postal_code: "75973",
    zip_name: "shelbyville, tx",
  },
  {
    postal_code: "61486",
    zip_name: "viola, il",
  },
  {
    postal_code: "17053",
    zip_name: "marysville, pa",
  },
  {
    postal_code: "47523",
    zip_name: "dale, in",
  },
  {
    postal_code: "38601",
    zip_name: "abbeville, ms",
  },
  {
    postal_code: "13335",
    zip_name: "edmeston, ny",
  },
  {
    postal_code: "6607",
    zip_name: "bridgeport, ct",
  },
  {
    postal_code: "73573",
    zip_name: "waurika, ok",
  },
  {
    postal_code: "21875",
    zip_name: "delmar, md",
  },
  {
    postal_code: "54865",
    zip_name: "port wing, wi",
  },
  {
    postal_code: "46923",
    zip_name: "delphi, in",
  },
  {
    postal_code: "48414",
    zip_name: "bancroft, mi",
  },
  {
    postal_code: "75705",
    zip_name: "tyler, tx",
  },
  {
    postal_code: "54498",
    zip_name: "withee, wi",
  },
  {
    postal_code: "40104",
    zip_name: "battletown, ky",
  },
  {
    postal_code: "36451",
    zip_name: "grove hill, al",
  },
  {
    postal_code: "63561",
    zip_name: "queen city, mo",
  },
  {
    postal_code: "27341",
    zip_name: "seagrove, nc",
  },
  {
    postal_code: "47260",
    zip_name: "medora, in",
  },
  {
    postal_code: "47838",
    zip_name: "carlisle, in",
  },
  {
    postal_code: "5824",
    zip_name: "concord, vt",
  },
  {
    postal_code: "52639",
    zip_name: "montrose, ia",
  },
  {
    postal_code: "53930",
    zip_name: "endeavor, wi",
  },
  {
    postal_code: "29729",
    zip_name: "richburg, sc",
  },
  {
    postal_code: "74072",
    zip_name: "s coffeyville, ok",
  },
  {
    postal_code: "4001",
    zip_name: "acton, me",
  },
  {
    postal_code: "75475",
    zip_name: "randolph, tx",
  },
  {
    postal_code: "55918",
    zip_name: "brownsdale, mn",
  },
  {
    postal_code: "24069",
    zip_name: "cascade, va",
  },
  {
    postal_code: "67454",
    zip_name: "kanopolis, ks",
  },
  {
    postal_code: "4786",
    zip_name: "washburn, me",
  },
  {
    postal_code: "68137",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "49327",
    zip_name: "grant, mi",
  },
  {
    postal_code: "92010",
    zip_name: "carlsbad, ca",
  },
  {
    postal_code: "62401",
    zip_name: "effingham, il",
  },
  {
    postal_code: "99556",
    zip_name: "anchor point, ak",
  },
  {
    postal_code: "7436",
    zip_name: "oakland, nj",
  },
  {
    postal_code: "53185",
    zip_name: "waterford, wi",
  },
  {
    postal_code: "68352",
    zip_name: "fairbury, ne",
  },
  {
    postal_code: "8609",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "35083",
    zip_name: "holly pond, al",
  },
  {
    postal_code: "10514",
    zip_name: "chappaqua, ny",
  },
  {
    postal_code: "7004",
    zip_name: "fairfield, nj",
  },
  {
    postal_code: "53228",
    zip_name: "milwaukee, wi",
  },
  {
    postal_code: "49067",
    zip_name: "marcellus, mi",
  },
  {
    postal_code: "88007",
    zip_name: "las cruces, nm",
  },
  {
    postal_code: "49014",
    zip_name: "battle creek, mi",
  },
  {
    postal_code: "7876",
    zip_name: "succasunna, nj",
  },
  {
    postal_code: "89118",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "4257",
    zip_name: "mexico, me",
  },
  {
    postal_code: "76301",
    zip_name: "wichita falls, tx",
  },
  {
    postal_code: "56633",
    zip_name: "cass lake, mn",
  },
  {
    postal_code: "48632",
    zip_name: "lake, mi",
  },
  {
    postal_code: "57201",
    zip_name: "watertown, sd",
  },
  {
    postal_code: "7105",
    zip_name: "newark, nj",
  },
  {
    postal_code: "73071",
    zip_name: "norman, ok",
  },
  {
    postal_code: "8750",
    zip_name: "sea girt, nj",
  },
  {
    postal_code: "64076",
    zip_name: "odessa, mo",
  },
  {
    postal_code: "54217",
    zip_name: "luxemburg, wi",
  },
  {
    postal_code: "73159",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "83628",
    zip_name: "homedale, id",
  },
  {
    postal_code: "2535",
    zip_name: "chilmark, ma",
  },
  {
    postal_code: "77046",
    zip_name: "houston, tx",
  },
  {
    postal_code: "48341",
    zip_name: "pontiac, mi",
  },
  {
    postal_code: "8004",
    zip_name: "atco, nj",
  },
  {
    postal_code: "14467",
    zip_name: "henrietta, ny",
  },
  {
    postal_code: "30901",
    zip_name: "augusta, ga",
  },
  {
    postal_code: "49668",
    zip_name: "mesick, mi",
  },
  {
    postal_code: "48883",
    zip_name: "shepherd, mi",
  },
  {
    postal_code: "10707",
    zip_name: "tuckahoe, ny",
  },
  {
    postal_code: "77702",
    zip_name: "beaumont, tx",
  },
  {
    postal_code: "56520",
    zip_name: "breckenridge, mn",
  },
  {
    postal_code: "87510",
    zip_name: "abiquiu, nm",
  },
  {
    postal_code: "27897",
    zip_name: "woodland, nc",
  },
  {
    postal_code: "23456",
    zip_name: "virginia beach, va",
  },
  {
    postal_code: "48301",
    zip_name: "bloomfield hills, mi",
  },
  {
    postal_code: "36442",
    zip_name: "florala, al",
  },
  {
    postal_code: "56549",
    zip_name: "hawley, mn",
  },
  {
    postal_code: "54136",
    zip_name: "kimberly, wi",
  },
  {
    postal_code: "58102",
    zip_name: "fargo, nd",
  },
  {
    postal_code: "18641",
    zip_name: "pittston, pa",
  },
  {
    postal_code: "12124",
    zip_name: "new baltimore, ny",
  },
  {
    postal_code: "76454",
    zip_name: "gorman, tx",
  },
  {
    postal_code: "95459",
    zip_name: "manchester, ca",
  },
  {
    postal_code: "1330",
    zip_name: "ashfield, ma",
  },
  {
    postal_code: "78375",
    zip_name: "premont, tx",
  },
  {
    postal_code: "14532",
    zip_name: "phelps, ny",
  },
  {
    postal_code: "36323",
    zip_name: "elba, al",
  },
  {
    postal_code: "46725",
    zip_name: "columbia city, in",
  },
  {
    postal_code: "48847",
    zip_name: "ithaca, mi",
  },
  {
    postal_code: "83805",
    zip_name: "bonners ferry, id",
  },
  {
    postal_code: "35010",
    zip_name: "alexander city, al",
  },
  {
    postal_code: "71202",
    zip_name: "monroe, la",
  },
  {
    postal_code: "39652",
    zip_name: "magnolia, ms",
  },
  {
    postal_code: "4861",
    zip_name: "thomaston, me",
  },
  {
    postal_code: "26810",
    zip_name: "lost city, wv",
  },
  {
    postal_code: "49670",
    zip_name: "northport, mi",
  },
  {
    postal_code: "73118",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "72142",
    zip_name: "scott, ar",
  },
  {
    postal_code: "62640",
    zip_name: "girard, il",
  },
  {
    postal_code: "37408",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "24084",
    zip_name: "dublin, va",
  },
  {
    postal_code: "72576",
    zip_name: "salem, ar",
  },
  {
    postal_code: "71201",
    zip_name: "monroe, la",
  },
  {
    postal_code: "7869",
    zip_name: "randolph, nj",
  },
  {
    postal_code: "73026",
    zip_name: "norman, ok",
  },
  {
    postal_code: "83204",
    zip_name: "pocatello, id",
  },
  {
    postal_code: "48091",
    zip_name: "warren, mi",
  },
  {
    postal_code: "7607",
    zip_name: "maywood, nj",
  },
  {
    postal_code: "17032",
    zip_name: "halifax, pa",
  },
  {
    postal_code: "83467",
    zip_name: "salmon, id",
  },
  {
    postal_code: "19074",
    zip_name: "norwood, pa",
  },
  {
    postal_code: "49838",
    zip_name: "gould city, mi",
  },
  {
    postal_code: "94528",
    zip_name: "diablo, ca",
  },
  {
    postal_code: "99139",
    zip_name: "ione, wa",
  },
  {
    postal_code: "19951",
    zip_name: "harbeson, de",
  },
  {
    postal_code: "24549",
    zip_name: "dry fork, va",
  },
  {
    postal_code: "50536",
    zip_name: "emmetsburg, ia",
  },
  {
    postal_code: "14522",
    zip_name: "palmyra, ny",
  },
  {
    postal_code: "1080",
    zip_name: "three rivers, ma",
  },
  {
    postal_code: "13656",
    zip_name: "la fargeville, ny",
  },
  {
    postal_code: "56068",
    zip_name: "minnesota lake, mn",
  },
  {
    postal_code: "55710",
    zip_name: "britt, mn",
  },
  {
    postal_code: "7927",
    zip_name: "cedar knolls, nj",
  },
  {
    postal_code: "67003",
    zip_name: "anthony, ks",
  },
  {
    postal_code: "17098",
    zip_name: "williamstown, pa",
  },
  {
    postal_code: "65462",
    zip_name: "edgar springs, mo",
  },
  {
    postal_code: "67137",
    zip_name: "severy, ks",
  },
  {
    postal_code: "41539",
    zip_name: "kimper, ky",
  },
  {
    postal_code: "89825",
    zip_name: "jackpot, nv",
  },
  {
    postal_code: "54512",
    zip_name: "boulder junction, wi",
  },
  {
    postal_code: "21918",
    zip_name: "conowingo, md",
  },
  {
    postal_code: "5647",
    zip_name: "cabot, vt",
  },
  {
    postal_code: "50543",
    zip_name: "gowrie, ia",
  },
  {
    postal_code: "65754",
    zip_name: "spokane, mo",
  },
  {
    postal_code: "40371",
    zip_name: "salt lick, ky",
  },
  {
    postal_code: "7933",
    zip_name: "gillette, nj",
  },
  {
    postal_code: "4554",
    zip_name: "new harbor, me",
  },
  {
    postal_code: "20617",
    zip_name: "bryantown, md",
  },
  {
    postal_code: "1342",
    zip_name: "deerfield, ma",
  },
  {
    postal_code: "49958",
    zip_name: "pelkie, mi",
  },
  {
    postal_code: "38220",
    zip_name: "atwood, tn",
  },
  {
    postal_code: "32343",
    zip_name: "midway, fl",
  },
  {
    postal_code: "42740",
    zip_name: "glendale, ky",
  },
  {
    postal_code: "61749",
    zip_name: "kenney, il",
  },
  {
    postal_code: "12068",
    zip_name: "fonda, ny",
  },
  {
    postal_code: "86022",
    zip_name: "fredonia, az",
  },
  {
    postal_code: "95724",
    zip_name: "norden, ca",
  },
  {
    postal_code: "14807",
    zip_name: "arkport, ny",
  },
  {
    postal_code: "14141",
    zip_name: "springville, ny",
  },
  {
    postal_code: "54515",
    zip_name: "catawba, wi",
  },
  {
    postal_code: "85939",
    zip_name: "taylor, az",
  },
  {
    postal_code: "68029",
    zip_name: "herman, ne",
  },
  {
    postal_code: "57058",
    zip_name: "salem, sd",
  },
  {
    postal_code: "61031",
    zip_name: "franklin grove, il",
  },
  {
    postal_code: "64444",
    zip_name: "edgerton, mo",
  },
  {
    postal_code: "68124",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "27923",
    zip_name: "coinjock, nc",
  },
  {
    postal_code: "96710",
    zip_name: "hakalau, hi",
  },
  {
    postal_code: "59057",
    zip_name: "molt, mt",
  },
  {
    postal_code: "16049",
    zip_name: "parker, pa",
  },
  {
    postal_code: "73160",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "71119",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "22576",
    zip_name: "weems, va",
  },
  {
    postal_code: "73170",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "31030",
    zip_name: "fort valley, ga",
  },
  {
    postal_code: "67020",
    zip_name: "burrton, ks",
  },
  {
    postal_code: "62712",
    zip_name: "springfield, il",
  },
  {
    postal_code: "18812",
    zip_name: "brackney, pa",
  },
  {
    postal_code: "12932",
    zip_name: "elizabethtown, ny",
  },
  {
    postal_code: "46816",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "69147",
    zip_name: "lewellen, ne",
  },
  {
    postal_code: "97022",
    zip_name: "eagle creek, or",
  },
  {
    postal_code: "46795",
    zip_name: "wolcottville, in",
  },
  {
    postal_code: "7052",
    zip_name: "west orange, nj",
  },
  {
    postal_code: "92091",
    zip_name: "rancho santa fe, ca",
  },
  {
    postal_code: "36528",
    zip_name: "dauphin island, al",
  },
  {
    postal_code: "7740",
    zip_name: "long branch, nj",
  },
  {
    postal_code: "50701",
    zip_name: "waterloo, ia",
  },
  {
    postal_code: "54667",
    zip_name: "westby, wi",
  },
  {
    postal_code: "83406",
    zip_name: "idaho falls, id",
  },
  {
    postal_code: "37026",
    zip_name: "bradyville, tn",
  },
  {
    postal_code: "5903",
    zip_name: "canaan, vt",
  },
  {
    postal_code: "73080",
    zip_name: "purcell, ok",
  },
  {
    postal_code: "12436",
    zip_name: "haines falls, ny",
  },
  {
    postal_code: "15333",
    zip_name: "fredericktown, pa",
  },
  {
    postal_code: "70657",
    zip_name: "ragley, la",
  },
  {
    postal_code: "74871",
    zip_name: "stonewall, ok",
  },
  {
    postal_code: "4449",
    zip_name: "hudson, me",
  },
  {
    postal_code: "36319",
    zip_name: "columbia, al",
  },
  {
    postal_code: "28636",
    zip_name: "hiddenite, nc",
  },
  {
    postal_code: "22713",
    zip_name: "boston, va",
  },
  {
    postal_code: "46050",
    zip_name: "kirklin, in",
  },
  {
    postal_code: "8511",
    zip_name: "cookstown, nj",
  },
  {
    postal_code: "41256",
    zip_name: "staffordsville, ky",
  },
  {
    postal_code: "4553",
    zip_name: "newcastle, me",
  },
  {
    postal_code: "53144",
    zip_name: "kenosha, wi",
  },
  {
    postal_code: "8825",
    zip_name: "frenchtown, nj",
  },
  {
    postal_code: "8560",
    zip_name: "titusville, nj",
  },
  {
    postal_code: "59826",
    zip_name: "condon, mt",
  },
  {
    postal_code: "17569",
    zip_name: "reinholds, pa",
  },
  {
    postal_code: "23321",
    zip_name: "chesapeake, va",
  },
  {
    postal_code: "82922",
    zip_name: "bondurant, wy",
  },
  {
    postal_code: "76360",
    zip_name: "electra, tx",
  },
  {
    postal_code: "45629",
    zip_name: "franklin furnace, oh",
  },
  {
    postal_code: "54664",
    zip_name: "viola, wi",
  },
  {
    postal_code: "1026",
    zip_name: "cummington, ma",
  },
  {
    postal_code: "45760",
    zip_name: "middleport, oh",
  },
  {
    postal_code: "62910",
    zip_name: "brookport, il",
  },
  {
    postal_code: "22802",
    zip_name: "harrisonburg, va",
  },
  {
    postal_code: "32331",
    zip_name: "greenville, fl",
  },
  {
    postal_code: "32071",
    zip_name: "o brien, fl",
  },
  {
    postal_code: "56316",
    zip_name: "brooten, mn",
  },
  {
    postal_code: "19013",
    zip_name: "chester, pa",
  },
  {
    postal_code: "99320",
    zip_name: "benton city, wa",
  },
  {
    postal_code: "28556",
    zip_name: "merritt, nc",
  },
  {
    postal_code: "17519",
    zip_name: "east earl, pa",
  },
  {
    postal_code: "46504",
    zip_name: "bourbon, in",
  },
  {
    postal_code: "53061",
    zip_name: "new holstein, wi",
  },
  {
    postal_code: "7044",
    zip_name: "verona, nj",
  },
  {
    postal_code: "83676",
    zip_name: "wilder, id",
  },
  {
    postal_code: "56589",
    zip_name: "waubun, mn",
  },
  {
    postal_code: "36330",
    zip_name: "enterprise, al",
  },
  {
    postal_code: "49048",
    zip_name: "kalamazoo, mi",
  },
  {
    postal_code: "49333",
    zip_name: "middleville, mi",
  },
  {
    postal_code: "81601",
    zip_name: "glenwood springs, co",
  },
  {
    postal_code: "10516",
    zip_name: "cold spring, ny",
  },
  {
    postal_code: "46635",
    zip_name: "south bend, in",
  },
  {
    postal_code: "70377",
    zip_name: "montegut, la",
  },
  {
    postal_code: "10804",
    zip_name: "new rochelle, ny",
  },
  {
    postal_code: "26582",
    zip_name: "mannington, wv",
  },
  {
    postal_code: "70363",
    zip_name: "houma, la",
  },
  {
    postal_code: "7046",
    zip_name: "mountain lakes, nj",
  },
  {
    postal_code: "7310",
    zip_name: "jersey city, nj",
  },
  {
    postal_code: "8755",
    zip_name: "toms river, nj",
  },
  {
    postal_code: "18049",
    zip_name: "emmaus, pa",
  },
  {
    postal_code: "19075",
    zip_name: "oreland, pa",
  },
  {
    postal_code: "86329",
    zip_name: "humboldt, az",
  },
  {
    postal_code: "18850",
    zip_name: "ulster, pa",
  },
  {
    postal_code: "36467",
    zip_name: "opp, al",
  },
  {
    postal_code: "81641",
    zip_name: "meeker, co",
  },
  {
    postal_code: "73013",
    zip_name: "edmond, ok",
  },
  {
    postal_code: "10952",
    zip_name: "monsey, ny",
  },
  {
    postal_code: "55910",
    zip_name: "altura, mn",
  },
  {
    postal_code: "8648",
    zip_name: "lawrence township, nj",
  },
  {
    postal_code: "32425",
    zip_name: "bonifay, fl",
  },
  {
    postal_code: "41701",
    zip_name: "hazard, ky",
  },
  {
    postal_code: "47670",
    zip_name: "princeton, in",
  },
  {
    postal_code: "36542",
    zip_name: "gulf shores, al",
  },
  {
    postal_code: "5146",
    zip_name: "grafton, vt",
  },
  {
    postal_code: "8104",
    zip_name: "camden, nj",
  },
  {
    postal_code: "41222",
    zip_name: "hagerhill, ky",
  },
  {
    postal_code: "49051",
    zip_name: "east leroy, mi",
  },
  {
    postal_code: "47432",
    zip_name: "french lick, in",
  },
  {
    postal_code: "26155",
    zip_name: "new martinsville, wv",
  },
  {
    postal_code: "18088",
    zip_name: "walnutport, pa",
  },
  {
    postal_code: "37412",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "37754",
    zip_name: "heiskell, tn",
  },
  {
    postal_code: "54612",
    zip_name: "arcadia, wi",
  },
  {
    postal_code: "47408",
    zip_name: "bloomington, in",
  },
  {
    postal_code: "56136",
    zip_name: "hendricks, mn",
  },
  {
    postal_code: "5358",
    zip_name: "west halifax, vt",
  },
  {
    postal_code: "73027",
    zip_name: "coyle, ok",
  },
  {
    postal_code: "59018",
    zip_name: "clyde park, mt",
  },
  {
    postal_code: "95225",
    zip_name: "burson, ca",
  },
  {
    postal_code: "13615",
    zip_name: "brownville, ny",
  },
  {
    postal_code: "14454",
    zip_name: "geneseo, ny",
  },
  {
    postal_code: "83327",
    zip_name: "fairfield, id",
  },
  {
    postal_code: "97539",
    zip_name: "shady cove, or",
  },
  {
    postal_code: "17970",
    zip_name: "saint clair, pa",
  },
  {
    postal_code: "89021",
    zip_name: "logandale, nv",
  },
  {
    postal_code: "51103",
    zip_name: "sioux city, ia",
  },
  {
    postal_code: "80239",
    zip_name: "denver, co",
  },
  {
    postal_code: "8884",
    zip_name: "spotswood, nj",
  },
  {
    postal_code: "54930",
    zip_name: "coloma, wi",
  },
  {
    postal_code: "62074",
    zip_name: "new douglas, il",
  },
  {
    postal_code: "24184",
    zip_name: "wirtz, va",
  },
  {
    postal_code: "14001",
    zip_name: "akron, ny",
  },
  {
    postal_code: "49546",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "7023",
    zip_name: "fanwood, nj",
  },
  {
    postal_code: "19512",
    zip_name: "boyertown, pa",
  },
  {
    postal_code: "46514",
    zip_name: "elkhart, in",
  },
  {
    postal_code: "52349",
    zip_name: "vinton, ia",
  },
  {
    postal_code: "48060",
    zip_name: "port huron, mi",
  },
  {
    postal_code: "52223",
    zip_name: "delhi, ia",
  },
  {
    postal_code: "4579",
    zip_name: "woolwich, me",
  },
  {
    postal_code: "8026",
    zip_name: "gibbsboro, nj",
  },
  {
    postal_code: "4691",
    zip_name: "whiting, me",
  },
  {
    postal_code: "29709",
    zip_name: "chesterfield, sc",
  },
  {
    postal_code: "17094",
    zip_name: "thompsontown, pa",
  },
  {
    postal_code: "2637",
    zip_name: "cummaquid, ma",
  },
  {
    postal_code: "70346",
    zip_name: "donaldsonville, la",
  },
  {
    postal_code: "17329",
    zip_name: "glenville, pa",
  },
  {
    postal_code: "58630",
    zip_name: "gladstone, nd",
  },
  {
    postal_code: "45334",
    zip_name: "jackson center, oh",
  },
  {
    postal_code: "54950",
    zip_name: "marion, wi",
  },
  {
    postal_code: "97442",
    zip_name: "glendale, or",
  },
  {
    postal_code: "78401",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "64489",
    zip_name: "stanberry, mo",
  },
  {
    postal_code: "12969",
    zip_name: "owls head, ny",
  },
  {
    postal_code: "76671",
    zip_name: "morgan, tx",
  },
  {
    postal_code: "53518",
    zip_name: "blue river, wi",
  },
  {
    postal_code: "15067",
    zip_name: "new eagle, pa",
  },
  {
    postal_code: "46617",
    zip_name: "south bend, in",
  },
  {
    postal_code: "38368",
    zip_name: "reagan, tn",
  },
  {
    postal_code: "24597",
    zip_name: "vernon hill, va",
  },
  {
    postal_code: "38614",
    zip_name: "clarksdale, ms",
  },
  {
    postal_code: "46619",
    zip_name: "south bend, in",
  },
  {
    postal_code: "53059",
    zip_name: "neosho, wi",
  },
  {
    postal_code: "62677",
    zip_name: "pleasant plains, il",
  },
  {
    postal_code: "78057",
    zip_name: "moore, tx",
  },
  {
    postal_code: "83810",
    zip_name: "cataldo, id",
  },
  {
    postal_code: "22436",
    zip_name: "caret, va",
  },
  {
    postal_code: "42441",
    zip_name: "nebo, ky",
  },
  {
    postal_code: "20690",
    zip_name: "tall timbers, md",
  },
  {
    postal_code: "4418",
    zip_name: "greenbush, me",
  },
  {
    postal_code: "78662",
    zip_name: "red rock, tx",
  },
  {
    postal_code: "31046",
    zip_name: "juliette, ga",
  },
  {
    postal_code: "67880",
    zip_name: "ulysses, ks",
  },
  {
    postal_code: "23301",
    zip_name: "accomac, va",
  },
  {
    postal_code: "32621",
    zip_name: "bronson, fl",
  },
  {
    postal_code: "78389",
    zip_name: "skidmore, tx",
  },
  {
    postal_code: "47923",
    zip_name: "brookston, in",
  },
  {
    postal_code: "97487",
    zip_name: "veneta, or",
  },
  {
    postal_code: "6409",
    zip_name: "centerbrook, ct",
  },
  {
    postal_code: "65604",
    zip_name: "ash grove, mo",
  },
  {
    postal_code: "48401",
    zip_name: "applegate, mi",
  },
  {
    postal_code: "4010",
    zip_name: "brownfield, me",
  },
  {
    postal_code: "29847",
    zip_name: "trenton, sc",
  },
  {
    postal_code: "66090",
    zip_name: "wathena, ks",
  },
  {
    postal_code: "63863",
    zip_name: "malden, mo",
  },
  {
    postal_code: "64761",
    zip_name: "leeton, mo",
  },
  {
    postal_code: "63384",
    zip_name: "wellsville, mo",
  },
  {
    postal_code: "25979",
    zip_name: "pipestem, wv",
  },
  {
    postal_code: "73109",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "89011",
    zip_name: "henderson, nv",
  },
  {
    postal_code: "54929",
    zip_name: "clintonville, wi",
  },
  {
    postal_code: "31305",
    zip_name: "darien, ga",
  },
  {
    postal_code: "49506",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "36856",
    zip_name: "fort mitchell, al",
  },
  {
    postal_code: "48738",
    zip_name: "greenbush, mi",
  },
  {
    postal_code: "48025",
    zip_name: "franklin, mi",
  },
  {
    postal_code: "80127",
    zip_name: "littleton, co",
  },
  {
    postal_code: "68333",
    zip_name: "crete, ne",
  },
  {
    postal_code: "35016",
    zip_name: "arab, al",
  },
  {
    postal_code: "19038",
    zip_name: "glenside, pa",
  },
  {
    postal_code: "18947",
    zip_name: "pipersville, pa",
  },
  {
    postal_code: "56560",
    zip_name: "moorhead, mn",
  },
  {
    postal_code: "7020",
    zip_name: "edgewater, nj",
  },
  {
    postal_code: "12864",
    zip_name: "sabael, ny",
  },
  {
    postal_code: "80928",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "29945",
    zip_name: "yemassee, sc",
  },
  {
    postal_code: "56685",
    zip_name: "waskish, mn",
  },
  {
    postal_code: "4097",
    zip_name: "north yarmouth, me",
  },
  {
    postal_code: "27283",
    zip_name: "julian, nc",
  },
  {
    postal_code: "18934",
    zip_name: "mechanicsville, pa",
  },
  {
    postal_code: "48179",
    zip_name: "south rockwood, mi",
  },
  {
    postal_code: "7716",
    zip_name: "atlantic highlands, nj",
  },
  {
    postal_code: "17961",
    zip_name: "orwigsburg, pa",
  },
  {
    postal_code: "7082",
    zip_name: "towaco, nj",
  },
  {
    postal_code: "50677",
    zip_name: "waverly, ia",
  },
  {
    postal_code: "8518",
    zip_name: "florence, nj",
  },
  {
    postal_code: "84779",
    zip_name: "virgin, ut",
  },
  {
    postal_code: "34498",
    zip_name: "yankeetown, fl",
  },
  {
    postal_code: "74851",
    zip_name: "mcloud, ok",
  },
  {
    postal_code: "16901",
    zip_name: "wellsboro, pa",
  },
  {
    postal_code: "54174",
    zip_name: "suring, wi",
  },
  {
    postal_code: "7006",
    zip_name: "caldwell, nj",
  },
  {
    postal_code: "38588",
    zip_name: "whitleyville, tn",
  },
  {
    postal_code: "35973",
    zip_name: "gaylesville, al",
  },
  {
    postal_code: "48742",
    zip_name: "lincoln, mi",
  },
  {
    postal_code: "68430",
    zip_name: "roca, ne",
  },
  {
    postal_code: "14011",
    zip_name: "attica, ny",
  },
  {
    postal_code: "3216",
    zip_name: "andover, nh",
  },
  {
    postal_code: "26385",
    zip_name: "lost creek, wv",
  },
  {
    postal_code: "39066",
    zip_name: "edwards, ms",
  },
  {
    postal_code: "17241",
    zip_name: "newville, pa",
  },
  {
    postal_code: "72432",
    zip_name: "harrisburg, ar",
  },
  {
    postal_code: "12775",
    zip_name: "rock hill, ny",
  },
  {
    postal_code: "65692",
    zip_name: "koshkonong, mo",
  },
  {
    postal_code: "62638",
    zip_name: "franklin, il",
  },
  {
    postal_code: "68502",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "68147",
    zip_name: "bellevue, ne",
  },
  {
    postal_code: "19355",
    zip_name: "malvern, pa",
  },
  {
    postal_code: "14048",
    zip_name: "dunkirk, ny",
  },
  {
    postal_code: "8075",
    zip_name: "riverside, nj",
  },
  {
    postal_code: "54155",
    zip_name: "oneida, wi",
  },
  {
    postal_code: "75851",
    zip_name: "lovelady, tx",
  },
  {
    postal_code: "17062",
    zip_name: "millerstown, pa",
  },
  {
    postal_code: "19547",
    zip_name: "oley, pa",
  },
  {
    postal_code: "31788",
    zip_name: "moultrie, ga",
  },
  {
    postal_code: "71040",
    zip_name: "homer, la",
  },
  {
    postal_code: "10528",
    zip_name: "harrison, ny",
  },
  {
    postal_code: "75630",
    zip_name: "avinger, tx",
  },
  {
    postal_code: "2568",
    zip_name: "vineyard haven, ma",
  },
  {
    postal_code: "92007",
    zip_name: "cardiff by the sea, ca",
  },
  {
    postal_code: "80449",
    zip_name: "hartsel, co",
  },
  {
    postal_code: "95503",
    zip_name: "eureka, ca",
  },
  {
    postal_code: "49774",
    zip_name: "pickford, mi",
  },
  {
    postal_code: "7208",
    zip_name: "elizabeth, nj",
  },
  {
    postal_code: "46933",
    zip_name: "gas city, in",
  },
  {
    postal_code: "56315",
    zip_name: "brandon, mn",
  },
  {
    postal_code: "56576",
    zip_name: "richville, mn",
  },
  {
    postal_code: "13145",
    zip_name: "sandy creek, ny",
  },
  {
    postal_code: "40409",
    zip_name: "brodhead, ky",
  },
  {
    postal_code: "78577",
    zip_name: "pharr, tx",
  },
  {
    postal_code: "89115",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "70577",
    zip_name: "port barre, la",
  },
  {
    postal_code: "19066",
    zip_name: "merion station, pa",
  },
  {
    postal_code: "80487",
    zip_name: "steamboat springs, co",
  },
  {
    postal_code: "8103",
    zip_name: "camden, nj",
  },
  {
    postal_code: "4983",
    zip_name: "strong, me",
  },
  {
    postal_code: "66610",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "13662",
    zip_name: "massena, ny",
  },
  {
    postal_code: "35405",
    zip_name: "tuscaloosa, al",
  },
  {
    postal_code: "36310",
    zip_name: "abbeville, al",
  },
  {
    postal_code: "72840",
    zip_name: "hartman, ar",
  },
  {
    postal_code: "49643",
    zip_name: "interlochen, mi",
  },
  {
    postal_code: "30555",
    zip_name: "mc caysville, ga",
  },
  {
    postal_code: "10990",
    zip_name: "warwick, ny",
  },
  {
    postal_code: "24426",
    zip_name: "covington, va",
  },
  {
    postal_code: "79019",
    zip_name: "claude, tx",
  },
  {
    postal_code: "38568",
    zip_name: "hilham, tn",
  },
  {
    postal_code: "81654",
    zip_name: "snowmass, co",
  },
  {
    postal_code: "4750",
    zip_name: "limestone, me",
  },
  {
    postal_code: "47462",
    zip_name: "springville, in",
  },
  {
    postal_code: "54437",
    zip_name: "greenwood, wi",
  },
  {
    postal_code: "68164",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "8102",
    zip_name: "camden, nj",
  },
  {
    postal_code: "8850",
    zip_name: "milltown, nj",
  },
  {
    postal_code: "62305",
    zip_name: "quincy, il",
  },
  {
    postal_code: "17532",
    zip_name: "holtwood, pa",
  },
  {
    postal_code: "30467",
    zip_name: "sylvania, ga",
  },
  {
    postal_code: "83607",
    zip_name: "caldwell, id",
  },
  {
    postal_code: "54956",
    zip_name: "neenah, wi",
  },
  {
    postal_code: "27874",
    zip_name: "scotland neck, nc",
  },
  {
    postal_code: "23035",
    zip_name: "cobbs creek, va",
  },
  {
    postal_code: "65725",
    zip_name: "pleasant hope, mo",
  },
  {
    postal_code: "20645",
    zip_name: "issue, md",
  },
  {
    postal_code: "77443",
    zip_name: "glen flora, tx",
  },
  {
    postal_code: "8534",
    zip_name: "pennington, nj",
  },
  {
    postal_code: "82501",
    zip_name: "riverton, wy",
  },
  {
    postal_code: "8886",
    zip_name: "stewartsville, nj",
  },
  {
    postal_code: "28781",
    zip_name: "topton, nc",
  },
  {
    postal_code: "50058",
    zip_name: "coon rapids, ia",
  },
  {
    postal_code: "97467",
    zip_name: "reedsport, or",
  },
  {
    postal_code: "13461",
    zip_name: "sherrill, ny",
  },
  {
    postal_code: "97227",
    zip_name: "portland, or",
  },
  {
    postal_code: "85603",
    zip_name: "bisbee, az",
  },
  {
    postal_code: "49646",
    zip_name: "kalkaska, mi",
  },
  {
    postal_code: "22827",
    zip_name: "elkton, va",
  },
  {
    postal_code: "14482",
    zip_name: "le roy, ny",
  },
  {
    postal_code: "97330",
    zip_name: "corvallis, or",
  },
  {
    postal_code: "24586",
    zip_name: "ringgold, va",
  },
  {
    postal_code: "52569",
    zip_name: "melrose, ia",
  },
  {
    postal_code: "97844",
    zip_name: "irrigon, or",
  },
  {
    postal_code: "97327",
    zip_name: "brownsville, or",
  },
  {
    postal_code: "80498",
    zip_name: "silverthorne, co",
  },
  {
    postal_code: "13620",
    zip_name: "castorland, ny",
  },
  {
    postal_code: "97067",
    zip_name: "welches, or",
  },
  {
    postal_code: "85650",
    zip_name: "sierra vista, az",
  },
  {
    postal_code: "77611",
    zip_name: "bridge city, tx",
  },
  {
    postal_code: "5858",
    zip_name: "north concord, vt",
  },
  {
    postal_code: "97205",
    zip_name: "portland, or",
  },
  {
    postal_code: "22572",
    zip_name: "warsaw, va",
  },
  {
    postal_code: "25270",
    zip_name: "reedy, wv",
  },
  {
    postal_code: "97845",
    zip_name: "john day, or",
  },
  {
    postal_code: "97462",
    zip_name: "oakland, or",
  },
  {
    postal_code: "22435",
    zip_name: "callao, va",
  },
  {
    postal_code: "10927",
    zip_name: "haverstraw, ny",
  },
  {
    postal_code: "97410",
    zip_name: "azalea, or",
  },
  {
    postal_code: "97348",
    zip_name: "halsey, or",
  },
  {
    postal_code: "99301",
    zip_name: "pasco, wa",
  },
  {
    postal_code: "97325",
    zip_name: "aumsville, or",
  },
  {
    postal_code: "26133",
    zip_name: "belleville, wv",
  },
  {
    postal_code: "97828",
    zip_name: "enterprise, or",
  },
  {
    postal_code: "43740",
    zip_name: "gratiot, oh",
  },
  {
    postal_code: "24148",
    zip_name: "ridgeway, va",
  },
  {
    postal_code: "70755",
    zip_name: "livonia, la",
  },
  {
    postal_code: "48858",
    zip_name: "mount pleasant, mi",
  },
  {
    postal_code: "98606",
    zip_name: "brush prairie, wa",
  },
  {
    postal_code: "66611",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "48128",
    zip_name: "dearborn, mi",
  },
  {
    postal_code: "35077",
    zip_name: "hanceville, al",
  },
  {
    postal_code: "94973",
    zip_name: "woodacre, ca",
  },
  {
    postal_code: "99338",
    zip_name: "kennewick, wa",
  },
  {
    postal_code: "11439",
    zip_name: "jamaica, ny",
  },
  {
    postal_code: "67734",
    zip_name: "gem, ks",
  },
  {
    postal_code: "47616",
    zip_name: "griffin, in",
  },
  {
    postal_code: "1747",
    zip_name: "hopedale, ma",
  },
  {
    postal_code: "43758",
    zip_name: "malta, oh",
  },
  {
    postal_code: "48618",
    zip_name: "coleman, mi",
  },
  {
    postal_code: "49310",
    zip_name: "blanchard, mi",
  },
  {
    postal_code: "56256",
    zip_name: "madison, mn",
  },
  {
    postal_code: "65771",
    zip_name: "walnut shade, mo",
  },
  {
    postal_code: "97338",
    zip_name: "dallas, or",
  },
  {
    postal_code: "83429",
    zip_name: "island park, id",
  },
  {
    postal_code: "46554",
    zip_name: "north liberty, in",
  },
  {
    postal_code: "44491",
    zip_name: "west farmington, oh",
  },
  {
    postal_code: "49332",
    zip_name: "mecosta, mi",
  },
  {
    postal_code: "29168",
    zip_name: "wedgefield, sc",
  },
  {
    postal_code: "71674",
    zip_name: "watson, ar",
  },
  {
    postal_code: "29332",
    zip_name: "cross hill, sc",
  },
  {
    postal_code: "52078",
    zip_name: "worthington, ia",
  },
  {
    postal_code: "11715",
    zip_name: "blue point, ny",
  },
  {
    postal_code: "30241",
    zip_name: "lagrange, ga",
  },
  {
    postal_code: "83615",
    zip_name: "donnelly, id",
  },
  {
    postal_code: "31773",
    zip_name: "ochlocknee, ga",
  },
  {
    postal_code: "49686",
    zip_name: "traverse city, mi",
  },
  {
    postal_code: "43529",
    zip_name: "hoytville, oh",
  },
  {
    postal_code: "22473",
    zip_name: "heathsville, va",
  },
  {
    postal_code: "96813",
    zip_name: "honolulu, hi",
  },
  {
    postal_code: "48383",
    zip_name: "white lake, mi",
  },
  {
    postal_code: "23336",
    zip_name: "chincoteague island, va",
  },
  {
    postal_code: "98635",
    zip_name: "lyle, wa",
  },
  {
    postal_code: "67748",
    zip_name: "oakley, ks",
  },
  {
    postal_code: "48363",
    zip_name: "oakland, mi",
  },
  {
    postal_code: "74085",
    zip_name: "yale, ok",
  },
  {
    postal_code: "62563",
    zip_name: "rochester, il",
  },
  {
    postal_code: "67901",
    zip_name: "liberal, ks",
  },
  {
    postal_code: "29374",
    zip_name: "pauline, sc",
  },
  {
    postal_code: "35179",
    zip_name: "vinemont, al",
  },
  {
    postal_code: "97465",
    zip_name: "port orford, or",
  },
  {
    postal_code: "23921",
    zip_name: "buckingham, va",
  },
  {
    postal_code: "99163",
    zip_name: "pullman, wa",
  },
  {
    postal_code: "26170",
    zip_name: "saint marys, wv",
  },
  {
    postal_code: "11514",
    zip_name: "carle place, ny",
  },
  {
    postal_code: "97496",
    zip_name: "winston, or",
  },
  {
    postal_code: "93458",
    zip_name: "santa maria, ca",
  },
  {
    postal_code: "96770",
    zip_name: "maunaloa, hi",
  },
  {
    postal_code: "76935",
    zip_name: "christoval, tx",
  },
  {
    postal_code: "36278",
    zip_name: "wedowee, al",
  },
  {
    postal_code: "28073",
    zip_name: "grover, nc",
  },
  {
    postal_code: "52134",
    zip_name: "chester, ia",
  },
  {
    postal_code: "97215",
    zip_name: "portland, or",
  },
  {
    postal_code: "44609",
    zip_name: "beloit, oh",
  },
  {
    postal_code: "67550",
    zip_name: "larned, ks",
  },
  {
    postal_code: "23944",
    zip_name: "kenbridge, va",
  },
  {
    postal_code: "99350",
    zip_name: "prosser, wa",
  },
  {
    postal_code: "18962",
    zip_name: "silverdale, pa",
  },
  {
    postal_code: "31757",
    zip_name: "thomasville, ga",
  },
  {
    postal_code: "23962",
    zip_name: "randolph, va",
  },
  {
    postal_code: "36701",
    zip_name: "selma, al",
  },
  {
    postal_code: "16602",
    zip_name: "altoona, pa",
  },
  {
    postal_code: "48098",
    zip_name: "troy, mi",
  },
  {
    postal_code: "49665",
    zip_name: "marion, mi",
  },
  {
    postal_code: "46785",
    zip_name: "saint joe, in",
  },
  {
    postal_code: "97444",
    zip_name: "gold beach, or",
  },
  {
    postal_code: "97322",
    zip_name: "albany, or",
  },
  {
    postal_code: "37043",
    zip_name: "clarksville, tn",
  },
  {
    postal_code: "97005",
    zip_name: "beaverton, or",
  },
  {
    postal_code: "47441",
    zip_name: "linton, in",
  },
  {
    postal_code: "97449",
    zip_name: "lakeside, or",
  },
  {
    postal_code: "65251",
    zip_name: "fulton, mo",
  },
  {
    postal_code: "48312",
    zip_name: "sterling heights, mi",
  },
  {
    postal_code: "37058",
    zip_name: "dover, tn",
  },
  {
    postal_code: "46710",
    zip_name: "avilla, in",
  },
  {
    postal_code: "40873",
    zip_name: "wallins creek, ky",
  },
  {
    postal_code: "32359",
    zip_name: "steinhatchee, fl",
  },
  {
    postal_code: "98611",
    zip_name: "castle rock, wa",
  },
  {
    postal_code: "48754",
    zip_name: "owendale, mi",
  },
  {
    postal_code: "29929",
    zip_name: "islandton, sc",
  },
  {
    postal_code: "14731",
    zip_name: "ellicottville, ny",
  },
  {
    postal_code: "67545",
    zip_name: "hudson, ks",
  },
  {
    postal_code: "37206",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "48756",
    zip_name: "prescott, mi",
  },
  {
    postal_code: "44485",
    zip_name: "warren, oh",
  },
  {
    postal_code: "96768",
    zip_name: "makawao, hi",
  },
  {
    postal_code: "96732",
    zip_name: "kahului, hi",
  },
  {
    postal_code: "67480",
    zip_name: "solomon, ks",
  },
  {
    postal_code: "14304",
    zip_name: "niagara falls, ny",
  },
  {
    postal_code: "23113",
    zip_name: "midlothian, va",
  },
  {
    postal_code: "51436",
    zip_name: "breda, ia",
  },
  {
    postal_code: "47918",
    zip_name: "attica, in",
  },
  {
    postal_code: "56131",
    zip_name: "fulda, mn",
  },
  {
    postal_code: "69334",
    zip_name: "bayard, ne",
  },
  {
    postal_code: "22482",
    zip_name: "kilmarnock, va",
  },
  {
    postal_code: "64728",
    zip_name: "bronaugh, mo",
  },
  {
    postal_code: "97383",
    zip_name: "stayton, or",
  },
  {
    postal_code: "97456",
    zip_name: "monroe, or",
  },
  {
    postal_code: "48442",
    zip_name: "holly, mi",
  },
  {
    postal_code: "72662",
    zip_name: "omaha, ar",
  },
  {
    postal_code: "12732",
    zip_name: "eldred, ny",
  },
  {
    postal_code: "98610",
    zip_name: "carson, wa",
  },
  {
    postal_code: "49919",
    zip_name: "covington, mi",
  },
  {
    postal_code: "13616",
    zip_name: "calcium, ny",
  },
  {
    postal_code: "66441",
    zip_name: "junction city, ks",
  },
  {
    postal_code: "6334",
    zip_name: "bozrah, ct",
  },
  {
    postal_code: "49738",
    zip_name: "grayling, mi",
  },
  {
    postal_code: "80456",
    zip_name: "jefferson, co",
  },
  {
    postal_code: "99354",
    zip_name: "richland, wa",
  },
  {
    postal_code: "52728",
    zip_name: "buffalo, ia",
  },
  {
    postal_code: "97385",
    zip_name: "sublimity, or",
  },
  {
    postal_code: "97201",
    zip_name: "portland, or",
  },
  {
    postal_code: "17366",
    zip_name: "windsor, pa",
  },
  {
    postal_code: "97481",
    zip_name: "tenmile, or",
  },
  {
    postal_code: "24171",
    zip_name: "stuart, va",
  },
  {
    postal_code: "83611",
    zip_name: "cascade, id",
  },
  {
    postal_code: "31602",
    zip_name: "valdosta, ga",
  },
  {
    postal_code: "48622",
    zip_name: "farwell, mi",
  },
  {
    postal_code: "96713",
    zip_name: "hana, hi",
  },
  {
    postal_code: "43314",
    zip_name: "caledonia, oh",
  },
  {
    postal_code: "97211",
    zip_name: "portland, or",
  },
  {
    postal_code: "97850",
    zip_name: "la grande, or",
  },
  {
    postal_code: "32680",
    zip_name: "old town, fl",
  },
  {
    postal_code: "35073",
    zip_name: "graysville, al",
  },
  {
    postal_code: "30631",
    zip_name: "crawfordville, ga",
  },
  {
    postal_code: "14210",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "44507",
    zip_name: "youngstown, oh",
  },
  {
    postal_code: "43106",
    zip_name: "bloomingburg, oh",
  },
  {
    postal_code: "92067",
    zip_name: "rancho santa fe, ca",
  },
  {
    postal_code: "97101",
    zip_name: "amity, or",
  },
  {
    postal_code: "13028",
    zip_name: "bernhards bay, ny",
  },
  {
    postal_code: "66425",
    zip_name: "fairview, ks",
  },
  {
    postal_code: "76904",
    zip_name: "san angelo, tx",
  },
  {
    postal_code: "31558",
    zip_name: "saint marys, ga",
  },
  {
    postal_code: "54773",
    zip_name: "whitehall, wi",
  },
  {
    postal_code: "96734",
    zip_name: "kailua, hi",
  },
  {
    postal_code: "7204",
    zip_name: "roselle park, nj",
  },
  {
    postal_code: "66736",
    zip_name: "fredonia, ks",
  },
  {
    postal_code: "97115",
    zip_name: "dundee, or",
  },
  {
    postal_code: "49601",
    zip_name: "cadillac, mi",
  },
  {
    postal_code: "39828",
    zip_name: "cairo, ga",
  },
  {
    postal_code: "97495",
    zip_name: "winchester, or",
  },
  {
    postal_code: "97362",
    zip_name: "mount angel, or",
  },
  {
    postal_code: "97147",
    zip_name: "wheeler, or",
  },
  {
    postal_code: "15082",
    zip_name: "sturgeon, pa",
  },
  {
    postal_code: "66739",
    zip_name: "galena, ks",
  },
  {
    postal_code: "80420",
    zip_name: "alma, co",
  },
  {
    postal_code: "47920",
    zip_name: "battle ground, in",
  },
  {
    postal_code: "49656",
    zip_name: "luther, mi",
  },
  {
    postal_code: "18854",
    zip_name: "wysox, pa",
  },
  {
    postal_code: "27007",
    zip_name: "ararat, nc",
  },
  {
    postal_code: "1518",
    zip_name: "fiskdale, ma",
  },
  {
    postal_code: "10588",
    zip_name: "shrub oak, ny",
  },
  {
    postal_code: "47991",
    zip_name: "west lebanon, in",
  },
  {
    postal_code: "32619",
    zip_name: "bell, fl",
  },
  {
    postal_code: "14172",
    zip_name: "wilson, ny",
  },
  {
    postal_code: "19533",
    zip_name: "leesport, pa",
  },
  {
    postal_code: "74106",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "6230",
    zip_name: "abington, ct",
  },
  {
    postal_code: "74445",
    zip_name: "morris, ok",
  },
  {
    postal_code: "83857",
    zip_name: "princeton, id",
  },
  {
    postal_code: "52551",
    zip_name: "douds, ia",
  },
  {
    postal_code: "4252",
    zip_name: "lisbon falls, me",
  },
  {
    postal_code: "42437",
    zip_name: "morganfield, ky",
  },
  {
    postal_code: "74578",
    zip_name: "wilburton, ok",
  },
  {
    postal_code: "14471",
    zip_name: "honeoye, ny",
  },
  {
    postal_code: "10470",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "15832",
    zip_name: "driftwood, pa",
  },
  {
    postal_code: "14804",
    zip_name: "almond, ny",
  },
  {
    postal_code: "49818",
    zip_name: "cornell, mi",
  },
  {
    postal_code: "65323",
    zip_name: "calhoun, mo",
  },
  {
    postal_code: "38449",
    zip_name: "ardmore, tn",
  },
  {
    postal_code: "55969",
    zip_name: "rollingstone, mn",
  },
  {
    postal_code: "76693",
    zip_name: "wortham, tx",
  },
  {
    postal_code: "68920",
    zip_name: "alma, ne",
  },
  {
    postal_code: "47355",
    zip_name: "lynn, in",
  },
  {
    postal_code: "56270",
    zip_name: "morton, mn",
  },
  {
    postal_code: "97127",
    zip_name: "lafayette, or",
  },
  {
    postal_code: "56738",
    zip_name: "newfolden, mn",
  },
  {
    postal_code: "70532",
    zip_name: "elton, la",
  },
  {
    postal_code: "66967",
    zip_name: "smith center, ks",
  },
  {
    postal_code: "12792",
    zip_name: "yulan, ny",
  },
  {
    postal_code: "62460",
    zip_name: "saint francisville, il",
  },
  {
    postal_code: "42602",
    zip_name: "albany, ky",
  },
  {
    postal_code: "10502",
    zip_name: "ardsley, ny",
  },
  {
    postal_code: "97469",
    zip_name: "riddle, or",
  },
  {
    postal_code: "10577",
    zip_name: "purchase, ny",
  },
  {
    postal_code: "18321",
    zip_name: "bartonsville, pa",
  },
  {
    postal_code: "47562",
    zip_name: "odon, in",
  },
  {
    postal_code: "74041",
    zip_name: "kiefer, ok",
  },
  {
    postal_code: "1038",
    zip_name: "hatfield, ma",
  },
  {
    postal_code: "14808",
    zip_name: "atlanta, ny",
  },
  {
    postal_code: "15631",
    zip_name: "everson, pa",
  },
  {
    postal_code: "2702",
    zip_name: "assonet, ma",
  },
  {
    postal_code: "20778",
    zip_name: "west river, md",
  },
  {
    postal_code: "1105",
    zip_name: "springfield, ma",
  },
  {
    postal_code: "96764",
    zip_name: "laupahoehoe, hi",
  },
  {
    postal_code: "74547",
    zip_name: "hartshorne, ok",
  },
  {
    postal_code: "48380",
    zip_name: "milford, mi",
  },
  {
    postal_code: "64448",
    zip_name: "faucett, mo",
  },
  {
    postal_code: "97875",
    zip_name: "stanfield, or",
  },
  {
    postal_code: "46601",
    zip_name: "south bend, in",
  },
  {
    postal_code: "74146",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "72672",
    zip_name: "pyatt, ar",
  },
  {
    postal_code: "1522",
    zip_name: "jefferson, ma",
  },
  {
    postal_code: "1092",
    zip_name: "west warren, ma",
  },
  {
    postal_code: "92368",
    zip_name: "oro grande, ca",
  },
  {
    postal_code: "10974",
    zip_name: "sloatsburg, ny",
  },
  {
    postal_code: "46507",
    zip_name: "bristol, in",
  },
  {
    postal_code: "16116",
    zip_name: "edinburg, pa",
  },
  {
    postal_code: "1073",
    zip_name: "southampton, ma",
  },
  {
    postal_code: "4974",
    zip_name: "searsport, me",
  },
  {
    postal_code: "14543",
    zip_name: "rush, ny",
  },
  {
    postal_code: "74108",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "10549",
    zip_name: "mount kisco, ny",
  },
  {
    postal_code: "3445",
    zip_name: "sullivan, nh",
  },
  {
    postal_code: "28707",
    zip_name: "balsam, nc",
  },
  {
    postal_code: "1343",
    zip_name: "drury, ma",
  },
  {
    postal_code: "4544",
    zip_name: "east boothbay, me",
  },
  {
    postal_code: "38664",
    zip_name: "robinsonville, ms",
  },
  {
    postal_code: "1053",
    zip_name: "leeds, ma",
  },
  {
    postal_code: "48422",
    zip_name: "croswell, mi",
  },
  {
    postal_code: "67753",
    zip_name: "rexford, ks",
  },
  {
    postal_code: "47995",
    zip_name: "wolcott, in",
  },
  {
    postal_code: "14033",
    zip_name: "colden, ny",
  },
  {
    postal_code: "18829",
    zip_name: "le raysville, pa",
  },
  {
    postal_code: "1718",
    zip_name: "acton, ma",
  },
  {
    postal_code: "67701",
    zip_name: "colby, ks",
  },
  {
    postal_code: "12950",
    zip_name: "lewis, ny",
  },
  {
    postal_code: "4221",
    zip_name: "canton, me",
  },
  {
    postal_code: "76621",
    zip_name: "abbott, tx",
  },
  {
    postal_code: "17370",
    zip_name: "york haven, pa",
  },
  {
    postal_code: "74962",
    zip_name: "vian, ok",
  },
  {
    postal_code: "47712",
    zip_name: "evansville, in",
  },
  {
    postal_code: "10547",
    zip_name: "mohegan lake, ny",
  },
  {
    postal_code: "8230",
    zip_name: "ocean view, nj",
  },
  {
    postal_code: "39845",
    zip_name: "donalsonville, ga",
  },
  {
    postal_code: "47579",
    zip_name: "santa claus, in",
  },
  {
    postal_code: "18618",
    zip_name: "harveys lake, pa",
  },
  {
    postal_code: "14028",
    zip_name: "burt, ny",
  },
  {
    postal_code: "98040",
    zip_name: "mercer island, wa",
  },
  {
    postal_code: "52003",
    zip_name: "dubuque, ia",
  },
  {
    postal_code: "29646",
    zip_name: "greenwood, sc",
  },
  {
    postal_code: "14755",
    zip_name: "little valley, ny",
  },
  {
    postal_code: "74133",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "23417",
    zip_name: "onancock, va",
  },
  {
    postal_code: "97239",
    zip_name: "portland, or",
  },
  {
    postal_code: "31626",
    zip_name: "boston, ga",
  },
  {
    postal_code: "10589",
    zip_name: "somers, ny",
  },
  {
    postal_code: "96052",
    zip_name: "lewiston, ca",
  },
  {
    postal_code: "25446",
    zip_name: "summit point, wv",
  },
  {
    postal_code: "54824",
    zip_name: "centuria, wi",
  },
  {
    postal_code: "14004",
    zip_name: "alden, ny",
  },
  {
    postal_code: "32626",
    zip_name: "chiefland, fl",
  },
  {
    postal_code: "79756",
    zip_name: "monahans, tx",
  },
  {
    postal_code: "74937",
    zip_name: "heavener, ok",
  },
  {
    postal_code: "74107",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "97520",
    zip_name: "ashland, or",
  },
  {
    postal_code: "83341",
    zip_name: "kimberly, id",
  },
  {
    postal_code: "12790",
    zip_name: "wurtsboro, ny",
  },
  {
    postal_code: "23175",
    zip_name: "urbanna, va",
  },
  {
    postal_code: "74066",
    zip_name: "sapulpa, ok",
  },
  {
    postal_code: "48653",
    zip_name: "roscommon, mi",
  },
  {
    postal_code: "56575",
    zip_name: "ponsford, mn",
  },
  {
    postal_code: "17552",
    zip_name: "mount joy, pa",
  },
  {
    postal_code: "47725",
    zip_name: "evansville, in",
  },
  {
    postal_code: "74354",
    zip_name: "miami, ok",
  },
  {
    postal_code: "47711",
    zip_name: "evansville, in",
  },
  {
    postal_code: "91916",
    zip_name: "descanso, ca",
  },
  {
    postal_code: "74361",
    zip_name: "pryor, ok",
  },
  {
    postal_code: "54727",
    zip_name: "cadott, wi",
  },
  {
    postal_code: "1010",
    zip_name: "brimfield, ma",
  },
  {
    postal_code: "48084",
    zip_name: "troy, mi",
  },
  {
    postal_code: "97524",
    zip_name: "eagle point, or",
  },
  {
    postal_code: "2460",
    zip_name: "newtonville, ma",
  },
  {
    postal_code: "62568",
    zip_name: "taylorville, il",
  },
  {
    postal_code: "22511",
    zip_name: "lottsburg, va",
  },
  {
    postal_code: "8091",
    zip_name: "west berlin, nj",
  },
  {
    postal_code: "97439",
    zip_name: "florence, or",
  },
  {
    postal_code: "88045",
    zip_name: "lordsburg, nm",
  },
  {
    postal_code: "67431",
    zip_name: "chapman, ks",
  },
  {
    postal_code: "96816",
    zip_name: "honolulu, hi",
  },
  {
    postal_code: "14204",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "10980",
    zip_name: "stony point, ny",
  },
  {
    postal_code: "46530",
    zip_name: "granger, in",
  },
  {
    postal_code: "21912",
    zip_name: "warwick, md",
  },
  {
    postal_code: "42635",
    zip_name: "pine knot, ky",
  },
  {
    postal_code: "46765",
    zip_name: "leo, in",
  },
  {
    postal_code: "10607",
    zip_name: "white plains, ny",
  },
  {
    postal_code: "3049",
    zip_name: "hollis, nh",
  },
  {
    postal_code: "21871",
    zip_name: "westover, md",
  },
  {
    postal_code: "14618",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "16327",
    zip_name: "guys mills, pa",
  },
  {
    postal_code: "49076",
    zip_name: "olivet, mi",
  },
  {
    postal_code: "15759",
    zip_name: "marion center, pa",
  },
  {
    postal_code: "47348",
    zip_name: "hartford city, in",
  },
  {
    postal_code: "65231",
    zip_name: "auxvasse, mo",
  },
  {
    postal_code: "24541",
    zip_name: "danville, va",
  },
  {
    postal_code: "59866",
    zip_name: "saint regis, mt",
  },
  {
    postal_code: "31014",
    zip_name: "cochran, ga",
  },
  {
    postal_code: "47710",
    zip_name: "evansville, in",
  },
  {
    postal_code: "81008",
    zip_name: "pueblo, co",
  },
  {
    postal_code: "97221",
    zip_name: "portland, or",
  },
  {
    postal_code: "74037",
    zip_name: "jenks, ok",
  },
  {
    postal_code: "97027",
    zip_name: "gladstone, or",
  },
  {
    postal_code: "47396",
    zip_name: "yorktown, in",
  },
  {
    postal_code: "25070",
    zip_name: "eleanor, wv",
  },
  {
    postal_code: "97134",
    zip_name: "oceanside, or",
  },
  {
    postal_code: "14047",
    zip_name: "derby, ny",
  },
  {
    postal_code: "74434",
    zip_name: "fort gibson, ok",
  },
  {
    postal_code: "74120",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "74525",
    zip_name: "atoka, ok",
  },
  {
    postal_code: "8740",
    zip_name: "ocean gate, nj",
  },
  {
    postal_code: "74073",
    zip_name: "sperry, ok",
  },
  {
    postal_code: "14220",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "14224",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "97220",
    zip_name: "portland, or",
  },
  {
    postal_code: "10583",
    zip_name: "scarsdale, ny",
  },
  {
    postal_code: "97048",
    zip_name: "rainier, or",
  },
  {
    postal_code: "74053",
    zip_name: "oologah, ok",
  },
  {
    postal_code: "46743",
    zip_name: "harlan, in",
  },
  {
    postal_code: "27964",
    zip_name: "point harbor, nc",
  },
  {
    postal_code: "61883",
    zip_name: "westville, il",
  },
  {
    postal_code: "62999",
    zip_name: "zeigler, il",
  },
  {
    postal_code: "13426",
    zip_name: "orwell, ny",
  },
  {
    postal_code: "57751",
    zip_name: "keystone, sd",
  },
  {
    postal_code: "11749",
    zip_name: "islandia, ny",
  },
  {
    postal_code: "14425",
    zip_name: "farmington, ny",
  },
  {
    postal_code: "74467",
    zip_name: "wagoner, ok",
  },
  {
    postal_code: "97862",
    zip_name: "milton freewater, or",
  },
  {
    postal_code: "97479",
    zip_name: "sutherlin, or",
  },
  {
    postal_code: "7753",
    zip_name: "neptune, nj",
  },
  {
    postal_code: "70669",
    zip_name: "westlake, la",
  },
  {
    postal_code: "32625",
    zip_name: "cedar key, fl",
  },
  {
    postal_code: "97231",
    zip_name: "portland, or",
  },
  {
    postal_code: "97218",
    zip_name: "portland, or",
  },
  {
    postal_code: "2114",
    zip_name: "boston, ma",
  },
  {
    postal_code: "10458",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "24112",
    zip_name: "martinsville, va",
  },
  {
    postal_code: "74136",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "48504",
    zip_name: "flint, mi",
  },
  {
    postal_code: "67801",
    zip_name: "dodge city, ks",
  },
  {
    postal_code: "47714",
    zip_name: "evansville, in",
  },
  {
    postal_code: "8344",
    zip_name: "newfield, nj",
  },
  {
    postal_code: "7731",
    zip_name: "howell, nj",
  },
  {
    postal_code: "13118",
    zip_name: "moravia, ny",
  },
  {
    postal_code: "88341",
    zip_name: "nogal, nm",
  },
  {
    postal_code: "74061",
    zip_name: "ramona, ok",
  },
  {
    postal_code: "93283",
    zip_name: "weldon, ca",
  },
  {
    postal_code: "47720",
    zip_name: "evansville, in",
  },
  {
    postal_code: "25921",
    zip_name: "sophia, wv",
  },
  {
    postal_code: "76933",
    zip_name: "bronte, tx",
  },
  {
    postal_code: "18109",
    zip_name: "allentown, pa",
  },
  {
    postal_code: "14085",
    zip_name: "lake view, ny",
  },
  {
    postal_code: "14070",
    zip_name: "gowanda, ny",
  },
  {
    postal_code: "14103",
    zip_name: "medina, ny",
  },
  {
    postal_code: "54750",
    zip_name: "maiden rock, wi",
  },
  {
    postal_code: "11796",
    zip_name: "west sayville, ny",
  },
  {
    postal_code: "49814",
    zip_name: "champion, mi",
  },
  {
    postal_code: "45678",
    zip_name: "scottown, oh",
  },
  {
    postal_code: "97018",
    zip_name: "columbia city, or",
  },
  {
    postal_code: "55924",
    zip_name: "claremont, mn",
  },
  {
    postal_code: "52042",
    zip_name: "edgewood, ia",
  },
  {
    postal_code: "1773",
    zip_name: "lincoln, ma",
  },
  {
    postal_code: "74029",
    zip_name: "dewey, ok",
  },
  {
    postal_code: "14068",
    zip_name: "getzville, ny",
  },
  {
    postal_code: "52316",
    zip_name: "north english, ia",
  },
  {
    postal_code: "74365",
    zip_name: "salina, ok",
  },
  {
    postal_code: "43511",
    zip_name: "custar, oh",
  },
  {
    postal_code: "65761",
    zip_name: "theodosia, mo",
  },
  {
    postal_code: "74020",
    zip_name: "cleveland, ok",
  },
  {
    postal_code: "7438",
    zip_name: "oak ridge, nj",
  },
  {
    postal_code: "74427",
    zip_name: "cookson, ok",
  },
  {
    postal_code: "74435",
    zip_name: "gore, ok",
  },
  {
    postal_code: "76950",
    zip_name: "sonora, tx",
  },
  {
    postal_code: "74331",
    zip_name: "afton, ok",
  },
  {
    postal_code: "41143",
    zip_name: "grayson, ky",
  },
  {
    postal_code: "85615",
    zip_name: "hereford, az",
  },
  {
    postal_code: "74859",
    zip_name: "okemah, ok",
  },
  {
    postal_code: "66434",
    zip_name: "hiawatha, ks",
  },
  {
    postal_code: "72405",
    zip_name: "jonesboro, ar",
  },
  {
    postal_code: "2723",
    zip_name: "fall river, ma",
  },
  {
    postal_code: "17307",
    zip_name: "biglerville, pa",
  },
  {
    postal_code: "74342",
    zip_name: "eucha, ok",
  },
  {
    postal_code: "1005",
    zip_name: "barre, ma",
  },
  {
    postal_code: "74012",
    zip_name: "broken arrow, ok",
  },
  {
    postal_code: "74429",
    zip_name: "coweta, ok",
  },
  {
    postal_code: "35058",
    zip_name: "cullman, al",
  },
  {
    postal_code: "87901",
    zip_name: "truth or consequences, nm",
  },
  {
    postal_code: "74437",
    zip_name: "henryetta, ok",
  },
  {
    postal_code: "84759",
    zip_name: "panguitch, ut",
  },
  {
    postal_code: "46902",
    zip_name: "kokomo, in",
  },
  {
    postal_code: "67530",
    zip_name: "great bend, ks",
  },
  {
    postal_code: "8202",
    zip_name: "avalon, nj",
  },
  {
    postal_code: "54529",
    zip_name: "harshaw, wi",
  },
  {
    postal_code: "7095",
    zip_name: "woodbridge, nj",
  },
  {
    postal_code: "10541",
    zip_name: "mahopac, ny",
  },
  {
    postal_code: "10801",
    zip_name: "new rochelle, ny",
  },
  {
    postal_code: "84027",
    zip_name: "fruitland, ut",
  },
  {
    postal_code: "29726",
    zip_name: "mc connells, sc",
  },
  {
    postal_code: "14305",
    zip_name: "niagara falls, ny",
  },
  {
    postal_code: "97051",
    zip_name: "saint helens, or",
  },
  {
    postal_code: "98840",
    zip_name: "okanogan, wa",
  },
  {
    postal_code: "96779",
    zip_name: "paia, hi",
  },
  {
    postal_code: "97006",
    zip_name: "beaverton, or",
  },
  {
    postal_code: "1585",
    zip_name: "west brookfield, ma",
  },
  {
    postal_code: "2322",
    zip_name: "avon, ma",
  },
  {
    postal_code: "52033",
    zip_name: "cascade, ia",
  },
  {
    postal_code: "10570",
    zip_name: "pleasantville, ny",
  },
  {
    postal_code: "50707",
    zip_name: "evansdale, ia",
  },
  {
    postal_code: "68776",
    zip_name: "south sioux city, ne",
  },
  {
    postal_code: "97448",
    zip_name: "junction city, or",
  },
  {
    postal_code: "48150",
    zip_name: "livonia, mi",
  },
  {
    postal_code: "74015",
    zip_name: "catoosa, ok",
  },
  {
    postal_code: "49442",
    zip_name: "muskegon, mi",
  },
  {
    postal_code: "74137",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "48304",
    zip_name: "bloomfield hills, mi",
  },
  {
    postal_code: "97411",
    zip_name: "bandon, or",
  },
  {
    postal_code: "76905",
    zip_name: "san angelo, tx",
  },
  {
    postal_code: "94567",
    zip_name: "pope valley, ca",
  },
  {
    postal_code: "47336",
    zip_name: "dunkirk, in",
  },
  {
    postal_code: "15225",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "22578",
    zip_name: "white stone, va",
  },
  {
    postal_code: "19522",
    zip_name: "fleetwood, pa",
  },
  {
    postal_code: "55717",
    zip_name: "canyon, mn",
  },
  {
    postal_code: "42133",
    zip_name: "fountain run, ky",
  },
  {
    postal_code: "74070",
    zip_name: "skiatook, ok",
  },
  {
    postal_code: "97111",
    zip_name: "carlton, or",
  },
  {
    postal_code: "47630",
    zip_name: "newburgh, in",
  },
  {
    postal_code: "74126",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "10605",
    zip_name: "white plains, ny",
  },
  {
    postal_code: "14607",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "48331",
    zip_name: "farmington, mi",
  },
  {
    postal_code: "11956",
    zip_name: "new suffolk, ny",
  },
  {
    postal_code: "76701",
    zip_name: "waco, tx",
  },
  {
    postal_code: "97063",
    zip_name: "tygh valley, or",
  },
  {
    postal_code: "68801",
    zip_name: "grand island, ne",
  },
  {
    postal_code: "43504",
    zip_name: "berkey, oh",
  },
  {
    postal_code: "74047",
    zip_name: "mounds, ok",
  },
  {
    postal_code: "4107",
    zip_name: "cape elizabeth, me",
  },
  {
    postal_code: "43116",
    zip_name: "commercial point, oh",
  },
  {
    postal_code: "43022",
    zip_name: "gambier, oh",
  },
  {
    postal_code: "46788",
    zip_name: "spencerville, in",
  },
  {
    postal_code: "41095",
    zip_name: "warsaw, ky",
  },
  {
    postal_code: "14502",
    zip_name: "macedon, ny",
  },
  {
    postal_code: "15349",
    zip_name: "mount morris, pa",
  },
  {
    postal_code: "18334",
    zip_name: "long pond, pa",
  },
  {
    postal_code: "8079",
    zip_name: "salem, nj",
  },
  {
    postal_code: "74119",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "6039",
    zip_name: "lakeville, ct",
  },
  {
    postal_code: "21874",
    zip_name: "willards, md",
  },
  {
    postal_code: "15419",
    zip_name: "california, pa",
  },
  {
    postal_code: "74129",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "42064",
    zip_name: "marion, ky",
  },
  {
    postal_code: "31548",
    zip_name: "kingsland, ga",
  },
  {
    postal_code: "8053",
    zip_name: "marlton, nj",
  },
  {
    postal_code: "72648",
    zip_name: "marble falls, ar",
  },
  {
    postal_code: "83554",
    zip_name: "white bird, id",
  },
  {
    postal_code: "97457",
    zip_name: "myrtle creek, or",
  },
  {
    postal_code: "10524",
    zip_name: "garrison, ny",
  },
  {
    postal_code: "46540",
    zip_name: "middlebury, in",
  },
  {
    postal_code: "70449",
    zip_name: "maurepas, la",
  },
  {
    postal_code: "12721",
    zip_name: "bloomingburg, ny",
  },
  {
    postal_code: "14610",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "98105",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "17841",
    zip_name: "mc clure, pa",
  },
  {
    postal_code: "14727",
    zip_name: "cuba, ny",
  },
  {
    postal_code: "51638",
    zip_name: "essex, ia",
  },
  {
    postal_code: "79322",
    zip_name: "crosbyton, tx",
  },
  {
    postal_code: "17025",
    zip_name: "enola, pa",
  },
  {
    postal_code: "12531",
    zip_name: "holmes, ny",
  },
  {
    postal_code: "8068",
    zip_name: "pemberton, nj",
  },
  {
    postal_code: "35057",
    zip_name: "cullman, al",
  },
  {
    postal_code: "26704",
    zip_name: "augusta, wv",
  },
  {
    postal_code: "10510",
    zip_name: "briarcliff manor, ny",
  },
  {
    postal_code: "6031",
    zip_name: "falls village, ct",
  },
  {
    postal_code: "11765",
    zip_name: "mill neck, ny",
  },
  {
    postal_code: "35053",
    zip_name: "crane hill, al",
  },
  {
    postal_code: "58270",
    zip_name: "park river, nd",
  },
  {
    postal_code: "16601",
    zip_name: "altoona, pa",
  },
  {
    postal_code: "17360",
    zip_name: "seven valleys, pa",
  },
  {
    postal_code: "14111",
    zip_name: "north collins, ny",
  },
  {
    postal_code: "97212",
    zip_name: "portland, or",
  },
  {
    postal_code: "47715",
    zip_name: "evansville, in",
  },
  {
    postal_code: "49895",
    zip_name: "wetmore, mi",
  },
  {
    postal_code: "73086",
    zip_name: "sulphur, ok",
  },
  {
    postal_code: "97435",
    zip_name: "drain, or",
  },
  {
    postal_code: "14301",
    zip_name: "niagara falls, ny",
  },
  {
    postal_code: "97106",
    zip_name: "banks, or",
  },
  {
    postal_code: "97458",
    zip_name: "myrtle point, or",
  },
  {
    postal_code: "62691",
    zip_name: "virginia, il",
  },
  {
    postal_code: "47520",
    zip_name: "cannelton, in",
  },
  {
    postal_code: "97876",
    zip_name: "summerville, or",
  },
  {
    postal_code: "70543",
    zip_name: "iota, la",
  },
  {
    postal_code: "15338",
    zip_name: "greensboro, pa",
  },
  {
    postal_code: "14214",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "11980",
    zip_name: "yaphank, ny",
  },
  {
    postal_code: "87740",
    zip_name: "raton, nm",
  },
  {
    postal_code: "62082",
    zip_name: "roodhouse, il",
  },
  {
    postal_code: "47342",
    zip_name: "gaston, in",
  },
  {
    postal_code: "1929",
    zip_name: "essex, ma",
  },
  {
    postal_code: "14861",
    zip_name: "lowman, ny",
  },
  {
    postal_code: "67846",
    zip_name: "garden city, ks",
  },
  {
    postal_code: "6242",
    zip_name: "eastford, ct",
  },
  {
    postal_code: "31632",
    zip_name: "hahira, ga",
  },
  {
    postal_code: "19530",
    zip_name: "kutztown, pa",
  },
  {
    postal_code: "73801",
    zip_name: "woodward, ok",
  },
  {
    postal_code: "11742",
    zip_name: "holtsville, ny",
  },
  {
    postal_code: "74033",
    zip_name: "glenpool, ok",
  },
  {
    postal_code: "39365",
    zip_name: "union, ms",
  },
  {
    postal_code: "8751",
    zip_name: "seaside heights, nj",
  },
  {
    postal_code: "31605",
    zip_name: "valdosta, ga",
  },
  {
    postal_code: "97023",
    zip_name: "estacada, or",
  },
  {
    postal_code: "18031",
    zip_name: "breinigsville, pa",
  },
  {
    postal_code: "1534",
    zip_name: "northbridge, ma",
  },
  {
    postal_code: "85173",
    zip_name: "superior, az",
  },
  {
    postal_code: "1612",
    zip_name: "paxton, ma",
  },
  {
    postal_code: "74451",
    zip_name: "park hill, ok",
  },
  {
    postal_code: "97210",
    zip_name: "portland, or",
  },
  {
    postal_code: "74462",
    zip_name: "stigler, ok",
  },
  {
    postal_code: "86047",
    zip_name: "winslow, az",
  },
  {
    postal_code: "24363",
    zip_name: "mouth of wilson, va",
  },
  {
    postal_code: "39119",
    zip_name: "mount olive, ms",
  },
  {
    postal_code: "1503",
    zip_name: "berlin, ma",
  },
  {
    postal_code: "46985",
    zip_name: "star city, in",
  },
  {
    postal_code: "14005",
    zip_name: "alexander, ny",
  },
  {
    postal_code: "88130",
    zip_name: "portales, nm",
  },
  {
    postal_code: "30439",
    zip_name: "metter, ga",
  },
  {
    postal_code: "10701",
    zip_name: "yonkers, ny",
  },
  {
    postal_code: "95461",
    zip_name: "middletown, ca",
  },
  {
    postal_code: "61285",
    zip_name: "thomson, il",
  },
  {
    postal_code: "38751",
    zip_name: "indianola, ms",
  },
  {
    postal_code: "48101",
    zip_name: "allen park, mi",
  },
  {
    postal_code: "18229",
    zip_name: "jim thorpe, pa",
  },
  {
    postal_code: "49307",
    zip_name: "big rapids, mi",
  },
  {
    postal_code: "52151",
    zip_name: "lansing, ia",
  },
  {
    postal_code: "97537",
    zip_name: "rogue river, or",
  },
  {
    postal_code: "7430",
    zip_name: "mahwah, nj",
  },
  {
    postal_code: "56222",
    zip_name: "clara city, mn",
  },
  {
    postal_code: "22539",
    zip_name: "reedville, va",
  },
  {
    postal_code: "47338",
    zip_name: "eaton, in",
  },
  {
    postal_code: "18434",
    zip_name: "jessup, pa",
  },
  {
    postal_code: "8403",
    zip_name: "longport, nj",
  },
  {
    postal_code: "60657",
    zip_name: "chicago, il",
  },
  {
    postal_code: "77429",
    zip_name: "cypress, tx",
  },
  {
    postal_code: "30135",
    zip_name: "douglasville, ga",
  },
  {
    postal_code: "78642",
    zip_name: "liberty hill, tx",
  },
  {
    postal_code: "83212",
    zip_name: "arbon, id",
  },
  {
    postal_code: "43156",
    zip_name: "tarlton, oh",
  },
  {
    postal_code: "36768",
    zip_name: "pine apple, al",
  },
  {
    postal_code: "98815",
    zip_name: "cashmere, wa",
  },
  {
    postal_code: "28456",
    zip_name: "riegelwood, nc",
  },
  {
    postal_code: "29448",
    zip_name: "harleyville, sc",
  },
  {
    postal_code: "5761",
    zip_name: "pawlet, vt",
  },
  {
    postal_code: "5744",
    zip_name: "florence, vt",
  },
  {
    postal_code: "40007",
    zip_name: "bethlehem, ky",
  },
  {
    postal_code: "12463",
    zip_name: "palenville, ny",
  },
  {
    postal_code: "43037",
    zip_name: "martinsburg, oh",
  },
  {
    postal_code: "26426",
    zip_name: "salem, wv",
  },
  {
    postal_code: "80122",
    zip_name: "littleton, co",
  },
  {
    postal_code: "49055",
    zip_name: "gobles, mi",
  },
  {
    postal_code: "56037",
    zip_name: "good thunder, mn",
  },
  {
    postal_code: "43764",
    zip_name: "new lexington, oh",
  },
  {
    postal_code: "50229",
    zip_name: "prole, ia",
  },
  {
    postal_code: "61313",
    zip_name: "blackstone, il",
  },
  {
    postal_code: "61273",
    zip_name: "orion, il",
  },
  {
    postal_code: "48076",
    zip_name: "southfield, mi",
  },
  {
    postal_code: "42170",
    zip_name: "woodburn, ky",
  },
  {
    postal_code: "30510",
    zip_name: "alto, ga",
  },
  {
    postal_code: "1107",
    zip_name: "springfield, ma",
  },
  {
    postal_code: "74058",
    zip_name: "pawnee, ok",
  },
  {
    postal_code: "22060",
    zip_name: "fort belvoir, va",
  },
  {
    postal_code: "71328",
    zip_name: "deville, la",
  },
  {
    postal_code: "44608",
    zip_name: "beach city, oh",
  },
  {
    postal_code: "13402",
    zip_name: "madison, ny",
  },
  {
    postal_code: "83705",
    zip_name: "boise, id",
  },
  {
    postal_code: "16346",
    zip_name: "seneca, pa",
  },
  {
    postal_code: "65641",
    zip_name: "eagle rock, mo",
  },
  {
    postal_code: "42086",
    zip_name: "west paducah, ky",
  },
  {
    postal_code: "80108",
    zip_name: "castle rock, co",
  },
  {
    postal_code: "49659",
    zip_name: "mancelona, mi",
  },
  {
    postal_code: "80231",
    zip_name: "denver, co",
  },
  {
    postal_code: "61884",
    zip_name: "white heath, il",
  },
  {
    postal_code: "50514",
    zip_name: "armstrong, ia",
  },
  {
    postal_code: "66943",
    zip_name: "greenleaf, ks",
  },
  {
    postal_code: "72841",
    zip_name: "harvey, ar",
  },
  {
    postal_code: "62218",
    zip_name: "bartelso, il",
  },
  {
    postal_code: "31630",
    zip_name: "du pont, ga",
  },
  {
    postal_code: "50020",
    zip_name: "anita, ia",
  },
  {
    postal_code: "2802",
    zip_name: "albion, ri",
  },
  {
    postal_code: "42354",
    zip_name: "mc henry, ky",
  },
  {
    postal_code: "66936",
    zip_name: "burr oak, ks",
  },
  {
    postal_code: "54524",
    zip_name: "fifield, wi",
  },
  {
    postal_code: "67457",
    zip_name: "little river, ks",
  },
  {
    postal_code: "64862",
    zip_name: "sarcoxie, mo",
  },
  {
    postal_code: "8317",
    zip_name: "dorothy, nj",
  },
  {
    postal_code: "24012",
    zip_name: "roanoke, va",
  },
  {
    postal_code: "13156",
    zip_name: "sterling, ny",
  },
  {
    postal_code: "99031",
    zip_name: "spangle, wa",
  },
  {
    postal_code: "54759",
    zip_name: "pepin, wi",
  },
  {
    postal_code: "17584",
    zip_name: "willow street, pa",
  },
  {
    postal_code: "80030",
    zip_name: "westminster, co",
  },
  {
    postal_code: "61020",
    zip_name: "davis junction, il",
  },
  {
    postal_code: "12913",
    zip_name: "bloomingdale, ny",
  },
  {
    postal_code: "70554",
    zip_name: "mamou, la",
  },
  {
    postal_code: "47940",
    zip_name: "darlington, in",
  },
  {
    postal_code: "72131",
    zip_name: "quitman, ar",
  },
  {
    postal_code: "57718",
    zip_name: "black hawk, sd",
  },
  {
    postal_code: "74701",
    zip_name: "durant, ok",
  },
  {
    postal_code: "13471",
    zip_name: "taberg, ny",
  },
  {
    postal_code: "48067",
    zip_name: "royal oak, mi",
  },
  {
    postal_code: "28430",
    zip_name: "cerro gordo, nc",
  },
  {
    postal_code: "5039",
    zip_name: "corinth, vt",
  },
  {
    postal_code: "32709",
    zip_name: "christmas, fl",
  },
  {
    postal_code: "53081",
    zip_name: "sheboygan, wi",
  },
  {
    postal_code: "47112",
    zip_name: "corydon, in",
  },
  {
    postal_code: "43317",
    zip_name: "chesterville, oh",
  },
  {
    postal_code: "82721",
    zip_name: "moorcroft, wy",
  },
  {
    postal_code: "38060",
    zip_name: "oakland, tn",
  },
  {
    postal_code: "75451",
    zip_name: "leesburg, tx",
  },
  {
    postal_code: "27813",
    zip_name: "black creek, nc",
  },
  {
    postal_code: "18456",
    zip_name: "prompton, pa",
  },
  {
    postal_code: "51467",
    zip_name: "westside, ia",
  },
  {
    postal_code: "3751",
    zip_name: "georges mills, nh",
  },
  {
    postal_code: "24539",
    zip_name: "crystal hill, va",
  },
  {
    postal_code: "70654",
    zip_name: "mittie, la",
  },
  {
    postal_code: "80612",
    zip_name: "carr, co",
  },
  {
    postal_code: "51575",
    zip_name: "treynor, ia",
  },
  {
    postal_code: "37143",
    zip_name: "pegram, tn",
  },
  {
    postal_code: "59872",
    zip_name: "superior, mt",
  },
  {
    postal_code: "62629",
    zip_name: "chatham, il",
  },
  {
    postal_code: "12305",
    zip_name: "schenectady, ny",
  },
  {
    postal_code: "13810",
    zip_name: "mount vision, ny",
  },
  {
    postal_code: "43413",
    zip_name: "cygnet, oh",
  },
  {
    postal_code: "77577",
    zip_name: "liverpool, tx",
  },
  {
    postal_code: "55757",
    zip_name: "kettle river, mn",
  },
  {
    postal_code: "69356",
    zip_name: "minatare, ne",
  },
  {
    postal_code: "1245",
    zip_name: "monterey, ma",
  },
  {
    postal_code: "15692",
    zip_name: "westmoreland city, pa",
  },
  {
    postal_code: "24324",
    zip_name: "draper, va",
  },
  {
    postal_code: "42210",
    zip_name: "brownsville, ky",
  },
  {
    postal_code: "36091",
    zip_name: "verbena, al",
  },
  {
    postal_code: "80136",
    zip_name: "strasburg, co",
  },
  {
    postal_code: "23950",
    zip_name: "la crosse, va",
  },
  {
    postal_code: "74865",
    zip_name: "roff, ok",
  },
  {
    postal_code: "18201",
    zip_name: "hazleton, pa",
  },
  {
    postal_code: "44093",
    zip_name: "williamsfield, oh",
  },
  {
    postal_code: "48532",
    zip_name: "flint, mi",
  },
  {
    postal_code: "81327",
    zip_name: "lewis, co",
  },
  {
    postal_code: "56175",
    zip_name: "tracy, mn",
  },
  {
    postal_code: "47042",
    zip_name: "versailles, in",
  },
  {
    postal_code: "26416",
    zip_name: "philippi, wv",
  },
  {
    postal_code: "30230",
    zip_name: "hogansville, ga",
  },
  {
    postal_code: "75447",
    zip_name: "ivanhoe, tx",
  },
  {
    postal_code: "10453",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "64788",
    zip_name: "urich, mo",
  },
  {
    postal_code: "8821",
    zip_name: "flagtown, nj",
  },
  {
    postal_code: "18087",
    zip_name: "trexlertown, pa",
  },
  {
    postal_code: "80832",
    zip_name: "ramah, co",
  },
  {
    postal_code: "30286",
    zip_name: "thomaston, ga",
  },
  {
    postal_code: "77435",
    zip_name: "east bernard, tx",
  },
  {
    postal_code: "92105",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "47537",
    zip_name: "gentryville, in",
  },
  {
    postal_code: "64066",
    zip_name: "levasy, mo",
  },
  {
    postal_code: "84516",
    zip_name: "clawson, ut",
  },
  {
    postal_code: "55917",
    zip_name: "blooming prairie, mn",
  },
  {
    postal_code: "44688",
    zip_name: "waynesburg, oh",
  },
  {
    postal_code: "42330",
    zip_name: "central city, ky",
  },
  {
    postal_code: "60029",
    zip_name: "golf, il",
  },
  {
    postal_code: "24361",
    zip_name: "meadowview, va",
  },
  {
    postal_code: "54082",
    zip_name: "houlton, wi",
  },
  {
    postal_code: "1862",
    zip_name: "north billerica, ma",
  },
  {
    postal_code: "86413",
    zip_name: "golden valley, az",
  },
  {
    postal_code: "55939",
    zip_name: "harmony, mn",
  },
  {
    postal_code: "18053",
    zip_name: "germansville, pa",
  },
  {
    postal_code: "43837",
    zip_name: "port washington, oh",
  },
  {
    postal_code: "75503",
    zip_name: "texarkana, tx",
  },
  {
    postal_code: "5142",
    zip_name: "cavendish, vt",
  },
  {
    postal_code: "13490",
    zip_name: "westmoreland, ny",
  },
  {
    postal_code: "55971",
    zip_name: "rushford, mn",
  },
  {
    postal_code: "35135",
    zip_name: "riverside, al",
  },
  {
    postal_code: "70067",
    zip_name: "lafitte, la",
  },
  {
    postal_code: "30257",
    zip_name: "milner, ga",
  },
  {
    postal_code: "30525",
    zip_name: "clayton, ga",
  },
  {
    postal_code: "73531",
    zip_name: "devol, ok",
  },
  {
    postal_code: "3455",
    zip_name: "marlborough, nh",
  },
  {
    postal_code: "37151",
    zip_name: "riddleton, tn",
  },
  {
    postal_code: "30438",
    zip_name: "manassas, ga",
  },
  {
    postal_code: "51441",
    zip_name: "deloit, ia",
  },
  {
    postal_code: "61327",
    zip_name: "hennepin, il",
  },
  {
    postal_code: "18232",
    zip_name: "lansford, pa",
  },
  {
    postal_code: "48375",
    zip_name: "novi, mi",
  },
  {
    postal_code: "72125",
    zip_name: "perry, ar",
  },
  {
    postal_code: "48890",
    zip_name: "sunfield, mi",
  },
  {
    postal_code: "45390",
    zip_name: "union city, oh",
  },
  {
    postal_code: "65753",
    zip_name: "sparta, mo",
  },
  {
    postal_code: "47941",
    zip_name: "dayton, in",
  },
  {
    postal_code: "61832",
    zip_name: "danville, il",
  },
  {
    postal_code: "70721",
    zip_name: "carville, la",
  },
  {
    postal_code: "48307",
    zip_name: "rochester, mi",
  },
  {
    postal_code: "66087",
    zip_name: "troy, ks",
  },
  {
    postal_code: "30736",
    zip_name: "ringgold, ga",
  },
  {
    postal_code: "30545",
    zip_name: "helen, ga",
  },
  {
    postal_code: "91204",
    zip_name: "glendale, ca",
  },
  {
    postal_code: "54542",
    zip_name: "long lake, wi",
  },
  {
    postal_code: "29944",
    zip_name: "varnville, sc",
  },
  {
    postal_code: "95465",
    zip_name: "occidental, ca",
  },
  {
    postal_code: "60936",
    zip_name: "gibson city, il",
  },
  {
    postal_code: "64139",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "50104",
    zip_name: "gibson, ia",
  },
  {
    postal_code: "25009",
    zip_name: "ashford, wv",
  },
  {
    postal_code: "66091",
    zip_name: "welda, ks",
  },
  {
    postal_code: "67741",
    zip_name: "kanorado, ks",
  },
  {
    postal_code: "65591",
    zip_name: "montreal, mo",
  },
  {
    postal_code: "30522",
    zip_name: "cherry log, ga",
  },
  {
    postal_code: "7735",
    zip_name: "keyport, nj",
  },
  {
    postal_code: "24055",
    zip_name: "bassett, va",
  },
  {
    postal_code: "32606",
    zip_name: "gainesville, fl",
  },
  {
    postal_code: "48309",
    zip_name: "rochester, mi",
  },
  {
    postal_code: "95703",
    zip_name: "applegate, ca",
  },
  {
    postal_code: "56528",
    zip_name: "dent, mn",
  },
  {
    postal_code: "96019",
    zip_name: "shasta lake, ca",
  },
  {
    postal_code: "38866",
    zip_name: "saltillo, ms",
  },
  {
    postal_code: "48193",
    zip_name: "riverview, mi",
  },
  {
    postal_code: "2149",
    zip_name: "everett, ma",
  },
  {
    postal_code: "62870",
    zip_name: "odin, il",
  },
  {
    postal_code: "89402",
    zip_name: "crystal bay, nv",
  },
  {
    postal_code: "27239",
    zip_name: "denton, nc",
  },
  {
    postal_code: "64771",
    zip_name: "moundville, mo",
  },
  {
    postal_code: "60129",
    zip_name: "esmond, il",
  },
  {
    postal_code: "50249",
    zip_name: "stratford, ia",
  },
  {
    postal_code: "31793",
    zip_name: "tifton, ga",
  },
  {
    postal_code: "18222",
    zip_name: "drums, pa",
  },
  {
    postal_code: "73401",
    zip_name: "ardmore, ok",
  },
  {
    postal_code: "74964",
    zip_name: "watts, ok",
  },
  {
    postal_code: "24101",
    zip_name: "hardy, va",
  },
  {
    postal_code: "44710",
    zip_name: "canton, oh",
  },
  {
    postal_code: "76530",
    zip_name: "granger, tx",
  },
  {
    postal_code: "63558",
    zip_name: "new cambria, mo",
  },
  {
    postal_code: "68855",
    zip_name: "mason city, ne",
  },
  {
    postal_code: "59724",
    zip_name: "dell, mt",
  },
  {
    postal_code: "16845",
    zip_name: "karthaus, pa",
  },
  {
    postal_code: "28357",
    zip_name: "lumber bridge, nc",
  },
  {
    postal_code: "51579",
    zip_name: "woodbine, ia",
  },
  {
    postal_code: "70080",
    zip_name: "paradis, la",
  },
  {
    postal_code: "84652",
    zip_name: "redmond, ut",
  },
  {
    postal_code: "64022",
    zip_name: "dover, mo",
  },
  {
    postal_code: "1951",
    zip_name: "newbury, ma",
  },
  {
    postal_code: "13796",
    zip_name: "laurens, ny",
  },
  {
    postal_code: "55932",
    zip_name: "elgin, mn",
  },
  {
    postal_code: "62931",
    zip_name: "elizabethtown, il",
  },
  {
    postal_code: "29915",
    zip_name: "daufuskie island, sc",
  },
  {
    postal_code: "4779",
    zip_name: "sinclair, me",
  },
  {
    postal_code: "95321",
    zip_name: "groveland, ca",
  },
  {
    postal_code: "15714",
    zip_name: "northern cambria, pa",
  },
  {
    postal_code: "14609",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "59874",
    zip_name: "trout creek, mt",
  },
  {
    postal_code: "12067",
    zip_name: "feura bush, ny",
  },
  {
    postal_code: "56024",
    zip_name: "eagle lake, mn",
  },
  {
    postal_code: "63673",
    zip_name: "saint mary, mo",
  },
  {
    postal_code: "27970",
    zip_name: "roper, nc",
  },
  {
    postal_code: "37308",
    zip_name: "birchwood, tn",
  },
  {
    postal_code: "61039",
    zip_name: "german valley, il",
  },
  {
    postal_code: "16232",
    zip_name: "knox, pa",
  },
  {
    postal_code: "64724",
    zip_name: "appleton city, mo",
  },
  {
    postal_code: "82440",
    zip_name: "ralston, wy",
  },
  {
    postal_code: "5041",
    zip_name: "east randolph, vt",
  },
  {
    postal_code: "50272",
    zip_name: "williamson, ia",
  },
  {
    postal_code: "79544",
    zip_name: "rochester, tx",
  },
  {
    postal_code: "58254",
    zip_name: "mcville, nd",
  },
  {
    postal_code: "44652",
    zip_name: "middlebranch, oh",
  },
  {
    postal_code: "56098",
    zip_name: "winnebago, mn",
  },
  {
    postal_code: "78226",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "65714",
    zip_name: "nixa, mo",
  },
  {
    postal_code: "93604",
    zip_name: "bass lake, ca",
  },
  {
    postal_code: "15956",
    zip_name: "south fork, pa",
  },
  {
    postal_code: "30662",
    zip_name: "royston, ga",
  },
  {
    postal_code: "2324",
    zip_name: "bridgewater, ma",
  },
  {
    postal_code: "64734",
    zip_name: "cleveland, mo",
  },
  {
    postal_code: "43009",
    zip_name: "cable, oh",
  },
  {
    postal_code: "49106",
    zip_name: "bridgman, mi",
  },
  {
    postal_code: "77957",
    zip_name: "edna, tx",
  },
  {
    postal_code: "54757",
    zip_name: "new auburn, wi",
  },
  {
    postal_code: "49912",
    zip_name: "bruce crossing, mi",
  },
  {
    postal_code: "19967",
    zip_name: "millville, de",
  },
  {
    postal_code: "64037",
    zip_name: "higginsville, mo",
  },
  {
    postal_code: "50595",
    zip_name: "webster city, ia",
  },
  {
    postal_code: "48167",
    zip_name: "northville, mi",
  },
  {
    postal_code: "45312",
    zip_name: "casstown, oh",
  },
  {
    postal_code: "15031",
    zip_name: "cuddy, pa",
  },
  {
    postal_code: "6243",
    zip_name: "east killingly, ct",
  },
  {
    postal_code: "38670",
    zip_name: "sledge, ms",
  },
  {
    postal_code: "53599",
    zip_name: "woodford, wi",
  },
  {
    postal_code: "45164",
    zip_name: "port william, oh",
  },
  {
    postal_code: "1347",
    zip_name: "lake pleasant, ma",
  },
  {
    postal_code: "88028",
    zip_name: "cliff, nm",
  },
  {
    postal_code: "67009",
    zip_name: "attica, ks",
  },
  {
    postal_code: "21520",
    zip_name: "accident, md",
  },
  {
    postal_code: "38589",
    zip_name: "wilder, tn",
  },
  {
    postal_code: "5842",
    zip_name: "greensboro bend, vt",
  },
  {
    postal_code: "24301",
    zip_name: "pulaski, va",
  },
  {
    postal_code: "12108",
    zip_name: "lake pleasant, ny",
  },
  {
    postal_code: "49445",
    zip_name: "muskegon, mi",
  },
  {
    postal_code: "61567",
    zip_name: "topeka, il",
  },
  {
    postal_code: "70647",
    zip_name: "iowa, la",
  },
  {
    postal_code: "42120",
    zip_name: "adolphus, ky",
  },
  {
    postal_code: "35014",
    zip_name: "alpine, al",
  },
  {
    postal_code: "79553",
    zip_name: "stamford, tx",
  },
  {
    postal_code: "75965",
    zip_name: "nacogdoches, tx",
  },
  {
    postal_code: "27983",
    zip_name: "windsor, nc",
  },
  {
    postal_code: "29045",
    zip_name: "elgin, sc",
  },
  {
    postal_code: "15901",
    zip_name: "johnstown, pa",
  },
  {
    postal_code: "15027",
    zip_name: "conway, pa",
  },
  {
    postal_code: "38549",
    zip_name: "byrdstown, tn",
  },
  {
    postal_code: "50234",
    zip_name: "rhodes, ia",
  },
  {
    postal_code: "30173",
    zip_name: "silver creek, ga",
  },
  {
    postal_code: "47024",
    zip_name: "laurel, in",
  },
  {
    postal_code: "30185",
    zip_name: "whitesburg, ga",
  },
  {
    postal_code: "70392",
    zip_name: "patterson, la",
  },
  {
    postal_code: "68358",
    zip_name: "firth, ne",
  },
  {
    postal_code: "45886",
    zip_name: "scott, oh",
  },
  {
    postal_code: "15532",
    zip_name: "boynton, pa",
  },
  {
    postal_code: "61321",
    zip_name: "dana, il",
  },
  {
    postal_code: "42762",
    zip_name: "millwood, ky",
  },
  {
    postal_code: "19550",
    zip_name: "rehrersburg, pa",
  },
  {
    postal_code: "49934",
    zip_name: "hubbell, mi",
  },
  {
    postal_code: "26753",
    zip_name: "ridgeley, wv",
  },
  {
    postal_code: "24312",
    zip_name: "austinville, va",
  },
  {
    postal_code: "51054",
    zip_name: "sergeant bluff, ia",
  },
  {
    postal_code: "47330",
    zip_name: "centerville, in",
  },
  {
    postal_code: "27831",
    zip_name: "garysburg, nc",
  },
  {
    postal_code: "58250",
    zip_name: "lankin, nd",
  },
  {
    postal_code: "52765",
    zip_name: "new liberty, ia",
  },
  {
    postal_code: "41754",
    zip_name: "krypton, ky",
  },
  {
    postal_code: "15563",
    zip_name: "stoystown, pa",
  },
  {
    postal_code: "27053",
    zip_name: "westfield, nc",
  },
  {
    postal_code: "29435",
    zip_name: "cottageville, sc",
  },
  {
    postal_code: "16828",
    zip_name: "centre hall, pa",
  },
  {
    postal_code: "24137",
    zip_name: "penhook, va",
  },
  {
    postal_code: "44215",
    zip_name: "chippewa lake, oh",
  },
  {
    postal_code: "64776",
    zip_name: "osceola, mo",
  },
  {
    postal_code: "77836",
    zip_name: "caldwell, tx",
  },
  {
    postal_code: "13160",
    zip_name: "union springs, ny",
  },
  {
    postal_code: "83638",
    zip_name: "mccall, id",
  },
  {
    postal_code: "79062",
    zip_name: "morse, tx",
  },
  {
    postal_code: "41002",
    zip_name: "augusta, ky",
  },
  {
    postal_code: "55332",
    zip_name: "fairfax, mn",
  },
  {
    postal_code: "22663",
    zip_name: "white post, va",
  },
  {
    postal_code: "43343",
    zip_name: "quincy, oh",
  },
  {
    postal_code: "12808",
    zip_name: "adirondack, ny",
  },
  {
    postal_code: "13304",
    zip_name: "barneveld, ny",
  },
  {
    postal_code: "48224",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "46102",
    zip_name: "advance, in",
  },
  {
    postal_code: "34760",
    zip_name: "oakland, fl",
  },
  {
    postal_code: "49008",
    zip_name: "kalamazoo, mi",
  },
  {
    postal_code: "18250",
    zip_name: "summit hill, pa",
  },
  {
    postal_code: "26034",
    zip_name: "chester, wv",
  },
  {
    postal_code: "53803",
    zip_name: "benton, wi",
  },
  {
    postal_code: "96121",
    zip_name: "milford, ca",
  },
  {
    postal_code: "67854",
    zip_name: "jetmore, ks",
  },
  {
    postal_code: "80836",
    zip_name: "stratton, co",
  },
  {
    postal_code: "25570",
    zip_name: "wayne, wv",
  },
  {
    postal_code: "62241",
    zip_name: "ellis grove, il",
  },
  {
    postal_code: "16262",
    zip_name: "worthington, pa",
  },
  {
    postal_code: "48227",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "43462",
    zip_name: "rudolph, oh",
  },
  {
    postal_code: "83234",
    zip_name: "downey, id",
  },
  {
    postal_code: "93424",
    zip_name: "avila beach, ca",
  },
  {
    postal_code: "24088",
    zip_name: "ferrum, va",
  },
  {
    postal_code: "42041",
    zip_name: "fulton, ky",
  },
  {
    postal_code: "74563",
    zip_name: "red oak, ok",
  },
  {
    postal_code: "12737",
    zip_name: "glen spey, ny",
  },
  {
    postal_code: "44264",
    zip_name: "peninsula, oh",
  },
  {
    postal_code: "48353",
    zip_name: "hartland, mi",
  },
  {
    postal_code: "60474",
    zip_name: "south wilmington, il",
  },
  {
    postal_code: "65767",
    zip_name: "urbana, mo",
  },
  {
    postal_code: "79381",
    zip_name: "wilson, tx",
  },
  {
    postal_code: "14580",
    zip_name: "webster, ny",
  },
  {
    postal_code: "93428",
    zip_name: "cambria, ca",
  },
  {
    postal_code: "69145",
    zip_name: "kimball, ne",
  },
  {
    postal_code: "52302",
    zip_name: "marion, ia",
  },
  {
    postal_code: "61849",
    zip_name: "homer, il",
  },
  {
    postal_code: "27024",
    zip_name: "lowgap, nc",
  },
  {
    postal_code: "25239",
    zip_name: "cottageville, wv",
  },
  {
    postal_code: "85532",
    zip_name: "claypool, az",
  },
  {
    postal_code: "37710",
    zip_name: "briceville, tn",
  },
  {
    postal_code: "16444",
    zip_name: "edinboro, pa",
  },
  {
    postal_code: "15760",
    zip_name: "marsteller, pa",
  },
  {
    postal_code: "95720",
    zip_name: "kyburz, ca",
  },
  {
    postal_code: "61852",
    zip_name: "longview, il",
  },
  {
    postal_code: "41751",
    zip_name: "jeff, ky",
  },
  {
    postal_code: "36016",
    zip_name: "clayton, al",
  },
  {
    postal_code: "3817",
    zip_name: "chocorua, nh",
  },
  {
    postal_code: "35903",
    zip_name: "gadsden, al",
  },
  {
    postal_code: "27544",
    zip_name: "kittrell, nc",
  },
  {
    postal_code: "83851",
    zip_name: "plummer, id",
  },
  {
    postal_code: "2744",
    zip_name: "new bedford, ma",
  },
  {
    postal_code: "65807",
    zip_name: "springfield, mo",
  },
  {
    postal_code: "27231",
    zip_name: "cedar grove, nc",
  },
  {
    postal_code: "53541",
    zip_name: "gratiot, wi",
  },
  {
    postal_code: "56080",
    zip_name: "saint clair, mn",
  },
  {
    postal_code: "20751",
    zip_name: "deale, md",
  },
  {
    postal_code: "75925",
    zip_name: "alto, tx",
  },
  {
    postal_code: "28018",
    zip_name: "bostic, nc",
  },
  {
    postal_code: "92119",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "17349",
    zip_name: "new freedom, pa",
  },
  {
    postal_code: "14530",
    zip_name: "perry, ny",
  },
  {
    postal_code: "65330",
    zip_name: "gilliam, mo",
  },
  {
    postal_code: "54159",
    zip_name: "porterfield, wi",
  },
  {
    postal_code: "12926",
    zip_name: "constable, ny",
  },
  {
    postal_code: "19501",
    zip_name: "adamstown, pa",
  },
  {
    postal_code: "30521",
    zip_name: "carnesville, ga",
  },
  {
    postal_code: "75479",
    zip_name: "savoy, tx",
  },
  {
    postal_code: "14615",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "5037",
    zip_name: "brownsville, vt",
  },
  {
    postal_code: "77835",
    zip_name: "burton, tx",
  },
  {
    postal_code: "56567",
    zip_name: "new york mills, mn",
  },
  {
    postal_code: "58353",
    zip_name: "mylo, nd",
  },
  {
    postal_code: "97056",
    zip_name: "scappoose, or",
  },
  {
    postal_code: "43901",
    zip_name: "adena, oh",
  },
  {
    postal_code: "49749",
    zip_name: "indian river, mi",
  },
  {
    postal_code: "56634",
    zip_name: "clearbrook, mn",
  },
  {
    postal_code: "28090",
    zip_name: "lawndale, nc",
  },
  {
    postal_code: "68620",
    zip_name: "albion, ne",
  },
  {
    postal_code: "1524",
    zip_name: "leicester, ma",
  },
  {
    postal_code: "21104",
    zip_name: "marriottsville, md",
  },
  {
    postal_code: "48640",
    zip_name: "midland, mi",
  },
  {
    postal_code: "55019",
    zip_name: "dundas, mn",
  },
  {
    postal_code: "43154",
    zip_name: "stoutsville, oh",
  },
  {
    postal_code: "48174",
    zip_name: "romulus, mi",
  },
  {
    postal_code: "38506",
    zip_name: "cookeville, tn",
  },
  {
    postal_code: "22125",
    zip_name: "occoquan, va",
  },
  {
    postal_code: "30808",
    zip_name: "dearing, ga",
  },
  {
    postal_code: "52405",
    zip_name: "cedar rapids, ia",
  },
  {
    postal_code: "13493",
    zip_name: "williamstown, ny",
  },
  {
    postal_code: "64849",
    zip_name: "neck city, mo",
  },
  {
    postal_code: "14815",
    zip_name: "bradford, ny",
  },
  {
    postal_code: "45843",
    zip_name: "forest, oh",
  },
  {
    postal_code: "97003",
    zip_name: "beaverton, or",
  },
  {
    postal_code: "56736",
    zip_name: "mentor, mn",
  },
  {
    postal_code: "49265",
    zip_name: "onsted, mi",
  },
  {
    postal_code: "13403",
    zip_name: "marcy, ny",
  },
  {
    postal_code: "77872",
    zip_name: "north zulch, tx",
  },
  {
    postal_code: "97130",
    zip_name: "manzanita, or",
  },
  {
    postal_code: "99169",
    zip_name: "ritzville, wa",
  },
  {
    postal_code: "49884",
    zip_name: "shingleton, mi",
  },
  {
    postal_code: "42038",
    zip_name: "eddyville, ky",
  },
  {
    postal_code: "1259",
    zip_name: "southfield, ma",
  },
  {
    postal_code: "70785",
    zip_name: "walker, la",
  },
  {
    postal_code: "48146",
    zip_name: "lincoln park, mi",
  },
  {
    postal_code: "31804",
    zip_name: "cataula, ga",
  },
  {
    postal_code: "74469",
    zip_name: "warner, ok",
  },
  {
    postal_code: "75476",
    zip_name: "ravenna, tx",
  },
  {
    postal_code: "68724",
    zip_name: "center, ne",
  },
  {
    postal_code: "28575",
    zip_name: "salter path, nc",
  },
  {
    postal_code: "16820",
    zip_name: "aaronsburg, pa",
  },
  {
    postal_code: "26588",
    zip_name: "rivesville, wv",
  },
  {
    postal_code: "70090",
    zip_name: "vacherie, la",
  },
  {
    postal_code: "42160",
    zip_name: "park city, ky",
  },
  {
    postal_code: "44064",
    zip_name: "montville, oh",
  },
  {
    postal_code: "4537",
    zip_name: "boothbay, me",
  },
  {
    postal_code: "16236",
    zip_name: "mc grann, pa",
  },
  {
    postal_code: "44048",
    zip_name: "kingsville, oh",
  },
  {
    postal_code: "64109",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "48154",
    zip_name: "livonia, mi",
  },
  {
    postal_code: "13438",
    zip_name: "remsen, ny",
  },
  {
    postal_code: "43749",
    zip_name: "kimbolton, oh",
  },
  {
    postal_code: "92117",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "17520",
    zip_name: "east petersburg, pa",
  },
  {
    postal_code: "76579",
    zip_name: "troy, tx",
  },
  {
    postal_code: "1224",
    zip_name: "berkshire, ma",
  },
  {
    postal_code: "96772",
    zip_name: "naalehu, hi",
  },
  {
    postal_code: "20763",
    zip_name: "savage, md",
  },
  {
    postal_code: "55973",
    zip_name: "sargeant, mn",
  },
  {
    postal_code: "45662",
    zip_name: "portsmouth, oh",
  },
  {
    postal_code: "98926",
    zip_name: "ellensburg, wa",
  },
  {
    postal_code: "42044",
    zip_name: "gilbertsville, ky",
  },
  {
    postal_code: "68371",
    zip_name: "henderson, ne",
  },
  {
    postal_code: "49795",
    zip_name: "vanderbilt, mi",
  },
  {
    postal_code: "45836",
    zip_name: "dunkirk, oh",
  },
  {
    postal_code: "42722",
    zip_name: "canmer, ky",
  },
  {
    postal_code: "6444",
    zip_name: "marion, ct",
  },
  {
    postal_code: "86445",
    zip_name: "willow beach, az",
  },
  {
    postal_code: "50102",
    zip_name: "garden city, ia",
  },
  {
    postal_code: "47963",
    zip_name: "morocco, in",
  },
  {
    postal_code: "50127",
    zip_name: "ira, ia",
  },
  {
    postal_code: "43445",
    zip_name: "martin, oh",
  },
  {
    postal_code: "71292",
    zip_name: "west monroe, la",
  },
  {
    postal_code: "83873",
    zip_name: "wallace, id",
  },
  {
    postal_code: "67861",
    zip_name: "leoti, ks",
  },
  {
    postal_code: "44628",
    zip_name: "glenmont, oh",
  },
  {
    postal_code: "60958",
    zip_name: "pembroke township, il",
  },
  {
    postal_code: "13454",
    zip_name: "salisbury center, ny",
  },
  {
    postal_code: "65803",
    zip_name: "springfield, mo",
  },
  {
    postal_code: "49454",
    zip_name: "scottville, mi",
  },
  {
    postal_code: "45804",
    zip_name: "lima, oh",
  },
  {
    postal_code: "13116",
    zip_name: "minoa, ny",
  },
  {
    postal_code: "16248",
    zip_name: "rimersburg, pa",
  },
  {
    postal_code: "91905",
    zip_name: "boulevard, ca",
  },
  {
    postal_code: "45659",
    zip_name: "pedro, oh",
  },
  {
    postal_code: "30290",
    zip_name: "tyrone, ga",
  },
  {
    postal_code: "74039",
    zip_name: "kellyville, ok",
  },
  {
    postal_code: "61334",
    zip_name: "lostant, il",
  },
  {
    postal_code: "49417",
    zip_name: "grand haven, mi",
  },
  {
    postal_code: "12937",
    zip_name: "fort covington, ny",
  },
  {
    postal_code: "48880",
    zip_name: "saint louis, mi",
  },
  {
    postal_code: "98620",
    zip_name: "goldendale, wa",
  },
  {
    postal_code: "81221",
    zip_name: "coal creek, co",
  },
  {
    postal_code: "97527",
    zip_name: "grants pass, or",
  },
  {
    postal_code: "30547",
    zip_name: "homer, ga",
  },
  {
    postal_code: "73757",
    zip_name: "lucien, ok",
  },
  {
    postal_code: "57040",
    zip_name: "lesterville, sd",
  },
  {
    postal_code: "26039",
    zip_name: "glen easton, wv",
  },
  {
    postal_code: "35097",
    zip_name: "locust fork, al",
  },
  {
    postal_code: "6258",
    zip_name: "pomfret, ct",
  },
  {
    postal_code: "46162",
    zip_name: "needham, in",
  },
  {
    postal_code: "43791",
    zip_name: "white cottage, oh",
  },
  {
    postal_code: "78349",
    zip_name: "concepcion, tx",
  },
  {
    postal_code: "79379",
    zip_name: "whiteface, tx",
  },
  {
    postal_code: "92317",
    zip_name: "blue jay, ca",
  },
  {
    postal_code: "18845",
    zip_name: "stevensville, pa",
  },
  {
    postal_code: "78417",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "22853",
    zip_name: "timberville, va",
  },
  {
    postal_code: "77983",
    zip_name: "seadrift, tx",
  },
  {
    postal_code: "97016",
    zip_name: "clatskanie, or",
  },
  {
    postal_code: "5872",
    zip_name: "west charleston, vt",
  },
  {
    postal_code: "56711",
    zip_name: "angle inlet, mn",
  },
  {
    postal_code: "74038",
    zip_name: "jennings, ok",
  },
  {
    postal_code: "97504",
    zip_name: "medford, or",
  },
  {
    postal_code: "44454",
    zip_name: "petersburg, oh",
  },
  {
    postal_code: "61570",
    zip_name: "washburn, il",
  },
  {
    postal_code: "38554",
    zip_name: "crawford, tn",
  },
  {
    postal_code: "65535",
    zip_name: "leasburg, mo",
  },
  {
    postal_code: "13813",
    zip_name: "nineveh, ny",
  },
  {
    postal_code: "38041",
    zip_name: "henning, tn",
  },
  {
    postal_code: "4030",
    zip_name: "east waterboro, me",
  },
  {
    postal_code: "43986",
    zip_name: "jewett, oh",
  },
  {
    postal_code: "48393",
    zip_name: "wixom, mi",
  },
  {
    postal_code: "24472",
    zip_name: "raphine, va",
  },
  {
    postal_code: "54541",
    zip_name: "laona, wi",
  },
  {
    postal_code: "11428",
    zip_name: "queens village, ny",
  },
  {
    postal_code: "67449",
    zip_name: "herington, ks",
  },
  {
    postal_code: "70085",
    zip_name: "saint bernard, la",
  },
  {
    postal_code: "52057",
    zip_name: "manchester, ia",
  },
  {
    postal_code: "57760",
    zip_name: "newell, sd",
  },
  {
    postal_code: "48846",
    zip_name: "ionia, mi",
  },
  {
    postal_code: "14719",
    zip_name: "cattaraugus, ny",
  },
  {
    postal_code: "47371",
    zip_name: "portland, in",
  },
  {
    postal_code: "51101",
    zip_name: "sioux city, ia",
  },
  {
    postal_code: "80801",
    zip_name: "anton, co",
  },
  {
    postal_code: "57257",
    zip_name: "peever, sd",
  },
  {
    postal_code: "62572",
    zip_name: "waggoner, il",
  },
  {
    postal_code: "30474",
    zip_name: "vidalia, ga",
  },
  {
    postal_code: "32139",
    zip_name: "georgetown, fl",
  },
  {
    postal_code: "68787",
    zip_name: "wayne, ne",
  },
  {
    postal_code: "79567",
    zip_name: "winters, tx",
  },
  {
    postal_code: "32686",
    zip_name: "reddick, fl",
  },
  {
    postal_code: "98288",
    zip_name: "skykomish, wa",
  },
  {
    postal_code: "66076",
    zip_name: "pomona, ks",
  },
  {
    postal_code: "19503",
    zip_name: "bally, pa",
  },
  {
    postal_code: "65747",
    zip_name: "shell knob, mo",
  },
  {
    postal_code: "35091",
    zip_name: "kimberly, al",
  },
  {
    postal_code: "48623",
    zip_name: "freeland, mi",
  },
  {
    postal_code: "56180",
    zip_name: "walnut grove, mn",
  },
  {
    postal_code: "54140",
    zip_name: "little chute, wi",
  },
  {
    postal_code: "75640",
    zip_name: "diana, tx",
  },
  {
    postal_code: "54618",
    zip_name: "camp douglas, wi",
  },
  {
    postal_code: "40078",
    zip_name: "willisburg, ky",
  },
  {
    postal_code: "30170",
    zip_name: "roopville, ga",
  },
  {
    postal_code: "68359",
    zip_name: "friend, ne",
  },
  {
    postal_code: "51656",
    zip_name: "yorktown, ia",
  },
  {
    postal_code: "17344",
    zip_name: "mc sherrystown, pa",
  },
  {
    postal_code: "24641",
    zip_name: "richlands, va",
  },
  {
    postal_code: "29384",
    zip_name: "waterloo, sc",
  },
  {
    postal_code: "6060",
    zip_name: "north granby, ct",
  },
  {
    postal_code: "14779",
    zip_name: "salamanca, ny",
  },
  {
    postal_code: "13112",
    zip_name: "memphis, ny",
  },
  {
    postal_code: "61516",
    zip_name: "benson, il",
  },
  {
    postal_code: "80130",
    zip_name: "littleton, co",
  },
  {
    postal_code: "3467",
    zip_name: "westmoreland, nh",
  },
  {
    postal_code: "62920",
    zip_name: "cobden, il",
  },
  {
    postal_code: "44681",
    zip_name: "sugarcreek, oh",
  },
  {
    postal_code: "5906",
    zip_name: "lunenburg, vt",
  },
  {
    postal_code: "79506",
    zip_name: "blackwell, tx",
  },
  {
    postal_code: "76665",
    zip_name: "meridian, tx",
  },
  {
    postal_code: "54856",
    zip_name: "mason, wi",
  },
  {
    postal_code: "15635",
    zip_name: "hannastown, pa",
  },
  {
    postal_code: "58486",
    zip_name: "sykeston, nd",
  },
  {
    postal_code: "74842",
    zip_name: "fittstown, ok",
  },
  {
    postal_code: "49922",
    zip_name: "dollar bay, mi",
  },
  {
    postal_code: "16036",
    zip_name: "foxburg, pa",
  },
  {
    postal_code: "48003",
    zip_name: "almont, mi",
  },
  {
    postal_code: "6027",
    zip_name: "east hartland, ct",
  },
  {
    postal_code: "72088",
    zip_name: "fairfield bay, ar",
  },
  {
    postal_code: "6796",
    zip_name: "west cornwall, ct",
  },
  {
    postal_code: "4936",
    zip_name: "eustis, me",
  },
  {
    postal_code: "54104",
    zip_name: "athelstane, wi",
  },
  {
    postal_code: "16874",
    zip_name: "snow shoe, pa",
  },
  {
    postal_code: "5101",
    zip_name: "bellows falls, vt",
  },
  {
    postal_code: "55748",
    zip_name: "hill city, mn",
  },
  {
    postal_code: "28360",
    zip_name: "lumberton, nc",
  },
  {
    postal_code: "48624",
    zip_name: "gladwin, mi",
  },
  {
    postal_code: "61074",
    zip_name: "savanna, il",
  },
  {
    postal_code: "83849",
    zip_name: "osburn, id",
  },
  {
    postal_code: "30668",
    zip_name: "tignall, ga",
  },
  {
    postal_code: "12469",
    zip_name: "preston hollow, ny",
  },
  {
    postal_code: "75750",
    zip_name: "arp, tx",
  },
  {
    postal_code: "42743",
    zip_name: "greensburg, ky",
  },
  {
    postal_code: "84330",
    zip_name: "plymouth, ut",
  },
  {
    postal_code: "61418",
    zip_name: "biggsville, il",
  },
  {
    postal_code: "53405",
    zip_name: "racine, wi",
  },
  {
    postal_code: "30552",
    zip_name: "lakemont, ga",
  },
  {
    postal_code: "41425",
    zip_name: "ezel, ky",
  },
  {
    postal_code: "16365",
    zip_name: "warren, pa",
  },
  {
    postal_code: "90704",
    zip_name: "avalon, ca",
  },
  {
    postal_code: "79245",
    zip_name: "memphis, tx",
  },
  {
    postal_code: "46175",
    zip_name: "russellville, in",
  },
  {
    postal_code: "31321",
    zip_name: "pembroke, ga",
  },
  {
    postal_code: "64632",
    zip_name: "cainsville, mo",
  },
  {
    postal_code: "81090",
    zip_name: "walsh, co",
  },
  {
    postal_code: "18702",
    zip_name: "wilkes barre, pa",
  },
  {
    postal_code: "22709",
    zip_name: "aroda, va",
  },
  {
    postal_code: "77426",
    zip_name: "chappell hill, tx",
  },
  {
    postal_code: "62868",
    zip_name: "noble, il",
  },
  {
    postal_code: "49719",
    zip_name: "cedarville, mi",
  },
  {
    postal_code: "63787",
    zip_name: "zalma, mo",
  },
  {
    postal_code: "58744",
    zip_name: "karlsruhe, nd",
  },
  {
    postal_code: "52050",
    zip_name: "greeley, ia",
  },
  {
    postal_code: "80109",
    zip_name: "castle rock, co",
  },
  {
    postal_code: "47916",
    zip_name: "alamo, in",
  },
  {
    postal_code: "60407",
    zip_name: "braceville, il",
  },
  {
    postal_code: "24450",
    zip_name: "lexington, va",
  },
  {
    postal_code: "20611",
    zip_name: "bel alton, md",
  },
  {
    postal_code: "48072",
    zip_name: "berkley, mi",
  },
  {
    postal_code: "46182",
    zip_name: "waldron, in",
  },
  {
    postal_code: "63332",
    zip_name: "augusta, mo",
  },
  {
    postal_code: "12960",
    zip_name: "moriah, ny",
  },
  {
    postal_code: "59301",
    zip_name: "miles city, mt",
  },
  {
    postal_code: "76538",
    zip_name: "jonesboro, tx",
  },
  {
    postal_code: "32094",
    zip_name: "wellborn, fl",
  },
  {
    postal_code: "24283",
    zip_name: "saint paul, va",
  },
  {
    postal_code: "38334",
    zip_name: "finger, tn",
  },
  {
    postal_code: "17004",
    zip_name: "belleville, pa",
  },
  {
    postal_code: "11020",
    zip_name: "great neck, ny",
  },
  {
    postal_code: "18091",
    zip_name: "wind gap, pa",
  },
  {
    postal_code: "92120",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "45742",
    zip_name: "little hocking, oh",
  },
  {
    postal_code: "61013",
    zip_name: "cedarville, il",
  },
  {
    postal_code: "38732",
    zip_name: "cleveland, ms",
  },
  {
    postal_code: "51560",
    zip_name: "oakland, ia",
  },
  {
    postal_code: "64726",
    zip_name: "blairstown, mo",
  },
  {
    postal_code: "47227",
    zip_name: "commiskey, in",
  },
  {
    postal_code: "98134",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "98357",
    zip_name: "neah bay, wa",
  },
  {
    postal_code: "95429",
    zip_name: "dos rios, ca",
  },
  {
    postal_code: "97213",
    zip_name: "portland, or",
  },
  {
    postal_code: "75487",
    zip_name: "talco, tx",
  },
  {
    postal_code: "80224",
    zip_name: "denver, co",
  },
  {
    postal_code: "13115",
    zip_name: "minetto, ny",
  },
  {
    postal_code: "46967",
    zip_name: "onward, in",
  },
  {
    postal_code: "94929",
    zip_name: "dillon beach, ca",
  },
  {
    postal_code: "23607",
    zip_name: "newport news, va",
  },
  {
    postal_code: "31808",
    zip_name: "fortson, ga",
  },
  {
    postal_code: "44084",
    zip_name: "rock creek, oh",
  },
  {
    postal_code: "45723",
    zip_name: "coolville, oh",
  },
  {
    postal_code: "54726",
    zip_name: "boyd, wi",
  },
  {
    postal_code: "76064",
    zip_name: "maypearl, tx",
  },
  {
    postal_code: "10573",
    zip_name: "port chester, ny",
  },
  {
    postal_code: "29229",
    zip_name: "columbia, sc",
  },
  {
    postal_code: "48381",
    zip_name: "milford, mi",
  },
  {
    postal_code: "39044",
    zip_name: "braxton, ms",
  },
  {
    postal_code: "71457",
    zip_name: "natchitoches, la",
  },
  {
    postal_code: "50171",
    zip_name: "montezuma, ia",
  },
  {
    postal_code: "61733",
    zip_name: "deer creek, il",
  },
  {
    postal_code: "8221",
    zip_name: "linwood, nj",
  },
  {
    postal_code: "45679",
    zip_name: "seaman, oh",
  },
  {
    postal_code: "54235",
    zip_name: "sturgeon bay, wi",
  },
  {
    postal_code: "83832",
    zip_name: "genesee, id",
  },
  {
    postal_code: "56143",
    zip_name: "jackson, mn",
  },
  {
    postal_code: "41408",
    zip_name: "cannel city, ky",
  },
  {
    postal_code: "98845",
    zip_name: "palisades, wa",
  },
  {
    postal_code: "95668",
    zip_name: "pleasant grove, ca",
  },
  {
    postal_code: "67341",
    zip_name: "dennis, ks",
  },
  {
    postal_code: "75471",
    zip_name: "pickton, tx",
  },
  {
    postal_code: "13333",
    zip_name: "east springfield, ny",
  },
  {
    postal_code: "13117",
    zip_name: "montezuma, ny",
  },
  {
    postal_code: "78883",
    zip_name: "tarpley, tx",
  },
  {
    postal_code: "72443",
    zip_name: "marmaduke, ar",
  },
  {
    postal_code: "42371",
    zip_name: "rumsey, ky",
  },
  {
    postal_code: "63751",
    zip_name: "glenallen, mo",
  },
  {
    postal_code: "42029",
    zip_name: "calvert city, ky",
  },
  {
    postal_code: "47354",
    zip_name: "losantville, in",
  },
  {
    postal_code: "16120",
    zip_name: "enon valley, pa",
  },
  {
    postal_code: "31021",
    zip_name: "dublin, ga",
  },
  {
    postal_code: "35063",
    zip_name: "empire, al",
  },
  {
    postal_code: "24553",
    zip_name: "gladstone, va",
  },
  {
    postal_code: "48237",
    zip_name: "oak park, mi",
  },
  {
    postal_code: "18071",
    zip_name: "palmerton, pa",
  },
  {
    postal_code: "1151",
    zip_name: "indian orchard, ma",
  },
  {
    postal_code: "51433",
    zip_name: "auburn, ia",
  },
  {
    postal_code: "74338",
    zip_name: "colcord, ok",
  },
  {
    postal_code: "23234",
    zip_name: "richmond, va",
  },
  {
    postal_code: "18818",
    zip_name: "friendsville, pa",
  },
  {
    postal_code: "77982",
    zip_name: "port o connor, tx",
  },
  {
    postal_code: "98932",
    zip_name: "granger, wa",
  },
  {
    postal_code: "56045",
    zip_name: "hollandale, mn",
  },
  {
    postal_code: "97232",
    zip_name: "portland, or",
  },
  {
    postal_code: "16922",
    zip_name: "galeton, pa",
  },
  {
    postal_code: "46815",
    zip_name: "fort wayne, in",
  },
  {
    postal_code: "65674",
    zip_name: "humansville, mo",
  },
  {
    postal_code: "43155",
    zip_name: "sugar grove, oh",
  },
  {
    postal_code: "48328",
    zip_name: "waterford, mi",
  },
  {
    postal_code: "28638",
    zip_name: "hudson, nc",
  },
  {
    postal_code: "44653",
    zip_name: "midvale, oh",
  },
  {
    postal_code: "47114",
    zip_name: "crandall, in",
  },
  {
    postal_code: "15322",
    zip_name: "clarksville, pa",
  },
  {
    postal_code: "95837",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "80802",
    zip_name: "arapahoe, co",
  },
  {
    postal_code: "91980",
    zip_name: "tecate, ca",
  },
  {
    postal_code: "15615",
    zip_name: "ardara, pa",
  },
  {
    postal_code: "23879",
    zip_name: "skippers, va",
  },
  {
    postal_code: "13040",
    zip_name: "cincinnatus, ny",
  },
  {
    postal_code: "47327",
    zip_name: "cambridge city, in",
  },
  {
    postal_code: "39213",
    zip_name: "jackson, ms",
  },
  {
    postal_code: "15445",
    zip_name: "hopwood, pa",
  },
  {
    postal_code: "48849",
    zip_name: "lake odessa, mi",
  },
  {
    postal_code: "13801",
    zip_name: "mc donough, ny",
  },
  {
    postal_code: "97455",
    zip_name: "pleasant hill, or",
  },
  {
    postal_code: "12060",
    zip_name: "east chatham, ny",
  },
  {
    postal_code: "93218",
    zip_name: "ducor, ca",
  },
  {
    postal_code: "55723",
    zip_name: "cook, mn",
  },
  {
    postal_code: "1376",
    zip_name: "turners falls, ma",
  },
  {
    postal_code: "81410",
    zip_name: "austin, co",
  },
  {
    postal_code: "83115",
    zip_name: "daniel, wy",
  },
  {
    postal_code: "13354",
    zip_name: "holland patent, ny",
  },
  {
    postal_code: "56209",
    zip_name: "atwater, mn",
  },
  {
    postal_code: "59632",
    zip_name: "boulder, mt",
  },
  {
    postal_code: "23947",
    zip_name: "keysville, va",
  },
  {
    postal_code: "40977",
    zip_name: "pineville, ky",
  },
  {
    postal_code: "8833",
    zip_name: "lebanon, nj",
  },
  {
    postal_code: "26415",
    zip_name: "pennsboro, wv",
  },
  {
    postal_code: "24277",
    zip_name: "pennington gap, va",
  },
  {
    postal_code: "43066",
    zip_name: "radnor, oh",
  },
  {
    postal_code: "63739",
    zip_name: "burfordville, mo",
  },
  {
    postal_code: "82242",
    zip_name: "van tassell, wy",
  },
  {
    postal_code: "68004",
    zip_name: "bancroft, ne",
  },
  {
    postal_code: "51058",
    zip_name: "sutherland, ia",
  },
  {
    postal_code: "57472",
    zip_name: "selby, sd",
  },
  {
    postal_code: "46704",
    zip_name: "arcola, in",
  },
  {
    postal_code: "83644",
    zip_name: "middleton, id",
  },
  {
    postal_code: "22027",
    zip_name: "dunn loring, va",
  },
  {
    postal_code: "38069",
    zip_name: "stanton, tn",
  },
  {
    postal_code: "20629",
    zip_name: "dowell, md",
  },
  {
    postal_code: "17572",
    zip_name: "ronks, pa",
  },
  {
    postal_code: "4652",
    zip_name: "lubec, me",
  },
  {
    postal_code: "95690",
    zip_name: "walnut grove, ca",
  },
  {
    postal_code: "99033",
    zip_name: "tekoa, wa",
  },
  {
    postal_code: "71322",
    zip_name: "bunkie, la",
  },
  {
    postal_code: "30705",
    zip_name: "chatsworth, ga",
  },
  {
    postal_code: "1541",
    zip_name: "princeton, ma",
  },
  {
    postal_code: "4344",
    zip_name: "farmingdale, me",
  },
  {
    postal_code: "84657",
    zip_name: "sigurd, ut",
  },
  {
    postal_code: "62681",
    zip_name: "rushville, il",
  },
  {
    postal_code: "50233",
    zip_name: "redfield, ia",
  },
  {
    postal_code: "83629",
    zip_name: "horseshoe bend, id",
  },
  {
    postal_code: "62565",
    zip_name: "shelbyville, il",
  },
  {
    postal_code: "71118",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "14057",
    zip_name: "eden, ny",
  },
  {
    postal_code: "48734",
    zip_name: "frankenmuth, mi",
  },
  {
    postal_code: "48438",
    zip_name: "goodrich, mi",
  },
  {
    postal_code: "93513",
    zip_name: "big pine, ca",
  },
  {
    postal_code: "63627",
    zip_name: "bloomsdale, mo",
  },
  {
    postal_code: "67732",
    zip_name: "brewster, ks",
  },
  {
    postal_code: "14872",
    zip_name: "pine valley, ny",
  },
  {
    postal_code: "47226",
    zip_name: "clifford, in",
  },
  {
    postal_code: "47975",
    zip_name: "pine village, in",
  },
  {
    postal_code: "50681",
    zip_name: "westgate, ia",
  },
  {
    postal_code: "47957",
    zip_name: "medaryville, in",
  },
  {
    postal_code: "56232",
    zip_name: "dawson, mn",
  },
  {
    postal_code: "12070",
    zip_name: "fort johnson, ny",
  },
  {
    postal_code: "38565",
    zip_name: "grimsley, tn",
  },
  {
    postal_code: "64742",
    zip_name: "drexel, mo",
  },
  {
    postal_code: "13120",
    zip_name: "nedrow, ny",
  },
  {
    postal_code: "62301",
    zip_name: "quincy, il",
  },
  {
    postal_code: "49277",
    zip_name: "rives junction, mi",
  },
  {
    postal_code: "96134",
    zip_name: "tulelake, ca",
  },
  {
    postal_code: "48192",
    zip_name: "wyandotte, mi",
  },
  {
    postal_code: "79541",
    zip_name: "ovalo, tx",
  },
  {
    postal_code: "85617",
    zip_name: "mc neal, az",
  },
  {
    postal_code: "45688",
    zip_name: "waterloo, oh",
  },
  {
    postal_code: "43976",
    zip_name: "hopedale, oh",
  },
  {
    postal_code: "61047",
    zip_name: "leaf river, il",
  },
  {
    postal_code: "59845",
    zip_name: "hot springs, mt",
  },
  {
    postal_code: "83803",
    zip_name: "bayview, id",
  },
  {
    postal_code: "39851",
    zip_name: "fort gaines, ga",
  },
  {
    postal_code: "69132",
    zip_name: "dickens, ne",
  },
  {
    postal_code: "73460",
    zip_name: "tishomingo, ok",
  },
  {
    postal_code: "78591",
    zip_name: "santa elena, tx",
  },
  {
    postal_code: "29691",
    zip_name: "walhalla, sc",
  },
  {
    postal_code: "80216",
    zip_name: "denver, co",
  },
  {
    postal_code: "81140",
    zip_name: "la jara, co",
  },
  {
    postal_code: "26757",
    zip_name: "romney, wv",
  },
  {
    postal_code: "70714",
    zip_name: "baker, la",
  },
  {
    postal_code: "26254",
    zip_name: "bowden, wv",
  },
  {
    postal_code: "29712",
    zip_name: "edgemoor, sc",
  },
  {
    postal_code: "21841",
    zip_name: "newark, md",
  },
  {
    postal_code: "1505",
    zip_name: "boylston, ma",
  },
  {
    postal_code: "93405",
    zip_name: "san luis obispo, ca",
  },
  {
    postal_code: "50130",
    zip_name: "jewell, ia",
  },
  {
    postal_code: "70816",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "14622",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "74346",
    zip_name: "jay, ok",
  },
  {
    postal_code: "79096",
    zip_name: "wheeler, tx",
  },
  {
    postal_code: "93270",
    zip_name: "terra bella, ca",
  },
  {
    postal_code: "48073",
    zip_name: "royal oak, mi",
  },
  {
    postal_code: "95442",
    zip_name: "glen ellen, ca",
  },
  {
    postal_code: "23661",
    zip_name: "hampton, va",
  },
  {
    postal_code: "78377",
    zip_name: "refugio, tx",
  },
  {
    postal_code: "48021",
    zip_name: "eastpointe, mi",
  },
  {
    postal_code: "27863",
    zip_name: "pikeville, nc",
  },
  {
    postal_code: "28091",
    zip_name: "lilesville, nc",
  },
  {
    postal_code: "41041",
    zip_name: "flemingsburg, ky",
  },
  {
    postal_code: "67430",
    zip_name: "cawker city, ks",
  },
  {
    postal_code: "76565",
    zip_name: "pottsville, tx",
  },
  {
    postal_code: "25432",
    zip_name: "millville, wv",
  },
  {
    postal_code: "52552",
    zip_name: "drakesville, ia",
  },
  {
    postal_code: "68068",
    zip_name: "washington, ne",
  },
  {
    postal_code: "65649",
    zip_name: "fair play, mo",
  },
  {
    postal_code: "87047",
    zip_name: "sandia park, nm",
  },
  {
    postal_code: "13321",
    zip_name: "clark mills, ny",
  },
  {
    postal_code: "50042",
    zip_name: "brayton, ia",
  },
  {
    postal_code: "67552",
    zip_name: "lewis, ks",
  },
  {
    postal_code: "95257",
    zip_name: "wilseyville, ca",
  },
  {
    postal_code: "6757",
    zip_name: "kent, ct",
  },
  {
    postal_code: "45862",
    zip_name: "mendon, oh",
  },
  {
    postal_code: "32617",
    zip_name: "anthony, fl",
  },
  {
    postal_code: "55951",
    zip_name: "le roy, mn",
  },
  {
    postal_code: "29372",
    zip_name: "pacolet, sc",
  },
  {
    postal_code: "23662",
    zip_name: "poquoson, va",
  },
  {
    postal_code: "24263",
    zip_name: "jonesville, va",
  },
  {
    postal_code: "62883",
    zip_name: "scheller, il",
  },
  {
    postal_code: "75436",
    zip_name: "detroit, tx",
  },
  {
    postal_code: "56583",
    zip_name: "tintah, mn",
  },
  {
    postal_code: "29742",
    zip_name: "sharon, sc",
  },
  {
    postal_code: "97136",
    zip_name: "rockaway beach, or",
  },
  {
    postal_code: "61469",
    zip_name: "oquawka, il",
  },
  {
    postal_code: "39854",
    zip_name: "georgetown, ga",
  },
  {
    postal_code: "60935",
    zip_name: "essex, il",
  },
  {
    postal_code: "45860",
    zip_name: "maria stein, oh",
  },
  {
    postal_code: "57440",
    zip_name: "frankfort, sd",
  },
  {
    postal_code: "12410",
    zip_name: "big indian, ny",
  },
  {
    postal_code: "22610",
    zip_name: "bentonville, va",
  },
  {
    postal_code: "42776",
    zip_name: "sonora, ky",
  },
  {
    postal_code: "50276",
    zip_name: "woodward, ia",
  },
  {
    postal_code: "51557",
    zip_name: "mondamin, ia",
  },
  {
    postal_code: "48323",
    zip_name: "west bloomfield, mi",
  },
  {
    postal_code: "82053",
    zip_name: "burns, wy",
  },
  {
    postal_code: "87558",
    zip_name: "red river, nm",
  },
  {
    postal_code: "62889",
    zip_name: "texico, il",
  },
  {
    postal_code: "66046",
    zip_name: "lawrence, ks",
  },
  {
    postal_code: "47264",
    zip_name: "norman, in",
  },
  {
    postal_code: "47421",
    zip_name: "bedford, in",
  },
  {
    postal_code: "80128",
    zip_name: "littleton, co",
  },
  {
    postal_code: "26601",
    zip_name: "sutton, wv",
  },
  {
    postal_code: "2839",
    zip_name: "mapleville, ri",
  },
  {
    postal_code: "4921",
    zip_name: "brooks, me",
  },
  {
    postal_code: "57076",
    zip_name: "winfred, sd",
  },
  {
    postal_code: "99782",
    zip_name: "wainwright, ak",
  },
  {
    postal_code: "25261",
    zip_name: "millstone, wv",
  },
  {
    postal_code: "72769",
    zip_name: "summers, ar",
  },
  {
    postal_code: "7013",
    zip_name: "clifton, nj",
  },
  {
    postal_code: "74060",
    zip_name: "prue, ok",
  },
  {
    postal_code: "52619",
    zip_name: "argyle, ia",
  },
  {
    postal_code: "16214",
    zip_name: "clarion, pa",
  },
  {
    postal_code: "65810",
    zip_name: "springfield, mo",
  },
  {
    postal_code: "56360",
    zip_name: "osakis, mn",
  },
  {
    postal_code: "97501",
    zip_name: "medford, or",
  },
  {
    postal_code: "36266",
    zip_name: "lineville, al",
  },
  {
    postal_code: "62557",
    zip_name: "pana, il",
  },
  {
    postal_code: "79520",
    zip_name: "hamlin, tx",
  },
  {
    postal_code: "60966",
    zip_name: "sheldon, il",
  },
  {
    postal_code: "69162",
    zip_name: "sidney, ne",
  },
  {
    postal_code: "67230",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "24651",
    zip_name: "tazewell, va",
  },
  {
    postal_code: "65082",
    zip_name: "tuscumbia, mo",
  },
  {
    postal_code: "85924",
    zip_name: "concho, az",
  },
  {
    postal_code: "61428",
    zip_name: "dahinda, il",
  },
  {
    postal_code: "39117",
    zip_name: "morton, ms",
  },
  {
    postal_code: "16153",
    zip_name: "stoneboro, pa",
  },
  {
    postal_code: "12873",
    zip_name: "shushan, ny",
  },
  {
    postal_code: "10552",
    zip_name: "mount vernon, ny",
  },
  {
    postal_code: "77359",
    zip_name: "oakhurst, tx",
  },
  {
    postal_code: "56592",
    zip_name: "winger, mn",
  },
  {
    postal_code: "82071",
    zip_name: "laramie, wy",
  },
  {
    postal_code: "61478",
    zip_name: "smithshire, il",
  },
  {
    postal_code: "50839",
    zip_name: "carbon, ia",
  },
  {
    postal_code: "15696",
    zip_name: "youngstown, pa",
  },
  {
    postal_code: "56382",
    zip_name: "swanville, mn",
  },
  {
    postal_code: "20675",
    zip_name: "pomfret, md",
  },
  {
    postal_code: "70769",
    zip_name: "prairieville, la",
  },
  {
    postal_code: "43565",
    zip_name: "tontogany, oh",
  },
  {
    postal_code: "26241",
    zip_name: "elkins, wv",
  },
  {
    postal_code: "84630",
    zip_name: "fayette, ut",
  },
  {
    postal_code: "74022",
    zip_name: "copan, ok",
  },
  {
    postal_code: "91502",
    zip_name: "burbank, ca",
  },
  {
    postal_code: "2738",
    zip_name: "marion, ma",
  },
  {
    postal_code: "1034",
    zip_name: "granville, ma",
  },
  {
    postal_code: "8865",
    zip_name: "phillipsburg, nj",
  },
  {
    postal_code: "61529",
    zip_name: "elmwood, il",
  },
  {
    postal_code: "93643",
    zip_name: "north fork, ca",
  },
  {
    postal_code: "31216",
    zip_name: "macon, ga",
  },
  {
    postal_code: "12174",
    zip_name: "stuyvesant falls, ny",
  },
  {
    postal_code: "13034",
    zip_name: "cayuga, ny",
  },
  {
    postal_code: "61401",
    zip_name: "galesburg, il",
  },
  {
    postal_code: "81643",
    zip_name: "mesa, co",
  },
  {
    postal_code: "76890",
    zip_name: "zephyr, tx",
  },
  {
    postal_code: "49799",
    zip_name: "wolverine, mi",
  },
  {
    postal_code: "63732",
    zip_name: "altenburg, mo",
  },
  {
    postal_code: "97823",
    zip_name: "condon, or",
  },
  {
    postal_code: "77954",
    zip_name: "cuero, tx",
  },
  {
    postal_code: "62922",
    zip_name: "creal springs, il",
  },
  {
    postal_code: "52359",
    zip_name: "west chester, ia",
  },
  {
    postal_code: "31753",
    zip_name: "funston, ga",
  },
  {
    postal_code: "15015",
    zip_name: "bradfordwoods, pa",
  },
  {
    postal_code: "53057",
    zip_name: "mount calvary, wi",
  },
  {
    postal_code: "49707",
    zip_name: "alpena, mi",
  },
  {
    postal_code: "60938",
    zip_name: "gilman, il",
  },
  {
    postal_code: "61278",
    zip_name: "rapids city, il",
  },
  {
    postal_code: "24598",
    zip_name: "virgilina, va",
  },
  {
    postal_code: "6254",
    zip_name: "north franklin, ct",
  },
  {
    postal_code: "49421",
    zip_name: "hesperia, mi",
  },
  {
    postal_code: "62379",
    zip_name: "warsaw, il",
  },
  {
    postal_code: "2804",
    zip_name: "ashaway, ri",
  },
  {
    postal_code: "32053",
    zip_name: "jennings, fl",
  },
  {
    postal_code: "62935",
    zip_name: "galatia, il",
  },
  {
    postal_code: "78343",
    zip_name: "bishop, tx",
  },
  {
    postal_code: "98625",
    zip_name: "kalama, wa",
  },
  {
    postal_code: "2764",
    zip_name: "north dighton, ma",
  },
  {
    postal_code: "43008",
    zip_name: "buckeye lake, oh",
  },
  {
    postal_code: "15676",
    zip_name: "pleasant unity, pa",
  },
  {
    postal_code: "13081",
    zip_name: "king ferry, ny",
  },
  {
    postal_code: "78004",
    zip_name: "bergheim, tx",
  },
  {
    postal_code: "83702",
    zip_name: "boise, id",
  },
  {
    postal_code: "15841",
    zip_name: "force, pa",
  },
  {
    postal_code: "95226",
    zip_name: "campo seco, ca",
  },
  {
    postal_code: "23156",
    zip_name: "shacklefords, va",
  },
  {
    postal_code: "97034",
    zip_name: "lake oswego, or",
  },
  {
    postal_code: "51459",
    zip_name: "ralston, ia",
  },
  {
    postal_code: "97378",
    zip_name: "sheridan, or",
  },
  {
    postal_code: "22830",
    zip_name: "fulks run, va",
  },
  {
    postal_code: "12935",
    zip_name: "ellenburg depot, ny",
  },
  {
    postal_code: "32608",
    zip_name: "gainesville, fl",
  },
  {
    postal_code: "56636",
    zip_name: "deer river, mn",
  },
  {
    postal_code: "70811",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "3826",
    zip_name: "east hampstead, nh",
  },
  {
    postal_code: "76687",
    zip_name: "thornton, tx",
  },
  {
    postal_code: "7028",
    zip_name: "glen ridge, nj",
  },
  {
    postal_code: "76524",
    zip_name: "eddy, tx",
  },
  {
    postal_code: "2468",
    zip_name: "waban, ma",
  },
  {
    postal_code: "17301",
    zip_name: "abbottstown, pa",
  },
  {
    postal_code: "51535",
    zip_name: "griswold, ia",
  },
  {
    postal_code: "78636",
    zip_name: "johnson city, tx",
  },
  {
    postal_code: "41168",
    zip_name: "rush, ky",
  },
  {
    postal_code: "13803",
    zip_name: "marathon, ny",
  },
  {
    postal_code: "31721",
    zip_name: "albany, ga",
  },
  {
    postal_code: "59821",
    zip_name: "arlee, mt",
  },
  {
    postal_code: "24141",
    zip_name: "radford, va",
  },
  {
    postal_code: "38754",
    zip_name: "isola, ms",
  },
  {
    postal_code: "68928",
    zip_name: "bladen, ne",
  },
  {
    postal_code: "4280",
    zip_name: "sabattus, me",
  },
  {
    postal_code: "12943",
    zip_name: "keene valley, ny",
  },
  {
    postal_code: "25825",
    zip_name: "cool ridge, wv",
  },
  {
    postal_code: "48507",
    zip_name: "flint, mi",
  },
  {
    postal_code: "48419",
    zip_name: "carsonville, mi",
  },
  {
    postal_code: "80209",
    zip_name: "denver, co",
  },
  {
    postal_code: "1355",
    zip_name: "new salem, ma",
  },
  {
    postal_code: "64778",
    zip_name: "richards, mo",
  },
  {
    postal_code: "40481",
    zip_name: "sandgap, ky",
  },
  {
    postal_code: "29115",
    zip_name: "orangeburg, sc",
  },
  {
    postal_code: "21034",
    zip_name: "darlington, md",
  },
  {
    postal_code: "38352",
    zip_name: "luray, tn",
  },
  {
    postal_code: "39232",
    zip_name: "flowood, ms",
  },
  {
    postal_code: "15411",
    zip_name: "addison, pa",
  },
  {
    postal_code: "93664",
    zip_name: "shaver lake, ca",
  },
  {
    postal_code: "15030",
    zip_name: "creighton, pa",
  },
  {
    postal_code: "38361",
    zip_name: "morris chapel, tn",
  },
  {
    postal_code: "28640",
    zip_name: "jefferson, nc",
  },
  {
    postal_code: "94946",
    zip_name: "nicasio, ca",
  },
  {
    postal_code: "17236",
    zip_name: "mercersburg, pa",
  },
  {
    postal_code: "4664",
    zip_name: "sullivan, me",
  },
  {
    postal_code: "60604",
    zip_name: "chicago, il",
  },
  {
    postal_code: "2575",
    zip_name: "west tisbury, ma",
  },
  {
    postal_code: "28160",
    zip_name: "spindale, nc",
  },
  {
    postal_code: "61052",
    zip_name: "monroe center, il",
  },
  {
    postal_code: "15032",
    zip_name: "curtisville, pa",
  },
  {
    postal_code: "80824",
    zip_name: "kirk, co",
  },
  {
    postal_code: "79312",
    zip_name: "amherst, tx",
  },
  {
    postal_code: "25989",
    zip_name: "white oak, wv",
  },
  {
    postal_code: "13673",
    zip_name: "philadelphia, ny",
  },
  {
    postal_code: "1367",
    zip_name: "rowe, ma",
  },
  {
    postal_code: "14521",
    zip_name: "ovid, ny",
  },
  {
    postal_code: "12858",
    zip_name: "paradox, ny",
  },
  {
    postal_code: "92268",
    zip_name: "pioneertown, ca",
  },
  {
    postal_code: "3602",
    zip_name: "alstead, nh",
  },
  {
    postal_code: "7712",
    zip_name: "asbury park, nj",
  },
  {
    postal_code: "83837",
    zip_name: "kellogg, id",
  },
  {
    postal_code: "13478",
    zip_name: "verona, ny",
  },
  {
    postal_code: "50046",
    zip_name: "cambridge, ia",
  },
  {
    postal_code: "98802",
    zip_name: "east wenatchee, wa",
  },
  {
    postal_code: "22718",
    zip_name: "elkwood, va",
  },
  {
    postal_code: "36110",
    zip_name: "montgomery, al",
  },
  {
    postal_code: "29653",
    zip_name: "hodges, sc",
  },
  {
    postal_code: "83660",
    zip_name: "parma, id",
  },
  {
    postal_code: "17560",
    zip_name: "new providence, pa",
  },
  {
    postal_code: "5667",
    zip_name: "plainfield, vt",
  },
  {
    postal_code: "86441",
    zip_name: "dolan springs, az",
  },
  {
    postal_code: "16841",
    zip_name: "howard, pa",
  },
  {
    postal_code: "35136",
    zip_name: "rockford, al",
  },
  {
    postal_code: "79201",
    zip_name: "childress, tx",
  },
  {
    postal_code: "47942",
    zip_name: "earl park, in",
  },
  {
    postal_code: "63531",
    zip_name: "baring, mo",
  },
  {
    postal_code: "39177",
    zip_name: "valley park, ms",
  },
  {
    postal_code: "43940",
    zip_name: "neffs, oh",
  },
  {
    postal_code: "40177",
    zip_name: "west point, ky",
  },
  {
    postal_code: "56081",
    zip_name: "saint james, mn",
  },
  {
    postal_code: "48042",
    zip_name: "macomb, mi",
  },
  {
    postal_code: "11732",
    zip_name: "east norwich, ny",
  },
  {
    postal_code: "16354",
    zip_name: "titusville, pa",
  },
  {
    postal_code: "48138",
    zip_name: "grosse ile, mi",
  },
  {
    postal_code: "23039",
    zip_name: "crozier, va",
  },
  {
    postal_code: "54849",
    zip_name: "lake nebagamon, wi",
  },
  {
    postal_code: "2807",
    zip_name: "block island, ri",
  },
  {
    postal_code: "30741",
    zip_name: "rossville, ga",
  },
  {
    postal_code: "45144",
    zip_name: "manchester, oh",
  },
  {
    postal_code: "87144",
    zip_name: "rio rancho, nm",
  },
  {
    postal_code: "32087",
    zip_name: "sanderson, fl",
  },
  {
    postal_code: "38801",
    zip_name: "tupelo, ms",
  },
  {
    postal_code: "14127",
    zip_name: "orchard park, ny",
  },
  {
    postal_code: "56676",
    zip_name: "shevlin, mn",
  },
  {
    postal_code: "82072",
    zip_name: "laramie, wy",
  },
  {
    postal_code: "49036",
    zip_name: "coldwater, mi",
  },
  {
    postal_code: "30182",
    zip_name: "waco, ga",
  },
  {
    postal_code: "48310",
    zip_name: "sterling heights, mi",
  },
  {
    postal_code: "71929",
    zip_name: "bismarck, ar",
  },
  {
    postal_code: "41169",
    zip_name: "russell, ky",
  },
  {
    postal_code: "54241",
    zip_name: "two rivers, wi",
  },
  {
    postal_code: "62357",
    zip_name: "new salem, il",
  },
  {
    postal_code: "26294",
    zip_name: "valley head, wv",
  },
  {
    postal_code: "43222",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "11970",
    zip_name: "south jamesport, ny",
  },
  {
    postal_code: "21678",
    zip_name: "worton, md",
  },
  {
    postal_code: "46382",
    zip_name: "union mills, in",
  },
  {
    postal_code: "5738",
    zip_name: "cuttingsville, vt",
  },
  {
    postal_code: "80443",
    zip_name: "frisco, co",
  },
  {
    postal_code: "62205",
    zip_name: "east saint louis, il",
  },
  {
    postal_code: "13063",
    zip_name: "fabius, ny",
  },
  {
    postal_code: "23960",
    zip_name: "prospect, va",
  },
  {
    postal_code: "16240",
    zip_name: "mayport, pa",
  },
  {
    postal_code: "43620",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "87714",
    zip_name: "cimarron, nm",
  },
  {
    postal_code: "8319",
    zip_name: "estell manor, nj",
  },
  {
    postal_code: "16942",
    zip_name: "osceola, pa",
  },
  {
    postal_code: "54942",
    zip_name: "greenville, wi",
  },
  {
    postal_code: "37341",
    zip_name: "harrison, tn",
  },
  {
    postal_code: "53811",
    zip_name: "hazel green, wi",
  },
  {
    postal_code: "50458",
    zip_name: "nora springs, ia",
  },
  {
    postal_code: "62707",
    zip_name: "springfield, il",
  },
  {
    postal_code: "64671",
    zip_name: "polo, mo",
  },
  {
    postal_code: "28906",
    zip_name: "murphy, nc",
  },
  {
    postal_code: "80218",
    zip_name: "denver, co",
  },
  {
    postal_code: "40472",
    zip_name: "ravenna, ky",
  },
  {
    postal_code: "51341",
    zip_name: "gillett grove, ia",
  },
  {
    postal_code: "43101",
    zip_name: "adelphi, oh",
  },
  {
    postal_code: "50232",
    zip_name: "reasnor, ia",
  },
  {
    postal_code: "20130",
    zip_name: "paris, va",
  },
  {
    postal_code: "57269",
    zip_name: "twin brooks, sd",
  },
  {
    postal_code: "38834",
    zip_name: "corinth, ms",
  },
  {
    postal_code: "47922",
    zip_name: "brook, in",
  },
  {
    postal_code: "48306",
    zip_name: "rochester, mi",
  },
  {
    postal_code: "52534",
    zip_name: "beacon, ia",
  },
  {
    postal_code: "64088",
    zip_name: "sibley, mo",
  },
  {
    postal_code: "37307",
    zip_name: "benton, tn",
  },
  {
    postal_code: "44418",
    zip_name: "fowler, oh",
  },
  {
    postal_code: "17851",
    zip_name: "mount carmel, pa",
  },
  {
    postal_code: "56757",
    zip_name: "stephen, mn",
  },
  {
    postal_code: "1452",
    zip_name: "hubbardston, ma",
  },
  {
    postal_code: "14526",
    zip_name: "penfield, ny",
  },
  {
    postal_code: "54770",
    zip_name: "strum, wi",
  },
  {
    postal_code: "97760",
    zip_name: "terrebonne, or",
  },
  {
    postal_code: "67010",
    zip_name: "augusta, ks",
  },
  {
    postal_code: "24482",
    zip_name: "verona, va",
  },
  {
    postal_code: "78596",
    zip_name: "weslaco, tx",
  },
  {
    postal_code: "20779",
    zip_name: "tracys landing, md",
  },
  {
    postal_code: "4258",
    zip_name: "minot, me",
  },
  {
    postal_code: "66606",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "44230",
    zip_name: "doylestown, oh",
  },
  {
    postal_code: "74081",
    zip_name: "terlton, ok",
  },
  {
    postal_code: "56356",
    zip_name: "new munich, mn",
  },
  {
    postal_code: "26615",
    zip_name: "copen, wv",
  },
  {
    postal_code: "17505",
    zip_name: "bird in hand, pa",
  },
  {
    postal_code: "79832",
    zip_name: "alpine, tx",
  },
  {
    postal_code: "43805",
    zip_name: "blissfield, oh",
  },
  {
    postal_code: "76490",
    zip_name: "whitt, tx",
  },
  {
    postal_code: "43346",
    zip_name: "roundhead, oh",
  },
  {
    postal_code: "57468",
    zip_name: "pierpont, sd",
  },
  {
    postal_code: "72175",
    zip_name: "wabbaseka, ar",
  },
  {
    postal_code: "47387",
    zip_name: "straughn, in",
  },
  {
    postal_code: "11378",
    zip_name: "maspeth, ny",
  },
  {
    postal_code: "64490",
    zip_name: "stewartsville, mo",
  },
  {
    postal_code: "68050",
    zip_name: "prague, ne",
  },
  {
    postal_code: "97008",
    zip_name: "beaverton, or",
  },
  {
    postal_code: "44645",
    zip_name: "marshallville, oh",
  },
  {
    postal_code: "38425",
    zip_name: "clifton, tn",
  },
  {
    postal_code: "88049",
    zip_name: "mimbres, nm",
  },
  {
    postal_code: "68403",
    zip_name: "manley, ne",
  },
  {
    postal_code: "95955",
    zip_name: "maxwell, ca",
  },
  {
    postal_code: "61049",
    zip_name: "lindenwood, il",
  },
  {
    postal_code: "76706",
    zip_name: "waco, tx",
  },
  {
    postal_code: "4631",
    zip_name: "eastport, me",
  },
  {
    postal_code: "50032",
    zip_name: "berwick, ia",
  },
  {
    postal_code: "48187",
    zip_name: "canton, mi",
  },
  {
    postal_code: "38052",
    zip_name: "middleton, tn",
  },
  {
    postal_code: "92081",
    zip_name: "vista, ca",
  },
  {
    postal_code: "23888",
    zip_name: "wakefield, va",
  },
  {
    postal_code: "4040",
    zip_name: "harrison, me",
  },
  {
    postal_code: "54943",
    zip_name: "hancock, wi",
  },
  {
    postal_code: "56035",
    zip_name: "geneva, mn",
  },
  {
    postal_code: "79782",
    zip_name: "stanton, tx",
  },
  {
    postal_code: "14519",
    zip_name: "ontario, ny",
  },
  {
    postal_code: "21664",
    zip_name: "secretary, md",
  },
  {
    postal_code: "16317",
    zip_name: "cooperstown, pa",
  },
  {
    postal_code: "66066",
    zip_name: "oskaloosa, ks",
  },
  {
    postal_code: "16864",
    zip_name: "orviston, pa",
  },
  {
    postal_code: "50649",
    zip_name: "kesley, ia",
  },
  {
    postal_code: "50154",
    zip_name: "mc callsburg, ia",
  },
  {
    postal_code: "53942",
    zip_name: "lime ridge, wi",
  },
  {
    postal_code: "76544",
    zip_name: "fort hood, tx",
  },
  {
    postal_code: "81435",
    zip_name: "telluride, co",
  },
  {
    postal_code: "66716",
    zip_name: "bronson, ks",
  },
  {
    postal_code: "84724",
    zip_name: "elsinore, ut",
  },
  {
    postal_code: "5067",
    zip_name: "south pomfret, vt",
  },
  {
    postal_code: "7882",
    zip_name: "washington, nj",
  },
  {
    postal_code: "4858",
    zip_name: "south thomaston, me",
  },
  {
    postal_code: "13368",
    zip_name: "lyons falls, ny",
  },
  {
    postal_code: "76705",
    zip_name: "waco, tx",
  },
  {
    postal_code: "24179",
    zip_name: "vinton, va",
  },
  {
    postal_code: "23004",
    zip_name: "arvonia, va",
  },
  {
    postal_code: "11501",
    zip_name: "mineola, ny",
  },
  {
    postal_code: "83547",
    zip_name: "pollock, id",
  },
  {
    postal_code: "46996",
    zip_name: "winamac, in",
  },
  {
    postal_code: "28665",
    zip_name: "purlear, nc",
  },
  {
    postal_code: "64439",
    zip_name: "dearborn, mo",
  },
  {
    postal_code: "47129",
    zip_name: "clarksville, in",
  },
  {
    postal_code: "44827",
    zip_name: "crestline, oh",
  },
  {
    postal_code: "44626",
    zip_name: "east sparta, oh",
  },
  {
    postal_code: "1741",
    zip_name: "carlisle, ma",
  },
  {
    postal_code: "68065",
    zip_name: "valparaiso, ne",
  },
  {
    postal_code: "47960",
    zip_name: "monticello, in",
  },
  {
    postal_code: "49119",
    zip_name: "new troy, mi",
  },
  {
    postal_code: "44678",
    zip_name: "somerdale, oh",
  },
  {
    postal_code: "12512",
    zip_name: "chelsea, ny",
  },
  {
    postal_code: "66857",
    zip_name: "le roy, ks",
  },
  {
    postal_code: "17355",
    zip_name: "railroad, pa",
  },
  {
    postal_code: "46340",
    zip_name: "hanna, in",
  },
  {
    postal_code: "80438",
    zip_name: "empire, co",
  },
  {
    postal_code: "66901",
    zip_name: "concordia, ks",
  },
  {
    postal_code: "59489",
    zip_name: "winifred, mt",
  },
  {
    postal_code: "49947",
    zip_name: "marenisco, mi",
  },
  {
    postal_code: "7009",
    zip_name: "cedar grove, nj",
  },
  {
    postal_code: "37411",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "43456",
    zip_name: "put in bay, oh",
  },
  {
    postal_code: "80125",
    zip_name: "littleton, co",
  },
  {
    postal_code: "1834",
    zip_name: "groveland, ma",
  },
  {
    postal_code: "66614",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "65680",
    zip_name: "kissee mills, mo",
  },
  {
    postal_code: "13863",
    zip_name: "willet, ny",
  },
  {
    postal_code: "28901",
    zip_name: "andrews, nc",
  },
  {
    postal_code: "17982",
    zip_name: "tuscarora, pa",
  },
  {
    postal_code: "56181",
    zip_name: "welcome, mn",
  },
  {
    postal_code: "45826",
    zip_name: "chickasaw, oh",
  },
  {
    postal_code: "4930",
    zip_name: "dexter, me",
  },
  {
    postal_code: "42333",
    zip_name: "cromwell, ky",
  },
  {
    postal_code: "38620",
    zip_name: "courtland, ms",
  },
  {
    postal_code: "84522",
    zip_name: "emery, ut",
  },
  {
    postal_code: "38222",
    zip_name: "buchanan, tn",
  },
  {
    postal_code: "83811",
    zip_name: "clark fork, id",
  },
  {
    postal_code: "62069",
    zip_name: "mount olive, il",
  },
  {
    postal_code: "5458",
    zip_name: "grand isle, vt",
  },
  {
    postal_code: "2894",
    zip_name: "wood river junction, ri",
  },
  {
    postal_code: "22732",
    zip_name: "radiant, va",
  },
  {
    postal_code: "45810",
    zip_name: "ada, oh",
  },
  {
    postal_code: "86444",
    zip_name: "meadview, az",
  },
  {
    postal_code: "30739",
    zip_name: "rock spring, ga",
  },
  {
    postal_code: "12407",
    zip_name: "ashland, ny",
  },
  {
    postal_code: "79022",
    zip_name: "dalhart, tx",
  },
  {
    postal_code: "59477",
    zip_name: "simms, mt",
  },
  {
    postal_code: "60109",
    zip_name: "burlington, il",
  },
  {
    postal_code: "61855",
    zip_name: "milmine, il",
  },
  {
    postal_code: "61235",
    zip_name: "atkinson, il",
  },
  {
    postal_code: "48038",
    zip_name: "clinton township, mi",
  },
  {
    postal_code: "45850",
    zip_name: "harrod, oh",
  },
  {
    postal_code: "55053",
    zip_name: "nerstrand, mn",
  },
  {
    postal_code: "22938",
    zip_name: "faber, va",
  },
  {
    postal_code: "15681",
    zip_name: "saltsburg, pa",
  },
  {
    postal_code: "17009",
    zip_name: "burnham, pa",
  },
  {
    postal_code: "13613",
    zip_name: "brasher falls, ny",
  },
  {
    postal_code: "13030",
    zip_name: "bridgeport, ny",
  },
  {
    postal_code: "28163",
    zip_name: "stanfield, nc",
  },
  {
    postal_code: "21541",
    zip_name: "mc henry, md",
  },
  {
    postal_code: "36258",
    zip_name: "delta, al",
  },
  {
    postal_code: "67058",
    zip_name: "harper, ks",
  },
  {
    postal_code: "24330",
    zip_name: "fries, va",
  },
  {
    postal_code: "91945",
    zip_name: "lemon grove, ca",
  },
  {
    postal_code: "47882",
    zip_name: "sullivan, in",
  },
  {
    postal_code: "29927",
    zip_name: "hardeeville, sc",
  },
  {
    postal_code: "2108",
    zip_name: "boston, ma",
  },
  {
    postal_code: "64836",
    zip_name: "carthage, mo",
  },
  {
    postal_code: "70809",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "35118",
    zip_name: "mulga, al",
  },
  {
    postal_code: "49090",
    zip_name: "south haven, mi",
  },
  {
    postal_code: "56318",
    zip_name: "burtrum, mn",
  },
  {
    postal_code: "2461",
    zip_name: "newton highlands, ma",
  },
  {
    postal_code: "5081",
    zip_name: "wells river, vt",
  },
  {
    postal_code: "99018",
    zip_name: "latah, wa",
  },
  {
    postal_code: "50591",
    zip_name: "thor, ia",
  },
  {
    postal_code: "66949",
    zip_name: "jewell, ks",
  },
  {
    postal_code: "40826",
    zip_name: "eolia, ky",
  },
  {
    postal_code: "50264",
    zip_name: "weldon, ia",
  },
  {
    postal_code: "43772",
    zip_name: "pleasant city, oh",
  },
  {
    postal_code: "95651",
    zip_name: "lotus, ca",
  },
  {
    postal_code: "56651",
    zip_name: "lengby, mn",
  },
  {
    postal_code: "75141",
    zip_name: "hutchins, tx",
  },
  {
    postal_code: "95938",
    zip_name: "durham, ca",
  },
  {
    postal_code: "67065",
    zip_name: "isabel, ks",
  },
  {
    postal_code: "4918",
    zip_name: "belgrade lakes, me",
  },
  {
    postal_code: "47863",
    zip_name: "new goshen, in",
  },
  {
    postal_code: "97370",
    zip_name: "philomath, or",
  },
  {
    postal_code: "56527",
    zip_name: "deer creek, mn",
  },
  {
    postal_code: "45871",
    zip_name: "new knoxville, oh",
  },
  {
    postal_code: "48861",
    zip_name: "mulliken, mi",
  },
  {
    postal_code: "13044",
    zip_name: "constantia, ny",
  },
  {
    postal_code: "92273",
    zip_name: "seeley, ca",
  },
  {
    postal_code: "27350",
    zip_name: "sophia, nc",
  },
  {
    postal_code: "97146",
    zip_name: "warrenton, or",
  },
  {
    postal_code: "1346",
    zip_name: "heath, ma",
  },
  {
    postal_code: "56171",
    zip_name: "sherburn, mn",
  },
  {
    postal_code: "16743",
    zip_name: "port allegany, pa",
  },
  {
    postal_code: "62027",
    zip_name: "eldred, il",
  },
  {
    postal_code: "4986",
    zip_name: "thorndike, me",
  },
  {
    postal_code: "80741",
    zip_name: "merino, co",
  },
  {
    postal_code: "29556",
    zip_name: "kingstree, sc",
  },
  {
    postal_code: "28379",
    zip_name: "rockingham, nc",
  },
  {
    postal_code: "29545",
    zip_name: "green sea, sc",
  },
  {
    postal_code: "27233",
    zip_name: "climax, nc",
  },
  {
    postal_code: "21779",
    zip_name: "rohrersville, md",
  },
  {
    postal_code: "28529",
    zip_name: "grantsboro, nc",
  },
  {
    postal_code: "1068",
    zip_name: "oakham, ma",
  },
  {
    postal_code: "56762",
    zip_name: "warren, mn",
  },
  {
    postal_code: "17764",
    zip_name: "renovo, pa",
  },
  {
    postal_code: "14825",
    zip_name: "chemung, ny",
  },
  {
    postal_code: "44730",
    zip_name: "east canton, oh",
  },
  {
    postal_code: "71968",
    zip_name: "royal, ar",
  },
  {
    postal_code: "18202",
    zip_name: "hazleton, pa",
  },
  {
    postal_code: "5765",
    zip_name: "proctor, vt",
  },
  {
    postal_code: "48140",
    zip_name: "ida, mi",
  },
  {
    postal_code: "38562",
    zip_name: "gainesboro, tn",
  },
  {
    postal_code: "83241",
    zip_name: "grace, id",
  },
  {
    postal_code: "13202",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "3466",
    zip_name: "west chesterfield, nh",
  },
  {
    postal_code: "62090",
    zip_name: "venice, il",
  },
  {
    postal_code: "50168",
    zip_name: "mingo, ia",
  },
  {
    postal_code: "83352",
    zip_name: "shoshone, id",
  },
  {
    postal_code: "7103",
    zip_name: "newark, nj",
  },
  {
    postal_code: "37208",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "77659",
    zip_name: "sour lake, tx",
  },
  {
    postal_code: "83122",
    zip_name: "grover, wy",
  },
  {
    postal_code: "45882",
    zip_name: "rockford, oh",
  },
  {
    postal_code: "53121",
    zip_name: "elkhorn, wi",
  },
  {
    postal_code: "52625",
    zip_name: "donnellson, ia",
  },
  {
    postal_code: "21532",
    zip_name: "frostburg, md",
  },
  {
    postal_code: "84523",
    zip_name: "ferron, ut",
  },
  {
    postal_code: "78022",
    zip_name: "george west, tx",
  },
  {
    postal_code: "49953",
    zip_name: "ontonagon, mi",
  },
  {
    postal_code: "16123",
    zip_name: "fombell, pa",
  },
  {
    postal_code: "15327",
    zip_name: "dilliner, pa",
  },
  {
    postal_code: "52630",
    zip_name: "hillsboro, ia",
  },
  {
    postal_code: "12041",
    zip_name: "clarksville, ny",
  },
  {
    postal_code: "48731",
    zip_name: "elkton, mi",
  },
  {
    postal_code: "17842",
    zip_name: "middleburg, pa",
  },
  {
    postal_code: "3768",
    zip_name: "lyme, nh",
  },
  {
    postal_code: "93667",
    zip_name: "tollhouse, ca",
  },
  {
    postal_code: "26180",
    zip_name: "walker, wv",
  },
  {
    postal_code: "48382",
    zip_name: "commerce township, mi",
  },
  {
    postal_code: "26430",
    zip_name: "sand fork, wv",
  },
  {
    postal_code: "30512",
    zip_name: "blairsville, ga",
  },
  {
    postal_code: "26354",
    zip_name: "grafton, wv",
  },
  {
    postal_code: "40037",
    zip_name: "loretto, ky",
  },
  {
    postal_code: "48610",
    zip_name: "alger, mi",
  },
  {
    postal_code: "56663",
    zip_name: "pennington, mn",
  },
  {
    postal_code: "80232",
    zip_name: "denver, co",
  },
  {
    postal_code: "95674",
    zip_name: "rio oso, ca",
  },
  {
    postal_code: "13473",
    zip_name: "turin, ny",
  },
  {
    postal_code: "59602",
    zip_name: "helena, mt",
  },
  {
    postal_code: "12405",
    zip_name: "acra, ny",
  },
  {
    postal_code: "97017",
    zip_name: "colton, or",
  },
  {
    postal_code: "45644",
    zip_name: "kingston, oh",
  },
  {
    postal_code: "65274",
    zip_name: "new franklin, mo",
  },
  {
    postal_code: "47111",
    zip_name: "charlestown, in",
  },
  {
    postal_code: "21650",
    zip_name: "massey, md",
  },
  {
    postal_code: "29520",
    zip_name: "cheraw, sc",
  },
  {
    postal_code: "41260",
    zip_name: "thelma, ky",
  },
  {
    postal_code: "80206",
    zip_name: "denver, co",
  },
  {
    postal_code: "62849",
    zip_name: "iuka, il",
  },
  {
    postal_code: "25661",
    zip_name: "williamson, wv",
  },
  {
    postal_code: "93276",
    zip_name: "tupman, ca",
  },
  {
    postal_code: "50258",
    zip_name: "union, ia",
  },
  {
    postal_code: "71069",
    zip_name: "rodessa, la",
  },
  {
    postal_code: "45859",
    zip_name: "mc guffey, oh",
  },
  {
    postal_code: "12854",
    zip_name: "north granville, ny",
  },
  {
    postal_code: "13325",
    zip_name: "constableville, ny",
  },
  {
    postal_code: "97415",
    zip_name: "brookings, or",
  },
  {
    postal_code: "46407",
    zip_name: "gary, in",
  },
  {
    postal_code: "48240",
    zip_name: "redford, mi",
  },
  {
    postal_code: "65711",
    zip_name: "mountain grove, mo",
  },
  {
    postal_code: "2743",
    zip_name: "acushnet, ma",
  },
  {
    postal_code: "40769",
    zip_name: "williamsburg, ky",
  },
  {
    postal_code: "75426",
    zip_name: "clarksville, tx",
  },
  {
    postal_code: "44003",
    zip_name: "andover, oh",
  },
  {
    postal_code: "80019",
    zip_name: "aurora, co",
  },
  {
    postal_code: "50227",
    zip_name: "popejoy, ia",
  },
  {
    postal_code: "28341",
    zip_name: "faison, nc",
  },
  {
    postal_code: "73733",
    zip_name: "douglas, ok",
  },
  {
    postal_code: "56245",
    zip_name: "hanley falls, mn",
  },
  {
    postal_code: "71426",
    zip_name: "fisher, la",
  },
  {
    postal_code: "54762",
    zip_name: "prairie farm, wi",
  },
  {
    postal_code: "26036",
    zip_name: "dallas, wv",
  },
  {
    postal_code: "44837",
    zip_name: "greenwich, oh",
  },
  {
    postal_code: "65571",
    zip_name: "summersville, mo",
  },
  {
    postal_code: "35501",
    zip_name: "jasper, al",
  },
  {
    postal_code: "19945",
    zip_name: "frankford, de",
  },
  {
    postal_code: "13617",
    zip_name: "canton, ny",
  },
  {
    postal_code: "28694",
    zip_name: "west jefferson, nc",
  },
  {
    postal_code: "77640",
    zip_name: "port arthur, tx",
  },
  {
    postal_code: "6783",
    zip_name: "roxbury, ct",
  },
  {
    postal_code: "14736",
    zip_name: "findley lake, ny",
  },
  {
    postal_code: "97058",
    zip_name: "the dalles, or",
  },
  {
    postal_code: "4071",
    zip_name: "raymond, me",
  },
  {
    postal_code: "21651",
    zip_name: "millington, md",
  },
  {
    postal_code: "23803",
    zip_name: "petersburg, va",
  },
  {
    postal_code: "54933",
    zip_name: "embarrass, wi",
  },
  {
    postal_code: "54459",
    zip_name: "ogema, wi",
  },
  {
    postal_code: "49806",
    zip_name: "au train, mi",
  },
  {
    postal_code: "89828",
    zip_name: "lamoille, nv",
  },
  {
    postal_code: "87048",
    zip_name: "corrales, nm",
  },
  {
    postal_code: "55991",
    zip_name: "zumbro falls, mn",
  },
  {
    postal_code: "61721",
    zip_name: "armington, il",
  },
  {
    postal_code: "33877",
    zip_name: "waverly, fl",
  },
  {
    postal_code: "2770",
    zip_name: "rochester, ma",
  },
  {
    postal_code: "14125",
    zip_name: "oakfield, ny",
  },
  {
    postal_code: "56267",
    zip_name: "morris, mn",
  },
  {
    postal_code: "11366",
    zip_name: "fresh meadows, ny",
  },
  {
    postal_code: "28654",
    zip_name: "moravian falls, nc",
  },
  {
    postal_code: "95449",
    zip_name: "hopland, ca",
  },
  {
    postal_code: "10455",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "97001",
    zip_name: "antelope, or",
  },
  {
    postal_code: "28634",
    zip_name: "harmony, nc",
  },
  {
    postal_code: "76155",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "41230",
    zip_name: "louisa, ky",
  },
  {
    postal_code: "28790",
    zip_name: "zirconia, nc",
  },
  {
    postal_code: "50129",
    zip_name: "jefferson, ia",
  },
  {
    postal_code: "92548",
    zip_name: "homeland, ca",
  },
  {
    postal_code: "14043",
    zip_name: "depew, ny",
  },
  {
    postal_code: "29692",
    zip_name: "ware shoals, sc",
  },
  {
    postal_code: "4271",
    zip_name: "paris, me",
  },
  {
    postal_code: "28630",
    zip_name: "granite falls, nc",
  },
  {
    postal_code: "29058",
    zip_name: "heath springs, sc",
  },
  {
    postal_code: "61864",
    zip_name: "philo, il",
  },
  {
    postal_code: "14612",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "93204",
    zip_name: "avenal, ca",
  },
  {
    postal_code: "7109",
    zip_name: "belleville, nj",
  },
  {
    postal_code: "27986",
    zip_name: "winton, nc",
  },
  {
    postal_code: "67510",
    zip_name: "abbyville, ks",
  },
  {
    postal_code: "15924",
    zip_name: "cairnbrook, pa",
  },
  {
    postal_code: "53517",
    zip_name: "blue mounds, wi",
  },
  {
    postal_code: "59014",
    zip_name: "bridger, mt",
  },
  {
    postal_code: "58725",
    zip_name: "carpio, nd",
  },
  {
    postal_code: "93242",
    zip_name: "laton, ca",
  },
  {
    postal_code: "48377",
    zip_name: "novi, mi",
  },
  {
    postal_code: "97601",
    zip_name: "klamath falls, or",
  },
  {
    postal_code: "43347",
    zip_name: "rushsylvania, oh",
  },
  {
    postal_code: "76857",
    zip_name: "may, tx",
  },
  {
    postal_code: "56385",
    zip_name: "villard, mn",
  },
  {
    postal_code: "3590",
    zip_name: "north stratford, nh",
  },
  {
    postal_code: "83716",
    zip_name: "boise, id",
  },
  {
    postal_code: "84639",
    zip_name: "levan, ut",
  },
  {
    postal_code: "48184",
    zip_name: "wayne, mi",
  },
  {
    postal_code: "50105",
    zip_name: "gilbert, ia",
  },
  {
    postal_code: "70820",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "30055",
    zip_name: "mansfield, ga",
  },
  {
    postal_code: "81120",
    zip_name: "antonito, co",
  },
  {
    postal_code: "98531",
    zip_name: "centralia, wa",
  },
  {
    postal_code: "38320",
    zip_name: "camden, tn",
  },
  {
    postal_code: "32405",
    zip_name: "panama city, fl",
  },
  {
    postal_code: "26719",
    zip_name: "fort ashby, wv",
  },
  {
    postal_code: "31204",
    zip_name: "macon, ga",
  },
  {
    postal_code: "3462",
    zip_name: "spofford, nh",
  },
  {
    postal_code: "54141",
    zip_name: "little suamico, wi",
  },
  {
    postal_code: "31778",
    zip_name: "pavo, ga",
  },
  {
    postal_code: "40069",
    zip_name: "springfield, ky",
  },
  {
    postal_code: "31076",
    zip_name: "reynolds, ga",
  },
  {
    postal_code: "79821",
    zip_name: "anthony, tx",
  },
  {
    postal_code: "27054",
    zip_name: "woodleaf, nc",
  },
  {
    postal_code: "50542",
    zip_name: "goldfield, ia",
  },
  {
    postal_code: "53588",
    zip_name: "spring green, wi",
  },
  {
    postal_code: "61349",
    zip_name: "ohio, il",
  },
  {
    postal_code: "25253",
    zip_name: "letart, wv",
  },
  {
    postal_code: "24439",
    zip_name: "goshen, va",
  },
  {
    postal_code: "49762",
    zip_name: "naubinway, mi",
  },
  {
    postal_code: "12024",
    zip_name: "brainard, ny",
  },
  {
    postal_code: "21777",
    zip_name: "point of rocks, md",
  },
  {
    postal_code: "24581",
    zip_name: "norwood, va",
  },
  {
    postal_code: "4068",
    zip_name: "porter, me",
  },
  {
    postal_code: "4357",
    zip_name: "richmond, me",
  },
  {
    postal_code: "66851",
    zip_name: "florence, ks",
  },
  {
    postal_code: "61451",
    zip_name: "laura, il",
  },
  {
    postal_code: "15725",
    zip_name: "clarksburg, pa",
  },
  {
    postal_code: "13757",
    zip_name: "east meredith, ny",
  },
  {
    postal_code: "36051",
    zip_name: "marbury, al",
  },
  {
    postal_code: "63041",
    zip_name: "grubville, mo",
  },
  {
    postal_code: "62084",
    zip_name: "roxana, il",
  },
  {
    postal_code: "54003",
    zip_name: "beldenville, wi",
  },
  {
    postal_code: "16727",
    zip_name: "derrick city, pa",
  },
  {
    postal_code: "47968",
    zip_name: "new ross, in",
  },
  {
    postal_code: "23066",
    zip_name: "gwynn, va",
  },
  {
    postal_code: "56673",
    zip_name: "roosevelt, mn",
  },
  {
    postal_code: "10553",
    zip_name: "mount vernon, ny",
  },
  {
    postal_code: "80112",
    zip_name: "englewood, co",
  },
  {
    postal_code: "68933",
    zip_name: "clay center, ne",
  },
  {
    postal_code: "28577",
    zip_name: "sealevel, nc",
  },
  {
    postal_code: "28363",
    zip_name: "marston, nc",
  },
  {
    postal_code: "43767",
    zip_name: "norwich, oh",
  },
  {
    postal_code: "44815",
    zip_name: "bettsville, oh",
  },
  {
    postal_code: "49096",
    zip_name: "vermontville, mi",
  },
  {
    postal_code: "3750",
    zip_name: "etna, nh",
  },
  {
    postal_code: "70441",
    zip_name: "greensburg, la",
  },
  {
    postal_code: "85193",
    zip_name: "casa grande, az",
  },
  {
    postal_code: "12160",
    zip_name: "sloansville, ny",
  },
  {
    postal_code: "26542",
    zip_name: "masontown, wv",
  },
  {
    postal_code: "23887",
    zip_name: "valentines, va",
  },
  {
    postal_code: "63555",
    zip_name: "memphis, mo",
  },
  {
    postal_code: "13148",
    zip_name: "seneca falls, ny",
  },
  {
    postal_code: "88347",
    zip_name: "sacramento, nm",
  },
  {
    postal_code: "36401",
    zip_name: "evergreen, al",
  },
  {
    postal_code: "61062",
    zip_name: "pearl city, il",
  },
  {
    postal_code: "68961",
    zip_name: "nelson, ne",
  },
  {
    postal_code: "13425",
    zip_name: "oriskany falls, ny",
  },
  {
    postal_code: "56266",
    zip_name: "morgan, mn",
  },
  {
    postal_code: "78655",
    zip_name: "martindale, tx",
  },
  {
    postal_code: "99170",
    zip_name: "rosalia, wa",
  },
  {
    postal_code: "78870",
    zip_name: "knippa, tx",
  },
  {
    postal_code: "29321",
    zip_name: "buffalo, sc",
  },
  {
    postal_code: "84722",
    zip_name: "central, ut",
  },
  {
    postal_code: "56120",
    zip_name: "butterfield, mn",
  },
  {
    postal_code: "50069",
    zip_name: "de soto, ia",
  },
  {
    postal_code: "97522",
    zip_name: "butte falls, or",
  },
  {
    postal_code: "55335",
    zip_name: "gibbon, mn",
  },
  {
    postal_code: "62932",
    zip_name: "elkville, il",
  },
  {
    postal_code: "58716",
    zip_name: "benedict, nd",
  },
  {
    postal_code: "70039",
    zip_name: "boutte, la",
  },
  {
    postal_code: "44840",
    zip_name: "jeromesville, oh",
  },
  {
    postal_code: "95041",
    zip_name: "mount hermon, ca",
  },
  {
    postal_code: "54769",
    zip_name: "stockholm, wi",
  },
  {
    postal_code: "70426",
    zip_name: "angie, la",
  },
  {
    postal_code: "79045",
    zip_name: "hereford, tx",
  },
  {
    postal_code: "4222",
    zip_name: "durham, me",
  },
  {
    postal_code: "27020",
    zip_name: "hamptonville, nc",
  },
  {
    postal_code: "4757",
    zip_name: "mapleton, me",
  },
  {
    postal_code: "47236",
    zip_name: "grammer, in",
  },
  {
    postal_code: "27325",
    zip_name: "robbins, nc",
  },
  {
    postal_code: "82727",
    zip_name: "rozet, wy",
  },
  {
    postal_code: "87321",
    zip_name: "ramah, nm",
  },
  {
    postal_code: "4681",
    zip_name: "stonington, me",
  },
  {
    postal_code: "15478",
    zip_name: "smithfield, pa",
  },
  {
    postal_code: "47949",
    zip_name: "hillsboro, in",
  },
  {
    postal_code: "79545",
    zip_name: "roscoe, tx",
  },
  {
    postal_code: "92122",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "4562",
    zip_name: "phippsburg, me",
  },
  {
    postal_code: "59464",
    zip_name: "moore, mt",
  },
  {
    postal_code: "54027",
    zip_name: "wilson, wi",
  },
  {
    postal_code: "12153",
    zip_name: "sand lake, ny",
  },
  {
    postal_code: "62414",
    zip_name: "beecher city, il",
  },
  {
    postal_code: "43746",
    zip_name: "hopewell, oh",
  },
  {
    postal_code: "80546",
    zip_name: "severance, co",
  },
  {
    postal_code: "40076",
    zip_name: "waddy, ky",
  },
  {
    postal_code: "12861",
    zip_name: "putnam station, ny",
  },
  {
    postal_code: "4989",
    zip_name: "vassalboro, me",
  },
  {
    postal_code: "68017",
    zip_name: "ceresco, ne",
  },
  {
    postal_code: "26404",
    zip_name: "meadowbrook, wv",
  },
  {
    postal_code: "68660",
    zip_name: "saint edward, ne",
  },
  {
    postal_code: "38541",
    zip_name: "allons, tn",
  },
  {
    postal_code: "56239",
    zip_name: "ghent, mn",
  },
  {
    postal_code: "62972",
    zip_name: "ozark, il",
  },
  {
    postal_code: "1474",
    zip_name: "west townsend, ma",
  },
  {
    postal_code: "50574",
    zip_name: "pocahontas, ia",
  },
  {
    postal_code: "97114",
    zip_name: "dayton, or",
  },
  {
    postal_code: "22952",
    zip_name: "lyndhurst, va",
  },
  {
    postal_code: "97380",
    zip_name: "siletz, or",
  },
  {
    postal_code: "99167",
    zip_name: "rice, wa",
  },
  {
    postal_code: "44677",
    zip_name: "smithville, oh",
  },
  {
    postal_code: "5053",
    zip_name: "north pomfret, vt",
  },
  {
    postal_code: "16434",
    zip_name: "spartansburg, pa",
  },
  {
    postal_code: "85645",
    zip_name: "amado, az",
  },
  {
    postal_code: "3448",
    zip_name: "gilsum, nh",
  },
  {
    postal_code: "43438",
    zip_name: "kelleys island, oh",
  },
  {
    postal_code: "36752",
    zip_name: "lowndesboro, al",
  },
  {
    postal_code: "53926",
    zip_name: "dalton, wi",
  },
  {
    postal_code: "99151",
    zip_name: "marcus, wa",
  },
  {
    postal_code: "45382",
    zip_name: "west manchester, oh",
  },
  {
    postal_code: "21767",
    zip_name: "maugansville, md",
  },
  {
    postal_code: "74633",
    zip_name: "burbank, ok",
  },
  {
    postal_code: "14878",
    zip_name: "rock stream, ny",
  },
  {
    postal_code: "98601",
    zip_name: "amboy, wa",
  },
  {
    postal_code: "48631",
    zip_name: "kawkawlin, mi",
  },
  {
    postal_code: "77050",
    zip_name: "houston, tx",
  },
  {
    postal_code: "26218",
    zip_name: "french creek, wv",
  },
  {
    postal_code: "14208",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "55085",
    zip_name: "vermillion, mn",
  },
  {
    postal_code: "16426",
    zip_name: "mc kean, pa",
  },
  {
    postal_code: "16229",
    zip_name: "freeport, pa",
  },
  {
    postal_code: "66021",
    zip_name: "edgerton, ks",
  },
  {
    postal_code: "56014",
    zip_name: "bricelyn, mn",
  },
  {
    postal_code: "64830",
    zip_name: "alba, mo",
  },
  {
    postal_code: "22654",
    zip_name: "star tannery, va",
  },
  {
    postal_code: "62520",
    zip_name: "dawson, il",
  },
  {
    postal_code: "13408",
    zip_name: "morrisville, ny",
  },
  {
    postal_code: "17235",
    zip_name: "marion, pa",
  },
  {
    postal_code: "97049",
    zip_name: "rhododendron, or",
  },
  {
    postal_code: "87830",
    zip_name: "reserve, nm",
  },
  {
    postal_code: "93606",
    zip_name: "biola, ca",
  },
  {
    postal_code: "68745",
    zip_name: "laurel, ne",
  },
  {
    postal_code: "62048",
    zip_name: "hartford, il",
  },
  {
    postal_code: "55370",
    zip_name: "plato, mn",
  },
  {
    postal_code: "4020",
    zip_name: "cornish, me",
  },
  {
    postal_code: "12458",
    zip_name: "napanoch, ny",
  },
  {
    postal_code: "37770",
    zip_name: "lancing, tn",
  },
  {
    postal_code: "4614",
    zip_name: "blue hill, me",
  },
  {
    postal_code: "50155",
    zip_name: "macksburg, ia",
  },
  {
    postal_code: "72102",
    zip_name: "mc rae, ar",
  },
  {
    postal_code: "4008",
    zip_name: "bowdoinham, me",
  },
  {
    postal_code: "15952",
    zip_name: "salix, pa",
  },
  {
    postal_code: "28463",
    zip_name: "tabor city, nc",
  },
  {
    postal_code: "43319",
    zip_name: "east liberty, oh",
  },
  {
    postal_code: "76239",
    zip_name: "forestburg, tx",
  },
  {
    postal_code: "5867",
    zip_name: "sutton, vt",
  },
  {
    postal_code: "22437",
    zip_name: "center cross, va",
  },
  {
    postal_code: "16855",
    zip_name: "mineral springs, pa",
  },
  {
    postal_code: "15778",
    zip_name: "timblin, pa",
  },
  {
    postal_code: "16844",
    zip_name: "julian, pa",
  },
  {
    postal_code: "85607",
    zip_name: "douglas, az",
  },
  {
    postal_code: "68956",
    zip_name: "kenesaw, ne",
  },
  {
    postal_code: "41262",
    zip_name: "tomahawk, ky",
  },
  {
    postal_code: "1360",
    zip_name: "northfield, ma",
  },
  {
    postal_code: "4363",
    zip_name: "windsor, me",
  },
  {
    postal_code: "39464",
    zip_name: "ovett, ms",
  },
  {
    postal_code: "21737",
    zip_name: "glenelg, md",
  },
  {
    postal_code: "90212",
    zip_name: "beverly hills, ca",
  },
  {
    postal_code: "22733",
    zip_name: "rapidan, va",
  },
  {
    postal_code: "79512",
    zip_name: "colorado city, tx",
  },
  {
    postal_code: "24593",
    zip_name: "spout spring, va",
  },
  {
    postal_code: "44849",
    zip_name: "nevada, oh",
  },
  {
    postal_code: "45101",
    zip_name: "aberdeen, oh",
  },
  {
    postal_code: "63546",
    zip_name: "greentop, mo",
  },
  {
    postal_code: "84307",
    zip_name: "corinne, ut",
  },
  {
    postal_code: "97078",
    zip_name: "beaverton, or",
  },
  {
    postal_code: "1860",
    zip_name: "merrimac, ma",
  },
  {
    postal_code: "12058",
    zip_name: "earlton, ny",
  },
  {
    postal_code: "61256",
    zip_name: "hampton, il",
  },
  {
    postal_code: "12424",
    zip_name: "east jewett, ny",
  },
  {
    postal_code: "43731",
    zip_name: "crooksville, oh",
  },
  {
    postal_code: "78407",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "5826",
    zip_name: "craftsbury, vt",
  },
  {
    postal_code: "29468",
    zip_name: "pineville, sc",
  },
  {
    postal_code: "29475",
    zip_name: "ruffin, sc",
  },
  {
    postal_code: "3837",
    zip_name: "gilmanton iron works, nh",
  },
  {
    postal_code: "65663",
    zip_name: "half way, mo",
  },
  {
    postal_code: "54733",
    zip_name: "dallas, wi",
  },
  {
    postal_code: "38329",
    zip_name: "decaturville, tn",
  },
  {
    postal_code: "14054",
    zip_name: "east bethany, ny",
  },
  {
    postal_code: "41254",
    zip_name: "river, ky",
  },
  {
    postal_code: "61569",
    zip_name: "trivoli, il",
  },
  {
    postal_code: "54850",
    zip_name: "la pointe, wi",
  },
  {
    postal_code: "31309",
    zip_name: "fleming, ga",
  },
  {
    postal_code: "78039",
    zip_name: "la coste, tx",
  },
  {
    postal_code: "79540",
    zip_name: "old glory, tx",
  },
  {
    postal_code: "50122",
    zip_name: "hubbard, ia",
  },
  {
    postal_code: "54635",
    zip_name: "hixton, wi",
  },
  {
    postal_code: "45203",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "12719",
    zip_name: "barryville, ny",
  },
  {
    postal_code: "50145",
    zip_name: "liberty center, ia",
  },
  {
    postal_code: "45768",
    zip_name: "newport, oh",
  },
  {
    postal_code: "68946",
    zip_name: "hendley, ne",
  },
  {
    postal_code: "75420",
    zip_name: "brashear, tx",
  },
  {
    postal_code: "24527",
    zip_name: "blairs, va",
  },
  {
    postal_code: "69350",
    zip_name: "hyannis, ne",
  },
  {
    postal_code: "21657",
    zip_name: "queen anne, md",
  },
  {
    postal_code: "1350",
    zip_name: "monroe bridge, ma",
  },
  {
    postal_code: "66842",
    zip_name: "cassoday, ks",
  },
  {
    postal_code: "48069",
    zip_name: "pleasant ridge, mi",
  },
  {
    postal_code: "56150",
    zip_name: "lakefield, mn",
  },
  {
    postal_code: "33835",
    zip_name: "bradley, fl",
  },
  {
    postal_code: "16443",
    zip_name: "west springfield, pa",
  },
  {
    postal_code: "61742",
    zip_name: "goodfield, il",
  },
  {
    postal_code: "28442",
    zip_name: "hallsboro, nc",
  },
  {
    postal_code: "76690",
    zip_name: "walnut springs, tx",
  },
  {
    postal_code: "30810",
    zip_name: "gibson, ga",
  },
  {
    postal_code: "23070",
    zip_name: "hardyville, va",
  },
  {
    postal_code: "19492",
    zip_name: "zieglerville, pa",
  },
  {
    postal_code: "27846",
    zip_name: "jamesville, nc",
  },
  {
    postal_code: "2739",
    zip_name: "mattapoisett, ma",
  },
  {
    postal_code: "20777",
    zip_name: "highland, md",
  },
  {
    postal_code: "53816",
    zip_name: "mount hope, wi",
  },
  {
    postal_code: "12985",
    zip_name: "schuyler falls, ny",
  },
  {
    postal_code: "76627",
    zip_name: "blum, tx",
  },
  {
    postal_code: "96120",
    zip_name: "markleeville, ca",
  },
  {
    postal_code: "84644",
    zip_name: "meadow, ut",
  },
  {
    postal_code: "81152",
    zip_name: "san luis, co",
  },
  {
    postal_code: "62272",
    zip_name: "percy, il",
  },
  {
    postal_code: "20664",
    zip_name: "newburg, md",
  },
  {
    postal_code: "51577",
    zip_name: "walnut, ia",
  },
  {
    postal_code: "60940",
    zip_name: "grant park, il",
  },
  {
    postal_code: "1590",
    zip_name: "sutton, ma",
  },
  {
    postal_code: "54545",
    zip_name: "manitowish waters, wi",
  },
  {
    postal_code: "26624",
    zip_name: "gassaway, wv",
  },
  {
    postal_code: "24431",
    zip_name: "crimora, va",
  },
  {
    postal_code: "61862",
    zip_name: "penfield, il",
  },
  {
    postal_code: "68649",
    zip_name: "north bend, ne",
  },
  {
    postal_code: "49745",
    zip_name: "hessel, mi",
  },
  {
    postal_code: "61769",
    zip_name: "saunemin, il",
  },
  {
    postal_code: "41332",
    zip_name: "hazel green, ky",
  },
  {
    postal_code: "3745",
    zip_name: "cornish, nh",
  },
  {
    postal_code: "11569",
    zip_name: "point lookout, ny",
  },
  {
    postal_code: "43732",
    zip_name: "cumberland, oh",
  },
  {
    postal_code: "38868",
    zip_name: "shannon, ms",
  },
  {
    postal_code: "4563",
    zip_name: "cushing, me",
  },
  {
    postal_code: "75477",
    zip_name: "roxton, tx",
  },
  {
    postal_code: "4910",
    zip_name: "albion, me",
  },
  {
    postal_code: "56551",
    zip_name: "henning, mn",
  },
  {
    postal_code: "4958",
    zip_name: "north anson, me",
  },
  {
    postal_code: "15428",
    zip_name: "dawson, pa",
  },
  {
    postal_code: "40046",
    zip_name: "mount eden, ky",
  },
  {
    postal_code: "42712",
    zip_name: "big clifty, ky",
  },
  {
    postal_code: "91210",
    zip_name: "glendale, ca",
  },
  {
    postal_code: "95912",
    zip_name: "arbuckle, ca",
  },
  {
    postal_code: "5759",
    zip_name: "north clarendon, vt",
  },
  {
    postal_code: "44669",
    zip_name: "paris, oh",
  },
  {
    postal_code: "53582",
    zip_name: "ridgeway, wi",
  },
  {
    postal_code: "43534",
    zip_name: "mc clure, oh",
  },
  {
    postal_code: "95979",
    zip_name: "stonyford, ca",
  },
  {
    postal_code: "45867",
    zip_name: "mount blanchard, oh",
  },
  {
    postal_code: "85332",
    zip_name: "congress, az",
  },
  {
    postal_code: "12434",
    zip_name: "grand gorge, ny",
  },
  {
    postal_code: "46128",
    zip_name: "fillmore, in",
  },
  {
    postal_code: "56343",
    zip_name: "kensington, mn",
  },
  {
    postal_code: "62326",
    zip_name: "colchester, il",
  },
  {
    postal_code: "1984",
    zip_name: "wenham, ma",
  },
  {
    postal_code: "56369",
    zip_name: "rockville, mn",
  },
  {
    postal_code: "75550",
    zip_name: "annona, tx",
  },
  {
    postal_code: "58002",
    zip_name: "absaraka, nd",
  },
  {
    postal_code: "47456",
    zip_name: "quincy, in",
  },
  {
    postal_code: "84002",
    zip_name: "altonah, ut",
  },
  {
    postal_code: "79358",
    zip_name: "ropesville, tx",
  },
  {
    postal_code: "74646",
    zip_name: "nardin, ok",
  },
  {
    postal_code: "80820",
    zip_name: "guffey, co",
  },
  {
    postal_code: "31303",
    zip_name: "clyo, ga",
  },
  {
    postal_code: "77564",
    zip_name: "hull, tx",
  },
  {
    postal_code: "28670",
    zip_name: "ronda, nc",
  },
  {
    postal_code: "24448",
    zip_name: "iron gate, va",
  },
  {
    postal_code: "76656",
    zip_name: "lott, tx",
  },
  {
    postal_code: "55031",
    zip_name: "hampton, mn",
  },
  {
    postal_code: "40854",
    zip_name: "loyall, ky",
  },
  {
    postal_code: "77703",
    zip_name: "beaumont, tx",
  },
  {
    postal_code: "43320",
    zip_name: "edison, oh",
  },
  {
    postal_code: "31085",
    zip_name: "shady dale, ga",
  },
  {
    postal_code: "61329",
    zip_name: "ladd, il",
  },
  {
    postal_code: "24260",
    zip_name: "honaker, va",
  },
  {
    postal_code: "61526",
    zip_name: "edelstein, il",
  },
  {
    postal_code: "43360",
    zip_name: "zanesfield, oh",
  },
  {
    postal_code: "42343",
    zip_name: "fordsville, ky",
  },
  {
    postal_code: "62373",
    zip_name: "sutter, il",
  },
  {
    postal_code: "17747",
    zip_name: "loganton, pa",
  },
  {
    postal_code: "27041",
    zip_name: "pilot mountain, nc",
  },
  {
    postal_code: "38916",
    zip_name: "calhoun city, ms",
  },
  {
    postal_code: "25154",
    zip_name: "peytona, wv",
  },
  {
    postal_code: "62838",
    zip_name: "farina, il",
  },
  {
    postal_code: "62821",
    zip_name: "carmi, il",
  },
  {
    postal_code: "15362",
    zip_name: "spraggs, pa",
  },
  {
    postal_code: "97477",
    zip_name: "springfield, or",
  },
  {
    postal_code: "43406",
    zip_name: "bradner, oh",
  },
  {
    postal_code: "52768",
    zip_name: "princeton, ia",
  },
  {
    postal_code: "50465",
    zip_name: "rake, ia",
  },
  {
    postal_code: "45148",
    zip_name: "midland, oh",
  },
  {
    postal_code: "40170",
    zip_name: "stephensport, ky",
  },
  {
    postal_code: "84007",
    zip_name: "bluebell, ut",
  },
  {
    postal_code: "57641",
    zip_name: "mc intosh, sd",
  },
  {
    postal_code: "28735",
    zip_name: "gerton, nc",
  },
  {
    postal_code: "22971",
    zip_name: "shipman, va",
  },
  {
    postal_code: "42732",
    zip_name: "eastview, ky",
  },
  {
    postal_code: "35959",
    zip_name: "cedar bluff, al",
  },
  {
    postal_code: "76934",
    zip_name: "carlsbad, tx",
  },
  {
    postal_code: "2188",
    zip_name: "weymouth, ma",
  },
  {
    postal_code: "96107",
    zip_name: "coleville, ca",
  },
  {
    postal_code: "43754",
    zip_name: "lewisville, oh",
  },
  {
    postal_code: "97431",
    zip_name: "dexter, or",
  },
  {
    postal_code: "74054",
    zip_name: "osage, ok",
  },
  {
    postal_code: "97865",
    zip_name: "mount vernon, or",
  },
  {
    postal_code: "52751",
    zip_name: "grand mound, ia",
  },
  {
    postal_code: "64402",
    zip_name: "albany, mo",
  },
  {
    postal_code: "13675",
    zip_name: "plessis, ny",
  },
  {
    postal_code: "27865",
    zip_name: "pinetown, nc",
  },
  {
    postal_code: "45744",
    zip_name: "lowell, oh",
  },
  {
    postal_code: "1770",
    zip_name: "sherborn, ma",
  },
  {
    postal_code: "95970",
    zip_name: "princeton, ca",
  },
  {
    postal_code: "47932",
    zip_name: "covington, in",
  },
  {
    postal_code: "39169",
    zip_name: "tchula, ms",
  },
  {
    postal_code: "36279",
    zip_name: "wellington, al",
  },
  {
    postal_code: "56166",
    zip_name: "revere, mn",
  },
  {
    postal_code: "17769",
    zip_name: "slate run, pa",
  },
  {
    postal_code: "52530",
    zip_name: "agency, ia",
  },
  {
    postal_code: "43443",
    zip_name: "luckey, oh",
  },
  {
    postal_code: "44855",
    zip_name: "north fairfield, oh",
  },
  {
    postal_code: "50424",
    zip_name: "buffalo center, ia",
  },
  {
    postal_code: "25523",
    zip_name: "hamlin, wv",
  },
  {
    postal_code: "56153",
    zip_name: "leota, mn",
  },
  {
    postal_code: "54454",
    zip_name: "milladore, wi",
  },
  {
    postal_code: "70659",
    zip_name: "rosepine, la",
  },
  {
    postal_code: "78632",
    zip_name: "harwood, tx",
  },
  {
    postal_code: "50851",
    zip_name: "lenox, ia",
  },
  {
    postal_code: "49056",
    zip_name: "grand junction, mi",
  },
  {
    postal_code: "72663",
    zip_name: "onia, ar",
  },
  {
    postal_code: "17758",
    zip_name: "muncy valley, pa",
  },
  {
    postal_code: "4354",
    zip_name: "palermo, me",
  },
  {
    postal_code: "66544",
    zip_name: "vermillion, ks",
  },
  {
    postal_code: "92285",
    zip_name: "landers, ca",
  },
  {
    postal_code: "16250",
    zip_name: "sagamore, pa",
  },
  {
    postal_code: "47030",
    zip_name: "metamora, in",
  },
  {
    postal_code: "33854",
    zip_name: "lakeshore, fl",
  },
  {
    postal_code: "49861",
    zip_name: "michigamme, mi",
  },
  {
    postal_code: "2876",
    zip_name: "slatersville, ri",
  },
  {
    postal_code: "29434",
    zip_name: "cordesville, sc",
  },
  {
    postal_code: "13450",
    zip_name: "roseboom, ny",
  },
  {
    postal_code: "58746",
    zip_name: "kenmare, nd",
  },
  {
    postal_code: "38356",
    zip_name: "medon, tn",
  },
  {
    postal_code: "12910",
    zip_name: "altona, ny",
  },
  {
    postal_code: "58722",
    zip_name: "burlington, nd",
  },
  {
    postal_code: "64136",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "79357",
    zip_name: "ralls, tx",
  },
  {
    postal_code: "75486",
    zip_name: "sumner, tx",
  },
  {
    postal_code: "67837",
    zip_name: "copeland, ks",
  },
  {
    postal_code: "85034",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "12966",
    zip_name: "north bangor, ny",
  },
  {
    postal_code: "68924",
    zip_name: "axtell, ne",
  },
  {
    postal_code: "54745",
    zip_name: "holcombe, wi",
  },
  {
    postal_code: "97390",
    zip_name: "tidewater, or",
  },
  {
    postal_code: "47270",
    zip_name: "paris crossing, in",
  },
  {
    postal_code: "47018",
    zip_name: "dillsboro, in",
  },
  {
    postal_code: "97758",
    zip_name: "riley, or",
  },
  {
    postal_code: "72538",
    zip_name: "gepp, ar",
  },
  {
    postal_code: "71429",
    zip_name: "florien, la",
  },
  {
    postal_code: "62424",
    zip_name: "dieterich, il",
  },
  {
    postal_code: "43933",
    zip_name: "jacobsburg, oh",
  },
  {
    postal_code: "75489",
    zip_name: "tom bean, tx",
  },
  {
    postal_code: "16029",
    zip_name: "east butler, pa",
  },
  {
    postal_code: "4854",
    zip_name: "owls head, me",
  },
  {
    postal_code: "68716",
    zip_name: "beemer, ne",
  },
  {
    postal_code: "29042",
    zip_name: "denmark, sc",
  },
  {
    postal_code: "35610",
    zip_name: "anderson, al",
  },
  {
    postal_code: "4943",
    zip_name: "hartland, me",
  },
  {
    postal_code: "56684",
    zip_name: "trail, mn",
  },
  {
    postal_code: "12169",
    zip_name: "stephentown, ny",
  },
  {
    postal_code: "64647",
    zip_name: "jameson, mo",
  },
  {
    postal_code: "55713",
    zip_name: "buhl, mn",
  },
  {
    postal_code: "72024",
    zip_name: "carlisle, ar",
  },
  {
    postal_code: "50153",
    zip_name: "lynnville, ia",
  },
  {
    postal_code: "29639",
    zip_name: "due west, sc",
  },
  {
    postal_code: "96774",
    zip_name: "ookala, hi",
  },
  {
    postal_code: "62028",
    zip_name: "elsah, il",
  },
  {
    postal_code: "76825",
    zip_name: "brady, tx",
  },
  {
    postal_code: "30452",
    zip_name: "register, ga",
  },
  {
    postal_code: "14869",
    zip_name: "odessa, ny",
  },
  {
    postal_code: "93660",
    zip_name: "san joaquin, ca",
  },
  {
    postal_code: "79042",
    zip_name: "happy, tx",
  },
  {
    postal_code: "24430",
    zip_name: "craigsville, va",
  },
  {
    postal_code: "3586",
    zip_name: "sugar hill, nh",
  },
  {
    postal_code: "22935",
    zip_name: "dyke, va",
  },
  {
    postal_code: "15558",
    zip_name: "salisbury, pa",
  },
  {
    postal_code: "79370",
    zip_name: "spur, tx",
  },
  {
    postal_code: "1922",
    zip_name: "byfield, ma",
  },
  {
    postal_code: "53911",
    zip_name: "arlington, wi",
  },
  {
    postal_code: "3444",
    zip_name: "dublin, nh",
  },
  {
    postal_code: "43755",
    zip_name: "lore city, oh",
  },
  {
    postal_code: "21539",
    zip_name: "lonaconing, md",
  },
  {
    postal_code: "62877",
    zip_name: "richview, il",
  },
  {
    postal_code: "70773",
    zip_name: "rougon, la",
  },
  {
    postal_code: "57762",
    zip_name: "nisland, sd",
  },
  {
    postal_code: "94305",
    zip_name: "stanford, ca",
  },
  {
    postal_code: "25235",
    zip_name: "chloe, wv",
  },
  {
    postal_code: "50218",
    zip_name: "patterson, ia",
  },
  {
    postal_code: "46939",
    zip_name: "kewanna, in",
  },
  {
    postal_code: "51443",
    zip_name: "glidden, ia",
  },
  {
    postal_code: "15958",
    zip_name: "summerhill, pa",
  },
  {
    postal_code: "13488",
    zip_name: "westford, ny",
  },
  {
    postal_code: "56440",
    zip_name: "clarissa, mn",
  },
  {
    postal_code: "97812",
    zip_name: "arlington, or",
  },
  {
    postal_code: "17006",
    zip_name: "blain, pa",
  },
  {
    postal_code: "61747",
    zip_name: "hopedale, il",
  },
  {
    postal_code: "62216",
    zip_name: "aviston, il",
  },
  {
    postal_code: "65734",
    zip_name: "purdy, mo",
  },
  {
    postal_code: "3215",
    zip_name: "waterville valley, nh",
  },
  {
    postal_code: "2715",
    zip_name: "dighton, ma",
  },
  {
    postal_code: "15727",
    zip_name: "clune, pa",
  },
  {
    postal_code: "38380",
    zip_name: "sugar tree, tn",
  },
  {
    postal_code: "23850",
    zip_name: "ford, va",
  },
  {
    postal_code: "55114",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "61729",
    zip_name: "congerville, il",
  },
  {
    postal_code: "24487",
    zip_name: "williamsville, va",
  },
  {
    postal_code: "25515",
    zip_name: "gallipolis ferry, wv",
  },
  {
    postal_code: "5033",
    zip_name: "bradford, vt",
  },
  {
    postal_code: "95913",
    zip_name: "artois, ca",
  },
  {
    postal_code: "41174",
    zip_name: "south portsmouth, ky",
  },
  {
    postal_code: "17728",
    zip_name: "cogan station, pa",
  },
  {
    postal_code: "76636",
    zip_name: "covington, tx",
  },
  {
    postal_code: "93040",
    zip_name: "piru, ca",
  },
  {
    postal_code: "65773",
    zip_name: "wasola, mo",
  },
  {
    postal_code: "79041",
    zip_name: "hale center, tx",
  },
  {
    postal_code: "1074",
    zip_name: "south barre, ma",
  },
  {
    postal_code: "95419",
    zip_name: "camp meeker, ca",
  },
  {
    postal_code: "99137",
    zip_name: "hunters, wa",
  },
  {
    postal_code: "56519",
    zip_name: "borup, mn",
  },
  {
    postal_code: "84539",
    zip_name: "sunnyside, ut",
  },
  {
    postal_code: "5774",
    zip_name: "wells, vt",
  },
  {
    postal_code: "3882",
    zip_name: "effingham, nh",
  },
  {
    postal_code: "50001",
    zip_name: "ackworth, ia",
  },
  {
    postal_code: "47955",
    zip_name: "linden, in",
  },
  {
    postal_code: "62240",
    zip_name: "east carondelet, il",
  },
  {
    postal_code: "38080",
    zip_name: "ridgely, tn",
  },
  {
    postal_code: "92385",
    zip_name: "skyforest, ca",
  },
  {
    postal_code: "56660",
    zip_name: "mizpah, mn",
  },
  {
    postal_code: "61875",
    zip_name: "seymour, il",
  },
  {
    postal_code: "76371",
    zip_name: "munday, tx",
  },
  {
    postal_code: "79097",
    zip_name: "white deer, tx",
  },
  {
    postal_code: "62440",
    zip_name: "lerna, il",
  },
  {
    postal_code: "47884",
    zip_name: "universal, in",
  },
  {
    postal_code: "18471",
    zip_name: "waverly, pa",
  },
  {
    postal_code: "97720",
    zip_name: "burns, or",
  },
  {
    postal_code: "78884",
    zip_name: "utopia, tx",
  },
  {
    postal_code: "96048",
    zip_name: "junction city, ca",
  },
  {
    postal_code: "38481",
    zip_name: "saint joseph, tn",
  },
  {
    postal_code: "98850",
    zip_name: "rock island, wa",
  },
  {
    postal_code: "50210",
    zip_name: "new virginia, ia",
  },
  {
    postal_code: "8106",
    zip_name: "audubon, nj",
  },
  {
    postal_code: "93066",
    zip_name: "somis, ca",
  },
  {
    postal_code: "38311",
    zip_name: "bath springs, tn",
  },
  {
    postal_code: "44445",
    zip_name: "new waterford, oh",
  },
  {
    postal_code: "28385",
    zip_name: "salemburg, nc",
  },
  {
    postal_code: "4493",
    zip_name: "west enfield, me",
  },
  {
    postal_code: "1564",
    zip_name: "sterling, ma",
  },
  {
    postal_code: "15946",
    zip_name: "portage, pa",
  },
  {
    postal_code: "70357",
    zip_name: "golden meadow, la",
  },
  {
    postal_code: "88339",
    zip_name: "mayhill, nm",
  },
  {
    postal_code: "4048",
    zip_name: "limerick, me",
  },
  {
    postal_code: "72947",
    zip_name: "mulberry, ar",
  },
  {
    postal_code: "3082",
    zip_name: "lyndeborough, nh",
  },
  {
    postal_code: "93453",
    zip_name: "santa margarita, ca",
  },
  {
    postal_code: "1754",
    zip_name: "maynard, ma",
  },
  {
    postal_code: "5857",
    zip_name: "newport center, vt",
  },
  {
    postal_code: "5361",
    zip_name: "whitingham, vt",
  },
  {
    postal_code: "62237",
    zip_name: "coulterville, il",
  },
  {
    postal_code: "14428",
    zip_name: "churchville, ny",
  },
  {
    postal_code: "41064",
    zip_name: "mount olivet, ky",
  },
  {
    postal_code: "15050",
    zip_name: "hookstown, pa",
  },
  {
    postal_code: "59065",
    zip_name: "pray, mt",
  },
  {
    postal_code: "7080",
    zip_name: "south plainfield, nj",
  },
  {
    postal_code: "54748",
    zip_name: "jim falls, wi",
  },
  {
    postal_code: "25164",
    zip_name: "procious, wv",
  },
  {
    postal_code: "56386",
    zip_name: "wahkon, mn",
  },
  {
    postal_code: "20842",
    zip_name: "dickerson, md",
  },
  {
    postal_code: "5655",
    zip_name: "hyde park, vt",
  },
  {
    postal_code: "76534",
    zip_name: "holland, tx",
  },
  {
    postal_code: "43518",
    zip_name: "edon, oh",
  },
  {
    postal_code: "66524",
    zip_name: "overbrook, ks",
  },
  {
    postal_code: "53143",
    zip_name: "kenosha, wi",
  },
  {
    postal_code: "30619",
    zip_name: "arnoldsville, ga",
  },
  {
    postal_code: "77964",
    zip_name: "hallettsville, tx",
  },
  {
    postal_code: "49305",
    zip_name: "barryton, mi",
  },
  {
    postal_code: "31779",
    zip_name: "pelham, ga",
  },
  {
    postal_code: "38650",
    zip_name: "myrtle, ms",
  },
  {
    postal_code: "44032",
    zip_name: "dorset, oh",
  },
  {
    postal_code: "43557",
    zip_name: "stryker, oh",
  },
  {
    postal_code: "49102",
    zip_name: "berrien center, mi",
  },
  {
    postal_code: "42757",
    zip_name: "magnolia, ky",
  },
  {
    postal_code: "53515",
    zip_name: "black earth, wi",
  },
  {
    postal_code: "1081",
    zip_name: "wales, ma",
  },
  {
    postal_code: "4653",
    zip_name: "bass harbor, me",
  },
  {
    postal_code: "34956",
    zip_name: "indiantown, fl",
  },
  {
    postal_code: "68301",
    zip_name: "adams, ne",
  },
  {
    postal_code: "26456",
    zip_name: "west union, wv",
  },
  {
    postal_code: "78672",
    zip_name: "tow, tx",
  },
  {
    postal_code: "36877",
    zip_name: "smiths station, al",
  },
  {
    postal_code: "21738",
    zip_name: "glenwood, md",
  },
  {
    postal_code: "4259",
    zip_name: "monmouth, me",
  },
  {
    postal_code: "28679",
    zip_name: "sugar grove, nc",
  },
  {
    postal_code: "95681",
    zip_name: "sheridan, ca",
  },
  {
    postal_code: "5083",
    zip_name: "west fairlee, vt",
  },
  {
    postal_code: "74131",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "10710",
    zip_name: "yonkers, ny",
  },
  {
    postal_code: "55615",
    zip_name: "tofte, mn",
  },
  {
    postal_code: "78631",
    zip_name: "harper, tx",
  },
  {
    postal_code: "26362",
    zip_name: "harrisville, wv",
  },
  {
    postal_code: "55342",
    zip_name: "hector, mn",
  },
  {
    postal_code: "4294",
    zip_name: "wilton, me",
  },
  {
    postal_code: "71341",
    zip_name: "hessmer, la",
  },
  {
    postal_code: "70665",
    zip_name: "sulphur, la",
  },
  {
    postal_code: "46390",
    zip_name: "wanatah, in",
  },
  {
    postal_code: "38659",
    zip_name: "potts camp, ms",
  },
  {
    postal_code: "4732",
    zip_name: "ashland, me",
  },
  {
    postal_code: "12936",
    zip_name: "essex, ny",
  },
  {
    postal_code: "77701",
    zip_name: "beaumont, tx",
  },
  {
    postal_code: "12930",
    zip_name: "dickinson center, ny",
  },
  {
    postal_code: "14433",
    zip_name: "clyde, ny",
  },
  {
    postal_code: "14167",
    zip_name: "varysburg, ny",
  },
  {
    postal_code: "43001",
    zip_name: "alexandria, oh",
  },
  {
    postal_code: "12192",
    zip_name: "west coxsackie, ny",
  },
  {
    postal_code: "1351",
    zip_name: "montague, ma",
  },
  {
    postal_code: "79226",
    zip_name: "clarendon, tx",
  },
  {
    postal_code: "97229",
    zip_name: "portland, or",
  },
  {
    postal_code: "68361",
    zip_name: "geneva, ne",
  },
  {
    postal_code: "48933",
    zip_name: "lansing, mi",
  },
  {
    postal_code: "49816",
    zip_name: "chatham, mi",
  },
  {
    postal_code: "78950",
    zip_name: "new ulm, tx",
  },
  {
    postal_code: "51232",
    zip_name: "ashton, ia",
  },
  {
    postal_code: "23920",
    zip_name: "brodnax, va",
  },
  {
    postal_code: "66449",
    zip_name: "leonardville, ks",
  },
  {
    postal_code: "21057",
    zip_name: "glen arm, md",
  },
  {
    postal_code: "59486",
    zip_name: "valier, mt",
  },
  {
    postal_code: "40360",
    zip_name: "owingsville, ky",
  },
  {
    postal_code: "2109",
    zip_name: "boston, ma",
  },
  {
    postal_code: "49094",
    zip_name: "union city, mi",
  },
  {
    postal_code: "88401",
    zip_name: "tucumcari, nm",
  },
  {
    postal_code: "48701",
    zip_name: "akron, mi",
  },
  {
    postal_code: "43136",
    zip_name: "lithopolis, oh",
  },
  {
    postal_code: "37847",
    zip_name: "pioneer, tn",
  },
  {
    postal_code: "43045",
    zip_name: "milford center, oh",
  },
  {
    postal_code: "67420",
    zip_name: "beloit, ks",
  },
  {
    postal_code: "76484",
    zip_name: "palo pinto, tx",
  },
  {
    postal_code: "18453",
    zip_name: "pleasant mount, pa",
  },
  {
    postal_code: "85324",
    zip_name: "black canyon city, az",
  },
  {
    postal_code: "28421",
    zip_name: "atkinson, nc",
  },
  {
    postal_code: "33851",
    zip_name: "lake hamilton, fl",
  },
  {
    postal_code: "45877",
    zip_name: "pandora, oh",
  },
  {
    postal_code: "50257",
    zip_name: "truro, ia",
  },
  {
    postal_code: "18833",
    zip_name: "new albany, pa",
  },
  {
    postal_code: "12859",
    zip_name: "porter corners, ny",
  },
  {
    postal_code: "49227",
    zip_name: "allen, mi",
  },
  {
    postal_code: "43739",
    zip_name: "glenford, oh",
  },
  {
    postal_code: "62278",
    zip_name: "red bud, il",
  },
  {
    postal_code: "84746",
    zip_name: "leeds, ut",
  },
  {
    postal_code: "13334",
    zip_name: "eaton, ny",
  },
  {
    postal_code: "61728",
    zip_name: "colfax, il",
  },
  {
    postal_code: "35188",
    zip_name: "woodstock, al",
  },
  {
    postal_code: "23148",
    zip_name: "saint stephens church, va",
  },
  {
    postal_code: "56243",
    zip_name: "grove city, mn",
  },
  {
    postal_code: "67056",
    zip_name: "halstead, ks",
  },
  {
    postal_code: "56090",
    zip_name: "vernon center, mn",
  },
  {
    postal_code: "12450",
    zip_name: "lanesville, ny",
  },
  {
    postal_code: "45034",
    zip_name: "kings mills, oh",
  },
  {
    postal_code: "49840",
    zip_name: "gulliver, mi",
  },
  {
    postal_code: "74056",
    zip_name: "pawhuska, ok",
  },
  {
    postal_code: "96057",
    zip_name: "mccloud, ca",
  },
  {
    postal_code: "55314",
    zip_name: "buffalo lake, mn",
  },
  {
    postal_code: "62634",
    zip_name: "elkhart, il",
  },
  {
    postal_code: "4539",
    zip_name: "bristol, me",
  },
  {
    postal_code: "93545",
    zip_name: "lone pine, ca",
  },
  {
    postal_code: "25843",
    zip_name: "ghent, wv",
  },
  {
    postal_code: "52637",
    zip_name: "mediapolis, ia",
  },
  {
    postal_code: "15360",
    zip_name: "scenery hill, pa",
  },
  {
    postal_code: "4863",
    zip_name: "vinalhaven, me",
  },
  {
    postal_code: "65713",
    zip_name: "niangua, mo",
  },
  {
    postal_code: "51239",
    zip_name: "hull, ia",
  },
  {
    postal_code: "68651",
    zip_name: "osceola, ne",
  },
  {
    postal_code: "49436",
    zip_name: "mears, mi",
  },
  {
    postal_code: "3902",
    zip_name: "cape neddick, me",
  },
  {
    postal_code: "60531",
    zip_name: "leland, il",
  },
  {
    postal_code: "5753",
    zip_name: "middlebury, vt",
  },
  {
    postal_code: "80731",
    zip_name: "haxtun, co",
  },
  {
    postal_code: "96140",
    zip_name: "carnelian bay, ca",
  },
  {
    postal_code: "11962",
    zip_name: "sagaponack, ny",
  },
  {
    postal_code: "31555",
    zip_name: "odum, ga",
  },
  {
    postal_code: "1583",
    zip_name: "west boylston, ma",
  },
  {
    postal_code: "2032",
    zip_name: "east walpole, ma",
  },
  {
    postal_code: "62865",
    zip_name: "mulkeytown, il",
  },
  {
    postal_code: "13835",
    zip_name: "richford, ny",
  },
  {
    postal_code: "22846",
    zip_name: "penn laird, va",
  },
  {
    postal_code: "38948",
    zip_name: "oakland, ms",
  },
  {
    postal_code: "38367",
    zip_name: "ramer, tn",
  },
  {
    postal_code: "43145",
    zip_name: "new holland, oh",
  },
  {
    postal_code: "45881",
    zip_name: "rawson, oh",
  },
  {
    postal_code: "38316",
    zip_name: "bradford, tn",
  },
  {
    postal_code: "48417",
    zip_name: "burt, mi",
  },
  {
    postal_code: "76638",
    zip_name: "crawford, tx",
  },
  {
    postal_code: "15469",
    zip_name: "normalville, pa",
  },
  {
    postal_code: "72044",
    zip_name: "edgemont, ar",
  },
  {
    postal_code: "3609",
    zip_name: "north walpole, nh",
  },
  {
    postal_code: "68778",
    zip_name: "springview, ne",
  },
  {
    postal_code: "49927",
    zip_name: "gaastra, mi",
  },
  {
    postal_code: "62295",
    zip_name: "valmeyer, il",
  },
  {
    postal_code: "50468",
    zip_name: "rockford, ia",
  },
  {
    postal_code: "16925",
    zip_name: "gillett, pa",
  },
  {
    postal_code: "96044",
    zip_name: "hornbrook, ca",
  },
  {
    postal_code: "8323",
    zip_name: "greenwich, nj",
  },
  {
    postal_code: "27847",
    zip_name: "kelford, nc",
  },
  {
    postal_code: "97346",
    zip_name: "gates, or",
  },
  {
    postal_code: "53804",
    zip_name: "bloomington, wi",
  },
  {
    postal_code: "95019",
    zip_name: "freedom, ca",
  },
  {
    postal_code: "87540",
    zip_name: "lamy, nm",
  },
  {
    postal_code: "12177",
    zip_name: "tribes hill, ny",
  },
  {
    postal_code: "8536",
    zip_name: "plainsboro, nj",
  },
  {
    postal_code: "18463",
    zip_name: "sterling, pa",
  },
  {
    postal_code: "63436",
    zip_name: "center, mo",
  },
  {
    postal_code: "51564",
    zip_name: "pisgah, ia",
  },
  {
    postal_code: "38258",
    zip_name: "trezevant, tn",
  },
  {
    postal_code: "55959",
    zip_name: "minnesota city, mn",
  },
  {
    postal_code: "1236",
    zip_name: "housatonic, ma",
  },
  {
    postal_code: "80864",
    zip_name: "yoder, co",
  },
  {
    postal_code: "37872",
    zip_name: "sunbright, tn",
  },
  {
    postal_code: "85606",
    zip_name: "cochise, az",
  },
  {
    postal_code: "99119",
    zip_name: "cusick, wa",
  },
  {
    postal_code: "91962",
    zip_name: "pine valley, ca",
  },
  {
    postal_code: "49747",
    zip_name: "hubbard lake, mi",
  },
  {
    postal_code: "97032",
    zip_name: "hubbard, or",
  },
  {
    postal_code: "14757",
    zip_name: "mayville, ny",
  },
  {
    postal_code: "15943",
    zip_name: "nanty glo, pa",
  },
  {
    postal_code: "28524",
    zip_name: "davis, nc",
  },
  {
    postal_code: "66052",
    zip_name: "linwood, ks",
  },
  {
    postal_code: "13739",
    zip_name: "bloomville, ny",
  },
  {
    postal_code: "52068",
    zip_name: "peosta, ia",
  },
  {
    postal_code: "32096",
    zip_name: "white springs, fl",
  },
  {
    postal_code: "56277",
    zip_name: "olivia, mn",
  },
  {
    postal_code: "27823",
    zip_name: "enfield, nc",
  },
  {
    postal_code: "95245",
    zip_name: "mokelumne hill, ca",
  },
  {
    postal_code: "35172",
    zip_name: "trafford, al",
  },
  {
    postal_code: "46115",
    zip_name: "carthage, in",
  },
  {
    postal_code: "22742",
    zip_name: "sumerduck, va",
  },
  {
    postal_code: "76554",
    zip_name: "little river academy, tx",
  },
  {
    postal_code: "92086",
    zip_name: "warner springs, ca",
  },
  {
    postal_code: "12468",
    zip_name: "prattsville, ny",
  },
  {
    postal_code: "18451",
    zip_name: "paupack, pa",
  },
  {
    postal_code: "76569",
    zip_name: "rogers, tx",
  },
  {
    postal_code: "45614",
    zip_name: "bidwell, oh",
  },
  {
    postal_code: "21155",
    zip_name: "upperco, md",
  },
  {
    postal_code: "87035",
    zip_name: "moriarty, nm",
  },
  {
    postal_code: "62682",
    zip_name: "san jose, il",
  },
  {
    postal_code: "4061",
    zip_name: "north waterboro, me",
  },
  {
    postal_code: "26031",
    zip_name: "benwood, wv",
  },
  {
    postal_code: "1537",
    zip_name: "north oxford, ma",
  },
  {
    postal_code: "1827",
    zip_name: "dunstable, ma",
  },
  {
    postal_code: "41085",
    zip_name: "silver grove, ky",
  },
  {
    postal_code: "90270",
    zip_name: "maywood, ca",
  },
  {
    postal_code: "75431",
    zip_name: "como, tx",
  },
  {
    postal_code: "50864",
    zip_name: "villisca, ia",
  },
  {
    postal_code: "61028",
    zip_name: "elizabeth, il",
  },
  {
    postal_code: "17065",
    zip_name: "mount holly springs, pa",
  },
  {
    postal_code: "71343",
    zip_name: "jonesville, la",
  },
  {
    postal_code: "44412",
    zip_name: "diamond, oh",
  },
  {
    postal_code: "3779",
    zip_name: "piermont, nh",
  },
  {
    postal_code: "13812",
    zip_name: "nichols, ny",
  },
  {
    postal_code: "55929",
    zip_name: "dover, mn",
  },
  {
    postal_code: "94586",
    zip_name: "sunol, ca",
  },
  {
    postal_code: "22969",
    zip_name: "schuyler, va",
  },
  {
    postal_code: "44695",
    zip_name: "bowerston, oh",
  },
  {
    postal_code: "17931",
    zip_name: "frackville, pa",
  },
  {
    postal_code: "13165",
    zip_name: "waterloo, ny",
  },
  {
    postal_code: "18252",
    zip_name: "tamaqua, pa",
  },
  {
    postal_code: "55354",
    zip_name: "lester prairie, mn",
  },
  {
    postal_code: "32351",
    zip_name: "quincy, fl",
  },
  {
    postal_code: "3044",
    zip_name: "fremont, nh",
  },
  {
    postal_code: "31078",
    zip_name: "roberta, ga",
  },
  {
    postal_code: "24279",
    zip_name: "pound, va",
  },
  {
    postal_code: "43988",
    zip_name: "scio, oh",
  },
  {
    postal_code: "66536",
    zip_name: "saint marys, ks",
  },
  {
    postal_code: "55396",
    zip_name: "winthrop, mn",
  },
  {
    postal_code: "61770",
    zip_name: "saybrook, il",
  },
  {
    postal_code: "35987",
    zip_name: "steele, al",
  },
  {
    postal_code: "74849",
    zip_name: "konawa, ok",
  },
  {
    postal_code: "12042",
    zip_name: "climax, ny",
  },
  {
    postal_code: "68349",
    zip_name: "elmwood, ne",
  },
  {
    postal_code: "67464",
    zip_name: "marquette, ks",
  },
  {
    postal_code: "56115",
    zip_name: "balaton, mn",
  },
  {
    postal_code: "98847",
    zip_name: "peshastin, wa",
  },
  {
    postal_code: "80624",
    zip_name: "gill, co",
  },
  {
    postal_code: "54805",
    zip_name: "almena, wi",
  },
  {
    postal_code: "63655",
    zip_name: "marquand, mo",
  },
  {
    postal_code: "62912",
    zip_name: "buncombe, il",
  },
  {
    postal_code: "48727",
    zip_name: "clifford, mi",
  },
  {
    postal_code: "21861",
    zip_name: "sharptown, md",
  },
  {
    postal_code: "60550",
    zip_name: "shabbona, il",
  },
  {
    postal_code: "66050",
    zip_name: "lecompton, ks",
  },
  {
    postal_code: "70715",
    zip_name: "batchelor, la",
  },
  {
    postal_code: "50830",
    zip_name: "afton, ia",
  },
  {
    postal_code: "62203",
    zip_name: "east saint louis, il",
  },
  {
    postal_code: "5682",
    zip_name: "worcester, vt",
  },
  {
    postal_code: "76077",
    zip_name: "rainbow, tx",
  },
  {
    postal_code: "3279",
    zip_name: "warren, nh",
  },
  {
    postal_code: "93207",
    zip_name: "california hot springs, ca",
  },
  {
    postal_code: "55941",
    zip_name: "hokah, mn",
  },
  {
    postal_code: "43036",
    zip_name: "magnetic springs, oh",
  },
  {
    postal_code: "54511",
    zip_name: "argonne, wi",
  },
  {
    postal_code: "29351",
    zip_name: "joanna, sc",
  },
  {
    postal_code: "33438",
    zip_name: "canal point, fl",
  },
  {
    postal_code: "14062",
    zip_name: "forestville, ny",
  },
  {
    postal_code: "68735",
    zip_name: "ewing, ne",
  },
  {
    postal_code: "12130",
    zip_name: "niverville, ny",
  },
  {
    postal_code: "56312",
    zip_name: "belgrade, mn",
  },
  {
    postal_code: "50601",
    zip_name: "ackley, ia",
  },
  {
    postal_code: "40050",
    zip_name: "new castle, ky",
  },
  {
    postal_code: "56285",
    zip_name: "sacred heart, mn",
  },
  {
    postal_code: "72581",
    zip_name: "tumbling shoals, ar",
  },
  {
    postal_code: "20198",
    zip_name: "the plains, va",
  },
  {
    postal_code: "14037",
    zip_name: "cowlesville, ny",
  },
  {
    postal_code: "41568",
    zip_name: "stopover, ky",
  },
  {
    postal_code: "50647",
    zip_name: "janesville, ia",
  },
  {
    postal_code: "2113",
    zip_name: "boston, ma",
  },
  {
    postal_code: "43084",
    zip_name: "woodstock, oh",
  },
  {
    postal_code: "83540",
    zip_name: "lapwai, id",
  },
  {
    postal_code: "23337",
    zip_name: "wallops island, va",
  },
  {
    postal_code: "66777",
    zip_name: "toronto, ks",
  },
  {
    postal_code: "37345",
    zip_name: "huntland, tn",
  },
  {
    postal_code: "61015",
    zip_name: "chana, il",
  },
  {
    postal_code: "56715",
    zip_name: "brooks, mn",
  },
  {
    postal_code: "68357",
    zip_name: "filley, ne",
  },
  {
    postal_code: "61279",
    zip_name: "reynolds, il",
  },
  {
    postal_code: "62093",
    zip_name: "wilsonville, il",
  },
  {
    postal_code: "79355",
    zip_name: "plains, tx",
  },
  {
    postal_code: "68420",
    zip_name: "pawnee city, ne",
  },
  {
    postal_code: "62938",
    zip_name: "golconda, il",
  },
  {
    postal_code: "52070",
    zip_name: "sabula, ia",
  },
  {
    postal_code: "13841",
    zip_name: "smithville flats, ny",
  },
  {
    postal_code: "96106",
    zip_name: "clio, ca",
  },
  {
    postal_code: "43435",
    zip_name: "helena, oh",
  },
  {
    postal_code: "13842",
    zip_name: "south kortright, ny",
  },
  {
    postal_code: "22959",
    zip_name: "north garden, va",
  },
  {
    postal_code: "94020",
    zip_name: "la honda, ca",
  },
  {
    postal_code: "27291",
    zip_name: "leasburg, nc",
  },
  {
    postal_code: "84013",
    zip_name: "cedar valley, ut",
  },
  {
    postal_code: "39561",
    zip_name: "mc henry, ms",
  },
  {
    postal_code: "10459",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "67851",
    zip_name: "holcomb, ks",
  },
  {
    postal_code: "43115",
    zip_name: "clarksburg, oh",
  },
  {
    postal_code: "54747",
    zip_name: "independence, wi",
  },
  {
    postal_code: "12977",
    zip_name: "ray brook, ny",
  },
  {
    postal_code: "93601",
    zip_name: "ahwahnee, ca",
  },
  {
    postal_code: "65650",
    zip_name: "flemington, mo",
  },
  {
    postal_code: "2779",
    zip_name: "berkley, ma",
  },
  {
    postal_code: "45146",
    zip_name: "martinsville, oh",
  },
  {
    postal_code: "26030",
    zip_name: "beech bottom, wv",
  },
  {
    postal_code: "24479",
    zip_name: "swoope, va",
  },
  {
    postal_code: "17368",
    zip_name: "wrightsville, pa",
  },
  {
    postal_code: "54632",
    zip_name: "genoa, wi",
  },
  {
    postal_code: "28515",
    zip_name: "bayboro, nc",
  },
  {
    postal_code: "63456",
    zip_name: "monroe city, mo",
  },
  {
    postal_code: "20004",
    zip_name: "washington, dc",
  },
  {
    postal_code: "60948",
    zip_name: "loda, il",
  },
  {
    postal_code: "54854",
    zip_name: "maple, wi",
  },
  {
    postal_code: "79764",
    zip_name: "odessa, tx",
  },
  {
    postal_code: "3278",
    zip_name: "warner, nh",
  },
  {
    postal_code: "65724",
    zip_name: "pittsburg, mo",
  },
  {
    postal_code: "55368",
    zip_name: "norwood young america, mn",
  },
  {
    postal_code: "67428",
    zip_name: "canton, ks",
  },
  {
    postal_code: "1375",
    zip_name: "sunderland, ma",
  },
  {
    postal_code: "15060",
    zip_name: "midway, pa",
  },
  {
    postal_code: "15049",
    zip_name: "harwick, pa",
  },
  {
    postal_code: "87020",
    zip_name: "grants, nm",
  },
  {
    postal_code: "75936",
    zip_name: "chester, tx",
  },
  {
    postal_code: "12814",
    zip_name: "bolton landing, ny",
  },
  {
    postal_code: "1610",
    zip_name: "worcester, ma",
  },
  {
    postal_code: "68073",
    zip_name: "yutan, ne",
  },
  {
    postal_code: "53035",
    zip_name: "iron ridge, wi",
  },
  {
    postal_code: "58579",
    zip_name: "wilton, nd",
  },
  {
    postal_code: "52731",
    zip_name: "charlotte, ia",
  },
  {
    postal_code: "51001",
    zip_name: "akron, ia",
  },
  {
    postal_code: "41003",
    zip_name: "berry, ky",
  },
  {
    postal_code: "57276",
    zip_name: "white, sd",
  },
  {
    postal_code: "13618",
    zip_name: "cape vincent, ny",
  },
  {
    postal_code: "28343",
    zip_name: "gibson, nc",
  },
  {
    postal_code: "77560",
    zip_name: "hankamer, tx",
  },
  {
    postal_code: "16113",
    zip_name: "clark, pa",
  },
  {
    postal_code: "28349",
    zip_name: "kenansville, nc",
  },
  {
    postal_code: "84046",
    zip_name: "manila, ut",
  },
  {
    postal_code: "44866",
    zip_name: "polk, oh",
  },
  {
    postal_code: "51652",
    zip_name: "sidney, ia",
  },
  {
    postal_code: "29162",
    zip_name: "turbeville, sc",
  },
  {
    postal_code: "50634",
    zip_name: "gilbertville, ia",
  },
  {
    postal_code: "26032",
    zip_name: "bethany, wv",
  },
  {
    postal_code: "56043",
    zip_name: "hayward, mn",
  },
  {
    postal_code: "96039",
    zip_name: "happy camp, ca",
  },
  {
    postal_code: "56009",
    zip_name: "alden, mn",
  },
  {
    postal_code: "24138",
    zip_name: "pilot, va",
  },
  {
    postal_code: "1521",
    zip_name: "holland, ma",
  },
  {
    postal_code: "61537",
    zip_name: "henry, il",
  },
  {
    postal_code: "52658",
    zip_name: "wever, ia",
  },
  {
    postal_code: "43903",
    zip_name: "amsterdam, oh",
  },
  {
    postal_code: "89418",
    zip_name: "imlay, nv",
  },
  {
    postal_code: "48519",
    zip_name: "burton, mi",
  },
  {
    postal_code: "49653",
    zip_name: "lake leelanau, mi",
  },
  {
    postal_code: "2641",
    zip_name: "east dennis, ma",
  },
  {
    postal_code: "5777",
    zip_name: "west rutland, vt",
  },
  {
    postal_code: "6247",
    zip_name: "hampton, ct",
  },
  {
    postal_code: "65745",
    zip_name: "seligman, mo",
  },
  {
    postal_code: "13026",
    zip_name: "aurora, ny",
  },
  {
    postal_code: "15944",
    zip_name: "new florence, pa",
  },
  {
    postal_code: "30072",
    zip_name: "pine lake, ga",
  },
  {
    postal_code: "16061",
    zip_name: "west sunbury, pa",
  },
  {
    postal_code: "63459",
    zip_name: "new london, mo",
  },
  {
    postal_code: "1949",
    zip_name: "middleton, ma",
  },
  {
    postal_code: "18439",
    zip_name: "lakewood, pa",
  },
  {
    postal_code: "13433",
    zip_name: "port leyden, ny",
  },
  {
    postal_code: "44666",
    zip_name: "north lawrence, oh",
  },
  {
    postal_code: "36340",
    zip_name: "geneva, al",
  },
  {
    postal_code: "59743",
    zip_name: "melrose, mt",
  },
  {
    postal_code: "55783",
    zip_name: "sturgeon lake, mn",
  },
  {
    postal_code: "14613",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "32324",
    zip_name: "chattahoochee, fl",
  },
  {
    postal_code: "26142",
    zip_name: "davisville, wv",
  },
  {
    postal_code: "55795",
    zip_name: "willow river, mn",
  },
  {
    postal_code: "44214",
    zip_name: "burbank, oh",
  },
  {
    postal_code: "68418",
    zip_name: "palmyra, ne",
  },
  {
    postal_code: "61857",
    zip_name: "muncie, il",
  },
  {
    postal_code: "60157",
    zip_name: "medinah, il",
  },
  {
    postal_code: "54611",
    zip_name: "alma center, wi",
  },
  {
    postal_code: "50563",
    zip_name: "manson, ia",
  },
  {
    postal_code: "99111",
    zip_name: "colfax, wa",
  },
  {
    postal_code: "15665",
    zip_name: "manor, pa",
  },
  {
    postal_code: "17737",
    zip_name: "hughesville, pa",
  },
  {
    postal_code: "79836",
    zip_name: "clint, tx",
  },
  {
    postal_code: "3887",
    zip_name: "union, nh",
  },
  {
    postal_code: "15051",
    zip_name: "indianola, pa",
  },
  {
    postal_code: "27937",
    zip_name: "gates, nc",
  },
  {
    postal_code: "63363",
    zip_name: "new florence, mo",
  },
  {
    postal_code: "68621",
    zip_name: "ames, ne",
  },
  {
    postal_code: "1468",
    zip_name: "templeton, ma",
  },
  {
    postal_code: "54546",
    zip_name: "mellen, wi",
  },
  {
    postal_code: "39480",
    zip_name: "soso, ms",
  },
  {
    postal_code: "43540",
    zip_name: "metamora, oh",
  },
  {
    postal_code: "94951",
    zip_name: "penngrove, ca",
  },
  {
    postal_code: "17040",
    zip_name: "landisburg, pa",
  },
  {
    postal_code: "45661",
    zip_name: "piketon, oh",
  },
  {
    postal_code: "64848",
    zip_name: "la russell, mo",
  },
  {
    postal_code: "50475",
    zip_name: "sheffield, ia",
  },
  {
    postal_code: "13606",
    zip_name: "adams center, ny",
  },
  {
    postal_code: "21917",
    zip_name: "colora, md",
  },
  {
    postal_code: "95951",
    zip_name: "hamilton city, ca",
  },
  {
    postal_code: "43340",
    zip_name: "mount victory, oh",
  },
  {
    postal_code: "38459",
    zip_name: "frankewing, tn",
  },
  {
    postal_code: "12187",
    zip_name: "warnerville, ny",
  },
  {
    postal_code: "85172",
    zip_name: "stanfield, az",
  },
  {
    postal_code: "26451",
    zip_name: "west milford, wv",
  },
  {
    postal_code: "95636",
    zip_name: "grizzly flats, ca",
  },
  {
    postal_code: "50557",
    zip_name: "lehigh, ia",
  },
  {
    postal_code: "62257",
    zip_name: "marissa, il",
  },
  {
    postal_code: "54106",
    zip_name: "black creek, wi",
  },
  {
    postal_code: "80860",
    zip_name: "victor, co",
  },
  {
    postal_code: "77664",
    zip_name: "warren, tx",
  },
  {
    postal_code: "13861",
    zip_name: "west oneonta, ny",
  },
  {
    postal_code: "42406",
    zip_name: "corydon, ky",
  },
  {
    postal_code: "61748",
    zip_name: "hudson, il",
  },
  {
    postal_code: "14625",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "98581",
    zip_name: "ryderwood, wa",
  },
  {
    postal_code: "78638",
    zip_name: "kingsbury, tx",
  },
  {
    postal_code: "65534",
    zip_name: "laquey, mo",
  },
  {
    postal_code: "83112",
    zip_name: "bedford, wy",
  },
  {
    postal_code: "37694",
    zip_name: "watauga, tn",
  },
  {
    postal_code: "16928",
    zip_name: "knoxville, pa",
  },
  {
    postal_code: "40510",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "68404",
    zip_name: "martell, ne",
  },
  {
    postal_code: "99158",
    zip_name: "oakesdale, wa",
  },
  {
    postal_code: "27916",
    zip_name: "aydlett, nc",
  },
  {
    postal_code: "1607",
    zip_name: "worcester, ma",
  },
  {
    postal_code: "62854",
    zip_name: "kinmundy, il",
  },
  {
    postal_code: "70515",
    zip_name: "basile, la",
  },
  {
    postal_code: "3268",
    zip_name: "salisbury, nh",
  },
  {
    postal_code: "12412",
    zip_name: "boiceville, ny",
  },
  {
    postal_code: "48449",
    zip_name: "lennon, mi",
  },
  {
    postal_code: "13693",
    zip_name: "three mile bay, ny",
  },
  {
    postal_code: "38006",
    zip_name: "bells, tn",
  },
  {
    postal_code: "54171",
    zip_name: "sobieski, wi",
  },
  {
    postal_code: "49733",
    zip_name: "frederic, mi",
  },
  {
    postal_code: "76578",
    zip_name: "thrall, tx",
  },
  {
    postal_code: "54232",
    zip_name: "saint nazianz, wi",
  },
  {
    postal_code: "14855",
    zip_name: "jasper, ny",
  },
  {
    postal_code: "26591",
    zip_name: "worthington, wv",
  },
  {
    postal_code: "27852",
    zip_name: "macclesfield, nc",
  },
  {
    postal_code: "56336",
    zip_name: "grey eagle, mn",
  },
  {
    postal_code: "28635",
    zip_name: "hays, nc",
  },
  {
    postal_code: "46130",
    zip_name: "fountaintown, in",
  },
  {
    postal_code: "55935",
    zip_name: "fountain, mn",
  },
  {
    postal_code: "39071",
    zip_name: "flora, ms",
  },
  {
    postal_code: "52565",
    zip_name: "keosauqua, ia",
  },
  {
    postal_code: "30205",
    zip_name: "brooks, ga",
  },
  {
    postal_code: "52157",
    zip_name: "mc gregor, ia",
  },
  {
    postal_code: "3581",
    zip_name: "gorham, nh",
  },
  {
    postal_code: "43316",
    zip_name: "carey, oh",
  },
  {
    postal_code: "54739",
    zip_name: "elk mound, wi",
  },
  {
    postal_code: "26059",
    zip_name: "triadelphia, wv",
  },
  {
    postal_code: "3307",
    zip_name: "loudon, nh",
  },
  {
    postal_code: "23840",
    zip_name: "dewitt, va",
  },
  {
    postal_code: "52255",
    zip_name: "lowden, ia",
  },
  {
    postal_code: "14129",
    zip_name: "perrysburg, ny",
  },
  {
    postal_code: "61060",
    zip_name: "orangeville, il",
  },
  {
    postal_code: "43569",
    zip_name: "weston, oh",
  },
  {
    postal_code: "55032",
    zip_name: "harris, mn",
  },
  {
    postal_code: "1011",
    zip_name: "chester, ma",
  },
  {
    postal_code: "37916",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "4087",
    zip_name: "waterboro, me",
  },
  {
    postal_code: "26038",
    zip_name: "glen dale, wv",
  },
  {
    postal_code: "57002",
    zip_name: "aurora, sd",
  },
  {
    postal_code: "69143",
    zip_name: "hershey, ne",
  },
  {
    postal_code: "4093",
    zip_name: "buxton, me",
  },
  {
    postal_code: "71350",
    zip_name: "mansura, la",
  },
  {
    postal_code: "54732",
    zip_name: "cornell, wi",
  },
  {
    postal_code: "21531",
    zip_name: "friendsville, md",
  },
  {
    postal_code: "62907",
    zip_name: "ava, il",
  },
  {
    postal_code: "74441",
    zip_name: "hulbert, ok",
  },
  {
    postal_code: "18041",
    zip_name: "east greenville, pa",
  },
  {
    postal_code: "44082",
    zip_name: "pierpont, oh",
  },
  {
    postal_code: "66409",
    zip_name: "berryton, ks",
  },
  {
    postal_code: "2717",
    zip_name: "east freetown, ma",
  },
  {
    postal_code: "13315",
    zip_name: "burlington flats, ny",
  },
  {
    postal_code: "43973",
    zip_name: "freeport, oh",
  },
  {
    postal_code: "6103",
    zip_name: "hartford, ct",
  },
  {
    postal_code: "45779",
    zip_name: "syracuse, oh",
  },
  {
    postal_code: "47380",
    zip_name: "ridgeville, in",
  },
  {
    postal_code: "52203",
    zip_name: "amana, ia",
  },
  {
    postal_code: "38015",
    zip_name: "burlison, tn",
  },
  {
    postal_code: "43943",
    zip_name: "rayland, oh",
  },
  {
    postal_code: "46216",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "76641",
    zip_name: "frost, tx",
  },
  {
    postal_code: "1057",
    zip_name: "monson, ma",
  },
  {
    postal_code: "54180",
    zip_name: "wrightstown, wi",
  },
  {
    postal_code: "93616",
    zip_name: "del rey, ca",
  },
  {
    postal_code: "14873",
    zip_name: "prattsburgh, ny",
  },
  {
    postal_code: "78140",
    zip_name: "nixon, tx",
  },
  {
    postal_code: "93250",
    zip_name: "mc farland, ca",
  },
  {
    postal_code: "15321",
    zip_name: "cecil, pa",
  },
  {
    postal_code: "55454",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "79371",
    zip_name: "sudan, tx",
  },
  {
    postal_code: "45696",
    zip_name: "willow wood, oh",
  },
  {
    postal_code: "80737",
    zip_name: "julesburg, co",
  },
  {
    postal_code: "53919",
    zip_name: "brandon, wi",
  },
  {
    postal_code: "93648",
    zip_name: "parlier, ca",
  },
  {
    postal_code: "45856",
    zip_name: "leipsic, oh",
  },
  {
    postal_code: "46590",
    zip_name: "winona lake, in",
  },
  {
    postal_code: "18327",
    zip_name: "delaware water gap, pa",
  },
  {
    postal_code: "38231",
    zip_name: "henry, tn",
  },
  {
    postal_code: "61565",
    zip_name: "sparland, il",
  },
  {
    postal_code: "2826",
    zip_name: "glendale, ri",
  },
  {
    postal_code: "37713",
    zip_name: "bybee, tn",
  },
  {
    postal_code: "4276",
    zip_name: "rumford, me",
  },
  {
    postal_code: "62080",
    zip_name: "ramsey, il",
  },
  {
    postal_code: "55338",
    zip_name: "green isle, mn",
  },
  {
    postal_code: "13123",
    zip_name: "north bay, ny",
  },
  {
    postal_code: "15547",
    zip_name: "jennerstown, pa",
  },
  {
    postal_code: "99029",
    zip_name: "reardan, wa",
  },
  {
    postal_code: "63351",
    zip_name: "jonesburg, mo",
  },
  {
    postal_code: "16112",
    zip_name: "bessemer, pa",
  },
  {
    postal_code: "48469",
    zip_name: "port sanilac, mi",
  },
  {
    postal_code: "56475",
    zip_name: "randall, mn",
  },
  {
    postal_code: "61030",
    zip_name: "forreston, il",
  },
  {
    postal_code: "84633",
    zip_name: "goshen, ut",
  },
  {
    postal_code: "74454",
    zip_name: "porter, ok",
  },
  {
    postal_code: "18623",
    zip_name: "laceyville, pa",
  },
  {
    postal_code: "77430",
    zip_name: "damon, tx",
  },
  {
    postal_code: "95139",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "15935",
    zip_name: "hollsopple, pa",
  },
  {
    postal_code: "87056",
    zip_name: "stanley, nm",
  },
  {
    postal_code: "20129",
    zip_name: "paeonian springs, va",
  },
  {
    postal_code: "76453",
    zip_name: "gordon, tx",
  },
  {
    postal_code: "76679",
    zip_name: "purdon, tx",
  },
  {
    postal_code: "68955",
    zip_name: "juniata, ne",
  },
  {
    postal_code: "99126",
    zip_name: "evans, wa",
  },
  {
    postal_code: "55080",
    zip_name: "stanchfield, mn",
  },
  {
    postal_code: "27553",
    zip_name: "manson, nc",
  },
  {
    postal_code: "34250",
    zip_name: "terra ceia, fl",
  },
  {
    postal_code: "47954",
    zip_name: "ladoga, in",
  },
  {
    postal_code: "94599",
    zip_name: "yountville, ca",
  },
  {
    postal_code: "55384",
    zip_name: "spring park, mn",
  },
  {
    postal_code: "4015",
    zip_name: "casco, me",
  },
  {
    postal_code: "49847",
    zip_name: "hermansville, mi",
  },
  {
    postal_code: "18462",
    zip_name: "starrucca, pa",
  },
  {
    postal_code: "53577",
    zip_name: "plain, wi",
  },
  {
    postal_code: "84324",
    zip_name: "mantua, ut",
  },
  {
    postal_code: "47847",
    zip_name: "dana, in",
  },
  {
    postal_code: "24248",
    zip_name: "ewing, va",
  },
  {
    postal_code: "2858",
    zip_name: "oakland, ri",
  },
  {
    postal_code: "38621",
    zip_name: "crenshaw, ms",
  },
  {
    postal_code: "68723",
    zip_name: "carroll, ne",
  },
  {
    postal_code: "29432",
    zip_name: "branchville, sc",
  },
  {
    postal_code: "12810",
    zip_name: "athol, ny",
  },
  {
    postal_code: "97364",
    zip_name: "neotsu, or",
  },
  {
    postal_code: "39837",
    zip_name: "colquitt, ga",
  },
  {
    postal_code: "76475",
    zip_name: "strawn, tx",
  },
  {
    postal_code: "5758",
    zip_name: "mount holly, vt",
  },
  {
    postal_code: "28009",
    zip_name: "badin, nc",
  },
  {
    postal_code: "72685",
    zip_name: "western grove, ar",
  },
  {
    postal_code: "78833",
    zip_name: "camp wood, tx",
  },
  {
    postal_code: "5474",
    zip_name: "north hero, vt",
  },
  {
    postal_code: "23405",
    zip_name: "machipongo, va",
  },
  {
    postal_code: "64492",
    zip_name: "trimble, mo",
  },
  {
    postal_code: "17840",
    zip_name: "locust gap, pa",
  },
  {
    postal_code: "64430",
    zip_name: "clarksdale, mo",
  },
  {
    postal_code: "42539",
    zip_name: "liberty, ky",
  },
  {
    postal_code: "17772",
    zip_name: "turbotville, pa",
  },
  {
    postal_code: "95984",
    zip_name: "twain, ca",
  },
  {
    postal_code: "72537",
    zip_name: "gamaliel, ar",
  },
  {
    postal_code: "73859",
    zip_name: "vici, ok",
  },
  {
    postal_code: "99110",
    zip_name: "clayton, wa",
  },
  {
    postal_code: "46553",
    zip_name: "new paris, in",
  },
  {
    postal_code: "16656",
    zip_name: "irvona, pa",
  },
  {
    postal_code: "12418",
    zip_name: "cornwallville, ny",
  },
  {
    postal_code: "14847",
    zip_name: "interlaken, ny",
  },
  {
    postal_code: "28622",
    zip_name: "elk park, nc",
  },
  {
    postal_code: "4951",
    zip_name: "monroe, me",
  },
  {
    postal_code: "6081",
    zip_name: "tariffville, ct",
  },
  {
    postal_code: "59030",
    zip_name: "gardiner, mt",
  },
  {
    postal_code: "37731",
    zip_name: "eidson, tn",
  },
  {
    postal_code: "3449",
    zip_name: "hancock, nh",
  },
  {
    postal_code: "42280",
    zip_name: "sharon grove, ky",
  },
  {
    postal_code: "13327",
    zip_name: "croghan, ny",
  },
  {
    postal_code: "80728",
    zip_name: "fleming, co",
  },
  {
    postal_code: "5153",
    zip_name: "proctorsville, vt",
  },
  {
    postal_code: "16332",
    zip_name: "lickingville, pa",
  },
  {
    postal_code: "68406",
    zip_name: "milligan, ne",
  },
  {
    postal_code: "1469",
    zip_name: "townsend, ma",
  },
  {
    postal_code: "49766",
    zip_name: "ossineke, mi",
  },
  {
    postal_code: "56223",
    zip_name: "clarkfield, mn",
  },
  {
    postal_code: "82051",
    zip_name: "bosler, wy",
  },
  {
    postal_code: "31070",
    zip_name: "pinehurst, ga",
  },
  {
    postal_code: "62998",
    zip_name: "wolf lake, il",
  },
  {
    postal_code: "42259",
    zip_name: "mammoth cave, ky",
  },
  {
    postal_code: "39156",
    zip_name: "redwood, ms",
  },
  {
    postal_code: "48137",
    zip_name: "gregory, mi",
  },
  {
    postal_code: "74637",
    zip_name: "fairfax, ok",
  },
  {
    postal_code: "72084",
    zip_name: "leola, ar",
  },
  {
    postal_code: "28423",
    zip_name: "bolton, nc",
  },
  {
    postal_code: "3819",
    zip_name: "danville, nh",
  },
  {
    postal_code: "66966",
    zip_name: "scandia, ks",
  },
  {
    postal_code: "82730",
    zip_name: "upton, wy",
  },
  {
    postal_code: "61933",
    zip_name: "kansas, il",
  },
  {
    postal_code: "35006",
    zip_name: "adger, al",
  },
  {
    postal_code: "62842",
    zip_name: "geff, il",
  },
  {
    postal_code: "70756",
    zip_name: "lottie, la",
  },
  {
    postal_code: "56089",
    zip_name: "twin lakes, mn",
  },
  {
    postal_code: "96109",
    zip_name: "doyle, ca",
  },
  {
    postal_code: "43556",
    zip_name: "sherwood, oh",
  },
  {
    postal_code: "35042",
    zip_name: "centreville, al",
  },
  {
    postal_code: "58581",
    zip_name: "zeeland, nd",
  },
  {
    postal_code: "68836",
    zip_name: "elm creek, ne",
  },
  {
    postal_code: "18092",
    zip_name: "zionsville, pa",
  },
  {
    postal_code: "71368",
    zip_name: "sicily island, la",
  },
  {
    postal_code: "22711",
    zip_name: "banco, va",
  },
  {
    postal_code: "83320",
    zip_name: "carey, id",
  },
  {
    postal_code: "49126",
    zip_name: "sodus, mi",
  },
  {
    postal_code: "75959",
    zip_name: "milam, tx",
  },
  {
    postal_code: "24714",
    zip_name: "beeson, wv",
  },
  {
    postal_code: "98355",
    zip_name: "mineral, wa",
  },
  {
    postal_code: "79502",
    zip_name: "aspermont, tx",
  },
  {
    postal_code: "5828",
    zip_name: "danville, vt",
  },
  {
    postal_code: "61043",
    zip_name: "holcomb, il",
  },
  {
    postal_code: "61830",
    zip_name: "cisco, il",
  },
  {
    postal_code: "50682",
    zip_name: "winthrop, ia",
  },
  {
    postal_code: "10454",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "63465",
    zip_name: "revere, mo",
  },
  {
    postal_code: "51346",
    zip_name: "hartley, ia",
  },
  {
    postal_code: "76639",
    zip_name: "dawson, tx",
  },
  {
    postal_code: "29353",
    zip_name: "jonesville, sc",
  },
  {
    postal_code: "42452",
    zip_name: "robards, ky",
  },
  {
    postal_code: "52574",
    zip_name: "mystic, ia",
  },
  {
    postal_code: "5847",
    zip_name: "lowell, vt",
  },
  {
    postal_code: "99326",
    zip_name: "connell, wa",
  },
  {
    postal_code: "56641",
    zip_name: "federal dam, mn",
  },
  {
    postal_code: "38476",
    zip_name: "primm springs, tn",
  },
  {
    postal_code: "67568",
    zip_name: "plevna, ks",
  },
  {
    postal_code: "25248",
    zip_name: "kenna, wv",
  },
  {
    postal_code: "64725",
    zip_name: "archie, mo",
  },
  {
    postal_code: "5068",
    zip_name: "south royalton, vt",
  },
  {
    postal_code: "27956",
    zip_name: "maple, nc",
  },
  {
    postal_code: "72122",
    zip_name: "paron, ar",
  },
  {
    postal_code: "55722",
    zip_name: "coleraine, mn",
  },
  {
    postal_code: "48628",
    zip_name: "hope, mi",
  },
  {
    postal_code: "70668",
    zip_name: "vinton, la",
  },
  {
    postal_code: "81418",
    zip_name: "eckert, co",
  },
  {
    postal_code: "96752",
    zip_name: "kekaha, hi",
  },
  {
    postal_code: "64454",
    zip_name: "gower, mo",
  },
  {
    postal_code: "1270",
    zip_name: "windsor, ma",
  },
  {
    postal_code: "98649",
    zip_name: "toutle, wa",
  },
  {
    postal_code: "42328",
    zip_name: "centertown, ky",
  },
  {
    postal_code: "86320",
    zip_name: "ash fork, az",
  },
  {
    postal_code: "21914",
    zip_name: "charlestown, md",
  },
  {
    postal_code: "59846",
    zip_name: "huson, mt",
  },
  {
    postal_code: "43719",
    zip_name: "bethesda, oh",
  },
  {
    postal_code: "18322",
    zip_name: "brodheadsville, pa",
  },
  {
    postal_code: "12046",
    zip_name: "coeymans hollow, ny",
  },
  {
    postal_code: "97024",
    zip_name: "fairview, or",
  },
  {
    postal_code: "5404",
    zip_name: "winooski, vt",
  },
  {
    postal_code: "56437",
    zip_name: "bertha, mn",
  },
  {
    postal_code: "2210",
    zip_name: "boston, ma",
  },
  {
    postal_code: "38261",
    zip_name: "union city, tn",
  },
  {
    postal_code: "48462",
    zip_name: "ortonville, mi",
  },
  {
    postal_code: "32344",
    zip_name: "monticello, fl",
  },
  {
    postal_code: "63857",
    zip_name: "kennett, mo",
  },
  {
    postal_code: "78056",
    zip_name: "mico, tx",
  },
  {
    postal_code: "45648",
    zip_name: "lucasville, oh",
  },
  {
    postal_code: "7732",
    zip_name: "highlands, nj",
  },
  {
    postal_code: "5459",
    zip_name: "highgate center, vt",
  },
  {
    postal_code: "99337",
    zip_name: "kennewick, wa",
  },
  {
    postal_code: "29620",
    zip_name: "abbeville, sc",
  },
  {
    postal_code: "77963",
    zip_name: "goliad, tx",
  },
  {
    postal_code: "64477",
    zip_name: "plattsburg, mo",
  },
  {
    postal_code: "92655",
    zip_name: "midway city, ca",
  },
  {
    postal_code: "23922",
    zip_name: "burkeville, va",
  },
  {
    postal_code: "93518",
    zip_name: "caliente, ca",
  },
  {
    postal_code: "2379",
    zip_name: "west bridgewater, ma",
  },
  {
    postal_code: "2718",
    zip_name: "east taunton, ma",
  },
  {
    postal_code: "83672",
    zip_name: "weiser, id",
  },
  {
    postal_code: "64640",
    zip_name: "gallatin, mo",
  },
  {
    postal_code: "19129",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "30673",
    zip_name: "washington, ga",
  },
  {
    postal_code: "48759",
    zip_name: "sebewaing, mi",
  },
  {
    postal_code: "48191",
    zip_name: "willis, mi",
  },
  {
    postal_code: "25434",
    zip_name: "paw paw, wv",
  },
  {
    postal_code: "14858",
    zip_name: "lindley, ny",
  },
  {
    postal_code: "46166",
    zip_name: "paragon, in",
  },
  {
    postal_code: "13110",
    zip_name: "marietta, ny",
  },
  {
    postal_code: "49287",
    zip_name: "tipton, mi",
  },
  {
    postal_code: "17856",
    zip_name: "new columbia, pa",
  },
  {
    postal_code: "97266",
    zip_name: "portland, or",
  },
  {
    postal_code: "7081",
    zip_name: "springfield, nj",
  },
  {
    postal_code: "73439",
    zip_name: "kingston, ok",
  },
  {
    postal_code: "65740",
    zip_name: "rockaway beach, mo",
  },
  {
    postal_code: "76306",
    zip_name: "wichita falls, tx",
  },
  {
    postal_code: "14624",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "77656",
    zip_name: "silsbee, tx",
  },
  {
    postal_code: "93625",
    zip_name: "fowler, ca",
  },
  {
    postal_code: "66713",
    zip_name: "baxter springs, ks",
  },
  {
    postal_code: "83655",
    zip_name: "new plymouth, id",
  },
  {
    postal_code: "8901",
    zip_name: "new brunswick, nj",
  },
  {
    postal_code: "75846",
    zip_name: "jewett, tx",
  },
  {
    postal_code: "78580",
    zip_name: "raymondville, tx",
  },
  {
    postal_code: "24273",
    zip_name: "norton, va",
  },
  {
    postal_code: "54817",
    zip_name: "birchwood, wi",
  },
  {
    postal_code: "45710",
    zip_name: "albany, oh",
  },
  {
    postal_code: "20677",
    zip_name: "port tobacco, md",
  },
  {
    postal_code: "64831",
    zip_name: "anderson, mo",
  },
  {
    postal_code: "66618",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "5732",
    zip_name: "bomoseen, vt",
  },
  {
    postal_code: "14724",
    zip_name: "clymer, ny",
  },
  {
    postal_code: "33128",
    zip_name: "miami, fl",
  },
  {
    postal_code: "16738",
    zip_name: "lewis run, pa",
  },
  {
    postal_code: "6608",
    zip_name: "bridgeport, ct",
  },
  {
    postal_code: "45346",
    zip_name: "new madison, oh",
  },
  {
    postal_code: "27814",
    zip_name: "blounts creek, nc",
  },
  {
    postal_code: "3855",
    zip_name: "new durham, nh",
  },
  {
    postal_code: "5853",
    zip_name: "morgan, vt",
  },
  {
    postal_code: "6256",
    zip_name: "north windham, ct",
  },
  {
    postal_code: "48159",
    zip_name: "maybee, mi",
  },
  {
    postal_code: "13491",
    zip_name: "west winfield, ny",
  },
  {
    postal_code: "21028",
    zip_name: "churchville, md",
  },
  {
    postal_code: "4530",
    zip_name: "bath, me",
  },
  {
    postal_code: "94515",
    zip_name: "calistoga, ca",
  },
  {
    postal_code: "17551",
    zip_name: "millersville, pa",
  },
  {
    postal_code: "1364",
    zip_name: "orange, ma",
  },
  {
    postal_code: "59844",
    zip_name: "heron, mt",
  },
  {
    postal_code: "65769",
    zip_name: "verona, mo",
  },
  {
    postal_code: "98320",
    zip_name: "brinnon, wa",
  },
  {
    postal_code: "92233",
    zip_name: "calipatria, ca",
  },
  {
    postal_code: "22460",
    zip_name: "farnham, va",
  },
  {
    postal_code: "97060",
    zip_name: "troutdale, or",
  },
  {
    postal_code: "52211",
    zip_name: "brooklyn, ia",
  },
  {
    postal_code: "48737",
    zip_name: "glennie, mi",
  },
  {
    postal_code: "31036",
    zip_name: "hawkinsville, ga",
  },
  {
    postal_code: "58852",
    zip_name: "tioga, nd",
  },
  {
    postal_code: "71964",
    zip_name: "pearcy, ar",
  },
  {
    postal_code: "13080",
    zip_name: "jordan, ny",
  },
  {
    postal_code: "72512",
    zip_name: "horseshoe bend, ar",
  },
  {
    postal_code: "50854",
    zip_name: "mount ayr, ia",
  },
  {
    postal_code: "54665",
    zip_name: "viroqua, wi",
  },
  {
    postal_code: "49259",
    zip_name: "munith, mi",
  },
  {
    postal_code: "32346",
    zip_name: "panacea, fl",
  },
  {
    postal_code: "12586",
    zip_name: "walden, ny",
  },
  {
    postal_code: "15767",
    zip_name: "punxsutawney, pa",
  },
  {
    postal_code: "65658",
    zip_name: "golden, mo",
  },
  {
    postal_code: "8029",
    zip_name: "glendora, nj",
  },
  {
    postal_code: "53158",
    zip_name: "pleasant prairie, wi",
  },
  {
    postal_code: "60603",
    zip_name: "chicago, il",
  },
  {
    postal_code: "93650",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "38059",
    zip_name: "newbern, tn",
  },
  {
    postal_code: "8861",
    zip_name: "perth amboy, nj",
  },
  {
    postal_code: "92107",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "29805",
    zip_name: "aiken, sc",
  },
  {
    postal_code: "76443",
    zip_name: "cross plains, tx",
  },
  {
    postal_code: "27019",
    zip_name: "germanton, nc",
  },
  {
    postal_code: "95918",
    zip_name: "browns valley, ca",
  },
  {
    postal_code: "99181",
    zip_name: "valley, wa",
  },
  {
    postal_code: "16150",
    zip_name: "sharpsville, pa",
  },
  {
    postal_code: "85931",
    zip_name: "forest lakes, az",
  },
  {
    postal_code: "16406",
    zip_name: "conneautville, pa",
  },
  {
    postal_code: "1255",
    zip_name: "sandisfield, ma",
  },
  {
    postal_code: "81006",
    zip_name: "pueblo, co",
  },
  {
    postal_code: "5352",
    zip_name: "stamford, vt",
  },
  {
    postal_code: "37365",
    zip_name: "palmer, tn",
  },
  {
    postal_code: "56534",
    zip_name: "erhard, mn",
  },
  {
    postal_code: "1103",
    zip_name: "springfield, ma",
  },
  {
    postal_code: "42167",
    zip_name: "tompkinsville, ky",
  },
  {
    postal_code: "29010",
    zip_name: "bishopville, sc",
  },
  {
    postal_code: "44675",
    zip_name: "sherrodsville, oh",
  },
  {
    postal_code: "16442",
    zip_name: "wattsburg, pa",
  },
  {
    postal_code: "12164",
    zip_name: "speculator, ny",
  },
  {
    postal_code: "61238",
    zip_name: "cambridge, il",
  },
  {
    postal_code: "4426",
    zip_name: "dover foxcroft, me",
  },
  {
    postal_code: "25159",
    zip_name: "poca, wv",
  },
  {
    postal_code: "73570",
    zip_name: "tipton, ok",
  },
  {
    postal_code: "56358",
    zip_name: "ogilvie, mn",
  },
  {
    postal_code: "15541",
    zip_name: "friedens, pa",
  },
  {
    postal_code: "52654",
    zip_name: "wayland, ia",
  },
  {
    postal_code: "84783",
    zip_name: "dammeron valley, ut",
  },
  {
    postal_code: "63106",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "12022",
    zip_name: "berlin, ny",
  },
  {
    postal_code: "30630",
    zip_name: "crawford, ga",
  },
  {
    postal_code: "3240",
    zip_name: "grafton, nh",
  },
  {
    postal_code: "44826",
    zip_name: "collins, oh",
  },
  {
    postal_code: "95975",
    zip_name: "rough and ready, ca",
  },
  {
    postal_code: "68135",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "5471",
    zip_name: "montgomery center, vt",
  },
  {
    postal_code: "43013",
    zip_name: "croton, oh",
  },
  {
    postal_code: "12547",
    zip_name: "milton, ny",
  },
  {
    postal_code: "38573",
    zip_name: "monroe, tn",
  },
  {
    postal_code: "13669",
    zip_name: "ogdensburg, ny",
  },
  {
    postal_code: "27806",
    zip_name: "aurora, nc",
  },
  {
    postal_code: "10704",
    zip_name: "yonkers, ny",
  },
  {
    postal_code: "65265",
    zip_name: "mexico, mo",
  },
  {
    postal_code: "16038",
    zip_name: "harrisville, pa",
  },
  {
    postal_code: "49945",
    zip_name: "lake linden, mi",
  },
  {
    postal_code: "17517",
    zip_name: "denver, pa",
  },
  {
    postal_code: "71373",
    zip_name: "vidalia, la",
  },
  {
    postal_code: "18355",
    zip_name: "scotrun, pa",
  },
  {
    postal_code: "72529",
    zip_name: "cherokee village, ar",
  },
  {
    postal_code: "79088",
    zip_name: "tulia, tx",
  },
  {
    postal_code: "18058",
    zip_name: "kunkletown, pa",
  },
  {
    postal_code: "21794",
    zip_name: "west friendship, md",
  },
  {
    postal_code: "48453",
    zip_name: "marlette, mi",
  },
  {
    postal_code: "28458",
    zip_name: "rose hill, nc",
  },
  {
    postal_code: "96024",
    zip_name: "douglas city, ca",
  },
  {
    postal_code: "83402",
    zip_name: "idaho falls, id",
  },
  {
    postal_code: "75473",
    zip_name: "powderly, tx",
  },
  {
    postal_code: "19153",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "83110",
    zip_name: "afton, wy",
  },
  {
    postal_code: "5739",
    zip_name: "danby, vt",
  },
  {
    postal_code: "20769",
    zip_name: "glenn dale, md",
  },
  {
    postal_code: "97267",
    zip_name: "portland, or",
  },
  {
    postal_code: "77417",
    zip_name: "beasley, tx",
  },
  {
    postal_code: "57735",
    zip_name: "edgemont, sd",
  },
  {
    postal_code: "5048",
    zip_name: "hartland, vt",
  },
  {
    postal_code: "54102",
    zip_name: "amberg, wi",
  },
  {
    postal_code: "21087",
    zip_name: "kingsville, md",
  },
  {
    postal_code: "30577",
    zip_name: "toccoa, ga",
  },
  {
    postal_code: "55955",
    zip_name: "mantorville, mn",
  },
  {
    postal_code: "14487",
    zip_name: "livonia, ny",
  },
  {
    postal_code: "21783",
    zip_name: "smithsburg, md",
  },
  {
    postal_code: "61427",
    zip_name: "cuba, il",
  },
  {
    postal_code: "50635",
    zip_name: "gladbrook, ia",
  },
  {
    postal_code: "49068",
    zip_name: "marshall, mi",
  },
  {
    postal_code: "55732",
    zip_name: "embarrass, mn",
  },
  {
    postal_code: "8820",
    zip_name: "edison, nj",
  },
  {
    postal_code: "8406",
    zip_name: "ventnor city, nj",
  },
  {
    postal_code: "80126",
    zip_name: "littleton, co",
  },
  {
    postal_code: "93619",
    zip_name: "clovis, ca",
  },
  {
    postal_code: "49709",
    zip_name: "atlanta, mi",
  },
  {
    postal_code: "7067",
    zip_name: "colonia, nj",
  },
  {
    postal_code: "8361",
    zip_name: "vineland, nj",
  },
  {
    postal_code: "8824",
    zip_name: "kendall park, nj",
  },
  {
    postal_code: "65786",
    zip_name: "macks creek, mo",
  },
  {
    postal_code: "29474",
    zip_name: "round o, sc",
  },
  {
    postal_code: "68714",
    zip_name: "bassett, ne",
  },
  {
    postal_code: "16749",
    zip_name: "smethport, pa",
  },
  {
    postal_code: "95714",
    zip_name: "dutch flat, ca",
  },
  {
    postal_code: "71957",
    zip_name: "mount ida, ar",
  },
  {
    postal_code: "82240",
    zip_name: "torrington, wy",
  },
  {
    postal_code: "13324",
    zip_name: "cold brook, ny",
  },
  {
    postal_code: "4694",
    zip_name: "baileyville, me",
  },
  {
    postal_code: "71105",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "51534",
    zip_name: "glenwood, ia",
  },
  {
    postal_code: "49464",
    zip_name: "zeeland, mi",
  },
  {
    postal_code: "32404",
    zip_name: "panama city, fl",
  },
  {
    postal_code: "14895",
    zip_name: "wellsville, ny",
  },
  {
    postal_code: "78873",
    zip_name: "leakey, tx",
  },
  {
    postal_code: "54870",
    zip_name: "sarona, wi",
  },
  {
    postal_code: "1036",
    zip_name: "hampden, ma",
  },
  {
    postal_code: "61326",
    zip_name: "granville, il",
  },
  {
    postal_code: "38855",
    zip_name: "mantachie, ms",
  },
  {
    postal_code: "40419",
    zip_name: "crab orchard, ky",
  },
  {
    postal_code: "42721",
    zip_name: "caneyville, ky",
  },
  {
    postal_code: "92104",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "23434",
    zip_name: "suffolk, va",
  },
  {
    postal_code: "96002",
    zip_name: "redding, ca",
  },
  {
    postal_code: "19608",
    zip_name: "reading, pa",
  },
  {
    postal_code: "54302",
    zip_name: "green bay, wi",
  },
  {
    postal_code: "8863",
    zip_name: "fords, nj",
  },
  {
    postal_code: "47170",
    zip_name: "scottsburg, in",
  },
  {
    postal_code: "5401",
    zip_name: "burlington, vt",
  },
  {
    postal_code: "8010",
    zip_name: "beverly, nj",
  },
  {
    postal_code: "18064",
    zip_name: "nazareth, pa",
  },
  {
    postal_code: "36695",
    zip_name: "mobile, al",
  },
  {
    postal_code: "45347",
    zip_name: "new paris, oh",
  },
  {
    postal_code: "23890",
    zip_name: "waverly, va",
  },
  {
    postal_code: "7087",
    zip_name: "union city, nj",
  },
  {
    postal_code: "67661",
    zip_name: "phillipsburg, ks",
  },
  {
    postal_code: "49064",
    zip_name: "lawrence, mi",
  },
  {
    postal_code: "96815",
    zip_name: "honolulu, hi",
  },
  {
    postal_code: "8031",
    zip_name: "bellmawr, nj",
  },
  {
    postal_code: "24014",
    zip_name: "roanoke, va",
  },
  {
    postal_code: "8723",
    zip_name: "brick, nj",
  },
  {
    postal_code: "83861",
    zip_name: "saint maries, id",
  },
  {
    postal_code: "8302",
    zip_name: "bridgeton, nj",
  },
  {
    postal_code: "49220",
    zip_name: "addison, mi",
  },
  {
    postal_code: "8741",
    zip_name: "pine beach, nj",
  },
  {
    postal_code: "54847",
    zip_name: "iron river, wi",
  },
  {
    postal_code: "89301",
    zip_name: "ely, nv",
  },
  {
    postal_code: "41339",
    zip_name: "jackson, ky",
  },
  {
    postal_code: "78598",
    zip_name: "port mansfield, tx",
  },
  {
    postal_code: "81130",
    zip_name: "creede, co",
  },
  {
    postal_code: "21813",
    zip_name: "bishopville, md",
  },
  {
    postal_code: "15148",
    zip_name: "wilmerding, pa",
  },
  {
    postal_code: "64163",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "56661",
    zip_name: "northome, mn",
  },
  {
    postal_code: "1950",
    zip_name: "newburyport, ma",
  },
  {
    postal_code: "28133",
    zip_name: "peachland, nc",
  },
  {
    postal_code: "21791",
    zip_name: "union bridge, md",
  },
  {
    postal_code: "75417",
    zip_name: "bogata, tx",
  },
  {
    postal_code: "84774",
    zip_name: "toquerville, ut",
  },
  {
    postal_code: "77984",
    zip_name: "shiner, tx",
  },
  {
    postal_code: "8092",
    zip_name: "west creek, nj",
  },
  {
    postal_code: "83619",
    zip_name: "fruitland, id",
  },
  {
    postal_code: "8823",
    zip_name: "franklin park, nj",
  },
  {
    postal_code: "14809",
    zip_name: "avoca, ny",
  },
  {
    postal_code: "37134",
    zip_name: "new johnsonville, tn",
  },
  {
    postal_code: "12921",
    zip_name: "chazy, ny",
  },
  {
    postal_code: "38034",
    zip_name: "friendship, tn",
  },
  {
    postal_code: "83245",
    zip_name: "inkom, id",
  },
  {
    postal_code: "36617",
    zip_name: "mobile, al",
  },
  {
    postal_code: "13650",
    zip_name: "henderson, ny",
  },
  {
    postal_code: "36088",
    zip_name: "tuskegee institute, al",
  },
  {
    postal_code: "12193",
    zip_name: "westerlo, ny",
  },
  {
    postal_code: "16374",
    zip_name: "kennerdell, pa",
  },
  {
    postal_code: "15059",
    zip_name: "midland, pa",
  },
  {
    postal_code: "80623",
    zip_name: "gilcrest, co",
  },
  {
    postal_code: "30571",
    zip_name: "sautee nacoochee, ga",
  },
  {
    postal_code: "15697",
    zip_name: "youngwood, pa",
  },
  {
    postal_code: "28345",
    zip_name: "hamlet, nc",
  },
  {
    postal_code: "19041",
    zip_name: "haverford, pa",
  },
  {
    postal_code: "85920",
    zip_name: "alpine, az",
  },
  {
    postal_code: "19012",
    zip_name: "cheltenham, pa",
  },
  {
    postal_code: "38580",
    zip_name: "rickman, tn",
  },
  {
    postal_code: "61006",
    zip_name: "ashton, il",
  },
  {
    postal_code: "6777",
    zip_name: "new preston marble dale, ct",
  },
  {
    postal_code: "19132",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "62903",
    zip_name: "carbondale, il",
  },
  {
    postal_code: "37726",
    zip_name: "deer lodge, tn",
  },
  {
    postal_code: "17540",
    zip_name: "leola, pa",
  },
  {
    postal_code: "70663",
    zip_name: "sulphur, la",
  },
  {
    postal_code: "68048",
    zip_name: "plattsmouth, ne",
  },
  {
    postal_code: "14215",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "73025",
    zip_name: "edmond, ok",
  },
  {
    postal_code: "49614",
    zip_name: "bear lake, mi",
  },
  {
    postal_code: "97202",
    zip_name: "portland, or",
  },
  {
    postal_code: "19505",
    zip_name: "bechtelsville, pa",
  },
  {
    postal_code: "17314",
    zip_name: "delta, pa",
  },
  {
    postal_code: "49269",
    zip_name: "parma, mi",
  },
  {
    postal_code: "19032",
    zip_name: "folcroft, pa",
  },
  {
    postal_code: "6794",
    zip_name: "washington depot, ct",
  },
  {
    postal_code: "93651",
    zip_name: "prather, ca",
  },
  {
    postal_code: "92118",
    zip_name: "coronado, ca",
  },
  {
    postal_code: "29550",
    zip_name: "hartsville, sc",
  },
  {
    postal_code: "8872",
    zip_name: "sayreville, nj",
  },
  {
    postal_code: "4655",
    zip_name: "machiasport, me",
  },
  {
    postal_code: "60424",
    zip_name: "gardner, il",
  },
  {
    postal_code: "17322",
    zip_name: "felton, pa",
  },
  {
    postal_code: "12863",
    zip_name: "rock city falls, ny",
  },
  {
    postal_code: "50070",
    zip_name: "dexter, ia",
  },
  {
    postal_code: "97426",
    zip_name: "creswell, or",
  },
  {
    postal_code: "38233",
    zip_name: "kenton, tn",
  },
  {
    postal_code: "61415",
    zip_name: "avon, il",
  },
  {
    postal_code: "3861",
    zip_name: "lee, nh",
  },
  {
    postal_code: "54740",
    zip_name: "elmwood, wi",
  },
  {
    postal_code: "4005",
    zip_name: "biddeford, me",
  },
  {
    postal_code: "98947",
    zip_name: "tieton, wa",
  },
  {
    postal_code: "7074",
    zip_name: "moonachie, nj",
  },
  {
    postal_code: "4353",
    zip_name: "whitefield, me",
  },
  {
    postal_code: "53951",
    zip_name: "north freedom, wi",
  },
  {
    postal_code: "84775",
    zip_name: "torrey, ut",
  },
  {
    postal_code: "22738",
    zip_name: "rochelle, va",
  },
  {
    postal_code: "24185",
    zip_name: "woolwine, va",
  },
  {
    postal_code: "24053",
    zip_name: "ararat, va",
  },
  {
    postal_code: "4256",
    zip_name: "mechanic falls, me",
  },
  {
    postal_code: "65644",
    zip_name: "elkland, mo",
  },
  {
    postal_code: "42749",
    zip_name: "horse cave, ky",
  },
  {
    postal_code: "71115",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "14108",
    zip_name: "newfane, ny",
  },
  {
    postal_code: "97824",
    zip_name: "cove, or",
  },
  {
    postal_code: "8873",
    zip_name: "somerset, nj",
  },
  {
    postal_code: "15376",
    zip_name: "west alexander, pa",
  },
  {
    postal_code: "43432",
    zip_name: "graytown, oh",
  },
  {
    postal_code: "40152",
    zip_name: "mc daniels, ky",
  },
  {
    postal_code: "56531",
    zip_name: "elbow lake, mn",
  },
  {
    postal_code: "1535",
    zip_name: "north brookfield, ma",
  },
  {
    postal_code: "98340",
    zip_name: "hansville, wa",
  },
  {
    postal_code: "12120",
    zip_name: "medusa, ny",
  },
  {
    postal_code: "72011",
    zip_name: "bauxite, ar",
  },
  {
    postal_code: "28420",
    zip_name: "ash, nc",
  },
  {
    postal_code: "42123",
    zip_name: "austin, ky",
  },
  {
    postal_code: "52590",
    zip_name: "seymour, ia",
  },
  {
    postal_code: "4461",
    zip_name: "milford, me",
  },
  {
    postal_code: "1373",
    zip_name: "south deerfield, ma",
  },
  {
    postal_code: "44875",
    zip_name: "shelby, oh",
  },
  {
    postal_code: "22735",
    zip_name: "reva, va",
  },
  {
    postal_code: "52571",
    zip_name: "moravia, ia",
  },
  {
    postal_code: "45817",
    zip_name: "bluffton, oh",
  },
  {
    postal_code: "76648",
    zip_name: "hubbard, tx",
  },
  {
    postal_code: "4957",
    zip_name: "norridgewock, me",
  },
  {
    postal_code: "72348",
    zip_name: "hughes, ar",
  },
  {
    postal_code: "13060",
    zip_name: "elbridge, ny",
  },
  {
    postal_code: "49755",
    zip_name: "levering, mi",
  },
  {
    postal_code: "57106",
    zip_name: "sioux falls, sd",
  },
  {
    postal_code: "63380",
    zip_name: "truesdale, mo",
  },
  {
    postal_code: "23830",
    zip_name: "carson, va",
  },
  {
    postal_code: "17050",
    zip_name: "mechanicsburg, pa",
  },
  {
    postal_code: "24637",
    zip_name: "pounding mill, va",
  },
  {
    postal_code: "38581",
    zip_name: "rock island, tn",
  },
  {
    postal_code: "83833",
    zip_name: "harrison, id",
  },
  {
    postal_code: "48740",
    zip_name: "harrisville, mi",
  },
  {
    postal_code: "28101",
    zip_name: "mc adenville, nc",
  },
  {
    postal_code: "54456",
    zip_name: "neillsville, wi",
  },
  {
    postal_code: "47446",
    zip_name: "mitchell, in",
  },
  {
    postal_code: "4270",
    zip_name: "oxford, me",
  },
  {
    postal_code: "62958",
    zip_name: "makanda, il",
  },
  {
    postal_code: "35218",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "14843",
    zip_name: "hornell, ny",
  },
  {
    postal_code: "2467",
    zip_name: "chestnut hill, ma",
  },
  {
    postal_code: "6793",
    zip_name: "washington, ct",
  },
  {
    postal_code: "42784",
    zip_name: "upton, ky",
  },
  {
    postal_code: "38504",
    zip_name: "allardt, tn",
  },
  {
    postal_code: "60511",
    zip_name: "big rock, il",
  },
  {
    postal_code: "6331",
    zip_name: "canterbury, ct",
  },
  {
    postal_code: "55312",
    zip_name: "brownton, mn",
  },
  {
    postal_code: "97217",
    zip_name: "portland, or",
  },
  {
    postal_code: "12780",
    zip_name: "sparrow bush, ny",
  },
  {
    postal_code: "74730",
    zip_name: "calera, ok",
  },
  {
    postal_code: "87301",
    zip_name: "gallup, nm",
  },
  {
    postal_code: "40008",
    zip_name: "bloomfield, ky",
  },
  {
    postal_code: "22849",
    zip_name: "shenandoah, va",
  },
  {
    postal_code: "30559",
    zip_name: "mineral bluff, ga",
  },
  {
    postal_code: "53115",
    zip_name: "delavan, wi",
  },
  {
    postal_code: "92014",
    zip_name: "del mar, ca",
  },
  {
    postal_code: "49065",
    zip_name: "lawton, mi",
  },
  {
    postal_code: "42765",
    zip_name: "munfordville, ky",
  },
  {
    postal_code: "32605",
    zip_name: "gainesville, fl",
  },
  {
    postal_code: "65622",
    zip_name: "buffalo, mo",
  },
  {
    postal_code: "30346",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "68144",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "49010",
    zip_name: "allegan, mi",
  },
  {
    postal_code: "68937",
    zip_name: "elwood, ne",
  },
  {
    postal_code: "78599",
    zip_name: "weslaco, tx",
  },
  {
    postal_code: "12431",
    zip_name: "freehold, ny",
  },
  {
    postal_code: "50438",
    zip_name: "garner, ia",
  },
  {
    postal_code: "4347",
    zip_name: "hallowell, me",
  },
  {
    postal_code: "44234",
    zip_name: "hiram, oh",
  },
  {
    postal_code: "1430",
    zip_name: "ashburnham, ma",
  },
  {
    postal_code: "54538",
    zip_name: "lac du flambeau, wi",
  },
  {
    postal_code: "94706",
    zip_name: "albany, ca",
  },
  {
    postal_code: "26143",
    zip_name: "elizabeth, wv",
  },
  {
    postal_code: "38230",
    zip_name: "greenfield, tn",
  },
  {
    postal_code: "43149",
    zip_name: "rockbridge, oh",
  },
  {
    postal_code: "61368",
    zip_name: "tiskilwa, il",
  },
  {
    postal_code: "65349",
    zip_name: "slater, mo",
  },
  {
    postal_code: "72959",
    zip_name: "winslow, ar",
  },
  {
    postal_code: "84542",
    zip_name: "wellington, ut",
  },
  {
    postal_code: "45814",
    zip_name: "arlington, oh",
  },
  {
    postal_code: "82426",
    zip_name: "greybull, wy",
  },
  {
    postal_code: "4848",
    zip_name: "islesboro, me",
  },
  {
    postal_code: "75778",
    zip_name: "murchison, tx",
  },
  {
    postal_code: "3840",
    zip_name: "greenland, nh",
  },
  {
    postal_code: "89413",
    zip_name: "glenbrook, nv",
  },
  {
    postal_code: "38551",
    zip_name: "celina, tn",
  },
  {
    postal_code: "11024",
    zip_name: "great neck, ny",
  },
  {
    postal_code: "8015",
    zip_name: "browns mills, nj",
  },
  {
    postal_code: "59479",
    zip_name: "stanford, mt",
  },
  {
    postal_code: "60150",
    zip_name: "malta, il",
  },
  {
    postal_code: "43332",
    zip_name: "la rue, oh",
  },
  {
    postal_code: "18826",
    zip_name: "kingsley, pa",
  },
  {
    postal_code: "14838",
    zip_name: "erin, ny",
  },
  {
    postal_code: "95010",
    zip_name: "capitola, ca",
  },
  {
    postal_code: "37356",
    zip_name: "monteagle, tn",
  },
  {
    postal_code: "4039",
    zip_name: "gray, me",
  },
  {
    postal_code: "32202",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "45341",
    zip_name: "medway, oh",
  },
  {
    postal_code: "35131",
    zip_name: "ragland, al",
  },
  {
    postal_code: "35540",
    zip_name: "addison, al",
  },
  {
    postal_code: "44010",
    zip_name: "austinburg, oh",
  },
  {
    postal_code: "60530",
    zip_name: "lee, il",
  },
  {
    postal_code: "38629",
    zip_name: "falkner, ms",
  },
  {
    postal_code: "4239",
    zip_name: "jay, me",
  },
  {
    postal_code: "16877",
    zip_name: "warriors mark, pa",
  },
  {
    postal_code: "52773",
    zip_name: "walcott, ia",
  },
  {
    postal_code: "80644",
    zip_name: "kersey, co",
  },
  {
    postal_code: "27207",
    zip_name: "bear creek, nc",
  },
  {
    postal_code: "46165",
    zip_name: "north salem, in",
  },
  {
    postal_code: "16115",
    zip_name: "darlington, pa",
  },
  {
    postal_code: "96776",
    zip_name: "paauilo, hi",
  },
  {
    postal_code: "24348",
    zip_name: "independence, va",
  },
  {
    postal_code: "79508",
    zip_name: "buffalo gap, tx",
  },
  {
    postal_code: "15618",
    zip_name: "avonmore, pa",
  },
  {
    postal_code: "55787",
    zip_name: "tamarack, mn",
  },
  {
    postal_code: "46051",
    zip_name: "lapel, in",
  },
  {
    postal_code: "23881",
    zip_name: "spring grove, va",
  },
  {
    postal_code: "56229",
    zip_name: "cottonwood, mn",
  },
  {
    postal_code: "44836",
    zip_name: "green springs, oh",
  },
  {
    postal_code: "48634",
    zip_name: "linwood, mi",
  },
  {
    postal_code: "54979",
    zip_name: "van dyne, wi",
  },
  {
    postal_code: "18446",
    zip_name: "nicholson, pa",
  },
  {
    postal_code: "54556",
    zip_name: "prentice, wi",
  },
  {
    postal_code: "45053",
    zip_name: "okeana, oh",
  },
  {
    postal_code: "22514",
    zip_name: "milford, va",
  },
  {
    postal_code: "12196",
    zip_name: "west sand lake, ny",
  },
  {
    postal_code: "58433",
    zip_name: "edgeley, nd",
  },
  {
    postal_code: "44436",
    zip_name: "lowellville, oh",
  },
  {
    postal_code: "98857",
    zip_name: "warden, wa",
  },
  {
    postal_code: "1432",
    zip_name: "ayer, ma",
  },
  {
    postal_code: "16838",
    zip_name: "grampian, pa",
  },
  {
    postal_code: "29055",
    zip_name: "great falls, sc",
  },
  {
    postal_code: "16435",
    zip_name: "springboro, pa",
  },
  {
    postal_code: "42274",
    zip_name: "rockfield, ky",
  },
  {
    postal_code: "60924",
    zip_name: "cissna park, il",
  },
  {
    postal_code: "84624",
    zip_name: "delta, ut",
  },
  {
    postal_code: "23011",
    zip_name: "barhamsville, va",
  },
  {
    postal_code: "18076",
    zip_name: "red hill, pa",
  },
  {
    postal_code: "62022",
    zip_name: "dow, il",
  },
  {
    postal_code: "2898",
    zip_name: "wyoming, ri",
  },
  {
    postal_code: "5491",
    zip_name: "vergennes, vt",
  },
  {
    postal_code: "5355",
    zip_name: "wardsboro, vt",
  },
  {
    postal_code: "17240",
    zip_name: "newburg, pa",
  },
  {
    postal_code: "61877",
    zip_name: "sidney, il",
  },
  {
    postal_code: "1366",
    zip_name: "petersham, ma",
  },
  {
    postal_code: "48509",
    zip_name: "burton, mi",
  },
  {
    postal_code: "48745",
    zip_name: "mikado, mi",
  },
  {
    postal_code: "17847",
    zip_name: "milton, pa",
  },
  {
    postal_code: "4864",
    zip_name: "warren, me",
  },
  {
    postal_code: "87520",
    zip_name: "chama, nm",
  },
  {
    postal_code: "11363",
    zip_name: "little neck, ny",
  },
  {
    postal_code: "11972",
    zip_name: "speonk, ny",
  },
  {
    postal_code: "44086",
    zip_name: "thompson, oh",
  },
  {
    postal_code: "63107",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "49283",
    zip_name: "spring arbor, mi",
  },
  {
    postal_code: "56721",
    zip_name: "east grand forks, mn",
  },
  {
    postal_code: "49765",
    zip_name: "onaway, mi",
  },
  {
    postal_code: "46070",
    zip_name: "summitville, in",
  },
  {
    postal_code: "47102",
    zip_name: "austin, in",
  },
  {
    postal_code: "82082",
    zip_name: "pine bluffs, wy",
  },
  {
    postal_code: "37779",
    zip_name: "luttrell, tn",
  },
  {
    postal_code: "50002",
    zip_name: "adair, ia",
  },
  {
    postal_code: "1841",
    zip_name: "lawrence, ma",
  },
  {
    postal_code: "84326",
    zip_name: "millville, ut",
  },
  {
    postal_code: "21161",
    zip_name: "white hall, md",
  },
  {
    postal_code: "56278",
    zip_name: "ortonville, mn",
  },
  {
    postal_code: "77032",
    zip_name: "houston, tx",
  },
  {
    postal_code: "56554",
    zip_name: "lake park, mn",
  },
  {
    postal_code: "40440",
    zip_name: "junction city, ky",
  },
  {
    postal_code: "23430",
    zip_name: "smithfield, va",
  },
  {
    postal_code: "39773",
    zip_name: "west point, ms",
  },
  {
    postal_code: "11964",
    zip_name: "shelter island, ny",
  },
  {
    postal_code: "54601",
    zip_name: "la crosse, wi",
  },
  {
    postal_code: "10452",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "4051",
    zip_name: "lovell, me",
  },
  {
    postal_code: "43917",
    zip_name: "dillonvale, oh",
  },
  {
    postal_code: "13302",
    zip_name: "altmar, ny",
  },
  {
    postal_code: "66617",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "92111",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "70607",
    zip_name: "lake charles, la",
  },
  {
    postal_code: "8059",
    zip_name: "mount ephraim, nj",
  },
  {
    postal_code: "32461",
    zip_name: "inlet beach, fl",
  },
  {
    postal_code: "63023",
    zip_name: "dittmer, mo",
  },
  {
    postal_code: "1069",
    zip_name: "palmer, ma",
  },
  {
    postal_code: "5859",
    zip_name: "north troy, vt",
  },
  {
    postal_code: "60184",
    zip_name: "wayne, il",
  },
  {
    postal_code: "91950",
    zip_name: "national city, ca",
  },
  {
    postal_code: "80461",
    zip_name: "leadville, co",
  },
  {
    postal_code: "46953",
    zip_name: "marion, in",
  },
  {
    postal_code: "61362",
    zip_name: "spring valley, il",
  },
  {
    postal_code: "8902",
    zip_name: "north brunswick, nj",
  },
  {
    postal_code: "53128",
    zip_name: "genoa city, wi",
  },
  {
    postal_code: "92113",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "61542",
    zip_name: "lewistown, il",
  },
  {
    postal_code: "3856",
    zip_name: "newfields, nh",
  },
  {
    postal_code: "13329",
    zip_name: "dolgeville, ny",
  },
  {
    postal_code: "17070",
    zip_name: "new cumberland, pa",
  },
  {
    postal_code: "49911",
    zip_name: "bessemer, mi",
  },
  {
    postal_code: "35907",
    zip_name: "gadsden, al",
  },
  {
    postal_code: "85344",
    zip_name: "parker, az",
  },
  {
    postal_code: "18470",
    zip_name: "union dale, pa",
  },
  {
    postal_code: "46574",
    zip_name: "walkerton, in",
  },
  {
    postal_code: "51537",
    zip_name: "harlan, ia",
  },
  {
    postal_code: "18972",
    zip_name: "upper black eddy, pa",
  },
  {
    postal_code: "92110",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "95540",
    zip_name: "fortuna, ca",
  },
  {
    postal_code: "36080",
    zip_name: "titus, al",
  },
  {
    postal_code: "49408",
    zip_name: "fennville, mi",
  },
  {
    postal_code: "70546",
    zip_name: "jennings, la",
  },
  {
    postal_code: "97838",
    zip_name: "hermiston, or",
  },
  {
    postal_code: "28676",
    zip_name: "state road, nc",
  },
  {
    postal_code: "2121",
    zip_name: "dorchester, ma",
  },
  {
    postal_code: "61920",
    zip_name: "charleston, il",
  },
  {
    postal_code: "99157",
    zip_name: "northport, wa",
  },
  {
    postal_code: "74953",
    zip_name: "poteau, ok",
  },
  {
    postal_code: "16239",
    zip_name: "marienville, pa",
  },
  {
    postal_code: "4917",
    zip_name: "belgrade, me",
  },
  {
    postal_code: "8063",
    zip_name: "national park, nj",
  },
  {
    postal_code: "80433",
    zip_name: "conifer, co",
  },
  {
    postal_code: "48239",
    zip_name: "redford, mi",
  },
  {
    postal_code: "31904",
    zip_name: "columbus, ga",
  },
  {
    postal_code: "92029",
    zip_name: "escondido, ca",
  },
  {
    postal_code: "55735",
    zip_name: "finlayson, mn",
  },
  {
    postal_code: "2143",
    zip_name: "somerville, ma",
  },
  {
    postal_code: "77363",
    zip_name: "plantersville, tx",
  },
  {
    postal_code: "18706",
    zip_name: "wilkes barre, pa",
  },
  {
    postal_code: "3872",
    zip_name: "sanbornville, nh",
  },
  {
    postal_code: "1740",
    zip_name: "bolton, ma",
  },
  {
    postal_code: "38229",
    zip_name: "gleason, tn",
  },
  {
    postal_code: "70652",
    zip_name: "longville, la",
  },
  {
    postal_code: "19506",
    zip_name: "bernville, pa",
  },
  {
    postal_code: "61602",
    zip_name: "peoria, il",
  },
  {
    postal_code: "17233",
    zip_name: "mc connellsburg, pa",
  },
  {
    postal_code: "59825",
    zip_name: "clinton, mt",
  },
  {
    postal_code: "10705",
    zip_name: "yonkers, ny",
  },
  {
    postal_code: "49870",
    zip_name: "norway, mi",
  },
  {
    postal_code: "71078",
    zip_name: "stonewall, la",
  },
  {
    postal_code: "48703",
    zip_name: "au gres, mi",
  },
  {
    postal_code: "75603",
    zip_name: "longview, tx",
  },
  {
    postal_code: "97463",
    zip_name: "oakridge, or",
  },
  {
    postal_code: "91759",
    zip_name: "mt baldy, ca",
  },
  {
    postal_code: "61353",
    zip_name: "paw paw, il",
  },
  {
    postal_code: "43447",
    zip_name: "millbury, oh",
  },
  {
    postal_code: "61882",
    zip_name: "weldon, il",
  },
  {
    postal_code: "35580",
    zip_name: "parrish, al",
  },
  {
    postal_code: "28709",
    zip_name: "barnardsville, nc",
  },
  {
    postal_code: "53807",
    zip_name: "cuba city, wi",
  },
  {
    postal_code: "54112",
    zip_name: "coleman, wi",
  },
  {
    postal_code: "56524",
    zip_name: "clitherall, mn",
  },
  {
    postal_code: "4626",
    zip_name: "cutler, me",
  },
  {
    postal_code: "23038",
    zip_name: "columbia, va",
  },
  {
    postal_code: "1254",
    zip_name: "richmond, ma",
  },
  {
    postal_code: "8215",
    zip_name: "egg harbor city, nj",
  },
  {
    postal_code: "85646",
    zip_name: "tubac, az",
  },
  {
    postal_code: "65787",
    zip_name: "roach, mo",
  },
  {
    postal_code: "24090",
    zip_name: "fincastle, va",
  },
  {
    postal_code: "40312",
    zip_name: "clay city, ky",
  },
  {
    postal_code: "15853",
    zip_name: "ridgway, pa",
  },
  {
    postal_code: "3777",
    zip_name: "orford, nh",
  },
  {
    postal_code: "58257",
    zip_name: "mayville, nd",
  },
  {
    postal_code: "93531",
    zip_name: "keene, ca",
  },
  {
    postal_code: "53529",
    zip_name: "dane, wi",
  },
  {
    postal_code: "49089",
    zip_name: "sherwood, mi",
  },
  {
    postal_code: "52645",
    zip_name: "new london, ia",
  },
  {
    postal_code: "40489",
    zip_name: "waynesburg, ky",
  },
  {
    postal_code: "25286",
    zip_name: "walton, wv",
  },
  {
    postal_code: "5656",
    zip_name: "johnson, vt",
  },
  {
    postal_code: "59457",
    zip_name: "lewistown, mt",
  },
  {
    postal_code: "60962",
    zip_name: "roberts, il",
  },
  {
    postal_code: "38460",
    zip_name: "goodspring, tn",
  },
  {
    postal_code: "97011",
    zip_name: "brightwood, or",
  },
  {
    postal_code: "78358",
    zip_name: "fulton, tx",
  },
  {
    postal_code: "42729",
    zip_name: "cub run, ky",
  },
  {
    postal_code: "80454",
    zip_name: "indian hills, co",
  },
  {
    postal_code: "60444",
    zip_name: "mazon, il",
  },
  {
    postal_code: "92398",
    zip_name: "yermo, ca",
  },
  {
    postal_code: "98593",
    zip_name: "vader, wa",
  },
  {
    postal_code: "15530",
    zip_name: "berlin, pa",
  },
  {
    postal_code: "16133",
    zip_name: "jackson center, pa",
  },
  {
    postal_code: "68405",
    zip_name: "milford, ne",
  },
  {
    postal_code: "76433",
    zip_name: "bluff dale, tx",
  },
  {
    postal_code: "38362",
    zip_name: "oakfield, tn",
  },
  {
    postal_code: "45314",
    zip_name: "cedarville, oh",
  },
  {
    postal_code: "27871",
    zip_name: "robersonville, nc",
  },
  {
    postal_code: "54754",
    zip_name: "merrillan, wi",
  },
  {
    postal_code: "56248",
    zip_name: "herman, mn",
  },
  {
    postal_code: "1431",
    zip_name: "ashby, ma",
  },
  {
    postal_code: "13804",
    zip_name: "masonville, ny",
  },
  {
    postal_code: "54026",
    zip_name: "star prairie, wi",
  },
  {
    postal_code: "16141",
    zip_name: "new galilee, pa",
  },
  {
    postal_code: "54945",
    zip_name: "iola, wi",
  },
  {
    postal_code: "15055",
    zip_name: "lawrence, pa",
  },
  {
    postal_code: "22922",
    zip_name: "arrington, va",
  },
  {
    postal_code: "79543",
    zip_name: "roby, tx",
  },
  {
    postal_code: "62087",
    zip_name: "south roxana, il",
  },
  {
    postal_code: "15679",
    zip_name: "ruffs dale, pa",
  },
  {
    postal_code: "40022",
    zip_name: "finchville, ky",
  },
  {
    postal_code: "17244",
    zip_name: "orrstown, pa",
  },
  {
    postal_code: "5257",
    zip_name: "north bennington, vt",
  },
  {
    postal_code: "53726",
    zip_name: "madison, wi",
  },
  {
    postal_code: "38862",
    zip_name: "plantersville, ms",
  },
  {
    postal_code: "6232",
    zip_name: "andover, ct",
  },
  {
    postal_code: "69165",
    zip_name: "sutherland, ne",
  },
  {
    postal_code: "44217",
    zip_name: "creston, oh",
  },
  {
    postal_code: "15670",
    zip_name: "new alexandria, pa",
  },
  {
    postal_code: "62058",
    zip_name: "livingston, il",
  },
  {
    postal_code: "21077",
    zip_name: "harmans, md",
  },
  {
    postal_code: "56477",
    zip_name: "sebeka, mn",
  },
  {
    postal_code: "65438",
    zip_name: "birch tree, mo",
  },
  {
    postal_code: "61737",
    zip_name: "ellsworth, il",
  },
  {
    postal_code: "78944",
    zip_name: "industry, tx",
  },
  {
    postal_code: "95612",
    zip_name: "clarksburg, ca",
  },
  {
    postal_code: "51455",
    zip_name: "manning, ia",
  },
  {
    postal_code: "61242",
    zip_name: "cordova, il",
  },
  {
    postal_code: "61361",
    zip_name: "sheffield, il",
  },
  {
    postal_code: "43525",
    zip_name: "haskins, oh",
  },
  {
    postal_code: "44276",
    zip_name: "sterling, oh",
  },
  {
    postal_code: "43527",
    zip_name: "holgate, oh",
  },
  {
    postal_code: "47386",
    zip_name: "springport, in",
  },
  {
    postal_code: "55802",
    zip_name: "duluth, mn",
  },
  {
    postal_code: "67120",
    zip_name: "peck, ks",
  },
  {
    postal_code: "79104",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "18920",
    zip_name: "erwinna, pa",
  },
  {
    postal_code: "19076",
    zip_name: "prospect park, pa",
  },
  {
    postal_code: "43554",
    zip_name: "pioneer, oh",
  },
  {
    postal_code: "28582",
    zip_name: "stella, nc",
  },
  {
    postal_code: "31097",
    zip_name: "yatesville, ga",
  },
  {
    postal_code: "56368",
    zip_name: "richmond, mn",
  },
  {
    postal_code: "18452",
    zip_name: "peckville, pa",
  },
  {
    postal_code: "43821",
    zip_name: "dresden, oh",
  },
  {
    postal_code: "53550",
    zip_name: "juda, wi",
  },
  {
    postal_code: "47841",
    zip_name: "clay city, in",
  },
  {
    postal_code: "23420",
    zip_name: "painter, va",
  },
  {
    postal_code: "98339",
    zip_name: "port hadlock, wa",
  },
  {
    postal_code: "48888",
    zip_name: "stanton, mi",
  },
  {
    postal_code: "65770",
    zip_name: "walnut grove, mo",
  },
  {
    postal_code: "80202",
    zip_name: "denver, co",
  },
  {
    postal_code: "11947",
    zip_name: "jamesport, ny",
  },
  {
    postal_code: "1519",
    zip_name: "grafton, ma",
  },
  {
    postal_code: "14564",
    zip_name: "victor, ny",
  },
  {
    postal_code: "43730",
    zip_name: "corning, oh",
  },
  {
    postal_code: "8006",
    zip_name: "barnegat light, nj",
  },
  {
    postal_code: "84757",
    zip_name: "new harmony, ut",
  },
  {
    postal_code: "14772",
    zip_name: "randolph, ny",
  },
  {
    postal_code: "23974",
    zip_name: "victoria, va",
  },
  {
    postal_code: "13052",
    zip_name: "de ruyter, ny",
  },
  {
    postal_code: "64720",
    zip_name: "adrian, mo",
  },
  {
    postal_code: "46327",
    zip_name: "hammond, in",
  },
  {
    postal_code: "85621",
    zip_name: "nogales, az",
  },
  {
    postal_code: "97141",
    zip_name: "tillamook, or",
  },
  {
    postal_code: "53105",
    zip_name: "burlington, wi",
  },
  {
    postal_code: "39601",
    zip_name: "brookhaven, ms",
  },
  {
    postal_code: "8859",
    zip_name: "parlin, nj",
  },
  {
    postal_code: "78263",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "34797",
    zip_name: "yalaha, fl",
  },
  {
    postal_code: "34215",
    zip_name: "cortez, fl",
  },
  {
    postal_code: "12549",
    zip_name: "montgomery, ny",
  },
  {
    postal_code: "8525",
    zip_name: "hopewell, nj",
  },
  {
    postal_code: "8067",
    zip_name: "pedricktown, nj",
  },
  {
    postal_code: "5822",
    zip_name: "barton, vt",
  },
  {
    postal_code: "71104",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "49228",
    zip_name: "blissfield, mi",
  },
  {
    postal_code: "17036",
    zip_name: "hummelstown, pa",
  },
  {
    postal_code: "71417",
    zip_name: "colfax, la",
  },
  {
    postal_code: "23109",
    zip_name: "mathews, va",
  },
  {
    postal_code: "82520",
    zip_name: "lander, wy",
  },
  {
    postal_code: "46186",
    zip_name: "wilkinson, in",
  },
  {
    postal_code: "84719",
    zip_name: "brian head, ut",
  },
  {
    postal_code: "48461",
    zip_name: "north branch, mi",
  },
  {
    postal_code: "40202",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "42129",
    zip_name: "edmonton, ky",
  },
  {
    postal_code: "8037",
    zip_name: "hammonton, nj",
  },
  {
    postal_code: "37391",
    zip_name: "turtletown, tn",
  },
  {
    postal_code: "38683",
    zip_name: "walnut, ms",
  },
  {
    postal_code: "1451",
    zip_name: "harvard, ma",
  },
  {
    postal_code: "2747",
    zip_name: "north dartmouth, ma",
  },
  {
    postal_code: "99336",
    zip_name: "kennewick, wa",
  },
  {
    postal_code: "18235",
    zip_name: "lehighton, pa",
  },
  {
    postal_code: "76302",
    zip_name: "wichita falls, tx",
  },
  {
    postal_code: "29536",
    zip_name: "dillon, sc",
  },
  {
    postal_code: "7974",
    zip_name: "new providence, nj",
  },
  {
    postal_code: "39367",
    zip_name: "waynesboro, ms",
  },
  {
    postal_code: "45769",
    zip_name: "pomeroy, oh",
  },
  {
    postal_code: "42023",
    zip_name: "bardwell, ky",
  },
  {
    postal_code: "6377",
    zip_name: "sterling, ct",
  },
  {
    postal_code: "66006",
    zip_name: "baldwin city, ks",
  },
  {
    postal_code: "14710",
    zip_name: "ashville, ny",
  },
  {
    postal_code: "2111",
    zip_name: "boston, ma",
  },
  {
    postal_code: "54902",
    zip_name: "oshkosh, wi",
  },
  {
    postal_code: "24554",
    zip_name: "gladys, va",
  },
  {
    postal_code: "50140",
    zip_name: "lamoni, ia",
  },
  {
    postal_code: "70601",
    zip_name: "lake charles, la",
  },
  {
    postal_code: "78504",
    zip_name: "mcallen, tx",
  },
  {
    postal_code: "6069",
    zip_name: "sharon, ct",
  },
  {
    postal_code: "64127",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "8701",
    zip_name: "lakewood, nj",
  },
  {
    postal_code: "51360",
    zip_name: "spirit lake, ia",
  },
  {
    postal_code: "83814",
    zip_name: "coeur d alene, id",
  },
  {
    postal_code: "14718",
    zip_name: "cassadaga, ny",
  },
  {
    postal_code: "47471",
    zip_name: "worthington, in",
  },
  {
    postal_code: "48324",
    zip_name: "west bloomfield, mi",
  },
  {
    postal_code: "52031",
    zip_name: "bellevue, ia",
  },
  {
    postal_code: "73507",
    zip_name: "lawton, ok",
  },
  {
    postal_code: "48386",
    zip_name: "white lake, mi",
  },
  {
    postal_code: "54476",
    zip_name: "schofield, wi",
  },
  {
    postal_code: "8089",
    zip_name: "waterford works, nj",
  },
  {
    postal_code: "49730",
    zip_name: "elmira, mi",
  },
  {
    postal_code: "81154",
    zip_name: "south fork, co",
  },
  {
    postal_code: "17067",
    zip_name: "myerstown, pa",
  },
  {
    postal_code: "18055",
    zip_name: "hellertown, pa",
  },
  {
    postal_code: "32618",
    zip_name: "archer, fl",
  },
  {
    postal_code: "31206",
    zip_name: "macon, ga",
  },
  {
    postal_code: "19946",
    zip_name: "frederica, de",
  },
  {
    postal_code: "35504",
    zip_name: "jasper, al",
  },
  {
    postal_code: "23664",
    zip_name: "hampton, va",
  },
  {
    postal_code: "97730",
    zip_name: "camp sherman, or",
  },
  {
    postal_code: "75559",
    zip_name: "de kalb, tx",
  },
  {
    postal_code: "56639",
    zip_name: "effie, mn",
  },
  {
    postal_code: "2048",
    zip_name: "mansfield, ma",
  },
  {
    postal_code: "2464",
    zip_name: "newton upper falls, ma",
  },
  {
    postal_code: "97376",
    zip_name: "seal rock, or",
  },
  {
    postal_code: "27966",
    zip_name: "powells point, nc",
  },
  {
    postal_code: "68661",
    zip_name: "schuyler, ne",
  },
  {
    postal_code: "3824",
    zip_name: "durham, nh",
  },
  {
    postal_code: "62643",
    zip_name: "hartsburg, il",
  },
  {
    postal_code: "3465",
    zip_name: "troy, nh",
  },
  {
    postal_code: "22534",
    zip_name: "partlow, va",
  },
  {
    postal_code: "48441",
    zip_name: "harbor beach, mi",
  },
  {
    postal_code: "13839",
    zip_name: "sidney center, ny",
  },
  {
    postal_code: "4988",
    zip_name: "unity, me",
  },
  {
    postal_code: "56686",
    zip_name: "williams, mn",
  },
  {
    postal_code: "29335",
    zip_name: "enoree, sc",
  },
  {
    postal_code: "14781",
    zip_name: "sherman, ny",
  },
  {
    postal_code: "44624",
    zip_name: "dundee, oh",
  },
  {
    postal_code: "4660",
    zip_name: "mount desert, me",
  },
  {
    postal_code: "76651",
    zip_name: "italy, tx",
  },
  {
    postal_code: "49406",
    zip_name: "douglas, mi",
  },
  {
    postal_code: "16145",
    zip_name: "sandy lake, pa",
  },
  {
    postal_code: "13862",
    zip_name: "whitney point, ny",
  },
  {
    postal_code: "28383",
    zip_name: "rowland, nc",
  },
  {
    postal_code: "20684",
    zip_name: "saint inigoes, md",
  },
  {
    postal_code: "63730",
    zip_name: "advance, mo",
  },
  {
    postal_code: "4463",
    zip_name: "milo, me",
  },
  {
    postal_code: "38547",
    zip_name: "brush creek, tn",
  },
  {
    postal_code: "93440",
    zip_name: "los alamos, ca",
  },
  {
    postal_code: "59740",
    zip_name: "mc allister, mt",
  },
  {
    postal_code: "97015",
    zip_name: "clackamas, or",
  },
  {
    postal_code: "3771",
    zip_name: "monroe, nh",
  },
  {
    postal_code: "19311",
    zip_name: "avondale, pa",
  },
  {
    postal_code: "97131",
    zip_name: "nehalem, or",
  },
  {
    postal_code: "55367",
    zip_name: "new germany, mn",
  },
  {
    postal_code: "60034",
    zip_name: "hebron, il",
  },
  {
    postal_code: "83338",
    zip_name: "jerome, id",
  },
  {
    postal_code: "32134",
    zip_name: "fort mc coy, fl",
  },
  {
    postal_code: "4630",
    zip_name: "east machias, me",
  },
  {
    postal_code: "7036",
    zip_name: "linden, nj",
  },
  {
    postal_code: "49503",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "44905",
    zip_name: "mansfield, oh",
  },
  {
    postal_code: "29512",
    zip_name: "bennettsville, sc",
  },
  {
    postal_code: "5152",
    zip_name: "peru, vt",
  },
  {
    postal_code: "18067",
    zip_name: "northampton, pa",
  },
  {
    postal_code: "47031",
    zip_name: "milan, in",
  },
  {
    postal_code: "94923",
    zip_name: "bodega bay, ca",
  },
  {
    postal_code: "71112",
    zip_name: "bossier city, la",
  },
  {
    postal_code: "3906",
    zip_name: "north berwick, me",
  },
  {
    postal_code: "62075",
    zip_name: "nokomis, il",
  },
  {
    postal_code: "97219",
    zip_name: "portland, or",
  },
  {
    postal_code: "19540",
    zip_name: "mohnton, pa",
  },
  {
    postal_code: "14456",
    zip_name: "geneva, ny",
  },
  {
    postal_code: "48611",
    zip_name: "auburn, mi",
  },
  {
    postal_code: "4654",
    zip_name: "machias, me",
  },
  {
    postal_code: "81524",
    zip_name: "loma, co",
  },
  {
    postal_code: "49645",
    zip_name: "kaleva, mi",
  },
  {
    postal_code: "2139",
    zip_name: "cambridge, ma",
  },
  {
    postal_code: "71342",
    zip_name: "jena, la",
  },
  {
    postal_code: "46723",
    zip_name: "churubusco, in",
  },
  {
    postal_code: "16154",
    zip_name: "transfer, pa",
  },
  {
    postal_code: "5462",
    zip_name: "huntington, vt",
  },
  {
    postal_code: "44814",
    zip_name: "berlin heights, oh",
  },
  {
    postal_code: "6235",
    zip_name: "chaplin, ct",
  },
  {
    postal_code: "1778",
    zip_name: "wayland, ma",
  },
  {
    postal_code: "76527",
    zip_name: "florence, tx",
  },
  {
    postal_code: "72016",
    zip_name: "bigelow, ar",
  },
  {
    postal_code: "3588",
    zip_name: "milan, nh",
  },
  {
    postal_code: "72013",
    zip_name: "bee branch, ar",
  },
  {
    postal_code: "45694",
    zip_name: "wheelersburg, oh",
  },
  {
    postal_code: "88352",
    zip_name: "tularosa, nm",
  },
  {
    postal_code: "55769",
    zip_name: "nashwauk, mn",
  },
  {
    postal_code: "99166",
    zip_name: "republic, wa",
  },
  {
    postal_code: "37755",
    zip_name: "helenwood, tn",
  },
  {
    postal_code: "75941",
    zip_name: "diboll, tx",
  },
  {
    postal_code: "80223",
    zip_name: "denver, co",
  },
  {
    postal_code: "78659",
    zip_name: "paige, tx",
  },
  {
    postal_code: "50849",
    zip_name: "greenfield, ia",
  },
  {
    postal_code: "77013",
    zip_name: "houston, tx",
  },
  {
    postal_code: "37350",
    zip_name: "lookout mountain, tn",
  },
  {
    postal_code: "51020",
    zip_name: "galva, ia",
  },
  {
    postal_code: "55768",
    zip_name: "mountain iron, mn",
  },
  {
    postal_code: "50055",
    zip_name: "collins, ia",
  },
  {
    postal_code: "3241",
    zip_name: "hebron, nh",
  },
  {
    postal_code: "20609",
    zip_name: "avenue, md",
  },
  {
    postal_code: "98232",
    zip_name: "bow, wa",
  },
  {
    postal_code: "53952",
    zip_name: "oxford, wi",
  },
  {
    postal_code: "48444",
    zip_name: "imlay city, mi",
  },
  {
    postal_code: "18612",
    zip_name: "dallas, pa",
  },
  {
    postal_code: "72641",
    zip_name: "jasper, ar",
  },
  {
    postal_code: "14626",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "3592",
    zip_name: "pittsburg, nh",
  },
  {
    postal_code: "52040",
    zip_name: "dyersville, ia",
  },
  {
    postal_code: "92124",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "46371",
    zip_name: "rolling prairie, in",
  },
  {
    postal_code: "62526",
    zip_name: "decatur, il",
  },
  {
    postal_code: "4496",
    zip_name: "winterport, me",
  },
  {
    postal_code: "93710",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "17057",
    zip_name: "middletown, pa",
  },
  {
    postal_code: "8069",
    zip_name: "penns grove, nj",
  },
  {
    postal_code: "19079",
    zip_name: "sharon hill, pa",
  },
  {
    postal_code: "47403",
    zip_name: "bloomington, in",
  },
  {
    postal_code: "43357",
    zip_name: "west liberty, oh",
  },
  {
    postal_code: "28382",
    zip_name: "roseboro, nc",
  },
  {
    postal_code: "4953",
    zip_name: "newport, me",
  },
  {
    postal_code: "19023",
    zip_name: "darby, pa",
  },
  {
    postal_code: "2150",
    zip_name: "chelsea, ma",
  },
  {
    postal_code: "14072",
    zip_name: "grand island, ny",
  },
  {
    postal_code: "22720",
    zip_name: "goldvein, va",
  },
  {
    postal_code: "15634",
    zip_name: "grapeville, pa",
  },
  {
    postal_code: "67954",
    zip_name: "rolla, ks",
  },
  {
    postal_code: "30336",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "68967",
    zip_name: "oxford, ne",
  },
  {
    postal_code: "13782",
    zip_name: "hamden, ny",
  },
  {
    postal_code: "99179",
    zip_name: "uniontown, wa",
  },
  {
    postal_code: "95428",
    zip_name: "covelo, ca",
  },
  {
    postal_code: "81326",
    zip_name: "hesperus, co",
  },
  {
    postal_code: "25555",
    zip_name: "prichard, wv",
  },
  {
    postal_code: "61239",
    zip_name: "carbon cliff, il",
  },
  {
    postal_code: "99030",
    zip_name: "rockford, wa",
  },
  {
    postal_code: "87556",
    zip_name: "questa, nm",
  },
  {
    postal_code: "15724",
    zip_name: "cherry tree, pa",
  },
  {
    postal_code: "59748",
    zip_name: "ramsay, mt",
  },
  {
    postal_code: "75237",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "55324",
    zip_name: "darwin, mn",
  },
  {
    postal_code: "43450",
    zip_name: "pemberville, oh",
  },
  {
    postal_code: "98570",
    zip_name: "onalaska, wa",
  },
  {
    postal_code: "52214",
    zip_name: "central city, ia",
  },
  {
    postal_code: "41046",
    zip_name: "glencoe, ky",
  },
  {
    postal_code: "55027",
    zip_name: "goodhue, mn",
  },
  {
    postal_code: "55949",
    zip_name: "lanesboro, mn",
  },
  {
    postal_code: "49950",
    zip_name: "mohawk, mi",
  },
  {
    postal_code: "53032",
    zip_name: "horicon, wi",
  },
  {
    postal_code: "53806",
    zip_name: "cassville, wi",
  },
  {
    postal_code: "49318",
    zip_name: "casnovia, mi",
  },
  {
    postal_code: "30627",
    zip_name: "carlton, ga",
  },
  {
    postal_code: "29074",
    zip_name: "liberty hill, sc",
  },
  {
    postal_code: "28338",
    zip_name: "ellerbe, nc",
  },
  {
    postal_code: "69149",
    zip_name: "lodgepole, ne",
  },
  {
    postal_code: "16249",
    zip_name: "rural valley, pa",
  },
  {
    postal_code: "15458",
    zip_name: "mc clellandtown, pa",
  },
  {
    postal_code: "20662",
    zip_name: "nanjemoy, md",
  },
  {
    postal_code: "40734",
    zip_name: "gray, ky",
  },
  {
    postal_code: "74820",
    zip_name: "ada, ok",
  },
  {
    postal_code: "4912",
    zip_name: "athens, me",
  },
  {
    postal_code: "50049",
    zip_name: "chariton, ia",
  },
  {
    postal_code: "25241",
    zip_name: "evans, wv",
  },
  {
    postal_code: "21659",
    zip_name: "rhodesdale, md",
  },
  {
    postal_code: "39474",
    zip_name: "prentiss, ms",
  },
  {
    postal_code: "54974",
    zip_name: "rosendale, wi",
  },
  {
    postal_code: "41080",
    zip_name: "petersburg, ky",
  },
  {
    postal_code: "96703",
    zip_name: "anahola, hi",
  },
  {
    postal_code: "22660",
    zip_name: "toms brook, va",
  },
  {
    postal_code: "67427",
    zip_name: "bushton, ks",
  },
  {
    postal_code: "38057",
    zip_name: "moscow, tn",
  },
  {
    postal_code: "63468",
    zip_name: "shelbina, mo",
  },
  {
    postal_code: "83286",
    zip_name: "weston, id",
  },
  {
    postal_code: "26184",
    zip_name: "waverly, wv",
  },
  {
    postal_code: "26568",
    zip_name: "enterprise, wv",
  },
  {
    postal_code: "60520",
    zip_name: "hinckley, il",
  },
  {
    postal_code: "46349",
    zip_name: "lake village, in",
  },
  {
    postal_code: "43787",
    zip_name: "stockport, oh",
  },
  {
    postal_code: "55946",
    zip_name: "kenyon, mn",
  },
  {
    postal_code: "67548",
    zip_name: "la crosse, ks",
  },
  {
    postal_code: "70031",
    zip_name: "ama, la",
  },
  {
    postal_code: "26050",
    zip_name: "newell, wv",
  },
  {
    postal_code: "48897",
    zip_name: "woodland, mi",
  },
  {
    postal_code: "65785",
    zip_name: "stockton, mo",
  },
  {
    postal_code: "32767",
    zip_name: "paisley, fl",
  },
  {
    postal_code: "41224",
    zip_name: "inez, ky",
  },
  {
    postal_code: "40107",
    zip_name: "boston, ky",
  },
  {
    postal_code: "26571",
    zip_name: "farmington, wv",
  },
  {
    postal_code: "6353",
    zip_name: "montville, ct",
  },
  {
    postal_code: "28351",
    zip_name: "laurel hill, nc",
  },
  {
    postal_code: "55065",
    zip_name: "randolph, mn",
  },
  {
    postal_code: "56237",
    zip_name: "echo, mn",
  },
  {
    postal_code: "68366",
    zip_name: "greenwood, ne",
  },
  {
    postal_code: "5837",
    zip_name: "east haven, vt",
  },
  {
    postal_code: "28521",
    zip_name: "chinquapin, nc",
  },
  {
    postal_code: "17049",
    zip_name: "mc alisterville, pa",
  },
  {
    postal_code: "12989",
    zip_name: "vermontville, ny",
  },
  {
    postal_code: "5046",
    zip_name: "groton, vt",
  },
  {
    postal_code: "38922",
    zip_name: "coffeeville, ms",
  },
  {
    postal_code: "29563",
    zip_name: "lake view, sc",
  },
  {
    postal_code: "46366",
    zip_name: "north judson, in",
  },
  {
    postal_code: "3907",
    zip_name: "ogunquit, me",
  },
  {
    postal_code: "52210",
    zip_name: "brandon, ia",
  },
  {
    postal_code: "45874",
    zip_name: "ohio city, oh",
  },
  {
    postal_code: "30746",
    zip_name: "sugar valley, ga",
  },
  {
    postal_code: "12086",
    zip_name: "hagaman, ny",
  },
  {
    postal_code: "50206",
    zip_name: "new providence, ia",
  },
  {
    postal_code: "30909",
    zip_name: "augusta, ga",
  },
  {
    postal_code: "29918",
    zip_name: "estill, sc",
  },
  {
    postal_code: "61050",
    zip_name: "mc connell, il",
  },
  {
    postal_code: "14464",
    zip_name: "hamlin, ny",
  },
  {
    postal_code: "12190",
    zip_name: "wells, ny",
  },
  {
    postal_code: "49402",
    zip_name: "branch, mi",
  },
  {
    postal_code: "26378",
    zip_name: "jane lew, wv",
  },
  {
    postal_code: "99101",
    zip_name: "addy, wa",
  },
  {
    postal_code: "17074",
    zip_name: "newport, pa",
  },
  {
    postal_code: "62264",
    zip_name: "new athens, il",
  },
  {
    postal_code: "3785",
    zip_name: "woodsville, nh",
  },
  {
    postal_code: "18080",
    zip_name: "slatington, pa",
  },
  {
    postal_code: "4619",
    zip_name: "calais, me",
  },
  {
    postal_code: "55771",
    zip_name: "orr, mn",
  },
  {
    postal_code: "32124",
    zip_name: "daytona beach, fl",
  },
  {
    postal_code: "62354",
    zip_name: "nauvoo, il",
  },
  {
    postal_code: "5830",
    zip_name: "derby line, vt",
  },
  {
    postal_code: "42256",
    zip_name: "lewisburg, ky",
  },
  {
    postal_code: "12440",
    zip_name: "high falls, ny",
  },
  {
    postal_code: "2671",
    zip_name: "west harwich, ma",
  },
  {
    postal_code: "90401",
    zip_name: "santa monica, ca",
  },
  {
    postal_code: "68845",
    zip_name: "kearney, ne",
  },
  {
    postal_code: "73077",
    zip_name: "perry, ok",
  },
  {
    postal_code: "17319",
    zip_name: "etters, pa",
  },
  {
    postal_code: "42031",
    zip_name: "clinton, ky",
  },
  {
    postal_code: "3258",
    zip_name: "chichester, nh",
  },
  {
    postal_code: "18930",
    zip_name: "kintnersville, pa",
  },
  {
    postal_code: "8812",
    zip_name: "dunellen, nj",
  },
  {
    postal_code: "8530",
    zip_name: "lambertville, nj",
  },
  {
    postal_code: "59840",
    zip_name: "hamilton, mt",
  },
  {
    postal_code: "72921",
    zip_name: "alma, ar",
  },
  {
    postal_code: "48164",
    zip_name: "new boston, mi",
  },
  {
    postal_code: "36726",
    zip_name: "camden, al",
  },
  {
    postal_code: "79903",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "2126",
    zip_name: "mattapan, ma",
  },
  {
    postal_code: "13658",
    zip_name: "lisbon, ny",
  },
  {
    postal_code: "54202",
    zip_name: "baileys harbor, wi",
  },
  {
    postal_code: "63771",
    zip_name: "oran, mo",
  },
  {
    postal_code: "28615",
    zip_name: "creston, nc",
  },
  {
    postal_code: "67439",
    zip_name: "ellsworth, ks",
  },
  {
    postal_code: "48302",
    zip_name: "bloomfield hills, mi",
  },
  {
    postal_code: "70836",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "75601",
    zip_name: "longview, tx",
  },
  {
    postal_code: "38703",
    zip_name: "greenville, ms",
  },
  {
    postal_code: "31501",
    zip_name: "waycross, ga",
  },
  {
    postal_code: "17058",
    zip_name: "mifflin, pa",
  },
  {
    postal_code: "8036",
    zip_name: "hainesport, nj",
  },
  {
    postal_code: "65590",
    zip_name: "long lane, mo",
  },
  {
    postal_code: "32140",
    zip_name: "florahome, fl",
  },
  {
    postal_code: "93527",
    zip_name: "inyokern, ca",
  },
  {
    postal_code: "32193",
    zip_name: "welaka, fl",
  },
  {
    postal_code: "38079",
    zip_name: "tiptonville, tn",
  },
  {
    postal_code: "20144",
    zip_name: "delaplane, va",
  },
  {
    postal_code: "54527",
    zip_name: "glidden, wi",
  },
  {
    postal_code: "13809",
    zip_name: "mount upton, ny",
  },
  {
    postal_code: "97420",
    zip_name: "coos bay, or",
  },
  {
    postal_code: "30124",
    zip_name: "cave spring, ga",
  },
  {
    postal_code: "65669",
    zip_name: "highlandville, mo",
  },
  {
    postal_code: "21838",
    zip_name: "marion station, md",
  },
  {
    postal_code: "92322",
    zip_name: "cedarpines park, ca",
  },
  {
    postal_code: "21776",
    zip_name: "new windsor, md",
  },
  {
    postal_code: "21851",
    zip_name: "pocomoke city, md",
  },
  {
    postal_code: "71467",
    zip_name: "pollock, la",
  },
  {
    postal_code: "98826",
    zip_name: "leavenworth, wa",
  },
  {
    postal_code: "36280",
    zip_name: "woodland, al",
  },
  {
    postal_code: "1077",
    zip_name: "southwick, ma",
  },
  {
    postal_code: "65610",
    zip_name: "billings, mo",
  },
  {
    postal_code: "13640",
    zip_name: "wellesley island, ny",
  },
  {
    postal_code: "4607",
    zip_name: "gouldsboro, me",
  },
  {
    postal_code: "12430",
    zip_name: "fleischmanns, ny",
  },
  {
    postal_code: "49879",
    zip_name: "republic, mi",
  },
  {
    postal_code: "35757",
    zip_name: "madison, al",
  },
  {
    postal_code: "29161",
    zip_name: "timmonsville, sc",
  },
  {
    postal_code: "4351",
    zip_name: "manchester, me",
  },
  {
    postal_code: "48235",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "4282",
    zip_name: "turner, me",
  },
  {
    postal_code: "13340",
    zip_name: "frankfort, ny",
  },
  {
    postal_code: "13843",
    zip_name: "south new berlin, ny",
  },
  {
    postal_code: "40033",
    zip_name: "lebanon, ky",
  },
  {
    postal_code: "80648",
    zip_name: "nunn, co",
  },
  {
    postal_code: "21013",
    zip_name: "baldwin, md",
  },
  {
    postal_code: "4042",
    zip_name: "hollis center, me",
  },
  {
    postal_code: "41465",
    zip_name: "salyersville, ky",
  },
  {
    postal_code: "24609",
    zip_name: "cedar bluff, va",
  },
  {
    postal_code: "2152",
    zip_name: "winthrop, ma",
  },
  {
    postal_code: "75684",
    zip_name: "overton, tx",
  },
  {
    postal_code: "14223",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "2465",
    zip_name: "west newton, ma",
  },
  {
    postal_code: "93920",
    zip_name: "big sur, ca",
  },
  {
    postal_code: "49853",
    zip_name: "mc millan, mi",
  },
  {
    postal_code: "36260",
    zip_name: "eastaboga, al",
  },
  {
    postal_code: "14606",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "29655",
    zip_name: "iva, sc",
  },
  {
    postal_code: "30204",
    zip_name: "barnesville, ga",
  },
  {
    postal_code: "19807",
    zip_name: "wilmington, de",
  },
  {
    postal_code: "48455",
    zip_name: "metamora, mi",
  },
  {
    postal_code: "97526",
    zip_name: "grants pass, or",
  },
  {
    postal_code: "61911",
    zip_name: "arthur, il",
  },
  {
    postal_code: "68108",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "8889",
    zip_name: "whitehouse station, nj",
  },
  {
    postal_code: "66002",
    zip_name: "atchison, ks",
  },
  {
    postal_code: "19962",
    zip_name: "magnolia, de",
  },
  {
    postal_code: "8318",
    zip_name: "elmer, nj",
  },
  {
    postal_code: "80237",
    zip_name: "denver, co",
  },
  {
    postal_code: "29048",
    zip_name: "eutawville, sc",
  },
  {
    postal_code: "19352",
    zip_name: "lincoln university, pa",
  },
  {
    postal_code: "59758",
    zip_name: "west yellowstone, mt",
  },
  {
    postal_code: "95669",
    zip_name: "plymouth, ca",
  },
  {
    postal_code: "31909",
    zip_name: "columbus, ga",
  },
  {
    postal_code: "48071",
    zip_name: "madison heights, mi",
  },
  {
    postal_code: "80510",
    zip_name: "allenspark, co",
  },
  {
    postal_code: "71483",
    zip_name: "winnfield, la",
  },
  {
    postal_code: "98629",
    zip_name: "la center, wa",
  },
  {
    postal_code: "48229",
    zip_name: "ecorse, mi",
  },
  {
    postal_code: "97366",
    zip_name: "south beach, or",
  },
  {
    postal_code: "88081",
    zip_name: "chaparral, nm",
  },
  {
    postal_code: "18610",
    zip_name: "blakeslee, pa",
  },
  {
    postal_code: "3904",
    zip_name: "kittery, me",
  },
  {
    postal_code: "40146",
    zip_name: "irvington, ky",
  },
  {
    postal_code: "45831",
    zip_name: "continental, oh",
  },
  {
    postal_code: "61534",
    zip_name: "green valley, il",
  },
  {
    postal_code: "41063",
    zip_name: "morning view, ky",
  },
  {
    postal_code: "48317",
    zip_name: "utica, mi",
  },
  {
    postal_code: "70806",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "65781",
    zip_name: "willard, mo",
  },
  {
    postal_code: "49651",
    zip_name: "lake city, mi",
  },
  {
    postal_code: "48124",
    zip_name: "dearborn, mi",
  },
  {
    postal_code: "48421",
    zip_name: "columbiaville, mi",
  },
  {
    postal_code: "40729",
    zip_name: "east bernstadt, ky",
  },
  {
    postal_code: "2115",
    zip_name: "boston, ma",
  },
  {
    postal_code: "53181",
    zip_name: "twin lakes, wi",
  },
  {
    postal_code: "32310",
    zip_name: "tallahassee, fl",
  },
  {
    postal_code: "17020",
    zip_name: "duncannon, pa",
  },
  {
    postal_code: "14559",
    zip_name: "spencerport, ny",
  },
  {
    postal_code: "17362",
    zip_name: "spring grove, pa",
  },
  {
    postal_code: "4937",
    zip_name: "fairfield, me",
  },
  {
    postal_code: "84662",
    zip_name: "spring city, ut",
  },
  {
    postal_code: "3827",
    zip_name: "east kingston, nh",
  },
  {
    postal_code: "5359",
    zip_name: "west townshend, vt",
  },
  {
    postal_code: "12495",
    zip_name: "willow, ny",
  },
  {
    postal_code: "29426",
    zip_name: "adams run, sc",
  },
  {
    postal_code: "61878",
    zip_name: "thomasboro, il",
  },
  {
    postal_code: "46161",
    zip_name: "morristown, in",
  },
  {
    postal_code: "24440",
    zip_name: "greenville, va",
  },
  {
    postal_code: "55012",
    zip_name: "center city, mn",
  },
  {
    postal_code: "60941",
    zip_name: "herscher, il",
  },
  {
    postal_code: "75844",
    zip_name: "grapeland, tx",
  },
  {
    postal_code: "54636",
    zip_name: "holmen, wi",
  },
  {
    postal_code: "77364",
    zip_name: "pointblank, tx",
  },
  {
    postal_code: "48220",
    zip_name: "ferndale, mi",
  },
  {
    postal_code: "24370",
    zip_name: "saltville, va",
  },
  {
    postal_code: "7062",
    zip_name: "plainfield, nj",
  },
  {
    postal_code: "85619",
    zip_name: "mount lemmon, az",
  },
  {
    postal_code: "66025",
    zip_name: "eudora, ks",
  },
  {
    postal_code: "8242",
    zip_name: "rio grande, nj",
  },
  {
    postal_code: "21631",
    zip_name: "east new market, md",
  },
  {
    postal_code: "44103",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "16666",
    zip_name: "osceola mills, pa",
  },
  {
    postal_code: "60556",
    zip_name: "waterman, il",
  },
  {
    postal_code: "62411",
    zip_name: "altamont, il",
  },
  {
    postal_code: "11579",
    zip_name: "sea cliff, ny",
  },
  {
    postal_code: "12487",
    zip_name: "ulster park, ny",
  },
  {
    postal_code: "14534",
    zip_name: "pittsford, ny",
  },
  {
    postal_code: "48316",
    zip_name: "utica, mi",
  },
  {
    postal_code: "83605",
    zip_name: "caldwell, id",
  },
  {
    postal_code: "93701",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "49426",
    zip_name: "hudsonville, mi",
  },
  {
    postal_code: "80829",
    zip_name: "manitou springs, co",
  },
  {
    postal_code: "83860",
    zip_name: "sagle, id",
  },
  {
    postal_code: "24557",
    zip_name: "gretna, va",
  },
  {
    postal_code: "31545",
    zip_name: "jesup, ga",
  },
  {
    postal_code: "48416",
    zip_name: "brown city, mi",
  },
  {
    postal_code: "8879",
    zip_name: "south amboy, nj",
  },
  {
    postal_code: "60602",
    zip_name: "chicago, il",
  },
  {
    postal_code: "4730",
    zip_name: "houlton, me",
  },
  {
    postal_code: "93728",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "98253",
    zip_name: "greenbank, wa",
  },
  {
    postal_code: "48023",
    zip_name: "fair haven, mi",
  },
  {
    postal_code: "1905",
    zip_name: "lynn, ma",
  },
  {
    postal_code: "79511",
    zip_name: "coahoma, tx",
  },
  {
    postal_code: "16731",
    zip_name: "eldred, pa",
  },
  {
    postal_code: "1719",
    zip_name: "boxborough, ma",
  },
  {
    postal_code: "27011",
    zip_name: "boonville, nc",
  },
  {
    postal_code: "37891",
    zip_name: "whitesburg, tn",
  },
  {
    postal_code: "1843",
    zip_name: "lawrence, ma",
  },
  {
    postal_code: "85543",
    zip_name: "pima, az",
  },
  {
    postal_code: "47868",
    zip_name: "poland, in",
  },
  {
    postal_code: "59036",
    zip_name: "harlowton, mt",
  },
  {
    postal_code: "25053",
    zip_name: "danville, wv",
  },
  {
    postal_code: "60921",
    zip_name: "chatsworth, il",
  },
  {
    postal_code: "15673",
    zip_name: "north apollo, pa",
  },
  {
    postal_code: "6870",
    zip_name: "old greenwich, ct",
  },
  {
    postal_code: "4047",
    zip_name: "parsonsfield, me",
  },
  {
    postal_code: "18054",
    zip_name: "green lane, pa",
  },
  {
    postal_code: "49689",
    zip_name: "wellston, mi",
  },
  {
    postal_code: "10468",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "97471",
    zip_name: "roseburg, or",
  },
  {
    postal_code: "93562",
    zip_name: "trona, ca",
  },
  {
    postal_code: "10460",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "94111",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "53504",
    zip_name: "argyle, wi",
  },
  {
    postal_code: "45232",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "55088",
    zip_name: "webster, mn",
  },
  {
    postal_code: "2140",
    zip_name: "cambridge, ma",
  },
  {
    postal_code: "8527",
    zip_name: "jackson, nj",
  },
  {
    postal_code: "42633",
    zip_name: "monticello, ky",
  },
  {
    postal_code: "84511",
    zip_name: "blanding, ut",
  },
  {
    postal_code: "12473",
    zip_name: "round top, ny",
  },
  {
    postal_code: "11941",
    zip_name: "eastport, ny",
  },
  {
    postal_code: "5651",
    zip_name: "east montpelier, vt",
  },
  {
    postal_code: "59823",
    zip_name: "bonner, mt",
  },
  {
    postal_code: "33857",
    zip_name: "lorida, fl",
  },
  {
    postal_code: "17103",
    zip_name: "harrisburg, pa",
  },
  {
    postal_code: "49677",
    zip_name: "reed city, mi",
  },
  {
    postal_code: "82410",
    zip_name: "basin, wy",
  },
  {
    postal_code: "12824",
    zip_name: "diamond point, ny",
  },
  {
    postal_code: "17034",
    zip_name: "highspire, pa",
  },
  {
    postal_code: "45880",
    zip_name: "payne, oh",
  },
  {
    postal_code: "79504",
    zip_name: "baird, tx",
  },
  {
    postal_code: "79356",
    zip_name: "post, tx",
  },
  {
    postal_code: "4022",
    zip_name: "denmark, me",
  },
  {
    postal_code: "95134",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "3245",
    zip_name: "holderness, nh",
  },
  {
    postal_code: "26287",
    zip_name: "parsons, wv",
  },
  {
    postal_code: "52142",
    zip_name: "fayette, ia",
  },
  {
    postal_code: "78069",
    zip_name: "somerset, tx",
  },
  {
    postal_code: "1863",
    zip_name: "north chelmsford, ma",
  },
  {
    postal_code: "70071",
    zip_name: "lutcher, la",
  },
  {
    postal_code: "38641",
    zip_name: "lake cormorant, ms",
  },
  {
    postal_code: "5354",
    zip_name: "vernon, vt",
  },
  {
    postal_code: "71960",
    zip_name: "norman, ar",
  },
  {
    postal_code: "83325",
    zip_name: "eden, id",
  },
  {
    postal_code: "5051",
    zip_name: "newbury, vt",
  },
  {
    postal_code: "15086",
    zip_name: "warrendale, pa",
  },
  {
    postal_code: "5660",
    zip_name: "moretown, vt",
  },
  {
    postal_code: "15468",
    zip_name: "new salem, pa",
  },
  {
    postal_code: "21719",
    zip_name: "cascade, md",
  },
  {
    postal_code: "85321",
    zip_name: "ajo, az",
  },
  {
    postal_code: "65764",
    zip_name: "tunas, mo",
  },
  {
    postal_code: "98039",
    zip_name: "medina, wa",
  },
  {
    postal_code: "62037",
    zip_name: "grafton, il",
  },
  {
    postal_code: "83639",
    zip_name: "marsing, id",
  },
  {
    postal_code: "22542",
    zip_name: "rhoadesville, va",
  },
  {
    postal_code: "21201",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "93653",
    zip_name: "raymond, ca",
  },
  {
    postal_code: "95310",
    zip_name: "columbia, ca",
  },
  {
    postal_code: "55724",
    zip_name: "cotton, mn",
  },
  {
    postal_code: "48157",
    zip_name: "luna pier, mi",
  },
  {
    postal_code: "21782",
    zip_name: "sharpsburg, md",
  },
  {
    postal_code: "43516",
    zip_name: "deshler, oh",
  },
  {
    postal_code: "48463",
    zip_name: "otisville, mi",
  },
  {
    postal_code: "1022",
    zip_name: "chicopee, ma",
  },
  {
    postal_code: "5486",
    zip_name: "south hero, vt",
  },
  {
    postal_code: "56467",
    zip_name: "nevis, mn",
  },
  {
    postal_code: "16051",
    zip_name: "portersville, pa",
  },
  {
    postal_code: "53956",
    zip_name: "randolph, wi",
  },
  {
    postal_code: "28373",
    zip_name: "pinebluff, nc",
  },
  {
    postal_code: "68409",
    zip_name: "murray, ne",
  },
  {
    postal_code: "12838",
    zip_name: "hartford, ny",
  },
  {
    postal_code: "46919",
    zip_name: "converse, in",
  },
  {
    postal_code: "14589",
    zip_name: "williamson, ny",
  },
  {
    postal_code: "4428",
    zip_name: "eddington, me",
  },
  {
    postal_code: "14052",
    zip_name: "east aurora, ny",
  },
  {
    postal_code: "37201",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "75838",
    zip_name: "donie, tx",
  },
  {
    postal_code: "47272",
    zip_name: "saint paul, in",
  },
  {
    postal_code: "47232",
    zip_name: "elizabethtown, in",
  },
  {
    postal_code: "56161",
    zip_name: "okabena, mn",
  },
  {
    postal_code: "95202",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "53510",
    zip_name: "belmont, wi",
  },
  {
    postal_code: "1983",
    zip_name: "topsfield, ma",
  },
  {
    postal_code: "71282",
    zip_name: "tallulah, la",
  },
  {
    postal_code: "18630",
    zip_name: "meshoppen, pa",
  },
  {
    postal_code: "17965",
    zip_name: "port carbon, pa",
  },
  {
    postal_code: "16373",
    zip_name: "emlenton, pa",
  },
  {
    postal_code: "55007",
    zip_name: "brook park, mn",
  },
  {
    postal_code: "44613",
    zip_name: "brewster, oh",
  },
  {
    postal_code: "55329",
    zip_name: "eden valley, mn",
  },
  {
    postal_code: "56447",
    zip_name: "emily, mn",
  },
  {
    postal_code: "59522",
    zip_name: "chester, mt",
  },
  {
    postal_code: "50149",
    zip_name: "lorimor, ia",
  },
  {
    postal_code: "66712",
    zip_name: "arma, ks",
  },
  {
    postal_code: "92230",
    zip_name: "cabazon, ca",
  },
  {
    postal_code: "63736",
    zip_name: "benton, mo",
  },
  {
    postal_code: "21162",
    zip_name: "white marsh, md",
  },
  {
    postal_code: "43431",
    zip_name: "gibsonburg, oh",
  },
  {
    postal_code: "54004",
    zip_name: "clayton, wi",
  },
  {
    postal_code: "13744",
    zip_name: "castle creek, ny",
  },
  {
    postal_code: "16222",
    zip_name: "dayton, pa",
  },
  {
    postal_code: "13076",
    zip_name: "hastings, ny",
  },
  {
    postal_code: "65275",
    zip_name: "paris, mo",
  },
  {
    postal_code: "48141",
    zip_name: "inkster, mi",
  },
  {
    postal_code: "6778",
    zip_name: "northfield, ct",
  },
  {
    postal_code: "95374",
    zip_name: "stevinson, ca",
  },
  {
    postal_code: "61376",
    zip_name: "walnut, il",
  },
  {
    postal_code: "52554",
    zip_name: "eldon, ia",
  },
  {
    postal_code: "3217",
    zip_name: "ashland, nh",
  },
  {
    postal_code: "26714",
    zip_name: "delray, wv",
  },
  {
    postal_code: "94018",
    zip_name: "el granada, ca",
  },
  {
    postal_code: "3269",
    zip_name: "sanbornton, nh",
  },
  {
    postal_code: "62888",
    zip_name: "tamaroa, il",
  },
  {
    postal_code: "32643",
    zip_name: "high springs, fl",
  },
  {
    postal_code: "92070",
    zip_name: "santa ysabel, ca",
  },
  {
    postal_code: "15672",
    zip_name: "new stanton, pa",
  },
  {
    postal_code: "16045",
    zip_name: "lyndora, pa",
  },
  {
    postal_code: "12183",
    zip_name: "troy, ny",
  },
  {
    postal_code: "21830",
    zip_name: "hebron, md",
  },
  {
    postal_code: "52778",
    zip_name: "wilton, ia",
  },
  {
    postal_code: "21863",
    zip_name: "snow hill, md",
  },
  {
    postal_code: "15639",
    zip_name: "hunker, pa",
  },
  {
    postal_code: "62271",
    zip_name: "okawville, il",
  },
  {
    postal_code: "3046",
    zip_name: "dunbarton, nh",
  },
  {
    postal_code: "2558",
    zip_name: "onset, ma",
  },
  {
    postal_code: "47989",
    zip_name: "waveland, in",
  },
  {
    postal_code: "12592",
    zip_name: "wassaic, ny",
  },
  {
    postal_code: "37171",
    zip_name: "southside, tn",
  },
  {
    postal_code: "22725",
    zip_name: "leon, va",
  },
  {
    postal_code: "88022",
    zip_name: "arenas valley, nm",
  },
  {
    postal_code: "26033",
    zip_name: "cameron, wv",
  },
  {
    postal_code: "12035",
    zip_name: "central bridge, ny",
  },
  {
    postal_code: "65014",
    zip_name: "bland, mo",
  },
  {
    postal_code: "4086",
    zip_name: "topsham, me",
  },
  {
    postal_code: "36079",
    zip_name: "troy, al",
  },
  {
    postal_code: "38049",
    zip_name: "mason, tn",
  },
  {
    postal_code: "44882",
    zip_name: "sycamore, oh",
  },
  {
    postal_code: "28318",
    zip_name: "autryville, nc",
  },
  {
    postal_code: "97886",
    zip_name: "weston, or",
  },
  {
    postal_code: "43532",
    zip_name: "liberty center, oh",
  },
  {
    postal_code: "2141",
    zip_name: "cambridge, ma",
  },
  {
    postal_code: "1033",
    zip_name: "granby, ma",
  },
  {
    postal_code: "29436",
    zip_name: "cross, sc",
  },
  {
    postal_code: "55797",
    zip_name: "wrenshall, mn",
  },
  {
    postal_code: "41537",
    zip_name: "jenkins, ky",
  },
  {
    postal_code: "13416",
    zip_name: "newport, ny",
  },
  {
    postal_code: "17026",
    zip_name: "fredericksburg, pa",
  },
  {
    postal_code: "12942",
    zip_name: "keene, ny",
  },
  {
    postal_code: "38075",
    zip_name: "whiteville, tn",
  },
  {
    postal_code: "75770",
    zip_name: "larue, tx",
  },
  {
    postal_code: "35549",
    zip_name: "carbon hill, al",
  },
  {
    postal_code: "38915",
    zip_name: "bruce, ms",
  },
  {
    postal_code: "49916",
    zip_name: "chassell, mi",
  },
  {
    postal_code: "62988",
    zip_name: "tamms, il",
  },
  {
    postal_code: "3574",
    zip_name: "bethlehem, nh",
  },
  {
    postal_code: "45830",
    zip_name: "columbus grove, oh",
  },
  {
    postal_code: "27581",
    zip_name: "stem, nc",
  },
  {
    postal_code: "55721",
    zip_name: "cohasset, mn",
  },
  {
    postal_code: "84528",
    zip_name: "huntington, ut",
  },
  {
    postal_code: "13780",
    zip_name: "guilford, ny",
  },
  {
    postal_code: "20197",
    zip_name: "waterford, va",
  },
  {
    postal_code: "12503",
    zip_name: "ancramdale, ny",
  },
  {
    postal_code: "5440",
    zip_name: "alburgh, vt",
  },
  {
    postal_code: "32702",
    zip_name: "altoona, fl",
  },
  {
    postal_code: "27013",
    zip_name: "cleveland, nc",
  },
  {
    postal_code: "53018",
    zip_name: "delafield, wi",
  },
  {
    postal_code: "18822",
    zip_name: "hallstead, pa",
  },
  {
    postal_code: "83651",
    zip_name: "nampa, id",
  },
  {
    postal_code: "14621",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "37640",
    zip_name: "butler, tn",
  },
  {
    postal_code: "6280",
    zip_name: "windham, ct",
  },
  {
    postal_code: "12853",
    zip_name: "north creek, ny",
  },
  {
    postal_code: "1864",
    zip_name: "north reading, ma",
  },
  {
    postal_code: "38040",
    zip_name: "halls, tn",
  },
  {
    postal_code: "54876",
    zip_name: "stone lake, wi",
  },
  {
    postal_code: "14837",
    zip_name: "dundee, ny",
  },
  {
    postal_code: "67756",
    zip_name: "saint francis, ks",
  },
  {
    postal_code: "44704",
    zip_name: "canton, oh",
  },
  {
    postal_code: "30256",
    zip_name: "meansville, ga",
  },
  {
    postal_code: "44842",
    zip_name: "loudonville, oh",
  },
  {
    postal_code: "70744",
    zip_name: "holden, la",
  },
  {
    postal_code: "7723",
    zip_name: "deal, nj",
  },
  {
    postal_code: "6330",
    zip_name: "baltic, ct",
  },
  {
    postal_code: "1035",
    zip_name: "hadley, ma",
  },
  {
    postal_code: "31791",
    zip_name: "sylvester, ga",
  },
  {
    postal_code: "25082",
    zip_name: "fraziers bottom, wv",
  },
  {
    postal_code: "48866",
    zip_name: "ovid, mi",
  },
  {
    postal_code: "42413",
    zip_name: "hanson, ky",
  },
  {
    postal_code: "86018",
    zip_name: "parks, az",
  },
  {
    postal_code: "79525",
    zip_name: "hawley, tx",
  },
  {
    postal_code: "29487",
    zip_name: "wadmalaw island, sc",
  },
  {
    postal_code: "54175",
    zip_name: "townsend, wi",
  },
  {
    postal_code: "15845",
    zip_name: "johnsonburg, pa",
  },
  {
    postal_code: "54028",
    zip_name: "woodville, wi",
  },
  {
    postal_code: "61443",
    zip_name: "kewanee, il",
  },
  {
    postal_code: "92321",
    zip_name: "cedar glen, ca",
  },
  {
    postal_code: "17316",
    zip_name: "east berlin, pa",
  },
  {
    postal_code: "27950",
    zip_name: "knotts island, nc",
  },
  {
    postal_code: "36043",
    zip_name: "hope hull, al",
  },
  {
    postal_code: "11102",
    zip_name: "astoria, ny",
  },
  {
    postal_code: "95641",
    zip_name: "isleton, ca",
  },
  {
    postal_code: "48074",
    zip_name: "smiths creek, mi",
  },
  {
    postal_code: "10282",
    zip_name: "new york, ny",
  },
  {
    postal_code: "63102",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "53573",
    zip_name: "muscoda, wi",
  },
  {
    postal_code: "14619",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "54858",
    zip_name: "milltown, wi",
  },
  {
    postal_code: "48066",
    zip_name: "roseville, mi",
  },
  {
    postal_code: "76431",
    zip_name: "chico, tx",
  },
  {
    postal_code: "13752",
    zip_name: "delancey, ny",
  },
  {
    postal_code: "31709",
    zip_name: "americus, ga",
  },
  {
    postal_code: "48097",
    zip_name: "yale, mi",
  },
  {
    postal_code: "48036",
    zip_name: "clinton township, mi",
  },
  {
    postal_code: "17111",
    zip_name: "harrisburg, pa",
  },
  {
    postal_code: "14217",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "72065",
    zip_name: "hensley, ar",
  },
  {
    postal_code: "79758",
    zip_name: "gardendale, tx",
  },
  {
    postal_code: "1985",
    zip_name: "west newbury, ma",
  },
  {
    postal_code: "27929",
    zip_name: "currituck, nc",
  },
  {
    postal_code: "47303",
    zip_name: "muncie, in",
  },
  {
    postal_code: "78956",
    zip_name: "schulenburg, tx",
  },
  {
    postal_code: "13679",
    zip_name: "redwood, ny",
  },
  {
    postal_code: "83612",
    zip_name: "council, id",
  },
  {
    postal_code: "4421",
    zip_name: "castine, me",
  },
  {
    postal_code: "61413",
    zip_name: "alpha, il",
  },
  {
    postal_code: "75568",
    zip_name: "naples, tx",
  },
  {
    postal_code: "4780",
    zip_name: "smyrna mills, me",
  },
  {
    postal_code: "37061",
    zip_name: "erin, tn",
  },
  {
    postal_code: "79855",
    zip_name: "van horn, tx",
  },
  {
    postal_code: "76903",
    zip_name: "san angelo, tx",
  },
  {
    postal_code: "16701",
    zip_name: "bradford, pa",
  },
  {
    postal_code: "31811",
    zip_name: "hamilton, ga",
  },
  {
    postal_code: "45801",
    zip_name: "lima, oh",
  },
  {
    postal_code: "11975",
    zip_name: "wainscott, ny",
  },
  {
    postal_code: "42567",
    zip_name: "eubank, ky",
  },
  {
    postal_code: "18103",
    zip_name: "allentown, pa",
  },
  {
    postal_code: "28904",
    zip_name: "hayesville, nc",
  },
  {
    postal_code: "54566",
    zip_name: "wabeno, wi",
  },
  {
    postal_code: "67410",
    zip_name: "abilene, ks",
  },
  {
    postal_code: "74016",
    zip_name: "chelsea, ok",
  },
  {
    postal_code: "36104",
    zip_name: "montgomery, al",
  },
  {
    postal_code: "4220",
    zip_name: "buckfield, me",
  },
  {
    postal_code: "77831",
    zip_name: "bedias, tx",
  },
  {
    postal_code: "20711",
    zip_name: "lothian, md",
  },
  {
    postal_code: "49441",
    zip_name: "muskegon, mi",
  },
  {
    postal_code: "78370",
    zip_name: "odem, tx",
  },
  {
    postal_code: "95452",
    zip_name: "kenwood, ca",
  },
  {
    postal_code: "64740",
    zip_name: "deepwater, mo",
  },
  {
    postal_code: "95237",
    zip_name: "lockeford, ca",
  },
  {
    postal_code: "59645",
    zip_name: "white sulphur springs, mt",
  },
  {
    postal_code: "62548",
    zip_name: "mount pulaski, il",
  },
  {
    postal_code: "2215",
    zip_name: "boston, ma",
  },
  {
    postal_code: "75490",
    zip_name: "trenton, tx",
  },
  {
    postal_code: "64844",
    zip_name: "granby, mo",
  },
  {
    postal_code: "40831",
    zip_name: "harlan, ky",
  },
  {
    postal_code: "3782",
    zip_name: "sunapee, nh",
  },
  {
    postal_code: "45359",
    zip_name: "pleasant hill, oh",
  },
  {
    postal_code: "59820",
    zip_name: "alberton, mt",
  },
  {
    postal_code: "16157",
    zip_name: "wampum, pa",
  },
  {
    postal_code: "5353",
    zip_name: "townshend, vt",
  },
  {
    postal_code: "51449",
    zip_name: "lake city, ia",
  },
  {
    postal_code: "82701",
    zip_name: "newcastle, wy",
  },
  {
    postal_code: "23310",
    zip_name: "cape charles, va",
  },
  {
    postal_code: "80105",
    zip_name: "deer trail, co",
  },
  {
    postal_code: "8027",
    zip_name: "gibbstown, nj",
  },
  {
    postal_code: "94108",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "79366",
    zip_name: "ransom canyon, tx",
  },
  {
    postal_code: "87042",
    zip_name: "peralta, nm",
  },
  {
    postal_code: "64125",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "1523",
    zip_name: "lancaster, ma",
  },
  {
    postal_code: "28775",
    zip_name: "scaly mountain, nc",
  },
  {
    postal_code: "79905",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "37079",
    zip_name: "indian mound, tn",
  },
  {
    postal_code: "11509",
    zip_name: "atlantic beach, ny",
  },
  {
    postal_code: "25262",
    zip_name: "millwood, wv",
  },
  {
    postal_code: "71423",
    zip_name: "dry prong, la",
  },
  {
    postal_code: "12582",
    zip_name: "stormville, ny",
  },
  {
    postal_code: "50135",
    zip_name: "kellogg, ia",
  },
  {
    postal_code: "13092",
    zip_name: "locke, ny",
  },
  {
    postal_code: "63039",
    zip_name: "gray summit, mo",
  },
  {
    postal_code: "58436",
    zip_name: "ellendale, nd",
  },
  {
    postal_code: "56074",
    zip_name: "nicollet, mn",
  },
  {
    postal_code: "59749",
    zip_name: "sheridan, mt",
  },
  {
    postal_code: "88011",
    zip_name: "las cruces, nm",
  },
  {
    postal_code: "65689",
    zip_name: "cabool, mo",
  },
  {
    postal_code: "7648",
    zip_name: "norwood, nj",
  },
  {
    postal_code: "63565",
    zip_name: "unionville, mo",
  },
  {
    postal_code: "38369",
    zip_name: "rutherford, tn",
  },
  {
    postal_code: "4350",
    zip_name: "litchfield, me",
  },
  {
    postal_code: "8061",
    zip_name: "mount royal, nj",
  },
  {
    postal_code: "37175",
    zip_name: "stewart, tn",
  },
  {
    postal_code: "47967",
    zip_name: "new richmond, in",
  },
  {
    postal_code: "48886",
    zip_name: "six lakes, mi",
  },
  {
    postal_code: "76365",
    zip_name: "henrietta, tx",
  },
  {
    postal_code: "23047",
    zip_name: "doswell, va",
  },
  {
    postal_code: "15531",
    zip_name: "boswell, pa",
  },
  {
    postal_code: "28678",
    zip_name: "stony point, nc",
  },
  {
    postal_code: "4106",
    zip_name: "south portland, me",
  },
  {
    postal_code: "5763",
    zip_name: "pittsford, vt",
  },
  {
    postal_code: "47229",
    zip_name: "crothersville, in",
  },
  {
    postal_code: "12017",
    zip_name: "austerlitz, ny",
  },
  {
    postal_code: "43522",
    zip_name: "grand rapids, oh",
  },
  {
    postal_code: "55965",
    zip_name: "preston, mn",
  },
  {
    postal_code: "14894",
    zip_name: "wellsburg, ny",
  },
  {
    postal_code: "78379",
    zip_name: "riviera, tx",
  },
  {
    postal_code: "47032",
    zip_name: "moores hill, in",
  },
  {
    postal_code: "4085",
    zip_name: "steep falls, me",
  },
  {
    postal_code: "46048",
    zip_name: "ingalls, in",
  },
  {
    postal_code: "61230",
    zip_name: "albany, il",
  },
  {
    postal_code: "37178",
    zip_name: "tennessee ridge, tn",
  },
  {
    postal_code: "3057",
    zip_name: "mont vernon, nh",
  },
  {
    postal_code: "37878",
    zip_name: "tallassee, tn",
  },
  {
    postal_code: "17361",
    zip_name: "shrewsbury, pa",
  },
  {
    postal_code: "74421",
    zip_name: "beggs, ok",
  },
  {
    postal_code: "98686",
    zip_name: "vancouver, wa",
  },
  {
    postal_code: "87745",
    zip_name: "sapello, nm",
  },
  {
    postal_code: "27235",
    zip_name: "colfax, nc",
  },
  {
    postal_code: "5482",
    zip_name: "shelburne, vt",
  },
  {
    postal_code: "92109",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "4911",
    zip_name: "anson, me",
  },
  {
    postal_code: "97062",
    zip_name: "tualatin, or",
  },
  {
    postal_code: "92019",
    zip_name: "el cajon, ca",
  },
  {
    postal_code: "96797",
    zip_name: "waipahu, hi",
  },
  {
    postal_code: "80439",
    zip_name: "evergreen, co",
  },
  {
    postal_code: "78578",
    zip_name: "port isabel, tx",
  },
  {
    postal_code: "18018",
    zip_name: "bethlehem, pa",
  },
  {
    postal_code: "62454",
    zip_name: "robinson, il",
  },
  {
    postal_code: "83713",
    zip_name: "boise, id",
  },
  {
    postal_code: "30809",
    zip_name: "evans, ga",
  },
  {
    postal_code: "98663",
    zip_name: "vancouver, wa",
  },
  {
    postal_code: "13360",
    zip_name: "inlet, ny",
  },
  {
    postal_code: "43412",
    zip_name: "curtice, oh",
  },
  {
    postal_code: "3084",
    zip_name: "temple, nh",
  },
  {
    postal_code: "71251",
    zip_name: "jonesboro, la",
  },
  {
    postal_code: "97408",
    zip_name: "eugene, or",
  },
  {
    postal_code: "4860",
    zip_name: "tenants harbor, me",
  },
  {
    postal_code: "97214",
    zip_name: "portland, or",
  },
  {
    postal_code: "49969",
    zip_name: "watersmeet, mi",
  },
  {
    postal_code: "10918",
    zip_name: "chester, ny",
  },
  {
    postal_code: "97818",
    zip_name: "boardman, or",
  },
  {
    postal_code: "6043",
    zip_name: "bolton, ct",
  },
  {
    postal_code: "64504",
    zip_name: "saint joseph, mo",
  },
  {
    postal_code: "75859",
    zip_name: "streetman, tx",
  },
  {
    postal_code: "54629",
    zip_name: "fountain city, wi",
  },
  {
    postal_code: "97203",
    zip_name: "portland, or",
  },
  {
    postal_code: "48327",
    zip_name: "waterford, mi",
  },
  {
    postal_code: "91902",
    zip_name: "bonita, ca",
  },
  {
    postal_code: "49129",
    zip_name: "union pier, mi",
  },
  {
    postal_code: "52404",
    zip_name: "cedar rapids, ia",
  },
  {
    postal_code: "58267",
    zip_name: "northwood, nd",
  },
  {
    postal_code: "1515",
    zip_name: "east brookfield, ma",
  },
  {
    postal_code: "98321",
    zip_name: "buckley, wa",
  },
  {
    postal_code: "74330",
    zip_name: "adair, ok",
  },
  {
    postal_code: "28682",
    zip_name: "terrell, nc",
  },
  {
    postal_code: "20833",
    zip_name: "brookeville, md",
  },
  {
    postal_code: "16652",
    zip_name: "huntingdon, pa",
  },
  {
    postal_code: "21205",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "27541",
    zip_name: "hurdle mills, nc",
  },
  {
    postal_code: "4348",
    zip_name: "jefferson, me",
  },
  {
    postal_code: "52623",
    zip_name: "danville, ia",
  },
  {
    postal_code: "53715",
    zip_name: "madison, wi",
  },
  {
    postal_code: "52175",
    zip_name: "west union, ia",
  },
  {
    postal_code: "63841",
    zip_name: "dexter, mo",
  },
  {
    postal_code: "45690",
    zip_name: "waverly, oh",
  },
  {
    postal_code: "14075",
    zip_name: "hamburg, ny",
  },
  {
    postal_code: "64150",
    zip_name: "riverside, mo",
  },
  {
    postal_code: "70030",
    zip_name: "des allemands, la",
  },
  {
    postal_code: "39150",
    zip_name: "port gibson, ms",
  },
  {
    postal_code: "18372",
    zip_name: "tannersville, pa",
  },
  {
    postal_code: "81647",
    zip_name: "new castle, co",
  },
  {
    postal_code: "30511",
    zip_name: "baldwin, ga",
  },
  {
    postal_code: "98275",
    zip_name: "mukilteo, wa",
  },
  {
    postal_code: "8629",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "8638",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "1851",
    zip_name: "lowell, ma",
  },
  {
    postal_code: "17109",
    zip_name: "harrisburg, pa",
  },
  {
    postal_code: "54814",
    zip_name: "bayfield, wi",
  },
  {
    postal_code: "17007",
    zip_name: "boiling springs, pa",
  },
  {
    postal_code: "98662",
    zip_name: "vancouver, wa",
  },
  {
    postal_code: "48661",
    zip_name: "west branch, mi",
  },
  {
    postal_code: "18255",
    zip_name: "weatherly, pa",
  },
  {
    postal_code: "89510",
    zip_name: "reno, nv",
  },
  {
    postal_code: "95004",
    zip_name: "aromas, ca",
  },
  {
    postal_code: "45813",
    zip_name: "antwerp, oh",
  },
  {
    postal_code: "4236",
    zip_name: "greene, me",
  },
  {
    postal_code: "77861",
    zip_name: "iola, tx",
  },
  {
    postal_code: "27009",
    zip_name: "belews creek, nc",
  },
  {
    postal_code: "58565",
    zip_name: "riverdale, nd",
  },
  {
    postal_code: "98295",
    zip_name: "sumas, wa",
  },
  {
    postal_code: "70079",
    zip_name: "norco, la",
  },
  {
    postal_code: "1337",
    zip_name: "bernardston, ma",
  },
  {
    postal_code: "97846",
    zip_name: "joseph, or",
  },
  {
    postal_code: "28698",
    zip_name: "zionville, nc",
  },
  {
    postal_code: "84634",
    zip_name: "gunnison, ut",
  },
  {
    postal_code: "15442",
    zip_name: "grindstone, pa",
  },
  {
    postal_code: "98356",
    zip_name: "morton, wa",
  },
  {
    postal_code: "80835",
    zip_name: "simla, co",
  },
  {
    postal_code: "4029",
    zip_name: "sebago, me",
  },
  {
    postal_code: "61759",
    zip_name: "minier, il",
  },
  {
    postal_code: "84340",
    zip_name: "willard, ut",
  },
  {
    postal_code: "53038",
    zip_name: "johnson creek, wi",
  },
  {
    postal_code: "47433",
    zip_name: "gosport, in",
  },
  {
    postal_code: "6354",
    zip_name: "moosup, ct",
  },
  {
    postal_code: "95982",
    zip_name: "sutter, ca",
  },
  {
    postal_code: "65011",
    zip_name: "barnett, mo",
  },
  {
    postal_code: "96114",
    zip_name: "janesville, ca",
  },
  {
    postal_code: "12996",
    zip_name: "willsboro, ny",
  },
  {
    postal_code: "90742",
    zip_name: "sunset beach, ca",
  },
  {
    postal_code: "2812",
    zip_name: "carolina, ri",
  },
  {
    postal_code: "10567",
    zip_name: "cortlandt manor, ny",
  },
  {
    postal_code: "71409",
    zip_name: "boyce, la",
  },
  {
    postal_code: "96790",
    zip_name: "kula, hi",
  },
  {
    postal_code: "14031",
    zip_name: "clarence, ny",
  },
  {
    postal_code: "12481",
    zip_name: "shokan, ny",
  },
  {
    postal_code: "64053",
    zip_name: "independence, mo",
  },
  {
    postal_code: "52756",
    zip_name: "long grove, ia",
  },
  {
    postal_code: "21635",
    zip_name: "galena, md",
  },
  {
    postal_code: "15142",
    zip_name: "presto, pa",
  },
  {
    postal_code: "10977",
    zip_name: "spring valley, ny",
  },
  {
    postal_code: "1464",
    zip_name: "shirley, ma",
  },
  {
    postal_code: "13203",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "8078",
    zip_name: "runnemede, nj",
  },
  {
    postal_code: "7066",
    zip_name: "clark, nj",
  },
  {
    postal_code: "68136",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "29479",
    zip_name: "saint stephen, sc",
  },
  {
    postal_code: "48467",
    zip_name: "port austin, mi",
  },
  {
    postal_code: "58785",
    zip_name: "surrey, nd",
  },
  {
    postal_code: "14617",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "93426",
    zip_name: "bradley, ca",
  },
  {
    postal_code: "55307",
    zip_name: "arlington, mn",
  },
  {
    postal_code: "68122",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "18624",
    zip_name: "lake harmony, pa",
  },
  {
    postal_code: "24060",
    zip_name: "blacksburg, va",
  },
  {
    postal_code: "72903",
    zip_name: "fort smith, ar",
  },
  {
    postal_code: "47250",
    zip_name: "madison, in",
  },
  {
    postal_code: "97209",
    zip_name: "portland, or",
  },
  {
    postal_code: "80228",
    zip_name: "denver, co",
  },
  {
    postal_code: "49329",
    zip_name: "howard city, mi",
  },
  {
    postal_code: "74425",
    zip_name: "canadian, ok",
  },
  {
    postal_code: "80827",
    zip_name: "lake george, co",
  },
  {
    postal_code: "95962",
    zip_name: "oregon house, ca",
  },
  {
    postal_code: "44311",
    zip_name: "akron, oh",
  },
  {
    postal_code: "96719",
    zip_name: "hawi, hi",
  },
  {
    postal_code: "24136",
    zip_name: "pembroke, va",
  },
  {
    postal_code: "97502",
    zip_name: "central point, or",
  },
  {
    postal_code: "56172",
    zip_name: "slayton, mn",
  },
  {
    postal_code: "73701",
    zip_name: "enid, ok",
  },
  {
    postal_code: "11709",
    zip_name: "bayville, ny",
  },
  {
    postal_code: "2554",
    zip_name: "nantucket, ma",
  },
  {
    postal_code: "71485",
    zip_name: "woodworth, la",
  },
  {
    postal_code: "13131",
    zip_name: "parish, ny",
  },
  {
    postal_code: "17104",
    zip_name: "harrisburg, pa",
  },
  {
    postal_code: "62263",
    zip_name: "nashville, il",
  },
  {
    postal_code: "54211",
    zip_name: "ephraim, wi",
  },
  {
    postal_code: "98252",
    zip_name: "granite falls, wa",
  },
  {
    postal_code: "97603",
    zip_name: "klamath falls, or",
  },
  {
    postal_code: "23356",
    zip_name: "greenbackville, va",
  },
  {
    postal_code: "62983",
    zip_name: "royalton, il",
  },
  {
    postal_code: "79329",
    zip_name: "idalou, tx",
  },
  {
    postal_code: "21903",
    zip_name: "perryville, md",
  },
  {
    postal_code: "2650",
    zip_name: "north chatham, ma",
  },
  {
    postal_code: "48215",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "39168",
    zip_name: "taylorsville, ms",
  },
  {
    postal_code: "62926",
    zip_name: "dongola, il",
  },
  {
    postal_code: "54428",
    zip_name: "elcho, wi",
  },
  {
    postal_code: "21654",
    zip_name: "oxford, md",
  },
  {
    postal_code: "3864",
    zip_name: "ossipee, nh",
  },
  {
    postal_code: "81324",
    zip_name: "dove creek, co",
  },
  {
    postal_code: "72031",
    zip_name: "clinton, ar",
  },
  {
    postal_code: "52060",
    zip_name: "maquoketa, ia",
  },
  {
    postal_code: "23062",
    zip_name: "gloucester point, va",
  },
  {
    postal_code: "42408",
    zip_name: "dawson springs, ky",
  },
  {
    postal_code: "66604",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "6519",
    zip_name: "new haven, ct",
  },
  {
    postal_code: "43061",
    zip_name: "ostrander, oh",
  },
  {
    postal_code: "61726",
    zip_name: "chenoa, il",
  },
  {
    postal_code: "83501",
    zip_name: "lewiston, id",
  },
  {
    postal_code: "15034",
    zip_name: "dravosburg, pa",
  },
  {
    postal_code: "29437",
    zip_name: "dorchester, sc",
  },
  {
    postal_code: "49657",
    zip_name: "mc bain, mi",
  },
  {
    postal_code: "4224",
    zip_name: "dixfield, me",
  },
  {
    postal_code: "68061",
    zip_name: "tekamah, ne",
  },
  {
    postal_code: "2375",
    zip_name: "south easton, ma",
  },
  {
    postal_code: "59474",
    zip_name: "shelby, mt",
  },
  {
    postal_code: "17846",
    zip_name: "millville, pa",
  },
  {
    postal_code: "42217",
    zip_name: "crofton, ky",
  },
  {
    postal_code: "54435",
    zip_name: "gleason, wi",
  },
  {
    postal_code: "40374",
    zip_name: "sharpsburg, ky",
  },
  {
    postal_code: "3839",
    zip_name: "rochester, nh",
  },
  {
    postal_code: "62685",
    zip_name: "shipman, il",
  },
  {
    postal_code: "48741",
    zip_name: "kingston, mi",
  },
  {
    postal_code: "70711",
    zip_name: "albany, la",
  },
  {
    postal_code: "36274",
    zip_name: "roanoke, al",
  },
  {
    postal_code: "10579",
    zip_name: "putnam valley, ny",
  },
  {
    postal_code: "86337",
    zip_name: "seligman, az",
  },
  {
    postal_code: "47429",
    zip_name: "ellettsville, in",
  },
  {
    postal_code: "18036",
    zip_name: "coopersburg, pa",
  },
  {
    postal_code: "12701",
    zip_name: "monticello, ny",
  },
  {
    postal_code: "24244",
    zip_name: "duffield, va",
  },
  {
    postal_code: "56481",
    zip_name: "verndale, mn",
  },
  {
    postal_code: "72202",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "19525",
    zip_name: "gilbertsville, pa",
  },
  {
    postal_code: "35906",
    zip_name: "rainbow city, al",
  },
  {
    postal_code: "24236",
    zip_name: "damascus, va",
  },
  {
    postal_code: "23690",
    zip_name: "yorktown, va",
  },
  {
    postal_code: "22637",
    zip_name: "gore, va",
  },
  {
    postal_code: "1104",
    zip_name: "springfield, ma",
  },
  {
    postal_code: "98304",
    zip_name: "ashford, wa",
  },
  {
    postal_code: "17889",
    zip_name: "winfield, pa",
  },
  {
    postal_code: "16822",
    zip_name: "beech creek, pa",
  },
  {
    postal_code: "53502",
    zip_name: "albany, wi",
  },
  {
    postal_code: "98664",
    zip_name: "vancouver, wa",
  },
  {
    postal_code: "81648",
    zip_name: "rangely, co",
  },
  {
    postal_code: "21798",
    zip_name: "woodsboro, md",
  },
  {
    postal_code: "6120",
    zip_name: "hartford, ct",
  },
  {
    postal_code: "81428",
    zip_name: "paonia, co",
  },
  {
    postal_code: "37142",
    zip_name: "palmyra, tn",
  },
  {
    postal_code: "62053",
    zip_name: "kampsville, il",
  },
  {
    postal_code: "1262",
    zip_name: "stockbridge, ma",
  },
  {
    postal_code: "48117",
    zip_name: "carleton, mi",
  },
  {
    postal_code: "60918",
    zip_name: "buckley, il",
  },
  {
    postal_code: "29686",
    zip_name: "tamassee, sc",
  },
  {
    postal_code: "53560",
    zip_name: "mazomanie, wi",
  },
  {
    postal_code: "95635",
    zip_name: "greenwood, ca",
  },
  {
    postal_code: "38666",
    zip_name: "sardis, ms",
  },
  {
    postal_code: "12156",
    zip_name: "schodack landing, ny",
  },
  {
    postal_code: "61438",
    zip_name: "good hope, il",
  },
  {
    postal_code: "59834",
    zip_name: "frenchtown, mt",
  },
  {
    postal_code: "4684",
    zip_name: "surry, me",
  },
  {
    postal_code: "37180",
    zip_name: "unionville, tn",
  },
  {
    postal_code: "59029",
    zip_name: "fromberg, mt",
  },
  {
    postal_code: "14879",
    zip_name: "savona, ny",
  },
  {
    postal_code: "94939",
    zip_name: "larkspur, ca",
  },
  {
    postal_code: "48739",
    zip_name: "hale, mi",
  },
  {
    postal_code: "37153",
    zip_name: "rockvale, tn",
  },
  {
    postal_code: "94038",
    zip_name: "moss beach, ca",
  },
  {
    postal_code: "95372",
    zip_name: "soulsbyville, ca",
  },
  {
    postal_code: "64779",
    zip_name: "rich hill, mo",
  },
  {
    postal_code: "65774",
    zip_name: "weaubleau, mo",
  },
  {
    postal_code: "80428",
    zip_name: "clark, co",
  },
  {
    postal_code: "35759",
    zip_name: "meridianville, al",
  },
  {
    postal_code: "7945",
    zip_name: "mendham, nj",
  },
  {
    postal_code: "71446",
    zip_name: "leesville, la",
  },
  {
    postal_code: "2482",
    zip_name: "wellesley, ma",
  },
  {
    postal_code: "48168",
    zip_name: "northville, mi",
  },
  {
    postal_code: "4274",
    zip_name: "poland, me",
  },
  {
    postal_code: "17547",
    zip_name: "marietta, pa",
  },
  {
    postal_code: "14590",
    zip_name: "wolcott, ny",
  },
  {
    postal_code: "10562",
    zip_name: "ossining, ny",
  },
  {
    postal_code: "49091",
    zip_name: "sturgis, mi",
  },
  {
    postal_code: "48867",
    zip_name: "owosso, mi",
  },
  {
    postal_code: "7470",
    zip_name: "wayne, nj",
  },
  {
    postal_code: "17013",
    zip_name: "carlisle, pa",
  },
  {
    postal_code: "49946",
    zip_name: "lanse, mi",
  },
  {
    postal_code: "37023",
    zip_name: "big rock, tn",
  },
  {
    postal_code: "1119",
    zip_name: "springfield, ma",
  },
  {
    postal_code: "62917",
    zip_name: "carrier mills, il",
  },
  {
    postal_code: "47022",
    zip_name: "guilford, in",
  },
  {
    postal_code: "84620",
    zip_name: "aurora, ut",
  },
  {
    postal_code: "46767",
    zip_name: "ligonier, in",
  },
  {
    postal_code: "22937",
    zip_name: "esmont, va",
  },
  {
    postal_code: "58835",
    zip_name: "arnegard, nd",
  },
  {
    postal_code: "98661",
    zip_name: "vancouver, wa",
  },
  {
    postal_code: "24019",
    zip_name: "roanoke, va",
  },
  {
    postal_code: "35208",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "81137",
    zip_name: "ignacio, co",
  },
  {
    postal_code: "7031",
    zip_name: "north arlington, nj",
  },
  {
    postal_code: "18642",
    zip_name: "duryea, pa",
  },
  {
    postal_code: "93451",
    zip_name: "san miguel, ca",
  },
  {
    postal_code: "2025",
    zip_name: "cohasset, ma",
  },
  {
    postal_code: "54465",
    zip_name: "pickerel, wi",
  },
  {
    postal_code: "4254",
    zip_name: "livermore falls, me",
  },
  {
    postal_code: "14092",
    zip_name: "lewiston, ny",
  },
  {
    postal_code: "74955",
    zip_name: "sallisaw, ok",
  },
  {
    postal_code: "53050",
    zip_name: "mayville, wi",
  },
  {
    postal_code: "54157",
    zip_name: "peshtigo, wi",
  },
  {
    postal_code: "73738",
    zip_name: "garber, ok",
  },
  {
    postal_code: "5254",
    zip_name: "manchester, vt",
  },
  {
    postal_code: "68111",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "97225",
    zip_name: "portland, or",
  },
  {
    postal_code: "35143",
    zip_name: "shelby, al",
  },
  {
    postal_code: "50225",
    zip_name: "pleasantville, ia",
  },
  {
    postal_code: "4088",
    zip_name: "waterford, me",
  },
  {
    postal_code: "6831",
    zip_name: "greenwich, ct",
  },
  {
    postal_code: "17112",
    zip_name: "harrisburg, pa",
  },
  {
    postal_code: "37309",
    zip_name: "calhoun, tn",
  },
  {
    postal_code: "4102",
    zip_name: "portland, me",
  },
  {
    postal_code: "97089",
    zip_name: "damascus, or",
  },
  {
    postal_code: "20688",
    zip_name: "solomons, md",
  },
  {
    postal_code: "80018",
    zip_name: "aurora, co",
  },
  {
    postal_code: "94612",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "76577",
    zip_name: "thorndale, tx",
  },
  {
    postal_code: "58640",
    zip_name: "killdeer, nd",
  },
  {
    postal_code: "18951",
    zip_name: "quakertown, pa",
  },
  {
    postal_code: "18045",
    zip_name: "easton, pa",
  },
  {
    postal_code: "48390",
    zip_name: "walled lake, mi",
  },
  {
    postal_code: "49253",
    zip_name: "manitou beach, mi",
  },
  {
    postal_code: "1944",
    zip_name: "manchester, ma",
  },
  {
    postal_code: "60460",
    zip_name: "odell, il",
  },
  {
    postal_code: "64153",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "4487",
    zip_name: "springfield, me",
  },
  {
    postal_code: "31520",
    zip_name: "brunswick, ga",
  },
  {
    postal_code: "21634",
    zip_name: "fishing creek, md",
  },
  {
    postal_code: "54424",
    zip_name: "deerbrook, wi",
  },
  {
    postal_code: "31822",
    zip_name: "pine mountain, ga",
  },
  {
    postal_code: "31792",
    zip_name: "thomasville, ga",
  },
  {
    postal_code: "65793",
    zip_name: "willow springs, mo",
  },
  {
    postal_code: "51347",
    zip_name: "lake park, ia",
  },
  {
    postal_code: "28033",
    zip_name: "crouse, nc",
  },
  {
    postal_code: "49688",
    zip_name: "tustin, mi",
  },
  {
    postal_code: "28742",
    zip_name: "horse shoe, nc",
  },
  {
    postal_code: "93260",
    zip_name: "posey, ca",
  },
  {
    postal_code: "37342",
    zip_name: "hillsboro, tn",
  },
  {
    postal_code: "67672",
    zip_name: "wakeeney, ks",
  },
  {
    postal_code: "68105",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "13756",
    zip_name: "east branch, ny",
  },
  {
    postal_code: "12878",
    zip_name: "stony creek, ny",
  },
  {
    postal_code: "49885",
    zip_name: "skandia, mi",
  },
  {
    postal_code: "43060",
    zip_name: "north lewisburg, oh",
  },
  {
    postal_code: "13750",
    zip_name: "davenport, ny",
  },
  {
    postal_code: "48335",
    zip_name: "farmington, mi",
  },
  {
    postal_code: "38483",
    zip_name: "summertown, tn",
  },
  {
    postal_code: "93280",
    zip_name: "wasco, ca",
  },
  {
    postal_code: "96725",
    zip_name: "holualoa, hi",
  },
  {
    postal_code: "64843",
    zip_name: "goodman, mo",
  },
  {
    postal_code: "38651",
    zip_name: "nesbit, ms",
  },
  {
    postal_code: "54401",
    zip_name: "wausau, wi",
  },
  {
    postal_code: "93285",
    zip_name: "wofford heights, ca",
  },
  {
    postal_code: "48186",
    zip_name: "westland, mi",
  },
  {
    postal_code: "76380",
    zip_name: "seymour, tx",
  },
  {
    postal_code: "41164",
    zip_name: "olive hill, ky",
  },
  {
    postal_code: "48413",
    zip_name: "bad axe, mi",
  },
  {
    postal_code: "35171",
    zip_name: "thorsby, al",
  },
  {
    postal_code: "3814",
    zip_name: "center ossipee, nh",
  },
  {
    postal_code: "1536",
    zip_name: "north grafton, ma",
  },
  {
    postal_code: "48047",
    zip_name: "new baltimore, mi",
  },
  {
    postal_code: "46320",
    zip_name: "hammond, in",
  },
  {
    postal_code: "21722",
    zip_name: "clear spring, md",
  },
  {
    postal_code: "14850",
    zip_name: "ithaca, ny",
  },
  {
    postal_code: "2724",
    zip_name: "fall river, ma",
  },
  {
    postal_code: "32137",
    zip_name: "palm coast, fl",
  },
  {
    postal_code: "98642",
    zip_name: "ridgefield, wa",
  },
  {
    postal_code: "66061",
    zip_name: "olathe, ks",
  },
  {
    postal_code: "30024",
    zip_name: "suwanee, ga",
  },
  {
    postal_code: "34135",
    zip_name: "bonita springs, fl",
  },
  {
    postal_code: "66062",
    zip_name: "olathe, ks",
  },
  {
    postal_code: "8226",
    zip_name: "ocean city, nj",
  },
  {
    postal_code: "54819",
    zip_name: "bruce, wi",
  },
  {
    postal_code: "56187",
    zip_name: "worthington, mn",
  },
  {
    postal_code: "3740",
    zip_name: "bath, nh",
  },
  {
    postal_code: "46149",
    zip_name: "lizton, in",
  },
  {
    postal_code: "83221",
    zip_name: "blackfoot, id",
  },
  {
    postal_code: "38668",
    zip_name: "senatobia, ms",
  },
  {
    postal_code: "42519",
    zip_name: "burnside, ky",
  },
  {
    postal_code: "97432",
    zip_name: "dillard, or",
  },
  {
    postal_code: "75708",
    zip_name: "tyler, tx",
  },
  {
    postal_code: "87010",
    zip_name: "cerrillos, nm",
  },
  {
    postal_code: "93401",
    zip_name: "san luis obispo, ca",
  },
  {
    postal_code: "80135",
    zip_name: "sedalia, co",
  },
  {
    postal_code: "44411",
    zip_name: "deerfield, oh",
  },
  {
    postal_code: "43906",
    zip_name: "bellaire, oh",
  },
  {
    postal_code: "50207",
    zip_name: "new sharon, ia",
  },
  {
    postal_code: "61001",
    zip_name: "apple river, il",
  },
  {
    postal_code: "20676",
    zip_name: "port republic, md",
  },
  {
    postal_code: "80212",
    zip_name: "denver, co",
  },
  {
    postal_code: "97918",
    zip_name: "vale, or",
  },
  {
    postal_code: "72135",
    zip_name: "roland, ar",
  },
  {
    postal_code: "21502",
    zip_name: "cumberland, md",
  },
  {
    postal_code: "59701",
    zip_name: "butte, mt",
  },
  {
    postal_code: "82431",
    zip_name: "lovell, wy",
  },
  {
    postal_code: "39563",
    zip_name: "moss point, ms",
  },
  {
    postal_code: "1301",
    zip_name: "greenfield, ma",
  },
  {
    postal_code: "44201",
    zip_name: "atwater, oh",
  },
  {
    postal_code: "75452",
    zip_name: "leonard, tx",
  },
  {
    postal_code: "29458",
    zip_name: "mc clellanville, sc",
  },
  {
    postal_code: "67505",
    zip_name: "south hutchinson, ks",
  },
  {
    postal_code: "50228",
    zip_name: "prairie city, ia",
  },
  {
    postal_code: "28743",
    zip_name: "hot springs, nc",
  },
  {
    postal_code: "47468",
    zip_name: "unionville, in",
  },
  {
    postal_code: "53576",
    zip_name: "orfordville, wi",
  },
  {
    postal_code: "12546",
    zip_name: "millerton, ny",
  },
  {
    postal_code: "15774",
    zip_name: "shelocta, pa",
  },
  {
    postal_code: "24558",
    zip_name: "halifax, va",
  },
  {
    postal_code: "28320",
    zip_name: "bladenboro, nc",
  },
  {
    postal_code: "56265",
    zip_name: "montevideo, mn",
  },
  {
    postal_code: "90716",
    zip_name: "hawaiian gardens, ca",
  },
  {
    postal_code: "28431",
    zip_name: "chadbourn, nc",
  },
  {
    postal_code: "13101",
    zip_name: "mc graw, ny",
  },
  {
    postal_code: "24174",
    zip_name: "thaxton, va",
  },
  {
    postal_code: "47334",
    zip_name: "daleville, in",
  },
  {
    postal_code: "44112",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "75602",
    zip_name: "longview, tx",
  },
  {
    postal_code: "4736",
    zip_name: "caribou, me",
  },
  {
    postal_code: "40807",
    zip_name: "benham, ky",
  },
  {
    postal_code: "2863",
    zip_name: "central falls, ri",
  },
  {
    postal_code: "44817",
    zip_name: "bloomdale, oh",
  },
  {
    postal_code: "13734",
    zip_name: "barton, ny",
  },
  {
    postal_code: "5743",
    zip_name: "fair haven, vt",
  },
  {
    postal_code: "95335",
    zip_name: "long barn, ca",
  },
  {
    postal_code: "37656",
    zip_name: "fall branch, tn",
  },
  {
    postal_code: "44481",
    zip_name: "warren, oh",
  },
  {
    postal_code: "12464",
    zip_name: "phoenicia, ny",
  },
  {
    postal_code: "66092",
    zip_name: "wellsville, ks",
  },
  {
    postal_code: "47904",
    zip_name: "lafayette, in",
  },
  {
    postal_code: "64850",
    zip_name: "neosho, mo",
  },
  {
    postal_code: "3849",
    zip_name: "madison, nh",
  },
  {
    postal_code: "37811",
    zip_name: "mooresburg, tn",
  },
  {
    postal_code: "4037",
    zip_name: "fryeburg, me",
  },
  {
    postal_code: "80220",
    zip_name: "denver, co",
  },
  {
    postal_code: "26150",
    zip_name: "mineral wells, wv",
  },
  {
    postal_code: "15312",
    zip_name: "avella, pa",
  },
  {
    postal_code: "92173",
    zip_name: "san ysidro, ca",
  },
  {
    postal_code: "13646",
    zip_name: "hammond, ny",
  },
  {
    postal_code: "51526",
    zip_name: "crescent, ia",
  },
  {
    postal_code: "43907",
    zip_name: "cadiz, oh",
  },
  {
    postal_code: "25306",
    zip_name: "charleston, wv",
  },
  {
    postal_code: "70394",
    zip_name: "raceland, la",
  },
  {
    postal_code: "13140",
    zip_name: "port byron, ny",
  },
  {
    postal_code: "18472",
    zip_name: "waymart, pa",
  },
  {
    postal_code: "80123",
    zip_name: "littleton, co",
  },
  {
    postal_code: "77856",
    zip_name: "franklin, tx",
  },
  {
    postal_code: "6279",
    zip_name: "willington, ct",
  },
  {
    postal_code: "62016",
    zip_name: "carrollton, il",
  },
  {
    postal_code: "78942",
    zip_name: "giddings, tx",
  },
  {
    postal_code: "40051",
    zip_name: "new haven, ky",
  },
  {
    postal_code: "73755",
    zip_name: "longdale, ok",
  },
  {
    postal_code: "61316",
    zip_name: "cedar point, il",
  },
  {
    postal_code: "75650",
    zip_name: "hallsville, tx",
  },
  {
    postal_code: "28573",
    zip_name: "pollocksville, nc",
  },
  {
    postal_code: "56535",
    zip_name: "erskine, mn",
  },
  {
    postal_code: "91733",
    zip_name: "south el monte, ca",
  },
  {
    postal_code: "48334",
    zip_name: "farmington, mi",
  },
  {
    postal_code: "76823",
    zip_name: "bangs, tx",
  },
  {
    postal_code: "42240",
    zip_name: "hopkinsville, ky",
  },
  {
    postal_code: "96761",
    zip_name: "lahaina, hi",
  },
  {
    postal_code: "6365",
    zip_name: "preston, ct",
  },
  {
    postal_code: "12758",
    zip_name: "livingston manor, ny",
  },
  {
    postal_code: "54634",
    zip_name: "hillsboro, wi",
  },
  {
    postal_code: "22645",
    zip_name: "middletown, va",
  },
  {
    postal_code: "13825",
    zip_name: "otego, ny",
  },
  {
    postal_code: "44654",
    zip_name: "millersburg, oh",
  },
  {
    postal_code: "22737",
    zip_name: "rixeyville, va",
  },
  {
    postal_code: "18326",
    zip_name: "cresco, pa",
  },
  {
    postal_code: "12036",
    zip_name: "charlotteville, ny",
  },
  {
    postal_code: "53556",
    zip_name: "lone rock, wi",
  },
  {
    postal_code: "97119",
    zip_name: "gaston, or",
  },
  {
    postal_code: "75647",
    zip_name: "gladewater, tx",
  },
  {
    postal_code: "62704",
    zip_name: "springfield, il",
  },
  {
    postal_code: "37421",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "73548",
    zip_name: "hastings, ok",
  },
  {
    postal_code: "80102",
    zip_name: "bennett, co",
  },
  {
    postal_code: "12093",
    zip_name: "jefferson, ny",
  },
  {
    postal_code: "10941",
    zip_name: "middletown, ny",
  },
  {
    postal_code: "24319",
    zip_name: "chilhowie, va",
  },
  {
    postal_code: "1606",
    zip_name: "worcester, ma",
  },
  {
    postal_code: "23954",
    zip_name: "meherrin, va",
  },
  {
    postal_code: "28377",
    zip_name: "red springs, nc",
  },
  {
    postal_code: "32640",
    zip_name: "hawthorne, fl",
  },
  {
    postal_code: "80481",
    zip_name: "ward, co",
  },
  {
    postal_code: "39479",
    zip_name: "seminary, ms",
  },
  {
    postal_code: "38852",
    zip_name: "iuka, ms",
  },
  {
    postal_code: "48808",
    zip_name: "bath, mi",
  },
  {
    postal_code: "61012",
    zip_name: "capron, il",
  },
  {
    postal_code: "5445",
    zip_name: "charlotte, vt",
  },
  {
    postal_code: "25601",
    zip_name: "logan, wv",
  },
  {
    postal_code: "30576",
    zip_name: "tiger, ga",
  },
  {
    postal_code: "49031",
    zip_name: "cassopolis, mi",
  },
  {
    postal_code: "22724",
    zip_name: "jeffersonton, va",
  },
  {
    postal_code: "76657",
    zip_name: "mc gregor, tx",
  },
  {
    postal_code: "52730",
    zip_name: "camanche, ia",
  },
  {
    postal_code: "80422",
    zip_name: "black hawk, co",
  },
  {
    postal_code: "75605",
    zip_name: "longview, tx",
  },
  {
    postal_code: "1030",
    zip_name: "feeding hills, ma",
  },
  {
    postal_code: "57703",
    zip_name: "rapid city, sd",
  },
  {
    postal_code: "44452",
    zip_name: "north lima, oh",
  },
  {
    postal_code: "55750",
    zip_name: "hoyt lakes, mn",
  },
  {
    postal_code: "70810",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "97386",
    zip_name: "sweet home, or",
  },
  {
    postal_code: "12521",
    zip_name: "craryville, ny",
  },
  {
    postal_code: "63630",
    zip_name: "cadet, mo",
  },
  {
    postal_code: "29940",
    zip_name: "seabrook, sc",
  },
  {
    postal_code: "46391",
    zip_name: "westville, in",
  },
  {
    postal_code: "37367",
    zip_name: "pikeville, tn",
  },
  {
    postal_code: "13612",
    zip_name: "black river, ny",
  },
  {
    postal_code: "64146",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "49082",
    zip_name: "quincy, mi",
  },
  {
    postal_code: "56511",
    zip_name: "audubon, mn",
  },
  {
    postal_code: "85356",
    zip_name: "wellton, az",
  },
  {
    postal_code: "58656",
    zip_name: "taylor, nd",
  },
  {
    postal_code: "44621",
    zip_name: "dennison, oh",
  },
  {
    postal_code: "5056",
    zip_name: "plymouth, vt",
  },
  {
    postal_code: "15456",
    zip_name: "lemont furnace, pa",
  },
  {
    postal_code: "77632",
    zip_name: "orange, tx",
  },
  {
    postal_code: "65708",
    zip_name: "monett, mo",
  },
  {
    postal_code: "11740",
    zip_name: "greenlawn, ny",
  },
  {
    postal_code: "75961",
    zip_name: "nacogdoches, tx",
  },
  {
    postal_code: "5088",
    zip_name: "wilder, vt",
  },
  {
    postal_code: "51555",
    zip_name: "missouri valley, ia",
  },
  {
    postal_code: "83452",
    zip_name: "tetonia, id",
  },
  {
    postal_code: "40045",
    zip_name: "milton, ky",
  },
  {
    postal_code: "61754",
    zip_name: "mc lean, il",
  },
  {
    postal_code: "50060",
    zip_name: "corydon, ia",
  },
  {
    postal_code: "12912",
    zip_name: "au sable forks, ny",
  },
  {
    postal_code: "80203",
    zip_name: "denver, co",
  },
  {
    postal_code: "80743",
    zip_name: "otis, co",
  },
  {
    postal_code: "65486",
    zip_name: "iberia, mo",
  },
  {
    postal_code: "85928",
    zip_name: "heber, az",
  },
  {
    postal_code: "30147",
    zip_name: "lindale, ga",
  },
  {
    postal_code: "32322",
    zip_name: "carrabelle, fl",
  },
  {
    postal_code: "55934",
    zip_name: "eyota, mn",
  },
  {
    postal_code: "62281",
    zip_name: "saint jacob, il",
  },
  {
    postal_code: "70057",
    zip_name: "hahnville, la",
  },
  {
    postal_code: "97304",
    zip_name: "salem, or",
  },
  {
    postal_code: "78572",
    zip_name: "mission, tx",
  },
  {
    postal_code: "74301",
    zip_name: "vinita, ok",
  },
  {
    postal_code: "95701",
    zip_name: "alta, ca",
  },
  {
    postal_code: "18853",
    zip_name: "wyalusing, pa",
  },
  {
    postal_code: "65542",
    zip_name: "licking, mo",
  },
  {
    postal_code: "16323",
    zip_name: "franklin, pa",
  },
  {
    postal_code: "26159",
    zip_name: "paden city, wv",
  },
  {
    postal_code: "48114",
    zip_name: "brighton, mi",
  },
  {
    postal_code: "54520",
    zip_name: "crandon, wi",
  },
  {
    postal_code: "65326",
    zip_name: "edwards, mo",
  },
  {
    postal_code: "43135",
    zip_name: "laurelville, oh",
  },
  {
    postal_code: "2559",
    zip_name: "pocasset, ma",
  },
  {
    postal_code: "48603",
    zip_name: "saginaw, mi",
  },
  {
    postal_code: "50039",
    zip_name: "bouton, ia",
  },
  {
    postal_code: "11355",
    zip_name: "flushing, ny",
  },
  {
    postal_code: "55707",
    zip_name: "barnum, mn",
  },
  {
    postal_code: "43318",
    zip_name: "de graff, oh",
  },
  {
    postal_code: "6472",
    zip_name: "northford, ct",
  },
  {
    postal_code: "66725",
    zip_name: "columbus, ks",
  },
  {
    postal_code: "63624",
    zip_name: "bismarck, mo",
  },
  {
    postal_code: "97116",
    zip_name: "forest grove, or",
  },
  {
    postal_code: "77801",
    zip_name: "bryan, tx",
  },
  {
    postal_code: "53168",
    zip_name: "salem, wi",
  },
  {
    postal_code: "50072",
    zip_name: "earlham, ia",
  },
  {
    postal_code: "13441",
    zip_name: "rome, ny",
  },
  {
    postal_code: "54738",
    zip_name: "eleva, wi",
  },
  {
    postal_code: "17566",
    zip_name: "quarryville, pa",
  },
  {
    postal_code: "70455",
    zip_name: "robert, la",
  },
  {
    postal_code: "48451",
    zip_name: "linden, mi",
  },
  {
    postal_code: "32668",
    zip_name: "morriston, fl",
  },
  {
    postal_code: "41059",
    zip_name: "melbourne, ky",
  },
  {
    postal_code: "27589",
    zip_name: "warrenton, nc",
  },
  {
    postal_code: "44430",
    zip_name: "leavittsburg, oh",
  },
  {
    postal_code: "56288",
    zip_name: "spicer, mn",
  },
  {
    postal_code: "75755",
    zip_name: "big sandy, tx",
  },
  {
    postal_code: "12472",
    zip_name: "rosendale, ny",
  },
  {
    postal_code: "77371",
    zip_name: "shepherd, tx",
  },
  {
    postal_code: "62884",
    zip_name: "sesser, il",
  },
  {
    postal_code: "63441",
    zip_name: "frankford, mo",
  },
  {
    postal_code: "14420",
    zip_name: "brockport, ny",
  },
  {
    postal_code: "40906",
    zip_name: "barbourville, ky",
  },
  {
    postal_code: "19939",
    zip_name: "dagsboro, de",
  },
  {
    postal_code: "64062",
    zip_name: "lawson, mo",
  },
  {
    postal_code: "92536",
    zip_name: "aguanga, ca",
  },
  {
    postal_code: "79027",
    zip_name: "dimmitt, tx",
  },
  {
    postal_code: "11930",
    zip_name: "amagansett, ny",
  },
  {
    postal_code: "17821",
    zip_name: "danville, pa",
  },
  {
    postal_code: "72661",
    zip_name: "oakland, ar",
  },
  {
    postal_code: "87552",
    zip_name: "pecos, nm",
  },
  {
    postal_code: "2090",
    zip_name: "westwood, ma",
  },
  {
    postal_code: "56330",
    zip_name: "foreston, mn",
  },
  {
    postal_code: "78264",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "65452",
    zip_name: "crocker, mo",
  },
  {
    postal_code: "65775",
    zip_name: "west plains, mo",
  },
  {
    postal_code: "1833",
    zip_name: "georgetown, ma",
  },
  {
    postal_code: "32054",
    zip_name: "lake butler, fl",
  },
  {
    postal_code: "12815",
    zip_name: "brant lake, ny",
  },
  {
    postal_code: "41139",
    zip_name: "flatwoods, ky",
  },
  {
    postal_code: "98640",
    zip_name: "ocean park, wa",
  },
  {
    postal_code: "67422",
    zip_name: "bennington, ks",
  },
  {
    postal_code: "27052",
    zip_name: "walnut cove, nc",
  },
  {
    postal_code: "75929",
    zip_name: "broaddus, tx",
  },
  {
    postal_code: "22427",
    zip_name: "bowling green, va",
  },
  {
    postal_code: "56655",
    zip_name: "longville, mn",
  },
  {
    postal_code: "19970",
    zip_name: "ocean view, de",
  },
  {
    postal_code: "30633",
    zip_name: "danielsville, ga",
  },
  {
    postal_code: "84333",
    zip_name: "richmond, ut",
  },
  {
    postal_code: "21561",
    zip_name: "swanton, md",
  },
  {
    postal_code: "29682",
    zip_name: "six mile, sc",
  },
  {
    postal_code: "49022",
    zip_name: "benton harbor, mi",
  },
  {
    postal_code: "28740",
    zip_name: "green mountain, nc",
  },
  {
    postal_code: "28642",
    zip_name: "jonesville, nc",
  },
  {
    postal_code: "49746",
    zip_name: "hillman, mi",
  },
  {
    postal_code: "12155",
    zip_name: "schenevus, ny",
  },
  {
    postal_code: "61310",
    zip_name: "amboy, il",
  },
  {
    postal_code: "65550",
    zip_name: "newburg, mo",
  },
  {
    postal_code: "12928",
    zip_name: "crown point, ny",
  },
  {
    postal_code: "19043",
    zip_name: "holmes, pa",
  },
  {
    postal_code: "97801",
    zip_name: "pendleton, or",
  },
  {
    postal_code: "5773",
    zip_name: "wallingford, vt",
  },
  {
    postal_code: "41472",
    zip_name: "west liberty, ky",
  },
  {
    postal_code: "63377",
    zip_name: "silex, mo",
  },
  {
    postal_code: "90304",
    zip_name: "inglewood, ca",
  },
  {
    postal_code: "50057",
    zip_name: "columbia, ia",
  },
  {
    postal_code: "44615",
    zip_name: "carrollton, oh",
  },
  {
    postal_code: "12121",
    zip_name: "melrose, ny",
  },
  {
    postal_code: "49456",
    zip_name: "spring lake, mi",
  },
  {
    postal_code: "32180",
    zip_name: "pierson, fl",
  },
  {
    postal_code: "56283",
    zip_name: "redwood falls, mn",
  },
  {
    postal_code: "97223",
    zip_name: "portland, or",
  },
  {
    postal_code: "93609",
    zip_name: "caruthers, ca",
  },
  {
    postal_code: "71023",
    zip_name: "doyline, la",
  },
  {
    postal_code: "66013",
    zip_name: "bucyrus, ks",
  },
  {
    postal_code: "76264",
    zip_name: "sadler, tx",
  },
  {
    postal_code: "63347",
    zip_name: "foley, mo",
  },
  {
    postal_code: "11004",
    zip_name: "glen oaks, ny",
  },
  {
    postal_code: "38225",
    zip_name: "dresden, tn",
  },
  {
    postal_code: "48009",
    zip_name: "birmingham, mi",
  },
  {
    postal_code: "15623",
    zip_name: "claridge, pa",
  },
  {
    postal_code: "1475",
    zip_name: "winchendon, ma",
  },
  {
    postal_code: "13413",
    zip_name: "new hartford, ny",
  },
  {
    postal_code: "64747",
    zip_name: "garden city, mo",
  },
  {
    postal_code: "35062",
    zip_name: "dora, al",
  },
  {
    postal_code: "29696",
    zip_name: "west union, sc",
  },
  {
    postal_code: "6755",
    zip_name: "gaylordsville, ct",
  },
  {
    postal_code: "12115",
    zip_name: "malden bridge, ny",
  },
  {
    postal_code: "95814",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "54114",
    zip_name: "crivitz, wi",
  },
  {
    postal_code: "24556",
    zip_name: "goode, va",
  },
  {
    postal_code: "49120",
    zip_name: "niles, mi",
  },
  {
    postal_code: "96716",
    zip_name: "hanapepe, hi",
  },
  {
    postal_code: "42320",
    zip_name: "beaver dam, ky",
  },
  {
    postal_code: "97835",
    zip_name: "helix, or",
  },
  {
    postal_code: "39059",
    zip_name: "crystal springs, ms",
  },
  {
    postal_code: "48439",
    zip_name: "grand blanc, mi",
  },
  {
    postal_code: "62204",
    zip_name: "east saint louis, il",
  },
  {
    postal_code: "51521",
    zip_name: "avoca, ia",
  },
  {
    postal_code: "83822",
    zip_name: "oldtown, id",
  },
  {
    postal_code: "97068",
    zip_name: "west linn, or",
  },
  {
    postal_code: "43844",
    zip_name: "warsaw, oh",
  },
  {
    postal_code: "64054",
    zip_name: "independence, mo",
  },
  {
    postal_code: "29624",
    zip_name: "anderson, sc",
  },
  {
    postal_code: "40143",
    zip_name: "hardinsburg, ky",
  },
  {
    postal_code: "59034",
    zip_name: "hardin, mt",
  },
  {
    postal_code: "65737",
    zip_name: "reeds spring, mo",
  },
  {
    postal_code: "48348",
    zip_name: "clarkston, mi",
  },
  {
    postal_code: "28726",
    zip_name: "east flat rock, nc",
  },
  {
    postal_code: "39074",
    zip_name: "forest, ms",
  },
  {
    postal_code: "78570",
    zip_name: "mercedes, tx",
  },
  {
    postal_code: "74344",
    zip_name: "grove, ok",
  },
  {
    postal_code: "13797",
    zip_name: "lisle, ny",
  },
  {
    postal_code: "82070",
    zip_name: "laramie, wy",
  },
  {
    postal_code: "17512",
    zip_name: "columbia, pa",
  },
  {
    postal_code: "58540",
    zip_name: "garrison, nd",
  },
  {
    postal_code: "97070",
    zip_name: "wilsonville, or",
  },
  {
    postal_code: "98626",
    zip_name: "kelso, wa",
  },
  {
    postal_code: "16915",
    zip_name: "coudersport, pa",
  },
  {
    postal_code: "37658",
    zip_name: "hampton, tn",
  },
  {
    postal_code: "44907",
    zip_name: "mansfield, oh",
  },
  {
    postal_code: "71468",
    zip_name: "provencal, la",
  },
  {
    postal_code: "97112",
    zip_name: "cloverdale, or",
  },
  {
    postal_code: "80107",
    zip_name: "elizabeth, co",
  },
  {
    postal_code: "49117",
    zip_name: "new buffalo, mi",
  },
  {
    postal_code: "72126",
    zip_name: "perryville, ar",
  },
  {
    postal_code: "35151",
    zip_name: "sylacauga, al",
  },
  {
    postal_code: "68532",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "54486",
    zip_name: "tigerton, wi",
  },
  {
    postal_code: "29541",
    zip_name: "effingham, sc",
  },
  {
    postal_code: "67452",
    zip_name: "hunter, ks",
  },
  {
    postal_code: "61462",
    zip_name: "monmouth, il",
  },
  {
    postal_code: "17543",
    zip_name: "lititz, pa",
  },
  {
    postal_code: "51632",
    zip_name: "clarinda, ia",
  },
  {
    postal_code: "13685",
    zip_name: "sackets harbor, ny",
  },
  {
    postal_code: "13308",
    zip_name: "blossvale, ny",
  },
  {
    postal_code: "2119",
    zip_name: "roxbury, ma",
  },
  {
    postal_code: "78405",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "13083",
    zip_name: "lacona, ny",
  },
  {
    postal_code: "78941",
    zip_name: "flatonia, tx",
  },
  {
    postal_code: "2539",
    zip_name: "edgartown, ma",
  },
  {
    postal_code: "23223",
    zip_name: "richmond, va",
  },
  {
    postal_code: "75839",
    zip_name: "elkhart, tx",
  },
  {
    postal_code: "67637",
    zip_name: "ellis, ks",
  },
  {
    postal_code: "13142",
    zip_name: "pulaski, ny",
  },
  {
    postal_code: "27801",
    zip_name: "rocky mount, nc",
  },
  {
    postal_code: "80205",
    zip_name: "denver, co",
  },
  {
    postal_code: "61540",
    zip_name: "lacon, il",
  },
  {
    postal_code: "22842",
    zip_name: "mount jackson, va",
  },
  {
    postal_code: "68463",
    zip_name: "weeping water, ne",
  },
  {
    postal_code: "93267",
    zip_name: "strathmore, ca",
  },
  {
    postal_code: "48819",
    zip_name: "dansville, mi",
  },
  {
    postal_code: "36250",
    zip_name: "alexandria, al",
  },
  {
    postal_code: "61536",
    zip_name: "hanna city, il",
  },
  {
    postal_code: "19549",
    zip_name: "port clinton, pa",
  },
  {
    postal_code: "27349",
    zip_name: "snow camp, nc",
  },
  {
    postal_code: "55709",
    zip_name: "bovey, mn",
  },
  {
    postal_code: "44401",
    zip_name: "berlin center, oh",
  },
  {
    postal_code: "24563",
    zip_name: "hurt, va",
  },
  {
    postal_code: "8349",
    zip_name: "port norris, nj",
  },
  {
    postal_code: "4862",
    zip_name: "union, me",
  },
  {
    postal_code: "12501",
    zip_name: "amenia, ny",
  },
  {
    postal_code: "44656",
    zip_name: "mineral city, oh",
  },
  {
    postal_code: "92130",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "78408",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "44062",
    zip_name: "middlefield, oh",
  },
  {
    postal_code: "93235",
    zip_name: "ivanhoe, ca",
  },
  {
    postal_code: "35085",
    zip_name: "jemison, al",
  },
  {
    postal_code: "59638",
    zip_name: "jefferson city, mt",
  },
  {
    postal_code: "61744",
    zip_name: "gridley, il",
  },
  {
    postal_code: "1854",
    zip_name: "lowell, ma",
  },
  {
    postal_code: "30137",
    zip_name: "emerson, ga",
  },
  {
    postal_code: "12165",
    zip_name: "spencertown, ny",
  },
  {
    postal_code: "56586",
    zip_name: "underwood, mn",
  },
  {
    postal_code: "28553",
    zip_name: "marshallberg, nc",
  },
  {
    postal_code: "43517",
    zip_name: "edgerton, oh",
  },
  {
    postal_code: "6068",
    zip_name: "salisbury, ct",
  },
  {
    postal_code: "51046",
    zip_name: "paullina, ia",
  },
  {
    postal_code: "63072",
    zip_name: "robertsville, mo",
  },
  {
    postal_code: "79529",
    zip_name: "knox city, tx",
  },
  {
    postal_code: "61272",
    zip_name: "new boston, il",
  },
  {
    postal_code: "59422",
    zip_name: "choteau, mt",
  },
  {
    postal_code: "24437",
    zip_name: "fort defiance, va",
  },
  {
    postal_code: "33476",
    zip_name: "pahokee, fl",
  },
  {
    postal_code: "3845",
    zip_name: "intervale, nh",
  },
  {
    postal_code: "95464",
    zip_name: "nice, ca",
  },
  {
    postal_code: "12916",
    zip_name: "brushton, ny",
  },
  {
    postal_code: "43804",
    zip_name: "baltic, oh",
  },
  {
    postal_code: "40109",
    zip_name: "brooks, ky",
  },
  {
    postal_code: "65772",
    zip_name: "washburn, mo",
  },
  {
    postal_code: "28125",
    zip_name: "mount ulla, nc",
  },
  {
    postal_code: "62974",
    zip_name: "pittsburg, il",
  },
  {
    postal_code: "46506",
    zip_name: "bremen, in",
  },
  {
    postal_code: "5301",
    zip_name: "brattleboro, vt",
  },
  {
    postal_code: "27050",
    zip_name: "tobaccoville, nc",
  },
  {
    postal_code: "26405",
    zip_name: "moatsville, wv",
  },
  {
    postal_code: "43793",
    zip_name: "woodsfield, oh",
  },
  {
    postal_code: "18834",
    zip_name: "new milford, pa",
  },
  {
    postal_code: "43780",
    zip_name: "senecaville, oh",
  },
  {
    postal_code: "13406",
    zip_name: "middleville, ny",
  },
  {
    postal_code: "55389",
    zip_name: "watkins, mn",
  },
  {
    postal_code: "40068",
    zip_name: "smithfield, ky",
  },
  {
    postal_code: "84320",
    zip_name: "lewiston, ut",
  },
  {
    postal_code: "3260",
    zip_name: "north sutton, nh",
  },
  {
    postal_code: "13348",
    zip_name: "hartwick, ny",
  },
  {
    postal_code: "46068",
    zip_name: "sharpsville, in",
  },
  {
    postal_code: "28040",
    zip_name: "ellenboro, nc",
  },
  {
    postal_code: "14225",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "20158",
    zip_name: "hamilton, va",
  },
  {
    postal_code: "97814",
    zip_name: "baker city, or",
  },
  {
    postal_code: "61277",
    zip_name: "prophetstown, il",
  },
  {
    postal_code: "43067",
    zip_name: "raymond, oh",
  },
  {
    postal_code: "48428",
    zip_name: "dryden, mi",
  },
  {
    postal_code: "72081",
    zip_name: "judsonia, ar",
  },
  {
    postal_code: "30733",
    zip_name: "plainville, ga",
  },
  {
    postal_code: "19330",
    zip_name: "cochranville, pa",
  },
  {
    postal_code: "2881",
    zip_name: "kingston, ri",
  },
  {
    postal_code: "56088",
    zip_name: "truman, mn",
  },
  {
    postal_code: "48041",
    zip_name: "memphis, mi",
  },
  {
    postal_code: "7827",
    zip_name: "montague, nj",
  },
  {
    postal_code: "53530",
    zip_name: "darlington, wi",
  },
  {
    postal_code: "80759",
    zip_name: "yuma, co",
  },
  {
    postal_code: "61232",
    zip_name: "andalusia, il",
  },
  {
    postal_code: "38860",
    zip_name: "okolona, ms",
  },
  {
    postal_code: "56352",
    zip_name: "melrose, mn",
  },
  {
    postal_code: "12094",
    zip_name: "johnsonville, ny",
  },
  {
    postal_code: "37843",
    zip_name: "parrottsville, tn",
  },
  {
    postal_code: "87549",
    zip_name: "ojo caliente, nm",
  },
  {
    postal_code: "4971",
    zip_name: "saint albans, me",
  },
  {
    postal_code: "24230",
    zip_name: "coeburn, va",
  },
  {
    postal_code: "62067",
    zip_name: "moro, il",
  },
  {
    postal_code: "30565",
    zip_name: "nicholson, ga",
  },
  {
    postal_code: "80520",
    zip_name: "firestone, co",
  },
  {
    postal_code: "66070",
    zip_name: "ozawkie, ks",
  },
  {
    postal_code: "30735",
    zip_name: "resaca, ga",
  },
  {
    postal_code: "78409",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "43080",
    zip_name: "utica, oh",
  },
  {
    postal_code: "56570",
    zip_name: "osage, mn",
  },
  {
    postal_code: "28523",
    zip_name: "cove city, nc",
  },
  {
    postal_code: "83846",
    zip_name: "mullan, id",
  },
  {
    postal_code: "97026",
    zip_name: "gervais, or",
  },
  {
    postal_code: "3043",
    zip_name: "francestown, nh",
  },
  {
    postal_code: "8326",
    zip_name: "landisville, nj",
  },
  {
    postal_code: "85637",
    zip_name: "sonoita, az",
  },
  {
    postal_code: "84526",
    zip_name: "helper, ut",
  },
  {
    postal_code: "95977",
    zip_name: "smartsville, ca",
  },
  {
    postal_code: "4648",
    zip_name: "jonesboro, me",
  },
  {
    postal_code: "95919",
    zip_name: "brownsville, ca",
  },
  {
    postal_code: "62265",
    zip_name: "new baden, il",
  },
  {
    postal_code: "57532",
    zip_name: "fort pierre, sd",
  },
  {
    postal_code: "54722",
    zip_name: "augusta, wi",
  },
  {
    postal_code: "3903",
    zip_name: "eliot, me",
  },
  {
    postal_code: "43466",
    zip_name: "wayne, oh",
  },
  {
    postal_code: "75850",
    zip_name: "leona, tx",
  },
  {
    postal_code: "58759",
    zip_name: "max, nd",
  },
  {
    postal_code: "43150",
    zip_name: "rushville, oh",
  },
  {
    postal_code: "25234",
    zip_name: "arnoldsburg, wv",
  },
  {
    postal_code: "50439",
    zip_name: "goodell, ia",
  },
  {
    postal_code: "12185",
    zip_name: "valley falls, ny",
  },
  {
    postal_code: "62924",
    zip_name: "de soto, il",
  },
  {
    postal_code: "80535",
    zip_name: "laporte, co",
  },
  {
    postal_code: "53543",
    zip_name: "highland, wi",
  },
  {
    postal_code: "2466",
    zip_name: "auburndale, ma",
  },
  {
    postal_code: "84754",
    zip_name: "monroe, ut",
  },
  {
    postal_code: "24614",
    zip_name: "grundy, va",
  },
  {
    postal_code: "15655",
    zip_name: "laughlintown, pa",
  },
  {
    postal_code: "30678",
    zip_name: "white plains, ga",
  },
  {
    postal_code: "51542",
    zip_name: "honey creek, ia",
  },
  {
    postal_code: "91203",
    zip_name: "glendale, ca",
  },
  {
    postal_code: "84646",
    zip_name: "moroni, ut",
  },
  {
    postal_code: "93721",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "5663",
    zip_name: "northfield, vt",
  },
  {
    postal_code: "51653",
    zip_name: "tabor, ia",
  },
  {
    postal_code: "56062",
    zip_name: "madelia, mn",
  },
  {
    postal_code: "65287",
    zip_name: "wooldridge, mo",
  },
  {
    postal_code: "15675",
    zip_name: "penn, pa",
  },
  {
    postal_code: "15928",
    zip_name: "davidsville, pa",
  },
  {
    postal_code: "1463",
    zip_name: "pepperell, ma",
  },
  {
    postal_code: "69033",
    zip_name: "imperial, ne",
  },
  {
    postal_code: "78025",
    zip_name: "ingram, tx",
  },
  {
    postal_code: "39669",
    zip_name: "woodville, ms",
  },
  {
    postal_code: "1746",
    zip_name: "holliston, ma",
  },
  {
    postal_code: "37818",
    zip_name: "mosheim, tn",
  },
  {
    postal_code: "54768",
    zip_name: "stanley, wi",
  },
  {
    postal_code: "28364",
    zip_name: "maxton, nc",
  },
  {
    postal_code: "18074",
    zip_name: "perkiomenville, pa",
  },
  {
    postal_code: "12542",
    zip_name: "marlboro, ny",
  },
  {
    postal_code: "92102",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "70533",
    zip_name: "erath, la",
  },
  {
    postal_code: "10006",
    zip_name: "new york, ny",
  },
  {
    postal_code: "1450",
    zip_name: "groton, ma",
  },
  {
    postal_code: "65739",
    zip_name: "ridgedale, mo",
  },
  {
    postal_code: "99173",
    zip_name: "springdale, wa",
  },
  {
    postal_code: "47234",
    zip_name: "flat rock, in",
  },
  {
    postal_code: "48145",
    zip_name: "la salle, mi",
  },
  {
    postal_code: "36025",
    zip_name: "elmore, al",
  },
  {
    postal_code: "4358",
    zip_name: "south china, me",
  },
  {
    postal_code: "3442",
    zip_name: "bennington, nh",
  },
  {
    postal_code: "59759",
    zip_name: "whitehall, mt",
  },
  {
    postal_code: "18704",
    zip_name: "kingston, pa",
  },
  {
    postal_code: "24526",
    zip_name: "big island, va",
  },
  {
    postal_code: "55985",
    zip_name: "west concord, mn",
  },
  {
    postal_code: "12847",
    zip_name: "long lake, ny",
  },
  {
    postal_code: "14020",
    zip_name: "batavia, ny",
  },
  {
    postal_code: "11416",
    zip_name: "ozone park, ny",
  },
  {
    postal_code: "46044",
    zip_name: "frankton, in",
  },
  {
    postal_code: "25123",
    zip_name: "leon, wv",
  },
  {
    postal_code: "85539",
    zip_name: "miami, az",
  },
  {
    postal_code: "75422",
    zip_name: "campbell, tx",
  },
  {
    postal_code: "98388",
    zip_name: "steilacoom, wa",
  },
  {
    postal_code: "26047",
    zip_name: "new cumberland, wv",
  },
  {
    postal_code: "50250",
    zip_name: "stuart, ia",
  },
  {
    postal_code: "60072",
    zip_name: "ringwood, il",
  },
  {
    postal_code: "68319",
    zip_name: "bradshaw, ne",
  },
  {
    postal_code: "44606",
    zip_name: "apple creek, oh",
  },
  {
    postal_code: "45869",
    zip_name: "new bremen, oh",
  },
  {
    postal_code: "1529",
    zip_name: "millville, ma",
  },
  {
    postal_code: "55940",
    zip_name: "hayfield, mn",
  },
  {
    postal_code: "67333",
    zip_name: "caney, ks",
  },
  {
    postal_code: "32009",
    zip_name: "bryceville, fl",
  },
  {
    postal_code: "3844",
    zip_name: "hampton falls, nh",
  },
  {
    postal_code: "62009",
    zip_name: "benld, il",
  },
  {
    postal_code: "54534",
    zip_name: "hurley, wi",
  },
  {
    postal_code: "95664",
    zip_name: "pilot hill, ca",
  },
  {
    postal_code: "45052",
    zip_name: "north bend, oh",
  },
  {
    postal_code: "45003",
    zip_name: "college corner, oh",
  },
  {
    postal_code: "55052",
    zip_name: "morristown, mn",
  },
  {
    postal_code: "81069",
    zip_name: "rye, co",
  },
  {
    postal_code: "18810",
    zip_name: "athens, pa",
  },
  {
    postal_code: "53561",
    zip_name: "merrimac, wi",
  },
  {
    postal_code: "85288",
    zip_name: "tempe, az",
  },
  {
    postal_code: "65682",
    zip_name: "lockwood, mo",
  },
  {
    postal_code: "49229",
    zip_name: "britton, mi",
  },
  {
    postal_code: "29581",
    zip_name: "nichols, sc",
  },
  {
    postal_code: "37810",
    zip_name: "mohawk, tn",
  },
  {
    postal_code: "35178",
    zip_name: "vincent, al",
  },
  {
    postal_code: "44629",
    zip_name: "gnadenhutten, oh",
  },
  {
    postal_code: "29702",
    zip_name: "blacksburg, sc",
  },
  {
    postal_code: "44431",
    zip_name: "leetonia, oh",
  },
  {
    postal_code: "45303",
    zip_name: "ansonia, oh",
  },
  {
    postal_code: "14865",
    zip_name: "montour falls, ny",
  },
  {
    postal_code: "48857",
    zip_name: "morrice, mi",
  },
  {
    postal_code: "25545",
    zip_name: "ona, wv",
  },
  {
    postal_code: "15552",
    zip_name: "meyersdale, pa",
  },
  {
    postal_code: "62060",
    zip_name: "madison, il",
  },
  {
    postal_code: "65632",
    zip_name: "conway, mo",
  },
  {
    postal_code: "4847",
    zip_name: "hope, me",
  },
  {
    postal_code: "64866",
    zip_name: "stark city, mo",
  },
  {
    postal_code: "54121",
    zip_name: "florence, wi",
  },
  {
    postal_code: "54724",
    zip_name: "bloomer, wi",
  },
  {
    postal_code: "29067",
    zip_name: "kershaw, sc",
  },
  {
    postal_code: "4429",
    zip_name: "holden, me",
  },
  {
    postal_code: "7401",
    zip_name: "allendale, nj",
  },
  {
    postal_code: "28609",
    zip_name: "catawba, nc",
  },
  {
    postal_code: "53944",
    zip_name: "lyndon station, wi",
  },
  {
    postal_code: "13332",
    zip_name: "earlville, ny",
  },
  {
    postal_code: "38851",
    zip_name: "houston, ms",
  },
  {
    postal_code: "2474",
    zip_name: "arlington, ma",
  },
  {
    postal_code: "54820",
    zip_name: "brule, wi",
  },
  {
    postal_code: "63822",
    zip_name: "bernie, mo",
  },
  {
    postal_code: "3874",
    zip_name: "seabrook, nh",
  },
  {
    postal_code: "10456",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "56026",
    zip_name: "ellendale, mn",
  },
  {
    postal_code: "38067",
    zip_name: "saulsbury, tn",
  },
  {
    postal_code: "90056",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "95324",
    zip_name: "hilmar, ca",
  },
  {
    postal_code: "94925",
    zip_name: "corte madera, ca",
  },
  {
    postal_code: "37853",
    zip_name: "rockford, tn",
  },
  {
    postal_code: "63382",
    zip_name: "vandalia, mo",
  },
  {
    postal_code: "64070",
    zip_name: "lone jack, mo",
  },
  {
    postal_code: "47220",
    zip_name: "brownstown, in",
  },
  {
    postal_code: "45858",
    zip_name: "mc comb, oh",
  },
  {
    postal_code: "40142",
    zip_name: "guston, ky",
  },
  {
    postal_code: "43944",
    zip_name: "richmond, oh",
  },
  {
    postal_code: "45308",
    zip_name: "bradford, oh",
  },
  {
    postal_code: "84520",
    zip_name: "east carbon, ut",
  },
  {
    postal_code: "49233",
    zip_name: "cement city, mi",
  },
  {
    postal_code: "54610",
    zip_name: "alma, wi",
  },
  {
    postal_code: "1969",
    zip_name: "rowley, ma",
  },
  {
    postal_code: "12057",
    zip_name: "eagle bridge, ny",
  },
  {
    postal_code: "44904",
    zip_name: "mansfield, oh",
  },
  {
    postal_code: "64856",
    zip_name: "pineville, mo",
  },
  {
    postal_code: "98591",
    zip_name: "toledo, wa",
  },
  {
    postal_code: "44280",
    zip_name: "valley city, oh",
  },
  {
    postal_code: "53039",
    zip_name: "juneau, wi",
  },
  {
    postal_code: "1235",
    zip_name: "hinsdale, ma",
  },
  {
    postal_code: "2538",
    zip_name: "east wareham, ma",
  },
  {
    postal_code: "61723",
    zip_name: "atlanta, il",
  },
  {
    postal_code: "3461",
    zip_name: "rindge, nh",
  },
  {
    postal_code: "24102",
    zip_name: "henry, va",
  },
  {
    postal_code: "49113",
    zip_name: "galien, mi",
  },
  {
    postal_code: "4543",
    zip_name: "damariscotta, me",
  },
  {
    postal_code: "4555",
    zip_name: "nobleboro, me",
  },
  {
    postal_code: "3851",
    zip_name: "milton, nh",
  },
  {
    postal_code: "17935",
    zip_name: "girardville, pa",
  },
  {
    postal_code: "11356",
    zip_name: "college point, ny",
  },
  {
    postal_code: "79068",
    zip_name: "panhandle, tx",
  },
  {
    postal_code: "12147",
    zip_name: "rensselaerville, ny",
  },
  {
    postal_code: "75833",
    zip_name: "centerville, tx",
  },
  {
    postal_code: "35952",
    zip_name: "altoona, al",
  },
  {
    postal_code: "81624",
    zip_name: "collbran, co",
  },
  {
    postal_code: "49635",
    zip_name: "frankfort, mi",
  },
  {
    postal_code: "95046",
    zip_name: "san martin, ca",
  },
  {
    postal_code: "12062",
    zip_name: "east nassau, ny",
  },
  {
    postal_code: "65565",
    zip_name: "steelville, mo",
  },
  {
    postal_code: "78947",
    zip_name: "lexington, tx",
  },
  {
    postal_code: "62635",
    zip_name: "emden, il",
  },
  {
    postal_code: "32102",
    zip_name: "astor, fl",
  },
  {
    postal_code: "42327",
    zip_name: "calhoun, ky",
  },
  {
    postal_code: "33585",
    zip_name: "sumterville, fl",
  },
  {
    postal_code: "63361",
    zip_name: "montgomery city, mo",
  },
  {
    postal_code: "2170",
    zip_name: "quincy, ma",
  },
  {
    postal_code: "32187",
    zip_name: "san mateo, fl",
  },
  {
    postal_code: "25015",
    zip_name: "belle, wv",
  },
  {
    postal_code: "46555",
    zip_name: "north webster, in",
  },
  {
    postal_code: "80515",
    zip_name: "drake, co",
  },
  {
    postal_code: "94005",
    zip_name: "brisbane, ca",
  },
  {
    postal_code: "2481",
    zip_name: "wellesley hills, ma",
  },
  {
    postal_code: "43162",
    zip_name: "west jefferson, oh",
  },
  {
    postal_code: "54896",
    zip_name: "winter, wi",
  },
  {
    postal_code: "8555",
    zip_name: "roosevelt, nj",
  },
  {
    postal_code: "2659",
    zip_name: "south chatham, ma",
  },
  {
    postal_code: "23084",
    zip_name: "kents store, va",
  },
  {
    postal_code: "5855",
    zip_name: "newport, vt",
  },
  {
    postal_code: "15233",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "47948",
    zip_name: "goodland, in",
  },
  {
    postal_code: "45389",
    zip_name: "christiansburg, oh",
  },
  {
    postal_code: "54725",
    zip_name: "boyceville, wi",
  },
  {
    postal_code: "12525",
    zip_name: "gardiner, ny",
  },
  {
    postal_code: "35054",
    zip_name: "cropwell, al",
  },
  {
    postal_code: "54531",
    zip_name: "hazelhurst, wi",
  },
  {
    postal_code: "98546",
    zip_name: "grapeview, wa",
  },
  {
    postal_code: "60195",
    zip_name: "schaumburg, il",
  },
  {
    postal_code: "3255",
    zip_name: "newbury, nh",
  },
  {
    postal_code: "11955",
    zip_name: "moriches, ny",
  },
  {
    postal_code: "20903",
    zip_name: "silver spring, md",
  },
  {
    postal_code: "86436",
    zip_name: "topock, az",
  },
  {
    postal_code: "17084",
    zip_name: "reedsville, pa",
  },
  {
    postal_code: "75433",
    zip_name: "cumby, tx",
  },
  {
    postal_code: "68339",
    zip_name: "denton, ne",
  },
  {
    postal_code: "28366",
    zip_name: "newton grove, nc",
  },
  {
    postal_code: "29431",
    zip_name: "bonneau, sc",
  },
  {
    postal_code: "12422",
    zip_name: "durham, ny",
  },
  {
    postal_code: "80133",
    zip_name: "palmer lake, co",
  },
  {
    postal_code: "4927",
    zip_name: "clinton, me",
  },
  {
    postal_code: "44451",
    zip_name: "north jackson, oh",
  },
  {
    postal_code: "65013",
    zip_name: "belle, mo",
  },
  {
    postal_code: "1129",
    zip_name: "springfield, ma",
  },
  {
    postal_code: "85342",
    zip_name: "morristown, az",
  },
  {
    postal_code: "27299",
    zip_name: "linwood, nc",
  },
  {
    postal_code: "13074",
    zip_name: "hannibal, ny",
  },
  {
    postal_code: "49807",
    zip_name: "bark river, mi",
  },
  {
    postal_code: "44846",
    zip_name: "milan, oh",
  },
  {
    postal_code: "42724",
    zip_name: "cecilia, ky",
  },
  {
    postal_code: "63344",
    zip_name: "eolia, mo",
  },
  {
    postal_code: "25320",
    zip_name: "charleston, wv",
  },
  {
    postal_code: "37190",
    zip_name: "woodbury, tn",
  },
  {
    postal_code: "14069",
    zip_name: "glenwood, ny",
  },
  {
    postal_code: "56044",
    zip_name: "henderson, mn",
  },
  {
    postal_code: "55353",
    zip_name: "kimball, mn",
  },
  {
    postal_code: "13746",
    zip_name: "chenango forks, ny",
  },
  {
    postal_code: "50674",
    zip_name: "sumner, ia",
  },
  {
    postal_code: "16947",
    zip_name: "troy, pa",
  },
  {
    postal_code: "74116",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "17402",
    zip_name: "york, pa",
  },
  {
    postal_code: "93646",
    zip_name: "orange cove, ca",
  },
  {
    postal_code: "58638",
    zip_name: "hebron, nd",
  },
  {
    postal_code: "57759",
    zip_name: "nemo, sd",
  },
  {
    postal_code: "62061",
    zip_name: "marine, il",
  },
  {
    postal_code: "15686",
    zip_name: "spring church, pa",
  },
  {
    postal_code: "1331",
    zip_name: "athol, ma",
  },
  {
    postal_code: "76271",
    zip_name: "tioga, tx",
  },
  {
    postal_code: "53820",
    zip_name: "potosi, wi",
  },
  {
    postal_code: "95987",
    zip_name: "williams, ca",
  },
  {
    postal_code: "53516",
    zip_name: "blanchardville, wi",
  },
  {
    postal_code: "3451",
    zip_name: "hinsdale, nh",
  },
  {
    postal_code: "28454",
    zip_name: "maple hill, nc",
  },
  {
    postal_code: "95012",
    zip_name: "castroville, ca",
  },
  {
    postal_code: "44880",
    zip_name: "sullivan, oh",
  },
  {
    postal_code: "81143",
    zip_name: "moffat, co",
  },
  {
    postal_code: "15646",
    zip_name: "jones mills, pa",
  },
  {
    postal_code: "15473",
    zip_name: "perryopolis, pa",
  },
  {
    postal_code: "48445",
    zip_name: "kinde, mi",
  },
  {
    postal_code: "5465",
    zip_name: "jericho, vt",
  },
  {
    postal_code: "95460",
    zip_name: "mendocino, ca",
  },
  {
    postal_code: "38366",
    zip_name: "pinson, tn",
  },
  {
    postal_code: "98536",
    zip_name: "copalis crossing, wa",
  },
  {
    postal_code: "16663",
    zip_name: "morann, pa",
  },
  {
    postal_code: "65723",
    zip_name: "pierce city, mo",
  },
  {
    postal_code: "67232",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "28525",
    zip_name: "deep run, nc",
  },
  {
    postal_code: "53955",
    zip_name: "poynette, wi",
  },
  {
    postal_code: "16851",
    zip_name: "lemont, pa",
  },
  {
    postal_code: "42351",
    zip_name: "lewisport, ky",
  },
  {
    postal_code: "19029",
    zip_name: "essington, pa",
  },
  {
    postal_code: "12958",
    zip_name: "mooers, ny",
  },
  {
    postal_code: "19372",
    zip_name: "thorndale, pa",
  },
  {
    postal_code: "56264",
    zip_name: "minneota, mn",
  },
  {
    postal_code: "75969",
    zip_name: "pollok, tx",
  },
  {
    postal_code: "83210",
    zip_name: "aberdeen, id",
  },
  {
    postal_code: "67107",
    zip_name: "moundridge, ks",
  },
  {
    postal_code: "63801",
    zip_name: "sikeston, mo",
  },
  {
    postal_code: "42717",
    zip_name: "burkesville, ky",
  },
  {
    postal_code: "49908",
    zip_name: "baraga, mi",
  },
  {
    postal_code: "72626",
    zip_name: "cotter, ar",
  },
  {
    postal_code: "78616",
    zip_name: "dale, tx",
  },
  {
    postal_code: "95935",
    zip_name: "dobbins, ca",
  },
  {
    postal_code: "28745",
    zip_name: "lake junaluska, nc",
  },
  {
    postal_code: "73939",
    zip_name: "goodwell, ok",
  },
  {
    postal_code: "74825",
    zip_name: "allen, ok",
  },
  {
    postal_code: "56281",
    zip_name: "prinsburg, mn",
  },
  {
    postal_code: "54749",
    zip_name: "knapp, wi",
  },
  {
    postal_code: "40370",
    zip_name: "sadieville, ky",
  },
  {
    postal_code: "63349",
    zip_name: "hawk point, mo",
  },
  {
    postal_code: "67579",
    zip_name: "sterling, ks",
  },
  {
    postal_code: "18465",
    zip_name: "thompson, pa",
  },
  {
    postal_code: "1776",
    zip_name: "sudbury, ma",
  },
  {
    postal_code: "72638",
    zip_name: "green forest, ar",
  },
  {
    postal_code: "80401",
    zip_name: "golden, co",
  },
  {
    postal_code: "97055",
    zip_name: "sandy, or",
  },
  {
    postal_code: "4578",
    zip_name: "wiscasset, me",
  },
  {
    postal_code: "1581",
    zip_name: "westborough, ma",
  },
  {
    postal_code: "35217",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "10950",
    zip_name: "monroe, ny",
  },
  {
    postal_code: "12502",
    zip_name: "ancram, ny",
  },
  {
    postal_code: "21915",
    zip_name: "chesapeake city, md",
  },
  {
    postal_code: "95311",
    zip_name: "coulterville, ca",
  },
  {
    postal_code: "55047",
    zip_name: "marine on saint croix, mn",
  },
  {
    postal_code: "55921",
    zip_name: "caledonia, mn",
  },
  {
    postal_code: "7853",
    zip_name: "long valley, nj",
  },
  {
    postal_code: "92103",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "2670",
    zip_name: "west dennis, ma",
  },
  {
    postal_code: "1879",
    zip_name: "tyngsboro, ma",
  },
  {
    postal_code: "61375",
    zip_name: "varna, il",
  },
  {
    postal_code: "72201",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "13787",
    zip_name: "harpursville, ny",
  },
  {
    postal_code: "97402",
    zip_name: "eugene, or",
  },
  {
    postal_code: "65360",
    zip_name: "windsor, mo",
  },
  {
    postal_code: "42544",
    zip_name: "nancy, ky",
  },
  {
    postal_code: "1082",
    zip_name: "ware, ma",
  },
  {
    postal_code: "70364",
    zip_name: "houma, la",
  },
  {
    postal_code: "66502",
    zip_name: "manhattan, ks",
  },
  {
    postal_code: "1850",
    zip_name: "lowell, ma",
  },
  {
    postal_code: "2191",
    zip_name: "north weymouth, ma",
  },
  {
    postal_code: "56449",
    zip_name: "fort ripley, mn",
  },
  {
    postal_code: "50156",
    zip_name: "madrid, ia",
  },
  {
    postal_code: "47006",
    zip_name: "batesville, in",
  },
  {
    postal_code: "43570",
    zip_name: "west unity, oh",
  },
  {
    postal_code: "56339",
    zip_name: "hoffman, mn",
  },
  {
    postal_code: "53525",
    zip_name: "clinton, wi",
  },
  {
    postal_code: "35150",
    zip_name: "sylacauga, al",
  },
  {
    postal_code: "22642",
    zip_name: "linden, va",
  },
  {
    postal_code: "6890",
    zip_name: "southport, ct",
  },
  {
    postal_code: "1588",
    zip_name: "whitinsville, ma",
  },
  {
    postal_code: "15926",
    zip_name: "central city, pa",
  },
  {
    postal_code: "64834",
    zip_name: "carl junction, mo",
  },
  {
    postal_code: "64507",
    zip_name: "saint joseph, mo",
  },
  {
    postal_code: "19030",
    zip_name: "fairless hills, pa",
  },
  {
    postal_code: "94508",
    zip_name: "angwin, ca",
  },
  {
    postal_code: "49249",
    zip_name: "jerome, mi",
  },
  {
    postal_code: "2171",
    zip_name: "quincy, ma",
  },
  {
    postal_code: "19056",
    zip_name: "levittown, pa",
  },
  {
    postal_code: "11379",
    zip_name: "middle village, ny",
  },
  {
    postal_code: "21660",
    zip_name: "ridgely, md",
  },
  {
    postal_code: "54540",
    zip_name: "land o lakes, wi",
  },
  {
    postal_code: "42206",
    zip_name: "auburn, ky",
  },
  {
    postal_code: "13167",
    zip_name: "west monroe, ny",
  },
  {
    postal_code: "8757",
    zip_name: "toms river, nj",
  },
  {
    postal_code: "93523",
    zip_name: "edwards, ca",
  },
  {
    postal_code: "1527",
    zip_name: "millbury, ma",
  },
  {
    postal_code: "37887",
    zip_name: "wartburg, tn",
  },
  {
    postal_code: "54013",
    zip_name: "glenwood city, wi",
  },
  {
    postal_code: "48876",
    zip_name: "potterville, mi",
  },
  {
    postal_code: "50028",
    zip_name: "baxter, ia",
  },
  {
    postal_code: "2052",
    zip_name: "medfield, ma",
  },
  {
    postal_code: "53570",
    zip_name: "monticello, wi",
  },
  {
    postal_code: "1702",
    zip_name: "framingham, ma",
  },
  {
    postal_code: "49457",
    zip_name: "twin lake, mi",
  },
  {
    postal_code: "81328",
    zip_name: "mancos, co",
  },
  {
    postal_code: "48601",
    zip_name: "saginaw, mi",
  },
  {
    postal_code: "1568",
    zip_name: "upton, ma",
  },
  {
    postal_code: "17045",
    zip_name: "liverpool, pa",
  },
  {
    postal_code: "59072",
    zip_name: "roundup, mt",
  },
  {
    postal_code: "6442",
    zip_name: "ivoryton, ct",
  },
  {
    postal_code: "55741",
    zip_name: "gilbert, mn",
  },
  {
    postal_code: "45833",
    zip_name: "delphos, oh",
  },
  {
    postal_code: "95249",
    zip_name: "san andreas, ca",
  },
  {
    postal_code: "10550",
    zip_name: "mount vernon, ny",
  },
  {
    postal_code: "45159",
    zip_name: "new vienna, oh",
  },
  {
    postal_code: "92037",
    zip_name: "la jolla, ca",
  },
  {
    postal_code: "23917",
    zip_name: "boydton, va",
  },
  {
    postal_code: "89124",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "19115",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "93675",
    zip_name: "squaw valley, ca",
  },
  {
    postal_code: "2721",
    zip_name: "fall river, ma",
  },
  {
    postal_code: "8721",
    zip_name: "bayville, nj",
  },
  {
    postal_code: "17602",
    zip_name: "lancaster, pa",
  },
  {
    postal_code: "86024",
    zip_name: "happy jack, az",
  },
  {
    postal_code: "4250",
    zip_name: "lisbon, me",
  },
  {
    postal_code: "1108",
    zip_name: "springfield, ma",
  },
  {
    postal_code: "5860",
    zip_name: "orleans, vt",
  },
  {
    postal_code: "53809",
    zip_name: "fennimore, wi",
  },
  {
    postal_code: "5476",
    zip_name: "richford, vt",
  },
  {
    postal_code: "97388",
    zip_name: "gleneden beach, or",
  },
  {
    postal_code: "54177",
    zip_name: "wausaukee, wi",
  },
  {
    postal_code: "74010",
    zip_name: "bristow, ok",
  },
  {
    postal_code: "23149",
    zip_name: "saluda, va",
  },
  {
    postal_code: "14221",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "89440",
    zip_name: "virginia city, nv",
  },
  {
    postal_code: "8816",
    zip_name: "east brunswick, nj",
  },
  {
    postal_code: "8759",
    zip_name: "manchester township, nj",
  },
  {
    postal_code: "45732",
    zip_name: "glouster, oh",
  },
  {
    postal_code: "78390",
    zip_name: "taft, tx",
  },
  {
    postal_code: "4478",
    zip_name: "rockwood, me",
  },
  {
    postal_code: "45130",
    zip_name: "hamersville, oh",
  },
  {
    postal_code: "15144",
    zip_name: "springdale, pa",
  },
  {
    postal_code: "22469",
    zip_name: "hague, va",
  },
  {
    postal_code: "48195",
    zip_name: "southgate, mi",
  },
  {
    postal_code: "39194",
    zip_name: "yazoo city, ms",
  },
  {
    postal_code: "45692",
    zip_name: "wellston, oh",
  },
  {
    postal_code: "29555",
    zip_name: "johnsonville, sc",
  },
  {
    postal_code: "5735",
    zip_name: "castleton, vt",
  },
  {
    postal_code: "4101",
    zip_name: "portland, me",
  },
  {
    postal_code: "39216",
    zip_name: "jackson, ms",
  },
  {
    postal_code: "1603",
    zip_name: "worcester, ma",
  },
  {
    postal_code: "54153",
    zip_name: "oconto, wi",
  },
  {
    postal_code: "21223",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "88210",
    zip_name: "artesia, nm",
  },
  {
    postal_code: "4950",
    zip_name: "madison, me",
  },
  {
    postal_code: "4472",
    zip_name: "orland, me",
  },
  {
    postal_code: "13320",
    zip_name: "cherry valley, ny",
  },
  {
    postal_code: "20794",
    zip_name: "jessup, md",
  },
  {
    postal_code: "89419",
    zip_name: "lovelock, nv",
  },
  {
    postal_code: "70112",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "2081",
    zip_name: "walpole, ma",
  },
  {
    postal_code: "25508",
    zip_name: "chapmanville, wv",
  },
  {
    postal_code: "44423",
    zip_name: "hanoverton, oh",
  },
  {
    postal_code: "21562",
    zip_name: "westernport, md",
  },
  {
    postal_code: "1562",
    zip_name: "spencer, ma",
  },
  {
    postal_code: "22480",
    zip_name: "irvington, va",
  },
  {
    postal_code: "4679",
    zip_name: "southwest harbor, me",
  },
  {
    postal_code: "45784",
    zip_name: "vincent, oh",
  },
  {
    postal_code: "54771",
    zip_name: "thorp, wi",
  },
  {
    postal_code: "2492",
    zip_name: "needham, ma",
  },
  {
    postal_code: "29047",
    zip_name: "elloree, sc",
  },
  {
    postal_code: "3266",
    zip_name: "rumney, nh",
  },
  {
    postal_code: "42355",
    zip_name: "maceo, ky",
  },
  {
    postal_code: "91932",
    zip_name: "imperial beach, ca",
  },
  {
    postal_code: "20712",
    zip_name: "mount rainier, md",
  },
  {
    postal_code: "83123",
    zip_name: "la barge, wy",
  },
  {
    postal_code: "49619",
    zip_name: "brethren, mi",
  },
  {
    postal_code: "98555",
    zip_name: "lilliwaup, wa",
  },
  {
    postal_code: "35234",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "45054",
    zip_name: "oregonia, oh",
  },
  {
    postal_code: "48662",
    zip_name: "wheeler, mi",
  },
  {
    postal_code: "17363",
    zip_name: "stewartstown, pa",
  },
  {
    postal_code: "8110",
    zip_name: "pennsauken, nj",
  },
  {
    postal_code: "56572",
    zip_name: "pelican rapids, mn",
  },
  {
    postal_code: "38585",
    zip_name: "spencer, tn",
  },
  {
    postal_code: "3908",
    zip_name: "south berwick, me",
  },
  {
    postal_code: "12023",
    zip_name: "berne, ny",
  },
  {
    postal_code: "8002",
    zip_name: "cherry hill, nj",
  },
  {
    postal_code: "72364",
    zip_name: "marion, ar",
  },
  {
    postal_code: "65202",
    zip_name: "columbia, mo",
  },
  {
    postal_code: "79922",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "30629",
    zip_name: "comer, ga",
  },
  {
    postal_code: "14814",
    zip_name: "big flats, ny",
  },
  {
    postal_code: "61063",
    zip_name: "pecatonica, il",
  },
  {
    postal_code: "91915",
    zip_name: "chula vista, ca",
  },
  {
    postal_code: "27248",
    zip_name: "franklinville, nc",
  },
  {
    postal_code: "54156",
    zip_name: "pembine, wi",
  },
  {
    postal_code: "2054",
    zip_name: "millis, ma",
  },
  {
    postal_code: "6754",
    zip_name: "cornwall bridge, ct",
  },
  {
    postal_code: "22624",
    zip_name: "clear brook, va",
  },
  {
    postal_code: "46171",
    zip_name: "reelsville, in",
  },
  {
    postal_code: "76487",
    zip_name: "poolville, tx",
  },
  {
    postal_code: "61561",
    zip_name: "roanoke, il",
  },
  {
    postal_code: "76476",
    zip_name: "tolar, tx",
  },
  {
    postal_code: "45656",
    zip_name: "oak hill, oh",
  },
  {
    postal_code: "54562",
    zip_name: "three lakes, wi",
  },
  {
    postal_code: "1605",
    zip_name: "worcester, ma",
  },
  {
    postal_code: "28435",
    zip_name: "currie, nc",
  },
  {
    postal_code: "97149",
    zip_name: "neskowin, or",
  },
  {
    postal_code: "13731",
    zip_name: "andes, ny",
  },
  {
    postal_code: "54002",
    zip_name: "baldwin, wi",
  },
  {
    postal_code: "12059",
    zip_name: "east berne, ny",
  },
  {
    postal_code: "64061",
    zip_name: "kingsville, mo",
  },
  {
    postal_code: "37756",
    zip_name: "huntsville, tn",
  },
  {
    postal_code: "12513",
    zip_name: "claverack, ny",
  },
  {
    postal_code: "84642",
    zip_name: "manti, ut",
  },
  {
    postal_code: "2762",
    zip_name: "plainville, ma",
  },
  {
    postal_code: "48075",
    zip_name: "southfield, mi",
  },
  {
    postal_code: "55790",
    zip_name: "tower, mn",
  },
  {
    postal_code: "63133",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "74743",
    zip_name: "hugo, ok",
  },
  {
    postal_code: "19971",
    zip_name: "rehoboth beach, de",
  },
  {
    postal_code: "7444",
    zip_name: "pompton plains, nj",
  },
  {
    postal_code: "1504",
    zip_name: "blackstone, ma",
  },
  {
    postal_code: "21140",
    zip_name: "riva, md",
  },
  {
    postal_code: "52240",
    zip_name: "iowa city, ia",
  },
  {
    postal_code: "24293",
    zip_name: "wise, va",
  },
  {
    postal_code: "50115",
    zip_name: "guthrie center, ia",
  },
  {
    postal_code: "78024",
    zip_name: "hunt, tx",
  },
  {
    postal_code: "53147",
    zip_name: "lake geneva, wi",
  },
  {
    postal_code: "31047",
    zip_name: "kathleen, ga",
  },
  {
    postal_code: "78516",
    zip_name: "alamo, tx",
  },
  {
    postal_code: "63461",
    zip_name: "palmyra, mo",
  },
  {
    postal_code: "59037",
    zip_name: "huntley, mt",
  },
  {
    postal_code: "90040",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "23219",
    zip_name: "richmond, va",
  },
  {
    postal_code: "92036",
    zip_name: "julian, ca",
  },
  {
    postal_code: "54853",
    zip_name: "luck, wi",
  },
  {
    postal_code: "39083",
    zip_name: "hazlehurst, ms",
  },
  {
    postal_code: "28729",
    zip_name: "etowah, nc",
  },
  {
    postal_code: "28902",
    zip_name: "brasstown, nc",
  },
  {
    postal_code: "22454",
    zip_name: "dunnsville, va",
  },
  {
    postal_code: "48357",
    zip_name: "highland, mi",
  },
  {
    postal_code: "95689",
    zip_name: "volcano, ca",
  },
  {
    postal_code: "10708",
    zip_name: "bronxville, ny",
  },
  {
    postal_code: "70544",
    zip_name: "jeanerette, la",
  },
  {
    postal_code: "77547",
    zip_name: "galena park, tx",
  },
  {
    postal_code: "49509",
    zip_name: "wyoming, mi",
  },
  {
    postal_code: "13642",
    zip_name: "gouverneur, ny",
  },
  {
    postal_code: "5488",
    zip_name: "swanton, vt",
  },
  {
    postal_code: "2144",
    zip_name: "somerville, ma",
  },
  {
    postal_code: "62644",
    zip_name: "havana, il",
  },
  {
    postal_code: "23924",
    zip_name: "chase city, va",
  },
  {
    postal_code: "53104",
    zip_name: "bristol, wi",
  },
  {
    postal_code: "2186",
    zip_name: "milton, ma",
  },
  {
    postal_code: "34705",
    zip_name: "astatula, fl",
  },
  {
    postal_code: "41004",
    zip_name: "brooksville, ky",
  },
  {
    postal_code: "3032",
    zip_name: "auburn, nh",
  },
  {
    postal_code: "53586",
    zip_name: "shullsburg, wi",
  },
  {
    postal_code: "4742",
    zip_name: "fort fairfield, me",
  },
  {
    postal_code: "17554",
    zip_name: "mountville, pa",
  },
  {
    postal_code: "13166",
    zip_name: "weedsport, ny",
  },
  {
    postal_code: "6455",
    zip_name: "middlefield, ct",
  },
  {
    postal_code: "65350",
    zip_name: "smithton, mo",
  },
  {
    postal_code: "15936",
    zip_name: "hooversville, pa",
  },
  {
    postal_code: "41092",
    zip_name: "verona, ky",
  },
  {
    postal_code: "42553",
    zip_name: "science hill, ky",
  },
  {
    postal_code: "20608",
    zip_name: "aquasco, md",
  },
  {
    postal_code: "39145",
    zip_name: "pelahatchie, ms",
  },
  {
    postal_code: "50244",
    zip_name: "slater, ia",
  },
  {
    postal_code: "53569",
    zip_name: "montfort, wi",
  },
  {
    postal_code: "15557",
    zip_name: "rockwood, pa",
  },
  {
    postal_code: "95436",
    zip_name: "forestville, ca",
  },
  {
    postal_code: "51576",
    zip_name: "underwood, ia",
  },
  {
    postal_code: "63369",
    zip_name: "old monroe, mo",
  },
  {
    postal_code: "3224",
    zip_name: "canterbury, nh",
  },
  {
    postal_code: "44824",
    zip_name: "castalia, oh",
  },
  {
    postal_code: "93516",
    zip_name: "boron, ca",
  },
  {
    postal_code: "61533",
    zip_name: "glasford, il",
  },
  {
    postal_code: "12140",
    zip_name: "poestenkill, ny",
  },
  {
    postal_code: "55009",
    zip_name: "cannon falls, mn",
  },
  {
    postal_code: "11219",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "97004",
    zip_name: "beavercreek, or",
  },
  {
    postal_code: "59011",
    zip_name: "big timber, mt",
  },
  {
    postal_code: "93109",
    zip_name: "santa barbara, ca",
  },
  {
    postal_code: "77457",
    zip_name: "matagorda, tx",
  },
  {
    postal_code: "12567",
    zip_name: "pine plains, ny",
  },
  {
    postal_code: "2808",
    zip_name: "bradford, ri",
  },
  {
    postal_code: "67835",
    zip_name: "cimarron, ks",
  },
  {
    postal_code: "50166",
    zip_name: "milo, ia",
  },
  {
    postal_code: "54555",
    zip_name: "phillips, wi",
  },
  {
    postal_code: "44085",
    zip_name: "rome, oh",
  },
  {
    postal_code: "68069",
    zip_name: "waterloo, ne",
  },
  {
    postal_code: "29635",
    zip_name: "cleveland, sc",
  },
  {
    postal_code: "44637",
    zip_name: "killbuck, oh",
  },
  {
    postal_code: "8096",
    zip_name: "woodbury, nj",
  },
  {
    postal_code: "83287",
    zip_name: "fish haven, id",
  },
  {
    postal_code: "14424",
    zip_name: "canandaigua, ny",
  },
  {
    postal_code: "61944",
    zip_name: "paris, il",
  },
  {
    postal_code: "2180",
    zip_name: "stoneham, ma",
  },
  {
    postal_code: "93553",
    zip_name: "pearblossom, ca",
  },
  {
    postal_code: "78059",
    zip_name: "natalia, tx",
  },
  {
    postal_code: "21780",
    zip_name: "sabillasville, md",
  },
  {
    postal_code: "47043",
    zip_name: "vevay, in",
  },
  {
    postal_code: "48726",
    zip_name: "cass city, mi",
  },
  {
    postal_code: "14883",
    zip_name: "spencer, ny",
  },
  {
    postal_code: "48872",
    zip_name: "perry, mi",
  },
  {
    postal_code: "70092",
    zip_name: "violet, la",
  },
  {
    postal_code: "18052",
    zip_name: "whitehall, pa",
  },
  {
    postal_code: "2382",
    zip_name: "whitman, ma",
  },
  {
    postal_code: "12981",
    zip_name: "saranac, ny",
  },
  {
    postal_code: "37846",
    zip_name: "philadelphia, tn",
  },
  {
    postal_code: "29020",
    zip_name: "camden, sc",
  },
  {
    postal_code: "81635",
    zip_name: "parachute, co",
  },
  {
    postal_code: "4474",
    zip_name: "orrington, me",
  },
  {
    postal_code: "2110",
    zip_name: "boston, ma",
  },
  {
    postal_code: "13204",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "31719",
    zip_name: "americus, ga",
  },
  {
    postal_code: "98402",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "78629",
    zip_name: "gonzales, tx",
  },
  {
    postal_code: "92131",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "19605",
    zip_name: "reading, pa",
  },
  {
    postal_code: "98178",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "78652",
    zip_name: "manchaca, tx",
  },
  {
    postal_code: "57732",
    zip_name: "deadwood, sd",
  },
  {
    postal_code: "84021",
    zip_name: "duchesne, ut",
  },
  {
    postal_code: "16946",
    zip_name: "tioga, pa",
  },
  {
    postal_code: "49887",
    zip_name: "stephenson, mi",
  },
  {
    postal_code: "2562",
    zip_name: "sagamore beach, ma",
  },
  {
    postal_code: "98136",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "81419",
    zip_name: "hotchkiss, co",
  },
  {
    postal_code: "23043",
    zip_name: "deltaville, va",
  },
  {
    postal_code: "48446",
    zip_name: "lapeer, mi",
  },
  {
    postal_code: "48085",
    zip_name: "troy, mi",
  },
  {
    postal_code: "38313",
    zip_name: "beech bluff, tn",
  },
  {
    postal_code: "92127",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "2136",
    zip_name: "hyde park, ma",
  },
  {
    postal_code: "75966",
    zip_name: "newton, tx",
  },
  {
    postal_code: "3225",
    zip_name: "center barnstead, nh",
  },
  {
    postal_code: "42345",
    zip_name: "greenville, ky",
  },
  {
    postal_code: "81415",
    zip_name: "crawford, co",
  },
  {
    postal_code: "29631",
    zip_name: "clemson, sc",
  },
  {
    postal_code: "35133",
    zip_name: "remlap, al",
  },
  {
    postal_code: "18837",
    zip_name: "rome, pa",
  },
  {
    postal_code: "56241",
    zip_name: "granite falls, mn",
  },
  {
    postal_code: "6018",
    zip_name: "canaan, ct",
  },
  {
    postal_code: "8028",
    zip_name: "glassboro, nj",
  },
  {
    postal_code: "45806",
    zip_name: "lima, oh",
  },
  {
    postal_code: "19139",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "65324",
    zip_name: "climax springs, mo",
  },
  {
    postal_code: "13159",
    zip_name: "tully, ny",
  },
  {
    postal_code: "10037",
    zip_name: "new york, ny",
  },
  {
    postal_code: "55120",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "45865",
    zip_name: "minster, oh",
  },
  {
    postal_code: "61281",
    zip_name: "sherrard, il",
  },
  {
    postal_code: "56307",
    zip_name: "albany, mn",
  },
  {
    postal_code: "62930",
    zip_name: "eldorado, il",
  },
  {
    postal_code: "24528",
    zip_name: "brookneal, va",
  },
  {
    postal_code: "56057",
    zip_name: "le center, mn",
  },
  {
    postal_code: "22815",
    zip_name: "broadway, va",
  },
  {
    postal_code: "11001",
    zip_name: "floral park, ny",
  },
  {
    postal_code: "76270",
    zip_name: "sunset, tx",
  },
  {
    postal_code: "13431",
    zip_name: "poland, ny",
  },
  {
    postal_code: "63341",
    zip_name: "defiance, mo",
  },
  {
    postal_code: "77358",
    zip_name: "new waverly, tx",
  },
  {
    postal_code: "44847",
    zip_name: "monroeville, oh",
  },
  {
    postal_code: "19094",
    zip_name: "woodlyn, pa",
  },
  {
    postal_code: "94089",
    zip_name: "sunnyvale, ca",
  },
  {
    postal_code: "62890",
    zip_name: "thompsonville, il",
  },
  {
    postal_code: "3582",
    zip_name: "groveton, nh",
  },
  {
    postal_code: "54139",
    zip_name: "lena, wi",
  },
  {
    postal_code: "3287",
    zip_name: "wilmot, nh",
  },
  {
    postal_code: "49446",
    zip_name: "new era, mi",
  },
  {
    postal_code: "54812",
    zip_name: "barron, wi",
  },
  {
    postal_code: "75756",
    zip_name: "brownsboro, tx",
  },
  {
    postal_code: "96032",
    zip_name: "fort jones, ca",
  },
  {
    postal_code: "2035",
    zip_name: "foxboro, ma",
  },
  {
    postal_code: "14787",
    zip_name: "westfield, ny",
  },
  {
    postal_code: "48228",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "54840",
    zip_name: "grantsburg, wi",
  },
  {
    postal_code: "89444",
    zip_name: "wellington, nv",
  },
  {
    postal_code: "44127",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "61560",
    zip_name: "putnam, il",
  },
  {
    postal_code: "8009",
    zip_name: "berlin, nj",
  },
  {
    postal_code: "40347",
    zip_name: "midway, ky",
  },
  {
    postal_code: "33890",
    zip_name: "zolfo springs, fl",
  },
  {
    postal_code: "67522",
    zip_name: "buhler, ks",
  },
  {
    postal_code: "25703",
    zip_name: "huntington, wv",
  },
  {
    postal_code: "19390",
    zip_name: "west grove, pa",
  },
  {
    postal_code: "78834",
    zip_name: "carrizo springs, tx",
  },
  {
    postal_code: "88325",
    zip_name: "high rolls mountain park, nm",
  },
  {
    postal_code: "27925",
    zip_name: "columbia, nc",
  },
  {
    postal_code: "84631",
    zip_name: "fillmore, ut",
  },
  {
    postal_code: "11516",
    zip_name: "cedarhurst, ny",
  },
  {
    postal_code: "89820",
    zip_name: "battle mountain, nv",
  },
  {
    postal_code: "1803",
    zip_name: "burlington, ma",
  },
  {
    postal_code: "12136",
    zip_name: "old chatham, ny",
  },
  {
    postal_code: "87718",
    zip_name: "eagle nest, nm",
  },
  {
    postal_code: "85643",
    zip_name: "willcox, az",
  },
  {
    postal_code: "63068",
    zip_name: "new haven, mo",
  },
  {
    postal_code: "18640",
    zip_name: "pittston, pa",
  },
  {
    postal_code: "45872",
    zip_name: "north baltimore, oh",
  },
  {
    postal_code: "59930",
    zip_name: "rexford, mt",
  },
  {
    postal_code: "4268",
    zip_name: "norway, me",
  },
  {
    postal_code: "26041",
    zip_name: "moundsville, wv",
  },
  {
    postal_code: "79735",
    zip_name: "fort stockton, tx",
  },
  {
    postal_code: "49410",
    zip_name: "fountain, mi",
  },
  {
    postal_code: "31005",
    zip_name: "bonaire, ga",
  },
  {
    postal_code: "27048",
    zip_name: "stoneville, nc",
  },
  {
    postal_code: "12581",
    zip_name: "stanfordville, ny",
  },
  {
    postal_code: "1266",
    zip_name: "west stockbridge, ma",
  },
  {
    postal_code: "77830",
    zip_name: "anderson, tx",
  },
  {
    postal_code: "23455",
    zip_name: "virginia beach, va",
  },
  {
    postal_code: "62952",
    zip_name: "jonesboro, il",
  },
  {
    postal_code: "17051",
    zip_name: "mc veytown, pa",
  },
  {
    postal_code: "11358",
    zip_name: "flushing, ny",
  },
  {
    postal_code: "19034",
    zip_name: "fort washington, pa",
  },
  {
    postal_code: "55607",
    zip_name: "isabella, mn",
  },
  {
    postal_code: "83704",
    zip_name: "boise, id",
  },
  {
    postal_code: "19968",
    zip_name: "milton, de",
  },
  {
    postal_code: "38701",
    zip_name: "greenville, ms",
  },
  {
    postal_code: "1027",
    zip_name: "easthampton, ma",
  },
  {
    postal_code: "59922",
    zip_name: "lakeside, mt",
  },
  {
    postal_code: "84325",
    zip_name: "mendon, ut",
  },
  {
    postal_code: "61760",
    zip_name: "minonk, il",
  },
  {
    postal_code: "13214",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "12979",
    zip_name: "rouses point, ny",
  },
  {
    postal_code: "4355",
    zip_name: "readfield, me",
  },
  {
    postal_code: "65043",
    zip_name: "holts summit, mo",
  },
  {
    postal_code: "56096",
    zip_name: "waterville, mn",
  },
  {
    postal_code: "1772",
    zip_name: "southborough, ma",
  },
  {
    postal_code: "22851",
    zip_name: "stanley, va",
  },
  {
    postal_code: "1966",
    zip_name: "rockport, ma",
  },
  {
    postal_code: "13736",
    zip_name: "berkshire, ny",
  },
  {
    postal_code: "34488",
    zip_name: "silver springs, fl",
  },
  {
    postal_code: "1775",
    zip_name: "stow, ma",
  },
  {
    postal_code: "76059",
    zip_name: "keene, tx",
  },
  {
    postal_code: "56484",
    zip_name: "walker, mn",
  },
  {
    postal_code: "5408",
    zip_name: "burlington, vt",
  },
  {
    postal_code: "1008",
    zip_name: "blandford, ma",
  },
  {
    postal_code: "18343",
    zip_name: "mount bethel, pa",
  },
  {
    postal_code: "19973",
    zip_name: "seaford, de",
  },
  {
    postal_code: "35051",
    zip_name: "columbiana, al",
  },
  {
    postal_code: "5764",
    zip_name: "poultney, vt",
  },
  {
    postal_code: "61736",
    zip_name: "downs, il",
  },
  {
    postal_code: "14303",
    zip_name: "niagara falls, ny",
  },
  {
    postal_code: "19095",
    zip_name: "wyncote, pa",
  },
  {
    postal_code: "2838",
    zip_name: "manville, ri",
  },
  {
    postal_code: "95458",
    zip_name: "lucerne, ca",
  },
  {
    postal_code: "93544",
    zip_name: "llano, ca",
  },
  {
    postal_code: "91008",
    zip_name: "duarte, ca",
  },
  {
    postal_code: "4002",
    zip_name: "alfred, me",
  },
  {
    postal_code: "13135",
    zip_name: "phoenix, ny",
  },
  {
    postal_code: "62664",
    zip_name: "mason city, il",
  },
  {
    postal_code: "2646",
    zip_name: "harwich port, ma",
  },
  {
    postal_code: "54005",
    zip_name: "clear lake, wi",
  },
  {
    postal_code: "27027",
    zip_name: "mayodan, nc",
  },
  {
    postal_code: "5464",
    zip_name: "jeffersonville, vt",
  },
  {
    postal_code: "47987",
    zip_name: "veedersburg, in",
  },
  {
    postal_code: "21035",
    zip_name: "davidsonville, md",
  },
  {
    postal_code: "62692",
    zip_name: "waverly, il",
  },
  {
    postal_code: "13748",
    zip_name: "conklin, ny",
  },
  {
    postal_code: "61568",
    zip_name: "tremont, il",
  },
  {
    postal_code: "66514",
    zip_name: "milford, ks",
  },
  {
    postal_code: "12585",
    zip_name: "verbank, ny",
  },
  {
    postal_code: "60180",
    zip_name: "union, il",
  },
  {
    postal_code: "11952",
    zip_name: "mattituck, ny",
  },
  {
    postal_code: "16503",
    zip_name: "erie, pa",
  },
  {
    postal_code: "78559",
    zip_name: "la feria, tx",
  },
  {
    postal_code: "97396",
    zip_name: "willamina, or",
  },
  {
    postal_code: "40379",
    zip_name: "stamping ground, ky",
  },
  {
    postal_code: "56544",
    zip_name: "frazee, mn",
  },
  {
    postal_code: "28124",
    zip_name: "mount pleasant, nc",
  },
  {
    postal_code: "36273",
    zip_name: "ranburne, al",
  },
  {
    postal_code: "3447",
    zip_name: "fitzwilliam, nh",
  },
  {
    postal_code: "60518",
    zip_name: "earlville, il",
  },
  {
    postal_code: "13634",
    zip_name: "dexter, ny",
  },
  {
    postal_code: "56373",
    zip_name: "royalton, mn",
  },
  {
    postal_code: "15834",
    zip_name: "emporium, pa",
  },
  {
    postal_code: "64067",
    zip_name: "lexington, mo",
  },
  {
    postal_code: "32064",
    zip_name: "live oak, fl",
  },
  {
    postal_code: "95917",
    zip_name: "biggs, ca",
  },
  {
    postal_code: "14450",
    zip_name: "fairport, ny",
  },
  {
    postal_code: "26847",
    zip_name: "petersburg, wv",
  },
  {
    postal_code: "1566",
    zip_name: "sturbridge, ma",
  },
  {
    postal_code: "12449",
    zip_name: "lake katrine, ny",
  },
  {
    postal_code: "11365",
    zip_name: "fresh meadows, ny",
  },
  {
    postal_code: "90211",
    zip_name: "beverly hills, ca",
  },
  {
    postal_code: "78962",
    zip_name: "weimar, tx",
  },
  {
    postal_code: "98624",
    zip_name: "ilwaco, wa",
  },
  {
    postal_code: "37724",
    zip_name: "cumberland gap, tn",
  },
  {
    postal_code: "44676",
    zip_name: "shreve, oh",
  },
  {
    postal_code: "23868",
    zip_name: "lawrenceville, va",
  },
  {
    postal_code: "53014",
    zip_name: "chilton, wi",
  },
  {
    postal_code: "37873",
    zip_name: "surgoinsville, tn",
  },
  {
    postal_code: "15728",
    zip_name: "clymer, pa",
  },
  {
    postal_code: "85736",
    zip_name: "tucson, az",
  },
  {
    postal_code: "15610",
    zip_name: "acme, pa",
  },
  {
    postal_code: "3230",
    zip_name: "danbury, nh",
  },
  {
    postal_code: "37616",
    zip_name: "afton, tn",
  },
  {
    postal_code: "44818",
    zip_name: "bloomville, oh",
  },
  {
    postal_code: "68843",
    zip_name: "hampton, ne",
  },
  {
    postal_code: "54170",
    zip_name: "shiocton, wi",
  },
  {
    postal_code: "16053",
    zip_name: "renfrew, pa",
  },
  {
    postal_code: "4096",
    zip_name: "yarmouth, me",
  },
  {
    postal_code: "84765",
    zip_name: "santa clara, ut",
  },
  {
    postal_code: "27572",
    zip_name: "rougemont, nc",
  },
  {
    postal_code: "2053",
    zip_name: "medway, ma",
  },
  {
    postal_code: "2067",
    zip_name: "sharon, ma",
  },
  {
    postal_code: "25571",
    zip_name: "west hamlin, wv",
  },
  {
    postal_code: "74653",
    zip_name: "tonkawa, ok",
  },
  {
    postal_code: "15431",
    zip_name: "dunbar, pa",
  },
  {
    postal_code: "27313",
    zip_name: "pleasant garden, nc",
  },
  {
    postal_code: "1516",
    zip_name: "douglas, ma",
  },
  {
    postal_code: "68002",
    zip_name: "arlington, ne",
  },
  {
    postal_code: "2726",
    zip_name: "somerset, ma",
  },
  {
    postal_code: "63015",
    zip_name: "catawissa, mo",
  },
  {
    postal_code: "15052",
    zip_name: "industry, pa",
  },
  {
    postal_code: "43604",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "18325",
    zip_name: "canadensis, pa",
  },
  {
    postal_code: "40507",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "19947",
    zip_name: "georgetown, de",
  },
  {
    postal_code: "4769",
    zip_name: "presque isle, me",
  },
  {
    postal_code: "61745",
    zip_name: "heyworth, il",
  },
  {
    postal_code: "27920",
    zip_name: "buxton, nc",
  },
  {
    postal_code: "40468",
    zip_name: "perryville, ky",
  },
  {
    postal_code: "40117",
    zip_name: "ekron, ky",
  },
  {
    postal_code: "95663",
    zip_name: "penryn, ca",
  },
  {
    postal_code: "24571",
    zip_name: "lynch station, va",
  },
  {
    postal_code: "90014",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "54968",
    zip_name: "princeton, wi",
  },
  {
    postal_code: "70462",
    zip_name: "springfield, la",
  },
  {
    postal_code: "97233",
    zip_name: "portland, or",
  },
  {
    postal_code: "83669",
    zip_name: "star, id",
  },
  {
    postal_code: "65717",
    zip_name: "norwood, mo",
  },
  {
    postal_code: "49284",
    zip_name: "springport, mi",
  },
  {
    postal_code: "10473",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "48135",
    zip_name: "garden city, mi",
  },
  {
    postal_code: "94574",
    zip_name: "saint helena, ca",
  },
  {
    postal_code: "68522",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "13460",
    zip_name: "sherburne, ny",
  },
  {
    postal_code: "96141",
    zip_name: "homewood, ca",
  },
  {
    postal_code: "1225",
    zip_name: "cheshire, ma",
  },
  {
    postal_code: "88065",
    zip_name: "tyrone, nm",
  },
  {
    postal_code: "19116",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "59063",
    zip_name: "park city, mt",
  },
  {
    postal_code: "92282",
    zip_name: "whitewater, ca",
  },
  {
    postal_code: "75860",
    zip_name: "teague, tx",
  },
  {
    postal_code: "83687",
    zip_name: "nampa, id",
  },
  {
    postal_code: "54514",
    zip_name: "butternut, wi",
  },
  {
    postal_code: "48657",
    zip_name: "sanford, mi",
  },
  {
    postal_code: "54871",
    zip_name: "shell lake, wi",
  },
  {
    postal_code: "2333",
    zip_name: "east bridgewater, ma",
  },
  {
    postal_code: "21661",
    zip_name: "rock hall, md",
  },
  {
    postal_code: "8837",
    zip_name: "edison, nj",
  },
  {
    postal_code: "18073",
    zip_name: "pennsburg, pa",
  },
  {
    postal_code: "56028",
    zip_name: "elysian, mn",
  },
  {
    postal_code: "74647",
    zip_name: "newkirk, ok",
  },
  {
    postal_code: "60146",
    zip_name: "kirkland, il",
  },
  {
    postal_code: "3261",
    zip_name: "northwood, nh",
  },
  {
    postal_code: "32340",
    zip_name: "madison, fl",
  },
  {
    postal_code: "4281",
    zip_name: "south paris, me",
  },
  {
    postal_code: "72143",
    zip_name: "searcy, ar",
  },
  {
    postal_code: "58801",
    zip_name: "williston, nd",
  },
  {
    postal_code: "2748",
    zip_name: "south dartmouth, ma",
  },
  {
    postal_code: "1940",
    zip_name: "lynnfield, ma",
  },
  {
    postal_code: "6063",
    zip_name: "barkhamsted, ct",
  },
  {
    postal_code: "35953",
    zip_name: "ashville, al",
  },
  {
    postal_code: "81003",
    zip_name: "pueblo, co",
  },
  {
    postal_code: "4627",
    zip_name: "deer isle, me",
  },
  {
    postal_code: "38485",
    zip_name: "waynesboro, tn",
  },
  {
    postal_code: "58368",
    zip_name: "rugby, nd",
  },
  {
    postal_code: "6370",
    zip_name: "oakdale, ct",
  },
  {
    postal_code: "1748",
    zip_name: "hopkinton, ma",
  },
  {
    postal_code: "39426",
    zip_name: "carriere, ms",
  },
  {
    postal_code: "47331",
    zip_name: "connersville, in",
  },
  {
    postal_code: "68305",
    zip_name: "auburn, ne",
  },
  {
    postal_code: "61752",
    zip_name: "le roy, il",
  },
  {
    postal_code: "17078",
    zip_name: "palmyra, pa",
  },
  {
    postal_code: "66111",
    zip_name: "kansas city, ks",
  },
  {
    postal_code: "66064",
    zip_name: "osawatomie, ks",
  },
  {
    postal_code: "97222",
    zip_name: "portland, or",
  },
  {
    postal_code: "56334",
    zip_name: "glenwood, mn",
  },
  {
    postal_code: "2129",
    zip_name: "charlestown, ma",
  },
  {
    postal_code: "1462",
    zip_name: "lunenburg, ma",
  },
  {
    postal_code: "36027",
    zip_name: "eufaula, al",
  },
  {
    postal_code: "44104",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "96753",
    zip_name: "kihei, hi",
  },
  {
    postal_code: "48059",
    zip_name: "fort gratiot, mi",
  },
  {
    postal_code: "52655",
    zip_name: "west burlington, ia",
  },
  {
    postal_code: "13084",
    zip_name: "la fayette, ny",
  },
  {
    postal_code: "1220",
    zip_name: "adams, ma",
  },
  {
    postal_code: "12151",
    zip_name: "round lake, ny",
  },
  {
    postal_code: "47906",
    zip_name: "west lafayette, in",
  },
  {
    postal_code: "98360",
    zip_name: "orting, wa",
  },
  {
    postal_code: "2652",
    zip_name: "north truro, ma",
  },
  {
    postal_code: "56215",
    zip_name: "benson, mn",
  },
  {
    postal_code: "64128",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "11977",
    zip_name: "westhampton, ny",
  },
  {
    postal_code: "33855",
    zip_name: "indian lake estates, fl",
  },
  {
    postal_code: "54730",
    zip_name: "colfax, wi",
  },
  {
    postal_code: "12052",
    zip_name: "cropseyville, ny",
  },
  {
    postal_code: "1460",
    zip_name: "littleton, ma",
  },
  {
    postal_code: "47384",
    zip_name: "shirley, in",
  },
  {
    postal_code: "4027",
    zip_name: "lebanon, me",
  },
  {
    postal_code: "91901",
    zip_name: "alpine, ca",
  },
  {
    postal_code: "95306",
    zip_name: "catheys valley, ca",
  },
  {
    postal_code: "65681",
    zip_name: "lampe, mo",
  },
  {
    postal_code: "96118",
    zip_name: "loyalton, ca",
  },
  {
    postal_code: "61813",
    zip_name: "bement, il",
  },
  {
    postal_code: "54666",
    zip_name: "warrens, wi",
  },
  {
    postal_code: "4217",
    zip_name: "bethel, me",
  },
  {
    postal_code: "47246",
    zip_name: "hope, in",
  },
  {
    postal_code: "63120",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "48642",
    zip_name: "midland, mi",
  },
  {
    postal_code: "3220",
    zip_name: "belmont, nh",
  },
  {
    postal_code: "84762",
    zip_name: "duck creek village, ut",
  },
  {
    postal_code: "8844",
    zip_name: "hillsborough, nj",
  },
  {
    postal_code: "68107",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "93301",
    zip_name: "bakersfield, ca",
  },
  {
    postal_code: "37332",
    zip_name: "evensville, tn",
  },
  {
    postal_code: "4915",
    zip_name: "belfast, me",
  },
  {
    postal_code: "45064",
    zip_name: "somerville, oh",
  },
  {
    postal_code: "28627",
    zip_name: "glade valley, nc",
  },
  {
    postal_code: "44504",
    zip_name: "youngstown, oh",
  },
  {
    postal_code: "30663",
    zip_name: "rutledge, ga",
  },
  {
    postal_code: "54014",
    zip_name: "hager city, wi",
  },
  {
    postal_code: "53521",
    zip_name: "brooklyn, wi",
  },
  {
    postal_code: "58318",
    zip_name: "bottineau, nd",
  },
  {
    postal_code: "19960",
    zip_name: "lincoln, de",
  },
  {
    postal_code: "34449",
    zip_name: "inglis, fl",
  },
  {
    postal_code: "20759",
    zip_name: "fulton, md",
  },
  {
    postal_code: "45764",
    zip_name: "nelsonville, oh",
  },
  {
    postal_code: "54941",
    zip_name: "green lake, wi",
  },
  {
    postal_code: "57745",
    zip_name: "hill city, sd",
  },
  {
    postal_code: "74864",
    zip_name: "prague, ok",
  },
  {
    postal_code: "45214",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "89411",
    zip_name: "genoa, nv",
  },
  {
    postal_code: "4473",
    zip_name: "orono, me",
  },
  {
    postal_code: "3816",
    zip_name: "center tuftonboro, nh",
  },
  {
    postal_code: "48088",
    zip_name: "warren, mi",
  },
  {
    postal_code: "17011",
    zip_name: "camp hill, pa",
  },
  {
    postal_code: "3830",
    zip_name: "east wakefield, nh",
  },
  {
    postal_code: "3748",
    zip_name: "enfield, nh",
  },
  {
    postal_code: "94027",
    zip_name: "atherton, ca",
  },
  {
    postal_code: "29505",
    zip_name: "florence, sc",
  },
  {
    postal_code: "13470",
    zip_name: "stratford, ny",
  },
  {
    postal_code: "17201",
    zip_name: "chambersburg, pa",
  },
  {
    postal_code: "78838",
    zip_name: "concan, tx",
  },
  {
    postal_code: "68847",
    zip_name: "kearney, ne",
  },
  {
    postal_code: "2451",
    zip_name: "waltham, ma",
  },
  {
    postal_code: "32044",
    zip_name: "hampton, fl",
  },
  {
    postal_code: "38756",
    zip_name: "leland, ms",
  },
  {
    postal_code: "13338",
    zip_name: "forestport, ny",
  },
  {
    postal_code: "97002",
    zip_name: "aurora, or",
  },
  {
    postal_code: "63966",
    zip_name: "wappapello, mo",
  },
  {
    postal_code: "16950",
    zip_name: "westfield, pa",
  },
  {
    postal_code: "44470",
    zip_name: "southington, oh",
  },
  {
    postal_code: "92123",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "26525",
    zip_name: "bruceton mills, wv",
  },
  {
    postal_code: "18371",
    zip_name: "tamiment, pa",
  },
  {
    postal_code: "77665",
    zip_name: "winnie, tx",
  },
  {
    postal_code: "37870",
    zip_name: "speedwell, tn",
  },
  {
    postal_code: "95236",
    zip_name: "linden, ca",
  },
  {
    postal_code: "21821",
    zip_name: "deal island, md",
  },
  {
    postal_code: "27551",
    zip_name: "macon, nc",
  },
  {
    postal_code: "63037",
    zip_name: "gerald, mo",
  },
  {
    postal_code: "44253",
    zip_name: "litchfield, oh",
  },
  {
    postal_code: "64854",
    zip_name: "noel, mo",
  },
  {
    postal_code: "23958",
    zip_name: "pamplin, va",
  },
  {
    postal_code: "19022",
    zip_name: "crum lynne, pa",
  },
  {
    postal_code: "40447",
    zip_name: "mc kee, ky",
  },
  {
    postal_code: "26147",
    zip_name: "grantsville, wv",
  },
  {
    postal_code: "14889",
    zip_name: "van etten, ny",
  },
  {
    postal_code: "21770",
    zip_name: "monrovia, md",
  },
  {
    postal_code: "47978",
    zip_name: "rensselaer, in",
  },
  {
    postal_code: "13042",
    zip_name: "cleveland, ny",
  },
  {
    postal_code: "29689",
    zip_name: "townville, sc",
  },
  {
    postal_code: "89415",
    zip_name: "hawthorne, nv",
  },
  {
    postal_code: "24095",
    zip_name: "goodview, va",
  },
  {
    postal_code: "96755",
    zip_name: "kapaau, hi",
  },
  {
    postal_code: "30258",
    zip_name: "molena, ga",
  },
  {
    postal_code: "65606",
    zip_name: "alton, mo",
  },
  {
    postal_code: "3280",
    zip_name: "washington, nh",
  },
  {
    postal_code: "28772",
    zip_name: "rosman, nc",
  },
  {
    postal_code: "94525",
    zip_name: "crockett, ca",
  },
  {
    postal_code: "12056",
    zip_name: "duanesburg, ny",
  },
  {
    postal_code: "88231",
    zip_name: "eunice, nm",
  },
  {
    postal_code: "93926",
    zip_name: "gonzales, ca",
  },
  {
    postal_code: "49028",
    zip_name: "bronson, mi",
  },
  {
    postal_code: "34734",
    zip_name: "gotha, fl",
  },
  {
    postal_code: "55395",
    zip_name: "winsted, mn",
  },
  {
    postal_code: "43143",
    zip_name: "mount sterling, oh",
  },
  {
    postal_code: "22740",
    zip_name: "sperryville, va",
  },
  {
    postal_code: "14882",
    zip_name: "lansing, ny",
  },
  {
    postal_code: "28098",
    zip_name: "lowell, nc",
  },
  {
    postal_code: "38374",
    zip_name: "scotts hill, tn",
  },
  {
    postal_code: "75901",
    zip_name: "lufkin, tx",
  },
  {
    postal_code: "75446",
    zip_name: "honey grove, tx",
  },
  {
    postal_code: "56381",
    zip_name: "starbuck, mn",
  },
  {
    postal_code: "41179",
    zip_name: "vanceburg, ky",
  },
  {
    postal_code: "44643",
    zip_name: "magnolia, oh",
  },
  {
    postal_code: "48436",
    zip_name: "gaines, mi",
  },
  {
    postal_code: "75932",
    zip_name: "burkeville, tx",
  },
  {
    postal_code: "2872",
    zip_name: "prudence island, ri",
  },
  {
    postal_code: "3865",
    zip_name: "plaistow, nh",
  },
  {
    postal_code: "3048",
    zip_name: "greenville, nh",
  },
  {
    postal_code: "36271",
    zip_name: "ohatchee, al",
  },
  {
    postal_code: "2458",
    zip_name: "newton, ma",
  },
  {
    postal_code: "53968",
    zip_name: "wonewoc, wi",
  },
  {
    postal_code: "56571",
    zip_name: "ottertail, mn",
  },
  {
    postal_code: "41086",
    zip_name: "sparta, ky",
  },
  {
    postal_code: "5261",
    zip_name: "pownal, vt",
  },
  {
    postal_code: "84654",
    zip_name: "salina, ut",
  },
  {
    postal_code: "61756",
    zip_name: "maroa, il",
  },
  {
    postal_code: "16056",
    zip_name: "saxonburg, pa",
  },
  {
    postal_code: "56623",
    zip_name: "baudette, mn",
  },
  {
    postal_code: "31027",
    zip_name: "east dublin, ga",
  },
  {
    postal_code: "6438",
    zip_name: "haddam, ct",
  },
  {
    postal_code: "47040",
    zip_name: "rising sun, in",
  },
  {
    postal_code: "51528",
    zip_name: "dow city, ia",
  },
  {
    postal_code: "55074",
    zip_name: "shafer, mn",
  },
  {
    postal_code: "75437",
    zip_name: "dike, tx",
  },
  {
    postal_code: "15637",
    zip_name: "herminie, pa",
  },
  {
    postal_code: "40385",
    zip_name: "waco, ky",
  },
  {
    postal_code: "11096",
    zip_name: "inwood, ny",
  },
  {
    postal_code: "43811",
    zip_name: "conesville, oh",
  },
  {
    postal_code: "2713",
    zip_name: "cuttyhunk, ma",
  },
  {
    postal_code: "38828",
    zip_name: "blue springs, ms",
  },
  {
    postal_code: "28167",
    zip_name: "union mills, nc",
  },
  {
    postal_code: "1720",
    zip_name: "acton, ma",
  },
  {
    postal_code: "55382",
    zip_name: "south haven, mn",
  },
  {
    postal_code: "34216",
    zip_name: "anna maria, fl",
  },
  {
    postal_code: "46347",
    zip_name: "kouts, in",
  },
  {
    postal_code: "44046",
    zip_name: "huntsburg, oh",
  },
  {
    postal_code: "13437",
    zip_name: "redfield, ny",
  },
  {
    postal_code: "96041",
    zip_name: "hayfork, ca",
  },
  {
    postal_code: "43748",
    zip_name: "junction city, oh",
  },
  {
    postal_code: "37010",
    zip_name: "adams, tn",
  },
  {
    postal_code: "5251",
    zip_name: "dorset, vt",
  },
  {
    postal_code: "28783",
    zip_name: "tuckasegee, nc",
  },
  {
    postal_code: "15035",
    zip_name: "east mc keesport, pa",
  },
  {
    postal_code: "12578",
    zip_name: "salt point, ny",
  },
  {
    postal_code: "27962",
    zip_name: "plymouth, nc",
  },
  {
    postal_code: "36024",
    zip_name: "eclectic, al",
  },
  {
    postal_code: "46071",
    zip_name: "thorntown, in",
  },
  {
    postal_code: "78832",
    zip_name: "brackettville, tx",
  },
  {
    postal_code: "97470",
    zip_name: "roseburg, or",
  },
  {
    postal_code: "6090",
    zip_name: "west granby, ct",
  },
  {
    postal_code: "70083",
    zip_name: "port sulphur, la",
  },
  {
    postal_code: "79401",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "5343",
    zip_name: "jamaica, vt",
  },
  {
    postal_code: "5674",
    zip_name: "warren, vt",
  },
  {
    postal_code: "70444",
    zip_name: "kentwood, la",
  },
  {
    postal_code: "62285",
    zip_name: "smithton, il",
  },
  {
    postal_code: "28618",
    zip_name: "deep gap, nc",
  },
  {
    postal_code: "93223",
    zip_name: "farmersville, ca",
  },
  {
    postal_code: "45369",
    zip_name: "south vienna, oh",
  },
  {
    postal_code: "86015",
    zip_name: "bellemont, az",
  },
  {
    postal_code: "98068",
    zip_name: "snoqualmie pass, wa",
  },
  {
    postal_code: "79835",
    zip_name: "canutillo, tx",
  },
  {
    postal_code: "3242",
    zip_name: "henniker, nh",
  },
  {
    postal_code: "16142",
    zip_name: "new wilmington, pa",
  },
  {
    postal_code: "39443",
    zip_name: "laurel, ms",
  },
  {
    postal_code: "19001",
    zip_name: "abington, pa",
  },
  {
    postal_code: "8083",
    zip_name: "somerdale, nj",
  },
  {
    postal_code: "50144",
    zip_name: "leon, ia",
  },
  {
    postal_code: "24266",
    zip_name: "lebanon, va",
  },
  {
    postal_code: "92106",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "32358",
    zip_name: "sopchoppy, fl",
  },
  {
    postal_code: "78361",
    zip_name: "hebbronville, tx",
  },
  {
    postal_code: "16433",
    zip_name: "saegertown, pa",
  },
  {
    postal_code: "62014",
    zip_name: "bunker hill, il",
  },
  {
    postal_code: "14840",
    zip_name: "hammondsport, ny",
  },
  {
    postal_code: "97014",
    zip_name: "cascade locks, or",
  },
  {
    postal_code: "56036",
    zip_name: "glenville, mn",
  },
  {
    postal_code: "83709",
    zip_name: "boise, id",
  },
  {
    postal_code: "90007",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "11804",
    zip_name: "old bethpage, ny",
  },
  {
    postal_code: "40372",
    zip_name: "salvisa, ky",
  },
  {
    postal_code: "3810",
    zip_name: "alton bay, nh",
  },
  {
    postal_code: "54984",
    zip_name: "wild rose, wi",
  },
  {
    postal_code: "11103",
    zip_name: "astoria, ny",
  },
  {
    postal_code: "15140",
    zip_name: "pitcairn, pa",
  },
  {
    postal_code: "21713",
    zip_name: "boonsboro, md",
  },
  {
    postal_code: "94028",
    zip_name: "portola valley, ca",
  },
  {
    postal_code: "55705",
    zip_name: "aurora, mn",
  },
  {
    postal_code: "1921",
    zip_name: "boxford, ma",
  },
  {
    postal_code: "3848",
    zip_name: "kingston, nh",
  },
  {
    postal_code: "3290",
    zip_name: "nottingham, nh",
  },
  {
    postal_code: "80720",
    zip_name: "akron, co",
  },
  {
    postal_code: "75496",
    zip_name: "wolfe city, tx",
  },
  {
    postal_code: "7057",
    zip_name: "wallington, nj",
  },
  {
    postal_code: "35046",
    zip_name: "clanton, al",
  },
  {
    postal_code: "45771",
    zip_name: "racine, oh",
  },
  {
    postal_code: "99148",
    zip_name: "loon lake, wa",
  },
  {
    postal_code: "1532",
    zip_name: "northborough, ma",
  },
  {
    postal_code: "14086",
    zip_name: "lancaster, ny",
  },
  {
    postal_code: "2452",
    zip_name: "waltham, ma",
  },
  {
    postal_code: "56443",
    zip_name: "cushing, mn",
  },
  {
    postal_code: "45887",
    zip_name: "spencerville, oh",
  },
  {
    postal_code: "53179",
    zip_name: "trevor, wi",
  },
  {
    postal_code: "97360",
    zip_name: "mill city, or",
  },
  {
    postal_code: "1540",
    zip_name: "oxford, ma",
  },
  {
    postal_code: "61739",
    zip_name: "fairbury, il",
  },
  {
    postal_code: "61048",
    zip_name: "lena, il",
  },
  {
    postal_code: "20722",
    zip_name: "brentwood, md",
  },
  {
    postal_code: "61535",
    zip_name: "groveland, il",
  },
  {
    postal_code: "8065",
    zip_name: "palmyra, nj",
  },
  {
    postal_code: "62033",
    zip_name: "gillespie, il",
  },
  {
    postal_code: "6259",
    zip_name: "pomfret center, ct",
  },
  {
    postal_code: "77434",
    zip_name: "eagle lake, tx",
  },
  {
    postal_code: "18415",
    zip_name: "damascus, pa",
  },
  {
    postal_code: "44442",
    zip_name: "new middletown, oh",
  },
  {
    postal_code: "19520",
    zip_name: "elverson, pa",
  },
  {
    postal_code: "11934",
    zip_name: "center moriches, ny",
  },
  {
    postal_code: "97038",
    zip_name: "molalla, or",
  },
  {
    postal_code: "85630",
    zip_name: "saint david, az",
  },
  {
    postal_code: "77979",
    zip_name: "port lavaca, tx",
  },
  {
    postal_code: "19055",
    zip_name: "levittown, pa",
  },
  {
    postal_code: "30427",
    zip_name: "glennville, ga",
  },
  {
    postal_code: "4261",
    zip_name: "newry, me",
  },
  {
    postal_code: "21850",
    zip_name: "pittsville, md",
  },
  {
    postal_code: "22801",
    zip_name: "harrisonburg, va",
  },
  {
    postal_code: "80651",
    zip_name: "platteville, co",
  },
  {
    postal_code: "76035",
    zip_name: "cresson, tx",
  },
  {
    postal_code: "3741",
    zip_name: "canaan, nh",
  },
  {
    postal_code: "4963",
    zip_name: "oakland, me",
  },
  {
    postal_code: "56097",
    zip_name: "wells, mn",
  },
  {
    postal_code: "75935",
    zip_name: "center, tx",
  },
  {
    postal_code: "77707",
    zip_name: "beaumont, tx",
  },
  {
    postal_code: "13035",
    zip_name: "cazenovia, ny",
  },
  {
    postal_code: "30669",
    zip_name: "union point, ga",
  },
  {
    postal_code: "31906",
    zip_name: "columbus, ga",
  },
  {
    postal_code: "33827",
    zip_name: "babson park, fl",
  },
  {
    postal_code: "8540",
    zip_name: "princeton, nj",
  },
  {
    postal_code: "47130",
    zip_name: "jeffersonville, in",
  },
  {
    postal_code: "78123",
    zip_name: "mc queeney, tx",
  },
  {
    postal_code: "74035",
    zip_name: "hominy, ok",
  },
  {
    postal_code: "56441",
    zip_name: "crosby, mn",
  },
  {
    postal_code: "55381",
    zip_name: "silver lake, mn",
  },
  {
    postal_code: "26175",
    zip_name: "sistersville, wv",
  },
  {
    postal_code: "8094",
    zip_name: "williamstown, nj",
  },
  {
    postal_code: "2666",
    zip_name: "truro, ma",
  },
  {
    postal_code: "28668",
    zip_name: "roaring gap, nc",
  },
  {
    postal_code: "8515",
    zip_name: "chesterfield, nj",
  },
  {
    postal_code: "41056",
    zip_name: "maysville, ky",
  },
  {
    postal_code: "79907",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "50548",
    zip_name: "humboldt, ia",
  },
  {
    postal_code: "76844",
    zip_name: "goldthwaite, tx",
  },
  {
    postal_code: "56340",
    zip_name: "holdingford, mn",
  },
  {
    postal_code: "83237",
    zip_name: "franklin, id",
  },
  {
    postal_code: "40461",
    zip_name: "paint lick, ky",
  },
  {
    postal_code: "15636",
    zip_name: "harrison city, pa",
  },
  {
    postal_code: "13807",
    zip_name: "milford, ny",
  },
  {
    postal_code: "64501",
    zip_name: "saint joseph, mo",
  },
  {
    postal_code: "77705",
    zip_name: "beaumont, tx",
  },
  {
    postal_code: "45380",
    zip_name: "versailles, oh",
  },
  {
    postal_code: "64124",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "8005",
    zip_name: "barnegat, nj",
  },
  {
    postal_code: "52772",
    zip_name: "tipton, ia",
  },
  {
    postal_code: "6359",
    zip_name: "north stonington, ct",
  },
  {
    postal_code: "4021",
    zip_name: "cumberland center, me",
  },
  {
    postal_code: "19952",
    zip_name: "harrington, de",
  },
  {
    postal_code: "61559",
    zip_name: "princeville, il",
  },
  {
    postal_code: "35031",
    zip_name: "blountsville, al",
  },
  {
    postal_code: "37415",
    zip_name: "chattanooga, tn",
  },
  {
    postal_code: "56220",
    zip_name: "canby, mn",
  },
  {
    postal_code: "1952",
    zip_name: "salisbury, ma",
  },
  {
    postal_code: "50170",
    zip_name: "monroe, ia",
  },
  {
    postal_code: "37020",
    zip_name: "bell buckle, tn",
  },
  {
    postal_code: "95389",
    zip_name: "yosemite national park, ca",
  },
  {
    postal_code: "3870",
    zip_name: "rye, nh",
  },
  {
    postal_code: "68446",
    zip_name: "syracuse, ne",
  },
  {
    postal_code: "3262",
    zip_name: "north woodstock, nh",
  },
  {
    postal_code: "3234",
    zip_name: "epsom, nh",
  },
  {
    postal_code: "17724",
    zip_name: "canton, pa",
  },
  {
    postal_code: "62233",
    zip_name: "chester, il",
  },
  {
    postal_code: "66018",
    zip_name: "de soto, ks",
  },
  {
    postal_code: "21727",
    zip_name: "emmitsburg, md",
  },
  {
    postal_code: "37310",
    zip_name: "charleston, tn",
  },
  {
    postal_code: "26764",
    zip_name: "terra alta, wv",
  },
  {
    postal_code: "2145",
    zip_name: "somerville, ma",
  },
  {
    postal_code: "95445",
    zip_name: "gualala, ca",
  },
  {
    postal_code: "85552",
    zip_name: "thatcher, az",
  },
  {
    postal_code: "70431",
    zip_name: "bush, la",
  },
  {
    postal_code: "19809",
    zip_name: "wilmington, de",
  },
  {
    postal_code: "89449",
    zip_name: "stateline, nv",
  },
  {
    postal_code: "20714",
    zip_name: "north beach, md",
  },
  {
    postal_code: "91731",
    zip_name: "el monte, ca",
  },
  {
    postal_code: "55360",
    zip_name: "mayer, mn",
  },
  {
    postal_code: "65706",
    zip_name: "marshfield, mo",
  },
  {
    postal_code: "19070",
    zip_name: "morton, pa",
  },
  {
    postal_code: "45879",
    zip_name: "paulding, oh",
  },
  {
    postal_code: "63650",
    zip_name: "ironton, mo",
  },
  {
    postal_code: "15626",
    zip_name: "delmont, pa",
  },
  {
    postal_code: "83869",
    zip_name: "spirit lake, id",
  },
  {
    postal_code: "54519",
    zip_name: "conover, wi",
  },
  {
    postal_code: "49449",
    zip_name: "pentwater, mi",
  },
  {
    postal_code: "42726",
    zip_name: "clarkson, ky",
  },
  {
    postal_code: "3809",
    zip_name: "alton, nh",
  },
  {
    postal_code: "60970",
    zip_name: "watseka, il",
  },
  {
    postal_code: "81650",
    zip_name: "rifle, co",
  },
  {
    postal_code: "36853",
    zip_name: "dadeville, al",
  },
  {
    postal_code: "49098",
    zip_name: "watervliet, mi",
  },
  {
    postal_code: "81101",
    zip_name: "alamosa, co",
  },
  {
    postal_code: "2030",
    zip_name: "dover, ma",
  },
  {
    postal_code: "76711",
    zip_name: "waco, tx",
  },
  {
    postal_code: "74455",
    zip_name: "porum, ok",
  },
  {
    postal_code: "24224",
    zip_name: "castlewood, va",
  },
  {
    postal_code: "11721",
    zip_name: "centerport, ny",
  },
  {
    postal_code: "16025",
    zip_name: "chicora, pa",
  },
  {
    postal_code: "85003",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "38930",
    zip_name: "greenwood, ms",
  },
  {
    postal_code: "92365",
    zip_name: "newberry springs, ca",
  },
  {
    postal_code: "48180",
    zip_name: "taylor, mi",
  },
  {
    postal_code: "48221",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "14616",
    zip_name: "rochester, ny",
  },
  {
    postal_code: "72601",
    zip_name: "harrison, ar",
  },
  {
    postal_code: "60408",
    zip_name: "braidwood, il",
  },
  {
    postal_code: "42518",
    zip_name: "bronston, ky",
  },
  {
    postal_code: "75831",
    zip_name: "buffalo, tx",
  },
  {
    postal_code: "11507",
    zip_name: "albertson, ny",
  },
  {
    postal_code: "80809",
    zip_name: "cascade, co",
  },
  {
    postal_code: "78002",
    zip_name: "atascosa, tx",
  },
  {
    postal_code: "21639",
    zip_name: "greensboro, md",
  },
  {
    postal_code: "17313",
    zip_name: "dallastown, pa",
  },
  {
    postal_code: "6751",
    zip_name: "bethlehem, ct",
  },
  {
    postal_code: "84781",
    zip_name: "pine valley, ut",
  },
  {
    postal_code: "66605",
    zip_name: "topeka, ks",
  },
  {
    postal_code: "19137",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "92339",
    zip_name: "forest falls, ca",
  },
  {
    postal_code: "5679",
    zip_name: "williamstown, vt",
  },
  {
    postal_code: "98465",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "87557",
    zip_name: "ranchos de taos, nm",
  },
  {
    postal_code: "45167",
    zip_name: "ripley, oh",
  },
  {
    postal_code: "21853",
    zip_name: "princess anne, md",
  },
  {
    postal_code: "55334",
    zip_name: "gaylord, mn",
  },
  {
    postal_code: "50214",
    zip_name: "otley, ia",
  },
  {
    postal_code: "45326",
    zip_name: "fletcher, oh",
  },
  {
    postal_code: "65047",
    zip_name: "kaiser, mo",
  },
  {
    postal_code: "54129",
    zip_name: "hilbert, wi",
  },
  {
    postal_code: "12150",
    zip_name: "rotterdam junction, ny",
  },
  {
    postal_code: "98937",
    zip_name: "naches, wa",
  },
  {
    postal_code: "91020",
    zip_name: "montrose, ca",
  },
  {
    postal_code: "48017",
    zip_name: "clawson, mi",
  },
  {
    postal_code: "50248",
    zip_name: "story city, ia",
  },
  {
    postal_code: "61753",
    zip_name: "lexington, il",
  },
  {
    postal_code: "15104",
    zip_name: "braddock, pa",
  },
  {
    postal_code: "80650",
    zip_name: "pierce, co",
  },
  {
    postal_code: "5829",
    zip_name: "derby, vt",
  },
  {
    postal_code: "20135",
    zip_name: "bluemont, va",
  },
  {
    postal_code: "25301",
    zip_name: "charleston, wv",
  },
  {
    postal_code: "83647",
    zip_name: "mountain home, id",
  },
  {
    postal_code: "62822",
    zip_name: "christopher, il",
  },
  {
    postal_code: "44021",
    zip_name: "burton, oh",
  },
  {
    postal_code: "65705",
    zip_name: "marionville, mo",
  },
  {
    postal_code: "72768",
    zip_name: "sulphur springs, ar",
  },
  {
    postal_code: "71449",
    zip_name: "many, la",
  },
  {
    postal_code: "98665",
    zip_name: "vancouver, wa",
  },
  {
    postal_code: "40387",
    zip_name: "wellington, ky",
  },
  {
    postal_code: "34681",
    zip_name: "crystal beach, fl",
  },
  {
    postal_code: "92249",
    zip_name: "heber, ca",
  },
  {
    postal_code: "60301",
    zip_name: "oak park, il",
  },
  {
    postal_code: "61373",
    zip_name: "utica, il",
  },
  {
    postal_code: "49221",
    zip_name: "adrian, mi",
  },
  {
    postal_code: "4055",
    zip_name: "naples, me",
  },
  {
    postal_code: "6782",
    zip_name: "plymouth, ct",
  },
  {
    postal_code: "8734",
    zip_name: "lanoka harbor, nj",
  },
  {
    postal_code: "39428",
    zip_name: "collins, ms",
  },
  {
    postal_code: "22844",
    zip_name: "new market, va",
  },
  {
    postal_code: "60541",
    zip_name: "newark, il",
  },
  {
    postal_code: "55357",
    zip_name: "loretto, mn",
  },
  {
    postal_code: "97124",
    zip_name: "hillsboro, or",
  },
  {
    postal_code: "49440",
    zip_name: "muskegon, mi",
  },
  {
    postal_code: "75633",
    zip_name: "carthage, tx",
  },
  {
    postal_code: "92250",
    zip_name: "holtville, ca",
  },
  {
    postal_code: "4468",
    zip_name: "old town, me",
  },
  {
    postal_code: "2189",
    zip_name: "east weymouth, ma",
  },
  {
    postal_code: "3818",
    zip_name: "conway, nh",
  },
  {
    postal_code: "72619",
    zip_name: "bull shoals, ar",
  },
  {
    postal_code: "49751",
    zip_name: "johannesburg, mi",
  },
  {
    postal_code: "6384",
    zip_name: "voluntown, ct",
  },
  {
    postal_code: "56465",
    zip_name: "merrifield, mn",
  },
  {
    postal_code: "99701",
    zip_name: "fairbanks, ak",
  },
  {
    postal_code: "56469",
    zip_name: "palisade, mn",
  },
  {
    postal_code: "79235",
    zip_name: "floydada, tx",
  },
  {
    postal_code: "16314",
    zip_name: "cochranton, pa",
  },
  {
    postal_code: "65679",
    zip_name: "kirbyville, mo",
  },
  {
    postal_code: "49455",
    zip_name: "shelby, mi",
  },
  {
    postal_code: "1473",
    zip_name: "westminster, ma",
  },
  {
    postal_code: "17522",
    zip_name: "ephrata, pa",
  },
  {
    postal_code: "11786",
    zip_name: "shoreham, ny",
  },
  {
    postal_code: "84745",
    zip_name: "la verkin, ut",
  },
  {
    postal_code: "83646",
    zip_name: "meridian, id",
  },
  {
    postal_code: "59935",
    zip_name: "troy, mt",
  },
  {
    postal_code: "61088",
    zip_name: "winnebago, il",
  },
  {
    postal_code: "65757",
    zip_name: "strafford, mo",
  },
  {
    postal_code: "75904",
    zip_name: "lufkin, tx",
  },
  {
    postal_code: "49675",
    zip_name: "onekama, mi",
  },
  {
    postal_code: "42366",
    zip_name: "philpot, ky",
  },
  {
    postal_code: "76462",
    zip_name: "lipan, tx",
  },
  {
    postal_code: "1062",
    zip_name: "florence, ma",
  },
  {
    postal_code: "95626",
    zip_name: "elverta, ca",
  },
  {
    postal_code: "95627",
    zip_name: "esparto, ca",
  },
  {
    postal_code: "3034",
    zip_name: "candia, nh",
  },
  {
    postal_code: "13622",
    zip_name: "chaumont, ny",
  },
  {
    postal_code: "44473",
    zip_name: "vienna, oh",
  },
  {
    postal_code: "59722",
    zip_name: "deer lodge, mt",
  },
  {
    postal_code: "27960",
    zip_name: "ocracoke, nc",
  },
  {
    postal_code: "15046",
    zip_name: "crescent, pa",
  },
  {
    postal_code: "32195",
    zip_name: "weirsdale, fl",
  },
  {
    postal_code: "59027",
    zip_name: "emigrant, mt",
  },
  {
    postal_code: "1609",
    zip_name: "worcester, ma",
  },
  {
    postal_code: "43112",
    zip_name: "carroll, oh",
  },
  {
    postal_code: "84339",
    zip_name: "wellsville, ut",
  },
  {
    postal_code: "6255",
    zip_name: "north grosvenordale, ct",
  },
  {
    postal_code: "47283",
    zip_name: "westport, in",
  },
  {
    postal_code: "12423",
    zip_name: "east durham, ny",
  },
  {
    postal_code: "12903",
    zip_name: "plattsburgh, ny",
  },
  {
    postal_code: "12494",
    zip_name: "west shokan, ny",
  },
  {
    postal_code: "54742",
    zip_name: "fall creek, wi",
  },
  {
    postal_code: "63055",
    zip_name: "labadie, mo",
  },
  {
    postal_code: "56630",
    zip_name: "blackduck, mn",
  },
  {
    postal_code: "55719",
    zip_name: "chisholm, mn",
  },
  {
    postal_code: "17225",
    zip_name: "greencastle, pa",
  },
  {
    postal_code: "2343",
    zip_name: "holbrook, ma",
  },
  {
    postal_code: "90006",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "78542",
    zip_name: "edinburg, tx",
  },
  {
    postal_code: "38310",
    zip_name: "adamsville, tn",
  },
  {
    postal_code: "48152",
    zip_name: "livonia, mi",
  },
  {
    postal_code: "59804",
    zip_name: "missoula, mt",
  },
  {
    postal_code: "56364",
    zip_name: "pierz, mn",
  },
  {
    postal_code: "38563",
    zip_name: "gordonsville, tn",
  },
  {
    postal_code: "56510",
    zip_name: "ada, mn",
  },
  {
    postal_code: "26250",
    zip_name: "belington, wv",
  },
  {
    postal_code: "59041",
    zip_name: "joliet, mt",
  },
  {
    postal_code: "36305",
    zip_name: "dothan, al",
  },
  {
    postal_code: "30295",
    zip_name: "zebulon, ga",
  },
  {
    postal_code: "14527",
    zip_name: "penn yan, ny",
  },
  {
    postal_code: "75840",
    zip_name: "fairfield, tx",
  },
  {
    postal_code: "2630",
    zip_name: "barnstable, ma",
  },
  {
    postal_code: "19121",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "30234",
    zip_name: "jenkinsburg, ga",
  },
  {
    postal_code: "11693",
    zip_name: "far rockaway, ny",
  },
  {
    postal_code: "95971",
    zip_name: "quincy, ca",
  },
  {
    postal_code: "83864",
    zip_name: "sandpoint, id",
  },
  {
    postal_code: "10598",
    zip_name: "yorktown heights, ny",
  },
  {
    postal_code: "24219",
    zip_name: "big stone gap, va",
  },
  {
    postal_code: "92083",
    zip_name: "vista, ca",
  },
  {
    postal_code: "8360",
    zip_name: "vineland, nj",
  },
  {
    postal_code: "95379",
    zip_name: "tuolumne, ca",
  },
  {
    postal_code: "99009",
    zip_name: "elk, wa",
  },
  {
    postal_code: "17257",
    zip_name: "shippensburg, pa",
  },
  {
    postal_code: "85937",
    zip_name: "snowflake, az",
  },
  {
    postal_code: "61517",
    zip_name: "brimfield, il",
  },
  {
    postal_code: "92676",
    zip_name: "silverado, ca",
  },
  {
    postal_code: "75117",
    zip_name: "edgewood, tx",
  },
  {
    postal_code: "81425",
    zip_name: "olathe, co",
  },
  {
    postal_code: "8807",
    zip_name: "bridgewater, nj",
  },
  {
    postal_code: "97405",
    zip_name: "eugene, or",
  },
  {
    postal_code: "78102",
    zip_name: "beeville, tx",
  },
  {
    postal_code: "4976",
    zip_name: "skowhegan, me",
  },
  {
    postal_code: "14411",
    zip_name: "albion, ny",
  },
  {
    postal_code: "63552",
    zip_name: "macon, mo",
  },
  {
    postal_code: "48236",
    zip_name: "grosse pointe, mi",
  },
  {
    postal_code: "87104",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "54494",
    zip_name: "wisconsin rapids, wi",
  },
  {
    postal_code: "84627",
    zip_name: "ephraim, ut",
  },
  {
    postal_code: "14801",
    zip_name: "addison, ny",
  },
  {
    postal_code: "77335",
    zip_name: "goodrich, tx",
  },
  {
    postal_code: "61847",
    zip_name: "gifford, il",
  },
  {
    postal_code: "15748",
    zip_name: "homer city, pa",
  },
  {
    postal_code: "28585",
    zip_name: "trenton, nc",
  },
  {
    postal_code: "2341",
    zip_name: "hanson, ma",
  },
  {
    postal_code: "32424",
    zip_name: "blountstown, fl",
  },
  {
    postal_code: "32058",
    zip_name: "lawtey, fl",
  },
  {
    postal_code: "35098",
    zip_name: "logan, al",
  },
  {
    postal_code: "8817",
    zip_name: "edison, nj",
  },
  {
    postal_code: "92040",
    zip_name: "lakeside, ca",
  },
  {
    postal_code: "8533",
    zip_name: "new egypt, nj",
  },
  {
    postal_code: "65668",
    zip_name: "hermitage, mo",
  },
  {
    postal_code: "28643",
    zip_name: "lansing, nc",
  },
  {
    postal_code: "56361",
    zip_name: "parkers prairie, mn",
  },
  {
    postal_code: "3835",
    zip_name: "farmington, nh",
  },
  {
    postal_code: "93656",
    zip_name: "riverdale, ca",
  },
  {
    postal_code: "61275",
    zip_name: "port byron, il",
  },
  {
    postal_code: "37352",
    zip_name: "lynchburg, tn",
  },
  {
    postal_code: "54837",
    zip_name: "frederic, wi",
  },
  {
    postal_code: "85638",
    zip_name: "tombstone, az",
  },
  {
    postal_code: "13407",
    zip_name: "mohawk, ny",
  },
  {
    postal_code: "72722",
    zip_name: "decatur, ar",
  },
  {
    postal_code: "61528",
    zip_name: "edwards, il",
  },
  {
    postal_code: "96750",
    zip_name: "kealakekua, hi",
  },
  {
    postal_code: "19044",
    zip_name: "horsham, pa",
  },
  {
    postal_code: "1507",
    zip_name: "charlton, ma",
  },
  {
    postal_code: "93636",
    zip_name: "madera, ca",
  },
  {
    postal_code: "40119",
    zip_name: "falls of rough, ky",
  },
  {
    postal_code: "1028",
    zip_name: "east longmeadow, ma",
  },
  {
    postal_code: "19102",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "25813",
    zip_name: "beaver, wv",
  },
  {
    postal_code: "49892",
    zip_name: "vulcan, mi",
  },
  {
    postal_code: "81323",
    zip_name: "dolores, co",
  },
  {
    postal_code: "12866",
    zip_name: "saratoga springs, ny",
  },
  {
    postal_code: "33909",
    zip_name: "cape coral, fl",
  },
  {
    postal_code: "29526",
    zip_name: "conway, sc",
  },
  {
    postal_code: "30052",
    zip_name: "loganville, ga",
  },
  {
    postal_code: "17350",
    zip_name: "new oxford, pa",
  },
  {
    postal_code: "49726",
    zip_name: "drummond island, mi",
  },
  {
    postal_code: "17327",
    zip_name: "glen rock, pa",
  },
  {
    postal_code: "69153",
    zip_name: "ogallala, ne",
  },
  {
    postal_code: "81630",
    zip_name: "de beque, co",
  },
  {
    postal_code: "47012",
    zip_name: "brookville, in",
  },
  {
    postal_code: "39730",
    zip_name: "aberdeen, ms",
  },
  {
    postal_code: "43025",
    zip_name: "hebron, oh",
  },
  {
    postal_code: "48185",
    zip_name: "westland, mi",
  },
  {
    postal_code: "94511",
    zip_name: "bethel island, ca",
  },
  {
    postal_code: "71603",
    zip_name: "pine bluff, ar",
  },
  {
    postal_code: "93433",
    zip_name: "grover beach, ca",
  },
  {
    postal_code: "97914",
    zip_name: "ontario, or",
  },
  {
    postal_code: "28344",
    zip_name: "godwin, nc",
  },
  {
    postal_code: "29728",
    zip_name: "pageland, sc",
  },
  {
    postal_code: "93444",
    zip_name: "nipomo, ca",
  },
  {
    postal_code: "27316",
    zip_name: "ramseur, nc",
  },
  {
    postal_code: "65707",
    zip_name: "miller, mo",
  },
  {
    postal_code: "28762",
    zip_name: "old fort, nc",
  },
  {
    postal_code: "5846",
    zip_name: "island pond, vt",
  },
  {
    postal_code: "65338",
    zip_name: "lincoln, mo",
  },
  {
    postal_code: "15112",
    zip_name: "east pittsburgh, pa",
  },
  {
    postal_code: "39073",
    zip_name: "florence, ms",
  },
  {
    postal_code: "19975",
    zip_name: "selbyville, de",
  },
  {
    postal_code: "64759",
    zip_name: "lamar, mo",
  },
  {
    postal_code: "77012",
    zip_name: "houston, tx",
  },
  {
    postal_code: "77465",
    zip_name: "palacios, tx",
  },
  {
    postal_code: "3781",
    zip_name: "plainfield, nh",
  },
  {
    postal_code: "20685",
    zip_name: "saint leonard, md",
  },
  {
    postal_code: "21756",
    zip_name: "keedysville, md",
  },
  {
    postal_code: "27371",
    zip_name: "troy, nc",
  },
  {
    postal_code: "30513",
    zip_name: "blue ridge, ga",
  },
  {
    postal_code: "3440",
    zip_name: "antrim, nh",
  },
  {
    postal_code: "85935",
    zip_name: "pinetop, az",
  },
  {
    postal_code: "16833",
    zip_name: "curwensville, pa",
  },
  {
    postal_code: "40456",
    zip_name: "mount vernon, ky",
  },
  {
    postal_code: "74965",
    zip_name: "westville, ok",
  },
  {
    postal_code: "92082",
    zip_name: "valley center, ca",
  },
  {
    postal_code: "79411",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "86334",
    zip_name: "paulden, az",
  },
  {
    postal_code: "54810",
    zip_name: "balsam lake, wi",
  },
  {
    postal_code: "29532",
    zip_name: "darlington, sc",
  },
  {
    postal_code: "43832",
    zip_name: "newcomerstown, oh",
  },
  {
    postal_code: "25420",
    zip_name: "gerrardstown, wv",
  },
  {
    postal_code: "26452",
    zip_name: "weston, wv",
  },
  {
    postal_code: "42127",
    zip_name: "cave city, ky",
  },
  {
    postal_code: "37915",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "80118",
    zip_name: "larkspur, co",
  },
  {
    postal_code: "13164",
    zip_name: "warners, ny",
  },
  {
    postal_code: "80210",
    zip_name: "denver, co",
  },
  {
    postal_code: "68940",
    zip_name: "funk, ne",
  },
  {
    postal_code: "92101",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "92866",
    zip_name: "orange, ca",
  },
  {
    postal_code: "29630",
    zip_name: "central, sc",
  },
  {
    postal_code: "27947",
    zip_name: "jarvisburg, nc",
  },
  {
    postal_code: "68057",
    zip_name: "scribner, ne",
  },
  {
    postal_code: "14094",
    zip_name: "lockport, ny",
  },
  {
    postal_code: "43968",
    zip_name: "wellsville, oh",
  },
  {
    postal_code: "51340",
    zip_name: "fostoria, ia",
  },
  {
    postal_code: "13607",
    zip_name: "alexandria bay, ny",
  },
  {
    postal_code: "54982",
    zip_name: "wautoma, wi",
  },
  {
    postal_code: "55704",
    zip_name: "askov, mn",
  },
  {
    postal_code: "11010",
    zip_name: "franklin square, ny",
  },
  {
    postal_code: "49107",
    zip_name: "buchanan, mi",
  },
  {
    postal_code: "24328",
    zip_name: "fancy gap, va",
  },
  {
    postal_code: "62230",
    zip_name: "breese, il",
  },
  {
    postal_code: "27018",
    zip_name: "east bend, nc",
  },
  {
    postal_code: "10940",
    zip_name: "middletown, ny",
  },
  {
    postal_code: "39556",
    zip_name: "kiln, ms",
  },
  {
    postal_code: "38501",
    zip_name: "cookeville, tn",
  },
  {
    postal_code: "85363",
    zip_name: "youngtown, az",
  },
  {
    postal_code: "78208",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "72110",
    zip_name: "morrilton, ar",
  },
  {
    postal_code: "21655",
    zip_name: "preston, md",
  },
  {
    postal_code: "43912",
    zip_name: "bridgeport, oh",
  },
  {
    postal_code: "72106",
    zip_name: "mayflower, ar",
  },
  {
    postal_code: "59868",
    zip_name: "seeley lake, mt",
  },
  {
    postal_code: "70113",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "64129",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "63073",
    zip_name: "saint albans, mo",
  },
  {
    postal_code: "50158",
    zip_name: "marshalltown, ia",
  },
  {
    postal_code: "10465",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "23141",
    zip_name: "quinton, va",
  },
  {
    postal_code: "23601",
    zip_name: "newport news, va",
  },
  {
    postal_code: "83634",
    zip_name: "kuna, id",
  },
  {
    postal_code: "18353",
    zip_name: "saylorsburg, pa",
  },
  {
    postal_code: "54023",
    zip_name: "roberts, wi",
  },
  {
    postal_code: "30303",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "13833",
    zip_name: "port crane, ny",
  },
  {
    postal_code: "28464",
    zip_name: "teachey, nc",
  },
  {
    postal_code: "37806",
    zip_name: "mascot, tn",
  },
  {
    postal_code: "77807",
    zip_name: "bryan, tx",
  },
  {
    postal_code: "63780",
    zip_name: "scott city, mo",
  },
  {
    postal_code: "13492",
    zip_name: "whitesboro, ny",
  },
  {
    postal_code: "11104",
    zip_name: "sunnyside, ny",
  },
  {
    postal_code: "2494",
    zip_name: "needham heights, ma",
  },
  {
    postal_code: "45693",
    zip_name: "west union, oh",
  },
  {
    postal_code: "80643",
    zip_name: "keenesburg, co",
  },
  {
    postal_code: "56342",
    zip_name: "isle, mn",
  },
  {
    postal_code: "30567",
    zip_name: "pendergrass, ga",
  },
  {
    postal_code: "12072",
    zip_name: "fultonville, ny",
  },
  {
    postal_code: "80106",
    zip_name: "elbert, co",
  },
  {
    postal_code: "22580",
    zip_name: "woodford, va",
  },
  {
    postal_code: "78065",
    zip_name: "poteet, tx",
  },
  {
    postal_code: "13053",
    zip_name: "dryden, ny",
  },
  {
    postal_code: "13494",
    zip_name: "woodgate, ny",
  },
  {
    postal_code: "29926",
    zip_name: "hilton head island, sc",
  },
  {
    postal_code: "21163",
    zip_name: "woodstock, md",
  },
  {
    postal_code: "23885",
    zip_name: "sutherland, va",
  },
  {
    postal_code: "80113",
    zip_name: "englewood, co",
  },
  {
    postal_code: "56065",
    zip_name: "mapleton, mn",
  },
  {
    postal_code: "46341",
    zip_name: "hebron, in",
  },
  {
    postal_code: "95470",
    zip_name: "redwood valley, ca",
  },
  {
    postal_code: "56672",
    zip_name: "remer, mn",
  },
  {
    postal_code: "50627",
    zip_name: "eldora, ia",
  },
  {
    postal_code: "30277",
    zip_name: "sharpsburg, ga",
  },
  {
    postal_code: "5495",
    zip_name: "williston, vt",
  },
  {
    postal_code: "97009",
    zip_name: "boring, or",
  },
  {
    postal_code: "30104",
    zip_name: "aragon, ga",
  },
  {
    postal_code: "72114",
    zip_name: "north little rock, ar",
  },
  {
    postal_code: "2832",
    zip_name: "hope valley, ri",
  },
  {
    postal_code: "80033",
    zip_name: "wheat ridge, co",
  },
  {
    postal_code: "80111",
    zip_name: "englewood, co",
  },
  {
    postal_code: "98951",
    zip_name: "wapato, wa",
  },
  {
    postal_code: "70538",
    zip_name: "franklin, la",
  },
  {
    postal_code: "45123",
    zip_name: "greenfield, oh",
  },
  {
    postal_code: "18405",
    zip_name: "beach lake, pa",
  },
  {
    postal_code: "75964",
    zip_name: "nacogdoches, tx",
  },
  {
    postal_code: "44502",
    zip_name: "youngstown, oh",
  },
  {
    postal_code: "27534",
    zip_name: "goldsboro, nc",
  },
  {
    postal_code: "20710",
    zip_name: "bladensburg, md",
  },
  {
    postal_code: "7003",
    zip_name: "bloomfield, nj",
  },
  {
    postal_code: "12154",
    zip_name: "schaghticoke, ny",
  },
  {
    postal_code: "23089",
    zip_name: "lanexa, va",
  },
  {
    postal_code: "39577",
    zip_name: "wiggins, ms",
  },
  {
    postal_code: "59923",
    zip_name: "libby, mt",
  },
  {
    postal_code: "80021",
    zip_name: "broomfield, co",
  },
  {
    postal_code: "39154",
    zip_name: "raymond, ms",
  },
  {
    postal_code: "93647",
    zip_name: "orosi, ca",
  },
  {
    postal_code: "46147",
    zip_name: "jamestown, in",
  },
  {
    postal_code: "76444",
    zip_name: "de leon, tx",
  },
  {
    postal_code: "10566",
    zip_name: "peekskill, ny",
  },
  {
    postal_code: "15825",
    zip_name: "brookville, pa",
  },
  {
    postal_code: "30543",
    zip_name: "gillsville, ga",
  },
  {
    postal_code: "27809",
    zip_name: "battleboro, nc",
  },
  {
    postal_code: "11596",
    zip_name: "williston park, ny",
  },
  {
    postal_code: "11370",
    zip_name: "east elmhurst, ny",
  },
  {
    postal_code: "6378",
    zip_name: "stonington, ct",
  },
  {
    postal_code: "2745",
    zip_name: "new bedford, ma",
  },
  {
    postal_code: "41129",
    zip_name: "catlettsburg, ky",
  },
  {
    postal_code: "95954",
    zip_name: "magalia, ca",
  },
  {
    postal_code: "92241",
    zip_name: "desert hot springs, ca",
  },
  {
    postal_code: "43777",
    zip_name: "roseville, oh",
  },
  {
    postal_code: "79065",
    zip_name: "pampa, tx",
  },
  {
    postal_code: "68355",
    zip_name: "falls city, ne",
  },
  {
    postal_code: "72651",
    zip_name: "midway, ar",
  },
  {
    postal_code: "67124",
    zip_name: "pratt, ks",
  },
  {
    postal_code: "10461",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "14891",
    zip_name: "watkins glen, ny",
  },
  {
    postal_code: "40337",
    zip_name: "jeffersonville, ky",
  },
  {
    postal_code: "44262",
    zip_name: "munroe falls, oh",
  },
  {
    postal_code: "20117",
    zip_name: "middleburg, va",
  },
  {
    postal_code: "44672",
    zip_name: "sebring, oh",
  },
  {
    postal_code: "18517",
    zip_name: "taylor, pa",
  },
  {
    postal_code: "72067",
    zip_name: "higden, ar",
  },
  {
    postal_code: "60145",
    zip_name: "kingston, il",
  },
  {
    postal_code: "46750",
    zip_name: "huntington, in",
  },
  {
    postal_code: "48658",
    zip_name: "standish, mi",
  },
  {
    postal_code: "27883",
    zip_name: "stantonsburg, nc",
  },
  {
    postal_code: "83252",
    zip_name: "malad city, id",
  },
  {
    postal_code: "90248",
    zip_name: "gardena, ca",
  },
  {
    postal_code: "45873",
    zip_name: "oakwood, oh",
  },
  {
    postal_code: "43344",
    zip_name: "richwood, oh",
  },
  {
    postal_code: "55043",
    zip_name: "lakeland, mn",
  },
  {
    postal_code: "76539",
    zip_name: "kempner, tx",
  },
  {
    postal_code: "98936",
    zip_name: "moxee, wa",
  },
  {
    postal_code: "56621",
    zip_name: "bagley, mn",
  },
  {
    postal_code: "96781",
    zip_name: "papaikou, hi",
  },
  {
    postal_code: "95346",
    zip_name: "mi wuk village, ca",
  },
  {
    postal_code: "55733",
    zip_name: "esko, mn",
  },
  {
    postal_code: "22644",
    zip_name: "maurertown, va",
  },
  {
    postal_code: "13061",
    zip_name: "erieville, ny",
  },
  {
    postal_code: "66214",
    zip_name: "overland park, ks",
  },
  {
    postal_code: "42348",
    zip_name: "hawesville, ky",
  },
  {
    postal_code: "81242",
    zip_name: "poncha springs, co",
  },
  {
    postal_code: "70548",
    zip_name: "kaplan, la",
  },
  {
    postal_code: "95638",
    zip_name: "herald, ca",
  },
  {
    postal_code: "54539",
    zip_name: "lake tomahawk, wi",
  },
  {
    postal_code: "3784",
    zip_name: "west lebanon, nh",
  },
  {
    postal_code: "12944",
    zip_name: "keeseville, ny",
  },
  {
    postal_code: "17063",
    zip_name: "milroy, pa",
  },
  {
    postal_code: "80536",
    zip_name: "livermore, co",
  },
  {
    postal_code: "18447",
    zip_name: "olyphant, pa",
  },
  {
    postal_code: "92861",
    zip_name: "villa park, ca",
  },
  {
    postal_code: "37035",
    zip_name: "chapmansboro, tn",
  },
  {
    postal_code: "1257",
    zip_name: "sheffield, ma",
  },
  {
    postal_code: "22620",
    zip_name: "boyce, va",
  },
  {
    postal_code: "2043",
    zip_name: "hingham, ma",
  },
  {
    postal_code: "2421",
    zip_name: "lexington, ma",
  },
  {
    postal_code: "92305",
    zip_name: "angelus oaks, ca",
  },
  {
    postal_code: "32465",
    zip_name: "wewahitchka, fl",
  },
  {
    postal_code: "30206",
    zip_name: "concord, ga",
  },
  {
    postal_code: "30178",
    zip_name: "taylorsville, ga",
  },
  {
    postal_code: "40055",
    zip_name: "pendleton, ky",
  },
  {
    postal_code: "61342",
    zip_name: "mendota, il",
  },
  {
    postal_code: "44620",
    zip_name: "dellroy, oh",
  },
  {
    postal_code: "2125",
    zip_name: "dorchester, ma",
  },
  {
    postal_code: "30415",
    zip_name: "brooklet, ga",
  },
  {
    postal_code: "11530",
    zip_name: "garden city, ny",
  },
  {
    postal_code: "24092",
    zip_name: "glade hill, va",
  },
  {
    postal_code: "4076",
    zip_name: "shapleigh, me",
  },
  {
    postal_code: "13319",
    zip_name: "chadwicks, ny",
  },
  {
    postal_code: "16217",
    zip_name: "cooksburg, pa",
  },
  {
    postal_code: "95258",
    zip_name: "woodbridge, ca",
  },
  {
    postal_code: "25827",
    zip_name: "crab orchard, wv",
  },
  {
    postal_code: "42372",
    zip_name: "sacramento, ky",
  },
  {
    postal_code: "75423",
    zip_name: "celeste, tx",
  },
  {
    postal_code: "45302",
    zip_name: "anna, oh",
  },
  {
    postal_code: "23106",
    zip_name: "manquin, va",
  },
  {
    postal_code: "44275",
    zip_name: "spencer, oh",
  },
  {
    postal_code: "43756",
    zip_name: "mcconnelsville, oh",
  },
  {
    postal_code: "3036",
    zip_name: "chester, nh",
  },
  {
    postal_code: "95328",
    zip_name: "keyes, ca",
  },
  {
    postal_code: "32062",
    zip_name: "mc alpin, fl",
  },
  {
    postal_code: "46030",
    zip_name: "arcadia, in",
  },
  {
    postal_code: "76055",
    zip_name: "itasca, tx",
  },
  {
    postal_code: "98948",
    zip_name: "toppenish, wa",
  },
  {
    postal_code: "17406",
    zip_name: "york, pa",
  },
  {
    postal_code: "40006",
    zip_name: "bedford, ky",
  },
  {
    postal_code: "15026",
    zip_name: "clinton, pa",
  },
  {
    postal_code: "43460",
    zip_name: "rossford, oh",
  },
  {
    postal_code: "91201",
    zip_name: "glendale, ca",
  },
  {
    postal_code: "92058",
    zip_name: "oceanside, ca",
  },
  {
    postal_code: "48471",
    zip_name: "sandusky, mi",
  },
  {
    postal_code: "3603",
    zip_name: "charlestown, nh",
  },
  {
    postal_code: "12444",
    zip_name: "jewett, ny",
  },
  {
    postal_code: "62239",
    zip_name: "dupo, il",
  },
  {
    postal_code: "32040",
    zip_name: "glen saint mary, fl",
  },
  {
    postal_code: "1013",
    zip_name: "chicopee, ma",
  },
  {
    postal_code: "49267",
    zip_name: "ottawa lake, mi",
  },
  {
    postal_code: "50247",
    zip_name: "state center, ia",
  },
  {
    postal_code: "48134",
    zip_name: "flat rock, mi",
  },
  {
    postal_code: "54949",
    zip_name: "manawa, wi",
  },
  {
    postal_code: "27509",
    zip_name: "butner, nc",
  },
  {
    postal_code: "1982",
    zip_name: "south hamilton, ma",
  },
  {
    postal_code: "5345",
    zip_name: "newfane, vt",
  },
  {
    postal_code: "45118",
    zip_name: "fayetteville, oh",
  },
  {
    postal_code: "56374",
    zip_name: "saint joseph, mn",
  },
  {
    postal_code: "72644",
    zip_name: "lead hill, ar",
  },
  {
    postal_code: "13459",
    zip_name: "sharon springs, ny",
  },
  {
    postal_code: "33194",
    zip_name: "miami, fl",
  },
  {
    postal_code: "95254",
    zip_name: "wallace, ca",
  },
  {
    postal_code: "18840",
    zip_name: "sayre, pa",
  },
  {
    postal_code: "40322",
    zip_name: "frenchburg, ky",
  },
  {
    postal_code: "41006",
    zip_name: "butler, ky",
  },
  {
    postal_code: "47460",
    zip_name: "spencer, in",
  },
  {
    postal_code: "10466",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "21623",
    zip_name: "church hill, md",
  },
  {
    postal_code: "36037",
    zip_name: "greenville, al",
  },
  {
    postal_code: "57069",
    zip_name: "vermillion, sd",
  },
  {
    postal_code: "52002",
    zip_name: "dubuque, ia",
  },
  {
    postal_code: "52339",
    zip_name: "tama, ia",
  },
  {
    postal_code: "15314",
    zip_name: "bentleyville, pa",
  },
  {
    postal_code: "95633",
    zip_name: "garden valley, ca",
  },
  {
    postal_code: "2827",
    zip_name: "greene, ri",
  },
  {
    postal_code: "62231",
    zip_name: "carlyle, il",
  },
  {
    postal_code: "49326",
    zip_name: "gowen, mi",
  },
  {
    postal_code: "70052",
    zip_name: "gramercy, la",
  },
  {
    postal_code: "65325",
    zip_name: "cole camp, mo",
  },
  {
    postal_code: "50643",
    zip_name: "hudson, ia",
  },
  {
    postal_code: "18077",
    zip_name: "riegelsville, pa",
  },
  {
    postal_code: "78383",
    zip_name: "sandia, tx",
  },
  {
    postal_code: "27557",
    zip_name: "middlesex, nc",
  },
  {
    postal_code: "68739",
    zip_name: "hartington, ne",
  },
  {
    postal_code: "18942",
    zip_name: "ottsville, pa",
  },
  {
    postal_code: "5450",
    zip_name: "enosburg falls, vt",
  },
  {
    postal_code: "61530",
    zip_name: "eureka, il",
  },
  {
    postal_code: "33471",
    zip_name: "moore haven, fl",
  },
  {
    postal_code: "60203",
    zip_name: "evanston, il",
  },
  {
    postal_code: "87529",
    zip_name: "el prado, nm",
  },
  {
    postal_code: "67067",
    zip_name: "kechi, ks",
  },
  {
    postal_code: "2359",
    zip_name: "pembroke, ma",
  },
  {
    postal_code: "27943",
    zip_name: "hatteras, nc",
  },
  {
    postal_code: "12764",
    zip_name: "narrowsburg, ny",
  },
  {
    postal_code: "1543",
    zip_name: "rutland, ma",
  },
  {
    postal_code: "95728",
    zip_name: "soda springs, ca",
  },
  {
    postal_code: "43465",
    zip_name: "walbridge, oh",
  },
  {
    postal_code: "12066",
    zip_name: "esperance, ny",
  },
  {
    postal_code: "56542",
    zip_name: "fosston, mn",
  },
  {
    postal_code: "56072",
    zip_name: "new richland, mn",
  },
  {
    postal_code: "27973",
    zip_name: "shawboro, nc",
  },
  {
    postal_code: "48892",
    zip_name: "webberville, mi",
  },
  {
    postal_code: "4084",
    zip_name: "standish, me",
  },
  {
    postal_code: "98592",
    zip_name: "union, wa",
  },
  {
    postal_code: "44076",
    zip_name: "orwell, oh",
  },
  {
    postal_code: "53960",
    zip_name: "rio, wi",
  },
  {
    postal_code: "27503",
    zip_name: "bahama, nc",
  },
  {
    postal_code: "80545",
    zip_name: "red feather lakes, co",
  },
  {
    postal_code: "68523",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "50236",
    zip_name: "roland, ia",
  },
  {
    postal_code: "3229",
    zip_name: "contoocook, nh",
  },
  {
    postal_code: "49238",
    zip_name: "deerfield, mi",
  },
  {
    postal_code: "67546",
    zip_name: "inman, ks",
  },
  {
    postal_code: "56649",
    zip_name: "international falls, mn",
  },
  {
    postal_code: "22920",
    zip_name: "afton, va",
  },
  {
    postal_code: "53549",
    zip_name: "jefferson, wi",
  },
  {
    postal_code: "94704",
    zip_name: "berkeley, ca",
  },
  {
    postal_code: "55812",
    zip_name: "duluth, mn",
  },
  {
    postal_code: "94930",
    zip_name: "fairfax, ca",
  },
  {
    postal_code: "21111",
    zip_name: "monkton, md",
  },
  {
    postal_code: "15424",
    zip_name: "confluence, pa",
  },
  {
    postal_code: "1930",
    zip_name: "gloucester, ma",
  },
  {
    postal_code: "1075",
    zip_name: "south hadley, ma",
  },
  {
    postal_code: "48170",
    zip_name: "plymouth, mi",
  },
  {
    postal_code: "44251",
    zip_name: "westfield center, oh",
  },
  {
    postal_code: "61605",
    zip_name: "peoria, il",
  },
  {
    postal_code: "96001",
    zip_name: "redding, ca",
  },
  {
    postal_code: "8210",
    zip_name: "cape may court house, nj",
  },
  {
    postal_code: "6710",
    zip_name: "waterbury, ct",
  },
  {
    postal_code: "35553",
    zip_name: "double springs, al",
  },
  {
    postal_code: "3251",
    zip_name: "lincoln, nh",
  },
  {
    postal_code: "10462",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "19440",
    zip_name: "hatfield, pa",
  },
  {
    postal_code: "2093",
    zip_name: "wrentham, ma",
  },
  {
    postal_code: "18102",
    zip_name: "allentown, pa",
  },
  {
    postal_code: "92561",
    zip_name: "mountain center, ca",
  },
  {
    postal_code: "12446",
    zip_name: "kerhonkson, ny",
  },
  {
    postal_code: "12125",
    zip_name: "new lebanon, ny",
  },
  {
    postal_code: "75125",
    zip_name: "ferris, tx",
  },
  {
    postal_code: "15622",
    zip_name: "champion, pa",
  },
  {
    postal_code: "95642",
    zip_name: "jackson, ca",
  },
  {
    postal_code: "85735",
    zip_name: "tucson, az",
  },
  {
    postal_code: "86332",
    zip_name: "kirkland, az",
  },
  {
    postal_code: "97123",
    zip_name: "hillsboro, or",
  },
  {
    postal_code: "25438",
    zip_name: "ranson, wv",
  },
  {
    postal_code: "2476",
    zip_name: "arlington, ma",
  },
  {
    postal_code: "4032",
    zip_name: "freeport, me",
  },
  {
    postal_code: "29654",
    zip_name: "honea path, sc",
  },
  {
    postal_code: "59920",
    zip_name: "kila, mt",
  },
  {
    postal_code: "20622",
    zip_name: "charlotte hall, md",
  },
  {
    postal_code: "93022",
    zip_name: "oak view, ca",
  },
  {
    postal_code: "46118",
    zip_name: "clayton, in",
  },
  {
    postal_code: "6441",
    zip_name: "higganum, ct",
  },
  {
    postal_code: "27880",
    zip_name: "sims, nc",
  },
  {
    postal_code: "3811",
    zip_name: "atkinson, nh",
  },
  {
    postal_code: "85362",
    zip_name: "yarnell, az",
  },
  {
    postal_code: "75163",
    zip_name: "trinidad, tx",
  },
  {
    postal_code: "28530",
    zip_name: "grifton, nc",
  },
  {
    postal_code: "19035",
    zip_name: "gladwyne, pa",
  },
  {
    postal_code: "1701",
    zip_name: "framingham, ma",
  },
  {
    postal_code: "13811",
    zip_name: "newark valley, ny",
  },
  {
    postal_code: "67554",
    zip_name: "lyons, ks",
  },
  {
    postal_code: "84101",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "2330",
    zip_name: "carver, ma",
  },
  {
    postal_code: "38632",
    zip_name: "hernando, ms",
  },
  {
    postal_code: "28071",
    zip_name: "gold hill, nc",
  },
  {
    postal_code: "28478",
    zip_name: "willard, nc",
  },
  {
    postal_code: "16134",
    zip_name: "jamestown, pa",
  },
  {
    postal_code: "64020",
    zip_name: "concordia, mo",
  },
  {
    postal_code: "39114",
    zip_name: "mendenhall, ms",
  },
  {
    postal_code: "56501",
    zip_name: "detroit lakes, mn",
  },
  {
    postal_code: "96148",
    zip_name: "tahoe vista, ca",
  },
  {
    postal_code: "21154",
    zip_name: "street, md",
  },
  {
    postal_code: "23606",
    zip_name: "newport news, va",
  },
  {
    postal_code: "2445",
    zip_name: "brookline, ma",
  },
  {
    postal_code: "77651",
    zip_name: "port neches, tx",
  },
  {
    postal_code: "4981",
    zip_name: "stockton springs, me",
  },
  {
    postal_code: "27343",
    zip_name: "semora, nc",
  },
  {
    postal_code: "59729",
    zip_name: "ennis, mt",
  },
  {
    postal_code: "96003",
    zip_name: "redding, ca",
  },
  {
    postal_code: "47150",
    zip_name: "new albany, in",
  },
  {
    postal_code: "61610",
    zip_name: "creve coeur, il",
  },
  {
    postal_code: "28690",
    zip_name: "valdese, nc",
  },
  {
    postal_code: "18346",
    zip_name: "pocono summit, pa",
  },
  {
    postal_code: "38221",
    zip_name: "big sandy, tn",
  },
  {
    postal_code: "81427",
    zip_name: "ouray, co",
  },
  {
    postal_code: "96093",
    zip_name: "weaverville, ca",
  },
  {
    postal_code: "75140",
    zip_name: "grand saline, tx",
  },
  {
    postal_code: "98683",
    zip_name: "vancouver, wa",
  },
  {
    postal_code: "15045",
    zip_name: "glassport, pa",
  },
  {
    postal_code: "27936",
    zip_name: "frisco, nc",
  },
  {
    postal_code: "62441",
    zip_name: "marshall, il",
  },
  {
    postal_code: "43107",
    zip_name: "bremen, oh",
  },
  {
    postal_code: "17702",
    zip_name: "williamsport, pa",
  },
  {
    postal_code: "55734",
    zip_name: "eveleth, mn",
  },
  {
    postal_code: "25045",
    zip_name: "clendenin, wv",
  },
  {
    postal_code: "92024",
    zip_name: "encinitas, ca",
  },
  {
    postal_code: "40150",
    zip_name: "lebanon junction, ky",
  },
  {
    postal_code: "56347",
    zip_name: "long prairie, mn",
  },
  {
    postal_code: "29510",
    zip_name: "andrews, sc",
  },
  {
    postal_code: "75931",
    zip_name: "brookeland, tx",
  },
  {
    postal_code: "60420",
    zip_name: "dwight, il",
  },
  {
    postal_code: "45338",
    zip_name: "lewisburg, oh",
  },
  {
    postal_code: "32181",
    zip_name: "pomona park, fl",
  },
  {
    postal_code: "22712",
    zip_name: "bealeton, va",
  },
  {
    postal_code: "72687",
    zip_name: "yellville, ar",
  },
  {
    postal_code: "61606",
    zip_name: "peoria, il",
  },
  {
    postal_code: "76704",
    zip_name: "waco, tx",
  },
  {
    postal_code: "1742",
    zip_name: "concord, ma",
  },
  {
    postal_code: "23153",
    zip_name: "sandy hook, va",
  },
  {
    postal_code: "70359",
    zip_name: "gray, la",
  },
  {
    postal_code: "77534",
    zip_name: "danbury, tx",
  },
  {
    postal_code: "2837",
    zip_name: "little compton, ri",
  },
  {
    postal_code: "62293",
    zip_name: "trenton, il",
  },
  {
    postal_code: "28572",
    zip_name: "pink hill, nc",
  },
  {
    postal_code: "55089",
    zip_name: "welch, mn",
  },
  {
    postal_code: "30572",
    zip_name: "suches, ga",
  },
  {
    postal_code: "98674",
    zip_name: "woodland, wa",
  },
  {
    postal_code: "97140",
    zip_name: "sherwood, or",
  },
  {
    postal_code: "44065",
    zip_name: "newbury, oh",
  },
  {
    postal_code: "14136",
    zip_name: "silver creek, ny",
  },
  {
    postal_code: "3237",
    zip_name: "gilmanton, nh",
  },
  {
    postal_code: "51601",
    zip_name: "shenandoah, ia",
  },
  {
    postal_code: "55947",
    zip_name: "la crescent, mn",
  },
  {
    postal_code: "13830",
    zip_name: "oxford, ny",
  },
  {
    postal_code: "43164",
    zip_name: "williamsport, oh",
  },
  {
    postal_code: "41030",
    zip_name: "crittenden, ky",
  },
  {
    postal_code: "29714",
    zip_name: "fort lawn, sc",
  },
  {
    postal_code: "19107",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "33924",
    zip_name: "captiva, fl",
  },
  {
    postal_code: "12566",
    zip_name: "pine bush, ny",
  },
  {
    postal_code: "97478",
    zip_name: "springfield, or",
  },
  {
    postal_code: "6412",
    zip_name: "chester, ct",
  },
  {
    postal_code: "94301",
    zip_name: "palo alto, ca",
  },
  {
    postal_code: "13480",
    zip_name: "waterville, ny",
  },
  {
    postal_code: "27573",
    zip_name: "roxboro, nc",
  },
  {
    postal_code: "56482",
    zip_name: "wadena, mn",
  },
  {
    postal_code: "79911",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "36272",
    zip_name: "piedmont, al",
  },
  {
    postal_code: "56466",
    zip_name: "motley, mn",
  },
  {
    postal_code: "63089",
    zip_name: "villa ridge, mo",
  },
  {
    postal_code: "66219",
    zip_name: "lenexa, ks",
  },
  {
    postal_code: "38357",
    zip_name: "michie, tn",
  },
  {
    postal_code: "1721",
    zip_name: "ashland, ma",
  },
  {
    postal_code: "76457",
    zip_name: "hico, tx",
  },
  {
    postal_code: "36322",
    zip_name: "daleville, al",
  },
  {
    postal_code: "12917",
    zip_name: "burke, ny",
  },
  {
    postal_code: "31601",
    zip_name: "valdosta, ga",
  },
  {
    postal_code: "65041",
    zip_name: "hermann, mo",
  },
  {
    postal_code: "39051",
    zip_name: "carthage, ms",
  },
  {
    postal_code: "44707",
    zip_name: "canton, oh",
  },
  {
    postal_code: "6752",
    zip_name: "bridgewater, ct",
  },
  {
    postal_code: "15323",
    zip_name: "claysville, pa",
  },
  {
    postal_code: "19007",
    zip_name: "bristol, pa",
  },
  {
    postal_code: "7417",
    zip_name: "franklin lakes, nj",
  },
  {
    postal_code: "92865",
    zip_name: "orange, ca",
  },
  {
    postal_code: "3598",
    zip_name: "whitefield, nh",
  },
  {
    postal_code: "3226",
    zip_name: "center harbor, nh",
  },
  {
    postal_code: "5262",
    zip_name: "shaftsbury, vt",
  },
  {
    postal_code: "4856",
    zip_name: "rockport, me",
  },
  {
    postal_code: "25918",
    zip_name: "shady spring, wv",
  },
  {
    postal_code: "49866",
    zip_name: "negaunee, mi",
  },
  {
    postal_code: "59864",
    zip_name: "ronan, mt",
  },
  {
    postal_code: "78010",
    zip_name: "center point, tx",
  },
  {
    postal_code: "44403",
    zip_name: "brookfield, oh",
  },
  {
    postal_code: "90005",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "2720",
    zip_name: "fall river, ma",
  },
  {
    postal_code: "86017",
    zip_name: "munds park, az",
  },
  {
    postal_code: "23030",
    zip_name: "charles city, va",
  },
  {
    postal_code: "16226",
    zip_name: "ford city, pa",
  },
  {
    postal_code: "36732",
    zip_name: "demopolis, al",
  },
  {
    postal_code: "47001",
    zip_name: "aurora, in",
  },
  {
    postal_code: "93271",
    zip_name: "three rivers, ca",
  },
  {
    postal_code: "44429",
    zip_name: "lake milton, oh",
  },
  {
    postal_code: "30176",
    zip_name: "tallapoosa, ga",
  },
  {
    postal_code: "42071",
    zip_name: "murray, ky",
  },
  {
    postal_code: "20851",
    zip_name: "rockville, md",
  },
  {
    postal_code: "56444",
    zip_name: "deerwood, mn",
  },
  {
    postal_code: "56310",
    zip_name: "avon, mn",
  },
  {
    postal_code: "13743",
    zip_name: "candor, ny",
  },
  {
    postal_code: "33973",
    zip_name: "lehigh acres, fl",
  },
  {
    postal_code: "21226",
    zip_name: "curtis bay, md",
  },
  {
    postal_code: "30625",
    zip_name: "buckhead, ga",
  },
  {
    postal_code: "28168",
    zip_name: "vale, nc",
  },
  {
    postal_code: "66217",
    zip_name: "shawnee, ks",
  },
  {
    postal_code: "80813",
    zip_name: "cripple creek, co",
  },
  {
    postal_code: "93501",
    zip_name: "mojave, ca",
  },
  {
    postal_code: "89447",
    zip_name: "yerington, nv",
  },
  {
    postal_code: "49858",
    zip_name: "menominee, mi",
  },
  {
    postal_code: "11767",
    zip_name: "nesconset, ny",
  },
  {
    postal_code: "1867",
    zip_name: "reading, ma",
  },
  {
    postal_code: "18017",
    zip_name: "bethlehem, pa",
  },
  {
    postal_code: "2124",
    zip_name: "dorchester center, ma",
  },
  {
    postal_code: "31643",
    zip_name: "quitman, ga",
  },
  {
    postal_code: "91911",
    zip_name: "chula vista, ca",
  },
  {
    postal_code: "25276",
    zip_name: "spencer, wv",
  },
  {
    postal_code: "12589",
    zip_name: "wallkill, ny",
  },
  {
    postal_code: "5733",
    zip_name: "brandon, vt",
  },
  {
    postal_code: "40962",
    zip_name: "manchester, ky",
  },
  {
    postal_code: "85624",
    zip_name: "patagonia, az",
  },
  {
    postal_code: "72830",
    zip_name: "clarksville, ar",
  },
  {
    postal_code: "13114",
    zip_name: "mexico, ny",
  },
  {
    postal_code: "55001",
    zip_name: "afton, mn",
  },
  {
    postal_code: "3033",
    zip_name: "brookline, nh",
  },
  {
    postal_code: "5661",
    zip_name: "morrisville, vt",
  },
  {
    postal_code: "32189",
    zip_name: "satsuma, fl",
  },
  {
    postal_code: "3846",
    zip_name: "jackson, nh",
  },
  {
    postal_code: "27842",
    zip_name: "henrico, nc",
  },
  {
    postal_code: "79347",
    zip_name: "muleshoe, tx",
  },
  {
    postal_code: "8810",
    zip_name: "dayton, nj",
  },
  {
    postal_code: "44644",
    zip_name: "malvern, oh",
  },
  {
    postal_code: "48166",
    zip_name: "newport, mi",
  },
  {
    postal_code: "4843",
    zip_name: "camden, me",
  },
  {
    postal_code: "4103",
    zip_name: "portland, me",
  },
  {
    postal_code: "52246",
    zip_name: "iowa city, ia",
  },
  {
    postal_code: "22311",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "11559",
    zip_name: "lawrence, ny",
  },
  {
    postal_code: "43910",
    zip_name: "bloomingdale, oh",
  },
  {
    postal_code: "84317",
    zip_name: "huntsville, ut",
  },
  {
    postal_code: "90017",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "27921",
    zip_name: "camden, nc",
  },
  {
    postal_code: "22567",
    zip_name: "unionville, va",
  },
  {
    postal_code: "75792",
    zip_name: "winona, tx",
  },
  {
    postal_code: "77587",
    zip_name: "south houston, tx",
  },
  {
    postal_code: "98328",
    zip_name: "eatonville, wa",
  },
  {
    postal_code: "22727",
    zip_name: "madison, va",
  },
  {
    postal_code: "3244",
    zip_name: "hillsborough, nh",
  },
  {
    postal_code: "17110",
    zip_name: "harrisburg, pa",
  },
  {
    postal_code: "37219",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "64123",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "21029",
    zip_name: "clarksville, md",
  },
  {
    postal_code: "48450",
    zip_name: "lexington, mi",
  },
  {
    postal_code: "81413",
    zip_name: "cedaredge, co",
  },
  {
    postal_code: "11411",
    zip_name: "cambria heights, ny",
  },
  {
    postal_code: "17601",
    zip_name: "lancaster, pa",
  },
  {
    postal_code: "56461",
    zip_name: "laporte, mn",
  },
  {
    postal_code: "12583",
    zip_name: "tivoli, ny",
  },
  {
    postal_code: "2118",
    zip_name: "boston, ma",
  },
  {
    postal_code: "11429",
    zip_name: "queens village, ny",
  },
  {
    postal_code: "2668",
    zip_name: "west barnstable, ma",
  },
  {
    postal_code: "37820",
    zip_name: "new market, tn",
  },
  {
    postal_code: "3037",
    zip_name: "deerfield, nh",
  },
  {
    postal_code: "55972",
    zip_name: "saint charles, mn",
  },
  {
    postal_code: "53594",
    zip_name: "waterloo, wi",
  },
  {
    postal_code: "31820",
    zip_name: "midland, ga",
  },
  {
    postal_code: "46126",
    zip_name: "fairland, in",
  },
  {
    postal_code: "13637",
    zip_name: "evans mills, ny",
  },
  {
    postal_code: "75752",
    zip_name: "athens, tx",
  },
  {
    postal_code: "4462",
    zip_name: "millinocket, me",
  },
  {
    postal_code: "96754",
    zip_name: "kilauea, hi",
  },
  {
    postal_code: "66049",
    zip_name: "lawrence, ks",
  },
  {
    postal_code: "45174",
    zip_name: "terrace park, oh",
  },
  {
    postal_code: "46204",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "12817",
    zip_name: "chestertown, ny",
  },
  {
    postal_code: "45142",
    zip_name: "lynchburg, oh",
  },
  {
    postal_code: "48722",
    zip_name: "bridgeport, mi",
  },
  {
    postal_code: "53933",
    zip_name: "fox lake, wi",
  },
  {
    postal_code: "4572",
    zip_name: "waldoboro, me",
  },
  {
    postal_code: "46408",
    zip_name: "gary, in",
  },
  {
    postal_code: "29477",
    zip_name: "saint george, sc",
  },
  {
    postal_code: "22202",
    zip_name: "arlington, va",
  },
  {
    postal_code: "98372",
    zip_name: "puyallup, wa",
  },
  {
    postal_code: "66743",
    zip_name: "girard, ks",
  },
  {
    postal_code: "17603",
    zip_name: "lancaster, pa",
  },
  {
    postal_code: "27306",
    zip_name: "mount gilead, nc",
  },
  {
    postal_code: "92011",
    zip_name: "carlsbad, ca",
  },
  {
    postal_code: "2138",
    zip_name: "cambridge, ma",
  },
  {
    postal_code: "39483",
    zip_name: "foxworth, ms",
  },
  {
    postal_code: "50237",
    zip_name: "runnells, ia",
  },
  {
    postal_code: "3221",
    zip_name: "bradford, nh",
  },
  {
    postal_code: "77657",
    zip_name: "lumberton, tx",
  },
  {
    postal_code: "97086",
    zip_name: "happy valley, or",
  },
  {
    postal_code: "30621",
    zip_name: "bishop, ga",
  },
  {
    postal_code: "10509",
    zip_name: "brewster, ny",
  },
  {
    postal_code: "81527",
    zip_name: "whitewater, co",
  },
  {
    postal_code: "55964",
    zip_name: "plainview, mn",
  },
  {
    postal_code: "13343",
    zip_name: "glenfield, ny",
  },
  {
    postal_code: "98380",
    zip_name: "seabeck, wa",
  },
  {
    postal_code: "97013",
    zip_name: "canby, or",
  },
  {
    postal_code: "56438",
    zip_name: "browerville, mn",
  },
  {
    postal_code: "15320",
    zip_name: "carmichaels, pa",
  },
  {
    postal_code: "62018",
    zip_name: "cottage hills, il",
  },
  {
    postal_code: "28395",
    zip_name: "wade, nc",
  },
  {
    postal_code: "5059",
    zip_name: "quechee, vt",
  },
  {
    postal_code: "34475",
    zip_name: "ocala, fl",
  },
  {
    postal_code: "11724",
    zip_name: "cold spring harbor, ny",
  },
  {
    postal_code: "15110",
    zip_name: "duquesne, pa",
  },
  {
    postal_code: "11563",
    zip_name: "lynbrook, ny",
  },
  {
    postal_code: "11741",
    zip_name: "holbrook, ny",
  },
  {
    postal_code: "64048",
    zip_name: "holt, mo",
  },
  {
    postal_code: "61348",
    zip_name: "oglesby, il",
  },
  {
    postal_code: "46034",
    zip_name: "cicero, in",
  },
  {
    postal_code: "2038",
    zip_name: "franklin, ma",
  },
  {
    postal_code: "46403",
    zip_name: "gary, in",
  },
  {
    postal_code: "41144",
    zip_name: "greenup, ky",
  },
  {
    postal_code: "21524",
    zip_name: "corriganville, md",
  },
  {
    postal_code: "92823",
    zip_name: "brea, ca",
  },
  {
    postal_code: "82831",
    zip_name: "arvada, wy",
  },
  {
    postal_code: "5060",
    zip_name: "randolph, vt",
  },
  {
    postal_code: "96064",
    zip_name: "montague, ca",
  },
  {
    postal_code: "49868",
    zip_name: "newberry, mi",
  },
  {
    postal_code: "62995",
    zip_name: "vienna, il",
  },
  {
    postal_code: "49095",
    zip_name: "vandalia, mi",
  },
  {
    postal_code: "19365",
    zip_name: "parkesburg, pa",
  },
  {
    postal_code: "43351",
    zip_name: "upper sandusky, oh",
  },
  {
    postal_code: "84071",
    zip_name: "stockton, ut",
  },
  {
    postal_code: "95005",
    zip_name: "ben lomond, ca",
  },
  {
    postal_code: "4038",
    zip_name: "gorham, me",
  },
  {
    postal_code: "55020",
    zip_name: "elko new market, mn",
  },
  {
    postal_code: "2356",
    zip_name: "north easton, ma",
  },
  {
    postal_code: "53503",
    zip_name: "arena, wi",
  },
  {
    postal_code: "18519",
    zip_name: "scranton, pa",
  },
  {
    postal_code: "55612",
    zip_name: "lutsen, mn",
  },
  {
    postal_code: "19341",
    zip_name: "exton, pa",
  },
  {
    postal_code: "78203",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "18944",
    zip_name: "perkasie, pa",
  },
  {
    postal_code: "97446",
    zip_name: "harrisburg, or",
  },
  {
    postal_code: "3263",
    zip_name: "pittsfield, nh",
  },
  {
    postal_code: "4841",
    zip_name: "rockland, me",
  },
  {
    postal_code: "54001",
    zip_name: "amery, wi",
  },
  {
    postal_code: "12175",
    zip_name: "summit, ny",
  },
  {
    postal_code: "12962",
    zip_name: "morrisonville, ny",
  },
  {
    postal_code: "29059",
    zip_name: "holly hill, sc",
  },
  {
    postal_code: "19938",
    zip_name: "clayton, de",
  },
  {
    postal_code: "55054",
    zip_name: "elko new market, mn",
  },
  {
    postal_code: "24381",
    zip_name: "woodlawn, va",
  },
  {
    postal_code: "32666",
    zip_name: "melrose, fl",
  },
  {
    postal_code: "37841",
    zip_name: "oneida, tn",
  },
  {
    postal_code: "3608",
    zip_name: "walpole, nh",
  },
  {
    postal_code: "12116",
    zip_name: "maryland, ny",
  },
  {
    postal_code: "12138",
    zip_name: "petersburg, ny",
  },
  {
    postal_code: "19078",
    zip_name: "ridley park, pa",
  },
  {
    postal_code: "38544",
    zip_name: "baxter, tn",
  },
  {
    postal_code: "24368",
    zip_name: "rural retreat, va",
  },
  {
    postal_code: "84318",
    zip_name: "hyde park, ut",
  },
  {
    postal_code: "60468",
    zip_name: "peotone, il",
  },
  {
    postal_code: "75144",
    zip_name: "kerens, tx",
  },
  {
    postal_code: "62286",
    zip_name: "sparta, il",
  },
  {
    postal_code: "64503",
    zip_name: "saint joseph, mo",
  },
  {
    postal_code: "89029",
    zip_name: "laughlin, nv",
  },
  {
    postal_code: "53190",
    zip_name: "whitewater, wi",
  },
  {
    postal_code: "45660",
    zip_name: "peebles, oh",
  },
  {
    postal_code: "48875",
    zip_name: "portland, mi",
  },
  {
    postal_code: "26501",
    zip_name: "morgantown, wv",
  },
  {
    postal_code: "68104",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "1056",
    zip_name: "ludlow, ma",
  },
  {
    postal_code: "93243",
    zip_name: "lebec, ca",
  },
  {
    postal_code: "32145",
    zip_name: "hastings, fl",
  },
  {
    postal_code: "94123",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "1095",
    zip_name: "wilbraham, ma",
  },
  {
    postal_code: "56716",
    zip_name: "crookston, mn",
  },
  {
    postal_code: "7728",
    zip_name: "freehold, nj",
  },
  {
    postal_code: "64801",
    zip_name: "joplin, mo",
  },
  {
    postal_code: "10034",
    zip_name: "new york, ny",
  },
  {
    postal_code: "98675",
    zip_name: "yacolt, wa",
  },
  {
    postal_code: "95121",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "55322",
    zip_name: "cologne, mn",
  },
  {
    postal_code: "62097",
    zip_name: "worden, il",
  },
  {
    postal_code: "32113",
    zip_name: "citra, fl",
  },
  {
    postal_code: "54889",
    zip_name: "turtle lake, wi",
  },
  {
    postal_code: "78575",
    zip_name: "olmito, tx",
  },
  {
    postal_code: "39209",
    zip_name: "jackson, ms",
  },
  {
    postal_code: "61046",
    zip_name: "lanark, il",
  },
  {
    postal_code: "18425",
    zip_name: "greeley, pa",
  },
  {
    postal_code: "92590",
    zip_name: "temecula, ca",
  },
  {
    postal_code: "8559",
    zip_name: "stockton, nj",
  },
  {
    postal_code: "35147",
    zip_name: "sterrett, al",
  },
  {
    postal_code: "70087",
    zip_name: "saint rose, la",
  },
  {
    postal_code: "27571",
    zip_name: "rolesville, nc",
  },
  {
    postal_code: "28159",
    zip_name: "spencer, nc",
  },
  {
    postal_code: "6089",
    zip_name: "weatogue, ct",
  },
  {
    postal_code: "13755",
    zip_name: "downsville, ny",
  },
  {
    postal_code: "37074",
    zip_name: "hartsville, tn",
  },
  {
    postal_code: "43324",
    zip_name: "huntsville, oh",
  },
  {
    postal_code: "55084",
    zip_name: "taylors falls, mn",
  },
  {
    postal_code: "2190",
    zip_name: "south weymouth, ma",
  },
  {
    postal_code: "95655",
    zip_name: "mather, ca",
  },
  {
    postal_code: "19072",
    zip_name: "narberth, pa",
  },
  {
    postal_code: "64744",
    zip_name: "el dorado springs, mo",
  },
  {
    postal_code: "48173",
    zip_name: "rockwood, mi",
  },
  {
    postal_code: "1821",
    zip_name: "billerica, ma",
  },
  {
    postal_code: "4849",
    zip_name: "lincolnville, me",
  },
  {
    postal_code: "75462",
    zip_name: "paris, tx",
  },
  {
    postal_code: "19933",
    zip_name: "bridgeville, de",
  },
  {
    postal_code: "21219",
    zip_name: "sparrows point, md",
  },
  {
    postal_code: "83211",
    zip_name: "american falls, id",
  },
  {
    postal_code: "31750",
    zip_name: "fitzgerald, ga",
  },
  {
    postal_code: "21773",
    zip_name: "myersville, md",
  },
  {
    postal_code: "59925",
    zip_name: "marion, mt",
  },
  {
    postal_code: "17751",
    zip_name: "mill hall, pa",
  },
  {
    postal_code: "77708",
    zip_name: "beaumont, tx",
  },
  {
    postal_code: "83113",
    zip_name: "big piney, wy",
  },
  {
    postal_code: "22923",
    zip_name: "barboursville, va",
  },
  {
    postal_code: "1571",
    zip_name: "dudley, ma",
  },
  {
    postal_code: "5478",
    zip_name: "saint albans, vt",
  },
  {
    postal_code: "50169",
    zip_name: "mitchellville, ia",
  },
  {
    postal_code: "37033",
    zip_name: "centerville, tn",
  },
  {
    postal_code: "4605",
    zip_name: "ellsworth, me",
  },
  {
    postal_code: "95485",
    zip_name: "upper lake, ca",
  },
  {
    postal_code: "18848",
    zip_name: "towanda, pa",
  },
  {
    postal_code: "99122",
    zip_name: "davenport, wa",
  },
  {
    postal_code: "15627",
    zip_name: "derry, pa",
  },
  {
    postal_code: "95457",
    zip_name: "lower lake, ca",
  },
  {
    postal_code: "3884",
    zip_name: "strafford, nh",
  },
  {
    postal_code: "45370",
    zip_name: "spring valley, oh",
  },
  {
    postal_code: "53813",
    zip_name: "lancaster, wi",
  },
  {
    postal_code: "1835",
    zip_name: "haverhill, ma",
  },
  {
    postal_code: "45162",
    zip_name: "pleasant plain, oh",
  },
  {
    postal_code: "80642",
    zip_name: "hudson, co",
  },
  {
    postal_code: "45325",
    zip_name: "farmersville, oh",
  },
  {
    postal_code: "94710",
    zip_name: "berkeley, ca",
  },
  {
    postal_code: "52591",
    zip_name: "sigourney, ia",
  },
  {
    postal_code: "23693",
    zip_name: "yorktown, va",
  },
  {
    postal_code: "43416",
    zip_name: "elmore, oh",
  },
  {
    postal_code: "75453",
    zip_name: "lone oak, tx",
  },
  {
    postal_code: "29831",
    zip_name: "jackson, sc",
  },
  {
    postal_code: "49250",
    zip_name: "jonesville, mi",
  },
  {
    postal_code: "59079",
    zip_name: "shepherd, mt",
  },
  {
    postal_code: "64098",
    zip_name: "weston, mo",
  },
  {
    postal_code: "75057",
    zip_name: "lewisville, tx",
  },
  {
    postal_code: "56628",
    zip_name: "bigfork, mn",
  },
  {
    postal_code: "61732",
    zip_name: "danvers, il",
  },
  {
    postal_code: "4967",
    zip_name: "pittsfield, me",
  },
  {
    postal_code: "44402",
    zip_name: "bristolville, oh",
  },
  {
    postal_code: "50062",
    zip_name: "melcher dallas, ia",
  },
  {
    postal_code: "49622",
    zip_name: "central lake, mi",
  },
  {
    postal_code: "39573",
    zip_name: "perkinston, ms",
  },
  {
    postal_code: "44437",
    zip_name: "mc donald, oh",
  },
  {
    postal_code: "3841",
    zip_name: "hampstead, nh",
  },
  {
    postal_code: "18661",
    zip_name: "white haven, pa",
  },
  {
    postal_code: "78726",
    zip_name: "austin, tx",
  },
  {
    postal_code: "55779",
    zip_name: "saginaw, mn",
  },
  {
    postal_code: "54758",
    zip_name: "osseo, wi",
  },
  {
    postal_code: "92254",
    zip_name: "mecca, ca",
  },
  {
    postal_code: "28384",
    zip_name: "saint pauls, nc",
  },
  {
    postal_code: "61011",
    zip_name: "caledonia, il",
  },
  {
    postal_code: "3580",
    zip_name: "franconia, nh",
  },
  {
    postal_code: "19444",
    zip_name: "lafayette hill, pa",
  },
  {
    postal_code: "53964",
    zip_name: "westfield, wi",
  },
  {
    postal_code: "64840",
    zip_name: "diamond, mo",
  },
  {
    postal_code: "15909",
    zip_name: "johnstown, pa",
  },
  {
    postal_code: "90623",
    zip_name: "la palma, ca",
  },
  {
    postal_code: "94158",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "2332",
    zip_name: "duxbury, ma",
  },
  {
    postal_code: "8822",
    zip_name: "flemington, nj",
  },
  {
    postal_code: "61064",
    zip_name: "polo, il",
  },
  {
    postal_code: "48612",
    zip_name: "beaverton, mi",
  },
  {
    postal_code: "77037",
    zip_name: "houston, tx",
  },
  {
    postal_code: "92025",
    zip_name: "escondido, ca",
  },
  {
    postal_code: "76066",
    zip_name: "millsap, tx",
  },
  {
    postal_code: "3886",
    zip_name: "tamworth, nh",
  },
  {
    postal_code: "40311",
    zip_name: "carlisle, ky",
  },
  {
    postal_code: "7621",
    zip_name: "bergenfield, nj",
  },
  {
    postal_code: "27025",
    zip_name: "madison, nc",
  },
  {
    postal_code: "3858",
    zip_name: "newton, nh",
  },
  {
    postal_code: "81652",
    zip_name: "silt, co",
  },
  {
    postal_code: "12087",
    zip_name: "hannacroix, ny",
  },
  {
    postal_code: "60476",
    zip_name: "thornton, il",
  },
  {
    postal_code: "50213",
    zip_name: "osceola, ia",
  },
  {
    postal_code: "17754",
    zip_name: "montoursville, pa",
  },
  {
    postal_code: "95634",
    zip_name: "georgetown, ca",
  },
  {
    postal_code: "6750",
    zip_name: "bantam, ct",
  },
  {
    postal_code: "45697",
    zip_name: "winchester, oh",
  },
  {
    postal_code: "48030",
    zip_name: "hazel park, mi",
  },
  {
    postal_code: "98303",
    zip_name: "anderson island, wa",
  },
  {
    postal_code: "54960",
    zip_name: "neshkoro, wi",
  },
  {
    postal_code: "31408",
    zip_name: "savannah, ga",
  },
  {
    postal_code: "28340",
    zip_name: "fairmont, nc",
  },
  {
    postal_code: "19014",
    zip_name: "aston, pa",
  },
  {
    postal_code: "48044",
    zip_name: "macomb, mi",
  },
  {
    postal_code: "2347",
    zip_name: "lakeville, ma",
  },
  {
    postal_code: "85701",
    zip_name: "tucson, az",
  },
  {
    postal_code: "80470",
    zip_name: "pine, co",
  },
  {
    postal_code: "93637",
    zip_name: "madera, ca",
  },
  {
    postal_code: "46392",
    zip_name: "wheatfield, in",
  },
  {
    postal_code: "76559",
    zip_name: "nolanville, tx",
  },
  {
    postal_code: "21804",
    zip_name: "salisbury, md",
  },
  {
    postal_code: "28032",
    zip_name: "cramerton, nc",
  },
  {
    postal_code: "28705",
    zip_name: "bakersville, nc",
  },
  {
    postal_code: "35116",
    zip_name: "morris, al",
  },
  {
    postal_code: "43358",
    zip_name: "west mansfield, oh",
  },
  {
    postal_code: "40057",
    zip_name: "pleasureville, ky",
  },
  {
    postal_code: "27807",
    zip_name: "bailey, nc",
  },
  {
    postal_code: "2364",
    zip_name: "kingston, ma",
  },
  {
    postal_code: "48744",
    zip_name: "mayville, mi",
  },
  {
    postal_code: "69358",
    zip_name: "morrill, ne",
  },
  {
    postal_code: "24104",
    zip_name: "huddleston, va",
  },
  {
    postal_code: "79521",
    zip_name: "haskell, tx",
  },
  {
    postal_code: "21911",
    zip_name: "rising sun, md",
  },
  {
    postal_code: "5089",
    zip_name: "windsor, vt",
  },
  {
    postal_code: "29482",
    zip_name: "sullivans island, sc",
  },
  {
    postal_code: "27542",
    zip_name: "kenly, nc",
  },
  {
    postal_code: "98241",
    zip_name: "darrington, wa",
  },
  {
    postal_code: "32320",
    zip_name: "apalachicola, fl",
  },
  {
    postal_code: "26431",
    zip_name: "shinnston, wv",
  },
  {
    postal_code: "95383",
    zip_name: "twain harte, ca",
  },
  {
    postal_code: "11769",
    zip_name: "oakdale, ny",
  },
  {
    postal_code: "22656",
    zip_name: "stephenson, va",
  },
  {
    postal_code: "35064",
    zip_name: "fairfield, al",
  },
  {
    postal_code: "45225",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "49425",
    zip_name: "holton, mi",
  },
  {
    postal_code: "71943",
    zip_name: "glenwood, ar",
  },
  {
    postal_code: "43718",
    zip_name: "belmont, oh",
  },
  {
    postal_code: "60472",
    zip_name: "robbins, il",
  },
  {
    postal_code: "28763",
    zip_name: "otto, nc",
  },
  {
    postal_code: "43430",
    zip_name: "genoa, oh",
  },
  {
    postal_code: "21710",
    zip_name: "adamstown, md",
  },
  {
    postal_code: "48219",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "21758",
    zip_name: "knoxville, md",
  },
  {
    postal_code: "44307",
    zip_name: "akron, oh",
  },
  {
    postal_code: "28651",
    zip_name: "millers creek, nc",
  },
  {
    postal_code: "97374",
    zip_name: "scio, or",
  },
  {
    postal_code: "1501",
    zip_name: "auburn, ma",
  },
  {
    postal_code: "85631",
    zip_name: "san manuel, az",
  },
  {
    postal_code: "46552",
    zip_name: "new carlisle, in",
  },
  {
    postal_code: "54009",
    zip_name: "dresser, wi",
  },
  {
    postal_code: "21643",
    zip_name: "hurlock, md",
  },
  {
    postal_code: "83801",
    zip_name: "athol, id",
  },
  {
    postal_code: "17268",
    zip_name: "waynesboro, pa",
  },
  {
    postal_code: "29506",
    zip_name: "florence, sc",
  },
  {
    postal_code: "48083",
    zip_name: "troy, mi",
  },
  {
    postal_code: "14738",
    zip_name: "frewsburg, ny",
  },
  {
    postal_code: "38001",
    zip_name: "alamo, tn",
  },
  {
    postal_code: "5454",
    zip_name: "fairfax, vt",
  },
  {
    postal_code: "78736",
    zip_name: "austin, tx",
  },
  {
    postal_code: "19462",
    zip_name: "plymouth meeting, pa",
  },
  {
    postal_code: "17356",
    zip_name: "red lion, pa",
  },
  {
    postal_code: "56048",
    zip_name: "janesville, mn",
  },
  {
    postal_code: "37869",
    zip_name: "sneedville, tn",
  },
  {
    postal_code: "28701",
    zip_name: "alexander, nc",
  },
  {
    postal_code: "26164",
    zip_name: "ravenswood, wv",
  },
  {
    postal_code: "32038",
    zip_name: "fort white, fl",
  },
  {
    postal_code: "56479",
    zip_name: "staples, mn",
  },
  {
    postal_code: "22734",
    zip_name: "remington, va",
  },
  {
    postal_code: "97031",
    zip_name: "hood river, or",
  },
  {
    postal_code: "38330",
    zip_name: "dyer, tn",
  },
  {
    postal_code: "1060",
    zip_name: "northampton, ma",
  },
  {
    postal_code: "13605",
    zip_name: "adams, ny",
  },
  {
    postal_code: "75763",
    zip_name: "frankston, tx",
  },
  {
    postal_code: "13691",
    zip_name: "theresa, ny",
  },
  {
    postal_code: "52653",
    zip_name: "wapello, ia",
  },
  {
    postal_code: "60040",
    zip_name: "highwood, il",
  },
  {
    postal_code: "43542",
    zip_name: "monclova, oh",
  },
  {
    postal_code: "18104",
    zip_name: "allentown, pa",
  },
  {
    postal_code: "77485",
    zip_name: "wallis, tx",
  },
  {
    postal_code: "12485",
    zip_name: "tannersville, ny",
  },
  {
    postal_code: "16401",
    zip_name: "albion, pa",
  },
  {
    postal_code: "39202",
    zip_name: "jackson, ms",
  },
  {
    postal_code: "27017",
    zip_name: "dobson, nc",
  },
  {
    postal_code: "55006",
    zip_name: "braham, mn",
  },
  {
    postal_code: "2472",
    zip_name: "watertown, ma",
  },
  {
    postal_code: "35044",
    zip_name: "childersburg, al",
  },
  {
    postal_code: "29450",
    zip_name: "huger, sc",
  },
  {
    postal_code: "83313",
    zip_name: "bellevue, id",
  },
  {
    postal_code: "15502",
    zip_name: "hidden valley, pa",
  },
  {
    postal_code: "13037",
    zip_name: "chittenango, ny",
  },
  {
    postal_code: "16933",
    zip_name: "mansfield, pa",
  },
  {
    postal_code: "19136",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "2050",
    zip_name: "marshfield, ma",
  },
  {
    postal_code: "18445",
    zip_name: "newfoundland, pa",
  },
  {
    postal_code: "21152",
    zip_name: "sparks glencoe, md",
  },
  {
    postal_code: "54736",
    zip_name: "durand, wi",
  },
  {
    postal_code: "19555",
    zip_name: "shoemakersville, pa",
  },
  {
    postal_code: "48183",
    zip_name: "trenton, mi",
  },
  {
    postal_code: "27892",
    zip_name: "williamston, nc",
  },
  {
    postal_code: "83455",
    zip_name: "victor, id",
  },
  {
    postal_code: "2056",
    zip_name: "norfolk, ma",
  },
  {
    postal_code: "3257",
    zip_name: "new london, nh",
  },
  {
    postal_code: "3869",
    zip_name: "rollinsford, nh",
  },
  {
    postal_code: "31730",
    zip_name: "camilla, ga",
  },
  {
    postal_code: "2420",
    zip_name: "lexington, ma",
  },
  {
    postal_code: "12084",
    zip_name: "guilderland, ny",
  },
  {
    postal_code: "4609",
    zip_name: "bar harbor, me",
  },
  {
    postal_code: "30643",
    zip_name: "hartwell, ga",
  },
  {
    postal_code: "98930",
    zip_name: "grandview, wa",
  },
  {
    postal_code: "83686",
    zip_name: "nampa, id",
  },
  {
    postal_code: "32132",
    zip_name: "edgewater, fl",
  },
  {
    postal_code: "33109",
    zip_name: "miami beach, fl",
  },
  {
    postal_code: "31069",
    zip_name: "perry, ga",
  },
  {
    postal_code: "98237",
    zip_name: "concrete, wa",
  },
  {
    postal_code: "40011",
    zip_name: "campbellsburg, ky",
  },
  {
    postal_code: "76164",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "60954",
    zip_name: "momence, il",
  },
  {
    postal_code: "45113",
    zip_name: "clarksville, oh",
  },
  {
    postal_code: "62839",
    zip_name: "flora, il",
  },
  {
    postal_code: "2339",
    zip_name: "hanover, ma",
  },
  {
    postal_code: "37727",
    zip_name: "del rio, tn",
  },
  {
    postal_code: "43011",
    zip_name: "centerburg, oh",
  },
  {
    postal_code: "43203",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "90028",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "32179",
    zip_name: "ocklawaha, fl",
  },
  {
    postal_code: "71006",
    zip_name: "benton, la",
  },
  {
    postal_code: "8077",
    zip_name: "riverton, nj",
  },
  {
    postal_code: "61024",
    zip_name: "durand, il",
  },
  {
    postal_code: "6105",
    zip_name: "hartford, ct",
  },
  {
    postal_code: "17325",
    zip_name: "gettysburg, pa",
  },
  {
    postal_code: "21032",
    zip_name: "crownsville, md",
  },
  {
    postal_code: "12137",
    zip_name: "pattersonville, ny",
  },
  {
    postal_code: "65441",
    zip_name: "bourbon, mo",
  },
  {
    postal_code: "80640",
    zip_name: "henderson, co",
  },
  {
    postal_code: "68978",
    zip_name: "superior, ne",
  },
  {
    postal_code: "1906",
    zip_name: "saugus, ma",
  },
  {
    postal_code: "61738",
    zip_name: "el paso, il",
  },
  {
    postal_code: "27243",
    zip_name: "efland, nc",
  },
  {
    postal_code: "95065",
    zip_name: "santa cruz, ca",
  },
  {
    postal_code: "6248",
    zip_name: "hebron, ct",
  },
  {
    postal_code: "13456",
    zip_name: "sauquoit, ny",
  },
  {
    postal_code: "46105",
    zip_name: "bainbridge, in",
  },
  {
    postal_code: "23841",
    zip_name: "dinwiddie, va",
  },
  {
    postal_code: "79761",
    zip_name: "odessa, tx",
  },
  {
    postal_code: "11237",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "37860",
    zip_name: "russellville, tn",
  },
  {
    postal_code: "4011",
    zip_name: "brunswick, me",
  },
  {
    postal_code: "22520",
    zip_name: "montross, va",
  },
  {
    postal_code: "94931",
    zip_name: "cotati, ca",
  },
  {
    postal_code: "4538",
    zip_name: "boothbay harbor, me",
  },
  {
    postal_code: "6052",
    zip_name: "new britain, ct",
  },
  {
    postal_code: "14513",
    zip_name: "newark, ny",
  },
  {
    postal_code: "1938",
    zip_name: "ipswich, ma",
  },
  {
    postal_code: "69130",
    zip_name: "cozad, ne",
  },
  {
    postal_code: "11427",
    zip_name: "queens village, ny",
  },
  {
    postal_code: "56013",
    zip_name: "blue earth, mn",
  },
  {
    postal_code: "6093",
    zip_name: "west suffield, ct",
  },
  {
    postal_code: "15417",
    zip_name: "brownsville, pa",
  },
  {
    postal_code: "51510",
    zip_name: "carter lake, ia",
  },
  {
    postal_code: "2122",
    zip_name: "dorchester, ma",
  },
  {
    postal_code: "61531",
    zip_name: "farmington, il",
  },
  {
    postal_code: "42445",
    zip_name: "princeton, ky",
  },
  {
    postal_code: "53932",
    zip_name: "fall river, wi",
  },
  {
    postal_code: "92115",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "2537",
    zip_name: "east sandwich, ma",
  },
  {
    postal_code: "2719",
    zip_name: "fairhaven, ma",
  },
  {
    postal_code: "19707",
    zip_name: "hockessin, de",
  },
  {
    postal_code: "18042",
    zip_name: "easton, pa",
  },
  {
    postal_code: "21904",
    zip_name: "port deposit, md",
  },
  {
    postal_code: "53049",
    zip_name: "malone, wi",
  },
  {
    postal_code: "64016",
    zip_name: "buckner, mo",
  },
  {
    postal_code: "27051",
    zip_name: "walkertown, nc",
  },
  {
    postal_code: "50073",
    zip_name: "elkhart, ia",
  },
  {
    postal_code: "81625",
    zip_name: "craig, co",
  },
  {
    postal_code: "92341",
    zip_name: "green valley lake, ca",
  },
  {
    postal_code: "43046",
    zip_name: "millersport, oh",
  },
  {
    postal_code: "76245",
    zip_name: "gordonville, tx",
  },
  {
    postal_code: "18419",
    zip_name: "factoryville, pa",
  },
  {
    postal_code: "52537",
    zip_name: "bloomfield, ia",
  },
  {
    postal_code: "28398",
    zip_name: "warsaw, nc",
  },
  {
    postal_code: "30079",
    zip_name: "scottdale, ga",
  },
  {
    postal_code: "89448",
    zip_name: "zephyr cove, nv",
  },
  {
    postal_code: "76567",
    zip_name: "rockdale, tx",
  },
  {
    postal_code: "12571",
    zip_name: "red hook, ny",
  },
  {
    postal_code: "71037",
    zip_name: "haughton, la",
  },
  {
    postal_code: "48329",
    zip_name: "waterford, mi",
  },
  {
    postal_code: "53805",
    zip_name: "boscobel, wi",
  },
  {
    postal_code: "61054",
    zip_name: "mount morris, il",
  },
  {
    postal_code: "50056",
    zip_name: "colo, ia",
  },
  {
    postal_code: "83843",
    zip_name: "moscow, id",
  },
  {
    postal_code: "37687",
    zip_name: "roan mountain, tn",
  },
  {
    postal_code: "97224",
    zip_name: "portland, or",
  },
  {
    postal_code: "57071",
    zip_name: "volga, sd",
  },
  {
    postal_code: "13032",
    zip_name: "canastota, ny",
  },
  {
    postal_code: "23930",
    zip_name: "crewe, va",
  },
  {
    postal_code: "44287",
    zip_name: "west salem, oh",
  },
  {
    postal_code: "37826",
    zip_name: "niota, tn",
  },
  {
    postal_code: "2045",
    zip_name: "hull, ma",
  },
  {
    postal_code: "19149",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "16143",
    zip_name: "pulaski, pa",
  },
  {
    postal_code: "2478",
    zip_name: "belmont, ma",
  },
  {
    postal_code: "48895",
    zip_name: "williamston, mi",
  },
  {
    postal_code: "28510",
    zip_name: "arapahoe, nc",
  },
  {
    postal_code: "38315",
    zip_name: "bethel springs, tn",
  },
  {
    postal_code: "92071",
    zip_name: "santee, ca",
  },
  {
    postal_code: "52001",
    zip_name: "dubuque, ia",
  },
  {
    postal_code: "29576",
    zip_name: "murrells inlet, sc",
  },
  {
    postal_code: "33321",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "2644",
    zip_name: "forestdale, ma",
  },
  {
    postal_code: "42025",
    zip_name: "benton, ky",
  },
  {
    postal_code: "70584",
    zip_name: "sunset, la",
  },
  {
    postal_code: "2892",
    zip_name: "west kingston, ri",
  },
  {
    postal_code: "6878",
    zip_name: "riverside, ct",
  },
  {
    postal_code: "85194",
    zip_name: "casa grande, az",
  },
  {
    postal_code: "42629",
    zip_name: "jamestown, ky",
  },
  {
    postal_code: "29574",
    zip_name: "mullins, sc",
  },
  {
    postal_code: "12997",
    zip_name: "wilmington, ny",
  },
  {
    postal_code: "30641",
    zip_name: "good hope, ga",
  },
  {
    postal_code: "2857",
    zip_name: "north scituate, ri",
  },
  {
    postal_code: "42376",
    zip_name: "utica, ky",
  },
  {
    postal_code: "4444",
    zip_name: "hampden, me",
  },
  {
    postal_code: "12168",
    zip_name: "stephentown, ny",
  },
  {
    postal_code: "79403",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "78052",
    zip_name: "lytle, tx",
  },
  {
    postal_code: "2638",
    zip_name: "dennis, ma",
  },
  {
    postal_code: "11417",
    zip_name: "ozone park, ny",
  },
  {
    postal_code: "22213",
    zip_name: "arlington, va",
  },
  {
    postal_code: "80211",
    zip_name: "denver, co",
  },
  {
    postal_code: "11762",
    zip_name: "massapequa park, ny",
  },
  {
    postal_code: "18210",
    zip_name: "albrightsville, pa",
  },
  {
    postal_code: "80129",
    zip_name: "littleton, co",
  },
  {
    postal_code: "71351",
    zip_name: "marksville, la",
  },
  {
    postal_code: "70754",
    zip_name: "livingston, la",
  },
  {
    postal_code: "17059",
    zip_name: "mifflintown, pa",
  },
  {
    postal_code: "18507",
    zip_name: "moosic, pa",
  },
  {
    postal_code: "56450",
    zip_name: "garrison, mn",
  },
  {
    postal_code: "90201",
    zip_name: "bell gardens, ca",
  },
  {
    postal_code: "59932",
    zip_name: "somers, mt",
  },
  {
    postal_code: "54913",
    zip_name: "appleton, wi",
  },
  {
    postal_code: "60957",
    zip_name: "paxton, il",
  },
  {
    postal_code: "44820",
    zip_name: "bucyrus, oh",
  },
  {
    postal_code: "33597",
    zip_name: "webster, fl",
  },
  {
    postal_code: "2019",
    zip_name: "bellingham, ma",
  },
  {
    postal_code: "35117",
    zip_name: "mount olive, al",
  },
  {
    postal_code: "11023",
    zip_name: "great neck, ny",
  },
  {
    postal_code: "65556",
    zip_name: "richland, mo",
  },
  {
    postal_code: "80004",
    zip_name: "arvada, co",
  },
  {
    postal_code: "61010",
    zip_name: "byron, il",
  },
  {
    postal_code: "27281",
    zip_name: "jackson springs, nc",
  },
  {
    postal_code: "40437",
    zip_name: "hustonville, ky",
  },
  {
    postal_code: "62951",
    zip_name: "johnston city, il",
  },
  {
    postal_code: "78124",
    zip_name: "marion, tx",
  },
  {
    postal_code: "61284",
    zip_name: "taylor ridge, il",
  },
  {
    postal_code: "70345",
    zip_name: "cut off, la",
  },
  {
    postal_code: "17082",
    zip_name: "port royal, pa",
  },
  {
    postal_code: "28586",
    zip_name: "vanceboro, nc",
  },
  {
    postal_code: "42276",
    zip_name: "russellville, ky",
  },
  {
    postal_code: "20837",
    zip_name: "poolesville, md",
  },
  {
    postal_code: "2769",
    zip_name: "rehoboth, ma",
  },
  {
    postal_code: "20106",
    zip_name: "amissville, va",
  },
  {
    postal_code: "85354",
    zip_name: "tonopah, az",
  },
  {
    postal_code: "54981",
    zip_name: "waupaca, wi",
  },
  {
    postal_code: "23083",
    zip_name: "jetersville, va",
  },
  {
    postal_code: "35203",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "30171",
    zip_name: "rydal, ga",
  },
  {
    postal_code: "23692",
    zip_name: "yorktown, va",
  },
  {
    postal_code: "44612",
    zip_name: "bolivar, oh",
  },
  {
    postal_code: "92128",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "74023",
    zip_name: "cushing, ok",
  },
  {
    postal_code: "38008",
    zip_name: "bolivar, tn",
  },
  {
    postal_code: "53929",
    zip_name: "elroy, wi",
  },
  {
    postal_code: "32764",
    zip_name: "osteen, fl",
  },
  {
    postal_code: "54891",
    zip_name: "washburn, wi",
  },
  {
    postal_code: "38635",
    zip_name: "holly springs, ms",
  },
  {
    postal_code: "49236",
    zip_name: "clinton, mi",
  },
  {
    postal_code: "80219",
    zip_name: "denver, co",
  },
  {
    postal_code: "95619",
    zip_name: "diamond springs, ca",
  },
  {
    postal_code: "6278",
    zip_name: "ashford, ct",
  },
  {
    postal_code: "24538",
    zip_name: "concord, va",
  },
  {
    postal_code: "49913",
    zip_name: "calumet, mi",
  },
  {
    postal_code: "98684",
    zip_name: "vancouver, wa",
  },
  {
    postal_code: "74337",
    zip_name: "chouteau, ok",
  },
  {
    postal_code: "83617",
    zip_name: "emmett, id",
  },
  {
    postal_code: "44662",
    zip_name: "navarre, oh",
  },
  {
    postal_code: "84721",
    zip_name: "cedar city, ut",
  },
  {
    postal_code: "25130",
    zip_name: "madison, wv",
  },
  {
    postal_code: "12992",
    zip_name: "west chazy, ny",
  },
  {
    postal_code: "49938",
    zip_name: "ironwood, mi",
  },
  {
    postal_code: "37665",
    zip_name: "kingsport, tn",
  },
  {
    postal_code: "21550",
    zip_name: "oakland, md",
  },
  {
    postal_code: "77840",
    zip_name: "college station, tx",
  },
  {
    postal_code: "66085",
    zip_name: "stilwell, ks",
  },
  {
    postal_code: "86409",
    zip_name: "kingman, az",
  },
  {
    postal_code: "30233",
    zip_name: "jackson, ga",
  },
  {
    postal_code: "70583",
    zip_name: "scott, la",
  },
  {
    postal_code: "3576",
    zip_name: "colebrook, nh",
  },
  {
    postal_code: "70422",
    zip_name: "amite, la",
  },
  {
    postal_code: "12037",
    zip_name: "chatham, ny",
  },
  {
    postal_code: "25530",
    zip_name: "kenova, wv",
  },
  {
    postal_code: "1510",
    zip_name: "clinton, ma",
  },
  {
    postal_code: "76642",
    zip_name: "groesbeck, tx",
  },
  {
    postal_code: "81639",
    zip_name: "hayden, co",
  },
  {
    postal_code: "28080",
    zip_name: "iron station, nc",
  },
  {
    postal_code: "26301",
    zip_name: "clarksburg, wv",
  },
  {
    postal_code: "56763",
    zip_name: "warroad, mn",
  },
  {
    postal_code: "32052",
    zip_name: "jasper, fl",
  },
  {
    postal_code: "26105",
    zip_name: "vienna, wv",
  },
  {
    postal_code: "50022",
    zip_name: "atlantic, ia",
  },
  {
    postal_code: "59917",
    zip_name: "eureka, mt",
  },
  {
    postal_code: "44851",
    zip_name: "new london, oh",
  },
  {
    postal_code: "63664",
    zip_name: "potosi, mo",
  },
  {
    postal_code: "78619",
    zip_name: "driftwood, tx",
  },
  {
    postal_code: "54872",
    zip_name: "siren, wi",
  },
  {
    postal_code: "21919",
    zip_name: "earleville, md",
  },
  {
    postal_code: "27583",
    zip_name: "timberlake, nc",
  },
  {
    postal_code: "11105",
    zip_name: "astoria, ny",
  },
  {
    postal_code: "39455",
    zip_name: "lumberton, ms",
  },
  {
    postal_code: "75654",
    zip_name: "henderson, tx",
  },
  {
    postal_code: "37322",
    zip_name: "decatur, tn",
  },
  {
    postal_code: "38578",
    zip_name: "pleasant hill, tn",
  },
  {
    postal_code: "29643",
    zip_name: "fair play, sc",
  },
  {
    postal_code: "27298",
    zip_name: "liberty, nc",
  },
  {
    postal_code: "1602",
    zip_name: "worcester, ma",
  },
  {
    postal_code: "6612",
    zip_name: "easton, ct",
  },
  {
    postal_code: "83202",
    zip_name: "pocatello, id",
  },
  {
    postal_code: "6763",
    zip_name: "morris, ct",
  },
  {
    postal_code: "11421",
    zip_name: "woodhaven, ny",
  },
  {
    postal_code: "39218",
    zip_name: "richland, ms",
  },
  {
    postal_code: "24251",
    zip_name: "gate city, va",
  },
  {
    postal_code: "92391",
    zip_name: "twin peaks, ca",
  },
  {
    postal_code: "22810",
    zip_name: "basye, va",
  },
  {
    postal_code: "22936",
    zip_name: "earlysville, va",
  },
  {
    postal_code: "5751",
    zip_name: "killington, vt",
  },
  {
    postal_code: "14818",
    zip_name: "burdett, ny",
  },
  {
    postal_code: "44105",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "93422",
    zip_name: "atascadero, ca",
  },
  {
    postal_code: "32114",
    zip_name: "daytona beach, fl",
  },
  {
    postal_code: "1730",
    zip_name: "bedford, ma",
  },
  {
    postal_code: "5673",
    zip_name: "waitsfield, vt",
  },
  {
    postal_code: "48732",
    zip_name: "essexville, mi",
  },
  {
    postal_code: "65066",
    zip_name: "owensville, mo",
  },
  {
    postal_code: "55073",
    zip_name: "scandia, mn",
  },
  {
    postal_code: "49286",
    zip_name: "tecumseh, mi",
  },
  {
    postal_code: "56273",
    zip_name: "new london, mn",
  },
  {
    postal_code: "43762",
    zip_name: "new concord, oh",
  },
  {
    postal_code: "93110",
    zip_name: "santa barbara, ca",
  },
  {
    postal_code: "64105",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "35186",
    zip_name: "wilsonville, al",
  },
  {
    postal_code: "50025",
    zip_name: "audubon, ia",
  },
  {
    postal_code: "99025",
    zip_name: "newman lake, wa",
  },
  {
    postal_code: "5055",
    zip_name: "norwich, vt",
  },
  {
    postal_code: "55923",
    zip_name: "chatfield, mn",
  },
  {
    postal_code: "46936",
    zip_name: "greentown, in",
  },
  {
    postal_code: "95614",
    zip_name: "cool, ca",
  },
  {
    postal_code: "93111",
    zip_name: "santa barbara, ca",
  },
  {
    postal_code: "37866",
    zip_name: "sharps chapel, tn",
  },
  {
    postal_code: "53508",
    zip_name: "belleville, wi",
  },
  {
    postal_code: "27939",
    zip_name: "grandy, nc",
  },
  {
    postal_code: "11565",
    zip_name: "malverne, ny",
  },
  {
    postal_code: "12986",
    zip_name: "tupper lake, ny",
  },
  {
    postal_code: "30108",
    zip_name: "bowdon, ga",
  },
  {
    postal_code: "99138",
    zip_name: "inchelium, wa",
  },
  {
    postal_code: "76225",
    zip_name: "alvord, tx",
  },
  {
    postal_code: "32130",
    zip_name: "de leon springs, fl",
  },
  {
    postal_code: "16316",
    zip_name: "conneaut lake, pa",
  },
  {
    postal_code: "38965",
    zip_name: "water valley, ms",
  },
  {
    postal_code: "13690",
    zip_name: "star lake, ny",
  },
  {
    postal_code: "46041",
    zip_name: "frankfort, in",
  },
  {
    postal_code: "37047",
    zip_name: "cornersville, tn",
  },
  {
    postal_code: "27851",
    zip_name: "lucama, nc",
  },
  {
    postal_code: "75571",
    zip_name: "omaha, tx",
  },
  {
    postal_code: "53583",
    zip_name: "sauk city, wi",
  },
  {
    postal_code: "92020",
    zip_name: "el cajon, ca",
  },
  {
    postal_code: "60151",
    zip_name: "maple park, il",
  },
  {
    postal_code: "14042",
    zip_name: "delevan, ny",
  },
  {
    postal_code: "80951",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "6855",
    zip_name: "norwalk, ct",
  },
  {
    postal_code: "2116",
    zip_name: "boston, ma",
  },
  {
    postal_code: "2459",
    zip_name: "newton center, ma",
  },
  {
    postal_code: "99109",
    zip_name: "chewelah, wa",
  },
  {
    postal_code: "1887",
    zip_name: "wilmington, ma",
  },
  {
    postal_code: "77864",
    zip_name: "madisonville, tx",
  },
  {
    postal_code: "93202",
    zip_name: "armona, ca",
  },
  {
    postal_code: "6375",
    zip_name: "quaker hill, ct",
  },
  {
    postal_code: "62049",
    zip_name: "hillsboro, il",
  },
  {
    postal_code: "45229",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "27043",
    zip_name: "pinnacle, nc",
  },
  {
    postal_code: "94133",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "23086",
    zip_name: "king william, va",
  },
  {
    postal_code: "93445",
    zip_name: "oceano, ca",
  },
  {
    postal_code: "17756",
    zip_name: "muncy, pa",
  },
  {
    postal_code: "62275",
    zip_name: "pocahontas, il",
  },
  {
    postal_code: "38023",
    zip_name: "drummonds, tn",
  },
  {
    postal_code: "1907",
    zip_name: "swampscott, ma",
  },
  {
    postal_code: "17073",
    zip_name: "newmanstown, pa",
  },
  {
    postal_code: "73438",
    zip_name: "healdton, ok",
  },
  {
    postal_code: "54015",
    zip_name: "hammond, wi",
  },
  {
    postal_code: "1007",
    zip_name: "belchertown, ma",
  },
  {
    postal_code: "76093",
    zip_name: "rio vista, tx",
  },
  {
    postal_code: "95722",
    zip_name: "meadow vista, ca",
  },
  {
    postal_code: "76470",
    zip_name: "ranger, tx",
  },
  {
    postal_code: "48821",
    zip_name: "dimondale, mi",
  },
  {
    postal_code: "54646",
    zip_name: "necedah, wi",
  },
  {
    postal_code: "44680",
    zip_name: "strasburg, oh",
  },
  {
    postal_code: "6277",
    zip_name: "thompson, ct",
  },
  {
    postal_code: "54558",
    zip_name: "saint germain, wi",
  },
  {
    postal_code: "80512",
    zip_name: "bellvue, co",
  },
  {
    postal_code: "30139",
    zip_name: "fairmount, ga",
  },
  {
    postal_code: "24590",
    zip_name: "scottsville, va",
  },
  {
    postal_code: "94085",
    zip_name: "sunnyvale, ca",
  },
  {
    postal_code: "98557",
    zip_name: "mccleary, wa",
  },
  {
    postal_code: "95130",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "94904",
    zip_name: "greenbrae, ca",
  },
  {
    postal_code: "3235",
    zip_name: "franklin, nh",
  },
  {
    postal_code: "16159",
    zip_name: "west middlesex, pa",
  },
  {
    postal_code: "45387",
    zip_name: "yellow springs, oh",
  },
  {
    postal_code: "55003",
    zip_name: "bayport, mn",
  },
  {
    postal_code: "79902",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "37025",
    zip_name: "bon aqua, tn",
  },
  {
    postal_code: "56464",
    zip_name: "menahga, mn",
  },
  {
    postal_code: "94037",
    zip_name: "montara, ca",
  },
  {
    postal_code: "29661",
    zip_name: "marietta, sc",
  },
  {
    postal_code: "32129",
    zip_name: "port orange, fl",
  },
  {
    postal_code: "12993",
    zip_name: "westport, ny",
  },
  {
    postal_code: "98365",
    zip_name: "port ludlow, wa",
  },
  {
    postal_code: "55767",
    zip_name: "moose lake, mn",
  },
  {
    postal_code: "56435",
    zip_name: "backus, mn",
  },
  {
    postal_code: "60096",
    zip_name: "winthrop harbor, il",
  },
  {
    postal_code: "21050",
    zip_name: "forest hill, md",
  },
  {
    postal_code: "77517",
    zip_name: "santa fe, tx",
  },
  {
    postal_code: "8108",
    zip_name: "collingswood, nj",
  },
  {
    postal_code: "22728",
    zip_name: "midland, va",
  },
  {
    postal_code: "38553",
    zip_name: "clarkrange, tn",
  },
  {
    postal_code: "60043",
    zip_name: "kenilworth, il",
  },
  {
    postal_code: "3452",
    zip_name: "jaffrey, nh",
  },
  {
    postal_code: "1089",
    zip_name: "west springfield, ma",
  },
  {
    postal_code: "21017",
    zip_name: "belcamp, md",
  },
  {
    postal_code: "84017",
    zip_name: "coalville, ut",
  },
  {
    postal_code: "91205",
    zip_name: "glendale, ca",
  },
  {
    postal_code: "49224",
    zip_name: "albion, mi",
  },
  {
    postal_code: "22824",
    zip_name: "edinburg, va",
  },
  {
    postal_code: "38474",
    zip_name: "mount pleasant, tn",
  },
  {
    postal_code: "47304",
    zip_name: "muncie, in",
  },
  {
    postal_code: "81240",
    zip_name: "penrose, co",
  },
  {
    postal_code: "18964",
    zip_name: "souderton, pa",
  },
  {
    postal_code: "90057",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "3256",
    zip_name: "new hampton, nh",
  },
  {
    postal_code: "20634",
    zip_name: "great mills, md",
  },
  {
    postal_code: "13152",
    zip_name: "skaneateles, ny",
  },
  {
    postal_code: "1550",
    zip_name: "southbridge, ma",
  },
  {
    postal_code: "12809",
    zip_name: "argyle, ny",
  },
  {
    postal_code: "56751",
    zip_name: "roseau, mn",
  },
  {
    postal_code: "8050",
    zip_name: "manahawkin, nj",
  },
  {
    postal_code: "44040",
    zip_name: "gates mills, oh",
  },
  {
    postal_code: "15089",
    zip_name: "west newton, pa",
  },
  {
    postal_code: "32948",
    zip_name: "fellsmere, fl",
  },
  {
    postal_code: "63343",
    zip_name: "elsberry, mo",
  },
  {
    postal_code: "11935",
    zip_name: "cutchogue, ny",
  },
  {
    postal_code: "11944",
    zip_name: "greenport, ny",
  },
  {
    postal_code: "70374",
    zip_name: "lockport, la",
  },
  {
    postal_code: "43724",
    zip_name: "caldwell, oh",
  },
  {
    postal_code: "55320",
    zip_name: "clearwater, mn",
  },
  {
    postal_code: "25832",
    zip_name: "daniels, wv",
  },
  {
    postal_code: "79339",
    zip_name: "littlefield, tx",
  },
  {
    postal_code: "64040",
    zip_name: "holden, mo",
  },
  {
    postal_code: "63353",
    zip_name: "louisiana, mo",
  },
  {
    postal_code: "61053",
    zip_name: "mount carroll, il",
  },
  {
    postal_code: "78026",
    zip_name: "jourdanton, tx",
  },
  {
    postal_code: "50061",
    zip_name: "cumming, ia",
  },
  {
    postal_code: "60501",
    zip_name: "summit argo, il",
  },
  {
    postal_code: "28114",
    zip_name: "mooresboro, nc",
  },
  {
    postal_code: "95316",
    zip_name: "denair, ca",
  },
  {
    postal_code: "2351",
    zip_name: "abington, ma",
  },
  {
    postal_code: "43608",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "43783",
    zip_name: "somerset, oh",
  },
  {
    postal_code: "13648",
    zip_name: "harrisville, ny",
  },
  {
    postal_code: "42642",
    zip_name: "russell springs, ky",
  },
  {
    postal_code: "30560",
    zip_name: "morganton, ga",
  },
  {
    postal_code: "44438",
    zip_name: "masury, oh",
  },
  {
    postal_code: "13357",
    zip_name: "ilion, ny",
  },
  {
    postal_code: "31211",
    zip_name: "macon, ga",
  },
  {
    postal_code: "30557",
    zip_name: "martin, ga",
  },
  {
    postal_code: "1752",
    zip_name: "marlborough, ma",
  },
  {
    postal_code: "39204",
    zip_name: "jackson, ms",
  },
  {
    postal_code: "43019",
    zip_name: "fredericktown, oh",
  },
  {
    postal_code: "53910",
    zip_name: "adams, wi",
  },
  {
    postal_code: "51012",
    zip_name: "cherokee, ia",
  },
  {
    postal_code: "35049",
    zip_name: "cleveland, al",
  },
  {
    postal_code: "96025",
    zip_name: "dunsmuir, ca",
  },
  {
    postal_code: "75202",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "50074",
    zip_name: "ellston, ia",
  },
  {
    postal_code: "86432",
    zip_name: "littlefield, az",
  },
  {
    postal_code: "48505",
    zip_name: "flint, mi",
  },
  {
    postal_code: "42748",
    zip_name: "hodgenville, ky",
  },
  {
    postal_code: "78368",
    zip_name: "mathis, tx",
  },
  {
    postal_code: "48188",
    zip_name: "canton, mi",
  },
  {
    postal_code: "8033",
    zip_name: "haddonfield, nj",
  },
  {
    postal_code: "83263",
    zip_name: "preston, id",
  },
  {
    postal_code: "36201",
    zip_name: "anniston, al",
  },
  {
    postal_code: "19344",
    zip_name: "honey brook, pa",
  },
  {
    postal_code: "13057",
    zip_name: "east syracuse, ny",
  },
  {
    postal_code: "71301",
    zip_name: "alexandria, la",
  },
  {
    postal_code: "56573",
    zip_name: "perham, mn",
  },
  {
    postal_code: "76802",
    zip_name: "early, tx",
  },
  {
    postal_code: "8244",
    zip_name: "somers point, nj",
  },
  {
    postal_code: "29511",
    zip_name: "aynor, sc",
  },
  {
    postal_code: "96020",
    zip_name: "chester, ca",
  },
  {
    postal_code: "94965",
    zip_name: "sausalito, ca",
  },
  {
    postal_code: "54568",
    zip_name: "woodruff, wi",
  },
  {
    postal_code: "26711",
    zip_name: "capon bridge, wv",
  },
  {
    postal_code: "93737",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "6374",
    zip_name: "plainfield, ct",
  },
  {
    postal_code: "29330",
    zip_name: "cowpens, sc",
  },
  {
    postal_code: "92661",
    zip_name: "newport beach, ca",
  },
  {
    postal_code: "16423",
    zip_name: "lake city, pa",
  },
  {
    postal_code: "45368",
    zip_name: "south charleston, oh",
  },
  {
    postal_code: "67665",
    zip_name: "russell, ks",
  },
  {
    postal_code: "63357",
    zip_name: "marthasville, mo",
  },
  {
    postal_code: "31513",
    zip_name: "baxley, ga",
  },
  {
    postal_code: "39574",
    zip_name: "saucier, ms",
  },
  {
    postal_code: "68521",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "92027",
    zip_name: "escondido, ca",
  },
  {
    postal_code: "61734",
    zip_name: "delavan, il",
  },
  {
    postal_code: "67068",
    zip_name: "kingman, ks",
  },
  {
    postal_code: "12461",
    zip_name: "olivebridge, ny",
  },
  {
    postal_code: "50161",
    zip_name: "maxwell, ia",
  },
  {
    postal_code: "15014",
    zip_name: "brackenridge, pa",
  },
  {
    postal_code: "17814",
    zip_name: "benton, pa",
  },
  {
    postal_code: "60021",
    zip_name: "fox river grove, il",
  },
  {
    postal_code: "5143",
    zip_name: "chester, vt",
  },
  {
    postal_code: "4046",
    zip_name: "kennebunkport, me",
  },
  {
    postal_code: "65052",
    zip_name: "linn creek, mo",
  },
  {
    postal_code: "53578",
    zip_name: "prairie du sac, wi",
  },
  {
    postal_code: "74728",
    zip_name: "broken bow, ok",
  },
  {
    postal_code: "96146",
    zip_name: "olympic valley, ca",
  },
  {
    postal_code: "49841",
    zip_name: "gwinn, mi",
  },
  {
    postal_code: "19350",
    zip_name: "landenberg, pa",
  },
  {
    postal_code: "14892",
    zip_name: "waverly, ny",
  },
  {
    postal_code: "32348",
    zip_name: "perry, fl",
  },
  {
    postal_code: "89429",
    zip_name: "silver springs, nv",
  },
  {
    postal_code: "1760",
    zip_name: "natick, ma",
  },
  {
    postal_code: "32347",
    zip_name: "perry, fl",
  },
  {
    postal_code: "78363",
    zip_name: "kingsville, tx",
  },
  {
    postal_code: "2790",
    zip_name: "westport, ma",
  },
  {
    postal_code: "97368",
    zip_name: "otis, or",
  },
  {
    postal_code: "77461",
    zip_name: "needville, tx",
  },
  {
    postal_code: "70358",
    zip_name: "grand isle, la",
  },
  {
    postal_code: "8048",
    zip_name: "lumberton, nj",
  },
  {
    postal_code: "5676",
    zip_name: "waterbury, vt",
  },
  {
    postal_code: "30527",
    zip_name: "clermont, ga",
  },
  {
    postal_code: "22625",
    zip_name: "cross junction, va",
  },
  {
    postal_code: "49644",
    zip_name: "irons, mi",
  },
  {
    postal_code: "1749",
    zip_name: "hudson, ma",
  },
  {
    postal_code: "71405",
    zip_name: "ball, la",
  },
  {
    postal_code: "49242",
    zip_name: "hillsdale, mi",
  },
  {
    postal_code: "4073",
    zip_name: "sanford, me",
  },
  {
    postal_code: "6901",
    zip_name: "stamford, ct",
  },
  {
    postal_code: "98239",
    zip_name: "coupeville, wa",
  },
  {
    postal_code: "1253",
    zip_name: "otis, ma",
  },
  {
    postal_code: "12117",
    zip_name: "mayfield, ny",
  },
  {
    postal_code: "45807",
    zip_name: "lima, oh",
  },
  {
    postal_code: "94606",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "55390",
    zip_name: "waverly, mn",
  },
  {
    postal_code: "82401",
    zip_name: "worland, wy",
  },
  {
    postal_code: "19081",
    zip_name: "swarthmore, pa",
  },
  {
    postal_code: "8753",
    zip_name: "toms river, nj",
  },
  {
    postal_code: "31320",
    zip_name: "midway, ga",
  },
  {
    postal_code: "65233",
    zip_name: "boonville, mo",
  },
  {
    postal_code: "66053",
    zip_name: "louisburg, ks",
  },
  {
    postal_code: "25312",
    zip_name: "charleston, wv",
  },
  {
    postal_code: "75706",
    zip_name: "tyler, tx",
  },
  {
    postal_code: "94960",
    zip_name: "san anselmo, ca",
  },
  {
    postal_code: "92320",
    zip_name: "calimesa, ca",
  },
  {
    postal_code: "32317",
    zip_name: "tallahassee, fl",
  },
  {
    postal_code: "37324",
    zip_name: "decherd, tn",
  },
  {
    postal_code: "30734",
    zip_name: "ranger, ga",
  },
  {
    postal_code: "95018",
    zip_name: "felton, ca",
  },
  {
    postal_code: "22044",
    zip_name: "falls church, va",
  },
  {
    postal_code: "46017",
    zip_name: "anderson, in",
  },
  {
    postal_code: "3561",
    zip_name: "littleton, nh",
  },
  {
    postal_code: "16403",
    zip_name: "cambridge springs, pa",
  },
  {
    postal_code: "37379",
    zip_name: "soddy daisy, tn",
  },
  {
    postal_code: "53142",
    zip_name: "kenosha, wi",
  },
  {
    postal_code: "8223",
    zip_name: "marmora, nj",
  },
  {
    postal_code: "16121",
    zip_name: "farrell, pa",
  },
  {
    postal_code: "13077",
    zip_name: "homer, ny",
  },
  {
    postal_code: "12871",
    zip_name: "schuylerville, ny",
  },
  {
    postal_code: "70438",
    zip_name: "franklinton, la",
  },
  {
    postal_code: "62450",
    zip_name: "olney, il",
  },
  {
    postal_code: "78013",
    zip_name: "comfort, tx",
  },
  {
    postal_code: "79536",
    zip_name: "merkel, tx",
  },
  {
    postal_code: "30530",
    zip_name: "commerce, ga",
  },
  {
    postal_code: "97148",
    zip_name: "yamhill, or",
  },
  {
    postal_code: "1109",
    zip_name: "springfield, ma",
  },
  {
    postal_code: "38826",
    zip_name: "belden, ms",
  },
  {
    postal_code: "21754",
    zip_name: "ijamsville, md",
  },
  {
    postal_code: "73703",
    zip_name: "enid, ok",
  },
  {
    postal_code: "29924",
    zip_name: "hampton, sc",
  },
  {
    postal_code: "72086",
    zip_name: "lonoke, ar",
  },
  {
    postal_code: "19468",
    zip_name: "royersford, pa",
  },
  {
    postal_code: "54940",
    zip_name: "fremont, wi",
  },
  {
    postal_code: "6791",
    zip_name: "harwinton, ct",
  },
  {
    postal_code: "2907",
    zip_name: "providence, ri",
  },
  {
    postal_code: "94552",
    zip_name: "castro valley, ca",
  },
  {
    postal_code: "12569",
    zip_name: "pleasant valley, ny",
  },
  {
    postal_code: "2740",
    zip_name: "new bedford, ma",
  },
  {
    postal_code: "77642",
    zip_name: "port arthur, tx",
  },
  {
    postal_code: "2132",
    zip_name: "west roxbury, ma",
  },
  {
    postal_code: "45135",
    zip_name: "leesburg, oh",
  },
  {
    postal_code: "90022",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "63334",
    zip_name: "bowling green, mo",
  },
  {
    postal_code: "55049",
    zip_name: "medford, mn",
  },
  {
    postal_code: "12526",
    zip_name: "germantown, ny",
  },
  {
    postal_code: "3862",
    zip_name: "north hampton, nh",
  },
  {
    postal_code: "13029",
    zip_name: "brewerton, ny",
  },
  {
    postal_code: "41635",
    zip_name: "harold, ky",
  },
  {
    postal_code: "1902",
    zip_name: "lynn, ma",
  },
  {
    postal_code: "1238",
    zip_name: "lee, ma",
  },
  {
    postal_code: "46120",
    zip_name: "cloverdale, in",
  },
  {
    postal_code: "93013",
    zip_name: "carpinteria, ca",
  },
  {
    postal_code: "84111",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "98024",
    zip_name: "fall city, wa",
  },
  {
    postal_code: "11553",
    zip_name: "uniondale, ny",
  },
  {
    postal_code: "55415",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "3281",
    zip_name: "weare, nh",
  },
  {
    postal_code: "44432",
    zip_name: "lisbon, oh",
  },
  {
    postal_code: "37881",
    zip_name: "thorn hill, tn",
  },
  {
    postal_code: "53574",
    zip_name: "new glarus, wi",
  },
  {
    postal_code: "21750",
    zip_name: "hancock, md",
  },
  {
    postal_code: "48746",
    zip_name: "millington, mi",
  },
  {
    postal_code: "14886",
    zip_name: "trumansburg, ny",
  },
  {
    postal_code: "11418",
    zip_name: "richmond hill, ny",
  },
  {
    postal_code: "75497",
    zip_name: "yantis, tx",
  },
  {
    postal_code: "43315",
    zip_name: "cardington, oh",
  },
  {
    postal_code: "8628",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "13108",
    zip_name: "marcellus, ny",
  },
  {
    postal_code: "21210",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "72658",
    zip_name: "norfork, ar",
  },
  {
    postal_code: "92878",
    zip_name: "corona, ca",
  },
  {
    postal_code: "19312",
    zip_name: "berwyn, pa",
  },
  {
    postal_code: "15906",
    zip_name: "johnstown, pa",
  },
  {
    postal_code: "4074",
    zip_name: "scarborough, me",
  },
  {
    postal_code: "25387",
    zip_name: "charleston, wv",
  },
  {
    postal_code: "90701",
    zip_name: "artesia, ca",
  },
  {
    postal_code: "55981",
    zip_name: "wabasha, mn",
  },
  {
    postal_code: "77859",
    zip_name: "hearne, tx",
  },
  {
    postal_code: "48126",
    zip_name: "dearborn, mi",
  },
  {
    postal_code: "6756",
    zip_name: "goshen, ct",
  },
  {
    postal_code: "54829",
    zip_name: "cumberland, wi",
  },
  {
    postal_code: "49878",
    zip_name: "rapid river, mi",
  },
  {
    postal_code: "27910",
    zip_name: "ahoskie, nc",
  },
  {
    postal_code: "61360",
    zip_name: "seneca, il",
  },
  {
    postal_code: "88030",
    zip_name: "deming, nm",
  },
  {
    postal_code: "92078",
    zip_name: "san marcos, ca",
  },
  {
    postal_code: "35020",
    zip_name: "bessemer, al",
  },
  {
    postal_code: "37353",
    zip_name: "mc donald, tn",
  },
  {
    postal_code: "12941",
    zip_name: "jay, ny",
  },
  {
    postal_code: "62939",
    zip_name: "goreville, il",
  },
  {
    postal_code: "39567",
    zip_name: "pascagoula, ms",
  },
  {
    postal_code: "93427",
    zip_name: "buellton, ca",
  },
  {
    postal_code: "95811",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "34756",
    zip_name: "montverde, fl",
  },
  {
    postal_code: "28128",
    zip_name: "norwood, nc",
  },
  {
    postal_code: "11954",
    zip_name: "montauk, ny",
  },
  {
    postal_code: "27605",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "11752",
    zip_name: "islip terrace, ny",
  },
  {
    postal_code: "14812",
    zip_name: "beaver dams, ny",
  },
  {
    postal_code: "53950",
    zip_name: "new lisbon, wi",
  },
  {
    postal_code: "79331",
    zip_name: "lamesa, tx",
  },
  {
    postal_code: "55005",
    zip_name: "bethel, mn",
  },
  {
    postal_code: "71302",
    zip_name: "alexandria, la",
  },
  {
    postal_code: "5446",
    zip_name: "colchester, vt",
  },
  {
    postal_code: "44302",
    zip_name: "akron, oh",
  },
  {
    postal_code: "22301",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "37101",
    zip_name: "mc ewen, tn",
  },
  {
    postal_code: "69357",
    zip_name: "mitchell, ne",
  },
  {
    postal_code: "4345",
    zip_name: "gardiner, me",
  },
  {
    postal_code: "18938",
    zip_name: "new hope, pa",
  },
  {
    postal_code: "19082",
    zip_name: "upper darby, pa",
  },
  {
    postal_code: "48429",
    zip_name: "durand, mi",
  },
  {
    postal_code: "35005",
    zip_name: "adamsville, al",
  },
  {
    postal_code: "5255",
    zip_name: "manchester center, vt",
  },
  {
    postal_code: "57719",
    zip_name: "box elder, sd",
  },
  {
    postal_code: "14810",
    zip_name: "bath, ny",
  },
  {
    postal_code: "48131",
    zip_name: "dundee, mi",
  },
  {
    postal_code: "71602",
    zip_name: "white hall, ar",
  },
  {
    postal_code: "2026",
    zip_name: "dedham, ma",
  },
  {
    postal_code: "3909",
    zip_name: "york, me",
  },
  {
    postal_code: "98362",
    zip_name: "port angeles, wa",
  },
  {
    postal_code: "66047",
    zip_name: "lawrence, ks",
  },
  {
    postal_code: "65336",
    zip_name: "knob noster, mo",
  },
  {
    postal_code: "54201",
    zip_name: "algoma, wi",
  },
  {
    postal_code: "21769",
    zip_name: "middletown, md",
  },
  {
    postal_code: "1440",
    zip_name: "gardner, ma",
  },
  {
    postal_code: "31907",
    zip_name: "columbus, ga",
  },
  {
    postal_code: "48371",
    zip_name: "oxford, mi",
  },
  {
    postal_code: "6830",
    zip_name: "greenwich, ct",
  },
  {
    postal_code: "38201",
    zip_name: "mc kenzie, tn",
  },
  {
    postal_code: "69138",
    zip_name: "gothenburg, ne",
  },
  {
    postal_code: "21131",
    zip_name: "phoenix, md",
  },
  {
    postal_code: "6896",
    zip_name: "redding, ct",
  },
  {
    postal_code: "10039",
    zip_name: "new york, ny",
  },
  {
    postal_code: "75021",
    zip_name: "denison, tx",
  },
  {
    postal_code: "68102",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "75058",
    zip_name: "gunter, tx",
  },
  {
    postal_code: "29666",
    zip_name: "ninety six, sc",
  },
  {
    postal_code: "6420",
    zip_name: "salem, ct",
  },
  {
    postal_code: "59330",
    zip_name: "glendive, mt",
  },
  {
    postal_code: "2135",
    zip_name: "brighton, ma",
  },
  {
    postal_code: "93256",
    zip_name: "pixley, ca",
  },
  {
    postal_code: "8731",
    zip_name: "forked river, nj",
  },
  {
    postal_code: "6515",
    zip_name: "new haven, ct",
  },
  {
    postal_code: "95255",
    zip_name: "west point, ca",
  },
  {
    postal_code: "93953",
    zip_name: "pebble beach, ca",
  },
  {
    postal_code: "38382",
    zip_name: "trenton, tn",
  },
  {
    postal_code: "8758",
    zip_name: "waretown, nj",
  },
  {
    postal_code: "27968",
    zip_name: "rodanthe, nc",
  },
  {
    postal_code: "28684",
    zip_name: "todd, nc",
  },
  {
    postal_code: "28551",
    zip_name: "la grange, nc",
  },
  {
    postal_code: "70466",
    zip_name: "tickfaw, la",
  },
  {
    postal_code: "49240",
    zip_name: "grass lake, mi",
  },
  {
    postal_code: "56164",
    zip_name: "pipestone, mn",
  },
  {
    postal_code: "95329",
    zip_name: "la grange, ca",
  },
  {
    postal_code: "46312",
    zip_name: "east chicago, in",
  },
  {
    postal_code: "29685",
    zip_name: "sunset, sc",
  },
  {
    postal_code: "46173",
    zip_name: "rushville, in",
  },
  {
    postal_code: "10512",
    zip_name: "carmel, ny",
  },
  {
    postal_code: "1570",
    zip_name: "webster, ma",
  },
  {
    postal_code: "55963",
    zip_name: "pine island, mn",
  },
  {
    postal_code: "19046",
    zip_name: "jenkintown, pa",
  },
  {
    postal_code: "23666",
    zip_name: "hampton, va",
  },
  {
    postal_code: "56063",
    zip_name: "madison lake, mn",
  },
  {
    postal_code: "8501",
    zip_name: "allentown, nj",
  },
  {
    postal_code: "11692",
    zip_name: "arverne, ny",
  },
  {
    postal_code: "27258",
    zip_name: "haw river, nc",
  },
  {
    postal_code: "65704",
    zip_name: "mansfield, mo",
  },
  {
    postal_code: "55718",
    zip_name: "carlton, mn",
  },
  {
    postal_code: "96101",
    zip_name: "alturas, ca",
  },
  {
    postal_code: "1118",
    zip_name: "springfield, ma",
  },
  {
    postal_code: "3584",
    zip_name: "lancaster, nh",
  },
  {
    postal_code: "54025",
    zip_name: "somerset, wi",
  },
  {
    postal_code: "20015",
    zip_name: "washington, dc",
  },
  {
    postal_code: "58622",
    zip_name: "belfield, nd",
  },
  {
    postal_code: "49920",
    zip_name: "crystal falls, mi",
  },
  {
    postal_code: "2760",
    zip_name: "north attleboro, ma",
  },
  {
    postal_code: "26187",
    zip_name: "williamstown, wv",
  },
  {
    postal_code: "7717",
    zip_name: "avon by the sea, nj",
  },
  {
    postal_code: "15223",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "1890",
    zip_name: "winchester, ma",
  },
  {
    postal_code: "70737",
    zip_name: "gonzales, la",
  },
  {
    postal_code: "82716",
    zip_name: "gillette, wy",
  },
  {
    postal_code: "11933",
    zip_name: "calverton, ny",
  },
  {
    postal_code: "11971",
    zip_name: "southold, ny",
  },
  {
    postal_code: "12029",
    zip_name: "canaan, ny",
  },
  {
    postal_code: "49862",
    zip_name: "munising, mi",
  },
  {
    postal_code: "33786",
    zip_name: "belleair beach, fl",
  },
  {
    postal_code: "90033",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "60165",
    zip_name: "stone park, il",
  },
  {
    postal_code: "37022",
    zip_name: "bethpage, tn",
  },
  {
    postal_code: "11368",
    zip_name: "corona, ny",
  },
  {
    postal_code: "84312",
    zip_name: "garland, ut",
  },
  {
    postal_code: "3901",
    zip_name: "berwick, me",
  },
  {
    postal_code: "19465",
    zip_name: "pottstown, pa",
  },
  {
    postal_code: "79311",
    zip_name: "abernathy, tx",
  },
  {
    postal_code: "55927",
    zip_name: "dodge center, mn",
  },
  {
    postal_code: "61880",
    zip_name: "tolono, il",
  },
  {
    postal_code: "95315",
    zip_name: "delhi, ca",
  },
  {
    postal_code: "55072",
    zip_name: "sandstone, mn",
  },
  {
    postal_code: "74352",
    zip_name: "locust grove, ok",
  },
  {
    postal_code: "96142",
    zip_name: "tahoma, ca",
  },
  {
    postal_code: "1810",
    zip_name: "andover, ma",
  },
  {
    postal_code: "21227",
    zip_name: "halethorpe, md",
  },
  {
    postal_code: "24360",
    zip_name: "max meadows, va",
  },
  {
    postal_code: "78119",
    zip_name: "kenedy, tx",
  },
  {
    postal_code: "19040",
    zip_name: "hatboro, pa",
  },
  {
    postal_code: "2453",
    zip_name: "waltham, ma",
  },
  {
    postal_code: "18915",
    zip_name: "colmar, pa",
  },
  {
    postal_code: "40390",
    zip_name: "wilmore, ky",
  },
  {
    postal_code: "63764",
    zip_name: "marble hill, mo",
  },
  {
    postal_code: "61025",
    zip_name: "east dubuque, il",
  },
  {
    postal_code: "93240",
    zip_name: "lake isabella, ca",
  },
  {
    postal_code: "11792",
    zip_name: "wading river, ny",
  },
  {
    postal_code: "67544",
    zip_name: "hoisington, ks",
  },
  {
    postal_code: "43310",
    zip_name: "belle center, oh",
  },
  {
    postal_code: "76834",
    zip_name: "coleman, tx",
  },
  {
    postal_code: "12827",
    zip_name: "fort ann, ny",
  },
  {
    postal_code: "50063",
    zip_name: "dallas center, ia",
  },
  {
    postal_code: "43103",
    zip_name: "ashville, oh",
  },
  {
    postal_code: "14867",
    zip_name: "newfield, ny",
  },
  {
    postal_code: "47320",
    zip_name: "albany, in",
  },
  {
    postal_code: "43713",
    zip_name: "barnesville, oh",
  },
  {
    postal_code: "44618",
    zip_name: "dalton, oh",
  },
  {
    postal_code: "27055",
    zip_name: "yadkinville, nc",
  },
  {
    postal_code: "55321",
    zip_name: "cokato, mn",
  },
  {
    postal_code: "1830",
    zip_name: "haverhill, ma",
  },
  {
    postal_code: "80260",
    zip_name: "denver, co",
  },
  {
    postal_code: "5452",
    zip_name: "essex junction, vt",
  },
  {
    postal_code: "12157",
    zip_name: "schoharie, ny",
  },
  {
    postal_code: "1569",
    zip_name: "uxbridge, ma",
  },
  {
    postal_code: "17408",
    zip_name: "york, pa",
  },
  {
    postal_code: "53094",
    zip_name: "watertown, wi",
  },
  {
    postal_code: "46404",
    zip_name: "gary, in",
  },
  {
    postal_code: "53941",
    zip_name: "la valle, wi",
  },
  {
    postal_code: "19086",
    zip_name: "wallingford, pa",
  },
  {
    postal_code: "19904",
    zip_name: "dover, de",
  },
  {
    postal_code: "3813",
    zip_name: "center conway, nh",
  },
  {
    postal_code: "17315",
    zip_name: "dover, pa",
  },
  {
    postal_code: "97007",
    zip_name: "beaverton, or",
  },
  {
    postal_code: "83276",
    zip_name: "soda springs, id",
  },
  {
    postal_code: "44072",
    zip_name: "novelty, oh",
  },
  {
    postal_code: "77871",
    zip_name: "normangee, tx",
  },
  {
    postal_code: "16507",
    zip_name: "erie, pa",
  },
  {
    postal_code: "12835",
    zip_name: "hadley, ny",
  },
  {
    postal_code: "79501",
    zip_name: "anson, tx",
  },
  {
    postal_code: "3222",
    zip_name: "bristol, nh",
  },
  {
    postal_code: "36863",
    zip_name: "lanett, al",
  },
  {
    postal_code: "10469",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "11797",
    zip_name: "woodbury, ny",
  },
  {
    postal_code: "53565",
    zip_name: "mineral point, wi",
  },
  {
    postal_code: "2061",
    zip_name: "norwell, ma",
  },
  {
    postal_code: "41040",
    zip_name: "falmouth, ky",
  },
  {
    postal_code: "99712",
    zip_name: "fairbanks, ak",
  },
  {
    postal_code: "43610",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "3070",
    zip_name: "new boston, nh",
  },
  {
    postal_code: "28450",
    zip_name: "lake waccamaw, nc",
  },
  {
    postal_code: "78656",
    zip_name: "maxwell, tx",
  },
  {
    postal_code: "5156",
    zip_name: "springfield, vt",
  },
  {
    postal_code: "95694",
    zip_name: "winters, ca",
  },
  {
    postal_code: "8312",
    zip_name: "clayton, nj",
  },
  {
    postal_code: "11789",
    zip_name: "sound beach, ny",
  },
  {
    postal_code: "12553",
    zip_name: "new windsor, ny",
  },
  {
    postal_code: "48763",
    zip_name: "tawas city, mi",
  },
  {
    postal_code: "43830",
    zip_name: "nashport, oh",
  },
  {
    postal_code: "53559",
    zip_name: "marshall, wi",
  },
  {
    postal_code: "15902",
    zip_name: "johnstown, pa",
  },
  {
    postal_code: "75424",
    zip_name: "blue ridge, tx",
  },
  {
    postal_code: "37681",
    zip_name: "limestone, tn",
  },
  {
    postal_code: "2777",
    zip_name: "swansea, ma",
  },
  {
    postal_code: "24120",
    zip_name: "meadows of dan, va",
  },
  {
    postal_code: "46310",
    zip_name: "demotte, in",
  },
  {
    postal_code: "28174",
    zip_name: "wingate, nc",
  },
  {
    postal_code: "41102",
    zip_name: "ashland, ky",
  },
  {
    postal_code: "55041",
    zip_name: "lake city, mn",
  },
  {
    postal_code: "75656",
    zip_name: "hughes springs, tx",
  },
  {
    postal_code: "70452",
    zip_name: "pearl river, la",
  },
  {
    postal_code: "50441",
    zip_name: "hampton, ia",
  },
  {
    postal_code: "49420",
    zip_name: "hart, mi",
  },
  {
    postal_code: "94040",
    zip_name: "mountain view, ca",
  },
  {
    postal_code: "35960",
    zip_name: "centre, al",
  },
  {
    postal_code: "95692",
    zip_name: "wheatland, ca",
  },
  {
    postal_code: "93630",
    zip_name: "kerman, ca",
  },
  {
    postal_code: "83804",
    zip_name: "blanchard, id",
  },
  {
    postal_code: "20754",
    zip_name: "dunkirk, md",
  },
  {
    postal_code: "55435",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "37711",
    zip_name: "bulls gap, tn",
  },
  {
    postal_code: "97236",
    zip_name: "portland, or",
  },
  {
    postal_code: "12563",
    zip_name: "patterson, ny",
  },
  {
    postal_code: "16417",
    zip_name: "girard, pa",
  },
  {
    postal_code: "38570",
    zip_name: "livingston, tn",
  },
  {
    postal_code: "84055",
    zip_name: "oakley, ut",
  },
  {
    postal_code: "75709",
    zip_name: "tyler, tx",
  },
  {
    postal_code: "74848",
    zip_name: "holdenville, ok",
  },
  {
    postal_code: "1106",
    zip_name: "longmeadow, ma",
  },
  {
    postal_code: "6085",
    zip_name: "unionville, ct",
  },
  {
    postal_code: "31794",
    zip_name: "tifton, ga",
  },
  {
    postal_code: "4210",
    zip_name: "auburn, me",
  },
  {
    postal_code: "92806",
    zip_name: "anaheim, ca",
  },
  {
    postal_code: "28466",
    zip_name: "wallace, nc",
  },
  {
    postal_code: "47807",
    zip_name: "terre haute, in",
  },
  {
    postal_code: "78231",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "92276",
    zip_name: "thousand palms, ca",
  },
  {
    postal_code: "11518",
    zip_name: "east rockaway, ny",
  },
  {
    postal_code: "79404",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "37082",
    zip_name: "kingston springs, tn",
  },
  {
    postal_code: "84647",
    zip_name: "mount pleasant, ut",
  },
  {
    postal_code: "55037",
    zip_name: "hinckley, mn",
  },
  {
    postal_code: "19015",
    zip_name: "brookhaven, pa",
  },
  {
    postal_code: "90062",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "59019",
    zip_name: "columbus, mt",
  },
  {
    postal_code: "62243",
    zip_name: "freeburg, il",
  },
  {
    postal_code: "4412",
    zip_name: "brewer, me",
  },
  {
    postal_code: "89501",
    zip_name: "reno, nv",
  },
  {
    postal_code: "78648",
    zip_name: "luling, tx",
  },
  {
    postal_code: "65633",
    zip_name: "crane, mo",
  },
  {
    postal_code: "75158",
    zip_name: "scurry, tx",
  },
  {
    postal_code: "92313",
    zip_name: "grand terrace, ca",
  },
  {
    postal_code: "36105",
    zip_name: "montgomery, al",
  },
  {
    postal_code: "47905",
    zip_name: "lafayette, in",
  },
  {
    postal_code: "33838",
    zip_name: "dundee, fl",
  },
  {
    postal_code: "28129",
    zip_name: "oakboro, nc",
  },
  {
    postal_code: "59421",
    zip_name: "cascade, mt",
  },
  {
    postal_code: "37692",
    zip_name: "unicoi, tn",
  },
  {
    postal_code: "97498",
    zip_name: "yachats, or",
  },
  {
    postal_code: "66211",
    zip_name: "leawood, ks",
  },
  {
    postal_code: "61858",
    zip_name: "oakwood, il",
  },
  {
    postal_code: "84629",
    zip_name: "fairview, ut",
  },
  {
    postal_code: "29554",
    zip_name: "hemingway, sc",
  },
  {
    postal_code: "3585",
    zip_name: "lisbon, nh",
  },
  {
    postal_code: "21128",
    zip_name: "perry hall, md",
  },
  {
    postal_code: "19934",
    zip_name: "camden wyoming, de",
  },
  {
    postal_code: "52340",
    zip_name: "tiffin, ia",
  },
  {
    postal_code: "6335",
    zip_name: "gales ferry, ct",
  },
  {
    postal_code: "75604",
    zip_name: "longview, tx",
  },
  {
    postal_code: "14760",
    zip_name: "olean, ny",
  },
  {
    postal_code: "59644",
    zip_name: "townsend, mt",
  },
  {
    postal_code: "48855",
    zip_name: "howell, mi",
  },
  {
    postal_code: "19085",
    zip_name: "villanova, pa",
  },
  {
    postal_code: "97128",
    zip_name: "mcminnville, or",
  },
  {
    postal_code: "8084",
    zip_name: "stratford, nj",
  },
  {
    postal_code: "32949",
    zip_name: "grant, fl",
  },
  {
    postal_code: "12883",
    zip_name: "ticonderoga, ny",
  },
  {
    postal_code: "81223",
    zip_name: "cotopaxi, co",
  },
  {
    postal_code: "59833",
    zip_name: "florence, mt",
  },
  {
    postal_code: "92606",
    zip_name: "irvine, ca",
  },
  {
    postal_code: "94402",
    zip_name: "san mateo, ca",
  },
  {
    postal_code: "3446",
    zip_name: "swanzey, nh",
  },
  {
    postal_code: "11719",
    zip_name: "brookhaven, ny",
  },
  {
    postal_code: "66086",
    zip_name: "tonganoxie, ks",
  },
  {
    postal_code: "89034",
    zip_name: "mesquite, nv",
  },
  {
    postal_code: "8558",
    zip_name: "skillman, nj",
  },
  {
    postal_code: "38583",
    zip_name: "sparta, tn",
  },
  {
    postal_code: "80249",
    zip_name: "denver, co",
  },
  {
    postal_code: "18011",
    zip_name: "alburtis, pa",
  },
  {
    postal_code: "75644",
    zip_name: "gilmer, tx",
  },
  {
    postal_code: "79364",
    zip_name: "slaton, tx",
  },
  {
    postal_code: "10467",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "6112",
    zip_name: "hartford, ct",
  },
  {
    postal_code: "28127",
    zip_name: "new london, nc",
  },
  {
    postal_code: "17222",
    zip_name: "fayetteville, pa",
  },
  {
    postal_code: "77031",
    zip_name: "houston, tx",
  },
  {
    postal_code: "55960",
    zip_name: "oronoco, mn",
  },
  {
    postal_code: "48656",
    zip_name: "saint helen, mi",
  },
  {
    postal_code: "29582",
    zip_name: "north myrtle beach, sc",
  },
  {
    postal_code: "34787",
    zip_name: "winter garden, fl",
  },
  {
    postal_code: "80134",
    zip_name: "parker, co",
  },
  {
    postal_code: "11558",
    zip_name: "island park, ny",
  },
  {
    postal_code: "90001",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "96021",
    zip_name: "corning, ca",
  },
  {
    postal_code: "30558",
    zip_name: "maysville, ga",
  },
  {
    postal_code: "3264",
    zip_name: "plymouth, nh",
  },
  {
    postal_code: "83254",
    zip_name: "montpelier, id",
  },
  {
    postal_code: "48840",
    zip_name: "haslett, mi",
  },
  {
    postal_code: "50054",
    zip_name: "colfax, ia",
  },
  {
    postal_code: "24340",
    zip_name: "glade spring, va",
  },
  {
    postal_code: "45120",
    zip_name: "felicity, oh",
  },
  {
    postal_code: "91708",
    zip_name: "chino, ca",
  },
  {
    postal_code: "1886",
    zip_name: "westford, ma",
  },
  {
    postal_code: "95965",
    zip_name: "oroville, ca",
  },
  {
    postal_code: "37370",
    zip_name: "riceville, tn",
  },
  {
    postal_code: "80808",
    zip_name: "calhan, co",
  },
  {
    postal_code: "13904",
    zip_name: "binghamton, ny",
  },
  {
    postal_code: "90301",
    zip_name: "inglewood, ca",
  },
  {
    postal_code: "13350",
    zip_name: "herkimer, ny",
  },
  {
    postal_code: "95446",
    zip_name: "guerneville, ca",
  },
  {
    postal_code: "2370",
    zip_name: "rockland, ma",
  },
  {
    postal_code: "39530",
    zip_name: "biloxi, ms",
  },
  {
    postal_code: "30536",
    zip_name: "ellijay, ga",
  },
  {
    postal_code: "27310",
    zip_name: "oak ridge, nc",
  },
  {
    postal_code: "6417",
    zip_name: "deep river, ct",
  },
  {
    postal_code: "95334",
    zip_name: "livingston, ca",
  },
  {
    postal_code: "21795",
    zip_name: "williamsport, md",
  },
  {
    postal_code: "90023",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "2446",
    zip_name: "brookline, ma",
  },
  {
    postal_code: "18421",
    zip_name: "forest city, pa",
  },
  {
    postal_code: "36078",
    zip_name: "tallassee, al",
  },
  {
    postal_code: "99027",
    zip_name: "otis orchards, wa",
  },
  {
    postal_code: "97759",
    zip_name: "sisters, or",
  },
  {
    postal_code: "54024",
    zip_name: "saint croix falls, wi",
  },
  {
    postal_code: "63113",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "43334",
    zip_name: "marengo, oh",
  },
  {
    postal_code: "54952",
    zip_name: "menasha, wi",
  },
  {
    postal_code: "78605",
    zip_name: "bertram, tx",
  },
  {
    postal_code: "33540",
    zip_name: "zephyrhills, fl",
  },
  {
    postal_code: "75702",
    zip_name: "tyler, tx",
  },
  {
    postal_code: "28645",
    zip_name: "lenoir, nc",
  },
  {
    postal_code: "27976",
    zip_name: "south mills, nc",
  },
  {
    postal_code: "2130",
    zip_name: "jamaica plain, ma",
  },
  {
    postal_code: "27882",
    zip_name: "spring hope, nc",
  },
  {
    postal_code: "15461",
    zip_name: "masontown, pa",
  },
  {
    postal_code: "11716",
    zip_name: "bohemia, ny",
  },
  {
    postal_code: "28103",
    zip_name: "marshville, nc",
  },
  {
    postal_code: "78945",
    zip_name: "la grange, tx",
  },
  {
    postal_code: "5250",
    zip_name: "arlington, vt",
  },
  {
    postal_code: "23608",
    zip_name: "newport news, va",
  },
  {
    postal_code: "34474",
    zip_name: "ocala, fl",
  },
  {
    postal_code: "13078",
    zip_name: "jamesville, ny",
  },
  {
    postal_code: "44286",
    zip_name: "richfield, oh",
  },
  {
    postal_code: "2885",
    zip_name: "warren, ri",
  },
  {
    postal_code: "43469",
    zip_name: "woodville, oh",
  },
  {
    postal_code: "4043",
    zip_name: "kennebunk, me",
  },
  {
    postal_code: "44231",
    zip_name: "garrettsville, oh",
  },
  {
    postal_code: "30461",
    zip_name: "statesboro, ga",
  },
  {
    postal_code: "43240",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "12850",
    zip_name: "middle grove, ny",
  },
  {
    postal_code: "86440",
    zip_name: "mohave valley, az",
  },
  {
    postal_code: "26201",
    zip_name: "buckhannon, wv",
  },
  {
    postal_code: "12075",
    zip_name: "ghent, ny",
  },
  {
    postal_code: "85336",
    zip_name: "san luis, az",
  },
  {
    postal_code: "6605",
    zip_name: "bridgeport, ct",
  },
  {
    postal_code: "13730",
    zip_name: "afton, ny",
  },
  {
    postal_code: "12123",
    zip_name: "nassau, ny",
  },
  {
    postal_code: "55976",
    zip_name: "stewartville, mn",
  },
  {
    postal_code: "6807",
    zip_name: "cos cob, ct",
  },
  {
    postal_code: "95426",
    zip_name: "cobb, ca",
  },
  {
    postal_code: "28088",
    zip_name: "landis, nc",
  },
  {
    postal_code: "35079",
    zip_name: "hayden, al",
  },
  {
    postal_code: "99003",
    zip_name: "chattaroy, wa",
  },
  {
    postal_code: "3773",
    zip_name: "newport, nh",
  },
  {
    postal_code: "27810",
    zip_name: "belhaven, nc",
  },
  {
    postal_code: "11364",
    zip_name: "oakland gardens, ny",
  },
  {
    postal_code: "44721",
    zip_name: "canton, oh",
  },
  {
    postal_code: "55792",
    zip_name: "virginia, mn",
  },
  {
    postal_code: "29670",
    zip_name: "pendleton, sc",
  },
  {
    postal_code: "70586",
    zip_name: "ville platte, la",
  },
  {
    postal_code: "33051",
    zip_name: "key colony beach, fl",
  },
  {
    postal_code: "43348",
    zip_name: "russells point, oh",
  },
  {
    postal_code: "92256",
    zip_name: "morongo valley, ca",
  },
  {
    postal_code: "24121",
    zip_name: "moneta, va",
  },
  {
    postal_code: "52353",
    zip_name: "washington, ia",
  },
  {
    postal_code: "26554",
    zip_name: "fairmont, wv",
  },
  {
    postal_code: "29928",
    zip_name: "hilton head island, sc",
  },
  {
    postal_code: "60469",
    zip_name: "posen, il",
  },
  {
    postal_code: "44260",
    zip_name: "mogadore, oh",
  },
  {
    postal_code: "63115",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "40516",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "42347",
    zip_name: "hartford, ky",
  },
  {
    postal_code: "77081",
    zip_name: "houston, tx",
  },
  {
    postal_code: "78372",
    zip_name: "orange grove, tx",
  },
  {
    postal_code: "55397",
    zip_name: "young america, mn",
  },
  {
    postal_code: "95220",
    zip_name: "acampo, ca",
  },
  {
    postal_code: "34981",
    zip_name: "fort pierce, fl",
  },
  {
    postal_code: "55349",
    zip_name: "howard lake, mn",
  },
  {
    postal_code: "87025",
    zip_name: "jemez springs, nm",
  },
  {
    postal_code: "77662",
    zip_name: "vidor, tx",
  },
  {
    postal_code: "13036",
    zip_name: "central square, ny",
  },
  {
    postal_code: "79036",
    zip_name: "fritch, tx",
  },
  {
    postal_code: "95322",
    zip_name: "gustine, ca",
  },
  {
    postal_code: "37645",
    zip_name: "mount carmel, tn",
  },
  {
    postal_code: "11435",
    zip_name: "jamaica, ny",
  },
  {
    postal_code: "28756",
    zip_name: "mill spring, nc",
  },
  {
    postal_code: "21658",
    zip_name: "queenstown, md",
  },
  {
    postal_code: "19301",
    zip_name: "paoli, pa",
  },
  {
    postal_code: "78112",
    zip_name: "elmendorf, tx",
  },
  {
    postal_code: "76272",
    zip_name: "valley view, tx",
  },
  {
    postal_code: "28736",
    zip_name: "glenville, nc",
  },
  {
    postal_code: "13795",
    zip_name: "kirkwood, ny",
  },
  {
    postal_code: "13309",
    zip_name: "boonville, ny",
  },
  {
    postal_code: "43502",
    zip_name: "archbold, oh",
  },
  {
    postal_code: "85045",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "12167",
    zip_name: "stamford, ny",
  },
  {
    postal_code: "11560",
    zip_name: "locust valley, ny",
  },
  {
    postal_code: "11362",
    zip_name: "little neck, ny",
  },
  {
    postal_code: "95605",
    zip_name: "west sacramento, ca",
  },
  {
    postal_code: "12173",
    zip_name: "stuyvesant, ny",
  },
  {
    postal_code: "29429",
    zip_name: "awendaw, sc",
  },
  {
    postal_code: "3071",
    zip_name: "new ipswich, nh",
  },
  {
    postal_code: "61547",
    zip_name: "mapleton, il",
  },
  {
    postal_code: "66071",
    zip_name: "paola, ks",
  },
  {
    postal_code: "21209",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "51351",
    zip_name: "milford, ia",
  },
  {
    postal_code: "2368",
    zip_name: "randolph, ma",
  },
  {
    postal_code: "14025",
    zip_name: "boston, ny",
  },
  {
    postal_code: "98418",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "74347",
    zip_name: "kansas, ok",
  },
  {
    postal_code: "43105",
    zip_name: "baltimore, oh",
  },
  {
    postal_code: "90013",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "61873",
    zip_name: "saint joseph, il",
  },
  {
    postal_code: "74538",
    zip_name: "coalgate, ok",
  },
  {
    postal_code: "68508",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "78225",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "67456",
    zip_name: "lindsborg, ks",
  },
  {
    postal_code: "31415",
    zip_name: "savannah, ga",
  },
  {
    postal_code: "95497",
    zip_name: "the sea ranch, ca",
  },
  {
    postal_code: "3470",
    zip_name: "winchester, nh",
  },
  {
    postal_code: "61842",
    zip_name: "farmer city, il",
  },
  {
    postal_code: "37840",
    zip_name: "oliver springs, tn",
  },
  {
    postal_code: "3223",
    zip_name: "campton, nh",
  },
  {
    postal_code: "78724",
    zip_name: "austin, tx",
  },
  {
    postal_code: "48169",
    zip_name: "pinckney, mi",
  },
  {
    postal_code: "74436",
    zip_name: "haskell, ok",
  },
  {
    postal_code: "97080",
    zip_name: "gresham, or",
  },
  {
    postal_code: "33921",
    zip_name: "boca grande, fl",
  },
  {
    postal_code: "97392",
    zip_name: "turner, or",
  },
  {
    postal_code: "48212",
    zip_name: "hamtramck, mi",
  },
  {
    postal_code: "8071",
    zip_name: "pitman, nj",
  },
  {
    postal_code: "70443",
    zip_name: "independence, la",
  },
  {
    postal_code: "24550",
    zip_name: "evington, va",
  },
  {
    postal_code: "60421",
    zip_name: "elwood, il",
  },
  {
    postal_code: "20181",
    zip_name: "nokesville, va",
  },
  {
    postal_code: "68310",
    zip_name: "beatrice, ne",
  },
  {
    postal_code: "72616",
    zip_name: "berryville, ar",
  },
  {
    postal_code: "17042",
    zip_name: "lebanon, pa",
  },
  {
    postal_code: "50212",
    zip_name: "ogden, ia",
  },
  {
    postal_code: "93930",
    zip_name: "king city, ca",
  },
  {
    postal_code: "20841",
    zip_name: "boyds, md",
  },
  {
    postal_code: "27249",
    zip_name: "gibsonville, nc",
  },
  {
    postal_code: "26181",
    zip_name: "washington, wv",
  },
  {
    postal_code: "98541",
    zip_name: "elma, wa",
  },
  {
    postal_code: "94572",
    zip_name: "rodeo, ca",
  },
  {
    postal_code: "66112",
    zip_name: "kansas city, ks",
  },
  {
    postal_code: "6231",
    zip_name: "amston, ct",
  },
  {
    postal_code: "94102",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "92084",
    zip_name: "vista, ca",
  },
  {
    postal_code: "12032",
    zip_name: "caroga lake, ny",
  },
  {
    postal_code: "59829",
    zip_name: "darby, mt",
  },
  {
    postal_code: "45619",
    zip_name: "chesapeake, oh",
  },
  {
    postal_code: "11702",
    zip_name: "babylon, ny",
  },
  {
    postal_code: "46164",
    zip_name: "nineveh, in",
  },
  {
    postal_code: "60552",
    zip_name: "somonauk, il",
  },
  {
    postal_code: "78643",
    zip_name: "llano, tx",
  },
  {
    postal_code: "11959",
    zip_name: "quogue, ny",
  },
  {
    postal_code: "1020",
    zip_name: "chicopee, ma",
  },
  {
    postal_code: "3825",
    zip_name: "barrington, nh",
  },
  {
    postal_code: "74059",
    zip_name: "perkins, ok",
  },
  {
    postal_code: "94132",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "2338",
    zip_name: "halifax, ma",
  },
  {
    postal_code: "78583",
    zip_name: "rio hondo, tx",
  },
  {
    postal_code: "72117",
    zip_name: "north little rock, ar",
  },
  {
    postal_code: "76664",
    zip_name: "mart, tx",
  },
  {
    postal_code: "12887",
    zip_name: "whitehall, ny",
  },
  {
    postal_code: "86046",
    zip_name: "williams, az",
  },
  {
    postal_code: "40019",
    zip_name: "eminence, ky",
  },
  {
    postal_code: "39576",
    zip_name: "waveland, ms",
  },
  {
    postal_code: "37886",
    zip_name: "walland, tn",
  },
  {
    postal_code: "15330",
    zip_name: "eighty four, pa",
  },
  {
    postal_code: "45805",
    zip_name: "lima, oh",
  },
  {
    postal_code: "27562",
    zip_name: "new hill, nc",
  },
  {
    postal_code: "78612",
    zip_name: "cedar creek, tx",
  },
  {
    postal_code: "8003",
    zip_name: "cherry hill, nj",
  },
  {
    postal_code: "74044",
    zip_name: "mannford, ok",
  },
  {
    postal_code: "29706",
    zip_name: "chester, sc",
  },
  {
    postal_code: "22150",
    zip_name: "springfield, va",
  },
  {
    postal_code: "8502",
    zip_name: "belle mead, nj",
  },
  {
    postal_code: "82941",
    zip_name: "pinedale, wy",
  },
  {
    postal_code: "84332",
    zip_name: "providence, ut",
  },
  {
    postal_code: "21074",
    zip_name: "hampstead, md",
  },
  {
    postal_code: "24574",
    zip_name: "monroe, va",
  },
  {
    postal_code: "99026",
    zip_name: "nine mile falls, wa",
  },
  {
    postal_code: "8008",
    zip_name: "beach haven, nj",
  },
  {
    postal_code: "38256",
    zip_name: "springville, tn",
  },
  {
    postal_code: "19317",
    zip_name: "chadds ford, pa",
  },
  {
    postal_code: "4938",
    zip_name: "farmington, me",
  },
  {
    postal_code: "46534",
    zip_name: "knox, in",
  },
  {
    postal_code: "1001",
    zip_name: "agawam, ma",
  },
  {
    postal_code: "2916",
    zip_name: "rumford, ri",
  },
  {
    postal_code: "20637",
    zip_name: "hughesville, md",
  },
  {
    postal_code: "8691",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "49251",
    zip_name: "leslie, mi",
  },
  {
    postal_code: "46181",
    zip_name: "trafalgar, in",
  },
  {
    postal_code: "43102",
    zip_name: "amanda, oh",
  },
  {
    postal_code: "45714",
    zip_name: "belpre, oh",
  },
  {
    postal_code: "21632",
    zip_name: "federalsburg, md",
  },
  {
    postal_code: "78934",
    zip_name: "columbus, tx",
  },
  {
    postal_code: "98671",
    zip_name: "washougal, wa",
  },
  {
    postal_code: "55614",
    zip_name: "silver bay, mn",
  },
  {
    postal_code: "12428",
    zip_name: "ellenville, ny",
  },
  {
    postal_code: "86403",
    zip_name: "lake havasu city, az",
  },
  {
    postal_code: "47401",
    zip_name: "bloomington, in",
  },
  {
    postal_code: "32112",
    zip_name: "crescent city, fl",
  },
  {
    postal_code: "55375",
    zip_name: "saint bonifacius, mn",
  },
  {
    postal_code: "50216",
    zip_name: "panora, ia",
  },
  {
    postal_code: "93305",
    zip_name: "bakersfield, ca",
  },
  {
    postal_code: "52742",
    zip_name: "de witt, ia",
  },
  {
    postal_code: "19003",
    zip_name: "ardmore, pa",
  },
  {
    postal_code: "95247",
    zip_name: "murphys, ca",
  },
  {
    postal_code: "95222",
    zip_name: "angels camp, ca",
  },
  {
    postal_code: "75645",
    zip_name: "gilmer, tx",
  },
  {
    postal_code: "73541",
    zip_name: "fletcher, ok",
  },
  {
    postal_code: "33136",
    zip_name: "miami, fl",
  },
  {
    postal_code: "43072",
    zip_name: "saint paris, oh",
  },
  {
    postal_code: "31522",
    zip_name: "saint simons island, ga",
  },
  {
    postal_code: "37777",
    zip_name: "louisville, tn",
  },
  {
    postal_code: "55425",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "90670",
    zip_name: "santa fe springs, ca",
  },
  {
    postal_code: "60071",
    zip_name: "richmond, il",
  },
  {
    postal_code: "19541",
    zip_name: "mohrsville, pa",
  },
  {
    postal_code: "99001",
    zip_name: "airway heights, wa",
  },
  {
    postal_code: "13424",
    zip_name: "oriskany, ny",
  },
  {
    postal_code: "56474",
    zip_name: "pine river, mn",
  },
  {
    postal_code: "98841",
    zip_name: "omak, wa",
  },
  {
    postal_code: "31082",
    zip_name: "sandersville, ga",
  },
  {
    postal_code: "42261",
    zip_name: "morgantown, ky",
  },
  {
    postal_code: "15033",
    zip_name: "donora, pa",
  },
  {
    postal_code: "61546",
    zip_name: "manito, il",
  },
  {
    postal_code: "54755",
    zip_name: "mondovi, wi",
  },
  {
    postal_code: "44288",
    zip_name: "windham, oh",
  },
  {
    postal_code: "94041",
    zip_name: "mountain view, ca",
  },
  {
    postal_code: "12076",
    zip_name: "gilboa, ny",
  },
  {
    postal_code: "76520",
    zip_name: "cameron, tx",
  },
  {
    postal_code: "95304",
    zip_name: "tracy, ca",
  },
  {
    postal_code: "25422",
    zip_name: "great cacapon, wv",
  },
  {
    postal_code: "76691",
    zip_name: "west, tx",
  },
  {
    postal_code: "12204",
    zip_name: "albany, ny",
  },
  {
    postal_code: "45204",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "90029",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "4947",
    zip_name: "kingfield, me",
  },
  {
    postal_code: "75480",
    zip_name: "scroggins, tx",
  },
  {
    postal_code: "27524",
    zip_name: "four oaks, nc",
  },
  {
    postal_code: "98953",
    zip_name: "zillah, wa",
  },
  {
    postal_code: "61616",
    zip_name: "peoria heights, il",
  },
  {
    postal_code: "60551",
    zip_name: "sheridan, il",
  },
  {
    postal_code: "27808",
    zip_name: "bath, nc",
  },
  {
    postal_code: "32119",
    zip_name: "daytona beach, fl",
  },
  {
    postal_code: "75240",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "75790",
    zip_name: "van, tx",
  },
  {
    postal_code: "28621",
    zip_name: "elkin, nc",
  },
  {
    postal_code: "57793",
    zip_name: "whitewood, sd",
  },
  {
    postal_code: "18414",
    zip_name: "dalton, pa",
  },
  {
    postal_code: "78416",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "39090",
    zip_name: "kosciusko, ms",
  },
  {
    postal_code: "64804",
    zip_name: "joplin, mo",
  },
  {
    postal_code: "96067",
    zip_name: "mount shasta, ca",
  },
  {
    postal_code: "95119",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "56085",
    zip_name: "sleepy eye, mn",
  },
  {
    postal_code: "43515",
    zip_name: "delta, oh",
  },
  {
    postal_code: "75980",
    zip_name: "zavalla, tx",
  },
  {
    postal_code: "83622",
    zip_name: "garden valley, id",
  },
  {
    postal_code: "75223",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "46157",
    zip_name: "monrovia, in",
  },
  {
    postal_code: "6073",
    zip_name: "south glastonbury, ct",
  },
  {
    postal_code: "93927",
    zip_name: "greenfield, ca",
  },
  {
    postal_code: "53528",
    zip_name: "cross plains, wi",
  },
  {
    postal_code: "21078",
    zip_name: "havre de grace, md",
  },
  {
    postal_code: "76531",
    zip_name: "hamilton, tx",
  },
  {
    postal_code: "49128",
    zip_name: "three oaks, mi",
  },
  {
    postal_code: "16438",
    zip_name: "union city, pa",
  },
  {
    postal_code: "28356",
    zip_name: "linden, nc",
  },
  {
    postal_code: "31028",
    zip_name: "centerville, ga",
  },
  {
    postal_code: "3868",
    zip_name: "rochester, nh",
  },
  {
    postal_code: "48651",
    zip_name: "prudenville, mi",
  },
  {
    postal_code: "21613",
    zip_name: "cambridge, md",
  },
  {
    postal_code: "89439",
    zip_name: "verdi, nv",
  },
  {
    postal_code: "12529",
    zip_name: "hillsdale, ny",
  },
  {
    postal_code: "35121",
    zip_name: "oneonta, al",
  },
  {
    postal_code: "44272",
    zip_name: "rootstown, oh",
  },
  {
    postal_code: "41008",
    zip_name: "carrollton, ky",
  },
  {
    postal_code: "6268",
    zip_name: "storrs mansfield, ct",
  },
  {
    postal_code: "95928",
    zip_name: "chico, ca",
  },
  {
    postal_code: "44906",
    zip_name: "mansfield, oh",
  },
  {
    postal_code: "93311",
    zip_name: "bakersfield, ca",
  },
  {
    postal_code: "11738",
    zip_name: "farmingville, ny",
  },
  {
    postal_code: "11577",
    zip_name: "roslyn heights, ny",
  },
  {
    postal_code: "81526",
    zip_name: "palisade, co",
  },
  {
    postal_code: "38462",
    zip_name: "hohenwald, tn",
  },
  {
    postal_code: "5403",
    zip_name: "south burlington, vt",
  },
  {
    postal_code: "92108",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "25419",
    zip_name: "falling waters, wv",
  },
  {
    postal_code: "21015",
    zip_name: "bel air, md",
  },
  {
    postal_code: "77630",
    zip_name: "orange, tx",
  },
  {
    postal_code: "90036",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "55706",
    zip_name: "babbitt, mn",
  },
  {
    postal_code: "49270",
    zip_name: "petersburg, mi",
  },
  {
    postal_code: "94920",
    zip_name: "belvedere tiburon, ca",
  },
  {
    postal_code: "15062",
    zip_name: "monessen, pa",
  },
  {
    postal_code: "17824",
    zip_name: "elysburg, pa",
  },
  {
    postal_code: "39437",
    zip_name: "ellisville, ms",
  },
  {
    postal_code: "70525",
    zip_name: "church point, la",
  },
  {
    postal_code: "1826",
    zip_name: "dracut, ma",
  },
  {
    postal_code: "70512",
    zip_name: "arnaudville, la",
  },
  {
    postal_code: "31052",
    zip_name: "lizella, ga",
  },
  {
    postal_code: "2831",
    zip_name: "hope, ri",
  },
  {
    postal_code: "75428",
    zip_name: "commerce, tx",
  },
  {
    postal_code: "35160",
    zip_name: "talladega, al",
  },
  {
    postal_code: "95683",
    zip_name: "sloughhouse, ca",
  },
  {
    postal_code: "23146",
    zip_name: "rockville, va",
  },
  {
    postal_code: "49285",
    zip_name: "stockbridge, mi",
  },
  {
    postal_code: "94705",
    zip_name: "berkeley, ca",
  },
  {
    postal_code: "6250",
    zip_name: "mansfield center, ct",
  },
  {
    postal_code: "38012",
    zip_name: "brownsville, tn",
  },
  {
    postal_code: "44074",
    zip_name: "oberlin, oh",
  },
  {
    postal_code: "77713",
    zip_name: "beaumont, tx",
  },
  {
    postal_code: "72744",
    zip_name: "lincoln, ar",
  },
  {
    postal_code: "79108",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "13224",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "89030",
    zip_name: "north las vegas, nv",
  },
  {
    postal_code: "55327",
    zip_name: "dayton, mn",
  },
  {
    postal_code: "19120",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "12496",
    zip_name: "windham, ny",
  },
  {
    postal_code: "3885",
    zip_name: "stratham, nh",
  },
  {
    postal_code: "19008",
    zip_name: "broomall, pa",
  },
  {
    postal_code: "62056",
    zip_name: "litchfield, il",
  },
  {
    postal_code: "27530",
    zip_name: "goldsboro, nc",
  },
  {
    postal_code: "62906",
    zip_name: "anna, il",
  },
  {
    postal_code: "32128",
    zip_name: "port orange, fl",
  },
  {
    postal_code: "30184",
    zip_name: "white, ga",
  },
  {
    postal_code: "20764",
    zip_name: "shady side, md",
  },
  {
    postal_code: "14150",
    zip_name: "tonawanda, ny",
  },
  {
    postal_code: "12027",
    zip_name: "burnt hills, ny",
  },
  {
    postal_code: "77619",
    zip_name: "groves, tx",
  },
  {
    postal_code: "37187",
    zip_name: "white bluff, tn",
  },
  {
    postal_code: "80540",
    zip_name: "lyons, co",
  },
  {
    postal_code: "77995",
    zip_name: "yoakum, tx",
  },
  {
    postal_code: "95685",
    zip_name: "sutter creek, ca",
  },
  {
    postal_code: "79415",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "54971",
    zip_name: "ripon, wi",
  },
  {
    postal_code: "3304",
    zip_name: "bow, nh",
  },
  {
    postal_code: "10310",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "6906",
    zip_name: "stamford, ct",
  },
  {
    postal_code: "79924",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "37036",
    zip_name: "charlotte, tn",
  },
  {
    postal_code: "56329",
    zip_name: "foley, mn",
  },
  {
    postal_code: "20616",
    zip_name: "bryans road, md",
  },
  {
    postal_code: "96727",
    zip_name: "honokaa, hi",
  },
  {
    postal_code: "98148",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "6419",
    zip_name: "killingworth, ct",
  },
  {
    postal_code: "8225",
    zip_name: "northfield, nj",
  },
  {
    postal_code: "18518",
    zip_name: "old forge, pa",
  },
  {
    postal_code: "98346",
    zip_name: "kingston, wa",
  },
  {
    postal_code: "28333",
    zip_name: "dudley, nc",
  },
  {
    postal_code: "75754",
    zip_name: "ben wheeler, tx",
  },
  {
    postal_code: "93644",
    zip_name: "oakhurst, ca",
  },
  {
    postal_code: "8022",
    zip_name: "columbus, nj",
  },
  {
    postal_code: "55975",
    zip_name: "spring valley, mn",
  },
  {
    postal_code: "90303",
    zip_name: "inglewood, ca",
  },
  {
    postal_code: "55358",
    zip_name: "maple lake, mn",
  },
  {
    postal_code: "97132",
    zip_name: "newberg, or",
  },
  {
    postal_code: "46394",
    zip_name: "whiting, in",
  },
  {
    postal_code: "27301",
    zip_name: "mc leansville, nc",
  },
  {
    postal_code: "59635",
    zip_name: "east helena, mt",
  },
  {
    postal_code: "10030",
    zip_name: "new york, ny",
  },
  {
    postal_code: "79070",
    zip_name: "perryton, tx",
  },
  {
    postal_code: "5468",
    zip_name: "milton, vt",
  },
  {
    postal_code: "62088",
    zip_name: "staunton, il",
  },
  {
    postal_code: "95327",
    zip_name: "jamestown, ca",
  },
  {
    postal_code: "63362",
    zip_name: "moscow mills, mo",
  },
  {
    postal_code: "19363",
    zip_name: "oxford, pa",
  },
  {
    postal_code: "80734",
    zip_name: "holyoke, co",
  },
  {
    postal_code: "15342",
    zip_name: "houston, pa",
  },
  {
    postal_code: "55121",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "8554",
    zip_name: "roebling, nj",
  },
  {
    postal_code: "12842",
    zip_name: "indian lake, ny",
  },
  {
    postal_code: "8109",
    zip_name: "merchantville, nj",
  },
  {
    postal_code: "76233",
    zip_name: "collinsville, tx",
  },
  {
    postal_code: "83616",
    zip_name: "eagle, id",
  },
  {
    postal_code: "78722",
    zip_name: "austin, tx",
  },
  {
    postal_code: "43723",
    zip_name: "byesville, oh",
  },
  {
    postal_code: "99141",
    zip_name: "kettle falls, wa",
  },
  {
    postal_code: "63012",
    zip_name: "barnhart, mo",
  },
  {
    postal_code: "33430",
    zip_name: "belle glade, fl",
  },
  {
    postal_code: "53520",
    zip_name: "brodhead, wi",
  },
  {
    postal_code: "28580",
    zip_name: "snow hill, nc",
  },
  {
    postal_code: "53531",
    zip_name: "deerfield, wi",
  },
  {
    postal_code: "53037",
    zip_name: "jackson, wi",
  },
  {
    postal_code: "60135",
    zip_name: "genoa, il",
  },
  {
    postal_code: "32744",
    zip_name: "lake helen, fl",
  },
  {
    postal_code: "40162",
    zip_name: "rineyville, ky",
  },
  {
    postal_code: "62260",
    zip_name: "millstadt, il",
  },
  {
    postal_code: "36107",
    zip_name: "montgomery, al",
  },
  {
    postal_code: "1832",
    zip_name: "haverhill, ma",
  },
  {
    postal_code: "24577",
    zip_name: "nathalie, va",
  },
  {
    postal_code: "72635",
    zip_name: "gassville, ar",
  },
  {
    postal_code: "12919",
    zip_name: "champlain, ny",
  },
  {
    postal_code: "13068",
    zip_name: "freeville, ny",
  },
  {
    postal_code: "40056",
    zip_name: "pewee valley, ky",
  },
  {
    postal_code: "79549",
    zip_name: "snyder, tx",
  },
  {
    postal_code: "12516",
    zip_name: "copake, ny",
  },
  {
    postal_code: "4083",
    zip_name: "springvale, me",
  },
  {
    postal_code: "49931",
    zip_name: "houghton, mi",
  },
  {
    postal_code: "37641",
    zip_name: "chuckey, tn",
  },
  {
    postal_code: "18013",
    zip_name: "bangor, pa",
  },
  {
    postal_code: "53523",
    zip_name: "cambridge, wi",
  },
  {
    postal_code: "3873",
    zip_name: "sandown, nh",
  },
  {
    postal_code: "67017",
    zip_name: "benton, ks",
  },
  {
    postal_code: "75785",
    zip_name: "rusk, tx",
  },
  {
    postal_code: "44047",
    zip_name: "jefferson, oh",
  },
  {
    postal_code: "91024",
    zip_name: "sierra madre, ca",
  },
  {
    postal_code: "37861",
    zip_name: "rutledge, tn",
  },
  {
    postal_code: "21755",
    zip_name: "jefferson, md",
  },
  {
    postal_code: "1520",
    zip_name: "holden, ma",
  },
  {
    postal_code: "86429",
    zip_name: "bullhead city, az",
  },
  {
    postal_code: "59730",
    zip_name: "gallatin gateway, mt",
  },
  {
    postal_code: "26541",
    zip_name: "maidsville, wv",
  },
  {
    postal_code: "40380",
    zip_name: "stanton, ky",
  },
  {
    postal_code: "45154",
    zip_name: "mount orab, oh",
  },
  {
    postal_code: "46106",
    zip_name: "bargersville, in",
  },
  {
    postal_code: "16037",
    zip_name: "harmony, pa",
  },
  {
    postal_code: "20012",
    zip_name: "washington, dc",
  },
  {
    postal_code: "98270",
    zip_name: "marysville, wa",
  },
  {
    postal_code: "28610",
    zip_name: "claremont, nc",
  },
  {
    postal_code: "62690",
    zip_name: "virden, il",
  },
  {
    postal_code: "53934",
    zip_name: "friendship, wi",
  },
  {
    postal_code: "53946",
    zip_name: "markesan, wi",
  },
  {
    postal_code: "66846",
    zip_name: "council grove, ks",
  },
  {
    postal_code: "20115",
    zip_name: "marshall, va",
  },
  {
    postal_code: "48430",
    zip_name: "fenton, mi",
  },
  {
    postal_code: "17777",
    zip_name: "watsontown, pa",
  },
  {
    postal_code: "25071",
    zip_name: "elkview, wv",
  },
  {
    postal_code: "64485",
    zip_name: "savannah, mo",
  },
  {
    postal_code: "1002",
    zip_name: "amherst, ma",
  },
  {
    postal_code: "49756",
    zip_name: "lewiston, mi",
  },
  {
    postal_code: "95709",
    zip_name: "camino, ca",
  },
  {
    postal_code: "63740",
    zip_name: "chaffee, mo",
  },
  {
    postal_code: "55079",
    zip_name: "stacy, mn",
  },
  {
    postal_code: "39440",
    zip_name: "laurel, ms",
  },
  {
    postal_code: "93215",
    zip_name: "delano, ca",
  },
  {
    postal_code: "75124",
    zip_name: "eustace, tx",
  },
  {
    postal_code: "94063",
    zip_name: "redwood city, ca",
  },
  {
    postal_code: "47025",
    zip_name: "lawrenceburg, in",
  },
  {
    postal_code: "95693",
    zip_name: "wilton, ca",
  },
  {
    postal_code: "50261",
    zip_name: "van meter, ia",
  },
  {
    postal_code: "76043",
    zip_name: "glen rose, tx",
  },
  {
    postal_code: "84042",
    zip_name: "lindon, ut",
  },
  {
    postal_code: "4105",
    zip_name: "falmouth, me",
  },
  {
    postal_code: "6239",
    zip_name: "danielson, ct",
  },
  {
    postal_code: "11960",
    zip_name: "remsenburg, ny",
  },
  {
    postal_code: "28771",
    zip_name: "robbinsville, nc",
  },
  {
    postal_code: "11940",
    zip_name: "east moriches, ny",
  },
  {
    postal_code: "75233",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "55325",
    zip_name: "dassel, mn",
  },
  {
    postal_code: "4457",
    zip_name: "lincoln, me",
  },
  {
    postal_code: "3045",
    zip_name: "goffstown, nh",
  },
  {
    postal_code: "80221",
    zip_name: "denver, co",
  },
  {
    postal_code: "45176",
    zip_name: "williamsburg, oh",
  },
  {
    postal_code: "57701",
    zip_name: "rapid city, sd",
  },
  {
    postal_code: "83127",
    zip_name: "thayne, wy",
  },
  {
    postal_code: "17834",
    zip_name: "kulpmont, pa",
  },
  {
    postal_code: "48116",
    zip_name: "brighton, mi",
  },
  {
    postal_code: "75152",
    zip_name: "palmer, tx",
  },
  {
    postal_code: "90232",
    zip_name: "culver city, ca",
  },
  {
    postal_code: "17043",
    zip_name: "lemoyne, pa",
  },
  {
    postal_code: "78756",
    zip_name: "austin, tx",
  },
  {
    postal_code: "38066",
    zip_name: "rossville, tn",
  },
  {
    postal_code: "3060",
    zip_name: "nashua, nh",
  },
  {
    postal_code: "70084",
    zip_name: "reserve, la",
  },
  {
    postal_code: "91755",
    zip_name: "monterey park, ca",
  },
  {
    postal_code: "93268",
    zip_name: "taft, ca",
  },
  {
    postal_code: "98607",
    zip_name: "camas, wa",
  },
  {
    postal_code: "16424",
    zip_name: "linesville, pa",
  },
  {
    postal_code: "44090",
    zip_name: "wellington, oh",
  },
  {
    postal_code: "11713",
    zip_name: "bellport, ny",
  },
  {
    postal_code: "28773",
    zip_name: "saluda, nc",
  },
  {
    postal_code: "76557",
    zip_name: "moody, tx",
  },
  {
    postal_code: "2131",
    zip_name: "roslindale, ma",
  },
  {
    postal_code: "45631",
    zip_name: "gallipolis, oh",
  },
  {
    postal_code: "49781",
    zip_name: "saint ignace, mi",
  },
  {
    postal_code: "6422",
    zip_name: "durham, ct",
  },
  {
    postal_code: "88317",
    zip_name: "cloudcroft, nm",
  },
  {
    postal_code: "54021",
    zip_name: "prescott, wi",
  },
  {
    postal_code: "93725",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "2766",
    zip_name: "norton, ma",
  },
  {
    postal_code: "95658",
    zip_name: "newcastle, ca",
  },
  {
    postal_code: "3276",
    zip_name: "tilton, nh",
  },
  {
    postal_code: "19151",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "32976",
    zip_name: "sebastian, fl",
  },
  {
    postal_code: "35228",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "16407",
    zip_name: "corry, pa",
  },
  {
    postal_code: "70446",
    zip_name: "loranger, la",
  },
  {
    postal_code: "49344",
    zip_name: "shelbyville, mi",
  },
  {
    postal_code: "49013",
    zip_name: "bangor, mi",
  },
  {
    postal_code: "96103",
    zip_name: "blairsden graeagle, ca",
  },
  {
    postal_code: "18433",
    zip_name: "jermyn, pa",
  },
  {
    postal_code: "21202",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "78101",
    zip_name: "adkins, tx",
  },
  {
    postal_code: "90755",
    zip_name: "signal hill, ca",
  },
  {
    postal_code: "45220",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "94707",
    zip_name: "berkeley, ca",
  },
  {
    postal_code: "10018",
    zip_name: "new york, ny",
  },
  {
    postal_code: "77029",
    zip_name: "houston, tx",
  },
  {
    postal_code: "23072",
    zip_name: "hayes, va",
  },
  {
    postal_code: "17740",
    zip_name: "jersey shore, pa",
  },
  {
    postal_code: "12564",
    zip_name: "pawling, ny",
  },
  {
    postal_code: "19154",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "32305",
    zip_name: "tallahassee, fl",
  },
  {
    postal_code: "39702",
    zip_name: "columbus, ms",
  },
  {
    postal_code: "64429",
    zip_name: "cameron, mo",
  },
  {
    postal_code: "37701",
    zip_name: "alcoa, tn",
  },
  {
    postal_code: "28789",
    zip_name: "whittier, nc",
  },
  {
    postal_code: "48111",
    zip_name: "belleville, mi",
  },
  {
    postal_code: "93514",
    zip_name: "bishop, ca",
  },
  {
    postal_code: "55604",
    zip_name: "grand marais, mn",
  },
  {
    postal_code: "93286",
    zip_name: "woodlake, ca",
  },
  {
    postal_code: "19510",
    zip_name: "blandon, pa",
  },
  {
    postal_code: "49930",
    zip_name: "hancock, mi",
  },
  {
    postal_code: "87701",
    zip_name: "las vegas, nm",
  },
  {
    postal_code: "56087",
    zip_name: "springfield, mn",
  },
  {
    postal_code: "95988",
    zip_name: "willows, ca",
  },
  {
    postal_code: "79915",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "16929",
    zip_name: "lawrenceville, pa",
  },
  {
    postal_code: "80432",
    zip_name: "como, co",
  },
  {
    postal_code: "45218",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "1545",
    zip_name: "shrewsbury, ma",
  },
  {
    postal_code: "56055",
    zip_name: "lake crystal, mn",
  },
  {
    postal_code: "52726",
    zip_name: "blue grass, ia",
  },
  {
    postal_code: "65101",
    zip_name: "jefferson city, mo",
  },
  {
    postal_code: "94503",
    zip_name: "american canyon, ca",
  },
  {
    postal_code: "27574",
    zip_name: "roxboro, nc",
  },
  {
    postal_code: "97394",
    zip_name: "waldport, or",
  },
  {
    postal_code: "27344",
    zip_name: "siler city, nc",
  },
  {
    postal_code: "11369",
    zip_name: "east elmhurst, ny",
  },
  {
    postal_code: "91501",
    zip_name: "burbank, ca",
  },
  {
    postal_code: "95073",
    zip_name: "soquel, ca",
  },
  {
    postal_code: "55071",
    zip_name: "saint paul park, mn",
  },
  {
    postal_code: "95386",
    zip_name: "waterford, ca",
  },
  {
    postal_code: "76448",
    zip_name: "eastland, tx",
  },
  {
    postal_code: "21048",
    zip_name: "finksburg, md",
  },
  {
    postal_code: "13753",
    zip_name: "delhi, ny",
  },
  {
    postal_code: "47060",
    zip_name: "west harrison, in",
  },
  {
    postal_code: "11361",
    zip_name: "bayside, ny",
  },
  {
    postal_code: "21793",
    zip_name: "walkersville, md",
  },
  {
    postal_code: "43845",
    zip_name: "west lafayette, oh",
  },
  {
    postal_code: "65078",
    zip_name: "stover, mo",
  },
  {
    postal_code: "53527",
    zip_name: "cottage grove, wi",
  },
  {
    postal_code: "94024",
    zip_name: "los altos, ca",
  },
  {
    postal_code: "46001",
    zip_name: "alexandria, in",
  },
  {
    postal_code: "16502",
    zip_name: "erie, pa",
  },
  {
    postal_code: "27944",
    zip_name: "hertford, nc",
  },
  {
    postal_code: "79510",
    zip_name: "clyde, tx",
  },
  {
    postal_code: "76259",
    zip_name: "ponder, tx",
  },
  {
    postal_code: "75126",
    zip_name: "forney, tx",
  },
  {
    postal_code: "32092",
    zip_name: "saint augustine, fl",
  },
  {
    postal_code: "85383",
    zip_name: "peoria, az",
  },
  {
    postal_code: "28451",
    zip_name: "leland, nc",
  },
  {
    postal_code: "34746",
    zip_name: "kissimmee, fl",
  },
  {
    postal_code: "29681",
    zip_name: "simpsonville, sc",
  },
  {
    postal_code: "21054",
    zip_name: "gambrills, md",
  },
  {
    postal_code: "28777",
    zip_name: "spruce pine, nc",
  },
  {
    postal_code: "44081",
    zip_name: "perry, oh",
  },
  {
    postal_code: "93546",
    zip_name: "mammoth lakes, ca",
  },
  {
    postal_code: "85541",
    zip_name: "payson, az",
  },
  {
    postal_code: "44706",
    zip_name: "canton, oh",
  },
  {
    postal_code: "95684",
    zip_name: "somerset, ca",
  },
  {
    postal_code: "18466",
    zip_name: "tobyhanna, pa",
  },
  {
    postal_code: "76255",
    zip_name: "nocona, tx",
  },
  {
    postal_code: "23140",
    zip_name: "providence forge, va",
  },
  {
    postal_code: "75201",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "85019",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "72173",
    zip_name: "vilonia, ar",
  },
  {
    postal_code: "68110",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "64057",
    zip_name: "independence, mo",
  },
  {
    postal_code: "27244",
    zip_name: "elon, nc",
  },
  {
    postal_code: "53963",
    zip_name: "waupun, wi",
  },
  {
    postal_code: "35127",
    zip_name: "pleasant grove, al",
  },
  {
    postal_code: "44216",
    zip_name: "clinton, oh",
  },
  {
    postal_code: "2917",
    zip_name: "smithfield, ri",
  },
  {
    postal_code: "6779",
    zip_name: "oakville, ct",
  },
  {
    postal_code: "34972",
    zip_name: "okeechobee, fl",
  },
  {
    postal_code: "5155",
    zip_name: "south londonderry, vt",
  },
  {
    postal_code: "11751",
    zip_name: "islip, ny",
  },
  {
    postal_code: "12498",
    zip_name: "woodstock, ny",
  },
  {
    postal_code: "12404",
    zip_name: "accord, ny",
  },
  {
    postal_code: "94805",
    zip_name: "richmond, ca",
  },
  {
    postal_code: "90606",
    zip_name: "whittier, ca",
  },
  {
    postal_code: "77039",
    zip_name: "houston, tx",
  },
  {
    postal_code: "30288",
    zip_name: "conley, ga",
  },
  {
    postal_code: "18438",
    zip_name: "lakeville, pa",
  },
  {
    postal_code: "29148",
    zip_name: "summerton, sc",
  },
  {
    postal_code: "30628",
    zip_name: "colbert, ga",
  },
  {
    postal_code: "83856",
    zip_name: "priest river, id",
  },
  {
    postal_code: "44632",
    zip_name: "hartville, oh",
  },
  {
    postal_code: "45601",
    zip_name: "chillicothe, oh",
  },
  {
    postal_code: "56378",
    zip_name: "sauk centre, mn",
  },
  {
    postal_code: "77002",
    zip_name: "houston, tx",
  },
  {
    postal_code: "3755",
    zip_name: "hanover, nh",
  },
  {
    postal_code: "29571",
    zip_name: "marion, sc",
  },
  {
    postal_code: "85305",
    zip_name: "glendale, az",
  },
  {
    postal_code: "55055",
    zip_name: "newport, mn",
  },
  {
    postal_code: "6088",
    zip_name: "east windsor, ct",
  },
  {
    postal_code: "86333",
    zip_name: "mayer, az",
  },
  {
    postal_code: "38068",
    zip_name: "somerville, tn",
  },
  {
    postal_code: "85361",
    zip_name: "wittmann, az",
  },
  {
    postal_code: "30217",
    zip_name: "franklin, ga",
  },
  {
    postal_code: "27260",
    zip_name: "high point, nc",
  },
  {
    postal_code: "12833",
    zip_name: "greenfield center, ny",
  },
  {
    postal_code: "37312",
    zip_name: "cleveland, tn",
  },
  {
    postal_code: "75662",
    zip_name: "kilgore, tx",
  },
  {
    postal_code: "95623",
    zip_name: "el dorado, ca",
  },
  {
    postal_code: "56473",
    zip_name: "pillager, mn",
  },
  {
    postal_code: "78606",
    zip_name: "blanco, tx",
  },
  {
    postal_code: "16686",
    zip_name: "tyrone, pa",
  },
  {
    postal_code: "2635",
    zip_name: "cotuit, ma",
  },
  {
    postal_code: "64145",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "21638",
    zip_name: "grasonville, md",
  },
  {
    postal_code: "18015",
    zip_name: "bethlehem, pa",
  },
  {
    postal_code: "44233",
    zip_name: "hinckley, oh",
  },
  {
    postal_code: "17340",
    zip_name: "littlestown, pa",
  },
  {
    postal_code: "97701",
    zip_name: "bend, or",
  },
  {
    postal_code: "94118",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "13215",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "13316",
    zip_name: "camden, ny",
  },
  {
    postal_code: "79556",
    zip_name: "sweetwater, tx",
  },
  {
    postal_code: "61822",
    zip_name: "champaign, il",
  },
  {
    postal_code: "13411",
    zip_name: "new berlin, ny",
  },
  {
    postal_code: "84741",
    zip_name: "kanab, ut",
  },
  {
    postal_code: "85004",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "27401",
    zip_name: "greensboro, nc",
  },
  {
    postal_code: "45381",
    zip_name: "west alexandria, oh",
  },
  {
    postal_code: "71111",
    zip_name: "bossier city, la",
  },
  {
    postal_code: "80514",
    zip_name: "dacono, co",
  },
  {
    postal_code: "16059",
    zip_name: "valencia, pa",
  },
  {
    postal_code: "48133",
    zip_name: "erie, mi",
  },
  {
    postal_code: "77011",
    zip_name: "houston, tx",
  },
  {
    postal_code: "26101",
    zip_name: "parkersburg, wv",
  },
  {
    postal_code: "12134",
    zip_name: "northville, ny",
  },
  {
    postal_code: "43338",
    zip_name: "mount gilead, oh",
  },
  {
    postal_code: "35214",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "13754",
    zip_name: "deposit, ny",
  },
  {
    postal_code: "32055",
    zip_name: "lake city, fl",
  },
  {
    postal_code: "27510",
    zip_name: "carrboro, nc",
  },
  {
    postal_code: "44413",
    zip_name: "east palestine, oh",
  },
  {
    postal_code: "67467",
    zip_name: "minneapolis, ks",
  },
  {
    postal_code: "55810",
    zip_name: "duluth, mn",
  },
  {
    postal_code: "6234",
    zip_name: "brooklyn, ct",
  },
  {
    postal_code: "48723",
    zip_name: "caro, mi",
  },
  {
    postal_code: "75789",
    zip_name: "troup, tx",
  },
  {
    postal_code: "97045",
    zip_name: "oregon city, or",
  },
  {
    postal_code: "64465",
    zip_name: "lathrop, mo",
  },
  {
    postal_code: "44254",
    zip_name: "lodi, oh",
  },
  {
    postal_code: "54208",
    zip_name: "denmark, wi",
  },
  {
    postal_code: "80466",
    zip_name: "nederland, co",
  },
  {
    postal_code: "11755",
    zip_name: "lake grove, ny",
  },
  {
    postal_code: "5340",
    zip_name: "bondville, vt",
  },
  {
    postal_code: "21629",
    zip_name: "denton, md",
  },
  {
    postal_code: "39325",
    zip_name: "collinsville, ms",
  },
  {
    postal_code: "90814",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "37871",
    zip_name: "strawberry plains, tn",
  },
  {
    postal_code: "22172",
    zip_name: "triangle, va",
  },
  {
    postal_code: "15135",
    zip_name: "mckeesport, pa",
  },
  {
    postal_code: "19526",
    zip_name: "hamburg, pa",
  },
  {
    postal_code: "21673",
    zip_name: "trappe, md",
  },
  {
    postal_code: "68347",
    zip_name: "eagle, ne",
  },
  {
    postal_code: "80645",
    zip_name: "la salle, co",
  },
  {
    postal_code: "76201",
    zip_name: "denton, tx",
  },
  {
    postal_code: "2066",
    zip_name: "scituate, ma",
  },
  {
    postal_code: "16421",
    zip_name: "harborcreek, pa",
  },
  {
    postal_code: "70075",
    zip_name: "meraux, la",
  },
  {
    postal_code: "21716",
    zip_name: "brunswick, md",
  },
  {
    postal_code: "46124",
    zip_name: "edinburgh, in",
  },
  {
    postal_code: "28626",
    zip_name: "fleetwood, nc",
  },
  {
    postal_code: "3878",
    zip_name: "somersworth, nh",
  },
  {
    postal_code: "48362",
    zip_name: "lake orion, mi",
  },
  {
    postal_code: "92333",
    zip_name: "fawnskin, ca",
  },
  {
    postal_code: "75956",
    zip_name: "kirbyville, tx",
  },
  {
    postal_code: "32730",
    zip_name: "casselberry, fl",
  },
  {
    postal_code: "2184",
    zip_name: "braintree, ma",
  },
  {
    postal_code: "18240",
    zip_name: "nesquehoning, pa",
  },
  {
    postal_code: "53717",
    zip_name: "madison, wi",
  },
  {
    postal_code: "16102",
    zip_name: "new castle, pa",
  },
  {
    postal_code: "98563",
    zip_name: "montesano, wa",
  },
  {
    postal_code: "56011",
    zip_name: "belle plaine, mn",
  },
  {
    postal_code: "47990",
    zip_name: "waynetown, in",
  },
  {
    postal_code: "2655",
    zip_name: "osterville, ma",
  },
  {
    postal_code: "90710",
    zip_name: "harbor city, ca",
  },
  {
    postal_code: "2151",
    zip_name: "revere, ma",
  },
  {
    postal_code: "30113",
    zip_name: "buchanan, ga",
  },
  {
    postal_code: "19977",
    zip_name: "smyrna, de",
  },
  {
    postal_code: "64506",
    zip_name: "saint joseph, mo",
  },
  {
    postal_code: "68434",
    zip_name: "seward, ne",
  },
  {
    postal_code: "11423",
    zip_name: "hollis, ny",
  },
  {
    postal_code: "44889",
    zip_name: "wakeman, oh",
  },
  {
    postal_code: "21046",
    zip_name: "columbia, md",
  },
  {
    postal_code: "23102",
    zip_name: "maidens, va",
  },
  {
    postal_code: "66043",
    zip_name: "lansing, ks",
  },
  {
    postal_code: "44114",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "53818",
    zip_name: "platteville, wi",
  },
  {
    postal_code: "2072",
    zip_name: "stoughton, ma",
  },
  {
    postal_code: "36277",
    zip_name: "weaver, al",
  },
  {
    postal_code: "33538",
    zip_name: "lake panasoffkee, fl",
  },
  {
    postal_code: "98245",
    zip_name: "eastsound, wa",
  },
  {
    postal_code: "3055",
    zip_name: "milford, nh",
  },
  {
    postal_code: "48189",
    zip_name: "whitmore lake, mi",
  },
  {
    postal_code: "76071",
    zip_name: "newark, tx",
  },
  {
    postal_code: "32033",
    zip_name: "elkton, fl",
  },
  {
    postal_code: "54613",
    zip_name: "arkdale, wi",
  },
  {
    postal_code: "94019",
    zip_name: "half moon bay, ca",
  },
  {
    postal_code: "90602",
    zip_name: "whittier, ca",
  },
  {
    postal_code: "53598",
    zip_name: "windsor, wi",
  },
  {
    postal_code: "37705",
    zip_name: "andersonville, tn",
  },
  {
    postal_code: "16866",
    zip_name: "philipsburg, pa",
  },
  {
    postal_code: "43947",
    zip_name: "shadyside, oh",
  },
  {
    postal_code: "65625",
    zip_name: "cassville, mo",
  },
  {
    postal_code: "16830",
    zip_name: "clearfield, pa",
  },
  {
    postal_code: "44703",
    zip_name: "canton, oh",
  },
  {
    postal_code: "92868",
    zip_name: "orange, ca",
  },
  {
    postal_code: "2128",
    zip_name: "east boston, ma",
  },
  {
    postal_code: "21244",
    zip_name: "windsor mill, md",
  },
  {
    postal_code: "20770",
    zip_name: "greenbelt, md",
  },
  {
    postal_code: "88220",
    zip_name: "carlsbad, nm",
  },
  {
    postal_code: "78402",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "34470",
    zip_name: "ocala, fl",
  },
  {
    postal_code: "13210",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "54893",
    zip_name: "webster, wi",
  },
  {
    postal_code: "8854",
    zip_name: "piscataway, nj",
  },
  {
    postal_code: "78752",
    zip_name: "austin, tx",
  },
  {
    postal_code: "66221",
    zip_name: "overland park, ks",
  },
  {
    postal_code: "28337",
    zip_name: "elizabethtown, nc",
  },
  {
    postal_code: "12523",
    zip_name: "elizaville, ny",
  },
  {
    postal_code: "78404",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "21047",
    zip_name: "fallston, md",
  },
  {
    postal_code: "19033",
    zip_name: "folsom, pa",
  },
  {
    postal_code: "99022",
    zip_name: "medical lake, wa",
  },
  {
    postal_code: "6114",
    zip_name: "hartford, ct",
  },
  {
    postal_code: "59873",
    zip_name: "thompson falls, mt",
  },
  {
    postal_code: "64058",
    zip_name: "independence, mo",
  },
  {
    postal_code: "28544",
    zip_name: "midway park, nc",
  },
  {
    postal_code: "54554",
    zip_name: "phelps, wi",
  },
  {
    postal_code: "3857",
    zip_name: "newmarket, nh",
  },
  {
    postal_code: "30523",
    zip_name: "clarkesville, ga",
  },
  {
    postal_code: "28612",
    zip_name: "connelly springs, nc",
  },
  {
    postal_code: "93465",
    zip_name: "templeton, ca",
  },
  {
    postal_code: "12166",
    zip_name: "sprakers, ny",
  },
  {
    postal_code: "32667",
    zip_name: "micanopy, fl",
  },
  {
    postal_code: "84028",
    zip_name: "garden city, ut",
  },
  {
    postal_code: "22308",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "92026",
    zip_name: "escondido, ca",
  },
  {
    postal_code: "28692",
    zip_name: "vilas, nc",
  },
  {
    postal_code: "22181",
    zip_name: "vienna, va",
  },
  {
    postal_code: "30563",
    zip_name: "mount airy, ga",
  },
  {
    postal_code: "94002",
    zip_name: "belmont, ca",
  },
  {
    postal_code: "42171",
    zip_name: "smiths grove, ky",
  },
  {
    postal_code: "20005",
    zip_name: "washington, dc",
  },
  {
    postal_code: "8550",
    zip_name: "princeton junction, nj",
  },
  {
    postal_code: "81432",
    zip_name: "ridgway, co",
  },
  {
    postal_code: "32301",
    zip_name: "tallahassee, fl",
  },
  {
    postal_code: "28646",
    zip_name: "linville, nc",
  },
  {
    postal_code: "15024",
    zip_name: "cheswick, pa",
  },
  {
    postal_code: "48040",
    zip_name: "marysville, mi",
  },
  {
    postal_code: "62254",
    zip_name: "lebanon, il",
  },
  {
    postal_code: "64644",
    zip_name: "hamilton, mo",
  },
  {
    postal_code: "15042",
    zip_name: "freedom, pa",
  },
  {
    postal_code: "63019",
    zip_name: "crystal city, mo",
  },
  {
    postal_code: "72642",
    zip_name: "lakeview, ar",
  },
  {
    postal_code: "44255",
    zip_name: "mantua, oh",
  },
  {
    postal_code: "23181",
    zip_name: "west point, va",
  },
  {
    postal_code: "6469",
    zip_name: "moodus, ct",
  },
  {
    postal_code: "84720",
    zip_name: "cedar city, ut",
  },
  {
    postal_code: "28339",
    zip_name: "erwin, nc",
  },
  {
    postal_code: "23063",
    zip_name: "goochland, va",
  },
  {
    postal_code: "37714",
    zip_name: "caryville, tn",
  },
  {
    postal_code: "6339",
    zip_name: "ledyard, ct",
  },
  {
    postal_code: "80723",
    zip_name: "brush, co",
  },
  {
    postal_code: "40359",
    zip_name: "owenton, ky",
  },
  {
    postal_code: "3894",
    zip_name: "wolfeboro, nh",
  },
  {
    postal_code: "96091",
    zip_name: "trinity center, ca",
  },
  {
    postal_code: "90260",
    zip_name: "lawndale, ca",
  },
  {
    postal_code: "78733",
    zip_name: "austin, tx",
  },
  {
    postal_code: "54728",
    zip_name: "chetek, wi",
  },
  {
    postal_code: "20180",
    zip_name: "lovettsville, va",
  },
  {
    postal_code: "18435",
    zip_name: "lackawaxen, pa",
  },
  {
    postal_code: "4062",
    zip_name: "windham, me",
  },
  {
    postal_code: "82201",
    zip_name: "wheatland, wy",
  },
  {
    postal_code: "60514",
    zip_name: "clarendon hills, il",
  },
  {
    postal_code: "2822",
    zip_name: "exeter, ri",
  },
  {
    postal_code: "38652",
    zip_name: "new albany, ms",
  },
  {
    postal_code: "74036",
    zip_name: "inola, ok",
  },
  {
    postal_code: "20708",
    zip_name: "laurel, md",
  },
  {
    postal_code: "4072",
    zip_name: "saco, me",
  },
  {
    postal_code: "64078",
    zip_name: "peculiar, mo",
  },
  {
    postal_code: "65084",
    zip_name: "versailles, mo",
  },
  {
    postal_code: "92021",
    zip_name: "el cajon, ca",
  },
  {
    postal_code: "37752",
    zip_name: "harrogate, tn",
  },
  {
    postal_code: "1226",
    zip_name: "dalton, ma",
  },
  {
    postal_code: "10004",
    zip_name: "new york, ny",
  },
  {
    postal_code: "92843",
    zip_name: "garden grove, ca",
  },
  {
    postal_code: "21787",
    zip_name: "taneytown, md",
  },
  {
    postal_code: "48650",
    zip_name: "pinconning, mi",
  },
  {
    postal_code: "78624",
    zip_name: "fredericksburg, tx",
  },
  {
    postal_code: "52761",
    zip_name: "muscatine, ia",
  },
  {
    postal_code: "6078",
    zip_name: "suffield, ct",
  },
  {
    postal_code: "68462",
    zip_name: "waverly, ne",
  },
  {
    postal_code: "31087",
    zip_name: "sparta, ga",
  },
  {
    postal_code: "94941",
    zip_name: "mill valley, ca",
  },
  {
    postal_code: "66067",
    zip_name: "ottawa, ks",
  },
  {
    postal_code: "78725",
    zip_name: "austin, tx",
  },
  {
    postal_code: "12474",
    zip_name: "roxbury, ny",
  },
  {
    postal_code: "93101",
    zip_name: "santa barbara, ca",
  },
  {
    postal_code: "99328",
    zip_name: "dayton, wa",
  },
  {
    postal_code: "1237",
    zip_name: "lanesborough, ma",
  },
  {
    postal_code: "2148",
    zip_name: "malden, ma",
  },
  {
    postal_code: "62522",
    zip_name: "decatur, il",
  },
  {
    postal_code: "33873",
    zip_name: "wauchula, fl",
  },
  {
    postal_code: "64130",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "75410",
    zip_name: "alba, tx",
  },
  {
    postal_code: "90038",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "96143",
    zip_name: "kings beach, ca",
  },
  {
    postal_code: "2667",
    zip_name: "wellfleet, ma",
  },
  {
    postal_code: "85007",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "12083",
    zip_name: "greenville, ny",
  },
  {
    postal_code: "45318",
    zip_name: "covington, oh",
  },
  {
    postal_code: "90020",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "32131",
    zip_name: "east palatka, fl",
  },
  {
    postal_code: "1960",
    zip_name: "peabody, ma",
  },
  {
    postal_code: "83128",
    zip_name: "alpine, wy",
  },
  {
    postal_code: "47909",
    zip_name: "lafayette, in",
  },
  {
    postal_code: "95618",
    zip_name: "davis, ca",
  },
  {
    postal_code: "77017",
    zip_name: "houston, tx",
  },
  {
    postal_code: "55341",
    zip_name: "hanover, mn",
  },
  {
    postal_code: "89706",
    zip_name: "carson city, nv",
  },
  {
    postal_code: "19057",
    zip_name: "levittown, pa",
  },
  {
    postal_code: "3076",
    zip_name: "pelham, nh",
  },
  {
    postal_code: "97403",
    zip_name: "eugene, or",
  },
  {
    postal_code: "29470",
    zip_name: "ravenel, sc",
  },
  {
    postal_code: "52531",
    zip_name: "albia, ia",
  },
  {
    postal_code: "19804",
    zip_name: "wilmington, de",
  },
  {
    postal_code: "28722",
    zip_name: "columbus, nc",
  },
  {
    postal_code: "32266",
    zip_name: "neptune beach, fl",
  },
  {
    postal_code: "94022",
    zip_name: "los altos, ca",
  },
  {
    postal_code: "76115",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "45875",
    zip_name: "ottawa, oh",
  },
  {
    postal_code: "13073",
    zip_name: "groton, ny",
  },
  {
    postal_code: "29341",
    zip_name: "gaffney, sc",
  },
  {
    postal_code: "90305",
    zip_name: "inglewood, ca",
  },
  {
    postal_code: "6026",
    zip_name: "east granby, ct",
  },
  {
    postal_code: "12149",
    zip_name: "richmondville, ny",
  },
  {
    postal_code: "34946",
    zip_name: "fort pierce, fl",
  },
  {
    postal_code: "55760",
    zip_name: "mcgregor, mn",
  },
  {
    postal_code: "31308",
    zip_name: "ellabell, ga",
  },
  {
    postal_code: "21663",
    zip_name: "saint michaels, md",
  },
  {
    postal_code: "23015",
    zip_name: "beaverdam, va",
  },
  {
    postal_code: "11694",
    zip_name: "rockaway park, ny",
  },
  {
    postal_code: "28107",
    zip_name: "midland, nc",
  },
  {
    postal_code: "66701",
    zip_name: "fort scott, ks",
  },
  {
    postal_code: "38574",
    zip_name: "monterey, tn",
  },
  {
    postal_code: "80016",
    zip_name: "aurora, co",
  },
  {
    postal_code: "60964",
    zip_name: "saint anne, il",
  },
  {
    postal_code: "45335",
    zip_name: "jamestown, oh",
  },
  {
    postal_code: "39111",
    zip_name: "magee, ms",
  },
  {
    postal_code: "91723",
    zip_name: "covina, ca",
  },
  {
    postal_code: "5148",
    zip_name: "londonderry, vt",
  },
  {
    postal_code: "21085",
    zip_name: "joppa, md",
  },
  {
    postal_code: "6051",
    zip_name: "new britain, ct",
  },
  {
    postal_code: "1844",
    zip_name: "methuen, ma",
  },
  {
    postal_code: "79316",
    zip_name: "brownfield, tx",
  },
  {
    postal_code: "38344",
    zip_name: "huntingdon, tn",
  },
  {
    postal_code: "46072",
    zip_name: "tipton, in",
  },
  {
    postal_code: "47424",
    zip_name: "bloomfield, in",
  },
  {
    postal_code: "38063",
    zip_name: "ripley, tn",
  },
  {
    postal_code: "33306",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "34251",
    zip_name: "myakka city, fl",
  },
  {
    postal_code: "75432",
    zip_name: "cooper, tx",
  },
  {
    postal_code: "70125",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "62902",
    zip_name: "carbondale, il",
  },
  {
    postal_code: "8505",
    zip_name: "bordentown, nj",
  },
  {
    postal_code: "33868",
    zip_name: "polk city, fl",
  },
  {
    postal_code: "93460",
    zip_name: "santa ynez, ca",
  },
  {
    postal_code: "34431",
    zip_name: "dunnellon, fl",
  },
  {
    postal_code: "43543",
    zip_name: "montpelier, oh",
  },
  {
    postal_code: "78204",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "79103",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "18657",
    zip_name: "tunkhannock, pa",
  },
  {
    postal_code: "70380",
    zip_name: "morgan city, la",
  },
  {
    postal_code: "31546",
    zip_name: "jesup, ga",
  },
  {
    postal_code: "81225",
    zip_name: "crested butte, co",
  },
  {
    postal_code: "27536",
    zip_name: "henderson, nc",
  },
  {
    postal_code: "12522",
    zip_name: "dover plains, ny",
  },
  {
    postal_code: "95054",
    zip_name: "santa clara, ca",
  },
  {
    postal_code: "22303",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "95030",
    zip_name: "los gatos, ca",
  },
  {
    postal_code: "47872",
    zip_name: "rockville, in",
  },
  {
    postal_code: "30750",
    zip_name: "lookout mountain, ga",
  },
  {
    postal_code: "15963",
    zip_name: "windber, pa",
  },
  {
    postal_code: "11731",
    zip_name: "east northport, ny",
  },
  {
    postal_code: "6281",
    zip_name: "woodstock, ct",
  },
  {
    postal_code: "11950",
    zip_name: "mastic, ny",
  },
  {
    postal_code: "21040",
    zip_name: "edgewood, md",
  },
  {
    postal_code: "45219",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "17844",
    zip_name: "mifflinburg, pa",
  },
  {
    postal_code: "30313",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "31525",
    zip_name: "brunswick, ga",
  },
  {
    postal_code: "3064",
    zip_name: "nashua, nh",
  },
  {
    postal_code: "27522",
    zip_name: "creedmoor, nc",
  },
  {
    postal_code: "8831",
    zip_name: "monroe township, nj",
  },
  {
    postal_code: "11720",
    zip_name: "centereach, ny",
  },
  {
    postal_code: "61755",
    zip_name: "mackinaw, il",
  },
  {
    postal_code: "63103",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "79363",
    zip_name: "shallowater, tx",
  },
  {
    postal_code: "59847",
    zip_name: "lolo, mt",
  },
  {
    postal_code: "3458",
    zip_name: "peterborough, nh",
  },
  {
    postal_code: "44440",
    zip_name: "mineral ridge, oh",
  },
  {
    postal_code: "18969",
    zip_name: "telford, pa",
  },
  {
    postal_code: "8049",
    zip_name: "magnolia, nj",
  },
  {
    postal_code: "56362",
    zip_name: "paynesville, mn",
  },
  {
    postal_code: "75783",
    zip_name: "quitman, tx",
  },
  {
    postal_code: "6524",
    zip_name: "bethany, ct",
  },
  {
    postal_code: "33956",
    zip_name: "saint james city, fl",
  },
  {
    postal_code: "8322",
    zip_name: "franklinville, nj",
  },
  {
    postal_code: "12836",
    zip_name: "hague, ny",
  },
  {
    postal_code: "96137",
    zip_name: "westwood, ca",
  },
  {
    postal_code: "33567",
    zip_name: "plant city, fl",
  },
  {
    postal_code: "97341",
    zip_name: "depoe bay, or",
  },
  {
    postal_code: "17701",
    zip_name: "williamsport, pa",
  },
  {
    postal_code: "45426",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "60456",
    zip_name: "hometown, il",
  },
  {
    postal_code: "13439",
    zip_name: "richfield springs, ny",
  },
  {
    postal_code: "78016",
    zip_name: "devine, tx",
  },
  {
    postal_code: "11436",
    zip_name: "jamaica, ny",
  },
  {
    postal_code: "81004",
    zip_name: "pueblo, co",
  },
  {
    postal_code: "23936",
    zip_name: "dillwyn, va",
  },
  {
    postal_code: "95388",
    zip_name: "winton, ca",
  },
  {
    postal_code: "43146",
    zip_name: "orient, oh",
  },
  {
    postal_code: "21742",
    zip_name: "hagerstown, md",
  },
  {
    postal_code: "77706",
    zip_name: "beaumont, tx",
  },
  {
    postal_code: "80424",
    zip_name: "breckenridge, co",
  },
  {
    postal_code: "16353",
    zip_name: "tionesta, pa",
  },
  {
    postal_code: "16033",
    zip_name: "evans city, pa",
  },
  {
    postal_code: "55011",
    zip_name: "cedar, mn",
  },
  {
    postal_code: "32732",
    zip_name: "geneva, fl",
  },
  {
    postal_code: "12545",
    zip_name: "millbrook, ny",
  },
  {
    postal_code: "72103",
    zip_name: "mabelvale, ar",
  },
  {
    postal_code: "1880",
    zip_name: "wakefield, ma",
  },
  {
    postal_code: "13323",
    zip_name: "clinton, ny",
  },
  {
    postal_code: "5851",
    zip_name: "lyndonville, vt",
  },
  {
    postal_code: "56529",
    zip_name: "dilworth, mn",
  },
  {
    postal_code: "45207",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "62626",
    zip_name: "carlinville, il",
  },
  {
    postal_code: "95827",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "55344",
    zip_name: "eden prairie, mn",
  },
  {
    postal_code: "51566",
    zip_name: "red oak, ia",
  },
  {
    postal_code: "2767",
    zip_name: "raynham, ma",
  },
  {
    postal_code: "21009",
    zip_name: "abingdon, md",
  },
  {
    postal_code: "95246",
    zip_name: "mountain ranch, ca",
  },
  {
    postal_code: "79925",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "20816",
    zip_name: "bethesda, md",
  },
  {
    postal_code: "93402",
    zip_name: "los osos, ca",
  },
  {
    postal_code: "81001",
    zip_name: "pueblo, co",
  },
  {
    postal_code: "5363",
    zip_name: "wilmington, vt",
  },
  {
    postal_code: "85012",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "96122",
    zip_name: "portola, ca",
  },
  {
    postal_code: "55336",
    zip_name: "glencoe, mn",
  },
  {
    postal_code: "13420",
    zip_name: "old forge, ny",
  },
  {
    postal_code: "53581",
    zip_name: "richland center, wi",
  },
  {
    postal_code: "19141",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "31539",
    zip_name: "hazlehurst, ga",
  },
  {
    postal_code: "30175",
    zip_name: "talking rock, ga",
  },
  {
    postal_code: "10302",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "98070",
    zip_name: "vashon, wa",
  },
  {
    postal_code: "18510",
    zip_name: "scranton, pa",
  },
  {
    postal_code: "34479",
    zip_name: "ocala, fl",
  },
  {
    postal_code: "75161",
    zip_name: "terrell, tx",
  },
  {
    postal_code: "13849",
    zip_name: "unadilla, ny",
  },
  {
    postal_code: "2062",
    zip_name: "norwood, ma",
  },
  {
    postal_code: "55414",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "95864",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "1913",
    zip_name: "amesbury, ma",
  },
  {
    postal_code: "23901",
    zip_name: "farmville, va",
  },
  {
    postal_code: "20912",
    zip_name: "takoma park, md",
  },
  {
    postal_code: "93247",
    zip_name: "lindsay, ca",
  },
  {
    postal_code: "76060",
    zip_name: "kennedale, tx",
  },
  {
    postal_code: "6795",
    zip_name: "watertown, ct",
  },
  {
    postal_code: "12401",
    zip_name: "kingston, ny",
  },
  {
    postal_code: "93960",
    zip_name: "soledad, ca",
  },
  {
    postal_code: "43558",
    zip_name: "swanton, oh",
  },
  {
    postal_code: "75491",
    zip_name: "whitewright, tx",
  },
  {
    postal_code: "76458",
    zip_name: "jacksboro, tx",
  },
  {
    postal_code: "85635",
    zip_name: "sierra vista, az",
  },
  {
    postal_code: "8203",
    zip_name: "brigantine, nj",
  },
  {
    postal_code: "8201",
    zip_name: "absecon, nj",
  },
  {
    postal_code: "21225",
    zip_name: "brooklyn, md",
  },
  {
    postal_code: "83642",
    zip_name: "meridian, id",
  },
  {
    postal_code: "15022",
    zip_name: "charleroi, pa",
  },
  {
    postal_code: "92354",
    zip_name: "loma linda, ca",
  },
  {
    postal_code: "81303",
    zip_name: "durango, co",
  },
  {
    postal_code: "80447",
    zip_name: "grand lake, co",
  },
  {
    postal_code: "21076",
    zip_name: "hanover, md",
  },
  {
    postal_code: "2021",
    zip_name: "canton, ma",
  },
  {
    postal_code: "3275",
    zip_name: "suncook, nh",
  },
  {
    postal_code: "78152",
    zip_name: "saint hedwig, tx",
  },
  {
    postal_code: "79562",
    zip_name: "tuscola, tx",
  },
  {
    postal_code: "95932",
    zip_name: "colusa, ca",
  },
  {
    postal_code: "20733",
    zip_name: "churchton, md",
  },
  {
    postal_code: "35114",
    zip_name: "alabaster, al",
  },
  {
    postal_code: "39170",
    zip_name: "terry, ms",
  },
  {
    postal_code: "91506",
    zip_name: "burbank, ca",
  },
  {
    postal_code: "13421",
    zip_name: "oneida, ny",
  },
  {
    postal_code: "2176",
    zip_name: "melrose, ma",
  },
  {
    postal_code: "30683",
    zip_name: "winterville, ga",
  },
  {
    postal_code: "12865",
    zip_name: "salem, ny",
  },
  {
    postal_code: "19425",
    zip_name: "chester springs, pa",
  },
  {
    postal_code: "26070",
    zip_name: "wellsburg, wv",
  },
  {
    postal_code: "19343",
    zip_name: "glenmoore, pa",
  },
  {
    postal_code: "54547",
    zip_name: "mercer, wi",
  },
  {
    postal_code: "46160",
    zip_name: "morgantown, in",
  },
  {
    postal_code: "77627",
    zip_name: "nederland, tx",
  },
  {
    postal_code: "1845",
    zip_name: "north andover, ma",
  },
  {
    postal_code: "62832",
    zip_name: "du quoin, il",
  },
  {
    postal_code: "92844",
    zip_name: "garden grove, ca",
  },
  {
    postal_code: "91606",
    zip_name: "north hollywood, ca",
  },
  {
    postal_code: "32696",
    zip_name: "williston, fl",
  },
  {
    postal_code: "8204",
    zip_name: "cape may, nj",
  },
  {
    postal_code: "72150",
    zip_name: "sheridan, ar",
  },
  {
    postal_code: "85263",
    zip_name: "rio verde, az",
  },
  {
    postal_code: "3077",
    zip_name: "raymond, nh",
  },
  {
    postal_code: "96708",
    zip_name: "haiku, hi",
  },
  {
    postal_code: "64730",
    zip_name: "butler, mo",
  },
  {
    postal_code: "50321",
    zip_name: "des moines, ia",
  },
  {
    postal_code: "19802",
    zip_name: "wilmington, de",
  },
  {
    postal_code: "38363",
    zip_name: "parsons, tn",
  },
  {
    postal_code: "94582",
    zip_name: "san ramon, ca",
  },
  {
    postal_code: "15857",
    zip_name: "saint marys, pa",
  },
  {
    postal_code: "2648",
    zip_name: "marstons mills, ma",
  },
  {
    postal_code: "43449",
    zip_name: "oak harbor, oh",
  },
  {
    postal_code: "97753",
    zip_name: "powell butte, or",
  },
  {
    postal_code: "37709",
    zip_name: "blaine, tn",
  },
  {
    postal_code: "93602",
    zip_name: "auberry, ca",
  },
  {
    postal_code: "45416",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "46321",
    zip_name: "munster, in",
  },
  {
    postal_code: "56387",
    zip_name: "waite park, mn",
  },
  {
    postal_code: "19061",
    zip_name: "marcus hook, pa",
  },
  {
    postal_code: "75414",
    zip_name: "bells, tx",
  },
  {
    postal_code: "37329",
    zip_name: "englewood, tn",
  },
  {
    postal_code: "50428",
    zip_name: "clear lake, ia",
  },
  {
    postal_code: "27828",
    zip_name: "farmville, nc",
  },
  {
    postal_code: "12015",
    zip_name: "athens, ny",
  },
  {
    postal_code: "36606",
    zip_name: "mobile, al",
  },
  {
    postal_code: "61016",
    zip_name: "cherry valley, il",
  },
  {
    postal_code: "23168",
    zip_name: "toano, va",
  },
  {
    postal_code: "25901",
    zip_name: "oak hill, wv",
  },
  {
    postal_code: "87710",
    zip_name: "angel fire, nm",
  },
  {
    postal_code: "75109",
    zip_name: "corsicana, tx",
  },
  {
    postal_code: "43938",
    zip_name: "mingo junction, oh",
  },
  {
    postal_code: "1040",
    zip_name: "holyoke, ma",
  },
  {
    postal_code: "80003",
    zip_name: "arvada, co",
  },
  {
    postal_code: "39206",
    zip_name: "jackson, ms",
  },
  {
    postal_code: "97702",
    zip_name: "bend, or",
  },
  {
    postal_code: "77026",
    zip_name: "houston, tx",
  },
  {
    postal_code: "48768",
    zip_name: "vassar, mi",
  },
  {
    postal_code: "46755",
    zip_name: "kendallville, in",
  },
  {
    postal_code: "61104",
    zip_name: "rockford, il",
  },
  {
    postal_code: "61938",
    zip_name: "mattoon, il",
  },
  {
    postal_code: "99114",
    zip_name: "colville, wa",
  },
  {
    postal_code: "11419",
    zip_name: "south richmond hill, ny",
  },
  {
    postal_code: "12413",
    zip_name: "cairo, ny",
  },
  {
    postal_code: "91914",
    zip_name: "chula vista, ca",
  },
  {
    postal_code: "54859",
    zip_name: "minong, wi",
  },
  {
    postal_code: "44320",
    zip_name: "akron, oh",
  },
  {
    postal_code: "86335",
    zip_name: "rimrock, az",
  },
  {
    postal_code: "48178",
    zip_name: "south lyon, mi",
  },
  {
    postal_code: "64108",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "93730",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "22560",
    zip_name: "tappahannock, va",
  },
  {
    postal_code: "91910",
    zip_name: "chula vista, ca",
  },
  {
    postal_code: "91754",
    zip_name: "monterey park, ca",
  },
  {
    postal_code: "44641",
    zip_name: "louisville, oh",
  },
  {
    postal_code: "2830",
    zip_name: "harrisville, ri",
  },
  {
    postal_code: "29385",
    zip_name: "wellford, sc",
  },
  {
    postal_code: "2825",
    zip_name: "foster, ri",
  },
  {
    postal_code: "6057",
    zip_name: "new hartford, ct",
  },
  {
    postal_code: "2882",
    zip_name: "narragansett, ri",
  },
  {
    postal_code: "22209",
    zip_name: "arlington, va",
  },
  {
    postal_code: "78852",
    zip_name: "eagle pass, tx",
  },
  {
    postal_code: "85648",
    zip_name: "rio rico, az",
  },
  {
    postal_code: "11783",
    zip_name: "seaford, ny",
  },
  {
    postal_code: "37708",
    zip_name: "bean station, tn",
  },
  {
    postal_code: "11426",
    zip_name: "bellerose, ny",
  },
  {
    postal_code: "32176",
    zip_name: "ormond beach, fl",
  },
  {
    postal_code: "27505",
    zip_name: "broadway, nc",
  },
  {
    postal_code: "95045",
    zip_name: "san juan bautista, ca",
  },
  {
    postal_code: "41101",
    zip_name: "ashland, ky",
  },
  {
    postal_code: "95620",
    zip_name: "dixon, ca",
  },
  {
    postal_code: "53533",
    zip_name: "dodgeville, wi",
  },
  {
    postal_code: "39581",
    zip_name: "pascagoula, ms",
  },
  {
    postal_code: "43526",
    zip_name: "hicksville, oh",
  },
  {
    postal_code: "27358",
    zip_name: "summerfield, nc",
  },
  {
    postal_code: "2571",
    zip_name: "wareham, ma",
  },
  {
    postal_code: "90501",
    zip_name: "torrance, ca",
  },
  {
    postal_code: "11961",
    zip_name: "ridge, ny",
  },
  {
    postal_code: "54822",
    zip_name: "cameron, wi",
  },
  {
    postal_code: "44505",
    zip_name: "youngstown, oh",
  },
  {
    postal_code: "84648",
    zip_name: "nephi, ut",
  },
  {
    postal_code: "95131",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "76105",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "90012",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "94602",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "27101",
    zip_name: "winston salem, nc",
  },
  {
    postal_code: "13775",
    zip_name: "franklin, ny",
  },
  {
    postal_code: "23927",
    zip_name: "clarksville, va",
  },
  {
    postal_code: "12074",
    zip_name: "galway, ny",
  },
  {
    postal_code: "15717",
    zip_name: "blairsville, pa",
  },
  {
    postal_code: "90245",
    zip_name: "el segundo, ca",
  },
  {
    postal_code: "50401",
    zip_name: "mason city, ia",
  },
  {
    postal_code: "28170",
    zip_name: "wadesboro, nc",
  },
  {
    postal_code: "34737",
    zip_name: "howey in the hills, fl",
  },
  {
    postal_code: "72206",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "2921",
    zip_name: "cranston, ri",
  },
  {
    postal_code: "64158",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "20024",
    zip_name: "washington, dc",
  },
  {
    postal_code: "4079",
    zip_name: "harpswell, me",
  },
  {
    postal_code: "56367",
    zip_name: "rice, mn",
  },
  {
    postal_code: "15021",
    zip_name: "burgettstown, pa",
  },
  {
    postal_code: "22973",
    zip_name: "stanardsville, va",
  },
  {
    postal_code: "50533",
    zip_name: "eagle grove, ia",
  },
  {
    postal_code: "3086",
    zip_name: "wilton, nh",
  },
  {
    postal_code: "43528",
    zip_name: "holland, oh",
  },
  {
    postal_code: "66032",
    zip_name: "garnett, ks",
  },
  {
    postal_code: "55388",
    zip_name: "watertown, mn",
  },
  {
    postal_code: "15332",
    zip_name: "finleyville, pa",
  },
  {
    postal_code: "97391",
    zip_name: "toledo, or",
  },
  {
    postal_code: "54944",
    zip_name: "hortonville, wi",
  },
  {
    postal_code: "95816",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "6479",
    zip_name: "plantsville, ct",
  },
  {
    postal_code: "19941",
    zip_name: "ellendale, de",
  },
  {
    postal_code: "81144",
    zip_name: "monte vista, co",
  },
  {
    postal_code: "6016",
    zip_name: "broad brook, ct",
  },
  {
    postal_code: "4970",
    zip_name: "rangeley, me",
  },
  {
    postal_code: "12834",
    zip_name: "greenwich, ny",
  },
  {
    postal_code: "56515",
    zip_name: "battle lake, mn",
  },
  {
    postal_code: "55363",
    zip_name: "montrose, mn",
  },
  {
    postal_code: "45345",
    zip_name: "new lebanon, oh",
  },
  {
    postal_code: "68467",
    zip_name: "york, ne",
  },
  {
    postal_code: "76667",
    zip_name: "mexia, tx",
  },
  {
    postal_code: "70605",
    zip_name: "lake charles, la",
  },
  {
    postal_code: "68527",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "93723",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "66103",
    zip_name: "kansas city, ks",
  },
  {
    postal_code: "53925",
    zip_name: "columbus, wi",
  },
  {
    postal_code: "55069",
    zip_name: "rush city, mn",
  },
  {
    postal_code: "98596",
    zip_name: "winlock, wa",
  },
  {
    postal_code: "28761",
    zip_name: "nebo, nc",
  },
  {
    postal_code: "31537",
    zip_name: "folkston, ga",
  },
  {
    postal_code: "26260",
    zip_name: "davis, wv",
  },
  {
    postal_code: "40484",
    zip_name: "stanford, ky",
  },
  {
    postal_code: "2642",
    zip_name: "eastham, ma",
  },
  {
    postal_code: "54011",
    zip_name: "ellsworth, wi",
  },
  {
    postal_code: "32046",
    zip_name: "hilliard, fl",
  },
  {
    postal_code: "33922",
    zip_name: "bokeelia, fl",
  },
  {
    postal_code: "84315",
    zip_name: "hooper, ut",
  },
  {
    postal_code: "76424",
    zip_name: "breckenridge, tx",
  },
  {
    postal_code: "94703",
    zip_name: "berkeley, ca",
  },
  {
    postal_code: "41501",
    zip_name: "pikeville, ky",
  },
  {
    postal_code: "98944",
    zip_name: "sunnyside, wa",
  },
  {
    postal_code: "3249",
    zip_name: "gilford, nh",
  },
  {
    postal_code: "90806",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "21619",
    zip_name: "chester, md",
  },
  {
    postal_code: "25541",
    zip_name: "milton, wv",
  },
  {
    postal_code: "93105",
    zip_name: "santa barbara, ca",
  },
  {
    postal_code: "5871",
    zip_name: "west burke, vt",
  },
  {
    postal_code: "54873",
    zip_name: "solon springs, wi",
  },
  {
    postal_code: "16057",
    zip_name: "slippery rock, pa",
  },
  {
    postal_code: "78214",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "11206",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "28138",
    zip_name: "rockwell, nc",
  },
  {
    postal_code: "63038",
    zip_name: "wildwood, mo",
  },
  {
    postal_code: "63101",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "98467",
    zip_name: "university place, wa",
  },
  {
    postal_code: "1240",
    zip_name: "lenox, ma",
  },
  {
    postal_code: "19036",
    zip_name: "glenolden, pa",
  },
  {
    postal_code: "90063",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "34289",
    zip_name: "north port, fl",
  },
  {
    postal_code: "13211",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "79360",
    zip_name: "seminole, tx",
  },
  {
    postal_code: "80246",
    zip_name: "denver, co",
  },
  {
    postal_code: "30554",
    zip_name: "lula, ga",
  },
  {
    postal_code: "12885",
    zip_name: "warrensburg, ny",
  },
  {
    postal_code: "48158",
    zip_name: "manchester, mi",
  },
  {
    postal_code: "79412",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "78669",
    zip_name: "spicewood, tx",
  },
  {
    postal_code: "3766",
    zip_name: "lebanon, nh",
  },
  {
    postal_code: "74426",
    zip_name: "checotah, ok",
  },
  {
    postal_code: "93103",
    zip_name: "santa barbara, ca",
  },
  {
    postal_code: "7420",
    zip_name: "haskell, nj",
  },
  {
    postal_code: "45065",
    zip_name: "south lebanon, oh",
  },
  {
    postal_code: "1923",
    zip_name: "danvers, ma",
  },
  {
    postal_code: "28753",
    zip_name: "marshall, nc",
  },
  {
    postal_code: "21030",
    zip_name: "cockeysville, md",
  },
  {
    postal_code: "45171",
    zip_name: "sardinia, oh",
  },
  {
    postal_code: "44405",
    zip_name: "campbell, oh",
  },
  {
    postal_code: "79763",
    zip_name: "odessa, tx",
  },
  {
    postal_code: "32148",
    zip_name: "interlachen, fl",
  },
  {
    postal_code: "22032",
    zip_name: "fairfax, va",
  },
  {
    postal_code: "52342",
    zip_name: "toledo, ia",
  },
  {
    postal_code: "25550",
    zip_name: "point pleasant, wv",
  },
  {
    postal_code: "12159",
    zip_name: "slingerlands, ny",
  },
  {
    postal_code: "90715",
    zip_name: "lakewood, ca",
  },
  {
    postal_code: "92614",
    zip_name: "irvine, ca",
  },
  {
    postal_code: "18458",
    zip_name: "shohola, pa",
  },
  {
    postal_code: "62471",
    zip_name: "vandalia, il",
  },
  {
    postal_code: "59711",
    zip_name: "anaconda, mt",
  },
  {
    postal_code: "47265",
    zip_name: "north vernon, in",
  },
  {
    postal_code: "91803",
    zip_name: "alhambra, ca",
  },
  {
    postal_code: "52748",
    zip_name: "eldridge, ia",
  },
  {
    postal_code: "56069",
    zip_name: "montgomery, mn",
  },
  {
    postal_code: "43044",
    zip_name: "mechanicsburg, oh",
  },
  {
    postal_code: "85933",
    zip_name: "overgaard, az",
  },
  {
    postal_code: "97734",
    zip_name: "culver, or",
  },
  {
    postal_code: "45304",
    zip_name: "arcanum, oh",
  },
  {
    postal_code: "19901",
    zip_name: "dover, de",
  },
  {
    postal_code: "72774",
    zip_name: "west fork, ar",
  },
  {
    postal_code: "89705",
    zip_name: "carson city, nv",
  },
  {
    postal_code: "61252",
    zip_name: "fulton, il",
  },
  {
    postal_code: "94597",
    zip_name: "walnut creek, ca",
  },
  {
    postal_code: "18977",
    zip_name: "washington crossing, pa",
  },
  {
    postal_code: "55101",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "35115",
    zip_name: "montevallo, al",
  },
  {
    postal_code: "8251",
    zip_name: "villas, nj",
  },
  {
    postal_code: "8611",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "13865",
    zip_name: "windsor, ny",
  },
  {
    postal_code: "96760",
    zip_name: "kurtistown, hi",
  },
  {
    postal_code: "8051",
    zip_name: "mantua, nj",
  },
  {
    postal_code: "22305",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "76449",
    zip_name: "graford, tx",
  },
  {
    postal_code: "8551",
    zip_name: "ringoes, nj",
  },
  {
    postal_code: "54552",
    zip_name: "park falls, wi",
  },
  {
    postal_code: "85623",
    zip_name: "oracle, az",
  },
  {
    postal_code: "76634",
    zip_name: "clifton, tx",
  },
  {
    postal_code: "17820",
    zip_name: "catawissa, pa",
  },
  {
    postal_code: "61264",
    zip_name: "milan, il",
  },
  {
    postal_code: "77488",
    zip_name: "wharton, tx",
  },
  {
    postal_code: "79904",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "6096",
    zip_name: "windsor locks, ct",
  },
  {
    postal_code: "92359",
    zip_name: "mentone, ca",
  },
  {
    postal_code: "23847",
    zip_name: "emporia, va",
  },
  {
    postal_code: "94065",
    zip_name: "redwood city, ca",
  },
  {
    postal_code: "2556",
    zip_name: "north falmouth, ma",
  },
  {
    postal_code: "48750",
    zip_name: "oscoda, mi",
  },
  {
    postal_code: "32693",
    zip_name: "trenton, fl",
  },
  {
    postal_code: "6379",
    zip_name: "pawcatuck, ct",
  },
  {
    postal_code: "64106",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "45701",
    zip_name: "athens, oh",
  },
  {
    postal_code: "91748",
    zip_name: "rowland heights, ca",
  },
  {
    postal_code: "81131",
    zip_name: "crestone, co",
  },
  {
    postal_code: "6787",
    zip_name: "thomaston, ct",
  },
  {
    postal_code: "40242",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "62960",
    zip_name: "metropolis, il",
  },
  {
    postal_code: "21217",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "4416",
    zip_name: "bucksport, me",
  },
  {
    postal_code: "48848",
    zip_name: "laingsburg, mi",
  },
  {
    postal_code: "65608",
    zip_name: "ava, mo",
  },
  {
    postal_code: "2155",
    zip_name: "medford, ma",
  },
  {
    postal_code: "77076",
    zip_name: "houston, tx",
  },
  {
    postal_code: "11415",
    zip_name: "kew gardens, ny",
  },
  {
    postal_code: "27203",
    zip_name: "asheboro, nc",
  },
  {
    postal_code: "55103",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "93314",
    zip_name: "bakersfield, ca",
  },
  {
    postal_code: "29645",
    zip_name: "gray court, sc",
  },
  {
    postal_code: "42728",
    zip_name: "columbia, ky",
  },
  {
    postal_code: "77879",
    zip_name: "somerville, tx",
  },
  {
    postal_code: "14903",
    zip_name: "elmira, ny",
  },
  {
    postal_code: "96741",
    zip_name: "kalaheo, hi",
  },
  {
    postal_code: "4009",
    zip_name: "bridgton, me",
  },
  {
    postal_code: "53954",
    zip_name: "pardeeville, wi",
  },
  {
    postal_code: "94030",
    zip_name: "millbrae, ca",
  },
  {
    postal_code: "97071",
    zip_name: "woodburn, or",
  },
  {
    postal_code: "21236",
    zip_name: "nottingham, md",
  },
  {
    postal_code: "70129",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "2780",
    zip_name: "taunton, ma",
  },
  {
    postal_code: "2660",
    zip_name: "south dennis, ma",
  },
  {
    postal_code: "12148",
    zip_name: "rexford, ny",
  },
  {
    postal_code: "3109",
    zip_name: "manchester, nh",
  },
  {
    postal_code: "63147",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "44110",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "37722",
    zip_name: "cosby, tn",
  },
  {
    postal_code: "93610",
    zip_name: "chowchilla, ca",
  },
  {
    postal_code: "15658",
    zip_name: "ligonier, pa",
  },
  {
    postal_code: "44030",
    zip_name: "conneaut, oh",
  },
  {
    postal_code: "42003",
    zip_name: "paducah, ky",
  },
  {
    postal_code: "45669",
    zip_name: "proctorville, oh",
  },
  {
    postal_code: "3079",
    zip_name: "salem, nh",
  },
  {
    postal_code: "62274",
    zip_name: "pinckneyville, il",
  },
  {
    postal_code: "77518",
    zip_name: "bacliff, tx",
  },
  {
    postal_code: "68003",
    zip_name: "ashland, ne",
  },
  {
    postal_code: "54020",
    zip_name: "osceola, wi",
  },
  {
    postal_code: "65648",
    zip_name: "fair grove, mo",
  },
  {
    postal_code: "15690",
    zip_name: "vandergrift, pa",
  },
  {
    postal_code: "90255",
    zip_name: "huntington park, ca",
  },
  {
    postal_code: "56442",
    zip_name: "crosslake, mn",
  },
  {
    postal_code: "70611",
    zip_name: "lake charles, la",
  },
  {
    postal_code: "52753",
    zip_name: "le claire, ia",
  },
  {
    postal_code: "15801",
    zip_name: "du bois, pa",
  },
  {
    postal_code: "43605",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "22603",
    zip_name: "winchester, va",
  },
  {
    postal_code: "22401",
    zip_name: "fredericksburg, va",
  },
  {
    postal_code: "32304",
    zip_name: "tallahassee, fl",
  },
  {
    postal_code: "65037",
    zip_name: "gravois mills, mo",
  },
  {
    postal_code: "12106",
    zip_name: "kinderhook, ny",
  },
  {
    postal_code: "43822",
    zip_name: "frazeysburg, oh",
  },
  {
    postal_code: "34945",
    zip_name: "fort pierce, fl",
  },
  {
    postal_code: "75704",
    zip_name: "tyler, tx",
  },
  {
    postal_code: "22205",
    zip_name: "arlington, va",
  },
  {
    postal_code: "98390",
    zip_name: "sumner, wa",
  },
  {
    postal_code: "6092",
    zip_name: "west simsbury, ct",
  },
  {
    postal_code: "3285",
    zip_name: "thornton, nh",
  },
  {
    postal_code: "69001",
    zip_name: "mc cook, ne",
  },
  {
    postal_code: "47302",
    zip_name: "muncie, in",
  },
  {
    postal_code: "11949",
    zip_name: "manorville, ny",
  },
  {
    postal_code: "20895",
    zip_name: "kensington, md",
  },
  {
    postal_code: "90230",
    zip_name: "culver city, ca",
  },
  {
    postal_code: "2675",
    zip_name: "yarmouth port, ma",
  },
  {
    postal_code: "11771",
    zip_name: "oyster bay, ny",
  },
  {
    postal_code: "28675",
    zip_name: "sparta, nc",
  },
  {
    postal_code: "93222",
    zip_name: "pine mountain club, ca",
  },
  {
    postal_code: "83014",
    zip_name: "wilson, wy",
  },
  {
    postal_code: "89704",
    zip_name: "washoe valley, nv",
  },
  {
    postal_code: "92065",
    zip_name: "ramona, ca",
  },
  {
    postal_code: "68372",
    zip_name: "hickman, ne",
  },
  {
    postal_code: "15683",
    zip_name: "scottdale, pa",
  },
  {
    postal_code: "79930",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "49103",
    zip_name: "berrien springs, mi",
  },
  {
    postal_code: "90813",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "93455",
    zip_name: "santa maria, ca",
  },
  {
    postal_code: "13317",
    zip_name: "canajoharie, ny",
  },
  {
    postal_code: "12186",
    zip_name: "voorheesville, ny",
  },
  {
    postal_code: "56359",
    zip_name: "onamia, mn",
  },
  {
    postal_code: "54830",
    zip_name: "danbury, wi",
  },
  {
    postal_code: "32735",
    zip_name: "grand island, fl",
  },
  {
    postal_code: "77905",
    zip_name: "victoria, tx",
  },
  {
    postal_code: "55356",
    zip_name: "long lake, mn",
  },
  {
    postal_code: "85602",
    zip_name: "benson, az",
  },
  {
    postal_code: "6798",
    zip_name: "woodbury, ct",
  },
  {
    postal_code: "77085",
    zip_name: "houston, tx",
  },
  {
    postal_code: "90717",
    zip_name: "lomita, ca",
  },
  {
    postal_code: "66220",
    zip_name: "lenexa, ks",
  },
  {
    postal_code: "93620",
    zip_name: "dos palos, ca",
  },
  {
    postal_code: "13326",
    zip_name: "cooperstown, ny",
  },
  {
    postal_code: "16137",
    zip_name: "mercer, pa",
  },
  {
    postal_code: "43964",
    zip_name: "toronto, oh",
  },
  {
    postal_code: "76501",
    zip_name: "temple, tx",
  },
  {
    postal_code: "90404",
    zip_name: "santa monica, ca",
  },
  {
    postal_code: "21102",
    zip_name: "manchester, md",
  },
  {
    postal_code: "46405",
    zip_name: "lake station, in",
  },
  {
    postal_code: "10307",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "94517",
    zip_name: "clayton, ca",
  },
  {
    postal_code: "50138",
    zip_name: "knoxville, ia",
  },
  {
    postal_code: "11730",
    zip_name: "east islip, ny",
  },
  {
    postal_code: "78623",
    zip_name: "fischer, tx",
  },
  {
    postal_code: "13452",
    zip_name: "saint johnsville, ny",
  },
  {
    postal_code: "77306",
    zip_name: "conroe, tx",
  },
  {
    postal_code: "49448",
    zip_name: "nunica, mi",
  },
  {
    postal_code: "2828",
    zip_name: "greenville, ri",
  },
  {
    postal_code: "75803",
    zip_name: "palestine, tx",
  },
  {
    postal_code: "76442",
    zip_name: "comanche, tx",
  },
  {
    postal_code: "60606",
    zip_name: "chicago, il",
  },
  {
    postal_code: "78861",
    zip_name: "hondo, tx",
  },
  {
    postal_code: "22974",
    zip_name: "troy, va",
  },
  {
    postal_code: "6249",
    zip_name: "lebanon, ct",
  },
  {
    postal_code: "20724",
    zip_name: "laurel, md",
  },
  {
    postal_code: "43571",
    zip_name: "whitehouse, oh",
  },
  {
    postal_code: "29501",
    zip_name: "florence, sc",
  },
  {
    postal_code: "78732",
    zip_name: "austin, tx",
  },
  {
    postal_code: "95326",
    zip_name: "hughson, ca",
  },
  {
    postal_code: "12594",
    zip_name: "wingdale, ny",
  },
  {
    postal_code: "38671",
    zip_name: "southaven, ms",
  },
  {
    postal_code: "61061",
    zip_name: "oregon, il",
  },
  {
    postal_code: "37046",
    zip_name: "college grove, tn",
  },
  {
    postal_code: "11575",
    zip_name: "roosevelt, ny",
  },
  {
    postal_code: "49304",
    zip_name: "baldwin, mi",
  },
  {
    postal_code: "92624",
    zip_name: "capistrano beach, ca",
  },
  {
    postal_code: "76073",
    zip_name: "paradise, tx",
  },
  {
    postal_code: "11557",
    zip_name: "hewlett, ny",
  },
  {
    postal_code: "18801",
    zip_name: "montrose, pa",
  },
  {
    postal_code: "13365",
    zip_name: "little falls, ny",
  },
  {
    postal_code: "72007",
    zip_name: "austin, ar",
  },
  {
    postal_code: "21237",
    zip_name: "rosedale, md",
  },
  {
    postal_code: "29340",
    zip_name: "gaffney, sc",
  },
  {
    postal_code: "90290",
    zip_name: "topanga, ca",
  },
  {
    postal_code: "37220",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "76446",
    zip_name: "dublin, tx",
  },
  {
    postal_code: "24378",
    zip_name: "troutdale, va",
  },
  {
    postal_code: "93532",
    zip_name: "lake hughes, ca",
  },
  {
    postal_code: "31328",
    zip_name: "tybee island, ga",
  },
  {
    postal_code: "92567",
    zip_name: "nuevo, ca",
  },
  {
    postal_code: "72560",
    zip_name: "mountain view, ar",
  },
  {
    postal_code: "2817",
    zip_name: "west greenwich, ri",
  },
  {
    postal_code: "12443",
    zip_name: "hurley, ny",
  },
  {
    postal_code: "6106",
    zip_name: "hartford, ct",
  },
  {
    postal_code: "94087",
    zip_name: "sunnyvale, ca",
  },
  {
    postal_code: "98446",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "63670",
    zip_name: "sainte genevieve, mo",
  },
  {
    postal_code: "6423",
    zip_name: "east haddam, ct",
  },
  {
    postal_code: "14701",
    zip_name: "jamestown, ny",
  },
  {
    postal_code: "38024",
    zip_name: "dyersburg, tn",
  },
  {
    postal_code: "28391",
    zip_name: "stedman, nc",
  },
  {
    postal_code: "20678",
    zip_name: "prince frederick, md",
  },
  {
    postal_code: "75442",
    zip_name: "farmersville, tx",
  },
  {
    postal_code: "21108",
    zip_name: "millersville, md",
  },
  {
    postal_code: "92225",
    zip_name: "blythe, ca",
  },
  {
    postal_code: "20124",
    zip_name: "clifton, va",
  },
  {
    postal_code: "8243",
    zip_name: "sea isle city, nj",
  },
  {
    postal_code: "16415",
    zip_name: "fairview, pa",
  },
  {
    postal_code: "75758",
    zip_name: "chandler, tx",
  },
  {
    postal_code: "44321",
    zip_name: "akron, oh",
  },
  {
    postal_code: "30337",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "98247",
    zip_name: "everson, wa",
  },
  {
    postal_code: "66227",
    zip_name: "lenexa, ks",
  },
  {
    postal_code: "25702",
    zip_name: "huntington, wv",
  },
  {
    postal_code: "20866",
    zip_name: "burtonsville, md",
  },
  {
    postal_code: "94502",
    zip_name: "alameda, ca",
  },
  {
    postal_code: "25303",
    zip_name: "south charleston, wv",
  },
  {
    postal_code: "75472",
    zip_name: "point, tx",
  },
  {
    postal_code: "2127",
    zip_name: "south boston, ma",
  },
  {
    postal_code: "14131",
    zip_name: "ransomville, ny",
  },
  {
    postal_code: "49230",
    zip_name: "brooklyn, mi",
  },
  {
    postal_code: "46962",
    zip_name: "north manchester, in",
  },
  {
    postal_code: "45404",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "19703",
    zip_name: "claymont, de",
  },
  {
    postal_code: "92832",
    zip_name: "fullerton, ca",
  },
  {
    postal_code: "32950",
    zip_name: "malabar, fl",
  },
  {
    postal_code: "49837",
    zip_name: "gladstone, mi",
  },
  {
    postal_code: "31816",
    zip_name: "manchester, ga",
  },
  {
    postal_code: "19142",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "96097",
    zip_name: "yreka, ca",
  },
  {
    postal_code: "95672",
    zip_name: "rescue, ca",
  },
  {
    postal_code: "50201",
    zip_name: "nevada, ia",
  },
  {
    postal_code: "75440",
    zip_name: "emory, tx",
  },
  {
    postal_code: "27357",
    zip_name: "stokesdale, nc",
  },
  {
    postal_code: "89120",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "6355",
    zip_name: "mystic, ct",
  },
  {
    postal_code: "85625",
    zip_name: "pearce, az",
  },
  {
    postal_code: "26037",
    zip_name: "follansbee, wv",
  },
  {
    postal_code: "11220",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "18062",
    zip_name: "macungie, pa",
  },
  {
    postal_code: "81122",
    zip_name: "bayfield, co",
  },
  {
    postal_code: "18424",
    zip_name: "gouldsboro, pa",
  },
  {
    postal_code: "75801",
    zip_name: "palestine, tx",
  },
  {
    postal_code: "78717",
    zip_name: "austin, tx",
  },
  {
    postal_code: "45323",
    zip_name: "enon, oh",
  },
  {
    postal_code: "22664",
    zip_name: "woodstock, va",
  },
  {
    postal_code: "3836",
    zip_name: "freedom, nh",
  },
  {
    postal_code: "13205",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "55013",
    zip_name: "chisago city, mn",
  },
  {
    postal_code: "41097",
    zip_name: "williamstown, ky",
  },
  {
    postal_code: "18847",
    zip_name: "susquehanna, pa",
  },
  {
    postal_code: "90262",
    zip_name: "lynwood, ca",
  },
  {
    postal_code: "27214",
    zip_name: "browns summit, nc",
  },
  {
    postal_code: "36265",
    zip_name: "jacksonville, al",
  },
  {
    postal_code: "29577",
    zip_name: "myrtle beach, sc",
  },
  {
    postal_code: "77407",
    zip_name: "richmond, tx",
  },
  {
    postal_code: "50263",
    zip_name: "waukee, ia",
  },
  {
    postal_code: "11976",
    zip_name: "water mill, ny",
  },
  {
    postal_code: "30363",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "92316",
    zip_name: "bloomington, ca",
  },
  {
    postal_code: "90302",
    zip_name: "inglewood, ca",
  },
  {
    postal_code: "91913",
    zip_name: "chula vista, ca",
  },
  {
    postal_code: "59068",
    zip_name: "red lodge, mt",
  },
  {
    postal_code: "61301",
    zip_name: "la salle, il",
  },
  {
    postal_code: "77808",
    zip_name: "bryan, tx",
  },
  {
    postal_code: "35128",
    zip_name: "pell city, al",
  },
  {
    postal_code: "11798",
    zip_name: "wyandanch, ny",
  },
  {
    postal_code: "27817",
    zip_name: "chocowinity, nc",
  },
  {
    postal_code: "72543",
    zip_name: "heber springs, ar",
  },
  {
    postal_code: "77650",
    zip_name: "port bolivar, tx",
  },
  {
    postal_code: "54557",
    zip_name: "presque isle, wi",
  },
  {
    postal_code: "18347",
    zip_name: "pocono lake, pa",
  },
  {
    postal_code: "78801",
    zip_name: "uvalde, tx",
  },
  {
    postal_code: "28759",
    zip_name: "mills river, nc",
  },
  {
    postal_code: "28602",
    zip_name: "hickory, nc",
  },
  {
    postal_code: "6382",
    zip_name: "uncasville, ct",
  },
  {
    postal_code: "77087",
    zip_name: "houston, tx",
  },
  {
    postal_code: "85901",
    zip_name: "show low, az",
  },
  {
    postal_code: "11793",
    zip_name: "wantagh, ny",
  },
  {
    postal_code: "35068",
    zip_name: "fultondale, al",
  },
  {
    postal_code: "78332",
    zip_name: "alice, tx",
  },
  {
    postal_code: "5356",
    zip_name: "west dover, vt",
  },
  {
    postal_code: "75182",
    zip_name: "sunnyvale, tx",
  },
  {
    postal_code: "20736",
    zip_name: "owings, md",
  },
  {
    postal_code: "28723",
    zip_name: "cullowhee, nc",
  },
  {
    postal_code: "20166",
    zip_name: "sterling, va",
  },
  {
    postal_code: "98685",
    zip_name: "vancouver, wa",
  },
  {
    postal_code: "90744",
    zip_name: "wilmington, ca",
  },
  {
    postal_code: "44420",
    zip_name: "girard, oh",
  },
  {
    postal_code: "95423",
    zip_name: "clearlake oaks, ca",
  },
  {
    postal_code: "20036",
    zip_name: "washington, dc",
  },
  {
    postal_code: "92408",
    zip_name: "san bernardino, ca",
  },
  {
    postal_code: "11385",
    zip_name: "ridgewood, ny",
  },
  {
    postal_code: "12197",
    zip_name: "worcester, ny",
  },
  {
    postal_code: "8514",
    zip_name: "cream ridge, nj",
  },
  {
    postal_code: "20783",
    zip_name: "hyattsville, md",
  },
  {
    postal_code: "95472",
    zip_name: "sebastopol, ca",
  },
  {
    postal_code: "12442",
    zip_name: "hunter, ny",
  },
  {
    postal_code: "15208",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "45822",
    zip_name: "celina, oh",
  },
  {
    postal_code: "90249",
    zip_name: "gardena, ca",
  },
  {
    postal_code: "37880",
    zip_name: "ten mile, tn",
  },
  {
    postal_code: "13367",
    zip_name: "lowville, ny",
  },
  {
    postal_code: "23602",
    zip_name: "newport news, va",
  },
  {
    postal_code: "35146",
    zip_name: "springville, al",
  },
  {
    postal_code: "77060",
    zip_name: "houston, tx",
  },
  {
    postal_code: "92028",
    zip_name: "fallbrook, ca",
  },
  {
    postal_code: "32118",
    zip_name: "daytona beach, fl",
  },
  {
    postal_code: "77078",
    zip_name: "houston, tx",
  },
  {
    postal_code: "21286",
    zip_name: "towson, md",
  },
  {
    postal_code: "85128",
    zip_name: "coolidge, az",
  },
  {
    postal_code: "53572",
    zip_name: "mount horeb, wi",
  },
  {
    postal_code: "2302",
    zip_name: "brockton, ma",
  },
  {
    postal_code: "96704",
    zip_name: "captain cook, hi",
  },
  {
    postal_code: "6237",
    zip_name: "columbia, ct",
  },
  {
    postal_code: "95963",
    zip_name: "orland, ca",
  },
  {
    postal_code: "93924",
    zip_name: "carmel valley, ca",
  },
  {
    postal_code: "1801",
    zip_name: "woburn, ma",
  },
  {
    postal_code: "90402",
    zip_name: "santa monica, ca",
  },
  {
    postal_code: "54457",
    zip_name: "nekoosa, wi",
  },
  {
    postal_code: "38672",
    zip_name: "southaven, ms",
  },
  {
    postal_code: "1904",
    zip_name: "lynn, ma",
  },
  {
    postal_code: "99021",
    zip_name: "mead, wa",
  },
  {
    postal_code: "32814",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "16055",
    zip_name: "sarver, pa",
  },
  {
    postal_code: "24517",
    zip_name: "altavista, va",
  },
  {
    postal_code: "93463",
    zip_name: "solvang, ca",
  },
  {
    postal_code: "18464",
    zip_name: "tafton, pa",
  },
  {
    postal_code: "78387",
    zip_name: "sinton, tx",
  },
  {
    postal_code: "6784",
    zip_name: "sherman, ct",
  },
  {
    postal_code: "27617",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "19047",
    zip_name: "langhorne, pa",
  },
  {
    postal_code: "45056",
    zip_name: "oxford, oh",
  },
  {
    postal_code: "72740",
    zip_name: "huntsville, ar",
  },
  {
    postal_code: "28747",
    zip_name: "lake toxaway, nc",
  },
  {
    postal_code: "94010",
    zip_name: "burlingame, ca",
  },
  {
    postal_code: "6320",
    zip_name: "new london, ct",
  },
  {
    postal_code: "33036",
    zip_name: "islamorada, fl",
  },
  {
    postal_code: "91977",
    zip_name: "spring valley, ca",
  },
  {
    postal_code: "80654",
    zip_name: "wiggins, co",
  },
  {
    postal_code: "93933",
    zip_name: "marina, ca",
  },
  {
    postal_code: "43567",
    zip_name: "wauseon, oh",
  },
  {
    postal_code: "3753",
    zip_name: "grantham, nh",
  },
  {
    postal_code: "65559",
    zip_name: "saint james, mo",
  },
  {
    postal_code: "12122",
    zip_name: "middleburgh, ny",
  },
  {
    postal_code: "61240",
    zip_name: "coal valley, il",
  },
  {
    postal_code: "46280",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "48415",
    zip_name: "birch run, mi",
  },
  {
    postal_code: "92114",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "28779",
    zip_name: "sylva, nc",
  },
  {
    postal_code: "18914",
    zip_name: "chalfont, pa",
  },
  {
    postal_code: "6119",
    zip_name: "west hartford, ct",
  },
  {
    postal_code: "16412",
    zip_name: "edinboro, pa",
  },
  {
    postal_code: "94105",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "35224",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "1223",
    zip_name: "becket, ma",
  },
  {
    postal_code: "84738",
    zip_name: "ivins, ut",
  },
  {
    postal_code: "11768",
    zip_name: "northport, ny",
  },
  {
    postal_code: "46323",
    zip_name: "hammond, in",
  },
  {
    postal_code: "56353",
    zip_name: "milaca, mn",
  },
  {
    postal_code: "60712",
    zip_name: "lincolnwood, il",
  },
  {
    postal_code: "19119",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "6333",
    zip_name: "east lyme, ct",
  },
  {
    postal_code: "75948",
    zip_name: "hemphill, tx",
  },
  {
    postal_code: "59870",
    zip_name: "stevensville, mt",
  },
  {
    postal_code: "12202",
    zip_name: "albany, ny",
  },
  {
    postal_code: "49802",
    zip_name: "kingsford, mi",
  },
  {
    postal_code: "16301",
    zip_name: "oil city, pa",
  },
  {
    postal_code: "45309",
    zip_name: "brookville, oh",
  },
  {
    postal_code: "77061",
    zip_name: "houston, tx",
  },
  {
    postal_code: "20639",
    zip_name: "huntingtown, md",
  },
  {
    postal_code: "23970",
    zip_name: "south hill, va",
  },
  {
    postal_code: "37690",
    zip_name: "telford, tn",
  },
  {
    postal_code: "18974",
    zip_name: "warminster, pa",
  },
  {
    postal_code: "44714",
    zip_name: "canton, oh",
  },
  {
    postal_code: "30220",
    zip_name: "grantville, ga",
  },
  {
    postal_code: "57754",
    zip_name: "lead, sd",
  },
  {
    postal_code: "63775",
    zip_name: "perryville, mo",
  },
  {
    postal_code: "66007",
    zip_name: "basehor, ks",
  },
  {
    postal_code: "19131",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "20737",
    zip_name: "riverdale, md",
  },
  {
    postal_code: "92057",
    zip_name: "oceanside, ca",
  },
  {
    postal_code: "5672",
    zip_name: "stowe, vt",
  },
  {
    postal_code: "29142",
    zip_name: "santee, sc",
  },
  {
    postal_code: "61341",
    zip_name: "marseilles, il",
  },
  {
    postal_code: "3042",
    zip_name: "epping, nh",
  },
  {
    postal_code: "80610",
    zip_name: "ault, co",
  },
  {
    postal_code: "61282",
    zip_name: "silvis, il",
  },
  {
    postal_code: "55108",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "20194",
    zip_name: "reston, va",
  },
  {
    postal_code: "61241",
    zip_name: "colona, il",
  },
  {
    postal_code: "91801",
    zip_name: "alhambra, ca",
  },
  {
    postal_code: "19709",
    zip_name: "middletown, de",
  },
  {
    postal_code: "23824",
    zip_name: "blackstone, va",
  },
  {
    postal_code: "75210",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "1267",
    zip_name: "williamstown, ma",
  },
  {
    postal_code: "19064",
    zip_name: "springfield, pa",
  },
  {
    postal_code: "79072",
    zip_name: "plainview, tx",
  },
  {
    postal_code: "22835",
    zip_name: "luray, va",
  },
  {
    postal_code: "8610",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "64079",
    zip_name: "platte city, mo",
  },
  {
    postal_code: "13778",
    zip_name: "greene, ny",
  },
  {
    postal_code: "5201",
    zip_name: "bennington, vt",
  },
  {
    postal_code: "55424",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "37769",
    zip_name: "rocky top, tn",
  },
  {
    postal_code: "28371",
    zip_name: "parkton, nc",
  },
  {
    postal_code: "27523",
    zip_name: "apex, nc",
  },
  {
    postal_code: "76006",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "6035",
    zip_name: "granby, ct",
  },
  {
    postal_code: "3052",
    zip_name: "litchfield, nh",
  },
  {
    postal_code: "65459",
    zip_name: "dixon, mo",
  },
  {
    postal_code: "27370",
    zip_name: "trinity, nc",
  },
  {
    postal_code: "20882",
    zip_name: "gaithersburg, md",
  },
  {
    postal_code: "6447",
    zip_name: "marlborough, ct",
  },
  {
    postal_code: "90094",
    zip_name: "playa vista, ca",
  },
  {
    postal_code: "17202",
    zip_name: "chambersburg, pa",
  },
  {
    postal_code: "20607",
    zip_name: "accokeek, md",
  },
  {
    postal_code: "20165",
    zip_name: "sterling, va",
  },
  {
    postal_code: "30276",
    zip_name: "senoia, ga",
  },
  {
    postal_code: "1453",
    zip_name: "leominster, ma",
  },
  {
    postal_code: "68410",
    zip_name: "nebraska city, ne",
  },
  {
    postal_code: "6226",
    zip_name: "willimantic, ct",
  },
  {
    postal_code: "11705",
    zip_name: "bayport, ny",
  },
  {
    postal_code: "37029",
    zip_name: "burns, tn",
  },
  {
    postal_code: "15904",
    zip_name: "johnstown, pa",
  },
  {
    postal_code: "33592",
    zip_name: "thonotosassa, fl",
  },
  {
    postal_code: "22124",
    zip_name: "oakton, va",
  },
  {
    postal_code: "45121",
    zip_name: "georgetown, oh",
  },
  {
    postal_code: "44044",
    zip_name: "grafton, oh",
  },
  {
    postal_code: "8021",
    zip_name: "clementon, nj",
  },
  {
    postal_code: "21213",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "55046",
    zip_name: "lonsdale, mn",
  },
  {
    postal_code: "45216",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "25213",
    zip_name: "winfield, wv",
  },
  {
    postal_code: "84701",
    zip_name: "richfield, ut",
  },
  {
    postal_code: "23061",
    zip_name: "gloucester, va",
  },
  {
    postal_code: "3303",
    zip_name: "concord, nh",
  },
  {
    postal_code: "90254",
    zip_name: "hermosa beach, ca",
  },
  {
    postal_code: "97135",
    zip_name: "pacific city, or",
  },
  {
    postal_code: "66720",
    zip_name: "chanute, ks",
  },
  {
    postal_code: "76450",
    zip_name: "graham, tx",
  },
  {
    postal_code: "92501",
    zip_name: "riverside, ca",
  },
  {
    postal_code: "73054",
    zip_name: "luther, ok",
  },
  {
    postal_code: "25064",
    zip_name: "dunbar, wv",
  },
  {
    postal_code: "44026",
    zip_name: "chesterland, oh",
  },
  {
    postal_code: "83301",
    zip_name: "twin falls, id",
  },
  {
    postal_code: "55359",
    zip_name: "maple plain, mn",
  },
  {
    postal_code: "83353",
    zip_name: "sun valley, id",
  },
  {
    postal_code: "20032",
    zip_name: "washington, dc",
  },
  {
    postal_code: "21204",
    zip_name: "towson, md",
  },
  {
    postal_code: "40026",
    zip_name: "goshen, ky",
  },
  {
    postal_code: "80467",
    zip_name: "oak creek, co",
  },
  {
    postal_code: "92610",
    zip_name: "foothill ranch, ca",
  },
  {
    postal_code: "15666",
    zip_name: "mount pleasant, pa",
  },
  {
    postal_code: "92231",
    zip_name: "calexico, ca",
  },
  {
    postal_code: "15204",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "11420",
    zip_name: "south ozone park, ny",
  },
  {
    postal_code: "72058",
    zip_name: "greenbrier, ar",
  },
  {
    postal_code: "3860",
    zip_name: "north conway, nh",
  },
  {
    postal_code: "12580",
    zip_name: "staatsburg, ny",
  },
  {
    postal_code: "56452",
    zip_name: "hackensack, mn",
  },
  {
    postal_code: "65552",
    zip_name: "plato, mo",
  },
  {
    postal_code: "6907",
    zip_name: "stamford, ct",
  },
  {
    postal_code: "1852",
    zip_name: "lowell, ma",
  },
  {
    postal_code: "60069",
    zip_name: "lincolnshire, il",
  },
  {
    postal_code: "34269",
    zip_name: "arcadia, fl",
  },
  {
    postal_code: "22657",
    zip_name: "strasburg, va",
  },
  {
    postal_code: "98368",
    zip_name: "port townsend, wa",
  },
  {
    postal_code: "93638",
    zip_name: "madera, ca",
  },
  {
    postal_code: "65483",
    zip_name: "houston, mo",
  },
  {
    postal_code: "55992",
    zip_name: "zumbrota, mn",
  },
  {
    postal_code: "13346",
    zip_name: "hamilton, ny",
  },
  {
    postal_code: "79935",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "1420",
    zip_name: "fitchburg, ma",
  },
  {
    postal_code: "27282",
    zip_name: "jamestown, nc",
  },
  {
    postal_code: "84050",
    zip_name: "morgan, ut",
  },
  {
    postal_code: "94116",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "15085",
    zip_name: "trafford, pa",
  },
  {
    postal_code: "11576",
    zip_name: "roslyn, ny",
  },
  {
    postal_code: "19438",
    zip_name: "harleysville, pa",
  },
  {
    postal_code: "37618",
    zip_name: "bluff city, tn",
  },
  {
    postal_code: "2664",
    zip_name: "south yarmouth, ma",
  },
  {
    postal_code: "48879",
    zip_name: "saint johns, mi",
  },
  {
    postal_code: "93706",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "28518",
    zip_name: "beulaville, nc",
  },
  {
    postal_code: "27521",
    zip_name: "coats, nc",
  },
  {
    postal_code: "84066",
    zip_name: "roosevelt, ut",
  },
  {
    postal_code: "37825",
    zip_name: "new tazewell, tn",
  },
  {
    postal_code: "15219",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "93631",
    zip_name: "kingsburg, ca",
  },
  {
    postal_code: "22611",
    zip_name: "berryville, va",
  },
  {
    postal_code: "29626",
    zip_name: "anderson, sc",
  },
  {
    postal_code: "86040",
    zip_name: "page, az",
  },
  {
    postal_code: "41016",
    zip_name: "covington, ky",
  },
  {
    postal_code: "93703",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "38375",
    zip_name: "selmer, tn",
  },
  {
    postal_code: "37882",
    zip_name: "townsend, tn",
  },
  {
    postal_code: "53949",
    zip_name: "montello, wi",
  },
  {
    postal_code: "8055",
    zip_name: "medford, nj",
  },
  {
    postal_code: "35204",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "53821",
    zip_name: "prairie du chien, wi",
  },
  {
    postal_code: "50047",
    zip_name: "carlisle, ia",
  },
  {
    postal_code: "20640",
    zip_name: "indian head, md",
  },
  {
    postal_code: "17745",
    zip_name: "lock haven, pa",
  },
  {
    postal_code: "45157",
    zip_name: "new richmond, oh",
  },
  {
    postal_code: "55115",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "22958",
    zip_name: "nellysford, va",
  },
  {
    postal_code: "89445",
    zip_name: "winnemucca, nv",
  },
  {
    postal_code: "4064",
    zip_name: "old orchard beach, me",
  },
  {
    postal_code: "95215",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "80903",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "78735",
    zip_name: "austin, tx",
  },
  {
    postal_code: "61523",
    zip_name: "chillicothe, il",
  },
  {
    postal_code: "43609",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "12198",
    zip_name: "wynantskill, ny",
  },
  {
    postal_code: "25413",
    zip_name: "bunker hill, wv",
  },
  {
    postal_code: "50324",
    zip_name: "windsor heights, ia",
  },
  {
    postal_code: "12158",
    zip_name: "selkirk, ny",
  },
  {
    postal_code: "74960",
    zip_name: "stilwell, ok",
  },
  {
    postal_code: "46324",
    zip_name: "hammond, in",
  },
  {
    postal_code: "72104",
    zip_name: "malvern, ar",
  },
  {
    postal_code: "62946",
    zip_name: "harrisburg, il",
  },
  {
    postal_code: "99156",
    zip_name: "newport, wa",
  },
  {
    postal_code: "94303",
    zip_name: "palo alto, ca",
  },
  {
    postal_code: "5001",
    zip_name: "white river junction, vt",
  },
  {
    postal_code: "11373",
    zip_name: "elmhurst, ny",
  },
  {
    postal_code: "25427",
    zip_name: "hedgesville, wv",
  },
  {
    postal_code: "28650",
    zip_name: "maiden, nc",
  },
  {
    postal_code: "30002",
    zip_name: "avondale estates, ga",
  },
  {
    postal_code: "56425",
    zip_name: "baxter, mn",
  },
  {
    postal_code: "56101",
    zip_name: "windom, mn",
  },
  {
    postal_code: "87015",
    zip_name: "edgewood, nm",
  },
  {
    postal_code: "45311",
    zip_name: "camden, oh",
  },
  {
    postal_code: "93015",
    zip_name: "fillmore, ca",
  },
  {
    postal_code: "11212",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "46012",
    zip_name: "anderson, in",
  },
  {
    postal_code: "57730",
    zip_name: "custer, sd",
  },
  {
    postal_code: "35211",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "57717",
    zip_name: "belle fourche, sd",
  },
  {
    postal_code: "91601",
    zip_name: "north hollywood, ca",
  },
  {
    postal_code: "17403",
    zip_name: "york, pa",
  },
  {
    postal_code: "21120",
    zip_name: "parkton, md",
  },
  {
    postal_code: "94585",
    zip_name: "suisun city, ca",
  },
  {
    postal_code: "93308",
    zip_name: "bakersfield, ca",
  },
  {
    postal_code: "49849",
    zip_name: "ishpeming, mi",
  },
  {
    postal_code: "44683",
    zip_name: "uhrichsville, oh",
  },
  {
    postal_code: "1945",
    zip_name: "marblehead, ma",
  },
  {
    postal_code: "29379",
    zip_name: "union, sc",
  },
  {
    postal_code: "36108",
    zip_name: "montgomery, al",
  },
  {
    postal_code: "52544",
    zip_name: "centerville, ia",
  },
  {
    postal_code: "40013",
    zip_name: "coxs creek, ky",
  },
  {
    postal_code: "11795",
    zip_name: "west islip, ny",
  },
  {
    postal_code: "33843",
    zip_name: "frostproof, fl",
  },
  {
    postal_code: "25143",
    zip_name: "nitro, wv",
  },
  {
    postal_code: "19342",
    zip_name: "glen mills, pa",
  },
  {
    postal_code: "99204",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "22039",
    zip_name: "fairfax station, va",
  },
  {
    postal_code: "60555",
    zip_name: "warrenville, il",
  },
  {
    postal_code: "43125",
    zip_name: "groveport, oh",
  },
  {
    postal_code: "37331",
    zip_name: "etowah, tn",
  },
  {
    postal_code: "82718",
    zip_name: "gillette, wy",
  },
  {
    postal_code: "32091",
    zip_name: "starke, fl",
  },
  {
    postal_code: "22206",
    zip_name: "arlington, va",
  },
  {
    postal_code: "80440",
    zip_name: "fairplay, co",
  },
  {
    postal_code: "75474",
    zip_name: "quinlan, tx",
  },
  {
    postal_code: "38606",
    zip_name: "batesville, ms",
  },
  {
    postal_code: "27954",
    zip_name: "manteo, nc",
  },
  {
    postal_code: "63645",
    zip_name: "fredericktown, mo",
  },
  {
    postal_code: "78751",
    zip_name: "austin, tx",
  },
  {
    postal_code: "28166",
    zip_name: "troutman, nc",
  },
  {
    postal_code: "37902",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "33440",
    zip_name: "clewiston, fl",
  },
  {
    postal_code: "79107",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "33876",
    zip_name: "sebring, fl",
  },
  {
    postal_code: "6351",
    zip_name: "jewett city, ct",
  },
  {
    postal_code: "98251",
    zip_name: "gold bar, wa",
  },
  {
    postal_code: "94596",
    zip_name: "walnut creek, ca",
  },
  {
    postal_code: "22041",
    zip_name: "falls church, va",
  },
  {
    postal_code: "56537",
    zip_name: "fergus falls, mn",
  },
  {
    postal_code: "77480",
    zip_name: "sweeny, tx",
  },
  {
    postal_code: "72634",
    zip_name: "flippin, ar",
  },
  {
    postal_code: "55803",
    zip_name: "duluth, mn",
  },
  {
    postal_code: "28531",
    zip_name: "harkers island, nc",
  },
  {
    postal_code: "45106",
    zip_name: "bethel, oh",
  },
  {
    postal_code: "12972",
    zip_name: "peru, ny",
  },
  {
    postal_code: "79714",
    zip_name: "andrews, tx",
  },
  {
    postal_code: "98682",
    zip_name: "vancouver, wa",
  },
  {
    postal_code: "72632",
    zip_name: "eureka springs, ar",
  },
  {
    postal_code: "45680",
    zip_name: "south point, oh",
  },
  {
    postal_code: "43617",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "21014",
    zip_name: "bel air, md",
  },
  {
    postal_code: "75076",
    zip_name: "pottsboro, tx",
  },
  {
    postal_code: "66204",
    zip_name: "overland park, ks",
  },
  {
    postal_code: "27045",
    zip_name: "rural hall, nc",
  },
  {
    postal_code: "78617",
    zip_name: "del valle, tx",
  },
  {
    postal_code: "81226",
    zip_name: "florence, co",
  },
  {
    postal_code: "38340",
    zip_name: "henderson, tn",
  },
  {
    postal_code: "5819",
    zip_name: "saint johnsbury, vt",
  },
  {
    postal_code: "81005",
    zip_name: "pueblo, co",
  },
  {
    postal_code: "78245",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "78626",
    zip_name: "georgetown, tx",
  },
  {
    postal_code: "66218",
    zip_name: "shawnee, ks",
  },
  {
    postal_code: "32127",
    zip_name: "port orange, fl",
  },
  {
    postal_code: "28555",
    zip_name: "maysville, nc",
  },
  {
    postal_code: "85017",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "11704",
    zip_name: "west babylon, ny",
  },
  {
    postal_code: "76044",
    zip_name: "godley, tx",
  },
  {
    postal_code: "92252",
    zip_name: "joshua tree, ca",
  },
  {
    postal_code: "85145",
    zip_name: "red rock, az",
  },
  {
    postal_code: "90037",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "63703",
    zip_name: "cape girardeau, mo",
  },
  {
    postal_code: "6241",
    zip_name: "dayville, ct",
  },
  {
    postal_code: "46148",
    zip_name: "knightstown, in",
  },
  {
    postal_code: "22443",
    zip_name: "colonial beach, va",
  },
  {
    postal_code: "54935",
    zip_name: "fond du lac, wi",
  },
  {
    postal_code: "46121",
    zip_name: "coatesville, in",
  },
  {
    postal_code: "30025",
    zip_name: "social circle, ga",
  },
  {
    postal_code: "44833",
    zip_name: "galion, oh",
  },
  {
    postal_code: "94121",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "24333",
    zip_name: "galax, va",
  },
  {
    postal_code: "62896",
    zip_name: "west frankfort, il",
  },
  {
    postal_code: "77362",
    zip_name: "pinehurst, tx",
  },
  {
    postal_code: "54615",
    zip_name: "black river falls, wi",
  },
  {
    postal_code: "70726",
    zip_name: "denham springs, la",
  },
  {
    postal_code: "6883",
    zip_name: "weston, ct",
  },
  {
    postal_code: "62232",
    zip_name: "caseyville, il",
  },
  {
    postal_code: "44425",
    zip_name: "hubbard, oh",
  },
  {
    postal_code: "47356",
    zip_name: "middletown, in",
  },
  {
    postal_code: "91776",
    zip_name: "san gabriel, ca",
  },
  {
    postal_code: "11568",
    zip_name: "old westbury, ny",
  },
  {
    postal_code: "36111",
    zip_name: "montgomery, al",
  },
  {
    postal_code: "55805",
    zip_name: "duluth, mn",
  },
  {
    postal_code: "13207",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "40336",
    zip_name: "irvine, ky",
  },
  {
    postal_code: "11572",
    zip_name: "oceanside, ny",
  },
  {
    postal_code: "90680",
    zip_name: "stanton, ca",
  },
  {
    postal_code: "47834",
    zip_name: "brazil, in",
  },
  {
    postal_code: "92411",
    zip_name: "san bernardino, ca",
  },
  {
    postal_code: "69301",
    zip_name: "alliance, ne",
  },
  {
    postal_code: "13501",
    zip_name: "utica, ny",
  },
  {
    postal_code: "12514",
    zip_name: "clinton corners, ny",
  },
  {
    postal_code: "71953",
    zip_name: "mena, ar",
  },
  {
    postal_code: "57747",
    zip_name: "hot springs, sd",
  },
  {
    postal_code: "20186",
    zip_name: "warrenton, va",
  },
  {
    postal_code: "90067",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "91108",
    zip_name: "san marino, ca",
  },
  {
    postal_code: "63389",
    zip_name: "winfield, mo",
  },
  {
    postal_code: "49202",
    zip_name: "jackson, mi",
  },
  {
    postal_code: "78566",
    zip_name: "los fresnos, tx",
  },
  {
    postal_code: "48854",
    zip_name: "mason, mi",
  },
  {
    postal_code: "11554",
    zip_name: "east meadow, ny",
  },
  {
    postal_code: "43410",
    zip_name: "clyde, oh",
  },
  {
    postal_code: "77028",
    zip_name: "houston, tx",
  },
  {
    postal_code: "43074",
    zip_name: "sunbury, oh",
  },
  {
    postal_code: "27856",
    zip_name: "nashville, nc",
  },
  {
    postal_code: "75460",
    zip_name: "paris, tx",
  },
  {
    postal_code: "95953",
    zip_name: "live oak, ca",
  },
  {
    postal_code: "18407",
    zip_name: "carbondale, pa",
  },
  {
    postal_code: "91030",
    zip_name: "south pasadena, ca",
  },
  {
    postal_code: "33548",
    zip_name: "lutz, fl",
  },
  {
    postal_code: "29935",
    zip_name: "port royal, sc",
  },
  {
    postal_code: "95366",
    zip_name: "ripon, ca",
  },
  {
    postal_code: "25560",
    zip_name: "scott depot, wv",
  },
  {
    postal_code: "77093",
    zip_name: "houston, tx",
  },
  {
    postal_code: "94117",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "94708",
    zip_name: "berkeley, ca",
  },
  {
    postal_code: "91768",
    zip_name: "pomona, ca",
  },
  {
    postal_code: "56468",
    zip_name: "nisswa, mn",
  },
  {
    postal_code: "68059",
    zip_name: "springfield, ne",
  },
  {
    postal_code: "53558",
    zip_name: "mcfarland, wi",
  },
  {
    postal_code: "2859",
    zip_name: "pascoag, ri",
  },
  {
    postal_code: "78063",
    zip_name: "pipe creek, tx",
  },
  {
    postal_code: "25510",
    zip_name: "culloden, wv",
  },
  {
    postal_code: "13209",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "45002",
    zip_name: "cleves, oh",
  },
  {
    postal_code: "60173",
    zip_name: "schaumburg, il",
  },
  {
    postal_code: "84004",
    zip_name: "alpine, ut",
  },
  {
    postal_code: "8016",
    zip_name: "burlington, nj",
  },
  {
    postal_code: "21216",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "61254",
    zip_name: "geneseo, il",
  },
  {
    postal_code: "49721",
    zip_name: "cheboygan, mi",
  },
  {
    postal_code: "90011",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "35125",
    zip_name: "pell city, al",
  },
  {
    postal_code: "2346",
    zip_name: "middleboro, ma",
  },
  {
    postal_code: "19422",
    zip_name: "blue bell, pa",
  },
  {
    postal_code: "26726",
    zip_name: "keyser, wv",
  },
  {
    postal_code: "14871",
    zip_name: "pine city, ny",
  },
  {
    postal_code: "24078",
    zip_name: "collinsville, va",
  },
  {
    postal_code: "2771",
    zip_name: "seekonk, ma",
  },
  {
    postal_code: "54848",
    zip_name: "ladysmith, wi",
  },
  {
    postal_code: "48043",
    zip_name: "mount clemens, mi",
  },
  {
    postal_code: "77360",
    zip_name: "onalaska, tx",
  },
  {
    postal_code: "23842",
    zip_name: "disputanta, va",
  },
  {
    postal_code: "40965",
    zip_name: "middlesboro, ky",
  },
  {
    postal_code: "75751",
    zip_name: "athens, tx",
  },
  {
    postal_code: "29325",
    zip_name: "clinton, sc",
  },
  {
    postal_code: "78746",
    zip_name: "austin, tx",
  },
  {
    postal_code: "66202",
    zip_name: "mission, ks",
  },
  {
    postal_code: "36206",
    zip_name: "anniston, al",
  },
  {
    postal_code: "76050",
    zip_name: "grandview, tx",
  },
  {
    postal_code: "11777",
    zip_name: "port jefferson, ny",
  },
  {
    postal_code: "10032",
    zip_name: "new york, ny",
  },
  {
    postal_code: "95631",
    zip_name: "foresthill, ca",
  },
  {
    postal_code: "16002",
    zip_name: "butler, pa",
  },
  {
    postal_code: "6853",
    zip_name: "norwalk, ct",
  },
  {
    postal_code: "93306",
    zip_name: "bakersfield, ca",
  },
  {
    postal_code: "59741",
    zip_name: "manhattan, mt",
  },
  {
    postal_code: "60155",
    zip_name: "broadview, il",
  },
  {
    postal_code: "86325",
    zip_name: "cornville, az",
  },
  {
    postal_code: "32312",
    zip_name: "tallahassee, fl",
  },
  {
    postal_code: "20037",
    zip_name: "washington, dc",
  },
  {
    postal_code: "70437",
    zip_name: "folsom, la",
  },
  {
    postal_code: "90041",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "22151",
    zip_name: "springfield, va",
  },
  {
    postal_code: "20732",
    zip_name: "chesapeake beach, md",
  },
  {
    postal_code: "1085",
    zip_name: "westfield, ma",
  },
  {
    postal_code: "12455",
    zip_name: "margaretville, ny",
  },
  {
    postal_code: "19138",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "29438",
    zip_name: "edisto island, sc",
  },
  {
    postal_code: "11778",
    zip_name: "rocky point, ny",
  },
  {
    postal_code: "68512",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "41035",
    zip_name: "dry ridge, ky",
  },
  {
    postal_code: "6418",
    zip_name: "derby, ct",
  },
  {
    postal_code: "80235",
    zip_name: "denver, co",
  },
  {
    postal_code: "70420",
    zip_name: "abita springs, la",
  },
  {
    postal_code: "87547",
    zip_name: "white rock, nm",
  },
  {
    postal_code: "33815",
    zip_name: "lakeland, fl",
  },
  {
    postal_code: "32311",
    zip_name: "tallahassee, fl",
  },
  {
    postal_code: "60442",
    zip_name: "manhattan, il",
  },
  {
    postal_code: "3087",
    zip_name: "windham, nh",
  },
  {
    postal_code: "11422",
    zip_name: "rosedale, ny",
  },
  {
    postal_code: "76023",
    zip_name: "boyd, tx",
  },
  {
    postal_code: "89433",
    zip_name: "sun valley, nv",
  },
  {
    postal_code: "78701",
    zip_name: "austin, tx",
  },
  {
    postal_code: "11766",
    zip_name: "mount sinai, ny",
  },
  {
    postal_code: "96766",
    zip_name: "lihue, hi",
  },
  {
    postal_code: "72002",
    zip_name: "alexander, ar",
  },
  {
    postal_code: "62246",
    zip_name: "greenville, il",
  },
  {
    postal_code: "71106",
    zip_name: "shreveport, la",
  },
  {
    postal_code: "56431",
    zip_name: "aitkin, mn",
  },
  {
    postal_code: "11545",
    zip_name: "glen head, ny",
  },
  {
    postal_code: "17044",
    zip_name: "lewistown, pa",
  },
  {
    postal_code: "7830",
    zip_name: "califon, nj",
  },
  {
    postal_code: "49346",
    zip_name: "stanwood, mi",
  },
  {
    postal_code: "11213",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "95320",
    zip_name: "escalon, ca",
  },
  {
    postal_code: "95033",
    zip_name: "los gatos, ca",
  },
  {
    postal_code: "95117",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "93117",
    zip_name: "goleta, ca",
  },
  {
    postal_code: "92703",
    zip_name: "santa ana, ca",
  },
  {
    postal_code: "2169",
    zip_name: "quincy, ma",
  },
  {
    postal_code: "22046",
    zip_name: "falls church, va",
  },
  {
    postal_code: "62448",
    zip_name: "newton, il",
  },
  {
    postal_code: "28731",
    zip_name: "flat rock, nc",
  },
  {
    postal_code: "15145",
    zip_name: "turtle creek, pa",
  },
  {
    postal_code: "24504",
    zip_name: "lynchburg, va",
  },
  {
    postal_code: "90018",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "12920",
    zip_name: "chateaugay, ny",
  },
  {
    postal_code: "3051",
    zip_name: "hudson, nh",
  },
  {
    postal_code: "94547",
    zip_name: "hercules, ca",
  },
  {
    postal_code: "46356",
    zip_name: "lowell, in",
  },
  {
    postal_code: "66109",
    zip_name: "kansas city, ks",
  },
  {
    postal_code: "48144",
    zip_name: "lambertville, mi",
  },
  {
    postal_code: "19130",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "15501",
    zip_name: "somerset, pa",
  },
  {
    postal_code: "13039",
    zip_name: "cicero, ny",
  },
  {
    postal_code: "6098",
    zip_name: "winsted, ct",
  },
  {
    postal_code: "55405",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "77418",
    zip_name: "bellville, tx",
  },
  {
    postal_code: "12528",
    zip_name: "highland, ny",
  },
  {
    postal_code: "80615",
    zip_name: "eaton, co",
  },
  {
    postal_code: "50801",
    zip_name: "creston, ia",
  },
  {
    postal_code: "33635",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "32234",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "11223",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "6759",
    zip_name: "litchfield, ct",
  },
  {
    postal_code: "80116",
    zip_name: "franktown, co",
  },
  {
    postal_code: "90405",
    zip_name: "santa monica, ca",
  },
  {
    postal_code: "28217",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "60163",
    zip_name: "berkeley, il",
  },
  {
    postal_code: "32333",
    zip_name: "havana, fl",
  },
  {
    postal_code: "50112",
    zip_name: "grinnell, ia",
  },
  {
    postal_code: "12143",
    zip_name: "ravena, ny",
  },
  {
    postal_code: "76230",
    zip_name: "bowie, tx",
  },
  {
    postal_code: "61101",
    zip_name: "rockford, il",
  },
  {
    postal_code: "36502",
    zip_name: "atmore, al",
  },
  {
    postal_code: "11790",
    zip_name: "stony brook, ny",
  },
  {
    postal_code: "75979",
    zip_name: "woodville, tx",
  },
  {
    postal_code: "91007",
    zip_name: "arcadia, ca",
  },
  {
    postal_code: "68949",
    zip_name: "holdrege, ne",
  },
  {
    postal_code: "20132",
    zip_name: "purcellville, va",
  },
  {
    postal_code: "33070",
    zip_name: "tavernier, fl",
  },
  {
    postal_code: "78728",
    zip_name: "austin, tx",
  },
  {
    postal_code: "63117",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "55130",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "37617",
    zip_name: "blountville, tn",
  },
  {
    postal_code: "28697",
    zip_name: "wilkesboro, nc",
  },
  {
    postal_code: "11552",
    zip_name: "west hempstead, ny",
  },
  {
    postal_code: "63127",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "25430",
    zip_name: "kearneysville, wv",
  },
  {
    postal_code: "2703",
    zip_name: "attleboro, ma",
  },
  {
    postal_code: "8012",
    zip_name: "blackwood, nj",
  },
  {
    postal_code: "28021",
    zip_name: "cherryville, nc",
  },
  {
    postal_code: "91935",
    zip_name: "jamul, ca",
  },
  {
    postal_code: "94949",
    zip_name: "novato, ca",
  },
  {
    postal_code: "28748",
    zip_name: "leicester, nc",
  },
  {
    postal_code: "21231",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "70535",
    zip_name: "eunice, la",
  },
  {
    postal_code: "19127",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "66012",
    zip_name: "bonner springs, ks",
  },
  {
    postal_code: "19004",
    zip_name: "bala cynwyd, pa",
  },
  {
    postal_code: "15074",
    zip_name: "rochester, pa",
  },
  {
    postal_code: "93313",
    zip_name: "bakersfield, ca",
  },
  {
    postal_code: "3253",
    zip_name: "meredith, nh",
  },
  {
    postal_code: "72209",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "2532",
    zip_name: "buzzards bay, ma",
  },
  {
    postal_code: "94556",
    zip_name: "moraga, ca",
  },
  {
    postal_code: "90034",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "17404",
    zip_name: "york, pa",
  },
  {
    postal_code: "13126",
    zip_name: "oswego, ny",
  },
  {
    postal_code: "22201",
    zip_name: "arlington, va",
  },
  {
    postal_code: "76273",
    zip_name: "whitesboro, tx",
  },
  {
    postal_code: "37186",
    zip_name: "westmoreland, tn",
  },
  {
    postal_code: "98014",
    zip_name: "carnation, wa",
  },
  {
    postal_code: "11232",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "73108",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "94542",
    zip_name: "hayward, ca",
  },
  {
    postal_code: "29439",
    zip_name: "folly beach, sc",
  },
  {
    postal_code: "90031",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "37146",
    zip_name: "pleasant view, tn",
  },
  {
    postal_code: "38107",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "64112",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "12090",
    zip_name: "hoosick falls, ny",
  },
  {
    postal_code: "37686",
    zip_name: "piney flats, tn",
  },
  {
    postal_code: "29678",
    zip_name: "seneca, sc",
  },
  {
    postal_code: "15116",
    zip_name: "glenshaw, pa",
  },
  {
    postal_code: "11030",
    zip_name: "manhasset, ny",
  },
  {
    postal_code: "95451",
    zip_name: "kelseyville, ca",
  },
  {
    postal_code: "93612",
    zip_name: "clovis, ca",
  },
  {
    postal_code: "16201",
    zip_name: "kittanning, pa",
  },
  {
    postal_code: "20784",
    zip_name: "hyattsville, md",
  },
  {
    postal_code: "21012",
    zip_name: "arnold, md",
  },
  {
    postal_code: "20141",
    zip_name: "round hill, va",
  },
  {
    postal_code: "75148",
    zip_name: "malakoff, tx",
  },
  {
    postal_code: "28768",
    zip_name: "pisgah forest, nc",
  },
  {
    postal_code: "20720",
    zip_name: "bowie, md",
  },
  {
    postal_code: "23226",
    zip_name: "richmond, va",
  },
  {
    postal_code: "91202",
    zip_name: "glendale, ca",
  },
  {
    postal_code: "6475",
    zip_name: "old saybrook, ct",
  },
  {
    postal_code: "11953",
    zip_name: "middle island, ny",
  },
  {
    postal_code: "55413",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "44444",
    zip_name: "newton falls, oh",
  },
  {
    postal_code: "41014",
    zip_name: "covington, ky",
  },
  {
    postal_code: "13624",
    zip_name: "clayton, ny",
  },
  {
    postal_code: "60461",
    zip_name: "olympia fields, il",
  },
  {
    postal_code: "85714",
    zip_name: "tucson, az",
  },
  {
    postal_code: "64056",
    zip_name: "independence, mo",
  },
  {
    postal_code: "59725",
    zip_name: "dillon, mt",
  },
  {
    postal_code: "93004",
    zip_name: "ventura, ca",
  },
  {
    postal_code: "39470",
    zip_name: "poplarville, ms",
  },
  {
    postal_code: "62881",
    zip_name: "salem, il",
  },
  {
    postal_code: "78957",
    zip_name: "smithville, tx",
  },
  {
    postal_code: "90019",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "91792",
    zip_name: "west covina, ca",
  },
  {
    postal_code: "45327",
    zip_name: "germantown, oh",
  },
  {
    postal_code: "76110",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "98101",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "71901",
    zip_name: "hot springs national park, ar",
  },
  {
    postal_code: "56470",
    zip_name: "park rapids, mn",
  },
  {
    postal_code: "91752",
    zip_name: "mira loma, ca",
  },
  {
    postal_code: "48629",
    zip_name: "houghton lake, mi",
  },
  {
    postal_code: "8852",
    zip_name: "monmouth junction, nj",
  },
  {
    postal_code: "8619",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "95948",
    zip_name: "gridley, ca",
  },
  {
    postal_code: "61102",
    zip_name: "rockford, il",
  },
  {
    postal_code: "44270",
    zip_name: "rittman, oh",
  },
  {
    postal_code: "83835",
    zip_name: "hayden, id",
  },
  {
    postal_code: "6706",
    zip_name: "waterbury, ct",
  },
  {
    postal_code: "91775",
    zip_name: "san gabriel, ca",
  },
  {
    postal_code: "23002",
    zip_name: "amelia court house, va",
  },
  {
    postal_code: "76011",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "27886",
    zip_name: "tarboro, nc",
  },
  {
    postal_code: "76102",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "60513",
    zip_name: "brookfield, il",
  },
  {
    postal_code: "15370",
    zip_name: "waynesburg, pa",
  },
  {
    postal_code: "60661",
    zip_name: "chicago, il",
  },
  {
    postal_code: "12210",
    zip_name: "albany, ny",
  },
  {
    postal_code: "46167",
    zip_name: "pittsboro, in",
  },
  {
    postal_code: "98403",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "33182",
    zip_name: "miami, fl",
  },
  {
    postal_code: "3110",
    zip_name: "bedford, nh",
  },
  {
    postal_code: "6029",
    zip_name: "ellington, ct",
  },
  {
    postal_code: "30564",
    zip_name: "murrayville, ga",
  },
  {
    postal_code: "27525",
    zip_name: "franklinton, nc",
  },
  {
    postal_code: "12184",
    zip_name: "valatie, ny",
  },
  {
    postal_code: "21208",
    zip_name: "pikesville, md",
  },
  {
    postal_code: "95969",
    zip_name: "paradise, ca",
  },
  {
    postal_code: "76426",
    zip_name: "bridgeport, tx",
  },
  {
    postal_code: "18444",
    zip_name: "moscow, pa",
  },
  {
    postal_code: "61270",
    zip_name: "morrison, il",
  },
  {
    postal_code: "98266",
    zip_name: "maple falls, wa",
  },
  {
    postal_code: "90403",
    zip_name: "santa monica, ca",
  },
  {
    postal_code: "36064",
    zip_name: "pike road, al",
  },
  {
    postal_code: "60480",
    zip_name: "willow springs, il",
  },
  {
    postal_code: "44408",
    zip_name: "columbiana, oh",
  },
  {
    postal_code: "61356",
    zip_name: "princeton, il",
  },
  {
    postal_code: "53538",
    zip_name: "fort atkinson, wi",
  },
  {
    postal_code: "27822",
    zip_name: "elm city, nc",
  },
  {
    postal_code: "19810",
    zip_name: "wilmington, de",
  },
  {
    postal_code: "6426",
    zip_name: "essex, ct",
  },
  {
    postal_code: "55070",
    zip_name: "saint francis, mn",
  },
  {
    postal_code: "30292",
    zip_name: "williamson, ga",
  },
  {
    postal_code: "60526",
    zip_name: "la grange park, il",
  },
  {
    postal_code: "13339",
    zip_name: "fort plain, ny",
  },
  {
    postal_code: "21409",
    zip_name: "annapolis, md",
  },
  {
    postal_code: "91740",
    zip_name: "glendora, ca",
  },
  {
    postal_code: "13783",
    zip_name: "hancock, ny",
  },
  {
    postal_code: "64701",
    zip_name: "harrisonville, mo",
  },
  {
    postal_code: "19006",
    zip_name: "huntingdon valley, pa",
  },
  {
    postal_code: "60416",
    zip_name: "coal city, il",
  },
  {
    postal_code: "11901",
    zip_name: "riverhead, ny",
  },
  {
    postal_code: "90240",
    zip_name: "downey, ca",
  },
  {
    postal_code: "77562",
    zip_name: "highlands, tx",
  },
  {
    postal_code: "6071",
    zip_name: "somers, ct",
  },
  {
    postal_code: "86404",
    zip_name: "lake havasu city, az",
  },
  {
    postal_code: "32025",
    zip_name: "lake city, fl",
  },
  {
    postal_code: "37757",
    zip_name: "jacksboro, tn",
  },
  {
    postal_code: "59718",
    zip_name: "bozeman, mt",
  },
  {
    postal_code: "32164",
    zip_name: "palm coast, fl",
  },
  {
    postal_code: "32218",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "33180",
    zip_name: "miami, fl",
  },
  {
    postal_code: "30228",
    zip_name: "hampton, ga",
  },
  {
    postal_code: "29588",
    zip_name: "myrtle beach, sc",
  },
  {
    postal_code: "96145",
    zip_name: "tahoe city, ca",
  },
  {
    postal_code: "77484",
    zip_name: "waller, tx",
  },
  {
    postal_code: "61354",
    zip_name: "peru, il",
  },
  {
    postal_code: "2914",
    zip_name: "east providence, ri",
  },
  {
    postal_code: "28097",
    zip_name: "locust, nc",
  },
  {
    postal_code: "43302",
    zip_name: "marion, oh",
  },
  {
    postal_code: "92325",
    zip_name: "crestline, ca",
  },
  {
    postal_code: "92356",
    zip_name: "lucerne valley, ca",
  },
  {
    postal_code: "38112",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "92801",
    zip_name: "anaheim, ca",
  },
  {
    postal_code: "39429",
    zip_name: "columbia, ms",
  },
  {
    postal_code: "2874",
    zip_name: "saunderstown, ri",
  },
  {
    postal_code: "92410",
    zip_name: "san bernardino, ca",
  },
  {
    postal_code: "90504",
    zip_name: "torrance, ca",
  },
  {
    postal_code: "40210",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "78211",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "64156",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "77422",
    zip_name: "brazoria, tx",
  },
  {
    postal_code: "38637",
    zip_name: "horn lake, ms",
  },
  {
    postal_code: "85390",
    zip_name: "wickenburg, az",
  },
  {
    postal_code: "90703",
    zip_name: "cerritos, ca",
  },
  {
    postal_code: "39501",
    zip_name: "gulfport, ms",
  },
  {
    postal_code: "29303",
    zip_name: "spartanburg, sc",
  },
  {
    postal_code: "70360",
    zip_name: "houma, la",
  },
  {
    postal_code: "16441",
    zip_name: "waterford, pa",
  },
  {
    postal_code: "12845",
    zip_name: "lake george, ny",
  },
  {
    postal_code: "34947",
    zip_name: "fort pierce, fl",
  },
  {
    postal_code: "27021",
    zip_name: "king, nc",
  },
  {
    postal_code: "62206",
    zip_name: "east saint louis, il",
  },
  {
    postal_code: "85087",
    zip_name: "new river, az",
  },
  {
    postal_code: "51401",
    zip_name: "carroll, ia",
  },
  {
    postal_code: "28655",
    zip_name: "morganton, nc",
  },
  {
    postal_code: "55808",
    zip_name: "duluth, mn",
  },
  {
    postal_code: "15632",
    zip_name: "export, pa",
  },
  {
    postal_code: "27576",
    zip_name: "selma, nc",
  },
  {
    postal_code: "95490",
    zip_name: "willits, ca",
  },
  {
    postal_code: "22960",
    zip_name: "orange, va",
  },
  {
    postal_code: "66048",
    zip_name: "leavenworth, ks",
  },
  {
    postal_code: "55355",
    zip_name: "litchfield, mn",
  },
  {
    postal_code: "11590",
    zip_name: "westbury, ny",
  },
  {
    postal_code: "11542",
    zip_name: "glen cove, ny",
  },
  {
    postal_code: "44017",
    zip_name: "berea, oh",
  },
  {
    postal_code: "42431",
    zip_name: "madisonville, ky",
  },
  {
    postal_code: "18336",
    zip_name: "matamoras, pa",
  },
  {
    postal_code: "94127",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "15005",
    zip_name: "baden, pa",
  },
  {
    postal_code: "33850",
    zip_name: "lake alfred, fl",
  },
  {
    postal_code: "28134",
    zip_name: "pineville, nc",
  },
  {
    postal_code: "43021",
    zip_name: "galena, oh",
  },
  {
    postal_code: "27569",
    zip_name: "princeton, nc",
  },
  {
    postal_code: "25311",
    zip_name: "charleston, wv",
  },
  {
    postal_code: "55437",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "63348",
    zip_name: "foristell, mo",
  },
  {
    postal_code: "64134",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "52627",
    zip_name: "fort madison, ia",
  },
  {
    postal_code: "92377",
    zip_name: "rialto, ca",
  },
  {
    postal_code: "27915",
    zip_name: "avon, nc",
  },
  {
    postal_code: "95665",
    zip_name: "pine grove, ca",
  },
  {
    postal_code: "27403",
    zip_name: "greensboro, nc",
  },
  {
    postal_code: "57785",
    zip_name: "sturgis, sd",
  },
  {
    postal_code: "70578",
    zip_name: "rayne, la",
  },
  {
    postal_code: "44667",
    zip_name: "orrville, oh",
  },
  {
    postal_code: "43028",
    zip_name: "howard, oh",
  },
  {
    postal_code: "56003",
    zip_name: "mankato, mn",
  },
  {
    postal_code: "27288",
    zip_name: "eden, nc",
  },
  {
    postal_code: "87532",
    zip_name: "espanola, nm",
  },
  {
    postal_code: "30546",
    zip_name: "hiawassee, ga",
  },
  {
    postal_code: "60534",
    zip_name: "lyons, il",
  },
  {
    postal_code: "70062",
    zip_name: "kenner, la",
  },
  {
    postal_code: "43566",
    zip_name: "waterville, oh",
  },
  {
    postal_code: "45383",
    zip_name: "west milton, oh",
  },
  {
    postal_code: "61953",
    zip_name: "tuscola, il",
  },
  {
    postal_code: "45891",
    zip_name: "van wert, oh",
  },
  {
    postal_code: "93108",
    zip_name: "santa barbara, ca",
  },
  {
    postal_code: "85031",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "19010",
    zip_name: "bryn mawr, pa",
  },
  {
    postal_code: "10451",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "2540",
    zip_name: "falmouth, ma",
  },
  {
    postal_code: "78731",
    zip_name: "austin, tx",
  },
  {
    postal_code: "35096",
    zip_name: "lincoln, al",
  },
  {
    postal_code: "12572",
    zip_name: "rhinebeck, ny",
  },
  {
    postal_code: "6403",
    zip_name: "beacon falls, ct",
  },
  {
    postal_code: "98604",
    zip_name: "battle ground, wa",
  },
  {
    postal_code: "50273",
    zip_name: "winterset, ia",
  },
  {
    postal_code: "77504",
    zip_name: "pasadena, tx",
  },
  {
    postal_code: "95122",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "20018",
    zip_name: "washington, dc",
  },
  {
    postal_code: "25309",
    zip_name: "south charleston, wv",
  },
  {
    postal_code: "43935",
    zip_name: "martins ferry, oh",
  },
  {
    postal_code: "44273",
    zip_name: "seville, oh",
  },
  {
    postal_code: "33158",
    zip_name: "miami, fl",
  },
  {
    postal_code: "44614",
    zip_name: "canal fulton, oh",
  },
  {
    postal_code: "16127",
    zip_name: "grove city, pa",
  },
  {
    postal_code: "8690",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "97333",
    zip_name: "corvallis, or",
  },
  {
    postal_code: "10005",
    zip_name: "new york, ny",
  },
  {
    postal_code: "30553",
    zip_name: "lavonia, ga",
  },
  {
    postal_code: "78676",
    zip_name: "wimberley, tx",
  },
  {
    postal_code: "21001",
    zip_name: "aberdeen, md",
  },
  {
    postal_code: "80542",
    zip_name: "mead, co",
  },
  {
    postal_code: "11354",
    zip_name: "flushing, ny",
  },
  {
    postal_code: "75180",
    zip_name: "balch springs, tx",
  },
  {
    postal_code: "93309",
    zip_name: "bakersfield, ca",
  },
  {
    postal_code: "6712",
    zip_name: "prospect, ct",
  },
  {
    postal_code: "95110",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "34420",
    zip_name: "belleview, fl",
  },
  {
    postal_code: "25271",
    zip_name: "ripley, wv",
  },
  {
    postal_code: "19401",
    zip_name: "norristown, pa",
  },
  {
    postal_code: "27607",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "46225",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "53965",
    zip_name: "wisconsin dells, wi",
  },
  {
    postal_code: "46231",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "34436",
    zip_name: "floral city, fl",
  },
  {
    postal_code: "43231",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "33839",
    zip_name: "eagle lake, fl",
  },
  {
    postal_code: "61603",
    zip_name: "peoria, il",
  },
  {
    postal_code: "92372",
    zip_name: "pinon hills, ca",
  },
  {
    postal_code: "11726",
    zip_name: "copiague, ny",
  },
  {
    postal_code: "90502",
    zip_name: "torrance, ca",
  },
  {
    postal_code: "76015",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "5602",
    zip_name: "montpelier, vt",
  },
  {
    postal_code: "56304",
    zip_name: "saint cloud, mn",
  },
  {
    postal_code: "29697",
    zip_name: "williamston, sc",
  },
  {
    postal_code: "19125",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "42211",
    zip_name: "cadiz, ky",
  },
  {
    postal_code: "55616",
    zip_name: "two harbors, mn",
  },
  {
    postal_code: "2633",
    zip_name: "chatham, ma",
  },
  {
    postal_code: "21620",
    zip_name: "chestertown, md",
  },
  {
    postal_code: "34481",
    zip_name: "ocala, fl",
  },
  {
    postal_code: "12846",
    zip_name: "lake luzerne, ny",
  },
  {
    postal_code: "76550",
    zip_name: "lampasas, tx",
  },
  {
    postal_code: "46319",
    zip_name: "griffith, in",
  },
  {
    postal_code: "40514",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "4090",
    zip_name: "wells, me",
  },
  {
    postal_code: "64060",
    zip_name: "kearney, mo",
  },
  {
    postal_code: "70032",
    zip_name: "arabi, la",
  },
  {
    postal_code: "77474",
    zip_name: "sealy, tx",
  },
  {
    postal_code: "45506",
    zip_name: "springfield, oh",
  },
  {
    postal_code: "77094",
    zip_name: "houston, tx",
  },
  {
    postal_code: "44657",
    zip_name: "minerva, oh",
  },
  {
    postal_code: "44811",
    zip_name: "bellevue, oh",
  },
  {
    postal_code: "21133",
    zip_name: "randallstown, md",
  },
  {
    postal_code: "11360",
    zip_name: "bayside, ny",
  },
  {
    postal_code: "13206",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "70121",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "79121",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "98166",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "78009",
    zip_name: "castroville, tx",
  },
  {
    postal_code: "86322",
    zip_name: "camp verde, az",
  },
  {
    postal_code: "60160",
    zip_name: "melrose park, il",
  },
  {
    postal_code: "95824",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "12188",
    zip_name: "waterford, ny",
  },
  {
    postal_code: "27565",
    zip_name: "oxford, nc",
  },
  {
    postal_code: "12561",
    zip_name: "new paltz, ny",
  },
  {
    postal_code: "6108",
    zip_name: "east hartford, ct",
  },
  {
    postal_code: "20905",
    zip_name: "silver spring, md",
  },
  {
    postal_code: "98273",
    zip_name: "mount vernon, wa",
  },
  {
    postal_code: "38019",
    zip_name: "covington, tn",
  },
  {
    postal_code: "91208",
    zip_name: "glendale, ca",
  },
  {
    postal_code: "45169",
    zip_name: "sabina, oh",
  },
  {
    postal_code: "12053",
    zip_name: "delanson, ny",
  },
  {
    postal_code: "55371",
    zip_name: "princeton, mn",
  },
  {
    postal_code: "99201",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "53536",
    zip_name: "evansville, wi",
  },
  {
    postal_code: "61019",
    zip_name: "davis, il",
  },
  {
    postal_code: "16511",
    zip_name: "erie, pa",
  },
  {
    postal_code: "20746",
    zip_name: "suitland, md",
  },
  {
    postal_code: "33946",
    zip_name: "placida, fl",
  },
  {
    postal_code: "94619",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "28480",
    zip_name: "wrightsville beach, nc",
  },
  {
    postal_code: "60458",
    zip_name: "justice, il",
  },
  {
    postal_code: "43056",
    zip_name: "heath, oh",
  },
  {
    postal_code: "12832",
    zip_name: "granville, ny",
  },
  {
    postal_code: "66083",
    zip_name: "spring hill, ks",
  },
  {
    postal_code: "3570",
    zip_name: "berlin, nh",
  },
  {
    postal_code: "6480",
    zip_name: "portland, ct",
  },
  {
    postal_code: "30635",
    zip_name: "elberton, ga",
  },
  {
    postal_code: "39120",
    zip_name: "natchez, ms",
  },
  {
    postal_code: "37737",
    zip_name: "friendsville, tn",
  },
  {
    postal_code: "92841",
    zip_name: "garden grove, ca",
  },
  {
    postal_code: "54801",
    zip_name: "spooner, wi",
  },
  {
    postal_code: "60112",
    zip_name: "cortland, il",
  },
  {
    postal_code: "72210",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "97381",
    zip_name: "silverton, or",
  },
  {
    postal_code: "20876",
    zip_name: "germantown, md",
  },
  {
    postal_code: "22553",
    zip_name: "spotsylvania, va",
  },
  {
    postal_code: "95126",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "37642",
    zip_name: "church hill, tn",
  },
  {
    postal_code: "11764",
    zip_name: "miller place, ny",
  },
  {
    postal_code: "85350",
    zip_name: "somerton, az",
  },
  {
    postal_code: "22026",
    zip_name: "dumfries, va",
  },
  {
    postal_code: "93041",
    zip_name: "port hueneme, ca",
  },
  {
    postal_code: "99218",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "92625",
    zip_name: "corona del mar, ca",
  },
  {
    postal_code: "92139",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "60152",
    zip_name: "marengo, il",
  },
  {
    postal_code: "91207",
    zip_name: "glendale, ca",
  },
  {
    postal_code: "10035",
    zip_name: "new york, ny",
  },
  {
    postal_code: "92802",
    zip_name: "anaheim, ca",
  },
  {
    postal_code: "11932",
    zip_name: "bridgehampton, ny",
  },
  {
    postal_code: "56701",
    zip_name: "thief river falls, mn",
  },
  {
    postal_code: "28425",
    zip_name: "burgaw, nc",
  },
  {
    postal_code: "46952",
    zip_name: "marion, in",
  },
  {
    postal_code: "94306",
    zip_name: "palo alto, ca",
  },
  {
    postal_code: "11787",
    zip_name: "smithtown, ny",
  },
  {
    postal_code: "6371",
    zip_name: "old lyme, ct",
  },
  {
    postal_code: "54868",
    zip_name: "rice lake, wi",
  },
  {
    postal_code: "95425",
    zip_name: "cloverdale, ca",
  },
  {
    postal_code: "91101",
    zip_name: "pasadena, ca",
  },
  {
    postal_code: "95003",
    zip_name: "aptos, ca",
  },
  {
    postal_code: "34484",
    zip_name: "oxford, fl",
  },
  {
    postal_code: "2301",
    zip_name: "brockton, ma",
  },
  {
    postal_code: "38829",
    zip_name: "booneville, ms",
  },
  {
    postal_code: "63143",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "10029",
    zip_name: "new york, ny",
  },
  {
    postal_code: "98942",
    zip_name: "selah, wa",
  },
  {
    postal_code: "40023",
    zip_name: "fisherville, ky",
  },
  {
    postal_code: "8043",
    zip_name: "voorhees, nj",
  },
  {
    postal_code: "39540",
    zip_name: "diberville, ms",
  },
  {
    postal_code: "85131",
    zip_name: "eloy, az",
  },
  {
    postal_code: "11729",
    zip_name: "deer park, ny",
  },
  {
    postal_code: "20745",
    zip_name: "oxon hill, md",
  },
  {
    postal_code: "95357",
    zip_name: "modesto, ca",
  },
  {
    postal_code: "45050",
    zip_name: "monroe, oh",
  },
  {
    postal_code: "55445",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "77598",
    zip_name: "webster, tx",
  },
  {
    postal_code: "6498",
    zip_name: "westbrook, ct",
  },
  {
    postal_code: "15133",
    zip_name: "mckeesport, pa",
  },
  {
    postal_code: "64111",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "62812",
    zip_name: "benton, il",
  },
  {
    postal_code: "55319",
    zip_name: "clear lake, mn",
  },
  {
    postal_code: "93901",
    zip_name: "salinas, ca",
  },
  {
    postal_code: "25443",
    zip_name: "shepherdstown, wv",
  },
  {
    postal_code: "94131",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "31635",
    zip_name: "lakeland, ga",
  },
  {
    postal_code: "80816",
    zip_name: "florissant, co",
  },
  {
    postal_code: "76103",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "32060",
    zip_name: "live oak, fl",
  },
  {
    postal_code: "51442",
    zip_name: "denison, ia",
  },
  {
    postal_code: "30054",
    zip_name: "oxford, ga",
  },
  {
    postal_code: "6786",
    zip_name: "terryville, ct",
  },
  {
    postal_code: "37683",
    zip_name: "mountain city, tn",
  },
  {
    postal_code: "28472",
    zip_name: "whiteville, nc",
  },
  {
    postal_code: "16063",
    zip_name: "zelienople, pa",
  },
  {
    postal_code: "48473",
    zip_name: "swartz creek, mi",
  },
  {
    postal_code: "18403",
    zip_name: "archbald, pa",
  },
  {
    postal_code: "32754",
    zip_name: "mims, fl",
  },
  {
    postal_code: "37909",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "68037",
    zip_name: "louisville, ne",
  },
  {
    postal_code: "38237",
    zip_name: "martin, tn",
  },
  {
    postal_code: "94702",
    zip_name: "berkeley, ca",
  },
  {
    postal_code: "61607",
    zip_name: "peoria, il",
  },
  {
    postal_code: "75766",
    zip_name: "jacksonville, tx",
  },
  {
    postal_code: "15222",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "55806",
    zip_name: "duluth, mn",
  },
  {
    postal_code: "95815",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "45638",
    zip_name: "ironton, oh",
  },
  {
    postal_code: "1824",
    zip_name: "chelmsford, ma",
  },
  {
    postal_code: "28023",
    zip_name: "china grove, nc",
  },
  {
    postal_code: "48843",
    zip_name: "howell, mi",
  },
  {
    postal_code: "34480",
    zip_name: "ocala, fl",
  },
  {
    postal_code: "11701",
    zip_name: "amityville, ny",
  },
  {
    postal_code: "93420",
    zip_name: "arroyo grande, ca",
  },
  {
    postal_code: "68152",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "72732",
    zip_name: "garfield, ar",
  },
  {
    postal_code: "52802",
    zip_name: "davenport, ia",
  },
  {
    postal_code: "10038",
    zip_name: "new york, ny",
  },
  {
    postal_code: "27317",
    zip_name: "randleman, nc",
  },
  {
    postal_code: "53575",
    zip_name: "oregon, wi",
  },
  {
    postal_code: "53703",
    zip_name: "madison, wi",
  },
  {
    postal_code: "32309",
    zip_name: "tallahassee, fl",
  },
  {
    postal_code: "93210",
    zip_name: "coalinga, ca",
  },
  {
    postal_code: "99344",
    zip_name: "othello, wa",
  },
  {
    postal_code: "30742",
    zip_name: "fort oglethorpe, ga",
  },
  {
    postal_code: "93265",
    zip_name: "springville, ca",
  },
  {
    postal_code: "98077",
    zip_name: "woodinville, wa",
  },
  {
    postal_code: "29483",
    zip_name: "summerville, sc",
  },
  {
    postal_code: "56308",
    zip_name: "alexandria, mn",
  },
  {
    postal_code: "63070",
    zip_name: "pevely, mo",
  },
  {
    postal_code: "76645",
    zip_name: "hillsboro, tx",
  },
  {
    postal_code: "45107",
    zip_name: "blanchester, oh",
  },
  {
    postal_code: "10280",
    zip_name: "new york, ny",
  },
  {
    postal_code: "92706",
    zip_name: "santa ana, ca",
  },
  {
    postal_code: "44141",
    zip_name: "brecksville, oh",
  },
  {
    postal_code: "80403",
    zip_name: "golden, co",
  },
  {
    postal_code: "30607",
    zip_name: "athens, ga",
  },
  {
    postal_code: "64117",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "43440",
    zip_name: "lakeside marblehead, oh",
  },
  {
    postal_code: "78219",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "21146",
    zip_name: "severna park, md",
  },
  {
    postal_code: "90621",
    zip_name: "buena park, ca",
  },
  {
    postal_code: "37218",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "77575",
    zip_name: "liberty, tx",
  },
  {
    postal_code: "19966",
    zip_name: "millsboro, de",
  },
  {
    postal_code: "45750",
    zip_name: "marietta, oh",
  },
  {
    postal_code: "60064",
    zip_name: "north chicago, il",
  },
  {
    postal_code: "93614",
    zip_name: "coarsegold, ca",
  },
  {
    postal_code: "66208",
    zip_name: "prairie village, ks",
  },
  {
    postal_code: "92845",
    zip_name: "garden grove, ca",
  },
  {
    postal_code: "44106",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "92064",
    zip_name: "poway, ca",
  },
  {
    postal_code: "49935",
    zip_name: "iron river, mi",
  },
  {
    postal_code: "94580",
    zip_name: "san lorenzo, ca",
  },
  {
    postal_code: "44055",
    zip_name: "lorain, oh",
  },
  {
    postal_code: "2896",
    zip_name: "north smithfield, ri",
  },
  {
    postal_code: "39272",
    zip_name: "byram, ms",
  },
  {
    postal_code: "27932",
    zip_name: "edenton, nc",
  },
  {
    postal_code: "90242",
    zip_name: "downey, ca",
  },
  {
    postal_code: "32601",
    zip_name: "gainesville, fl",
  },
  {
    postal_code: "6610",
    zip_name: "bridgeport, ct",
  },
  {
    postal_code: "46016",
    zip_name: "anderson, in",
  },
  {
    postal_code: "34432",
    zip_name: "dunnellon, fl",
  },
  {
    postal_code: "90605",
    zip_name: "whittier, ca",
  },
  {
    postal_code: "11763",
    zip_name: "medford, ny",
  },
  {
    postal_code: "28204",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "44004",
    zip_name: "ashtabula, oh",
  },
  {
    postal_code: "98422",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "50220",
    zip_name: "perry, ia",
  },
  {
    postal_code: "78256",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "76541",
    zip_name: "killeen, tx",
  },
  {
    postal_code: "49038",
    zip_name: "coloma, mi",
  },
  {
    postal_code: "49829",
    zip_name: "escanaba, mi",
  },
  {
    postal_code: "8512",
    zip_name: "cranbury, nj",
  },
  {
    postal_code: "91010",
    zip_name: "duarte, ca",
  },
  {
    postal_code: "44303",
    zip_name: "akron, oh",
  },
  {
    postal_code: "19067",
    zip_name: "morrisville, pa",
  },
  {
    postal_code: "46303",
    zip_name: "cedar lake, in",
  },
  {
    postal_code: "89460",
    zip_name: "gardnerville, nv",
  },
  {
    postal_code: "21157",
    zip_name: "westminster, md",
  },
  {
    postal_code: "78586",
    zip_name: "san benito, tx",
  },
  {
    postal_code: "91746",
    zip_name: "la puente, ca",
  },
  {
    postal_code: "14870",
    zip_name: "painted post, ny",
  },
  {
    postal_code: "72631",
    zip_name: "eureka springs, ar",
  },
  {
    postal_code: "70047",
    zip_name: "destrehan, la",
  },
  {
    postal_code: "62966",
    zip_name: "murphysboro, il",
  },
  {
    postal_code: "15071",
    zip_name: "oakdale, pa",
  },
  {
    postal_code: "54923",
    zip_name: "berlin, wi",
  },
  {
    postal_code: "91377",
    zip_name: "oak park, ca",
  },
  {
    postal_code: "33513",
    zip_name: "bushnell, fl",
  },
  {
    postal_code: "90804",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "28774",
    zip_name: "sapphire, nc",
  },
  {
    postal_code: "94945",
    zip_name: "novato, ca",
  },
  {
    postal_code: "20740",
    zip_name: "college park, md",
  },
  {
    postal_code: "38326",
    zip_name: "counce, tn",
  },
  {
    postal_code: "91106",
    zip_name: "pasadena, ca",
  },
  {
    postal_code: "95203",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "28571",
    zip_name: "oriental, nc",
  },
  {
    postal_code: "14901",
    zip_name: "elmira, ny",
  },
  {
    postal_code: "75114",
    zip_name: "crandall, tx",
  },
  {
    postal_code: "80916",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "12953",
    zip_name: "malone, ny",
  },
  {
    postal_code: "60915",
    zip_name: "bradley, il",
  },
  {
    postal_code: "94947",
    zip_name: "novato, ca",
  },
  {
    postal_code: "75038",
    zip_name: "irving, tx",
  },
  {
    postal_code: "55302",
    zip_name: "annandale, mn",
  },
  {
    postal_code: "40067",
    zip_name: "simpsonville, ky",
  },
  {
    postal_code: "11208",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "93955",
    zip_name: "seaside, ca",
  },
  {
    postal_code: "92227",
    zip_name: "brawley, ca",
  },
  {
    postal_code: "91602",
    zip_name: "north hollywood, ca",
  },
  {
    postal_code: "31516",
    zip_name: "blackshear, ga",
  },
  {
    postal_code: "1915",
    zip_name: "beverly, ma",
  },
  {
    postal_code: "19144",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "96080",
    zip_name: "red bluff, ca",
  },
  {
    postal_code: "12025",
    zip_name: "broadalbin, ny",
  },
  {
    postal_code: "15126",
    zip_name: "imperial, pa",
  },
  {
    postal_code: "15066",
    zip_name: "new brighton, pa",
  },
  {
    postal_code: "34482",
    zip_name: "ocala, fl",
  },
  {
    postal_code: "3301",
    zip_name: "concord, nh",
  },
  {
    postal_code: "77067",
    zip_name: "houston, tx",
  },
  {
    postal_code: "52333",
    zip_name: "solon, ia",
  },
  {
    postal_code: "11782",
    zip_name: "sayville, ny",
  },
  {
    postal_code: "57783",
    zip_name: "spearfish, sd",
  },
  {
    postal_code: "49854",
    zip_name: "manistique, mi",
  },
  {
    postal_code: "93304",
    zip_name: "bakersfield, ca",
  },
  {
    postal_code: "80110",
    zip_name: "englewood, co",
  },
  {
    postal_code: "38104",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "15037",
    zip_name: "elizabeth, pa",
  },
  {
    postal_code: "96785",
    zip_name: "volcano, hi",
  },
  {
    postal_code: "15131",
    zip_name: "mckeesport, pa",
  },
  {
    postal_code: "20872",
    zip_name: "damascus, md",
  },
  {
    postal_code: "20910",
    zip_name: "silver spring, md",
  },
  {
    postal_code: "70529",
    zip_name: "duson, la",
  },
  {
    postal_code: "49801",
    zip_name: "iron mountain, mi",
  },
  {
    postal_code: "45434",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "30566",
    zip_name: "oakwood, ga",
  },
  {
    postal_code: "6076",
    zip_name: "stafford springs, ct",
  },
  {
    postal_code: "27023",
    zip_name: "lewisville, nc",
  },
  {
    postal_code: "61080",
    zip_name: "south beloit, il",
  },
  {
    postal_code: "75135",
    zip_name: "caddo mills, tx",
  },
  {
    postal_code: "55308",
    zip_name: "becker, mn",
  },
  {
    postal_code: "85377",
    zip_name: "carefree, az",
  },
  {
    postal_code: "39466",
    zip_name: "picayune, ms",
  },
  {
    postal_code: "43623",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "21921",
    zip_name: "elkton, md",
  },
  {
    postal_code: "14212",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "94609",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "93657",
    zip_name: "sanger, ca",
  },
  {
    postal_code: "18360",
    zip_name: "stroudsburg, pa",
  },
  {
    postal_code: "91504",
    zip_name: "burbank, ca",
  },
  {
    postal_code: "92657",
    zip_name: "newport coast, ca",
  },
  {
    postal_code: "45252",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "95368",
    zip_name: "salida, ca",
  },
  {
    postal_code: "39565",
    zip_name: "vancleave, ms",
  },
  {
    postal_code: "28449",
    zip_name: "kure beach, nc",
  },
  {
    postal_code: "77030",
    zip_name: "houston, tx",
  },
  {
    postal_code: "3104",
    zip_name: "manchester, nh",
  },
  {
    postal_code: "46107",
    zip_name: "beech grove, in",
  },
  {
    postal_code: "44117",
    zip_name: "euclid, oh",
  },
  {
    postal_code: "18426",
    zip_name: "greentown, pa",
  },
  {
    postal_code: "8857",
    zip_name: "old bridge, nj",
  },
  {
    postal_code: "43950",
    zip_name: "saint clairsville, oh",
  },
  {
    postal_code: "11570",
    zip_name: "rockville centre, ny",
  },
  {
    postal_code: "64138",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "94115",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "4330",
    zip_name: "augusta, me",
  },
  {
    postal_code: "45206",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "78237",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "43023",
    zip_name: "granville, oh",
  },
  {
    postal_code: "11005",
    zip_name: "floral park, ny",
  },
  {
    postal_code: "11413",
    zip_name: "springfield gardens, ny",
  },
  {
    postal_code: "75835",
    zip_name: "crockett, tx",
  },
  {
    postal_code: "75418",
    zip_name: "bonham, tx",
  },
  {
    postal_code: "21060",
    zip_name: "glen burnie, md",
  },
  {
    postal_code: "43545",
    zip_name: "napoleon, oh",
  },
  {
    postal_code: "95138",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "98310",
    zip_name: "bremerton, wa",
  },
  {
    postal_code: "93662",
    zip_name: "selma, ca",
  },
  {
    postal_code: "3431",
    zip_name: "keene, nh",
  },
  {
    postal_code: "64735",
    zip_name: "clinton, mo",
  },
  {
    postal_code: "80603",
    zip_name: "brighton, co",
  },
  {
    postal_code: "11566",
    zip_name: "merrick, ny",
  },
  {
    postal_code: "78721",
    zip_name: "austin, tx",
  },
  {
    postal_code: "64034",
    zip_name: "greenwood, mo",
  },
  {
    postal_code: "45895",
    zip_name: "wapakoneta, oh",
  },
  {
    postal_code: "60190",
    zip_name: "winfield, il",
  },
  {
    postal_code: "27537",
    zip_name: "henderson, nc",
  },
  {
    postal_code: "22182",
    zip_name: "vienna, va",
  },
  {
    postal_code: "13733",
    zip_name: "bainbridge, ny",
  },
  {
    postal_code: "95401",
    zip_name: "santa rosa, ca",
  },
  {
    postal_code: "32303",
    zip_name: "tallahassee, fl",
  },
  {
    postal_code: "77477",
    zip_name: "stafford, tx",
  },
  {
    postal_code: "29153",
    zip_name: "sumter, sc",
  },
  {
    postal_code: "11754",
    zip_name: "kings park, ny",
  },
  {
    postal_code: "90712",
    zip_name: "lakewood, ca",
  },
  {
    postal_code: "12570",
    zip_name: "poughquag, ny",
  },
  {
    postal_code: "33853",
    zip_name: "lake wales, fl",
  },
  {
    postal_code: "52641",
    zip_name: "mount pleasant, ia",
  },
  {
    postal_code: "19428",
    zip_name: "conshohocken, pa",
  },
  {
    postal_code: "37398",
    zip_name: "winchester, tn",
  },
  {
    postal_code: "77486",
    zip_name: "west columbia, tx",
  },
  {
    postal_code: "31302",
    zip_name: "bloomingdale, ga",
  },
  {
    postal_code: "77506",
    zip_name: "pasadena, tx",
  },
  {
    postal_code: "11598",
    zip_name: "woodmere, ny",
  },
  {
    postal_code: "93454",
    zip_name: "santa maria, ca",
  },
  {
    postal_code: "30646",
    zip_name: "hull, ga",
  },
  {
    postal_code: "45205",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "35180",
    zip_name: "warrior, al",
  },
  {
    postal_code: "1604",
    zip_name: "worcester, ma",
  },
  {
    postal_code: "45122",
    zip_name: "goshen, oh",
  },
  {
    postal_code: "94589",
    zip_name: "vallejo, ca",
  },
  {
    postal_code: "20855",
    zip_name: "derwood, md",
  },
  {
    postal_code: "95650",
    zip_name: "loomis, ca",
  },
  {
    postal_code: "77514",
    zip_name: "anahuac, tx",
  },
  {
    postal_code: "60176",
    zip_name: "schiller park, il",
  },
  {
    postal_code: "20010",
    zip_name: "washington, dc",
  },
  {
    postal_code: "84128",
    zip_name: "west valley city, ut",
  },
  {
    postal_code: "45315",
    zip_name: "clayton, oh",
  },
  {
    postal_code: "77038",
    zip_name: "houston, tx",
  },
  {
    postal_code: "27028",
    zip_name: "mocksville, nc",
  },
  {
    postal_code: "66210",
    zip_name: "overland park, ks",
  },
  {
    postal_code: "70526",
    zip_name: "crowley, la",
  },
  {
    postal_code: "76528",
    zip_name: "gatesville, tx",
  },
  {
    postal_code: "92274",
    zip_name: "thermal, ca",
  },
  {
    postal_code: "11942",
    zip_name: "east quogue, ny",
  },
  {
    postal_code: "78729",
    zip_name: "austin, tx",
  },
  {
    postal_code: "11779",
    zip_name: "ronkonkoma, ny",
  },
  {
    postal_code: "38478",
    zip_name: "pulaski, tn",
  },
  {
    postal_code: "65653",
    zip_name: "forsyth, mo",
  },
  {
    postal_code: "48160",
    zip_name: "milan, mi",
  },
  {
    postal_code: "60482",
    zip_name: "worth, il",
  },
  {
    postal_code: "77445",
    zip_name: "hempstead, tx",
  },
  {
    postal_code: "13069",
    zip_name: "fulton, ny",
  },
  {
    postal_code: "77437",
    zip_name: "el campo, tx",
  },
  {
    postal_code: "45409",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "57702",
    zip_name: "rapid city, sd",
  },
  {
    postal_code: "32826",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "94530",
    zip_name: "el cerrito, ca",
  },
  {
    postal_code: "55944",
    zip_name: "kasson, mn",
  },
  {
    postal_code: "78753",
    zip_name: "austin, tx",
  },
  {
    postal_code: "13208",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "13838",
    zip_name: "sidney, ny",
  },
  {
    postal_code: "63132",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "6357",
    zip_name: "niantic, ct",
  },
  {
    postal_code: "93030",
    zip_name: "oxnard, ca",
  },
  {
    postal_code: "28659",
    zip_name: "north wilkesboro, nc",
  },
  {
    postal_code: "20781",
    zip_name: "hyattsville, md",
  },
  {
    postal_code: "79336",
    zip_name: "levelland, tx",
  },
  {
    postal_code: "28016",
    zip_name: "bessemer city, nc",
  },
  {
    postal_code: "80751",
    zip_name: "sterling, co",
  },
  {
    postal_code: "19805",
    zip_name: "wilmington, de",
  },
  {
    postal_code: "21042",
    zip_name: "ellicott city, md",
  },
  {
    postal_code: "74110",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "48604",
    zip_name: "saginaw, mi",
  },
  {
    postal_code: "48130",
    zip_name: "dexter, mi",
  },
  {
    postal_code: "94066",
    zip_name: "san bruno, ca",
  },
  {
    postal_code: "91107",
    zip_name: "pasadena, ca",
  },
  {
    postal_code: "12043",
    zip_name: "cobleskill, ny",
  },
  {
    postal_code: "91763",
    zip_name: "montclair, ca",
  },
  {
    postal_code: "20695",
    zip_name: "white plains, md",
  },
  {
    postal_code: "39056",
    zip_name: "clinton, ms",
  },
  {
    postal_code: "22306",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "91103",
    zip_name: "pasadena, ca",
  },
  {
    postal_code: "10303",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "54720",
    zip_name: "altoona, wi",
  },
  {
    postal_code: "52101",
    zip_name: "decorah, ia",
  },
  {
    postal_code: "79410",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "40324",
    zip_name: "georgetown, ky",
  },
  {
    postal_code: "77494",
    zip_name: "katy, tx",
  },
  {
    postal_code: "33009",
    zip_name: "hallandale, fl",
  },
  {
    postal_code: "60622",
    zip_name: "chicago, il",
  },
  {
    postal_code: "69341",
    zip_name: "gering, ne",
  },
  {
    postal_code: "11776",
    zip_name: "port jefferson station, ny",
  },
  {
    postal_code: "72176",
    zip_name: "ward, ar",
  },
  {
    postal_code: "53551",
    zip_name: "lake mills, wi",
  },
  {
    postal_code: "85387",
    zip_name: "surprise, az",
  },
  {
    postal_code: "75143",
    zip_name: "kemp, tx",
  },
  {
    postal_code: "24210",
    zip_name: "abingdon, va",
  },
  {
    postal_code: "11374",
    zip_name: "rego park, ny",
  },
  {
    postal_code: "13502",
    zip_name: "utica, ny",
  },
  {
    postal_code: "85054",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "30263",
    zip_name: "newnan, ga",
  },
  {
    postal_code: "8232",
    zip_name: "pleasantville, nj",
  },
  {
    postal_code: "11735",
    zip_name: "farmingdale, ny",
  },
  {
    postal_code: "95404",
    zip_name: "santa rosa, ca",
  },
  {
    postal_code: "27803",
    zip_name: "rocky mount, nc",
  },
  {
    postal_code: "92352",
    zip_name: "lake arrowhead, ca",
  },
  {
    postal_code: "64772",
    zip_name: "nevada, mo",
  },
  {
    postal_code: "43812",
    zip_name: "coshocton, oh",
  },
  {
    postal_code: "51501",
    zip_name: "council bluffs, ia",
  },
  {
    postal_code: "1757",
    zip_name: "milford, ma",
  },
  {
    postal_code: "36022",
    zip_name: "deatsville, al",
  },
  {
    postal_code: "20650",
    zip_name: "leonardtown, md",
  },
  {
    postal_code: "48813",
    zip_name: "charlotte, mi",
  },
  {
    postal_code: "34448",
    zip_name: "homosassa, fl",
  },
  {
    postal_code: "11433",
    zip_name: "jamaica, ny",
  },
  {
    postal_code: "27320",
    zip_name: "reidsville, nc",
  },
  {
    postal_code: "10040",
    zip_name: "new york, ny",
  },
  {
    postal_code: "84737",
    zip_name: "hurricane, ut",
  },
  {
    postal_code: "80927",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "94103",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "13041",
    zip_name: "clay, ny",
  },
  {
    postal_code: "93702",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "94122",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "20716",
    zip_name: "bowie, md",
  },
  {
    postal_code: "40243",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "94577",
    zip_name: "san leandro, ca",
  },
  {
    postal_code: "70427",
    zip_name: "bogalusa, la",
  },
  {
    postal_code: "70006",
    zip_name: "metairie, la",
  },
  {
    postal_code: "2639",
    zip_name: "dennis port, ma",
  },
  {
    postal_code: "3063",
    zip_name: "nashua, nh",
  },
  {
    postal_code: "6903",
    zip_name: "stamford, ct",
  },
  {
    postal_code: "70037",
    zip_name: "belle chasse, la",
  },
  {
    postal_code: "45432",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "77530",
    zip_name: "channelview, tx",
  },
  {
    postal_code: "97216",
    zip_name: "portland, or",
  },
  {
    postal_code: "55063",
    zip_name: "pine city, mn",
  },
  {
    postal_code: "70445",
    zip_name: "lacombe, la",
  },
  {
    postal_code: "32163",
    zip_name: "the villages, fl",
  },
  {
    postal_code: "34266",
    zip_name: "arcadia, fl",
  },
  {
    postal_code: "76106",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "21784",
    zip_name: "sykesville, md",
  },
  {
    postal_code: "46544",
    zip_name: "mishawaka, in",
  },
  {
    postal_code: "37877",
    zip_name: "talbott, tn",
  },
  {
    postal_code: "33031",
    zip_name: "homestead, fl",
  },
  {
    postal_code: "64110",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "32327",
    zip_name: "crawfordville, fl",
  },
  {
    postal_code: "91724",
    zip_name: "covina, ca",
  },
  {
    postal_code: "66102",
    zip_name: "kansas city, ks",
  },
  {
    postal_code: "55107",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "90015",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "60624",
    zip_name: "chicago, il",
  },
  {
    postal_code: "22315",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "37385",
    zip_name: "tellico plains, tn",
  },
  {
    postal_code: "21817",
    zip_name: "crisfield, md",
  },
  {
    postal_code: "77331",
    zip_name: "coldspring, tx",
  },
  {
    postal_code: "45217",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "93036",
    zip_name: "oxnard, ca",
  },
  {
    postal_code: "25411",
    zip_name: "berkeley springs, wv",
  },
  {
    postal_code: "61866",
    zip_name: "rantoul, il",
  },
  {
    postal_code: "18966",
    zip_name: "southampton, pa",
  },
  {
    postal_code: "39562",
    zip_name: "moss point, ms",
  },
  {
    postal_code: "61364",
    zip_name: "streator, il",
  },
  {
    postal_code: "20613",
    zip_name: "brandywine, md",
  },
  {
    postal_code: "33634",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "8260",
    zip_name: "wildwood, nj",
  },
  {
    postal_code: "37363",
    zip_name: "ooltewah, tn",
  },
  {
    postal_code: "60484",
    zip_name: "university park, il",
  },
  {
    postal_code: "28721",
    zip_name: "clyde, nc",
  },
  {
    postal_code: "53563",
    zip_name: "milton, wi",
  },
  {
    postal_code: "2653",
    zip_name: "orleans, ma",
  },
  {
    postal_code: "59752",
    zip_name: "three forks, mt",
  },
  {
    postal_code: "75249",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "95713",
    zip_name: "colfax, ca",
  },
  {
    postal_code: "75116",
    zip_name: "duncanville, tx",
  },
  {
    postal_code: "33859",
    zip_name: "lake wales, fl",
  },
  {
    postal_code: "11204",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "20853",
    zip_name: "rockville, md",
  },
  {
    postal_code: "42134",
    zip_name: "franklin, ky",
  },
  {
    postal_code: "28673",
    zip_name: "sherrills ford, nc",
  },
  {
    postal_code: "37650",
    zip_name: "erwin, tn",
  },
  {
    postal_code: "79932",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "75773",
    zip_name: "mineola, tx",
  },
  {
    postal_code: "78064",
    zip_name: "pleasanton, tx",
  },
  {
    postal_code: "46069",
    zip_name: "sheridan, in",
  },
  {
    postal_code: "84108",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "85378",
    zip_name: "surprise, az",
  },
  {
    postal_code: "98444",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "62236",
    zip_name: "columbia, il",
  },
  {
    postal_code: "95062",
    zip_name: "santa cruz, ca",
  },
  {
    postal_code: "75707",
    zip_name: "tyler, tx",
  },
  {
    postal_code: "21666",
    zip_name: "stevensville, md",
  },
  {
    postal_code: "75147",
    zip_name: "mabank, tx",
  },
  {
    postal_code: "77565",
    zip_name: "kemah, tx",
  },
  {
    postal_code: "95448",
    zip_name: "healdsburg, ca",
  },
  {
    postal_code: "11581",
    zip_name: "valley stream, ny",
  },
  {
    postal_code: "22153",
    zip_name: "springfield, va",
  },
  {
    postal_code: "93923",
    zip_name: "carmel, ca",
  },
  {
    postal_code: "6424",
    zip_name: "east hampton, ct",
  },
  {
    postal_code: "21211",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "6762",
    zip_name: "middlebury, ct",
  },
  {
    postal_code: "61065",
    zip_name: "poplar grove, il",
  },
  {
    postal_code: "78644",
    zip_name: "lockhart, tx",
  },
  {
    postal_code: "94618",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "1970",
    zip_name: "salem, ma",
  },
  {
    postal_code: "22967",
    zip_name: "roseland, va",
  },
  {
    postal_code: "40118",
    zip_name: "fairdale, ky",
  },
  {
    postal_code: "91790",
    zip_name: "west covina, ca",
  },
  {
    postal_code: "14219",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "6067",
    zip_name: "rocky hill, ct",
  },
  {
    postal_code: "16506",
    zip_name: "erie, pa",
  },
  {
    postal_code: "15232",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "22066",
    zip_name: "great falls, va",
  },
  {
    postal_code: "21239",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "85544",
    zip_name: "pine, az",
  },
  {
    postal_code: "17113",
    zip_name: "harrisburg, pa",
  },
  {
    postal_code: "28681",
    zip_name: "taylorsville, nc",
  },
  {
    postal_code: "11357",
    zip_name: "whitestone, ny",
  },
  {
    postal_code: "15084",
    zip_name: "tarentum, pa",
  },
  {
    postal_code: "94519",
    zip_name: "concord, ca",
  },
  {
    postal_code: "37037",
    zip_name: "christiana, tn",
  },
  {
    postal_code: "90032",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "29449",
    zip_name: "hollywood, sc",
  },
  {
    postal_code: "53713",
    zip_name: "madison, wi",
  },
  {
    postal_code: "30329",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "90027",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "84093",
    zip_name: "sandy, ut",
  },
  {
    postal_code: "11224",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "78114",
    zip_name: "floresville, tx",
  },
  {
    postal_code: "61244",
    zip_name: "east moline, il",
  },
  {
    postal_code: "30297",
    zip_name: "forest park, ga",
  },
  {
    postal_code: "90505",
    zip_name: "torrance, ca",
  },
  {
    postal_code: "25313",
    zip_name: "charleston, wv",
  },
  {
    postal_code: "95818",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "24211",
    zip_name: "abingdon, va",
  },
  {
    postal_code: "77098",
    zip_name: "houston, tx",
  },
  {
    postal_code: "20871",
    zip_name: "clarksburg, md",
  },
  {
    postal_code: "78362",
    zip_name: "ingleside, tx",
  },
  {
    postal_code: "37879",
    zip_name: "tazewell, tn",
  },
  {
    postal_code: "37160",
    zip_name: "shelbyville, tn",
  },
  {
    postal_code: "19406",
    zip_name: "king of prussia, pa",
  },
  {
    postal_code: "62024",
    zip_name: "east alton, il",
  },
  {
    postal_code: "42754",
    zip_name: "leitchfield, ky",
  },
  {
    postal_code: "24605",
    zip_name: "bluefield, va",
  },
  {
    postal_code: "41031",
    zip_name: "cynthiana, ky",
  },
  {
    postal_code: "66206",
    zip_name: "leawood, ks",
  },
  {
    postal_code: "91206",
    zip_name: "glendale, ca",
  },
  {
    postal_code: "84337",
    zip_name: "tremonton, ut",
  },
  {
    postal_code: "55373",
    zip_name: "rockford, mn",
  },
  {
    postal_code: "37166",
    zip_name: "smithville, tn",
  },
  {
    postal_code: "94564",
    zip_name: "pinole, ca",
  },
  {
    postal_code: "15209",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "92251",
    zip_name: "imperial, ca",
  },
  {
    postal_code: "11733",
    zip_name: "east setauket, ny",
  },
  {
    postal_code: "48647",
    zip_name: "mio, mi",
  },
  {
    postal_code: "94563",
    zip_name: "orinda, ca",
  },
  {
    postal_code: "90008",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "95128",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "36576",
    zip_name: "silverhill, al",
  },
  {
    postal_code: "45885",
    zip_name: "saint marys, oh",
  },
  {
    postal_code: "40741",
    zip_name: "london, ky",
  },
  {
    postal_code: "36054",
    zip_name: "millbrook, al",
  },
  {
    postal_code: "75244",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "29322",
    zip_name: "campobello, sc",
  },
  {
    postal_code: "37885",
    zip_name: "vonore, tn",
  },
  {
    postal_code: "63040",
    zip_name: "wildwood, mo",
  },
  {
    postal_code: "24588",
    zip_name: "rustburg, va",
  },
  {
    postal_code: "19135",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "18960",
    zip_name: "sellersville, pa",
  },
  {
    postal_code: "78639",
    zip_name: "kingsland, tx",
  },
  {
    postal_code: "94566",
    zip_name: "pleasanton, ca",
  },
  {
    postal_code: "61068",
    zip_name: "rochelle, il",
  },
  {
    postal_code: "35045",
    zip_name: "clanton, al",
  },
  {
    postal_code: "27006",
    zip_name: "advance, nc",
  },
  {
    postal_code: "75173",
    zip_name: "nevada, tx",
  },
  {
    postal_code: "18512",
    zip_name: "scranton, pa",
  },
  {
    postal_code: "67401",
    zip_name: "salina, ks",
  },
  {
    postal_code: "13066",
    zip_name: "fayetteville, ny",
  },
  {
    postal_code: "29323",
    zip_name: "chesnee, sc",
  },
  {
    postal_code: "6260",
    zip_name: "putnam, ct",
  },
  {
    postal_code: "70517",
    zip_name: "breaux bridge, la",
  },
  {
    postal_code: "64116",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "48420",
    zip_name: "clio, mi",
  },
  {
    postal_code: "8205",
    zip_name: "absecon, nj",
  },
  {
    postal_code: "28206",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "94610",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "54022",
    zip_name: "river falls, wi",
  },
  {
    postal_code: "20902",
    zip_name: "silver spring, md",
  },
  {
    postal_code: "64081",
    zip_name: "lees summit, mo",
  },
  {
    postal_code: "19405",
    zip_name: "bridgeport, pa",
  },
  {
    postal_code: "32117",
    zip_name: "daytona beach, fl",
  },
  {
    postal_code: "6074",
    zip_name: "south windsor, ct",
  },
  {
    postal_code: "77075",
    zip_name: "houston, tx",
  },
  {
    postal_code: "27040",
    zip_name: "pfafftown, nc",
  },
  {
    postal_code: "53901",
    zip_name: "portage, wi",
  },
  {
    postal_code: "12540",
    zip_name: "lagrangeville, ny",
  },
  {
    postal_code: "43205",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "44050",
    zip_name: "lagrange, oh",
  },
  {
    postal_code: "74128",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "66223",
    zip_name: "overland park, ks",
  },
  {
    postal_code: "30326",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "76031",
    zip_name: "cleburne, tx",
  },
  {
    postal_code: "60143",
    zip_name: "itasca, il",
  },
  {
    postal_code: "19124",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "90810",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "95673",
    zip_name: "rio linda, ca",
  },
  {
    postal_code: "94954",
    zip_name: "petaluma, ca",
  },
  {
    postal_code: "2809",
    zip_name: "bristol, ri",
  },
  {
    postal_code: "22939",
    zip_name: "fishersville, va",
  },
  {
    postal_code: "20151",
    zip_name: "chantilly, va",
  },
  {
    postal_code: "93950",
    zip_name: "pacific grove, ca",
  },
  {
    postal_code: "24202",
    zip_name: "bristol, va",
  },
  {
    postal_code: "70119",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "38028",
    zip_name: "eads, tn",
  },
  {
    postal_code: "28470",
    zip_name: "shallotte, nc",
  },
  {
    postal_code: "29669",
    zip_name: "pelzer, sc",
  },
  {
    postal_code: "92397",
    zip_name: "wrightwood, ca",
  },
  {
    postal_code: "91607",
    zip_name: "valley village, ca",
  },
  {
    postal_code: "72727",
    zip_name: "elkins, ar",
  },
  {
    postal_code: "2915",
    zip_name: "riverside, ri",
  },
  {
    postal_code: "51301",
    zip_name: "spencer, ia",
  },
  {
    postal_code: "90723",
    zip_name: "paramount, ca",
  },
  {
    postal_code: "46250",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "94903",
    zip_name: "san rafael, ca",
  },
  {
    postal_code: "79927",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "92808",
    zip_name: "anaheim, ca",
  },
  {
    postal_code: "33841",
    zip_name: "fort meade, fl",
  },
  {
    postal_code: "17870",
    zip_name: "selinsgrove, pa",
  },
  {
    postal_code: "75203",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "27837",
    zip_name: "grimesland, nc",
  },
  {
    postal_code: "72736",
    zip_name: "gravette, ar",
  },
  {
    postal_code: "80305",
    zip_name: "boulder, co",
  },
  {
    postal_code: "6478",
    zip_name: "oxford, ct",
  },
  {
    postal_code: "38108",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "33776",
    zip_name: "seminole, fl",
  },
  {
    postal_code: "64093",
    zip_name: "warrensburg, mo",
  },
  {
    postal_code: "24521",
    zip_name: "amherst, va",
  },
  {
    postal_code: "1876",
    zip_name: "tewksbury, ma",
  },
  {
    postal_code: "75235",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "54521",
    zip_name: "eagle river, wi",
  },
  {
    postal_code: "60192",
    zip_name: "hoffman estates, il",
  },
  {
    postal_code: "94555",
    zip_name: "fremont, ca",
  },
  {
    postal_code: "6084",
    zip_name: "tolland, ct",
  },
  {
    postal_code: "44890",
    zip_name: "willard, oh",
  },
  {
    postal_code: "90603",
    zip_name: "whittier, ca",
  },
  {
    postal_code: "78003",
    zip_name: "bandera, tx",
  },
  {
    postal_code: "6238",
    zip_name: "coventry, ct",
  },
  {
    postal_code: "11412",
    zip_name: "saint albans, ny",
  },
  {
    postal_code: "60022",
    zip_name: "glencoe, il",
  },
  {
    postal_code: "29472",
    zip_name: "ridgeville, sc",
  },
  {
    postal_code: "60044",
    zip_name: "lake bluff, il",
  },
  {
    postal_code: "18302",
    zip_name: "east stroudsburg, pa",
  },
  {
    postal_code: "89109",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "84532",
    zip_name: "moab, ut",
  },
  {
    postal_code: "70053",
    zip_name: "gretna, la",
  },
  {
    postal_code: "8270",
    zip_name: "woodbine, nj",
  },
  {
    postal_code: "20008",
    zip_name: "washington, dc",
  },
  {
    postal_code: "75061",
    zip_name: "irving, tx",
  },
  {
    postal_code: "15061",
    zip_name: "monaca, pa",
  },
  {
    postal_code: "86442",
    zip_name: "bullhead city, az",
  },
  {
    postal_code: "34293",
    zip_name: "venice, fl",
  },
  {
    postal_code: "34473",
    zip_name: "ocala, fl",
  },
  {
    postal_code: "10016",
    zip_name: "new york, ny",
  },
  {
    postal_code: "11756",
    zip_name: "levittown, ny",
  },
  {
    postal_code: "6604",
    zip_name: "bridgeport, ct",
  },
  {
    postal_code: "92595",
    zip_name: "wildomar, ca",
  },
  {
    postal_code: "85009",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "44511",
    zip_name: "youngstown, oh",
  },
  {
    postal_code: "79102",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "4401",
    zip_name: "bangor, me",
  },
  {
    postal_code: "85118",
    zip_name: "gold canyon, az",
  },
  {
    postal_code: "80031",
    zip_name: "westminster, co",
  },
  {
    postal_code: "33576",
    zip_name: "san antonio, fl",
  },
  {
    postal_code: "67501",
    zip_name: "hutchinson, ks",
  },
  {
    postal_code: "90247",
    zip_name: "gardena, ca",
  },
  {
    postal_code: "2879",
    zip_name: "wakefield, ri",
  },
  {
    postal_code: "13440",
    zip_name: "rome, ny",
  },
  {
    postal_code: "22203",
    zip_name: "arlington, va",
  },
  {
    postal_code: "93446",
    zip_name: "paso robles, ca",
  },
  {
    postal_code: "11946",
    zip_name: "hampton bays, ny",
  },
  {
    postal_code: "39046",
    zip_name: "canton, ms",
  },
  {
    postal_code: "52556",
    zip_name: "fairfield, ia",
  },
  {
    postal_code: "6416",
    zip_name: "cromwell, ct",
  },
  {
    postal_code: "39157",
    zip_name: "ridgeland, ms",
  },
  {
    postal_code: "95066",
    zip_name: "scotts valley, ca",
  },
  {
    postal_code: "38120",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "33316",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "90746",
    zip_name: "carson, ca",
  },
  {
    postal_code: "20017",
    zip_name: "washington, dc",
  },
  {
    postal_code: "46075",
    zip_name: "whitestown, in",
  },
  {
    postal_code: "11963",
    zip_name: "sag harbor, ny",
  },
  {
    postal_code: "28746",
    zip_name: "lake lure, nc",
  },
  {
    postal_code: "65560",
    zip_name: "salem, mo",
  },
  {
    postal_code: "12816",
    zip_name: "cambridge, ny",
  },
  {
    postal_code: "59802",
    zip_name: "missoula, mt",
  },
  {
    postal_code: "45403",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "24201",
    zip_name: "bristol, va",
  },
  {
    postal_code: "15425",
    zip_name: "connellsville, pa",
  },
  {
    postal_code: "18508",
    zip_name: "scranton, pa",
  },
  {
    postal_code: "78750",
    zip_name: "austin, tx",
  },
  {
    postal_code: "40444",
    zip_name: "lancaster, ky",
  },
  {
    postal_code: "48045",
    zip_name: "harrison township, mi",
  },
  {
    postal_code: "42122",
    zip_name: "alvaton, ky",
  },
  {
    postal_code: "12822",
    zip_name: "corinth, ny",
  },
  {
    postal_code: "55077",
    zip_name: "inver grove heights, mn",
  },
  {
    postal_code: "93908",
    zip_name: "salinas, ca",
  },
  {
    postal_code: "19020",
    zip_name: "bensalem, pa",
  },
  {
    postal_code: "41073",
    zip_name: "bellevue, ky",
  },
  {
    postal_code: "89410",
    zip_name: "gardnerville, nv",
  },
  {
    postal_code: "64052",
    zip_name: "independence, mo",
  },
  {
    postal_code: "78741",
    zip_name: "austin, tx",
  },
  {
    postal_code: "53959",
    zip_name: "reedsburg, wi",
  },
  {
    postal_code: "75253",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "81416",
    zip_name: "delta, co",
  },
  {
    postal_code: "46322",
    zip_name: "highland, in",
  },
  {
    postal_code: "76258",
    zip_name: "pilot point, tx",
  },
  {
    postal_code: "6340",
    zip_name: "groton, ct",
  },
  {
    postal_code: "32063",
    zip_name: "macclenny, fl",
  },
  {
    postal_code: "46113",
    zip_name: "camby, in",
  },
  {
    postal_code: "60106",
    zip_name: "bensenville, il",
  },
  {
    postal_code: "22947",
    zip_name: "keswick, va",
  },
  {
    postal_code: "40351",
    zip_name: "morehead, ky",
  },
  {
    postal_code: "94510",
    zip_name: "benicia, ca",
  },
  {
    postal_code: "63016",
    zip_name: "cedar hill, mo",
  },
  {
    postal_code: "11003",
    zip_name: "elmont, ny",
  },
  {
    postal_code: "13827",
    zip_name: "owego, ny",
  },
  {
    postal_code: "12870",
    zip_name: "schroon lake, ny",
  },
  {
    postal_code: "60041",
    zip_name: "ingleside, il",
  },
  {
    postal_code: "45502",
    zip_name: "springfield, oh",
  },
  {
    postal_code: "63049",
    zip_name: "high ridge, mo",
  },
  {
    postal_code: "98499",
    zip_name: "lakewood, wa",
  },
  {
    postal_code: "35206",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "64131",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "91016",
    zip_name: "monrovia, ca",
  },
  {
    postal_code: "61231",
    zip_name: "aledo, il",
  },
  {
    postal_code: "60490",
    zip_name: "bolingbrook, il",
  },
  {
    postal_code: "90293",
    zip_name: "playa del rey, ca",
  },
  {
    postal_code: "76571",
    zip_name: "salado, tx",
  },
  {
    postal_code: "97361",
    zip_name: "monmouth, or",
  },
  {
    postal_code: "63074",
    zip_name: "saint ann, mo",
  },
  {
    postal_code: "55045",
    zip_name: "lindstrom, mn",
  },
  {
    postal_code: "74132",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "54843",
    zip_name: "hayward, wi",
  },
  {
    postal_code: "13212",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "60447",
    zip_name: "minooka, il",
  },
  {
    postal_code: "94803",
    zip_name: "el sobrante, ca",
  },
  {
    postal_code: "3053",
    zip_name: "londonderry, nh",
  },
  {
    postal_code: "18431",
    zip_name: "honesdale, pa",
  },
  {
    postal_code: "90503",
    zip_name: "torrance, ca",
  },
  {
    postal_code: "24572",
    zip_name: "madison heights, va",
  },
  {
    postal_code: "64024",
    zip_name: "excelsior springs, mo",
  },
  {
    postal_code: "3867",
    zip_name: "rochester, nh",
  },
  {
    postal_code: "98075",
    zip_name: "sammamish, wa",
  },
  {
    postal_code: "12009",
    zip_name: "altamont, ny",
  },
  {
    postal_code: "33762",
    zip_name: "clearwater, fl",
  },
  {
    postal_code: "40744",
    zip_name: "london, ky",
  },
  {
    postal_code: "85023",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "80814",
    zip_name: "divide, co",
  },
  {
    postal_code: "20016",
    zip_name: "washington, dc",
  },
  {
    postal_code: "27105",
    zip_name: "winston salem, nc",
  },
  {
    postal_code: "78754",
    zip_name: "austin, tx",
  },
  {
    postal_code: "15668",
    zip_name: "murrysville, pa",
  },
  {
    postal_code: "20120",
    zip_name: "centreville, va",
  },
  {
    postal_code: "84087",
    zip_name: "woods cross, ut",
  },
  {
    postal_code: "20164",
    zip_name: "sterling, va",
  },
  {
    postal_code: "12803",
    zip_name: "south glens falls, ny",
  },
  {
    postal_code: "68127",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "21601",
    zip_name: "easton, md",
  },
  {
    postal_code: "60475",
    zip_name: "steger, il",
  },
  {
    postal_code: "40508",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "28752",
    zip_name: "marion, nc",
  },
  {
    postal_code: "77901",
    zip_name: "victoria, tx",
  },
  {
    postal_code: "27514",
    zip_name: "chapel hill, nc",
  },
  {
    postal_code: "39452",
    zip_name: "lucedale, ms",
  },
  {
    postal_code: "44041",
    zip_name: "geneva, oh",
  },
  {
    postal_code: "63088",
    zip_name: "valley park, mo",
  },
  {
    postal_code: "22968",
    zip_name: "ruckersville, va",
  },
  {
    postal_code: "37062",
    zip_name: "fairview, tn",
  },
  {
    postal_code: "93312",
    zip_name: "bakersfield, ca",
  },
  {
    postal_code: "28323",
    zip_name: "bunnlevel, nc",
  },
  {
    postal_code: "20619",
    zip_name: "california, md",
  },
  {
    postal_code: "76205",
    zip_name: "denton, tx",
  },
  {
    postal_code: "99324",
    zip_name: "college place, wa",
  },
  {
    postal_code: "79765",
    zip_name: "odessa, tx",
  },
  {
    postal_code: "75686",
    zip_name: "pittsburg, tx",
  },
  {
    postal_code: "27601",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "68526",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "68504",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "92602",
    zip_name: "irvine, ca",
  },
  {
    postal_code: "85501",
    zip_name: "globe, az",
  },
  {
    postal_code: "12061",
    zip_name: "east greenbush, ny",
  },
  {
    postal_code: "12051",
    zip_name: "coxsackie, ny",
  },
  {
    postal_code: "95050",
    zip_name: "santa clara, ca",
  },
  {
    postal_code: "15215",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "90035",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "68131",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "3102",
    zip_name: "manchester, nh",
  },
  {
    postal_code: "36093",
    zip_name: "wetumpka, al",
  },
  {
    postal_code: "93618",
    zip_name: "dinuba, ca",
  },
  {
    postal_code: "15065",
    zip_name: "natrona heights, pa",
  },
  {
    postal_code: "80919",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "72745",
    zip_name: "lowell, ar",
  },
  {
    postal_code: "29040",
    zip_name: "dalzell, sc",
  },
  {
    postal_code: "40108",
    zip_name: "brandenburg, ky",
  },
  {
    postal_code: "34201",
    zip_name: "bradenton, fl",
  },
  {
    postal_code: "57769",
    zip_name: "piedmont, sd",
  },
  {
    postal_code: "22655",
    zip_name: "stephens city, va",
  },
  {
    postal_code: "83422",
    zip_name: "driggs, id",
  },
  {
    postal_code: "22307",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "60026",
    zip_name: "glenview, il",
  },
  {
    postal_code: "96771",
    zip_name: "mountain view, hi",
  },
  {
    postal_code: "2645",
    zip_name: "harwich, ma",
  },
  {
    postal_code: "95360",
    zip_name: "newman, ca",
  },
  {
    postal_code: "86336",
    zip_name: "sedona, az",
  },
  {
    postal_code: "92805",
    zip_name: "anaheim, ca",
  },
  {
    postal_code: "22302",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "75862",
    zip_name: "trinity, tx",
  },
  {
    postal_code: "21214",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "90003",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "12170",
    zip_name: "stillwater, ny",
  },
  {
    postal_code: "48827",
    zip_name: "eaton rapids, mi",
  },
  {
    postal_code: "77086",
    zip_name: "houston, tx",
  },
  {
    postal_code: "45439",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "74447",
    zip_name: "okmulgee, ok",
  },
  {
    postal_code: "77074",
    zip_name: "houston, tx",
  },
  {
    postal_code: "78380",
    zip_name: "robstown, tx",
  },
  {
    postal_code: "60136",
    zip_name: "gilberts, il",
  },
  {
    postal_code: "89519",
    zip_name: "reno, nv",
  },
  {
    postal_code: "92236",
    zip_name: "coachella, ca",
  },
  {
    postal_code: "91011",
    zip_name: "la canada flintridge, ca",
  },
  {
    postal_code: "75236",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "37764",
    zip_name: "kodak, tn",
  },
  {
    postal_code: "11717",
    zip_name: "brentwood, ny",
  },
  {
    postal_code: "54409",
    zip_name: "antigo, wi",
  },
  {
    postal_code: "81520",
    zip_name: "clifton, co",
  },
  {
    postal_code: "61764",
    zip_name: "pontiac, il",
  },
  {
    postal_code: "34974",
    zip_name: "okeechobee, fl",
  },
  {
    postal_code: "89148",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "21144",
    zip_name: "severn, md",
  },
  {
    postal_code: "69361",
    zip_name: "scottsbluff, ne",
  },
  {
    postal_code: "8722",
    zip_name: "beachwood, nj",
  },
  {
    postal_code: "90061",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "20636",
    zip_name: "hollywood, md",
  },
  {
    postal_code: "92604",
    zip_name: "irvine, ca",
  },
  {
    postal_code: "85215",
    zip_name: "mesa, az",
  },
  {
    postal_code: "15063",
    zip_name: "monongahela, pa",
  },
  {
    postal_code: "74631",
    zip_name: "blackwell, ok",
  },
  {
    postal_code: "95407",
    zip_name: "santa rosa, ca",
  },
  {
    postal_code: "22152",
    zip_name: "springfield, va",
  },
  {
    postal_code: "43506",
    zip_name: "bryan, oh",
  },
  {
    postal_code: "46410",
    zip_name: "merrillville, in",
  },
  {
    postal_code: "95482",
    zip_name: "ukiah, ca",
  },
  {
    postal_code: "10031",
    zip_name: "new york, ny",
  },
  {
    postal_code: "30622",
    zip_name: "bogart, ga",
  },
  {
    postal_code: "94134",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "60463",
    zip_name: "palos heights, il",
  },
  {
    postal_code: "66213",
    zip_name: "overland park, ks",
  },
  {
    postal_code: "44509",
    zip_name: "youngstown, oh",
  },
  {
    postal_code: "91303",
    zip_name: "canoga park, ca",
  },
  {
    postal_code: "60623",
    zip_name: "chicago, il",
  },
  {
    postal_code: "25304",
    zip_name: "charleston, wv",
  },
  {
    postal_code: "28139",
    zip_name: "rutherfordton, nc",
  },
  {
    postal_code: "77048",
    zip_name: "houston, tx",
  },
  {
    postal_code: "92386",
    zip_name: "sugarloaf, ca",
  },
  {
    postal_code: "41074",
    zip_name: "dayton, ky",
  },
  {
    postal_code: "34428",
    zip_name: "crystal river, fl",
  },
  {
    postal_code: "45402",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "28778",
    zip_name: "swannanoa, nc",
  },
  {
    postal_code: "92663",
    zip_name: "newport beach, ca",
  },
  {
    postal_code: "92337",
    zip_name: "fontana, ca",
  },
  {
    postal_code: "19320",
    zip_name: "coatesville, pa",
  },
  {
    postal_code: "85053",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "95492",
    zip_name: "windsor, ca",
  },
  {
    postal_code: "10069",
    zip_name: "new york, ny",
  },
  {
    postal_code: "6517",
    zip_name: "hamden, ct",
  },
  {
    postal_code: "90025",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "31620",
    zip_name: "adel, ga",
  },
  {
    postal_code: "24522",
    zip_name: "appomattox, va",
  },
  {
    postal_code: "56345",
    zip_name: "little falls, mn",
  },
  {
    postal_code: "70127",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "43227",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "17837",
    zip_name: "lewisburg, pa",
  },
  {
    postal_code: "5091",
    zip_name: "woodstock, vt",
  },
  {
    postal_code: "21797",
    zip_name: "woodbine, md",
  },
  {
    postal_code: "11434",
    zip_name: "jamaica, ny",
  },
  {
    postal_code: "13031",
    zip_name: "camillus, ny",
  },
  {
    postal_code: "19518",
    zip_name: "douglassville, pa",
  },
  {
    postal_code: "48638",
    zip_name: "saginaw, mi",
  },
  {
    postal_code: "50703",
    zip_name: "waterloo, ia",
  },
  {
    postal_code: "92860",
    zip_name: "norco, ca",
  },
  {
    postal_code: "13619",
    zip_name: "carthage, ny",
  },
  {
    postal_code: "75791",
    zip_name: "whitehouse, tx",
  },
  {
    postal_code: "55051",
    zip_name: "mora, mn",
  },
  {
    postal_code: "63628",
    zip_name: "bonne terre, mo",
  },
  {
    postal_code: "72227",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "48161",
    zip_name: "monroe, mi",
  },
  {
    postal_code: "3812",
    zip_name: "bartlett, nh",
  },
  {
    postal_code: "48820",
    zip_name: "dewitt, mi",
  },
  {
    postal_code: "22503",
    zip_name: "lancaster, va",
  },
  {
    postal_code: "31061",
    zip_name: "milledgeville, ga",
  },
  {
    postal_code: "30021",
    zip_name: "clarkston, ga",
  },
  {
    postal_code: "45015",
    zip_name: "hamilton, oh",
  },
  {
    postal_code: "65686",
    zip_name: "kimberling city, mo",
  },
  {
    postal_code: "8330",
    zip_name: "mays landing, nj",
  },
  {
    postal_code: "16428",
    zip_name: "north east, pa",
  },
  {
    postal_code: "25401",
    zip_name: "martinsburg, wv",
  },
  {
    postal_code: "84059",
    zip_name: "vineyard, ut",
  },
  {
    postal_code: "78253",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "6525",
    zip_name: "woodbridge, ct",
  },
  {
    postal_code: "43031",
    zip_name: "johnstown, oh",
  },
  {
    postal_code: "77414",
    zip_name: "bay city, tx",
  },
  {
    postal_code: "92508",
    zip_name: "riverside, ca",
  },
  {
    postal_code: "30223",
    zip_name: "griffin, ga",
  },
  {
    postal_code: "46135",
    zip_name: "greencastle, in",
  },
  {
    postal_code: "28147",
    zip_name: "salisbury, nc",
  },
  {
    postal_code: "93705",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "30183",
    zip_name: "waleska, ga",
  },
  {
    postal_code: "94571",
    zip_name: "rio vista, ca",
  },
  {
    postal_code: "90077",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "28043",
    zip_name: "forest city, nc",
  },
  {
    postal_code: "39475",
    zip_name: "purvis, ms",
  },
  {
    postal_code: "50124",
    zip_name: "huxley, ia",
  },
  {
    postal_code: "29306",
    zip_name: "spartanburg, sc",
  },
  {
    postal_code: "32024",
    zip_name: "lake city, fl",
  },
  {
    postal_code: "44108",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "30125",
    zip_name: "cedartown, ga",
  },
  {
    postal_code: "53948",
    zip_name: "mauston, wi",
  },
  {
    postal_code: "63080",
    zip_name: "sullivan, mo",
  },
  {
    postal_code: "90241",
    zip_name: "downey, ca",
  },
  {
    postal_code: "90640",
    zip_name: "montebello, ca",
  },
  {
    postal_code: "75495",
    zip_name: "van alstyne, tx",
  },
  {
    postal_code: "42501",
    zip_name: "somerset, ky",
  },
  {
    postal_code: "90604",
    zip_name: "whittier, ca",
  },
  {
    postal_code: "38114",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "28513",
    zip_name: "ayden, nc",
  },
  {
    postal_code: "93654",
    zip_name: "reedley, ca",
  },
  {
    postal_code: "63077",
    zip_name: "saint clair, mo",
  },
  {
    postal_code: "85006",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "60406",
    zip_name: "blue island, il",
  },
  {
    postal_code: "27253",
    zip_name: "graham, nc",
  },
  {
    postal_code: "95118",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "22043",
    zip_name: "falls church, va",
  },
  {
    postal_code: "94124",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "20601",
    zip_name: "waldorf, md",
  },
  {
    postal_code: "84341",
    zip_name: "logan, ut",
  },
  {
    postal_code: "32328",
    zip_name: "eastpoint, fl",
  },
  {
    postal_code: "95832",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "43607",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "21215",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "61820",
    zip_name: "champaign, il",
  },
  {
    postal_code: "20877",
    zip_name: "gaithersburg, md",
  },
  {
    postal_code: "75951",
    zip_name: "jasper, tx",
  },
  {
    postal_code: "49431",
    zip_name: "ludington, mi",
  },
  {
    postal_code: "19128",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "20707",
    zip_name: "laurel, md",
  },
  {
    postal_code: "22015",
    zip_name: "burke, va",
  },
  {
    postal_code: "19114",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "95437",
    zip_name: "fort bragg, ca",
  },
  {
    postal_code: "6518",
    zip_name: "hamden, ct",
  },
  {
    postal_code: "90222",
    zip_name: "compton, ca",
  },
  {
    postal_code: "6812",
    zip_name: "new fairfield, ct",
  },
  {
    postal_code: "91423",
    zip_name: "sherman oaks, ca",
  },
  {
    postal_code: "15224",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "22556",
    zip_name: "stafford, va",
  },
  {
    postal_code: "21114",
    zip_name: "crofton, md",
  },
  {
    postal_code: "40513",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "21771",
    zip_name: "mount airy, md",
  },
  {
    postal_code: "61856",
    zip_name: "monticello, il",
  },
  {
    postal_code: "99037",
    zip_name: "veradale, wa",
  },
  {
    postal_code: "75494",
    zip_name: "winnsboro, tx",
  },
  {
    postal_code: "76054",
    zip_name: "hurst, tx",
  },
  {
    postal_code: "60033",
    zip_name: "harvard, il",
  },
  {
    postal_code: "27263",
    zip_name: "high point, nc",
  },
  {
    postal_code: "55746",
    zip_name: "hibbing, mn",
  },
  {
    postal_code: "64113",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "53548",
    zip_name: "janesville, wi",
  },
  {
    postal_code: "81224",
    zip_name: "crested butte, co",
  },
  {
    postal_code: "65340",
    zip_name: "marshall, mo",
  },
  {
    postal_code: "75765",
    zip_name: "hawkins, tx",
  },
  {
    postal_code: "53566",
    zip_name: "monroe, wi",
  },
  {
    postal_code: "97424",
    zip_name: "cottage grove, or",
  },
  {
    postal_code: "85929",
    zip_name: "lakeside, az",
  },
  {
    postal_code: "75167",
    zip_name: "waxahachie, tx",
  },
  {
    postal_code: "61021",
    zip_name: "dixon, il",
  },
  {
    postal_code: "96818",
    zip_name: "honolulu, hi",
  },
  {
    postal_code: "19803",
    zip_name: "wilmington, de",
  },
  {
    postal_code: "13856",
    zip_name: "walton, ny",
  },
  {
    postal_code: "33144",
    zip_name: "miami, fl",
  },
  {
    postal_code: "54487",
    zip_name: "tomahawk, wi",
  },
  {
    postal_code: "60164",
    zip_name: "melrose park, il",
  },
  {
    postal_code: "25704",
    zip_name: "huntington, wv",
  },
  {
    postal_code: "60601",
    zip_name: "chicago, il",
  },
  {
    postal_code: "28328",
    zip_name: "clinton, nc",
  },
  {
    postal_code: "22204",
    zip_name: "arlington, va",
  },
  {
    postal_code: "5641",
    zip_name: "barre, vt",
  },
  {
    postal_code: "3820",
    zip_name: "dover, nh",
  },
  {
    postal_code: "43064",
    zip_name: "plain city, oh",
  },
  {
    postal_code: "46383",
    zip_name: "valparaiso, in",
  },
  {
    postal_code: "55920",
    zip_name: "byron, mn",
  },
  {
    postal_code: "34685",
    zip_name: "palm harbor, fl",
  },
  {
    postal_code: "32168",
    zip_name: "new smyrna beach, fl",
  },
  {
    postal_code: "37091",
    zip_name: "lewisburg, tn",
  },
  {
    postal_code: "94579",
    zip_name: "san leandro, ca",
  },
  {
    postal_code: "95351",
    zip_name: "modesto, ca",
  },
  {
    postal_code: "72212",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "55431",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "93449",
    zip_name: "pismo beach, ca",
  },
  {
    postal_code: "28713",
    zip_name: "bryson city, nc",
  },
  {
    postal_code: "78705",
    zip_name: "austin, tx",
  },
  {
    postal_code: "64089",
    zip_name: "smithville, mo",
  },
  {
    postal_code: "62095",
    zip_name: "wood river, il",
  },
  {
    postal_code: "12983",
    zip_name: "saranac lake, ny",
  },
  {
    postal_code: "95367",
    zip_name: "riverbank, ca",
  },
  {
    postal_code: "18301",
    zip_name: "east stroudsburg, pa",
  },
  {
    postal_code: "94518",
    zip_name: "concord, ca",
  },
  {
    postal_code: "12828",
    zip_name: "fort edward, ny",
  },
  {
    postal_code: "75082",
    zip_name: "richardson, tx",
  },
  {
    postal_code: "19150",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "28754",
    zip_name: "mars hill, nc",
  },
  {
    postal_code: "46241",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "32110",
    zip_name: "bunnell, fl",
  },
  {
    postal_code: "60119",
    zip_name: "elburn, il",
  },
  {
    postal_code: "65026",
    zip_name: "eldon, mo",
  },
  {
    postal_code: "96094",
    zip_name: "weed, ca",
  },
  {
    postal_code: "77034",
    zip_name: "houston, tx",
  },
  {
    postal_code: "38611",
    zip_name: "byhalia, ms",
  },
  {
    postal_code: "8098",
    zip_name: "woodstown, nj",
  },
  {
    postal_code: "85306",
    zip_name: "glendale, az",
  },
  {
    postal_code: "33920",
    zip_name: "alva, fl",
  },
  {
    postal_code: "36092",
    zip_name: "wetumpka, al",
  },
  {
    postal_code: "17331",
    zip_name: "hanover, pa",
  },
  {
    postal_code: "95926",
    zip_name: "chico, ca",
  },
  {
    postal_code: "48506",
    zip_name: "flint, mi",
  },
  {
    postal_code: "54660",
    zip_name: "tomah, wi",
  },
  {
    postal_code: "62918",
    zip_name: "carterville, il",
  },
  {
    postal_code: "93591",
    zip_name: "palmdale, ca",
  },
  {
    postal_code: "52632",
    zip_name: "keokuk, ia",
  },
  {
    postal_code: "6110",
    zip_name: "west hartford, ct",
  },
  {
    postal_code: "81230",
    zip_name: "gunnison, co",
  },
  {
    postal_code: "6461",
    zip_name: "milford, ct",
  },
  {
    postal_code: "55731",
    zip_name: "ely, mn",
  },
  {
    postal_code: "34291",
    zip_name: "north port, fl",
  },
  {
    postal_code: "95070",
    zip_name: "saratoga, ca",
  },
  {
    postal_code: "95006",
    zip_name: "boulder creek, ca",
  },
  {
    postal_code: "84310",
    zip_name: "eden, ut",
  },
  {
    postal_code: "33527",
    zip_name: "dover, fl",
  },
  {
    postal_code: "11727",
    zip_name: "coram, ny",
  },
  {
    postal_code: "4240",
    zip_name: "lewiston, me",
  },
  {
    postal_code: "33716",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "16146",
    zip_name: "sharon, pa",
  },
  {
    postal_code: "92570",
    zip_name: "perris, ca",
  },
  {
    postal_code: "90280",
    zip_name: "south gate, ca",
  },
  {
    postal_code: "33449",
    zip_name: "lake worth, fl",
  },
  {
    postal_code: "19090",
    zip_name: "willow grove, pa",
  },
  {
    postal_code: "35223",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "2835",
    zip_name: "jamestown, ri",
  },
  {
    postal_code: "44705",
    zip_name: "canton, oh",
  },
  {
    postal_code: "66106",
    zip_name: "kansas city, ks",
  },
  {
    postal_code: "37721",
    zip_name: "corryton, tn",
  },
  {
    postal_code: "64014",
    zip_name: "blue springs, mo",
  },
  {
    postal_code: "98520",
    zip_name: "aberdeen, wa",
  },
  {
    postal_code: "85546",
    zip_name: "safford, az",
  },
  {
    postal_code: "20659",
    zip_name: "mechanicsville, md",
  },
  {
    postal_code: "38358",
    zip_name: "milan, tn",
  },
  {
    postal_code: "55438",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "20653",
    zip_name: "lexington park, md",
  },
  {
    postal_code: "28305",
    zip_name: "fayetteville, nc",
  },
  {
    postal_code: "30012",
    zip_name: "conyers, ga",
  },
  {
    postal_code: "10033",
    zip_name: "new york, ny",
  },
  {
    postal_code: "95032",
    zip_name: "los gatos, ca",
  },
  {
    postal_code: "6716",
    zip_name: "wolcott, ct",
  },
  {
    postal_code: "12550",
    zip_name: "newburgh, ny",
  },
  {
    postal_code: "76067",
    zip_name: "mineral wells, tx",
  },
  {
    postal_code: "77563",
    zip_name: "hitchcock, tx",
  },
  {
    postal_code: "15214",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "40208",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "91750",
    zip_name: "la verne, ca",
  },
  {
    postal_code: "46122",
    zip_name: "danville, in",
  },
  {
    postal_code: "94044",
    zip_name: "pacifica, ca",
  },
  {
    postal_code: "11757",
    zip_name: "lindenhurst, ny",
  },
  {
    postal_code: "75094",
    zip_name: "plano, tx",
  },
  {
    postal_code: "80022",
    zip_name: "commerce city, co",
  },
  {
    postal_code: "38351",
    zip_name: "lexington, tn",
  },
  {
    postal_code: "95966",
    zip_name: "oroville, ca",
  },
  {
    postal_code: "54143",
    zip_name: "marinette, wi",
  },
  {
    postal_code: "33403",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "22079",
    zip_name: "lorton, va",
  },
  {
    postal_code: "91105",
    zip_name: "pasadena, ca",
  },
  {
    postal_code: "90026",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "29307",
    zip_name: "spartanburg, sc",
  },
  {
    postal_code: "95341",
    zip_name: "merced, ca",
  },
  {
    postal_code: "38103",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "95358",
    zip_name: "modesto, ca",
  },
  {
    postal_code: "93225",
    zip_name: "frazier park, ca",
  },
  {
    postal_code: "2814",
    zip_name: "chepachet, ri",
  },
  {
    postal_code: "66205",
    zip_name: "mission, ks",
  },
  {
    postal_code: "91789",
    zip_name: "walnut, ca",
  },
  {
    postal_code: "84049",
    zip_name: "midway, ut",
  },
  {
    postal_code: "39183",
    zip_name: "vicksburg, ms",
  },
  {
    postal_code: "25405",
    zip_name: "martinsburg, wv",
  },
  {
    postal_code: "2673",
    zip_name: "west yarmouth, ma",
  },
  {
    postal_code: "3031",
    zip_name: "amherst, nh",
  },
  {
    postal_code: "55408",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "75137",
    zip_name: "duncanville, tx",
  },
  {
    postal_code: "16505",
    zip_name: "erie, pa",
  },
  {
    postal_code: "77066",
    zip_name: "houston, tx",
  },
  {
    postal_code: "76022",
    zip_name: "bedford, tx",
  },
  {
    postal_code: "18350",
    zip_name: "pocono pines, pa",
  },
  {
    postal_code: "43953",
    zip_name: "steubenville, oh",
  },
  {
    postal_code: "75166",
    zip_name: "lavon, tx",
  },
  {
    postal_code: "80550",
    zip_name: "windsor, co",
  },
  {
    postal_code: "79928",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "33844",
    zip_name: "haines city, fl",
  },
  {
    postal_code: "44120",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "44119",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "2818",
    zip_name: "east greenwich, ri",
  },
  {
    postal_code: "93726",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "92647",
    zip_name: "huntington beach, ca",
  },
  {
    postal_code: "40203",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "27409",
    zip_name: "greensboro, nc",
  },
  {
    postal_code: "49203",
    zip_name: "jackson, mi",
  },
  {
    postal_code: "80234",
    zip_name: "denver, co",
  },
  {
    postal_code: "55401",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "44102",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "6511",
    zip_name: "new haven, ct",
  },
  {
    postal_code: "90221",
    zip_name: "compton, ca",
  },
  {
    postal_code: "25403",
    zip_name: "martinsburg, wv",
  },
  {
    postal_code: "78730",
    zip_name: "austin, tx",
  },
  {
    postal_code: "75169",
    zip_name: "wills point, tx",
  },
  {
    postal_code: "6013",
    zip_name: "burlington, ct",
  },
  {
    postal_code: "47448",
    zip_name: "nashville, in",
  },
  {
    postal_code: "85303",
    zip_name: "glendale, az",
  },
  {
    postal_code: "77503",
    zip_name: "pasadena, tx",
  },
  {
    postal_code: "34607",
    zip_name: "spring hill, fl",
  },
  {
    postal_code: "55403",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "43138",
    zip_name: "logan, oh",
  },
  {
    postal_code: "46036",
    zip_name: "elwood, in",
  },
  {
    postal_code: "90630",
    zip_name: "cypress, ca",
  },
  {
    postal_code: "12206",
    zip_name: "albany, ny",
  },
  {
    postal_code: "44147",
    zip_name: "broadview heights, oh",
  },
  {
    postal_code: "18328",
    zip_name: "dingmans ferry, pa",
  },
  {
    postal_code: "28782",
    zip_name: "tryon, nc",
  },
  {
    postal_code: "64154",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "21826",
    zip_name: "fruitland, md",
  },
  {
    postal_code: "32220",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "90660",
    zip_name: "pico rivera, ca",
  },
  {
    postal_code: "60621",
    zip_name: "chicago, il",
  },
  {
    postal_code: "94541",
    zip_name: "hayward, ca",
  },
  {
    postal_code: "20121",
    zip_name: "centreville, va",
  },
  {
    postal_code: "92780",
    zip_name: "tustin, ca",
  },
  {
    postal_code: "60171",
    zip_name: "river grove, il",
  },
  {
    postal_code: "72734",
    zip_name: "gentry, ar",
  },
  {
    postal_code: "20832",
    zip_name: "olney, md",
  },
  {
    postal_code: "62801",
    zip_name: "centralia, il",
  },
  {
    postal_code: "26330",
    zip_name: "bridgeport, wv",
  },
  {
    postal_code: "55442",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "30340",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "28075",
    zip_name: "harrisburg, nc",
  },
  {
    postal_code: "95111",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "22312",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "15243",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "90706",
    zip_name: "bellflower, ca",
  },
  {
    postal_code: "44028",
    zip_name: "columbia station, oh",
  },
  {
    postal_code: "98901",
    zip_name: "yakima, wa",
  },
  {
    postal_code: "11780",
    zip_name: "saint james, ny",
  },
  {
    postal_code: "20646",
    zip_name: "la plata, md",
  },
  {
    postal_code: "63044",
    zip_name: "bridgeton, mo",
  },
  {
    postal_code: "15139",
    zip_name: "oakmont, pa",
  },
  {
    postal_code: "78073",
    zip_name: "von ormy, tx",
  },
  {
    postal_code: "33330",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "81321",
    zip_name: "cortez, co",
  },
  {
    postal_code: "95338",
    zip_name: "mariposa, ca",
  },
  {
    postal_code: "85119",
    zip_name: "apache junction, az",
  },
  {
    postal_code: "55420",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "48089",
    zip_name: "warren, mi",
  },
  {
    postal_code: "95409",
    zip_name: "santa rosa, ca",
  },
  {
    postal_code: "59860",
    zip_name: "polson, mt",
  },
  {
    postal_code: "95666",
    zip_name: "pioneer, ca",
  },
  {
    postal_code: "78248",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "44131",
    zip_name: "independence, oh",
  },
  {
    postal_code: "94080",
    zip_name: "south san francisco, ca",
  },
  {
    postal_code: "20603",
    zip_name: "waldorf, md",
  },
  {
    postal_code: "77510",
    zip_name: "santa fe, tx",
  },
  {
    postal_code: "59047",
    zip_name: "livingston, mt",
  },
  {
    postal_code: "81403",
    zip_name: "montrose, co",
  },
  {
    postal_code: "81007",
    zip_name: "pueblo, co",
  },
  {
    postal_code: "53555",
    zip_name: "lodi, wi",
  },
  {
    postal_code: "77401",
    zip_name: "bellaire, tx",
  },
  {
    postal_code: "19711",
    zip_name: "newark, de",
  },
  {
    postal_code: "33127",
    zip_name: "miami, fl",
  },
  {
    postal_code: "37890",
    zip_name: "white pine, tn",
  },
  {
    postal_code: "79007",
    zip_name: "borger, tx",
  },
  {
    postal_code: "42503",
    zip_name: "somerset, ky",
  },
  {
    postal_code: "90713",
    zip_name: "lakewood, ca",
  },
  {
    postal_code: "29376",
    zip_name: "roebuck, sc",
  },
  {
    postal_code: "15213",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "98059",
    zip_name: "renton, wa",
  },
  {
    postal_code: "56472",
    zip_name: "pequot lakes, mn",
  },
  {
    postal_code: "55744",
    zip_name: "grand rapids, mn",
  },
  {
    postal_code: "76132",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "84097",
    zip_name: "orem, ut",
  },
  {
    postal_code: "60449",
    zip_name: "monee, il",
  },
  {
    postal_code: "98407",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "62901",
    zip_name: "carbondale, il",
  },
  {
    postal_code: "38343",
    zip_name: "humboldt, tn",
  },
  {
    postal_code: "49660",
    zip_name: "manistee, mi",
  },
  {
    postal_code: "20723",
    zip_name: "laurel, md",
  },
  {
    postal_code: "75159",
    zip_name: "seagoville, tx",
  },
  {
    postal_code: "45344",
    zip_name: "new carlisle, oh",
  },
  {
    postal_code: "27408",
    zip_name: "greensboro, nc",
  },
  {
    postal_code: "21234",
    zip_name: "parkville, md",
  },
  {
    postal_code: "13732",
    zip_name: "apalachin, ny",
  },
  {
    postal_code: "53913",
    zip_name: "baraboo, wi",
  },
  {
    postal_code: "6401",
    zip_name: "ansonia, ct",
  },
  {
    postal_code: "78220",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "94559",
    zip_name: "napa, ca",
  },
  {
    postal_code: "60162",
    zip_name: "hillside, il",
  },
  {
    postal_code: "32776",
    zip_name: "sorrento, fl",
  },
  {
    postal_code: "75457",
    zip_name: "mount vernon, tx",
  },
  {
    postal_code: "61072",
    zip_name: "rockton, il",
  },
  {
    postal_code: "22042",
    zip_name: "falls church, va",
  },
  {
    postal_code: "84501",
    zip_name: "price, ut",
  },
  {
    postal_code: "12018",
    zip_name: "averill park, ny",
  },
  {
    postal_code: "91042",
    zip_name: "tujunga, ca",
  },
  {
    postal_code: "37854",
    zip_name: "rockwood, tn",
  },
  {
    postal_code: "2903",
    zip_name: "providence, ri",
  },
  {
    postal_code: "21220",
    zip_name: "middle river, md",
  },
  {
    postal_code: "89508",
    zip_name: "reno, nv",
  },
  {
    postal_code: "55804",
    zip_name: "duluth, mn",
  },
  {
    postal_code: "6019",
    zip_name: "canton, ct",
  },
  {
    postal_code: "3103",
    zip_name: "manchester, nh",
  },
  {
    postal_code: "92782",
    zip_name: "tustin, ca",
  },
  {
    postal_code: "77531",
    zip_name: "clute, tx",
  },
  {
    postal_code: "85120",
    zip_name: "apache junction, az",
  },
  {
    postal_code: "19103",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "94952",
    zip_name: "petaluma, ca",
  },
  {
    postal_code: "44883",
    zip_name: "tiffin, oh",
  },
  {
    postal_code: "59912",
    zip_name: "columbia falls, mt",
  },
  {
    postal_code: "46228",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "45213",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "92867",
    zip_name: "orange, ca",
  },
  {
    postal_code: "27608",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "91340",
    zip_name: "san fernando, ca",
  },
  {
    postal_code: "85123",
    zip_name: "arizona city, az",
  },
  {
    postal_code: "18505",
    zip_name: "scranton, pa",
  },
  {
    postal_code: "56082",
    zip_name: "saint peter, mn",
  },
  {
    postal_code: "74868",
    zip_name: "seminole, ok",
  },
  {
    postal_code: "33043",
    zip_name: "big pine key, fl",
  },
  {
    postal_code: "11978",
    zip_name: "westhampton beach, ny",
  },
  {
    postal_code: "14213",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "43085",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "79934",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "80303",
    zip_name: "boulder, co",
  },
  {
    postal_code: "22207",
    zip_name: "arlington, va",
  },
  {
    postal_code: "22310",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "93277",
    zip_name: "visalia, ca",
  },
  {
    postal_code: "87102",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "48118",
    zip_name: "chelsea, mi",
  },
  {
    postal_code: "86401",
    zip_name: "kingman, az",
  },
  {
    postal_code: "78737",
    zip_name: "austin, tx",
  },
  {
    postal_code: "91766",
    zip_name: "pomona, ca",
  },
  {
    postal_code: "94549",
    zip_name: "lafayette, ca",
  },
  {
    postal_code: "65712",
    zip_name: "mount vernon, mo",
  },
  {
    postal_code: "62258",
    zip_name: "mascoutah, il",
  },
  {
    postal_code: "76111",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "55345",
    zip_name: "minnetonka, mn",
  },
  {
    postal_code: "75254",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "32204",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "99005",
    zip_name: "colbert, wa",
  },
  {
    postal_code: "78739",
    zip_name: "austin, tx",
  },
  {
    postal_code: "29544",
    zip_name: "galivants ferry, sc",
  },
  {
    postal_code: "90048",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "72022",
    zip_name: "bryant, ar",
  },
  {
    postal_code: "36066",
    zip_name: "prattville, al",
  },
  {
    postal_code: "55386",
    zip_name: "victoria, mn",
  },
  {
    postal_code: "27850",
    zip_name: "littleton, nc",
  },
  {
    postal_code: "45504",
    zip_name: "springfield, oh",
  },
  {
    postal_code: "20879",
    zip_name: "gaithersburg, md",
  },
  {
    postal_code: "75212",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "90291",
    zip_name: "venice, ca",
  },
  {
    postal_code: "15220",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "79701",
    zip_name: "midland, tx",
  },
  {
    postal_code: "19473",
    zip_name: "schwenksville, pa",
  },
  {
    postal_code: "21218",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "43140",
    zip_name: "london, oh",
  },
  {
    postal_code: "33332",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "79407",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "80905",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "91411",
    zip_name: "van nuys, ca",
  },
  {
    postal_code: "92551",
    zip_name: "moreno valley, ca",
  },
  {
    postal_code: "94545",
    zip_name: "hayward, ca",
  },
  {
    postal_code: "45223",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "36203",
    zip_name: "oxford, al",
  },
  {
    postal_code: "24343",
    zip_name: "hillsville, va",
  },
  {
    postal_code: "90720",
    zip_name: "los alamitos, ca",
  },
  {
    postal_code: "11580",
    zip_name: "valley stream, ny",
  },
  {
    postal_code: "75459",
    zip_name: "howe, tx",
  },
  {
    postal_code: "96130",
    zip_name: "susanville, ca",
  },
  {
    postal_code: "94070",
    zip_name: "san carlos, ca",
  },
  {
    postal_code: "60491",
    zip_name: "homer glen, il",
  },
  {
    postal_code: "31329",
    zip_name: "springfield, ga",
  },
  {
    postal_code: "78759",
    zip_name: "austin, tx",
  },
  {
    postal_code: "44805",
    zip_name: "ashland, oh",
  },
  {
    postal_code: "76005",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "93510",
    zip_name: "acton, ca",
  },
  {
    postal_code: "79762",
    zip_name: "odessa, tx",
  },
  {
    postal_code: "21842",
    zip_name: "ocean city, md",
  },
  {
    postal_code: "91505",
    zip_name: "burbank, ca",
  },
  {
    postal_code: "21221",
    zip_name: "essex, md",
  },
  {
    postal_code: "94520",
    zip_name: "concord, ca",
  },
  {
    postal_code: "93905",
    zip_name: "salinas, ca",
  },
  {
    postal_code: "21158",
    zip_name: "westminster, md",
  },
  {
    postal_code: "32308",
    zip_name: "tallahassee, fl",
  },
  {
    postal_code: "15656",
    zip_name: "leechburg, pa",
  },
  {
    postal_code: "74003",
    zip_name: "bartlesville, ok",
  },
  {
    postal_code: "38372",
    zip_name: "savannah, tn",
  },
  {
    postal_code: "80915",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "81505",
    zip_name: "grand junction, co",
  },
  {
    postal_code: "44241",
    zip_name: "streetsboro, oh",
  },
  {
    postal_code: "34429",
    zip_name: "crystal river, fl",
  },
  {
    postal_code: "95831",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "60545",
    zip_name: "plano, il",
  },
  {
    postal_code: "85353",
    zip_name: "tolleson, az",
  },
  {
    postal_code: "28202",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "8093",
    zip_name: "westville, nj",
  },
  {
    postal_code: "95453",
    zip_name: "lakeport, ca",
  },
  {
    postal_code: "66104",
    zip_name: "kansas city, ks",
  },
  {
    postal_code: "78130",
    zip_name: "new braunfels, tx",
  },
  {
    postal_code: "34219",
    zip_name: "parrish, fl",
  },
  {
    postal_code: "77016",
    zip_name: "houston, tx",
  },
  {
    postal_code: "66212",
    zip_name: "overland park, ks",
  },
  {
    postal_code: "37821",
    zip_name: "newport, tn",
  },
  {
    postal_code: "15025",
    zip_name: "clairton, pa",
  },
  {
    postal_code: "11722",
    zip_name: "central islip, ny",
  },
  {
    postal_code: "90808",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "34614",
    zip_name: "brooksville, fl",
  },
  {
    postal_code: "28601",
    zip_name: "hickory, nc",
  },
  {
    postal_code: "44111",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "30540",
    zip_name: "ellijay, ga",
  },
  {
    postal_code: "90740",
    zip_name: "seal beach, ca",
  },
  {
    postal_code: "95340",
    zip_name: "merced, ca",
  },
  {
    postal_code: "54751",
    zip_name: "menomonie, wi",
  },
  {
    postal_code: "70094",
    zip_name: "westwego, la",
  },
  {
    postal_code: "28717",
    zip_name: "cashiers, nc",
  },
  {
    postal_code: "32784",
    zip_name: "umatilla, fl",
  },
  {
    postal_code: "62012",
    zip_name: "brighton, il",
  },
  {
    postal_code: "39520",
    zip_name: "bay saint louis, ms",
  },
  {
    postal_code: "2911",
    zip_name: "north providence, ri",
  },
  {
    postal_code: "77022",
    zip_name: "houston, tx",
  },
  {
    postal_code: "62034",
    zip_name: "glen carbon, il",
  },
  {
    postal_code: "70118",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "77505",
    zip_name: "pasadena, tx",
  },
  {
    postal_code: "90807",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "30260",
    zip_name: "morrow, ga",
  },
  {
    postal_code: "30650",
    zip_name: "madison, ga",
  },
  {
    postal_code: "2632",
    zip_name: "centerville, ma",
  },
  {
    postal_code: "92596",
    zip_name: "winchester, ca",
  },
  {
    postal_code: "90601",
    zip_name: "whittier, ca",
  },
  {
    postal_code: "91104",
    zip_name: "pasadena, ca",
  },
  {
    postal_code: "85015",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "34472",
    zip_name: "ocala, fl",
  },
  {
    postal_code: "39553",
    zip_name: "gautier, ms",
  },
  {
    postal_code: "62656",
    zip_name: "lincoln, il",
  },
  {
    postal_code: "64050",
    zip_name: "independence, mo",
  },
  {
    postal_code: "64505",
    zip_name: "saint joseph, mo",
  },
  {
    postal_code: "75039",
    zip_name: "irving, tx",
  },
  {
    postal_code: "35094",
    zip_name: "leeds, al",
  },
  {
    postal_code: "2536",
    zip_name: "east falmouth, ma",
  },
  {
    postal_code: "84014",
    zip_name: "centerville, ut",
  },
  {
    postal_code: "78621",
    zip_name: "elgin, tx",
  },
  {
    postal_code: "91351",
    zip_name: "canyon country, ca",
  },
  {
    postal_code: "38571",
    zip_name: "crossville, tn",
  },
  {
    postal_code: "6615",
    zip_name: "stratford, ct",
  },
  {
    postal_code: "25314",
    zip_name: "charleston, wv",
  },
  {
    postal_code: "60464",
    zip_name: "palos park, il",
  },
  {
    postal_code: "70002",
    zip_name: "metairie, la",
  },
  {
    postal_code: "92585",
    zip_name: "menifee, ca",
  },
  {
    postal_code: "85043",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "53534",
    zip_name: "edgerton, wi",
  },
  {
    postal_code: "40212",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "34773",
    zip_name: "saint cloud, fl",
  },
  {
    postal_code: "85014",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "74021",
    zip_name: "collinsville, ok",
  },
  {
    postal_code: "55404",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "59828",
    zip_name: "corvallis, mt",
  },
  {
    postal_code: "95051",
    zip_name: "santa clara, ca",
  },
  {
    postal_code: "79124",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "92342",
    zip_name: "helendale, ca",
  },
  {
    postal_code: "11377",
    zip_name: "woodside, ny",
  },
  {
    postal_code: "64119",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "44113",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "76249",
    zip_name: "krum, tx",
  },
  {
    postal_code: "21704",
    zip_name: "frederick, md",
  },
  {
    postal_code: "37813",
    zip_name: "morristown, tn",
  },
  {
    postal_code: "38572",
    zip_name: "crossville, tn",
  },
  {
    postal_code: "80907",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "64083",
    zip_name: "raymore, mo",
  },
  {
    postal_code: "87571",
    zip_name: "taos, nm",
  },
  {
    postal_code: "27609",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "84109",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "60417",
    zip_name: "crete, il",
  },
  {
    postal_code: "85203",
    zip_name: "mesa, az",
  },
  {
    postal_code: "72207",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "30269",
    zip_name: "peachtree city, ga",
  },
  {
    postal_code: "29657",
    zip_name: "liberty, sc",
  },
  {
    postal_code: "56093",
    zip_name: "waseca, mn",
  },
  {
    postal_code: "95776",
    zip_name: "woodland, ca",
  },
  {
    postal_code: "60012",
    zip_name: "crystal lake, il",
  },
  {
    postal_code: "12182",
    zip_name: "troy, ny",
  },
  {
    postal_code: "44471",
    zip_name: "struthers, oh",
  },
  {
    postal_code: "3833",
    zip_name: "exeter, nh",
  },
  {
    postal_code: "70560",
    zip_name: "new iberia, la",
  },
  {
    postal_code: "70114",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "30360",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "63112",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "93307",
    zip_name: "bakersfield, ca",
  },
  {
    postal_code: "11788",
    zip_name: "hauppauge, ny",
  },
  {
    postal_code: "72032",
    zip_name: "conway, ar",
  },
  {
    postal_code: "94061",
    zip_name: "redwood city, ca",
  },
  {
    postal_code: "84054",
    zip_name: "north salt lake, ut",
  },
  {
    postal_code: "79703",
    zip_name: "midland, tx",
  },
  {
    postal_code: "15238",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "91345",
    zip_name: "mission hills, ca",
  },
  {
    postal_code: "21043",
    zip_name: "ellicott city, md",
  },
  {
    postal_code: "21206",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "22601",
    zip_name: "winchester, va",
  },
  {
    postal_code: "32011",
    zip_name: "callahan, fl",
  },
  {
    postal_code: "90266",
    zip_name: "manhattan beach, ca",
  },
  {
    postal_code: "35205",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "92675",
    zip_name: "san juan capistrano, ca",
  },
  {
    postal_code: "27217",
    zip_name: "burlington, nc",
  },
  {
    postal_code: "35004",
    zip_name: "moody, al",
  },
  {
    postal_code: "95133",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "8052",
    zip_name: "maple shade, nj",
  },
  {
    postal_code: "3106",
    zip_name: "hooksett, nh",
  },
  {
    postal_code: "2842",
    zip_name: "middletown, ri",
  },
  {
    postal_code: "11050",
    zip_name: "port washington, ny",
  },
  {
    postal_code: "84116",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "72118",
    zip_name: "north little rock, ar",
  },
  {
    postal_code: "15211",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "34785",
    zip_name: "wildwood, fl",
  },
  {
    postal_code: "22408",
    zip_name: "fredericksburg, va",
  },
  {
    postal_code: "30529",
    zip_name: "commerce, ga",
  },
  {
    postal_code: "3038",
    zip_name: "derry, nh",
  },
  {
    postal_code: "1247",
    zip_name: "north adams, ma",
  },
  {
    postal_code: "37086",
    zip_name: "la vergne, tn",
  },
  {
    postal_code: "36067",
    zip_name: "prattville, al",
  },
  {
    postal_code: "33637",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "49047",
    zip_name: "dowagiac, mi",
  },
  {
    postal_code: "91324",
    zip_name: "northridge, ca",
  },
  {
    postal_code: "6053",
    zip_name: "new britain, ct",
  },
  {
    postal_code: "76085",
    zip_name: "weatherford, tx",
  },
  {
    postal_code: "2657",
    zip_name: "provincetown, ma",
  },
  {
    postal_code: "92582",
    zip_name: "san jacinto, ca",
  },
  {
    postal_code: "43201",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "25701",
    zip_name: "huntington, wv",
  },
  {
    postal_code: "2649",
    zip_name: "mashpee, ma",
  },
  {
    postal_code: "79603",
    zip_name: "abilene, tx",
  },
  {
    postal_code: "99217",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "50311",
    zip_name: "des moines, ia",
  },
  {
    postal_code: "15905",
    zip_name: "johnstown, pa",
  },
  {
    postal_code: "62052",
    zip_name: "jerseyville, il",
  },
  {
    postal_code: "19403",
    zip_name: "norristown, pa",
  },
  {
    postal_code: "41075",
    zip_name: "fort thomas, ky",
  },
  {
    postal_code: "95825",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "85381",
    zip_name: "peoria, az",
  },
  {
    postal_code: "2905",
    zip_name: "providence, ri",
  },
  {
    postal_code: "43076",
    zip_name: "thornville, oh",
  },
  {
    postal_code: "90278",
    zip_name: "redondo beach, ca",
  },
  {
    postal_code: "6415",
    zip_name: "colchester, ct",
  },
  {
    postal_code: "45430",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "14905",
    zip_name: "elmira, ny",
  },
  {
    postal_code: "78727",
    zip_name: "austin, tx",
  },
  {
    postal_code: "46304",
    zip_name: "chesterton, in",
  },
  {
    postal_code: "91732",
    zip_name: "el monte, ca",
  },
  {
    postal_code: "77058",
    zip_name: "houston, tx",
  },
  {
    postal_code: "28730",
    zip_name: "fairview, nc",
  },
  {
    postal_code: "12189",
    zip_name: "watervliet, ny",
  },
  {
    postal_code: "74601",
    zip_name: "ponca city, ok",
  },
  {
    postal_code: "43619",
    zip_name: "northwood, oh",
  },
  {
    postal_code: "50014",
    zip_name: "ames, ia",
  },
  {
    postal_code: "15207",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "92335",
    zip_name: "fontana, ca",
  },
  {
    postal_code: "55418",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "51503",
    zip_name: "council bluffs, ia",
  },
  {
    postal_code: "8107",
    zip_name: "oaklyn, nj",
  },
  {
    postal_code: "95148",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "74048",
    zip_name: "nowata, ok",
  },
  {
    postal_code: "40213",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "64137",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "61071",
    zip_name: "rock falls, il",
  },
  {
    postal_code: "92881",
    zip_name: "corona, ca",
  },
  {
    postal_code: "66224",
    zip_name: "overland park, ks",
  },
  {
    postal_code: "49127",
    zip_name: "stevensville, mi",
  },
  {
    postal_code: "33935",
    zip_name: "labelle, fl",
  },
  {
    postal_code: "94014",
    zip_name: "daly city, ca",
  },
  {
    postal_code: "15003",
    zip_name: "ambridge, pa",
  },
  {
    postal_code: "97317",
    zip_name: "salem, or",
  },
  {
    postal_code: "77068",
    zip_name: "houston, tx",
  },
  {
    postal_code: "44142",
    zip_name: "brookpark, oh",
  },
  {
    postal_code: "15229",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "20109",
    zip_name: "manassas, va",
  },
  {
    postal_code: "91405",
    zip_name: "van nuys, ca",
  },
  {
    postal_code: "53562",
    zip_name: "middleton, wi",
  },
  {
    postal_code: "43452",
    zip_name: "port clinton, oh",
  },
  {
    postal_code: "55409",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "96756",
    zip_name: "koloa, hi",
  },
  {
    postal_code: "77003",
    zip_name: "houston, tx",
  },
  {
    postal_code: "29451",
    zip_name: "isle of palms, sc",
  },
  {
    postal_code: "54166",
    zip_name: "shawano, wi",
  },
  {
    postal_code: "37204",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "75010",
    zip_name: "carrollton, tx",
  },
  {
    postal_code: "46373",
    zip_name: "saint john, in",
  },
  {
    postal_code: "68112",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "28352",
    zip_name: "laurinburg, nc",
  },
  {
    postal_code: "60070",
    zip_name: "prospect heights, il",
  },
  {
    postal_code: "95060",
    zip_name: "santa cruz, ca",
  },
  {
    postal_code: "45249",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "76574",
    zip_name: "taylor, tx",
  },
  {
    postal_code: "75134",
    zip_name: "lancaster, tx",
  },
  {
    postal_code: "40217",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "98168",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "85304",
    zip_name: "glendale, az",
  },
  {
    postal_code: "20152",
    zip_name: "chantilly, va",
  },
  {
    postal_code: "20817",
    zip_name: "bethesda, md",
  },
  {
    postal_code: "50312",
    zip_name: "des moines, ia",
  },
  {
    postal_code: "28457",
    zip_name: "rocky point, nc",
  },
  {
    postal_code: "78266",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "60432",
    zip_name: "joliet, il",
  },
  {
    postal_code: "85040",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "13815",
    zip_name: "norwich, ny",
  },
  {
    postal_code: "55441",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "77478",
    zip_name: "sugar land, tx",
  },
  {
    postal_code: "19720",
    zip_name: "new castle, de",
  },
  {
    postal_code: "95370",
    zip_name: "sonora, ca",
  },
  {
    postal_code: "91741",
    zip_name: "glendora, ca",
  },
  {
    postal_code: "85310",
    zip_name: "glendale, az",
  },
  {
    postal_code: "35043",
    zip_name: "chelsea, al",
  },
  {
    postal_code: "60633",
    zip_name: "chicago, il",
  },
  {
    postal_code: "85041",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "63134",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "44145",
    zip_name: "westlake, oh",
  },
  {
    postal_code: "12095",
    zip_name: "johnstown, ny",
  },
  {
    postal_code: "31312",
    zip_name: "guyton, ga",
  },
  {
    postal_code: "28714",
    zip_name: "burnsville, nc",
  },
  {
    postal_code: "6820",
    zip_name: "darien, ct",
  },
  {
    postal_code: "5701",
    zip_name: "rutland, vt",
  },
  {
    postal_code: "44691",
    zip_name: "wooster, oh",
  },
  {
    postal_code: "43202",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "34601",
    zip_name: "brooksville, fl",
  },
  {
    postal_code: "25801",
    zip_name: "beckley, wv",
  },
  {
    postal_code: "15017",
    zip_name: "bridgeville, pa",
  },
  {
    postal_code: "95688",
    zip_name: "vacaville, ca",
  },
  {
    postal_code: "13045",
    zip_name: "cortland, ny",
  },
  {
    postal_code: "30354",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "90059",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "24354",
    zip_name: "marion, va",
  },
  {
    postal_code: "56320",
    zip_name: "cold spring, mn",
  },
  {
    postal_code: "33765",
    zip_name: "clearwater, fl",
  },
  {
    postal_code: "6117",
    zip_name: "west hartford, ct",
  },
  {
    postal_code: "77023",
    zip_name: "houston, tx",
  },
  {
    postal_code: "34476",
    zip_name: "ocala, fl",
  },
  {
    postal_code: "48225",
    zip_name: "harper woods, mi",
  },
  {
    postal_code: "95127",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "29356",
    zip_name: "landrum, sc",
  },
  {
    postal_code: "34461",
    zip_name: "lecanto, fl",
  },
  {
    postal_code: "86001",
    zip_name: "flagstaff, az",
  },
  {
    postal_code: "92887",
    zip_name: "yorba linda, ca",
  },
  {
    postal_code: "99006",
    zip_name: "deer park, wa",
  },
  {
    postal_code: "76543",
    zip_name: "killeen, tx",
  },
  {
    postal_code: "28034",
    zip_name: "dallas, nc",
  },
  {
    postal_code: "85083",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "91316",
    zip_name: "encino, ca",
  },
  {
    postal_code: "15228",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "29671",
    zip_name: "pickens, sc",
  },
  {
    postal_code: "37069",
    zip_name: "franklin, tn",
  },
  {
    postal_code: "81506",
    zip_name: "grand junction, co",
  },
  {
    postal_code: "78747",
    zip_name: "austin, tx",
  },
  {
    postal_code: "95422",
    zip_name: "clearlake, ca",
  },
  {
    postal_code: "55340",
    zip_name: "hamel, mn",
  },
  {
    postal_code: "27511",
    zip_name: "cary, nc",
  },
  {
    postal_code: "27712",
    zip_name: "durham, nc",
  },
  {
    postal_code: "34731",
    zip_name: "fruitland park, fl",
  },
  {
    postal_code: "96738",
    zip_name: "waikoloa, hi",
  },
  {
    postal_code: "79936",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "85013",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "70555",
    zip_name: "maurice, la",
  },
  {
    postal_code: "60415",
    zip_name: "chicago ridge, il",
  },
  {
    postal_code: "80002",
    zip_name: "arvada, co",
  },
  {
    postal_code: "60042",
    zip_name: "island lake, il",
  },
  {
    postal_code: "33184",
    zip_name: "miami, fl",
  },
  {
    postal_code: "48837",
    zip_name: "grand ledge, mi",
  },
  {
    postal_code: "79720",
    zip_name: "big spring, tx",
  },
  {
    postal_code: "19454",
    zip_name: "north wales, pa",
  },
  {
    postal_code: "91706",
    zip_name: "baldwin park, ca",
  },
  {
    postal_code: "33759",
    zip_name: "clearwater, fl",
  },
  {
    postal_code: "13104",
    zip_name: "manlius, ny",
  },
  {
    postal_code: "33146",
    zip_name: "miami, fl",
  },
  {
    postal_code: "61109",
    zip_name: "rockford, il",
  },
  {
    postal_code: "95112",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "56058",
    zip_name: "le sueur, mn",
  },
  {
    postal_code: "37807",
    zip_name: "maynardville, tn",
  },
  {
    postal_code: "43920",
    zip_name: "east liverpool, oh",
  },
  {
    postal_code: "62062",
    zip_name: "maryville, il",
  },
  {
    postal_code: "45212",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "16125",
    zip_name: "greenville, pa",
  },
  {
    postal_code: "76058",
    zip_name: "joshua, tx",
  },
  {
    postal_code: "48423",
    zip_name: "davison, mi",
  },
  {
    postal_code: "90272",
    zip_name: "pacific palisades, ca",
  },
  {
    postal_code: "94598",
    zip_name: "walnut creek, ca",
  },
  {
    postal_code: "78257",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "32833",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "76692",
    zip_name: "whitney, tx",
  },
  {
    postal_code: "30145",
    zip_name: "kingston, ga",
  },
  {
    postal_code: "76009",
    zip_name: "alvarado, tx",
  },
  {
    postal_code: "75232",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "94507",
    zip_name: "alamo, ca",
  },
  {
    postal_code: "15367",
    zip_name: "venetia, pa",
  },
  {
    postal_code: "60546",
    zip_name: "riverside, il",
  },
  {
    postal_code: "40204",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "61727",
    zip_name: "clinton, il",
  },
  {
    postal_code: "38141",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "63011",
    zip_name: "ballwin, mo",
  },
  {
    postal_code: "16148",
    zip_name: "hermitage, pa",
  },
  {
    postal_code: "60425",
    zip_name: "glenwood, il",
  },
  {
    postal_code: "90250",
    zip_name: "hawthorne, ca",
  },
  {
    postal_code: "46224",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "36106",
    zip_name: "montgomery, al",
  },
  {
    postal_code: "82435",
    zip_name: "powell, wy",
  },
  {
    postal_code: "55807",
    zip_name: "duluth, mn",
  },
  {
    postal_code: "60401",
    zip_name: "beecher, il",
  },
  {
    postal_code: "11226",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "35209",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "6037",
    zip_name: "berlin, ct",
  },
  {
    postal_code: "15202",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "25504",
    zip_name: "barboursville, wv",
  },
  {
    postal_code: "12946",
    zip_name: "lake placid, ny",
  },
  {
    postal_code: "22309",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "19026",
    zip_name: "drexel hill, pa",
  },
  {
    postal_code: "78738",
    zip_name: "austin, tx",
  },
  {
    postal_code: "80214",
    zip_name: "denver, co",
  },
  {
    postal_code: "56071",
    zip_name: "new prague, mn",
  },
  {
    postal_code: "95640",
    zip_name: "ione, ca",
  },
  {
    postal_code: "92870",
    zip_name: "placentia, ca",
  },
  {
    postal_code: "80904",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "47933",
    zip_name: "crawfordsville, in",
  },
  {
    postal_code: "27958",
    zip_name: "moyock, nc",
  },
  {
    postal_code: "95307",
    zip_name: "ceres, ca",
  },
  {
    postal_code: "45068",
    zip_name: "waynesville, oh",
  },
  {
    postal_code: "92603",
    zip_name: "irvine, ca",
  },
  {
    postal_code: "85622",
    zip_name: "green valley, az",
  },
  {
    postal_code: "95467",
    zip_name: "hidden valley lake, ca",
  },
  {
    postal_code: "79382",
    zip_name: "wolfforth, tx",
  },
  {
    postal_code: "95132",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "62035",
    zip_name: "godfrey, il",
  },
  {
    postal_code: "63048",
    zip_name: "herculaneum, mo",
  },
  {
    postal_code: "45030",
    zip_name: "harrison, oh",
  },
  {
    postal_code: "15203",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "78224",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "43402",
    zip_name: "bowling green, oh",
  },
  {
    postal_code: "6801",
    zip_name: "bethel, ct",
  },
  {
    postal_code: "95129",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "79414",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "77328",
    zip_name: "cleveland, tx",
  },
  {
    postal_code: "21113",
    zip_name: "odenton, md",
  },
  {
    postal_code: "20007",
    zip_name: "washington, dc",
  },
  {
    postal_code: "37760",
    zip_name: "jefferson city, tn",
  },
  {
    postal_code: "48094",
    zip_name: "washington, mi",
  },
  {
    postal_code: "22180",
    zip_name: "vienna, va",
  },
  {
    postal_code: "95817",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "10308",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "78758",
    zip_name: "austin, tx",
  },
  {
    postal_code: "18901",
    zip_name: "doylestown, pa",
  },
  {
    postal_code: "63050",
    zip_name: "hillsboro, mo",
  },
  {
    postal_code: "92549",
    zip_name: "idyllwild, ca",
  },
  {
    postal_code: "94551",
    zip_name: "livermore, ca",
  },
  {
    postal_code: "72012",
    zip_name: "beebe, ar",
  },
  {
    postal_code: "92505",
    zip_name: "riverside, ca",
  },
  {
    postal_code: "76088",
    zip_name: "weatherford, tx",
  },
  {
    postal_code: "80863",
    zip_name: "woodland park, co",
  },
  {
    postal_code: "20169",
    zip_name: "haymarket, va",
  },
  {
    postal_code: "86326",
    zip_name: "cottonwood, az",
  },
  {
    postal_code: "90024",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "66044",
    zip_name: "lawrence, ks",
  },
  {
    postal_code: "3801",
    zip_name: "portsmouth, nh",
  },
  {
    postal_code: "33565",
    zip_name: "plant city, fl",
  },
  {
    postal_code: "90210",
    zip_name: "beverly hills, ca",
  },
  {
    postal_code: "38115",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "80530",
    zip_name: "frederick, co",
  },
  {
    postal_code: "6705",
    zip_name: "waterbury, ct",
  },
  {
    postal_code: "6468",
    zip_name: "monroe, ct",
  },
  {
    postal_code: "40175",
    zip_name: "vine grove, ky",
  },
  {
    postal_code: "55362",
    zip_name: "monticello, mn",
  },
  {
    postal_code: "27560",
    zip_name: "morrisville, nc",
  },
  {
    postal_code: "86351",
    zip_name: "sedona, az",
  },
  {
    postal_code: "75224",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "33174",
    zip_name: "miami, fl",
  },
  {
    postal_code: "91791",
    zip_name: "west covina, ca",
  },
  {
    postal_code: "94506",
    zip_name: "danville, ca",
  },
  {
    postal_code: "21403",
    zip_name: "annapolis, md",
  },
  {
    postal_code: "31411",
    zip_name: "savannah, ga",
  },
  {
    postal_code: "78207",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "34433",
    zip_name: "dunnellon, fl",
  },
  {
    postal_code: "67060",
    zip_name: "haysville, ks",
  },
  {
    postal_code: "84606",
    zip_name: "provo, ut",
  },
  {
    postal_code: "60008",
    zip_name: "rolling meadows, il",
  },
  {
    postal_code: "78412",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "55123",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "66203",
    zip_name: "shawnee, ks",
  },
  {
    postal_code: "92831",
    zip_name: "fullerton, ca",
  },
  {
    postal_code: "8087",
    zip_name: "tuckerton, nj",
  },
  {
    postal_code: "21075",
    zip_name: "elkridge, md",
  },
  {
    postal_code: "75238",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "75252",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "78336",
    zip_name: "aransas pass, tx",
  },
  {
    postal_code: "78229",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "34953",
    zip_name: "port saint lucie, fl",
  },
  {
    postal_code: "77459",
    zip_name: "missouri city, tx",
  },
  {
    postal_code: "32805",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "30265",
    zip_name: "newnan, ga",
  },
  {
    postal_code: "44663",
    zip_name: "new philadelphia, oh",
  },
  {
    postal_code: "70301",
    zip_name: "thibodaux, la",
  },
  {
    postal_code: "30528",
    zip_name: "cleveland, ga",
  },
  {
    postal_code: "15057",
    zip_name: "mc donald, pa",
  },
  {
    postal_code: "77904",
    zip_name: "victoria, tx",
  },
  {
    postal_code: "2919",
    zip_name: "johnston, ri",
  },
  {
    postal_code: "46342",
    zip_name: "hobart, in",
  },
  {
    postal_code: "27549",
    zip_name: "louisburg, nc",
  },
  {
    postal_code: "63110",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "94112",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "11225",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "78611",
    zip_name: "burnet, tx",
  },
  {
    postal_code: "11743",
    zip_name: "huntington, ny",
  },
  {
    postal_code: "55315",
    zip_name: "carver, mn",
  },
  {
    postal_code: "3254",
    zip_name: "moultonborough, nh",
  },
  {
    postal_code: "63126",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "76014",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "91762",
    zip_name: "ontario, ca",
  },
  {
    postal_code: "75103",
    zip_name: "canton, tx",
  },
  {
    postal_code: "35212",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "50219",
    zip_name: "pella, ia",
  },
  {
    postal_code: "56201",
    zip_name: "willmar, mn",
  },
  {
    postal_code: "66762",
    zip_name: "pittsburg, ks",
  },
  {
    postal_code: "33413",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "55042",
    zip_name: "lake elmo, mn",
  },
  {
    postal_code: "91722",
    zip_name: "covina, ca",
  },
  {
    postal_code: "78757",
    zip_name: "austin, tx",
  },
  {
    postal_code: "46222",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "70116",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "46214",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "20170",
    zip_name: "herndon, va",
  },
  {
    postal_code: "78602",
    zip_name: "bastrop, tx",
  },
  {
    postal_code: "55305",
    zip_name: "hopkins, mn",
  },
  {
    postal_code: "76208",
    zip_name: "denton, tx",
  },
  {
    postal_code: "55439",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "75225",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "63601",
    zip_name: "park hills, mo",
  },
  {
    postal_code: "44333",
    zip_name: "akron, oh",
  },
  {
    postal_code: "90039",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "6095",
    zip_name: "windsor, ct",
  },
  {
    postal_code: "11747",
    zip_name: "melville, ny",
  },
  {
    postal_code: "72204",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "90002",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "55306",
    zip_name: "burnsville, mn",
  },
  {
    postal_code: "15009",
    zip_name: "beaver, pa",
  },
  {
    postal_code: "33714",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "77062",
    zip_name: "houston, tx",
  },
  {
    postal_code: "2908",
    zip_name: "providence, ri",
  },
  {
    postal_code: "33315",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "97365",
    zip_name: "newport, or",
  },
  {
    postal_code: "46368",
    zip_name: "portage, in",
  },
  {
    postal_code: "94621",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "98502",
    zip_name: "olympia, wa",
  },
  {
    postal_code: "70510",
    zip_name: "abbeville, la",
  },
  {
    postal_code: "34753",
    zip_name: "mascotte, fl",
  },
  {
    postal_code: "98632",
    zip_name: "longview, wa",
  },
  {
    postal_code: "66215",
    zip_name: "lenexa, ks",
  },
  {
    postal_code: "78210",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "20190",
    zip_name: "reston, va",
  },
  {
    postal_code: "6513",
    zip_name: "new haven, ct",
  },
  {
    postal_code: "70520",
    zip_name: "carencro, la",
  },
  {
    postal_code: "6514",
    zip_name: "hamden, ct",
  },
  {
    postal_code: "6840",
    zip_name: "new canaan, ct",
  },
  {
    postal_code: "33778",
    zip_name: "largo, fl",
  },
  {
    postal_code: "30501",
    zip_name: "gainesville, ga",
  },
  {
    postal_code: "6062",
    zip_name: "plainville, ct",
  },
  {
    postal_code: "6905",
    zip_name: "stamford, ct",
  },
  {
    postal_code: "61455",
    zip_name: "macomb, il",
  },
  {
    postal_code: "30107",
    zip_name: "ball ground, ga",
  },
  {
    postal_code: "44139",
    zip_name: "solon, oh",
  },
  {
    postal_code: "54548",
    zip_name: "minocqua, wi",
  },
  {
    postal_code: "25302",
    zip_name: "charleston, wv",
  },
  {
    postal_code: "86327",
    zip_name: "dewey, az",
  },
  {
    postal_code: "5149",
    zip_name: "ludlow, vt",
  },
  {
    postal_code: "38139",
    zip_name: "germantown, tn",
  },
  {
    postal_code: "60521",
    zip_name: "hinsdale, il",
  },
  {
    postal_code: "43223",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "77303",
    zip_name: "conroe, tx",
  },
  {
    postal_code: "60465",
    zip_name: "palos hills, il",
  },
  {
    postal_code: "29360",
    zip_name: "laurens, sc",
  },
  {
    postal_code: "99212",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "45246",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "65072",
    zip_name: "rocky mount, mo",
  },
  {
    postal_code: "90044",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "20187",
    zip_name: "warrenton, va",
  },
  {
    postal_code: "28152",
    zip_name: "shelby, nc",
  },
  {
    postal_code: "10007",
    zip_name: "new york, ny",
  },
  {
    postal_code: "55346",
    zip_name: "eden prairie, mn",
  },
  {
    postal_code: "55350",
    zip_name: "hutchinson, mn",
  },
  {
    postal_code: "28164",
    zip_name: "stanley, nc",
  },
  {
    postal_code: "61520",
    zip_name: "canton, il",
  },
  {
    postal_code: "56073",
    zip_name: "new ulm, mn",
  },
  {
    postal_code: "88061",
    zip_name: "silver city, nm",
  },
  {
    postal_code: "55075",
    zip_name: "south saint paul, mn",
  },
  {
    postal_code: "61111",
    zip_name: "loves park, il",
  },
  {
    postal_code: "76655",
    zip_name: "lorena, tx",
  },
  {
    postal_code: "93442",
    zip_name: "morro bay, ca",
  },
  {
    postal_code: "22406",
    zip_name: "fredericksburg, va",
  },
  {
    postal_code: "91040",
    zip_name: "sunland, ca",
  },
  {
    postal_code: "65355",
    zip_name: "warsaw, mo",
  },
  {
    postal_code: "53589",
    zip_name: "stoughton, wi",
  },
  {
    postal_code: "12208",
    zip_name: "albany, ny",
  },
  {
    postal_code: "46011",
    zip_name: "anderson, in",
  },
  {
    postal_code: "30215",
    zip_name: "fayetteville, ga",
  },
  {
    postal_code: "34602",
    zip_name: "brooksville, fl",
  },
  {
    postal_code: "92371",
    zip_name: "phelan, ca",
  },
  {
    postal_code: "76104",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "44054",
    zip_name: "sheffield lake, oh",
  },
  {
    postal_code: "62521",
    zip_name: "decatur, il",
  },
  {
    postal_code: "37083",
    zip_name: "lafayette, tn",
  },
  {
    postal_code: "91770",
    zip_name: "rosemead, ca",
  },
  {
    postal_code: "80226",
    zip_name: "denver, co",
  },
  {
    postal_code: "2813",
    zip_name: "charlestown, ri",
  },
  {
    postal_code: "33812",
    zip_name: "lakeland, fl",
  },
  {
    postal_code: "92543",
    zip_name: "hemet, ca",
  },
  {
    postal_code: "48093",
    zip_name: "warren, mi",
  },
  {
    postal_code: "27504",
    zip_name: "benson, nc",
  },
  {
    postal_code: "77054",
    zip_name: "houston, tx",
  },
  {
    postal_code: "90277",
    zip_name: "redondo beach, ca",
  },
  {
    postal_code: "78259",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "67601",
    zip_name: "hays, ks",
  },
  {
    postal_code: "77014",
    zip_name: "houston, tx",
  },
  {
    postal_code: "85297",
    zip_name: "gilbert, az",
  },
  {
    postal_code: "28212",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "6042",
    zip_name: "manchester, ct",
  },
  {
    postal_code: "34688",
    zip_name: "tarpon springs, fl",
  },
  {
    postal_code: "6704",
    zip_name: "waterbury, ct",
  },
  {
    postal_code: "65584",
    zip_name: "saint robert, mo",
  },
  {
    postal_code: "17019",
    zip_name: "dillsburg, pa",
  },
  {
    postal_code: "93940",
    zip_name: "monterey, ca",
  },
  {
    postal_code: "11106",
    zip_name: "astoria, ny",
  },
  {
    postal_code: "61548",
    zip_name: "metamora, il",
  },
  {
    postal_code: "20705",
    zip_name: "beltsville, md",
  },
  {
    postal_code: "43219",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "6070",
    zip_name: "simsbury, ct",
  },
  {
    postal_code: "55364",
    zip_name: "mound, mn",
  },
  {
    postal_code: "55102",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "93212",
    zip_name: "corcoran, ca",
  },
  {
    postal_code: "85208",
    zip_name: "mesa, az",
  },
  {
    postal_code: "77336",
    zip_name: "huffman, tx",
  },
  {
    postal_code: "94590",
    zip_name: "vallejo, ca",
  },
  {
    postal_code: "93543",
    zip_name: "littlerock, ca",
  },
  {
    postal_code: "21229",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "89052",
    zip_name: "henderson, nv",
  },
  {
    postal_code: "94025",
    zip_name: "menlo park, ca",
  },
  {
    postal_code: "90292",
    zip_name: "marina del rey, ca",
  },
  {
    postal_code: "30345",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "33028",
    zip_name: "pembroke pines, fl",
  },
  {
    postal_code: "91784",
    zip_name: "upland, ca",
  },
  {
    postal_code: "30666",
    zip_name: "statham, ga",
  },
  {
    postal_code: "6804",
    zip_name: "brookfield, ct",
  },
  {
    postal_code: "27278",
    zip_name: "hillsborough, nc",
  },
  {
    postal_code: "54806",
    zip_name: "ashland, wi",
  },
  {
    postal_code: "84094",
    zip_name: "sandy, ut",
  },
  {
    postal_code: "92840",
    zip_name: "garden grove, ca",
  },
  {
    postal_code: "94015",
    zip_name: "daly city, ca",
  },
  {
    postal_code: "95228",
    zip_name: "copperopolis, ca",
  },
  {
    postal_code: "77015",
    zip_name: "houston, tx",
  },
  {
    postal_code: "80215",
    zip_name: "denver, co",
  },
  {
    postal_code: "45377",
    zip_name: "vandalia, oh",
  },
  {
    postal_code: "44857",
    zip_name: "norwalk, oh",
  },
  {
    postal_code: "23124",
    zip_name: "new kent, va",
  },
  {
    postal_code: "28801",
    zip_name: "asheville, nc",
  },
  {
    postal_code: "42141",
    zip_name: "glasgow, ky",
  },
  {
    postal_code: "37745",
    zip_name: "greeneville, tn",
  },
  {
    postal_code: "11714",
    zip_name: "bethpage, ny",
  },
  {
    postal_code: "74432",
    zip_name: "eufaula, ok",
  },
  {
    postal_code: "78242",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "92618",
    zip_name: "irvine, ca",
  },
  {
    postal_code: "39211",
    zip_name: "jackson, ms",
  },
  {
    postal_code: "64151",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "20815",
    zip_name: "chevy chase, md",
  },
  {
    postal_code: "2904",
    zip_name: "providence, ri",
  },
  {
    postal_code: "55447",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "43160",
    zip_name: "washington court house, oh",
  },
  {
    postal_code: "78163",
    zip_name: "bulverde, tx",
  },
  {
    postal_code: "27701",
    zip_name: "durham, nc",
  },
  {
    postal_code: "44052",
    zip_name: "lorain, oh",
  },
  {
    postal_code: "45305",
    zip_name: "bellbrook, oh",
  },
  {
    postal_code: "48906",
    zip_name: "lansing, mi",
  },
  {
    postal_code: "74401",
    zip_name: "muskogee, ok",
  },
  {
    postal_code: "74104",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "20814",
    zip_name: "bethesda, md",
  },
  {
    postal_code: "14904",
    zip_name: "elmira, ny",
  },
  {
    postal_code: "15129",
    zip_name: "south park, pa",
  },
  {
    postal_code: "68128",
    zip_name: "la vista, ne",
  },
  {
    postal_code: "60304",
    zip_name: "oak park, il",
  },
  {
    postal_code: "63051",
    zip_name: "house springs, mo",
  },
  {
    postal_code: "91941",
    zip_name: "la mesa, ca",
  },
  {
    postal_code: "22101",
    zip_name: "mc lean, va",
  },
  {
    postal_code: "92833",
    zip_name: "fullerton, ca",
  },
  {
    postal_code: "38821",
    zip_name: "amory, ms",
  },
  {
    postal_code: "37742",
    zip_name: "greenback, tn",
  },
  {
    postal_code: "11710",
    zip_name: "bellmore, ny",
  },
  {
    postal_code: "19426",
    zip_name: "collegeville, pa",
  },
  {
    postal_code: "60639",
    zip_name: "chicago, il",
  },
  {
    postal_code: "28334",
    zip_name: "dunn, nc",
  },
  {
    postal_code: "6825",
    zip_name: "fairfield, ct",
  },
  {
    postal_code: "80917",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "92627",
    zip_name: "costa mesa, ca",
  },
  {
    postal_code: "44305",
    zip_name: "akron, oh",
  },
  {
    postal_code: "33613",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "76118",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "15012",
    zip_name: "belle vernon, pa",
  },
  {
    postal_code: "53718",
    zip_name: "madison, wi",
  },
  {
    postal_code: "60914",
    zip_name: "bourbonnais, il",
  },
  {
    postal_code: "95210",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "74604",
    zip_name: "ponca city, ok",
  },
  {
    postal_code: "94546",
    zip_name: "castro valley, ca",
  },
  {
    postal_code: "95946",
    zip_name: "penn valley, ca",
  },
  {
    postal_code: "60467",
    zip_name: "orland park, il",
  },
  {
    postal_code: "94601",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "40222",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "55343",
    zip_name: "hopkins, mn",
  },
  {
    postal_code: "37857",
    zip_name: "rogersville, tn",
  },
  {
    postal_code: "15613",
    zip_name: "apollo, pa",
  },
  {
    postal_code: "6470",
    zip_name: "newtown, ct",
  },
  {
    postal_code: "37381",
    zip_name: "spring city, tn",
  },
  {
    postal_code: "77302",
    zip_name: "conroe, tx",
  },
  {
    postal_code: "45209",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "95819",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "68130",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "32801",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "15226",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "30504",
    zip_name: "gainesville, ga",
  },
  {
    postal_code: "6040",
    zip_name: "manchester, ct",
  },
  {
    postal_code: "62249",
    zip_name: "highland, il",
  },
  {
    postal_code: "93704",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "62294",
    zip_name: "troy, il",
  },
  {
    postal_code: "75041",
    zip_name: "garland, tx",
  },
  {
    postal_code: "44057",
    zip_name: "madison, oh",
  },
  {
    postal_code: "34610",
    zip_name: "spring hill, fl",
  },
  {
    postal_code: "83858",
    zip_name: "rathdrum, id",
  },
  {
    postal_code: "84335",
    zip_name: "smithfield, ut",
  },
  {
    postal_code: "15218",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "11725",
    zip_name: "commack, ny",
  },
  {
    postal_code: "12209",
    zip_name: "albany, ny",
  },
  {
    postal_code: "93033",
    zip_name: "oxnard, ca",
  },
  {
    postal_code: "39507",
    zip_name: "gulfport, ms",
  },
  {
    postal_code: "33135",
    zip_name: "miami, fl",
  },
  {
    postal_code: "70128",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "37643",
    zip_name: "elizabethton, tn",
  },
  {
    postal_code: "11967",
    zip_name: "shirley, ny",
  },
  {
    postal_code: "37874",
    zip_name: "sweetwater, tn",
  },
  {
    postal_code: "30601",
    zip_name: "athens, ga",
  },
  {
    postal_code: "82414",
    zip_name: "cody, wy",
  },
  {
    postal_code: "13901",
    zip_name: "binghamton, ny",
  },
  {
    postal_code: "85716",
    zip_name: "tucson, az",
  },
  {
    postal_code: "15216",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "95838",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "92804",
    zip_name: "anaheim, ca",
  },
  {
    postal_code: "30110",
    zip_name: "bremen, ga",
  },
  {
    postal_code: "34222",
    zip_name: "ellenton, fl",
  },
  {
    postal_code: "42718",
    zip_name: "campbellsville, ky",
  },
  {
    postal_code: "68507",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "94608",
    zip_name: "emeryville, ca",
  },
  {
    postal_code: "30656",
    zip_name: "monroe, ga",
  },
  {
    postal_code: "76707",
    zip_name: "waco, tx",
  },
  {
    postal_code: "91711",
    zip_name: "claremont, ca",
  },
  {
    postal_code: "12308",
    zip_name: "schenectady, ny",
  },
  {
    postal_code: "26508",
    zip_name: "morgantown, wv",
  },
  {
    postal_code: "29672",
    zip_name: "seneca, sc",
  },
  {
    postal_code: "91006",
    zip_name: "arcadia, ca",
  },
  {
    postal_code: "11233",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "13850",
    zip_name: "vestal, ny",
  },
  {
    postal_code: "84119",
    zip_name: "west valley city, ut",
  },
  {
    postal_code: "11791",
    zip_name: "syosset, ny",
  },
  {
    postal_code: "19083",
    zip_name: "havertown, pa",
  },
  {
    postal_code: "94578",
    zip_name: "san leandro, ca",
  },
  {
    postal_code: "60803",
    zip_name: "alsip, il",
  },
  {
    postal_code: "93436",
    zip_name: "lompoc, ca",
  },
  {
    postal_code: "11228",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "10009",
    zip_name: "new york, ny",
  },
  {
    postal_code: "79106",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "85008",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "96746",
    zip_name: "kapaa, hi",
  },
  {
    postal_code: "50003",
    zip_name: "adel, ia",
  },
  {
    postal_code: "74127",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "21117",
    zip_name: "owings mills, md",
  },
  {
    postal_code: "95242",
    zip_name: "lodi, ca",
  },
  {
    postal_code: "91214",
    zip_name: "la crescenta, ca",
  },
  {
    postal_code: "95377",
    zip_name: "tracy, ca",
  },
  {
    postal_code: "85282",
    zip_name: "tempe, az",
  },
  {
    postal_code: "56031",
    zip_name: "fairmont, mn",
  },
  {
    postal_code: "12804",
    zip_name: "queensbury, ny",
  },
  {
    postal_code: "2631",
    zip_name: "brewster, ma",
  },
  {
    postal_code: "12839",
    zip_name: "hudson falls, ny",
  },
  {
    postal_code: "61802",
    zip_name: "urbana, il",
  },
  {
    postal_code: "20852",
    zip_name: "rockville, md",
  },
  {
    postal_code: "21774",
    zip_name: "new market, md",
  },
  {
    postal_code: "33549",
    zip_name: "lutz, fl",
  },
  {
    postal_code: "91406",
    zip_name: "van nuys, ca",
  },
  {
    postal_code: "18504",
    zip_name: "scranton, pa",
  },
  {
    postal_code: "93560",
    zip_name: "rosamond, ca",
  },
  {
    postal_code: "56377",
    zip_name: "sartell, mn",
  },
  {
    postal_code: "85705",
    zip_name: "tucson, az",
  },
  {
    postal_code: "85028",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "59044",
    zip_name: "laurel, mt",
  },
  {
    postal_code: "84780",
    zip_name: "washington, ut",
  },
  {
    postal_code: "93060",
    zip_name: "santa paula, ca",
  },
  {
    postal_code: "37663",
    zip_name: "kingsport, tn",
  },
  {
    postal_code: "37334",
    zip_name: "fayetteville, tn",
  },
  {
    postal_code: "60404",
    zip_name: "shorewood, il",
  },
  {
    postal_code: "95842",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "55430",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "43215",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "49057",
    zip_name: "hartford, mi",
  },
  {
    postal_code: "55352",
    zip_name: "jordan, mn",
  },
  {
    postal_code: "60191",
    zip_name: "wood dale, il",
  },
  {
    postal_code: "84102",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "46311",
    zip_name: "dyer, in",
  },
  {
    postal_code: "29488",
    zip_name: "walterboro, sc",
  },
  {
    postal_code: "55398",
    zip_name: "zimmerman, mn",
  },
  {
    postal_code: "90069",
    zip_name: "west hollywood, ca",
  },
  {
    postal_code: "85307",
    zip_name: "glendale, az",
  },
  {
    postal_code: "50314",
    zip_name: "des moines, ia",
  },
  {
    postal_code: "77059",
    zip_name: "houston, tx",
  },
  {
    postal_code: "28613",
    zip_name: "conover, nc",
  },
  {
    postal_code: "46268",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "77025",
    zip_name: "houston, tx",
  },
  {
    postal_code: "85302",
    zip_name: "glendale, az",
  },
  {
    postal_code: "11703",
    zip_name: "north babylon, ny",
  },
  {
    postal_code: "12414",
    zip_name: "catskill, ny",
  },
  {
    postal_code: "80549",
    zip_name: "wellington, co",
  },
  {
    postal_code: "26104",
    zip_name: "parkersburg, wv",
  },
  {
    postal_code: "78121",
    zip_name: "la vernia, tx",
  },
  {
    postal_code: "44144",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "94062",
    zip_name: "redwood city, ca",
  },
  {
    postal_code: "55426",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "21401",
    zip_name: "annapolis, md",
  },
  {
    postal_code: "97741",
    zip_name: "madras, or",
  },
  {
    postal_code: "84124",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "20886",
    zip_name: "montgomery village, md",
  },
  {
    postal_code: "60706",
    zip_name: "harwood heights, il",
  },
  {
    postal_code: "75050",
    zip_name: "grand prairie, tx",
  },
  {
    postal_code: "45245",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "94607",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "68022",
    zip_name: "elkhorn, ne",
  },
  {
    postal_code: "16105",
    zip_name: "new castle, pa",
  },
  {
    postal_code: "48706",
    zip_name: "bay city, mi",
  },
  {
    postal_code: "10036",
    zip_name: "new york, ny",
  },
  {
    postal_code: "2860",
    zip_name: "pawtucket, ri",
  },
  {
    postal_code: "11803",
    zip_name: "plainview, ny",
  },
  {
    postal_code: "83001",
    zip_name: "jackson, wy",
  },
  {
    postal_code: "94114",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "64155",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "24382",
    zip_name: "wytheville, va",
  },
  {
    postal_code: "65605",
    zip_name: "aurora, mo",
  },
  {
    postal_code: "95959",
    zip_name: "nevada city, ca",
  },
  {
    postal_code: "85301",
    zip_name: "glendale, az",
  },
  {
    postal_code: "12534",
    zip_name: "hudson, ny",
  },
  {
    postal_code: "83340",
    zip_name: "ketchum, id",
  },
  {
    postal_code: "44224",
    zip_name: "stow, oh",
  },
  {
    postal_code: "94043",
    zip_name: "mountain view, ca",
  },
  {
    postal_code: "90732",
    zip_name: "san pedro, ca",
  },
  {
    postal_code: "78202",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "30187",
    zip_name: "winston, ga",
  },
  {
    postal_code: "33004",
    zip_name: "dania, fl",
  },
  {
    postal_code: "83333",
    zip_name: "hailey, id",
  },
  {
    postal_code: "94505",
    zip_name: "discovery bay, ca",
  },
  {
    postal_code: "65619",
    zip_name: "brookline, mo",
  },
  {
    postal_code: "18411",
    zip_name: "clarks summit, pa",
  },
  {
    postal_code: "78749",
    zip_name: "austin, tx",
  },
  {
    postal_code: "64080",
    zip_name: "pleasant hill, mo",
  },
  {
    postal_code: "95120",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "94539",
    zip_name: "fremont, ca",
  },
  {
    postal_code: "37748",
    zip_name: "harriman, tn",
  },
  {
    postal_code: "84104",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "21212",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "93907",
    zip_name: "salinas, ca",
  },
  {
    postal_code: "31404",
    zip_name: "savannah, ga",
  },
  {
    postal_code: "45233",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "75215",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "98332",
    zip_name: "gig harbor, wa",
  },
  {
    postal_code: "33898",
    zip_name: "lake wales, fl",
  },
  {
    postal_code: "95136",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "77520",
    zip_name: "baytown, tx",
  },
  {
    postal_code: "28501",
    zip_name: "kinston, nc",
  },
  {
    postal_code: "46176",
    zip_name: "shelbyville, in",
  },
  {
    postal_code: "19958",
    zip_name: "lewes, de",
  },
  {
    postal_code: "40211",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "60459",
    zip_name: "burbank, il",
  },
  {
    postal_code: "75231",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "34604",
    zip_name: "brooksville, fl",
  },
  {
    postal_code: "95660",
    zip_name: "north highlands, ca",
  },
  {
    postal_code: "95014",
    zip_name: "cupertino, ca",
  },
  {
    postal_code: "23075",
    zip_name: "henrico, va",
  },
  {
    postal_code: "16117",
    zip_name: "ellwood city, pa",
  },
  {
    postal_code: "77053",
    zip_name: "houston, tx",
  },
  {
    postal_code: "95205",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "70501",
    zip_name: "lafayette, la",
  },
  {
    postal_code: "38556",
    zip_name: "jamestown, tn",
  },
  {
    postal_code: "55092",
    zip_name: "wyoming, mn",
  },
  {
    postal_code: "10301",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "2601",
    zip_name: "hyannis, ma",
  },
  {
    postal_code: "97103",
    zip_name: "astoria, or",
  },
  {
    postal_code: "63401",
    zip_name: "hannibal, mo",
  },
  {
    postal_code: "46256",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "46184",
    zip_name: "whiteland, in",
  },
  {
    postal_code: "75051",
    zip_name: "grand prairie, tx",
  },
  {
    postal_code: "94611",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "63640",
    zip_name: "farmington, mo",
  },
  {
    postal_code: "54311",
    zip_name: "green bay, wi",
  },
  {
    postal_code: "85284",
    zip_name: "tempe, az",
  },
  {
    postal_code: "60083",
    zip_name: "wadsworth, il",
  },
  {
    postal_code: "8234",
    zip_name: "egg harbor township, nj",
  },
  {
    postal_code: "38355",
    zip_name: "medina, tn",
  },
  {
    postal_code: "59808",
    zip_name: "missoula, mt",
  },
  {
    postal_code: "76209",
    zip_name: "denton, tx",
  },
  {
    postal_code: "48182",
    zip_name: "temperance, mi",
  },
  {
    postal_code: "28594",
    zip_name: "emerald isle, nc",
  },
  {
    postal_code: "87544",
    zip_name: "los alamos, nm",
  },
  {
    postal_code: "53597",
    zip_name: "waunakee, wi",
  },
  {
    postal_code: "35023",
    zip_name: "bessemer, al",
  },
  {
    postal_code: "28262",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "77502",
    zip_name: "pasadena, tx",
  },
  {
    postal_code: "44903",
    zip_name: "mansfield, oh",
  },
  {
    postal_code: "84319",
    zip_name: "hyrum, ut",
  },
  {
    postal_code: "47362",
    zip_name: "new castle, in",
  },
  {
    postal_code: "68510",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "30161",
    zip_name: "rome, ga",
  },
  {
    postal_code: "77320",
    zip_name: "huntsville, tx",
  },
  {
    postal_code: "91320",
    zip_name: "newbury park, ca",
  },
  {
    postal_code: "95116",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "95602",
    zip_name: "auburn, ca",
  },
  {
    postal_code: "28791",
    zip_name: "hendersonville, nc",
  },
  {
    postal_code: "44149",
    zip_name: "strongsville, oh",
  },
  {
    postal_code: "84105",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "94404",
    zip_name: "san mateo, ca",
  },
  {
    postal_code: "22551",
    zip_name: "spotsylvania, va",
  },
  {
    postal_code: "80301",
    zip_name: "boulder, co",
  },
  {
    postal_code: "85266",
    zip_name: "scottsdale, az",
  },
  {
    postal_code: "33405",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "45237",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "19050",
    zip_name: "lansdowne, pa",
  },
  {
    postal_code: "77063",
    zip_name: "houston, tx",
  },
  {
    postal_code: "60403",
    zip_name: "crest hill, il",
  },
  {
    postal_code: "46240",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "20706",
    zip_name: "lanham, md",
  },
  {
    postal_code: "45248",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "27501",
    zip_name: "angier, nc",
  },
  {
    postal_code: "60805",
    zip_name: "evergreen park, il",
  },
  {
    postal_code: "38119",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "33982",
    zip_name: "punta gorda, fl",
  },
  {
    postal_code: "76039",
    zip_name: "euless, tx",
  },
  {
    postal_code: "11203",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "6066",
    zip_name: "vernon rockville, ct",
  },
  {
    postal_code: "98115",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "43078",
    zip_name: "urbana, oh",
  },
  {
    postal_code: "60455",
    zip_name: "bridgeview, il",
  },
  {
    postal_code: "44410",
    zip_name: "cortland, oh",
  },
  {
    postal_code: "95662",
    zip_name: "orangevale, ca",
  },
  {
    postal_code: "95476",
    zip_name: "sonoma, ca",
  },
  {
    postal_code: "70005",
    zip_name: "metairie, la",
  },
  {
    postal_code: "11753",
    zip_name: "jericho, ny",
  },
  {
    postal_code: "52807",
    zip_name: "davenport, ia",
  },
  {
    postal_code: "92373",
    zip_name: "redlands, ca",
  },
  {
    postal_code: "48625",
    zip_name: "harrison, mi",
  },
  {
    postal_code: "53714",
    zip_name: "madison, wi",
  },
  {
    postal_code: "2909",
    zip_name: "providence, ri",
  },
  {
    postal_code: "55105",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "30168",
    zip_name: "austell, ga",
  },
  {
    postal_code: "44132",
    zip_name: "euclid, oh",
  },
  {
    postal_code: "90016",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "38618",
    zip_name: "coldwater, ms",
  },
  {
    postal_code: "33771",
    zip_name: "largo, fl",
  },
  {
    postal_code: "55014",
    zip_name: "circle pines, mn",
  },
  {
    postal_code: "60523",
    zip_name: "oak brook, il",
  },
  {
    postal_code: "66207",
    zip_name: "overland park, ks",
  },
  {
    postal_code: "37110",
    zip_name: "mcminnville, tn",
  },
  {
    postal_code: "43220",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "6413",
    zip_name: "clinton, ct",
  },
  {
    postal_code: "94801",
    zip_name: "richmond, ca",
  },
  {
    postal_code: "37321",
    zip_name: "dayton, tn",
  },
  {
    postal_code: "78212",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "27616",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "46278",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "75142",
    zip_name: "kaufman, tx",
  },
  {
    postal_code: "20175",
    zip_name: "leesburg, va",
  },
  {
    postal_code: "50309",
    zip_name: "des moines, ia",
  },
  {
    postal_code: "45405",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "35222",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "20657",
    zip_name: "lusby, md",
  },
  {
    postal_code: "16823",
    zip_name: "bellefonte, pa",
  },
  {
    postal_code: "64063",
    zip_name: "lees summit, mo",
  },
  {
    postal_code: "50702",
    zip_name: "waterloo, ia",
  },
  {
    postal_code: "6483",
    zip_name: "seymour, ct",
  },
  {
    postal_code: "94110",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "95380",
    zip_name: "turlock, ca",
  },
  {
    postal_code: "90066",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "45505",
    zip_name: "springfield, oh",
  },
  {
    postal_code: "78550",
    zip_name: "harlingen, tx",
  },
  {
    postal_code: "43611",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "94403",
    zip_name: "san mateo, ca",
  },
  {
    postal_code: "46013",
    zip_name: "anderson, in",
  },
  {
    postal_code: "21037",
    zip_name: "edgewater, md",
  },
  {
    postal_code: "37067",
    zip_name: "franklin, tn",
  },
  {
    postal_code: "44092",
    zip_name: "wickliffe, oh",
  },
  {
    postal_code: "80621",
    zip_name: "fort lupton, co",
  },
  {
    postal_code: "85250",
    zip_name: "scottsdale, az",
  },
  {
    postal_code: "33473",
    zip_name: "boynton beach, fl",
  },
  {
    postal_code: "6854",
    zip_name: "norwalk, ct",
  },
  {
    postal_code: "70401",
    zip_name: "hammond, la",
  },
  {
    postal_code: "84043",
    zip_name: "lehi, ut",
  },
  {
    postal_code: "70123",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "52577",
    zip_name: "oskaloosa, ia",
  },
  {
    postal_code: "43331",
    zip_name: "lakeview, oh",
  },
  {
    postal_code: "55328",
    zip_name: "delano, mn",
  },
  {
    postal_code: "91761",
    zip_name: "ontario, ca",
  },
  {
    postal_code: "27012",
    zip_name: "clemmons, nc",
  },
  {
    postal_code: "34453",
    zip_name: "inverness, fl",
  },
  {
    postal_code: "31024",
    zip_name: "eatonton, ga",
  },
  {
    postal_code: "11021",
    zip_name: "great neck, ny",
  },
  {
    postal_code: "6107",
    zip_name: "west hartford, ct",
  },
  {
    postal_code: "77091",
    zip_name: "houston, tx",
  },
  {
    postal_code: "8401",
    zip_name: "atlantic city, nj",
  },
  {
    postal_code: "92374",
    zip_name: "redlands, ca",
  },
  {
    postal_code: "28358",
    zip_name: "lumberton, nc",
  },
  {
    postal_code: "66209",
    zip_name: "leawood, ks",
  },
  {
    postal_code: "64075",
    zip_name: "oak grove, mo",
  },
  {
    postal_code: "46375",
    zip_name: "schererville, in",
  },
  {
    postal_code: "91786",
    zip_name: "upland, ca",
  },
  {
    postal_code: "85367",
    zip_name: "yuma, az",
  },
  {
    postal_code: "11801",
    zip_name: "hicksville, ny",
  },
  {
    postal_code: "32169",
    zip_name: "new smyrna beach, fl",
  },
  {
    postal_code: "93720",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "92821",
    zip_name: "brea, ca",
  },
  {
    postal_code: "61032",
    zip_name: "freeport, il",
  },
  {
    postal_code: "16101",
    zip_name: "new castle, pa",
  },
  {
    postal_code: "64085",
    zip_name: "richmond, mo",
  },
  {
    postal_code: "99203",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "75482",
    zip_name: "sulphur springs, tx",
  },
  {
    postal_code: "55040",
    zip_name: "isanti, mn",
  },
  {
    postal_code: "96722",
    zip_name: "princeville, hi",
  },
  {
    postal_code: "21617",
    zip_name: "centreville, md",
  },
  {
    postal_code: "84604",
    zip_name: "provo, ut",
  },
  {
    postal_code: "98026",
    zip_name: "edmonds, wa",
  },
  {
    postal_code: "44446",
    zip_name: "niles, oh",
  },
  {
    postal_code: "80908",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "97739",
    zip_name: "la pine, or",
  },
  {
    postal_code: "92653",
    zip_name: "laguna hills, ca",
  },
  {
    postal_code: "15106",
    zip_name: "carnegie, pa",
  },
  {
    postal_code: "77327",
    zip_name: "cleveland, tx",
  },
  {
    postal_code: "19702",
    zip_name: "newark, de",
  },
  {
    postal_code: "78744",
    zip_name: "austin, tx",
  },
  {
    postal_code: "37217",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "32656",
    zip_name: "keystone heights, fl",
  },
  {
    postal_code: "45406",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "77051",
    zip_name: "houston, tx",
  },
  {
    postal_code: "95726",
    zip_name: "pollock pines, ca",
  },
  {
    postal_code: "92646",
    zip_name: "huntington beach, ca",
  },
  {
    postal_code: "95135",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "6109",
    zip_name: "wethersfield, ct",
  },
  {
    postal_code: "85749",
    zip_name: "tucson, az",
  },
  {
    postal_code: "60118",
    zip_name: "dundee, il",
  },
  {
    postal_code: "43537",
    zip_name: "maumee, oh",
  },
  {
    postal_code: "16508",
    zip_name: "erie, pa",
  },
  {
    postal_code: "90805",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "55056",
    zip_name: "north branch, mn",
  },
  {
    postal_code: "33542",
    zip_name: "zephyrhills, fl",
  },
  {
    postal_code: "46151",
    zip_name: "martinsville, in",
  },
  {
    postal_code: "64015",
    zip_name: "blue springs, mo",
  },
  {
    postal_code: "21222",
    zip_name: "dundalk, md",
  },
  {
    postal_code: "44830",
    zip_name: "fostoria, oh",
  },
  {
    postal_code: "44483",
    zip_name: "warren, oh",
  },
  {
    postal_code: "60554",
    zip_name: "sugar grove, il",
  },
  {
    postal_code: "35210",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "90265",
    zip_name: "malibu, ca",
  },
  {
    postal_code: "19601",
    zip_name: "reading, pa",
  },
  {
    postal_code: "33875",
    zip_name: "sebring, fl",
  },
  {
    postal_code: "37771",
    zip_name: "lenoir city, tn",
  },
  {
    postal_code: "27205",
    zip_name: "asheboro, nc",
  },
  {
    postal_code: "94901",
    zip_name: "san rafael, ca",
  },
  {
    postal_code: "38106",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "63138",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "63084",
    zip_name: "union, mo",
  },
  {
    postal_code: "98264",
    zip_name: "lynden, wa",
  },
  {
    postal_code: "60087",
    zip_name: "waukegan, il",
  },
  {
    postal_code: "63042",
    zip_name: "hazelwood, mo",
  },
  {
    postal_code: "60185",
    zip_name: "west chicago, il",
  },
  {
    postal_code: "91745",
    zip_name: "hacienda heights, ca",
  },
  {
    postal_code: "29936",
    zip_name: "ridgeland, sc",
  },
  {
    postal_code: "75001",
    zip_name: "addison, tx",
  },
  {
    postal_code: "84057",
    zip_name: "orem, ut",
  },
  {
    postal_code: "76504",
    zip_name: "temple, tx",
  },
  {
    postal_code: "87113",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "87508",
    zip_name: "santa fe, nm",
  },
  {
    postal_code: "75093",
    zip_name: "plano, tx",
  },
  {
    postal_code: "80521",
    zip_name: "fort collins, co",
  },
  {
    postal_code: "95677",
    zip_name: "rocklin, ca",
  },
  {
    postal_code: "50109",
    zip_name: "granger, ia",
  },
  {
    postal_code: "33773",
    zip_name: "largo, fl",
  },
  {
    postal_code: "85392",
    zip_name: "avondale, az",
  },
  {
    postal_code: "94558",
    zip_name: "napa, ca",
  },
  {
    postal_code: "15147",
    zip_name: "verona, pa",
  },
  {
    postal_code: "15132",
    zip_name: "mckeesport, pa",
  },
  {
    postal_code: "97133",
    zip_name: "north plains, or",
  },
  {
    postal_code: "19096",
    zip_name: "wynnewood, pa",
  },
  {
    postal_code: "86004",
    zip_name: "flagstaff, az",
  },
  {
    postal_code: "92705",
    zip_name: "santa ana, ca",
  },
  {
    postal_code: "94401",
    zip_name: "san mateo, ca",
  },
  {
    postal_code: "6001",
    zip_name: "avon, ct",
  },
  {
    postal_code: "34950",
    zip_name: "fort pierce, fl",
  },
  {
    postal_code: "78148",
    zip_name: "universal city, tx",
  },
  {
    postal_code: "10304",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "22102",
    zip_name: "mc lean, va",
  },
  {
    postal_code: "78227",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "12033",
    zip_name: "castleton on hudson, ny",
  },
  {
    postal_code: "25404",
    zip_name: "martinsburg, wv",
  },
  {
    postal_code: "94806",
    zip_name: "san pablo, ca",
  },
  {
    postal_code: "34452",
    zip_name: "inverness, fl",
  },
  {
    postal_code: "68106",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "55444",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "61036",
    zip_name: "galena, il",
  },
  {
    postal_code: "33406",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "60433",
    zip_name: "joliet, il",
  },
  {
    postal_code: "77069",
    zip_name: "houston, tx",
  },
  {
    postal_code: "91301",
    zip_name: "agoura hills, ca",
  },
  {
    postal_code: "43212",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "12508",
    zip_name: "beacon, ny",
  },
  {
    postal_code: "20748",
    zip_name: "temple hills, md",
  },
  {
    postal_code: "62948",
    zip_name: "herrin, il",
  },
  {
    postal_code: "21044",
    zip_name: "columbia, md",
  },
  {
    postal_code: "77043",
    zip_name: "houston, tx",
  },
  {
    postal_code: "78657",
    zip_name: "horseshoe bay, tx",
  },
  {
    postal_code: "43113",
    zip_name: "circleville, oh",
  },
  {
    postal_code: "97305",
    zip_name: "salem, or",
  },
  {
    postal_code: "11205",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "50325",
    zip_name: "clive, ia",
  },
  {
    postal_code: "85201",
    zip_name: "mesa, az",
  },
  {
    postal_code: "95742",
    zip_name: "rancho cordova, ca",
  },
  {
    postal_code: "20721",
    zip_name: "bowie, md",
  },
  {
    postal_code: "60081",
    zip_name: "spring grove, il",
  },
  {
    postal_code: "75024",
    zip_name: "plano, tx",
  },
  {
    postal_code: "10305",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "75060",
    zip_name: "irving, tx",
  },
  {
    postal_code: "76010",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "54501",
    zip_name: "rhinelander, wi",
  },
  {
    postal_code: "77033",
    zip_name: "houston, tx",
  },
  {
    postal_code: "60457",
    zip_name: "hickory hills, il",
  },
  {
    postal_code: "94086",
    zip_name: "sunnyvale, ca",
  },
  {
    postal_code: "90631",
    zip_name: "la habra, ca",
  },
  {
    postal_code: "60169",
    zip_name: "hoffman estates, il",
  },
  {
    postal_code: "53716",
    zip_name: "madison, wi",
  },
  {
    postal_code: "45410",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "11772",
    zip_name: "patchogue, ny",
  },
  {
    postal_code: "61874",
    zip_name: "savoy, il",
  },
  {
    postal_code: "89451",
    zip_name: "incline village, nv",
  },
  {
    postal_code: "53532",
    zip_name: "deforest, wi",
  },
  {
    postal_code: "97321",
    zip_name: "albany, or",
  },
  {
    postal_code: "50501",
    zip_name: "fort dodge, ia",
  },
  {
    postal_code: "28150",
    zip_name: "shelby, nc",
  },
  {
    postal_code: "93906",
    zip_name: "salinas, ca",
  },
  {
    postal_code: "91361",
    zip_name: "westlake village, ca",
  },
  {
    postal_code: "11210",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "84115",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "62298",
    zip_name: "waterloo, il",
  },
  {
    postal_code: "31316",
    zip_name: "ludowici, ga",
  },
  {
    postal_code: "19734",
    zip_name: "townsend, de",
  },
  {
    postal_code: "92708",
    zip_name: "fountain valley, ca",
  },
  {
    postal_code: "17901",
    zip_name: "pottsville, pa",
  },
  {
    postal_code: "65809",
    zip_name: "springfield, mo",
  },
  {
    postal_code: "44839",
    zip_name: "huron, oh",
  },
  {
    postal_code: "47404",
    zip_name: "bloomington, in",
  },
  {
    postal_code: "33167",
    zip_name: "miami, fl",
  },
  {
    postal_code: "91436",
    zip_name: "encino, ca",
  },
  {
    postal_code: "60084",
    zip_name: "wauconda, il",
  },
  {
    postal_code: "85340",
    zip_name: "litchfield park, az",
  },
  {
    postal_code: "95252",
    zip_name: "valley springs, ca",
  },
  {
    postal_code: "6482",
    zip_name: "sandy hook, ct",
  },
  {
    postal_code: "81252",
    zip_name: "westcliffe, co",
  },
  {
    postal_code: "38654",
    zip_name: "olive branch, ms",
  },
  {
    postal_code: "44406",
    zip_name: "canfield, oh",
  },
  {
    postal_code: "92586",
    zip_name: "menifee, ca",
  },
  {
    postal_code: "29301",
    zip_name: "spartanburg, sc",
  },
  {
    postal_code: "75119",
    zip_name: "ennis, tx",
  },
  {
    postal_code: "34142",
    zip_name: "immokalee, fl",
  },
  {
    postal_code: "78653",
    zip_name: "manor, tx",
  },
  {
    postal_code: "92704",
    zip_name: "santa ana, ca",
  },
  {
    postal_code: "85085",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "72730",
    zip_name: "farmington, ar",
  },
  {
    postal_code: "76114",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "42164",
    zip_name: "scottsville, ky",
  },
  {
    postal_code: "27592",
    zip_name: "willow spring, nc",
  },
  {
    postal_code: "65401",
    zip_name: "rolla, mo",
  },
  {
    postal_code: "63144",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "44138",
    zip_name: "olmsted falls, oh",
  },
  {
    postal_code: "77568",
    zip_name: "la marque, tx",
  },
  {
    postal_code: "74075",
    zip_name: "stillwater, ok",
  },
  {
    postal_code: "94538",
    zip_name: "fremont, ca",
  },
  {
    postal_code: "35235",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "20020",
    zip_name: "washington, dc",
  },
  {
    postal_code: "76247",
    zip_name: "justin, tx",
  },
  {
    postal_code: "13027",
    zip_name: "baldwinsville, ny",
  },
  {
    postal_code: "80547",
    zip_name: "timnath, co",
  },
  {
    postal_code: "60548",
    zip_name: "sandwich, il",
  },
  {
    postal_code: "38118",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "90731",
    zip_name: "san pedro, ca",
  },
  {
    postal_code: "6850",
    zip_name: "norwalk, ct",
  },
  {
    postal_code: "60515",
    zip_name: "downers grove, il",
  },
  {
    postal_code: "38133",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "12801",
    zip_name: "glens falls, ny",
  },
  {
    postal_code: "75205",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "75762",
    zip_name: "flint, tx",
  },
  {
    postal_code: "14218",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "85202",
    zip_name: "mesa, az",
  },
  {
    postal_code: "48912",
    zip_name: "lansing, mi",
  },
  {
    postal_code: "20155",
    zip_name: "gainesville, va",
  },
  {
    postal_code: "33760",
    zip_name: "clearwater, fl",
  },
  {
    postal_code: "22508",
    zip_name: "locust grove, va",
  },
  {
    postal_code: "40353",
    zip_name: "mount sterling, ky",
  },
  {
    postal_code: "98006",
    zip_name: "bellevue, wa",
  },
  {
    postal_code: "33187",
    zip_name: "miami, fl",
  },
  {
    postal_code: "33149",
    zip_name: "key biscayne, fl",
  },
  {
    postal_code: "90220",
    zip_name: "compton, ca",
  },
  {
    postal_code: "28479",
    zip_name: "winnabow, nc",
  },
  {
    postal_code: "60612",
    zip_name: "chicago, il",
  },
  {
    postal_code: "27870",
    zip_name: "roanoke rapids, nc",
  },
  {
    postal_code: "78702",
    zip_name: "austin, tx",
  },
  {
    postal_code: "89703",
    zip_name: "carson city, nv",
  },
  {
    postal_code: "85335",
    zip_name: "el mirage, az",
  },
  {
    postal_code: "90045",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "76084",
    zip_name: "venus, tx",
  },
  {
    postal_code: "55432",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "6405",
    zip_name: "branford, ct",
  },
  {
    postal_code: "22025",
    zip_name: "dumfries, va",
  },
  {
    postal_code: "77035",
    zip_name: "houston, tx",
  },
  {
    postal_code: "71913",
    zip_name: "hot springs national park, ar",
  },
  {
    postal_code: "32736",
    zip_name: "eustis, fl",
  },
  {
    postal_code: "43119",
    zip_name: "galloway, oh",
  },
  {
    postal_code: "40701",
    zip_name: "corbin, ky",
  },
  {
    postal_code: "70460",
    zip_name: "slidell, la",
  },
  {
    postal_code: "33185",
    zip_name: "miami, fl",
  },
  {
    postal_code: "53916",
    zip_name: "beaver dam, wi",
  },
  {
    postal_code: "40361",
    zip_name: "paris, ky",
  },
  {
    postal_code: "77020",
    zip_name: "houston, tx",
  },
  {
    postal_code: "45414",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "90274",
    zip_name: "palos verdes peninsula, ca",
  },
  {
    postal_code: "80010",
    zip_name: "aurora, co",
  },
  {
    postal_code: "29102",
    zip_name: "manning, sc",
  },
  {
    postal_code: "44023",
    zip_name: "chagrin falls, oh",
  },
  {
    postal_code: "91739",
    zip_name: "rancho cucamonga, ca",
  },
  {
    postal_code: "19147",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "76109",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "8060",
    zip_name: "mount holly, nj",
  },
  {
    postal_code: "30308",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "91737",
    zip_name: "rancho cucamonga, ca",
  },
  {
    postal_code: "60565",
    zip_name: "naperville, il",
  },
  {
    postal_code: "33523",
    zip_name: "dade city, fl",
  },
  {
    postal_code: "44278",
    zip_name: "tallmadge, oh",
  },
  {
    postal_code: "84058",
    zip_name: "orem, ut",
  },
  {
    postal_code: "93023",
    zip_name: "ojai, ca",
  },
  {
    postal_code: "44056",
    zip_name: "macedonia, oh",
  },
  {
    postal_code: "93001",
    zip_name: "ventura, ca",
  },
  {
    postal_code: "44024",
    zip_name: "chardon, oh",
  },
  {
    postal_code: "48864",
    zip_name: "okemos, mi",
  },
  {
    postal_code: "27896",
    zip_name: "wilson, nc",
  },
  {
    postal_code: "55387",
    zip_name: "waconia, mn",
  },
  {
    postal_code: "29627",
    zip_name: "belton, sc",
  },
  {
    postal_code: "48108",
    zip_name: "ann arbor, mi",
  },
  {
    postal_code: "28751",
    zip_name: "maggie valley, nc",
  },
  {
    postal_code: "91381",
    zip_name: "stevenson ranch, ca",
  },
  {
    postal_code: "23009",
    zip_name: "aylett, va",
  },
  {
    postal_code: "61801",
    zip_name: "urbana, il",
  },
  {
    postal_code: "31406",
    zip_name: "savannah, ga",
  },
  {
    postal_code: "46163",
    zip_name: "new palestine, in",
  },
  {
    postal_code: "25425",
    zip_name: "harpers ferry, wv",
  },
  {
    postal_code: "85715",
    zip_name: "tucson, az",
  },
  {
    postal_code: "76013",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "93021",
    zip_name: "moorpark, ca",
  },
  {
    postal_code: "48911",
    zip_name: "lansing, mi",
  },
  {
    postal_code: "50226",
    zip_name: "polk city, ia",
  },
  {
    postal_code: "76117",
    zip_name: "haltom city, tx",
  },
  {
    postal_code: "99019",
    zip_name: "liberty lake, wa",
  },
  {
    postal_code: "91764",
    zip_name: "ontario, ca",
  },
  {
    postal_code: "91307",
    zip_name: "west hills, ca",
  },
  {
    postal_code: "44306",
    zip_name: "akron, oh",
  },
  {
    postal_code: "25177",
    zip_name: "saint albans, wv",
  },
  {
    postal_code: "93611",
    zip_name: "clovis, ca",
  },
  {
    postal_code: "35243",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "24523",
    zip_name: "bedford, va",
  },
  {
    postal_code: "63390",
    zip_name: "wright city, mo",
  },
  {
    postal_code: "48915",
    zip_name: "lansing, mi",
  },
  {
    postal_code: "76134",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "27704",
    zip_name: "durham, nc",
  },
  {
    postal_code: "90064",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "43725",
    zip_name: "cambridge, oh",
  },
  {
    postal_code: "90638",
    zip_name: "la mirada, ca",
  },
  {
    postal_code: "92835",
    zip_name: "fullerton, ca",
  },
  {
    postal_code: "61081",
    zip_name: "sterling, il",
  },
  {
    postal_code: "6032",
    zip_name: "farmington, ct",
  },
  {
    postal_code: "28504",
    zip_name: "kinston, nc",
  },
  {
    postal_code: "43616",
    zip_name: "oregon, oh",
  },
  {
    postal_code: "80924",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "43211",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "11222",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "60505",
    zip_name: "aurora, il",
  },
  {
    postal_code: "91702",
    zip_name: "azusa, ca",
  },
  {
    postal_code: "61611",
    zip_name: "east peoria, il",
  },
  {
    postal_code: "10026",
    zip_name: "new york, ny",
  },
  {
    postal_code: "77065",
    zip_name: "houston, tx",
  },
  {
    postal_code: "84601",
    zip_name: "provo, ut",
  },
  {
    postal_code: "40504",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "77406",
    zip_name: "richmond, tx",
  },
  {
    postal_code: "72713",
    zip_name: "bentonville, ar",
  },
  {
    postal_code: "27587",
    zip_name: "wake forest, nc",
  },
  {
    postal_code: "70070",
    zip_name: "luling, la",
  },
  {
    postal_code: "11207",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "91744",
    zip_name: "la puente, ca",
  },
  {
    postal_code: "92544",
    zip_name: "hemet, ca",
  },
  {
    postal_code: "60644",
    zip_name: "chicago, il",
  },
  {
    postal_code: "44512",
    zip_name: "youngstown, oh",
  },
  {
    postal_code: "72450",
    zip_name: "paragould, ar",
  },
  {
    postal_code: "62010",
    zip_name: "bethalto, il",
  },
  {
    postal_code: "79601",
    zip_name: "abilene, tx",
  },
  {
    postal_code: "30268",
    zip_name: "palmetto, ga",
  },
  {
    postal_code: "38004",
    zip_name: "atoka, tn",
  },
  {
    postal_code: "77045",
    zip_name: "houston, tx",
  },
  {
    postal_code: "6451",
    zip_name: "meriden, ct",
  },
  {
    postal_code: "77868",
    zip_name: "navasota, tx",
  },
  {
    postal_code: "37354",
    zip_name: "madisonville, tn",
  },
  {
    postal_code: "4901",
    zip_name: "waterville, me",
  },
  {
    postal_code: "39208",
    zip_name: "pearl, ms",
  },
  {
    postal_code: "80241",
    zip_name: "thornton, co",
  },
  {
    postal_code: "77380",
    zip_name: "spring, tx",
  },
  {
    postal_code: "33125",
    zip_name: "miami, fl",
  },
  {
    postal_code: "15044",
    zip_name: "gibsonia, pa",
  },
  {
    postal_code: "32750",
    zip_name: "longwood, fl",
  },
  {
    postal_code: "95301",
    zip_name: "atwater, ca",
  },
  {
    postal_code: "27215",
    zip_name: "burlington, nc",
  },
  {
    postal_code: "15120",
    zip_name: "homestead, pa",
  },
  {
    postal_code: "44011",
    zip_name: "avon, oh",
  },
  {
    postal_code: "27539",
    zip_name: "apex, nc",
  },
  {
    postal_code: "28658",
    zip_name: "newton, nc",
  },
  {
    postal_code: "19002",
    zip_name: "ambler, pa",
  },
  {
    postal_code: "38301",
    zip_name: "jackson, tn",
  },
  {
    postal_code: "92683",
    zip_name: "westminster, ca",
  },
  {
    postal_code: "37763",
    zip_name: "kingston, tn",
  },
  {
    postal_code: "20747",
    zip_name: "district heights, md",
  },
  {
    postal_code: "3054",
    zip_name: "merrimack, nh",
  },
  {
    postal_code: "15122",
    zip_name: "west mifflin, pa",
  },
  {
    postal_code: "45320",
    zip_name: "eaton, oh",
  },
  {
    postal_code: "77099",
    zip_name: "houston, tx",
  },
  {
    postal_code: "78734",
    zip_name: "austin, tx",
  },
  {
    postal_code: "41094",
    zip_name: "walton, ky",
  },
  {
    postal_code: "45226",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "76040",
    zip_name: "euless, tx",
  },
  {
    postal_code: "79110",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "11520",
    zip_name: "freeport, ny",
  },
  {
    postal_code: "37214",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "28394",
    zip_name: "vass, nc",
  },
  {
    postal_code: "84088",
    zip_name: "west jordan, ut",
  },
  {
    postal_code: "32726",
    zip_name: "eustis, fl",
  },
  {
    postal_code: "98144",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "45177",
    zip_name: "wilmington, oh",
  },
  {
    postal_code: "22314",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "98532",
    zip_name: "chehalis, wa",
  },
  {
    postal_code: "3062",
    zip_name: "nashua, nh",
  },
  {
    postal_code: "45419",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "60448",
    zip_name: "mokena, il",
  },
  {
    postal_code: "60471",
    zip_name: "richton park, il",
  },
  {
    postal_code: "78552",
    zip_name: "harlingen, tx",
  },
  {
    postal_code: "20111",
    zip_name: "manassas, va",
  },
  {
    postal_code: "98903",
    zip_name: "yakima, wa",
  },
  {
    postal_code: "60609",
    zip_name: "chicago, il",
  },
  {
    postal_code: "38058",
    zip_name: "munford, tn",
  },
  {
    postal_code: "92626",
    zip_name: "costa mesa, ca",
  },
  {
    postal_code: "23093",
    zip_name: "louisa, va",
  },
  {
    postal_code: "22031",
    zip_name: "fairfax, va",
  },
  {
    postal_code: "11951",
    zip_name: "mastic beach, ny",
  },
  {
    postal_code: "43214",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "55436",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "37148",
    zip_name: "portland, tn",
  },
  {
    postal_code: "54301",
    zip_name: "green bay, wi",
  },
  {
    postal_code: "2878",
    zip_name: "tiverton, ri",
  },
  {
    postal_code: "14830",
    zip_name: "corning, ny",
  },
  {
    postal_code: "40205",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "77551",
    zip_name: "galveston, tx",
  },
  {
    postal_code: "89512",
    zip_name: "reno, nv",
  },
  {
    postal_code: "80446",
    zip_name: "granby, co",
  },
  {
    postal_code: "77372",
    zip_name: "splendora, tx",
  },
  {
    postal_code: "63501",
    zip_name: "kirksville, mo",
  },
  {
    postal_code: "43206",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "81501",
    zip_name: "grand junction, co",
  },
  {
    postal_code: "44460",
    zip_name: "salem, oh",
  },
  {
    postal_code: "98801",
    zip_name: "wenatchee, wa",
  },
  {
    postal_code: "13903",
    zip_name: "binghamton, ny",
  },
  {
    postal_code: "94587",
    zip_name: "union city, ca",
  },
  {
    postal_code: "89502",
    zip_name: "reno, nv",
  },
  {
    postal_code: "60436",
    zip_name: "joliet, il",
  },
  {
    postal_code: "80920",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "47240",
    zip_name: "greensburg, in",
  },
  {
    postal_code: "44001",
    zip_name: "amherst, oh",
  },
  {
    postal_code: "70126",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "90004",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "34217",
    zip_name: "bradenton beach, fl",
  },
  {
    postal_code: "75401",
    zip_name: "greenville, tx",
  },
  {
    postal_code: "37601",
    zip_name: "johnson city, tn",
  },
  {
    postal_code: "50035",
    zip_name: "bondurant, ia",
  },
  {
    postal_code: "98370",
    zip_name: "poulsbo, wa",
  },
  {
    postal_code: "29420",
    zip_name: "north charleston, sc",
  },
  {
    postal_code: "75006",
    zip_name: "carrollton, tx",
  },
  {
    postal_code: "32920",
    zip_name: "cape canaveral, fl",
  },
  {
    postal_code: "29406",
    zip_name: "charleston, sc",
  },
  {
    postal_code: "29388",
    zip_name: "woodruff, sc",
  },
  {
    postal_code: "2360",
    zip_name: "plymouth, ma",
  },
  {
    postal_code: "28732",
    zip_name: "fletcher, nc",
  },
  {
    postal_code: "29401",
    zip_name: "charleston, sc",
  },
  {
    postal_code: "94560",
    zip_name: "newark, ca",
  },
  {
    postal_code: "12077",
    zip_name: "glenmont, ny",
  },
  {
    postal_code: "37055",
    zip_name: "dickson, tn",
  },
  {
    postal_code: "93035",
    zip_name: "oxnard, ca",
  },
  {
    postal_code: "84103",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "94928",
    zip_name: "rohnert park, ca",
  },
  {
    postal_code: "80620",
    zip_name: "evans, co",
  },
  {
    postal_code: "13790",
    zip_name: "johnson city, ny",
  },
  {
    postal_code: "91384",
    zip_name: "castaic, ca",
  },
  {
    postal_code: "75402",
    zip_name: "greenville, tx",
  },
  {
    postal_code: "60481",
    zip_name: "wilmington, il",
  },
  {
    postal_code: "74801",
    zip_name: "shawnee, ok",
  },
  {
    postal_code: "91401",
    zip_name: "van nuys, ca",
  },
  {
    postal_code: "92673",
    zip_name: "san clemente, ca",
  },
  {
    postal_code: "92129",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "27262",
    zip_name: "high point, nc",
  },
  {
    postal_code: "41076",
    zip_name: "newport, ky",
  },
  {
    postal_code: "84106",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "46040",
    zip_name: "fortville, in",
  },
  {
    postal_code: "20850",
    zip_name: "rockville, md",
  },
  {
    postal_code: "48842",
    zip_name: "holt, mi",
  },
  {
    postal_code: "95695",
    zip_name: "woodland, ca",
  },
  {
    postal_code: "38116",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "72753",
    zip_name: "prairie grove, ar",
  },
  {
    postal_code: "39525",
    zip_name: "diamondhead, ms",
  },
  {
    postal_code: "32766",
    zip_name: "oviedo, fl",
  },
  {
    postal_code: "70001",
    zip_name: "metairie, la",
  },
  {
    postal_code: "55033",
    zip_name: "hastings, mn",
  },
  {
    postal_code: "11550",
    zip_name: "hempstead, ny",
  },
  {
    postal_code: "15137",
    zip_name: "north versailles, pa",
  },
  {
    postal_code: "45429",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "52241",
    zip_name: "coralville, ia",
  },
  {
    postal_code: "31064",
    zip_name: "monticello, ga",
  },
  {
    postal_code: "61103",
    zip_name: "rockford, il",
  },
  {
    postal_code: "77340",
    zip_name: "huntsville, tx",
  },
  {
    postal_code: "93711",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "85044",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "92557",
    zip_name: "moreno valley, ca",
  },
  {
    postal_code: "22485",
    zip_name: "king george, va",
  },
  {
    postal_code: "83815",
    zip_name: "coeur d alene, id",
  },
  {
    postal_code: "85210",
    zip_name: "mesa, az",
  },
  {
    postal_code: "93221",
    zip_name: "exeter, ca",
  },
  {
    postal_code: "92555",
    zip_name: "moreno valley, ca",
  },
  {
    postal_code: "12054",
    zip_name: "delmar, ny",
  },
  {
    postal_code: "34613",
    zip_name: "brooksville, fl",
  },
  {
    postal_code: "67502",
    zip_name: "hutchinson, ks",
  },
  {
    postal_code: "80910",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "60655",
    zip_name: "chicago, il",
  },
  {
    postal_code: "45240",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "28805",
    zip_name: "asheville, nc",
  },
  {
    postal_code: "24592",
    zip_name: "south boston, va",
  },
  {
    postal_code: "21740",
    zip_name: "hagerstown, md",
  },
  {
    postal_code: "33774",
    zip_name: "largo, fl",
  },
  {
    postal_code: "70058",
    zip_name: "harvey, la",
  },
  {
    postal_code: "70710",
    zip_name: "addis, la",
  },
  {
    postal_code: "95603",
    zip_name: "auburn, ca",
  },
  {
    postal_code: "36109",
    zip_name: "montgomery, al",
  },
  {
    postal_code: "79938",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "68901",
    zip_name: "hastings, ne",
  },
  {
    postal_code: "95348",
    zip_name: "merced, ca",
  },
  {
    postal_code: "6810",
    zip_name: "danbury, ct",
  },
  {
    postal_code: "19335",
    zip_name: "downingtown, pa",
  },
  {
    postal_code: "33872",
    zip_name: "sebring, fl",
  },
  {
    postal_code: "61705",
    zip_name: "bloomington, il",
  },
  {
    postal_code: "95382",
    zip_name: "turlock, ca",
  },
  {
    postal_code: "27596",
    zip_name: "youngsville, nc",
  },
  {
    postal_code: "55060",
    zip_name: "owatonna, mn",
  },
  {
    postal_code: "48035",
    zip_name: "clinton township, mi",
  },
  {
    postal_code: "93552",
    zip_name: "palmdale, ca",
  },
  {
    postal_code: "55429",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "92612",
    zip_name: "irvine, ca",
  },
  {
    postal_code: "23150",
    zip_name: "sandston, va",
  },
  {
    postal_code: "95835",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "33966",
    zip_name: "fort myers, fl",
  },
  {
    postal_code: "98498",
    zip_name: "lakewood, wa",
  },
  {
    postal_code: "80906",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "95828",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "21230",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "33042",
    zip_name: "summerland key, fl",
  },
  {
    postal_code: "97030",
    zip_name: "gresham, or",
  },
  {
    postal_code: "8046",
    zip_name: "willingboro, nj",
  },
  {
    postal_code: "92376",
    zip_name: "rialto, ca",
  },
  {
    postal_code: "33809",
    zip_name: "lakeland, fl",
  },
  {
    postal_code: "15010",
    zip_name: "beaver falls, pa",
  },
  {
    postal_code: "80922",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "70117",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "61571",
    zip_name: "washington, il",
  },
  {
    postal_code: "16803",
    zip_name: "state college, pa",
  },
  {
    postal_code: "91352",
    zip_name: "sun valley, ca",
  },
  {
    postal_code: "84047",
    zip_name: "midvale, ut",
  },
  {
    postal_code: "90046",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "27577",
    zip_name: "smithfield, nc",
  },
  {
    postal_code: "85224",
    zip_name: "chandler, az",
  },
  {
    postal_code: "34788",
    zip_name: "leesburg, fl",
  },
  {
    postal_code: "68154",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "33606",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "60487",
    zip_name: "tinley park, il",
  },
  {
    postal_code: "98208",
    zip_name: "everett, wa",
  },
  {
    postal_code: "60659",
    zip_name: "chicago, il",
  },
  {
    postal_code: "91321",
    zip_name: "newhall, ca",
  },
  {
    postal_code: "1230",
    zip_name: "great barrington, ma",
  },
  {
    postal_code: "91730",
    zip_name: "rancho cucamonga, ca",
  },
  {
    postal_code: "35055",
    zip_name: "cullman, al",
  },
  {
    postal_code: "25526",
    zip_name: "hurricane, wv",
  },
  {
    postal_code: "60559",
    zip_name: "westmont, il",
  },
  {
    postal_code: "33559",
    zip_name: "lutz, fl",
  },
  {
    postal_code: "21228",
    zip_name: "catonsville, md",
  },
  {
    postal_code: "95403",
    zip_name: "santa rosa, ca",
  },
  {
    postal_code: "32934",
    zip_name: "melbourne, fl",
  },
  {
    postal_code: "33305",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "38555",
    zip_name: "crossville, tn",
  },
  {
    postal_code: "92405",
    zip_name: "san bernardino, ca",
  },
  {
    postal_code: "60175",
    zip_name: "saint charles, il",
  },
  {
    postal_code: "47203",
    zip_name: "columbus, in",
  },
  {
    postal_code: "37216",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "55410",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "99004",
    zip_name: "cheney, wa",
  },
  {
    postal_code: "63383",
    zip_name: "warrenton, mo",
  },
  {
    postal_code: "92679",
    zip_name: "trabuco canyon, ca",
  },
  {
    postal_code: "27516",
    zip_name: "chapel hill, nc",
  },
  {
    postal_code: "65079",
    zip_name: "sunrise beach, mo",
  },
  {
    postal_code: "75209",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "60429",
    zip_name: "hazel crest, il",
  },
  {
    postal_code: "56258",
    zip_name: "marshall, mn",
  },
  {
    postal_code: "76053",
    zip_name: "hurst, tx",
  },
  {
    postal_code: "92629",
    zip_name: "dana point, ca",
  },
  {
    postal_code: "60950",
    zip_name: "manteno, il",
  },
  {
    postal_code: "59911",
    zip_name: "bigfork, mt",
  },
  {
    postal_code: "20112",
    zip_name: "manassas, va",
  },
  {
    postal_code: "60423",
    zip_name: "frankfort, il",
  },
  {
    postal_code: "50316",
    zip_name: "des moines, ia",
  },
  {
    postal_code: "32809",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "33903",
    zip_name: "north fort myers, fl",
  },
  {
    postal_code: "48105",
    zip_name: "ann arbor, mi",
  },
  {
    postal_code: "55427",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "70056",
    zip_name: "gretna, la",
  },
  {
    postal_code: "77536",
    zip_name: "deer park, tx",
  },
  {
    postal_code: "32177",
    zip_name: "palatka, fl",
  },
  {
    postal_code: "80701",
    zip_name: "fort morgan, co",
  },
  {
    postal_code: "27959",
    zip_name: "nags head, nc",
  },
  {
    postal_code: "74115",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "85139",
    zip_name: "maricopa, az",
  },
  {
    postal_code: "33605",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "95682",
    zip_name: "shingle springs, ca",
  },
  {
    postal_code: "60445",
    zip_name: "midlothian, il",
  },
  {
    postal_code: "43213",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "2563",
    zip_name: "sandwich, ma",
  },
  {
    postal_code: "85050",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "80503",
    zip_name: "longmont, co",
  },
  {
    postal_code: "10312",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "6824",
    zip_name: "fairfield, ct",
  },
  {
    postal_code: "11758",
    zip_name: "massapequa, ny",
  },
  {
    postal_code: "10027",
    zip_name: "new york, ny",
  },
  {
    postal_code: "28741",
    zip_name: "highlands, nc",
  },
  {
    postal_code: "6473",
    zip_name: "north haven, ct",
  },
  {
    postal_code: "85206",
    zip_name: "mesa, az",
  },
  {
    postal_code: "60005",
    zip_name: "arlington heights, il",
  },
  {
    postal_code: "34233",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "44319",
    zip_name: "akron, oh",
  },
  {
    postal_code: "98290",
    zip_name: "snohomish, wa",
  },
  {
    postal_code: "33034",
    zip_name: "homestead, fl",
  },
  {
    postal_code: "94533",
    zip_name: "fairfield, ca",
  },
  {
    postal_code: "77590",
    zip_name: "texas city, tx",
  },
  {
    postal_code: "77071",
    zip_name: "houston, tx",
  },
  {
    postal_code: "76078",
    zip_name: "rhome, tx",
  },
  {
    postal_code: "16510",
    zip_name: "erie, pa",
  },
  {
    postal_code: "30103",
    zip_name: "adairsville, ga",
  },
  {
    postal_code: "28301",
    zip_name: "fayetteville, nc",
  },
  {
    postal_code: "29579",
    zip_name: "myrtle beach, sc",
  },
  {
    postal_code: "33914",
    zip_name: "cape coral, fl",
  },
  {
    postal_code: "91701",
    zip_name: "rancho cucamonga, ca",
  },
  {
    postal_code: "84029",
    zip_name: "grantsville, ut",
  },
  {
    postal_code: "27302",
    zip_name: "mebane, nc",
  },
  {
    postal_code: "40160",
    zip_name: "radcliff, ky",
  },
  {
    postal_code: "90815",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "61115",
    zip_name: "machesney park, il",
  },
  {
    postal_code: "28312",
    zip_name: "fayetteville, nc",
  },
  {
    postal_code: "2906",
    zip_name: "providence, ri",
  },
  {
    postal_code: "92879",
    zip_name: "corona, ca",
  },
  {
    postal_code: "6477",
    zip_name: "orange, ct",
  },
  {
    postal_code: "20854",
    zip_name: "potomac, md",
  },
  {
    postal_code: "77423",
    zip_name: "brookshire, tx",
  },
  {
    postal_code: "33301",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "13601",
    zip_name: "watertown, ny",
  },
  {
    postal_code: "2920",
    zip_name: "cranston, ri",
  },
  {
    postal_code: "61073",
    zip_name: "roscoe, il",
  },
  {
    postal_code: "78703",
    zip_name: "austin, tx",
  },
  {
    postal_code: "33010",
    zip_name: "hialeah, fl",
  },
  {
    postal_code: "60068",
    zip_name: "park ridge, il",
  },
  {
    postal_code: "32258",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "6708",
    zip_name: "waterbury, ct",
  },
  {
    postal_code: "28144",
    zip_name: "salisbury, nc",
  },
  {
    postal_code: "28625",
    zip_name: "statesville, nc",
  },
  {
    postal_code: "78023",
    zip_name: "helotes, tx",
  },
  {
    postal_code: "72019",
    zip_name: "benton, ar",
  },
  {
    postal_code: "33709",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "60181",
    zip_name: "villa park, il",
  },
  {
    postal_code: "45449",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "30296",
    zip_name: "riverdale, ga",
  },
  {
    postal_code: "86323",
    zip_name: "chino valley, az",
  },
  {
    postal_code: "20171",
    zip_name: "herndon, va",
  },
  {
    postal_code: "43004",
    zip_name: "blacklick, oh",
  },
  {
    postal_code: "15644",
    zip_name: "jeannette, pa",
  },
  {
    postal_code: "90275",
    zip_name: "rancho palos verdes, ca",
  },
  {
    postal_code: "62864",
    zip_name: "mount vernon, il",
  },
  {
    postal_code: "32821",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "91390",
    zip_name: "santa clarita, ca",
  },
  {
    postal_code: "27545",
    zip_name: "knightdale, nc",
  },
  {
    postal_code: "23117",
    zip_name: "mineral, va",
  },
  {
    postal_code: "6385",
    zip_name: "waterford, ct",
  },
  {
    postal_code: "34996",
    zip_name: "stuart, fl",
  },
  {
    postal_code: "71909",
    zip_name: "hot springs village, ar",
  },
  {
    postal_code: "62208",
    zip_name: "fairview heights, il",
  },
  {
    postal_code: "40503",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "44022",
    zip_name: "chagrin falls, oh",
  },
  {
    postal_code: "32951",
    zip_name: "melbourne beach, fl",
  },
  {
    postal_code: "37130",
    zip_name: "murfreesboro, tn",
  },
  {
    postal_code: "76710",
    zip_name: "waco, tx",
  },
  {
    postal_code: "39212",
    zip_name: "jackson, ms",
  },
  {
    postal_code: "91325",
    zip_name: "northridge, ca",
  },
  {
    postal_code: "61108",
    zip_name: "rockford, il",
  },
  {
    postal_code: "55434",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "45365",
    zip_name: "sidney, oh",
  },
  {
    postal_code: "94534",
    zip_name: "fairfield, ca",
  },
  {
    postal_code: "63111",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "75020",
    zip_name: "denison, tx",
  },
  {
    postal_code: "95354",
    zip_name: "modesto, ca",
  },
  {
    postal_code: "77096",
    zip_name: "houston, tx",
  },
  {
    postal_code: "76234",
    zip_name: "decatur, tx",
  },
  {
    postal_code: "30311",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "32219",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "66030",
    zip_name: "gardner, ks",
  },
  {
    postal_code: "92324",
    zip_name: "colton, ca",
  },
  {
    postal_code: "40071",
    zip_name: "taylorsville, ky",
  },
  {
    postal_code: "60020",
    zip_name: "fox lake, il",
  },
  {
    postal_code: "75287",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "43952",
    zip_name: "steubenville, oh",
  },
  {
    postal_code: "29708",
    zip_name: "fort mill, sc",
  },
  {
    postal_code: "95949",
    zip_name: "grass valley, ca",
  },
  {
    postal_code: "60130",
    zip_name: "forest park, il",
  },
  {
    postal_code: "95945",
    zip_name: "grass valley, ca",
  },
  {
    postal_code: "92701",
    zip_name: "santa ana, ca",
  },
  {
    postal_code: "33953",
    zip_name: "port charlotte, fl",
  },
  {
    postal_code: "55127",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "20003",
    zip_name: "washington, dc",
  },
  {
    postal_code: "98118",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "33150",
    zip_name: "miami, fl",
  },
  {
    postal_code: "56379",
    zip_name: "sauk rapids, mn",
  },
  {
    postal_code: "8081",
    zip_name: "sicklerville, nj",
  },
  {
    postal_code: "20904",
    zip_name: "silver spring, md",
  },
  {
    postal_code: "33477",
    zip_name: "jupiter, fl",
  },
  {
    postal_code: "78620",
    zip_name: "dripping springs, tx",
  },
  {
    postal_code: "75065",
    zip_name: "lake dallas, tx",
  },
  {
    postal_code: "28785",
    zip_name: "waynesville, nc",
  },
  {
    postal_code: "32953",
    zip_name: "merritt island, fl",
  },
  {
    postal_code: "70563",
    zip_name: "new iberia, la",
  },
  {
    postal_code: "85259",
    zip_name: "scottsdale, az",
  },
  {
    postal_code: "38135",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "32136",
    zip_name: "flagler beach, fl",
  },
  {
    postal_code: "94588",
    zip_name: "pleasanton, ca",
  },
  {
    postal_code: "23024",
    zip_name: "bumpass, va",
  },
  {
    postal_code: "33014",
    zip_name: "hialeah, fl",
  },
  {
    postal_code: "63124",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "78681",
    zip_name: "round rock, tx",
  },
  {
    postal_code: "80121",
    zip_name: "littleton, co",
  },
  {
    postal_code: "88203",
    zip_name: "roswell, nm",
  },
  {
    postal_code: "39482",
    zip_name: "sumrall, ms",
  },
  {
    postal_code: "60103",
    zip_name: "bartlett, il",
  },
  {
    postal_code: "85388",
    zip_name: "surprise, az",
  },
  {
    postal_code: "63108",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "37912",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "60450",
    zip_name: "morris, il",
  },
  {
    postal_code: "11040",
    zip_name: "new hyde park, ny",
  },
  {
    postal_code: "85033",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "7860",
    zip_name: "newton, nj",
  },
  {
    postal_code: "70471",
    zip_name: "mandeville, la",
  },
  {
    postal_code: "17046",
    zip_name: "lebanon, pa",
  },
  {
    postal_code: "23227",
    zip_name: "richmond, va",
  },
  {
    postal_code: "15241",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "46077",
    zip_name: "zionsville, in",
  },
  {
    postal_code: "91311",
    zip_name: "chatsworth, ca",
  },
  {
    postal_code: "76135",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "37914",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "20785",
    zip_name: "hyattsville, md",
  },
  {
    postal_code: "75022",
    zip_name: "flower mound, tx",
  },
  {
    postal_code: "78132",
    zip_name: "new braunfels, tx",
  },
  {
    postal_code: "50036",
    zip_name: "boone, ia",
  },
  {
    postal_code: "55008",
    zip_name: "cambridge, mn",
  },
  {
    postal_code: "76148",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "39042",
    zip_name: "brandon, ms",
  },
  {
    postal_code: "64030",
    zip_name: "grandview, mo",
  },
  {
    postal_code: "63755",
    zip_name: "jackson, mo",
  },
  {
    postal_code: "94109",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "26062",
    zip_name: "weirton, wv",
  },
  {
    postal_code: "27455",
    zip_name: "greensboro, nc",
  },
  {
    postal_code: "77049",
    zip_name: "houston, tx",
  },
  {
    postal_code: "19348",
    zip_name: "kennett square, pa",
  },
  {
    postal_code: "32803",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "33170",
    zip_name: "miami, fl",
  },
  {
    postal_code: "60452",
    zip_name: "oak forest, il",
  },
  {
    postal_code: "92553",
    zip_name: "moreno valley, ca",
  },
  {
    postal_code: "33498",
    zip_name: "boca raton, fl",
  },
  {
    postal_code: "91767",
    zip_name: "pomona, ca",
  },
  {
    postal_code: "85281",
    zip_name: "tempe, az",
  },
  {
    postal_code: "70124",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "34450",
    zip_name: "inverness, fl",
  },
  {
    postal_code: "33486",
    zip_name: "boca raton, fl",
  },
  {
    postal_code: "64082",
    zip_name: "lees summit, mo",
  },
  {
    postal_code: "84123",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "61853",
    zip_name: "mahomet, il",
  },
  {
    postal_code: "13088",
    zip_name: "liverpool, ny",
  },
  {
    postal_code: "92807",
    zip_name: "anaheim, ca",
  },
  {
    postal_code: "95209",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "29611",
    zip_name: "greenville, sc",
  },
  {
    postal_code: "90042",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "39465",
    zip_name: "petal, ms",
  },
  {
    postal_code: "8520",
    zip_name: "hightstown, nj",
  },
  {
    postal_code: "46142",
    zip_name: "greenwood, in",
  },
  {
    postal_code: "27597",
    zip_name: "zebulon, nc",
  },
  {
    postal_code: "45415",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "46158",
    zip_name: "mooresville, in",
  },
  {
    postal_code: "32817",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "72718",
    zip_name: "cave springs, ar",
  },
  {
    postal_code: "60156",
    zip_name: "lake in the hills, il",
  },
  {
    postal_code: "95616",
    zip_name: "davis, ca",
  },
  {
    postal_code: "2861",
    zip_name: "pawtucket, ri",
  },
  {
    postal_code: "52806",
    zip_name: "davenport, ia",
  },
  {
    postal_code: "15101",
    zip_name: "allison park, pa",
  },
  {
    postal_code: "85253",
    zip_name: "paradise valley, az",
  },
  {
    postal_code: "80005",
    zip_name: "arvada, co",
  },
  {
    postal_code: "13021",
    zip_name: "auburn, ny",
  },
  {
    postal_code: "60189",
    zip_name: "wheaton, il",
  },
  {
    postal_code: "44709",
    zip_name: "canton, oh",
  },
  {
    postal_code: "22546",
    zip_name: "ruther glen, va",
  },
  {
    postal_code: "77090",
    zip_name: "houston, tx",
  },
  {
    postal_code: "45014",
    zip_name: "fairfield, oh",
  },
  {
    postal_code: "43235",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "87106",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "16504",
    zip_name: "erie, pa",
  },
  {
    postal_code: "35213",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "20715",
    zip_name: "bowie, md",
  },
  {
    postal_code: "20019",
    zip_name: "washington, dc",
  },
  {
    postal_code: "39532",
    zip_name: "biloxi, ms",
  },
  {
    postal_code: "30317",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "60194",
    zip_name: "schaumburg, il",
  },
  {
    postal_code: "58854",
    zip_name: "watford city, nd",
  },
  {
    postal_code: "78213",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "90068",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "97303",
    zip_name: "salem, or",
  },
  {
    postal_code: "43232",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "55411",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "33781",
    zip_name: "pinellas park, fl",
  },
  {
    postal_code: "54017",
    zip_name: "new richmond, wi",
  },
  {
    postal_code: "52501",
    zip_name: "ottumwa, ia",
  },
  {
    postal_code: "64114",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "95678",
    zip_name: "roseville, ca",
  },
  {
    postal_code: "63069",
    zip_name: "pacific, mo",
  },
  {
    postal_code: "78520",
    zip_name: "brownsville, tx",
  },
  {
    postal_code: "38138",
    zip_name: "germantown, tn",
  },
  {
    postal_code: "28512",
    zip_name: "atlantic beach, nc",
  },
  {
    postal_code: "60131",
    zip_name: "franklin park, il",
  },
  {
    postal_code: "76086",
    zip_name: "weatherford, tx",
  },
  {
    postal_code: "15136",
    zip_name: "mc kees rocks, pa",
  },
  {
    postal_code: "53705",
    zip_name: "madison, wi",
  },
  {
    postal_code: "6484",
    zip_name: "shelton, ct",
  },
  {
    postal_code: "15090",
    zip_name: "wexford, pa",
  },
  {
    postal_code: "44314",
    zip_name: "akron, oh",
  },
  {
    postal_code: "85713",
    zip_name: "tucson, az",
  },
  {
    postal_code: "76016",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "92707",
    zip_name: "santa ana, ca",
  },
  {
    postal_code: "78374",
    zip_name: "portland, tx",
  },
  {
    postal_code: "95204",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "45102",
    zip_name: "amelia, oh",
  },
  {
    postal_code: "72034",
    zip_name: "conway, ar",
  },
  {
    postal_code: "75227",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "55118",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "97355",
    zip_name: "lebanon, or",
  },
  {
    postal_code: "13820",
    zip_name: "oneonta, ny",
  },
  {
    postal_code: "39531",
    zip_name: "biloxi, ms",
  },
  {
    postal_code: "95991",
    zip_name: "yuba city, ca",
  },
  {
    postal_code: "61550",
    zip_name: "morton, il",
  },
  {
    postal_code: "50327",
    zip_name: "pleasant hill, ia",
  },
  {
    postal_code: "28711",
    zip_name: "black mountain, nc",
  },
  {
    postal_code: "19382",
    zip_name: "west chester, pa",
  },
  {
    postal_code: "27292",
    zip_name: "lexington, nc",
  },
  {
    postal_code: "59801",
    zip_name: "missoula, mt",
  },
  {
    postal_code: "45224",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "48162",
    zip_name: "monroe, mi",
  },
  {
    postal_code: "87501",
    zip_name: "santa fe, nm",
  },
  {
    postal_code: "58703",
    zip_name: "minot, nd",
  },
  {
    postal_code: "32820",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "60656",
    zip_name: "chicago, il",
  },
  {
    postal_code: "43606",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "52803",
    zip_name: "davenport, ia",
  },
  {
    postal_code: "12538",
    zip_name: "hyde park, ny",
  },
  {
    postal_code: "44126",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "8070",
    zip_name: "pennsville, nj",
  },
  {
    postal_code: "30349",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "78109",
    zip_name: "converse, tx",
  },
  {
    postal_code: "85021",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "25428",
    zip_name: "inwood, wv",
  },
  {
    postal_code: "75090",
    zip_name: "sherman, tx",
  },
  {
    postal_code: "27376",
    zip_name: "west end, nc",
  },
  {
    postal_code: "78255",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "92869",
    zip_name: "orange, ca",
  },
  {
    postal_code: "92630",
    zip_name: "lake forest, ca",
  },
  {
    postal_code: "44133",
    zip_name: "north royalton, oh",
  },
  {
    postal_code: "75156",
    zip_name: "mabank, tx",
  },
  {
    postal_code: "66216",
    zip_name: "shawnee, ks",
  },
  {
    postal_code: "21207",
    zip_name: "gwynn oak, md",
  },
  {
    postal_code: "95746",
    zip_name: "granite bay, ca",
  },
  {
    postal_code: "85027",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "29920",
    zip_name: "saint helena island, sc",
  },
  {
    postal_code: "21701",
    zip_name: "frederick, md",
  },
  {
    postal_code: "95376",
    zip_name: "tracy, ca",
  },
  {
    postal_code: "60153",
    zip_name: "maywood, il",
  },
  {
    postal_code: "18976",
    zip_name: "warrington, pa",
  },
  {
    postal_code: "85741",
    zip_name: "tucson, az",
  },
  {
    postal_code: "16335",
    zip_name: "meadville, pa",
  },
  {
    postal_code: "6606",
    zip_name: "bridgeport, ct",
  },
  {
    postal_code: "30093",
    zip_name: "norcross, ga",
  },
  {
    postal_code: "53511",
    zip_name: "beloit, wi",
  },
  {
    postal_code: "78238",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "79706",
    zip_name: "midland, tx",
  },
  {
    postal_code: "63121",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "95356",
    zip_name: "modesto, ca",
  },
  {
    postal_code: "55407",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "11218",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "48708",
    zip_name: "bay city, mi",
  },
  {
    postal_code: "20105",
    zip_name: "aldie, va",
  },
  {
    postal_code: "20001",
    zip_name: "washington, dc",
  },
  {
    postal_code: "95363",
    zip_name: "patterson, ca",
  },
  {
    postal_code: "28716",
    zip_name: "canton, nc",
  },
  {
    postal_code: "89523",
    zip_name: "reno, nv",
  },
  {
    postal_code: "28429",
    zip_name: "castle hayne, nc",
  },
  {
    postal_code: "85037",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "70570",
    zip_name: "opelousas, la",
  },
  {
    postal_code: "95037",
    zip_name: "morgan hill, ca",
  },
  {
    postal_code: "77072",
    zip_name: "houston, tx",
  },
  {
    postal_code: "37323",
    zip_name: "cleveland, tn",
  },
  {
    postal_code: "40342",
    zip_name: "lawrenceburg, ky",
  },
  {
    postal_code: "32922",
    zip_name: "cocoa, fl",
  },
  {
    postal_code: "19122",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "60428",
    zip_name: "markham, il",
  },
  {
    postal_code: "29676",
    zip_name: "salem, sc",
  },
  {
    postal_code: "59937",
    zip_name: "whitefish, mt",
  },
  {
    postal_code: "92648",
    zip_name: "huntington beach, ca",
  },
  {
    postal_code: "84067",
    zip_name: "roy, ut",
  },
  {
    postal_code: "90065",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "61615",
    zip_name: "peoria, il",
  },
  {
    postal_code: "46052",
    zip_name: "lebanon, in",
  },
  {
    postal_code: "12477",
    zip_name: "saugerties, ny",
  },
  {
    postal_code: "28081",
    zip_name: "kannapolis, nc",
  },
  {
    postal_code: "75110",
    zip_name: "corsicana, tx",
  },
  {
    postal_code: "44136",
    zip_name: "strongsville, oh",
  },
  {
    postal_code: "91362",
    zip_name: "thousand oaks, ca",
  },
  {
    postal_code: "28083",
    zip_name: "kannapolis, nc",
  },
  {
    postal_code: "20136",
    zip_name: "bristow, va",
  },
  {
    postal_code: "62223",
    zip_name: "belleville, il",
  },
  {
    postal_code: "95219",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "33013",
    zip_name: "hialeah, fl",
  },
  {
    postal_code: "63052",
    zip_name: "imperial, mo",
  },
  {
    postal_code: "84129",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "72211",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "65672",
    zip_name: "hollister, mo",
  },
  {
    postal_code: "49093",
    zip_name: "three rivers, mi",
  },
  {
    postal_code: "11432",
    zip_name: "jamaica, ny",
  },
  {
    postal_code: "55116",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "62025",
    zip_name: "edwardsville, il",
  },
  {
    postal_code: "87506",
    zip_name: "santa fe, nm",
  },
  {
    postal_code: "92880",
    zip_name: "corona, ca",
  },
  {
    postal_code: "92008",
    zip_name: "carlsbad, ca",
  },
  {
    postal_code: "77042",
    zip_name: "houston, tx",
  },
  {
    postal_code: "43228",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "55987",
    zip_name: "winona, mn",
  },
  {
    postal_code: "55422",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "91302",
    zip_name: "calabasas, ca",
  },
  {
    postal_code: "50010",
    zip_name: "ames, ia",
  },
  {
    postal_code: "64157",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "44310",
    zip_name: "akron, oh",
  },
  {
    postal_code: "40014",
    zip_name: "crestwood, ky",
  },
  {
    postal_code: "21061",
    zip_name: "glen burnie, md",
  },
  {
    postal_code: "46064",
    zip_name: "pendleton, in",
  },
  {
    postal_code: "37604",
    zip_name: "johnson city, tn",
  },
  {
    postal_code: "77489",
    zip_name: "missouri city, tx",
  },
  {
    postal_code: "11236",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "19446",
    zip_name: "lansdale, pa",
  },
  {
    postal_code: "54911",
    zip_name: "appleton, wi",
  },
  {
    postal_code: "75025",
    zip_name: "plano, tx",
  },
  {
    postal_code: "43224",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "29617",
    zip_name: "greenville, sc",
  },
  {
    postal_code: "80501",
    zip_name: "longmont, co",
  },
  {
    postal_code: "70507",
    zip_name: "lafayette, la",
  },
  {
    postal_code: "22602",
    zip_name: "winchester, va",
  },
  {
    postal_code: "11706",
    zip_name: "bay shore, ny",
  },
  {
    postal_code: "98383",
    zip_name: "silverdale, wa",
  },
  {
    postal_code: "44313",
    zip_name: "akron, oh",
  },
  {
    postal_code: "67460",
    zip_name: "mcpherson, ks",
  },
  {
    postal_code: "80517",
    zip_name: "estes park, co",
  },
  {
    postal_code: "21703",
    zip_name: "frederick, md",
  },
  {
    postal_code: "79413",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "49685",
    zip_name: "traverse city, mi",
  },
  {
    postal_code: "16509",
    zip_name: "erie, pa",
  },
  {
    postal_code: "76801",
    zip_name: "brownwood, tx",
  },
  {
    postal_code: "95993",
    zip_name: "yuba city, ca",
  },
  {
    postal_code: "77346",
    zip_name: "humble, tx",
  },
  {
    postal_code: "85226",
    zip_name: "chandler, az",
  },
  {
    postal_code: "15206",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "65583",
    zip_name: "waynesville, mo",
  },
  {
    postal_code: "21224",
    zip_name: "baltimore, md",
  },
  {
    postal_code: "34229",
    zip_name: "osprey, fl",
  },
  {
    postal_code: "37303",
    zip_name: "athens, tn",
  },
  {
    postal_code: "45440",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "2871",
    zip_name: "portsmouth, ri",
  },
  {
    postal_code: "34994",
    zip_name: "stuart, fl",
  },
  {
    postal_code: "55720",
    zip_name: "cloquet, mn",
  },
  {
    postal_code: "90745",
    zip_name: "carson, ca",
  },
  {
    postal_code: "70131",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "63005",
    zip_name: "chesterfield, mo",
  },
  {
    postal_code: "27312",
    zip_name: "pittsboro, nc",
  },
  {
    postal_code: "76021",
    zip_name: "bedford, tx",
  },
  {
    postal_code: "21801",
    zip_name: "salisbury, md",
  },
  {
    postal_code: "91364",
    zip_name: "woodland hills, ca",
  },
  {
    postal_code: "12211",
    zip_name: "albany, ny",
  },
  {
    postal_code: "82007",
    zip_name: "cheyenne, wy",
  },
  {
    postal_code: "48917",
    zip_name: "lansing, mi",
  },
  {
    postal_code: "44301",
    zip_name: "akron, oh",
  },
  {
    postal_code: "95833",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "75081",
    zip_name: "richardson, tx",
  },
  {
    postal_code: "20011",
    zip_name: "washington, dc",
  },
  {
    postal_code: "75218",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "60077",
    zip_name: "skokie, il",
  },
  {
    postal_code: "98032",
    zip_name: "kent, wa",
  },
  {
    postal_code: "84790",
    zip_name: "saint george, ut",
  },
  {
    postal_code: "33331",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "85355",
    zip_name: "waddell, az",
  },
  {
    postal_code: "11691",
    zip_name: "far rockaway, ny",
  },
  {
    postal_code: "27519",
    zip_name: "cary, nc",
  },
  {
    postal_code: "45243",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "30307",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "80909",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "19943",
    zip_name: "felton, de",
  },
  {
    postal_code: "76018",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "40517",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "64029",
    zip_name: "grain valley, mo",
  },
  {
    postal_code: "98375",
    zip_name: "puyallup, wa",
  },
  {
    postal_code: "92587",
    zip_name: "menifee, ca",
  },
  {
    postal_code: "33145",
    zip_name: "miami, fl",
  },
  {
    postal_code: "33190",
    zip_name: "miami, fl",
  },
  {
    postal_code: "27949",
    zip_name: "kitty hawk, nc",
  },
  {
    postal_code: "77036",
    zip_name: "houston, tx",
  },
  {
    postal_code: "78230",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "30677",
    zip_name: "watkinsville, ga",
  },
  {
    postal_code: "12010",
    zip_name: "amsterdam, ny",
  },
  {
    postal_code: "92649",
    zip_name: "huntington beach, ca",
  },
  {
    postal_code: "11217",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "95829",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "31410",
    zip_name: "savannah, ga",
  },
  {
    postal_code: "91001",
    zip_name: "altadena, ca",
  },
  {
    postal_code: "43035",
    zip_name: "lewis center, oh",
  },
  {
    postal_code: "85364",
    zip_name: "yuma, az",
  },
  {
    postal_code: "75146",
    zip_name: "lancaster, tx",
  },
  {
    postal_code: "33525",
    zip_name: "dade city, fl",
  },
  {
    postal_code: "76033",
    zip_name: "cleburne, tx",
  },
  {
    postal_code: "11414",
    zip_name: "howard beach, ny",
  },
  {
    postal_code: "95223",
    zip_name: "arnold, ca",
  },
  {
    postal_code: "19073",
    zip_name: "newtown square, pa",
  },
  {
    postal_code: "55057",
    zip_name: "northfield, mn",
  },
  {
    postal_code: "8876",
    zip_name: "somerville, nj",
  },
  {
    postal_code: "91765",
    zip_name: "diamond bar, ca",
  },
  {
    postal_code: "33957",
    zip_name: "sanibel, fl",
  },
  {
    postal_code: "81401",
    zip_name: "montrose, co",
  },
  {
    postal_code: "95820",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "61614",
    zip_name: "peoria, il",
  },
  {
    postal_code: "75211",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "76140",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "79015",
    zip_name: "canyon, tx",
  },
  {
    postal_code: "60018",
    zip_name: "des plaines, il",
  },
  {
    postal_code: "60426",
    zip_name: "harvey, il",
  },
  {
    postal_code: "72116",
    zip_name: "north little rock, ar",
  },
  {
    postal_code: "46140",
    zip_name: "greenfield, in",
  },
  {
    postal_code: "91710",
    zip_name: "chino, ca",
  },
  {
    postal_code: "94107",
    zip_name: "san francisco, ca",
  },
  {
    postal_code: "80304",
    zip_name: "boulder, co",
  },
  {
    postal_code: "31313",
    zip_name: "hinesville, ga",
  },
  {
    postal_code: "91709",
    zip_name: "chino hills, ca",
  },
  {
    postal_code: "15701",
    zip_name: "indiana, pa",
  },
  {
    postal_code: "77802",
    zip_name: "bryan, tx",
  },
  {
    postal_code: "55117",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "60503",
    zip_name: "aurora, il",
  },
  {
    postal_code: "11221",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "80911",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "41005",
    zip_name: "burlington, ky",
  },
  {
    postal_code: "80921",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "95212",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "34235",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "27518",
    zip_name: "cary, nc",
  },
  {
    postal_code: "60076",
    zip_name: "skokie, il",
  },
  {
    postal_code: "43040",
    zip_name: "marysville, oh",
  },
  {
    postal_code: "84653",
    zip_name: "salem, ut",
  },
  {
    postal_code: "29302",
    zip_name: "spartanburg, sc",
  },
  {
    postal_code: "55904",
    zip_name: "rochester, mn",
  },
  {
    postal_code: "75230",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "6877",
    zip_name: "ridgefield, ct",
  },
  {
    postal_code: "74112",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "84041",
    zip_name: "layton, ut",
  },
  {
    postal_code: "77021",
    zip_name: "houston, tx",
  },
  {
    postal_code: "62702",
    zip_name: "springfield, il",
  },
  {
    postal_code: "46901",
    zip_name: "kokomo, in",
  },
  {
    postal_code: "43050",
    zip_name: "mount vernon, oh",
  },
  {
    postal_code: "44622",
    zip_name: "dover, oh",
  },
  {
    postal_code: "12524",
    zip_name: "fishkill, ny",
  },
  {
    postal_code: "46055",
    zip_name: "mccordsville, in",
  },
  {
    postal_code: "75229",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "38134",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "34224",
    zip_name: "englewood, fl",
  },
  {
    postal_code: "89012",
    zip_name: "henderson, nv",
  },
  {
    postal_code: "78521",
    zip_name: "brownsville, tx",
  },
  {
    postal_code: "92210",
    zip_name: "indian wells, ca",
  },
  {
    postal_code: "92660",
    zip_name: "newport beach, ca",
  },
  {
    postal_code: "44067",
    zip_name: "northfield, oh",
  },
  {
    postal_code: "35022",
    zip_name: "bessemer, al",
  },
  {
    postal_code: "90047",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "44123",
    zip_name: "euclid, oh",
  },
  {
    postal_code: "85653",
    zip_name: "marana, az",
  },
  {
    postal_code: "92243",
    zip_name: "el centro, ca",
  },
  {
    postal_code: "29662",
    zip_name: "mauldin, sc",
  },
  {
    postal_code: "77064",
    zip_name: "houston, tx",
  },
  {
    postal_code: "56007",
    zip_name: "albert lea, mn",
  },
  {
    postal_code: "92069",
    zip_name: "san marcos, ca",
  },
  {
    postal_code: "32233",
    zip_name: "atlantic beach, fl",
  },
  {
    postal_code: "19801",
    zip_name: "wilmington, de",
  },
  {
    postal_code: "39571",
    zip_name: "pass christian, ms",
  },
  {
    postal_code: "55376",
    zip_name: "saint michael, mn",
  },
  {
    postal_code: "21093",
    zip_name: "lutherville timonium, md",
  },
  {
    postal_code: "45331",
    zip_name: "greenville, oh",
  },
  {
    postal_code: "54016",
    zip_name: "hudson, wi",
  },
  {
    postal_code: "94804",
    zip_name: "richmond, ca",
  },
  {
    postal_code: "48433",
    zip_name: "flushing, mi",
  },
  {
    postal_code: "84084",
    zip_name: "west jordan, ut",
  },
  {
    postal_code: "23103",
    zip_name: "manakin sabot, va",
  },
  {
    postal_code: "78217",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "85122",
    zip_name: "casa grande, az",
  },
  {
    postal_code: "61525",
    zip_name: "dunlap, il",
  },
  {
    postal_code: "90802",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "59405",
    zip_name: "great falls, mt",
  },
  {
    postal_code: "20735",
    zip_name: "clinton, md",
  },
  {
    postal_code: "27502",
    zip_name: "apex, nc",
  },
  {
    postal_code: "6851",
    zip_name: "norwalk, ct",
  },
  {
    postal_code: "92545",
    zip_name: "hemet, ca",
  },
  {
    postal_code: "43209",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "6437",
    zip_name: "guilford, ct",
  },
  {
    postal_code: "33566",
    zip_name: "plant city, fl",
  },
  {
    postal_code: "53546",
    zip_name: "janesville, wi",
  },
  {
    postal_code: "45067",
    zip_name: "trenton, oh",
  },
  {
    postal_code: "92509",
    zip_name: "jurupa valley, ca",
  },
  {
    postal_code: "45133",
    zip_name: "hillsboro, oh",
  },
  {
    postal_code: "98011",
    zip_name: "bothell, wa",
  },
  {
    postal_code: "95691",
    zip_name: "west sacramento, ca",
  },
  {
    postal_code: "15239",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "93003",
    zip_name: "ventura, ca",
  },
  {
    postal_code: "53545",
    zip_name: "janesville, wi",
  },
  {
    postal_code: "15401",
    zip_name: "uniontown, pa",
  },
  {
    postal_code: "48602",
    zip_name: "saginaw, mi",
  },
  {
    postal_code: "28207",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "38122",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "55066",
    zip_name: "red wing, mn",
  },
  {
    postal_code: "50320",
    zip_name: "des moines, ia",
  },
  {
    postal_code: "92672",
    zip_name: "san clemente, ca",
  },
  {
    postal_code: "32226",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "43311",
    zip_name: "bellefontaine, oh",
  },
  {
    postal_code: "11249",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "55112",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "2840",
    zip_name: "newport, ri",
  },
  {
    postal_code: "77541",
    zip_name: "freeport, tx",
  },
  {
    postal_code: "45251",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "91605",
    zip_name: "north hollywood, ca",
  },
  {
    postal_code: "99202",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "98117",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "40228",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "60007",
    zip_name: "elk grove village, il",
  },
  {
    postal_code: "86315",
    zip_name: "prescott valley, az",
  },
  {
    postal_code: "95008",
    zip_name: "campbell, ca",
  },
  {
    postal_code: "55448",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "85029",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "77079",
    zip_name: "houston, tx",
  },
  {
    postal_code: "90620",
    zip_name: "buena park, ca",
  },
  {
    postal_code: "77545",
    zip_name: "fresno, tx",
  },
  {
    postal_code: "76401",
    zip_name: "stephenville, tx",
  },
  {
    postal_code: "85042",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "63379",
    zip_name: "troy, mo",
  },
  {
    postal_code: "85051",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "28557",
    zip_name: "morehead city, nc",
  },
  {
    postal_code: "29334",
    zip_name: "duncan, sc",
  },
  {
    postal_code: "38125",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "21090",
    zip_name: "linthicum heights, md",
  },
  {
    postal_code: "42001",
    zip_name: "paducah, ky",
  },
  {
    postal_code: "46236",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "92407",
    zip_name: "san bernardino, ca",
  },
  {
    postal_code: "28403",
    zip_name: "wilmington, nc",
  },
  {
    postal_code: "45230",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "92344",
    zip_name: "hesperia, ca",
  },
  {
    postal_code: "10306",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "53719",
    zip_name: "madison, wi",
  },
  {
    postal_code: "16801",
    zip_name: "state college, pa",
  },
  {
    postal_code: "43612",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "27407",
    zip_name: "greensboro, nc",
  },
  {
    postal_code: "41001",
    zip_name: "alexandria, ky",
  },
  {
    postal_code: "98102",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "36207",
    zip_name: "anniston, al",
  },
  {
    postal_code: "30011",
    zip_name: "auburn, ga",
  },
  {
    postal_code: "92253",
    zip_name: "la quinta, ca",
  },
  {
    postal_code: "23112",
    zip_name: "midlothian, va",
  },
  {
    postal_code: "81507",
    zip_name: "grand junction, co",
  },
  {
    postal_code: "29369",
    zip_name: "moore, sc",
  },
  {
    postal_code: "15234",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "90803",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "33541",
    zip_name: "zephyrhills, fl",
  },
  {
    postal_code: "94605",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "40215",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "33830",
    zip_name: "bartow, fl",
  },
  {
    postal_code: "45459",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "33772",
    zip_name: "seminole, fl",
  },
  {
    postal_code: "6512",
    zip_name: "east haven, ct",
  },
  {
    postal_code: "6902",
    zip_name: "stamford, ct",
  },
  {
    postal_code: "44718",
    zip_name: "canton, oh",
  },
  {
    postal_code: "98908",
    zip_name: "yakima, wa",
  },
  {
    postal_code: "91344",
    zip_name: "granada hills, ca",
  },
  {
    postal_code: "33050",
    zip_name: "marathon, fl",
  },
  {
    postal_code: "33785",
    zip_name: "indian rocks beach, fl",
  },
  {
    postal_code: "42420",
    zip_name: "henderson, ky",
  },
  {
    postal_code: "37743",
    zip_name: "greeneville, tn",
  },
  {
    postal_code: "77498",
    zip_name: "sugar land, tx",
  },
  {
    postal_code: "34237",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "76001",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "15201",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "60410",
    zip_name: "channahon, il",
  },
  {
    postal_code: "32254",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "44601",
    zip_name: "alliance, oh",
  },
  {
    postal_code: "11968",
    zip_name: "southampton, ny",
  },
  {
    postal_code: "46219",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "76002",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "45247",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "92692",
    zip_name: "mission viejo, ca",
  },
  {
    postal_code: "25705",
    zip_name: "huntington, wv",
  },
  {
    postal_code: "35216",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "55811",
    zip_name: "duluth, mn",
  },
  {
    postal_code: "78654",
    zip_name: "marble falls, tx",
  },
  {
    postal_code: "45417",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "68005",
    zip_name: "bellevue, ne",
  },
  {
    postal_code: "75019",
    zip_name: "coppell, tx",
  },
  {
    postal_code: "28120",
    zip_name: "mount holly, nc",
  },
  {
    postal_code: "92504",
    zip_name: "riverside, ca",
  },
  {
    postal_code: "92584",
    zip_name: "menifee, ca",
  },
  {
    postal_code: "35080",
    zip_name: "helena, al",
  },
  {
    postal_code: "95821",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "33825",
    zip_name: "avon park, fl",
  },
  {
    postal_code: "74135",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "77057",
    zip_name: "houston, tx",
  },
  {
    postal_code: "72761",
    zip_name: "siloam springs, ar",
  },
  {
    postal_code: "11367",
    zip_name: "flushing, ny",
  },
  {
    postal_code: "77041",
    zip_name: "houston, tx",
  },
  {
    postal_code: "46360",
    zip_name: "michigan city, in",
  },
  {
    postal_code: "64012",
    zip_name: "belton, mo",
  },
  {
    postal_code: "30106",
    zip_name: "austell, ga",
  },
  {
    postal_code: "63139",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "44223",
    zip_name: "cuyahoga falls, oh",
  },
  {
    postal_code: "23235",
    zip_name: "richmond, va",
  },
  {
    postal_code: "60015",
    zip_name: "deerfield, il",
  },
  {
    postal_code: "85345",
    zip_name: "peoria, az",
  },
  {
    postal_code: "55906",
    zip_name: "rochester, mn",
  },
  {
    postal_code: "70447",
    zip_name: "madisonville, la",
  },
  {
    postal_code: "33860",
    zip_name: "mulberry, fl",
  },
  {
    postal_code: "56601",
    zip_name: "bemidji, mn",
  },
  {
    postal_code: "98204",
    zip_name: "everett, wa",
  },
  {
    postal_code: "40241",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "75220",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "91343",
    zip_name: "north hills, ca",
  },
  {
    postal_code: "15143",
    zip_name: "sewickley, pa",
  },
  {
    postal_code: "75208",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "64068",
    zip_name: "liberty, mo",
  },
  {
    postal_code: "33165",
    zip_name: "miami, fl",
  },
  {
    postal_code: "6410",
    zip_name: "cheshire, ct",
  },
  {
    postal_code: "95826",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "91306",
    zip_name: "winnetka, ca",
  },
  {
    postal_code: "15205",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "30092",
    zip_name: "peachtree corners, ga",
  },
  {
    postal_code: "75013",
    zip_name: "allen, tx",
  },
  {
    postal_code: "24441",
    zip_name: "grottoes, va",
  },
  {
    postal_code: "34690",
    zip_name: "holiday, fl",
  },
  {
    postal_code: "80302",
    zip_name: "boulder, co",
  },
  {
    postal_code: "38558",
    zip_name: "crossville, tn",
  },
  {
    postal_code: "34669",
    zip_name: "hudson, fl",
  },
  {
    postal_code: "94526",
    zip_name: "danville, ca",
  },
  {
    postal_code: "84025",
    zip_name: "farmington, ut",
  },
  {
    postal_code: "10001",
    zip_name: "new york, ny",
  },
  {
    postal_code: "53704",
    zip_name: "madison, wi",
  },
  {
    postal_code: "36116",
    zip_name: "montgomery, al",
  },
  {
    postal_code: "80124",
    zip_name: "lone tree, co",
  },
  {
    postal_code: "59714",
    zip_name: "belgrade, mt",
  },
  {
    postal_code: "81521",
    zip_name: "fruita, co",
  },
  {
    postal_code: "45215",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "84092",
    zip_name: "sandy, ut",
  },
  {
    postal_code: "27295",
    zip_name: "lexington, nc",
  },
  {
    postal_code: "40031",
    zip_name: "la grange, ky",
  },
  {
    postal_code: "15212",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "54304",
    zip_name: "green bay, wi",
  },
  {
    postal_code: "84040",
    zip_name: "layton, ut",
  },
  {
    postal_code: "43615",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "22003",
    zip_name: "annandale, va",
  },
  {
    postal_code: "60014",
    zip_name: "crystal lake, il",
  },
  {
    postal_code: "60187",
    zip_name: "wheaton, il",
  },
  {
    postal_code: "46131",
    zip_name: "franklin, in",
  },
  {
    postal_code: "75063",
    zip_name: "irving, tx",
  },
  {
    postal_code: "78201",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "20874",
    zip_name: "germantown, md",
  },
  {
    postal_code: "60558",
    zip_name: "western springs, il",
  },
  {
    postal_code: "55104",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "97404",
    zip_name: "eugene, or",
  },
  {
    postal_code: "75042",
    zip_name: "garland, tx",
  },
  {
    postal_code: "60608",
    zip_name: "chicago, il",
  },
  {
    postal_code: "89441",
    zip_name: "sparks, nv",
  },
  {
    postal_code: "95035",
    zip_name: "milpitas, ca",
  },
  {
    postal_code: "77433",
    zip_name: "cypress, tx",
  },
  {
    postal_code: "35126",
    zip_name: "pinson, al",
  },
  {
    postal_code: "92651",
    zip_name: "laguna beach, ca",
  },
  {
    postal_code: "2864",
    zip_name: "cumberland, ri",
  },
  {
    postal_code: "30013",
    zip_name: "conyers, ga",
  },
  {
    postal_code: "93230",
    zip_name: "hanford, ca",
  },
  {
    postal_code: "89423",
    zip_name: "minden, nv",
  },
  {
    postal_code: "59716",
    zip_name: "big sky, mt",
  },
  {
    postal_code: "37863",
    zip_name: "pigeon forge, tn",
  },
  {
    postal_code: "44647",
    zip_name: "massillon, oh",
  },
  {
    postal_code: "28792",
    zip_name: "hendersonville, nc",
  },
  {
    postal_code: "22630",
    zip_name: "front royal, va",
  },
  {
    postal_code: "28712",
    zip_name: "brevard, nc",
  },
  {
    postal_code: "27546",
    zip_name: "lillington, nc",
  },
  {
    postal_code: "59803",
    zip_name: "missoula, mt",
  },
  {
    postal_code: "75757",
    zip_name: "bullard, tx",
  },
  {
    postal_code: "6118",
    zip_name: "east hartford, ct",
  },
  {
    postal_code: "95822",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "64132",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "95765",
    zip_name: "rocklin, ca",
  },
  {
    postal_code: "55421",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "2888",
    zip_name: "warwick, ri",
  },
  {
    postal_code: "45241",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "30071",
    zip_name: "norcross, ga",
  },
  {
    postal_code: "63104",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "20002",
    zip_name: "washington, dc",
  },
  {
    postal_code: "22701",
    zip_name: "culpeper, va",
  },
  {
    postal_code: "76012",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "37615",
    zip_name: "johnson city, tn",
  },
  {
    postal_code: "60104",
    zip_name: "bellwood, il",
  },
  {
    postal_code: "33480",
    zip_name: "palm beach, fl",
  },
  {
    postal_code: "33901",
    zip_name: "fort myers, fl",
  },
  {
    postal_code: "19053",
    zip_name: "feasterville trevose, pa",
  },
  {
    postal_code: "60538",
    zip_name: "montgomery, il",
  },
  {
    postal_code: "28315",
    zip_name: "aberdeen, nc",
  },
  {
    postal_code: "21045",
    zip_name: "columbia, md",
  },
  {
    postal_code: "30035",
    zip_name: "decatur, ga",
  },
  {
    postal_code: "33782",
    zip_name: "pinellas park, fl",
  },
  {
    postal_code: "27406",
    zip_name: "greensboro, nc",
  },
  {
    postal_code: "85257",
    zip_name: "scottsdale, az",
  },
  {
    postal_code: "46227",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "40330",
    zip_name: "harrodsburg, ky",
  },
  {
    postal_code: "60532",
    zip_name: "lisle, il",
  },
  {
    postal_code: "43082",
    zip_name: "westerville, oh",
  },
  {
    postal_code: "60202",
    zip_name: "evanston, il",
  },
  {
    postal_code: "95125",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "90650",
    zip_name: "norwalk, ca",
  },
  {
    postal_code: "32701",
    zip_name: "altamonte springs, fl",
  },
  {
    postal_code: "60107",
    zip_name: "streamwood, il",
  },
  {
    postal_code: "80247",
    zip_name: "denver, co",
  },
  {
    postal_code: "99801",
    zip_name: "juneau, ak",
  },
  {
    postal_code: "80918",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "27517",
    zip_name: "chapel hill, nc",
  },
  {
    postal_code: "77040",
    zip_name: "houston, tx",
  },
  {
    postal_code: "85205",
    zip_name: "mesa, az",
  },
  {
    postal_code: "10309",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "95757",
    zip_name: "elk grove, ca",
  },
  {
    postal_code: "61704",
    zip_name: "bloomington, il",
  },
  {
    postal_code: "32807",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "92882",
    zip_name: "corona, ca",
  },
  {
    postal_code: "39560",
    zip_name: "long beach, ms",
  },
  {
    postal_code: "3842",
    zip_name: "hampton, nh",
  },
  {
    postal_code: "10012",
    zip_name: "new york, ny",
  },
  {
    postal_code: "8062",
    zip_name: "mullica hill, nj",
  },
  {
    postal_code: "27030",
    zip_name: "mount airy, nc",
  },
  {
    postal_code: "8057",
    zip_name: "moorestown, nj",
  },
  {
    postal_code: "48197",
    zip_name: "ypsilanti, mi",
  },
  {
    postal_code: "60646",
    zip_name: "chicago, il",
  },
  {
    postal_code: "44140",
    zip_name: "bay village, oh",
  },
  {
    postal_code: "74019",
    zip_name: "claremore, ok",
  },
  {
    postal_code: "78745",
    zip_name: "austin, tx",
  },
  {
    postal_code: "28804",
    zip_name: "asheville, nc",
  },
  {
    postal_code: "27591",
    zip_name: "wendell, nc",
  },
  {
    postal_code: "43614",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "96720",
    zip_name: "hilo, hi",
  },
  {
    postal_code: "85233",
    zip_name: "gilbert, az",
  },
  {
    postal_code: "27405",
    zip_name: "greensboro, nc",
  },
  {
    postal_code: "35071",
    zip_name: "gardendale, al",
  },
  {
    postal_code: "23230",
    zip_name: "richmond, va",
  },
  {
    postal_code: "60631",
    zip_name: "chicago, il",
  },
  {
    postal_code: "84036",
    zip_name: "kamas, ut",
  },
  {
    postal_code: "44089",
    zip_name: "vermilion, oh",
  },
  {
    postal_code: "43512",
    zip_name: "defiance, oh",
  },
  {
    postal_code: "60544",
    zip_name: "plainfield, il",
  },
  {
    postal_code: "37772",
    zip_name: "lenoir city, tn",
  },
  {
    postal_code: "49735",
    zip_name: "gaylord, mi",
  },
  {
    postal_code: "84302",
    zip_name: "brigham city, ut",
  },
  {
    postal_code: "43229",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "91354",
    zip_name: "valencia, ca",
  },
  {
    postal_code: "91360",
    zip_name: "thousand oaks, ca",
  },
  {
    postal_code: "45236",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "28787",
    zip_name: "weaverville, nc",
  },
  {
    postal_code: "92311",
    zip_name: "barstow, ca",
  },
  {
    postal_code: "60645",
    zip_name: "chicago, il",
  },
  {
    postal_code: "92656",
    zip_name: "aliso viejo, ca",
  },
  {
    postal_code: "61114",
    zip_name: "rockford, il",
  },
  {
    postal_code: "63034",
    zip_name: "florissant, mo",
  },
  {
    postal_code: "85745",
    zip_name: "tucson, az",
  },
  {
    postal_code: "29693",
    zip_name: "westminster, sc",
  },
  {
    postal_code: "23805",
    zip_name: "petersburg, va",
  },
  {
    postal_code: "33715",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "35007",
    zip_name: "alabaster, al",
  },
  {
    postal_code: "24503",
    zip_name: "lynchburg, va",
  },
  {
    postal_code: "60418",
    zip_name: "crestwood, il",
  },
  {
    postal_code: "27707",
    zip_name: "durham, nc",
  },
  {
    postal_code: "30904",
    zip_name: "augusta, ga",
  },
  {
    postal_code: "92883",
    zip_name: "corona, ca",
  },
  {
    postal_code: "33155",
    zip_name: "miami, fl",
  },
  {
    postal_code: "33467",
    zip_name: "lake worth, fl",
  },
  {
    postal_code: "76179",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "27104",
    zip_name: "winston salem, nc",
  },
  {
    postal_code: "46259",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "48910",
    zip_name: "lansing, mi",
  },
  {
    postal_code: "75092",
    zip_name: "sherman, tx",
  },
  {
    postal_code: "38053",
    zip_name: "millington, tn",
  },
  {
    postal_code: "12304",
    zip_name: "schenectady, ny",
  },
  {
    postal_code: "92220",
    zip_name: "banning, ca",
  },
  {
    postal_code: "80229",
    zip_name: "denver, co",
  },
  {
    postal_code: "95841",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "28146",
    zip_name: "salisbury, nc",
  },
  {
    postal_code: "6360",
    zip_name: "norwich, ct",
  },
  {
    postal_code: "27804",
    zip_name: "rocky mount, nc",
  },
  {
    postal_code: "95687",
    zip_name: "vacaville, ca",
  },
  {
    postal_code: "49002",
    zip_name: "portage, mi",
  },
  {
    postal_code: "55128",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "84107",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "89178",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "98404",
    zip_name: "tacoma, wa",
  },
  {
    postal_code: "30338",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "55423",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "32829",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "50323",
    zip_name: "urbandale, ia",
  },
  {
    postal_code: "76120",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "33803",
    zip_name: "lakeland, fl",
  },
  {
    postal_code: "85704",
    zip_name: "tucson, az",
  },
  {
    postal_code: "40383",
    zip_name: "versailles, ky",
  },
  {
    postal_code: "12901",
    zip_name: "plattsburgh, ny",
  },
  {
    postal_code: "8034",
    zip_name: "cherry hill, nj",
  },
  {
    postal_code: "33168",
    zip_name: "miami, fl",
  },
  {
    postal_code: "81301",
    zip_name: "durango, co",
  },
  {
    postal_code: "85024",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "75455",
    zip_name: "mount pleasant, tx",
  },
  {
    postal_code: "46220",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "8735",
    zip_name: "lavallette, nj",
  },
  {
    postal_code: "11510",
    zip_name: "baldwin, ny",
  },
  {
    postal_code: "92054",
    zip_name: "oceanside, ca",
  },
  {
    postal_code: "28086",
    zip_name: "kings mountain, nc",
  },
  {
    postal_code: "37865",
    zip_name: "seymour, tn",
  },
  {
    postal_code: "28036",
    zip_name: "davidson, nc",
  },
  {
    postal_code: "43062",
    zip_name: "pataskala, oh",
  },
  {
    postal_code: "78239",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "77318",
    zip_name: "willis, tx",
  },
  {
    postal_code: "46205",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "30088",
    zip_name: "stone mountain, ga",
  },
  {
    postal_code: "95124",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "43560",
    zip_name: "sylvania, oh",
  },
  {
    postal_code: "30605",
    zip_name: "athens, ga",
  },
  {
    postal_code: "60615",
    zip_name: "chicago, il",
  },
  {
    postal_code: "89406",
    zip_name: "fallon, nv",
  },
  {
    postal_code: "23236",
    zip_name: "richmond, va",
  },
  {
    postal_code: "18902",
    zip_name: "doylestown, pa",
  },
  {
    postal_code: "8332",
    zip_name: "millville, nj",
  },
  {
    postal_code: "32836",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "30273",
    zip_name: "rex, ga",
  },
  {
    postal_code: "33189",
    zip_name: "miami, fl",
  },
  {
    postal_code: "27705",
    zip_name: "durham, nc",
  },
  {
    postal_code: "22405",
    zip_name: "fredericksburg, va",
  },
  {
    postal_code: "44484",
    zip_name: "warren, oh",
  },
  {
    postal_code: "92009",
    zip_name: "carlsbad, ca",
  },
  {
    postal_code: "21788",
    zip_name: "thurmont, md",
  },
  {
    postal_code: "37766",
    zip_name: "la follette, tn",
  },
  {
    postal_code: "78526",
    zip_name: "brownsville, tx",
  },
  {
    postal_code: "21901",
    zip_name: "north east, md",
  },
  {
    postal_code: "20906",
    zip_name: "silver spring, md",
  },
  {
    postal_code: "47274",
    zip_name: "seymour, in",
  },
  {
    postal_code: "55076",
    zip_name: "inver grove heights, mn",
  },
  {
    postal_code: "95661",
    zip_name: "roseville, ca",
  },
  {
    postal_code: "94550",
    zip_name: "livermore, ca",
  },
  {
    postal_code: "29745",
    zip_name: "york, sc",
  },
  {
    postal_code: "94523",
    zip_name: "pleasant hill, ca",
  },
  {
    postal_code: "33980",
    zip_name: "punta gorda, fl",
  },
  {
    postal_code: "92886",
    zip_name: "yorba linda, ca",
  },
  {
    postal_code: "96743",
    zip_name: "kamuela, hi",
  },
  {
    postal_code: "78411",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "33173",
    zip_name: "miami, fl",
  },
  {
    postal_code: "45066",
    zip_name: "springboro, oh",
  },
  {
    postal_code: "33770",
    zip_name: "largo, fl",
  },
  {
    postal_code: "40422",
    zip_name: "danville, ky",
  },
  {
    postal_code: "43204",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "15227",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "34434",
    zip_name: "dunnellon, fl",
  },
  {
    postal_code: "78415",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "94568",
    zip_name: "dublin, ca",
  },
  {
    postal_code: "33563",
    zip_name: "plant city, fl",
  },
  {
    postal_code: "94531",
    zip_name: "antioch, ca",
  },
  {
    postal_code: "29909",
    zip_name: "okatie, sc",
  },
  {
    postal_code: "28786",
    zip_name: "waynesville, nc",
  },
  {
    postal_code: "72076",
    zip_name: "jacksonville, ar",
  },
  {
    postal_code: "90043",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "40502",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "42103",
    zip_name: "bowling green, ky",
  },
  {
    postal_code: "91326",
    zip_name: "porter ranch, ca",
  },
  {
    postal_code: "80020",
    zip_name: "broomfield, co",
  },
  {
    postal_code: "81503",
    zip_name: "grand junction, co",
  },
  {
    postal_code: "20110",
    zip_name: "manassas, va",
  },
  {
    postal_code: "40207",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "46385",
    zip_name: "valparaiso, in",
  },
  {
    postal_code: "28273",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "55902",
    zip_name: "rochester, mn",
  },
  {
    postal_code: "61350",
    zip_name: "ottawa, il",
  },
  {
    postal_code: "60053",
    zip_name: "morton grove, il",
  },
  {
    postal_code: "44135",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "94591",
    zip_name: "vallejo, ca",
  },
  {
    postal_code: "43207",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "77006",
    zip_name: "houston, tx",
  },
  {
    postal_code: "60804",
    zip_name: "cicero, il",
  },
  {
    postal_code: "46350",
    zip_name: "la porte, in",
  },
  {
    postal_code: "78209",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "91403",
    zip_name: "sherman oaks, ca",
  },
  {
    postal_code: "28516",
    zip_name: "beaufort, nc",
  },
  {
    postal_code: "50125",
    zip_name: "indianola, ia",
  },
  {
    postal_code: "32810",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "55912",
    zip_name: "austin, mn",
  },
  {
    postal_code: "84078",
    zip_name: "vernal, ut",
  },
  {
    postal_code: "86324",
    zip_name: "clarkdale, az",
  },
  {
    postal_code: "68506",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "12203",
    zip_name: "albany, ny",
  },
  {
    postal_code: "29407",
    zip_name: "charleston, sc",
  },
  {
    postal_code: "67114",
    zip_name: "newton, ks",
  },
  {
    postal_code: "33954",
    zip_name: "port charlotte, fl",
  },
  {
    postal_code: "78664",
    zip_name: "round rock, tx",
  },
  {
    postal_code: "78704",
    zip_name: "austin, tx",
  },
  {
    postal_code: "33483",
    zip_name: "delray beach, fl",
  },
  {
    postal_code: "85204",
    zip_name: "mesa, az",
  },
  {
    postal_code: "45208",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "44708",
    zip_name: "canton, oh",
  },
  {
    postal_code: "28203",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "98012",
    zip_name: "bothell, wa",
  },
  {
    postal_code: "32901",
    zip_name: "melbourne, fl",
  },
  {
    postal_code: "90049",
    zip_name: "los angeles, ca",
  },
  {
    postal_code: "31326",
    zip_name: "rincon, ga",
  },
  {
    postal_code: "14845",
    zip_name: "horseheads, ny",
  },
  {
    postal_code: "29649",
    zip_name: "greenwood, sc",
  },
  {
    postal_code: "95632",
    zip_name: "galt, ca",
  },
  {
    postal_code: "85373",
    zip_name: "sun city, az",
  },
  {
    postal_code: "6443",
    zip_name: "madison, ct",
  },
  {
    postal_code: "46254",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "91402",
    zip_name: "panorama city, ca",
  },
  {
    postal_code: "30008",
    zip_name: "marietta, ga",
  },
  {
    postal_code: "33625",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "60629",
    zip_name: "chicago, il",
  },
  {
    postal_code: "30240",
    zip_name: "lagrange, ga",
  },
  {
    postal_code: "20176",
    zip_name: "leesburg, va",
  },
  {
    postal_code: "50265",
    zip_name: "west des moines, ia",
  },
  {
    postal_code: "85022",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "85035",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "79707",
    zip_name: "midland, tx",
  },
  {
    postal_code: "60636",
    zip_name: "chicago, il",
  },
  {
    postal_code: "30533",
    zip_name: "dahlonega, ga",
  },
  {
    postal_code: "44087",
    zip_name: "twinsburg, oh",
  },
  {
    postal_code: "78221",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "27614",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "55412",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "63105",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "97113",
    zip_name: "cornelius, or",
  },
  {
    postal_code: "55126",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "50313",
    zip_name: "des moines, ia",
  },
  {
    postal_code: "60060",
    zip_name: "mundelein, il",
  },
  {
    postal_code: "33478",
    zip_name: "jupiter, fl",
  },
  {
    postal_code: "6811",
    zip_name: "danbury, ct",
  },
  {
    postal_code: "91604",
    zip_name: "studio city, ca",
  },
  {
    postal_code: "92691",
    zip_name: "mission viejo, ca",
  },
  {
    postal_code: "70122",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "54701",
    zip_name: "eau claire, wi",
  },
  {
    postal_code: "64086",
    zip_name: "lees summit, mo",
  },
  {
    postal_code: "75160",
    zip_name: "terrell, tx",
  },
  {
    postal_code: "80204",
    zip_name: "denver, co",
  },
  {
    postal_code: "94583",
    zip_name: "san ramon, ca",
  },
  {
    postal_code: "70115",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "30341",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "12205",
    zip_name: "albany, ny",
  },
  {
    postal_code: "44116",
    zip_name: "rocky river, oh",
  },
  {
    postal_code: "96150",
    zip_name: "south lake tahoe, ca",
  },
  {
    postal_code: "98052",
    zip_name: "redmond, wa",
  },
  {
    postal_code: "76087",
    zip_name: "weatherford, tx",
  },
  {
    postal_code: "95610",
    zip_name: "citrus heights, ca",
  },
  {
    postal_code: "32804",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "28226",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "75407",
    zip_name: "princeton, tx",
  },
  {
    postal_code: "33579",
    zip_name: "riverview, fl",
  },
  {
    postal_code: "85212",
    zip_name: "mesa, az",
  },
  {
    postal_code: "35244",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "92223",
    zip_name: "beaumont, ca",
  },
  {
    postal_code: "85323",
    zip_name: "avondale, az",
  },
  {
    postal_code: "75062",
    zip_name: "irving, tx",
  },
  {
    postal_code: "78610",
    zip_name: "buda, tx",
  },
  {
    postal_code: "37659",
    zip_name: "jonesborough, tn",
  },
  {
    postal_code: "84321",
    zip_name: "logan, ut",
  },
  {
    postal_code: "63119",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "40223",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "92399",
    zip_name: "yucaipa, ca",
  },
  {
    postal_code: "27265",
    zip_name: "high point, nc",
  },
  {
    postal_code: "63118",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "6611",
    zip_name: "trumbull, ct",
  },
  {
    postal_code: "28422",
    zip_name: "bolivia, nc",
  },
  {
    postal_code: "55309",
    zip_name: "big lake, mn",
  },
  {
    postal_code: "26505",
    zip_name: "morgantown, wv",
  },
  {
    postal_code: "60435",
    zip_name: "joliet, il",
  },
  {
    postal_code: "92154",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "15102",
    zip_name: "bethel park, pa",
  },
  {
    postal_code: "30252",
    zip_name: "mcdonough, ga",
  },
  {
    postal_code: "6880",
    zip_name: "westport, ct",
  },
  {
    postal_code: "60714",
    zip_name: "niles, il",
  },
  {
    postal_code: "44720",
    zip_name: "north canton, oh",
  },
  {
    postal_code: "43701",
    zip_name: "zanesville, oh",
  },
  {
    postal_code: "91780",
    zip_name: "temple city, ca",
  },
  {
    postal_code: "28532",
    zip_name: "havelock, nc",
  },
  {
    postal_code: "33175",
    zip_name: "miami, fl",
  },
  {
    postal_code: "77027",
    zip_name: "houston, tx",
  },
  {
    postal_code: "89431",
    zip_name: "sparks, nv",
  },
  {
    postal_code: "67002",
    zip_name: "andover, ks",
  },
  {
    postal_code: "33073",
    zip_name: "pompano beach, fl",
  },
  {
    postal_code: "77586",
    zip_name: "seabrook, tx",
  },
  {
    postal_code: "53593",
    zip_name: "verona, wi",
  },
  {
    postal_code: "70503",
    zip_name: "lafayette, la",
  },
  {
    postal_code: "35111",
    zip_name: "mc calla, al",
  },
  {
    postal_code: "60133",
    zip_name: "hanover park, il",
  },
  {
    postal_code: "55313",
    zip_name: "buffalo, mn",
  },
  {
    postal_code: "33129",
    zip_name: "miami, fl",
  },
  {
    postal_code: "3246",
    zip_name: "laconia, nh",
  },
  {
    postal_code: "60637",
    zip_name: "chicago, il",
  },
  {
    postal_code: "47201",
    zip_name: "columbus, in",
  },
  {
    postal_code: "28584",
    zip_name: "swansboro, nc",
  },
  {
    postal_code: "93727",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "88101",
    zip_name: "clovis, nm",
  },
  {
    postal_code: "15210",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "84121",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "43110",
    zip_name: "canal winchester, oh",
  },
  {
    postal_code: "92532",
    zip_name: "lake elsinore, ca",
  },
  {
    postal_code: "30507",
    zip_name: "gainesville, ga",
  },
  {
    postal_code: "11216",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "73116",
    zip_name: "oklahoma city, ok",
  },
  {
    postal_code: "20147",
    zip_name: "ashburn, va",
  },
  {
    postal_code: "89434",
    zip_name: "sparks, nv",
  },
  {
    postal_code: "60002",
    zip_name: "antioch, il",
  },
  {
    postal_code: "6492",
    zip_name: "wallingford, ct",
  },
  {
    postal_code: "55109",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "45420",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "25414",
    zip_name: "charles town, wv",
  },
  {
    postal_code: "79118",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "75048",
    zip_name: "sachse, tx",
  },
  {
    postal_code: "45242",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "33805",
    zip_name: "lakeland, fl",
  },
  {
    postal_code: "33967",
    zip_name: "fort myers, fl",
  },
  {
    postal_code: "11235",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "75234",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "65536",
    zip_name: "lebanon, mo",
  },
  {
    postal_code: "94521",
    zip_name: "concord, ca",
  },
  {
    postal_code: "55433",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "60013",
    zip_name: "cary, il",
  },
  {
    postal_code: "60305",
    zip_name: "river forest, il",
  },
  {
    postal_code: "60402",
    zip_name: "berwyn, il",
  },
  {
    postal_code: "44146",
    zip_name: "bedford, oh",
  },
  {
    postal_code: "55316",
    zip_name: "champlin, mn",
  },
  {
    postal_code: "92404",
    zip_name: "san bernardino, ca",
  },
  {
    postal_code: "28112",
    zip_name: "monroe, nc",
  },
  {
    postal_code: "81504",
    zip_name: "grand junction, co",
  },
  {
    postal_code: "33955",
    zip_name: "punta gorda, fl",
  },
  {
    postal_code: "13905",
    zip_name: "binghamton, ny",
  },
  {
    postal_code: "85365",
    zip_name: "yuma, az",
  },
  {
    postal_code: "23225",
    zip_name: "richmond, va",
  },
  {
    postal_code: "24501",
    zip_name: "lynchburg, va",
  },
  {
    postal_code: "77447",
    zip_name: "hockley, tx",
  },
  {
    postal_code: "2889",
    zip_name: "warwick, ri",
  },
  {
    postal_code: "64064",
    zip_name: "lees summit, mo",
  },
  {
    postal_code: "27107",
    zip_name: "winston salem, nc",
  },
  {
    postal_code: "28715",
    zip_name: "candler, nc",
  },
  {
    postal_code: "72120",
    zip_name: "sherwood, ar",
  },
  {
    postal_code: "80023",
    zip_name: "broomfield, co",
  },
  {
    postal_code: "44035",
    zip_name: "elyria, oh",
  },
  {
    postal_code: "34239",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "44128",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "85757",
    zip_name: "tucson, az",
  },
  {
    postal_code: "30153",
    zip_name: "rockmart, ga",
  },
  {
    postal_code: "45227",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "34228",
    zip_name: "longboat key, fl",
  },
  {
    postal_code: "93010",
    zip_name: "camarillo, ca",
  },
  {
    postal_code: "60478",
    zip_name: "country club hills, il",
  },
  {
    postal_code: "45042",
    zip_name: "middletown, oh",
  },
  {
    postal_code: "60097",
    zip_name: "wonder lake, il",
  },
  {
    postal_code: "60025",
    zip_name: "glenview, il",
  },
  {
    postal_code: "95123",
    zip_name: "san jose, ca",
  },
  {
    postal_code: "15236",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "94536",
    zip_name: "fremont, ca",
  },
  {
    postal_code: "27127",
    zip_name: "winston salem, nc",
  },
  {
    postal_code: "44143",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "30060",
    zip_name: "marietta, ga",
  },
  {
    postal_code: "77581",
    zip_name: "pearland, tx",
  },
  {
    postal_code: "46234",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "60451",
    zip_name: "new lenox, il",
  },
  {
    postal_code: "95336",
    zip_name: "manteca, ca",
  },
  {
    postal_code: "55318",
    zip_name: "chaska, mn",
  },
  {
    postal_code: "85016",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "55391",
    zip_name: "wayzata, mn",
  },
  {
    postal_code: "77381",
    zip_name: "spring, tx",
  },
  {
    postal_code: "31407",
    zip_name: "port wentworth, ga",
  },
  {
    postal_code: "61008",
    zip_name: "belvidere, il",
  },
  {
    postal_code: "12831",
    zip_name: "gansevoort, ny",
  },
  {
    postal_code: "80923",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "62234",
    zip_name: "collinsville, il",
  },
  {
    postal_code: "32926",
    zip_name: "cocoa, fl",
  },
  {
    postal_code: "98274",
    zip_name: "mount vernon, wa",
  },
  {
    postal_code: "30224",
    zip_name: "griffin, ga",
  },
  {
    postal_code: "37135",
    zip_name: "nolensville, tn",
  },
  {
    postal_code: "11746",
    zip_name: "huntington station, ny",
  },
  {
    postal_code: "34653",
    zip_name: "new port richey, fl",
  },
  {
    postal_code: "32968",
    zip_name: "vero beach, fl",
  },
  {
    postal_code: "32751",
    zip_name: "maitland, fl",
  },
  {
    postal_code: "91773",
    zip_name: "san dimas, ca",
  },
  {
    postal_code: "44202",
    zip_name: "aurora, oh",
  },
  {
    postal_code: "43016",
    zip_name: "dublin, oh",
  },
  {
    postal_code: "30116",
    zip_name: "carrollton, ga",
  },
  {
    postal_code: "45239",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "66226",
    zip_name: "shawnee, ks",
  },
  {
    postal_code: "76051",
    zip_name: "grapevine, tx",
  },
  {
    postal_code: "78645",
    zip_name: "leander, tx",
  },
  {
    postal_code: "95361",
    zip_name: "oakdale, ca",
  },
  {
    postal_code: "93505",
    zip_name: "california city, ca",
  },
  {
    postal_code: "55119",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "60827",
    zip_name: "riverdale, il",
  },
  {
    postal_code: "78228",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "74403",
    zip_name: "muskogee, ok",
  },
  {
    postal_code: "23060",
    zip_name: "glen allen, va",
  },
  {
    postal_code: "28570",
    zip_name: "newport, nc",
  },
  {
    postal_code: "86005",
    zip_name: "flagstaff, az",
  },
  {
    postal_code: "78216",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "45371",
    zip_name: "tipp city, oh",
  },
  {
    postal_code: "95355",
    zip_name: "modesto, ca",
  },
  {
    postal_code: "28428",
    zip_name: "carolina beach, nc",
  },
  {
    postal_code: "30121",
    zip_name: "cartersville, ga",
  },
  {
    postal_code: "77471",
    zip_name: "rosenberg, tx",
  },
  {
    postal_code: "85283",
    zip_name: "tempe, az",
  },
  {
    postal_code: "37849",
    zip_name: "powell, tn",
  },
  {
    postal_code: "77591",
    zip_name: "texas city, tx",
  },
  {
    postal_code: "31401",
    zip_name: "savannah, ga",
  },
  {
    postal_code: "32903",
    zip_name: "indialantic, fl",
  },
  {
    postal_code: "22554",
    zip_name: "stafford, va",
  },
  {
    postal_code: "63141",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "74145",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "63010",
    zip_name: "arnold, mo",
  },
  {
    postal_code: "80925",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "33327",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "28468",
    zip_name: "sunset beach, nc",
  },
  {
    postal_code: "33602",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "80534",
    zip_name: "johnstown, co",
  },
  {
    postal_code: "33130",
    zip_name: "miami, fl",
  },
  {
    postal_code: "34949",
    zip_name: "fort pierce, fl",
  },
  {
    postal_code: "78254",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "74074",
    zip_name: "stillwater, ok",
  },
  {
    postal_code: "69101",
    zip_name: "north platte, ne",
  },
  {
    postal_code: "6002",
    zip_name: "bloomfield, ct",
  },
  {
    postal_code: "33870",
    zip_name: "sebring, fl",
  },
  {
    postal_code: "77357",
    zip_name: "new caney, tx",
  },
  {
    postal_code: "84117",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "55021",
    zip_name: "faribault, mn",
  },
  {
    postal_code: "92591",
    zip_name: "temecula, ca",
  },
  {
    postal_code: "77047",
    zip_name: "houston, tx",
  },
  {
    postal_code: "80132",
    zip_name: "monument, co",
  },
  {
    postal_code: "84770",
    zip_name: "saint george, ut",
  },
  {
    postal_code: "75216",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "76266",
    zip_name: "sanger, tx",
  },
  {
    postal_code: "98092",
    zip_name: "auburn, wa",
  },
  {
    postal_code: "33616",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "63025",
    zip_name: "eureka, mo",
  },
  {
    postal_code: "33142",
    zip_name: "miami, fl",
  },
  {
    postal_code: "32003",
    zip_name: "fleming island, fl",
  },
  {
    postal_code: "94603",
    zip_name: "oakland, ca",
  },
  {
    postal_code: "92503",
    zip_name: "riverside, ca",
  },
  {
    postal_code: "27948",
    zip_name: "kill devil hills, nc",
  },
  {
    postal_code: "33617",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "94501",
    zip_name: "alameda, ca",
  },
  {
    postal_code: "60108",
    zip_name: "bloomingdale, il",
  },
  {
    postal_code: "60632",
    zip_name: "chicago, il",
  },
  {
    postal_code: "43420",
    zip_name: "fremont, oh",
  },
  {
    postal_code: "37115",
    zip_name: "madison, tn",
  },
  {
    postal_code: "6450",
    zip_name: "meriden, ct",
  },
  {
    postal_code: "32779",
    zip_name: "longwood, fl",
  },
  {
    postal_code: "60074",
    zip_name: "palatine, il",
  },
  {
    postal_code: "33510",
    zip_name: "brandon, fl",
  },
  {
    postal_code: "75067",
    zip_name: "lewisville, tx",
  },
  {
    postal_code: "60061",
    zip_name: "vernon hills, il",
  },
  {
    postal_code: "80435",
    zip_name: "dillon, co",
  },
  {
    postal_code: "60642",
    zip_name: "chicago, il",
  },
  {
    postal_code: "30721",
    zip_name: "dalton, ga",
  },
  {
    postal_code: "2893",
    zip_name: "west warwick, ri",
  },
  {
    postal_code: "85706",
    zip_name: "tucson, az",
  },
  {
    postal_code: "75034",
    zip_name: "frisco, tx",
  },
  {
    postal_code: "12533",
    zip_name: "hopewell junction, ny",
  },
  {
    postal_code: "29418",
    zip_name: "north charleston, sc",
  },
  {
    postal_code: "37620",
    zip_name: "bristol, tn",
  },
  {
    postal_code: "94553",
    zip_name: "martinez, ca",
  },
  {
    postal_code: "99206",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "28405",
    zip_name: "wilmington, nc",
  },
  {
    postal_code: "77005",
    zip_name: "houston, tx",
  },
  {
    postal_code: "60542",
    zip_name: "north aurora, il",
  },
  {
    postal_code: "33704",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "44515",
    zip_name: "youngstown, oh",
  },
  {
    postal_code: "92315",
    zip_name: "big bear lake, ca",
  },
  {
    postal_code: "16046",
    zip_name: "mars, pa",
  },
  {
    postal_code: "28278",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "74134",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "70043",
    zip_name: "chalmette, la",
  },
  {
    postal_code: "44281",
    zip_name: "wadsworth, oh",
  },
  {
    postal_code: "10075",
    zip_name: "new york, ny",
  },
  {
    postal_code: "60430",
    zip_name: "homewood, il",
  },
  {
    postal_code: "62220",
    zip_name: "belleville, il",
  },
  {
    postal_code: "44236",
    zip_name: "hudson, oh",
  },
  {
    postal_code: "44012",
    zip_name: "avon lake, oh",
  },
  {
    postal_code: "32141",
    zip_name: "edgewater, fl",
  },
  {
    postal_code: "29907",
    zip_name: "beaufort, sc",
  },
  {
    postal_code: "55317",
    zip_name: "chanhassen, mn",
  },
  {
    postal_code: "15068",
    zip_name: "new kensington, pa",
  },
  {
    postal_code: "78232",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "55406",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "27889",
    zip_name: "washington, nc",
  },
  {
    postal_code: "27330",
    zip_name: "sanford, nc",
  },
  {
    postal_code: "84037",
    zip_name: "kaysville, ut",
  },
  {
    postal_code: "60651",
    zip_name: "chicago, il",
  },
  {
    postal_code: "85020",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "63135",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "55374",
    zip_name: "rogers, mn",
  },
  {
    postal_code: "60561",
    zip_name: "darien, il",
  },
  {
    postal_code: "28213",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "20878",
    zip_name: "gaithersburg, md",
  },
  {
    postal_code: "56001",
    zip_name: "mankato, mn",
  },
  {
    postal_code: "85743",
    zip_name: "tucson, az",
  },
  {
    postal_code: "77338",
    zip_name: "humble, tx",
  },
  {
    postal_code: "76207",
    zip_name: "denton, tx",
  },
  {
    postal_code: "49525",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "96793",
    zip_name: "wailuku, hi",
  },
  {
    postal_code: "60154",
    zip_name: "westchester, il",
  },
  {
    postal_code: "98110",
    zip_name: "bainbridge island, wa",
  },
  {
    postal_code: "41071",
    zip_name: "newport, ky",
  },
  {
    postal_code: "7748",
    zip_name: "middletown, nj",
  },
  {
    postal_code: "61604",
    zip_name: "peoria, il",
  },
  {
    postal_code: "76034",
    zip_name: "colleyville, tx",
  },
  {
    postal_code: "37924",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "78660",
    zip_name: "pflugerville, tx",
  },
  {
    postal_code: "45356",
    zip_name: "piqua, oh",
  },
  {
    postal_code: "85018",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "75150",
    zip_name: "mesquite, tx",
  },
  {
    postal_code: "55122",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "22942",
    zip_name: "gordonsville, va",
  },
  {
    postal_code: "12144",
    zip_name: "rensselaer, ny",
  },
  {
    postal_code: "50131",
    zip_name: "johnston, ia",
  },
  {
    postal_code: "28387",
    zip_name: "southern pines, nc",
  },
  {
    postal_code: "78413",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "95240",
    zip_name: "lodi, ca",
  },
  {
    postal_code: "63043",
    zip_name: "maryland heights, mo",
  },
  {
    postal_code: "77056",
    zip_name: "houston, tx",
  },
  {
    postal_code: "63020",
    zip_name: "de soto, mo",
  },
  {
    postal_code: "74017",
    zip_name: "claremore, ok",
  },
  {
    postal_code: "71360",
    zip_name: "pineville, la",
  },
  {
    postal_code: "60090",
    zip_name: "wheeling, il",
  },
  {
    postal_code: "44685",
    zip_name: "uniontown, oh",
  },
  {
    postal_code: "85712",
    zip_name: "tucson, az",
  },
  {
    postal_code: "39110",
    zip_name: "madison, ms",
  },
  {
    postal_code: "85737",
    zip_name: "tucson, az",
  },
  {
    postal_code: "29403",
    zip_name: "charleston, sc",
  },
  {
    postal_code: "62269",
    zip_name: "o fallon, il",
  },
  {
    postal_code: "28012",
    zip_name: "belmont, nc",
  },
  {
    postal_code: "75088",
    zip_name: "rowlett, tx",
  },
  {
    postal_code: "53711",
    zip_name: "madison, wi",
  },
  {
    postal_code: "15650",
    zip_name: "latrobe, pa",
  },
  {
    postal_code: "46260",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "28056",
    zip_name: "gastonia, nc",
  },
  {
    postal_code: "76133",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "49423",
    zip_name: "holland, mi",
  },
  {
    postal_code: "77073",
    zip_name: "houston, tx",
  },
  {
    postal_code: "34695",
    zip_name: "safety harbor, fl",
  },
  {
    postal_code: "45255",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "80528",
    zip_name: "fort collins, co",
  },
  {
    postal_code: "85746",
    zip_name: "tucson, az",
  },
  {
    postal_code: "60172",
    zip_name: "roselle, il",
  },
  {
    postal_code: "20743",
    zip_name: "capitol heights, md",
  },
  {
    postal_code: "93245",
    zip_name: "lemoore, ca",
  },
  {
    postal_code: "85711",
    zip_name: "tucson, az",
  },
  {
    postal_code: "32960",
    zip_name: "vero beach, fl",
  },
  {
    postal_code: "60422",
    zip_name: "flossmoor, il",
  },
  {
    postal_code: "71303",
    zip_name: "alexandria, la",
  },
  {
    postal_code: "19963",
    zip_name: "milford, de",
  },
  {
    postal_code: "45152",
    zip_name: "morrow, oh",
  },
  {
    postal_code: "55110",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "92688",
    zip_name: "rancho santa margarita, ca",
  },
  {
    postal_code: "44121",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "27612",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "60102",
    zip_name: "algonquin, il",
  },
  {
    postal_code: "18324",
    zip_name: "bushkill, pa",
  },
  {
    postal_code: "33763",
    zip_name: "clearwater, fl",
  },
  {
    postal_code: "28052",
    zip_name: "gastonia, nc",
  },
  {
    postal_code: "78218",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "78070",
    zip_name: "spring branch, tx",
  },
  {
    postal_code: "44514",
    zip_name: "youngstown, oh",
  },
  {
    postal_code: "60051",
    zip_name: "mchenry, il",
  },
  {
    postal_code: "92620",
    zip_name: "irvine, ca",
  },
  {
    postal_code: "95076",
    zip_name: "watsonville, ca",
  },
  {
    postal_code: "34446",
    zip_name: "homosassa, fl",
  },
  {
    postal_code: "59106",
    zip_name: "billings, mt",
  },
  {
    postal_code: "2891",
    zip_name: "westerly, ri",
  },
  {
    postal_code: "99224",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "87107",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "77092",
    zip_name: "houston, tx",
  },
  {
    postal_code: "72205",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "33160",
    zip_name: "north miami beach, fl",
  },
  {
    postal_code: "29486",
    zip_name: "summerville, sc",
  },
  {
    postal_code: "30291",
    zip_name: "union city, ga",
  },
  {
    postal_code: "72653",
    zip_name: "mountain home, ar",
  },
  {
    postal_code: "30620",
    zip_name: "bethlehem, ga",
  },
  {
    postal_code: "28208",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "85298",
    zip_name: "gilbert, az",
  },
  {
    postal_code: "78373",
    zip_name: "port aransas, tx",
  },
  {
    postal_code: "6776",
    zip_name: "new milford, ct",
  },
  {
    postal_code: "76180",
    zip_name: "north richland hills, tx",
  },
  {
    postal_code: "85209",
    zip_name: "mesa, az",
  },
  {
    postal_code: "15217",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "55038",
    zip_name: "hugo, mn",
  },
  {
    postal_code: "23834",
    zip_name: "colonial heights, va",
  },
  {
    postal_code: "46218",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "49341",
    zip_name: "rockford, mi",
  },
  {
    postal_code: "33351",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "95391",
    zip_name: "tracy, ca",
  },
  {
    postal_code: "22963",
    zip_name: "palmyra, va",
  },
  {
    postal_code: "95023",
    zip_name: "hollister, ca",
  },
  {
    postal_code: "79605",
    zip_name: "abilene, tx",
  },
  {
    postal_code: "78222",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "78240",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "60098",
    zip_name: "woodstock, il",
  },
  {
    postal_code: "22407",
    zip_name: "fredericksburg, va",
  },
  {
    postal_code: "52732",
    zip_name: "clinton, ia",
  },
  {
    postal_code: "34471",
    zip_name: "ocala, fl",
  },
  {
    postal_code: "75023",
    zip_name: "plano, tx",
  },
  {
    postal_code: "6897",
    zip_name: "wilton, ct",
  },
  {
    postal_code: "84118",
    zip_name: "salt lake city, ut",
  },
  {
    postal_code: "92394",
    zip_name: "victorville, ca",
  },
  {
    postal_code: "28105",
    zip_name: "matthews, nc",
  },
  {
    postal_code: "45431",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "33544",
    zip_name: "wesley chapel, fl",
  },
  {
    postal_code: "60115",
    zip_name: "dekalb, il",
  },
  {
    postal_code: "80007",
    zip_name: "arvada, co",
  },
  {
    postal_code: "45039",
    zip_name: "maineville, oh",
  },
  {
    postal_code: "44109",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "40206",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "78666",
    zip_name: "san marcos, tx",
  },
  {
    postal_code: "43017",
    zip_name: "dublin, oh",
  },
  {
    postal_code: "95901",
    zip_name: "marysville, ca",
  },
  {
    postal_code: "76901",
    zip_name: "san angelo, tx",
  },
  {
    postal_code: "27615",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "43054",
    zip_name: "new albany, oh",
  },
  {
    postal_code: "55331",
    zip_name: "excelsior, mn",
  },
  {
    postal_code: "32206",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "22030",
    zip_name: "fairfax, va",
  },
  {
    postal_code: "22191",
    zip_name: "woodbridge, va",
  },
  {
    postal_code: "8088",
    zip_name: "vincentown, nj",
  },
  {
    postal_code: "6457",
    zip_name: "middletown, ct",
  },
  {
    postal_code: "60124",
    zip_name: "elgin, il",
  },
  {
    postal_code: "28734",
    zip_name: "franklin, nc",
  },
  {
    postal_code: "33412",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "11372",
    zip_name: "jackson heights, ny",
  },
  {
    postal_code: "74114",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "61761",
    zip_name: "normal, il",
  },
  {
    postal_code: "11561",
    zip_name: "long beach, ny",
  },
  {
    postal_code: "75080",
    zip_name: "richardson, tx",
  },
  {
    postal_code: "6082",
    zip_name: "enfield, ct",
  },
  {
    postal_code: "63130",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "24015",
    zip_name: "roanoke, va",
  },
  {
    postal_code: "37938",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "12302",
    zip_name: "schenectady, ny",
  },
  {
    postal_code: "35120",
    zip_name: "odenville, al",
  },
  {
    postal_code: "60605",
    zip_name: "chicago, il",
  },
  {
    postal_code: "76708",
    zip_name: "waco, tx",
  },
  {
    postal_code: "20191",
    zip_name: "reston, va",
  },
  {
    postal_code: "75217",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "37804",
    zip_name: "maryville, tn",
  },
  {
    postal_code: "32763",
    zip_name: "orange city, fl",
  },
  {
    postal_code: "33314",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "40505",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "93257",
    zip_name: "porterville, ca",
  },
  {
    postal_code: "30314",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "72751",
    zip_name: "pea ridge, ar",
  },
  {
    postal_code: "41018",
    zip_name: "erlanger, ky",
  },
  {
    postal_code: "62221",
    zip_name: "belleville, il",
  },
  {
    postal_code: "84405",
    zip_name: "ogden, ut",
  },
  {
    postal_code: "29902",
    zip_name: "beaufort, sc",
  },
  {
    postal_code: "28677",
    zip_name: "statesville, nc",
  },
  {
    postal_code: "75028",
    zip_name: "flower mound, tx",
  },
  {
    postal_code: "33067",
    zip_name: "pompano beach, fl",
  },
  {
    postal_code: "84414",
    zip_name: "ogden, ut",
  },
  {
    postal_code: "55428",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "77345",
    zip_name: "kingwood, tx",
  },
  {
    postal_code: "27606",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "60134",
    zip_name: "geneva, il",
  },
  {
    postal_code: "96778",
    zip_name: "pahoa, hi",
  },
  {
    postal_code: "37128",
    zip_name: "murfreesboro, tn",
  },
  {
    postal_code: "84081",
    zip_name: "west jordan, ut",
  },
  {
    postal_code: "11375",
    zip_name: "forest hills, ny",
  },
  {
    postal_code: "32806",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "33166",
    zip_name: "miami, fl",
  },
  {
    postal_code: "92301",
    zip_name: "adelanto, ca",
  },
  {
    postal_code: "76549",
    zip_name: "killeen, tx",
  },
  {
    postal_code: "20744",
    zip_name: "fort washington, md",
  },
  {
    postal_code: "11231",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "70518",
    zip_name: "broussard, la",
  },
  {
    postal_code: "95608",
    zip_name: "carmichael, ca",
  },
  {
    postal_code: "28326",
    zip_name: "cameron, nc",
  },
  {
    postal_code: "72015",
    zip_name: "benton, ar",
  },
  {
    postal_code: "27909",
    zip_name: "elizabeth city, nc",
  },
  {
    postal_code: "60616",
    zip_name: "chicago, il",
  },
  {
    postal_code: "11937",
    zip_name: "east hampton, ny",
  },
  {
    postal_code: "27603",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "30141",
    zip_name: "hiram, ga",
  },
  {
    postal_code: "76119",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "2852",
    zip_name: "north kingstown, ri",
  },
  {
    postal_code: "23139",
    zip_name: "powhatan, va",
  },
  {
    postal_code: "6489",
    zip_name: "southington, ct",
  },
  {
    postal_code: "77550",
    zip_name: "galveston, tx",
  },
  {
    postal_code: "75243",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "29569",
    zip_name: "loris, sc",
  },
  {
    postal_code: "81212",
    zip_name: "canon city, co",
  },
  {
    postal_code: "34207",
    zip_name: "bradenton, fl",
  },
  {
    postal_code: "46235",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "77088",
    zip_name: "houston, tx",
  },
  {
    postal_code: "63146",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "96749",
    zip_name: "keaau, hi",
  },
  {
    postal_code: "78250",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "61554",
    zip_name: "pekin, il",
  },
  {
    postal_code: "79109",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "55125",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "32615",
    zip_name: "alachua, fl",
  },
  {
    postal_code: "60614",
    zip_name: "chicago, il",
  },
  {
    postal_code: "30046",
    zip_name: "lawrenceville, ga",
  },
  {
    postal_code: "79705",
    zip_name: "midland, tx",
  },
  {
    postal_code: "38111",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "38128",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "33016",
    zip_name: "hialeah, fl",
  },
  {
    postal_code: "6516",
    zip_name: "west haven, ct",
  },
  {
    postal_code: "44124",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "91942",
    zip_name: "la mesa, ca",
  },
  {
    postal_code: "29410",
    zip_name: "hanahan, sc",
  },
  {
    postal_code: "50266",
    zip_name: "west des moines, ia",
  },
  {
    postal_code: "8080",
    zip_name: "sewell, nj",
  },
  {
    postal_code: "45322",
    zip_name: "englewood, oh",
  },
  {
    postal_code: "55124",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "48104",
    zip_name: "ann arbor, mi",
  },
  {
    postal_code: "11784",
    zip_name: "selden, ny",
  },
  {
    postal_code: "33181",
    zip_name: "miami, fl",
  },
  {
    postal_code: "61107",
    zip_name: "rockford, il",
  },
  {
    postal_code: "6488",
    zip_name: "southbury, ct",
  },
  {
    postal_code: "72703",
    zip_name: "fayetteville, ar",
  },
  {
    postal_code: "75204",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "46229",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "30236",
    zip_name: "jonesboro, ga",
  },
  {
    postal_code: "60085",
    zip_name: "waukegan, il",
  },
  {
    postal_code: "74464",
    zip_name: "tahlequah, ok",
  },
  {
    postal_code: "46208",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "72714",
    zip_name: "bella vista, ar",
  },
  {
    postal_code: "28092",
    zip_name: "lincolnton, nc",
  },
  {
    postal_code: "55369",
    zip_name: "osseo, mn",
  },
  {
    postal_code: "56401",
    zip_name: "brainerd, mn",
  },
  {
    postal_code: "37738",
    zip_name: "gatlinburg, tn",
  },
  {
    postal_code: "27410",
    zip_name: "greensboro, nc",
  },
  {
    postal_code: "91304",
    zip_name: "canoga park, ca",
  },
  {
    postal_code: "33154",
    zip_name: "miami beach, fl",
  },
  {
    postal_code: "15237",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "77351",
    zip_name: "livingston, tx",
  },
  {
    postal_code: "33626",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "29492",
    zip_name: "charleston, sc",
  },
  {
    postal_code: "95330",
    zip_name: "lathrop, ca",
  },
  {
    postal_code: "49201",
    zip_name: "jackson, mi",
  },
  {
    postal_code: "72764",
    zip_name: "springdale, ar",
  },
  {
    postal_code: "19701",
    zip_name: "bear, de",
  },
  {
    postal_code: "8402",
    zip_name: "margate city, nj",
  },
  {
    postal_code: "37215",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "33323",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "33777",
    zip_name: "seminole, fl",
  },
  {
    postal_code: "87109",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "18428",
    zip_name: "hawley, pa",
  },
  {
    postal_code: "34240",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "60654",
    zip_name: "chicago, il",
  },
  {
    postal_code: "70461",
    zip_name: "slidell, la",
  },
  {
    postal_code: "29730",
    zip_name: "rock hill, sc",
  },
  {
    postal_code: "33706",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "48176",
    zip_name: "saline, mi",
  },
  {
    postal_code: "44266",
    zip_name: "ravenna, oh",
  },
  {
    postal_code: "14227",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "63128",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "29456",
    zip_name: "ladson, sc",
  },
  {
    postal_code: "55347",
    zip_name: "eden prairie, mn",
  },
  {
    postal_code: "40047",
    zip_name: "mount washington, ky",
  },
  {
    postal_code: "43068",
    zip_name: "reynoldsburg, oh",
  },
  {
    postal_code: "37923",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "92677",
    zip_name: "laguna niguel, ca",
  },
  {
    postal_code: "33054",
    zip_name: "opa locka, fl",
  },
  {
    postal_code: "95758",
    zip_name: "elk grove, ca",
  },
  {
    postal_code: "46202",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "30213",
    zip_name: "fairburn, ga",
  },
  {
    postal_code: "30813",
    zip_name: "grovetown, ga",
  },
  {
    postal_code: "30117",
    zip_name: "carrollton, ga",
  },
  {
    postal_code: "85234",
    zip_name: "gilbert, az",
  },
  {
    postal_code: "95834",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "12078",
    zip_name: "gloversville, ny",
  },
  {
    postal_code: "50322",
    zip_name: "urbandale, ia",
  },
  {
    postal_code: "39180",
    zip_name: "vicksburg, ms",
  },
  {
    postal_code: "56301",
    zip_name: "saint cloud, mn",
  },
  {
    postal_code: "29644",
    zip_name: "fountain inn, sc",
  },
  {
    postal_code: "78633",
    zip_name: "georgetown, tx",
  },
  {
    postal_code: "60649",
    zip_name: "chicago, il",
  },
  {
    postal_code: "41015",
    zip_name: "latonia, ky",
  },
  {
    postal_code: "32966",
    zip_name: "vero beach, fl",
  },
  {
    postal_code: "62959",
    zip_name: "marion, il",
  },
  {
    postal_code: "59101",
    zip_name: "billings, mt",
  },
  {
    postal_code: "85295",
    zip_name: "gilbert, az",
  },
  {
    postal_code: "33018",
    zip_name: "hialeah, fl",
  },
  {
    postal_code: "84044",
    zip_name: "magna, ut",
  },
  {
    postal_code: "23114",
    zip_name: "midlothian, va",
  },
  {
    postal_code: "2865",
    zip_name: "lincoln, ri",
  },
  {
    postal_code: "97367",
    zip_name: "lincoln city, or",
  },
  {
    postal_code: "77532",
    zip_name: "crosby, tx",
  },
  {
    postal_code: "40515",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "34637",
    zip_name: "land o lakes, fl",
  },
  {
    postal_code: "77082",
    zip_name: "houston, tx",
  },
  {
    postal_code: "63129",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "75075",
    zip_name: "plano, tx",
  },
  {
    postal_code: "44094",
    zip_name: "willoughby, oh",
  },
  {
    postal_code: "76126",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "45069",
    zip_name: "west chester, oh",
  },
  {
    postal_code: "92382",
    zip_name: "running springs, ca",
  },
  {
    postal_code: "44870",
    zip_name: "sandusky, oh",
  },
  {
    postal_code: "55311",
    zip_name: "maple grove, mn",
  },
  {
    postal_code: "95667",
    zip_name: "placerville, ca",
  },
  {
    postal_code: "20782",
    zip_name: "hyattsville, md",
  },
  {
    postal_code: "34715",
    zip_name: "clermont, fl",
  },
  {
    postal_code: "34223",
    zip_name: "englewood, fl",
  },
  {
    postal_code: "98103",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "46037",
    zip_name: "fishers, in",
  },
  {
    postal_code: "20774",
    zip_name: "upper marlboro, md",
  },
  {
    postal_code: "77382",
    zip_name: "spring, tx",
  },
  {
    postal_code: "76049",
    zip_name: "granbury, tx",
  },
  {
    postal_code: "64133",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "85296",
    zip_name: "gilbert, az",
  },
  {
    postal_code: "33325",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "63131",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "30102",
    zip_name: "acworth, ga",
  },
  {
    postal_code: "46038",
    zip_name: "fishers, in",
  },
  {
    postal_code: "99207",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "46168",
    zip_name: "plainfield, in",
  },
  {
    postal_code: "45202",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "39401",
    zip_name: "hattiesburg, ms",
  },
  {
    postal_code: "28465",
    zip_name: "oak island, nc",
  },
  {
    postal_code: "32707",
    zip_name: "casselberry, fl",
  },
  {
    postal_code: "97230",
    zip_name: "portland, or",
  },
  {
    postal_code: "65613",
    zip_name: "bolivar, mo",
  },
  {
    postal_code: "98513",
    zip_name: "olympia, wa",
  },
  {
    postal_code: "37803",
    zip_name: "maryville, tn",
  },
  {
    postal_code: "33712",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "30316",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "28001",
    zip_name: "albemarle, nc",
  },
  {
    postal_code: "37725",
    zip_name: "dandridge, tn",
  },
  {
    postal_code: "95961",
    zip_name: "olivehurst, ca",
  },
  {
    postal_code: "85379",
    zip_name: "surprise, az",
  },
  {
    postal_code: "15001",
    zip_name: "aliquippa, pa",
  },
  {
    postal_code: "55449",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "65301",
    zip_name: "sedalia, mo",
  },
  {
    postal_code: "34117",
    zip_name: "naples, fl",
  },
  {
    postal_code: "60443",
    zip_name: "matteson, il",
  },
  {
    postal_code: "30165",
    zip_name: "rome, ga",
  },
  {
    postal_code: "34677",
    zip_name: "oldsmar, fl",
  },
  {
    postal_code: "85730",
    zip_name: "tucson, az",
  },
  {
    postal_code: "72223",
    zip_name: "little rock, ar",
  },
  {
    postal_code: "27106",
    zip_name: "winston salem, nc",
  },
  {
    postal_code: "92506",
    zip_name: "riverside, ca",
  },
  {
    postal_code: "60517",
    zip_name: "woodridge, il",
  },
  {
    postal_code: "31324",
    zip_name: "richmond hill, ga",
  },
  {
    postal_code: "60707",
    zip_name: "elmwood park, il",
  },
  {
    postal_code: "80238",
    zip_name: "denver, co",
  },
  {
    postal_code: "84095",
    zip_name: "south jordan, ut",
  },
  {
    postal_code: "28607",
    zip_name: "boone, nc",
  },
  {
    postal_code: "98902",
    zip_name: "yakima, wa",
  },
  {
    postal_code: "50208",
    zip_name: "newton, ia",
  },
  {
    postal_code: "93292",
    zip_name: "visalia, ca",
  },
  {
    postal_code: "20009",
    zip_name: "washington, dc",
  },
  {
    postal_code: "97035",
    zip_name: "lake oswego, or",
  },
  {
    postal_code: "84120",
    zip_name: "west valley city, ut",
  },
  {
    postal_code: "85286",
    zip_name: "chandler, az",
  },
  {
    postal_code: "34743",
    zip_name: "kissimmee, fl",
  },
  {
    postal_code: "84664",
    zip_name: "mapleton, ut",
  },
  {
    postal_code: "7747",
    zip_name: "matawan, nj",
  },
  {
    postal_code: "85248",
    zip_name: "chandler, az",
  },
  {
    postal_code: "50111",
    zip_name: "grimes, ia",
  },
  {
    postal_code: "34951",
    zip_name: "fort pierce, fl",
  },
  {
    postal_code: "44312",
    zip_name: "akron, oh",
  },
  {
    postal_code: "21122",
    zip_name: "pasadena, md",
  },
  {
    postal_code: "91355",
    zip_name: "valencia, ca",
  },
  {
    postal_code: "34116",
    zip_name: "naples, fl",
  },
  {
    postal_code: "76107",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "84075",
    zip_name: "syracuse, ut",
  },
  {
    postal_code: "77009",
    zip_name: "houston, tx",
  },
  {
    postal_code: "44240",
    zip_name: "kent, oh",
  },
  {
    postal_code: "55378",
    zip_name: "savage, mn",
  },
  {
    postal_code: "33618",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "60525",
    zip_name: "la grange, il",
  },
  {
    postal_code: "45324",
    zip_name: "fairborn, oh",
  },
  {
    postal_code: "76240",
    zip_name: "gainesville, tx",
  },
  {
    postal_code: "78628",
    zip_name: "georgetown, tx",
  },
  {
    postal_code: "42303",
    zip_name: "owensboro, ky",
  },
  {
    postal_code: "32812",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "60466",
    zip_name: "park forest, il",
  },
  {
    postal_code: "30122",
    zip_name: "lithia springs, ga",
  },
  {
    postal_code: "60188",
    zip_name: "carol stream, il",
  },
  {
    postal_code: "91335",
    zip_name: "reseda, ca",
  },
  {
    postal_code: "60110",
    zip_name: "carpentersville, il",
  },
  {
    postal_code: "63137",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "77389",
    zip_name: "spring, tx",
  },
  {
    postal_code: "23838",
    zip_name: "chesterfield, va",
  },
  {
    postal_code: "78015",
    zip_name: "boerne, tx",
  },
  {
    postal_code: "33764",
    zip_name: "clearwater, fl",
  },
  {
    postal_code: "6111",
    zip_name: "newington, ct",
  },
  {
    postal_code: "60048",
    zip_name: "libertyville, il",
  },
  {
    postal_code: "94544",
    zip_name: "hayward, ca",
  },
  {
    postal_code: "78155",
    zip_name: "seguin, tx",
  },
  {
    postal_code: "60901",
    zip_name: "kankakee, il",
  },
  {
    postal_code: "20602",
    zip_name: "waldorf, md",
  },
  {
    postal_code: "84655",
    zip_name: "santaquin, ut",
  },
  {
    postal_code: "77019",
    zip_name: "houston, tx",
  },
  {
    postal_code: "11211",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "38127",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "21702",
    zip_name: "frederick, md",
  },
  {
    postal_code: "45211",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "63026",
    zip_name: "fenton, mo",
  },
  {
    postal_code: "30642",
    zip_name: "greensboro, ga",
  },
  {
    postal_code: "60516",
    zip_name: "downers grove, il",
  },
  {
    postal_code: "30087",
    zip_name: "stone mountain, ga",
  },
  {
    postal_code: "76017",
    zip_name: "arlington, tx",
  },
  {
    postal_code: "84070",
    zip_name: "sandy, ut",
  },
  {
    postal_code: "44122",
    zip_name: "beachwood, oh",
  },
  {
    postal_code: "89509",
    zip_name: "reno, nv",
  },
  {
    postal_code: "33612",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "40258",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "46221",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "79912",
    zip_name: "el paso, tx",
  },
  {
    postal_code: "46033",
    zip_name: "carmel, in",
  },
  {
    postal_code: "85718",
    zip_name: "tucson, az",
  },
  {
    postal_code: "78634",
    zip_name: "hutto, tx",
  },
  {
    postal_code: "41048",
    zip_name: "hebron, ky",
  },
  {
    postal_code: "10471",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "44212",
    zip_name: "brunswick, oh",
  },
  {
    postal_code: "33711",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "70403",
    zip_name: "hammond, la",
  },
  {
    postal_code: "60139",
    zip_name: "glendale heights, il",
  },
  {
    postal_code: "13090",
    zip_name: "liverpool, ny",
  },
  {
    postal_code: "74055",
    zip_name: "owasso, ok",
  },
  {
    postal_code: "50613",
    zip_name: "cedar falls, ia",
  },
  {
    postal_code: "80011",
    zip_name: "aurora, co",
  },
  {
    postal_code: "85382",
    zip_name: "peoria, az",
  },
  {
    postal_code: "34442",
    zip_name: "hernando, fl",
  },
  {
    postal_code: "34242",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "55113",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "62226",
    zip_name: "belleville, il",
  },
  {
    postal_code: "19140",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "29615",
    zip_name: "greenville, sc",
  },
  {
    postal_code: "55419",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "29412",
    zip_name: "charleston, sc",
  },
  {
    postal_code: "32174",
    zip_name: "ormond beach, fl",
  },
  {
    postal_code: "27529",
    zip_name: "garner, nc",
  },
  {
    postal_code: "33455",
    zip_name: "hobe sound, fl",
  },
  {
    postal_code: "87110",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "35040",
    zip_name: "calera, al",
  },
  {
    postal_code: "33409",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "60201",
    zip_name: "evanston, il",
  },
  {
    postal_code: "30312",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "33060",
    zip_name: "pompano beach, fl",
  },
  {
    postal_code: "60142",
    zip_name: "huntley, il",
  },
  {
    postal_code: "27927",
    zip_name: "corolla, nc",
  },
  {
    postal_code: "26003",
    zip_name: "wheeling, wv",
  },
  {
    postal_code: "37774",
    zip_name: "loudon, tn",
  },
  {
    postal_code: "54703",
    zip_name: "eau claire, wi",
  },
  {
    postal_code: "33755",
    zip_name: "clearwater, fl",
  },
  {
    postal_code: "93555",
    zip_name: "ridgecrest, ca",
  },
  {
    postal_code: "22192",
    zip_name: "woodbridge, va",
  },
  {
    postal_code: "78410",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "28216",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "6790",
    zip_name: "torrington, ct",
  },
  {
    postal_code: "60431",
    zip_name: "joliet, il",
  },
  {
    postal_code: "33852",
    zip_name: "lake placid, fl",
  },
  {
    postal_code: "32811",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "27360",
    zip_name: "thomasville, nc",
  },
  {
    postal_code: "80817",
    zip_name: "fountain, co",
  },
  {
    postal_code: "29566",
    zip_name: "little river, sc",
  },
  {
    postal_code: "38117",
    zip_name: "memphis, tn",
  },
  {
    postal_code: "37801",
    zip_name: "maryville, tn",
  },
  {
    postal_code: "85048",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "85339",
    zip_name: "laveen, az",
  },
  {
    postal_code: "32908",
    zip_name: "palm bay, fl",
  },
  {
    postal_code: "80516",
    zip_name: "erie, co",
  },
  {
    postal_code: "42701",
    zip_name: "elizabethtown, ky",
  },
  {
    postal_code: "80227",
    zip_name: "denver, co",
  },
  {
    postal_code: "60540",
    zip_name: "naperville, il",
  },
  {
    postal_code: "35173",
    zip_name: "trussville, al",
  },
  {
    postal_code: "2910",
    zip_name: "cranston, ri",
  },
  {
    postal_code: "33756",
    zip_name: "clearwater, fl",
  },
  {
    postal_code: "32818",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "55024",
    zip_name: "farmington, mn",
  },
  {
    postal_code: "85260",
    zip_name: "scottsdale, az",
  },
  {
    postal_code: "32952",
    zip_name: "merritt island, fl",
  },
  {
    postal_code: "80526",
    zip_name: "fort collins, co",
  },
  {
    postal_code: "34984",
    zip_name: "port saint lucie, fl",
  },
  {
    postal_code: "96161",
    zip_name: "truckee, ca",
  },
  {
    postal_code: "50211",
    zip_name: "norwalk, ia",
  },
  {
    postal_code: "60439",
    zip_name: "lemont, il",
  },
  {
    postal_code: "75219",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "78133",
    zip_name: "canyon lake, tx",
  },
  {
    postal_code: "78244",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "75701",
    zip_name: "tyler, tx",
  },
  {
    postal_code: "80017",
    zip_name: "aurora, co",
  },
  {
    postal_code: "92056",
    zip_name: "oceanside, ca",
  },
  {
    postal_code: "45373",
    zip_name: "troy, oh",
  },
  {
    postal_code: "77301",
    zip_name: "conroe, tx",
  },
  {
    postal_code: "33177",
    zip_name: "miami, fl",
  },
  {
    postal_code: "11229",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "93635",
    zip_name: "los banos, ca",
  },
  {
    postal_code: "77070",
    zip_name: "houston, tx",
  },
  {
    postal_code: "98516",
    zip_name: "olympia, wa",
  },
  {
    postal_code: "92314",
    zip_name: "big bear city, ca",
  },
  {
    postal_code: "80537",
    zip_name: "loveland, co",
  },
  {
    postal_code: "63304",
    zip_name: "saint charles, mo",
  },
  {
    postal_code: "22033",
    zip_name: "fairfax, va",
  },
  {
    postal_code: "76082",
    zip_name: "springtown, tx",
  },
  {
    postal_code: "33328",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "27513",
    zip_name: "cary, nc",
  },
  {
    postal_code: "98223",
    zip_name: "arlington, wa",
  },
  {
    postal_code: "91356",
    zip_name: "tarzana, ca",
  },
  {
    postal_code: "95973",
    zip_name: "chico, ca",
  },
  {
    postal_code: "85375",
    zip_name: "sun city west, az",
  },
  {
    postal_code: "63303",
    zip_name: "saint charles, mo",
  },
  {
    postal_code: "30040",
    zip_name: "cumming, ga",
  },
  {
    postal_code: "28054",
    zip_name: "gastonia, nc",
  },
  {
    postal_code: "40403",
    zip_name: "berea, ky",
  },
  {
    postal_code: "29906",
    zip_name: "beaufort, sc",
  },
  {
    postal_code: "29440",
    zip_name: "georgetown, sc",
  },
  {
    postal_code: "37660",
    zip_name: "kingsport, tn",
  },
  {
    postal_code: "89408",
    zip_name: "fernley, nv",
  },
  {
    postal_code: "85258",
    zip_name: "scottsdale, az",
  },
  {
    postal_code: "60563",
    zip_name: "naperville, il",
  },
  {
    postal_code: "76092",
    zip_name: "southlake, tx",
  },
  {
    postal_code: "99016",
    zip_name: "greenacres, wa",
  },
  {
    postal_code: "30033",
    zip_name: "decatur, ga",
  },
  {
    postal_code: "60137",
    zip_name: "glen ellyn, il",
  },
  {
    postal_code: "76048",
    zip_name: "granbury, tx",
  },
  {
    postal_code: "62040",
    zip_name: "granite city, il",
  },
  {
    postal_code: "34741",
    zip_name: "kissimmee, fl",
  },
  {
    postal_code: "85748",
    zip_name: "tucson, az",
  },
  {
    postal_code: "80120",
    zip_name: "littleton, co",
  },
  {
    postal_code: "80543",
    zip_name: "milliken, co",
  },
  {
    postal_code: "44134",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "33183",
    zip_name: "miami, fl",
  },
  {
    postal_code: "12047",
    zip_name: "cohoes, ny",
  },
  {
    postal_code: "83854",
    zip_name: "post falls, id",
  },
  {
    postal_code: "33040",
    zip_name: "key west, fl",
  },
  {
    postal_code: "63109",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "92571",
    zip_name: "perris, ca",
  },
  {
    postal_code: "32832",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "29640",
    zip_name: "easley, sc",
  },
  {
    postal_code: "27613",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "48823",
    zip_name: "east lansing, mi",
  },
  {
    postal_code: "33026",
    zip_name: "hollywood, fl",
  },
  {
    postal_code: "19606",
    zip_name: "reading, pa",
  },
  {
    postal_code: "29405",
    zip_name: "north charleston, sc",
  },
  {
    postal_code: "30179",
    zip_name: "temple, ga",
  },
  {
    postal_code: "12110",
    zip_name: "latham, ny",
  },
  {
    postal_code: "24073",
    zip_name: "christiansburg, va",
  },
  {
    postal_code: "37664",
    zip_name: "kingsport, tn",
  },
  {
    postal_code: "30342",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "32825",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "32927",
    zip_name: "cocoa, fl",
  },
  {
    postal_code: "8618",
    zip_name: "trenton, nj",
  },
  {
    postal_code: "84010",
    zip_name: "bountiful, ut",
  },
  {
    postal_code: "43065",
    zip_name: "powell, oh",
  },
  {
    postal_code: "77024",
    zip_name: "houston, tx",
  },
  {
    postal_code: "91350",
    zip_name: "santa clarita, ca",
  },
  {
    postal_code: "33907",
    zip_name: "fort myers, fl",
  },
  {
    postal_code: "33584",
    zip_name: "seffner, fl",
  },
  {
    postal_code: "60630",
    zip_name: "chicago, il",
  },
  {
    postal_code: "33558",
    zip_name: "lutz, fl",
  },
  {
    postal_code: "33947",
    zip_name: "rotonda west, fl",
  },
  {
    postal_code: "33193",
    zip_name: "miami, fl",
  },
  {
    postal_code: "33469",
    zip_name: "jupiter, fl",
  },
  {
    postal_code: "45503",
    zip_name: "springfield, oh",
  },
  {
    postal_code: "10017",
    zip_name: "new york, ny",
  },
  {
    postal_code: "34759",
    zip_name: "kissimmee, fl",
  },
  {
    postal_code: "52601",
    zip_name: "burlington, ia",
  },
  {
    postal_code: "75409",
    zip_name: "anna, tx",
  },
  {
    postal_code: "85140",
    zip_name: "san tan valley, az",
  },
  {
    postal_code: "59105",
    zip_name: "billings, mt",
  },
  {
    postal_code: "75454",
    zip_name: "melissa, tx",
  },
  {
    postal_code: "28104",
    zip_name: "matthews, nc",
  },
  {
    postal_code: "60035",
    zip_name: "highland park, il",
  },
  {
    postal_code: "95624",
    zip_name: "elk grove, ca",
  },
  {
    postal_code: "38016",
    zip_name: "cordova, tn",
  },
  {
    postal_code: "33703",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "55025",
    zip_name: "forest lake, mn",
  },
  {
    postal_code: "37716",
    zip_name: "clinton, tn",
  },
  {
    postal_code: "92284",
    zip_name: "yucca valley, ca",
  },
  {
    postal_code: "30009",
    zip_name: "alpharetta, ga",
  },
  {
    postal_code: "97754",
    zip_name: "prineville, or",
  },
  {
    postal_code: "30517",
    zip_name: "braselton, ga",
  },
  {
    postal_code: "32209",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "54880",
    zip_name: "superior, wi",
  },
  {
    postal_code: "75248",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "46032",
    zip_name: "carmel, in",
  },
  {
    postal_code: "94561",
    zip_name: "oakley, ca",
  },
  {
    postal_code: "61701",
    zip_name: "bloomington, il",
  },
  {
    postal_code: "65049",
    zip_name: "lake ozark, mo",
  },
  {
    postal_code: "34689",
    zip_name: "tarpon springs, fl",
  },
  {
    postal_code: "60091",
    zip_name: "wilmette, il",
  },
  {
    postal_code: "34210",
    zip_name: "bradenton, fl",
  },
  {
    postal_code: "70003",
    zip_name: "metairie, la",
  },
  {
    postal_code: "81147",
    zip_name: "pagosa springs, co",
  },
  {
    postal_code: "86426",
    zip_name: "fort mohave, az",
  },
  {
    postal_code: "30294",
    zip_name: "ellenwood, ga",
  },
  {
    postal_code: "34987",
    zip_name: "port saint lucie, fl",
  },
  {
    postal_code: "77385",
    zip_name: "conroe, tx",
  },
  {
    postal_code: "41051",
    zip_name: "independence, ky",
  },
  {
    postal_code: "33534",
    zip_name: "gibsonton, fl",
  },
  {
    postal_code: "75206",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "2816",
    zip_name: "coventry, ri",
  },
  {
    postal_code: "32456",
    zip_name: "port saint joe, fl",
  },
  {
    postal_code: "28270",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "44125",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "18436",
    zip_name: "lake ariel, pa",
  },
  {
    postal_code: "75089",
    zip_name: "rowlett, tx",
  },
  {
    postal_code: "44118",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "30701",
    zip_name: "calhoun, ga",
  },
  {
    postal_code: "45342",
    zip_name: "miamisburg, oh",
  },
  {
    postal_code: "28546",
    zip_name: "jacksonville, nc",
  },
  {
    postal_code: "74501",
    zip_name: "mcalester, ok",
  },
  {
    postal_code: "64055",
    zip_name: "independence, mo",
  },
  {
    postal_code: "30606",
    zip_name: "athens, ga",
  },
  {
    postal_code: "27540",
    zip_name: "holly springs, nc",
  },
  {
    postal_code: "35226",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "85308",
    zip_name: "glendale, az",
  },
  {
    postal_code: "34241",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "92562",
    zip_name: "murrieta, ca",
  },
  {
    postal_code: "85351",
    zip_name: "sun city, az",
  },
  {
    postal_code: "44130",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "76008",
    zip_name: "aledo, tx",
  },
  {
    postal_code: "76182",
    zip_name: "north richland hills, tx",
  },
  {
    postal_code: "43613",
    zip_name: "toledo, oh",
  },
  {
    postal_code: "37064",
    zip_name: "franklin, tn",
  },
  {
    postal_code: "93012",
    zip_name: "camarillo, ca",
  },
  {
    postal_code: "76248",
    zip_name: "keller, tx",
  },
  {
    postal_code: "75214",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "77388",
    zip_name: "spring, tx",
  },
  {
    postal_code: "30189",
    zip_name: "woodstock, ga",
  },
  {
    postal_code: "37814",
    zip_name: "morristown, tn",
  },
  {
    postal_code: "89027",
    zip_name: "mesquite, nv",
  },
  {
    postal_code: "75077",
    zip_name: "lewisville, tx",
  },
  {
    postal_code: "79416",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "75074",
    zip_name: "plano, tx",
  },
  {
    postal_code: "32714",
    zip_name: "altamonte springs, fl",
  },
  {
    postal_code: "33071",
    zip_name: "coral springs, fl",
  },
  {
    postal_code: "60178",
    zip_name: "sycamore, il",
  },
  {
    postal_code: "92694",
    zip_name: "ladera ranch, ca",
  },
  {
    postal_code: "29710",
    zip_name: "clover, sc",
  },
  {
    postal_code: "92507",
    zip_name: "riverside, ca",
  },
  {
    postal_code: "30655",
    zip_name: "monroe, ga",
  },
  {
    postal_code: "6010",
    zip_name: "bristol, ct",
  },
  {
    postal_code: "76116",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "85719",
    zip_name: "tucson, az",
  },
  {
    postal_code: "38305",
    zip_name: "jackson, tn",
  },
  {
    postal_code: "77004",
    zip_name: "houston, tx",
  },
  {
    postal_code: "29690",
    zip_name: "travelers rest, sc",
  },
  {
    postal_code: "45840",
    zip_name: "findlay, oh",
  },
  {
    postal_code: "33431",
    zip_name: "boca raton, fl",
  },
  {
    postal_code: "99223",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "61201",
    zip_name: "rock island, il",
  },
  {
    postal_code: "29673",
    zip_name: "piedmont, sc",
  },
  {
    postal_code: "60527",
    zip_name: "willowbrook, il",
  },
  {
    postal_code: "85213",
    zip_name: "mesa, az",
  },
  {
    postal_code: "76548",
    zip_name: "harker heights, tx",
  },
  {
    postal_code: "33131",
    zip_name: "miami, fl",
  },
  {
    postal_code: "28079",
    zip_name: "indian trail, nc",
  },
  {
    postal_code: "85331",
    zip_name: "cave creek, az",
  },
  {
    postal_code: "75069",
    zip_name: "mckinney, tx",
  },
  {
    postal_code: "6770",
    zip_name: "naugatuck, ct",
  },
  {
    postal_code: "95350",
    zip_name: "modesto, ca",
  },
  {
    postal_code: "55337",
    zip_name: "burnsville, mn",
  },
  {
    postal_code: "89701",
    zip_name: "carson city, nv",
  },
  {
    postal_code: "78249",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "60440",
    zip_name: "bolingbrook, il",
  },
  {
    postal_code: "33037",
    zip_name: "key largo, fl",
  },
  {
    postal_code: "30005",
    zip_name: "alpharetta, ga",
  },
  {
    postal_code: "89503",
    zip_name: "reno, nv",
  },
  {
    postal_code: "30134",
    zip_name: "douglasville, ga",
  },
  {
    postal_code: "15221",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "29150",
    zip_name: "sumter, sc",
  },
  {
    postal_code: "33020",
    zip_name: "hollywood, fl",
  },
  {
    postal_code: "94509",
    zip_name: "antioch, ca",
  },
  {
    postal_code: "33948",
    zip_name: "port charlotte, fl",
  },
  {
    postal_code: "63136",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "13219",
    zip_name: "syracuse, ny",
  },
  {
    postal_code: "86301",
    zip_name: "prescott, az",
  },
  {
    postal_code: "94595",
    zip_name: "walnut creek, ca",
  },
  {
    postal_code: "29605",
    zip_name: "greenville, sc",
  },
  {
    postal_code: "32819",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "76123",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "33317",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "49508",
    zip_name: "grand rapids, mi",
  },
  {
    postal_code: "75228",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "76537",
    zip_name: "jarrell, tx",
  },
  {
    postal_code: "95670",
    zip_name: "rancho cordova, ca",
  },
  {
    postal_code: "89521",
    zip_name: "reno, nv",
  },
  {
    postal_code: "31419",
    zip_name: "savannah, ga",
  },
  {
    postal_code: "15642",
    zip_name: "irwin, pa",
  },
  {
    postal_code: "28462",
    zip_name: "supply, nc",
  },
  {
    postal_code: "10010",
    zip_name: "new york, ny",
  },
  {
    postal_code: "20772",
    zip_name: "upper marlboro, md",
  },
  {
    postal_code: "6614",
    zip_name: "stratford, ct",
  },
  {
    postal_code: "65065",
    zip_name: "osage beach, mo",
  },
  {
    postal_code: "77566",
    zip_name: "lake jackson, tx",
  },
  {
    postal_code: "41011",
    zip_name: "covington, ky",
  },
  {
    postal_code: "92530",
    zip_name: "lake elsinore, ca",
  },
  {
    postal_code: "85739",
    zip_name: "tucson, az",
  },
  {
    postal_code: "29609",
    zip_name: "greenville, sc",
  },
  {
    postal_code: "39564",
    zip_name: "ocean springs, ms",
  },
  {
    postal_code: "33761",
    zip_name: "clearwater, fl",
  },
  {
    postal_code: "75771",
    zip_name: "lindale, tx",
  },
  {
    postal_code: "33619",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "33147",
    zip_name: "miami, fl",
  },
  {
    postal_code: "75007",
    zip_name: "carrollton, tx",
  },
  {
    postal_code: "33304",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "40391",
    zip_name: "winchester, ky",
  },
  {
    postal_code: "34654",
    zip_name: "new port richey, fl",
  },
  {
    postal_code: "60030",
    zip_name: "grayslake, il",
  },
  {
    postal_code: "30305",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "33030",
    zip_name: "homestead, fl",
  },
  {
    postal_code: "93063",
    zip_name: "simi valley, ca",
  },
  {
    postal_code: "37919",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "60093",
    zip_name: "winnetka, il",
  },
  {
    postal_code: "95206",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "84020",
    zip_name: "draper, ut",
  },
  {
    postal_code: "34292",
    zip_name: "venice, fl",
  },
  {
    postal_code: "33066",
    zip_name: "pompano beach, fl",
  },
  {
    postal_code: "72701",
    zip_name: "fayetteville, ar",
  },
  {
    postal_code: "63125",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "33603",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "60123",
    zip_name: "elgin, il",
  },
  {
    postal_code: "30068",
    zip_name: "marietta, ga",
  },
  {
    postal_code: "70506",
    zip_name: "lafayette, la",
  },
  {
    postal_code: "85374",
    zip_name: "surprise, az",
  },
  {
    postal_code: "30319",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "61821",
    zip_name: "champaign, il",
  },
  {
    postal_code: "92583",
    zip_name: "san jacinto, ca",
  },
  {
    postal_code: "65020",
    zip_name: "camdenton, mo",
  },
  {
    postal_code: "76020",
    zip_name: "azle, tx",
  },
  {
    postal_code: "33408",
    zip_name: "north palm beach, fl",
  },
  {
    postal_code: "11230",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "33615",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "79606",
    zip_name: "abilene, tx",
  },
  {
    postal_code: "30144",
    zip_name: "kennesaw, ga",
  },
  {
    postal_code: "60045",
    zip_name: "lake forest, il",
  },
  {
    postal_code: "62002",
    zip_name: "alton, il",
  },
  {
    postal_code: "60652",
    zip_name: "chicago, il",
  },
  {
    postal_code: "98072",
    zip_name: "woodinville, wa",
  },
  {
    postal_code: "44070",
    zip_name: "north olmsted, oh",
  },
  {
    postal_code: "32207",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "32216",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "49085",
    zip_name: "saint joseph, mi",
  },
  {
    postal_code: "6460",
    zip_name: "milford, ct",
  },
  {
    postal_code: "60510",
    zip_name: "batavia, il",
  },
  {
    postal_code: "70448",
    zip_name: "mandeville, la",
  },
  {
    postal_code: "40165",
    zip_name: "shepherdsville, ky",
  },
  {
    postal_code: "43081",
    zip_name: "westerville, oh",
  },
  {
    postal_code: "95337",
    zip_name: "manteca, ca",
  },
  {
    postal_code: "15146",
    zip_name: "monroeville, pa",
  },
  {
    postal_code: "32217",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "34205",
    zip_name: "bradenton, fl",
  },
  {
    postal_code: "27713",
    zip_name: "durham, nc",
  },
  {
    postal_code: "39503",
    zip_name: "gulfport, ms",
  },
  {
    postal_code: "77089",
    zip_name: "houston, tx",
  },
  {
    postal_code: "32839",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "85251",
    zip_name: "scottsdale, az",
  },
  {
    postal_code: "35242",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "27610",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "77384",
    zip_name: "conroe, tx",
  },
  {
    postal_code: "22304",
    zip_name: "alexandria, va",
  },
  {
    postal_code: "93534",
    zip_name: "lancaster, ca",
  },
  {
    postal_code: "29625",
    zip_name: "anderson, sc",
  },
  {
    postal_code: "44646",
    zip_name: "massillon, oh",
  },
  {
    postal_code: "20901",
    zip_name: "silver spring, md",
  },
  {
    postal_code: "33019",
    zip_name: "hollywood, fl",
  },
  {
    postal_code: "30238",
    zip_name: "jonesboro, ga",
  },
  {
    postal_code: "45458",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "74063",
    zip_name: "sand springs, ok",
  },
  {
    postal_code: "95207",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "55379",
    zip_name: "shakopee, mn",
  },
  {
    postal_code: "37027",
    zip_name: "brentwood, tn",
  },
  {
    postal_code: "78247",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "32720",
    zip_name: "deland, fl",
  },
  {
    postal_code: "33076",
    zip_name: "pompano beach, fl",
  },
  {
    postal_code: "54729",
    zip_name: "chippewa falls, wi",
  },
  {
    postal_code: "55372",
    zip_name: "prior lake, mn",
  },
  {
    postal_code: "32159",
    zip_name: "lady lake, fl",
  },
  {
    postal_code: "10314",
    zip_name: "staten island, ny",
  },
  {
    postal_code: "33055",
    zip_name: "opa locka, fl",
  },
  {
    postal_code: "30281",
    zip_name: "stockbridge, ga",
  },
  {
    postal_code: "32073",
    zip_name: "orange park, fl",
  },
  {
    postal_code: "30067",
    zip_name: "marietta, ga",
  },
  {
    postal_code: "44221",
    zip_name: "cuyahoga falls, oh",
  },
  {
    postal_code: "60073",
    zip_name: "round lake, il",
  },
  {
    postal_code: "55446",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "85032",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "63367",
    zip_name: "lake saint louis, mo",
  },
  {
    postal_code: "46217",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "85225",
    zip_name: "chandler, az",
  },
  {
    postal_code: "30017",
    zip_name: "grayson, ga",
  },
  {
    postal_code: "33461",
    zip_name: "lake worth, fl",
  },
  {
    postal_code: "24502",
    zip_name: "lynchburg, va",
  },
  {
    postal_code: "33931",
    zip_name: "fort myers beach, fl",
  },
  {
    postal_code: "33172",
    zip_name: "miami, fl",
  },
  {
    postal_code: "78251",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "28303",
    zip_name: "fayetteville, nc",
  },
  {
    postal_code: "72113",
    zip_name: "maumelle, ar",
  },
  {
    postal_code: "93561",
    zip_name: "tehachapi, ca",
  },
  {
    postal_code: "91387",
    zip_name: "canyon country, ca",
  },
  {
    postal_code: "44129",
    zip_name: "cleveland, oh",
  },
  {
    postal_code: "16001",
    zip_name: "butler, pa",
  },
  {
    postal_code: "10019",
    zip_name: "new york, ny",
  },
  {
    postal_code: "85262",
    zip_name: "scottsdale, az",
  },
  {
    postal_code: "42104",
    zip_name: "bowling green, ky",
  },
  {
    postal_code: "33710",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "80631",
    zip_name: "greeley, co",
  },
  {
    postal_code: "99216",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "80014",
    zip_name: "aurora, co",
  },
  {
    postal_code: "18337",
    zip_name: "milford, pa",
  },
  {
    postal_code: "32250",
    zip_name: "jacksonville beach, fl",
  },
  {
    postal_code: "30327",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "29154",
    zip_name: "sumter, sc",
  },
  {
    postal_code: "68028",
    zip_name: "gretna, ne",
  },
  {
    postal_code: "32931",
    zip_name: "cocoa beach, fl",
  },
  {
    postal_code: "13760",
    zip_name: "endicott, ny",
  },
  {
    postal_code: "78597",
    zip_name: "south padre island, tx",
  },
  {
    postal_code: "85756",
    zip_name: "tucson, az",
  },
  {
    postal_code: "77083",
    zip_name: "houston, tx",
  },
  {
    postal_code: "77546",
    zip_name: "friendswood, tx",
  },
  {
    postal_code: "33972",
    zip_name: "lehigh acres, fl",
  },
  {
    postal_code: "15301",
    zip_name: "washington, pa",
  },
  {
    postal_code: "60031",
    zip_name: "gurnee, il",
  },
  {
    postal_code: "49855",
    zip_name: "marquette, mi",
  },
  {
    postal_code: "34285",
    zip_name: "venice, fl",
  },
  {
    postal_code: "34108",
    zip_name: "naples, fl",
  },
  {
    postal_code: "42301",
    zip_name: "owensboro, ky",
  },
  {
    postal_code: "84403",
    zip_name: "ogden, ut",
  },
  {
    postal_code: "45005",
    zip_name: "franklin, oh",
  },
  {
    postal_code: "31008",
    zip_name: "byron, ga",
  },
  {
    postal_code: "65738",
    zip_name: "republic, mo",
  },
  {
    postal_code: "60099",
    zip_name: "zion, il",
  },
  {
    postal_code: "10065",
    zip_name: "new york, ny",
  },
  {
    postal_code: "89403",
    zip_name: "dayton, nv",
  },
  {
    postal_code: "33767",
    zip_name: "clearwater beach, fl",
  },
  {
    postal_code: "78640",
    zip_name: "kyle, tx",
  },
  {
    postal_code: "75044",
    zip_name: "garland, tx",
  },
  {
    postal_code: "28704",
    zip_name: "arden, nc",
  },
  {
    postal_code: "12303",
    zip_name: "schenectady, ny",
  },
  {
    postal_code: "33701",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "70433",
    zip_name: "covington, la",
  },
  {
    postal_code: "33971",
    zip_name: "lehigh acres, fl",
  },
  {
    postal_code: "33594",
    zip_name: "valrico, fl",
  },
  {
    postal_code: "92270",
    zip_name: "rancho mirage, ca",
  },
  {
    postal_code: "34639",
    zip_name: "land o lakes, fl",
  },
  {
    postal_code: "75104",
    zip_name: "cedar hill, tx",
  },
  {
    postal_code: "78541",
    zip_name: "edinburg, tx",
  },
  {
    postal_code: "52804",
    zip_name: "davenport, ia",
  },
  {
    postal_code: "32796",
    zip_name: "titusville, fl",
  },
  {
    postal_code: "85143",
    zip_name: "san tan valley, az",
  },
  {
    postal_code: "87505",
    zip_name: "santa fe, nm",
  },
  {
    postal_code: "46060",
    zip_name: "noblesville, in",
  },
  {
    postal_code: "22193",
    zip_name: "woodbridge, va",
  },
  {
    postal_code: "40065",
    zip_name: "shelbyville, ky",
  },
  {
    postal_code: "34714",
    zip_name: "clermont, fl",
  },
  {
    postal_code: "2895",
    zip_name: "woonsocket, ri",
  },
  {
    postal_code: "23238",
    zip_name: "henrico, va",
  },
  {
    postal_code: "12601",
    zip_name: "poughkeepsie, ny",
  },
  {
    postal_code: "12590",
    zip_name: "wappingers falls, ny",
  },
  {
    postal_code: "72756",
    zip_name: "rogers, ar",
  },
  {
    postal_code: "60120",
    zip_name: "elgin, il",
  },
  {
    postal_code: "34982",
    zip_name: "fort pierce, fl",
  },
  {
    postal_code: "45036",
    zip_name: "lebanon, oh",
  },
  {
    postal_code: "12118",
    zip_name: "mechanicville, ny",
  },
  {
    postal_code: "11214",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "85326",
    zip_name: "buckeye, az",
  },
  {
    postal_code: "28327",
    zip_name: "carthage, nc",
  },
  {
    postal_code: "43130",
    zip_name: "lancaster, oh",
  },
  {
    postal_code: "60177",
    zip_name: "south elgin, il",
  },
  {
    postal_code: "97707",
    zip_name: "bend, or",
  },
  {
    postal_code: "33547",
    zip_name: "lithia, fl",
  },
  {
    postal_code: "56303",
    zip_name: "saint cloud, mn",
  },
  {
    postal_code: "60067",
    zip_name: "palatine, il",
  },
  {
    postal_code: "85207",
    zip_name: "mesa, az",
  },
  {
    postal_code: "19143",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "80513",
    zip_name: "berthoud, co",
  },
  {
    postal_code: "78028",
    zip_name: "kerrville, tx",
  },
  {
    postal_code: "37211",
    zip_name: "nashville, tn",
  },
  {
    postal_code: "33126",
    zip_name: "miami, fl",
  },
  {
    postal_code: "32789",
    zip_name: "winter park, fl",
  },
  {
    postal_code: "75040",
    zip_name: "garland, tx",
  },
  {
    postal_code: "75181",
    zip_name: "mesquite, tx",
  },
  {
    postal_code: "55106",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "33056",
    zip_name: "miami gardens, fl",
  },
  {
    postal_code: "30062",
    zip_name: "marietta, ga",
  },
  {
    postal_code: "20148",
    zip_name: "ashburn, va",
  },
  {
    postal_code: "41017",
    zip_name: "ft mitchell, ky",
  },
  {
    postal_code: "30306",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "70435",
    zip_name: "covington, la",
  },
  {
    postal_code: "28211",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "21136",
    zip_name: "reisterstown, md",
  },
  {
    postal_code: "44137",
    zip_name: "maple heights, oh",
  },
  {
    postal_code: "97306",
    zip_name: "salem, or",
  },
  {
    postal_code: "91367",
    zip_name: "woodland hills, ca",
  },
  {
    postal_code: "34652",
    zip_name: "new port richey, fl",
  },
  {
    postal_code: "23220",
    zip_name: "richmond, va",
  },
  {
    postal_code: "10025",
    zip_name: "new york, ny",
  },
  {
    postal_code: "31093",
    zip_name: "warner robins, ga",
  },
  {
    postal_code: "38018",
    zip_name: "cordova, tn",
  },
  {
    postal_code: "33702",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "60050",
    zip_name: "mchenry, il",
  },
  {
    postal_code: "30315",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "85338",
    zip_name: "goodyear, az",
  },
  {
    postal_code: "33917",
    zip_name: "north fort myers, fl",
  },
  {
    postal_code: "85086",
    zip_name: "phoenix, az",
  },
  {
    postal_code: "36117",
    zip_name: "montgomery, al",
  },
  {
    postal_code: "33444",
    zip_name: "delray beach, fl",
  },
  {
    postal_code: "77578",
    zip_name: "manvel, tx",
  },
  {
    postal_code: "55416",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "28401",
    zip_name: "wilmington, nc",
  },
  {
    postal_code: "34786",
    zip_name: "windermere, fl",
  },
  {
    postal_code: "34683",
    zip_name: "palm harbor, fl",
  },
  {
    postal_code: "55417",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "89511",
    zip_name: "reno, nv",
  },
  {
    postal_code: "28469",
    zip_name: "ocean isle beach, nc",
  },
  {
    postal_code: "27604",
    zip_name: "raleigh, nc",
  },
  {
    postal_code: "74105",
    zip_name: "tulsa, ok",
  },
  {
    postal_code: "75032",
    zip_name: "rockwall, tx",
  },
  {
    postal_code: "55068",
    zip_name: "rosemount, mn",
  },
  {
    postal_code: "33912",
    zip_name: "fort myers, fl",
  },
  {
    postal_code: "77539",
    zip_name: "dickinson, tx",
  },
  {
    postal_code: "92234",
    zip_name: "cathedral city, ca",
  },
  {
    postal_code: "46112",
    zip_name: "brownsburg, in",
  },
  {
    postal_code: "33196",
    zip_name: "miami, fl",
  },
  {
    postal_code: "60441",
    zip_name: "lockport, il",
  },
  {
    postal_code: "23005",
    zip_name: "ashland, va",
  },
  {
    postal_code: "77378",
    zip_name: "willis, tx",
  },
  {
    postal_code: "60502",
    zip_name: "aurora, il",
  },
  {
    postal_code: "34109",
    zip_name: "naples, fl",
  },
  {
    postal_code: "33705",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "78252",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "77339",
    zip_name: "kingwood, tx",
  },
  {
    postal_code: "95823",
    zip_name: "sacramento, ca",
  },
  {
    postal_code: "60473",
    zip_name: "south holland, il",
  },
  {
    postal_code: "27893",
    zip_name: "wilson, nc",
  },
  {
    postal_code: "76112",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "85142",
    zip_name: "queen creek, az",
  },
  {
    postal_code: "55443",
    zip_name: "minneapolis, mn",
  },
  {
    postal_code: "33609",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "28037",
    zip_name: "denver, nc",
  },
  {
    postal_code: "33811",
    zip_name: "lakeland, fl",
  },
  {
    postal_code: "80233",
    zip_name: "denver, co",
  },
  {
    postal_code: "94513",
    zip_name: "brentwood, ca",
  },
  {
    postal_code: "34761",
    zip_name: "ocoee, fl",
  },
  {
    postal_code: "97401",
    zip_name: "eugene, or",
  },
  {
    postal_code: "63701",
    zip_name: "cape girardeau, mo",
  },
  {
    postal_code: "49684",
    zip_name: "traverse city, mi",
  },
  {
    postal_code: "6033",
    zip_name: "glastonbury, ct",
  },
  {
    postal_code: "92336",
    zip_name: "fontana, ca",
  },
  {
    postal_code: "28304",
    zip_name: "fayetteville, nc",
  },
  {
    postal_code: "77515",
    zip_name: "angleton, tx",
  },
  {
    postal_code: "80601",
    zip_name: "brighton, co",
  },
  {
    postal_code: "32222",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "33035",
    zip_name: "homestead, fl",
  },
  {
    postal_code: "32221",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "28031",
    zip_name: "cornelius, nc",
  },
  {
    postal_code: "1201",
    zip_name: "pittsfield, ma",
  },
  {
    postal_code: "28209",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "32257",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "45238",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "33596",
    zip_name: "valrico, fl",
  },
  {
    postal_code: "33426",
    zip_name: "boynton beach, fl",
  },
  {
    postal_code: "30344",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "45040",
    zip_name: "mason, oh",
  },
  {
    postal_code: "33570",
    zip_name: "ruskin, fl",
  },
  {
    postal_code: "35124",
    zip_name: "pelham, al",
  },
  {
    postal_code: "77554",
    zip_name: "galveston, tx",
  },
  {
    postal_code: "43026",
    zip_name: "hilliard, oh",
  },
  {
    postal_code: "34772",
    zip_name: "saint cloud, fl",
  },
  {
    postal_code: "63366",
    zip_name: "o fallon, mo",
  },
  {
    postal_code: "76210",
    zip_name: "denton, tx",
  },
  {
    postal_code: "67217",
    zip_name: "wichita, ks",
  },
  {
    postal_code: "86303",
    zip_name: "prescott, az",
  },
  {
    postal_code: "33823",
    zip_name: "auburndale, fl",
  },
  {
    postal_code: "33813",
    zip_name: "lakeland, fl",
  },
  {
    postal_code: "33472",
    zip_name: "boynton beach, fl",
  },
  {
    postal_code: "34990",
    zip_name: "palm city, fl",
  },
  {
    postal_code: "95630",
    zip_name: "folsom, ca",
  },
  {
    postal_code: "40004",
    zip_name: "bardstown, ky",
  },
  {
    postal_code: "29732",
    zip_name: "rock hill, sc",
  },
  {
    postal_code: "30548",
    zip_name: "hoschton, ga",
  },
  {
    postal_code: "63368",
    zip_name: "o fallon, mo",
  },
  {
    postal_code: "60625",
    zip_name: "chicago, il",
  },
  {
    postal_code: "60302",
    zip_name: "oak park, il",
  },
  {
    postal_code: "87105",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "45150",
    zip_name: "milford, oh",
  },
  {
    postal_code: "15317",
    zip_name: "canonsburg, pa",
  },
  {
    postal_code: "39402",
    zip_name: "hattiesburg, ms",
  },
  {
    postal_code: "85138",
    zip_name: "maricopa, az",
  },
  {
    postal_code: "33411",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "80831",
    zip_name: "peyton, co",
  },
  {
    postal_code: "28539",
    zip_name: "hubert, nc",
  },
  {
    postal_code: "84062",
    zip_name: "pleasant grove, ut",
  },
  {
    postal_code: "79602",
    zip_name: "abilene, tx",
  },
  {
    postal_code: "33309",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "58701",
    zip_name: "minot, nd",
  },
  {
    postal_code: "60089",
    zip_name: "buffalo grove, il",
  },
  {
    postal_code: "32713",
    zip_name: "debary, fl",
  },
  {
    postal_code: "46203",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "63031",
    zip_name: "florissant, mo",
  },
  {
    postal_code: "12309",
    zip_name: "schenectady, ny",
  },
  {
    postal_code: "60047",
    zip_name: "lake zurich, il",
  },
  {
    postal_code: "80027",
    zip_name: "louisville, co",
  },
  {
    postal_code: "28374",
    zip_name: "pinehurst, nc",
  },
  {
    postal_code: "91331",
    zip_name: "pacoima, ca",
  },
  {
    postal_code: "32162",
    zip_name: "the villages, fl",
  },
  {
    postal_code: "29466",
    zip_name: "mount pleasant, sc",
  },
  {
    postal_code: "82009",
    zip_name: "cheyenne, wy",
  },
  {
    postal_code: "52722",
    zip_name: "bettendorf, ia",
  },
  {
    postal_code: "30274",
    zip_name: "riverdale, ga",
  },
  {
    postal_code: "60126",
    zip_name: "elmhurst, il",
  },
  {
    postal_code: "55129",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "28604",
    zip_name: "banner elk, nc",
  },
  {
    postal_code: "78154",
    zip_name: "schertz, tx",
  },
  {
    postal_code: "34684",
    zip_name: "palm harbor, fl",
  },
  {
    postal_code: "76108",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "34110",
    zip_name: "naples, fl",
  },
  {
    postal_code: "33545",
    zip_name: "wesley chapel, fl",
  },
  {
    postal_code: "33607",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "74006",
    zip_name: "bartlesville, ok",
  },
  {
    postal_code: "34234",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "33880",
    zip_name: "winter haven, fl",
  },
  {
    postal_code: "50315",
    zip_name: "des moines, ia",
  },
  {
    postal_code: "60638",
    zip_name: "chicago, il",
  },
  {
    postal_code: "2806",
    zip_name: "barrington, ri",
  },
  {
    postal_code: "33012",
    zip_name: "hialeah, fl",
  },
  {
    postal_code: "29601",
    zip_name: "greenville, sc",
  },
  {
    postal_code: "84663",
    zip_name: "springville, ut",
  },
  {
    postal_code: "46237",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "30324",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "98033",
    zip_name: "kirkland, wa",
  },
  {
    postal_code: "2886",
    zip_name: "warwick, ri",
  },
  {
    postal_code: "34275",
    zip_name: "nokomis, fl",
  },
  {
    postal_code: "11238",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "33132",
    zip_name: "miami, fl",
  },
  {
    postal_code: "23111",
    zip_name: "mechanicsville, va",
  },
  {
    postal_code: "10013",
    zip_name: "new york, ny",
  },
  {
    postal_code: "65804",
    zip_name: "springfield, mo",
  },
  {
    postal_code: "33161",
    zip_name: "miami, fl",
  },
  {
    postal_code: "34691",
    zip_name: "holiday, fl",
  },
  {
    postal_code: "40511",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "78723",
    zip_name: "austin, tx",
  },
  {
    postal_code: "44107",
    zip_name: "lakewood, oh",
  },
  {
    postal_code: "29687",
    zip_name: "taylors, sc",
  },
  {
    postal_code: "33974",
    zip_name: "lehigh acres, fl",
  },
  {
    postal_code: "63028",
    zip_name: "festus, mo",
  },
  {
    postal_code: "33511",
    zip_name: "brandon, fl",
  },
  {
    postal_code: "43221",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "29707",
    zip_name: "fort mill, sc",
  },
  {
    postal_code: "28110",
    zip_name: "monroe, nc",
  },
  {
    postal_code: "40291",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "60653",
    zip_name: "chicago, il",
  },
  {
    postal_code: "29527",
    zip_name: "conway, sc",
  },
  {
    postal_code: "60148",
    zip_name: "lombard, il",
  },
  {
    postal_code: "49024",
    zip_name: "portage, mi",
  },
  {
    postal_code: "33162",
    zip_name: "miami, fl",
  },
  {
    postal_code: "50310",
    zip_name: "des moines, ia",
  },
  {
    postal_code: "30214",
    zip_name: "fayetteville, ga",
  },
  {
    postal_code: "92240",
    zip_name: "desert hot springs, ca",
  },
  {
    postal_code: "33137",
    zip_name: "miami, fl",
  },
  {
    postal_code: "97301",
    zip_name: "salem, or",
  },
  {
    postal_code: "12019",
    zip_name: "ballston lake, ny",
  },
  {
    postal_code: "92264",
    zip_name: "palm springs, ca",
  },
  {
    postal_code: "64118",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "34491",
    zip_name: "summerfield, fl",
  },
  {
    postal_code: "33981",
    zip_name: "port charlotte, fl",
  },
  {
    postal_code: "63017",
    zip_name: "chesterfield, mo",
  },
  {
    postal_code: "22902",
    zip_name: "charlottesville, va",
  },
  {
    postal_code: "77535",
    zip_name: "dayton, tx",
  },
  {
    postal_code: "33134",
    zip_name: "miami, fl",
  },
  {
    postal_code: "40220",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "60610",
    zip_name: "chicago, il",
  },
  {
    postal_code: "33611",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "33604",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "75070",
    zip_name: "mckinney, tx",
  },
  {
    postal_code: "33414",
    zip_name: "wellington, fl",
  },
  {
    postal_code: "19145",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "11209",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "87111",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "15235",
    zip_name: "pittsburgh, pa",
  },
  {
    postal_code: "76052",
    zip_name: "haslet, tx",
  },
  {
    postal_code: "60660",
    zip_name: "chicago, il",
  },
  {
    postal_code: "59715",
    zip_name: "bozeman, mt",
  },
  {
    postal_code: "95843",
    zip_name: "antelope, ca",
  },
  {
    postal_code: "92203",
    zip_name: "indio, ca",
  },
  {
    postal_code: "28390",
    zip_name: "spring lake, nc",
  },
  {
    postal_code: "59102",
    zip_name: "billings, mt",
  },
  {
    postal_code: "76036",
    zip_name: "crowley, tx",
  },
  {
    postal_code: "33837",
    zip_name: "davenport, fl",
  },
  {
    postal_code: "61265",
    zip_name: "moline, il",
  },
  {
    postal_code: "78748",
    zip_name: "austin, tx",
  },
  {
    postal_code: "53590",
    zip_name: "sun prairie, wi",
  },
  {
    postal_code: "46226",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "70065",
    zip_name: "kenner, la",
  },
  {
    postal_code: "10002",
    zip_name: "new york, ny",
  },
  {
    postal_code: "60504",
    zip_name: "aurora, il",
  },
  {
    postal_code: "34608",
    zip_name: "spring hill, fl",
  },
  {
    postal_code: "60062",
    zip_name: "northbrook, il",
  },
  {
    postal_code: "94565",
    zip_name: "pittsburg, ca",
  },
  {
    postal_code: "85395",
    zip_name: "goodyear, az",
  },
  {
    postal_code: "30044",
    zip_name: "lawrenceville, ga",
  },
  {
    postal_code: "77007",
    zip_name: "houston, tx",
  },
  {
    postal_code: "77077",
    zip_name: "houston, tx",
  },
  {
    postal_code: "60462",
    zip_name: "orland park, il",
  },
  {
    postal_code: "33983",
    zip_name: "punta gorda, fl",
  },
  {
    postal_code: "34957",
    zip_name: "jensen beach, fl",
  },
  {
    postal_code: "33029",
    zip_name: "hollywood, fl",
  },
  {
    postal_code: "27527",
    zip_name: "clayton, nc",
  },
  {
    postal_code: "30030",
    zip_name: "decatur, ga",
  },
  {
    postal_code: "31405",
    zip_name: "savannah, ga",
  },
  {
    postal_code: "27284",
    zip_name: "kernersville, nc",
  },
  {
    postal_code: "19146",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "87031",
    zip_name: "los lunas, nm",
  },
  {
    postal_code: "34120",
    zip_name: "naples, fl",
  },
  {
    postal_code: "40214",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "78613",
    zip_name: "cedar park, tx",
  },
  {
    postal_code: "41042",
    zip_name: "florence, ky",
  },
  {
    postal_code: "33610",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "10014",
    zip_name: "new york, ny",
  },
  {
    postal_code: "46307",
    zip_name: "crown point, in",
  },
  {
    postal_code: "37917",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "80026",
    zip_name: "lafayette, co",
  },
  {
    postal_code: "64152",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "80012",
    zip_name: "aurora, co",
  },
  {
    postal_code: "32082",
    zip_name: "ponte vedra beach, fl",
  },
  {
    postal_code: "22903",
    zip_name: "charlottesville, va",
  },
  {
    postal_code: "34208",
    zip_name: "bradenton, fl",
  },
  {
    postal_code: "70130",
    zip_name: "new orleans, la",
  },
  {
    postal_code: "40219",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "92395",
    zip_name: "victorville, ca",
  },
  {
    postal_code: "97302",
    zip_name: "salem, or",
  },
  {
    postal_code: "79119",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "97206",
    zip_name: "portland, or",
  },
  {
    postal_code: "63114",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "34465",
    zip_name: "beverly hills, fl",
  },
  {
    postal_code: "33916",
    zip_name: "fort myers, fl",
  },
  {
    postal_code: "55304",
    zip_name: "andover, mn",
  },
  {
    postal_code: "63116",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "63122",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "75703",
    zip_name: "tyler, tx",
  },
  {
    postal_code: "63123",
    zip_name: "saint louis, mo",
  },
  {
    postal_code: "33156",
    zip_name: "miami, fl",
  },
  {
    postal_code: "60641",
    zip_name: "chicago, il",
  },
  {
    postal_code: "37830",
    zip_name: "oak ridge, tn",
  },
  {
    postal_code: "87507",
    zip_name: "santa fe, nm",
  },
  {
    postal_code: "60056",
    zip_name: "mount prospect, il",
  },
  {
    postal_code: "96707",
    zip_name: "kapolei, hi",
  },
  {
    postal_code: "33976",
    zip_name: "lehigh acres, fl",
  },
  {
    postal_code: "28025",
    zip_name: "concord, nc",
  },
  {
    postal_code: "34288",
    zip_name: "north port, fl",
  },
  {
    postal_code: "89436",
    zip_name: "sparks, nv",
  },
  {
    postal_code: "85742",
    zip_name: "tucson, az",
  },
  {
    postal_code: "32963",
    zip_name: "vero beach, fl",
  },
  {
    postal_code: "33186",
    zip_name: "miami, fl",
  },
  {
    postal_code: "33441",
    zip_name: "deerfield beach, fl",
  },
  {
    postal_code: "29585",
    zip_name: "pawleys island, sc",
  },
  {
    postal_code: "48103",
    zip_name: "ann arbor, mi",
  },
  {
    postal_code: "24551",
    zip_name: "forest, va",
  },
  {
    postal_code: "40216",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "60438",
    zip_name: "lansing, il",
  },
  {
    postal_code: "32408",
    zip_name: "panama city, fl",
  },
  {
    postal_code: "60010",
    zip_name: "barrington, il",
  },
  {
    postal_code: "40059",
    zip_name: "prospect, ky",
  },
  {
    postal_code: "74011",
    zip_name: "broken arrow, ok",
  },
  {
    postal_code: "44053",
    zip_name: "lorain, oh",
  },
  {
    postal_code: "32967",
    zip_name: "vero beach, fl",
  },
  {
    postal_code: "60640",
    zip_name: "chicago, il",
  },
  {
    postal_code: "85750",
    zip_name: "tucson, az",
  },
  {
    postal_code: "93065",
    zip_name: "simi valley, ca",
  },
  {
    postal_code: "60477",
    zip_name: "tinley park, il",
  },
  {
    postal_code: "30120",
    zip_name: "cartersville, ga",
  },
  {
    postal_code: "34769",
    zip_name: "saint cloud, fl",
  },
  {
    postal_code: "43123",
    zip_name: "grove city, oh",
  },
  {
    postal_code: "86305",
    zip_name: "prescott, az",
  },
  {
    postal_code: "83201",
    zip_name: "pocatello, id",
  },
  {
    postal_code: "95628",
    zip_name: "fair oaks, ca",
  },
  {
    postal_code: "92392",
    zip_name: "victorville, ca",
  },
  {
    postal_code: "33462",
    zip_name: "lake worth, fl",
  },
  {
    postal_code: "44256",
    zip_name: "medina, oh",
  },
  {
    postal_code: "50009",
    zip_name: "altoona, ia",
  },
  {
    postal_code: "30549",
    zip_name: "jefferson, ga",
  },
  {
    postal_code: "34104",
    zip_name: "naples, fl",
  },
  {
    postal_code: "58601",
    zip_name: "dickinson, nd",
  },
  {
    postal_code: "14226",
    zip_name: "buffalo, ny",
  },
  {
    postal_code: "46201",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "63090",
    zip_name: "washington, mo",
  },
  {
    postal_code: "77479",
    zip_name: "sugar land, tx",
  },
  {
    postal_code: "75115",
    zip_name: "desoto, tx",
  },
  {
    postal_code: "77450",
    zip_name: "katy, tx",
  },
  {
    postal_code: "34105",
    zip_name: "naples, fl",
  },
  {
    postal_code: "76137",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "30014",
    zip_name: "covington, ga",
  },
  {
    postal_code: "27332",
    zip_name: "sanford, nc",
  },
  {
    postal_code: "19460",
    zip_name: "phoenixville, pa",
  },
  {
    postal_code: "34736",
    zip_name: "groveland, fl",
  },
  {
    postal_code: "93722",
    zip_name: "fresno, ca",
  },
  {
    postal_code: "80104",
    zip_name: "castle rock, co",
  },
  {
    postal_code: "45044",
    zip_name: "middletown, oh",
  },
  {
    postal_code: "46062",
    zip_name: "noblesville, in",
  },
  {
    postal_code: "77386",
    zip_name: "spring, tx",
  },
  {
    postal_code: "78233",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "30039",
    zip_name: "snellville, ga",
  },
  {
    postal_code: "28560",
    zip_name: "new bern, nc",
  },
  {
    postal_code: "33434",
    zip_name: "boca raton, fl",
  },
  {
    postal_code: "28739",
    zip_name: "hendersonville, nc",
  },
  {
    postal_code: "45244",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "73034",
    zip_name: "edmond, ok",
  },
  {
    postal_code: "29715",
    zip_name: "fort mill, sc",
  },
  {
    postal_code: "30084",
    zip_name: "tucker, ga",
  },
  {
    postal_code: "32065",
    zip_name: "orange park, fl",
  },
  {
    postal_code: "22980",
    zip_name: "waynesboro, va",
  },
  {
    postal_code: "30097",
    zip_name: "duluth, ga",
  },
  {
    postal_code: "30082",
    zip_name: "smyrna, ga",
  },
  {
    postal_code: "77355",
    zip_name: "magnolia, tx",
  },
  {
    postal_code: "92126",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "63033",
    zip_name: "florissant, mo",
  },
  {
    postal_code: "39047",
    zip_name: "brandon, ms",
  },
  {
    postal_code: "33143",
    zip_name: "miami, fl",
  },
  {
    postal_code: "27520",
    zip_name: "clayton, nc",
  },
  {
    postal_code: "77523",
    zip_name: "baytown, tx",
  },
  {
    postal_code: "28803",
    zip_name: "asheville, nc",
  },
  {
    postal_code: "43551",
    zip_name: "perrysburg, oh",
  },
  {
    postal_code: "75043",
    zip_name: "garland, tx",
  },
  {
    postal_code: "32780",
    zip_name: "titusville, fl",
  },
  {
    postal_code: "30126",
    zip_name: "mableton, ga",
  },
  {
    postal_code: "75033",
    zip_name: "frisco, tx",
  },
  {
    postal_code: "16066",
    zip_name: "cranberry township, pa",
  },
  {
    postal_code: "32277",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "92346",
    zip_name: "highland, ca",
  },
  {
    postal_code: "21811",
    zip_name: "berlin, md",
  },
  {
    postal_code: "70068",
    zip_name: "la place, la",
  },
  {
    postal_code: "55016",
    zip_name: "cottage grove, mn",
  },
  {
    postal_code: "34287",
    zip_name: "north port, fl",
  },
  {
    postal_code: "76513",
    zip_name: "belton, tx",
  },
  {
    postal_code: "55082",
    zip_name: "stillwater, mn",
  },
  {
    postal_code: "33463",
    zip_name: "lake worth, fl",
  },
  {
    postal_code: "60634",
    zip_name: "chicago, il",
  },
  {
    postal_code: "76262",
    zip_name: "roanoke, tx",
  },
  {
    postal_code: "77833",
    zip_name: "brenham, tx",
  },
  {
    postal_code: "92563",
    zip_name: "murrieta, ca",
  },
  {
    postal_code: "93550",
    zip_name: "palmdale, ca",
  },
  {
    postal_code: "87120",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "33139",
    zip_name: "miami beach, fl",
  },
  {
    postal_code: "30016",
    zip_name: "covington, ga",
  },
  {
    postal_code: "84651",
    zip_name: "payson, ut",
  },
  {
    postal_code: "12020",
    zip_name: "ballston spa, ny",
  },
  {
    postal_code: "32905",
    zip_name: "palm bay, fl",
  },
  {
    postal_code: "12180",
    zip_name: "troy, ny",
  },
  {
    postal_code: "17055",
    zip_name: "mechanicsburg, pa",
  },
  {
    postal_code: "85268",
    zip_name: "fountain hills, az",
  },
  {
    postal_code: "28574",
    zip_name: "richlands, nc",
  },
  {
    postal_code: "60140",
    zip_name: "hampshire, il",
  },
  {
    postal_code: "70072",
    zip_name: "marrero, la",
  },
  {
    postal_code: "60585",
    zip_name: "plainfield, il",
  },
  {
    postal_code: "29365",
    zip_name: "lyman, sc",
  },
  {
    postal_code: "32792",
    zip_name: "winter park, fl",
  },
  {
    postal_code: "72023",
    zip_name: "cabot, ar",
  },
  {
    postal_code: "70508",
    zip_name: "lafayette, la",
  },
  {
    postal_code: "35215",
    zip_name: "birmingham, al",
  },
  {
    postal_code: "60046",
    zip_name: "lake villa, il",
  },
  {
    postal_code: "60618",
    zip_name: "chicago, il",
  },
  {
    postal_code: "98133",
    zip_name: "seattle, wa",
  },
  {
    postal_code: "75087",
    zip_name: "rockwall, tx",
  },
  {
    postal_code: "45103",
    zip_name: "batavia, oh",
  },
  {
    postal_code: "77080",
    zip_name: "houston, tx",
  },
  {
    postal_code: "85254",
    zip_name: "scottsdale, az",
  },
  {
    postal_code: "80602",
    zip_name: "brighton, co",
  },
  {
    postal_code: "33629",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "37174",
    zip_name: "spring hill, tn",
  },
  {
    postal_code: "34606",
    zip_name: "spring hill, fl",
  },
  {
    postal_code: "10028",
    zip_name: "new york, ny",
  },
  {
    postal_code: "32778",
    zip_name: "tavares, fl",
  },
  {
    postal_code: "75241",
    zip_name: "dallas, tx",
  },
  {
    postal_code: "27703",
    zip_name: "durham, nc",
  },
  {
    postal_code: "30064",
    zip_name: "marietta, ga",
  },
  {
    postal_code: "44039",
    zip_name: "north ridgeville, oh",
  },
  {
    postal_code: "28445",
    zip_name: "holly ridge, nc",
  },
  {
    postal_code: "55303",
    zip_name: "anoka, mn",
  },
  {
    postal_code: "78258",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "60004",
    zip_name: "arlington heights, il",
  },
  {
    postal_code: "45385",
    zip_name: "xenia, oh",
  },
  {
    postal_code: "60560",
    zip_name: "yorkville, il",
  },
  {
    postal_code: "77095",
    zip_name: "houston, tx",
  },
  {
    postal_code: "60419",
    zip_name: "dolton, il",
  },
  {
    postal_code: "75154",
    zip_name: "red oak, tx",
  },
  {
    postal_code: "29349",
    zip_name: "inman, sc",
  },
  {
    postal_code: "76177",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "27103",
    zip_name: "winston salem, nc",
  },
  {
    postal_code: "43230",
    zip_name: "columbus, oh",
  },
  {
    postal_code: "28806",
    zip_name: "asheville, nc",
  },
  {
    postal_code: "77511",
    zip_name: "alvin, tx",
  },
  {
    postal_code: "77354",
    zip_name: "magnolia, tx",
  },
  {
    postal_code: "75054",
    zip_name: "grand prairie, tx",
  },
  {
    postal_code: "32935",
    zip_name: "melbourne, fl",
  },
  {
    postal_code: "60620",
    zip_name: "chicago, il",
  },
  {
    postal_code: "33460",
    zip_name: "lake worth beach, fl",
  },
  {
    postal_code: "33897",
    zip_name: "davenport, fl",
  },
  {
    postal_code: "72704",
    zip_name: "fayetteville, ar",
  },
  {
    postal_code: "23188",
    zip_name: "williamsburg, va",
  },
  {
    postal_code: "11101",
    zip_name: "long island city, ny",
  },
  {
    postal_code: "34209",
    zip_name: "bradenton, fl",
  },
  {
    postal_code: "68116",
    zip_name: "omaha, ne",
  },
  {
    postal_code: "28115",
    zip_name: "mooresville, nc",
  },
  {
    postal_code: "55901",
    zip_name: "rochester, mn",
  },
  {
    postal_code: "29680",
    zip_name: "simpsonville, sc",
  },
  {
    postal_code: "30022",
    zip_name: "alpharetta, ga",
  },
  {
    postal_code: "77365",
    zip_name: "porter, tx",
  },
  {
    postal_code: "78641",
    zip_name: "leander, tx",
  },
  {
    postal_code: "30152",
    zip_name: "kennesaw, ga",
  },
  {
    postal_code: "85614",
    zip_name: "green valley, az",
  },
  {
    postal_code: "89506",
    zip_name: "reno, nv",
  },
  {
    postal_code: "32827",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "29316",
    zip_name: "boiling springs, sc",
  },
  {
    postal_code: "46143",
    zip_name: "greenwood, in",
  },
  {
    postal_code: "76227",
    zip_name: "aubrey, tx",
  },
  {
    postal_code: "95621",
    zip_name: "citrus heights, ca",
  },
  {
    postal_code: "31322",
    zip_name: "pooler, ga",
  },
  {
    postal_code: "33069",
    zip_name: "pompano beach, fl",
  },
  {
    postal_code: "32223",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "15108",
    zip_name: "coraopolis, pa",
  },
  {
    postal_code: "29720",
    zip_name: "lancaster, sc",
  },
  {
    postal_code: "30083",
    zip_name: "stone mountain, ga",
  },
  {
    postal_code: "33324",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "77044",
    zip_name: "houston, tx",
  },
  {
    postal_code: "78006",
    zip_name: "boerne, tx",
  },
  {
    postal_code: "33169",
    zip_name: "miami, fl",
  },
  {
    postal_code: "85249",
    zip_name: "chandler, az",
  },
  {
    postal_code: "63301",
    zip_name: "saint charles, mo",
  },
  {
    postal_code: "65721",
    zip_name: "ozark, mo",
  },
  {
    postal_code: "93274",
    zip_name: "tulare, ca",
  },
  {
    postal_code: "29607",
    zip_name: "greenville, sc",
  },
  {
    postal_code: "38401",
    zip_name: "columbia, tn",
  },
  {
    postal_code: "33950",
    zip_name: "punta gorda, fl",
  },
  {
    postal_code: "33140",
    zip_name: "miami beach, fl",
  },
  {
    postal_code: "32708",
    zip_name: "winter springs, fl",
  },
  {
    postal_code: "85641",
    zip_name: "vail, az",
  },
  {
    postal_code: "78414",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "32835",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "88310",
    zip_name: "alamogordo, nm",
  },
  {
    postal_code: "33908",
    zip_name: "fort myers, fl",
  },
  {
    postal_code: "33407",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "33707",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "33624",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "60193",
    zip_name: "schaumburg, il",
  },
  {
    postal_code: "28027",
    zip_name: "concord, nc",
  },
  {
    postal_code: "32205",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "30096",
    zip_name: "duluth, ga",
  },
  {
    postal_code: "32208",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "33905",
    zip_name: "fort myers, fl",
  },
  {
    postal_code: "92592",
    zip_name: "temecula, ca",
  },
  {
    postal_code: "41091",
    zip_name: "union, ky",
  },
  {
    postal_code: "50317",
    zip_name: "des moines, ia",
  },
  {
    postal_code: "60543",
    zip_name: "oswego, il",
  },
  {
    postal_code: "33138",
    zip_name: "miami, fl",
  },
  {
    postal_code: "33614",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "60586",
    zip_name: "plainfield, il",
  },
  {
    postal_code: "32256",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "43055",
    zip_name: "newark, oh",
  },
  {
    postal_code: "44203",
    zip_name: "barberton, oh",
  },
  {
    postal_code: "34243",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "32773",
    zip_name: "sanford, fl",
  },
  {
    postal_code: "32837",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "85755",
    zip_name: "tucson, az",
  },
  {
    postal_code: "33179",
    zip_name: "miami, fl",
  },
  {
    postal_code: "32962",
    zip_name: "vero beach, fl",
  },
  {
    postal_code: "60611",
    zip_name: "chicago, il",
  },
  {
    postal_code: "75036",
    zip_name: "frisco, tx",
  },
  {
    postal_code: "34238",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "40272",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "28409",
    zip_name: "wilmington, nc",
  },
  {
    postal_code: "72719",
    zip_name: "centerton, ar",
  },
  {
    postal_code: "78418",
    zip_name: "corpus christi, tx",
  },
  {
    postal_code: "40509",
    zip_name: "lexington, ky",
  },
  {
    postal_code: "30339",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "30058",
    zip_name: "lithonia, ga",
  },
  {
    postal_code: "34286",
    zip_name: "north port, fl",
  },
  {
    postal_code: "34203",
    zip_name: "bradenton, fl",
  },
  {
    postal_code: "45013",
    zip_name: "hamilton, oh",
  },
  {
    postal_code: "44095",
    zip_name: "eastlake, oh",
  },
  {
    postal_code: "86314",
    zip_name: "prescott valley, az",
  },
  {
    postal_code: "33470",
    zip_name: "loxahatchee, fl",
  },
  {
    postal_code: "92307",
    zip_name: "apple valley, ca",
  },
  {
    postal_code: "92637",
    zip_name: "laguna woods, ca",
  },
  {
    postal_code: "30350",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "32703",
    zip_name: "apopka, fl",
  },
  {
    postal_code: "80634",
    zip_name: "greeley, co",
  },
  {
    postal_code: "40601",
    zip_name: "frankfort, ky",
  },
  {
    postal_code: "30075",
    zip_name: "roswell, ga",
  },
  {
    postal_code: "45424",
    zip_name: "dayton, oh",
  },
  {
    postal_code: "84401",
    zip_name: "ogden, ut",
  },
  {
    postal_code: "37921",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "37934",
    zip_name: "farragut, tn",
  },
  {
    postal_code: "33936",
    zip_name: "lehigh acres, fl",
  },
  {
    postal_code: "32712",
    zip_name: "apopka, fl",
  },
  {
    postal_code: "96789",
    zip_name: "mililani, hi",
  },
  {
    postal_code: "78261",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "77084",
    zip_name: "houston, tx",
  },
  {
    postal_code: "91342",
    zip_name: "sylmar, ca",
  },
  {
    postal_code: "19713",
    zip_name: "newark, de",
  },
  {
    postal_code: "68516",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "30034",
    zip_name: "decatur, ga",
  },
  {
    postal_code: "32904",
    zip_name: "melbourne, fl",
  },
  {
    postal_code: "19148",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "23185",
    zip_name: "williamsburg, va",
  },
  {
    postal_code: "33569",
    zip_name: "riverview, fl",
  },
  {
    postal_code: "28311",
    zip_name: "fayetteville, nc",
  },
  {
    postal_code: "11215",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "33133",
    zip_name: "miami, fl",
  },
  {
    postal_code: "30518",
    zip_name: "buford, ga",
  },
  {
    postal_code: "92262",
    zip_name: "palm springs, ca",
  },
  {
    postal_code: "46123",
    zip_name: "avon, in",
  },
  {
    postal_code: "79423",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "82001",
    zip_name: "cheyenne, wy",
  },
  {
    postal_code: "30253",
    zip_name: "mcdonough, ga",
  },
  {
    postal_code: "33432",
    zip_name: "boca raton, fl",
  },
  {
    postal_code: "77441",
    zip_name: "fulshear, tx",
  },
  {
    postal_code: "60647",
    zip_name: "chicago, il",
  },
  {
    postal_code: "32746",
    zip_name: "lake mary, fl",
  },
  {
    postal_code: "85629",
    zip_name: "sahuarita, az",
  },
  {
    postal_code: "34134",
    zip_name: "bonita springs, fl",
  },
  {
    postal_code: "33068",
    zip_name: "pompano beach, fl",
  },
  {
    postal_code: "34211",
    zip_name: "bradenton, fl",
  },
  {
    postal_code: "34748",
    zip_name: "leesburg, fl",
  },
  {
    postal_code: "32080",
    zip_name: "saint augustine, fl",
  },
  {
    postal_code: "77356",
    zip_name: "montgomery, tx",
  },
  {
    postal_code: "34102",
    zip_name: "naples, fl",
  },
  {
    postal_code: "12306",
    zip_name: "schenectady, ny",
  },
  {
    postal_code: "30078",
    zip_name: "snellville, ga",
  },
  {
    postal_code: "30180",
    zip_name: "villa rica, ga",
  },
  {
    postal_code: "85747",
    zip_name: "tucson, az",
  },
  {
    postal_code: "74008",
    zip_name: "bixby, ok",
  },
  {
    postal_code: "30328",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "92277",
    zip_name: "twentynine palms, ca",
  },
  {
    postal_code: "33410",
    zip_name: "palm beach gardens, fl",
  },
  {
    postal_code: "32086",
    zip_name: "saint augustine, fl",
  },
  {
    postal_code: "43147",
    zip_name: "pickerington, oh",
  },
  {
    postal_code: "44060",
    zip_name: "mentor, oh",
  },
  {
    postal_code: "27834",
    zip_name: "greenville, nc",
  },
  {
    postal_code: "33308",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "32738",
    zip_name: "deltona, fl",
  },
  {
    postal_code: "37918",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "30094",
    zip_name: "conyers, ga",
  },
  {
    postal_code: "28173",
    zip_name: "waxhaw, nc",
  },
  {
    postal_code: "30534",
    zip_name: "dawsonville, ga",
  },
  {
    postal_code: "22911",
    zip_name: "charlottesville, va",
  },
  {
    postal_code: "33708",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "33326",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "28306",
    zip_name: "fayetteville, nc",
  },
  {
    postal_code: "32244",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "32765",
    zip_name: "oviedo, fl",
  },
  {
    postal_code: "40299",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "10463",
    zip_name: "bronx, ny",
  },
  {
    postal_code: "32757",
    zip_name: "mount dora, fl",
  },
  {
    postal_code: "73064",
    zip_name: "mustang, ok",
  },
  {
    postal_code: "63021",
    zip_name: "ballwin, mo",
  },
  {
    postal_code: "80015",
    zip_name: "aurora, co",
  },
  {
    postal_code: "60101",
    zip_name: "addison, il",
  },
  {
    postal_code: "28210",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "30248",
    zip_name: "locust grove, ga",
  },
  {
    postal_code: "30032",
    zip_name: "decatur, ga",
  },
  {
    postal_code: "34698",
    zip_name: "dunedin, fl",
  },
  {
    postal_code: "80538",
    zip_name: "loveland, co",
  },
  {
    postal_code: "46239",
    zip_name: "indianapolis, in",
  },
  {
    postal_code: "33415",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "85658",
    zip_name: "marana, az",
  },
  {
    postal_code: "34145",
    zip_name: "marco island, fl",
  },
  {
    postal_code: "29414",
    zip_name: "charleston, sc",
  },
  {
    postal_code: "33647",
    zip_name: "tampa, fl",
  },
  {
    postal_code: "92308",
    zip_name: "apple valley, ca",
  },
  {
    postal_code: "32822",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "76522",
    zip_name: "copperas cove, tx",
  },
  {
    postal_code: "34112",
    zip_name: "naples, fl",
  },
  {
    postal_code: "32211",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "30019",
    zip_name: "dacula, ga",
  },
  {
    postal_code: "72762",
    zip_name: "springdale, ar",
  },
  {
    postal_code: "33176",
    zip_name: "miami, fl",
  },
  {
    postal_code: "75149",
    zip_name: "mesquite, tx",
  },
  {
    postal_code: "15601",
    zip_name: "greensburg, pa",
  },
  {
    postal_code: "63376",
    zip_name: "saint peters, mo",
  },
  {
    postal_code: "77583",
    zip_name: "rosharon, tx",
  },
  {
    postal_code: "33446",
    zip_name: "delray beach, fl",
  },
  {
    postal_code: "27526",
    zip_name: "fuquay varina, nc",
  },
  {
    postal_code: "77018",
    zip_name: "houston, tx",
  },
  {
    postal_code: "29568",
    zip_name: "longs, sc",
  },
  {
    postal_code: "30458",
    zip_name: "statesboro, ga",
  },
  {
    postal_code: "43015",
    zip_name: "delaware, oh",
  },
  {
    postal_code: "60564",
    zip_name: "naperville, il",
  },
  {
    postal_code: "60617",
    zip_name: "chicago, il",
  },
  {
    postal_code: "99205",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "75056",
    zip_name: "the colony, tx",
  },
  {
    postal_code: "28078",
    zip_name: "huntersville, nc",
  },
  {
    postal_code: "34997",
    zip_name: "stuart, fl",
  },
  {
    postal_code: "42101",
    zip_name: "bowling green, ky",
  },
  {
    postal_code: "30143",
    zip_name: "jasper, ga",
  },
  {
    postal_code: "75052",
    zip_name: "grand prairie, tx",
  },
  {
    postal_code: "86406",
    zip_name: "lake havasu city, az",
  },
  {
    postal_code: "75002",
    zip_name: "allen, tx",
  },
  {
    postal_code: "37920",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "33312",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "34986",
    zip_name: "port saint lucie, fl",
  },
  {
    postal_code: "78260",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "28348",
    zip_name: "hope mills, nc",
  },
  {
    postal_code: "33445",
    zip_name: "delray beach, fl",
  },
  {
    postal_code: "70454",
    zip_name: "ponchatoula, la",
  },
  {
    postal_code: "14120",
    zip_name: "north tonawanda, ny",
  },
  {
    postal_code: "28227",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "33141",
    zip_name: "miami beach, fl",
  },
  {
    postal_code: "84003",
    zip_name: "american fork, ut",
  },
  {
    postal_code: "37922",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "70592",
    zip_name: "youngsville, la",
  },
  {
    postal_code: "29464",
    zip_name: "mount pleasant, sc",
  },
  {
    postal_code: "92260",
    zip_name: "palm desert, ca",
  },
  {
    postal_code: "33065",
    zip_name: "coral springs, fl",
  },
  {
    postal_code: "40475",
    zip_name: "richmond, ky",
  },
  {
    postal_code: "30542",
    zip_name: "flowery branch, ga",
  },
  {
    postal_code: "60411",
    zip_name: "chicago heights, il",
  },
  {
    postal_code: "29650",
    zip_name: "greer, sc",
  },
  {
    postal_code: "60607",
    zip_name: "chicago, il",
  },
  {
    postal_code: "23120",
    zip_name: "moseley, va",
  },
  {
    postal_code: "11234",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "30038",
    zip_name: "lithonia, ga",
  },
  {
    postal_code: "33919",
    zip_name: "fort myers, fl",
  },
  {
    postal_code: "84060",
    zip_name: "park city, ut",
  },
  {
    postal_code: "72758",
    zip_name: "rogers, ar",
  },
  {
    postal_code: "29621",
    zip_name: "anderson, sc",
  },
  {
    postal_code: "19111",
    zip_name: "philadelphia, pa",
  },
  {
    postal_code: "85396",
    zip_name: "buckeye, az",
  },
  {
    postal_code: "30680",
    zip_name: "winder, ga",
  },
  {
    postal_code: "97703",
    zip_name: "bend, or",
  },
  {
    postal_code: "60619",
    zip_name: "chicago, il",
  },
  {
    postal_code: "80524",
    zip_name: "fort collins, co",
  },
  {
    postal_code: "85132",
    zip_name: "florence, az",
  },
  {
    postal_code: "32824",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "95762",
    zip_name: "el dorado hills, ca",
  },
  {
    postal_code: "78382",
    zip_name: "rockport, tx",
  },
  {
    postal_code: "33311",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "80525",
    zip_name: "fort collins, co",
  },
  {
    postal_code: "92201",
    zip_name: "indio, ca",
  },
  {
    postal_code: "29642",
    zip_name: "easley, sc",
  },
  {
    postal_code: "77379",
    zip_name: "spring, tx",
  },
  {
    postal_code: "93291",
    zip_name: "visalia, ca",
  },
  {
    postal_code: "23231",
    zip_name: "henrico, va",
  },
  {
    postal_code: "24401",
    zip_name: "staunton, va",
  },
  {
    postal_code: "31088",
    zip_name: "warner robins, ga",
  },
  {
    postal_code: "30310",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "33157",
    zip_name: "miami, fl",
  },
  {
    postal_code: "77571",
    zip_name: "la porte, tx",
  },
  {
    postal_code: "32724",
    zip_name: "deland, fl",
  },
  {
    postal_code: "28205",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "32958",
    zip_name: "sebastian, fl",
  },
  {
    postal_code: "32909",
    zip_name: "palm bay, fl",
  },
  {
    postal_code: "33442",
    zip_name: "deerfield beach, fl",
  },
  {
    postal_code: "50021",
    zip_name: "ankeny, ia",
  },
  {
    postal_code: "80013",
    zip_name: "aurora, co",
  },
  {
    postal_code: "32246",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "63385",
    zip_name: "wentzville, mo",
  },
  {
    postal_code: "10003",
    zip_name: "new york, ny",
  },
  {
    postal_code: "60174",
    zip_name: "saint charles, il",
  },
  {
    postal_code: "23228",
    zip_name: "henrico, va",
  },
  {
    postal_code: "95020",
    zip_name: "gilroy, ca",
  },
  {
    postal_code: "60628",
    zip_name: "chicago, il",
  },
  {
    postal_code: "33334",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "45231",
    zip_name: "cincinnati, oh",
  },
  {
    postal_code: "40356",
    zip_name: "nicholasville, ky",
  },
  {
    postal_code: "29445",
    zip_name: "goose creek, sc",
  },
  {
    postal_code: "28214",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "12603",
    zip_name: "poughkeepsie, ny",
  },
  {
    postal_code: "33990",
    zip_name: "cape coral, fl",
  },
  {
    postal_code: "65802",
    zip_name: "springfield, mo",
  },
  {
    postal_code: "50023",
    zip_name: "ankeny, ia",
  },
  {
    postal_code: "75189",
    zip_name: "royse city, tx",
  },
  {
    postal_code: "72715",
    zip_name: "bella vista, ar",
  },
  {
    postal_code: "45140",
    zip_name: "loveland, oh",
  },
  {
    postal_code: "76065",
    zip_name: "midlothian, tx",
  },
  {
    postal_code: "29575",
    zip_name: "myrtle beach, sc",
  },
  {
    postal_code: "28411",
    zip_name: "wilmington, nc",
  },
  {
    postal_code: "34202",
    zip_name: "bradenton, fl",
  },
  {
    postal_code: "33433",
    zip_name: "boca raton, fl",
  },
  {
    postal_code: "38002",
    zip_name: "arlington, tn",
  },
  {
    postal_code: "30519",
    zip_name: "buford, ga",
  },
  {
    postal_code: "40229",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "32771",
    zip_name: "sanford, fl",
  },
  {
    postal_code: "93551",
    zip_name: "palmdale, ca",
  },
  {
    postal_code: "30047",
    zip_name: "lilburn, ga",
  },
  {
    postal_code: "33487",
    zip_name: "boca raton, fl",
  },
  {
    postal_code: "77055",
    zip_name: "houston, tx",
  },
  {
    postal_code: "30066",
    zip_name: "marietta, ga",
  },
  {
    postal_code: "76063",
    zip_name: "mansfield, tx",
  },
  {
    postal_code: "77521",
    zip_name: "baytown, tx",
  },
  {
    postal_code: "27858",
    zip_name: "greenville, nc",
  },
  {
    postal_code: "32955",
    zip_name: "rockledge, fl",
  },
  {
    postal_code: "33021",
    zip_name: "hollywood, fl",
  },
  {
    postal_code: "40245",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "37932",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "36526",
    zip_name: "daphne, al",
  },
  {
    postal_code: "70458",
    zip_name: "slidell, la",
  },
  {
    postal_code: "60409",
    zip_name: "calumet city, il",
  },
  {
    postal_code: "30076",
    zip_name: "roswell, ga",
  },
  {
    postal_code: "30127",
    zip_name: "powder springs, ga",
  },
  {
    postal_code: "60016",
    zip_name: "des plaines, il",
  },
  {
    postal_code: "32808",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "8054",
    zip_name: "mount laurel, nj",
  },
  {
    postal_code: "10024",
    zip_name: "new york, ny",
  },
  {
    postal_code: "76028",
    zip_name: "burleson, tx",
  },
  {
    postal_code: "33991",
    zip_name: "cape coral, fl",
  },
  {
    postal_code: "75009",
    zip_name: "celina, tx",
  },
  {
    postal_code: "60643",
    zip_name: "chicago, il",
  },
  {
    postal_code: "65616",
    zip_name: "branson, mo",
  },
  {
    postal_code: "32034",
    zip_name: "fernandina beach, fl",
  },
  {
    postal_code: "60613",
    zip_name: "chicago, il",
  },
  {
    postal_code: "27377",
    zip_name: "whitsett, nc",
  },
  {
    postal_code: "32210",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "60626",
    zip_name: "chicago, il",
  },
  {
    postal_code: "33401",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "34113",
    zip_name: "naples, fl",
  },
  {
    postal_code: "29455",
    zip_name: "johns island, sc",
  },
  {
    postal_code: "30045",
    zip_name: "lawrenceville, ga",
  },
  {
    postal_code: "77396",
    zip_name: "humble, tx",
  },
  {
    postal_code: "45011",
    zip_name: "hamilton, oh",
  },
  {
    postal_code: "33928",
    zip_name: "estero, fl",
  },
  {
    postal_code: "76542",
    zip_name: "killeen, tx",
  },
  {
    postal_code: "32828",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "37862",
    zip_name: "sevierville, tn",
  },
  {
    postal_code: "37931",
    zip_name: "knoxville, tn",
  },
  {
    postal_code: "30506",
    zip_name: "gainesville, ga",
  },
  {
    postal_code: "37040",
    zip_name: "clarksville, tn",
  },
  {
    postal_code: "34771",
    zip_name: "saint cloud, fl",
  },
  {
    postal_code: "33484",
    zip_name: "delray beach, fl",
  },
  {
    postal_code: "30188",
    zip_name: "woodstock, ga",
  },
  {
    postal_code: "55044",
    zip_name: "lakeville, mn",
  },
  {
    postal_code: "33598",
    zip_name: "wimauma, fl",
  },
  {
    postal_code: "95648",
    zip_name: "lincoln, ca",
  },
  {
    postal_code: "33062",
    zip_name: "pompano beach, fl",
  },
  {
    postal_code: "79424",
    zip_name: "lubbock, tx",
  },
  {
    postal_code: "68007",
    zip_name: "bennington, ne",
  },
  {
    postal_code: "77377",
    zip_name: "tomball, tx",
  },
  {
    postal_code: "75165",
    zip_name: "waxahachie, tx",
  },
  {
    postal_code: "34103",
    zip_name: "naples, fl",
  },
  {
    postal_code: "10128",
    zip_name: "new york, ny",
  },
  {
    postal_code: "32224",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "34212",
    zip_name: "bradenton, fl",
  },
  {
    postal_code: "33496",
    zip_name: "boca raton, fl",
  },
  {
    postal_code: "33024",
    zip_name: "hollywood, fl",
  },
  {
    postal_code: "33178",
    zip_name: "miami, fl",
  },
  {
    postal_code: "33458",
    zip_name: "jupiter, fl",
  },
  {
    postal_code: "33015",
    zip_name: "hialeah, fl",
  },
  {
    postal_code: "30041",
    zip_name: "cumming, ga",
  },
  {
    postal_code: "33436",
    zip_name: "boynton beach, fl",
  },
  {
    postal_code: "32907",
    zip_name: "palm bay, fl",
  },
  {
    postal_code: "33810",
    zip_name: "lakeland, fl",
  },
  {
    postal_code: "33437",
    zip_name: "boynton beach, fl",
  },
  {
    postal_code: "30043",
    zip_name: "lawrenceville, ga",
  },
  {
    postal_code: "85255",
    zip_name: "scottsdale, az",
  },
  {
    postal_code: "33884",
    zip_name: "winter haven, fl",
  },
  {
    postal_code: "22901",
    zip_name: "charlottesville, va",
  },
  {
    postal_code: "96814",
    zip_name: "honolulu, hi",
  },
  {
    postal_code: "32068",
    zip_name: "middleburg, fl",
  },
  {
    postal_code: "29461",
    zip_name: "moncks corner, sc",
  },
  {
    postal_code: "34231",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "33417",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "28590",
    zip_name: "winterville, nc",
  },
  {
    postal_code: "32097",
    zip_name: "yulee, fl",
  },
  {
    postal_code: "10011",
    zip_name: "new york, ny",
  },
  {
    postal_code: "28467",
    zip_name: "calabash, nc",
  },
  {
    postal_code: "33064",
    zip_name: "pompano beach, fl",
  },
  {
    postal_code: "30331",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "28277",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "33881",
    zip_name: "winter haven, fl",
  },
  {
    postal_code: "34609",
    zip_name: "spring hill, fl",
  },
  {
    postal_code: "34236",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "75098",
    zip_name: "wylie, tx",
  },
  {
    postal_code: "60453",
    zip_name: "oak lawn, il",
  },
  {
    postal_code: "77304",
    zip_name: "conroe, tx",
  },
  {
    postal_code: "75071",
    zip_name: "mckinney, tx",
  },
  {
    postal_code: "32940",
    zip_name: "melbourne, fl",
  },
  {
    postal_code: "75078",
    zip_name: "prosper, tx",
  },
  {
    postal_code: "36535",
    zip_name: "foley, al",
  },
  {
    postal_code: "33952",
    zip_name: "port charlotte, fl",
  },
  {
    postal_code: "34232",
    zip_name: "sarasota, fl",
  },
  {
    postal_code: "30028",
    zip_name: "cumming, ga",
  },
  {
    postal_code: "23116",
    zip_name: "mechanicsville, va",
  },
  {
    postal_code: "76226",
    zip_name: "argyle, tx",
  },
  {
    postal_code: "44077",
    zip_name: "painesville, oh",
  },
  {
    postal_code: "28215",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "34221",
    zip_name: "palmetto, fl",
  },
  {
    postal_code: "30318",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "29485",
    zip_name: "summerville, sc",
  },
  {
    postal_code: "77493",
    zip_name: "katy, tx",
  },
  {
    postal_code: "74014",
    zip_name: "broken arrow, ok",
  },
  {
    postal_code: "85710",
    zip_name: "tucson, az",
  },
  {
    postal_code: "28562",
    zip_name: "new bern, nc",
  },
  {
    postal_code: "32043",
    zip_name: "green cove springs, fl",
  },
  {
    postal_code: "75035",
    zip_name: "frisco, tx",
  },
  {
    postal_code: "33023",
    zip_name: "hollywood, fl",
  },
  {
    postal_code: "30114",
    zip_name: "canton, ga",
  },
  {
    postal_code: "34667",
    zip_name: "hudson, fl",
  },
  {
    postal_code: "30101",
    zip_name: "acworth, ga",
  },
  {
    postal_code: "34668",
    zip_name: "port richey, fl",
  },
  {
    postal_code: "84065",
    zip_name: "riverton, ut",
  },
  {
    postal_code: "28117",
    zip_name: "mooresville, nc",
  },
  {
    postal_code: "84074",
    zip_name: "tooele, ut",
  },
  {
    postal_code: "72712",
    zip_name: "bentonville, ar",
  },
  {
    postal_code: "37876",
    zip_name: "sevierville, tn",
  },
  {
    postal_code: "80504",
    zip_name: "longmont, co",
  },
  {
    postal_code: "76502",
    zip_name: "temple, tx",
  },
  {
    postal_code: "23059",
    zip_name: "glen allen, va",
  },
  {
    postal_code: "28412",
    zip_name: "wilmington, nc",
  },
  {
    postal_code: "33418",
    zip_name: "palm beach gardens, fl",
  },
  {
    postal_code: "33713",
    zip_name: "saint petersburg, fl",
  },
  {
    postal_code: "30132",
    zip_name: "dallas, ga",
  },
  {
    postal_code: "32095",
    zip_name: "saint augustine, fl",
  },
  {
    postal_code: "99208",
    zip_name: "spokane, wa",
  },
  {
    postal_code: "33904",
    zip_name: "cape coral, fl",
  },
  {
    postal_code: "33313",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "30157",
    zip_name: "dallas, ga",
  },
  {
    postal_code: "77375",
    zip_name: "tomball, tx",
  },
  {
    postal_code: "33027",
    zip_name: "hollywood, fl",
  },
  {
    postal_code: "34655",
    zip_name: "new port richey, fl",
  },
  {
    postal_code: "28443",
    zip_name: "hampstead, nc",
  },
  {
    postal_code: "33435",
    zip_name: "boynton beach, fl",
  },
  {
    postal_code: "33063",
    zip_name: "pompano beach, fl",
  },
  {
    postal_code: "77573",
    zip_name: "league city, tx",
  },
  {
    postal_code: "33913",
    zip_name: "fort myers, fl",
  },
  {
    postal_code: "33572",
    zip_name: "apollo beach, fl",
  },
  {
    postal_code: "33896",
    zip_name: "davenport, fl",
  },
  {
    postal_code: "55330",
    zip_name: "elk river, mn",
  },
  {
    postal_code: "84032",
    zip_name: "heber city, ut",
  },
  {
    postal_code: "93536",
    zip_name: "lancaster, ca",
  },
  {
    postal_code: "84009",
    zip_name: "south jordan, ut",
  },
  {
    postal_code: "84660",
    zip_name: "spanish fork, ut",
  },
  {
    postal_code: "12065",
    zip_name: "clifton park, ny",
  },
  {
    postal_code: "76131",
    zip_name: "fort worth, tx",
  },
  {
    postal_code: "59901",
    zip_name: "kalispell, mt",
  },
  {
    postal_code: "34758",
    zip_name: "kissimmee, fl",
  },
  {
    postal_code: "96740",
    zip_name: "kailua kona, hi",
  },
  {
    postal_code: "30309",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "77584",
    zip_name: "pearland, tx",
  },
  {
    postal_code: "34744",
    zip_name: "kissimmee, fl",
  },
  {
    postal_code: "10022",
    zip_name: "new york, ny",
  },
  {
    postal_code: "33428",
    zip_name: "boca raton, fl",
  },
  {
    postal_code: "28269",
    zip_name: "charlotte, nc",
  },
  {
    postal_code: "76244",
    zip_name: "keller, tx",
  },
  {
    postal_code: "84005",
    zip_name: "eagle mountain, ut",
  },
  {
    postal_code: "98374",
    zip_name: "puyallup, wa",
  },
  {
    postal_code: "92345",
    zip_name: "hesperia, ca",
  },
  {
    postal_code: "84404",
    zip_name: "ogden, ut",
  },
  {
    postal_code: "75072",
    zip_name: "mckinney, tx",
  },
  {
    postal_code: "46074",
    zip_name: "westfield, in",
  },
  {
    postal_code: "11201",
    zip_name: "brooklyn, ny",
  },
  {
    postal_code: "77373",
    zip_name: "spring, tx",
  },
  {
    postal_code: "33032",
    zip_name: "homestead, fl",
  },
  {
    postal_code: "78108",
    zip_name: "cibolo, tx",
  },
  {
    postal_code: "30004",
    zip_name: "alpharetta, ga",
  },
  {
    postal_code: "34983",
    zip_name: "port saint lucie, fl",
  },
  {
    postal_code: "29651",
    zip_name: "greer, sc",
  },
  {
    postal_code: "77449",
    zip_name: "katy, tx",
  },
  {
    postal_code: "10023",
    zip_name: "new york, ny",
  },
  {
    postal_code: "92211",
    zip_name: "palm desert, ca",
  },
  {
    postal_code: "33404",
    zip_name: "west palm beach, fl",
  },
  {
    postal_code: "77845",
    zip_name: "college station, tx",
  },
  {
    postal_code: "84096",
    zip_name: "herriman, ut",
  },
  {
    postal_code: "38655",
    zip_name: "oxford, ms",
  },
  {
    postal_code: "32084",
    zip_name: "saint augustine, fl",
  },
  {
    postal_code: "33543",
    zip_name: "wesley chapel, fl",
  },
  {
    postal_code: "32937",
    zip_name: "satellite beach, fl",
  },
  {
    postal_code: "30115",
    zip_name: "canton, ga",
  },
  {
    postal_code: "34952",
    zip_name: "port saint lucie, fl",
  },
  {
    postal_code: "28540",
    zip_name: "jacksonville, nc",
  },
  {
    postal_code: "95747",
    zip_name: "roseville, ca",
  },
  {
    postal_code: "33573",
    zip_name: "sun city center, fl",
  },
  {
    postal_code: "33322",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "30080",
    zip_name: "smyrna, ga",
  },
  {
    postal_code: "75068",
    zip_name: "little elm, tx",
  },
  {
    postal_code: "29910",
    zip_name: "bluffton, sc",
  },
  {
    postal_code: "34119",
    zip_name: "naples, fl",
  },
  {
    postal_code: "34638",
    zip_name: "land o lakes, fl",
  },
  {
    postal_code: "23832",
    zip_name: "chesterfield, va",
  },
  {
    postal_code: "38017",
    zip_name: "collierville, tn",
  },
  {
    postal_code: "23233",
    zip_name: "henrico, va",
  },
  {
    postal_code: "33033",
    zip_name: "homestead, fl",
  },
  {
    postal_code: "97756",
    zip_name: "redmond, or",
  },
  {
    postal_code: "34114",
    zip_name: "naples, fl",
  },
  {
    postal_code: "28376",
    zip_name: "raeford, nc",
  },
  {
    postal_code: "28314",
    zip_name: "fayetteville, nc",
  },
  {
    postal_code: "10021",
    zip_name: "new york, ny",
  },
  {
    postal_code: "84045",
    zip_name: "saratoga springs, ut",
  },
  {
    postal_code: "28460",
    zip_name: "sneads ferry, nc",
  },
  {
    postal_code: "34711",
    zip_name: "clermont, fl",
  },
  {
    postal_code: "77316",
    zip_name: "montgomery, tx",
  },
  {
    postal_code: "33025",
    zip_name: "hollywood, fl",
  },
  {
    postal_code: "93535",
    zip_name: "lancaster, ca",
  },
  {
    postal_code: "33993",
    zip_name: "cape coral, fl",
  },
  {
    postal_code: "77008",
    zip_name: "houston, tx",
  },
  {
    postal_code: "8724",
    zip_name: "brick, nj",
  },
  {
    postal_code: "37042",
    zip_name: "clarksville, tn",
  },
  {
    postal_code: "34747",
    zip_name: "kissimmee, fl",
  },
  {
    postal_code: "32725",
    zip_name: "deltona, fl",
  },
  {
    postal_code: "32225",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "29572",
    zip_name: "myrtle beach, sc",
  },
  {
    postal_code: "78665",
    zip_name: "round rock, tx",
  },
  {
    postal_code: "32081",
    zip_name: "ponte vedra, fl",
  },
  {
    postal_code: "84015",
    zip_name: "clearfield, ut",
  },
  {
    postal_code: "28461",
    zip_name: "southport, nc",
  },
  {
    postal_code: "33319",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "33578",
    zip_name: "riverview, fl",
  },
  {
    postal_code: "32259",
    zip_name: "saint johns, fl",
  },
  {
    postal_code: "33556",
    zip_name: "odessa, fl",
  },
  {
    postal_code: "87114",
    zip_name: "albuquerque, nm",
  },
  {
    postal_code: "84098",
    zip_name: "park city, ut",
  },
  {
    postal_code: "72339",
    zip_name: "gilmore, ar",
  },
  {
    postal_code: "46508",
    zip_name: "burket, in",
  },
  {
    postal_code: "24879",
    zip_name: "raysal, wv",
  },
  {
    postal_code: "67049",
    zip_name: "freeport, ks",
  },
  {
    postal_code: "41839",
    zip_name: "mousie, ky",
  },
  {
    postal_code: "67023",
    zip_name: "cambridge, ks",
  },
  {
    postal_code: "67066",
    zip_name: "iuka, ks",
  },
  {
    postal_code: "46980",
    zip_name: "servia, in",
  },
  {
    postal_code: "50861",
    zip_name: "shannon city, ia",
  },
  {
    postal_code: "49035",
    zip_name: "cloverdale, mi",
  },
  {
    postal_code: "46968",
    zip_name: "ora, in",
  },
  {
    postal_code: "81126",
    zip_name: "chama, co",
  },
  {
    postal_code: "67673",
    zip_name: "waldo, ks",
  },
  {
    postal_code: "58853",
    zip_name: "trenton, nd",
  },
  {
    postal_code: "92332",
    zip_name: "essex, ca",
  },
  {
    postal_code: "58212",
    zip_name: "aneta, nd",
  },
  {
    postal_code: "22741",
    zip_name: "stevensburg, va",
  },
  {
    postal_code: "51056",
    zip_name: "smithland, ia",
  },
  {
    postal_code: "51011",
    zip_name: "chatsworth, ia",
  },
  {
    postal_code: "68416",
    zip_name: "ohiowa, ne",
  },
  {
    postal_code: "58008",
    zip_name: "barney, nd",
  },
  {
    postal_code: "88431",
    zip_name: "newkirk, nm",
  },
  {
    postal_code: "36915",
    zip_name: "needham, al",
  },
  {
    postal_code: "84534",
    zip_name: "montezuma creek, ut",
  },
  {
    postal_code: "99682",
    zip_name: "tyonek, ak",
  },
  {
    postal_code: "16331",
    zip_name: "kossuth, pa",
  },
  {
    postal_code: "64457",
    zip_name: "guilford, mo",
  },
  {
    postal_code: "57052",
    zip_name: "olivet, sd",
  },
  {
    postal_code: "43523",
    zip_name: "grelton, oh",
  },
  {
    postal_code: "62879",
    zip_name: "sailor springs, il",
  },
  {
    postal_code: "65468",
    zip_name: "eunice, mo",
  },
  {
    postal_code: "15460",
    zip_name: "martin, pa",
  },
  {
    postal_code: "71922",
    zip_name: "antoine, ar",
  },
  {
    postal_code: "41352",
    zip_name: "mize, ky",
  },
  {
    postal_code: "52166",
    zip_name: "saint lucas, ia",
  },
  {
    postal_code: "67332",
    zip_name: "bartlett, ks",
  },
  {
    postal_code: "30602",
    zip_name: "athens, ga",
  },
  {
    postal_code: "7505",
    zip_name: "paterson, nj",
  },
  {
    postal_code: "25044",
    zip_name: "clear creek, wv",
  },
  {
    postal_code: "43962",
    zip_name: "summitville, oh",
  },
  {
    postal_code: "71472",
    zip_name: "sieper, la",
  },
  {
    postal_code: "80840",
    zip_name: "usaf academy, co",
  },
  {
    postal_code: "83853",
    zip_name: "porthill, id",
  },
  {
    postal_code: "14173",
    zip_name: "yorkshire, ny",
  },
  {
    postal_code: "36790",
    zip_name: "stanton, al",
  },
  {
    postal_code: "87713",
    zip_name: "chacon, nm",
  },
  {
    postal_code: "68976",
    zip_name: "smithfield, ne",
  },
  {
    postal_code: "99649",
    zip_name: "pilot point, ak",
  },
  {
    postal_code: "72003",
    zip_name: "almyra, ar",
  },
  {
    postal_code: "79256",
    zip_name: "roaring springs, tx",
  },
  {
    postal_code: "83277",
    zip_name: "springfield, id",
  },
  {
    postal_code: "68623",
    zip_name: "belgrade, ne",
  },
  {
    postal_code: "68042",
    zip_name: "memphis, ne",
  },
  {
    postal_code: "57780",
    zip_name: "scenic, sd",
  },
  {
    postal_code: "71438",
    zip_name: "hineston, la",
  },
  {
    postal_code: "47986",
    zip_name: "templeton, in",
  },
  {
    postal_code: "79518",
    zip_name: "girard, tx",
  },
  {
    postal_code: "97144",
    zip_name: "timber, or",
  },
  {
    postal_code: "39057",
    zip_name: "conehatta, ms",
  },
  {
    postal_code: "36916",
    zip_name: "pennington, al",
  },
  {
    postal_code: "70436",
    zip_name: "fluker, la",
  },
  {
    postal_code: "30597",
    zip_name: "dahlonega, ga",
  },
  {
    postal_code: "39113",
    zip_name: "mayersville, ms",
  },
  {
    postal_code: "22730",
    zip_name: "oakpark, va",
  },
  {
    postal_code: "71441",
    zip_name: "kelly, la",
  },
  {
    postal_code: "57422",
    zip_name: "andover, sd",
  },
  {
    postal_code: "58794",
    zip_name: "white earth, nd",
  },
  {
    postal_code: "41861",
    zip_name: "raven, ky",
  },
  {
    postal_code: "75441",
    zip_name: "enloe, tx",
  },
  {
    postal_code: "68728",
    zip_name: "concord, ne",
  },
  {
    postal_code: "73736",
    zip_name: "fairmont, ok",
  },
  {
    postal_code: "76481",
    zip_name: "south bend, tx",
  },
  {
    postal_code: "50454",
    zip_name: "little cedar, ia",
  },
  {
    postal_code: "99628",
    zip_name: "manokotak, ak",
  },
  {
    postal_code: "51061",
    zip_name: "washta, ia",
  },
  {
    postal_code: "62523",
    zip_name: "decatur, il",
  },
  {
    postal_code: "79777",
    zip_name: "pyote, tx",
  },
  {
    postal_code: "68732",
    zip_name: "dixon, ne",
  },
  {
    postal_code: "69355",
    zip_name: "melbeta, ne",
  },
  {
    postal_code: "68348",
    zip_name: "elk creek, ne",
  },
  {
    postal_code: "25544",
    zip_name: "myra, wv",
  },
  {
    postal_code: "68882",
    zip_name: "wolbach, ne",
  },
  {
    postal_code: "99571",
    zip_name: "cold bay, ak",
  },
  {
    postal_code: "36115",
    zip_name: "montgomery, al",
  },
  {
    postal_code: "68747",
    zip_name: "mclean, ne",
  },
  {
    postal_code: "70504",
    zip_name: "lafayette, la",
  },
  {
    postal_code: "59082",
    zip_name: "springdale, mt",
  },
  {
    postal_code: "17120",
    zip_name: "harrisburg, pa",
  },
  {
    postal_code: "58313",
    zip_name: "balta, nd",
  },
  {
    postal_code: "97533",
    zip_name: "murphy, or",
  },
  {
    postal_code: "42788",
    zip_name: "white mills, ky",
  },
  {
    postal_code: "68837",
    zip_name: "elyria, ne",
  },
  {
    postal_code: "41766",
    zip_name: "thousandsticks, ky",
  },
  {
    postal_code: "87060",
    zip_name: "tome, nm",
  },
  {
    postal_code: "37072",
    zip_name: "goodlettsville, tn",
  },
  {
    postal_code: "30118",
    zip_name: "carrollton, ga",
  },
  {
    postal_code: "57714",
    zip_name: "allen, sd",
  },
  {
    postal_code: "65048",
    zip_name: "koeltztown, mo",
  },
  {
    postal_code: "74755",
    zip_name: "rufe, ok",
  },
  {
    postal_code: "68659",
    zip_name: "rogers, ne",
  },
  {
    postal_code: "56113",
    zip_name: "arco, mn",
  },
  {
    postal_code: "41563",
    zip_name: "shelby gap, ky",
  },
  {
    postal_code: "57644",
    zip_name: "meadow, sd",
  },
  {
    postal_code: "58712",
    zip_name: "balfour, nd",
  },
  {
    postal_code: "17232",
    zip_name: "lurgan, pa",
  },
  {
    postal_code: "45353",
    zip_name: "pemberton, oh",
  },
  {
    postal_code: "80862",
    zip_name: "wild horse, co",
  },
  {
    postal_code: "95305",
    zip_name: "big oak flat, ca",
  },
  {
    postal_code: "51033",
    zip_name: "linn grove, ia",
  },
  {
    postal_code: "11430",
    zip_name: "jamaica, ny",
  },
  {
    postal_code: "65232",
    zip_name: "benton city, mo",
  },
  {
    postal_code: "72028",
    zip_name: "choctaw, ar",
  },
  {
    postal_code: "50567",
    zip_name: "nemaha, ia",
  },
  {
    postal_code: "74576",
    zip_name: "wardville, ok",
  },
  {
    postal_code: "15476",
    zip_name: "ronco, pa",
  },
  {
    postal_code: "74521",
    zip_name: "albion, ok",
  },
  {
    postal_code: "56516",
    zip_name: "bejou, mn",
  },
  {
    postal_code: "65042",
    zip_name: "high point, mo",
  },
  {
    postal_code: "21522",
    zip_name: "bittinger, md",
  },
  {
    postal_code: "59039",
    zip_name: "ingomar, mt",
  },
  {
    postal_code: "57770",
    zip_name: "pine ridge, sd",
  },
  {
    postal_code: "24845",
    zip_name: "ikes fork, wv",
  },
  {
    postal_code: "67131",
    zip_name: "rock, ks",
  },
  {
    postal_code: "45033",
    zip_name: "hooven, oh",
  },
  {
    postal_code: "51550",
    zip_name: "magnolia, ia",
  },
  {
    postal_code: "58416",
    zip_name: "binford, nd",
  },
  {
    postal_code: "17247",
    zip_name: "quincy, pa",
  },
  {
    postal_code: "85709",
    zip_name: "tucson, az",
  },
  {
    postal_code: "89427",
    zip_name: "schurz, nv",
  },
  {
    postal_code: "67747",
    zip_name: "monument, ks",
  },
  {
    postal_code: "41464",
    zip_name: "royalton, ky",
  },
  {
    postal_code: "18085",
    zip_name: "tatamy, pa",
  },
  {
    postal_code: "50132",
    zip_name: "kamrar, ia",
  },
  {
    postal_code: "51235",
    zip_name: "doon, ia",
  },
  {
    postal_code: "79539",
    zip_name: "o brien, tx",
  },
  {
    postal_code: "26691",
    zip_name: "tioga, wv",
  },
  {
    postal_code: "65666",
    zip_name: "hardenville, mo",
  },
  {
    postal_code: "25008",
    zip_name: "artie, wv",
  },
  {
    postal_code: "98824",
    zip_name: "george, wa",
  },
  {
    postal_code: "25913",
    zip_name: "ravencliff, wv",
  },
  {
    postal_code: "24873",
    zip_name: "paynesville, wv",
  },
  {
    postal_code: "57538",
    zip_name: "herrick, sd",
  },
  {
    postal_code: "58566",
    zip_name: "saint anthony, nd",
  },
  {
    postal_code: "59544",
    zip_name: "whitewater, mt",
  },
  {
    postal_code: "73945",
    zip_name: "hooker, ok",
  },
  {
    postal_code: "72083",
    zip_name: "keo, ar",
  },
  {
    postal_code: "73440",
    zip_name: "lebanon, ok",
  },
  {
    postal_code: "26167",
    zip_name: "reader, wv",
  },
  {
    postal_code: "68932",
    zip_name: "campbell, ne",
  },
  {
    postal_code: "57256",
    zip_name: "ortley, sd",
  },
  {
    postal_code: "71745",
    zip_name: "harrell, ar",
  },
  {
    postal_code: "57370",
    zip_name: "pukwana, sd",
  },
  {
    postal_code: "68952",
    zip_name: "inavale, ne",
  },
  {
    postal_code: "4019",
    zip_name: "cliff island, me",
  },
  {
    postal_code: "72820",
    zip_name: "alix, ar",
  },
  {
    postal_code: "57424",
    zip_name: "ashton, sd",
  },
  {
    postal_code: "59243",
    zip_name: "lambert, mt",
  },
  {
    postal_code: "31623",
    zip_name: "argyle, ga",
  },
  {
    postal_code: "25111",
    zip_name: "indore, wv",
  },
  {
    postal_code: "50269",
    zip_name: "whitten, ia",
  },
  {
    postal_code: "22845",
    zip_name: "orkney springs, va",
  },
  {
    postal_code: "71447",
    zip_name: "lena, la",
  },
  {
    postal_code: "38780",
    zip_name: "wayside, ms",
  },
  {
    postal_code: "61479",
    zip_name: "speer, il",
  },
  {
    postal_code: "54123",
    zip_name: "forest junction, wi",
  },
  {
    postal_code: "16684",
    zip_name: "tipton, pa",
  },
  {
    postal_code: "39776",
    zip_name: "woodland, ms",
  },
  {
    postal_code: "39326",
    zip_name: "daleville, ms",
  },
  {
    postal_code: "68367",
    zip_name: "gresham, ne",
  },
  {
    postal_code: "59713",
    zip_name: "avon, mt",
  },
  {
    postal_code: "70801",
    zip_name: "baton rouge, la",
  },
  {
    postal_code: "57348",
    zip_name: "hitchcock, sd",
  },
  {
    postal_code: "45835",
    zip_name: "dola, oh",
  },
  {
    postal_code: "16734",
    zip_name: "james city, pa",
  },
  {
    postal_code: "79377",
    zip_name: "welch, tx",
  },
  {
    postal_code: "79755",
    zip_name: "midkiff, tx",
  },
  {
    postal_code: "56047",
    zip_name: "huntley, mn",
  },
  {
    postal_code: "57577",
    zip_name: "wanblee, sd",
  },
  {
    postal_code: "47455",
    zip_name: "patricksburg, in",
  },
  {
    postal_code: "90840",
    zip_name: "long beach, ca",
  },
  {
    postal_code: "73539",
    zip_name: "elmer, ok",
  },
  {
    postal_code: "88424",
    zip_name: "grenville, nm",
  },
  {
    postal_code: "29054",
    zip_name: "gilbert, sc",
  },
  {
    postal_code: "95920",
    zip_name: "butte city, ca",
  },
  {
    postal_code: "52164",
    zip_name: "randalia, ia",
  },
  {
    postal_code: "57584",
    zip_name: "witten, sd",
  },
  {
    postal_code: "65286",
    zip_name: "triplett, mo",
  },
  {
    postal_code: "16837",
    zip_name: "glen richey, pa",
  },
  {
    postal_code: "87044",
    zip_name: "ponderosa, nm",
  },
  {
    postal_code: "67751",
    zip_name: "park, ks",
  },
  {
    postal_code: "24872",
    zip_name: "panther, wv",
  },
  {
    postal_code: "17027",
    zip_name: "grantham, pa",
  },
  {
    postal_code: "29175",
    zip_name: "westville, sc",
  },
  {
    postal_code: "54150",
    zip_name: "neopit, wi",
  },
  {
    postal_code: "58016",
    zip_name: "clifford, nd",
  },
  {
    postal_code: "76870",
    zip_name: "priddy, tx",
  },
  {
    postal_code: "36849",
    zip_name: "auburn university, al",
  },
  {
    postal_code: "40808",
    zip_name: "big laurel, ky",
  },
  {
    postal_code: "58484",
    zip_name: "sutton, nd",
  },
  {
    postal_code: "57429",
    zip_name: "brentford, sd",
  },
  {
    postal_code: "82055",
    zip_name: "centennial, wy",
  },
  {
    postal_code: "35464",
    zip_name: "gainesville, al",
  },
  {
    postal_code: "99555",
    zip_name: "aleknagik, ak",
  },
  {
    postal_code: "61234",
    zip_name: "annawan, il",
  },
  {
    postal_code: "51551",
    zip_name: "malvern, ia",
  },
  {
    postal_code: "47174",
    zip_name: "sulphur, in",
  },
  {
    postal_code: "68531",
    zip_name: "lincoln, ne",
  },
  {
    postal_code: "58711",
    zip_name: "antler, nd",
  },
  {
    postal_code: "52573",
    zip_name: "mount sterling, ia",
  },
  {
    postal_code: "60557",
    zip_name: "wedron, il",
  },
  {
    postal_code: "57245",
    zip_name: "kranzburg, sd",
  },
  {
    postal_code: "52759",
    zip_name: "montpelier, ia",
  },
  {
    postal_code: "59255",
    zip_name: "poplar, mt",
  },
  {
    postal_code: "70643",
    zip_name: "grand chenier, la",
  },
  {
    postal_code: "31081",
    zip_name: "rupert, ga",
  },
  {
    postal_code: "54637",
    zip_name: "hustler, wi",
  },
  {
    postal_code: "18430",
    zip_name: "herrick center, pa",
  },
  {
    postal_code: "45675",
    zip_name: "rock camp, oh",
  },
  {
    postal_code: "52221",
    zip_name: "guernsey, ia",
  },
  {
    postal_code: "83421",
    zip_name: "chester, id",
  },
  {
    postal_code: "23357",
    zip_name: "greenbush, va",
  },
  {
    postal_code: "26348",
    zip_name: "folsom, wv",
  },
  {
    postal_code: "87724",
    zip_name: "la loma, nm",
  },
  {
    postal_code: "99751",
    zip_name: "kobuk, ak",
  },
  {
    postal_code: "44640",
    zip_name: "limaville, oh",
  },
  {
    postal_code: "58332",
    zip_name: "esmond, nd",
  },
  {
    postal_code: "72035",
    zip_name: "conway, ar",
  },
  {
    postal_code: "43547",
    zip_name: "neapolis, oh",
  },
  {
    postal_code: "72320",
    zip_name: "brickeys, ar",
  },
  {
    postal_code: "93549",
    zip_name: "olancha, ca",
  },
  {
    postal_code: "87820",
    zip_name: "aragon, nm",
  },
  {
    postal_code: "51343",
    zip_name: "greenville, ia",
  },
  {
    postal_code: "25126",
    zip_name: "london, wv",
  },
  {
    postal_code: "25977",
    zip_name: "meadow creek, wv",
  },
  {
    postal_code: "68711",
    zip_name: "amelia, ne",
  },
  {
    postal_code: "63770",
    zip_name: "old appleton, mo",
  },
  {
    postal_code: "52566",
    zip_name: "kirkville, ia",
  },
  {
    postal_code: "79225",
    zip_name: "chillicothe, tx",
  },
  {
    postal_code: "50576",
    zip_name: "rembrandt, ia",
  },
  {
    postal_code: "27556",
    zip_name: "middleburg, nc",
  },
  {
    postal_code: "43902",
    zip_name: "alledonia, oh",
  },
  {
    postal_code: "80757",
    zip_name: "woodrow, co",
  },
  {
    postal_code: "24627",
    zip_name: "mavisdale, va",
  },
  {
    postal_code: "59225",
    zip_name: "frazer, mt",
  },
  {
    postal_code: "41819",
    zip_name: "gordon, ky",
  },
  {
    postal_code: "58478",
    zip_name: "robinson, nd",
  },
  {
    postal_code: "48109",
    zip_name: "ann arbor, mi",
  },
  {
    postal_code: "52568",
    zip_name: "martinsburg, ia",
  },
  {
    postal_code: "58838",
    zip_name: "cartwright, nd",
  },
  {
    postal_code: "87569",
    zip_name: "serafina, nm",
  },
  {
    postal_code: "68345",
    zip_name: "du bois, ne",
  },
  {
    postal_code: "59260",
    zip_name: "richland, mt",
  },
  {
    postal_code: "63535",
    zip_name: "coatsville, mo",
  },
  {
    postal_code: "29828",
    zip_name: "gloverville, sc",
  },
  {
    postal_code: "61332",
    zip_name: "leonore, il",
  },
  {
    postal_code: "68458",
    zip_name: "virginia, ne",
  },
  {
    postal_code: "43717",
    zip_name: "belle valley, oh",
  },
  {
    postal_code: "16910",
    zip_name: "alba, pa",
  },
  {
    postal_code: "68379",
    zip_name: "julian, ne",
  },
  {
    postal_code: "71748",
    zip_name: "ivan, ar",
  },
  {
    postal_code: "68833",
    zip_name: "dunning, ne",
  },
  {
    postal_code: "65728",
    zip_name: "ponce de leon, mo",
  },
  {
    postal_code: "75687",
    zip_name: "price, tx",
  },
  {
    postal_code: "26203",
    zip_name: "erbacon, wv",
  },
  {
    postal_code: "31062",
    zip_name: "milledgeville, ga",
  },
  {
    postal_code: "25231",
    zip_name: "advent, wv",
  },
  {
    postal_code: "99684",
    zip_name: "unalakleet, ak",
  },
  {
    postal_code: "65735",
    zip_name: "quincy, mo",
  },
  {
    postal_code: "67102",
    zip_name: "maple city, ks",
  },
  {
    postal_code: "63434",
    zip_name: "bethel, mo",
  },
  {
    postal_code: "51645",
    zip_name: "imogene, ia",
  },
  {
    postal_code: "95211",
    zip_name: "stockton, ca",
  },
  {
    postal_code: "58833",
    zip_name: "ambrose, nd",
  },
  {
    postal_code: "76845",
    zip_name: "gouldbusk, tx",
  },
  {
    postal_code: "30912",
    zip_name: "augusta, ga",
  },
  {
    postal_code: "45743",
    zip_name: "long bottom, oh",
  },
  {
    postal_code: "81050",
    zip_name: "la junta, co",
  },
  {
    postal_code: "85534",
    zip_name: "duncan, az",
  },
  {
    postal_code: "72457",
    zip_name: "portia, ar",
  },
  {
    postal_code: "68380",
    zip_name: "lewiston, ne",
  },
  {
    postal_code: "13482",
    zip_name: "west burlington, ny",
  },
  {
    postal_code: "71828",
    zip_name: "cale, ar",
  },
  {
    postal_code: "76820",
    zip_name: "art, tx",
  },
  {
    postal_code: "68934",
    zip_name: "deweese, ne",
  },
  {
    postal_code: "58338",
    zip_name: "hampden, nd",
  },
  {
    postal_code: "45674",
    zip_name: "rio grande, oh",
  },
  {
    postal_code: "68856",
    zip_name: "merna, ne",
  },
  {
    postal_code: "67143",
    zip_name: "sun city, ks",
  },
  {
    postal_code: "58317",
    zip_name: "bisbee, nd",
  },
  {
    postal_code: "47420",
    zip_name: "avoca, in",
  },
  {
    postal_code: "25936",
    zip_name: "thurmond, wv",
  },
  {
    postal_code: "47958",
    zip_name: "mellott, in",
  },
  {
    postal_code: "39736",
    zip_name: "artesia, ms",
  },
  {
    postal_code: "87320",
    zip_name: "mexican springs, nm",
  },
  {
    postal_code: "67622",
    zip_name: "almena, ks",
  },
  {
    postal_code: "47535",
    zip_name: "freelandville, in",
  },
  {
    postal_code: "58361",
    zip_name: "pekin, nd",
  },
  {
    postal_code: "43757",
    zip_name: "malaga, oh",
  },
  {
    postal_code: "58321",
    zip_name: "brocket, nd",
  },
  {
    postal_code: "67024",
    zip_name: "cedar vale, ks",
  },
  {
    postal_code: "64664",
    zip_name: "mooresville, mo",
  },
  {
    postal_code: "40801",
    zip_name: "ages brookside, ky",
  },
  {
    postal_code: "50064",
    zip_name: "dana, ia",
  },
  {
    postal_code: "29424",
    zip_name: "charleston, sc",
  },
  {
    postal_code: "25651",
    zip_name: "wharncliffe, wv",
  },
  {
    postal_code: "88343",
    zip_name: "picacho, nm",
  },
  {
    postal_code: "99640",
    zip_name: "nondalton, ak",
  },
  {
    postal_code: "62098",
    zip_name: "wrights, il",
  },
  {
    postal_code: "99726",
    zip_name: "bettles field, ak",
  },
  {
    postal_code: "64102",
    zip_name: "kansas city, mo",
  },
  {
    postal_code: "38914",
    zip_name: "big creek, ms",
  },
  {
    postal_code: "55087",
    zip_name: "warsaw, mn",
  },
  {
    postal_code: "46932",
    zip_name: "galveston, in",
  },
  {
    postal_code: "57623",
    zip_name: "dupree, sd",
  },
  {
    postal_code: "72686",
    zip_name: "witts springs, ar",
  },
  {
    postal_code: "57552",
    zip_name: "midland, sd",
  },
  {
    postal_code: "3233",
    zip_name: "elkins, nh",
  },
  {
    postal_code: "58627",
    zip_name: "fairfield, nd",
  },
  {
    postal_code: "97735",
    zip_name: "fort rock, or",
  },
  {
    postal_code: "83243",
    zip_name: "holbrook, id",
  },
  {
    postal_code: "43836",
    zip_name: "plainfield, oh",
  },
  {
    postal_code: "57534",
    zip_name: "hamill, sd",
  },
  {
    postal_code: "58316",
    zip_name: "belcourt, nd",
  },
  {
    postal_code: "14039",
    zip_name: "dale, ny",
  },
  {
    postal_code: "6349",
    zip_name: "groton, ct",
  },
  {
    postal_code: "99580",
    zip_name: "ekwok, ak",
  },
  {
    postal_code: "82646",
    zip_name: "natrona, wy",
  },
  {
    postal_code: "87736",
    zip_name: "rainsville, nm",
  },
  {
    postal_code: "65752",
    zip_name: "south greenfield, mo",
  },
  {
    postal_code: "63473",
    zip_name: "williamstown, mo",
  },
  {
    postal_code: "25534",
    zip_name: "kiahsville, wv",
  },
  {
    postal_code: "66728",
    zip_name: "crestline, ks",
  },
  {
    postal_code: "55977",
    zip_name: "taopi, mn",
  },
  {
    postal_code: "57322",
    zip_name: "carpenter, sd",
  },
  {
    postal_code: "73551",
    zip_name: "hollister, ok",
  },
  {
    postal_code: "59841",
    zip_name: "pinesdale, mt",
  },
  {
    postal_code: "15565",
    zip_name: "west salisbury, pa",
  },
  {
    postal_code: "62479",
    zip_name: "wheeler, il",
  },
  {
    postal_code: "34445",
    zip_name: "holder, fl",
  },
  {
    postal_code: "49248",
    zip_name: "jasper, mi",
  },
  {
    postal_code: "25110",
    zip_name: "hugheston, wv",
  },
  {
    postal_code: "79061",
    zip_name: "mobeetie, tx",
  },
  {
    postal_code: "45789",
    zip_name: "wingett run, oh",
  },
  {
    postal_code: "17968",
    zip_name: "sacramento, pa",
  },
  {
    postal_code: "25088",
    zip_name: "glen, wv",
  },
  {
    postal_code: "64427",
    zip_name: "bolckow, mo",
  },
  {
    postal_code: "72338",
    zip_name: "frenchmans bayou, ar",
  },
  {
    postal_code: "99668",
    zip_name: "sleetmute, ak",
  },
  {
    postal_code: "97711",
    zip_name: "ashwood, or",
  },
  {
    postal_code: "92267",
    zip_name: "parker dam, ca",
  },
  {
    postal_code: "41714",
    zip_name: "bear branch, ky",
  },
  {
    postal_code: "36008",
    zip_name: "booth, al",
  },
  {
    postal_code: "88133",
    zip_name: "saint vrain, nm",
  },
  {
    postal_code: "68975",
    zip_name: "saronville, ne",
  },
  {
    postal_code: "72428",
    zip_name: "etowah, ar",
  },
  {
    postal_code: "99825",
    zip_name: "elfin cove, ak",
  },
  {
    postal_code: "24861",
    zip_name: "maybeury, wv",
  },
  {
    postal_code: "97145",
    zip_name: "tolovana park, or",
  },
  {
    postal_code: "57452",
    zip_name: "java, sd",
  },
  {
    postal_code: "58038",
    zip_name: "grandin, nd",
  },
  {
    postal_code: "81239",
    zip_name: "parlin, co",
  },
  {
    postal_code: "89311",
    zip_name: "baker, nv",
  },
  {
    postal_code: "57426",
    zip_name: "barnard, sd",
  },
  {
    postal_code: "59066",
    zip_name: "pryor, mt",
  },
  {
    postal_code: "26138",
    zip_name: "brohard, wv",
  },
  {
    postal_code: "58430",
    zip_name: "denhoff, nd",
  },
  {
    postal_code: "56125",
    zip_name: "dovray, mn",
  },
  {
    postal_code: "72683",
    zip_name: "vendor, ar",
  },
  {
    postal_code: "32013",
    zip_name: "day, fl",
  },
  {
    postal_code: "16940",
    zip_name: "nelson, pa",
  },
  {
    postal_code: "66963",
    zip_name: "randall, ks",
  },
  {
    postal_code: "71820",
    zip_name: "alleene, ar",
  },
  {
    postal_code: "73947",
    zip_name: "keyes, ok",
  },
  {
    postal_code: "67012",
    zip_name: "beaumont, ks",
  },
  {
    postal_code: "58487",
    zip_name: "tappen, nd",
  },
  {
    postal_code: "14479",
    zip_name: "knowlesville, ny",
  },
  {
    postal_code: "47457",
    zip_name: "scotland, in",
  },
  {
    postal_code: "99154",
    zip_name: "mohler, wa",
  },
  {
    postal_code: "10020",
    zip_name: "new york, ny",
  },
  {
    postal_code: "23409",
    zip_name: "mears, va",
  },
  {
    postal_code: "78931",
    zip_name: "bleiblerville, tx",
  },
  {
    postal_code: "24736",
    zip_name: "matoaka, wv",
  },
  {
    postal_code: "51578",
    zip_name: "westphalia, ia",
  },
  {
    postal_code: "58620",
    zip_name: "amidon, nd",
  },
  {
    postal_code: "84408",
    zip_name: "ogden, ut",
  },
  {
    postal_code: "58372",
    zip_name: "sarles, nd",
  },
  {
    postal_code: "27531",
    zip_name: "goldsboro, nc",
  },
  {
    postal_code: "57314",
    zip_name: "artesian, sd",
  },
  {
    postal_code: "43439",
    zip_name: "lacarne, oh",
  },
  {
    postal_code: "59418",
    zip_name: "buffalo, mt",
  },
  {
    postal_code: "32356",
    zip_name: "salem, fl",
  },
  {
    postal_code: "43414",
    zip_name: "dunbridge, oh",
  },
  {
    postal_code: "59867",
    zip_name: "saltese, mt",
  },
  {
    postal_code: "39160",
    zip_name: "sallis, ms",
  },
  {
    postal_code: "62319",
    zip_name: "camden, il",
  },
  {
    postal_code: "63430",
    zip_name: "alexandria, mo",
  },
  {
    postal_code: "17005",
    zip_name: "berrysburg, pa",
  },
  {
    postal_code: "64652",
    zip_name: "laredo, mo",
  },
  {
    postal_code: "48432",
    zip_name: "filion, mi",
  },
  {
    postal_code: "57272",
    zip_name: "wallace, sd",
  },
  {
    postal_code: "56668",
    zip_name: "ranier, mn",
  },
  {
    postal_code: "58494",
    zip_name: "wing, nd",
  },
  {
    postal_code: "74540",
    zip_name: "daisy, ok",
  },
  {
    postal_code: "59033",
    zip_name: "greycliff, mt",
  },
  {
    postal_code: "57640",
    zip_name: "lodgepole, sd",
  },
  {
    postal_code: "40824",
    zip_name: "dayhoit, ky",
  },
  {
    postal_code: "52593",
    zip_name: "udell, ia",
  },
  {
    postal_code: "32437",
    zip_name: "ebro, fl",
  },
  {
    postal_code: "59050",
    zip_name: "lodge grass, mt",
  },
  {
    postal_code: "68981",
    zip_name: "upland, ne",
  },
  {
    postal_code: "98330",
    zip_name: "elbe, wa",
  },
  {
    postal_code: "99551",
    zip_name: "akiachak, ak",
  },
  {
    postal_code: "50527",
    zip_name: "curlew, ia",
  },
  {
    postal_code: "52624",
    zip_name: "denmark, ia",
  },
  {
    postal_code: "67475",
    zip_name: "ramona, ks",
  },
  {
    postal_code: "14707",
    zip_name: "allentown, ny",
  },
  {
    postal_code: "16213",
    zip_name: "callensburg, pa",
  },
  {
    postal_code: "76460",
    zip_name: "loving, tx",
  },
  {
    postal_code: "68960",
    zip_name: "naponee, ne",
  },
  {
    postal_code: "4574",
    zip_name: "washington, me",
  },
  {
    postal_code: "41571",
    zip_name: "varney, ky",
  },
  {
    postal_code: "26636",
    zip_name: "rosedale, wv",
  },
  {
    postal_code: "99777",
    zip_name: "tanana, ak",
  },
  {
    postal_code: "72052",
    zip_name: "garner, ar",
  },
  {
    postal_code: "17934",
    zip_name: "gilberton, pa",
  },
  {
    postal_code: "60946",
    zip_name: "kempton, il",
  },
  {
    postal_code: "14754",
    zip_name: "little genesee, ny",
  },
  {
    postal_code: "89830",
    zip_name: "montello, nv",
  },
  {
    postal_code: "72444",
    zip_name: "maynard, ar",
  },
  {
    postal_code: "12811",
    zip_name: "bakers mills, ny",
  },
  {
    postal_code: "28452",
    zip_name: "longwood, nc",
  },
  {
    postal_code: "86036",
    zip_name: "marble canyon, az",
  },
  {
    postal_code: "21636",
    zip_name: "goldsboro, md",
  },
  {
    postal_code: "84031",
    zip_name: "hanna, ut",
  },
  {
    postal_code: "56758",
    zip_name: "strandquist, mn",
  },
  {
    postal_code: "87743",
    zip_name: "roy, nm",
  },
  {
    postal_code: "64638",
    zip_name: "dawn, mo",
  },
  {
    postal_code: "94970",
    zip_name: "stinson beach, ca",
  },
  {
    postal_code: "38953",
    zip_name: "scobey, ms",
  },
  {
    postal_code: "84316",
    zip_name: "howell, ut",
  },
  {
    postal_code: "12957",
    zip_name: "moira, ny",
  },
  {
    postal_code: "15739",
    zip_name: "ernest, pa",
  },
  {
    postal_code: "54659",
    zip_name: "taylor, wi",
  },
  {
    postal_code: "15564",
    zip_name: "wellersburg, pa",
  },
  {
    postal_code: "78371",
    zip_name: "oilton, tx",
  },
  {
    postal_code: "58568",
    zip_name: "selfridge, nd",
  },
  {
    postal_code: "47305",
    zip_name: "muncie, in",
  },
  {
    postal_code: "66534",
    zip_name: "sabetha, ks",
  },
  {
    postal_code: "68959",
    zip_name: "minden, ne",
  },
  {
    postal_code: "43766",
    zip_name: "new straitsville, oh",
  },
  {
    postal_code: "26230",
    zip_name: "pickens, wv",
  },
  {
    postal_code: "71031",
    zip_name: "goldonna, la",
  },
  {
    postal_code: "57224",
    zip_name: "claire city, sd",
  },
  {
    postal_code: "25958",
    zip_name: "charmco, wv",
  },
  {
    postal_code: "36052",
    zip_name: "mathews, al",
  },
  {
    postal_code: "13132",
    zip_name: "pennellville, ny",
  },
  {
    postal_code: "39439",
    zip_name: "heidelberg, ms",
  },
  {
    postal_code: "61871",
    zip_name: "royal, il",
  },
  {
    postal_code: "14083",
    zip_name: "java village, ny",
  },
  {
    postal_code: "83654",
    zip_name: "new meadows, id",
  },
  {
    postal_code: "66939",
    zip_name: "courtland, ks",
  },
  {
    postal_code: "73747",
    zip_name: "isabella, ok",
  },
  {
    postal_code: "56046",
    zip_name: "hope, mn",
  },
  {
    postal_code: "67743",
    zip_name: "levant, ks",
  },
  {
    postal_code: "59435",
    zip_name: "ethridge, mt",
  },
  {
    postal_code: "83548",
    zip_name: "reubens, id",
  },
  {
    postal_code: "38950",
    zip_name: "philipp, ms",
  },
  {
    postal_code: "42462",
    zip_name: "waverly, ky",
  },
  {
    postal_code: "6781",
    zip_name: "pequabuck, ct",
  },
  {
    postal_code: "58355",
    zip_name: "nekoma, nd",
  },
  {
    postal_code: "50847",
    zip_name: "grant, ia",
  },
  {
    postal_code: "54207",
    zip_name: "collins, wi",
  },
  {
    postal_code: "69331",
    zip_name: "angora, ne",
  },
  {
    postal_code: "96162",
    zip_name: "truckee, ca",
  },
  {
    postal_code: "61831",
    zip_name: "collison, il",
  },
  {
    postal_code: "56033",
    zip_name: "frost, mn",
  },
  {
    postal_code: "59933",
    zip_name: "stryker, mt",
  },
  {
    postal_code: "40828",
    zip_name: "evarts, ky",
  },
  {
    postal_code: "65657",
    zip_name: "garrison, mo",
  },
  {
    postal_code: "15467",
    zip_name: "new geneva, pa",
  },
  {
    postal_code: "16622",
    zip_name: "calvin, pa",
  },
  {
    postal_code: "21084",
    zip_name: "jarrettsville, md",
  },
  {
    postal_code: "16212",
    zip_name: "cadogan, pa",
  },
  {
    postal_code: "86020",
    zip_name: "cameron, az",
  },
  {
    postal_code: "63760",
    zip_name: "leopold, mo",
  },
  {
    postal_code: "73658",
    zip_name: "oakwood, ok",
  },
  {
    postal_code: "4764",
    zip_name: "oxbow, me",
  },
  {
    postal_code: "72017",
    zip_name: "biscoe, ar",
  },
  {
    postal_code: "25632",
    zip_name: "lyburn, wv",
  },
  {
    postal_code: "43047",
    zip_name: "mingo, oh",
  },
  {
    postal_code: "78072",
    zip_name: "tilden, tx",
  },
  {
    postal_code: "43750",
    zip_name: "kipling, oh",
  },
  {
    postal_code: "15465",
    zip_name: "mount braddock, pa",
  },
  {
    postal_code: "57214",
    zip_name: "badger, sd",
  },
  {
    postal_code: "73944",
    zip_name: "hardesty, ok",
  },
  {
    postal_code: "59242",
    zip_name: "homestead, mt",
  },
  {
    postal_code: "79548",
    zip_name: "rule, tx",
  },
  {
    postal_code: "52220",
    zip_name: "conroy, ia",
  },
  {
    postal_code: "50137",
    zip_name: "killduff, ia",
  },
  {
    postal_code: "25203",
    zip_name: "turtle creek, wv",
  },
  {
    postal_code: "76842",
    zip_name: "fredonia, tx",
  },
  {
    postal_code: "69037",
    zip_name: "max, ne",
  },
  {
    postal_code: "25093",
    zip_name: "gordon, wv",
  },
  {
    postal_code: "58057",
    zip_name: "mcleod, nd",
  },
  {
    postal_code: "51018",
    zip_name: "cushing, ia",
  },
  {
    postal_code: "63950",
    zip_name: "lodi, mo",
  },
  {
    postal_code: "96705",
    zip_name: "eleele, hi",
  },
  {
    postal_code: "64441",
    zip_name: "denver, mo",
  },
  {
    postal_code: "66838",
    zip_name: "burdick, ks",
  },
  {
    postal_code: "16136",
    zip_name: "koppel, pa",
  },
  {
    postal_code: "43519",
    zip_name: "evansport, oh",
  },
  {
    postal_code: "64641",
    zip_name: "galt, mo",
  },
  {
    postal_code: "41226",
    zip_name: "keaton, ky",
  },
  {
    postal_code: "46056",
    zip_name: "markleville, in",
  },
  {
    postal_code: "81146",
    zip_name: "mosca, co",
  },
  {
    postal_code: "84535",
    zip_name: "monticello, ut",
  },
  {
    postal_code: "30740",
    zip_name: "rocky face, ga",
  },
  {
    postal_code: "8056",
    zip_name: "mickleton, nj",
  },
  {
    postal_code: "17323",
    zip_name: "franklintown, pa",
  },
  {
    postal_code: "68725",
    zip_name: "chambers, ne",
  },
  {
    postal_code: "50165",
    zip_name: "millerton, ia",
  },
  {
    postal_code: "4364",
    zip_name: "winthrop, me",
  },
  {
    postal_code: "54888",
    zip_name: "trego, wi",
  },
  {
    postal_code: "76437",
    zip_name: "cisco, tx",
  },
  {
    postal_code: "97138",
    zip_name: "seaside, or",
  },
  {
    postal_code: "6471",
    zip_name: "north branford, ct",
  },
  {
    postal_code: "65453",
    zip_name: "cuba, mo",
  },
  {
    postal_code: "95405",
    zip_name: "santa rosa, ca",
  },
  {
    postal_code: "70582",
    zip_name: "saint martinville, la",
  },
  {
    postal_code: "18509",
    zip_name: "scranton, pa",
  },
  {
    postal_code: "55301",
    zip_name: "albertville, mn",
  },
  {
    postal_code: "48198",
    zip_name: "ypsilanti, mi",
  },
  {
    postal_code: "3743",
    zip_name: "claremont, nh",
  },
  {
    postal_code: "98512",
    zip_name: "olympia, wa",
  },
  {
    postal_code: "40218",
    zip_name: "louisville, ky",
  },
  {
    postal_code: "33801",
    zip_name: "lakeland, fl",
  },
  {
    postal_code: "78223",
    zip_name: "san antonio, tx",
  },
  {
    postal_code: "60446",
    zip_name: "romeoville, il",
  },
  {
    postal_code: "60506",
    zip_name: "aurora, il",
  },
  {
    postal_code: "77469",
    zip_name: "richmond, tx",
  },
  {
    postal_code: "57748",
    zip_name: "howes, sd",
  },
  {
    postal_code: "68964",
    zip_name: "oak, ne",
  },
  {
    postal_code: "61625",
    zip_name: "peoria, il",
  },
  {
    postal_code: "83436",
    zip_name: "newdale, id",
  },
  {
    postal_code: "50593",
    zip_name: "varina, ia",
  },
  {
    postal_code: "77440",
    zip_name: "elmaton, tx",
  },
  {
    postal_code: "57649",
    zip_name: "prairie city, sd",
  },
  {
    postal_code: "68943",
    zip_name: "hardy, ne",
  },
  {
    postal_code: "66955",
    zip_name: "mahaska, ks",
  },
  {
    postal_code: "20771",
    zip_name: "greenbelt, md",
  },
  {
    postal_code: "66775",
    zip_name: "stark, ks",
  },
  {
    postal_code: "13602",
    zip_name: "fort drum, ny",
  },
  {
    postal_code: "71002",
    zip_name: "ashland, la",
  },
  {
    postal_code: "14486",
    zip_name: "linwood, ny",
  },
  {
    postal_code: "97909",
    zip_name: "jamieson, or",
  },
  {
    postal_code: "67521",
    zip_name: "brownell, ks",
  },
  {
    postal_code: "66412",
    zip_name: "bremen, ks",
  },
  {
    postal_code: "67041",
    zip_name: "elbing, ks",
  },
  {
    postal_code: "59467",
    zip_name: "pendroy, mt",
  },
  {
    postal_code: "51015",
    zip_name: "climbing hill, ia",
  },
  {
    postal_code: "68945",
    zip_name: "heartwell, ne",
  },
  {
    postal_code: "57787",
    zip_name: "union center, sd",
  },
  {
    postal_code: "58345",
    zip_name: "lawton, nd",
  },
  {
    postal_code: "16724",
    zip_name: "crosby, pa",
  },
  {
    postal_code: "59461",
    zip_name: "lothair, mt",
  },
  {
    postal_code: "92328",
    zip_name: "death valley, ca",
  },
  {
    postal_code: "99136",
    zip_name: "hay, wa",
  },
  {
    postal_code: "51051",
    zip_name: "rodney, ia",
  },
  {
    postal_code: "73931",
    zip_name: "balko, ok",
  },
  {
    postal_code: "41643",
    zip_name: "lackey, ky",
  },
  {
    postal_code: "85328",
    zip_name: "cibola, az",
  },
  {
    postal_code: "26152",
    zip_name: "munday, wv",
  },
  {
    postal_code: "68452",
    zip_name: "ong, ne",
  },
  {
    postal_code: "76828",
    zip_name: "burkett, tx",
  },
  {
    postal_code: "67733",
    zip_name: "edson, ks",
  },
  {
    postal_code: "99549",
    zip_name: "port heiden, ak",
  },
  {
    postal_code: "99607",
    zip_name: "kalskag, ak",
  },
  {
    postal_code: "28537",
    zip_name: "hobucken, nc",
  },
  {
    postal_code: "41547",
    zip_name: "majestic, ky",
  },
  {
    postal_code: "69219",
    zip_name: "nenzel, ne",
  },
  {
    postal_code: "68309",
    zip_name: "barneston, ne",
  },
  {
    postal_code: "14545",
    zip_name: "scottsburg, ny",
  },
  {
    postal_code: "87322",
    zip_name: "rehoboth, nm",
  },
  {
    postal_code: "24828",
    zip_name: "davy, wv",
  },
  {
    postal_code: "57520",
    zip_name: "agar, sd",
  },
  {
    postal_code: "41621",
    zip_name: "dwale, ky",
  },
  {
    postal_code: "57544",
    zip_name: "kennebec, sd",
  },
  {
    postal_code: "14585",
    zip_name: "west bloomfield, ny",
  },
  {
    postal_code: "26617",
    zip_name: "dille, wv",
  },
  {
    postal_code: "59521",
    zip_name: "box elder, mt",
  },
  {
    postal_code: "58339",
    zip_name: "hansboro, nd",
  },
  {
    postal_code: "73855",
    zip_name: "rosston, ok",
  },
  {
    postal_code: "73571",
    zip_name: "vinson, ok",
  },
  {
    postal_code: "50545",
    zip_name: "hardy, ia",
  },
  {
    postal_code: "66017",
    zip_name: "denton, ks",
  },
  {
    postal_code: "72048",
    zip_name: "ethel, ar",
  },
  {
    postal_code: "24855",
    zip_name: "kyle, wv",
  },
  {
    postal_code: "15416",
    zip_name: "brownfield, pa",
  },
  {
    postal_code: "99626",
    zip_name: "lower kalskag, ak",
  },
  {
    postal_code: "45310",
    zip_name: "burkettsville, oh",
  },
  {
    postal_code: "57585",
    zip_name: "wood, sd",
  },
  {
    postal_code: "99768",
    zip_name: "ruby, ak",
  },
  {
    postal_code: "24829",
    zip_name: "eckman, wv",
  },
  {
    postal_code: "99926",
    zip_name: "metlakatla, ak",
  },
  {
    postal_code: "59274",
    zip_name: "vida, mt",
  },
  {
    postal_code: "57521",
    zip_name: "belvidere, sd",
  },
  {
    postal_code: "74830",
    zip_name: "bowlegs, ok",
  },
  {
    postal_code: "87746",
    zip_name: "solano, nm",
  },
  {
    postal_code: "82630",
    zip_name: "arminto, wy",
  },
  {
    postal_code: "52074",
    zip_name: "spragueville, ia",
  },
  {
    postal_code: "41825",
    zip_name: "jackhorn, ky",
  },
  {
    postal_code: "72313",
    zip_name: "bassett, ar",
  },
  {
    postal_code: "52036",
    zip_name: "delaware, ia",
  },
  {
    postal_code: "25857",
    zip_name: "josephine, wv",
  },
  {
    postal_code: "88414",
    zip_name: "capulin, nm",
  },
  {
    postal_code: "76955",
    zip_name: "vancourt, tx",
  },
  {
    postal_code: "41729",
    zip_name: "combs, ky",
  },
  {
    postal_code: "68441",
    zip_name: "steinauer, ne",
  },
  {
    postal_code: "16941",
    zip_name: "genesee, pa",
  },
  {
    postal_code: "24815",
    zip_name: "berwind, wv",
  },
  {
    postal_code: "58001",
    zip_name: "abercrombie, nd",
  },
  {
    postal_code: "24862",
    zip_name: "mohawk, wv",
  },
  {
    postal_code: "47647",
    zip_name: "buckskin, in",
  },
  {
    postal_code: "41386",
    zip_name: "vincent, ky",
  },
  {
    postal_code: "71952",
    zip_name: "langley, ar",
  },
  {
    postal_code: "52548",
    zip_name: "chillicothe, ia",
  },
  {
    postal_code: "59077",
    zip_name: "sand springs, mt",
  },
  {
    postal_code: "15488",
    zip_name: "waltersburg, pa",
  },
  {
    postal_code: "68972",
    zip_name: "riverton, ne",
  },
  {
    postal_code: "51243",
    zip_name: "little rock, ia",
  },
  {
    postal_code: "57236",
    zip_name: "garden city, sd",
  },
  {
    postal_code: "73960",
    zip_name: "texhoma, tx",
  },
  {
    postal_code: "84732",
    zip_name: "greenwich, ut",
  },
  {
    postal_code: "47035",
    zip_name: "new trenton, in",
  },
  {
    postal_code: "3291",
    zip_name: "west nottingham, nh",
  },
  {
    postal_code: "65344",
    zip_name: "miami, mo",
  },
  {
    postal_code: "36538",
    zip_name: "frankville, al",
  },
  {
    postal_code: "71831",
    zip_name: "columbus, ar",
  },
  {
    postal_code: "67150",
    zip_name: "waldron, ks",
  },
  {
    postal_code: "50608",
    zip_name: "austinville, ia",
  },
  {
    postal_code: "40982",
    zip_name: "scalf, ky",
  },
  {
    postal_code: "33039",
    zip_name: "homestead, fl",
  },
  {
    postal_code: "42131",
    zip_name: "etoile, ky",
  },
  {
    postal_code: "49848",
    zip_name: "ingalls, mi",
  },
  {
    postal_code: "65623",
    zip_name: "butterfield, mo",
  },
  {
    postal_code: "16670",
    zip_name: "queen, pa",
  },
  {
    postal_code: "85535",
    zip_name: "eden, az",
  },
  {
    postal_code: "99606",
    zip_name: "iliamna, ak",
  },
  {
    postal_code: "66428",
    zip_name: "goff, ks",
  },
  {
    postal_code: "50862",
    zip_name: "sharpsburg, ia",
  },
  {
    postal_code: "52771",
    zip_name: "teeds grove, ia",
  },
  {
    postal_code: "79016",
    zip_name: "canyon, tx",
  },
  {
    postal_code: "42047",
    zip_name: "hampton, ky",
  },
  {
    postal_code: "76831",
    zip_name: "castell, tx",
  },
  {
    postal_code: "51244",
    zip_name: "matlock, ia",
  },
  {
    postal_code: "94535",
    zip_name: "travis afb, ca",
  },
  {
    postal_code: "68751",
    zip_name: "maskell, ne",
  },
  {
    postal_code: "4013",
    zip_name: "bustins island, me",
  },
  {
    postal_code: "59240",
    zip_name: "glentana, mt",
  },
  {
    postal_code: "68859",
    zip_name: "north loup, ne",
  },
  {
    postal_code: "72038",
    zip_name: "crocketts bluff, ar",
  },
  {
    postal_code: "61750",
    zip_name: "lane, il",
  },
  {
    postal_code: "69353",
    zip_name: "mcgrew, ne",
  },
  {
    postal_code: "57576",
    zip_name: "vivian, sd",
  },
  {
    postal_code: "68734",
    zip_name: "emmet, ne",
  },
  {
    postal_code: "87017",
    zip_name: "gallina, nm",
  },
  {
    postal_code: "40827",
    zip_name: "essie, ky",
  },
  {
    postal_code: "15761",
    zip_name: "mentcle, pa",
  },
  {
    postal_code: "16114",
    zip_name: "clarks mills, pa",
  },
  {
    postal_code: "56646",
    zip_name: "gully, mn",
  },
  {
    postal_code: "68071",
    zip_name: "winnebago, ne",
  },
  {
    postal_code: "55111",
    zip_name: "saint paul, mn",
  },
  {
    postal_code: "50573",
    zip_name: "plover, ia",
  },
  {
    postal_code: "62809",
    zip_name: "barnhill, il",
  },
  {
    postal_code: "15776",
    zip_name: "sprankle mills, pa",
  },
  {
    postal_code: "93624",
    zip_name: "five points, ca",
  },
  {
    postal_code: "58311",
    zip_name: "alsen, nd",
  },
  {
    postal_code: "15366",
    zip_name: "van voorhis, pa",
  },
  {
    postal_code: "24887",
    zip_name: "switchback, wv",
  },
  {
    postal_code: "58219",
    zip_name: "caledonia, nd",
  },
  {
    postal_code: "69212",
    zip_name: "crookston, ne",
  },
  {
    postal_code: "76459",
    zip_name: "jermyn, tx",
  },
  {
    postal_code: "67850",
    zip_name: "healy, ks",
  },
  {
    postal_code: "62833",
    zip_name: "ellery, il",
  },
  {
    postal_code: "25915",
    zip_name: "rhodell, wv",
  },
  {
    postal_code: "38721",
    zip_name: "anguilla, ms",
  },
  {
    postal_code: "25608",
    zip_name: "baisden, wv",
  },
  {
    postal_code: "25160",
    zip_name: "pond gap, wv",
  },
  {
    postal_code: "11359",
    zip_name: "bayside, ny",
  },
  {
    postal_code: "62851",
    zip_name: "keenes, il",
  },
  {
    postal_code: "82434",
    zip_name: "otto, wy",
  },
  {
    postal_code: "79344",
    zip_name: "maple, tx",
  },
  {
    postal_code: "56553",
    zip_name: "kent, mn",
  },
  {
    postal_code: "63737",
    zip_name: "brazeau, mo",
  },
  {
    postal_code: "20656",
    zip_name: "loveville, md",
  },
  {
    postal_code: "58058",
    zip_name: "mantador, nd",
  },
  {
    postal_code: "50835",
    zip_name: "benton, ia",
  },
  {
    postal_code: "59062",
    zip_name: "otter, mt",
  },
  {
    postal_code: "57213",
    zip_name: "astoria, sd",
  },
  {
    postal_code: "58216",
    zip_name: "bathgate, nd",
  },
  {
    postal_code: "26056",
    zip_name: "new manchester, wv",
  },
  {
    postal_code: "79111",
    zip_name: "amarillo, tx",
  },
  {
    postal_code: "33199",
    zip_name: "miami, fl",
  },
  {
    postal_code: "63432",
    zip_name: "arbela, mo",
  },
  {
    postal_code: "76491",
    zip_name: "woodson, tx",
  },
  {
    postal_code: "57061",
    zip_name: "sinai, sd",
  },
  {
    postal_code: "24239",
    zip_name: "davenport, va",
  },
  {
    postal_code: "88436",
    zip_name: "sedan, nm",
  },
  {
    postal_code: "81129",
    zip_name: "conejos, co",
  },
  {
    postal_code: "72379",
    zip_name: "snow lake, ar",
  },
  {
    postal_code: "72166",
    zip_name: "tichnor, ar",
  },
  {
    postal_code: "57750",
    zip_name: "interior, sd",
  },
  {
    postal_code: "58224",
    zip_name: "dahlen, nd",
  },
  {
    postal_code: "79056",
    zip_name: "lipscomb, tx",
  },
  {
    postal_code: "23943",
    zip_name: "hampden sydney, va",
  },
  {
    postal_code: "68326",
    zip_name: "carleton, ne",
  },
  {
    postal_code: "79738",
    zip_name: "gail, tx",
  },
  {
    postal_code: "88264",
    zip_name: "maljamar, nm",
  },
  {
    postal_code: "44659",
    zip_name: "mount eaton, oh",
  },
  {
    postal_code: "58440",
    zip_name: "fredonia, nd",
  },
  {
    postal_code: "31315",
    zip_name: "fort stewart, ga",
  },
  {
    postal_code: "58415",
    zip_name: "berlin, nd",
  },
  {
    postal_code: "23709",
    zip_name: "portsmouth, va",
  },
  {
    postal_code: "69216",
    zip_name: "kilgore, ne",
  },
  {
    postal_code: "68622",
    zip_name: "bartlett, ne",
  },
  {
    postal_code: "68923",
    zip_name: "atlanta, ne",
  },
  {
    postal_code: "63450",
    zip_name: "lentner, mo",
  },
  {
    postal_code: "72341",
    zip_name: "haynes, ar",
  },
  {
    postal_code: "89409",
    zip_name: "gabbs, nv",
  },
  {
    postal_code: "15730",
    zip_name: "coolspring, pa",
  },
  {
    postal_code: "50837",
    zip_name: "bridgewater, ia",
  },
  {
    postal_code: "67675",
    zip_name: "woodston, ks",
  },
  {
    postal_code: "99703",
    zip_name: "fort wainwright, ak",
  },
  {
    postal_code: "38731",
    zip_name: "chatham, ms",
  },
  {
    postal_code: "92310",
    zip_name: "fort irwin, ca",
  },
  {
    postal_code: "67647",
    zip_name: "long island, ks",
  },
  {
    postal_code: "73503",
    zip_name: "fort sill, ok",
  },
  {
    postal_code: "78021",
    zip_name: "fowlerton, tx",
  },
  {
    postal_code: "99734",
    zip_name: "prudhoe bay, ak",
  },
  {
    postal_code: "88112",
    zip_name: "broadview, nm",
  },
  {
    postal_code: "41764",
    zip_name: "smilax, ky",
  },
  {
    postal_code: "29836",
    zip_name: "martin, sc",
  },
  {
    postal_code: "26524",
    zip_name: "bretz, wv",
  },
  {
    postal_code: "28633",
    zip_name: "lenoir, nc",
  },
  {
    postal_code: "30905",
    zip_name: "augusta, ga",
  },
  {
    postal_code: "41743",
    zip_name: "fisty, ky",
  },
  {
    postal_code: "81045",
    zip_name: "haswell, co",
  },
  {
    postal_code: "31409",
    zip_name: "savannah, ga",
  },
  {
    postal_code: "6702",
    zip_name: "waterbury, ct",
  },
  {
    postal_code: "67629",
    zip_name: "clayton, ks",
  },
  {
    postal_code: "68327",
    zip_name: "chester, ne",
  },
  {
    postal_code: "88126",
    zip_name: "pep, nm",
  },
  {
    postal_code: "41848",
    zip_name: "roxana, ky",
  },
  {
    postal_code: "58386",
    zip_name: "york, nd",
  },
  {
    postal_code: "77986",
    zip_name: "sublime, tx",
  },
  {
    postal_code: "41146",
    zip_name: "hitchins, ky",
  },
  {
    postal_code: "76373",
    zip_name: "oklaunion, tx",
  },
  {
    postal_code: "11042",
    zip_name: "new hyde park, ny",
  },
  {
    postal_code: "41725",
    zip_name: "carrie, ky",
  },
  {
    postal_code: "50623",
    zip_name: "dewar, ia",
  },
  {
    postal_code: "72139",
    zip_name: "russell, ar",
  },
  {
    postal_code: "50551",
    zip_name: "jolley, ia",
  },
  {
    postal_code: "24657",
    zip_name: "whitewood, va",
  },
  {
    postal_code: "51460",
    zip_name: "ricketts, ia",
  },
  {
    postal_code: "25214",
    zip_name: "winifrede, wv",
  },
  {
    postal_code: "73770",
    zip_name: "southard, ok",
  },
  {
    postal_code: "41760",
    zip_name: "scuddy, ky",
  },
  {
    postal_code: "76061",
    zip_name: "lillian, tx",
  },
  {
    postal_code: "29425",
    zip_name: "charleston, sc",
  },
  {
    postal_code: "56720",
    zip_name: "donaldson, mn",
  },
  {
    postal_code: "42338",
    zip_name: "dundee, ky",
  },
  {
    postal_code: "41160",
    zip_name: "mazie, ky",
  },
  {
    postal_code: "80746",
    zip_name: "paoli, co",
  },
  {
    postal_code: "88419",
    zip_name: "folsom, nm",
  },
  {
    postal_code: "59244",
    zip_name: "larslan, mt",
  },
  {
    postal_code: "79324",
    zip_name: "enochs, tx",
  },
  {
    postal_code: "76940",
    zip_name: "mereta, tx",
  },
  {
    postal_code: "56210",
    zip_name: "barry, mn",
  },
  {
    postal_code: "2918",
    zip_name: "providence, ri",
  },
  {
    postal_code: "57371",
    zip_name: "ree heights, sd",
  },
  {
    postal_code: "28308",
    zip_name: "pope army airfield, nc",
  },
  {
    postal_code: "56146",
    zip_name: "kanaranzi, mn",
  },
  {
    postal_code: "58477",
    zip_name: "regan, nd",
  },
  {
    postal_code: "24217",
    zip_name: "bee, va",
  },
  {
    postal_code: "80045",
    zip_name: "aurora, co",
  },
  {
    postal_code: "57767",
    zip_name: "owanka, sd",
  },
  {
    postal_code: "57466",
    zip_name: "onaka, sd",
  },
  {
    postal_code: "57258",
    zip_name: "raymond, sd",
  },
  {
    postal_code: "38542",
    zip_name: "allred, tn",
  },
  {
    postal_code: "40939",
    zip_name: "fourmile, ky",
  },
  {
    postal_code: "74651",
    zip_name: "red rock, ok",
  },
  {
    postal_code: "71401",
    zip_name: "aimwell, la",
  },
  {
    postal_code: "92134",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "66541",
    zip_name: "summerfield, ks",
  },
  {
    postal_code: "99772",
    zip_name: "shishmaref, ak",
  },
  {
    postal_code: "95318",
    zip_name: "el portal, ca",
  },
  {
    postal_code: "85724",
    zip_name: "tucson, az",
  },
  {
    postal_code: "59625",
    zip_name: "helena, mt",
  },
  {
    postal_code: "71459",
    zip_name: "fort polk, la",
  },
  {
    postal_code: "97712",
    zip_name: "brothers, or",
  },
  {
    postal_code: "88040",
    zip_name: "hachita, nm",
  },
  {
    postal_code: "79607",
    zip_name: "dyess afb, tx",
  },
  {
    postal_code: "97761",
    zip_name: "warm springs, or",
  },
  {
    postal_code: "30069",
    zip_name: "marietta, ga",
  },
  {
    postal_code: "68062",
    zip_name: "thurston, ne",
  },
  {
    postal_code: "26561",
    zip_name: "big run, wv",
  },
  {
    postal_code: "99155",
    zip_name: "nespelem, wa",
  },
  {
    postal_code: "26638",
    zip_name: "shock, wv",
  },
  {
    postal_code: "17076",
    zip_name: "oakland mills, pa",
  },
  {
    postal_code: "29152",
    zip_name: "shaw afb, sc",
  },
  {
    postal_code: "93437",
    zip_name: "lompoc, ca",
  },
  {
    postal_code: "73638",
    zip_name: "crawford, ok",
  },
  {
    postal_code: "58643",
    zip_name: "marmarth, nd",
  },
  {
    postal_code: "25654",
    zip_name: "yolyn, wv",
  },
  {
    postal_code: "55605",
    zip_name: "grand portage, mn",
  },
  {
    postal_code: "67476",
    zip_name: "roxbury, ks",
  },
  {
    postal_code: "89420",
    zip_name: "luning, nv",
  },
  {
    postal_code: "67018",
    zip_name: "bluff city, ks",
  },
  {
    postal_code: "97044",
    zip_name: "odell, or",
  },
  {
    postal_code: "91330",
    zip_name: "northridge, ca",
  },
  {
    postal_code: "30332",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "89022",
    zip_name: "manhattan, nv",
  },
  {
    postal_code: "99613",
    zip_name: "king salmon, ak",
  },
  {
    postal_code: "81087",
    zip_name: "vilas, co",
  },
  {
    postal_code: "35457",
    zip_name: "echola, al",
  },
  {
    postal_code: "31207",
    zip_name: "macon, ga",
  },
  {
    postal_code: "59025",
    zip_name: "decker, mt",
  },
  {
    postal_code: "88411",
    zip_name: "bard, nm",
  },
  {
    postal_code: "66942",
    zip_name: "formoso, ks",
  },
  {
    postal_code: "58431",
    zip_name: "dickey, nd",
  },
  {
    postal_code: "36722",
    zip_name: "arlington, al",
  },
  {
    postal_code: "92135",
    zip_name: "san diego, ca",
  },
  {
    postal_code: "48242",
    zip_name: "detroit, mi",
  },
  {
    postal_code: "52562",
    zip_name: "hayesville, ia",
  },
  {
    postal_code: "41607",
    zip_name: "blue river, ky",
  },
  {
    postal_code: "81231",
    zip_name: "gunnison, co",
  },
  {
    postal_code: "99745",
    zip_name: "hughes, ak",
  },
  {
    postal_code: "46502",
    zip_name: "atwood, in",
  },
  {
    postal_code: "15953",
    zip_name: "seanor, pa",
  },
  {
    postal_code: "99636",
    zip_name: "new stuyahok, ak",
  },
  {
    postal_code: "57477",
    zip_name: "turton, sd",
  },
  {
    postal_code: "59022",
    zip_name: "crow agency, mt",
  },
  {
    postal_code: "97908",
    zip_name: "ironside, or",
  },
  {
    postal_code: "28547",
    zip_name: "camp lejeune, nc",
  },
  {
    postal_code: "69340",
    zip_name: "ellsworth, ne",
  },
  {
    postal_code: "71864",
    zip_name: "willisville, ar",
  },
  {
    postal_code: "66417",
    zip_name: "corning, ks",
  },
  {
    postal_code: "79240",
    zip_name: "lelia lake, tx",
  },
  {
    postal_code: "79094",
    zip_name: "wayside, tx",
  },
  {
    postal_code: "23511",
    zip_name: "norfolk, va",
  },
  {
    postal_code: "85707",
    zip_name: "tucson, az",
  },
  {
    postal_code: "18084",
    zip_name: "sumneytown, pa",
  },
  {
    postal_code: "61346",
    zip_name: "new bedford, il",
  },
  {
    postal_code: "82227",
    zip_name: "manville, wy",
  },
  {
    postal_code: "82635",
    zip_name: "edgerton, wy",
  },
  {
    postal_code: "33965",
    zip_name: "fort myers, fl",
  },
  {
    postal_code: "68749",
    zip_name: "magnet, ne",
  },
  {
    postal_code: "52348",
    zip_name: "vining, ia",
  },
  {
    postal_code: "50570",
    zip_name: "ottosen, ia",
  },
  {
    postal_code: "58017",
    zip_name: "cogswell, nd",
  },
  {
    postal_code: "66760",
    zip_name: "opolis, ks",
  },
  {
    postal_code: "69039",
    zip_name: "moorefield, ne",
  },
  {
    postal_code: "81071",
    zip_name: "sheridan lake, co",
  },
  {
    postal_code: "21402",
    zip_name: "annapolis, md",
  },
  {
    postal_code: "68445",
    zip_name: "swanton, ne",
  },
  {
    postal_code: "58422",
    zip_name: "cathay, nd",
  },
  {
    postal_code: "57775",
    zip_name: "quinn, sd",
  },
  {
    postal_code: "69167",
    zip_name: "tryon, ne",
  },
  {
    postal_code: "93262",
    zip_name: "sequoia national park, ca",
  },
  {
    postal_code: "58426",
    zip_name: "courtenay, nd",
  },
  {
    postal_code: "15548",
    zip_name: "kantner, pa",
  },
  {
    postal_code: "24823",
    zip_name: "coal mountain, wv",
  },
  {
    postal_code: "87011",
    zip_name: "claunch, nm",
  },
  {
    postal_code: "58325",
    zip_name: "churchs ferry, nd",
  },
  {
    postal_code: "54641",
    zip_name: "mather, wi",
  },
  {
    postal_code: "83648",
    zip_name: "mountain home afb, id",
  },
  {
    postal_code: "91523",
    zip_name: "burbank, ca",
  },
  {
    postal_code: "68755",
    zip_name: "naper, ne",
  },
  {
    postal_code: "30320",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "50427",
    zip_name: "chapin, ia",
  },
  {
    postal_code: "40843",
    zip_name: "holmes mill, ky",
  },
  {
    postal_code: "79537",
    zip_name: "nolan, tx",
  },
  {
    postal_code: "58281",
    zip_name: "wales, nd",
  },
  {
    postal_code: "57656",
    zip_name: "timber lake, sd",
  },
  {
    postal_code: "4737",
    zip_name: "clayton lake, me",
  },
  {
    postal_code: "41812",
    zip_name: "deane, ky",
  },
  {
    postal_code: "57632",
    zip_name: "herreid, sd",
  },
  {
    postal_code: "38928",
    zip_name: "glendora, ms",
  },
  {
    postal_code: "79519",
    zip_name: "goldsboro, tx",
  },
  {
    postal_code: "84728",
    zip_name: "garrison, ut",
  },
  {
    postal_code: "66850",
    zip_name: "elmdale, ks",
  },
  {
    postal_code: "68929",
    zip_name: "bloomington, ne",
  },
  {
    postal_code: "32212",
    zip_name: "jacksonville, fl",
  },
  {
    postal_code: "46971",
    zip_name: "grissom arb, in",
  },
  {
    postal_code: "81029",
    zip_name: "campo, co",
  },
  {
    postal_code: "68330",
    zip_name: "cordova, ne",
  },
  {
    postal_code: "57756",
    zip_name: "manderson, sd",
  },
  {
    postal_code: "2912",
    zip_name: "providence, ri",
  },
  {
    postal_code: "96031",
    zip_name: "forks of salmon, ca",
  },
  {
    postal_code: "25848",
    zip_name: "glen rogers, wv",
  },
  {
    postal_code: "66960",
    zip_name: "narka, ks",
  },
  {
    postal_code: "89054",
    zip_name: "sloan, nv",
  },
  {
    postal_code: "67071",
    zip_name: "lake city, ks",
  },
  {
    postal_code: "79759",
    zip_name: "notrees, tx",
  },
  {
    postal_code: "72329",
    zip_name: "driver, ar",
  },
  {
    postal_code: "21010",
    zip_name: "gunpowder, md",
  },
  {
    postal_code: "58847",
    zip_name: "keene, nd",
  },
  {
    postal_code: "11437",
    zip_name: "jamaica, ny",
  },
  {
    postal_code: "57651",
    zip_name: "reva, sd",
  },
  {
    postal_code: "85329",
    zip_name: "cashion, az",
  },
  {
    postal_code: "24869",
    zip_name: "north spring, wv",
  },
  {
    postal_code: "28542",
    zip_name: "camp lejeune, nc",
  },
  {
    postal_code: "68362",
    zip_name: "gilead, ne",
  },
  {
    postal_code: "30751",
    zip_name: "tennga, ga",
  },
  {
    postal_code: "20670",
    zip_name: "patuxent river, md",
  },
  {
    postal_code: "58757",
    zip_name: "mandaree, nd",
  },
  {
    postal_code: "47580",
    zip_name: "schnellville, in",
  },
  {
    postal_code: "28310",
    zip_name: "fort bragg, nc",
  },
  {
    postal_code: "96863",
    zip_name: "mcbh kaneohe bay, hi",
  },
  {
    postal_code: "33394",
    zip_name: "fort lauderdale, fl",
  },
  {
    postal_code: "73851",
    zip_name: "may, ok",
  },
  {
    postal_code: "38047",
    zip_name: "lenox, tn",
  },
  {
    postal_code: "72465",
    zip_name: "sedgwick, ar",
  },
  {
    postal_code: "89165",
    zip_name: "las vegas, nv",
  },
  {
    postal_code: "57251",
    zip_name: "marvin, sd",
  },
  {
    postal_code: "58239",
    zip_name: "hannah, nd",
  },
  {
    postal_code: "68719",
    zip_name: "bristow, ne",
  },
  {
    postal_code: "6269",
    zip_name: "storrs mansfield, ct",
  },
  {
    postal_code: "98364",
    zip_name: "port gamble, wa",
  },
  {
    postal_code: "30322",
    zip_name: "atlanta, ga",
  },
  {
    postal_code: "59339",
    zip_name: "lindsay, mt",
  },
  {
    postal_code: "97312",
    zip_name: "salem, or",
  },
  {
    postal_code: "48870",
    zip_name: "palo, mi",
  },
  {
    postal_code: "59276",
    zip_name: "whitetail, mt",
  },
  {
    postal_code: "32925",
    zip_name: "patrick afb, fl",
  },
  {
    postal_code: "24724",
    zip_name: "freeman, wv",
  },
  {
    postal_code: "58007",
    zip_name: "ayr, nd",
  },
  {
    postal_code: "57537",
    zip_name: "hayes, sd",
  },
  {
    postal_code: "78385",
    zip_name: "sarita, tx",
  },
  {
    postal_code: "99922",
    zip_name: "hydaburg, ak",
  },
  {
    postal_code: "15020",
    zip_name: "bunola, pa",
  },
  {
    postal_code: "24882",
    zip_name: "simon, wv",
  },
  {
    postal_code: "58065",
    zip_name: "pillsbury, nd",
  },
  {
    postal_code: "82638",
    zip_name: "hiland, wy",
  },
  {
    postal_code: "80225",
    zip_name: "denver, co",
  },
  {
    postal_code: "57531",
    zip_name: "draper, sd",
  },
  {
    postal_code: "56591",
    zip_name: "white earth, mn",
  },
  {
    postal_code: "1063",
    zip_name: "northampton, ma",
  },
  {
    postal_code: "8544",
    zip_name: "princeton, nj",
  },
  {
    postal_code: "80914",
    zip_name: "colorado springs, co",
  },
  {
    postal_code: "85550",
    zip_name: "san carlos, az",
  },
  {
    postal_code: "26667",
    zip_name: "drennen, wv",
  },
  {
    postal_code: "65409",
    zip_name: "rolla, mo",
  },
  {
    postal_code: "29634",
    zip_name: "clemson, sc",
  },
  {
    postal_code: "57716",
    zip_name: "batesland, sd",
  },
  {
    postal_code: "67659",
    zip_name: "penokee, ks",
  },
  {
    postal_code: "84322",
    zip_name: "logan, ut",
  },
  {
    postal_code: "32815",
    zip_name: "orlando, fl",
  },
  {
    postal_code: "41364",
    zip_name: "ricetown, ky",
  },
  {
    postal_code: "99011",
    zip_name: "fairchild air force base, wa",
  },
  {
    postal_code: "45897",
    zip_name: "williamstown, oh",
  },
  {
    postal_code: "64654",
    zip_name: "lock springs, mo",
  },
  {
    postal_code: "1380",
    zip_name: "wendell depot, ma",
  },
  {
    postal_code: "7495",
    zip_name: "mahwah, nj",
  },
  {
    postal_code: "23884",
    zip_name: "sussex, va",
  },
  {
    postal_code: "23691",
    zip_name: "yorktown, va",
  },
  {
    postal_code: "63466",
    zip_name: "saint patrick, mo",
  },
  {
    postal_code: "69354",
    zip_name: "marsland, ne",
  },
  {
    postal_code: "98522",
    zip_name: null,
  },
  {
    postal_code: "18980",
    zip_name: null,
  },
  {
    postal_code: "99335",
    zip_name: null,
  },
  {
    postal_code: "7979",
    zip_name: null,
  },
  {
    postal_code: "74034",
    zip_name: null,
  },
  {
    postal_code: "29648",
    zip_name: null,
  },
  {
    postal_code: "8808",
    zip_name: null,
  },
  {
    postal_code: "96092",
    zip_name: null,
  },
  {
    postal_code: "48816",
    zip_name: null,
  },
  {
    postal_code: "18916",
    zip_name: null,
  },
  {
    postal_code: "70575",
    zip_name: null,
  },
  {
    postal_code: "24738",
    zip_name: null,
  },
  {
    postal_code: "99732",
    zip_name: null,
  },
  {
    postal_code: "44862",
    zip_name: null,
  },
  {
    postal_code: "36513",
    zip_name: null,
  },
  {
    postal_code: "44687",
    zip_name: null,
  },
  {
    postal_code: "74345",
    zip_name: null,
  },
  {
    postal_code: "39463",
    zip_name: null,
  },
  {
    postal_code: "17072",
    zip_name: null,
  },
  {
    postal_code: "25851",
    zip_name: null,
  },
  {
    postal_code: "76623",
    zip_name: null,
  },
  {
    postal_code: "38820",
    zip_name: null,
  },
  {
    postal_code: "18653",
    zip_name: null,
  },
  {
    postal_code: "71253",
    zip_name: null,
  },
  {
    postal_code: "76685",
    zip_name: null,
  },
  {
    postal_code: "99694",
    zip_name: null,
  },
  {
    postal_code: "45115",
    zip_name: null,
  },
  {
    postal_code: "72615",
    zip_name: null,
  },
  {
    postal_code: "97528",
    zip_name: null,
  },
  {
    postal_code: "37364",
    zip_name: null,
  },
  {
    postal_code: "44693",
    zip_name: null,
  },
  {
    postal_code: "49826",
    zip_name: null,
  },
  {
    postal_code: "72526",
    zip_name: null,
  },
  {
    postal_code: "36470",
    zip_name: null,
  },
  {
    postal_code: "14461",
    zip_name: null,
  },
  {
    postal_code: "7820",
    zip_name: null,
  },
  {
    postal_code: "28564",
    zip_name: null,
  },
  {
    postal_code: "54976",
    zip_name: null,
  },
  {
    postal_code: "25879",
    zip_name: null,
  },
  {
    postal_code: "45837",
    zip_name: null,
  },
  {
    postal_code: "18413",
    zip_name: null,
  },
  {
    postal_code: "31902",
    zip_name: null,
  },
  {
    postal_code: "42374",
    zip_name: null,
  },
  {
    postal_code: "43216",
    zip_name: null,
  },
  {
    postal_code: "42332",
    zip_name: null,
  },
  {
    postal_code: "45783",
    zip_name: null,
  },
  {
    postal_code: "24826",
    zip_name: null,
  },
  {
    postal_code: "12544",
    zip_name: null,
  },
  {
    postal_code: "22442",
    zip_name: null,
  },
  {
    postal_code: "25696",
    zip_name: null,
  },
  {
    postal_code: "5481",
    zip_name: null,
  },
  {
    postal_code: "95424",
    zip_name: null,
  },
  {
    postal_code: "98527",
    zip_name: null,
  },
  {
    postal_code: "18357",
    zip_name: null,
  },
  {
    postal_code: "45158",
    zip_name: null,
  },
  {
    postal_code: "72059",
    zip_name: null,
  },
  {
    postal_code: "33152",
    zip_name: null,
  },
  {
    postal_code: "28375",
    zip_name: null,
  },
  {
    postal_code: "4926",
    zip_name: null,
  },
  {
    postal_code: "78670",
    zip_name: null,
  },
  {
    postal_code: "13678",
    zip_name: null,
  },
  {
    postal_code: "46995",
    zip_name: null,
  },
  {
    postal_code: "62519",
    zip_name: null,
  },
  {
    postal_code: "8885",
    zip_name: null,
  },
  {
    postal_code: "99790",
    zip_name: null,
  },
  {
    postal_code: "49722",
    zip_name: null,
  },
  {
    postal_code: "29846",
    zip_name: null,
  },
  {
    postal_code: "7938",
    zip_name: null,
  },
  {
    postal_code: "77334",
    zip_name: null,
  },
  {
    postal_code: "13362",
    zip_name: null,
  },
  {
    postal_code: "14745",
    zip_name: null,
  },
  {
    postal_code: "75711",
    zip_name: null,
  },
  {
    postal_code: "96751",
    zip_name: null,
  },
  {
    postal_code: "10540",
    zip_name: null,
  },
  {
    postal_code: "8870",
    zip_name: null,
  },
  {
    postal_code: "95559",
    zip_name: null,
  },
  {
    postal_code: "23408",
    zip_name: null,
  },
  {
    postal_code: "77454",
    zip_name: null,
  },
  {
    postal_code: "85536",
    zip_name: null,
  },
  {
    postal_code: "13352",
    zip_name: null,
  },
  {
    postal_code: "17310",
    zip_name: null,
  },
  {
    postal_code: "28788",
    zip_name: null,
  },
  {
    postal_code: "46916",
    zip_name: null,
  },
  {
    postal_code: "17342",
    zip_name: null,
  },
  {
    postal_code: "62663",
    zip_name: null,
  },
  {
    postal_code: "26707",
    zip_name: null,
  },
  {
    postal_code: "74460",
    zip_name: null,
  },
  {
    postal_code: "15930",
    zip_name: null,
  },
  {
    postal_code: "2361",
    zip_name: null,
  },
  {
    postal_code: "25109",
    zip_name: null,
  },
  {
    postal_code: "26671",
    zip_name: null,
  },
  {
    postal_code: "95481",
    zip_name: null,
  },
  {
    postal_code: "12915",
    zip_name: null,
  },
  {
    postal_code: "54464",
    zip_name: null,
  },
  {
    postal_code: "18234",
    zip_name: null,
  },
  {
    postal_code: "70391",
    zip_name: null,
  },
  {
    postal_code: "29002",
    zip_name: null,
  },
  {
    postal_code: "75164",
    zip_name: null,
  },
  {
    postal_code: "74836",
    zip_name: null,
  },
  {
    postal_code: "75780",
    zip_name: null,
  },
  {
    postal_code: "14893",
    zip_name: null,
  },
  {
    postal_code: "29656",
    zip_name: null,
  },
  {
    postal_code: "72475",
    zip_name: null,
  },
  {
    postal_code: "20301",
    zip_name: null,
  },
  {
    postal_code: "18221",
    zip_name: null,
  },
  {
    postal_code: "79785",
    zip_name: null,
  },
  {
    postal_code: "63467",
    zip_name: null,
  },
  {
    postal_code: "2358",
    zip_name: null,
  },
  {
    postal_code: "13305",
    zip_name: null,
  },
  {
    postal_code: "97409",
    zip_name: null,
  },
  {
    postal_code: "3073",
    zip_name: null,
  },
  {
    postal_code: "39151",
    zip_name: null,
  },
  {
    postal_code: "92678",
    zip_name: null,
  },
  {
    postal_code: "21609",
    zip_name: null,
  },
  {
    postal_code: "31333",
    zip_name: null,
  },
  {
    postal_code: "49335",
    zip_name: null,
  },
  {
    postal_code: "75764",
    zip_name: null,
  },
  {
    postal_code: "10105",
    zip_name: null,
  },
  {
    postal_code: "18931",
    zip_name: null,
  },
  {
    postal_code: "43433",
    zip_name: null,
  },
  {
    postal_code: "53962",
    zip_name: null,
  },
  {
    postal_code: "54743",
    zip_name: null,
  },
  {
    postal_code: "21866",
    zip_name: null,
  },
  {
    postal_code: "95430",
    zip_name: null,
  },
  {
    postal_code: "68139",
    zip_name: null,
  },
  {
    postal_code: "72322",
    zip_name: null,
  },
  {
    postal_code: "47831",
    zip_name: null,
  },
  {
    postal_code: "27375",
    zip_name: null,
  },
  {
    postal_code: "27819",
    zip_name: null,
  },
  {
    postal_code: "22116",
    zip_name: null,
  },
  {
    postal_code: "95486",
    zip_name: null,
  },
  {
    postal_code: "32182",
    zip_name: null,
  },
  {
    postal_code: "43722",
    zip_name: null,
  },
  {
    postal_code: "73033",
    zip_name: null,
  },
  {
    postal_code: "37707",
    zip_name: null,
  },
  {
    postal_code: "48627",
    zip_name: null,
  },
  {
    postal_code: "41061",
    zip_name: null,
  },
  {
    postal_code: "30070",
    zip_name: null,
  },
  {
    postal_code: "53234",
    zip_name: null,
  },
  {
    postal_code: "17570",
    zip_name: null,
  },
  {
    postal_code: "83002",
    zip_name: null,
  },
  {
    postal_code: "5633",
    zip_name: null,
  },
  {
    postal_code: "48608",
    zip_name: null,
  },
  {
    postal_code: "18410",
    zip_name: null,
  },
  {
    postal_code: "18935",
    zip_name: null,
  },
  {
    postal_code: "10932",
    zip_name: null,
  },
  {
    postal_code: "53127",
    zip_name: null,
  },
  {
    postal_code: "7880",
    zip_name: null,
  },
  {
    postal_code: "11386",
    zip_name: null,
  },
  {
    postal_code: "77369",
    zip_name: null,
  },
  {
    postal_code: "18946",
    zip_name: null,
  },
  {
    postal_code: "72316",
    zip_name: null,
  },
  {
    postal_code: "17726",
    zip_name: null,
  },
  {
    postal_code: "59771",
    zip_name: null,
  },
  {
    postal_code: "93227",
    zip_name: null,
  },
  {
    postal_code: "39649",
    zip_name: null,
  },
  {
    postal_code: "19421",
    zip_name: null,
  },
  {
    postal_code: "38007",
    zip_name: null,
  },
  {
    postal_code: "87583",
    zip_name: null,
  },
  {
    postal_code: "33945",
    zip_name: null,
  },
  {
    postal_code: "25149",
    zip_name: null,
  },
  {
    postal_code: "34451",
    zip_name: null,
  },
  {
    postal_code: "78592",
    zip_name: null,
  },
  {
    postal_code: "62256",
    zip_name: null,
  },
  {
    postal_code: "13758",
    zip_name: null,
  },
  {
    postal_code: "76370",
    zip_name: null,
  },
  {
    postal_code: "44088",
    zip_name: null,
  },
  {
    postal_code: "14130",
    zip_name: null,
  },
  {
    postal_code: "97491",
    zip_name: null,
  },
  {
    postal_code: "98554",
    zip_name: null,
  },
  {
    postal_code: "44242",
    zip_name: null,
  },
  {
    postal_code: "73555",
    zip_name: null,
  },
  {
    postal_code: "78074",
    zip_name: null,
  },
  {
    postal_code: "44881",
    zip_name: null,
  },
  {
    postal_code: "22160",
    zip_name: null,
  },
  {
    postal_code: "44874",
    zip_name: null,
  },
  {
    postal_code: "54215",
    zip_name: null,
  },
  {
    postal_code: "65675",
    zip_name: null,
  },
  {
    postal_code: "32633",
    zip_name: null,
  },
  {
    postal_code: "16361",
    zip_name: null,
  },
  {
    postal_code: "79710",
    zip_name: null,
  },
  {
    postal_code: "8038",
    zip_name: null,
  },
  {
    postal_code: "53159",
    zip_name: null,
  },
  {
    postal_code: "70523",
    zip_name: null,
  },
  {
    postal_code: "78502",
    zip_name: null,
  },
  {
    postal_code: "74947",
    zip_name: null,
  },
  {
    postal_code: "45624",
    zip_name: null,
  },
  {
    postal_code: "24533",
    zip_name: null,
  },
  {
    postal_code: "96160",
    zip_name: null,
  },
  {
    postal_code: "29921",
    zip_name: null,
  },
  {
    postal_code: "79091",
    zip_name: null,
  },
  {
    postal_code: "76686",
    zip_name: null,
  },
  {
    postal_code: "74535",
    zip_name: null,
  },
  {
    postal_code: "1614",
    zip_name: null,
  },
  {
    postal_code: "23450",
    zip_name: null,
  },
  {
    postal_code: "35119",
    zip_name: null,
  },
  {
    postal_code: "3749",
    zip_name: null,
  },
  {
    postal_code: "13814",
    zip_name: null,
  },
  {
    postal_code: "71410",
    zip_name: null,
  },
  {
    postal_code: "93530",
    zip_name: null,
  },
  {
    postal_code: "84024",
    zip_name: null,
  },
  {
    postal_code: "53157",
    zip_name: null,
  },
  {
    postal_code: "48844",
    zip_name: null,
  },
  {
    postal_code: "33738",
    zip_name: null,
  },
  {
    postal_code: "43226",
    zip_name: null,
  },
  {
    postal_code: "76199",
    zip_name: null,
  },
  {
    postal_code: "14537",
    zip_name: null,
  },
  {
    postal_code: "1438",
    zip_name: null,
  },
  {
    postal_code: "35545",
    zip_name: null,
  },
  {
    postal_code: "19545",
    zip_name: null,
  },
  {
    postal_code: "25942",
    zip_name: null,
  },
  {
    postal_code: "48303",
    zip_name: null,
  },
  {
    postal_code: "73050",
    zip_name: null,
  },
  {
    postal_code: "29519",
    zip_name: null,
  },
  {
    postal_code: "33429",
    zip_name: null,
  },
  {
    postal_code: "53056",
    zip_name: null,
  },
  {
    postal_code: "39552",
    zip_name: null,
  },
  {
    postal_code: "79314",
    zip_name: null,
  },
  {
    postal_code: "65278",
    zip_name: null,
  },
  {
    postal_code: "84605",
    zip_name: null,
  },
  {
    postal_code: "46945",
    zip_name: null,
  },
  {
    postal_code: "74545",
    zip_name: null,
  },
  {
    postal_code: "5838",
    zip_name: null,
  },
  {
    postal_code: "18949",
    zip_name: null,
  },
  {
    postal_code: "17533",
    zip_name: null,
  },
  {
    postal_code: "30665",
    zip_name: null,
  },
  {
    postal_code: "73491",
    zip_name: null,
  },
  {
    postal_code: "24857",
    zip_name: null,
  },
  {
    postal_code: "59013",
    zip_name: null,
  },
  {
    postal_code: "62336",
    zip_name: null,
  },
  {
    postal_code: "61236",
    zip_name: null,
  },
  {
    postal_code: "13645",
    zip_name: null,
  },
  {
    postal_code: "75642",
    zip_name: null,
  },
  {
    postal_code: "49674",
    zip_name: null,
  },
  {
    postal_code: "77960",
    zip_name: null,
  },
  {
    postal_code: "5848",
    zip_name: null,
  },
  {
    postal_code: "27907",
    zip_name: null,
  },
  {
    postal_code: "23341",
    zip_name: null,
  },
  {
    postal_code: "86002",
    zip_name: null,
  },
  {
    postal_code: "8042",
    zip_name: null,
  },
  {
    postal_code: "44860",
    zip_name: null,
  },
  {
    postal_code: "71754",
    zip_name: null,
  },
  {
    postal_code: "78347",
    zip_name: null,
  },
  {
    postal_code: "60926",
    zip_name: null,
  },
  {
    postal_code: "89028",
    zip_name: null,
  },
  {
    postal_code: "4850",
    zip_name: null,
  },
  {
    postal_code: "35631",
    zip_name: null,
  },
  {
    postal_code: "8862",
    zip_name: null,
  },
  {
    postal_code: "28738",
    zip_name: null,
  },
  {
    postal_code: "34230",
    zip_name: null,
  },
  {
    postal_code: "85548",
    zip_name: null,
  },
  {
    postal_code: "21653",
    zip_name: null,
  },
  {
    postal_code: "28755",
    zip_name: null,
  },
  {
    postal_code: "77993",
    zip_name: null,
  },
  {
    postal_code: "23486",
    zip_name: null,
  },
  {
    postal_code: "61426",
    zip_name: null,
  },
  {
    postal_code: "15365",
    zip_name: null,
  },
  {
    postal_code: "30647",
    zip_name: null,
  },
  {
    postal_code: "88054",
    zip_name: null,
  },
  {
    postal_code: "34460",
    zip_name: null,
  },
  {
    postal_code: "6050",
    zip_name: null,
  },
  {
    postal_code: "59702",
    zip_name: null,
  },
  {
    postal_code: "10133",
    zip_name: null,
  },
  {
    postal_code: "8320",
    zip_name: null,
  },
  {
    postal_code: "65038",
    zip_name: null,
  },
  {
    postal_code: "61630",
    zip_name: null,
  },
  {
    postal_code: "27602",
    zip_name: null,
  },
  {
    postal_code: "13845",
    zip_name: null,
  },
  {
    postal_code: "95402",
    zip_name: null,
  },
  {
    postal_code: "21670",
    zip_name: null,
  },
  {
    postal_code: "88263",
    zip_name: null,
  },
  {
    postal_code: "77987",
    zip_name: null,
  },
  {
    postal_code: "1831",
    zip_name: null,
  },
  {
    postal_code: "23441",
    zip_name: null,
  },
  {
    postal_code: "5657",
    zip_name: null,
  },
  {
    postal_code: "75858",
    zip_name: null,
  },
  {
    postal_code: "81602",
    zip_name: null,
  },
  {
    postal_code: "43733",
    zip_name: null,
  },
  {
    postal_code: "73802",
    zip_name: null,
  },
  {
    postal_code: "38760",
    zip_name: null,
  },
  {
    postal_code: "69363",
    zip_name: null,
  },
  {
    postal_code: "29331",
    zip_name: null,
  },
  {
    postal_code: "19516",
    zip_name: null,
  },
  {
    postal_code: "77870",
    zip_name: null,
  },
  {
    postal_code: "6025",
    zip_name: null,
  },
  {
    postal_code: "4056",
    zip_name: null,
  },
  {
    postal_code: "16629",
    zip_name: null,
  },
  {
    postal_code: "47366",
    zip_name: null,
  },
  {
    postal_code: "48908",
    zip_name: null,
  },
  {
    postal_code: "28039",
    zip_name: null,
  },
  {
    postal_code: "14542",
    zip_name: null,
  },
  {
    postal_code: "15752",
    zip_name: null,
  },
  {
    postal_code: "78568",
    zip_name: null,
  },
  {
    postal_code: "70519",
    zip_name: null,
  },
  {
    postal_code: "62266",
    zip_name: null,
  },
  {
    postal_code: "28089",
    zip_name: null,
  },
  {
    postal_code: "95940",
    zip_name: null,
  },
  {
    postal_code: "39062",
    zip_name: null,
  },
  {
    postal_code: "37927",
    zip_name: null,
  },
  {
    postal_code: "75934",
    zip_name: null,
  },
  {
    postal_code: "78952",
    zip_name: null,
  },
  {
    postal_code: "48882",
    zip_name: null,
  },
  {
    postal_code: "17231",
    zip_name: null,
  },
  {
    postal_code: "96765",
    zip_name: null,
  },
  {
    postal_code: "16244",
    zip_name: null,
  },
  {
    postal_code: "16223",
    zip_name: null,
  },
  {
    postal_code: "89422",
    zip_name: null,
  },
  {
    postal_code: "48143",
    zip_name: null,
  },
  {
    postal_code: "88033",
    zip_name: null,
  },
  {
    postal_code: "47870",
    zip_name: null,
  },
  {
    postal_code: "23431",
    zip_name: null,
  },
  {
    postal_code: "78001",
    zip_name: null,
  },
  {
    postal_code: "16681",
    zip_name: null,
  },
  {
    postal_code: "17833",
    zip_name: null,
  },
  {
    postal_code: "62610",
    zip_name: null,
  },
  {
    postal_code: "53148",
    zip_name: null,
  },
  {
    postal_code: "70743",
    zip_name: null,
  },
  {
    postal_code: "73402",
    zip_name: null,
  },
  {
    postal_code: "40536",
    zip_name: null,
  },
  {
    postal_code: "16672",
    zip_name: null,
  },
  {
    postal_code: "30412",
    zip_name: null,
  },
  {
    postal_code: "72115",
    zip_name: null,
  },
  {
    postal_code: "75456",
    zip_name: null,
  },
  {
    postal_code: "4234",
    zip_name: null,
  },
  {
    postal_code: "38644",
    zip_name: null,
  },
  {
    postal_code: "15691",
    zip_name: null,
  },
  {
    postal_code: "43786",
    zip_name: null,
  },
  {
    postal_code: "13121",
    zip_name: null,
  },
  {
    postal_code: "85723",
    zip_name: null,
  },
  {
    postal_code: "17256",
    zip_name: null,
  },
  {
    postal_code: "44901",
    zip_name: null,
  },
  {
    postal_code: "33820",
    zip_name: null,
  },
  {
    postal_code: "18454",
    zip_name: null,
  },
  {
    postal_code: "25630",
    zip_name: null,
  },
  {
    postal_code: "92366",
    zip_name: null,
  },
  {
    postal_code: "60147",
    zip_name: null,
  },
  {
    postal_code: "12040",
    zip_name: null,
  },
  {
    postal_code: "7710",
    zip_name: null,
  },
  {
    postal_code: "12483",
    zip_name: null,
  },
  {
    postal_code: "13747",
    zip_name: null,
  },
  {
    postal_code: "7846",
    zip_name: null,
  },
  {
    postal_code: "28661",
    zip_name: null,
  },
  {
    postal_code: "4855",
    zip_name: null,
  },
  {
    postal_code: "23183",
    zip_name: null,
  },
  {
    postal_code: "52075",
    zip_name: null,
  },
  {
    postal_code: "25614",
    zip_name: null,
  },
  {
    postal_code: "54645",
    zip_name: null,
  },
  {
    postal_code: "40955",
    zip_name: null,
  },
  {
    postal_code: "47966",
    zip_name: null,
  },
  {
    postal_code: "14529",
    zip_name: null,
  },
  {
    postal_code: "13794",
    zip_name: null,
  },
  {
    postal_code: "32936",
    zip_name: null,
  },
  {
    postal_code: "17779",
    zip_name: null,
  },
  {
    postal_code: "54324",
    zip_name: null,
  },
  {
    postal_code: "40166",
    zip_name: null,
  },
  {
    postal_code: "62852",
    zip_name: null,
  },
  {
    postal_code: "70640",
    zip_name: null,
  },
  {
    postal_code: "62273",
    zip_name: null,
  },
  {
    postal_code: "18105",
    zip_name: null,
  },
  {
    postal_code: "2344",
    zip_name: null,
  },
  {
    postal_code: "37864",
    zip_name: null,
  },
  {
    postal_code: "87543",
    zip_name: null,
  },
  {
    postal_code: "72391",
    zip_name: null,
  },
  {
    postal_code: "60939",
    zip_name: null,
  },
  {
    postal_code: "93605",
    zip_name: null,
  },
  {
    postal_code: "49458",
    zip_name: null,
  },
  {
    postal_code: "36556",
    zip_name: null,
  },
  {
    postal_code: "29447",
    zip_name: null,
  },
  {
    postal_code: "46984",
    zip_name: null,
  },
  {
    postal_code: "36803",
    zip_name: null,
  },
  {
    postal_code: "52701",
    zip_name: null,
  },
  {
    postal_code: "45112",
    zip_name: null,
  },
  {
    postal_code: "22945",
    zip_name: null,
  },
  {
    postal_code: "5823",
    zip_name: null,
  },
  {
    postal_code: "46624",
    zip_name: null,
  },
  {
    postal_code: "88115",
    zip_name: null,
  },
  {
    postal_code: "91333",
    zip_name: null,
  },
  {
    postal_code: "45166",
    zip_name: null,
  },
  {
    postal_code: "96029",
    zip_name: null,
  },
  {
    postal_code: "35070",
    zip_name: null,
  },
  {
    postal_code: "28367",
    zip_name: null,
  },
  {
    postal_code: "62861",
    zip_name: null,
  },
  {
    postal_code: "80539",
    zip_name: null,
  },
  {
    postal_code: "74456",
    zip_name: null,
  },
  {
    postal_code: "72657",
    zip_name: null,
  },
  {
    postal_code: "25156",
    zip_name: null,
  },
  {
    postal_code: "14774",
    zip_name: null,
  },
  {
    postal_code: "83203",
    zip_name: null,
  },
  {
    postal_code: "18625",
    zip_name: null,
  },
  {
    postal_code: "29304",
    zip_name: null,
  },
  {
    postal_code: "37845",
    zip_name: null,
  },
  {
    postal_code: "6263",
    zip_name: null,
  },
  {
    postal_code: "15439",
    zip_name: null,
  },
  {
    postal_code: "19367",
    zip_name: null,
  },
  {
    postal_code: "85357",
    zip_name: null,
  },
  {
    postal_code: "40058",
    zip_name: null,
  },
  {
    postal_code: "32658",
    zip_name: null,
  },
  {
    postal_code: "35577",
    zip_name: null,
  },
  {
    postal_code: "14515",
    zip_name: null,
  },
  {
    postal_code: "5047",
    zip_name: null,
  },
  {
    postal_code: "74821",
    zip_name: null,
  },
  {
    postal_code: "41542",
    zip_name: null,
  },
  {
    postal_code: "14766",
    zip_name: null,
  },
  {
    postal_code: "24469",
    zip_name: null,
  },
  {
    postal_code: "74522",
    zip_name: null,
  },
  {
    postal_code: "78125",
    zip_name: null,
  },
  {
    postal_code: "12417",
    zip_name: null,
  },
  {
    postal_code: "24246",
    zip_name: null,
  },
  {
    postal_code: "26058",
    zip_name: null,
  },
  {
    postal_code: "98322",
    zip_name: null,
  },
  {
    postal_code: "29844",
    zip_name: null,
  },
  {
    postal_code: "37644",
    zip_name: null,
  },
  {
    postal_code: "47706",
    zip_name: null,
  },
  {
    postal_code: "95613",
    zip_name: null,
  },
  {
    postal_code: "54980",
    zip_name: null,
  },
  {
    postal_code: "31627",
    zip_name: null,
  },
  {
    postal_code: "52809",
    zip_name: null,
  },
  {
    postal_code: "15230",
    zip_name: null,
  },
  {
    postal_code: "4637",
    zip_name: null,
  },
  {
    postal_code: "6701",
    zip_name: null,
  },
  {
    postal_code: "25653",
    zip_name: null,
  },
  {
    postal_code: "47983",
    zip_name: null,
  },
  {
    postal_code: "5470",
    zip_name: null,
  },
  {
    postal_code: "79054",
    zip_name: null,
  },
  {
    postal_code: "71247",
    zip_name: null,
  },
  {
    postal_code: "35056",
    zip_name: null,
  },
  {
    postal_code: "43027",
    zip_name: null,
  },
  {
    postal_code: "48175",
    zip_name: null,
  },
  {
    postal_code: "44610",
    zip_name: null,
  },
  {
    postal_code: "46537",
    zip_name: null,
  },
  {
    postal_code: "95986",
    zip_name: null,
  },
  {
    postal_code: "18086",
    zip_name: null,
  },
  {
    postal_code: "25826",
    zip_name: null,
  },
  {
    postal_code: "90223",
    zip_name: null,
  },
  {
    postal_code: "13677",
    zip_name: null,
  },
  {
    postal_code: "17945",
    zip_name: null,
  },
  {
    postal_code: "99836",
    zip_name: null,
  },
  {
    postal_code: "75849",
    zip_name: null,
  },
  {
    postal_code: "17943",
    zip_name: null,
  },
  {
    postal_code: "35482",
    zip_name: null,
  },
  {
    postal_code: "62541",
    zip_name: null,
  },
  {
    postal_code: "32133",
    zip_name: null,
  },
  {
    postal_code: "43073",
    zip_name: null,
  },
  {
    postal_code: "44690",
    zip_name: null,
  },
  {
    postal_code: "77555",
    zip_name: null,
  },
  {
    postal_code: "62965",
    zip_name: null,
  },
  {
    postal_code: "70551",
    zip_name: null,
  },
  {
    postal_code: "48758",
    zip_name: null,
  },
  {
    postal_code: "86029",
    zip_name: null,
  },
  {
    postal_code: "14027",
    zip_name: null,
  },
  {
    postal_code: "82450",
    zip_name: null,
  },
  {
    postal_code: "16246",
    zip_name: null,
  },
  {
    postal_code: "78661",
    zip_name: null,
  },
  {
    postal_code: "24817",
    zip_name: null,
  },
  {
    postal_code: "5469",
    zip_name: null,
  },
  {
    postal_code: "74018",
    zip_name: null,
  },
  {
    postal_code: "75990",
    zip_name: null,
  },
  {
    postal_code: "30172",
    zip_name: null,
  },
  {
    postal_code: "28765",
    zip_name: null,
  },
  {
    postal_code: "24846",
    zip_name: null,
  },
  {
    postal_code: "31556",
    zip_name: null,
  },
  {
    postal_code: "17946",
    zip_name: null,
  },
  {
    postal_code: "47878",
    zip_name: null,
  },
  {
    postal_code: "37056",
    zip_name: null,
  },
  {
    postal_code: "72169",
    zip_name: null,
  },
  {
    postal_code: "24203",
    zip_name: null,
  },
  {
    postal_code: "44033",
    zip_name: null,
  },
  {
    postal_code: "34101",
    zip_name: null,
  },
  {
    postal_code: "74444",
    zip_name: null,
  },
  {
    postal_code: "74431",
    zip_name: null,
  },
  {
    postal_code: "60144",
    zip_name: null,
  },
  {
    postal_code: "45119",
    zip_name: null,
  },
  {
    postal_code: "16245",
    zip_name: null,
  },
  {
    postal_code: "15379",
    zip_name: null,
  },
  {
    postal_code: "96068",
    zip_name: null,
  },
  {
    postal_code: "34679",
    zip_name: null,
  },
  {
    postal_code: "28603",
    zip_name: null,
  },
  {
    postal_code: "24871",
    zip_name: null,
  },
  {
    postal_code: "87519",
    zip_name: null,
  },
  {
    postal_code: "30284",
    zip_name: null,
  },
  {
    postal_code: "93515",
    zip_name: null,
  },
  {
    postal_code: "88349",
    zip_name: null,
  },
  {
    postal_code: "24612",
    zip_name: null,
  },
  {
    postal_code: "73624",
    zip_name: null,
  },
  {
    postal_code: "54543",
    zip_name: null,
  },
  {
    postal_code: "39885",
    zip_name: null,
  },
  {
    postal_code: "72123",
    zip_name: null,
  },
  {
    postal_code: "72133",
    zip_name: null,
  },
  {
    postal_code: "77483",
    zip_name: null,
  },
  {
    postal_code: "29816",
    zip_name: null,
  },
  {
    postal_code: "28017",
    zip_name: null,
  },
  {
    postal_code: "77639",
    zip_name: null,
  },
  {
    postal_code: "27256",
    zip_name: null,
  },
  {
    postal_code: "41520",
    zip_name: null,
  },
  {
    postal_code: "46125",
    zip_name: null,
  },
  {
    postal_code: "25611",
    zip_name: null,
  },
  {
    postal_code: "78622",
    zip_name: null,
  },
  {
    postal_code: "78764",
    zip_name: null,
  },
  {
    postal_code: "79002",
    zip_name: null,
  },
  {
    postal_code: "29442",
    zip_name: null,
  },
  {
    postal_code: "85732",
    zip_name: null,
  },
  {
    postal_code: "54842",
    zip_name: null,
  },
  {
    postal_code: "42322",
    zip_name: null,
  },
  {
    postal_code: "99754",
    zip_name: null,
  },
  {
    postal_code: "93592",
    zip_name: null,
  },
  {
    postal_code: "31318",
    zip_name: null,
  },
  {
    postal_code: "63674",
    zip_name: null,
  },
  {
    postal_code: "49761",
    zip_name: null,
  },
  {
    postal_code: "43325",
    zip_name: null,
  },
  {
    postal_code: "5665",
    zip_name: null,
  },
  {
    postal_code: "28108",
    zip_name: null,
  },
  {
    postal_code: "25031",
    zip_name: null,
  },
  {
    postal_code: "1252",
    zip_name: null,
  },
  {
    postal_code: "27861",
    zip_name: null,
  },
  {
    postal_code: "30333",
    zip_name: null,
  },
  {
    postal_code: "46595",
    zip_name: null,
  },
  {
    postal_code: "27374",
    zip_name: null,
  },
  {
    postal_code: "54240",
    zip_name: null,
  },
  {
    postal_code: "97494",
    zip_name: null,
  },
  {
    postal_code: "43109",
    zip_name: null,
  },
  {
    postal_code: "78565",
    zip_name: null,
  },
  {
    postal_code: "23184",
    zip_name: null,
  },
  {
    postal_code: "1936",
    zip_name: null,
  },
  {
    postal_code: "12749",
    zip_name: null,
  },
  {
    postal_code: "12441",
    zip_name: null,
  },
  {
    postal_code: "18030",
    zip_name: null,
  },
  {
    postal_code: "19564",
    zip_name: null,
  },
  {
    postal_code: "19955",
    zip_name: null,
  },
  {
    postal_code: "25112",
    zip_name: null,
  },
  {
    postal_code: "85531",
    zip_name: null,
  },
  {
    postal_code: "99708",
    zip_name: null,
  },
  {
    postal_code: "15664",
    zip_name: null,
  },
  {
    postal_code: "28024",
    zip_name: null,
  },
  {
    postal_code: "30015",
    zip_name: null,
  },
  {
    postal_code: "78561",
    zip_name: null,
  },
  {
    postal_code: "20160",
    zip_name: null,
  },
  {
    postal_code: "87319",
    zip_name: null,
  },
  {
    postal_code: "70585",
    zip_name: null,
  },
  {
    postal_code: "30732",
    zip_name: null,
  },
  {
    postal_code: "48190",
    zip_name: null,
  },
  {
    postal_code: "25721",
    zip_name: null,
  },
  {
    postal_code: "4262",
    zip_name: null,
  },
  {
    postal_code: "7970",
    zip_name: null,
  },
  {
    postal_code: "54931",
    zip_name: null,
  },
  {
    postal_code: "77626",
    zip_name: null,
  },
  {
    postal_code: "72906",
    zip_name: null,
  },
  {
    postal_code: "12107",
    zip_name: null,
  },
  {
    postal_code: "26507",
    zip_name: null,
  },
  {
    postal_code: "38679",
    zip_name: null,
  },
  {
    postal_code: "46789",
    zip_name: null,
  },
  {
    postal_code: "14061",
    zip_name: null,
  },
  {
    postal_code: "43905",
    zip_name: null,
  },
  {
    postal_code: "23131",
    zip_name: null,
  },
  {
    postal_code: "36204",
    zip_name: null,
  },
  {
    postal_code: "70747",
    zip_name: null,
  },
  {
    postal_code: "12872",
    zip_name: null,
  },
  {
    postal_code: "63874",
    zip_name: null,
  },
  {
    postal_code: "66420",
    zip_name: null,
  },
  {
    postal_code: "34992",
    zip_name: null,
  },
  {
    postal_code: "49416",
    zip_name: null,
  },
  {
    postal_code: "71339",
    zip_name: null,
  },
  {
    postal_code: "63880",
    zip_name: null,
  },
  {
    postal_code: "15336",
    zip_name: null,
  },
  {
    postal_code: "41368",
    zip_name: null,
  },
  {
    postal_code: "92393",
    zip_name: null,
  },
  {
    postal_code: "10503",
    zip_name: null,
  },
  {
    postal_code: "43967",
    zip_name: null,
  },
  {
    postal_code: "62766",
    zip_name: null,
  },
  {
    postal_code: "13457",
    zip_name: null,
  },
  {
    postal_code: "75562",
    zip_name: null,
  },
  {
    postal_code: "18601",
    zip_name: null,
  },
  {
    postal_code: "43928",
    zip_name: null,
  },
  {
    postal_code: "87574",
    zip_name: null,
  },
  {
    postal_code: "41538",
    zip_name: null,
  },
  {
    postal_code: "12949",
    zip_name: null,
  },
  {
    postal_code: "46063",
    zip_name: null,
  },
  {
    postal_code: "74945",
    zip_name: null,
  },
  {
    postal_code: "46411",
    zip_name: null,
  },
  {
    postal_code: "17730",
    zip_name: null,
  },
  {
    postal_code: "79021",
    zip_name: null,
  },
  {
    postal_code: "12082",
    zip_name: null,
  },
  {
    postal_code: "70421",
    zip_name: null,
  },
  {
    postal_code: "78960",
    zip_name: null,
  },
  {
    postal_code: "20627",
    zip_name: null,
  },
  {
    postal_code: "30164",
    zip_name: null,
  },
  {
    postal_code: "17862",
    zip_name: null,
  },
  {
    postal_code: "46372",
    zip_name: null,
  },
  {
    postal_code: "24327",
    zip_name: null,
  },
  {
    postal_code: "26349",
    zip_name: null,
  },
  {
    postal_code: "80471",
    zip_name: null,
  },
  {
    postal_code: "2059",
    zip_name: null,
  },
  {
    postal_code: "25090",
    zip_name: null,
  },
  {
    postal_code: "33318",
    zip_name: null,
  },
  {
    postal_code: "28368",
    zip_name: null,
  },
  {
    postal_code: "25036",
    zip_name: null,
  },
  {
    postal_code: "22523",
    zip_name: null,
  },
  {
    postal_code: "24177",
    zip_name: null,
  },
  {
    postal_code: "26275",
    zip_name: null,
  },
  {
    postal_code: "28074",
    zip_name: null,
  },
  {
    postal_code: "75694",
    zip_name: null,
  },
  {
    postal_code: "8240",
    zip_name: null,
  },
  {
    postal_code: "83343",
    zip_name: null,
  },
  {
    postal_code: "71320",
    zip_name: null,
  },
  {
    postal_code: "30250",
    zip_name: null,
  },
  {
    postal_code: "28169",
    zip_name: null,
  },
  {
    postal_code: "63066",
    zip_name: null,
  },
  {
    postal_code: "98287",
    zip_name: null,
  },
  {
    postal_code: "83206",
    zip_name: null,
  },
  {
    postal_code: "46965",
    zip_name: null,
  },
  {
    postal_code: "10106",
    zip_name: null,
  },
  {
    postal_code: "78352",
    zip_name: null,
  },
  {
    postal_code: "93261",
    zip_name: null,
  },
  {
    postal_code: "71021",
    zip_name: null,
  },
  {
    postal_code: "18814",
    zip_name: null,
  },
  {
    postal_code: "26424",
    zip_name: null,
  },
  {
    postal_code: "42022",
    zip_name: null,
  },
  {
    postal_code: "75759",
    zip_name: null,
  },
  {
    postal_code: "18611",
    zip_name: null,
  },
  {
    postal_code: "14854",
    zip_name: null,
  },
  {
    postal_code: "31319",
    zip_name: null,
  },
  {
    postal_code: "23414",
    zip_name: null,
  },
  {
    postal_code: "16911",
    zip_name: null,
  },
  {
    postal_code: "54936",
    zip_name: null,
  },
  {
    postal_code: "27873",
    zip_name: null,
  },
  {
    postal_code: "32316",
    zip_name: null,
  },
  {
    postal_code: "18921",
    zip_name: null,
  },
  {
    postal_code: "60113",
    zip_name: null,
  },
  {
    postal_code: "99738",
    zip_name: null,
  },
  {
    postal_code: "75047",
    zip_name: null,
  },
  {
    postal_code: "24411",
    zip_name: null,
  },
  {
    postal_code: "45848",
    zip_name: null,
  },
  {
    postal_code: "95537",
    zip_name: null,
  },
  {
    postal_code: "29731",
    zip_name: null,
  },
  {
    postal_code: "31810",
    zip_name: null,
  },
  {
    postal_code: "1094",
    zip_name: null,
  },
  {
    postal_code: "75573",
    zip_name: null,
  },
  {
    postal_code: "74350",
    zip_name: null,
  },
  {
    postal_code: "49075",
    zip_name: null,
  },
  {
    postal_code: "10258",
    zip_name: null,
  },
  {
    postal_code: "77988",
    zip_name: null,
  },
  {
    postal_code: "13064",
    zip_name: null,
  },
  {
    postal_code: "52631",
    zip_name: null,
  },
  {
    postal_code: "8025",
    zip_name: null,
  },
  {
    postal_code: "83671",
    zip_name: null,
  },
  {
    postal_code: "6904",
    zip_name: null,
  },
  {
    postal_code: "27985",
    zip_name: null,
  },
  {
    postal_code: "28671",
    zip_name: null,
  },
  {
    postal_code: "23064",
    zip_name: null,
  },
  {
    postal_code: "75444",
    zip_name: null,
  },
  {
    postal_code: "74844",
    zip_name: null,
  },
  {
    postal_code: "46515",
    zip_name: null,
  },
  {
    postal_code: "32326",
    zip_name: null,
  },
  {
    postal_code: "77326",
    zip_name: null,
  },
  {
    postal_code: "31051",
    zip_name: null,
  },
  {
    postal_code: "62876",
    zip_name: null,
  },
  {
    postal_code: "18043",
    zip_name: null,
  },
  {
    postal_code: "75685",
    zip_name: null,
  },
  {
    postal_code: "2829",
    zip_name: null,
  },
  {
    postal_code: "76485",
    zip_name: null,
  },
  {
    postal_code: "70434",
    zip_name: null,
  },
  {
    postal_code: "38767",
    zip_name: null,
  },
  {
    postal_code: "21867",
    zip_name: null,
  },
  {
    postal_code: "73066",
    zip_name: null,
  },
  {
    postal_code: "8218",
    zip_name: null,
  },
  {
    postal_code: "26434",
    zip_name: null,
  },
  {
    postal_code: "74340",
    zip_name: null,
  },
  {
    postal_code: "24126",
    zip_name: null,
  },
  {
    postal_code: "33836",
    zip_name: null,
  },
  {
    postal_code: "42631",
    zip_name: null,
  },
  {
    postal_code: "17750",
    zip_name: null,
  },
  {
    postal_code: "28042",
    zip_name: null,
  },
  {
    postal_code: "19316",
    zip_name: null,
  },
  {
    postal_code: "10982",
    zip_name: null,
  },
  {
    postal_code: "84740",
    zip_name: null,
  },
  {
    postal_code: "43951",
    zip_name: null,
  },
  {
    postal_code: "18231",
    zip_name: null,
  },
  {
    postal_code: "7096",
    zip_name: null,
  },
  {
    postal_code: "12223",
    zip_name: null,
  },
  {
    postal_code: "24888",
    zip_name: null,
  },
  {
    postal_code: "53026",
    zip_name: null,
  },
  {
    postal_code: "45330",
    zip_name: null,
  },
  {
    postal_code: "93303",
    zip_name: null,
  },
  {
    postal_code: "71914",
    zip_name: null,
  },
  {
    postal_code: "76939",
    zip_name: null,
  },
  {
    postal_code: "23313",
    zip_name: null,
  },
  {
    postal_code: "21542",
    zip_name: null,
  },
  {
    postal_code: "18943",
    zip_name: null,
  },
  {
    postal_code: "33339",
    zip_name: null,
  },
  {
    postal_code: "17254",
    zip_name: null,
  },
  {
    postal_code: "99764",
    zip_name: null,
  },
  {
    postal_code: "18956",
    zip_name: null,
  },
  {
    postal_code: "27894",
    zip_name: null,
  },
  {
    postal_code: "25855",
    zip_name: null,
  },
  {
    postal_code: "38772",
    zip_name: null,
  },
  {
    postal_code: "61037",
    zip_name: null,
  },
  {
    postal_code: "75978",
    zip_name: null,
  },
  {
    postal_code: "3111",
    zip_name: null,
  },
  {
    postal_code: "47875",
    zip_name: null,
  },
  {
    postal_code: "8217",
    zip_name: null,
  },
  {
    postal_code: "21020",
    zip_name: null,
  },
  {
    postal_code: "32728",
    zip_name: null,
  },
  {
    postal_code: "32357",
    zip_name: null,
  },
  {
    postal_code: "27867",
    zip_name: null,
  },
  {
    postal_code: "23397",
    zip_name: null,
  },
  {
    postal_code: "24606",
    zip_name: null,
  },
  {
    postal_code: "11424",
    zip_name: null,
  },
  {
    postal_code: "18242",
    zip_name: null,
  },
  {
    postal_code: "30623",
    zip_name: null,
  },
  {
    postal_code: "49258",
    zip_name: null,
  },
  {
    postal_code: "31083",
    zip_name: null,
  },
  {
    postal_code: "17884",
    zip_name: null,
  },
  {
    postal_code: "47617",
    zip_name: null,
  },
  {
    postal_code: "76369",
    zip_name: null,
  },
  {
    postal_code: "49409",
    zip_name: null,
  },
  {
    postal_code: "83826",
    zip_name: null,
  },
  {
    postal_code: "72107",
    zip_name: null,
  },
  {
    postal_code: "65615",
    zip_name: null,
  },
  {
    postal_code: "28123",
    zip_name: null,
  },
  {
    postal_code: "78853",
    zip_name: null,
  },
  {
    postal_code: "28667",
    zip_name: null,
  },
  {
    postal_code: "59604",
    zip_name: null,
  },
  {
    postal_code: "75963",
    zip_name: null,
  },
  {
    postal_code: "99345",
    zip_name: null,
  },
  {
    postal_code: "21106",
    zip_name: null,
  },
  {
    postal_code: "72180",
    zip_name: null,
  },
  {
    postal_code: "76650",
    zip_name: null,
  },
  {
    postal_code: "2651",
    zip_name: null,
  },
  {
    postal_code: "22555",
    zip_name: null,
  },
  {
    postal_code: "37384",
    zip_name: null,
  },
  {
    postal_code: "54816",
    zip_name: null,
  },
  {
    postal_code: "19375",
    zip_name: null,
  },
  {
    postal_code: "36559",
    zip_name: null,
  },
  {
    postal_code: "84011",
    zip_name: null,
  },
  {
    postal_code: "77876",
    zip_name: null,
  },
  {
    postal_code: "75425",
    zip_name: null,
  },
  {
    postal_code: "24836",
    zip_name: null,
  },
  {
    postal_code: "70465",
    zip_name: null,
  },
  {
    postal_code: "73746",
    zip_name: null,
  },
  {
    postal_code: "72431",
    zip_name: null,
  },
  {
    postal_code: "99791",
    zip_name: null,
  },
  {
    postal_code: "77368",
    zip_name: null,
  },
  {
    postal_code: "15447",
    zip_name: null,
  },
  {
    postal_code: "23424",
    zip_name: null,
  },
  {
    postal_code: "12073",
    zip_name: null,
  },
  {
    postal_code: "70524",
    zip_name: null,
  },
  {
    postal_code: "25836",
    zip_name: null,
  },
  {
    postal_code: "22107",
    zip_name: null,
  },
  {
    postal_code: "6075",
    zip_name: null,
  },
  {
    postal_code: "4228",
    zip_name: null,
  },
  {
    postal_code: "13745",
    zip_name: null,
  },
  {
    postal_code: "2647",
    zip_name: null,
  },
  {
    postal_code: "85627",
    zip_name: null,
  },
  {
    postal_code: "32664",
    zip_name: null,
  },
  {
    postal_code: "29503",
    zip_name: null,
  },
  {
    postal_code: "13643",
    zip_name: null,
  },
  {
    postal_code: "43117",
    zip_name: null,
  },
  {
    postal_code: "46572",
    zip_name: null,
  },
  {
    postal_code: "95309",
    zip_name: null,
  },
  {
    postal_code: "22472",
    zip_name: null,
  },
  {
    postal_code: "22230",
    zip_name: null,
  },
  {
    postal_code: "25637",
    zip_name: null,
  },
  {
    postal_code: "17075",
    zip_name: null,
  },
  {
    postal_code: "28342",
    zip_name: null,
  },
  {
    postal_code: "48620",
    zip_name: null,
  },
  {
    postal_code: "6064",
    zip_name: null,
  },
  {
    postal_code: "19481",
    zip_name: null,
  },
  {
    postal_code: "35649",
    zip_name: null,
  },
  {
    postal_code: "47437",
    zip_name: null,
  },
  {
    postal_code: "73435",
    zip_name: null,
  },
  {
    postal_code: "74001",
    zip_name: null,
  },
  {
    postal_code: "72037",
    zip_name: null,
  },
  {
    postal_code: "99174",
    zip_name: null,
  },
  {
    postal_code: "15561",
    zip_name: null,
  },
  {
    postal_code: "12089",
    zip_name: null,
  },
  {
    postal_code: "16856",
    zip_name: null,
  },
  {
    postal_code: "30573",
    zip_name: null,
  },
  {
    postal_code: "2584",
    zip_name: null,
  },
  {
    postal_code: "32639",
    zip_name: null,
  },
  {
    postal_code: "17936",
    zip_name: null,
  },
  {
    postal_code: "4070",
    zip_name: null,
  },
  {
    postal_code: "15075",
    zip_name: null,
  },
  {
    postal_code: "24624",
    zip_name: null,
  },
  {
    postal_code: "63860",
    zip_name: null,
  },
  {
    postal_code: "30063",
    zip_name: null,
  },
  {
    postal_code: "21105",
    zip_name: null,
  },
  {
    postal_code: "34729",
    zip_name: null,
  },
  {
    postal_code: "8888",
    zip_name: null,
  },
  {
    postal_code: "5054",
    zip_name: null,
  },
  {
    postal_code: "36087",
    zip_name: null,
  },
  {
    postal_code: "53152",
    zip_name: null,
  },
  {
    postal_code: "5159",
    zip_name: null,
  },
  {
    postal_code: "46393",
    zip_name: null,
  },
  {
    postal_code: "35573",
    zip_name: null,
  },
  {
    postal_code: "21092",
    zip_name: null,
  },
  {
    postal_code: "10120",
    zip_name: null,
  },
  {
    postal_code: "75097",
    zip_name: null,
  },
  {
    postal_code: "35403",
    zip_name: null,
  },
  {
    postal_code: "20213",
    zip_name: null,
  },
  {
    postal_code: "83468",
    zip_name: null,
  },
  {
    postal_code: "75565",
    zip_name: null,
  },
  {
    postal_code: "3832",
    zip_name: null,
  },
  {
    postal_code: "71329",
    zip_name: null,
  },
  {
    postal_code: "60701",
    zip_name: null,
  },
  {
    postal_code: "75014",
    zip_name: null,
  },
  {
    postal_code: "19358",
    zip_name: null,
  },
  {
    postal_code: "37222",
    zip_name: null,
  },
  {
    postal_code: "22622",
    zip_name: null,
  },
  {
    postal_code: "78044",
    zip_name: null,
  },
  {
    postal_code: "24218",
    zip_name: null,
  },
  {
    postal_code: "79780",
    zip_name: null,
  },
  {
    postal_code: "43510",
    zip_name: null,
  },
  {
    postal_code: "49666",
    zip_name: null,
  },
  {
    postal_code: "2672",
    zip_name: null,
  },
  {
    postal_code: "18922",
    zip_name: null,
  },
  {
    postal_code: "99635",
    zip_name: null,
  },
  {
    postal_code: "18251",
    zip_name: null,
  },
  {
    postal_code: "38622",
    zip_name: null,
  },
  {
    postal_code: "82218",
    zip_name: null,
  },
  {
    postal_code: "39829",
    zip_name: null,
  },
  {
    postal_code: "96090",
    zip_name: null,
  },
  {
    postal_code: "29333",
    zip_name: null,
  },
  {
    postal_code: "10129",
    zip_name: null,
  },
  {
    postal_code: "17083",
    zip_name: null,
  },
  {
    postal_code: "95325",
    zip_name: null,
  },
  {
    postal_code: "41778",
    zip_name: null,
  },
  {
    postal_code: "93709",
    zip_name: null,
  },
  {
    postal_code: "17056",
    zip_name: null,
  },
  {
    postal_code: "6383",
    zip_name: null,
  },
  {
    postal_code: "44665",
    zip_name: null,
  },
  {
    postal_code: "2051",
    zip_name: null,
  },
  {
    postal_code: "29594",
    zip_name: null,
  },
  {
    postal_code: "31209",
    zip_name: null,
  },
  {
    postal_code: "85359",
    zip_name: null,
  },
  {
    postal_code: "29665",
    zip_name: null,
  },
  {
    postal_code: "45131",
    zip_name: null,
  },
  {
    postal_code: "44285",
    zip_name: null,
  },
  {
    postal_code: "72733",
    zip_name: null,
  },
  {
    postal_code: "19961",
    zip_name: null,
  },
  {
    postal_code: "46513",
    zip_name: null,
  },
  {
    postal_code: "83281",
    zip_name: null,
  },
  {
    postal_code: "19490",
    zip_name: null,
  },
  {
    postal_code: "98609",
    zip_name: null,
  },
  {
    postal_code: "75782",
    zip_name: null,
  },
  {
    postal_code: "95950",
    zip_name: null,
  },
  {
    postal_code: "70451",
    zip_name: null,
  },
  {
    postal_code: "14856",
    zip_name: null,
  },
  {
    postal_code: "76654",
    zip_name: null,
  },
  {
    postal_code: "96745",
    zip_name: null,
  },
  {
    postal_code: "48852",
    zip_name: null,
  },
  {
    postal_code: "88888",
    zip_name: null,
  },
  {
    postal_code: "99725",
    zip_name: null,
  },
  {
    postal_code: "83866",
    zip_name: null,
  },
  {
    postal_code: "73556",
    zip_name: null,
  },
  {
    postal_code: "18320",
    zip_name: null,
  },
  {
    postal_code: "10981",
    zip_name: null,
  },
  {
    postal_code: "24880",
    zip_name: null,
  },
  {
    postal_code: "95958",
    zip_name: null,
  },
  {
    postal_code: "48830",
    zip_name: null,
  },
  {
    postal_code: "36865",
    zip_name: null,
  },
  {
    postal_code: "7875",
    zip_name: null,
  },
  {
    postal_code: "6372",
    zip_name: null,
  },
  {
    postal_code: "49723",
    zip_name: null,
  },
  {
    postal_code: "70558",
    zip_name: null,
  },
  {
    postal_code: "75101",
    zip_name: null,
  },
  {
    postal_code: "32147",
    zip_name: null,
  },
  {
    postal_code: "45884",
    zip_name: null,
  },
  {
    postal_code: "75832",
    zip_name: null,
  },
  {
    postal_code: "4077",
    zip_name: null,
  },
  {
    postal_code: "38365",
    zip_name: null,
  },
  {
    postal_code: "28380",
    zip_name: null,
  },
  {
    postal_code: "75682",
    zip_name: null,
  },
  {
    postal_code: "22965",
    zip_name: null,
  },
  {
    postal_code: "38781",
    zip_name: null,
  },
  {
    postal_code: "63868",
    zip_name: null,
  },
  {
    postal_code: "54654",
    zip_name: null,
  },
  {
    postal_code: "18820",
    zip_name: null,
  },
  {
    postal_code: "78567",
    zip_name: null,
  },
  {
    postal_code: "70718",
    zip_name: null,
  },
  {
    postal_code: "39477",
    zip_name: null,
  },
  {
    postal_code: "45617",
    zip_name: null,
  },
  {
    postal_code: "44274",
    zip_name: null,
  },
  {
    postal_code: "29375",
    zip_name: null,
  },
  {
    postal_code: "23127",
    zip_name: null,
  },
  {
    postal_code: "97384",
    zip_name: null,
  },
  {
    postal_code: "34447",
    zip_name: null,
  },
  {
    postal_code: "43909",
    zip_name: null,
  },
  {
    postal_code: "19451",
    zip_name: null,
  },
  {
    postal_code: "74468",
    zip_name: null,
  },
  {
    postal_code: "75434",
    zip_name: null,
  },
  {
    postal_code: "96011",
    zip_name: null,
  },
  {
    postal_code: "77866",
    zip_name: null,
  },
  {
    postal_code: "37732",
    zip_name: null,
  },
  {
    postal_code: "23003",
    zip_name: null,
  },
  {
    postal_code: "30502",
    zip_name: null,
  },
  {
    postal_code: "20529",
    zip_name: null,
  },
  {
    postal_code: "38254",
    zip_name: null,
  },
  {
    postal_code: "47851",
    zip_name: null,
  },
  {
    postal_code: "76466",
    zip_name: null,
  },
  {
    postal_code: "14602",
    zip_name: null,
  },
  {
    postal_code: "27102",
    zip_name: null,
  },
  {
    postal_code: "72403",
    zip_name: null,
  },
  {
    postal_code: "18046",
    zip_name: null,
  },
  {
    postal_code: "18440",
    zip_name: null,
  },
  {
    postal_code: "24608",
    zip_name: null,
  },
  {
    postal_code: "5085",
    zip_name: null,
  },
  {
    postal_code: "70646",
    zip_name: null,
  },
  {
    postal_code: "17261",
    zip_name: null,
  },
  {
    postal_code: "95545",
    zip_name: null,
  },
  {
    postal_code: "29041",
    zip_name: null,
  },
  {
    postal_code: "12724",
    zip_name: null,
  },
  {
    postal_code: "13102",
    zip_name: null,
  },
  {
    postal_code: "94516",
    zip_name: null,
  },
  {
    postal_code: "22507",
    zip_name: null,
  },
  {
    postal_code: "25205",
    zip_name: null,
  },
  {
    postal_code: "7926",
    zip_name: null,
  },
  {
    postal_code: "24001",
    zip_name: null,
  },
  {
    postal_code: "62825",
    zip_name: null,
  },
  {
    postal_code: "32616",
    zip_name: null,
  },
  {
    postal_code: "79380",
    zip_name: null,
  },
  {
    postal_code: "4112",
    zip_name: null,
  },
  {
    postal_code: "47367",
    zip_name: null,
  },
  {
    postal_code: "26576",
    zip_name: null,
  },
  {
    postal_code: "18342",
    zip_name: null,
  },
  {
    postal_code: "5849",
    zip_name: null,
  },
  {
    postal_code: "76953",
    zip_name: null,
  },
  {
    postal_code: "23939",
    zip_name: null,
  },
  {
    postal_code: "44232",
    zip_name: null,
  },
  {
    postal_code: "49717",
    zip_name: null,
  },
  {
    postal_code: "12933",
    zip_name: null,
  },
  {
    postal_code: "80434",
    zip_name: null,
  },
  {
    postal_code: "22524",
    zip_name: null,
  },
  {
    postal_code: "37116",
    zip_name: null,
  },
  {
    postal_code: "47370",
    zip_name: null,
  },
  {
    postal_code: "5485",
    zip_name: null,
  },
  {
    postal_code: "27506",
    zip_name: null,
  },
  {
    postal_code: "37041",
    zip_name: null,
  },
  {
    postal_code: "24854",
    zip_name: null,
  },
  {
    postal_code: "18012",
    zip_name: null,
  },
  {
    postal_code: "23401",
    zip_name: null,
  },
  {
    postal_code: "71279",
    zip_name: null,
  },
  {
    postal_code: "6439",
    zip_name: null,
  },
  {
    postal_code: "70139",
    zip_name: null,
  },
  {
    postal_code: "21023",
    zip_name: null,
  },
  {
    postal_code: "74559",
    zip_name: null,
  },
  {
    postal_code: "99710",
    zip_name: null,
  },
  {
    postal_code: "85551",
    zip_name: null,
  },
  {
    postal_code: "44422",
    zip_name: null,
  },
  {
    postal_code: "63826",
    zip_name: null,
  },
  {
    postal_code: "45004",
    zip_name: null,
  },
  {
    postal_code: "13113",
    zip_name: null,
  },
  {
    postal_code: "19523",
    zip_name: null,
  },
  {
    postal_code: "32410",
    zip_name: null,
  },
  {
    postal_code: "25919",
    zip_name: null,
  },
  {
    postal_code: "34146",
    zip_name: null,
  },
  {
    postal_code: "8315",
    zip_name: null,
  },
  {
    postal_code: "77376",
    zip_name: null,
  },
  {
    postal_code: "29834",
    zip_name: null,
  },
  {
    postal_code: "23115",
    zip_name: null,
  },
  {
    postal_code: "29225",
    zip_name: null,
  },
  {
    postal_code: "31034",
    zip_name: null,
  },
  {
    postal_code: "92652",
    zip_name: null,
  },
  {
    postal_code: "35584",
    zip_name: null,
  },
  {
    postal_code: "91017",
    zip_name: null,
  },
  {
    postal_code: "72735",
    zip_name: null,
  },
  {
    postal_code: "25927",
    zip_name: null,
  },
  {
    postal_code: "66742",
    zip_name: null,
  },
  {
    postal_code: "78558",
    zip_name: null,
  },
  {
    postal_code: "53031",
    zip_name: null,
  },
  {
    postal_code: "17253",
    zip_name: null,
  },
  {
    postal_code: "13631",
    zip_name: null,
  },
  {
    postal_code: "63758",
    zip_name: null,
  },
  {
    postal_code: "70463",
    zip_name: null,
  },
  {
    postal_code: "18627",
    zip_name: null,
  },
  {
    postal_code: "71840",
    zip_name: null,
  },
  {
    postal_code: "10542",
    zip_name: null,
  },
  {
    postal_code: "28710",
    zip_name: null,
  },
  {
    postal_code: "72075",
    zip_name: null,
  },
  {
    postal_code: "38649",
    zip_name: null,
  },
  {
    postal_code: "24895",
    zip_name: null,
  },
  {
    postal_code: "18457",
    zip_name: null,
  },
  {
    postal_code: "96084",
    zip_name: null,
  },
  {
    postal_code: "36202",
    zip_name: null,
  },
  {
    postal_code: "12527",
    zip_name: null,
  },
  {
    postal_code: "43916",
    zip_name: null,
  },
  {
    postal_code: "78523",
    zip_name: null,
  },
  {
    postal_code: "54890",
    zip_name: null,
  },
  {
    postal_code: "17069",
    zip_name: null,
  },
  {
    postal_code: "48896",
    zip_name: null,
  },
  {
    postal_code: "14169",
    zip_name: null,
  },
  {
    postal_code: "2362",
    zip_name: null,
  },
  {
    postal_code: "30429",
    zip_name: null,
  },
  {
    postal_code: "80163",
    zip_name: null,
  },
  {
    postal_code: "13154",
    zip_name: null,
  },
  {
    postal_code: "38722",
    zip_name: null,
  },
  {
    postal_code: "24813",
    zip_name: null,
  },
  {
    postal_code: "94562",
    zip_name: null,
  },
  {
    postal_code: "77661",
    zip_name: null,
  },
  {
    postal_code: "49764",
    zip_name: null,
  },
  {
    postal_code: "20210",
    zip_name: null,
  },
  {
    postal_code: "31010",
    zip_name: null,
  },
  {
    postal_code: "12939",
    zip_name: null,
  },
  {
    postal_code: "36371",
    zip_name: null,
  },
  {
    postal_code: "54926",
    zip_name: null,
  },
  {
    postal_code: "10915",
    zip_name: null,
  },
  {
    postal_code: "8220",
    zip_name: null,
  },
  {
    postal_code: "70569",
    zip_name: null,
  },
  {
    postal_code: "56335",
    zip_name: null,
  },
  {
    postal_code: "31067",
    zip_name: null,
  },
  {
    postal_code: "27202",
    zip_name: null,
  },
  {
    postal_code: "8064",
    zip_name: null,
  },
  {
    postal_code: "77976",
    zip_name: null,
  },
  {
    postal_code: "63047",
    zip_name: null,
  },
  {
    postal_code: "24601",
    zip_name: null,
  },
  {
    postal_code: "79711",
    zip_name: null,
  },
  {
    postal_code: "30562",
    zip_name: null,
  },
  {
    postal_code: "12781",
    zip_name: null,
  },
  {
    postal_code: "73436",
    zip_name: null,
  },
  {
    postal_code: "36766",
    zip_name: null,
  },
  {
    postal_code: "79383",
    zip_name: null,
  },
  {
    postal_code: "17829",
    zip_name: null,
  },
  {
    postal_code: "5678",
    zip_name: null,
  },
  {
    postal_code: "47263",
    zip_name: null,
  },
  {
    postal_code: "12537",
    zip_name: null,
  },
  {
    postal_code: "26259",
    zip_name: null,
  },
  {
    postal_code: "18827",
    zip_name: null,
  },
  {
    postal_code: "62279",
    zip_name: null,
  },
  {
    postal_code: "75865",
    zip_name: null,
  },
  {
    postal_code: "42216",
    zip_name: null,
  },
  {
    postal_code: "2883",
    zip_name: null,
  },
  {
    postal_code: "4565",
    zip_name: null,
  },
  {
    postal_code: "14029",
    zip_name: null,
  },
  {
    postal_code: "79105",
    zip_name: null,
  },
  {
    postal_code: "72613",
    zip_name: null,
  },
  {
    postal_code: "77415",
    zip_name: null,
  },
  {
    postal_code: "23304",
    zip_name: null,
  },
  {
    postal_code: "52344",
    zip_name: null,
  },
  {
    postal_code: "61077",
    zip_name: null,
  },
  {
    postal_code: "8213",
    zip_name: null,
  },
  {
    postal_code: "17933",
    zip_name: null,
  },
  {
    postal_code: "70576",
    zip_name: null,
  },
  {
    postal_code: "38455",
    zip_name: null,
  },
  {
    postal_code: "12722",
    zip_name: null,
  },
  {
    postal_code: "95712",
    zip_name: null,
  },
  {
    postal_code: "49634",
    zip_name: null,
  },
  {
    postal_code: "37068",
    zip_name: null,
  },
  {
    postal_code: "22517",
    zip_name: null,
  },
  {
    postal_code: "39558",
    zip_name: null,
  },
  {
    postal_code: "61424",
    zip_name: null,
  },
  {
    postal_code: "56436",
    zip_name: null,
  },
  {
    postal_code: "46067",
    zip_name: null,
  },
  {
    postal_code: "22577",
    zip_name: null,
  },
  {
    postal_code: "7962",
    zip_name: null,
  },
  {
    postal_code: "31776",
    zip_name: null,
  },
  {
    postal_code: "32644",
    zip_name: null,
  },
  {
    postal_code: "99014",
    zip_name: null,
  },
  {
    postal_code: "31727",
    zip_name: null,
  },
  {
    postal_code: "18244",
    zip_name: null,
  },
  {
    postal_code: "28583",
    zip_name: null,
  },
  {
    postal_code: "45636",
    zip_name: null,
  },
  {
    postal_code: "35112",
    zip_name: null,
  },
  {
    postal_code: "85942",
    zip_name: null,
  },
  {
    postal_code: "1041",
    zip_name: null,
  },
  {
    postal_code: "18039",
    zip_name: null,
  },
  {
    postal_code: "78545",
    zip_name: null,
  },
  {
    postal_code: "88058",
    zip_name: null,
  },
  {
    postal_code: "49610",
    zip_name: null,
  },
  {
    postal_code: "2574",
    zip_name: null,
  },
  {
    postal_code: "92702",
    zip_name: null,
  },
  {
    postal_code: "62662",
    zip_name: null,
  },
  {
    postal_code: "41426",
    zip_name: null,
  },
  {
    postal_code: "10112",
    zip_name: null,
  },
  {
    postal_code: "53957",
    zip_name: null,
  },
  {
    postal_code: "39167",
    zip_name: null,
  },
  {
    postal_code: "92605",
    zip_name: null,
  },
  {
    postal_code: "78540",
    zip_name: null,
  },
  {
    postal_code: "30539",
    zip_name: null,
  },
  {
    postal_code: "5731",
    zip_name: null,
  },
  {
    postal_code: "29826",
    zip_name: null,
  },
  {
    postal_code: "28720",
    zip_name: null,
  },
  {
    postal_code: "78505",
    zip_name: null,
  },
  {
    postal_code: "30369",
    zip_name: null,
  },
  {
    postal_code: "38835",
    zip_name: null,
  },
  {
    postal_code: "7752",
    zip_name: null,
  },
  {
    postal_code: "40027",
    zip_name: null,
  },
  {
    postal_code: "25022",
    zip_name: null,
  },
  {
    postal_code: "36446",
    zip_name: null,
  },
  {
    postal_code: "28647",
    zip_name: null,
  },
  {
    postal_code: "72427",
    zip_name: null,
  },
  {
    postal_code: "86342",
    zip_name: null,
  },
  {
    postal_code: "86512",
    zip_name: null,
  },
  {
    postal_code: "25337",
    zip_name: null,
  },
  {
    postal_code: "36302",
    zip_name: null,
  },
  {
    postal_code: "77852",
    zip_name: null,
  },
  {
    postal_code: "18349",
    zip_name: null,
  },
  {
    postal_code: "84091",
    zip_name: null,
  },
  {
    postal_code: "7831",
    zip_name: null,
  },
  {
    postal_code: "95418",
    zip_name: null,
  },
  {
    postal_code: "45787",
    zip_name: null,
  },
  {
    postal_code: "65532",
    zip_name: null,
  },
  {
    postal_code: "3843",
    zip_name: null,
  },
  {
    postal_code: "61613",
    zip_name: null,
  },
  {
    postal_code: "7602",
    zip_name: null,
  },
  {
    postal_code: "26436",
    zip_name: null,
  },
  {
    postal_code: "25040",
    zip_name: null,
  },
  {
    postal_code: "45316",
    zip_name: null,
  },
  {
    postal_code: "26823",
    zip_name: null,
  },
  {
    postal_code: "28664",
    zip_name: null,
  },
  {
    postal_code: "54760",
    zip_name: null,
  },
  {
    postal_code: "45307",
    zip_name: null,
  },
  {
    postal_code: "65302",
    zip_name: null,
  },
  {
    postal_code: "7051",
    zip_name: null,
  },
  {
    postal_code: "43985",
    zip_name: null,
  },
  {
    postal_code: "36763",
    zip_name: null,
  },
  {
    postal_code: "30301",
    zip_name: null,
  },
  {
    postal_code: "47021",
    zip_name: null,
  },
  {
    postal_code: "44661",
    zip_name: null,
  },
  {
    postal_code: "49628",
    zip_name: null,
  },
  {
    postal_code: "7833",
    zip_name: null,
  },
  {
    postal_code: "28641",
    zip_name: null,
  },
  {
    postal_code: "35964",
    zip_name: null,
  },
  {
    postal_code: "3847",
    zip_name: null,
  },
  {
    postal_code: "95353",
    zip_name: null,
  },
  {
    postal_code: "74750",
    zip_name: null,
  },
  {
    postal_code: "21762",
    zip_name: null,
  },
  {
    postal_code: "14887",
    zip_name: null,
  },
  {
    postal_code: "24212",
    zip_name: null,
  },
  {
    postal_code: "30639",
    zip_name: null,
  },
  {
    postal_code: "62921",
    zip_name: null,
  },
  {
    postal_code: "47344",
    zip_name: null,
  },
  {
    postal_code: "37304",
    zip_name: null,
  },
  {
    postal_code: "23427",
    zip_name: null,
  },
  {
    postal_code: "13404",
    zip_name: null,
  },
  {
    postal_code: "88031",
    zip_name: null,
  },
  {
    postal_code: "36057",
    zip_name: null,
  },
  {
    postal_code: "95639",
    zip_name: null,
  },
  {
    postal_code: "13051",
    zip_name: null,
  },
  {
    postal_code: "71434",
    zip_name: null,
  },
  {
    postal_code: "45336",
    zip_name: null,
  },
  {
    postal_code: "77476",
    zip_name: null,
  },
  {
    postal_code: "17865",
    zip_name: null,
  },
  {
    postal_code: "24716",
    zip_name: null,
  },
  {
    postal_code: "88254",
    zip_name: null,
  },
  {
    postal_code: "82615",
    zip_name: null,
  },
  {
    postal_code: "99566",
    zip_name: null,
  },
  {
    postal_code: "95675",
    zip_name: null,
  },
  {
    postal_code: "4227",
    zip_name: null,
  },
  {
    postal_code: "2350",
    zip_name: null,
  },
  {
    postal_code: "99918",
    zip_name: null,
  },
  {
    postal_code: "26544",
    zip_name: null,
  },
  {
    postal_code: "8219",
    zip_name: null,
  },
  {
    postal_code: "12767",
    zip_name: null,
  },
  {
    postal_code: "22579",
    zip_name: null,
  },
  {
    postal_code: "38235",
    zip_name: null,
  },
  {
    postal_code: "94953",
    zip_name: null,
  },
  {
    postal_code: "81227",
    zip_name: null,
  },
  {
    postal_code: "10103",
    zip_name: null,
  },
  {
    postal_code: "78522",
    zip_name: null,
  },
  {
    postal_code: "36065",
    zip_name: null,
  },
  {
    postal_code: "28653",
    zip_name: null,
  },
  {
    postal_code: "11380",
    zip_name: null,
  },
  {
    postal_code: "18256",
    zip_name: null,
  },
  {
    postal_code: "38050",
    zip_name: null,
  },
  {
    postal_code: "19369",
    zip_name: null,
  },
  {
    postal_code: "7844",
    zip_name: null,
  },
  {
    postal_code: "29861",
    zip_name: null,
  },
  {
    postal_code: "48812",
    zip_name: null,
  },
  {
    postal_code: "27836",
    zip_name: null,
  },
  {
    postal_code: "77374",
    zip_name: null,
  },
  {
    postal_code: "20139",
    zip_name: null,
  },
  {
    postal_code: "99644",
    zip_name: null,
  },
  {
    postal_code: "29802",
    zip_name: null,
  },
  {
    postal_code: "25054",
    zip_name: null,
  },
  {
    postal_code: "53195",
    zip_name: null,
  },
  {
    postal_code: "76268",
    zip_name: null,
  },
  {
    postal_code: "38802",
    zip_name: null,
  },
  {
    postal_code: "20686",
    zip_name: null,
  },
  {
    postal_code: "36439",
    zip_name: null,
  },
  {
    postal_code: "99102",
    zip_name: null,
  },
  {
    postal_code: "53064",
    zip_name: null,
  },
  {
    postal_code: "83465",
    zip_name: null,
  },
  {
    postal_code: "16427",
    zip_name: null,
  },
  {
    postal_code: "23031",
    zip_name: null,
  },
  {
    postal_code: "63365",
    zip_name: null,
  },
  {
    postal_code: "70464",
    zip_name: null,
  },
  {
    postal_code: "17762",
    zip_name: null,
  },
  {
    postal_code: "3105",
    zip_name: null,
  },
  {
    postal_code: "49077",
    zip_name: null,
  },
  {
    postal_code: "85360",
    zip_name: null,
  },
  {
    postal_code: "80477",
    zip_name: null,
  },
  {
    postal_code: "27340",
    zip_name: null,
  },
  {
    postal_code: "22513",
    zip_name: null,
  },
  {
    postal_code: "77428",
    zip_name: null,
  },
  {
    postal_code: "18356",
    zip_name: null,
  },
  {
    postal_code: "25904",
    zip_name: null,
  },
  {
    postal_code: "70541",
    zip_name: null,
  },
  {
    postal_code: "99841",
    zip_name: null,
  },
  {
    postal_code: "72462",
    zip_name: null,
  },
  {
    postal_code: "49734",
    zip_name: null,
  },
  {
    postal_code: "46931",
    zip_name: null,
  },
  {
    postal_code: "77533",
    zip_name: null,
  },
  {
    postal_code: "8245",
    zip_name: null,
  },
  {
    postal_code: "15072",
    zip_name: null,
  },
  {
    postal_code: "72221",
    zip_name: null,
  },
  {
    postal_code: "46796",
    zip_name: null,
  },
  {
    postal_code: "47388",
    zip_name: null,
  },
  {
    postal_code: "99624",
    zip_name: null,
  },
  {
    postal_code: "7837",
    zip_name: null,
  },
  {
    postal_code: "25692",
    zip_name: null,
  },
  {
    postal_code: "8250",
    zip_name: null,
  },
  {
    postal_code: "29850",
    zip_name: null,
  },
  {
    postal_code: "8352",
    zip_name: null,
  },
  {
    postal_code: "37011",
    zip_name: null,
  },
  {
    postal_code: "87153",
    zip_name: null,
  },
  {
    postal_code: "84068",
    zip_name: null,
  },
  {
    postal_code: "17081",
    zip_name: null,
  },
  {
    postal_code: "12784",
    zip_name: null,
  },
  {
    postal_code: "16140",
    zip_name: null,
  },
  {
    postal_code: "22833",
    zip_name: null,
  },
  {
    postal_code: "5460",
    zip_name: null,
  },
  {
    postal_code: "53101",
    zip_name: null,
  },
  {
    postal_code: "84623",
    zip_name: null,
  },
  {
    postal_code: "96850",
    zip_name: null,
  },
  {
    postal_code: "13138",
    zip_name: null,
  },
  {
    postal_code: "18813",
    zip_name: null,
  },
  {
    postal_code: "47361",
    zip_name: null,
  },
  {
    postal_code: "98637",
    zip_name: null,
  },
  {
    postal_code: "36564",
    zip_name: null,
  },
  {
    postal_code: "98343",
    zip_name: null,
  },
  {
    postal_code: "28616",
    zip_name: null,
  },
  {
    postal_code: "43142",
    zip_name: null,
  },
  {
    postal_code: "72441",
    zip_name: null,
  },
  {
    postal_code: "15455",
    zip_name: null,
  },
  {
    postal_code: "47335",
    zip_name: null,
  },
  {
    postal_code: "28350",
    zip_name: null,
  },
  {
    postal_code: "27555",
    zip_name: null,
  },
  {
    postal_code: "28053",
    zip_name: null,
  },
  {
    postal_code: "64858",
    zip_name: null,
  },
  {
    postal_code: "25818",
    zip_name: null,
  },
  {
    postal_code: "43981",
    zip_name: null,
  },
  {
    postal_code: "17303",
    zip_name: null,
  },
  {
    postal_code: "18911",
    zip_name: null,
  },
  {
    postal_code: "18323",
    zip_name: null,
  },
  {
    postal_code: "38324",
    zip_name: null,
  },
  {
    postal_code: "38338",
    zip_name: null,
  },
  {
    postal_code: "5746",
    zip_name: null,
  },
  {
    postal_code: "13647",
    zip_name: null,
  },
  {
    postal_code: "12504",
    zip_name: null,
  },
  {
    postal_code: "62891",
    zip_name: null,
  },
  {
    postal_code: "60974",
    zip_name: null,
  },
  {
    postal_code: "12820",
    zip_name: null,
  },
  {
    postal_code: "3911",
    zip_name: null,
  },
  {
    postal_code: "18963",
    zip_name: null,
  },
  {
    postal_code: "98623",
    zip_name: null,
  },
  {
    postal_code: "23407",
    zip_name: null,
  },
  {
    postal_code: "32463",
    zip_name: null,
  },
  {
    postal_code: "49289",
    zip_name: null,
  },
  {
    postal_code: "73520",
    zip_name: null,
  },
  {
    postal_code: "77970",
    zip_name: null,
  },
  {
    postal_code: "28519",
    zip_name: null,
  },
  {
    postal_code: "25873",
    zip_name: null,
  },
  {
    postal_code: "70371",
    zip_name: null,
  },
  {
    postal_code: "25002",
    zip_name: null,
  },
  {
    postal_code: "99550",
    zip_name: null,
  },
  {
    postal_code: "89016",
    zip_name: null,
  },
  {
    postal_code: "95924",
    zip_name: null,
  },
  {
    postal_code: "87032",
    zip_name: null,
  },
  {
    postal_code: "75132",
    zip_name: null,
  },
  {
    postal_code: "70522",
    zip_name: null,
  },
  {
    postal_code: "12085",
    zip_name: null,
  },
  {
    postal_code: "16725",
    zip_name: null,
  },
  {
    postal_code: "28652",
    zip_name: null,
  },
  {
    postal_code: "44848",
    zip_name: null,
  },
  {
    postal_code: "44850",
    zip_name: null,
  },
  {
    postal_code: "70550",
    zip_name: null,
  },
  {
    postal_code: "8095",
    zip_name: null,
  },
  {
    postal_code: "29324",
    zip_name: null,
  },
  {
    postal_code: "31003",
    zip_name: null,
  },
  {
    postal_code: "28136",
    zip_name: null,
  },
  {
    postal_code: "2564",
    zip_name: null,
  },
  {
    postal_code: "3850",
    zip_name: null,
  },
  {
    postal_code: "65327",
    zip_name: null,
  },
  {
    postal_code: "47337",
    zip_name: null,
  },
  {
    postal_code: "98206",
    zip_name: null,
  },
  {
    postal_code: "12471",
    zip_name: null,
  },
  {
    postal_code: "8023",
    zip_name: null,
  },
  {
    postal_code: "55609",
    zip_name: null,
  },
  {
    postal_code: "23422",
    zip_name: null,
  },
  {
    postal_code: "87538",
    zip_name: null,
  },
  {
    postal_code: "4675",
    zip_name: null,
  },
  {
    postal_code: "12452",
    zip_name: null,
  },
  {
    postal_code: "73567",
    zip_name: null,
  },
  {
    postal_code: "23347",
    zip_name: null,
  },
  {
    postal_code: "24415",
    zip_name: null,
  },
  {
    postal_code: "8739",
    zip_name: null,
  },
  {
    postal_code: "77367",
    zip_name: null,
  },
  {
    postal_code: "77720",
    zip_name: null,
  },
  {
    postal_code: "27878",
    zip_name: null,
  },
  {
    postal_code: "5740",
    zip_name: null,
  },
  {
    postal_code: "73455",
    zip_name: null,
  },
  {
    postal_code: "23389",
    zip_name: null,
  },
  {
    postal_code: "26574",
    zip_name: null,
  },
  {
    postal_code: "48633",
    zip_name: null,
  },
  {
    postal_code: "96074",
    zip_name: null,
  },
  {
    postal_code: "11243",
    zip_name: null,
  },
  {
    postal_code: "91948",
    zip_name: null,
  },
  {
    postal_code: "37326",
    zip_name: null,
  },
  {
    postal_code: "14732",
    zip_name: null,
  },
  {
    postal_code: "46379",
    zip_name: null,
  },
  {
    postal_code: "15422",
    zip_name: null,
  },
  {
    postal_code: "12568",
    zip_name: null,
  },
  {
    postal_code: "25907",
    zip_name: null,
  },
  {
    postal_code: "15640",
    zip_name: null,
  },
  {
    postal_code: "71415",
    zip_name: null,
  },
  {
    postal_code: "74951",
    zip_name: null,
  },
  {
    postal_code: "30460",
    zip_name: null,
  },
  {
    postal_code: "15680",
    zip_name: null,
  },
  {
    postal_code: "18912",
    zip_name: null,
  },
  {
    postal_code: "15779",
    zip_name: null,
  },
  {
    postal_code: "94573",
    zip_name: null,
  },
  {
    postal_code: "15448",
    zip_name: null,
  },
  {
    postal_code: "85349",
    zip_name: null,
  },
  {
    postal_code: "89025",
    zip_name: null,
  },
  {
    postal_code: "71759",
    zip_name: null,
  },
  {
    postal_code: "74802",
    zip_name: null,
  },
  {
    postal_code: "4420",
    zip_name: null,
  },
  {
    postal_code: "77451",
    zip_name: null,
  },
  {
    postal_code: "13847",
    zip_name: null,
  },
  {
    postal_code: "31004",
    zip_name: null,
  },
  {
    postal_code: "25011",
    zip_name: null,
  },
  {
    postal_code: "74935",
    zip_name: null,
  },
  {
    postal_code: "21528",
    zip_name: null,
  },
  {
    postal_code: "49877",
    zip_name: null,
  },
  {
    postal_code: "27259",
    zip_name: null,
  },
  {
    postal_code: "14827",
    zip_name: null,
  },
  {
    postal_code: "23899",
    zip_name: null,
  },
  {
    postal_code: "12050",
    zip_name: null,
  },
  {
    postal_code: "49808",
    zip_name: null,
  },
  {
    postal_code: "4016",
    zip_name: null,
  },
  {
    postal_code: "27967",
    zip_name: null,
  },
  {
    postal_code: "62997",
    zip_name: null,
  },
  {
    postal_code: "8252",
    zip_name: null,
  },
  {
    postal_code: "13628",
    zip_name: null,
  },
  {
    postal_code: "77464",
    zip_name: null,
  },
  {
    postal_code: "2722",
    zip_name: null,
  },
  {
    postal_code: "55601",
    zip_name: null,
  },
  {
    postal_code: "25606",
    zip_name: null,
  },
  {
    postal_code: "49611",
    zip_name: null,
  },
  {
    postal_code: "89041",
    zip_name: null,
  },
  {
    postal_code: "32681",
    zip_name: null,
  },
  {
    postal_code: "99832",
    zip_name: null,
  },
  {
    postal_code: "16054",
    zip_name: null,
  },
  {
    postal_code: "28725",
    zip_name: null,
  },
  {
    postal_code: "29329",
    zip_name: null,
  },
  {
    postal_code: "15948",
    zip_name: null,
  },
  {
    postal_code: "37394",
    zip_name: null,
  },
  {
    postal_code: "44265",
    zip_name: null,
  },
  {
    postal_code: "39635",
    zip_name: null,
  },
  {
    postal_code: "95625",
    zip_name: null,
  },
  {
    postal_code: "35149",
    zip_name: null,
  },
  {
    postal_code: "44636",
    zip_name: null,
  },
  {
    postal_code: "29636",
    zip_name: null,
  },
  {
    postal_code: "25402",
    zip_name: null,
  },
  {
    postal_code: "48177",
    zip_name: null,
  },
  {
    postal_code: "43721",
    zip_name: null,
  },
  {
    postal_code: "43735",
    zip_name: null,
  },
  {
    postal_code: "99693",
    zip_name: null,
  },
  {
    postal_code: "26209",
    zip_name: null,
  },
  {
    postal_code: "19437",
    zip_name: null,
  },
  {
    postal_code: "8246",
    zip_name: null,
  },
  {
    postal_code: "77353",
    zip_name: null,
  },
  {
    postal_code: "23316",
    zip_name: null,
  },
  {
    postal_code: "38869",
    zip_name: null,
  },
  {
    postal_code: "31307",
    zip_name: null,
  },
  {
    postal_code: "8342",
    zip_name: null,
  },
  {
    postal_code: "21765",
    zip_name: null,
  },
  {
    postal_code: "17343",
    zip_name: null,
  },
  {
    postal_code: "18081",
    zip_name: null,
  },
  {
    postal_code: "25871",
    zip_name: null,
  },
  {
    postal_code: "12541",
    zip_name: null,
  },
  {
    postal_code: "59760",
    zip_name: null,
  },
  {
    postal_code: "28077",
    zip_name: null,
  },
  {
    postal_code: "71480",
    zip_name: null,
  },
  {
    postal_code: "12132",
    zip_name: null,
  },
  {
    postal_code: "5344",
    zip_name: null,
  },
  {
    postal_code: "1093",
    zip_name: null,
  },
  {
    postal_code: "23398",
    zip_name: null,
  },
  {
    postal_code: "2643",
    zip_name: null,
  },
  {
    postal_code: "25909",
    zip_name: null,
  },
  {
    postal_code: "25639",
    zip_name: null,
  },
  {
    postal_code: "22646",
    zip_name: null,
  },
  {
    postal_code: "76206",
    zip_name: null,
  },
  {
    postal_code: "32692",
    zip_name: null,
  },
  {
    postal_code: "20013",
    zip_name: null,
  },
  {
    postal_code: "18816",
    zip_name: null,
  },
  {
    postal_code: "20235",
    zip_name: null,
  },
  {
    postal_code: "46771",
    zip_name: null,
  },
  {
    postal_code: "62953",
    zip_name: null,
  },
  {
    postal_code: "12429",
    zip_name: null,
  },
  {
    postal_code: "47654",
    zip_name: null,
  },
  {
    postal_code: "47880",
    zip_name: null,
  },
  {
    postal_code: "28749",
    zip_name: null,
  },
  {
    postal_code: "5031",
    zip_name: null,
  },
  {
    postal_code: "22548",
    zip_name: null,
  },
  {
    postal_code: "74556",
    zip_name: null,
  },
  {
    postal_code: "1842",
    zip_name: null,
  },
  {
    postal_code: "30414",
    zip_name: null,
  },
  {
    postal_code: "78351",
    zip_name: null,
  },
  {
    postal_code: "25667",
    zip_name: null,
  },
  {
    postal_code: "78339",
    zip_name: null,
  },
  {
    postal_code: "61057",
    zip_name: null,
  },
  {
    postal_code: "83606",
    zip_name: null,
  },
  {
    postal_code: "3289",
    zip_name: null,
  },
  {
    postal_code: "40405",
    zip_name: null,
  },
  {
    postal_code: "2303",
    zip_name: null,
  },
  {
    postal_code: "38223",
    zip_name: null,
  },
  {
    postal_code: "2345",
    zip_name: null,
  },
  {
    postal_code: "8072",
    zip_name: null,
  },
  {
    postal_code: "45713",
    zip_name: null,
  },
  {
    postal_code: "42060",
    zip_name: null,
  },
  {
    postal_code: "60168",
    zip_name: null,
  },
  {
    postal_code: "43974",
    zip_name: null,
  },
  {
    postal_code: "2337",
    zip_name: null,
  },
  {
    postal_code: "27879",
    zip_name: null,
  },
  {
    postal_code: "62622",
    zip_name: null,
  },
  {
    postal_code: "17041",
    zip_name: null,
  },
  {
    postal_code: "29744",
    zip_name: null,
  },
  {
    postal_code: "42037",
    zip_name: null,
  },
  {
    postal_code: "27582",
    zip_name: null,
  },
  {
    postal_code: "91334",
    zip_name: null,
  },
  {
    postal_code: "29931",
    zip_name: null,
  },
  {
    postal_code: "30448",
    zip_name: null,
  },
  {
    postal_code: "28201",
    zip_name: null,
  },
  {
    postal_code: "79233",
    zip_name: null,
  },
  {
    postal_code: "49422",
    zip_name: null,
  },
  {
    postal_code: "66110",
    zip_name: null,
  },
  {
    postal_code: "32862",
    zip_name: null,
  },
  {
    postal_code: "73031",
    zip_name: null,
  },
  {
    postal_code: "43934",
    zip_name: null,
  },
  {
    postal_code: "43553",
    zip_name: null,
  },
  {
    postal_code: "23439",
    zip_name: null,
  },
  {
    postal_code: "29675",
    zip_name: null,
  },
  {
    postal_code: "45070",
    zip_name: null,
  },
  {
    postal_code: "19360",
    zip_name: null,
  },
  {
    postal_code: "16035",
    zip_name: null,
  },
  {
    postal_code: "44493",
    zip_name: null,
  },
  {
    postal_code: "27010",
    zip_name: null,
  },
  {
    postal_code: "34973",
    zip_name: null,
  },
  {
    postal_code: "23326",
    zip_name: null,
  },
  {
    postal_code: "33307",
    zip_name: null,
  },
  {
    postal_code: "98238",
    zip_name: null,
  },
  {
    postal_code: "30726",
    zip_name: null,
  },
  {
    postal_code: "75636",
    zip_name: null,
  },
  {
    postal_code: "30237",
    zip_name: null,
  },
  {
    postal_code: "5664",
    zip_name: null,
  },
  {
    postal_code: "44080",
    zip_name: null,
  },
  {
    postal_code: "27877",
    zip_name: null,
  },
  {
    postal_code: "12588",
    zip_name: null,
  },
  {
    postal_code: "98082",
    zip_name: null,
  },
  {
    postal_code: "19457",
    zip_name: null,
  },
  {
    postal_code: "77580",
    zip_name: null,
  },
  {
    postal_code: "18348",
    zip_name: null,
  },
  {
    postal_code: "43151",
    zip_name: null,
  },
  {
    postal_code: "15693",
    zip_name: null,
  },
  {
    postal_code: "95435",
    zip_name: null,
  },
  {
    postal_code: "8557",
    zip_name: null,
  },
  {
    postal_code: "12848",
    zip_name: null,
  },
  {
    postal_code: "30151",
    zip_name: null,
  },
  {
    postal_code: "77850",
    zip_name: null,
  },
  {
    postal_code: "35185",
    zip_name: null,
  },
  {
    postal_code: "28145",
    zip_name: null,
  },
  {
    postal_code: "78335",
    zip_name: null,
  },
  {
    postal_code: "1508",
    zip_name: null,
  },
  {
    postal_code: "39457",
    zip_name: null,
  },
  {
    postal_code: "43111",
    zip_name: null,
  },
  {
    postal_code: "3866",
    zip_name: null,
  },
  {
    postal_code: "25652",
    zip_name: null,
  },
  {
    postal_code: "21720",
    zip_name: null,
  },
  {
    postal_code: "29319",
    zip_name: null,
  },
  {
    postal_code: "29938",
    zip_name: null,
  },
  {
    postal_code: "93258",
    zip_name: null,
  },
  {
    postal_code: "63752",
    zip_name: null,
  },
  {
    postal_code: "12602",
    zip_name: null,
  },
  {
    postal_code: "65636",
    zip_name: null,
  },
  {
    postal_code: "20138",
    zip_name: null,
  },
  {
    postal_code: "16366",
    zip_name: null,
  },
  {
    postal_code: "94543",
    zip_name: null,
  },
  {
    postal_code: "46047",
    zip_name: null,
  },
  {
    postal_code: "25810",
    zip_name: null,
  },
  {
    postal_code: "84515",
    zip_name: null,
  },
  {
    postal_code: "17506",
    zip_name: null,
  },
  {
    postal_code: "2553",
    zip_name: null,
  },
  {
    postal_code: "49063",
    zip_name: null,
  },
  {
    postal_code: "43941",
    zip_name: null,
  },
  {
    postal_code: "61524",
    zip_name: null,
  },
  {
    postal_code: "75779",
    zip_name: null,
  },
  {
    postal_code: "54985",
    zip_name: null,
  },
  {
    postal_code: "99020",
    zip_name: null,
  },
  {
    postal_code: "95680",
    zip_name: null,
  },
  {
    postal_code: "76253",
    zip_name: null,
  },
  {
    postal_code: "74446",
    zip_name: null,
  },
  {
    postal_code: "80497",
    zip_name: null,
  },
  {
    postal_code: "13623",
    zip_name: null,
  },
  {
    postal_code: "13139",
    zip_name: null,
  },
  {
    postal_code: "28424",
    zip_name: null,
  },
  {
    postal_code: "43505",
    zip_name: null,
  },
  {
    postal_code: "36536",
    zip_name: null,
  },
  {
    postal_code: "19450",
    zip_name: null,
  },
  {
    postal_code: "13738",
    zip_name: null,
  },
  {
    postal_code: "2887",
    zip_name: null,
  },
  {
    postal_code: "74943",
    zip_name: null,
  },
  {
    postal_code: "70078",
    zip_name: null,
  },
  {
    postal_code: "71903",
    zip_name: null,
  },
  {
    postal_code: "45413",
    zip_name: null,
  },
  {
    postal_code: "14742",
    zip_name: null,
  },
  {
    postal_code: "38036",
    zip_name: null,
  },
  {
    postal_code: "40803",
    zip_name: null,
  },
  {
    postal_code: "45235",
    zip_name: null,
  },
  {
    postal_code: "56075",
    zip_name: null,
  },
  {
    postal_code: "74761",
    zip_name: null,
  },
  {
    postal_code: "12345",
    zip_name: null,
  },
  {
    postal_code: "35266",
    zip_name: null,
  },
  {
    postal_code: "98559",
    zip_name: null,
  },
  {
    postal_code: "12769",
    zip_name: null,
  },
  {
    postal_code: "95375",
    zip_name: null,
  },
  {
    postal_code: "47434",
    zip_name: null,
  },
  {
    postal_code: "96078",
    zip_name: null,
  },
  {
    postal_code: "60519",
    zip_name: null,
  },
  {
    postal_code: "17920",
    zip_name: null,
  },
  {
    postal_code: "17016",
    zip_name: null,
  },
  {
    postal_code: "27868",
    zip_name: null,
  },
  {
    postal_code: "13062",
    zip_name: null,
  },
  {
    postal_code: "30140",
    zip_name: null,
  },
  {
    postal_code: "29338",
    zip_name: null,
  },
  {
    postal_code: "98353",
    zip_name: null,
  },
  {
    postal_code: "49626",
    zip_name: null,
  },
  {
    postal_code: "77961",
    zip_name: null,
  },
  {
    postal_code: "28072",
    zip_name: null,
  },
  {
    postal_code: "28041",
    zip_name: null,
  },
  {
    postal_code: "44828",
    zip_name: null,
  },
  {
    postal_code: "43336",
    zip_name: null,
  },
  {
    postal_code: "12975",
    zip_name: null,
  },
  {
    postal_code: "18473",
    zip_name: null,
  },
  {
    postal_code: "73901",
    zip_name: null,
  },
  {
    postal_code: "16048",
    zip_name: null,
  },
  {
    postal_code: "98164",
    zip_name: null,
  },
  {
    postal_code: "74942",
    zip_name: null,
  },
  {
    postal_code: "96037",
    zip_name: null,
  },
  {
    postal_code: "22530",
    zip_name: null,
  },
  {
    postal_code: "53940",
    zip_name: null,
  },
  {
    postal_code: "89595",
    zip_name: null,
  },
  {
    postal_code: "95233",
    zip_name: null,
  },
  {
    postal_code: "74720",
    zip_name: null,
  },
  {
    postal_code: "12929",
    zip_name: null,
  },
  {
    postal_code: "56056",
    zip_name: null,
  },
  {
    postal_code: "2661",
    zip_name: null,
  },
  {
    postal_code: "77466",
    zip_name: null,
  },
  {
    postal_code: "28109",
    zip_name: null,
  },
  {
    postal_code: "61276",
    zip_name: null,
  },
  {
    postal_code: "61433",
    zip_name: null,
  },
  {
    postal_code: "45155",
    zip_name: null,
  },
  {
    postal_code: "78342",
    zip_name: null,
  },
  {
    postal_code: "2334",
    zip_name: null,
  },
  {
    postal_code: "70787",
    zip_name: null,
  },
  {
    postal_code: "26422",
    zip_name: null,
  },
  {
    postal_code: "33858",
    zip_name: null,
  },
  {
    postal_code: "60944",
    zip_name: null,
  },
  {
    postal_code: "28662",
    zip_name: null,
  },
  {
    postal_code: "77617",
    zip_name: null,
  },
  {
    postal_code: "79012",
    zip_name: null,
  },
  {
    postal_code: "96714",
    zip_name: null,
  },
  {
    postal_code: "52652",
    zip_name: null,
  },
  {
    postal_code: "8018",
    zip_name: null,
  },
  {
    postal_code: "44439",
    zip_name: null,
  },
  {
    postal_code: "32572",
    zip_name: null,
  },
  {
    postal_code: "28728",
    zip_name: null,
  },
  {
    postal_code: "75848",
    zip_name: null,
  },
  {
    postal_code: "77453",
    zip_name: null,
  },
  {
    postal_code: "93665",
    zip_name: null,
  },
  {
    postal_code: "79231",
    zip_name: null,
  },
  {
    postal_code: "44816",
    zip_name: null,
  },
  {
    postal_code: "28757",
    zip_name: null,
  },
  {
    postal_code: "43531",
    zip_name: null,
  },
  {
    postal_code: "45132",
    zip_name: null,
  },
  {
    postal_code: "95646",
    zip_name: null,
  },
  {
    postal_code: "81232",
    zip_name: null,
  },
  {
    postal_code: "90010",
    zip_name: null,
  },
  {
    postal_code: "32772",
    zip_name: null,
  },
  {
    postal_code: "28102",
    zip_name: null,
  },
  {
    postal_code: "28284",
    zip_name: null,
  },
  {
    postal_code: "11931",
    zip_name: null,
  },
  {
    postal_code: "93921",
    zip_name: null,
  },
  {
    postal_code: "83025",
    zip_name: null,
  },
  {
    postal_code: "85117",
    zip_name: null,
  },
  {
    postal_code: "96737",
    zip_name: null,
  },
  {
    postal_code: "30599",
    zip_name: null,
  },
  {
    postal_code: "15689",
    zip_name: null,
  },
  {
    postal_code: "33975",
    zip_name: null,
  },
  {
    postal_code: "26572",
    zip_name: null,
  },
  {
    postal_code: "72216",
    zip_name: null,
  },
  {
    postal_code: "14112",
    zip_name: null,
  },
  {
    postal_code: "47463",
    zip_name: null,
  },
  {
    postal_code: "26504",
    zip_name: null,
  },
  {
    postal_code: "28628",
    zip_name: null,
  },
  {
    postal_code: "30169",
    zip_name: null,
  },
  {
    postal_code: "26302",
    zip_name: null,
  },
  {
    postal_code: "5049",
    zip_name: null,
  },
  {
    postal_code: "18239",
    zip_name: null,
  },
  {
    postal_code: "22957",
    zip_name: null,
  },
  {
    postal_code: "33601",
    zip_name: null,
  },
  {
    postal_code: "46937",
    zip_name: null,
  },
  {
    postal_code: "3746",
    zip_name: null,
  },
  {
    postal_code: "22038",
    zip_name: null,
  },
  {
    postal_code: "2662",
    zip_name: null,
  },
  {
    postal_code: "73070",
    zip_name: null,
  },
  {
    postal_code: "70502",
    zip_name: null,
  },
  {
    postal_code: "79708",
    zip_name: null,
  },
  {
    postal_code: "32361",
    zip_name: null,
  },
  {
    postal_code: "49627",
    zip_name: null,
  },
  {
    postal_code: "70616",
    zip_name: null,
  },
  {
    postal_code: "24505",
    zip_name: null,
  },
  {
    postal_code: "96127",
    zip_name: null,
  },
  {
    postal_code: "2634",
    zip_name: null,
  },
  {
    postal_code: "72203",
    zip_name: null,
  },
  {
    postal_code: "79032",
    zip_name: null,
  },
  {
    postal_code: "87576",
    zip_name: null,
  },
  {
    postal_code: "97307",
    zip_name: null,
  },
  {
    postal_code: "95518",
    zip_name: null,
  },
  {
    postal_code: "62348",
    zip_name: null,
  },
  {
    postal_code: "28509",
    zip_name: null,
  },
  {
    postal_code: "83635",
    zip_name: null,
  },
  {
    postal_code: "47402",
    zip_name: null,
  },
  {
    postal_code: "18971",
    zip_name: null,
  },
  {
    postal_code: "99731",
    zip_name: null,
  },
  {
    postal_code: "25326",
    zip_name: null,
  },
  {
    postal_code: "18910",
    zip_name: null,
  },
  {
    postal_code: "39753",
    zip_name: null,
  },
  {
    postal_code: "77473",
    zip_name: null,
  },
  {
    postal_code: "30129",
    zip_name: null,
  },
  {
    postal_code: "14547",
    zip_name: null,
  },
  {
    postal_code: "34487",
    zip_name: null,
  },
  {
    postal_code: "74438",
    zip_name: null,
  },
  {
    postal_code: "43048",
    zip_name: null,
  },
  {
    postal_code: "5451",
    zip_name: null,
  },
  {
    postal_code: "12248",
    zip_name: null,
  },
  {
    postal_code: "24063",
    zip_name: null,
  },
  {
    postal_code: "78564",
    zip_name: null,
  },
  {
    postal_code: "27906",
    zip_name: null,
  },
  {
    postal_code: "25688",
    zip_name: null,
  },
  {
    postal_code: "7902",
    zip_name: null,
  },
  {
    postal_code: "3802",
    zip_name: null,
  },
  {
    postal_code: "75942",
    zip_name: null,
  },
  {
    postal_code: "24878",
    zip_name: null,
  },
  {
    postal_code: "8101",
    zip_name: null,
  },
  {
    postal_code: "72217",
    zip_name: null,
  },
  {
    postal_code: "24619",
    zip_name: null,
  },
  {
    postal_code: "45063",
    zip_name: null,
  },
  {
    postal_code: "16730",
    zip_name: null,
  },
  {
    postal_code: "30359",
    zip_name: null,
  },
  {
    postal_code: "82422",
    zip_name: null,
  },
  {
    postal_code: "75504",
    zip_name: null,
  },
  {
    postal_code: "53171",
    zip_name: null,
  },
  {
    postal_code: "28744",
    zip_name: null,
  },
  {
    postal_code: "35048",
    zip_name: null,
  },
  {
    postal_code: "32079",
    zip_name: null,
  },
  {
    postal_code: "37161",
    zip_name: null,
  },
  {
    postal_code: "88102",
    zip_name: null,
  },
  {
    postal_code: "81046",
    zip_name: null,
  },
  {
    postal_code: "92286",
    zip_name: null,
  },
  {
    postal_code: "11431",
    zip_name: null,
  },
  {
    postal_code: "49084",
    zip_name: null,
  },
  {
    postal_code: "49041",
    zip_name: null,
  },
  {
    postal_code: "15454",
    zip_name: null,
  },
  {
    postal_code: "5863",
    zip_name: null,
  },
  {
    postal_code: "32422",
    zip_name: null,
  },
  {
    postal_code: "84626",
    zip_name: null,
  },
  {
    postal_code: "77542",
    zip_name: null,
  },
  {
    postal_code: "45055",
    zip_name: null,
  },
  {
    postal_code: "14538",
    zip_name: null,
  },
  {
    postal_code: "57358",
    zip_name: null,
  },
  {
    postal_code: "78246",
    zip_name: null,
  },
  {
    postal_code: "20140",
    zip_name: null,
  },
  {
    postal_code: "22040",
    zip_name: null,
  },
  {
    postal_code: "14588",
    zip_name: null,
  },
  {
    postal_code: "20128",
    zip_name: null,
  },
  {
    postal_code: "28554",
    zip_name: null,
  },
  {
    postal_code: "2065",
    zip_name: null,
  },
  {
    postal_code: "36533",
    zip_name: null,
  },
  {
    postal_code: "95416",
    zip_name: null,
  },
  {
    postal_code: "7091",
    zip_name: null,
  },
  {
    postal_code: "28223",
    zip_name: null,
  },
  {
    postal_code: "19470",
    zip_name: null,
  },
  {
    postal_code: "99252",
    zip_name: null,
  },
  {
    postal_code: "23443",
    zip_name: null,
  },
  {
    postal_code: "28076",
    zip_name: null,
  },
  {
    postal_code: "25672",
    zip_name: null,
  },
  {
    postal_code: "83303",
    zip_name: null,
  },
  {
    postal_code: "23479",
    zip_name: null,
  },
  {
    postal_code: "92864",
    zip_name: null,
  },
  {
    postal_code: "87305",
    zip_name: null,
  },
  {
    postal_code: "501",
    zip_name: null,
  },
  {
    postal_code: "78553",
    zip_name: null,
  },
  {
    postal_code: "72702",
    zip_name: null,
  },
  {
    postal_code: "17010",
    zip_name: null,
  },
  {
    postal_code: "48901",
    zip_name: null,
  },
  {
    postal_code: "42460",
    zip_name: null,
  },
  {
    postal_code: "25906",
    zip_name: null,
  },
  {
    postal_code: "23955",
    zip_name: null,
  },
  {
    postal_code: "7877",
    zip_name: null,
  },
  {
    postal_code: "75772",
    zip_name: null,
  },
  {
    postal_code: "38378",
    zip_name: null,
  },
  {
    postal_code: "17039",
    zip_name: null,
  },
  {
    postal_code: "7099",
    zip_name: null,
  },
  {
    postal_code: "25943",
    zip_name: null,
  },
  {
    postal_code: "18223",
    zip_name: null,
  },
  {
    postal_code: "54643",
    zip_name: null,
  },
  {
    postal_code: "83229",
    zip_name: null,
  },
  {
    postal_code: "40018",
    zip_name: null,
  },
  {
    postal_code: "5601",
    zip_name: null,
  },
  {
    postal_code: "24457",
    zip_name: null,
  },
  {
    postal_code: "6602",
    zip_name: null,
  },
  {
    postal_code: "87504",
    zip_name: null,
  },
  {
    postal_code: "41517",
    zip_name: null,
  },
  {
    postal_code: "28688",
    zip_name: null,
  },
  {
    postal_code: "54415",
    zip_name: null,
  },
  {
    postal_code: "78551",
    zip_name: null,
  },
  {
    postal_code: "98297",
    zip_name: null,
  },
  {
    postal_code: "13455",
    zip_name: null,
  },
  {
    postal_code: "15674",
    zip_name: null,
  },
  {
    postal_code: "42463",
    zip_name: null,
  },
  {
    postal_code: "21022",
    zip_name: null,
  },
  {
    postal_code: "21556",
    zip_name: null,
  },
  {
    postal_code: "3469",
    zip_name: null,
  },
  {
    postal_code: "17317",
    zip_name: null,
  },
  {
    postal_code: "47683",
    zip_name: null,
  },
  {
    postal_code: "77333",
    zip_name: null,
  },
  {
    postal_code: "46942",
    zip_name: null,
  },
  {
    postal_code: "94914",
    zip_name: null,
  },
  {
    postal_code: "30722",
    zip_name: null,
  },
  {
    postal_code: "30081",
    zip_name: null,
  },
  {
    postal_code: "61328",
    zip_name: null,
  },
  {
    postal_code: "81414",
    zip_name: null,
  },
  {
    postal_code: "29578",
    zip_name: null,
  },
  {
    postal_code: "27213",
    zip_name: null,
  },
  {
    postal_code: "38825",
    zip_name: null,
  },
  {
    postal_code: "30515",
    zip_name: null,
  },
  {
    postal_code: "95373",
    zip_name: null,
  },
  {
    postal_code: "32183",
    zip_name: null,
  },
  {
    postal_code: "25183",
    zip_name: null,
  },
  {
    postal_code: "18065",
    zip_name: null,
  },
  {
    postal_code: "85628",
    zip_name: null,
  },
  {
    postal_code: "25972",
    zip_name: null,
  },
  {
    postal_code: "43520",
    zip_name: null,
  },
  {
    postal_code: "17507",
    zip_name: null,
  },
  {
    postal_code: "62532",
    zip_name: null,
  },
  {
    postal_code: "87518",
    zip_name: null,
  },
  {
    postal_code: "98293",
    zip_name: null,
  },
  {
    postal_code: "43441",
    zip_name: null,
  },
  {
    postal_code: "78842",
    zip_name: null,
  },
  {
    postal_code: "3910",
    zip_name: null,
  },
  {
    postal_code: "71659",
    zip_name: null,
  },
  {
    postal_code: "29062",
    zip_name: null,
  },
  {
    postal_code: "14702",
    zip_name: null,
  },
  {
    postal_code: "77631",
    zip_name: null,
  },
  {
    postal_code: "49430",
    zip_name: null,
  },
  {
    postal_code: "46345",
    zip_name: null,
  },
  {
    postal_code: "76503",
    zip_name: null,
  },
  {
    postal_code: "94119",
    zip_name: null,
  },
  {
    postal_code: "88062",
    zip_name: null,
  },
  {
    postal_code: "1931",
    zip_name: null,
  },
  {
    postal_code: "35032",
    zip_name: null,
  },
  {
    postal_code: "24851",
    zip_name: null,
  },
  {
    postal_code: "6072",
    zip_name: null,
  },
  {
    postal_code: "20643",
    zip_name: null,
  },
  {
    postal_code: "74837",
    zip_name: null,
  },
  {
    postal_code: "4343",
    zip_name: null,
  },
  {
    postal_code: "14151",
    zip_name: null,
  },
  {
    postal_code: "39818",
    zip_name: null,
  },
  {
    postal_code: "38723",
    zip_name: null,
  },
  {
    postal_code: "19482",
    zip_name: null,
  },
  {
    postal_code: "5768",
    zip_name: null,
  },
  {
    postal_code: "74722",
    zip_name: null,
  },
  {
    postal_code: "36476",
    zip_name: null,
  },
  {
    postal_code: "8347",
    zip_name: null,
  },
  {
    postal_code: "24604",
    zip_name: null,
  },
  {
    postal_code: "23694",
    zip_name: null,
  },
  {
    postal_code: "89155",
    zip_name: null,
  },
  {
    postal_code: "72829",
    zip_name: null,
  },
  {
    postal_code: "34660",
    zip_name: null,
  },
  {
    postal_code: "25441",
    zip_name: null,
  },
  {
    postal_code: "77404",
    zip_name: null,
  },
  {
    postal_code: "3769",
    zip_name: null,
  },
  {
    postal_code: "32111",
    zip_name: null,
  },
  {
    postal_code: "29216",
    zip_name: null,
  },
  {
    postal_code: "29222",
    zip_name: null,
  },
  {
    postal_code: "81329",
    zip_name: null,
  },
  {
    postal_code: "88241",
    zip_name: null,
  },
  {
    postal_code: "43005",
    zip_name: null,
  },
  {
    postal_code: "4415",
    zip_name: null,
  },
  {
    postal_code: "32710",
    zip_name: null,
  },
  {
    postal_code: "30703",
    zip_name: null,
  },
  {
    postal_code: "53187",
    zip_name: null,
  },
  {
    postal_code: "96784",
    zip_name: null,
  },
  {
    postal_code: "39181",
    zip_name: null,
  },
  {
    postal_code: "23162",
    zip_name: null,
  },
  {
    postal_code: "20546",
    zip_name: null,
  },
  {
    postal_code: "77549",
    zip_name: null,
  },
  {
    postal_code: "81420",
    zip_name: null,
  },
  {
    postal_code: "75802",
    zip_name: null,
  },
  {
    postal_code: "95229",
    zip_name: null,
  },
  {
    postal_code: "76468",
    zip_name: null,
  },
  {
    postal_code: "30085",
    zip_name: null,
  },
  {
    postal_code: "92659",
    zip_name: null,
  },
  {
    postal_code: "54429",
    zip_name: null,
  },
  {
    postal_code: "27533",
    zip_name: null,
  },
  {
    postal_code: "41810",
    zip_name: null,
  },
  {
    postal_code: "39121",
    zip_name: null,
  },
  {
    postal_code: "15028",
    zip_name: null,
  },
  {
    postal_code: "30289",
    zip_name: null,
  },
  {
    postal_code: "75834",
    zip_name: null,
  },
  {
    postal_code: "24881",
    zip_name: null,
  },
  {
    postal_code: "30271",
    zip_name: null,
  },
  {
    postal_code: "24843",
    zip_name: null,
  },
  {
    postal_code: "70651",
    zip_name: null,
  },
  {
    postal_code: "36547",
    zip_name: null,
  },
  {
    postal_code: "56502",
    zip_name: null,
  },
  {
    postal_code: "40392",
    zip_name: null,
  },
  {
    postal_code: "35559",
    zip_name: null,
  },
  {
    postal_code: "99214",
    zip_name: null,
  },
  {
    postal_code: "77350",
    zip_name: null,
  },
  {
    postal_code: "14786",
    zip_name: null,
  },
  {
    postal_code: "28758",
    zip_name: null,
  },
  {
    postal_code: "47869",
    zip_name: null,
  },
  {
    postal_code: "78767",
    zip_name: null,
  },
  {
    postal_code: "75443",
    zip_name: null,
  },
  {
    postal_code: "12128",
    zip_name: null,
  },
  {
    postal_code: "79025",
    zip_name: null,
  },
  {
    postal_code: "54735",
    zip_name: null,
  },
  {
    postal_code: "30304",
    zip_name: null,
  },
  {
    postal_code: "72053",
    zip_name: null,
  },
  {
    postal_code: "29695",
    zip_name: null,
  },
  {
    postal_code: "27593",
    zip_name: null,
  },
  {
    postal_code: "28332",
    zip_name: null,
  },
  {
    postal_code: "78143",
    zip_name: null,
  },
  {
    postal_code: "48061",
    zip_name: null,
  },
  {
    postal_code: "28093",
    zip_name: null,
  },
  {
    postal_code: "2823",
    zip_name: null,
  },
  {
    postal_code: "62767",
    zip_name: null,
  },
  {
    postal_code: "29217",
    zip_name: null,
  },
  {
    postal_code: "71281",
    zip_name: null,
  },
  {
    postal_code: "53060",
    zip_name: null,
  },
  {
    postal_code: "30475",
    zip_name: null,
  },
  {
    postal_code: "17944",
    zip_name: null,
  },
  {
    postal_code: "4082",
    zip_name: null,
  },
  {
    postal_code: "43077",
    zip_name: null,
  },
  {
    postal_code: "25853",
    zip_name: null,
  },
  {
    postal_code: "56341",
    zip_name: null,
  },
  {
    postal_code: "30133",
    zip_name: null,
  },
  {
    postal_code: "95433",
    zip_name: null,
  },
  {
    postal_code: "37235",
    zip_name: null,
  },
  {
    postal_code: "49964",
    zip_name: null,
  },
  {
    postal_code: "77325",
    zip_name: null,
  },
  {
    postal_code: "70540",
    zip_name: null,
  },
  {
    postal_code: "34656",
    zip_name: null,
  },
  {
    postal_code: "27373",
    zip_name: null,
  },
  {
    postal_code: "89450",
    zip_name: null,
  },
  {
    postal_code: "20857",
    zip_name: null,
  },
  {
    postal_code: "54912",
    zip_name: null,
  },
  {
    postal_code: "21721",
    zip_name: null,
  },
  {
    postal_code: "79003",
    zip_name: null,
  },
  {
    postal_code: "23242",
    zip_name: null,
  },
  {
    postal_code: "8039",
    zip_name: null,
  },
  {
    postal_code: "72770",
    zip_name: null,
  },
  {
    postal_code: "16512",
    zip_name: null,
  },
  {
    postal_code: "25911",
    zip_name: null,
  },
  {
    postal_code: "61027",
    zip_name: null,
  },
  {
    postal_code: "1526",
    zip_name: null,
  },
  {
    postal_code: "28388",
    zip_name: null,
  },
  {
    postal_code: "43937",
    zip_name: null,
  },
  {
    postal_code: "94802",
    zip_name: null,
  },
  {
    postal_code: "78585",
    zip_name: null,
  },
  {
    postal_code: "98544",
    zip_name: null,
  },
  {
    postal_code: "19442",
    zip_name: null,
  },
  {
    postal_code: "92247",
    zip_name: null,
  },
  {
    postal_code: "11352",
    zip_name: null,
  },
  {
    postal_code: "17580",
    zip_name: null,
  },
  {
    postal_code: "62222",
    zip_name: null,
  },
  {
    postal_code: "30459",
    zip_name: null,
  },
  {
    postal_code: "12995",
    zip_name: null,
  },
  {
    postal_code: "3061",
    zip_name: null,
  },
  {
    postal_code: "76628",
    zip_name: null,
  },
  {
    postal_code: "37315",
    zip_name: null,
  },
  {
    postal_code: "44415",
    zip_name: null,
  },
  {
    postal_code: "13674",
    zip_name: null,
  },
  {
    postal_code: "10313",
    zip_name: null,
  },
  {
    postal_code: "27841",
    zip_name: null,
  },
  {
    postal_code: "78283",
    zip_name: null,
  },
  {
    postal_code: "71910",
    zip_name: null,
  },
  {
    postal_code: "38168",
    zip_name: null,
  },
  {
    postal_code: "7855",
    zip_name: null,
  },
  {
    postal_code: "37744",
    zip_name: null,
  },
  {
    postal_code: "96135",
    zip_name: null,
  },
  {
    postal_code: "47458",
    zip_name: null,
  },
  {
    postal_code: "11551",
    zip_name: null,
  },
  {
    postal_code: "93661",
    zip_name: null,
  },
  {
    postal_code: "15339",
    zip_name: null,
  },
  {
    postal_code: "43970",
    zip_name: null,
  },
  {
    postal_code: "34755",
    zip_name: null,
  },
  {
    postal_code: "40448",
    zip_name: null,
  },
  {
    postal_code: "60932",
    zip_name: null,
  },
  {
    postal_code: "17861",
    zip_name: null,
  },
  {
    postal_code: "93456",
    zip_name: null,
  },
  {
    postal_code: "20108",
    zip_name: null,
  },
  {
    postal_code: "19395",
    zip_name: null,
  },
  {
    postal_code: "20218",
    zip_name: null,
  },
  {
    postal_code: "20188",
    zip_name: null,
  },
  {
    postal_code: "79768",
    zip_name: null,
  },
  {
    postal_code: "22924",
    zip_name: null,
  },
  {
    postal_code: "75483",
    zip_name: null,
  },
  {
    postal_code: "71230",
    zip_name: null,
  },
  {
    postal_code: "73502",
    zip_name: null,
  },
  {
    postal_code: "30756",
    zip_name: null,
  },
  {
    postal_code: "30514",
    zip_name: null,
  },
  {
    postal_code: "73137",
    zip_name: null,
  },
  {
    postal_code: "75658",
    zip_name: null,
  },
  {
    postal_code: "12240",
    zip_name: null,
  },
  {
    postal_code: "45643",
    zip_name: null,
  },
  {
    postal_code: "31026",
    zip_name: null,
  },
  {
    postal_code: "45633",
    zip_name: null,
  },
  {
    postal_code: "94966",
    zip_name: null,
  },
  {
    postal_code: "31039",
    zip_name: null,
  },
  {
    postal_code: "14876",
    zip_name: null,
  },
  {
    postal_code: "17085",
    zip_name: null,
  },
  {
    postal_code: "45739",
    zip_name: null,
  },
  {
    postal_code: "88202",
    zip_name: null,
  },
  {
    postal_code: "2669",
    zip_name: null,
  },
  {
    postal_code: "61562",
    zip_name: null,
  },
  {
    postal_code: "35560",
    zip_name: null,
  },
  {
    postal_code: "27825",
    zip_name: null,
  },
  {
    postal_code: "17748",
    zip_name: null,
  },
  {
    postal_code: "95656",
    zip_name: null,
  },
  {
    postal_code: "33845",
    zip_name: null,
  },
  {
    postal_code: "17054",
    zip_name: null,
  },
  {
    postal_code: "71330",
    zip_name: null,
  },
  {
    postal_code: "22748",
    zip_name: null,
  },
  {
    postal_code: "18459",
    zip_name: null,
  },
  {
    postal_code: "87735",
    zip_name: null,
  },
  {
    postal_code: "12420",
    zip_name: null,
  },
  {
    postal_code: "38764",
    zip_name: null,
  },
  {
    postal_code: "30150",
    zip_name: null,
  },
  {
    postal_code: "5861",
    zip_name: null,
  },
  {
    postal_code: "33101",
    zip_name: null,
  },
  {
    postal_code: "30229",
    zip_name: null,
  },
  {
    postal_code: "98507",
    zip_name: null,
  },
  {
    postal_code: "49239",
    zip_name: null,
  },
  {
    postal_code: "40730",
    zip_name: null,
  },
  {
    postal_code: "35220",
    zip_name: null,
  },
  {
    postal_code: "32362",
    zip_name: null,
  },
  {
    postal_code: "99333",
    zip_name: null,
  },
  {
    postal_code: "76533",
    zip_name: null,
  },
  {
    postal_code: "25875",
    zip_name: null,
  },
  {
    postal_code: "72124",
    zip_name: null,
  },
  {
    postal_code: "35181",
    zip_name: null,
  },
  {
    postal_code: "30061",
    zip_name: null,
  },
  {
    postal_code: "30503",
    zip_name: null,
  },
  {
    postal_code: "32957",
    zip_name: null,
  },
  {
    postal_code: "29721",
    zip_name: null,
  },
  {
    postal_code: "96151",
    zip_name: null,
  },
  {
    postal_code: "93044",
    zip_name: null,
  },
  {
    postal_code: "89438",
    zip_name: null,
  },
  {
    postal_code: "27584",
    zip_name: null,
  },
  {
    postal_code: "6350",
    zip_name: null,
  },
  {
    postal_code: "30449",
    zip_name: null,
  },
  {
    postal_code: "7879",
    zip_name: null,
  },
  {
    postal_code: "77452",
    zip_name: null,
  },
  {
    postal_code: "3803",
    zip_name: null,
  },
  {
    postal_code: "63774",
    zip_name: null,
  },
  {
    postal_code: "28111",
    zip_name: null,
  },
  {
    postal_code: "28082",
    zip_name: null,
  },
  {
    postal_code: "29623",
    zip_name: null,
  },
  {
    postal_code: "5009",
    zip_name: null,
  },
  {
    postal_code: "63387",
    zip_name: null,
  },
  {
    postal_code: "1703",
    zip_name: null,
  },
  {
    postal_code: "68501",
    zip_name: null,
  },
  {
    postal_code: "6792",
    zip_name: null,
  },
  {
    postal_code: "78115",
    zip_name: null,
  },
  {
    postal_code: "11351",
    zip_name: null,
  },
  {
    postal_code: "89507",
    zip_name: null,
  },
  {
    postal_code: "71728",
    zip_name: null,
  },
  {
    postal_code: "92406",
    zip_name: null,
  },
  {
    postal_code: "21916",
    zip_name: null,
  },
  {
    postal_code: "50831",
    zip_name: null,
  },
  {
    postal_code: "37682",
    zip_name: null,
  },
  {
    postal_code: "81658",
    zip_name: null,
  },
  {
    postal_code: "42457",
    zip_name: null,
  },
  {
    postal_code: "45361",
    zip_name: null,
  },
  {
    postal_code: "28750",
    zip_name: null,
  },
  {
    postal_code: "13119",
    zip_name: null,
  },
  {
    postal_code: "43711",
    zip_name: null,
  },
  {
    postal_code: "60075",
    zip_name: null,
  },
  {
    postal_code: "70165",
    zip_name: null,
  },
  {
    postal_code: "25727",
    zip_name: null,
  },
  {
    postal_code: "56333",
    zip_name: null,
  },
  {
    postal_code: "95173",
    zip_name: null,
  },
  {
    postal_code: "67363",
    zip_name: null,
  },
  {
    postal_code: "41561",
    zip_name: null,
  },
  {
    postal_code: "98324",
    zip_name: null,
  },
  {
    postal_code: "17858",
    zip_name: null,
  },
  {
    postal_code: "18953",
    zip_name: null,
  },
  {
    postal_code: "27532",
    zip_name: null,
  },
  {
    postal_code: "77842",
    zip_name: null,
  },
  {
    postal_code: "30119",
    zip_name: null,
  },
  {
    postal_code: "23241",
    zip_name: null,
  },
  {
    postal_code: "77305",
    zip_name: null,
  },
  {
    postal_code: "74530",
    zip_name: null,
  },
  {
    postal_code: "44501",
    zip_name: null,
  },
  {
    postal_code: "5074",
    zip_name: null,
  },
  {
    postal_code: "27230",
    zip_name: null,
  },
  {
    postal_code: "26578",
    zip_name: null,
  },
  {
    postal_code: "28106",
    zip_name: null,
  },
  {
    postal_code: "43083",
    zip_name: null,
  },
  {
    postal_code: "27811",
    zip_name: null,
  },
  {
    postal_code: "87101",
    zip_name: null,
  },
  {
    postal_code: "28038",
    zip_name: null,
  },
  {
    postal_code: "49797",
    zip_name: null,
  },
  {
    postal_code: "93928",
    zip_name: null,
  },
  {
    postal_code: "29122",
    zip_name: null,
  },
  {
    postal_code: "12141",
    zip_name: null,
  },
  {
    postal_code: "22320",
    zip_name: null,
  },
  {
    postal_code: "76439",
    zip_name: null,
  },
  {
    postal_code: "43738",
    zip_name: null,
  },
  {
    postal_code: "5466",
    zip_name: null,
  },
  {
    postal_code: "95867",
    zip_name: null,
  },
  {
    postal_code: "7019",
    zip_name: null,
  },
  {
    postal_code: "20201",
    zip_name: null,
  },
  {
    postal_code: "8855",
    zip_name: null,
  },
  {
    postal_code: "45222",
    zip_name: null,
  },
  {
    postal_code: "2761",
    zip_name: null,
  },
  {
    postal_code: "18060",
    zip_name: null,
  },
  {
    postal_code: "12195",
    zip_name: null,
  },
  {
    postal_code: "52071",
    zip_name: null,
  },
  {
    postal_code: "13401",
    zip_name: null,
  },
  {
    postal_code: "13761",
    zip_name: null,
  },
  {
    postal_code: "73403",
    zip_name: null,
  },
  {
    postal_code: "79760",
    zip_name: null,
  },
  {
    postal_code: "19472",
    zip_name: null,
  },
  {
    postal_code: "7509",
    zip_name: null,
  },
  {
    postal_code: "78765",
    zip_name: null,
  },
  {
    postal_code: "71823",
    zip_name: null,
  },
  {
    postal_code: "33164",
    zip_name: null,
  },
  {
    postal_code: "44424",
    zip_name: null,
  },
  {
    postal_code: "16413",
    zip_name: null,
  },
  {
    postal_code: "17272",
    zip_name: null,
  },
  {
    postal_code: "19430",
    zip_name: null,
  },
  {
    postal_code: "71233",
    zip_name: null,
  },
  {
    postal_code: "28019",
    zip_name: null,
  },
  {
    postal_code: "41142",
    zip_name: null,
  },
  {
    postal_code: "38959",
    zip_name: null,
  },
  {
    postal_code: "49027",
    zip_name: null,
  },
  {
    postal_code: "31799",
    zip_name: null,
  },
  {
    postal_code: "39182",
    zip_name: null,
  },
  {
    postal_code: "73184",
    zip_name: null,
  },
  {
    postal_code: "28522",
    zip_name: null,
  },
  {
    postal_code: "2331",
    zip_name: null,
  },
  {
    postal_code: "73140",
    zip_name: null,
  },
  {
    postal_code: "31310",
    zip_name: null,
  },
  {
    postal_code: "61331",
    zip_name: null,
  },
  {
    postal_code: "84402",
    zip_name: null,
  },
  {
    postal_code: "36741",
    zip_name: null,
  },
  {
    postal_code: "49257",
    zip_name: null,
  },
  {
    postal_code: "20172",
    zip_name: null,
  },
  {
    postal_code: "22909",
    zip_name: null,
  },
  {
    postal_code: "16221",
    zip_name: null,
  },
  {
    postal_code: "77481",
    zip_name: null,
  },
  {
    postal_code: "35477",
    zip_name: null,
  },
  {
    postal_code: "45234",
    zip_name: null,
  },
  {
    postal_code: "1101",
    zip_name: null,
  },
  {
    postal_code: "34423",
    zip_name: null,
  },
  {
    postal_code: "63053",
    zip_name: null,
  },
  {
    postal_code: "2355",
    zip_name: null,
  },
  {
    postal_code: "88004",
    zip_name: null,
  },
  {
    postal_code: "22565",
    zip_name: null,
  },
  {
    postal_code: "17885",
    zip_name: null,
  },
  {
    postal_code: "41549",
    zip_name: null,
  },
  {
    postal_code: "38389",
    zip_name: null,
  },
  {
    postal_code: "20560",
    zip_name: null,
  },
  {
    postal_code: "12181",
    zip_name: null,
  },
  {
    postal_code: "32314",
    zip_name: null,
  },
  {
    postal_code: "54649",
    zip_name: null,
  },
  {
    postal_code: "56561",
    zip_name: null,
  },
  {
    postal_code: "35615",
    zip_name: null,
  },
  {
    postal_code: "79369",
    zip_name: null,
  },
  {
    postal_code: "45172",
    zip_name: null,
  },
  {
    postal_code: "78674",
    zip_name: null,
  },
  {
    postal_code: "33970",
    zip_name: null,
  },
  {
    postal_code: "3468",
    zip_name: null,
  },
  {
    postal_code: "93522",
    zip_name: null,
  },
  {
    postal_code: "4431",
    zip_name: null,
  },
  {
    postal_code: "18068",
    zip_name: null,
  },
  {
    postal_code: "5453",
    zip_name: null,
  },
  {
    postal_code: "92599",
    zip_name: null,
  },
  {
    postal_code: "16039",
    zip_name: null,
  },
  {
    postal_code: "28362",
    zip_name: null,
  },
  {
    postal_code: "6601",
    zip_name: null,
  },
  {
    postal_code: "2205",
    zip_name: null,
  },
  {
    postal_code: "83403",
    zip_name: null,
  },
  {
    postal_code: "19052",
    zip_name: null,
  },
  {
    postal_code: "29822",
    zip_name: null,
  },
  {
    postal_code: "78104",
    zip_name: null,
  },
  {
    postal_code: "89136",
    zip_name: null,
  },
  {
    postal_code: "31603",
    zip_name: null,
  },
  {
    postal_code: "36831",
    zip_name: null,
  },
  {
    postal_code: "98811",
    zip_name: null,
  },
  {
    postal_code: "18247",
    zip_name: null,
  },
  {
    postal_code: "16631",
    zip_name: null,
  },
  {
    postal_code: "14429",
    zip_name: null,
  },
  {
    postal_code: "33022",
    zip_name: null,
  },
  {
    postal_code: "72325",
    zip_name: null,
  },
  {
    postal_code: "38088",
    zip_name: null,
  },
  {
    postal_code: "42221",
    zip_name: null,
  },
  {
    postal_code: "63840",
    zip_name: null,
  },
  {
    postal_code: "14110",
    zip_name: null,
  },
  {
    postal_code: "63302",
    zip_name: null,
  },
  {
    postal_code: "30464",
    zip_name: null,
  },
  {
    postal_code: "10119",
    zip_name: null,
  },
  {
    postal_code: "95532",
    zip_name: null,
  },
  {
    postal_code: "22226",
    zip_name: null,
  },
  {
    postal_code: "40153",
    zip_name: null,
  },
  {
    postal_code: "36275",
    zip_name: null,
  },
  {
    postal_code: "27216",
    zip_name: null,
  },
  {
    postal_code: "81302",
    zip_name: null,
  },
  {
    postal_code: "27201",
    zip_name: null,
  },
  {
    postal_code: "23155",
    zip_name: null,
  },
  {
    postal_code: "93009",
    zip_name: null,
  },
  {
    postal_code: "30581",
    zip_name: null,
  },
  {
    postal_code: "32302",
    zip_name: null,
  },
  {
    postal_code: "92226",
    zip_name: null,
  },
  {
    postal_code: "39832",
    zip_name: null,
  },
  {
    postal_code: "48480",
    zip_name: null,
  },
  {
    postal_code: "97440",
    zip_name: null,
  },
  {
    postal_code: "17979",
    zip_name: null,
  },
  {
    postal_code: "24719",
    zip_name: null,
  },
  {
    postal_code: "74050",
    zip_name: null,
  },
  {
    postal_code: "72737",
    zip_name: null,
  },
  {
    postal_code: "88342",
    zip_name: null,
  },
  {
    postal_code: "80261",
    zip_name: null,
  },
  {
    postal_code: "23105",
    zip_name: null,
  },
  {
    postal_code: "33871",
    zip_name: null,
  },
  {
    postal_code: "72182",
    zip_name: null,
  },
  {
    postal_code: "85327",
    zip_name: null,
  },
  {
    postal_code: "24576",
    zip_name: null,
  },
  {
    postal_code: "72178",
    zip_name: null,
  },
  {
    postal_code: "40523",
    zip_name: null,
  },
  {
    postal_code: "30266",
    zip_name: null,
  },
  {
    postal_code: "27512",
    zip_name: null,
  },
  {
    postal_code: "30916",
    zip_name: null,
  },
  {
    postal_code: "34270",
    zip_name: null,
  },
  {
    postal_code: "77726",
    zip_name: null,
  },
  {
    postal_code: "17311",
    zip_name: null,
  },
  {
    postal_code: "10080",
    zip_name: null,
  },
  {
    postal_code: "38745",
    zip_name: null,
  },
  {
    postal_code: "44871",
    zip_name: null,
  },
  {
    postal_code: "23293",
    zip_name: null,
  },
  {
    postal_code: "17605",
    zip_name: null,
  },
  {
    postal_code: "13341",
    zip_name: null,
  },
  {
    postal_code: "29939",
    zip_name: null,
  },
  {
    postal_code: "2357",
    zip_name: null,
  },
  {
    postal_code: "94807",
    zip_name: null,
  },
  {
    postal_code: "85130",
    zip_name: null,
  },
  {
    postal_code: "28666",
    zip_name: null,
  },
  {
    postal_code: "45061",
    zip_name: null,
  },
  {
    postal_code: "21027",
    zip_name: null,
  },
  {
    postal_code: "36068",
    zip_name: null,
  },
  {
    postal_code: "14863",
    zip_name: null,
  },
  {
    postal_code: "4944",
    zip_name: null,
  },
  {
    postal_code: "20206",
    zip_name: null,
  },
  {
    postal_code: "16003",
    zip_name: null,
  },
  {
    postal_code: "19535",
    zip_name: null,
  },
  {
    postal_code: "20660",
    zip_name: null,
  },
  {
    postal_code: "46325",
    zip_name: null,
  },
  {
    postal_code: "33191",
    zip_name: null,
  },
  {
    postal_code: "28236",
    zip_name: null,
  },
  {
    postal_code: "27594",
    zip_name: null,
  },
  {
    postal_code: "25866",
    zip_name: null,
  },
  {
    postal_code: "70340",
    zip_name: null,
  },
  {
    postal_code: "10157",
    zip_name: null,
  },
  {
    postal_code: "30275",
    zip_name: null,
  },
  {
    postal_code: "64781",
    zip_name: null,
  },
  {
    postal_code: "77561",
    zip_name: null,
  },
  {
    postal_code: "79776",
    zip_name: null,
  },
  {
    postal_code: "36868",
    zip_name: null,
  },
  {
    postal_code: "95221",
    zip_name: null,
  },
  {
    postal_code: "4288",
    zip_name: null,
  },
  {
    postal_code: "72503",
    zip_name: null,
  },
  {
    postal_code: "98129",
    zip_name: null,
  },
  {
    postal_code: "2366",
    zip_name: null,
  },
  {
    postal_code: "50255",
    zip_name: null,
  },
  {
    postal_code: "28903",
    zip_name: null,
  },
  {
    postal_code: "77882",
    zip_name: null,
  },
  {
    postal_code: "47733",
    zip_name: null,
  },
  {
    postal_code: "8543",
    zip_name: null,
  },
  {
    postal_code: "46355",
    zip_name: null,
  },
  {
    postal_code: "31706",
    zip_name: null,
  },
  {
    postal_code: "75017",
    zip_name: null,
  },
  {
    postal_code: "72654",
    zip_name: null,
  },
  {
    postal_code: "77989",
    zip_name: null,
  },
  {
    postal_code: "2862",
    zip_name: null,
  },
  {
    postal_code: "92728",
    zip_name: null,
  },
  {
    postal_code: "30031",
    zip_name: null,
  },
  {
    postal_code: "21150",
    zip_name: null,
  },
  {
    postal_code: "48332",
    zip_name: null,
  },
  {
    postal_code: "18501",
    zip_name: null,
  },
  {
    postal_code: "20122",
    zip_name: null,
  },
  {
    postal_code: "61237",
    zip_name: null,
  },
  {
    postal_code: "48833",
    zip_name: null,
  },
  {
    postal_code: "46922",
    zip_name: null,
  },
  {
    postal_code: "40434",
    zip_name: null,
  },
  {
    postal_code: "30042",
    zip_name: null,
  },
  {
    postal_code: "63079",
    zip_name: null,
  },
  {
    postal_code: "5357",
    zip_name: null,
  },
  {
    postal_code: "54969",
    zip_name: null,
  },
  {
    postal_code: "19478",
    zip_name: null,
  },
  {
    postal_code: "13902",
    zip_name: null,
  },
  {
    postal_code: "82073",
    zip_name: null,
  },
  {
    postal_code: "27887",
    zip_name: null,
  },
  {
    postal_code: "63171",
    zip_name: null,
  },
  {
    postal_code: "68109",
    zip_name: null,
  },
  {
    postal_code: "59403",
    zip_name: null,
  },
  {
    postal_code: "22529",
    zip_name: null,
  },
  {
    postal_code: "78268",
    zip_name: null,
  },
  {
    postal_code: "40339",
    zip_name: null,
  },
  {
    postal_code: "71414",
    zip_name: null,
  },
  {
    postal_code: "14902",
    zip_name: null,
  },
  {
    postal_code: "71772",
    zip_name: null,
  },
  {
    postal_code: "12301",
    zip_name: null,
  },
  {
    postal_code: "77855",
    zip_name: null,
  },
  {
    postal_code: "33302",
    zip_name: null,
  },
  {
    postal_code: "95480",
    zip_name: null,
  },
  {
    postal_code: "27359",
    zip_name: null,
  },
  {
    postal_code: "94950",
    zip_name: null,
  },
  {
    postal_code: "11241",
    zip_name: null,
  },
  {
    postal_code: "18843",
    zip_name: null,
  },
  {
    postal_code: "36261",
    zip_name: null,
  },
  {
    postal_code: "24024",
    zip_name: null,
  },
  {
    postal_code: "57709",
    zip_name: null,
  },
  {
    postal_code: "65546",
    zip_name: null,
  },
  {
    postal_code: "81043",
    zip_name: null,
  },
  {
    postal_code: "5741",
    zip_name: null,
  },
  {
    postal_code: "8601",
    zip_name: null,
  },
  {
    postal_code: "98207",
    zip_name: null,
  },
  {
    postal_code: "71477",
    zip_name: null,
  },
  {
    postal_code: "93649",
    zip_name: null,
  },
  {
    postal_code: "29221",
    zip_name: null,
  },
  {
    postal_code: "34430",
    zip_name: null,
  },
  {
    postal_code: "29603",
    zip_name: null,
  },
  {
    postal_code: "72108",
    zip_name: null,
  },
  {
    postal_code: "72214",
    zip_name: null,
  },
  {
    postal_code: "79954",
    zip_name: null,
  },
  {
    postal_code: "45051",
    zip_name: null,
  },
  {
    postal_code: "4054",
    zip_name: null,
  },
  {
    postal_code: "65673",
    zip_name: null,
  },
  {
    postal_code: "36753",
    zip_name: null,
  },
  {
    postal_code: "81002",
    zip_name: null,
  },
  {
    postal_code: "32655",
    zip_name: null,
  },
  {
    postal_code: "25628",
    zip_name: null,
  },
  {
    postal_code: "6829",
    zip_name: null,
  },
  {
    postal_code: "60454",
    zip_name: null,
  },
  {
    postal_code: "47445",
    zip_name: null,
  },
  {
    postal_code: "17568",
    zip_name: null,
  },
  {
    postal_code: "8803",
    zip_name: null,
  },
  {
    postal_code: "70073",
    zip_name: null,
  },
  {
    postal_code: "16544",
    zip_name: null,
  },
  {
    postal_code: "29677",
    zip_name: null,
  },
  {
    postal_code: "22122",
    zip_name: null,
  },
  {
    postal_code: "4291",
    zip_name: null,
  },
  {
    postal_code: "29604",
    zip_name: null,
  },
  {
    postal_code: "38776",
    zip_name: null,
  },
  {
    postal_code: "38877",
    zip_name: null,
  },
  {
    postal_code: "16804",
    zip_name: null,
  },
  {
    postal_code: "21843",
    zip_name: null,
  },
  {
    postal_code: "42502",
    zip_name: null,
  },
  {
    postal_code: "75713",
    zip_name: null,
  },
  {
    postal_code: "72918",
    zip_name: null,
  },
  {
    postal_code: "47202",
    zip_name: null,
  },
  {
    postal_code: "28246",
    zip_name: null,
  },
  {
    postal_code: "27881",
    zip_name: null,
  },
  {
    postal_code: "83531",
    zip_name: null,
  },
  {
    postal_code: "35502",
    zip_name: null,
  },
  {
    postal_code: "35767",
    zip_name: null,
  },
  {
    postal_code: "53138",
    zip_name: null,
  },
  {
    postal_code: "76469",
    zip_name: null,
  },
  {
    postal_code: "62023",
    zip_name: null,
  },
  {
    postal_code: "75011",
    zip_name: null,
  },
  {
    postal_code: "75606",
    zip_name: null,
  },
  {
    postal_code: "29641",
    zip_name: null,
  },
  {
    postal_code: "21852",
    zip_name: null,
  },
  {
    postal_code: "23429",
    zip_name: null,
  },
  {
    postal_code: "29602",
    zip_name: null,
  },
  {
    postal_code: "27552",
    zip_name: null,
  },
  {
    postal_code: "63850",
    zip_name: null,
  },
  {
    postal_code: "77432",
    zip_name: null,
  },
  {
    postal_code: "38021",
    zip_name: null,
  },
  {
    postal_code: "54532",
    zip_name: null,
  },
  {
    postal_code: "20790",
    zip_name: null,
  },
  {
    postal_code: "41526",
    zip_name: null,
  },
  {
    postal_code: "96155",
    zip_name: null,
  },
  {
    postal_code: "43349",
    zip_name: null,
  },
  {
    postal_code: "37383",
    zip_name: null,
  },
  {
    postal_code: "72575",
    zip_name: null,
  },
  {
    postal_code: "10587",
    zip_name: null,
  },
  {
    postal_code: "62961",
    zip_name: null,
  },
  {
    postal_code: "2663",
    zip_name: null,
  },
  {
    postal_code: "63878",
    zip_name: null,
  },
  {
    postal_code: "18957",
    zip_name: null,
  },
  {
    postal_code: "93504",
    zip_name: null,
  },
  {
    postal_code: "14168",
    zip_name: null,
  },
  {
    postal_code: "95347",
    zip_name: null,
  },
  {
    postal_code: "39436",
    zip_name: null,
  },
  {
    postal_code: "29901",
    zip_name: null,
  },
  {
    postal_code: "7870",
    zip_name: null,
  },
  {
    postal_code: "73743",
    zip_name: null,
  },
  {
    postal_code: "22451",
    zip_name: null,
  },
  {
    postal_code: "29804",
    zip_name: null,
  },
  {
    postal_code: "72765",
    zip_name: null,
  },
  {
    postal_code: "47033",
    zip_name: null,
  },
  {
    postal_code: "85633",
    zip_name: null,
  },
  {
    postal_code: "25026",
    zip_name: null,
  },
  {
    postal_code: "7061",
    zip_name: null,
  },
  {
    postal_code: "27656",
    zip_name: null,
  },
  {
    postal_code: "19903",
    zip_name: null,
  },
  {
    postal_code: "11247",
    zip_name: null,
  },
  {
    postal_code: "22803",
    zip_name: null,
  },
  {
    postal_code: "31702",
    zip_name: null,
  },
  {
    postal_code: "70404",
    zip_name: null,
  },
  {
    postal_code: "87654",
    zip_name: null,
  },
  {
    postal_code: "20810",
    zip_name: null,
  },
  {
    postal_code: "72711",
    zip_name: null,
  },
  {
    postal_code: "79120",
    zip_name: null,
  },
  {
    postal_code: "75666",
    zip_name: null,
  },
  {
    postal_code: "35187",
    zip_name: null,
  },
  {
    postal_code: "30580",
    zip_name: null,
  },
  {
    postal_code: "49281",
    zip_name: null,
  },
  {
    postal_code: "13762",
    zip_name: null,
  },
  {
    postal_code: "4935",
    zip_name: null,
  },
  {
    postal_code: "71218",
    zip_name: null,
  },
  {
    postal_code: "32042",
    zip_name: null,
  },
  {
    postal_code: "13851",
    zip_name: null,
  },
  {
    postal_code: "1090",
    zip_name: null,
  },
  {
    postal_code: "61601",
    zip_name: null,
  },
  {
    postal_code: "11405",
    zip_name: null,
  },
  {
    postal_code: "78646",
    zip_name: null,
  },
  {
    postal_code: "27293",
    zip_name: null,
  },
  {
    postal_code: "56541",
    zip_name: null,
  },
  {
    postal_code: "47435",
    zip_name: null,
  },
  {
    postal_code: "3238",
    zip_name: null,
  },
  {
    postal_code: "1538",
    zip_name: null,
  },
  {
    postal_code: "1561",
    zip_name: null,
  },
  {
    postal_code: "75788",
    zip_name: null,
  },
  {
    postal_code: "80046",
    zip_name: null,
  },
  {
    postal_code: "56002",
    zip_name: null,
  },
  {
    postal_code: "6373",
    zip_name: null,
  },
  {
    postal_code: "12584",
    zip_name: null,
  },
  {
    postal_code: "1805",
    zip_name: null,
  },
  {
    postal_code: "64013",
    zip_name: null,
  },
  {
    postal_code: "15831",
    zip_name: null,
  },
  {
    postal_code: "32559",
    zip_name: null,
  },
  {
    postal_code: "76803",
    zip_name: null,
  },
  {
    postal_code: "55474",
    zip_name: null,
  },
  {
    postal_code: "1784",
    zip_name: null,
  },
  {
    postal_code: "66119",
    zip_name: null,
  },
  {
    postal_code: "27697",
    zip_name: null,
  },
  {
    postal_code: "16058",
    zip_name: null,
  },
  {
    postal_code: "21560",
    zip_name: null,
  },
  {
    postal_code: "36253",
    zip_name: null,
  },
  {
    postal_code: "90083",
    zip_name: null,
  },
  {
    postal_code: "17567",
    zip_name: null,
  },
  {
    postal_code: "60055",
    zip_name: null,
  },
  {
    postal_code: "98921",
    zip_name: null,
  },
  {
    postal_code: "37719",
    zip_name: null,
  },
  {
    postal_code: "76241",
    zip_name: null,
  },
  {
    postal_code: "4078",
    zip_name: null,
  },
  {
    postal_code: "30162",
    zip_name: null,
  },
  {
    postal_code: "44210",
    zip_name: null,
  },
  {
    postal_code: "19538",
    zip_name: null,
  },
  {
    postal_code: "77001",
    zip_name: null,
  },
  {
    postal_code: "65055",
    zip_name: null,
  },
  {
    postal_code: "29616",
    zip_name: null,
  },
  {
    postal_code: "35201",
    zip_name: null,
  },
  {
    postal_code: "44630",
    zip_name: null,
  },
  {
    postal_code: "4933",
    zip_name: null,
  },
  {
    postal_code: "32747",
    zip_name: null,
  },
  {
    postal_code: "12551",
    zip_name: null,
  },
  {
    postal_code: "29386",
    zip_name: null,
  },
  {
    postal_code: "23018",
    zip_name: null,
  },
  {
    postal_code: "16541",
    zip_name: null,
  },
  {
    postal_code: "47107",
    zip_name: null,
  },
  {
    postal_code: "30664",
    zip_name: null,
  },
  {
    postal_code: "37089",
    zip_name: null,
  },
  {
    postal_code: "31202",
    zip_name: null,
  },
  {
    postal_code: "74076",
    zip_name: null,
  },
  {
    postal_code: "14410",
    zip_name: null,
  },
  {
    postal_code: "77867",
    zip_name: null,
  },
  {
    postal_code: "7978",
    zip_name: null,
  },
  {
    postal_code: "74866",
    zip_name: null,
  },
  {
    postal_code: "38966",
    zip_name: null,
  },
  {
    postal_code: "4489",
    zip_name: null,
  },
  {
    postal_code: "54764",
    zip_name: null,
  },
  {
    postal_code: "37933",
    zip_name: null,
  },
  {
    postal_code: "32662",
    zip_name: null,
  },
  {
    postal_code: "53802",
    zip_name: null,
  },
  {
    postal_code: "33041",
    zip_name: null,
  },
  {
    postal_code: "38946",
    zip_name: null,
  },
  {
    postal_code: "79258",
    zip_name: null,
  },
  {
    postal_code: "19443",
    zip_name: null,
  },
  {
    postal_code: "98920",
    zip_name: null,
  },
  {
    postal_code: "37382",
    zip_name: null,
  },
  {
    postal_code: "7428",
    zip_name: null,
  },
  {
    postal_code: "23190",
    zip_name: null,
  },
  {
    postal_code: "98384",
    zip_name: null,
  },
  {
    postal_code: "52243",
    zip_name: null,
  },
  {
    postal_code: "47536",
    zip_name: null,
  },
  {
    postal_code: "66036",
    zip_name: null,
  },
  {
    postal_code: "83354",
    zip_name: null,
  },
  {
    postal_code: "42061",
    zip_name: null,
  },
  {
    postal_code: "56344",
    zip_name: null,
  },
  {
    postal_code: "13024",
    zip_name: null,
  },
  {
    postal_code: "92222",
    zip_name: null,
  },
  {
    postal_code: "28370",
    zip_name: null,
  },
  {
    postal_code: "27802",
    zip_name: null,
  },
  {
    postal_code: "53099",
    zip_name: null,
  },
  {
    postal_code: "63065",
    zip_name: null,
  },
  {
    postal_code: "32706",
    zip_name: null,
  },
  {
    postal_code: "48667",
    zip_name: null,
  },
  {
    postal_code: "12235",
    zip_name: null,
  },
  {
    postal_code: "98041",
    zip_name: null,
  },
  {
    postal_code: "72659",
    zip_name: null,
  },
  {
    postal_code: "58702",
    zip_name: null,
  },
  {
    postal_code: "90651",
    zip_name: null,
  },
  {
    postal_code: "22227",
    zip_name: null,
  },
  {
    postal_code: "29151",
    zip_name: null,
  },
  {
    postal_code: "36512",
    zip_name: null,
  },
  {
    postal_code: "33583",
    zip_name: null,
  },
  {
    postal_code: "30724",
    zip_name: null,
  },
  {
    postal_code: "79053",
    zip_name: null,
  },
  {
    postal_code: "86405",
    zip_name: null,
  },
  {
    postal_code: "3822",
    zip_name: null,
  },
  {
    postal_code: "94083",
    zip_name: null,
  },
  {
    postal_code: "29598",
    zip_name: null,
  },
  {
    postal_code: "20875",
    zip_name: null,
  },
  {
    postal_code: "72728",
    zip_name: null,
  },
  {
    postal_code: "98046",
    zip_name: null,
  },
  {
    postal_code: "20726",
    zip_name: null,
  },
  {
    postal_code: "77391",
    zip_name: null,
  },
  {
    postal_code: "33222",
    zip_name: null,
  },
  {
    postal_code: "29632",
    zip_name: null,
  },
  {
    postal_code: "25802",
    zip_name: null,
  },
  {
    postal_code: "15682",
    zip_name: null,
  },
  {
    postal_code: "61702",
    zip_name: null,
  },
  {
    postal_code: "78054",
    zip_name: null,
  },
  {
    postal_code: "41713",
    zip_name: null,
  },
  {
    postal_code: "17504",
    zip_name: null,
  },
  {
    postal_code: "20401",
    zip_name: null,
  },
  {
    postal_code: "44416",
    zip_name: null,
  },
  {
    postal_code: "76804",
    zip_name: null,
  },
  {
    postal_code: "29597",
    zip_name: null,
  },
  {
    postal_code: "4098",
    zip_name: null,
  },
  {
    postal_code: "34778",
    zip_name: null,
  },
  {
    postal_code: "28770",
    zip_name: null,
  },
  {
    postal_code: "30357",
    zip_name: null,
  },
  {
    postal_code: "5702",
    zip_name: null,
  },
  {
    postal_code: "85728",
    zip_name: null,
  },
  {
    postal_code: "63853",
    zip_name: null,
  },
  {
    postal_code: "12260",
    zip_name: null,
  },
  {
    postal_code: "48909",
    zip_name: null,
  },
  {
    postal_code: "40452",
    zip_name: null,
  },
  {
    postal_code: "45720",
    zip_name: null,
  },
  {
    postal_code: "38623",
    zip_name: null,
  },
  {
    postal_code: "20131",
    zip_name: null,
  },
  {
    postal_code: "27495",
    zip_name: null,
  },
  {
    postal_code: "38025",
    zip_name: null,
  },
  {
    postal_code: "32523",
    zip_name: null,
  },
  {
    postal_code: "12257",
    zip_name: null,
  },
  {
    postal_code: "92522",
    zip_name: null,
  },
  {
    postal_code: "35013",
    zip_name: null,
  },
  {
    postal_code: "33831",
    zip_name: null,
  },
  {
    postal_code: "28459",
    zip_name: null,
  },
  {
    postal_code: "17720",
    zip_name: null,
  },
  {
    postal_code: "53584",
    zip_name: null,
  },
  {
    postal_code: "74043",
    zip_name: null,
  },
  {
    postal_code: "29923",
    zip_name: null,
  },
  {
    postal_code: "11969",
    zip_name: null,
  },
  {
    postal_code: "65890",
    zip_name: null,
  },
  {
    postal_code: "89702",
    zip_name: null,
  },
  {
    postal_code: "26463",
    zip_name: null,
  },
  {
    postal_code: "27827",
    zip_name: null,
  },
  {
    postal_code: "4954",
    zip_name: null,
  },
  {
    postal_code: "32538",
    zip_name: null,
  },
  {
    postal_code: "72043",
    zip_name: null,
  },
  {
    postal_code: "50395",
    zip_name: null,
  },
  {
    postal_code: "19351",
    zip_name: null,
  },
  {
    postal_code: "6537",
    zip_name: null,
  },
  {
    postal_code: "17831",
    zip_name: null,
  },
  {
    postal_code: "85744",
    zip_name: null,
  },
  {
    postal_code: "72164",
    zip_name: null,
  },
  {
    postal_code: "37044",
    zip_name: null,
  },
  {
    postal_code: "82931",
    zip_name: null,
  },
  {
    postal_code: "93232",
    zip_name: null,
  },
  {
    postal_code: "25686",
    zip_name: null,
  },
  {
    postal_code: "35402",
    zip_name: null,
  },
  {
    postal_code: "72402",
    zip_name: null,
  },
  {
    postal_code: "7303",
    zip_name: null,
  },
  {
    postal_code: "23067",
    zip_name: null,
  },
  {
    postal_code: "37314",
    zip_name: null,
  },
  {
    postal_code: "77243",
    zip_name: null,
  },
  {
    postal_code: "26366",
    zip_name: null,
  },
  {
    postal_code: "59806",
    zip_name: null,
  },
  {
    postal_code: "36543",
    zip_name: null,
  },
  {
    postal_code: "74529",
    zip_name: null,
  },
  {
    postal_code: "76465",
    zip_name: null,
  },
  {
    postal_code: "36568",
    zip_name: null,
  },
  {
    postal_code: "38739",
    zip_name: null,
  },
  {
    postal_code: "78761",
    zip_name: null,
  },
  {
    postal_code: "7961",
    zip_name: null,
  },
  {
    postal_code: "42102",
    zip_name: null,
  },
  {
    postal_code: "72078",
    zip_name: null,
  },
  {
    postal_code: "2902",
    zip_name: null,
  },
  {
    postal_code: "7765",
    zip_name: null,
  },
  {
    postal_code: "10203",
    zip_name: null,
  },
  {
    postal_code: "53535",
    zip_name: null,
  },
  {
    postal_code: "10169",
    zip_name: null,
  },
  {
    postal_code: "76267",
    zip_name: null,
  },
  {
    postal_code: "10172",
    zip_name: null,
  },
  {
    postal_code: "16375",
    zip_name: null,
  },
  {
    postal_code: "92863",
    zip_name: null,
  },
  {
    postal_code: "85001",
    zip_name: null,
  },
  {
    postal_code: "73487",
    zip_name: null,
  },
  {
    postal_code: "2117",
    zip_name: null,
  },
  {
    postal_code: "17880",
    zip_name: null,
  },
  {
    postal_code: "72445",
    zip_name: null,
  },
  {
    postal_code: "18016",
    zip_name: null,
  },
  {
    postal_code: "39282",
    zip_name: null,
  },
  {
    postal_code: "74141",
    zip_name: null,
  },
  {
    postal_code: "21759",
    zip_name: null,
  },
  {
    postal_code: "74602",
    zip_name: null,
  },
  {
    postal_code: "30264",
    zip_name: null,
  },
  {
    postal_code: "56084",
    zip_name: null,
  },
  {
    postal_code: "33802",
    zip_name: null,
  },
  {
    postal_code: "17843",
    zip_name: null,
  },
  {
    postal_code: "95611",
    zip_name: null,
  },
  {
    postal_code: "23260",
    zip_name: null,
  },
  {
    postal_code: "20703",
    zip_name: null,
  },
  {
    postal_code: "70580",
    zip_name: null,
  },
  {
    postal_code: "70727",
    zip_name: null,
  },
  {
    postal_code: "21501",
    zip_name: null,
  },
  {
    postal_code: "92418",
    zip_name: null,
  },
  {
    postal_code: "96739",
    zip_name: null,
  },
  {
    postal_code: "7890",
    zip_name: null,
  },
  {
    postal_code: "54903",
    zip_name: null,
  },
  {
    postal_code: "38602",
    zip_name: null,
  },
  {
    postal_code: "23941",
    zip_name: null,
  },
  {
    postal_code: "15443",
    zip_name: null,
  },
  {
    postal_code: "29679",
    zip_name: null,
  },
  {
    postal_code: "7845",
    zip_name: null,
  },
  {
    postal_code: "53547",
    zip_name: null,
  },
  {
    postal_code: "30086",
    zip_name: null,
  },
  {
    postal_code: "12055",
    zip_name: null,
  },
  {
    postal_code: "30396",
    zip_name: null,
  },
  {
    postal_code: "7507",
    zip_name: null,
  },
  {
    postal_code: "29622",
    zip_name: null,
  },
  {
    postal_code: "35902",
    zip_name: null,
  },
  {
    postal_code: "75915",
    zip_name: null,
  },
  {
    postal_code: "17537",
    zip_name: null,
  },
  {
    postal_code: "30321",
    zip_name: null,
  },
  {
    postal_code: "16367",
    zip_name: null,
  },
  {
    postal_code: "48318",
    zip_name: null,
  },
  {
    postal_code: "10163",
    zip_name: null,
  },
  {
    postal_code: "29348",
    zip_name: null,
  },
  {
    postal_code: "75680",
    zip_name: null,
  },
  {
    postal_code: "4629",
    zip_name: null,
  },
  {
    postal_code: "4028",
    zip_name: null,
  },
  {
    postal_code: "77834",
    zip_name: null,
  },
  {
    postal_code: "50402",
    zip_name: null,
  },
  {
    postal_code: "20113",
    zip_name: null,
  },
  {
    postal_code: "92329",
    zip_name: null,
  },
  {
    postal_code: "70571",
    zip_name: null,
  },
  {
    postal_code: "33949",
    zip_name: null,
  },
  {
    postal_code: "76902",
    zip_name: null,
  },
  {
    postal_code: "47039",
    zip_name: null,
  },
  {
    postal_code: "36313",
    zip_name: null,
  },
  {
    postal_code: "34136",
    zip_name: null,
  },
  {
    postal_code: "37378",
    zip_name: null,
  },
  {
    postal_code: "77641",
    zip_name: null,
  },
  {
    postal_code: "37831",
    zip_name: null,
  },
  {
    postal_code: "44648",
    zip_name: null,
  },
  {
    postal_code: "38402",
    zip_name: null,
  },
  {
    postal_code: "29502",
    zip_name: null,
  },
  {
    postal_code: "74068",
    zip_name: null,
  },
  {
    postal_code: "4057",
    zip_name: null,
  },
  {
    postal_code: "68026",
    zip_name: null,
  },
  {
    postal_code: "78269",
    zip_name: null,
  },
  {
    postal_code: "3041",
    zip_name: null,
  },
  {
    postal_code: "16654",
    zip_name: null,
  },
  {
    postal_code: "39555",
    zip_name: null,
  },
  {
    postal_code: "63779",
    zip_name: null,
  },
  {
    postal_code: "66063",
    zip_name: null,
  },
  {
    postal_code: "1822",
    zip_name: null,
  },
  {
    postal_code: "92357",
    zip_name: null,
  },
  {
    postal_code: "81009",
    zip_name: null,
  },
  {
    postal_code: "13749",
    zip_name: null,
  },
  {
    postal_code: "10108",
    zip_name: null,
  },
  {
    postal_code: "75712",
    zip_name: null,
  },
  {
    postal_code: "31013",
    zip_name: null,
  },
  {
    postal_code: "78673",
    zip_name: null,
  },
  {
    postal_code: "38639",
    zip_name: null,
  },
  {
    postal_code: "11022",
    zip_name: null,
  },
  {
    postal_code: "75660",
    zip_name: null,
  },
  {
    postal_code: "79931",
    zip_name: null,
  },
  {
    postal_code: "1021",
    zip_name: null,
  },
  {
    postal_code: "84158",
    zip_name: null,
  },
  {
    postal_code: "31534",
    zip_name: null,
  },
  {
    postal_code: "31086",
    zip_name: null,
  },
  {
    postal_code: "32635",
    zip_name: null,
  },
  {
    postal_code: "49311",
    zip_name: null,
  },
  {
    postal_code: "79740",
    zip_name: null,
  },
  {
    postal_code: "48605",
    zip_name: null,
  },
  {
    postal_code: "24129",
    zip_name: null,
  },
  {
    postal_code: "22403",
    zip_name: null,
  },
  {
    postal_code: "24811",
    zip_name: null,
  },
  {
    postal_code: "75461",
    zip_name: null,
  },
  {
    postal_code: "35471",
    zip_name: null,
  },
  {
    postal_code: "30604",
    zip_name: null,
  },
  {
    postal_code: "14452",
    zip_name: null,
  },
  {
    postal_code: "18981",
    zip_name: null,
  },
  {
    postal_code: "18958",
    zip_name: null,
  },
  {
    postal_code: "23467",
    zip_name: null,
  },
  {
    postal_code: "42702",
    zip_name: null,
  },
  {
    postal_code: "14851",
    zip_name: null,
  },
  {
    postal_code: "44856",
    zip_name: null,
  },
  {
    postal_code: "33550",
    zip_name: null,
  },
  {
    postal_code: "24068",
    zip_name: null,
  },
  {
    postal_code: "27636",
    zip_name: null,
  },
  {
    postal_code: "36015",
    zip_name: null,
  },
  {
    postal_code: "27351",
    zip_name: null,
  },
  {
    postal_code: "37868",
    zip_name: null,
  },
  {
    postal_code: "45481",
    zip_name: null,
  },
  {
    postal_code: "93724",
    zip_name: null,
  },
  {
    postal_code: "25729",
    zip_name: null,
  },
  {
    postal_code: "46249",
    zip_name: null,
  },
  {
    postal_code: "40256",
    zip_name: null,
  },
  {
    postal_code: "30074",
    zip_name: null,
  },
  {
    postal_code: "25691",
    zip_name: null,
  },
  {
    postal_code: "16312",
    zip_name: null,
  },
  {
    postal_code: "28563",
    zip_name: null,
  },
  {
    postal_code: "19511",
    zip_name: null,
  },
  {
    postal_code: "87502",
    zip_name: null,
  },
  {
    postal_code: "99302",
    zip_name: null,
  },
  {
    postal_code: "60566",
    zip_name: null,
  },
  {
    postal_code: "8899",
    zip_name: null,
  },
  {
    postal_code: "1885",
    zip_name: null,
  },
  {
    postal_code: "40844",
    zip_name: null,
  },
  {
    postal_code: "40410",
    zip_name: null,
  },
  {
    postal_code: "49351",
    zip_name: null,
  },
  {
    postal_code: "45350",
    zip_name: null,
  },
  {
    postal_code: "35082",
    zip_name: null,
  },
  {
    postal_code: "31708",
    zip_name: null,
  },
  {
    postal_code: "13737",
    zip_name: null,
  },
  {
    postal_code: "17503",
    zip_name: null,
  },
  {
    postal_code: "75151",
    zip_name: null,
  },
  {
    postal_code: "80006",
    zip_name: null,
  },
  {
    postal_code: "63742",
    zip_name: null,
  },
  {
    postal_code: "75671",
    zip_name: null,
  },
  {
    postal_code: "6487",
    zip_name: null,
  },
  {
    postal_code: "70521",
    zip_name: null,
  },
  {
    postal_code: "32173",
    zip_name: null,
  },
  {
    postal_code: "89152",
    zip_name: null,
  },
  {
    postal_code: "20847",
    zip_name: null,
  },
  {
    postal_code: "93275",
    zip_name: null,
  },
  {
    postal_code: "6115",
    zip_name: null,
  },
  {
    postal_code: "31502",
    zip_name: null,
  },
  {
    postal_code: "19544",
    zip_name: null,
  },
  {
    postal_code: "12506",
    zip_name: null,
  },
  {
    postal_code: "95234",
    zip_name: null,
  },
  {
    postal_code: "48233",
    zip_name: null,
  },
  {
    postal_code: "21139",
    zip_name: null,
  },
  {
    postal_code: "90251",
    zip_name: null,
  },
  {
    postal_code: "2060",
    zip_name: null,
  },
  {
    postal_code: "30638",
    zip_name: null,
  },
  {
    postal_code: "49516",
    zip_name: null,
  },
  {
    postal_code: "12133",
    zip_name: null,
  },
  {
    postal_code: "37152",
    zip_name: null,
  },
  {
    postal_code: "2454",
    zip_name: null,
  },
  {
    postal_code: "7839",
    zip_name: null,
  },
  {
    postal_code: "85654",
    zip_name: null,
  },
  {
    postal_code: "33163",
    zip_name: null,
  },
  {
    postal_code: "72183",
    zip_name: null,
  },
  {
    postal_code: "89314",
    zip_name: null,
  },
  {
    postal_code: "29336",
    zip_name: null,
  },
  {
    postal_code: "23001",
    zip_name: null,
  },
  {
    postal_code: "66855",
    zip_name: null,
  },
  {
    postal_code: "80960",
    zip_name: null,
  },
  {
    postal_code: "87594",
    zip_name: null,
  },
  {
    postal_code: "77725",
    zip_name: null,
  },
  {
    postal_code: "70459",
    zip_name: null,
  },
  {
    postal_code: "33883",
    zip_name: null,
  },
  {
    postal_code: "31221",
    zip_name: null,
  },
  {
    postal_code: "47852",
    zip_name: null,
  },
  {
    postal_code: "15415",
    zip_name: null,
  },
  {
    postal_code: "85902",
    zip_name: null,
  },
  {
    postal_code: "82335",
    zip_name: null,
  },
  {
    postal_code: "27331",
    zip_name: null,
  },
  {
    postal_code: "20303",
    zip_name: null,
  },
  {
    postal_code: "19612",
    zip_name: null,
  },
  {
    postal_code: "42402",
    zip_name: null,
  },
  {
    postal_code: "25423",
    zip_name: null,
  },
  {
    postal_code: "79712",
    zip_name: null,
  },
  {
    postal_code: "85002",
    zip_name: null,
  },
  {
    postal_code: "72018",
    zip_name: null,
  },
  {
    postal_code: "99220",
    zip_name: null,
  },
  {
    postal_code: "22446",
    zip_name: null,
  },
  {
    postal_code: "20704",
    zip_name: null,
  },
  {
    postal_code: "23081",
    zip_name: null,
  },
  {
    postal_code: "24463",
    zip_name: null,
  },
  {
    postal_code: "31059",
    zip_name: null,
  },
  {
    postal_code: "97902",
    zip_name: null,
  },
  {
    postal_code: "15723",
    zip_name: null,
  },
  {
    postal_code: "98397",
    zip_name: null,
  },
  {
    postal_code: "31769",
    zip_name: null,
  },
  {
    postal_code: "25690",
    zip_name: null,
  },
  {
    postal_code: "68103",
    zip_name: null,
  },
  {
    postal_code: "74459",
    zip_name: null,
  },
  {
    postal_code: "32192",
    zip_name: null,
  },
  {
    postal_code: "33106",
    zip_name: null,
  },
  {
    postal_code: "76246",
    zip_name: null,
  },
  {
    postal_code: "49081",
    zip_name: null,
  },
  {
    postal_code: "11002",
    zip_name: null,
  },
  {
    postal_code: "6101",
    zip_name: null,
  },
  {
    postal_code: "5439",
    zip_name: null,
  },
  {
    postal_code: "25331",
    zip_name: null,
  },
  {
    postal_code: "23515",
    zip_name: null,
  },
  {
    postal_code: "29021",
    zip_name: null,
  },
  {
    postal_code: "27515",
    zip_name: null,
  },
  {
    postal_code: "25723",
    zip_name: null,
  },
  {
    postal_code: "29652",
    zip_name: null,
  },
  {
    postal_code: "2027",
    zip_name: null,
  },
  {
    postal_code: "3252",
    zip_name: null,
  },
  {
    postal_code: "12510",
    zip_name: null,
  },
  {
    postal_code: "98228",
    zip_name: null,
  },
  {
    postal_code: "84090",
    zip_name: null,
  },
  {
    postal_code: "99209",
    zip_name: null,
  },
  {
    postal_code: "30098",
    zip_name: null,
  },
  {
    postal_code: "2742",
    zip_name: null,
  },
  {
    postal_code: "70470",
    zip_name: null,
  },
  {
    postal_code: "50359",
    zip_name: null,
  },
  {
    postal_code: "1525",
    zip_name: null,
  },
  {
    postal_code: "6540",
    zip_name: null,
  },
  {
    postal_code: "38874",
    zip_name: null,
  },
  {
    postal_code: "29528",
    zip_name: null,
  },
  {
    postal_code: "23232",
    zip_name: null,
  },
  {
    postal_code: "95813",
    zip_name: null,
  },
  {
    postal_code: "82605",
    zip_name: null,
  },
  {
    postal_code: "73113",
    zip_name: null,
  },
  {
    postal_code: "48232",
    zip_name: null,
  },
  {
    postal_code: "53109",
    zip_name: null,
  },
  {
    postal_code: "98111",
    zip_name: null,
  },
  {
    postal_code: "37227",
    zip_name: null,
  },
  {
    postal_code: "20534",
    zip_name: null,
  },
  {
    postal_code: "57402",
    zip_name: null,
  },
  {
    postal_code: "70469",
    zip_name: null,
  },
  {
    postal_code: "43761",
    zip_name: null,
  },
  {
    postal_code: "22333",
    zip_name: null,
  },
  {
    postal_code: "46015",
    zip_name: null,
  },
  {
    postal_code: "86545",
    zip_name: null,
  },
  {
    postal_code: "2455",
    zip_name: null,
  },
  {
    postal_code: "1441",
    zip_name: null,
  },
  {
    postal_code: "56593",
    zip_name: null,
  },
  {
    postal_code: "49515",
    zip_name: null,
  },
  {
    postal_code: "72811",
    zip_name: null,
  },
  {
    postal_code: "30380",
    zip_name: null,
  },
  {
    postal_code: "74071",
    zip_name: null,
  },
  {
    postal_code: "30142",
    zip_name: null,
  },
  {
    postal_code: "38803",
    zip_name: null,
  },
  {
    postal_code: "73001",
    zip_name: null,
  },
  {
    postal_code: "22313",
    zip_name: null,
  },
  {
    postal_code: "14778",
    zip_name: null,
  },
  {
    postal_code: "38101",
    zip_name: null,
  },
  {
    postal_code: "98025",
    zip_name: null,
  },
  {
    postal_code: "2133",
    zip_name: null,
  },
  {
    postal_code: "7007",
    zip_name: null,
  },
  {
    postal_code: "89140",
    zip_name: null,
  },
  {
    postal_code: "98511",
    zip_name: null,
  },
  {
    postal_code: "88255",
    zip_name: null,
  },
  {
    postal_code: "77875",
    zip_name: null,
  },
  {
    postal_code: "68738",
    zip_name: null,
  },
  {
    postal_code: "7653",
    zip_name: null,
  },
  {
    postal_code: "22471",
    zip_name: null,
  },
  {
    postal_code: "77460",
    zip_name: null,
  },
  {
    postal_code: "33902",
    zip_name: null,
  },
  {
    postal_code: "92149",
    zip_name: null,
  },
  {
    postal_code: "42152",
    zip_name: null,
  },
  {
    postal_code: "17932",
    zip_name: null,
  },
  {
    postal_code: "20375",
    zip_name: null,
  },
  {
    postal_code: "58502",
    zip_name: null,
  },
  {
    postal_code: "89160",
    zip_name: null,
  },
  {
    postal_code: "24003",
    zip_name: null,
  },
  {
    postal_code: "96801",
    zip_name: null,
  },
  {
    postal_code: "88355",
    zip_name: null,
  },
  {
    postal_code: "1613",
    zip_name: null,
  },
  {
    postal_code: "66201",
    zip_name: null,
  },
  {
    postal_code: "37422",
    zip_name: null,
  },
  {
    postal_code: "37662",
    zip_name: null,
  },
  {
    postal_code: "27342",
    zip_name: null,
  },
  {
    postal_code: "46251",
    zip_name: null,
  },
  {
    postal_code: "25720",
    zip_name: null,
  },
  {
    postal_code: "5490",
    zip_name: null,
  },
  {
    postal_code: "1111",
    zip_name: null,
  },
  {
    postal_code: "99608",
    zip_name: null,
  },
  {
    postal_code: "89006",
    zip_name: null,
  },
  {
    postal_code: "49020",
    zip_name: null,
  },
  {
    postal_code: "93423",
    zip_name: null,
  },
  {
    postal_code: "96807",
    zip_name: null,
  },
  {
    postal_code: "35899",
    zip_name: null,
  },
  {
    postal_code: "88009",
    zip_name: null,
  },
  {
    postal_code: "95538",
    zip_name: null,
  },
  {
    postal_code: "23178",
    zip_name: null,
  },
  {
    postal_code: "92375",
    zip_name: null,
  },
  {
    postal_code: "73157",
    zip_name: null,
  },
  {
    postal_code: "89180",
    zip_name: null,
  },
  {
    postal_code: "76113",
    zip_name: null,
  },
  {
    postal_code: "29904",
    zip_name: null,
  },
  {
    postal_code: "77431",
    zip_name: null,
  },
  {
    postal_code: "99228",
    zip_name: null,
  },
  {
    postal_code: "8903",
    zip_name: null,
  },
  {
    postal_code: "47545",
    zip_name: null,
  },
  {
    postal_code: "27833",
    zip_name: null,
  },
  {
    postal_code: "39130",
    zip_name: null,
  },
  {
    postal_code: "14453",
    zip_name: null,
  },
  {
    postal_code: "20811",
    zip_name: null,
  },
  {
    postal_code: "27570",
    zip_name: null,
  },
  {
    postal_code: "70156",
    zip_name: null,
  },
  {
    postal_code: "30049",
    zip_name: null,
  },
  {
    postal_code: "45802",
    zip_name: null,
  },
  {
    postal_code: "85726",
    zip_name: null,
  },
  {
    postal_code: "18918",
    zip_name: null,
  },
  {
    postal_code: "2348",
    zip_name: null,
  },
  {
    postal_code: "97464",
    zip_name: null,
  },
  {
    postal_code: "64051",
    zip_name: null,
  },
  {
    postal_code: "22526",
    zip_name: null,
  },
  {
    postal_code: "60507",
    zip_name: null,
  },
  {
    postal_code: "8868",
    zip_name: null,
  },
  {
    postal_code: "60522",
    zip_name: null,
  },
  {
    postal_code: "14518",
    zip_name: null,
  },
  {
    postal_code: "86402",
    zip_name: null,
  },
  {
    postal_code: "14430",
    zip_name: null,
  },
  {
    postal_code: "33198",
    zip_name: null,
  },
  {
    postal_code: "37901",
    zip_name: null,
  },
  {
    postal_code: "37602",
    zip_name: null,
  },
  {
    postal_code: "29734",
    zip_name: null,
  },
  {
    postal_code: "4962",
    zip_name: null,
  },
  {
    postal_code: "68802",
    zip_name: null,
  },
  {
    postal_code: "70728",
    zip_name: null,
  },
  {
    postal_code: "30302",
    zip_name: null,
  },
  {
    postal_code: "33112",
    zip_name: null,
  },
  {
    postal_code: "98540",
    zip_name: null,
  },
  {
    postal_code: "25707",
    zip_name: null,
  },
  {
    postal_code: "18449",
    zip_name: null,
  },
  {
    postal_code: "32716",
    zip_name: null,
  },
  {
    postal_code: "64141",
    zip_name: null,
  },
  {
    postal_code: "75564",
    zip_name: null,
  },
  {
    postal_code: "3896",
    zip_name: null,
  },
  {
    postal_code: "85380",
    zip_name: null,
  },
  {
    postal_code: "70143",
    zip_name: null,
  },
  {
    postal_code: "27640",
    zip_name: null,
  },
  {
    postal_code: "30608",
    zip_name: null,
  },
  {
    postal_code: "93502",
    zip_name: null,
  },
  {
    postal_code: "47902",
    zip_name: null,
  },
  {
    postal_code: "27676",
    zip_name: null,
  },
  {
    postal_code: "1229",
    zip_name: null,
  },
  {
    postal_code: "5750",
    zip_name: null,
  },
  {
    postal_code: "55816",
    zip_name: null,
  },
  {
    postal_code: "33568",
    zip_name: null,
  },
  {
    postal_code: "70044",
    zip_name: null,
  },
  {
    postal_code: "33448",
    zip_name: null,
  },
];
