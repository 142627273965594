import { User } from "backend/types/user.type";
import { Flex } from "components/Flex";
import RoundedCrossIcon from "components/icons/RoundedCrossIcon";
import { doc, updateDoc } from "firebase/firestore";
import { useUserData } from "hooks/useUser";
import { createUserAttributes } from "lib/helpers/userHelpers";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthModalContext } from "../../lib/context";
import { db } from "../../lib/firebase";
import { Button } from "../Button";
import { Text } from "../Text";
import { useTheme } from "@emotion/react";
import { Modal } from "antd";
import { useMediaQuery } from "react-responsive";

export const showWhatsYourRoleDelayTimerSeconds = 3 * 60;
export const showAfterOnceClosed = 24 * 60 * 60;
export const YourRole = () => {
  const theme = useTheme();
  const {
    showWhatsYourRolePopup: showModal,
    setShowWhatsYourRolePopup: setShowModal,
  } = useContext(AuthModalContext);
  const whoAreYouOptions = ["Homebuyer", "Investor", "Realtor", "Other"];
  const [whoAreYou, setWhoAreYou] = useState({ label: "", value: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const posthog = usePostHog();
  const user = useUserData();
  const isMobile = useMediaQuery({ query: "(max-width: 426px)" });

  useEffect(() => {
    if (typeof window !== "undefined") {
      const currentTime = Math.floor(new Date().getTime() / 1000);
      let timerToShowPopup = showWhatsYourRoleDelayTimerSeconds;
      if (
        user?.openWhatsYourRoleAfter &&
        user?.openWhatsYourRoleAfter > currentTime
      ) {
        timerToShowPopup =
          showWhatsYourRoleDelayTimerSeconds +
          user?.openWhatsYourRoleAfter -
          currentTime;
      }
      timerToShowPopup *= 1000;
      if (router.pathname === "/dashboard" && user?.id && !user?.whoAreYou) {
        const timer = setTimeout(() => {
          setShowModal(true);
        }, timerToShowPopup);
        return () => clearTimeout(timer);
      }
    }
  }, [user?.id, user?.whoAreYou, router.pathname, user]);

  useMemo(() => {
    setShowModal(false);
  }, [user?.openWhatsYourRoleAfter]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!showModal) {
        resetInitialState();
      }
    }
  }, [showModal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setError("Please select your role!");
      return;
    }
    try {
      setError("");
      setLoading(true);
      await updateDoc(doc(db, "users", user.uid as string), {
        whoAreYou: whoAreYou?.value,
      });

      const userAttributes = createUserAttributes({
        user: user as User,
        overwrite: {
          role: whoAreYou.value || user.whoAreYou,
        },
      });

      posthog.identify(user.email, userAttributes);

      let relativeURL = "";

      const slug = router.query.slug;
      if (slug) {
        relativeURL = router.pathname.replace("[slug]", slug as string);
      } else {
        relativeURL = router.pathname;
      }
      router.replace(
        { pathname: relativeURL, query: router.query },
        undefined,
        {
          shallow: true,
        },
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  const handleClose = async () => {
    setShowModal(false);
    const currentTime = Math.floor(new Date().getTime() / 1000);
    const openWhatsYourRoleAfter = currentTime + showAfterOnceClosed;
    try {
      await updateDoc(doc(db, "users", user.uid as string), {
        openWhatsYourRoleAfter: openWhatsYourRoleAfter,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const validateForm = () => {
    return !!whoAreYou?.value;
  };

  const resetInitialState = () => {
    setWhoAreYou({
      label: "",
      value: "",
    });
    setError("");
    setLoading(false);
  };

  return (
    //@ts-ignore
    <Modal
      closable={false}
      centered={!isMobile}
      open={showModal && router.pathname === "/dashboard"}
      footer={null}
      onCancel={() => {
        handleClose();
      }}
      destroyOnClose
      style={{ ...(isMobile ? { top: "calc(100vh - 255px)" } : {}) }}
      css={{
        width: "100%",
        maxWidth: isMobile ? "300px" : "400px",
        ".ant-modal-body": { padding: "0px" },
        ".ant-modal-centered": {
          ...(isMobile ? { display: "flex", alignItems: "end" } : {}),
        },
      }}
    >
      <Flex
        justify="center"
        align="center"
        onClick={() => {
          handleClose();
        }}
        css={{
          position: "absolute",
          top: 15,
          right: 15,
          width: 30,
          height: 30,
          zIndex: 1,
          filter: `drop-shadow(-6px 6px 12px rgba(0, 0, 0, 0.34))`,
          cursor: "pointer",

          ":hover": {
            backgroundColor: theme.colors.lightBg,
          },
        }}
      >
        <RoundedCrossIcon />
      </Flex>
      <div
        css={(theme) => ({
          position: "relative",
          width: "100%",
          borderRadius: theme.radii.small,
          boxShadow: theme.shadow,
          backgroundColor: theme.colors.white,
          padding: theme.padding.large,
        })}
      >
        <h1
          css={(theme) => ({
            paddingTop: "0px",
            paddingRight: theme.padding.medium,
            fontSize: isMobile ? 24 : 30,
            fontWeight: 600,
            margin: "0px 0px 5px 0px",
          })}
        >
          <span>What is your Role</span>
        </h1>
        <Flex
          css={{
            padding: 5,
            borderRadius: theme.radii.smaller,
            overflow: "auto",
          }}
          wrap="nowrap"
        >
          {whoAreYouOptions.map((role) => {
            return (
              <Button
                onClick={() => {
                  setWhoAreYou({ value: role, label: role } as {
                    value: string;
                    label: string;
                  });
                }}
                css={{
                  display: "flex",
                  alignItems: "center",
                  gap: 6,
                  justifyContent: "center",
                  fontSize: isMobile ? "11px" : theme.fontSizes.small,
                  padding: isMobile ? "5px 10px" : "8px 20px",
                  lineHeight: "14px",
                  borderRadius: "50px",
                  boxShadow:
                    "0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12)",
                  border: "0.5px solid rgba(0, 0, 0, 0.04)",
                  ...(whoAreYou.value === role
                    ? {
                        background: theme.colors.black,
                        color: theme.colors.white,
                      }
                    : { background: theme.colors.white }),
                }}
                key={role}
              >
                {role}
              </Button>
            );
          })}
        </Flex>

        {error && (
          <Text
            css={(theme) => ({
              color: theme.colors.red,
              textAlign: "center",
              marginTop: 8,
            })}
          >
            {error}
          </Text>
        )}
        <Button
          css={() => ({
            marginTop: isMobile ? "15px" : "26px",
            marginBottom: isMobile ? 8 : 10,
            width: "100%",
          })}
          variant="primary"
          onClick={handleSubmit}
          disabled={loading}
        >
          Continue
        </Button>
      </div>
    </Modal>
  );
};
