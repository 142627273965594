import { useTheme } from "@emotion/react";
import { Price } from "backend/types/stripe.type";
import { Plan, PlanType } from "backend/utils/plan";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import CrossIcon from "components/icons/CrossIcon";
import DatapointsModal from "components/plan/DatapointsModal";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { usePlans } from "hooks/usePlans";
import useUser, { useUserData } from "hooks/useUser";
import { AuthModalContext, ResponsiveContext } from "lib/context";
import { getRelativePathname } from "lib/getRelativePathname";
import { useRouter } from "next/router";
import { useContext, useEffect, useMemo, useState } from "react";
import { SignupCheckout } from "./SignupCheckout";
import { useLocalStorage } from "react-use";

const SignupCheckoutModal = ({ userName }): JSX.Element => {
  const plans = usePlans();
  const user = useUserData();
  const router = useRouter();
  const [checkingOut, setCheckingOut] = useLocalStorage("checkingOut", false);
  const [selectedPlan, setSelectedPlan] = useState<Plan>(Plan.Premium_Monthly);
  const { setShowAuthModal, showAuthModal } = useContext(AuthModalContext);
  const [appliedCode, setAppliedCode] = useState("");
  const [stateChanged, setStateChanged] = useState(false);
  const [planFeaturesState, setPlanFeaturesState] = useState({
    show: false,
    selectedIndex: -1,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setStateChanged(!stateChanged);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [stateChanged]);

  const { setShowPlanModal, setPlans, showPlanModal } =
    useContext(PlanModalContext);

  useEffect(() => {
    setPlans(premiumPlans);
  }, [plans]);

  const premiumPlans = useMemo<Price[]>(() => {
    return plans
      .filter((plan) =>
        [Plan.Premium_Monthly, Plan.Premium_Yearly].includes(plan.name),
      )
      .sort((a, b) => a.amount - b.amount);
  }, [plans]);
  const handleChangePlan = (plan: Plan) => {
    setSelectedPlan(plan);
  };
  useEffect(() => {
    if (showPlanModal) {
      setShowAuthModal(false);
    }
  }, [showPlanModal, showAuthModal]);
  return plans.length > 0 ? (
    <>
      <DatapointsModal
        open={!!router.query.showDatapointModal}
        onClose={() => router.replace(`${getRelativePathname(router)}`)}
      />
      <Modal
        overlayStyle={{ overflow: "auto" }}
        stateChanged={stateChanged}
        contentStyle={{
          padding: 0,
          background: "white",
          maxWidth: 835,
          width: "100%",
        }}
        isOpen={
          checkingOut ||
          (showPlanModal &&
            (user?.plan === PlanType.Free ||
              user?.plan === PlanType.State_Level_Access ||
              !user?.uid))
        }
      >
        <Flex
          onClick={() => {
            setShowPlanModal(false);
            setCheckingOut(false);
          }}
          align="center"
          justify="center"
          css={{
            position: "absolute",
            cursor: "pointer",
            background: "#EFEFEF",
            borderRadius: 4,
            height: 37,
            width: 37,
            top: 14,
            right: 14,
          }}
        >
          <CrossIcon />
        </Flex>
        {premiumPlans.length > 0 ? (
          <SignupCheckout
            userName={userName}
            checkingOut={checkingOut}
            setCheckingOut={setCheckingOut}
            handleChangePlan={handleChangePlan}
            plans={premiumPlans}
            priceId={plans?.filter((item) => item.name === selectedPlan)[0]?.id}
            appliedCode={appliedCode}
            selectedPlan={selectedPlan}
            planFeaturesState={planFeaturesState}
            onWhatsInsideClick={(index) => {
              setPlanFeaturesState((prev) => ({
                show: prev.selectedIndex === index ? false : true,
                selectedIndex: prev.selectedIndex === index ? -1 : index,
              }));
            }}
          />
        ) : (
          <></>
        )}
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default SignupCheckoutModal;
