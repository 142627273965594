import { Price } from "backend/types/stripe.type";
import { Flex } from "components/Flex";
import { Text } from "components/Text";
import { PlanInformation } from "components/common/PlanInformation";
import { useTheme } from "@emotion/react";
import { Plan } from "backend/utils/plan";
import { useMediaQuery } from "react-responsive";
import { useRouter } from "next/router";
import { currencyFormatter } from "lib/currencyFormatter";
import { useMounted } from "lib/hooks";
import { useContext, useMemo } from "react";
import { StripeElementsNewDesign } from "./StripeElementsNewDesign";
import { CheckoutPlanItem } from "./CheckoutPlanItem";
import { Button } from "antd";
import { AuthModalContext } from "lib/context";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import { useUserData } from "hooks/useUser";
import { ArrowLeftOutlined } from "@ant-design/icons";
// import { Button } from "components/Button";

type Props = {
  priceId: string;
  handleChangePlan: (plan: Plan) => void;
  plans: Price[];
  selectedPlan: Plan;
  planFeaturesState: {
    show: boolean;
    selectedIndex: number;
  };
  onWhatsInsideClick: (index: number) => void;
  appliedCode?: string;
  checkingOut: boolean | undefined;
  setCheckingOut: any;
  userName?: any;
};

export const SignupCheckout = ({
  handleChangePlan,
  plans,
  priceId,
  selectedPlan,
  planFeaturesState,
  onWhatsInsideClick,
  appliedCode,
  checkingOut,
  setCheckingOut,
  userName,
}: Props) => {
  const theme = useTheme();
  const { setShowAuthModal, setAuthType } = useContext(AuthModalContext);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const router = useRouter();
  const user = useUserData();
  const isMounted = useMounted();
  const { setShowPlanModal } = useContext(PlanModalContext);
  const planObject = useMemo(() => {
    return plans.find((plan) => plan.name === selectedPlan);
  }, [plans, selectedPlan]);
  return (
    isMounted && (
      <Flex
        direction={isTabletOrMobile ? "column" : "row"}
        css={{
          background: "white",
          minHeight: "100%",
          overflow: "auto",
          alignItems: "stretch",
        }}
      >
        <Flex
          flex="1"
          css={{
            ...(isTabletOrMobile ? { width: "100%" } : {}),
            background: "#F7F6F9",
            height: isTabletOrMobile ? "fit-content" : "auto",
            paddingBottom: "45px",
            position: "relative",
          }}
          justify="center"
        >
          <Flex
            direction="column"
            css={{
              maxWidth: !isTabletOrMobile ? 503 : "100%",
              marginTop: 22,
              padding: isTabletOrMobile ? "0 8px" : "0 24px",
            }}
          >
            <Flex gap={10} align="center">
              {isTabletOrMobile && (
                <ArrowLeftOutlined
                  style={{ fontSize: 20 }}
                  onClick={() => {
                    setShowPlanModal(false);
                    setCheckingOut(false);
                  }}
                />
              )}
              <Text bolder css={{ fontSize: "20px" }}>
                Selected Plan
              </Text>
            </Flex>
            <Flex
              direction="column"
              style={{ marginTop: 10, width: "100%" }}
              gap={20}
            >
              {plans.map((plan, index) => (
                <CheckoutPlanItem
                  onWhatsInsideClick={() => {
                    onWhatsInsideClick(index);
                  }}
                  isWhatsInsideOpen={planFeaturesState.selectedIndex === index}
                  isTabletOrMobile={isTabletOrMobile}
                  selectedPlan={selectedPlan}
                  key={plan.id}
                  handleChangePlan={handleChangePlan}
                  plan={plan}
                  appliedCode={appliedCode}
                />
              ))}
            </Flex>

            <PlanInformation
              planObject={planObject}
              headingProps={{
                style: { display: isTabletOrMobile ? "none" : "block" },
              }}
              textGap={12}
              type={selectedPlan}
              css={{
                marginTop: isTabletOrMobile ? 0 : 36,
                ...(isTabletOrMobile
                  ? !planFeaturesState.show
                    ? {
                        maxHeight: 0,
                        transition: "max-height 0.15s ease-out",
                        overflow: "hidden",
                      }
                    : {
                        maxHeight: 500,
                        transition: "max-height 0.25s ease-in",
                      }
                  : {}),
              }}
            />
            {!isTabletOrMobile && (
              <div
                css={{
                  maxWidth: !isTabletOrMobile ? 503 : "100%",
                  marginTop: isTabletOrMobile ? 24 : 25,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Text
                  css={{
                    padding: isTabletOrMobile ? "5px 10px" : "18px 24px",
                    background: theme.colors.brightGray,
                    borderRadius: 4,
                    width: "fit-content",
                    fontSize: "13px",
                  }}
                >
                  By clicking the &apos;Pay&apos; button you agree to be{" "}
                  <b>
                    billed{" "}
                    {currencyFormatter((planObject?.amount || 0) / 100, {
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    })}{" "}
                    {planObject?.name === Plan.Premium_Monthly
                      ? "monthly"
                      : "annually"}{" "}
                  </b>{" "}
                  for this subscription.
                </Text>
              </div>
            )}
            {!user.uid && (
              <div
                css={{
                  maxWidth: !isTabletOrMobile ? 503 : "100%",
                  marginTop: isTabletOrMobile ? 12 : 15,
                  padding: isTabletOrMobile ? "2px 7px" : "2px 24px",
                  borderRadius: 4,
                  background: "#1890ff30",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <Text
                  align="center"
                  css={{ fontSize: isTabletOrMobile ? "14px" : "14px" }}
                >
                  Already have a premium account?
                </Text>
                <Button
                  onClick={() => {
                    setShowPlanModal(false);
                    setAuthType("login");
                    setShowAuthModal(true);
                  }}
                  style={{
                    padding: "2px 20px",
                    lineHeight: "10px",
                    height: "25px",
                    textAlign: "center",
                    margin: "7px 0",
                  }}
                >
                  Login
                </Button>
              </div>
            )}
          </Flex>
        </Flex>
        <Flex
          flex="1"
          justify="center"
          css={{
            ...(isTabletOrMobile ? { width: "100%" } : {}),
            height: "100%",
          }}
        >
          <Flex
            css={{
              maxWidth: !isTabletOrMobile ? 420 : "100%",
              marginTop: isTabletOrMobile ? 0 : 22,
              padding: isTabletOrMobile ? "0 10px" : "0 24px",
            }}
            direction="column"
          >
            {!isTabletOrMobile && (
              <Flex
                direction="column"
                css={{ width: "100%", padding: "0 15px", margin: "0" }}
              >
                <Text bolder css={{ fontSize: "20px" }}>
                  Payment Details
                </Text>
                <Text
                  css={{
                    color: theme.colors.grayText,
                    marginTop: 0,
                    fontSize: 13,
                  }}
                >
                  Complete your purchase by providing your payment details
                </Text>
              </Flex>
            )}
            <StripeElementsNewDesign
              checkingOut={checkingOut}
              userName={userName}
              setCheckingOut={setCheckingOut}
              productId={
                plans.find((plan) => plan.name === selectedPlan)!?.productId
              }
              priceId={priceId}
              selectedPlan={selectedPlan}
              amount={plans.find((plan) => plan.name === selectedPlan)!?.amount}
            />
          </Flex>
        </Flex>
      </Flex>
    )
  );
};
