import axios from "axios";
import { Plan, PlanType } from "backend/utils/plan";
import { signInWithPopup } from "firebase/auth";
import { doc, getDoc, increment, setDoc, updateDoc } from "firebase/firestore";
import posthog from "posthog-js";
import { auth, db, firebaseErrors, googleAuthProvider } from "./firebase";
import { createUserAttributes } from "lib/helpers/userHelpers";
import { showAfterOnceClosed } from "components/auth/YourRole";

export const getUser = async (user) => {
  const document = await getDoc(doc(db, "users", user.uid));
  return document.data() ?? null;
};

export const signInWithGoogle = async () => {
  const res = await signInWithPopup(auth, googleAuthProvider);
  const user = res.user;
  return user;
};

export const getAuthError = (error) => {
  const errorCode = error.code;
  if (
    errorCode &&
    errorCode !== "auth/cancelled-popup-request" &&
    errorCode !== "auth/popup-closed-by-user" &&
    errorCode !== "auth/internal-error"
  ) {
    return firebaseErrors[errorCode];
  } else {
    return `Authentication error occurred`;
  }
};

export const identifyUser = async (user) => {
  const userAttributes = createUserAttributes({ user });

  posthog.identify(user.email, userAttributes);
};

export const createUser = async (user, name) => {
  await setDoc(doc(db, "users", user.uid), {
    name,
    email: user.email.toLowerCase(),
    plan: "Free",
    createdAt: new Date(),
    updatedAt: new Date(),
    openWhatsYourRoleAfter:
      Math.floor(new Date().getTime() / 1000) + showAfterOnceClosed,
    loginCount: 1,
    profilePicture: user.photoURL,
  });

  try {
    await axios.post("/api/member", {
      email: user.email.toLowerCase(),
    });
  } catch (error) {
    console.log(error, "error");
  }
};

export const authEvent = ({ provider, user, authType }) => {
  posthog.capture(authType, {
    id: user.email,
    email: user.email,
    created_at: Date.now(),
    provider,
  });
};

export const updateLoginCount = async (user) => {
  await updateDoc(doc(db, "users", user.uid), {
    loginCount: increment(1),
  });
};
