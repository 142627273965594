import { Theme } from "@emotion/react";
import maxBy from "lodash/maxBy";
import minBy from "lodash/minBy";
import omit from "lodash/omit";
import { Expression, Map as MapboxMap, SymbolLayout } from "mapbox-gl";
import { ComponentProps } from "react";
import { Layer, MapRef } from "react-map-gl";
import { Geo } from "types/MapContext";
import { FeatureState, LayerFillIds, SourceMap } from "types/Mapbox";
import { CurrentDataPoints, Datapoints } from "types/cube";
import { checkNumberValidity } from "./helpers/common";
import {
  generateHistoricColorSteps,
  generateIntervalMap,
  generateMapStep,
  getColorScale,
  quantileMaker,
} from "./helpers/mapHelpers";

export const geoSettingsMap = {
  state: {
    minZoom: 4,
    maxZoom: 6,
    defaultZoom: 4,
    fillOpacity: 0.45,
    lineWidth: [6, 3.2],
    lineWidthBold: 6,
    lineWidthDefault: 3.2,
    stateOutlineWidth: 3.5,
    stateOutlineOpacity: 0.5,
    source:
      "https://gis.data.census.gov/arcgis/rest/services/Hosted/VT_2021_040_00_PP_D1/VectorTileServer/tile/{z}/{y}/{x}.pbf",
    sharedLabelLayoutProperties: {
      "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
      "symbol-placement": "point",
      // 'text-variable-anchor': [
      //   'center',
      //   'left',
      //   'right',
      //   'top',
      //   'bottom',
      //   'top-left',
      //   'top-right',
      //   'bottom-left',
      //   'bottom-right',
      // ],
      "icon-ignore-placement": true,
      "icon-allow-overlap": true,
      "text-allow-overlap": true,
      "text-size": 16,
      "text-justify": "auto",
      "text-anchor": "bottom",
    } as SymbolLayout,
  },
  metro: {
    minZoom: 4,
    maxZoom: 17,
    defaultZoom: 6,
    fillOpacity: 0.3,
    lineWidth: [6, 1.5],
    lineWidthBold: 6,
    lineWidthDefault: 1.5,
    stateOutlineWidth: 3.5,
    stateOutlineOpacity: 0.5,
    source:
      "https://gis.data.census.gov/arcgis/rest/services/Hosted/VT_2021_310_M6_PY_D1/VectorTileServer/tile/{z}/{y}/{x}.pbf",
    sharedLabelLayoutProperties: {
      "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
      "symbol-placement": "point",
      // 'text-variable-anchor': [
      //   'center',
      //   'left',
      //   'right',
      //   'top',
      //   'bottom',
      //   'top-left',
      //   'top-right',
      //   'bottom-left',
      //   'bottom-right',
      // ],
      "icon-ignore-placement": true,
      "icon-allow-overlap": true,
      "text-allow-overlap": true,
      "text-ignore-placement": true,
      "text-size": ["interpolate", ["linear"], ["zoom"], 5, 12, 7, 14, 10, 16],
      "text-justify": "auto",
      "text-anchor": "bottom",
    },
  },
  county: {
    minZoom: 4,
    maxZoom: 17,
    defaultZoom: 9,
    fillOpacity: 0.3,
    lineWidth: [6, 1.5],
    lineWidthBold: 6,
    lineWidthDefault: 1.5,
    stateOutlineWidth: 3.5,
    stateOutlineOpacity: 0.5,
    source:
      "https://gis.data.census.gov/arcgis/rest/services/Hosted/VT_2021_050_00_PY_D1/VectorTileServer/tile/{z}/{y}/{x}.pbf",
    sharedLabelLayoutProperties: {
      "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
      "symbol-placement": "point",
      // 'text-variable-anchor': [
      //   'center',
      //   'left',
      //   'right',
      //   'top',
      //   'bottom',
      //   'top-left',
      //   'top-right',
      //   'bottom-left',
      //   'bottom-right',
      // ],
      // 'text-size': 16,
      "text-size": ["interpolate", ["linear"], ["zoom"], 5, 12, 7, 14, 10, 16],
      "text-justify": "auto",
      "text-anchor": "bottom",
      "icon-ignore-placement": true,
      "icon-allow-overlap": true,
      "text-allow-overlap": true,
      "text-ignore-placement": true,
    },
  },
  zip: {
    minZoom: 2,
    maxZoom: 17,
    defaultZoom: 12,
    fillOpacity: 0.21,
    lineWidth: [3, 1.25],
    lineWidthBold: 3,
    lineWidthDefault: 1.25,
    stateOutlineWidth: 1.5,
    stateOutlineOpacity: 0.5,
    source:
      "https://gis.data.census.gov/arcgis/rest/services/Hosted/VT_2022_860_Z2_PY_D1/VectorTileServer/tile/{z}/{y}/{x}.pbf",
    sharedLabelLayoutProperties: {
      "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
      // 'text-size': 13,
      "text-size": ["interpolate", ["linear"], ["zoom"], 5, 13, 17, 16],
      "symbol-placement": "point",
      // 'text-variable-anchor': [
      //   'center',
      //   'left',
      //   'right',
      //   'top',
      //   'bottom',
      //   'top-left',
      //   'top-right',
      //   'bottom-left',
      //   'bottom-right',
      // ],
      "icon-ignore-placement": true,
      "icon-allow-overlap": true,
      "text-allow-overlap": true,
      "text-ignore-placement": true,
      "text-justify": "auto",
      "text-anchor": "bottom",
    },
  },
};

export const getLayers = ({
  geoLayerPrefix,
  sourceName,
  geo,
  exploratory,
  scale,
  matchExpression,
  theme,
}: {
  geoLayerPrefix: string;
  sourceName: string;
  geo: Geo;
  exploratory: string;
  scale: [number, string][];
  matchExpression: Expression;
  theme: Theme;
}) => {
  type LayerProps = ComponentProps<typeof Layer>;
  const layers: Record<string, LayerProps[]> = {};

  layers[geoLayerPrefix] = [
    {
      id: `${geoLayerPrefix}-fill`,
      type: "fill",
      source: geoLayerPrefix,
      "source-layer": `${sourceName}`,
      paint: {
        "fill-opacity": [
          "case",
          ["==", ["feature-state", "isInvalid"], true],
          0.08,
          ["boolean", ["feature-state", "isFiltered"], false],
          geoSettingsMap[geo].fillOpacity,
          0,
        ],
        //@ts-ignore
        "fill-color": {
          property: exploratory,
          stops: scale,
        },
      },
    },
    {
      id: `${geoLayerPrefix}-line`,
      type: "line",
      source: geoLayerPrefix,
      "source-layer": `${sourceName}`,
      paint: {
        "line-opacity": [
          "case",
          ["==", ["feature-state", "isInvalid"], true],
          0.3,
          ["boolean", ["feature-state", "isFiltered"], false],
          0.9,
          0,
        ],
        //@ts-ignore
        "line-width": [
          "case",
          ["boolean", ["feature-state", "isHovered"], false],
          geoSettingsMap[geo].lineWidthBold,
          ["boolean", ["feature-state", "isSearched"], false],
          geoSettingsMap[geo].lineWidthBold,
          geoSettingsMap[geo].lineWidthDefault,
        ],
        "line-color": theme.colors.navy,
      },
    },
  ];

  const exploratoryLabelOptions: LayerProps = {
    id: "exploratory-label",
    type: "symbol",
    source: geoLayerPrefix === "states" ? "states-shape" : geoLayerPrefix,
    "source-layer": `${sourceName}/label`,
    layout: {
      "text-field": matchExpression as Expression,
      "text-offset": [0, 1.3],
      ...geoSettingsMap[geo].sharedLabelLayoutProperties,
    } as any,
    paint: {
      "text-opacity": [
        "case",
        ["boolean", ["feature-state", "isLabelHidden"], false],
        0,
        0.9,
      ],
      "text-color": theme.colors.navy,
      "text-halo-color": theme.colors.white,
      "text-halo-width": 1,
    },
  };

  const dataSource =
    geoLayerPrefix === "states" ? "states-shape" : geoLayerPrefix;

  if (layers[dataSource]) {
    layers[dataSource].push({
      ...(geoLayerPrefix === "states"
        ? omit(exploratoryLabelOptions, "source-layer")
        : exploratoryLabelOptions),
    });
  } else {
    layers[dataSource] = [
      {
        ...(geoLayerPrefix === "states"
          ? omit(exploratoryLabelOptions, "source-layer")
          : exploratoryLabelOptions),
      },
    ];
  }

  if (geo === Geo.ZIP) {
    if (!layers["metros"]) {
      layers["metros"] = [];
    }

    layers["metros"].push(
      {
        id: LayerFillIds[Geo.METRO],
        type: "fill",
        source: SourceMap[Geo.METRO].source,
        "source-layer": SourceMap[Geo.METRO].sourceLayer,
        paint: {
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "isFiltered"], false],
            0.15,
            0,
          ],
          "fill-color": theme.colors.black,
        },
      },
      {
        id: "metros-line",
        type: "line",
        source: SourceMap[Geo.METRO].source,
        "source-layer": SourceMap[Geo.METRO].sourceLayer,
        paint: {
          "line-opacity": [
            "case",
            ["boolean", ["feature-state", "isFiltered"], false],
            0.9,
            0,
          ],
          "line-width": [
            "case",
            ["boolean", ["feature-state", "isHovered"], false],
            6,
            1,
          ],
          "line-color": theme.colors.navy,
        },
      },
      {
        id: "metros-labels",
        type: "symbol",
        source: "metros",
        "source-layer": "CBSA/label",
        layout: {
          "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
          "symbol-placement": "point",
          "icon-ignore-placement": true,
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "text-ignore-placement": true,
          "text-field": ["get", "BASENAME"],
          "text-size": 14,
          "text-justify": "auto",
          "text-anchor": "bottom",
        },
        paint: {
          "text-opacity": [
            "case",
            ["boolean", ["feature-state", "isFiltered"], false],
            0.9,
            0,
          ],
          "text-color": theme.colors.navy,
          "text-halo-color": theme.colors.white,
          "text-halo-width": 1,
        },
      },
    );
  }
  if ([Geo.COUNTY, Geo.METRO, Geo.ZIP].includes(geo)) {
    layers["states"] = [];
    layers["states"].push(
      {
        id: "states-fill",
        type: "fill",
        source: "states",
        "source-layer": "State",
        paint: {
          "fill-opacity": 0,
        },
      },
      {
        id: "states-line",
        type: "line",
        source: "states",
        "source-layer": "State",
        paint: {
          "line-opacity": geo === Geo.COUNTY ? 0.6 : 0.7,
          "line-width": geo === Geo.COUNTY ? 4 : 2,
          "line-color": geo === Geo.COUNTY ? "#000000" : "#00212c",
        },
      },
    );
  }
  return layers;
};

export const repaintMap = ({
  map,
  geo,
  theme,
  setScale,
  exploratory,
  featureStates,
}: {
  map: MapboxMap;
  geo: Geo;
  theme: Theme;
  setScale: any;
  exploratory: string;
  featureStates: FeatureState[];
}) => {
  try {
    const featureValues = featureStates
      .filter((feature) => {
        return feature.isFiltered && checkNumberValidity(feature[exploratory]);
      })
      .map((feature) => feature[exploratory]);

    if (featureValues?.length && map) {
      const maxVal = maxBy(featureValues, (v) => v);
      const minVal = minBy(featureValues, (v) => v);
      const scale = quantileMaker(5, minVal, maxVal, exploratory == Datapoints.VOTING_DEMOCRAT_PERCENTAGE ? theme.map.colorRangeReverse : theme.map.colorRange);

      setScale(scale);

      if (featureValues.length) {
        const intervalMap = generateIntervalMap(featureValues);
        const mapState = generateMapStep(
          intervalMap,
            exploratory === Datapoints.VOTING_DEMOCRAT_PERCENTAGE ? theme.map.paintPercentileColorRangeRevers :theme.map.paintPercentileColorRange,
        );

        map.setPaintProperty(`${SourceMap[geo].source}-fill`, "fill-color", [
          "step",
          ["feature-state", exploratory],
          ...mapState,
        ]);
      }
    }

    map?.triggerRepaint();
  } catch (e) {
    console.error("Error", e);
  }
};

export const repaintHistoricMap = ({
  map,
  geo,
  theme,
  setScale,
  exploratory,
  featureStates,
  midPoint,
}: {
  map: MapboxMap;
  geo: Geo;
  theme: Theme;
  setScale: any;
  exploratory: Datapoints;
  featureStates: FeatureState[];
  midPoint?: number;
}) => {
  try {
    const expression = ["case"] as Expression;
    const colorRange = exploratory === Datapoints.VOTING_DEMOCRAT_PERCENTAGE ? theme.map.paintPercentileColorRangeRevers :theme.map.paintPercentileColorRange;
    const rangeLength = colorRange.length;

    setScale(getColorScale(featureStates, exploratory, colorRange));

    const sanitized = featureStates.filter((v) => !!v[exploratory]);
    const rangeSteps = generateHistoricColorSteps(
      sanitized,
      exploratory,
      Math.ceil(rangeLength / 2),
      midPoint,
    );

    rangeSteps.forEach((_, index) => {
      if (index < Math.floor(rangeLength / 2)) {
        expression.push(
          ["<", ["feature-state", exploratory], rangeSteps[index + 1]],
          colorRange[index],
        );
      } else if (index === Math.floor(rangeLength / 2)) {
        expression.push(
          ["==", ["feature-state", exploratory], rangeSteps[index]],
          colorRange[index],
        );
      } else if (index === rangeLength - 1) {
        expression.push(
          [">=", ["feature-state", exploratory], rangeSteps[index - 1]],
          colorRange[index],
        );
      } else if (index > Math.floor(rangeLength / 2)) {
        expression.push(
          ["<", ["feature-state", exploratory], rangeSteps[index]],
          colorRange[index],
        );
      }
    });

    expression.push(colorRange[Math.ceil(rangeLength / 2)]);

    map.setPaintProperty(
      `${SourceMap[geo].source}-fill`,
      "fill-color",
      expression,
    );

    map?.triggerRepaint();
  } catch (e) {
    console.error("Error", e);
  }
};

export const featureMeetsCriteriaState = (selectedFilter, filteredProperty) => {
  if (selectedFilter.label === "state_code") {
    return selectedFilter?.val?.includes(filteredProperty);
  }
  if (!selectedFilter.val.length) return true;

  const [lower, upper] = selectedFilter.val;
  const fixedProperty = Number(Number(filteredProperty).toFixed(1));
  const fixedLower = Number(Number(lower).toFixed(1));
  const fixedUppper = Number(Number(upper).toFixed(1));

  return fixedProperty >= fixedLower && fixedProperty <= fixedUppper;
};

export const clearPreviousFeaturesByLayer = (
  geos: Geo[],
  map: MapboxMap | MapRef,
) => {
  geos.forEach((geo) => {
    map.removeFeatureState({
      source: SourceMap[geo].source,
      sourceLayer: SourceMap[geo].sourceLayer,
    });
  });
};

export function createGeoId(row: CurrentDataPoints, geo: Geo) {
  return geo === Geo.COUNTY ? row.state_code + row.geo_code : row.geo_code;
}
