import { Datapoints, ScoreDatapoints } from "types/cube";
import { ExploratoryMap, ExploratorySources } from "types/exploratory";

export const exploratoryMap: ExploratoryMap = {
  [Datapoints.POPULATION]: {
    code: Datapoints.POPULATION,
    name: "Population",
    filterName: "Population",
    sources: [ExploratorySources.ACS],
    censusCodes: ["B01003_001E"],
    summary: `The area's population according to the most recent year of the US Census Bureau American Community Survey (“ACS”).`,
    calculated: false,
  },
  [Datapoints.COLLEGE_DEGREE_RATE]: {
    code: Datapoints.MEDIAN_HOUSEHOLD_INCOME,
    name: "College Degree Rate",
    filterName: "College Degree Rate",
    sources: [ExploratorySources.ACS],
    range: "2023",
    censusCodes: [
      "B15002_001E", // total_education_population
      "B15002_014E", // male_associate_degree
      "B15002_015E", // male_bachelors_degree
      "B15002_016E", // male_master_degree
      "B15002_017E", // male_professional_degree
      "B15002_018E", // male_doctorate_degree
      "B15002_032E", // female_bachelors_degree
      "B15002_033E", // female_master_degree
      "B15002_034E", // female_professional_degree
      "B15002_035E", // female_doctorate_degree
    ],

    summary: ` The percentage of 25+ aged population in the area that has a bachelor's degree or higher. Generally speaking - higher college degree rates are associated with higher home prices and income levels.`,
    calculated: false,
  },
  [Datapoints.MEDIAN_HOUSEHOLD_INCOME]: {
    code: Datapoints.MEDIAN_HOUSEHOLD_INCOME,
    name: "Median Household Income",
    filterName: "Median Household Income",
    sources: [ExploratorySources.ACS],
    range: "2023",
    censusCodes: ["B19013_001E"],
    summary: `The income of the median, or middle, household in the area according to the most recent year of the US Census Bureau American Community Survey (“ACS”).`,
    calculated: false,
  },
  [Datapoints.HOUSEHOLDS]: {
    code: Datapoints.HOUSEHOLDS,
    name: "Households",
    filterName: "Households",
    sources: [ExploratorySources.ACS],
    censusCodes: ["B11001_001E"],
    summary: ``,
    calculated: false,
  },
  [Datapoints.OVER_UNDER_VALUED_PERCENTAGE]: {
    code: Datapoints.OVER_UNDER_VALUED_PERCENTAGE,
    name: "Overvalued %",
    filterName: "Overvalued %",
    sources: [ExploratorySources.ZILLOW, ExploratorySources.ACS],
    range: "2023",
    censusCodes: ["B19013_001E"],
    summary: `An estimate of how over or undervalued current Home Values in the area are relative to the fundamentals dictated by local median incomes. The Overvaluation % is calculated by comparing the area's current Value / Income Ratio to the long-term average. Note that this is not a projection for how much home prices will increase or decline, but merely an estimate of over or undervaluation compared to incomes at a given point in time.`,
    calculated: true,
  },
  [Datapoints.PERCENT_CRASH_GREAT_RECESSION]: {
    code: Datapoints.PERCENT_CRASH_GREAT_RECESSION,
    name: "% Crash from 2007-12",
    filterName: "% Crash from 2007-12",
    sources: [ExploratorySources.ZILLOW],
    range: "2007-2012",
    censusCodes: ["B19013_001E"],
    summary: `How much home prices declined in the biggest Housing Crash of all-time from 2007 to 2012 according to the Zillow Home Value Index (“ZHVI”). Note some geographies might be excluded as they didn't have full data reporting back then. `,
    calculated: false,
  },
  [Datapoints.MEDIAN_AGE]: {
    code: Datapoints.MEDIAN_AGE,
    name: "Median Age",
    filterName: "Median Age",
    sources: [ExploratorySources.ACS],
    censusCodes: ["B01002_001E"],
    summary: `The age of the median, or middle, resident according to the US Census Bureau.`,
    calculated: false,
  },
  [Datapoints.POVERTY_PERCENTAGE]: {
    code: Datapoints.POVERTY_PERCENTAGE,
    name: "Poverty Rate",
    filterName: "Poverty Rate",
    sources: [ExploratorySources.ACS],
    description: "Pop. Below Poverty Line / Total Pop.",
    censusCodes: ["B14006_002E", "B01003_001E"],
    summary: `The percentage of households in the area that fall below the federal poverty rate line based on the most recent year of the US Census Bureau American Community Survey (“ACS”).`,
    calculated: false,
  },
  [Datapoints.HOME_OWNERSHIP_RATE]: {
    code: Datapoints.HOME_OWNERSHIP_RATE,
    name: "Homeownership Rate",
    filterName: "Homeownership Rate",
    sources: [ExploratorySources.ACS],
    description: "Ownership HHs / Total HHs",
    censusCodes: ["B25003_002E", "B11001_001E"],
    summary: `The percentage of Households that own their homes in the local area according to the US Census Bureau.`,
    calculated: false,
  },
  [Datapoints.POPULATION_GROWTH_RATE]: {
    code: Datapoints.POPULATION_GROWTH_RATE,
    name: "Population Growth",
    filterName: "Population Growth",
    sources: [ExploratorySources.ACS],
    range: "2016 - 2021",
    censusCodes: ["B01003_001E"],
    summary: `The growth rate in Population over the last five years according to the most recent year of the US Census Bureau American Community Survey (“ACS”).`,
    calculated: false,
  },
  [Datapoints.HOME_VALUE_TO_EARNINGS_RATIO]: {
    code: Datapoints.HOME_VALUE_TO_EARNINGS_RATIO,
    name: "Value / Income Ratio",
    filterName: "Value / Income Ratio",
    sources: [ExploratorySources.ZILLOW, ExploratorySources.ACS],
    summary: `The typical Home Value in the area divided by the Median Household Income, an indication of how expensive houses are for local buyers. The higher the Value / Income Ratio compared to historical norms in the area, the greater the likelihood of a Housing Bubble.`,
    calculated: false,
  },
  [Datapoints.HOME_VALUE_TO_ANNUAL_RENT_RATIO]: {
    code: Datapoints.HOME_VALUE_TO_ANNUAL_RENT_RATIO,
    name: "Home Value to Rent Ratio",
    filterName: "Home Value to Rent Ratio",
    sources: [ExploratorySources.ZILLOW],
    summary: `The typical Home Value in an area divided by the Annual Rent for Houses. This metric gives an indication of how expensive it is for a homebuyer to buy compared to rent. This metric also gives an indication of how much rental revenue an investor can expect relative to their purchase price (lower Home Value to Rent Ratios indicate more affordable markets to buy from an investor perspective).`,
    calculated: false,
  },
  [Datapoints.MIGRATION_PERCENTAGE]: {
    code: Datapoints.MIGRATION_PERCENTAGE,
    name: "Migration % of Population",
    filterName: "Migration % of Population",
    sources: [ExploratorySources.ACS],
    description: "Total Migration / Population",
    range: "2022",
    summary: `A relative indication of how many people are moving to an area, taking the most recent years Migration Total and dividing it by the Population. The higher the Migration %, the more people that are moving in.`,
    calculated: false,
  },
  [Datapoints.MEDIAN_INCOME_GROWTH_RATE]: {
    code: Datapoints.MEDIAN_INCOME_GROWTH_RATE,
    name: "Income Growth",
    filterName: "Income Growth",
    sources: [ExploratorySources.ACS],
    range: "2016 - 2022",
    censusCodes: ["B19013_001E"],
    summary: `The growth rate in Median Household Income over the last five years according to the most recent year of the US Census Bureau American Community Survey (“ACS”). Higher income growth is generally a positive for Home Values.`,
    calculated: false,
  },
  [Datapoints.HOME_VALUE]: {
    code: Datapoints.HOME_VALUE,
    name: "Home Value",
    filterName: "Home Value",
    sources: [ExploratorySources.ZILLOW],
    summary: `The area's typical Home Value as determined by the Zillow Home Value Index (“ZHVI”). This is a smoothed, seasonally adjusted figure.`,
    calculated: false,
  },
  [Datapoints.HOME_VALUE_GROWTH_RATE]: {
    code: Datapoints.HOME_VALUE_GROWTH_RATE,
    name: "Home Value Growth (YoY)",
    filterName: "Home Value Growth (YoY)",
    description: "Home Value Growth (12-Month)",
    sources: [ExploratorySources.ZILLOW],
    summary: `The year-over-year growth rate in the area's typical Home Value according to the Zillow Home Value Index (“ZHVI”).`,
    calculated: false,
  },
  [Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE]: {
    code: Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE,
    name: "Home Value Growth (5-Year)",
    filterName: "Home Value Growth (5-Year)",
    description: "Home Value Growth (5-Year)",
    sources: [ExploratorySources.ZILLOW],
    summary: `The 5-year growth rate in home values for an area. Looking at this data point gives a longer-term view of appreciation in the market. The higher the long-term appreciation, the better for homeowners and investors. However - make sure to use this data point in conjunction with Overvalued % to understand if 5-year appreciation is likely to go up or down into the future`,
    calculated: false,
  },
  [Datapoints.TOTAL_ACTIVE_INVENTORY]: {
    code: Datapoints.TOTAL_ACTIVE_INVENTORY,
    name: "For Sale Inventory",
    filterName: "For Sale Inventory",
    sources: [ExploratorySources.REALTOR],
    summary: `The number of active listings on the market for sale in the area in a given month as determined by Realtor.com. Note: For Sale Inventory excludes listings that are pending.`,
    calculated: false,
  },
  [Datapoints.HOUSING_UNITS]: {
    code: Datapoints.HOUSING_UNITS,
    name: "Housing Units",
    filterName: "Housing Units",
    sources: [ExploratorySources.ACS],
    censusCodes: ["B25001_001E"],
    summary: `The number of Housing Units in the area according to the most year of the US Census Bureau American Community Survey (“ACS”).`,
    calculated: false,
  },
  [Datapoints.MOM_ACTIVE_INVENTORY_CHANGE_PERCENTAGE]: {
    code: Datapoints.MOM_ACTIVE_INVENTORY_CHANGE_PERCENTAGE,
    name: "Sale Inventory Growth (MoM)",
    filterName: "Sale Inventory Growth (MoM)",
    sources: [ExploratorySources.REALTOR],
    summary: `The month-over-month growth rate in the area's For Sale Inventory according to Realtor.com. Note that the monthly growth rate in inventory is heavily influenced by seasonality in the local Housing Market.`,
    calculated: false,
  },
  [Datapoints.SHARE_OF_LISTINGS_WITH_PRICE_CUT]: {
    code: Datapoints.SHARE_OF_LISTINGS_WITH_PRICE_CUT,
    name: "Price Cut %",
    filterName: "Price Cut %",
    sources: [ExploratorySources.REALTOR],
    summary: `The percentage of total listings that had a price reduction in each month according to Realtor.com. If more sellers are cuting the price compared to historical norms, it could be a signal that home prices will drop in future months.`,
    calculated: false,
  },
  [Datapoints.YOY_INVENTORY_CHANGE_PERCENTAGE]: {
    code: Datapoints.YOY_INVENTORY_CHANGE_PERCENTAGE,
    name: "Sale Inventory Growth (YoY)",
    filterName: "Sale Inventory Growth (YoY)",
    sources: [ExploratorySources.REALTOR],
    description: "For Sale Inventory Growth (12-Month)",
    summary: `The year-over-year growth rate in the area's For Sale Inventory according to Realtor.com. If an area has big inventory increases, it could mean greater likelihood of home price declines, while sharply lower inventory could mean that home prices will continue to go up.`,
    calculated: false,
  },
  [Datapoints.DAYS_ON_MARKET]: {
    code: Datapoints.DAYS_ON_MARKET,
    name: "Days on Market",
    filterName: "Days on Market",
    sources: [ExploratorySources.REALTOR],
    summary: `The median number of days a house spends on the market before it either sells or is de-listed. Big increases in Days on the Market from historical norms could be a signal that the local Housing Market is slowing.`,
    calculated: false,
  },
  [Datapoints.YOY_DAYS_ON_MARKET]: {
    code: Datapoints.YOY_DAYS_ON_MARKET,
    name: "Days on Market Growth (YoY)",
    filterName: "Days on Market Growth (YoY)",
    sources: [ExploratorySources.REALTOR],
    description: "Days on Market Growth (12-Month)",
    summary: `The year-over-year growth rate in the area's median Days on the Market according to Realtor.com.`,
    calculated: false,
  },
  [Datapoints.MEDIAN_TAXES]: {
    code: Datapoints.MEDIAN_TAXES,
    name: "2022 Property Taxes",
    filterName: "2022 Property Taxes",
    sources: [ExploratorySources.ZILLOW, ExploratorySources.ACS],
    censusCodes: ["B25103_001E", "B25077_001E"],
    summary: ``,
    calculated: false,
  },
  [Datapoints.PROPERTY_TAX_RATE]: {
    code: Datapoints.PROPERTY_TAX_RATE,
    name: "Property Tax Rate",
    filterName: "Property Tax Rate",
    sources: [ExploratorySources.ZILLOW, ExploratorySources.ACS],
    censusCodes: ["B25103_001E", "B25077_001E"],
    summary: `The Property Tax Rate as a percentage of the Typical Home Value in the area. Note: this data is derived from the US Census Bureau and is reported on a lag, so in certain cases it might be off slightly from what you see in Zillow's Tax Estimates or in your Property Tax Bills.`,
    calculated: false,
  },
  [Datapoints.NET_RENT_INCOME]: {
    code: Datapoints.NET_RENT_INCOME,
    name: "Net Rent Income",
    filterName: "Net Rent Income",
    sources: [ExploratorySources.ZILLOW, ExploratorySources.ACS],
    censusCodes: ["B25059_001E"],
    summary: `The area's typical Annual Rent divided by the Median Household Income, an indication of renter affordability.`,
    calculated: false,
  },
  [Datapoints.CAP_RATE]: {
    code: Datapoints.CAP_RATE,
    name: "Cap Rate",
    filterName: "Cap Rate",
    sources: [ExploratorySources.ZILLOW, ExploratorySources.ACS],
    censusCodes: ["B25103_001E", "B25077_001E"],
    summary: `The unlevered annual return a real estate investor can expect if they were to buy a property at prevailing Home Values and rent it out at prevailing Rental Rates. Higher cap rates mean more cash flow returns to investors. Note that Cap Rate figures on Reventure App are estimates and could be subject to error, especially at the ZIP Code Level.`,
    calculated: true,
  },
  [Datapoints.TYPICAL_HOUSE_PAYMENT]: {
    code: Datapoints.TYPICAL_HOUSE_PAYMENT,
    name: "Mortgage Payment",
    filterName: "Mortgage Payment",
    sources: [
      ExploratorySources.ZILLOW,
      ExploratorySources.ACS,
      ExploratorySources.FRED,
    ],
    censusCodes: ["B25027_002E", "B25001_001E", "B25077_001E", "B25103_001E"],
    description:
      "Calculation assumes 13% down with recent home values and interest rates",
    summary: `An estimate of the Mortgage Payment, including mortgage interest, property taxes, and insurance, a homebuyer can expect in each area given prevailing Home Values and Mortgage Rates.`,
    calculated: false,
  },
  [Datapoints.SALARY_TO_AFFORD_HOUSE]: {
    code: Datapoints.SALARY_TO_AFFORD_HOUSE,
    name: "Salary to Afford a House",
    filterName: "Salary to Afford a House",
    sources: [
      ExploratorySources.ZILLOW,
      ExploratorySources.ACS,
      ExploratorySources.FRED,
    ],
    summary: `The household income needed to comfortably afford to buy a house in the area at current home prices and mortgage rates. This calculation assumes that a household spends 30% of their gross income on their mortgage, tax, and insurance payment`,
    calculated: false,
  },
  [Datapoints.HOUSE_PAYMENT_RATE_OF_MED_INCOME]: {
    code: Datapoints.HOUSE_PAYMENT_RATE_OF_MED_INCOME,
    name: "Mtg Payment as % of Income",
    filterName: "Mtg Payment as % of Income",
    sources: [
      ExploratorySources.ZILLOW,
      ExploratorySources.ACS,
      ExploratorySources.FRED,
    ],
    summary: `The area's Annual House Payment, including mortgage interest, property taxes, and insurance, divided by the area's Median Household Income. This is a measure of relatively homebuyer affordability given prevailing Home Values and Mortgage Rates.`,
    calculated: false,
    censusCodes: [
      "B19013_001E",
      "B25027_002E",
      "B25001_001E",
      "B25077_001E",
      "B25103_001E",
    ],
  },
  [Datapoints.TOTAL_MIGRATION]: {
    code: Datapoints.TOTAL_MIGRATION,
    name: "Migration Total",
    filterName: "Migration Total",
    sources: [ExploratorySources.ACS],
    range: "2022",
    summary: `Think of this data point as “how many people are moving here”. The net inbound or outbound Migration from an area in the most recent year according to the US Census Bureau migration report. Note that this data is only available at the State, Metro, and County levels.`,
    calculated: false,
  },
  [Datapoints.MORTGAGED_HOME_PERCENTAGE]: {
    code: Datapoints.MORTGAGED_HOME_PERCENTAGE,
    name: "Mortgaged Home %",
    filterName: "Mortgaged Home %",
    sources: [ExploratorySources.ACS],
    censusCodes: ["B25027_002E", "B25001_001E", "B11001_001E"],
    summary: `The percentage of Homeowner Households in the area that have a mortgage on their property.`,
    calculated: false,
  },
  [Datapoints.HOUSING_UNITS_WITH_A_MORTGAGE]: {
    code: Datapoints.HOUSING_UNITS_WITH_A_MORTGAGE,
    name: "Housing Units With Mortgage",
    filterName: "Housing Units With Mortgage",
    sources: [ExploratorySources.ACS],
    censusCodes: ["B25027_002E"],
    summary: ``,
    calculated: false,
  },
  [Datapoints.OWNERSHIP_HHS]: {
    code: Datapoints.OWNERSHIP_HHS,
    name: "Owner Occupied Units",
    filterName: "Owner Occupied Units",
    sources: [ExploratorySources.ACS],
    censusCodes: ["B25003_002E"],
    summary: ``,
    calculated: false,
  },
  [Datapoints.ABSENTEE_OWNED_UNITS]: {
    code: Datapoints.ABSENTEE_OWNED_UNITS,
    name: "Absentee Owned Units",
    filterName: "Absentee Owned Units",
    sources: [ExploratorySources.ACS],
    censusCodes: ["B25004_006E"],
    summary: ``,
    calculated: false,
  },
  [Datapoints.ABSENTEE_OWNER_PERCENTAGE]: {
    code: Datapoints.ABSENTEE_OWNER_PERCENTAGE,
    name: "Shadow Inventory %",
    filterName: "Shadow Inventory %",
    sources: [ExploratorySources.ACS],
    censusCodes: ["B25001_001E", "B25004_006E"],
    summary: `The share of owned-houses that are seasonally vacant and/or owned by absentee owners. Areas with higher Shadow Inventory could be more prone to home price volatility and declines during a Recession. Shadow Inventory tends to be higher in vacation and 2nd homes destinations.`,
    calculated: false,
  },
  [Datapoints.RENT_FOR_APARTMENTS]: {
    code: Datapoints.RENT_FOR_APARTMENTS,
    name: "Rental Rate",
    filterName: "Rental Rate",
    sources: [ExploratorySources.ZILLOW],
    // censusCodes: ['B01003_001E'],
    summary: `The area’s typical Monthly Rent as determined by the Zillow Observed Rent Index (“ZORI”) at the Metro Level. Note that Rental Rates at the County and ZIP Code Levels were estimated by comparing Zillow’s rental rate values to historical US Census Bureau Rents.`,
    calculated: false,
  },
  [Datapoints.RENT_FOR_HOUSES]: {
    code: Datapoints.RENT_FOR_HOUSES,
    name: "Rent For Houses",
    filterName: "Rent For Houses",
    sources: [ExploratorySources.ZILLOW],
    // censusCodes: ['B01003_001E'],
    summary: `The typical Monthly Rent for a medium-sized to large house in the area. Rent for Houses is calculated by increasing the Zillow Observed Rent Index (“ZORI”) by 25% to account for larger-sized units.`,
    calculated: false,
  },
  [Datapoints.BUY_VERSUS_RENT_PERCENTAGE]: {
    code: Datapoints.BUY_VERSUS_RENT_PERCENTAGE,
    name: "Buy v Rent Differential",
    filterName: "Buy v Rent Differential",
    sources: [ExploratorySources.ZILLOW, ExploratorySources.ACS],
    // censusCodes: ['B01003_001E'],
    summary: `Compares the cost of Buying a House in terms of the Mortgage Payment to the cost of Renting an Apartment. The higher the percentage, the more expensive it to Buy compared to Rent.`,
    calculated: false,
  },
  [Datapoints.INVENTORY_PERCENTAGE_OF_HOUSES]: {
    code: Datapoints.INVENTORY_PERCENTAGE_OF_HOUSES,
    name: "Inventory as % of Houses",
    filterName: "Inventory as % of Houses",
    sources: [ExploratorySources.REALTOR],
    // censusCodes: ['B01003_001E'],
    summary: `The number of Homes currently For Sale according to Realtor.com divided by the total numbers of owned Homes in the area.`,
    calculated: false,
  },
  [Datapoints.RENT_PERCENTAGE_OF_INCOME]: {
    code: Datapoints.RENT_PERCENTAGE_OF_INCOME,
    name: "Rent as % of Income",
    filterName: "Rent as % of Income",
    sources: [ExploratorySources.ZILLOW, ExploratorySources.ACS],
    // censusCodes: ['B01003_001E'],
    summary: `The area's typical Annual Rent divided by the Median Household Income, an indication of renter affordability.`,
    calculated: false,
  },
  [Datapoints.MOM_HOME_VALUE_GROWTH]: {
    code: Datapoints.MOM_HOME_VALUE_GROWTH,
    name: "Home Value Growth (MoM)",
    filterName: "Home Value Growth (MoM)",
    sources: [ExploratorySources.ZILLOW],
    // censusCodes: ['B01003_001E'],
    summary: `The month-over-month growth rate in the area's typical Home Value according to the Zilllow Home Value Index (“ZHVI”).`,
    calculated: false,
  },
  [Datapoints.RENT_GROWTH_YOY]: {
    code: Datapoints.RENT_GROWTH_YOY,
    name: "Rent Growth (YoY)",
    filterName: "Rent Growth (YoY)",
    sources: [ExploratorySources.ZILLOW],
    summary: `The year-over-year growth rate in Rental Rates according to the Zillow Observed Rent Index (“ZORI”). Note that this data point is currently only available at Metro and County geographies.`,
    calculated: false,
  },
  [Datapoints.HOUSING_UNIT_GROWTH]: {
    code: Datapoints.HOUSING_UNIT_GROWTH,
    name: "Housing Unit Growth Rate",
    filterName: "Housing Unit Growth Rate",
    sources: [ExploratorySources.ACS],
    range: "2016 - 2021",
    censusCodes: ["B25001_001E"],
    summary: `The growth rate in the number of Housing Units in the area over the last five years according to the most recent years of the US Census Bureau American Community Survey. Higher rates of Housing Unit Growth tend to occur in areas with high Population Growth.`,
    calculated: false,
  },
  [Datapoints.PER_CHANGE_HOME_VALUE_JUNE]: {
    code: Datapoints.PER_CHANGE_HOME_VALUE_JUNE,
    name: "% Change from June 2022",
    filterName: "% Change from June 2022",
    sources: [ExploratorySources.ZILLOW],
    summary: `The % change in Home Value from the "peak" of the Housing Bubble in June 2022. Home Values in many markets have declined since then, while in other markets they are still rising. Homebuyers and investors wanting a quick understanding of the medium-term trends in their markets should look at this data point.`,
    calculated: false,
  },
  [Datapoints.MEDIAN_LISTING_PRICE]: {
    code: Datapoints.MEDIAN_LISTING_PRICE,
    name: "Median Listing Price",
    filterName: "Median Listing Price",
    sources: [ExploratorySources.REALTOR],
    summary: `The median price of total listings in the specified area during the most recent month. Median List Price is a good real-time indicator of how prices are trending in the market. However, be sure to note that in smaller geographies with a lower sample of listings (e.g., Counties or ZIP Codes), the list price can fluctuate significantly based on the size and quality of houses listed in a given month.`,
    calculated: false,
  },
  [Datapoints.MEDIAN_LISTING_PRICE_YOY]: {
    code: Datapoints.MEDIAN_LISTING_PRICE_YOY,
    name: "Median Listing Price (YoY)",
    filterName: "Median Listing Price (YoY)",
    sources: [ExploratorySources.REALTOR],
    summary: `The year-over-year growth rate in the Median List Price of listings in the specific geography in the most recent month. Looking at this data point will help you understand if sellers are increasing or decreasing the prices of listings compared to one year ago. However, please note: there can be significant fluctuations in median list price based on the size and quality of houses listed, so this might not always be an apples-to-apples comparison of true appreciation.`,
    calculated: false,
  },
  [Datapoints.NEW_LISTING_COUNT]: {
    code: Datapoints.NEW_LISTING_COUNT,
    name: "New Listing Count",
    filterName: "New Listing Count",
    sources: [ExploratorySources.REALTOR],
    summary: `The number of new seller listings that came to the market in a given month. If the number of new listings is increasing over historical norms, that could be a sign that some distress in the market is building and that supply and inventory will shoot up in future months. Conversely, if new listings are decreasing from historical norms, it could be a signal that seller's in the market are "on strike" and refusing to list their homes.`,
    calculated: false,
  },
  [Datapoints.NEW_LISTING_COUNT_YOY]: {
    code: Datapoints.NEW_LISTING_COUNT_YOY,
    name: "New Listing Count (YoY)",
    filterName: "New Listing Count (YoY)",
    sources: [ExploratorySources.REALTOR],
    summary: `The year-over-year growth rate in new seller listings in a given geographic area. The higher the growth rate in New Listings, the more likely it is that inventory will increase and prices decline. Moreover, if New Listings are on a sharp decline, that could eventually translate into a lack of inventory and more stable or growing prices.`,
    calculated: false,
  },
  [Datapoints.FOR_SALE_INVENTORY_V_AVG]: {
    code: Datapoints.FOR_SALE_INVENTORY_V_AVG,
    name: "Inventory Surplus/Deficit",
    filterName: "Inventory Surplus/Deficit",
    sources: [ExploratorySources.REALTOR],
    summary: `Comparing the inventory of active listings in the most recent month compared to the long-term average for that month. This metric will you help you understand if inventory in your area is "high" or "low" compared to the long-run norm. Areas with more inventory than normal could be more exposed to price declines in the future. While areas with less inventory could experience more stable prices, or prices going up.`,
    calculated: false,
  },

  [Datapoints.VOTING_DEMOCRAT_PERCENTAGE]: {
    code: Datapoints.VOTING_DEMOCRAT_PERCENTAGE,
    name: "Vote Democrat %",
    filterName: "Vote Democrat %",
    sources: [ExploratorySources.HARVARD],
    summary: `Voting trends in the last 7 presidential elections, expressed as the % of votes that went to the Democrat candidate. Most recent election displayed is 2024.`,
    calculated: false,
  },

  [Datapoints.VOTING_REPUBLICAN_PERCENTAGE]: {
    code: Datapoints.VOTING_REPUBLICAN_PERCENTAGE,
    name: "Vote Republican %",
    filterName: "Vote Republican %",
    sources: [ExploratorySources.HARVARD],
    summary: `Voting trends in the last 7 presidential elections, expressed as the % of votes that went to the Republican candidate. Most recent election displayed is 2024.`,
    calculated: false,
  },
  // [Datapoints.BUILDING_PERMITS]: {
  //   code: Datapoints.BUILDING_PERMITS,
  //   name: "Building Permits",
  //   filterName: "Building Permits",
  //   sources: [ExploratorySources.ACS],
  //   summary: `The number of building permits of new structures, both single-family and multifamily, pulled over the previous 12 months in the year. Data comes from the US Census Bureau.`,
  //   calculated: false,
  // },
  // [Datapoints.SINGLE_FAMILY_UNITS_PERMITTED]: {
  //   code: Datapoints.SINGLE_FAMILY_UNITS_PERMITTED,
  //   name: "Single Family Permits",
  //   filterName: "Single Family Permits",
  //   sources: [ExploratorySources.ACS],
  //   summary: ``,
  //   calculated: false,
  // },
  // [Datapoints.MULTI_FAMILY_UNITS_PERMITTED]: {
  //   code: Datapoints.MULTI_FAMILY_UNITS_PERMITTED,
  //   name: "Multi Family Permits",
  //   filterName: "Multi Family Permits",
  //   sources: [ExploratorySources.ACS],
  //   summary: ``,
  //   calculated: false,
  // },
  [Datapoints.TRAILING_TWELVE_MONTHS_BUILDING_PERMITS]: {
    code: Datapoints.TRAILING_TWELVE_MONTHS_BUILDING_PERMITS,
    name: "Building Permits",
    filterName: "Building Permits",
    sources: [ExploratorySources.ACS],
    summary: `The 12-month sum of the number of building permits for new structures pulled by home builders and apartment developers. This data is reported to the US Census Bureau each month and is a good indication of how much future housing supply a homebuyer or investor can expect in a market. Permits can take anywhere from 6 to 24 months to turn into a completed unit.`,
    calculated: false,
  },
  // [Datapoints.TRAILING_TWELVE_MONTHS_SINGLE_PERMITS]: {
  //   code: Datapoints.TRAILING_TWELVE_MONTHS_SINGLE_PERMITS,
  //   name: "Single Family Permits",
  //   filterName: "Single Family Permits",
  //   sources: [ExploratorySources.ACS],
  //   summary: `The 12-month sum of single family building permits pulled by home builders. This data is reported to the US Census Bureau each month and is a good indication fo how much future housing supply a homebuyer or investor can expect in a market over the next year.`,
  //   calculated: false,
  // },
  // [Datapoints.TRAILING_TWELVE_MONTHS_MULTI_PERMITS]: {
  //   code: Datapoints.TRAILING_TWELVE_MONTHS_MULTI_PERMITS,
  //   name: "Multi Family Permits",
  //   filterName: "Multi Family Permits",
  //   sources: [ExploratorySources.ACS],
  //   summary: `The 12-month sum of multi family building permits pulled by home builders - specifically, structures where there is more than one unit being built. This data is reported to the US Census Bureau each month and is a good indication of how much future housing supply a homebuyer or investor can expect in a market over the two years. Note: this data also includes for-sale condo units.`,
  //   calculated: false,
  // },
  // [Datapoints.BUILDING_PERMIT_PERCENTAGE]: {
  //   code: Datapoints.BUILDING_PERMIT_PERCENTAGE,
  //   name: "Building Permit %",
  //   filterName: "Building Permit %",
  //   sources: [ExploratorySources.ACS],
  //   summary: `An indication of how much relative home building there is in an area, calculated by dividing the number of total building permits pulled over the last 12 months by the population. The higher the Building Permit %, the more supply that will be added to the market in the future. Markets with a high Building Permit % will need a high level of demographic growth to absorb the new supply. Conversely - markets with a low Building Permit % could be facing a housing shortage.`,
  //   calculated: false,
  // },
  [Datapoints.EMPLOYMENT_LEVEL]: {
    code: Datapoints.EMPLOYMENT_LEVEL,
    name: "Employment Level",
    filterName: "Employment Level",
    sources: [ExploratorySources.BLS],
    summary: ``,
    calculated: false,
  },
  [Datapoints.UNEMPLOYMENT_RATE]: {
    code: Datapoints.UNEMPLOYMENT_RATE,
    name: "Unemployment Rate",
    filterName: "Unemployment Rate",
    sources: [ExploratorySources.BLS],
    summary: `The unemployment rate across the geographic area in the most recent month. 
    The unemployment rate is calculated by dividing the unemployed population searching 
    for work by the total labor force. Lower unemployment rates are generally a signal of 
    a healthier local economy, which tends to be better for real estate in the long-run.`,
    calculated: false,
  },
  [Datapoints.UNEMPLOYMENT_LEVEL]: {
    code: Datapoints.UNEMPLOYMENT_LEVEL,
    name: "Unemployment Level",
    filterName: "Unemployment Level",
    sources: [ExploratorySources.BLS],
    summary: ``,
    calculated: false,
  },
  [Datapoints.TOTAL_NONFARM_PAYROLL]: {
    code: Datapoints.TOTAL_NONFARM_PAYROLL,
    name: "Total Non-Farm Payroll",
    filterName: "Total Non-Farm Payroll",
    sources: [ExploratorySources.BLS],
    summary: ``,
    calculated: false,
  },
  [Datapoints.TOTAL_PRIVATE_HOURLY_EARNINGS]: {
    code: Datapoints.TOTAL_PRIVATE_HOURLY_EARNINGS,
    name: "Total Private Hourly Earnings",
    filterName: "Total Private Hourly Earnings",
    sources: [ExploratorySources.BLS],
    summary: ``,
    calculated: false,
  },
  [Datapoints.TOTAL_PRIVATE_WEEKLY_HOURS]: {
    code: Datapoints.TOTAL_PRIVATE_WEEKLY_HOURS,
    name: "Total Private Weekly Hours",
    filterName: "Total Private Weekly Hours",
    sources: [ExploratorySources.BLS],
    summary: ``,
    calculated: false,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_WEEKLY_HOURS]: {
    code: Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_WEEKLY_HOURS,
    name: "Total Private Weekly Hours (Trailing Twelve Months)",
    filterName: "Total Private Weekly Hours (Trailing Twelve Months)",
    sources: [ExploratorySources.BLS],
    summary: ``,
    calculated: false,
  },
  [Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_HOURLY_EARNINGS]: {
    code: Datapoints.TRAILING_TWELVE_MONTHS_TOTAL_PRIVATE_HOURLY_EARNINGS,
    name: "Total Private Hourly Earning (Trailing Twelve Months)",
    filterName: "Total Private Hourly Earning (Trailing Twelve Months)",
    sources: [ExploratorySources.BLS],
    summary: ``,
    calculated: false,
  },
  [Datapoints.TRAILING_WEEKLY_EARNINGS]: {
    code: Datapoints.TRAILING_WEEKLY_EARNINGS,
    name: "Trailing weekly earning",
    filterName: "Trailing weekly earning",
    sources: [ExploratorySources.BLS],
    summary: ``,
    calculated: false,
  },
  [Datapoints.VACANCY_RATE]: {
    code: Datapoints.VACANCY_RATE,
    name: "Vacancy Rate",
    filterName: "Vacancy Rate",
    sources: [ExploratorySources.ACS],
    censusCodes: ["B25001_001E", "B25002_003E"],
    summary: `The number of vacant housing units in the area divided by total housing units.`,
    calculated: false,
  },
  // inventory_percentage: {
  //   code: "inventory_percentage",
  //   name: "Inventory Percentage of Total Units",
  //   filterName: "Inventory Percentage of Total Units",
  //   sources: [ExploratorySources.REALTOR, ExploratorySources.ACS],
  //   censusCodes: ["B25001_001E"],
  //   summary: `The number of Homes currently For Sale according to Realtor.com divided by the total numbers of owned Homes in the area.`,
  //   calculated: false,
  // },
  // sfh_unit_percentage: {
  //   code: "sfh_unit_percentage",
  //   name: "SFH Unit Percentage",
  //   filterName: "SFH Unit Percentage",
  //   sources: [ExploratorySources.ACS],
  //   censusCodes: ["B25001_001E", "B25024_003E", "B25024_002E"],
  //   summary: ``,
  //   calculated: false,
  // },
  // remote_worker_percentage: {
  //   code: "remote_worker_percentage",
  //   name: "Remote Worker Percentage",
  //   filterName: "Remote Worker Percentage",
  //   sources: [ExploratorySources.ACS],
  //   censusCodes: ["S2401_C01_007E", "S2401_C01_001E"],
  //   summary: ``,
  //   calculated: false,
  // },
  // vacant_percentage: {
  //   code: "vacant_percentage",
  //   name: "Vacant Percentage",
  //   filterName: "Vacant Percentage",
  //   sources: [ExploratorySources.ACS],
  //   censusCodes: ["B25004_001E", "B25001_001E"],
  //   summary: ``,
  //   calculated: false,
  // },
  // vacant_units: {
  //   code: "vacant_units",
  //   name: "Vacant Units",
  //   filterName: "Vacant Units",
  //   sources: [ExploratorySources.ACS],
  //   censusCodes: ["B25004_001E"],
  //   summary: ``,
  //   calculated: false,
  // },
  // tech_workers: {
  //   code: "tech_workers",
  //   name: "Tech Workers",
  //   filterName: "Tech Workers",
  //   sources: [ExploratorySources.ACS],
  //   censusCodes: ["S2401_C01_007E"],
  //   summary: ``,
  //   calculated: false,
  // },
  // total_workers: {
  //   code: "total_workers",
  //   name: "Total Workers",
  //   filterName: "Total Workers",
  //   sources: [ExploratorySources.ACS],
  //   censusCodes: ["S2401_C01_001E"],
  //   summary: ``,
  //   calculated: false,
  // },
  // tech_worker_percentage: {
  //   code: "tech_worker_percentage",
  //   name: "Tech Worker Percentage",
  //   filterName: "Tech Worker Percentage",
  //   sources: [ExploratorySources.ACS],
  //   censusCodes: ["S2401_C01_007E", "S2401_C01_001E"],
  //   summary: ``,
  //   calculated: false,
  // },
  // sfh_units: {
  //   code: "sfh_units",
  //   name: "SFH Units",
  //   filterName: "SFH Units",
  //   sources: [ExploratorySources.ACS],
  //   censusCodes: ["B25024_003E", "B25024_002E"],
  //   summary: ``,
  //   calculated: false,
  // },
  // remote_workers: {
  //   code: "remote_workers",
  //   name: "Remote Workers",
  //   filterName: "Remote Workers",
  //   sources: [ExploratorySources.ACS],
  //   censusCodes: ["B08006_017E"],
  //   summary: ``,
  //   calculated: false,
  // },
  // total_housing_units_in_structure: {
  //   code: "total_housing_units_in_structure",
  //   name: "Housing Units In Structure",
  //   filterName: "Housing Units In Structure",
  //   sources: [ExploratorySources.ACS],
  //   censusCodes: ["B25024_001E"],
  //   summary: `The number of total housing units, covering single-family, multifamily, condo, and manufactured housing, in the local area according to the US Census Bureau.`,
  //   calculated: false,
  // },
  // typical_rent: {
  //   code: "typical_rent",
  //   name: "Typical Rent",
  //   filterName: "Typical Rent",
  //   sources: [ExploratorySources.ACS],
  //   censusCodes: ["B25059_001E"],
  //   summary: ``,
  //   calculated: false,
  // },
  // yoy_price_reduced_percentage: {
  //   code: "yoy_price_reduced_percentage",
  //   name: "Listings With a Price Cut (YoY)",
  //   filterName: "Listings With a Price Cut (YoY)",
  //   sources: [ExploratorySources.REALTOR],
  //   summary: ``,
  //   calculated: false,
  // },
  // rental_rate_growth: {
  //   code: "rental_rate_growth",
  //   name: "Rental Rate Growth",
  //   filterName: "Rental Rate Growth",
  //   sources: [ExploratorySources.ZILLOW],
  //   summary: ``,
  //   calculated: false,
  // },
  // rental_yield_rate: {
  //   code: "rental_yield_rate",
  //   name: "Rental Yield",
  //   filterName: "Rental Yield",
  //   sources: [ExploratorySources.ZILLOW],
  //   summary: ``,
  //   calculated: false,
  // },
  // millenial_percentage: {
  //   code: "millenial_percentage",
  //   name: "Millenial HH Percentage",
  //   filterName: "Millenial HH Percentage",
  //   sources: [ExploratorySources.ACS],
  //   description: "25-44 HH. / Total HH.",
  //   censusCodes: ["B19037_019E", "B11001_001E"],
  //   summary: ``,
  //   calculated: false,
  // },
  // senior_percentage: {
  //   code: "senior_percentage",
  //   name: "Senior HH Percentage",
  //   filterName: "Senior HH Percentage",
  //   sources: [ExploratorySources.ACS],
  //   description: "65+ HH. / Total HH.",
  //   censusCodes: ["B19037_053E", "B11001_001E"],
  //   summary: ``,
  //   calculated: false,
  // },

  // median_home_price: {
  //   code: 'B25077_001E',
  //   name: 'Median Home Price',
  //   filterName: 'Median Home Price',
  //   sources: 'Census ACS',
  //

  // sfh_attached: {
  //   code: 'sfh_attached',
  //   name: 'SFH Attached Units',
  //   filterName: 'SFH Attached Units',
  //   sources: 'Census ACS',
  //   range: '2020'
  // },
  // sfh_detached: {
  //   code: 'sfh_detached',
  //   name: 'SFH Detached Units',
  //   filterName: 'SFH Detached Units',
  //   sources: 'Census ACS',
  //   range: '2020'
  // },

  // total_employed: {
  //   code: 'total_employed',
  //   name: 'Total Employed',
  //   filterName: 'Total Employed',
  //   sources: 'Census ACS',
  //   range: '2020'
  // },

  // yoy_inventory_growth_percentage: {
  //   code: 'yoy_inventory_growth_percentage',
  //   name: 'Inventory Growth (YOY)',
  //   filterName: 'Inventory Growth (YOY)',
  //   sources: 'Realtor.com',
  //   range: 'Nov. 2022',
  // },

  // work_from_home_population: {
  //   code: 'B08537_019E',
  //   name: 'Work From Home Population',
  //   filterName: 'Work From Home Population',
  //   sources: 'Census ACS',
  //   range: '2020',
  // },

  // listings_with_price_cut: {
  //   code: 'listings_with_price_cut',
  //   name: 'Listings With Price Cut',
  //   filterName: 'Listings With Price Cut',
  //   sources: 'Realtor.com',
  //   range: 'Nov. 2022',
  // },

  // mom_listings_with_price_cut_percentage: {
  //   code: 'mom_listings_with_price_cut_percentage',
  //   name: 'Listings With Price Cut (MOM)',
  //   filterName: 'Listings With Price Cut (MOM)',
  //   sources: 'Realtor.com',
  //   range: 'Nov. 2022',
  // },

  // mom_inventory_change_percentage: {
  //   code: 'mom_inventory_change_percentage',
  //   name: 'Inventory Change (MOM)',
  //   filterName: 'Inventory Change (MOM)',
  //   sources: 'Realtor.com',
  //

  //! Eliminated until BLS data pipe line is implemented
  // total_jobs: {
  //   code: "total_jobs",
  //   name: "Payroll Jobs",
  //   filterName: "Payroll Jobs",
  //   sources: "BLS",
  //   summary: `the number of jobs on employer payrolls in the local area as reported by the Bureau of Labor Statistics.`,
  //   //   calculated: false,
  // },
  // average_weekly_hours_worked: {
  //   code: "average_weekly_hours_worked",
  //   name: "Average Weekly Hours Worked",
  //   filterName: "Average Weekly Hours Worked",
  //   sources: "BLS",
  //
  // average_hourly_wage: {
  //   code: "average_hourly_wage",
  //   name: "Average Hourly Wage",
  //   filterName: "Average Hourly Wage",
  //   sources: "BLS",
  //
  // wage_growth_rate: {
  //   code: "wage_growth_rate",
  //   name: "Wage Growth Rate",
  //   filterName: "Wage Growth Rate",
  //   sources: "BLS",
  //
  // annual_earnings: {
  //   code: "annual_earnings",
  //   name: "Annual Earnings",
  //   filterName: "Annual Earnings",
  //   sources: "BLS",
  //   description: "Hourly Wage * Weekly Hours * 50",
  //
  // job_growth_rate: {
  //   code: "job_growth_rate",
  //   name: "Job Growth Rate",
  //   filterName: "Job Growth Rate",
  //   sources: "BLS",
  //   summary: `the % growth rate in payroll jobs over the last five years. Higher job growth rates tend to be correlated with higher home price appreciation in the long-run.`,
  //   //   calculated: false,
  // },

  //! Eliminated until census permits data pipeline is implemented
  // total_permits: {
  //   code: "total_permits",
  //   name: "Building Permits",
  //   filterName: "Building Permits",
  //   sources: "Census Permit",
  //   summary: `the number of building permits of new structures, both single-family and multifamily, pulled over the previous 12 months in the year. Data comes from the US Census Bureau.`,
  //   //   calculated: false,
  // },
  // single_family_permits: {
  //   code: "single_family_permits",
  //   name: "Single Family Permits",
  //   filterName: "Single Family Permits",
  //   sources: "Census Permit",
  //   range: "Nov. 2022",
  // },
  // multi_family_permits: {
  //   code: "multi_family_permits",
  //   name: "Multi Family Permits",
  //   filterName: "Multi Family Permits",
  //   sources: "Census Permit",
  //   range: "Nov. 2022",
  // },
  // permit_percentage: {
  //   code: "permit_percentage",
  //   name: "Building Permit %",
  //   filterName: "Building Permit %",
  //   sources: "Census Permit",
  //   range: "Nov. 2022",
  //   summary: `the number of building permits pulled over the previous 12 months divided by the numbers of jobs in the local economy. This figures a relative measure of how much home building is occurring. The higher the Permit %, the greater the likelihood of overbuilding and price declines.`,
  //   //   calculated: false,
  // },

  // Scores
  [ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE]: {
    code: ScoreDatapoints.HOME_PRICE_MOMENTUM_SCORE,
    name: "Home Price Forecast",
    filterName: "Home Price Forecast",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "A forecast of the future direction of home prices in the area based on the most recent trends in the local housing market. The Home Price Forecast is calculated by Reventure using a proprietary algorithm that analyzes the most recent trends in the local housing market.",
    calculated: true,
  },

  [ScoreDatapoints.RECENT_APPRECIATION_SCORE]: {
    code: ScoreDatapoints.RECENT_APPRECIATION_SCORE,
    name: "Recent Appreciation Score",
    filterName: "Recent Appreciation Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "An assessment of recent property value appreciation, based on data trends and recent activity in the market.",
    calculated: true,
  },
  [ScoreDatapoints.DAYS_ON_MARKET_SCORE]: {
    code: ScoreDatapoints.DAYS_ON_MARKET_SCORE,
    name: "Days on Market Score",
    filterName: "Days on Market Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "A measure of how long homes have been listed before sale, giving insight into demand and market conditions.",
    calculated: true,
  },
  [ScoreDatapoints.MORTGAGE_RATES_SCORE]: {
    code: ScoreDatapoints.MORTGAGE_RATES_SCORE,
    name: "Mortgage Rates Score",
    filterName: "Mortgage Rates Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "An index based on current mortgage rates, affecting affordability and buyer interest in the market.",
    calculated: true,
  },
  [ScoreDatapoints.INVENTORY_LEVELS_SCORE]: {
    code: ScoreDatapoints.INVENTORY_LEVELS_SCORE,
    name: "Inventory Score",
    filterName: "Inventory Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "Reflects the availability of homes in the market, influencing competition and pricing power.",
    calculated: true,
  },
  [ScoreDatapoints.PRICE_CUTS_SCORE]: {
    code: ScoreDatapoints.PRICE_CUTS_SCORE,
    name: "Price Cuts Score",
    filterName: "Price Cuts Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "An indicator of sellers' willingness to reduce prices to attract buyers, a signal of market demand dynamics.",
    calculated: true,
  },
  [ScoreDatapoints.INVESTOR_SCORE]: {
    code: ScoreDatapoints.INVESTOR_SCORE,
    name: "Investor Forecast",
    filterName: "Investor Forecast",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "A score that evaluates the investment potential of a property based on key metrics like appreciation potential, rental yields, and more. The Investor Score is designed to help investors identify the most promising real estate opportunities.",
    calculated: true,
  },
  [ScoreDatapoints.LONG_TERM_APPRECIATION_PERCENTILE]: {
    code: ScoreDatapoints.LONG_TERM_APPRECIATION_PERCENTILE,
    name: "Long Term Appreciation Score",
    filterName: "Long Term Appreciation Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "Measures the expected long-term appreciation of a property value based on historical trends in the area.",
    calculated: true,
  },
  [ScoreDatapoints.CAP_RATE_PERCENTILE]: {
    code: ScoreDatapoints.CAP_RATE_PERCENTILE,
    name: "Cap Rate Score",
    filterName: "Cap Rate Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "A metric used to evaluate the return on investment in a property, factoring in the capitalization rate based on income generation.",
    calculated: true,
  },
  [ScoreDatapoints.DEMOGRAPHIC_GROWTH_PERCENTILE]: {
    code: ScoreDatapoints.DEMOGRAPHIC_GROWTH_PERCENTILE,
    name: "Demographic Growth Score",
    filterName: "Demographic Growth Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "This metric evaluates the population growth in the area, helping investors understand the demographic trends influencing housing demand.",
    calculated: true,
  },
  [ScoreDatapoints.RENT_PERCENTILE]: {
    code: ScoreDatapoints.RENT_PERCENTILE,
    name: "Rent Growth Score",
    filterName: "Rent Growth Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "This metric evaluates the rent affordability and potential returns from renting a property in a given area.",
    calculated: true,
  },
  [ScoreDatapoints.OVERVALUATION_PERCENTILE]: {
    code: ScoreDatapoints.OVERVALUATION_PERCENTILE,
    name: "Valuation Score",
    filterName: "Valuation Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "Measures the potential overvaluation of properties in an area compared to historical price trends and market fundamentals.",
    calculated: true,
  },
  [ScoreDatapoints.HOME_BUYER_SCORE]: {
    code: ScoreDatapoints.HOME_BUYER_SCORE,
    name: "Long-Term Growth",
    filterName: "Long-Term Growth",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "A score designed for home buyers that helps evaluate the affordability and long-term stability of a housing market. The Home Buyer Score takes into account metrics such as price growth, affordability, and risk of overvaluation.",
    calculated: true,
  },
  [ScoreDatapoints.WEALTH_INCOME_PERCENTILE]: {
    code: ScoreDatapoints.WEALTH_INCOME_PERCENTILE,
    name: "Wealth/Income Score",
    filterName: "Wealth/Income Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "This metric assesses the economic wealth and income distribution in an area, giving insight into home buyer affordability.",
    calculated: true,
  },
  [ScoreDatapoints.VALUE_INCOME_RATIO_PERCENTILE]: {
    code: ScoreDatapoints.VALUE_INCOME_RATIO_PERCENTILE,
    name: "Affordability Score",
    filterName: "Affordability Score",
    sources: [ExploratorySources.REVENTURE],
    summary:
      "Assesses the relationship between property values and income levels in an area, providing insights into affordability for home buyers.",
    calculated: true,
  },
  [Datapoints.REMOTE_WORK_PERCENTAGE]: {
    code: Datapoints.REMOTE_WORK_PERCENTAGE,
    name: "Remote Work %",
    filterName: "Remote Work %",
    sources: [ExploratorySources.ACS],
    summary: `
    The percentage of employees in an area that work from home. This figure spiked during the pandemic in 2020-21 across most markets.
    `,
    calculated: false,
  },
};
